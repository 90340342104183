import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { followCursor as followCursorPlugin } from 'tippy.js';

import './styles.css';

const appendTo = () => document.body;

export default function Tooltip({
    children, text, className, component, disabled, position, align, tooltipTextClassName,
    duration, delay, followCursor, tooltipClassName
}) {
    const Component = component;
    const [arrow, setArrow] = useState(null);

    return (
        <Tippy
            className={cx('tooltip-container', tooltipClassName)}
            appendTo={appendTo}
            followCursor={followCursor}
            plugins={[followCursorPlugin]}
            // hideOnClick
            // trigger="click"
            // interactive
            content={(
                <div
                    className={cx('tooltip-content', {
                        [tooltipTextClassName]: !!tooltipTextClassName
                    })}
                >
                    {text}
                    <div className="tooltip-arrow" ref={setArrow} />
                </div>
            )}
            maxWidth={false}
            animation="shift-toward"
            placement={[position, align].filter(val => val).join('-')}
            offset={0}
            popperOptions={{
                modifiers: [
                    {
                        name: 'arrow',
                        options: {
                            element: arrow
                        },
                    },
                ],
            }}
            disabled={disabled}
            duration={duration}
            delay={delay}
        >
            <Component
                className={cx('tooltip', {
                    [className]: !!className
                })}
            >
                {children}
            </Component>
        </Tippy>
    );
}

Tooltip.position = {
    auto: 'auto',
    top: 'top',
    right: 'right',
    bottom: 'bottom',
    left: 'left',
};

Tooltip.align = {
    center: undefined,
    start: 'start',
    end: 'end'
};

Tooltip.defaultProps = {
    className: undefined,
    tooltipTextClassName: undefined,
    tooltipClassName: undefined,
    children: undefined,
    text: undefined,
    component: 'div',
    disabled: false,
    position: Tooltip.position.bottom,
    align: Tooltip.align.center,
    duration: 200,
    delay: [200, null],
    followCursor: false
};

Tooltip.propTypes = {
    component: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    tooltipTextClassName: PropTypes.string,
    tooltipClassName: PropTypes.string,
    text: PropTypes.node,
    disabled: PropTypes.bool,
    position: PropTypes.string,
    align: PropTypes.string,
    duration: PropTypes.number,
    delay: PropTypes.array,
    followCursor: PropTypes.oneOfType([
        PropTypes.string, PropTypes.bool
    ])
};
