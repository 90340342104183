import createSagas from './sagas';

class ModelCollectionUpdates {
    constructor(options) {
        this.name = options.name;
        this.collection = options.collection;
        this.model = null;
        this.optimisticUpdates = options.optimisticUpdates || false;

        this.initialize(this.collection);
    }

    get collectionActions() {
        const {
            push,
            update,
            remove,

        } = this.collection.actions;

        return {
            create: push,
            remove,
            update,
        };
    }

    get modelActions() {
        const {
            create,
            update,
            remove,
        } = this.model.actions;

        return {
            create,
            update,
            remove,
        };
    }

    initialize = (parent) => {
        this.model = parent;
        this.saga = this.getSaga(parent.actions);

        return {
            saga: this.saga,
        };
    }

    getSaga = () => {
        const { modelActions, collectionActions, optimisticUpdates } = this;

        return createSagas({
            modelActions,
            collectionActions,
            collectionSelectors: this.collection.selectors,
            optimisticUpdates,
        });
    }
}

export default ModelCollectionUpdates;
