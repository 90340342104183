import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Spinner from './spinner';
import { sizes, styleTypes } from '../../styles/constants';
import './styles.css';

const classNames = {
    default: 'button',
    loading: 'button-state-loading',
    outline: 'button-outline',
    noStyle: 'button-no-style',
    blockLevel: 'button-block-level',
    disabled: 'disabled',
    withShadow: 'button-shadow',
    round: 'button-round',
    mobile: 'button-mobile',
};

const classNamesByType = {
    [styleTypes.default]: 'button-type-default',
    [styleTypes.info]: 'button-type-info',
    [styleTypes.success]: 'button-type-success',
    [styleTypes.warning]: 'button-type-warning',
    [styleTypes.danger]: 'button-type-danger',
    [styleTypes.accent]: 'button-type-accent',
    [styleTypes.brand]: 'button-type-brand',
};

const classNameBySize = {
    [sizes.small]: 'button-size-small',
    [sizes.smallMedium]: 'button-size-small-medium',
    [sizes.medium]: 'button-size-medium',
    [sizes.large]: 'button-size-large',
};

function getClassNameByType(type) {
    return classNamesByType[type] || classNamesByType.default;
}

function getClassNameBySize(size) {
    return classNameBySize[size] || classNameBySize.medium;
}

export default function Button({
    block,
    className,
    children,
    disabled,
    loading,
    outline,
    styleType,
    size,
    component,
    withShadow,
    round,
    mobile,
    ...rest
}) {
    const Component = component;

    return (
        <Component
            className={classnames(classNames.default, {
                [classNames.blockLevel]: block,
                [classNames.loading]: loading,
                [classNames.outline]: outline,
                [classNames.noStyle]: styleType === 'no-style',
                [classNames.disabled]: disabled || loading,
                [classNames.withShadow]: withShadow,
                [classNames.round]: round,
                [classNames.mobile]: mobile,
                [getClassNameByType(styleType)]: (
                    styleType
                    && styleType !== 'no-style'
                ),
                [getClassNameBySize(size)]: size,
                [className]: className,
            })}
            disabled={disabled || loading}
            {...rest}
        >
            <span
                className={classnames('button-content', {
                    'button-content-hidden': loading,
                })}
            >
                {children}
            </span>

            {loading && (
                <Spinner className="button-spinner" />
            )}
        </Component>
    );
}

Button.sizes = sizes;

Button.styleTypes = styleTypes;

Button.classNames = {
    icon: {
        large: 'button-icon-large',
        small: 'button-icon-small',
    }
};

Button.defaultProps = {
    disabled: false,
    loading: false,
    outline: false,
    className: undefined,
    children: undefined,
    styleType: 'default',
    size: 'medium',
    block: false,
    component: 'button',
    withShadow: false,
    round: false,
    mobile: false,
};

Button.propTypes = {
    /**
        Toggle for disabled state
    */
    disabled: PropTypes.bool,
    /**
        Toggle for loading state
    */
    loading: PropTypes.bool,
    /**
        Toggle outlined style modifier
    */
    outline: PropTypes.bool,
    /**
        Compoments className
    */
    className: PropTypes.string,
    /**
        Contents
    */
    children: PropTypes.node,
    /**
        Toggle to stretch button to width of container
    */
    block: PropTypes.bool,
    /**
        Color Type
    */
    styleType: PropTypes.oneOf([
        'default',
        'info',
        'success',
        'warning',
        'danger',
        'accent',
        'no-style',
        'brand'
    ]),
    /**
        Size
    */
    size: PropTypes.oneOf([
        'small',
        'small-medium',
        'medium',
        'large',
    ]),
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
    ]),
    /**
        Toggle button shadow
    */
    withShadow: PropTypes.bool,
    /**
        Toggle round button styles
    */
    round: PropTypes.bool,
    mobile: PropTypes.bool,
};
