import { takeEvery, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { csfRelatedLinkCreateModel, csfRelatedLinkModel } from './csf-related-link/csf-related-links-models';
import { csfModel } from './csf-models';

function* handleUpdateCSFHealthStatus({ payload }) {
    const data = Array.isArray(payload) ? payload[0] : payload;
    yield put({
        type: csfModel.actions.update.success.getType(),
        payload: get(data, 'primary_link', {})
    });
}

export default function* driverDetailsSaga() {
    yield takeEvery(csfRelatedLinkCreateModel.actions.success, handleUpdateCSFHealthStatus);
    yield takeEvery(csfRelatedLinkModel.actions.remove.success, handleUpdateCSFHealthStatus);
}
