import { createAction } from 'redux-act';
import { prefix, attributes } from '../config';

export default {
    initialize: createAction(`${prefix}/INITIALIZE`),
    destroy: createAction(`${prefix}/DESTROY`),

    [attributes.transport]: {
        setState: createAction(`${prefix}/${attributes.transport}/SET_STATE`),
        send: createAction(`${prefix}/${attributes.transport}/SEND`),
        receive: createAction(`${prefix}/${attributes.transport}/RECEIVE`),
        remove: createAction(`${prefix}/${attributes.transport}/REMOVE`),
    },

    [attributes.app]: {
        setVisibilityState: createAction(`${prefix}/${attributes.app}/SET_VIS_STATE`),
        setMasterTabState: createAction(`${prefix}/${attributes.app}/SET_MASTER_TAB_STATE`),
    },

    [attributes.notification]: {
        click: createAction(`${prefix}/${attributes.notification}/CLICK`),
        close: createAction(`${prefix}/${attributes.notification}/CLOSE`),
    },

    [attributes.sync]: {
        receiveAdd: createAction(`${prefix}/${attributes.sync}/RECEIVE_ADD`),
        receiveRemove: createAction(`${prefix}/${attributes.sync}/RECEIVE_REMOVE`),
        sendAdd: createAction(`${prefix}/${attributes.sync}/SEND_ADD`),
        sendRemove: createAction(`${prefix}/${attributes.sync}/SEND_REMOVE`),
    }
};
