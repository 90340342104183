import { types } from '../../../shared/utils/entity-type';

import StrategyChildrenList from '../create-wizard/items-list';
import ObjectiveForm from '../form/objective-form';
import PlanActionForm from '../../goalmap-plans-actions/form';
import MilestoneCreatePage1 from '../../goalmap-milestones/create/milestone-create-page1';
import MilestoneCreatePage2 from '../../goalmap-milestones/create/milestone-create-page2';

import { mapInitialCreateValuesActionFromParent } from '../../goalmap-plans-actions/utils';
import { mapInitialCreateValuesMilestoneFromParent } from '../../goalmap-milestones/create/utils';
import { mapInitialCreateValuesObjectiveFromParent } from '../utils';

export function getScreensByType(type, titles) {
    switch (type) {
        case types.action:
            return [
                {
                    ContentComponent: StrategyChildrenList,
                    description: 'Select type of entity'
                },
                {
                    ContentComponent: PlanActionForm,
                    description: `Name your ${titles[types.action]}, assign Owner, add Target State`
                },
            ];
        case types.milestone:
            return [
                {
                    ContentComponent: StrategyChildrenList,
                    description: 'Select type of entity'
                },
                {
                    ContentComponent: MilestoneCreatePage1,
                    description: `Name your ${titles[types.milestone]}, assign Owner, add Target State`
                },
                {
                    ContentComponent: MilestoneCreatePage2,
                    description: `Select type of ${titles[types.milestone]} and configure it`
                },
            ];
        case types.sub_segment:
        default:
            return [
                {
                    ContentComponent: StrategyChildrenList,
                    description: 'Select type of entity'
                },
                {
                    ContentComponent: ObjectiveForm,
                    description: `Name your ${titles[types.sub_segment]}, assign Owner, add Target State`
                },
            ];
    }
}

const emptySelector = () => ({});

export function getInitialValuesSelectorByType(type) {
    switch (type) {
        case types.action:
            return mapInitialCreateValuesActionFromParent;
        case types.milestone:
            return mapInitialCreateValuesMilestoneFromParent;
        case types.sub_segment:
            return mapInitialCreateValuesObjectiveFromParent;
        default:
            return emptySelector;
    }
}

export const getFirstAvailableEntity = availableEntities => Object.keys(availableEntities).find(key => availableEntities[key] === true);
