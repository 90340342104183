import { debounce, find } from 'lodash';
import {
    useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import u from 'updeep';
import { selectUserId, selectIsAdmin } from '../../../modules/user/selectors';
import { selectCurrentLocation } from '../../selectors/routing';
import { getHrefByLocation } from '../../utils/url';
import { queryFields, roles } from './team-section-constants';
import TeamModel from './team-model';

const defaultSort = 'title,asc';

const defaultParams = {
    order_by: defaultSort,
};

const submitTypesByRoleId = {
    [roles.leader]: TeamModel.submitTypes.leader,
    [roles.member]: TeamModel.submitTypes.member,
};


const teamUserMapper = user => ({
    userId: user.pivot.userId,
    teamRoleId: user.pivot.teamRoleId,
});

const useTeamSection = ({ model }) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [sortValue, setSortValue] = useState(defaultSort);
    const [params, setParams] = useState(defaultParams);
    const [createModalVisible, setCreateModalVisible] = useState(false);

    const isAdmin = useSelector(selectIsAdmin);
    const location = useSelector(selectCurrentLocation);
    const detailsId = location.query[queryFields.teamId];
    const updateModalVisible = !!detailsId;
    const details = useSelector(model.selectors.getAttributes);
    const detailsError = useSelector(model.selectors.getErrorFetch);
    const detailsFetching = useSelector(model.selectors.getStateFetching);
    const userId = useSelector(selectUserId);

    const createFormInitialValues = {
        teamUsers: [
            { userId, teamRoleId: roles.leader },
            {},
        ]
    };

    const updateFormInitialValues = useMemo(() => {
        const nextTeamUsers = details.teamUsers?.map(teamUserMapper);

        return u.updateIn('teamUsers', nextTeamUsers, details);
    }, [details]);

    const debouncedSetParams = useCallback(debounce((search, sort) => {
        const hasSearch = !!search;

        setParams({
            ...defaultParams,
            q: hasSearch ? search : undefined,
            order_by: sort,
        });
    }, 300), []);

    useEffect(() => {
        debouncedSetParams(searchValue, sortValue);
    }, [searchValue, sortValue]);

    useEffect(() => {
        if (detailsId) {
            const action = model.actions.fetch.request({ id: detailsId });
            dispatch(action);
        }
    }, [detailsId]);

    const onTeamRemove = useCallback((id) => {
        const action = model.actions.remove.request({ id });

        dispatch(action);
    }, [dispatch]);

    const onUpdateModalShow = useCallback((id) => {
        const action = push(
            getHrefByLocation(location, {
                [queryFields.teamId]: id
            })
        );

        dispatch(action);
    }, []);

    const onUpdateModalHide = useCallback(() => {
        const action = push(
            getHrefByLocation(location, {
                [queryFields.teamId]: undefined
            })
        );
        dispatch(action);
    }, [dispatch]);

    const onCreateModalShow = useCallback(() => {
        setCreateModalVisible(true);
    }, []);

    const onCreateModalHide = useCallback(() => {
        setCreateModalVisible(false);
    }, []);

    const onTeamUpdate = (data, options) => {
        const dataToSend = { ...data };
        let submitType;
        if (isAdmin) {
            submitType = TeamModel.submitTypes.leader;
        } else {
            const { teamUsers } = updateFormInitialValues;
            const roleId = find(teamUsers, { userId })?.teamRoleId || roles.member;
            submitType = submitTypesByRoleId[roleId];
        }

        const persistedTeamUserIds = details.teamUsers.map(member => member.pivot.userId);

        dataToSend.params = {
            ...dataToSend.params,
            persistedTeamUserIds,
            submitType,
        };

        const action = model.actions.update.request(dataToSend, options);
        dispatch(action);
    };

    const onTeamCreate = useCallback((...props) => {
        const action = model.actions.create.request(...props);
        dispatch(action);
    }, [dispatch]);

    return {
        searchValue,
        setSearchValue,
        sortValue,
        setSortValue,
        onTeamRemove,
        onTeamUpdate,
        onTeamCreate,
        updateModalVisible,
        onUpdateModalShow,
        onUpdateModalHide,
        createModalVisible,
        onCreateModalShow,
        onCreateModalHide,
        params,
        details,
        detailsError,
        detailsFetching,
        updateFormInitialValues,
        createFormInitialValues,
        useRestrictions: !isAdmin,
    };
};

export default useTeamSection;
