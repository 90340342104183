import { find, flow, isEmpty } from 'lodash';
import { typeCodes } from '../../../shared/utils/entity-type';
import { roundDecimal } from '../../../shared/utils/math';
import {
    requestParams,
    filterOptionValues,
    tableViewElelementLevels,
    filterOptionValueTitles,
    tableViewItemAttributes,
    workCenterTableViewTypes,
} from '../constants';

const entityTypeNumber = (entityType) => entityType.map((type) => Number(type));

export const isActionOnly = (entityType) =>
    entityType.length > 0 && !entityType.includes(typeCodes.kpi);

export const isKpiOnly = (entityType) =>
    entityType.length > 0 &&
    !entityType.includes(typeCodes.action) &&
    !entityType.includes(typeCodes.tactic);

export const isActionsOnly = (entityType) =>
    flow(entityTypeNumber, isActionOnly)(entityType);

export const isKpisOnly = (entityType) =>
    flow(entityTypeNumber, isKpiOnly)(entityType);

export const getLastTrendFromDataPoints = (data_points = []) => {
    if (isEmpty(data_points)) return null;
    const trend = data_points.reverse().find((point) => !!point.trend)?.trend;
    return trend ? `${roundDecimal(trend)} %` : null;
};

const colors = {
    danger: 'danger',
    warning: 'warning',
    warningDark: 'warning-dark',
    success: 'success',
    info: 'info',
};

const getColorByCount = (count, baseColor = colors.danger) =>
    count > 0 ? baseColor : colors.success;

const colorsBySlug = {
    [filterOptionValues.updateRequired]: getColorByCount,
    [filterOptionValues.pastDue]: getColorByCount,
    [filterOptionValues.comingDue]: (count) =>
        getColorByCount(count, colors.warning),
    [filterOptionValues.current]: () => colors.success,
    [filterOptionValues.due]: (count) =>
        getColorByCount(count, colors.warningDark),
    [filterOptionValues.draft]: () => colors.info,
};

export const prepareGridTypeFilter = (filter) => {
    const { slug, count } = filter;
    return {
        name: requestParams.filterType,
        value: slug,
        label: filterOptionValueTitles[slug],
        count,
        color: colorsBySlug[slug]?.(count),
    };
};

const sortedGridTypeFilterSlug = [
    filterOptionValues.allActive,
    filterOptionValues.draft,
    filterOptionValues.updateRequired,
    filterOptionValues.pastDue,
    filterOptionValues.due,
    filterOptionValues.current,
    filterOptionValues.comingDue,
    filterOptionValues.startingSoon,
    filterOptionValues.notStarted,
    filterOptionValues.updatedRecently,
    filterOptionValues.allInactive,
];

export const sortGridTypeFilters = (filters) => {
    return sortedGridTypeFilterSlug.reduce((accum, slug) => {
        const filter = find(filters, { slug });

        if (filter) {
            accum.push(filter);
        }

        return accum;
    }, []);
};

export const isChildLevel = (item) => !!item.parent?.id;
export const isSubParentLevel = (item) =>
    item.level !== tableViewElelementLevels.parent && !!item.counter;
export const isParentLevel = (item) =>
    item.level === tableViewElelementLevels.parent && !!item.counter;

const allHeaderElements = [
    { title: 'Entity Type', class: 'Entity Type', attribute: tableViewItemAttributes.type_code },
    { title: 'Name', class: 'Name', attribute: tableViewItemAttributes.title },
    {
        title: 'Health',
        class: 'Health',
        attribute: tableViewItemAttributes.health,
    },
    {
        title: 'Status',
        class: 'Status',
        attribute: tableViewItemAttributes.status,
    },
    {
        title: 'Owner',
        class: 'Owner',
        attribute: tableViewItemAttributes.owner,
    },
    { title: 'Plan', class: 'Value', attribute: tableViewItemAttributes.plan },
    {
        title: 'Location',
        class: 'Location',
        attribute: tableViewItemAttributes.location,
    },
    {
        title: 'Start Date',
        class: 'Date',
        attribute: tableViewItemAttributes.start_date,
    },
    {
        title: 'Target Date',
        class: 'Date',
        attribute: tableViewItemAttributes.target_date,
    },
    {
        title: 'Closed Date',
        class: 'Date',
        attribute: tableViewItemAttributes.closed_date,
    },
    {
        title: 'Last Updated',
        class: 'Date',
        attribute: tableViewItemAttributes.last_update,
    },
    {
        title: 'Priority',
        class: 'Value',
        attribute: tableViewItemAttributes.priority,
    },
    {
        title: 'Frequency',
        class: 'Value',
        attribute: tableViewItemAttributes.frequency,
    },
    {
        title: 'Target Value',
        class: 'Value',
        attribute: tableViewItemAttributes.target_value,
    },
    {
        title: 'Current Value',
        class: 'Value',
        attribute: tableViewItemAttributes.current_value,
    },
    {
        title: 'Trend',
        class: 'Value',
        attribute: tableViewItemAttributes.trend,
    },
];

const draftHeaderElements = [
    { title: 'Name', class: 'Name', attribute: tableViewItemAttributes.title },
    {
        title: 'Created By',
        class: 'Owner',
        attribute: tableViewItemAttributes.created_by,
    },
    {
        title: 'Owner',
        class: 'Owner',
        attribute: tableViewItemAttributes.owner,
    },
    {
        title: 'Target State / Description',
        class: 'Description',
        attribute: tableViewItemAttributes.description,
    },
    {
        title: 'Created Date',
        class: 'Date',
        attribute: tableViewItemAttributes.created_date,
    },
];

const ownerAttributes = [tableViewItemAttributes.location];
const planAttributes = [tableViewItemAttributes.owner];
const actionAttributes = [
    tableViewItemAttributes.closed_date,
    tableViewItemAttributes.priority,
];
const kpiAttributes = [
    tableViewItemAttributes.target_value,
    tableViewItemAttributes.current_value,
    tableViewItemAttributes.trend,
];

export const getHeaderElements = (
    tableViewType,
    entityType = [],
    filterType
) => {
    if (filterType === filterOptionValues.draft) {
        return draftHeaderElements;
    }

    return allHeaderElements.filter((item) => {
        if ([tableViewItemAttributes.plan, tableViewItemAttributes.type_code].includes(item.attribute)) {
            return false;
        }

        if (
            tableViewType === workCenterTableViewTypes.plan &&
            ownerAttributes.includes(item.attribute)
        )
            return false;
        if (
            tableViewType === workCenterTableViewTypes.owner &&
            planAttributes.includes(item.attribute)
        )
            return false;
        if (isActionsOnly(entityType) && kpiAttributes.includes(item.attribute))
            return false;
        if (isKpisOnly(entityType) && actionAttributes.includes(item.attribute))
            return false;
        return true;
    });
};

export const getExportHeaderElements = (entityType = []) => {
    return allHeaderElements.filter((item) => {
        if (isActionsOnly(entityType) && kpiAttributes.includes(item.attribute))
            return false;
        if (isKpisOnly(entityType) && actionAttributes.includes(item.attribute))
            return false;
        return true;
    });
};
