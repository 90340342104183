import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { invert } from 'lodash';
import config from '../../../config';
import './styles.css';

const { statusMap } = config;
const sizes = Object.freeze({
    small: 'small',
    smallMedium: 'small-medium',
    medium: 'medium',
    large: 'large',
});

export default function HealthIndicator({
    className,
    status,
    size,
    isPrintMode,
    ...rest
}) {
    const safeStatus = status || 1;

    return (
        <span
            className={classnames('status-indicator', {
                [`status-indicator-${statusMap[safeStatus]}`]: true,
                [`status-indicator-size-${size}`]: true,
                'status-indicator-print': isPrintMode,
                [className]: className,
            })}
            {...rest}
        />
    );
}

HealthIndicator.defaultProps = {
    className: undefined,
    size: sizes.medium,
    status: undefined,
    isPrintMode: false,
};

HealthIndicator.propTypes = {
    className: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf([
        sizes.small,
        sizes.smallMedium,
        sizes.medium,
        sizes.large,
    ]),
    isPrintMode: PropTypes.bool,
};

HealthIndicator.sizes = sizes;
HealthIndicator.statuses = invert(statusMap);
