import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { colorThemeSelectors } from '../../../modules/application';
import './styles.css';

function LoadingIndicator({
    className, size, centered, accentColor
}) {
    const commonAnimateProps = {
        attributeName: 'fill',
        from: '#c9c9c9',
        to: accentColor,
        repeatCount: 'indefinite',
        dur: '2s',
        values: `${accentColor};${accentColor};#c9c9c9;#c9c9c9`,
        keyTimes: '0;0.1;0.2;1',
    };

    return (
        <span
            className={classnames('loading-indicator', {
                [size]: size,
                [className]: className,
                'centered': centered
            })}
        >
            <svg
                width="90"
                height="90"
                preserveAspectRatio="xMidYMid"
                className="sc-circles"
            >
                <rect width="90" height="90" className="pr-bg" fill="none" />
                <circle cx="65" cy="15" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.0s"
                    />
                </circle>
                <circle cx="40" cy="15" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.125s"
                    />
                </circle>
                <circle cx="15" cy="15" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.25s"
                    />
                </circle>
                <circle cx="15" cy="40" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.375s"
                    />
                </circle>
                <circle cx="40" cy="40" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.5s"
                    />
                </circle>
                <circle cx="65" cy="40" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.625"
                    />
                </circle>
                <circle cx="65" cy="65" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.75s"
                    />
                </circle>
                <circle cx="40" cy="65" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="0.875s"
                    />
                </circle>
                <circle cx="15" cy="65" r="5" className="cr a">
                    <animate
                        {...commonAnimateProps}
                        begin="1.0s"
                    />
                </circle>
            </svg>
        </span>
    );
}

LoadingIndicator.defaultProps = {
    className: undefined,
    size: 'large',
    centered: false
};

LoadingIndicator.propTypes = {
    centered: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.oneOf([
        'small', 'medium', 'large'
    ]),
    accentColor: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        accentColor: colorThemeSelectors.selectAccentColor(state),
    };
}

export default connect(mapStateToProps)(LoadingIndicator);
