import { isNil } from 'lodash';
/** @namespace Utils.String */

/**
 * Capitalize string
 * @function capitalizeFirstLetter
 * @memberof Utils.String
 * @param {string} string String which will be capitalized
 * @return {string} Capitalized string
 */
export function capitalizeFirstLetter(string) { // eslint-disable-line import/prefer-default-export
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Trim string from the end
 * @function trimRight
 * @memberof Utils.String
 * @param {string} string String which will be trimmed from the end
 * @param {string} charsList Chars which will be trimmed
 * @return {string} Trimmed string from the end
 */
export function trimRight(string, charsList) {
    if (charsList === undefined) {
        charsList = '\\s';
    }

    return string.replace(new RegExp(`[${charsList}]+$`), '');
}

/**
 * Trim string from the start
 * @function trimLeft
 * @memberof Utils.String
 * @param {string} string String which will be trimmed from the start
 * @param {string} charsList Chars which will be trimmed
 * @return {string} Trimmed string from the start
 */
export function trimLeft(string, charsList) {
    if (charsList === undefined) {
        charsList = '\\s';
    }

    return string.replace(new RegExp(`^[${charsList}]+`), '');
}

/**
 * Trim string
 * @function trimLeft
 * @memberof Utils.String
 * @param {string} string String which will be trimmed
 * @return {string} Trimmed string
 */
export function trim(string) {
    return trimRight(trimLeft(string));
}

/**
 * Check Empty string
 * @function isEmpty
 * @memberof Utils.String
 * @param {string} string String to test
 * @return {boolean} Is empty value
 */
export function isEmpty(string) {
    return isNil(string) || string === '';
}
