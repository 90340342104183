import { takeLatest } from 'redux-saga/effects';
import createAsyncSaga from '../../../shared/sagas/async';

export default function createSaga(actions, api) {
    const getList = createAsyncSaga(actions, api);
    const getListMore = createAsyncSaga({
        success: actions.requestMoreSuccess,
        error: actions.requestMoreError,
    }, api);

    return function* listSaga() {
        yield takeLatest(actions.request.getType(), getList);
        yield takeLatest(actions.requestMore.getType(), getListMore);
    };
}
