import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import {
    DropdownDeprecated,
    Clickable,
    Icon,
    Avatar,
} from '../../../shared/components';
import { createUrl, paths } from '../../../routes/rules';
import config from '../../../config';
import ZapierLogo from './zapier-logo';
import '../styles/user-info.css';

export default class UserInfo extends Component {
    handleDropdownHide = () => {
        this.dropdown.hide();
    };

    render() {
        const {
            userName, logout, avatar, userEmail, isUserHavePermissionForSystemSettings,
            isZapierActive
        } = this.props;

        return (
            <DropdownDeprecated
                ref={(el) => { this.dropdown = el; }}
                containerClassName="nav-header-user-info-container"
                triggerClassName="nav-header-user-info-trigger"
                triggerActiveClassName="active"
                trigger={({ open }) => (
                    <Fragment>
                        <Avatar
                            src={avatar}
                            full_name={userName}
                            alt="user avatar"
                            className="nav-header-user-info-avatar"
                            borderless
                        />
                        <span className="user-full-name-trigger">{userName}</span>
                        <Icon
                            name={open ? 'angle-up' : 'angle-down'}
                            className="nav-header-user-info-icon"
                        />
                    </Fragment>
                )}
            >
                <div className="dropdown-content">
                    <div className="dropdown-content-item">
                        <Avatar
                            src={avatar}
                            full_name={userName}
                            alt="user avatar"
                            className="user-avatar"
                            classNameContainer="user-avatar-container"
                            borderless
                        />
                        <div>
                            <span className="user-full-name">{userName}</span>
                            <span className="user-email">{userEmail}</span>
                        </div>
                    </div>
                    <Link
                        to={createUrl(paths.profile)}
                        className="dropdown-content-item"
                        onClick={this.handleDropdownHide}
                    >
                        <Icon fixedWidth type="regular" name="user-circle" className="item-icon" /> Profile
                    </Link>
                    <Link
                        to={createUrl(paths.preferences)}
                        className="dropdown-content-item"
                        onClick={this.handleDropdownHide}
                    >
                        <Icon fixedWidth type="regular" name="cog" className="item-icon" /> Preferences
                    </Link>

                    <Link
                        to={createUrl(paths.teams)}
                        className="dropdown-content-item"
                        onClick={this.handleDropdownHide}
                    >
                        <Icon fixedWidth type="regular" name="users" className="item-icon" /> My Teams
                    </Link>

                    {isUserHavePermissionForSystemSettings && (
                        <Link
                            to={createUrl(paths.settings)}
                            className="dropdown-content-item"
                            onClick={this.handleDropdownHide}
                        >
                            <Icon fixedWidth type="regular" name="tools" className="item-icon" /> System Settings
                        </Link>
                    )}

                    {!config.zapierDisabled() && isZapierActive && (
                        <Link
                            to={createUrl(paths.integrationZapier)}
                            className="dropdown-content-item"
                            onClick={this.handleDropdownHide}
                        >
                            <div className="dropdown-content-menu-item-content">
                                <ZapierLogo className="dropdown-content-icon-svg item-icon" />
                                <div className="dropdown-content-menu-item-text">
                                    Add Integration
                                    <div className="dropdown-content-item-desc">
                                        Powered by Zapier
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )}

                    <a
                        href={config.helpCenterLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="dropdown-content-item"
                        onClick={this.handleDropdownHide}
                    >
                        <Icon fixedWidth type="regular" name="life-ring" className="item-icon" /> Help Center
                    </a>

                    <Clickable
                        className="dropdown-content-item"
                        action="logout"
                        onClick={() => {
                            logout();
                            this.handleDropdownHide();
                        }}
                    >
                        <Icon fixedWidth type="regular" name="sign-out-alt" className="item-icon" /> Logout
                    </Clickable>
                </div>
            </DropdownDeprecated>
        );
    }
}

UserInfo.defaultProps = {
    userName: 'First Last',
    avatar: undefined,
};

UserInfo.propTypes = {
    logout: PropTypes.func.isRequired,
    userName: PropTypes.string,
    avatar: PropTypes.string,
    userEmail: PropTypes.string.isRequired,
    isZapierActive: PropTypes.bool.isRequired,
    isUserHavePermissionForSystemSettings: PropTypes.bool.isRequired,
};
