import config from '../config';
import { localStorageSlicer as navDrawerRightSlicer } from '../modules/navigation-drawer-right/reducer';
import {
    applicationSettingsSelectors, applicationSettingsReducer, applicationSettingsModel, serviceWorkerSelectors,
    serviceWorkerReducer, colorThemeReducer, colorThemeSelectors,
} from '../modules/application';
import { name as navigationContextName } from '../modules/navigation-context/config';
import { navigationContextSelector } from '../modules/navigation-context/selectors';
import { notificationsHeaderCollection } from '../modules/navigation-header/notifications/entity';
import { stateBranchName as authStateBranchName } from '../modules/authentication/config';
import { getAuthenticationPersistShape } from '../modules/authentication/selectors';
import { stateBranchName as userStateBranchName } from '../modules/user/config';
import { getUserBranch } from '../modules/user/selectors';
import * as changePassword from '../modules/profile/change-password/change-password-model';
import { selectChangePasswordPersistState } from '../modules/profile/change-password/selectors';
import { selectStateNavigatorTreePersistState, name as treeNavigationName } from '../modules/plan-navigator/reducer';
import workCenterCollection from '../modules/work-center/work-center-collection';
import { selectFilterParamsPersistState } from '../modules/work-center/selectors';
import serviceAuthModel from '../modules/authentication/service-auth/service-auth-model';
import { name as workCenterViewType, selectWorkCenterViewTypePersistState } from '../modules/work-center/reducer';
import workCenterTimelineModel from '../modules/work-center/timeline/model/timeline-model';
import { selectFilterParamsPersistStateTimeline } from '../modules/work-center/timeline/model/timeline-model-selectors';
import workCenterGridModel from '../modules/work-center/grid/model/grid-model';
import { selectFilterParamsPersistStateGrid } from '../modules/work-center/grid/model/grid-model-selectors';
import workCenterCustomFilterModel from '../modules/work-center/custom-filters/model';

export function slicer() {
    return state => ({
        'navDrawerRight': navDrawerRightSlicer(state),
        [authStateBranchName]: getAuthenticationPersistShape(state),
        [colorThemeReducer.path]: colorThemeSelectors.persistStateSelector(state),
        [applicationSettingsReducer.branchName]: applicationSettingsSelectors.localStorageSelector(state),
        [applicationSettingsModel.name]: applicationSettingsSelectors.selectApplicationSettingsPersist(state),
        // eslint-disable-next-line import/no-named-as-default-member
        [navigationContextName]: navigationContextSelector(state),
        [notificationsHeaderCollection.name]: notificationsHeaderCollection.selectors.getBranch(state),
        [userStateBranchName]: getUserBranch(state),
        [serviceWorkerReducer.path]: serviceWorkerSelectors.selectPersistShape(state),
        [changePassword.constants.name]: selectChangePasswordPersistState(state),
        [treeNavigationName]: selectStateNavigatorTreePersistState(state),
        [workCenterCollection.name]: selectFilterParamsPersistState(state),
        [workCenterTimelineModel.name]: selectFilterParamsPersistStateTimeline(state),
        [workCenterGridModel.name]: selectFilterParamsPersistStateGrid(state),
        [serviceAuthModel.name]: serviceAuthModel.selectors.getRoot(state),
        [workCenterViewType]: selectWorkCenterViewTypePersistState(state),
        [workCenterCustomFilterModel.name]: workCenterCustomFilterModel.selectors.getRoot(state),
    });
}

export const localStoragePath = config.localStorageStateKey;
