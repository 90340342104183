import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { get } from 'lodash';
import { normalizeNumberValue } from '../../../../shared/utils/form';
import {
    Editor, Col, Row, Text, Input,
} from '../../../../shared/components';
import { attributes as KPIAttributes, dataPointAttributes } from '../../../goalmap-milestones/constants';
import { attributes } from '../../constants';
import PeriodSelector from '../../../goalmap-milestones/period-selector';

export const PeriodActualField = ({
    index, initialValues, isUpdateDataPoint, ...rest
}) => {
    const fieldNamePrefix = `${KPIAttributes.data_points}.${index}`;
    const hasInitialValue = get(initialValues, [
        KPIAttributes.data_points,
        index,
        dataPointAttributes.value,
    ]);
    return (
        <Field
            component={Input}
            name={`${fieldNamePrefix}.${attributes.value}`}
            type="number"
            step="any"
            label=""
            disabled={(hasInitialValue === 0 && isUpdateDataPoint) ? true : !!hasInitialValue}
            normalize={normalizeNumberValue}
            required
            {...rest}
        />
    );
};

PeriodActualField.propTypes = {
    isUpdateDataPoint: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    initialValues: PropTypes.object.isRequired,
};

export const PeriodTargetField = ({
    index, initialValues, isUpdateDataPoint, ...rest
}) => {
    const fieldNamePrefix = `${KPIAttributes.data_points}.${index}`;
    const hasInitialPeriodTarget = get(initialValues, [
        KPIAttributes.data_points,
        index,
        dataPointAttributes.period_target,
    ]);
    return (
        <Field
            component={Input}
            name={`${fieldNamePrefix}.${attributes.period_target}`}
            type="number"
            step="any"
            label=""
            disabled={(hasInitialPeriodTarget === 0 && isUpdateDataPoint) ? true : !!hasInitialPeriodTarget}
            required
            {...rest}
        />
    );
};

PeriodTargetField.propTypes = {
    isUpdateDataPoint: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    initialValues: PropTypes.object.isRequired,
};

export const PeriodNoteFields = ({
    frequency, setPeriodStart, isUpdateDataPoint, goal_id
}) => {
    const handlePeriodChange = (item) => {
        setPeriodStart(item.value);
    };

    return (
        <>
            <Row>
                <Col xs={12} lg={3}>
                    <PeriodSelector
                        name={attributes.period_start}
                        frequency={frequency}
                        placeholder="Period *"
                        onChange={handlePeriodChange}
                        label={(
                            <span className="milestone-data-point-form-label">
                                Period <Text styleType="danger">*</Text>
                            </span>
                        )}
                        props={{
                            alwaysRenderLabel: true,
                        }}
                        required
                        disabled={isUpdateDataPoint}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name={attributes.progress_note_content}
                        component={Editor}
                        props={{
                            label: 'Note',
                            toolbarHidden: true,
                            controlsHidden: true,
                            wrapperClassName: 'milestone-data-point-editor',
                            // alwaysRenderLabel: true,
                            goal_id,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

PeriodNoteFields.propTypes = {
    frequency: PropTypes.string.isRequired,
    setPeriodStart: PropTypes.func.isRequired,
    isUpdateDataPoint: PropTypes.bool.isRequired,
    goal_id: PropTypes.number.isRequired,
};
