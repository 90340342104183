import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import DropdownDeprecated from '../dropdown/deprecated/component';
import Icon from '../icon/component';
import Clickable from '../clickable/component';
import LoadingIndicator from '../loading-indicator/component';
import './styles.css';

export default class PageSettings extends Component {
    render() {
        const {
            options, updating, className, iconClassName, iconName, disabled,
        } = this.props;

        return (
            <DropdownDeprecated
                ref={(el) => { this.dropdown = el; }}
                className="page-settings-content"
                containerClassName={classnames('page-settings', {
                    [className]: className
                })}
                trigger={() => (
                    updating
                        ? (<LoadingIndicator size="small" />)
                        : (
                            <Icon
                                name={iconName}
                                className={classnames('page-settings-icon', {
                                    [iconClassName]: !!iconClassName,
                                })}
                            />
                        )
                )}
                disabled={disabled}
            >
                {options.map(option => (
                    <Clickable
                        className={classnames('page-settings-item', {
                            [option.className]: option.className
                        })}
                        action={`page_setting-set_${option.title}`}
                        onClick={() => {
                            this.dropdown.hide();

                            if (option.action) {
                                option.action();
                            }
                        }}
                        key={option.title}
                    >
                        {option.title}
                    </Clickable>
                ))}
            </DropdownDeprecated>
        );
    }
}

PageSettings.defaultProps = {
    options: [],
    updating: false,
    className: undefined,
    iconClassName: undefined,
    iconName: 'ellipsis-h',
    disabled: false,
};

PageSettings.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            className: PropTypes.string,
        }),
    ),
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    updating: PropTypes.bool,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
};
