import React from 'react';
import PropTypes from 'prop-types';
import { ServicePage } from '../../shared/components';

export default function ServicePage405({ children }) {
    return (
        <ServicePage
            icon="eye-slash"
            title="You don’t have a permission to access this page"
        >
            {children}
        </ServicePage>
    );
}

ServicePage405.defaultProps = {
    children: undefined
};

ServicePage405.propTypes = {
    children: PropTypes.node,
};
