import { flow } from 'lodash';
import { takeEvery, put } from 'redux-saga/effects';
import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import moment from 'moment';
import request from '../../../../request';
import formatRequestPayload from '../../utils/format-request-payload';

class CachedModel {
    constructor(options) {
        this.name = options.name;
    }

    initialize(parent) {
        this.parent = parent;
        this.actions = this.createActions();
        this.saga = this.createSaga();
        this.reducer = this.createReducer();
        this.selectors = this.createSelectors();

        return {
            actions: this.actions,
            saga: this.saga,
            reducer: this.reducer,
            selectors: this.selectors,
        };
    }

    createUrl = (params) => {
        return this.parent.getApiUrl(params);
    };

    fetch = (params, options) => {
        const url = this.createUrl(params);
        const formattedParams = this.parent.formatParams(params);
        this.id = params[this.idKey];

        if (formattedParams[this.idKey]) {
            delete formattedParams[this.idKey];
        }

        if (typeof formattedParams.options === 'object') {
            delete formattedParams.options;
        }

        return request.cachedFetch(url, {
            params: formattedParams,
            ...options,
        });
    };

    createActions() {
        return {
            fetch: createAction(
                `${this.name}/MODEL_CACHE_PLUGIN/fetch`,
                formatRequestPayload
            ),
            setCurrentRequestStartTime: createAction(
                `${this.name}/MODEL_CACHE_PLUGIN/setCurrentRequestStartTime`
            ),
        };
    }

    createSaga() {
        const self = this;

        const onSuccess = flow(
            self.parent.parse,
            self.parent.actions.fetch.success,
            put
        );

        const onError = flow(
            self.parent.parseError,
            self.parent.actions.fetch.error,
            put
        );

        const setStartTime = flow(self.actions.setCurrentRequestStartTime, put);

        const setParams = flow(self.parent.actions.setParams, put);

        function* handleFetch(action) {
            yield setStartTime(moment().toString());
            const { data, options } = action.payload;

            yield setParams(data);

            const [cachePromise, requestPromise] = self.fetch(data, options);
            const cache = yield cachePromise;

            if (cache !== null) {
                yield onSuccess(cache);
                yield setStartTime(null);
            }

            try {
                const response = yield requestPromise;
                yield onSuccess(response);
            } catch (err) {
                yield onError(err);
            }
            yield setStartTime(null);
        }

        return function* modelCachePluginSaga() {
            yield takeEvery(self.actions.fetch, handleFetch);
        };
    }

    createReducer() {
        return createReducer(
            {
                [this.actions.setCurrentRequestStartTime]: (state, action) => {
                    return {
                        ...state,
                        currentRequestStartTime: action,
                    };
                },
            },
            { currentRequestStartTime: null }
        );
    }

    createSelectors() {
        const rootSelector = (state) =>
            this.parent.selectors.getPluginsState(state)[this.name];

        return {
            rootSelector,
            selectCurrentRequestStartTime: createSelector(
                rootSelector,
                (state) => state.currentRequestStartTime
            ),
        };
    }
}

export default CachedModel;
