import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    GridListRender,
} from '../../components';
import {
    GoalMemberUpdPane,
    GoalMemberRoles,
} from '../index';
import MembersSelectGroup from './members-select-group';

class GoalMapMembersSelect extends Component {
    onAddMembers = (event) => {
        event.preventDefault();

        const { currentValues, change } = this.props;
        const { goal_members, members_select, role_select } = currentValues;
        const existingMembers = goal_members || [];
        const membersToAdd = members_select.map(item => ({ ...item, role_id: role_select }));

        change('goal_members', [
            ...existingMembers,
            ...membersToAdd
        ]);

        change('members_select', null);
        change('role_select', null);
    }

    onRemoveMember = (index) => {
        const { currentValues, change } = this.props;
        const { goal_members } = currentValues;
        const result = [...goal_members];
        result.splice(index, 1);

        change('goal_members', result);
    }

    onMemberRoleChange = (memberIndex, value) => {
        const { change, currentValues } = this.props;
        change(`goal_members[${memberIndex}]`, {
            ...currentValues.goal_members[memberIndex],
            role_id: value
        });
    }

    getItemProps = (item, index) => {
        const { rolesMap } = this.props;
        const planRoles = rolesMap[item.user_account_role.id].goal_roles;

        return {
            ...item,
            roles: planRoles,
            onRemoveUser: () => this.onRemoveMember(index),
            onChangeRole: role =>
                this.onMemberRoleChange(index, role.id),
            canChangeRole: true,
            canRemove: true,
        };
    }

    render() {
        const {
            currentValues,
            goalId,
            containerClassName,
            change,
            handleSubmit,
            roles
        } = this.props;

        return (
            <span>
                <MembersSelectGroup
                    currentValues={currentValues}
                    goalId={goalId}
                    change={change}
                    handleSubmit={handleSubmit}
                    onAddMembers={this.onAddMembers}
                    roles={roles}
                />
                <Row>
                    <Col base={12}>
                        <h3>Members</h3>
                    </Col>

                    <Col base={12}>
                        <GoalMemberRoles silent>
                            <span>
                                {!currentValues.goal_members || !currentValues.goal_members.length
                                    ? (<p>No members added yet</p>)
                                    : (
                                        <GridListRender
                                            containerClassName={containerClassName}
                                            items={currentValues.goal_members}
                                            ItemComponent={GoalMemberUpdPane}
                                            getItemProps={this.getItemProps}
                                            columns={3}
                                        />
                                    )
                                }
                            </span>
                        </GoalMemberRoles>
                    </Col>
                </Row>
            </span>
        );
    }
}

GoalMapMembersSelect.defaultProps = {
    roles: [],
    rolesMap: {},
    goalId: undefined,
    containerClassName: undefined,
};

GoalMapMembersSelect.propTypes = {
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
        members_select: PropTypes.array,
        role_select: PropTypes.number,
        goal_members: PropTypes.array,
    }).isRequired,
    roles: PropTypes.array,
    rolesMap: PropTypes.object,
    containerClassName: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    goalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state) {
    const rolesBranch = state['goal-role-list'];

    return {
        roles: rolesBranch && rolesBranch.items,
        rolesMap: rolesBranch && _.keyBy(rolesBranch.items, 'id')
    };
}

export default connect(mapStateToProps)(GoalMapMembersSelect);
