import { isEmpty } from 'lodash';
import { createAction } from 'redux-act';
import { Collection } from '../../../shared/entities-v2';

const entityType = { plan: 8 };

const constants = Object.freeze({
    apiUrl: `/entities/${entityType.plan}/recent`,
    branchName: 'recent-plans',
});

export const localUpdateRecentPlansAction = createAction(`${constants.branchName}/localUpdateRecentPlans`);
export const localDeleteRecentPlansAction = createAction(`${constants.branchName}/localDeleteRecentPlans`);

const localUpdateRecentPlansReducer = (state, payload) => {
    if (isEmpty(state.raw)) return { ...state, raw: [payload] };
    const newRecentPlansList = [payload, ...state.raw.filter(plan => plan.id !== payload.id)];
    return { ...state, raw: newRecentPlansList };
};

const localDeleteRecentPlansReducer = (state, payload) => {
    if (isEmpty(state.raw)) return state;
    const newRecentPlansList = [...state.raw.filter(plan => plan.id !== payload.id)];
    return { ...state, raw: newRecentPlansList };
};

export const recentPlansModel = new Collection({
    getApiUrl() { return constants.apiUrl; },
    name: constants.branchName,
    additionalArtifacts: {
        actions: {
            localUpdateRecentPlansAction,
            localDeleteRecentPlansAction
        },
        reducers: {
            [localUpdateRecentPlansAction]: localUpdateRecentPlansReducer,
            [localDeleteRecentPlansAction]: localDeleteRecentPlansReducer
        }
    }
});
