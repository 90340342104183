import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Clickable from '../clickable/component';
import Icon from '../icon/component';
import './styles.css';

export default function IconButton({
    icon,
    className,
    iconClassName,
    disabled,
    iconProps,
    ...rest
}) {
    return (
        <Clickable
            className={classnames('button-icon', {
                [className]: className,
                'button-icon-disabled': disabled,
            })}
            {...rest}
        >
            <Icon
                name={icon}
                className={iconClassName}
                {...iconProps}
            />
        </Clickable>
    );
}

IconButton.defaultProps = {
    className: undefined,
    iconClassName: undefined,
    disabled: false,
    iconProps: {},
};

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    disabled: PropTypes.bool,
    iconProps: PropTypes.object,
};
