import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import Icon from '../icon/component';

import './styles.css';

export default function BadgeDraft({ value }) {
    const completionText = isNil(value) ? null : `: ${value}% completed`;

    return (
        <div className="badge-draft">
            <Icon name="file-archive" className="badge-draft-icon" /> DRAFT{completionText}
        </div>
    );
}

BadgeDraft.propTypes = {
    value: PropTypes.number.isRequired
};
