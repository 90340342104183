import config from '../../../config';

export const valueRenderTypes = {
    none: 'none',
    description: 'description',
    sensitivity: 'sensitivity',
    progressStatus: 'progress_status',
    state: 'state',
    date: 'date',
    status: 'status',
    user: 'user',
    value: 'value',
};

export const sensitivities = {
    1: 'Standard',
    2: 'Restricted',
    3: 'Confidential',
};

export const progressStatuses = {
    1: 'Green',
    2: 'Yellow',
    3: 'Red',
    4: 'Grey'
};

export const states = {
    1: 'active',
    2: 'inactive'
};

export const activityTypes = {
    create: 'created',
    update: 'updated',
};

const descriptionFields = [
    'description',
    'mission',
    'vision',
    'values',
];

export function isDescriptionField(name) {
    return descriptionFields.includes(name);
}

export function isTitleField(name) {
    return name === 'title';
}

export function humanizeFieldName(name) {
    return name
        .split('_')
        .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(' ');
}

export function formatFieldName(name) {
    let result;

    switch (name) {
        case 'target_at':
            result = 'target_date';
            break;
        case 'owner_id':
            result = 'Owner';
            break;
        case 'description':
            result = 'Target State';
            break;
        case 'progress_status':
            result = 'Health';
            break;
        default:
            result = name;
    }

    return humanizeFieldName(result);
}

export function getValueRenderType(name) {
    switch (name) {
        case 'progress_bar':
            return valueRenderTypes.none;

        case 'description':
        case 'mission':
        case 'vision':
        case 'values':
            return valueRenderTypes.description;

        case 'sensitivity':
            return valueRenderTypes.sensitivity;

        case 'progress_status':
            return valueRenderTypes.progressStatus;

        case 'state':
            return valueRenderTypes.state;

        case 'target_at':
        case 'start_date':
        case 'target_date':
        case 'closed_date':
            return valueRenderTypes.date;

        case 'owner_id':
            return valueRenderTypes.user;

        case 'status':
            return valueRenderTypes.status;

        default:
            return valueRenderTypes.value;
    }
}

export const getOwnerValue = (owner) => {
    if (!owner) {
        return 'Unassigned';
    }

    const { first_name, last_name } = owner.user_profile;

    return `${first_name} ${last_name}`;
};

export const getProgressStatusValue = progressStatus => progressStatuses[progressStatus];

export const getStatusValue = status => config.statusNames[status];

export const getStateValue = state => states[state];

export const getSensitivityValue = sensitivity => sensitivities[sensitivity];

export const specialTypes = [
    'created_progress_note',
    'created_related_link',
    'created_collaborator',
];

export function checkIfSpecialType(type) {
    return !!specialTypes.find(item => (item === type));
}

export function getSpecialTypeField(type = '') {
    return type.replace('created_', '').replace(/_/g, ' ');
}
