import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { get } from 'lodash';
import createListOfItems from '../../../../shared/modules/list-view/component';
import { selectCurrentLocation } from '../../../../shared/selectors/routing';
import { entityState } from '../../../../shared/utils/entity';
import { attachmentCollection } from '../../attachment';
import { progressNoteCollection } from '../progress-note-models';
import {
    updateUrlToShowProgressNoteDetailsModal
} from '../details/use-progress-note-details-modal-binder';
import ProgressNotesListItem from './progress-note-list-item';

const { actions, selector } = progressNoteCollection;

const getStateByParentState = (parentState) => {
    switch (parentState) {
        case entityState.inactive:
            return entityState.active;
        default:
            return parentState;
    }
};

const ProgressNoteList = createListOfItems({
    actions,
    selector,
    mapStateToProps: (state, ownProps) => {
        return {
            params: {
                state: getStateByParentState(ownProps.parentEntityState),
                entity_id: ownProps.parentId,
                entity_type: ownProps.parentEntityType,
                includes: 'author.user_profile,attachments',
                order_by: 'created_at,desc'
            }
        };
    }
})(ProgressNotesListItem);

const shouldFetchAttachments = (isFetchAttachments, prevItemsLength, currItemsLength) =>
    (isFetchAttachments && prevItemsLength && prevItemsLength > currItemsLength);

class ProgressNotesListContainer extends Component {
    componentDidUpdate(prevProps) {
        const { isFetchAttachments } = this.props;
        const prevItems = get(prevProps, 'progressNotesSelector.items', []);
        const currItems = get(this.props, 'progressNotesSelector.items', []);

        if (shouldFetchAttachments(isFetchAttachments, prevItems.length, currItems.length)) {
            this.fetchAttachments();
        }
    }

    fetchAttachments = () => {
        const {
            attachmentCollectionFetch,
            progressNotesSelector: {
                params: { entity_id, entity_type },
            }
        } = this.props;

        attachmentCollectionFetch({ entity_id, entity_type });
    }

    handleShowDetailModal = ({ id: progressNoteId }) => {
        const { location, historyReplace } = this.props;
        updateUrlToShowProgressNoteDetailsModal(location, historyReplace, progressNoteId);
    }

    render() {
        const {
            userId,
            permissions,
            ...rest
        } = this.props;

        return (
            <ProgressNoteList
                {...rest}
                paginationDisabled
                itemProps={{
                    showDetailsModal: this.handleShowDetailModal,
                    userId,
                    permissions,
                }}
            />
        );
    }
}

ProgressNotesListContainer.defaultProps = {
    userId: undefined,
    permissions: undefined,
    isFetchAttachments: true,
};

ProgressNotesListContainer.propTypes = {
    userId: PropTypes.number,
    permissions: PropTypes.object,
    location: PropTypes.object.isRequired,
    progressNotesSelector: PropTypes.object.isRequired,
    historyReplace: PropTypes.func.isRequired,
    attachmentCollectionFetch: PropTypes.func.isRequired,
    isFetchAttachments: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        location: selectCurrentLocation(state),
        progressNotesSelector: selector(state),
    };
}

const mapDispatchToProps = {
    historyReplace: replace,
    attachmentCollectionFetch: attachmentCollection.actions.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNotesListContainer);
