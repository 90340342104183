import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { integrationsCollection } from '../application';

class SettingsIntegrationContainer extends React.Component {
    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate() {
        this.getIntegrationList();
    }

    getIntegrationList = () => {
        const { loading, items } = this.props;

        if (!loading && !items.length) {
            this.fetch();
        }
    }

    fetch = () => {
        this.props.getSettingsIntegrationList({
            order_by: 'app_integrations.title,asc',
            page: 1,
        });
    }

    render() {
        const { children } = this.props;

        return (
            <>
                {children}
            </>
        );
    }
}

SettingsIntegrationContainer.defaultProps = {
    children: undefined,
};

SettingsIntegrationContainer.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    getSettingsIntegrationList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: integrationsCollection.selectors.getFetchingState(state),
    items: integrationsCollection.selectors.getItems(state),
});

const mapDispatchToProps = {
    getSettingsIntegrationList: integrationsCollection.actions.fetch.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsIntegrationContainer);
