/** @namespace Utils.BuildQueryString */

/**
 * Build url query string from query object
 * @function buildQueryString
 * @memberof Utils.BuildQueryString
 * @param {Object} query - Query object
 * @return {string} Query string
 */
function buildQueryString(query) {
    let string = '';
    let prefix = '';
    let counter = 0;

    Object.keys(query).map((objKey) => {
        if (query[objKey]) {
            prefix = counter === 0 ? '?' : '&';
            string += `${prefix}${objKey}=${query[objKey]}`;
            counter += 1;
        }

        return null;
    });
    return string;
}

export default buildQueryString;
