import { chunk } from 'lodash';
import dictionary from './dictionary';

export const collectionDefaultParams = {
    type: 2,
};

const columns = 3;
export const formName = 'role-details-form';

export const radioRoles = [
    'Security-Threshold-Sensitivity-Confidential',
    'Security-Threshold-Sensitivity-Restricted',
    'Security-Threshold-Sensitivity-Standard',
];

export const radioRolesParent = 'SP-Goal';

export function prepareRoleGroups(groups) {
    return Object.keys(groups).map(groupName => ({
        key: groupName,
        ...groups[groupName],
    }));
}

function removeRadioPermissions(permissions) {
    const permissionsClone = { ...permissions };

    radioRoles.forEach((item) => {
        delete permissionsClone[item];
    });

    return permissionsClone;
}

export function preparePermissions(permissions) {
    const permissionsWithoutRadio = removeRadioPermissions(permissions);

    const array = Object.keys(permissionsWithoutRadio).map(permissionName => ({
        key: permissionName,
        ...permissions[permissionName],
    }));

    const chunkedArray = chunk(array, columns);

    return chunkedArray;
}

export function parsePermissionValue(value) {
    return value ? 1 : 0;
}

export function createFieldName(group, name) {
    return `role_groups.${group}.permissions.${name}.checked`;
}

let creationFormInitialValues = null; // eslint-disable-line no-unused-vars

function resetPermissions(groups) {
    const result = {};

    Object.entries(groups).forEach(([key, value]) => {
        const permissions = {};

        Object.entries(value.permissions).forEach(([permKey, permValue]) => {
            permissions[permKey] = {
                ...permValue,
                checked: 0,
            };
        });

        result[key] = {
            ...value,
            permissions,
        };
    });

    return result;
}

export function storeCreationFormInitialValues(data) {
    creationFormInitialValues = {
        display_name: '',
        role_groups: resetPermissions(data.role_groups),
        is_editable: true,
    };
}

export function getCreationFormInitialValues() {
    return creationFormInitialValues;
}

export function getRemoveConfirmTextByRole(role = {}) {
    return (role.users > 0)
        ? dictionary.confirmRoleRemove
        : dictionary.confirmRoleRemoveEmpty;
}

export function prepareDuplicateFormInitialValues(data) {
    return {
        display_name: data.display_name,
        role_groups: data.role_groups,
        is_editable: true,
    };
}
