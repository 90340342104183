import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalSuccess } from '../../../shared/components';
import {
    changePasswordModel,
    changePasswordModal,
    changePasswordSuccessModal,
} from './change-password-model';
import ChangePasswordModal from './change-password-modal';

function ProfileChangePasswordService({
    modalVisible,
    requestError,
    toggleModal,
    onSubmit,
    toggleSuccessModal,
    successModalVisible,
}) {
    return (
        <Fragment>
            <ChangePasswordModal
                onSubmit={onSubmit}
                visible={modalVisible}
                hide={toggleModal}
                requestError={requestError}
            />

            <ModalSuccess
                title="Password was updated"
                visible={successModalVisible}
                hide={toggleSuccessModal}
            >
                <p>Your password was updated.</p>
                <p>Please re-login with the new password.</p>
            </ModalSuccess>
        </Fragment>
    );
}

ProfileChangePasswordService.defaultProps = {
    requestError: null,
};

ProfileChangePasswordService.propTypes = {
    modalVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    toggleSuccessModal: PropTypes.func.isRequired,
    successModalVisible: PropTypes.bool.isRequired,
    requestError: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        requestError: changePasswordModel.selectors.getErrorUpdate(state),
        modalVisible: changePasswordModal.selectors.isVisible(state),
        successModalVisible: changePasswordSuccessModal.selectors.isVisible(state),
    };
}

const mapDispatchToProps = {
    toggleModal: changePasswordModal.actions.toggle,
    toggleSuccessModal: changePasswordSuccessModal.actions.toggle,
    onSubmit: changePasswordModel.actions.update.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileChangePasswordService);
