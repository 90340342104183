import { createAction } from 'redux-act';
import { name } from './config';

export const setSelectedItemId = createAction(`${name}/SET_SELECTED_ITEM_ID`);
export const setSelectedItemIdRequest = createAction(`${name}/SET_SELECTED_ITEM_ID_REQUEST`);
export const setCreateState = createAction(`${name}/SET_CREATE_STATE`);
export const resetCreateState = createAction(`${name}/RESET_CREATE_FORMS`);
export const setRemoveItemId = createAction(`${name}/SET_REMOVE_ITEM_ID`);
export const setDuplicateItemId = createAction(`${name}/SET_DUPLICATE_ITEM_ID`);
export const setDirtyFormNavigationId = createAction(`${name}/SET_DIRTY_FORM_NAV_ID`);
export const triggerFormSubmit = createAction(`${name}/TRIGGER_FORM_SUBMIT`);
