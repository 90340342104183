const measureRanges = {
    ascending: {
        label: 'Ascending',
        value: 'asc',
    },
    descending: {
        label: 'Descending',
        value: 'desc',
    },
    between: {
        label: 'Between',
        value: 'between',
    }
};

export default measureRanges;
