import { get } from 'lodash';
import { createSelector as createSelectorReselect } from 'reselect';
import subscriptionLimitCollection from './subscription-limit-collection';
import {
    empty, features, getLimit, stateBranchName
} from './subscription-config';

function getSubscriptions(state) {
    return state[stateBranchName];
}

export function getLimitByEntity(state, name) {
    const items = subscriptionLimitCollection.selectors.getItems(state);

    if (!items || !items.length) {
        return empty;
    }

    return getLimit(items, name);
}

export function getKpiSubscription(state) {
    const subscriptionItems = get(getSubscriptions(state), 'items', []);
    return subscriptionItems.find(itm => itm.key === features.milestones)?.fieldCounters?.value;
}

export function canCreateDraft(state) {
    return !!getLimitByEntity(state, features.draft).value;
}

export const getStateFetching = createSelectorReselect(
    getSubscriptions,
    subscription => subscription.loading
);
