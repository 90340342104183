import {
    takeEvery, select, put
} from 'redux-saga/effects';
import workCenterCustomFilterModelUpdate, { workCenterCustomFilterModelView } from './model';


function* handleUpdateCustomFilterSuccess({ payload }) {
    const attributes = yield select(workCenterCustomFilterModelView.selectors.getAttributes);

    if (attributes.id === payload.response.id) {
        yield put(workCenterCustomFilterModelView.actions.fetch.request({ id: payload.response.id }));
    }

    yield false;
}

export default function* saga() {
    yield takeEvery(workCenterCustomFilterModelUpdate.actions.update.success.getType(), handleUpdateCustomFilterSuccess);
}
