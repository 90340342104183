import { get, flow } from 'lodash';
import { getFormValues } from 'redux-form';
import { withBreakpoints } from 'react-breakpoints';
import createFormModal from '../../../shared/modules/create-view/component';
import parseValidationMessage from '../../../shared/modules/input-attachments/parse-validation';
import { withAttachmentFilePickerState } from '../../secondary-entities/attachment';
import { ProgressNoteForm } from '../../secondary-entities/progress-note';
import { withAppContext } from '../../app-context/with-app-context';
import Permissions from '../../permissions';
import quickAddModel from './quick-add-model';
import FormControls from './quick-add-controls';
import { formName } from './constants';

const currentValuesSelector = getFormValues(formName);

const formModal = createFormModal({
    actions: quickAddModel.actions,
    selector: quickAddModel.selector,
    formName,
    parseFormSubmitError: parseValidationMessage,
    updateHtml: true,
    ControlsComponent: FormControls,
    enableReinitialize: true,
    mapStateToProps(state, ownProps) {
        let initialValues = {
            content: '',
            attachments: [],
        };

        let aclRestrictionText;

        if (ownProps.contextQuickAdd && Object.values(ownProps.contextQuickAdd).length) {
            const {
                entity_id, entity_type, entity_title, goal_id
            } = ownProps.contextQuickAdd;
            const permissionSectionName = Permissions.constants.entitySections.note;
            const permissions = Permissions.selectors.selectEntityPermissions(state, goal_id, permissionSectionName);
            const canCreateNote = get(permissions, Permissions.keys.canCreate);

            if (canCreateNote) {
                initialValues = {
                    ...initialValues,
                    entity_id,
                    entity: { id: entity_id, title: entity_title, entity_type, },
                    state: 1,
                    entity_type,
                    goal_id,
                };
            } else {
                aclRestrictionText = `You do not have permission to add a Progress Note to '${entity_title}'.`;
            }
        }

        return {
            title: 'Progress Note',
            initialValues,
            currentValues: currentValuesSelector(state),
            aclRestrictionText,
            canCreateDraft: ownProps.canCreateDraft,
            controlProps: {
                submitDisabled: ownProps.filePicker.visible,
                canCreateDraft: ownProps.canCreateDraft
            },
            onHide: ownProps.filePicker.hide,
        };
    }
});

export default flow(
    formModal,
    withAttachmentFilePickerState,
    withBreakpoints,
    withAppContext,
)(ProgressNoteForm);
