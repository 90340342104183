import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import { Icon } from '../../components';
import { Select } from '../../modules';
import { get } from './api';
import './styles.css';

export default function CategorizationTagSelector({ goal_id, ...rest }) {
    return (
        <Select
            className="categorization-tag-selector"
            multi
            clearable={false}
            loadOptions={search => get(search, goal_id)}
            {...rest}
            valueRenderer={(option) => {
                return (
                    <div className="categorization-tag-selector-item">
                        <Icon className="categorization-tag-selector-icon" name="tags" />
                        <span>{option.title}</span>
                    </div>
                );
            }}
            component={ReactSelect.AsyncCreatable}
        />
    );
}

CategorizationTagSelector.propTypes = {
    goal_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};
