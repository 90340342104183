import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button } from '../../../../shared/components';

export default function AttachmentFormControls({
    toggle,
    reset,
    submitting,
    submitDisabled,
}) {
    return (
        <Fragment>
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    toggle();
                    reset();
                }}
                outline
                disabled={submitting}
            >
                Cancel
            </Button>
            {' '}
            <Button
                styleType="success"
                type="submit"
                disabled={submitDisabled}
                loading={submitting}
            >
                Publish
            </Button>
        </Fragment>
    );
}

AttachmentFormControls.defaultProps = {};

AttachmentFormControls.propTypes = {
    toggle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
};
