import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GridContainer } from '../../../shared/components';
import { prepareDataSources } from '../utils';
import SettingsForm from '../settings-form';
import { attributes } from '../constants';
import styles from '../styles/milestone-page-settings.module.css';

class MilestoneCreatePage2 extends Component {
    formatData = (data) => {
        const { initialValues } = this.props;
        const dataSources = prepareDataSources(initialValues, data);

        return {
            ...data,
            [attributes.advanced_calculation_sources]: dataSources,
        };
    }

    render() {
        const {
            change,
            currentValues,
            form,
            ...rest
        } = this.props;
        return (
            <div className={styles.milestonePageSettings}>
                <GridContainer>
                    <h1 className={styles.milestoneTitle}>KPI Type</h1>
                </GridContainer>
                <SettingsForm
                    editingDisabled={false}
                    change={change}
                    startOfPeriod
                    currentValues={currentValues}
                    formatData={this.formatData}
                    formName={form}
                    {...rest}
                />
            </div>
        );
    }
}

MilestoneCreatePage2.propTypes = {
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
};

export default MilestoneCreatePage2;
