import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import apiService from '../../../request';

import { setError } from './actions';

const useLinkDownloadSecure = ({ url, method = 'get', params }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState({});

    const fetchSuccess = useCallback((response) => {
        setFetching(false);
        setData(response);
    }, [url, method, params]);

    const fetchError = useCallback((error) => {
        setFetching(false);
        dispatch(setError(error));
        throw new Error(error);
    }, [url, method, params]);

    const fetch = useCallback(() => {
        setFetching(true);
        const options = method === 'get' ? { params } : params;

        return apiService[method](url, options)
            .then(fetchSuccess, fetchError);
    }, [url, method, params]);

    return {
        fetch,
        fetching,
        href: data.temporaryUrl,
        filename: data.user_filename,
        slug: data.attachment?.storage_type
    };
};

export default useLinkDownloadSecure;
