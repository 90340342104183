import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { createFormModal, } from '../../../shared/modules';
import { WizardHeader, LoadingIndicator, } from '../../../shared/components';
import { isStrategy as getIsStrategy, types as entityTypes } from '../../../shared/utils/entity-type';
import ObjectiveForm from '../../goalmap-segment-children/form/objective-form';
import { attributes } from '../constants';
import { copy as createCopySegmentObjectiveModalEntity } from '../entity';
import SegmentForm from '../form/index';

import styles from '../../../shared/components/wizard/wizard-header/styles.module.css';

const SegmentsFormWithWizardHeader = (props) => {
    const { titles, isStrategy, loading } = props;

    const description = `Create a new ${isStrategy ? titles.segment : titles.sub_segment} with the
    same configuration as the current entity has.`;
    return (
        <>
            <WizardHeader
                className={styles.wizardHeaderInModal}
                steps={[{ description }]}
                title={`Copy ${isStrategy ? titles.segment : titles.sub_segment}`}
                currentStep={1}
                entity={{ type: isStrategy ? entityTypes.segment : entityTypes.sub_segment }}
            />
            {loading
                ? <LoadingIndicator centered />
                : (
                    <>
                        {isStrategy ? <SegmentForm {...props} /> : <ObjectiveForm {...props} />}
                    </>
                )
            }
        </>
    );
};

SegmentsFormWithWizardHeader.defaultProps = {
    loading: false,
};

SegmentsFormWithWizardHeader.propTypes = {
    isStrategy: PropTypes.bool.isRequired,
    titles: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = (_state, ownProps) => {
    return ({
        initialValues: {
            ...omit(ownProps.data, [
                attributes.entity_children_counters,
                attributes.strategy_map,
                attributes.children,
                attributes.children_counts,
                attributes.has_plan_actions,
                getIsStrategy(ownProps.data) && attributes.parent_id,
                !getIsStrategy(ownProps.data) && attributes.parent,
            ]),
            [attributes.tag_name]: undefined,
        },
        isStrategy: getIsStrategy(ownProps.data),
    });
};

const formName = 'segment-copy';

const formModal = createFormModal({
    actions: createCopySegmentObjectiveModalEntity.actions,
    selector: createCopySegmentObjectiveModalEntity.selector,
    formName,
    enableReinitialize: true,
    mapStateToProps,
})(SegmentsFormWithWizardHeader);

export default formModal;
