import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '../../avatar/component';
import './styles.css';

export default function SelectUserOption({
    first_name, last_name, tag_name, avatar
}) {
    return (
        <div className="select-user-option">
            <Avatar
                src={avatar}
                className="user-option-avatar"
                alt="User Select Avatar"
                first_name={first_name}
                last_name={last_name}
            />

            <div className="user-option-info">
                <div className="user-option-name">{first_name} {last_name}</div>
                <div className="user-option-tagname">@{tag_name}</div>
            </div>
        </div>
    );
}

SelectUserOption.defaultProps = {
    avatar: undefined,
};

SelectUserOption.propTypes = {
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    tag_name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
};
