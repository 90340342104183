import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';
import { Icon, Clickable, Image } from '../../../shared/components';
import { LinkDownloadSecure } from '../index';
import styles from './styles.module.css';

export default function InputAttachmentItem({
    title, onRemove, error, storageIcon, className, id
}) {
    return (
        <div className={cx(styles.inputAttachmentItem, className)}>
            {!!error && (
                <div className={styles.attachmentErrors}>
                    {error.map(item => (
                        <span key={item}>
                            {item} <br />
                        </span>
                    ))}
                </div>
            )}
            <div className={styles.itemContainer}>
                <Image src={storageIcon} className={styles.attachmentStorageIcon} />

                <span className={styles.attachmentTitle}>{title}</span>

                <div className={styles.attachmentItemControlsGroup}>
                    {!!id && (
                        <LinkDownloadSecure
                            className={cx(styles.attachmentIcon, styles.attachmentDownloadLink)}
                            url={`/attachments/${id}`}
                            iconName="arrow-to-bottom"
                            downloadIconEnabled={false}
                            name={title}
                        >
                            <Icon className={cx(styles.attachmentDownloadIcon)} name="arrow-to-bottom" />
                        </LinkDownloadSecure>
                    )}

                    {!!onRemove && (
                        <Clickable
                            action="Remove Attachment"
                            className={cx(styles.attachmentIcon)}
                            onClick={onRemove}
                        >
                            <Icon className={cx(styles.attachmentRemoveIcon)} name="times-circle" />
                        </Clickable>
                    )}
                </div>
            </div>

        </div>
    );
}

InputAttachmentItem.defaultProps = {
    onRemove: undefined,
    error: [],
    id: undefined
};

InputAttachmentItem.propTypes = {
    title: PropTypes.string.isRequired,
    storageIcon: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    error: PropTypes.arrayOf(
        PropTypes.string,
    ),
    className: PropTypes.string.isRequired,
    id: PropTypes.number
};
