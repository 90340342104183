import Author from './author';
import Content from './content';
import Container from './container';
import Status from './status';

export default {
    Author,
    Content,
    Container,
    Status,
};
