import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaskInput from 'react-input-mask';
import classnames from 'classnames';
import InputContainer from './container';
import { reasons, getValidityMessages } from './validity';
import './base.css';
import { wait } from '../../utils/common';

/*
    Jira issue - ENVSC-2501
    This is needed to prevent form controls jump on input blur, when input
    is not valid. Input will wait UI to settle down and then it will render
    error messages.
*/
const uiSettleDelay = 150;

export default class InputBase extends Component {
    state = {
        errors: [],
        focus: false,
    };

    componentDidMount() {
        if (this.props.autoFocus) {
            this.input.focus();
        }
    }

    get mergedErrors() {
        return [
            ...this.state.errors,
            ...(this.props.meta.error || [])
        ];
    }

    handleInvalid = (event) => {
        event.preventDefault();
        const { validationMessages } = this.props;
        const { validity, validationMessage } = event.target;
        const messages = getValidityMessages(validity, validationMessages, validationMessage);

        wait(uiSettleDelay).then(() => this.setState({ errors: messages }));
    }

    focus = () => {
        if (this.input.focus) {
            this.input.focus();
        }
    }

    checkValidity = () => {
        if (this.input && this.input.checkValidity && this.input.checkValidity()) {
            // with delay to prevent batched updates wich causes caret to "jump"
            // to the end of the line
            // Jira Issue - SC-279
            wait(15).then(() => this.setState({ errors: [] }));
        }
    }

    handleFocus = (event) => {
        const { input } = this.props;
        this.setState({
            focus: true,
        });

        if (input.onFocus) input.onFocus(event);
    }

    handleBlur = (event) => {
        const { input } = this.props;
        this.setState({
            focus: false,
        });

        this.checkValidity();
        if (input.onBlur) input.onBlur(event);
    }

    render() {
        const {
            className,
            input,
            placeholder,
            component,
            meta,
            value,
            label,
            tooltip,
            validationMessages,
            description,
            prefix,
            postfix,
            disabled,
            containerClassName,
            formGroupClassName,
            inputPrefixClassName,
            noMargin,
            horizontalLabel,
            inputPostfixClassName,
            ...rest
        } = this.props;

        const errors = this.mergedErrors;
        const RenderComponent = rest.mask
            ? MaskInput
            : component;

        return (
            <InputContainer
                value={value}
                input={input}
                label={label}
                tooltip={tooltip}
                errors={errors}
                description={description}
                noMargin={noMargin}
                horizontalLabel={horizontalLabel}
                className={formGroupClassName}
            >
                <div
                    className={classnames('input-field-container', {
                        [containerClassName]: containerClassName,
                        focus: this.state.focus,
                        error: !!errors.length,
                        disabled,
                    })}
                >
                    {!!prefix && (
                        <div className={classnames('input-prefix', { [inputPrefixClassName]: inputPrefixClassName, })}>
                            {prefix({
                                iconClassName: 'input-prefix-icon'
                            })}
                        </div>
                    )}

                    <RenderComponent
                        ref={(el) => { this.input = el; }}
                        placeholder={placeholder}
                        className={classnames('input', {
                            [className]: className,
                            'input-with-fix': !!prefix || !!postfix,
                            'input-error': !!errors.length
                        })}
                        value={value}
                        {...input}
                        onBlur={this.handleBlur}
                        onInvalid={this.handleInvalid}
                        disabled={disabled}
                        {...rest}
                    />

                    {!!postfix && (
                        <div className={classnames('input-postfix', { [inputPostfixClassName]: inputPostfixClassName, })}>
                            {postfix({
                                iconClassName: 'input-postfix-icon',
                                value,
                            })}
                        </div>
                    )}
                </div>
            </InputContainer>
        );
    }
}

InputBase.validityReasons = reasons;

InputBase.defaultProps = {
    placeholder: undefined,
    className: undefined,
    containerClassName: undefined,
    formGroupClassName: undefined,
    inputPrefixClassName: undefined,
    inputPostfixClassName: undefined,
    component: 'input',
    autoFocus: false,
    meta: {
        error: []
    },
    input: {
        onChange() {},
        onFocus() {},
        onBlur() {},
    },
    value: undefined,
    label: undefined,
    validationMessages: {},
    description: undefined,
    prefix: undefined,
    postfix: undefined,
    disabled: false,
    noMargin: false,
    tooltip: undefined,
    horizontalLabel: false,
};

InputBase.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    formGroupClassName: PropTypes.string,
    inputPrefixClassName: PropTypes.string,
    inputPostfixClassName: PropTypes.string,
    component: PropTypes.node,
    autoFocus: PropTypes.bool,
    meta: PropTypes.shape({
        error: PropTypes.array,
    }),
    input: PropTypes.shape({
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.any,
    }),
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    label: PropTypes.node,
    tooltip: PropTypes.string,
    validationMessages: PropTypes.object,
    description: PropTypes.string,
    prefix: PropTypes.func,
    postfix: PropTypes.func,
    disabled: PropTypes.bool,
    noMargin: PropTypes.bool,
    horizontalLabel: PropTypes.bool,
};
