/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { unionWith, isEqual } from 'lodash';
import classnames from 'classnames';
import createListOfItems from '../../../../shared/modules/list-view/component';
import AttachmentItem from '../../../../shared/modules/input-attachments/item';
import InputDropzone from '../../../../shared/components/input-dropzone-uploader';
import { PromptProvider, IntegrationsError, Tooltip, } from '../../../../shared/components';
import isIEorEdge from '../../../../shared/utils/detection';
import { list } from '../../../profile-preferences/integrations/entity';
import { selectError } from '../../../profile-preferences/integrations/selectors';
import { redirectUri } from '../../../profile-preferences/integrations/config';
import { selectUserId } from '../../../user/selectors';
import AttachmentFilePicker from '../file-picker/attachment-file-picker-container';
import AttachmentFileSourceItem from './file-source-item/attachment-file-source-item';
import { attachmentFilePickerShape } from '../attachment-constants';
import getStorageIcon from '../attachment-storage-icon';

import './styles.css';

const SourceList = createListOfItems({
    actions: list.actions,
    selector: list.selector,
})(AttachmentFileSourceItem);

function redirect(url) {
    window.location.href = url;
}

const isUserAttachmentOwner = (userId, itemUserId) => userId === itemUserId;
const canRemoveAttachmentItem = (readonly, canDestroyAttachment, canDestroyOwnAttachment, userId, itemUserId) =>
    !readonly && (canDestroyAttachment || (isUserAttachmentOwner(userId, itemUserId) && canDestroyOwnAttachment));

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            picker: null,
        };

        this.attachmentsAnchor = React.createRef();
        this.dropzoneComponent = React.createRef();
        this.dropzonePrompt = React.createRef();
    }

    get safeValue() {
        const { input } = this.props;
        return input.value || [];
    }

    getItemProps = (item, promptShow) => ({
        title: item.storage_type.title,
        icon: item.storage_type.logo_url,
        onClick: () => this.handleItemClick(item, promptShow),
        disabled: (item.is_active && !item.is_available) || !item.storage_type.is_active,
        isUnavailable: (!item.is_available && item.is_active),
    })

    handleItemClick = (item, promptShow) => {
        const { dirtyForm, filePicker } = this.props;
        if (!item.is_active && !dirtyForm) {
            redirect(item.auth_url);
            return;
        }

        if (!item.is_active && dirtyForm) {
            promptShow(item.auth_url, {
                description: 'You will be redirected to another page to link your storage service account. All unsaved data will be lost. Are you sure?',
            });
            return;
        }

        this.setState({
            picker: item.storage_type.slug,
        });

        filePicker.toggle();
    }

    handleFileAddWithScrollDown = (fn) => {
        if (isIEorEdge()) {
            fn();
            return;
        }

        new Promise((res) => {
            fn();
            return res();
        }).then(() => setTimeout(() => this.attachmentsAnchor.current.scrollIntoView({ behavior: 'smooth' }), 200));
    }

    handleAdd = (files) => {
        this.handleFileAddWithScrollDown(
            () => this.props.input.onChange(this.safeValue.concat([...files]))
        );
    }

    handleStorageAdd = (files) => {
        this.handleFileAddWithScrollDown(
            () => this.props.input.onChange(unionWith(this.safeValue, files, isEqual))
        );
    }

    showFileTypeError = () => {
        this.dropzonePrompt.current.show(
            null, {
                description: `
                This file type is not supported.
                Supported file types are:

                jpeg, jpg, png, gif, pdf, doc, docx, xls, xlsx, ppt, pptx, pps, ppsx, pages, numbers, odt, ods, odp.
                `
            }
        );
    }

    handleRemove = (item, index) => {
        const { input, onRemove } = this.props;

        if (item.pivot) {
            return onRemove(item);
        }

        const value = [...input.value];
        value.splice(index, 1);

        return input.onChange(value);
    }

    handleAccept = (files) => {
        this.handleStorageAdd(files);
        this.hidePicker();
    }

    hidePicker = () => {
        const { filePicker } = this.props;

        this.setState({
            picker: null,
        });
        filePicker.toggle();
    }

    render() {
        const {
            extraItems, error, canCreateAttachment, canDestroyOwnAttachment, canDestroyAttachment, userId, ...otherProps
        } = this.props;
        const {
            input, meta, fileSizeLimit, readonly
        } = otherProps;

        const { picker } = this.state;
        const safeValue = input.value || [];
        const allItems = [...safeValue, ...extraItems];
        const info = `Max attachment size is ${fileSizeLimit} megabytes`;

        return (
            <div className={classnames('file-source-container', { 'file-source-container-readonly': readonly })}>
                {!readonly && (
                    <Tooltip
                        text="This action is not available for your role."
                        disabled={canCreateAttachment}
                    >
                        <h3>Select attachment(s)</h3>
                    </Tooltip>
                )}
                <span className={classnames({ 'util-hidden': !!picker, })}>
                    {!readonly && (
                        <Fragment>
                            <div className="file-source-list">
                                {canCreateAttachment && (
                                    <PromptProvider
                                        onAccept={redirect}
                                        isTitle={false}
                                    >
                                        {prompt => (
                                            <SourceList
                                                uniqueIdAttribute="app_integration_id"
                                                paginationDisabled
                                                {...otherProps}
                                                params={{
                                                    redirect_uri: redirectUri,
                                                    order_by: 'app_integrations.position,asc|app_integrations.storage_type.is_active,asc|app_integrations.is_active,asc|app_integrations.title,asc',
                                                }}
                                                getItemProps={
                                                    item => this.getItemProps(item, prompt.show)
                                                }
                                                resetOnUnmount={false}
                                                wrapperClassName="file-source-list-wrapper"
                                                hideOnError
                                            />
                                        )}
                                    </PromptProvider>
                                )}

                                {error && (
                                    <IntegrationsError
                                        description="File Storage integrations are not available for your current subscription."
                                        error={error}
                                    />
                                )}
                            </div>

                            <PromptProvider
                                ref={this.dropzonePrompt}
                                buttonText="Got it!"
                                isConfirmation
                                isTitle={false}
                            >
                                {() => (
                                    <Tooltip
                                        text="This action is not available for your role."
                                        className="attachment-tooltip"
                                        disabled={canCreateAttachment}
                                    >
                                        <InputDropzone
                                            ref={this.dropzoneComponent}
                                            onAddItem={this.handleAdd}
                                            onError={this.showFileTypeError}
                                            tip={info}
                                            input={input}
                                            meta={meta}
                                            disabled={!canCreateAttachment}
                                        />
                                    </Tooltip>
                                )}
                            </PromptProvider>

                        </Fragment>
                    )}

                    {!!allItems.length && (
                        <div className="attachment-list">
                            <h3>Attachments</h3>
                            {allItems.map((item, index) => (
                                <AttachmentItem
                                    className="file-source-container-item"
                                    key={item.id || item.name}
                                    id={item.pivot && item.pivot.id}
                                    title={item.name || item.pivot.user_filename}
                                    onRemove={
                                        canRemoveAttachmentItem(readonly, canDestroyAttachment, canDestroyOwnAttachment, userId, item?.pivot?.author_id)
                                            ? () => this.handleRemove(item, index)
                                            : undefined
                                    }
                                    error={meta.error && meta.error[index]}
                                    storageIcon={getStorageIcon(item.storage || item.storage_type)}
                                />
                            ))}
                            <div ref={this.attachmentsAnchor} className="attachment-anchor" />
                        </div>
                    )}
                </span>
                {!!picker && (
                    <AttachmentFilePicker
                        storageType={picker}
                        value={input.value}
                        onAccept={this.handleAccept}
                        onCancel={this.hidePicker}
                    />
                )}
            </div>
        );
    }
}

FileUpload.defaultProps = {
    extraItems: [],
    onRemove() { },
    dirtyForm: true,
    readonly: false,
    error: null,
    canCreateAttachment: false,
    canDestroyAttachment: false,
    canDestroyOwnAttachment: false,
};

FileUpload.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        value: PropTypes.array,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.object,
    }).isRequired,
    readonly: PropTypes.bool,
    onRemove: PropTypes.func,
    extraItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        }),
    ),
    dirtyForm: PropTypes.bool,
    fileSizeLimit: PropTypes.number.isRequired,
    filePicker: attachmentFilePickerShape.isRequired,
    error: PropTypes.object,
    canCreateAttachment: PropTypes.bool,
    canDestroyAttachment: PropTypes.bool,
    canDestroyOwnAttachment: PropTypes.bool,
    userId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    error: selectError(state),
    userId: selectUserId(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(FileUpload);
