import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './styles.css';

export default function PageTitle({ children, className, iconLeftAligned }) {
    return (
        <h1 className={cx('page-title', className, { 'icon-left-aligned': iconLeftAligned })}>{children}</h1>
    );
}

PageTitle.defaultProps = {
    children: 'Page title',
    className: null,
    iconLeftAligned: false
};

PageTitle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    iconLeftAligned: PropTypes.bool
};
