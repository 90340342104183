import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoadingIndicator } from '../../../shared/components';
import entity from './entity';

class TeamRoleList extends Component {
    componentWillMount() {
        this.props.fetch({ type: 2 });
    }

    render() {
        const { children, loading } = this.props;

        if (loading) {
            return (<LoadingIndicator centered />);
        }

        return children;
    }
}

TeamRoleList.defaultProps = {
    children: null,
};

TeamRoleList.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loading: entity.selector(state).loading,
    };
}

const actions = {
    fetch: entity.actions.request,
};

const connected = connect(
    mapStateToProps,
    actions,
)(TeamRoleList);

export default connected;
