/* eslint-disable jsx-a11y/label-has-for */

import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import config from '../../../config';
import { Text, Icon, InputErrorMessage } from '../../components';
import './styles/dropzone.css';

const dzuClassNames = {
    dropzone: 'dropzone-wrapper',
    dropzoneActive: 'active',
};

function DropzoneCustomInput({
    tip, accept, onFiles, getFilesFromEvent, input, meta, disabled,
}) {
    return (
        <Fragment>
            <div className="dropzone-description">
                <Text className="dropzone-description-big" size="large">
                    Drag a file here
                </Text>
                <Text className="dropzone-description-small" size="medium">
                    or, if you prefer...
                </Text>
            </div>
            <label className="dropzone-add-button">
                Select from computer
                <input
                    name={input.name}
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={(e) => {
                        getFilesFromEvent(e).then((chosenFiles) => {
                            onFiles(chosenFiles);
                        });
                    }}
                    disabled={disabled}
                />
            </label>
            <div className="dropzone-tip">
                <Text styleType="muted" size="small" className="file-source-tip">
                    <Text
                        component={Icon}
                        name="info-circle"
                        styleType="accent"
                    />
                    <i> {tip}</i>
                </Text>
            </div>
            <InputErrorMessage touched={meta.touched} errors={meta.error} />
        </Fragment>
    );
}

class InputDropzoneUploader extends Component {
    constructor(props) {
        super(props);

        this.dropzone = React.createRef();
    }

    // called every time a file's `status` changes
    handleChangeStatus = ({ file }, status) => {
        if (status === 'done') {
            this.props.onAddItem([file]);
        }
    };

    handleValidation = (vals) => {
        let goodToGo = false;
        if (!vals.meta.type || vals.meta.type.length === 0) {
            goodToGo = true;
        } else {
            goodToGo = config.attachments.mimeTypes.indexOf(vals.meta.type) !== -1;
        }

        if (!goodToGo) {
            this.props.onError();
            return true;
        }

        return !goodToGo;
    };

    getFilesFromEvent = (e) => {
        return new Promise((resolve) => {
            getDroppedOrSelectedFiles(e).then((chosenFiles) => {
                resolve(chosenFiles.map(f => f.fileObject));
            });
        });
    };

    renderDropZone = (props) => {
        const {
            tip, input, meta, disabled,
        } = this.props;

        return <DropzoneCustomInput {...props} input={input} meta={meta} tip={tip} disabled={disabled} />;
    }

    render() {
        return (
            <Dropzone
                ref={this.dropzone}
                classNames={dzuClassNames}
                getUploadParams={null}
                PreviewComponent={null}
                onChangeStatus={this.handleChangeStatus}
                InputComponent={this.renderDropZone}
                getFilesFromEvent={this.getFilesFromEvent}
                SubmitButtonComponent={null}
                onSubmit={null}
                validate={this.handleValidation}
                accept=""
                disabled={this.props.disabled}
                {...this.props}
            />
        );
    }
}

DropzoneCustomInput.propTypes = {
    onFiles: PropTypes.func.isRequired,
    getFilesFromEvent: PropTypes.func.isRequired,
    tip: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
};

InputDropzoneUploader.defaultProps = {};

InputDropzoneUploader.propTypes = {
    onAddItem: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    tip: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default InputDropzoneUploader;
