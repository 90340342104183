import React from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import './status.css';

const statusMap = config.statusNames;

export default function StatusFormatter({ status }) {
    return (
        <div className="table-formatter-status">{statusMap[status] || status}</div>
    );
}

StatusFormatter.propTypes = {
    status: PropTypes.string.isRequired
};
