import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import colors, { propTypes as colorPropTypes } from './colors';
import Icon from '../icon/component';

function getValueTrendName(increased) {
    if (increased) {
        return 'Increased';
    }

    return 'Decreased';
}

export default function Trend({
    className, increased, percent, since, color
}) {
    const valueTrendName = getValueTrendName(increased);

    return (
        <div
            className={classnames('micro-widget-value-change-container', {
                [className]: !!className
            })}
        >
            <Icon name="info-circle" className="value-changed-icon" />
            <span>
                {valueTrendName}{' '}
                <b style={{ color }}>
                    {percent}%
                </b> {since}
            </span>
        </div>
    );
}

Trend.defaultProps = {
    className: undefined,
    increased: false,
    percent: 0,
    since: '',
    color: colors.default,
};

Trend.propTypes = {
    className: PropTypes.string,
    increased: PropTypes.bool,
    percent: PropTypes.number,
    since: PropTypes.string,
    color: colorPropTypes,
};
