import { typeCodes, types } from '../../../shared/utils/entity-type';

const levelOffset = 28;
const relativeLineOffset = 5;

export function getItemStyles(level) {
    return {
        item: {
            paddingLeft: `${(level - 1) * levelOffset}px`
        },
        relativeLine: {
            width: `${((level - 1) * levelOffset) - relativeLineOffset}px`
        }
    };
}

export function itemHasChildren(item = {}) {
    return !!(item.children && item.children.length);
}

export function itemIsExpanded(expandedIds, itemId) {
    return expandedIds.includes(itemId);
}

export function getAddingTooltipByEntity(entity, titles, allowedEntityType) {
    switch (entity.entity_type) {
        case typeCodes.plan: return `Add New ${titles.strategy_map}`;
        case typeCodes.segment: return `Add New ${titles.segment}`;
        case typeCodes.strategy: {
            if (allowedEntityType === types.sub_segment) {
                return `Add New ${titles.sub_segment}`;
            }
            if (allowedEntityType === types.plan) {
                return `Add New ${titles.action}, ${titles.milestone}`;
            }

            return `Add New ${titles.sub_segment}, ${titles.action}, ${titles.milestone}`;
        }
        case typeCodes.objective:
        case typeCodes.action:
        case typeCodes.tactic: return `Add New ${titles.action}, ${titles.milestone}`;
        default: return 'Add child entity';
    }
}
