import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './styles.css';

export default function ModuleTitle({ children, className }) {
    return (
        <h2 className={cx('module-title', className)}>{children}</h2>
    );
}

ModuleTitle.defaultProps = {
    children: 'Module title',
    className: null
};

ModuleTitle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
