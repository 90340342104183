import { takeEvery, put } from 'redux-saga/effects';
import goalSettingsTitlesModel from './goalmap-settings-titles-model';
import { updateDetails } from '../goalmap-list/actions';
import { formatSettings } from '../goalmap-list/api';

function* updateGoalDetails(action) {
    const parsedResponse = formatSettings(action.payload);
    yield put(updateDetails.success(parsedResponse));
}

export default function* goalSettingsTitlesSaga() {
    yield takeEvery(goalSettingsTitlesModel.actions.update.success, updateGoalDetails);
}
