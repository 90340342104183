import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class NodeExtractor extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        this.setState({ element: ReactDOM.findDOMNode(this) });
    }

    render() {
        const { children } = this.props;
        const { element } = this.state;

        return children(element);
    }
}

NodeExtractor.propTypes = {
    children: PropTypes.func.isRequired
};
