import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ListViewInfiniteScrollProvider from '../../../../../shared/modules/list-view/infinite-scroll';
import { set as setNavigationContextAction } from '../../../../../modules/navigation-context/actions';
import { types as entityTypes, typeCodes } from '../../../../../shared/utils/entity-type';
import { csfCollection } from '../../csf-models';
import CsfItemMobile from './csf-list-item-mobile';
import styles from './csf-list-mobile.module.css';

class CsfListMobile extends Component {
    componentDidMount() {
        this.props.setNavigationContext({
            id: this.props.goalId,
            entityType: entityTypes.goal,
            entityTypeCode: typeCodes.plan,
        });
    }

    componentWillUnmount() {
        this.props.resetDriversList();
    }

    renderItem = (item) => {
        const { navigate } = this.props;
        return (
            <CsfItemMobile {...item} navigate={navigate} />
        );
    }

    renderListItem = ({ items }) => items.map(this.renderItem);

    render() {
        const {
            goalId, fetch, fetchMore,
        } = this.props;

        return (
            <div className={styles.csfViewListContainer}>
                <ListViewInfiniteScrollProvider
                    params={{
                        goal_id: goalId,
                        limit: 25,
                        state: 1
                    }}
                    selector={csfCollection.selector}
                    fetch={fetch}
                    fetchMore={fetchMore}
                    silentReFetch={false}
                >
                    {this.renderListItem}
                </ListViewInfiniteScrollProvider>
            </div>
        );
    }
}

CsfListMobile.propTypes = {
    fetch: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    resetDriversList: PropTypes.func.isRequired,
    goalId: PropTypes.number.isRequired,
    navigate: PropTypes.func.isRequired,
    setNavigationContext: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    fetch: csfCollection.actions.request,
    fetchMore: csfCollection.actions.requestMore,
    resetDriversList: csfCollection.actions.reset,
    navigate: push,
    setNavigationContext: setNavigationContextAction,
};

export default connect(null, mapDispatchToProps)(CsfListMobile);
