import { createAction } from 'redux-act';
import { createSelector } from 'reselect';
import u from 'updeep';
import uniqid from 'uniqid';
import { find } from 'lodash';
import { Collection } from '../../../shared/entities-v2';

const name = 'notification-toast-collection';
const actions = {
    create: createAction(`${name}/CREATE`),
};
const reducers = {
    [actions.create]: (state, action) => {
        const newItem = { ...action, id: uniqid() };

        return u({
            items: [...state.items, newItem],
        }, state);
    }
};

const notificationToastCollection = new Collection({
    name,
    apiUrl: '/',
    additionalArtifacts: {
        actions,
        reducers,
    }
});

export const selectItemById = createSelector(
    [notificationToastCollection.selectors.getItems, (_, id) => id],
    (items, id) => find(items, { id }) || find(items, { data: { id } })
);

export default notificationToastCollection;
