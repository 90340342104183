export const integrationsTypes = {
    communication: 1,
    fileStorage: 2,
    zapier: 3,
    publicAPI: 4,
};

export const integrationAttributes = {
    id: 'id',
    slug: 'slug',
    group_type: 'group_type',
    is_active: 'is_active',
};
