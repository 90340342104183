import u from 'updeep';
import { createReducer } from 'redux-act';
import * as actions from './actions';

const initialState = {
    selectedItemId: undefined,
    create: false,
    removePromptVisible: false,
    removeItemId: undefined,
    duplicateItemId: undefined,
    dirtyFormNavigationId: undefined,
};

function handleSetSelectedItemId(state, action = {}) {
    return u({
        selectedItemId: action.id,
        duplicateItemId: undefined,
        create: false,
        dirtyFormNavigationId: undefined,
    }, state);
}

function handleSetCreationState(state) {
    return u({
        selectedItemId: undefined,
        duplicateItemId: undefined,
        create: true,
    }, state);
}

function handleSetRemoveItemId(state, action = {}) {
    return u({
        removePromptVisible: !!action.id,
        removeItemId: action.id,
    }, state);
}

function handleSetDuplicateItemId(state, action = {}) {
    return u({
        create: false,
        selectedItemId: undefined,
        duplicateItemId: action.id,
    }, state);
}

function handleSetDirtyFormNavigationId(state, action = {}) {
    return u({
        dirtyFormNavigationId: action.id,
    }, state);
}

function handleResetCreateState(state) {
    return u({
        create: false,
        duplicateItemId: undefined,
    }, state);
}

const reducerMap = {
    [actions.setSelectedItemId]: handleSetSelectedItemId,
    [actions.setCreateState]: handleSetCreationState,
    [actions.setRemoveItemId]: handleSetRemoveItemId,
    [actions.setDuplicateItemId]: handleSetDuplicateItemId,
    [actions.setDirtyFormNavigationId]: handleSetDirtyFormNavigationId,
    [actions.resetCreateState]: handleResetCreateState,
};

export default createReducer(reducerMap, initialState);
