import { createReducer } from 'redux-act';
import u from 'updeep';
import * as actions from './actions';

const initialState = {
    error: null,
};

function handleSetError(state, error) {
    return u({ error: error.response.data }, state);
}

function handleClearError(state) {
    return u({ error: null }, state);
}

const reducer = createReducer({
    [actions.setError]: handleSetError,
    [actions.clearError]: handleClearError,
}, initialState);

export const name = 'link-download-secure';
export default reducer;
