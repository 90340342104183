import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from '../../../../shared/components';
import gearSvg from '../app-updating-popup/images/gear.svg';
import logoSvg from '../app-updating-popup/images/logo.svg';
import { updatingState } from '../../service-worker/service-worker-constants';

import styles from './styles.module.css';

export default function AppUpdatingBubble({ state }) {
    const [currentUpdatingState, setCurrentUpdatingState] = useState();

    // needed for corrected animation starting
    useEffect(() => {
        setCurrentUpdatingState(state);
    }, [state]);

    return (
        <div className={cx(styles.appUpdatingBubble, styles[currentUpdatingState])}>
            <div className={cx(styles.layer, styles.layerUpdating)}>
                <div className={styles.layerInner}>
                    <div className={styles.iconWrapper}>
                        <img className={styles.gear} src={gearSvg} alt="" />
                        <img className={styles.logo} src={logoSvg} alt="" />
                    </div>
                    <div className={styles.updatingText}>
                        The App is updating...
                    </div>
                </div>
            </div>
            <div className={cx(styles.layer, styles.layerUpdated)}>
                <div className={styles.layerInner}>
                    <div className={styles.iconWrapper}>
                        <div className={styles.successIconWrapper}>
                            <Icon className={styles.successIcon} name="check" />
                        </div>
                    </div>
                    <div className={styles.updatingText}>
                        Successfully updated!
                    </div>
                </div>
            </div>
        </div>
    );
}

AppUpdatingBubble.defaultProps = {
    state: undefined
};

AppUpdatingBubble.propTypes = {
    state: PropTypes.oneOf([
        updatingState.default,
        updatingState.updating,
        updatingState.updated
    ])
};
