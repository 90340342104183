function toLowerCase(str = '') {
    return str.toLowerCase();
}

export function sortItemByTitle(a, b) {
    const lowerA = toLowerCase(a.title);
    const lowerB = toLowerCase(b.title);

    if (lowerA < lowerB) { return -1; }
    if (lowerA > lowerB) { return 1; }

    return 0;
}

export default function getSortedFilters(obj) {
    const arr = Object.entries(obj).map(item => item[1]);
    arr.sort(sortItemByTitle);

    return arr;
}
