import PropTypes from 'prop-types';
import React from 'react';
import { SelectOptionEntity } from '../../../shared/components';
import { Select } from '../../../shared/modules';
import getLinks from './api';

export default function RelatedLinkSelect({ params, ...rest }) {
    return (
        <Select
            loadOptions={search => getLinks(params, search)}
            optionRenderer={entity => (
                <SelectOptionEntity
                    type_code={entity.type_code}
                    title={entity.title}
                    tag_name={entity.tag_name}
                    parent_id={entity.parent_id}
                    child_count={entity.child_count}
                />
            )}
            clearable={false}
            {...rest}
        />
    );
}

RelatedLinkSelect.defaultProps = {
    params: {},
};

RelatedLinkSelect.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
        primary_link_type: PropTypes.string,
    }),
};
