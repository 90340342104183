import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './styles.css';

export default function ProgressStatusLabel({ children, className, ...rest }) {
    return (
        <span
            className={classnames('progress-status-label', {
                [className]: className
            })}
            {...rest}
        >
            {children}
        </span>
    );
}

ProgressStatusLabel.defaultProps = {
    children: undefined,
    className: undefined
};

ProgressStatusLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
