import request from '../../request';
import { createDetails } from '../../shared/entities';

const apiUrl = '/goals/notifications';

// eslint-disable-next-line import/prefer-default-export
export const details = createDetails({
    apiUrl,
    name: 'goal-notifications',
    extendAPI: {
        get({ id }) {
            return request.get(`/goals/${id}/notifications`);
        },
        update({ id, params }) {
            return request.put(`/goals/${id}/notifications`, params);
        }
    }
});
