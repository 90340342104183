import Loadable from 'react-loadable';
import { flow } from 'lodash';
import withMobileRestriction from '../shared/modules/with-mobile-restrict';
import withScrollTop from '../shared/components/with-scroll-top';
import PlanWizardRoutes from '../modules/goalmap-wizard/routes';
import { workCenterViewTypes } from '../modules/work-center/constants';
import Loading from './components/loadable';
import PlanRoutes from './plan';
import Settings from './settings';
import Preferences from './preferences';
import { createUrl, paths } from './rules';
import { EmptyPageReports } from './components/empty-page';

const User = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/user/container'),
    loading: Loading,
});

const Profile = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/profile/container'),
    loading: Loading,
});

const Teams = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/teams/teams'),
    loading: Loading,
});

const Dashboard = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/dashboard/dashboard-container'),
    loading: Loading,
});

const ActivityFeedList = Loadable({
    // eslint-disable-next-line max-len
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/dashboard/widgets/activity-stream/widget-activity-stream-page'),
    loading: Loading,
});

const DraftsFeedList = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/dashboard/widgets/drafts/widget-drafts-list-page'),
    loading: Loading,
});

const NotificationList = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/notifications/list/container'),
    loading: Loading,
});

const SearchRoot = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/search/container'),
    loading: Loading,
});

const ArchivedPlans = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/archived-goal-maps/container'),
    loading: Loading,
});

const PlanListOverview = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/plan/modules/plan-list-overview'),
    loading: Loading,
});

const WorkCenter = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/work-center'),
    loading: Loading,
});

const WorkCenterCalendar = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/work-center/calendar/component'),
    loading: Loading,
});

const WorkCenterTimeline = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/work-center/timeline/component'),
    loading: Loading,
});

const WorkCenterGrid = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/work-center/grid/component'),
    loading: Loading,
});

const ZapierIntegrationCreate = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/application/integrations/zapier/integrations-zapier-page'),
    loading: Loading,
});

export default [{
    component: flow(withScrollTop)(User),
    childRoutes: [
        {
            path: createUrl(paths.planList),
            component: flow(
                withMobileRestriction,
                withScrollTop
            )(PlanListOverview),
        },
        {
            path: createUrl(paths.profile),
            component: flow(
                withMobileRestriction,
                withScrollTop
            )(Profile),
        },
        {
            path: createUrl(paths.teams),
            component: flow(
                withMobileRestriction,
                withScrollTop
            )(Teams),
        },
        {
            path: createUrl(paths.dashboard),
            component: withScrollTop(Dashboard),
        },
        {
            path: createUrl(paths.widgetList),
            childRoutes: [
                {
                    path: createUrl(paths.widgetListActivityFeed),
                    component: withScrollTop(ActivityFeedList),
                },
                {
                    path: createUrl(paths.widgetListMyDrafts),
                    component: withScrollTop(DraftsFeedList),
                },
            ]
        },
        {
            path: createUrl(paths.notifications),
            component: flow(
                withMobileRestriction,
                withScrollTop
            )(NotificationList)
        },
        {
            path: createUrl(paths.reports),
            component: flow(
                withMobileRestriction,
                withScrollTop
            )(EmptyPageReports),
        },
        {
            path: createUrl(paths.search),
            component: flow(
                withScrollTop
            )(SearchRoot),
        },
        {
            path: createUrl(paths.archivedPlans),
            component: flow(
                withMobileRestriction,
                withScrollTop
            )(ArchivedPlans),
        },
        {
            path: createUrl(paths.workCenter),
            component: flow(
                // withMobileRestriction,
                withScrollTop
            )(WorkCenter),
            childRoutes: [
                {
                    path: createUrl(paths.workCenterView),
                    getComponent: ({ params }, cb) => {
                        let component;
                        switch (params.viewType) {
                            case workCenterViewTypes.calendar:
                                component = WorkCenterCalendar;
                                break;
                            case workCenterViewTypes.timeline:
                                component = WorkCenterTimeline;
                                break;
                            case workCenterViewTypes.grid:
                            default: component = WorkCenterGrid;
                        }
                        cb(null, component);
                    }

                }
            ]
        },
        {
            path: createUrl(paths.integrationZapier),
            component: flow(
                withMobileRestriction,
                withScrollTop
            )(ZapierIntegrationCreate),
        },
        PlanWizardRoutes,
        PlanRoutes,
        Settings,
        Preferences,
    ]
}];
