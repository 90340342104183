import React, { Fragment } from 'react';
import { Text } from '../../../shared/components';

function SlackIntegrationInstructions() {
    const slackAppLink = (
        <Text
            component="a"
            underline
            href="https://api.slack.com/apps?new_app=1"
            target="_blank"
            rel="noopener noreferrer"
        >
            Create a new Slack app
        </Text>
    );

    const learnMoreLink = (
        <Text
            component="a"
            underline
            href="https://mpowrsoftware.atlassian.net/servicedesk/customer/kb/view/691830902"
            target="_blank"
            rel="noopener noreferrer"
        >
            Click here
        </Text>
    );

    return (
        <Fragment>
            <Text component="h2">
                Instructions
            </Text>

            <Text component="p">
                WARNING Sending activity out of MPOWR Envision and into Slack bypasses the
                security controls in MPOWR Envision. Anyone who has access to your Slack channel
                will be able to see the activity, regardless of whether they have access to that information in MPOWR Envision.
            </Text>

            <Text className="instructions-block-title" bold>
                Create an incoming webhook in Slack
            </Text>

            <ul>
                <li>Login to your Slack Account and then click here to {slackAppLink}</li>
                <li>Name the App (e.g. Envision Updates)</li>
                <li>Select the Workspace you want the App to be connected to</li>
                <li>Select Incoming Webhooks</li>
                <li>If this is your first webhook you will need to Activate Incoming Webhooks</li>
                <li>Click Add New Webhook to Workspace</li>
                <li>Choose which channel you wish to receive MPOWR Envision notifications and click Allow</li>
                <li>Click Add Incoming Webhooks Integration</li>
                <li>Click Copy on the Webhook URL</li>
            </ul>

            <Text className="instructions-block-title" bold>
                Configure the integration in MPOWR Envision
            </Text>

            <ul>
                <li>Select the Plan you want to initiate Slack notifications and click Configure.</li>
                <li>Paste the URL into the Input Webhook URL field.</li>
                <li>Select the Sensitivity level to control the sensitivity of notifications being sent into Slack</li>
                <li>Make sure the Notifications toggle is On and click Save when ready to activate the integration.</li>
            </ul>

            <Text className="instructions-block-link" component="p">
                {learnMoreLink} to learn more.
            </Text>
        </Fragment>
    );
}

export default SlackIntegrationInstructions;
