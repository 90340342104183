import { createSelector, defaultMemoize } from 'reselect';
import {
    get, reject, uniqWith, isEqual, omit,
} from 'lodash';
import { getFormValues } from 'redux-form';
import { keysToCamelCase, addUniqueKeys } from '../../../../shared/utils/object';
import { typeCodes } from '../../../../shared/utils/entity-type';
import { initialState } from '../../../../shared/entities-v2/model/reducer';
import { getPlansItems } from '../../../plans-list-my/selectors';
import {
    filtersFormName, filterOptionValues, workCenterTableViewTypes
} from '../../constants';
import workCenterCustomFilterModelUpdate from '../../custom-filters/model';
import {
    addOwnerFullName, collectItems, getChildrenByPlan, getItemsByOwner, getPlanColorMap, getTimelinePlans, getTreeForTable
} from '../../table-grid-timeline-utils';
import workCenterTimelineModel from './timeline-model';
import { TimelineModelFetchResponse } from './timeline-model-types';

const selectChildren = createSelector(
    [workCenterTimelineModel.selectors.getAttributes],
    data => get(data, TimelineModelFetchResponse.items, [])
        .map(addUniqueKeys)
        .map(addOwnerFullName(data[TimelineModelFetchResponse.owners]))
        .map(keysToCamelCase)
);

const selectParents = createSelector(
    [workCenterTimelineModel.selectors.getAttributes],
    data => get(data, TimelineModelFetchResponse.parents, [])
        .map(keysToCamelCase)
);

const selectOwners = createSelector(
    [workCenterTimelineModel.selectors.getAttributes, selectChildren],
    (data, childrens) => {
        const owners = get(data, TimelineModelFetchResponse.owners, []).map(keysToCamelCase);
        return childrens.some(child => child.ownerId === null)
            ? [...owners, { id: null, firstName: 'Unassigned', lastName: '' }]
            : owners;
    }
);

export const selectHasResults = createSelector(
    selectChildren,
    children => !!children.length
);

const selectHistoricalHealth = createSelector(
    [workCenterTimelineModel.selectors.getAttributes],
    data => get(data, TimelineModelFetchResponse.historicalHealth, [])
        .map(keysToCamelCase)
);

/**
 * @function selectPlans
 * @memberof WorkCenterTimeline.Selectors
 * @param {Object} state - application state
 * @returns {Object[]} Array of Plans
 */
export const selectPlans = createSelector(
    [selectParents, getPlansItems],
    getTimelinePlans
);

const selectPlanParents = createSelector(
    selectParents,
    parents => uniqWith(
        reject(parents, { typeCode: typeCodes.plan }),
        isEqual
    ),
);

const selectTree = defaultMemoize(sortByAttribute =>
    createSelector(
        [getPlansItems, selectPlans, selectPlanParents, selectChildren, () => sortByAttribute],
        getTreeForTable
    ));

const getItemHistoricalHealth = (history, itemId) => get(history.find(h => h.id === itemId), 'history', []);

export const selectItemsArray = defaultMemoize((filterType, sortByAttribute) =>
    createSelector(
        [selectTree(sortByAttribute), selectHistoricalHealth],
        (items, history) => {
            return filterType === filterOptionValues.historicalHealth
                ? collectItems(items, 1, []).map(item =>
                    ({ ...item, history: getItemHistoricalHealth(history, item.id) }))
                : collectItems(items, 1, []);
        }
    ));

export const selectPlanColorMap = createSelector(
    [selectPlans, getFormValues(filtersFormName), workCenterCustomFilterModelUpdate.selectors.getAttributes],
    getPlanColorMap
);

export const selectFilterParamsPersistStateTimeline = createSelector(
    [workCenterTimelineModel.selectors.getRoot],
    data => ({
        ...data, model: { ...data.model, ...omit(initialState, 'fetchParams') },
    })
);

export const createSelectChildrenByPlan = createSelector(
    [selectChildren, selectParents],
    getChildrenByPlan
);

export const selectItemsByOwner = defaultMemoize(sortByAttribute =>
    createSelector(
        [selectPlans, selectPlanParents, selectChildren, selectOwners, () => sortByAttribute],
        getItemsByOwner
    ));

export const selectTimelineItems = defaultMemoize((tableViewType, filterType, sortByAttribute) => {
    switch (tableViewType) {
        case workCenterTableViewTypes.owner:
            return selectItemsByOwner(sortByAttribute);
        case workCenterTableViewTypes.plan:
        default:
            return selectItemsArray(filterType, sortByAttribute);
    }
});
