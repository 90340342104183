import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { Icon } from '../../../../shared/components';
import Permissions from '../../../../modules/permissions';
import { types as entityTypes } from '../../../../shared/utils/entity-type';

import { ProgressNoteList, ProgressNoteCreateModal, progressNoteCollection } from '../../../../modules/secondary-entities/progress-note';

import RightDrawerTab from './right-drawer-tab';
import styles from './styles.module.css';

const isGoal = parentEntityType => parentEntityType === entityTypes.goal;

const EntityProgressNotes = ({
    handleToggleTab, author, created_at, parentId, userId, parentEntityType, goal_id, permissions,
    totalProgressNotes, callbackOnEntityChange, isActiveTab,
}) => {
    const handleSubmitSuccessChangeNote = (entityData) => {
        callbackOnEntityChange(entityData);
    };

    const renderProgressNoteAddButton = useCallback(() =>
        (get(permissions, Permissions.keys.canCreate)
            ? (
                <Fragment>
                    <ProgressNoteCreateModal
                        className={styles.tabAddButton}
                        parentId={parentId}
                        parentEntityType={parentEntityType}
                        onCreateSuccess={handleSubmitSuccessChangeNote}
                        goal_id={goal_id}
                    />
                    <div className={styles.tabButtonDivider} />
                </Fragment>
            )
            : null), [parentId, parentEntityType]);

    return (
        <div className={styles.rightDrawerTabWrapper}>
            <RightDrawerTab
                handleToggleTab={handleToggleTab}
                isActiveTab={isActiveTab}
                title="Progress Notes"
                icon={() => (
                    <Fragment>
                        <Icon
                            className={styles.tabInfoTabIcon}
                            name="clipboard-list-check"
                            type={Icon.types.regular}
                        />
                        <span className={styles.progressNotesIconCounter}>
                            {totalProgressNotes}
                        </span>
                    </Fragment>
                )}
                createButton={renderProgressNoteAddButton}
                goal_id={goal_id}
            />
            <div className={classnames(styles.rightDrawerTabContent, { [styles.visible]: isActiveTab })}>
                <ProgressNoteList
                    created_at={created_at}
                    author={author}
                    permissions={permissions}
                    parentId={parentId}
                    parentEntityType={parentEntityType}
                    userId={userId}
                    handleSuccessSubmit={handleSubmitSuccessChangeNote}
                    isFetchAttachments={false}
                />
            </div>
        </div>
    );
};

EntityProgressNotes.propTypes = {
    author: PropTypes.object.isRequired,
    created_at: PropTypes.string.isRequired,
    parentId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    parentEntityType: PropTypes.string.isRequired,
    permissions: PropTypes.object.isRequired,
    totalProgressNotes: PropTypes.number.isRequired,
    callbackOnEntityChange: PropTypes.func.isRequired,
    handleToggleTab: PropTypes.func.isRequired,
    isActiveTab: PropTypes.bool.isRequired,
    goal_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const itemsProgressNote = get(progressNoteCollection.selector(state), 'items', []);
    const goal_id = isGoal(ownProps.parentEntityType) ? ownProps.parentId : ownProps.goal_id;
    return {
        permissions:
            Permissions.selectors.selectEntityPermissions(state, goal_id, Permissions.constants.entitySections.note),
        totalProgressNotes: itemsProgressNote.length,
    };
};

export default connect(mapStateToProps, null)(EntityProgressNotes);
