import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.css';

export default function WizardFormSlot({ children, withOffset }) {
    return (
        <div className={cx(styles.wizardFormSlot, { [styles.wizardFormSlotWithOffset]: withOffset })}>
            {children}
        </div>
    );
}

WizardFormSlot.defaultProps = {
    withOffset: false
};

WizardFormSlot.propTypes = {
    children: PropTypes.node.isRequired,
    withOffset: PropTypes.bool
};
