import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, initialize } from 'redux-form';
import { flow } from 'lodash';
import { withBreakpoints } from 'react-breakpoints';
import classnames from 'classnames';
import { getTitlesByGoalId } from '../../../modules/goalmap-list/reducer';
import { applicationSettingsSelectors } from '../../../modules/application';
import {
    Button, Modal, Icon, Clickable,
} from '../../components';
import ModalForm from './modal-form';

import './styles.css';

export default function createForm(options) {
    const {
        actions,
        selector,
        formName,
        modalClassName = '',
        ControlsComponent,
        parseFormSubmitError,
        enableReinitialize,
        modalFormContainerClassName = '',
        errorObjectGetter,
    } = options;

    return (EnhancedComponent) => {
        const ReduxBindedFormContent = reduxForm({
            form: formName,
            enableReinitialize,
        })(ModalForm);

        class CreateFormModal extends Component {
            componentDidMount() {
                this.props.methodCallback({
                    reinitializeWith: this.reinitializeWith,
                });
            }

            reinitializeWith = (values = {}) => {
                const { initializeForm, initialValues } = this.props;

                initializeForm(formName, {
                    ...initialValues,
                    ...values,
                });
            }

            render() {
                const {
                    visible,
                    onSubmit,
                    toggle,
                    children,
                    className,
                    controlHidden,
                    creationDisabled,
                    modalContentClassName,
                    disabledCreateButton,
                    ...rest
                } = this.props;

                return (
                    <span className={classnames('create-container', { 'control-hidden': controlHidden })}>
                        {(controlHidden || !!children) || (
                            <Button
                                styleType="accent"
                                size="small"
                                round
                                withShadow
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!creationDisabled) {
                                        toggle();
                                    }
                                }}
                                className={classnames('create-button', {
                                    [className]: !!className,
                                })}
                                disabled={disabledCreateButton}
                            >
                                <Icon name="plus" />
                            </Button>
                        )}
                        {children && (
                            <Clickable
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggle();
                                }}
                            >
                                {children}
                            </Clickable>
                        )}

                        <Modal
                            className={modalClassName}
                            modalContentClassName={modalContentClassName}
                            id="milestone-create"
                            visible={visible}
                            hide={this.hide}
                        >
                            <ReduxBindedFormContent
                                EnhancedComponent={EnhancedComponent}
                                ControlsComponent={ControlsComponent}
                                parseFormSubmitError={parseFormSubmitError}
                                errorObjectGetter={errorObjectGetter}
                                modalFormContainerClassName={modalFormContainerClassName}
                                onSubmit={onSubmit}
                                toggle={toggle}
                                {...rest}
                            />
                        </Modal>
                    </span>
                );
            }
        }

        CreateFormModal.defaultProps = {
            children: undefined,
            className: undefined,
            title: undefined,
            draftAvailable: true,
            initialValues: {},
            onCreateSuccess() { },
            methodCallback() { },
            controlHidden: false,
            controlProps: {},
            onHide() { },
            onHideEnd() { },
            currentStateValue: undefined,
            submitTitle: 'Publish',
            creationDisabled: false,
            visible: false,
            modalContentClassName: undefined,
            disabledCreateButton: false,
        };

        CreateFormModal.propTypes = {
            handleSubmit: PropTypes.func.isRequired,
            onSubmit: PropTypes.func.isRequired,
            toggle: PropTypes.func.isRequired,
            visible: PropTypes.bool,
            initialValues: PropTypes.object,
            children: PropTypes.node,
            className: PropTypes.string,
            draftAvailable: PropTypes.bool,
            onCreateSuccess: PropTypes.func,
            methodCallback: PropTypes.func,
            controlHidden: PropTypes.bool,
            controlProps: PropTypes.object,
            onHide: PropTypes.func,
            onHideEnd: PropTypes.func,
            title: PropTypes.string,
            currentStateValue: PropTypes.number,
            isMobile: PropTypes.bool.isRequired,
            submitTitle: PropTypes.string,
            creationDisabled: PropTypes.bool,
            initializeForm: PropTypes.func.isRequired,
            modalContentClassName: PropTypes.string,
            disabledCreateButton: PropTypes.bool,
        };

        function mapStateToProps(state, ownProps) {
            const branch = selector(state);
            return {
                visible: branch.modalVisible ?? branch.visible,
                titles: getTitlesByGoalId(state, ownProps.goalId),
                currentStateValue: formValueSelector(formName)(state, 'state'),
                isMobile: applicationSettingsSelectors.isMobileSelector(state),
                ...(
                    options.mapStateToProps
                        ? options.mapStateToProps(state, ownProps)
                        : undefined
                ),
            };
        }

        const mapDispatchToProps = {
            onSubmit: actions.request,
            toggle: actions.toggle,
            initializeForm: initialize,
        };

        return flow(
            withBreakpoints,
            connect(mapStateToProps, mapDispatchToProps),
        )(CreateFormModal);
    };
}
