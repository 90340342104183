export const GaugeType = {
    ASC: 'asc',
    DESC: 'desc',
    BETWEEN: 'between'
};

export const HealthColors = {
    SUCCESS: 'color-success',
    WARNING: 'color-warning',
    DANGER: 'color-danger',
    ACCENT: 'color-accent'
};
