import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import '../../request';
import { Router, browserHistory } from 'react-router';
import smoothscroll from 'smoothscroll-polyfill';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';
import ReactBreakpoints from 'react-breakpoints';
import routes from '../../routes/index';
import store from '../../store/configure-store';
import '../../shared/styles/variables.css';
import 'react-dates/initialize';
import '../../shared/utils/mouse-event-polyfill';
import 'normalize.css';
import { AppContextProvider } from '../app-context/component';
import { ServicePageCookiesError } from '../service-pages';
import Breakpoints from './components/breakpoints';
import withDndContext from '../../shared/utils/with-dnd-context';
import config from '../../config';
import ApplicationConfigLoader from './config-loader/application-config-loader';

import './styles/main.css';

smoothscroll.polyfill();

const history = syncHistoryWithStore(browserHistory, store);

class ApplicationContainer extends Component { // eslint-disable-line react/prefer-stateless-function
    render() {
        const { cookiesError } = this.props;

        if (cookiesError) {
            return <ServicePageCookiesError />;
        }

        return (
            <ReactBreakpoints breakpoints={config.breakpoints}>
                <Provider store={store}>
                    <AppContextProvider>
                        <Fragment>
                            <Breakpoints />

                            <ApplicationConfigLoader>
                                <Router history={history} routes={routes} />
                            </ApplicationConfigLoader>
                        </Fragment>
                    </AppContextProvider>
                </Provider>
            </ReactBreakpoints>
        );
    }
}

ApplicationContainer.defaultProps = {
    cookiesError: false,
};

ApplicationContainer.propTypes = {
    cookiesError: PropTypes.bool,
};

export default withDndContext(ApplicationContainer);
