import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import u from 'updeep';
import classnames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import InfiniteScroll from 'react-infinite-scroller';
import { isEqual } from 'lodash';
import config from '../../../config';
import { LoadingIndicator, Text, GridContainer } from '../../components';
import { ServicePage404, ServicePage405, ServicePageConnectionError } from '../../../modules/service-pages';

class ListViewInfiniteScrollProvider extends Component {
    componentDidMount() {
        const { pager, autoFetch } = this.props;

        if (autoFetch) {
            this.fetch(pager.current);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            pager,
            fetchOnParamsChange,
            params,
            autoFetch,
        } = this.props;

        if (!autoFetch) {
            return null;
        }

        if (fetchOnParamsChange && !isEqual(prevProps.params, params)) {
            return this.fetch(pager.current);
        }

        return null;
    }

    componentWillUnmount() {
        const { reset, resetOnUnmount } = this.props;

        if (resetOnUnmount) {
            reset();
        }
    }

    composeParams = (page) => {
        const { params } = this.props;

        return u({ page }, params);
    }

    fetch = () => {
        const {
            fetch, items, silentReFetch, isNewCollection,
        } = this.props;
        const params = this.composeParams(1);

        return isNewCollection
            ? fetch({ ...params, }, { silent: silentReFetch && !!items.length, })
            : fetch({ silent: silentReFetch && !!items.length, ...params, });
    }

    fetchListMore = (page) => {
        const { fetchMore } = this.props;
        const params = this.composeParams(page);

        return fetchMore(params);
    }

    render() {
        const {
            children, error, items, fetching, fetchingMore, emptyListClassName, EmptyListComponent,
            pager, itemsProps,
        } = this.props;

        if (!fetching && error) {
            if (config.isNetworkError(error)) {
                return <ServicePageConnectionError />;
            }

            if (error.code === 405) {
                return <ServicePage405 />;
            }

            if (error.code === 400) {
                return (
                    <GridContainer>
                        <Text component="p">Error occured during request, please refresh page.</Text>
                    </GridContainer>
                );
            }

            return <ServicePage404 />;
        }

        return (
            <InfiniteScroll
                pageStart={1}
                loadMore={this.fetchListMore}
                initialLoad={false}
                hasMore={!fetching && !fetchingMore && pager.current < pager.totalPages}
            >
                {!fetching && !items.length && !EmptyListComponent && (
                    <GridContainer className={emptyListClassName}>
                        <Text component="p">No Results</Text>
                    </GridContainer>
                )}

                {EmptyListComponent && !fetching && !items.length && (
                    <GridContainer className={emptyListClassName}>
                        <EmptyListComponent />
                    </GridContainer>
                )}

                <ReactCSSTransitionGroup
                    className={classnames('list-wrapper', {
                        // [wrapperClassName]: !!wrapperClassName,
                    })}
                    transitionName="list-transition"
                    transitionAppear
                    transitionAppearTimeout={500}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={100}
                >
                    {children({
                        items, ...itemsProps,
                    })}
                </ReactCSSTransitionGroup>

                {(fetching || fetchingMore) && <LoadingIndicator centered size="small" />}
            </InfiniteScroll>
        );
    }
}

ListViewInfiniteScrollProvider.defaultProps = {
    pager: {
        current: 1,
        totalPages: 1,
    },
    params: {},
    items: [],
    error: null,
    fetching: false,
    fetchingMore: false,
    reset: undefined,
    resetOnUnmount: false,
    autoFetch: true,
    fetchOnParamsChange: true,
    silentReFetch: true,
    EmptyListComponent: null,
    emptyListClassName: undefined,
    itemsProps: {},
    // toDo temporary, delete after impl new Collection
    isNewCollection: false,
};

ListViewInfiniteScrollProvider.propTypes = {
    children: PropTypes.func.isRequired,

    params: PropTypes.object,
    pager: PropTypes.shape({
        current: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        totalPages: PropTypes.number,
    }),
    items: PropTypes.array,
    error: PropTypes.object,
    fetching: PropTypes.bool,
    fetchingMore: PropTypes.bool,

    selector: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    fetch: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    reset: PropTypes.func,

    resetOnUnmount: PropTypes.bool,
    autoFetch: PropTypes.bool,
    fetchOnParamsChange: PropTypes.bool,
    silentReFetch: PropTypes.bool,

    EmptyListComponent: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.node
    ]),
    emptyListClassName: PropTypes.string,
    itemsProps: PropTypes.object,
    // toDo temporary, delete after impl new Collection
    isNewCollection: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    const branch = ownProps.selector(state);

    return {
        items: branch.items,
        error: branch.error,
        fetching: branch.loading,
        fetchingMore: branch.loadingMore,
        pager: branch.pager,
    };
}

export default connect(mapStateToProps)(ListViewInfiniteScrollProvider);
