import u from 'updeep';
import {
    find, get, omit, pick
} from 'lodash';
import { createAction } from 'redux-act';
import { createSelector } from 'reselect';
import { Model } from '../../../shared/entities-v2';
import { ModelNotificationsPlugin, ModelCollectionSyncPlugin } from '../../../shared/entities-v2/plugins';
import { typeCodes } from '../../../shared/utils/entity-type';
import { normalizeFilterValues, formatUnassignedPlan } from '../mappers';
import { unassignedPlan } from '../constants';
import customFilterCollection from './collection';

class WorkCenterCustomFilterModel extends Model {
    format = (data) => {
        const result = { ...data };

        const normalizeData = result?.filters ? normalizeFilterValues(result, 'filters') : result;
        const formattedData = formatUnassignedPlan(normalizeData, 'filters');

        return omit(formattedData, ['related_data', 'errors']);
    }

    parse = (response) => {
        const planValue = get(response, 'filters.plan.value', []);
        const unassignedEnabled = get(response, 'filters.include_unassigned', false);
        const planLocationsRelatedData = get(response, 'related_data.plan.entity_titles', []);
        const nextPlanValue = planValue.map((item) => {
            const hasLocation = item.entity_type !== typeCodes.plan;

            if (!hasLocation) return item;

            const title = find(planLocationsRelatedData, {
                id: item.entity_id,
                type: item.entity_type,
            })?.title;

            item.title = title;
            return item;
        });

        if (unassignedEnabled) {
            nextPlanValue.push({
                entity_id: unassignedPlan.id,
                entity_type: typeCodes.plan,
                plan_color_index: null,
                plan_id: unassignedPlan.id,
            });
        }

        return u.updateIn('filters.plan.value', nextPlanValue, response);
    }
}

const modelName = 'work-center-custom-filter-model';

const setFilterData = createAction(`${modelName}/SET_FILTER_DATA`);
const setFilterParams = createAction(`${modelName}/SET_FILTER_PARAMS`);
const setFilterParamsId = createAction(`${modelName}/SET_FILTER_PARAMS_ID`);

const workCenterCustomFilterModelUpdate = new WorkCenterCustomFilterModel({
    apiUrl: '/work_center/custom_filters',
    name: modelName,
    plugins: [
        new ModelNotificationsPlugin({
            name: 'work-center-custom-filter-model-notifications',
            getActions: actions => ([
                actions.create.success,
                actions.create.error,
                actions.update.success,
                actions.update.error,
                actions.remove.success,
            ]),
            dictionary: {
                ...ModelNotificationsPlugin.dictionary,
                success: {
                    ...ModelNotificationsPlugin.dictionary.success,
                    create: 'The custom filter has been successfully created',
                    update: 'The custom filter has been updated',
                    remove: 'The custom filter has been deleted'
                }
            },
        }),
        new ModelCollectionSyncPlugin({
            name: 'work-center-custom-filter-model-collection-sync',
            collection: customFilterCollection,
        }),
    ],
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onCreateSuccess]: setFilterParamsId,
        },
        [Model.additionalArtifacts.actions]: {
            setFilterData,
            setFilterParams,
            setFilterParamsId,
        },
        [Model.additionalArtifacts.reducers]: {
            [setFilterData]: (state, action) => {
                return u.updateIn('extensionState.filterData', u.constant(action), state);
            },
            [setFilterParams]: (state, action) => {
                return u.updateIn('extensionState', {
                    filterParams: action,
                    filterData: null,
                }, state);
            },
            [setFilterParamsId]: (state, { response }) => {
                return u.updateIn('extensionState.filterData', pick(response, ['id']), state);
            }
        },
        [Model.additionalArtifacts.initialState]: {
            filterData: null,
            filterParams: null,
        },
    }
});

export const workCenterCustomFilterModelView = new Model({
    apiUrl: '/work_center/custom_filters',
    name: 'work-center-current-custom-filter-model'
});

export const selectFilterParams = createSelector(
    [workCenterCustomFilterModelUpdate.selectors.getExtensionState],
    state => state.filterParams,
);

export const selectFilterData = createSelector(
    [workCenterCustomFilterModelUpdate.selectors.getExtensionState],
    state => state.filterData,
);

export default workCenterCustomFilterModelUpdate;
