import { find } from 'lodash';
import apiService from '../../../request';
import formatUserData from '../../../shared/utils/formatters';
import * as Modules from '../../settings-users/modules';
import { roleTypes, planRoleNames } from '../../user/constants';

const format = (data) => {
    const myParams = formatUserData(data);
    myParams.account_role = myParams.user_account_role.id ? myParams.user_account_role.id : myParams.user_account_role;

    delete myParams.goal_roles;
    delete myParams.user_account_role;

    return Modules.buildFormData(myParams);
};

const assignUser = (userId, planId) => {
    return Promise.all([
        apiService.get('roles', { params: { type: roleTypes.local } }),
        apiService.get(`/users/${userId}`),
        apiService.get(`/user/${userId}/assignments`)
    ]).then(([roles, userData, assignmentsData]) => {
        const leaderRole = find(roles.data, { name: planRoleNames.leader });
        const nextAssignments = assignmentsData
            .map(item => ({
                goal_id: item.plan_id,
                role_id: item.role_id,
                status: item.status
            }));

        nextAssignments.push({
            goal_id: planId,
            role_id: leaderRole.id,
            status: 1,
        });

        const dataToSend = format({
            ...userData,
            send_invitation: false,
            goals: nextAssignments
        });

        return apiService.post(`/users/update/${userId}`, dataToSend);
    });
};

export default assignUser;
