import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Composer from 'react-composer';
import { TableView, PromptProvider, LoadingIndicator, } from '../../../../../shared/components';
import createListOfItems from '../../../../../shared/modules/list-view/component';
import { mapActionsToLegacyFormat, legacyFormatSelectorFactory } from '../../../../../shared/entities-v2/collection/legacy';
import { csfConnectionModel } from '../csf-connection-models';
import {
    types, defaultRequestIncludes, attributeNames as attrNames, entityTypes
} from '../../related-link-constants';
import CsfConnectionTable from './csf-connection-table';
import csfConnectionCollection from '../csf-connection-collection';
import { selectUserId } from '../../../../user/selectors';

const Table = createListOfItems({
    actions: mapActionsToLegacyFormat(csfConnectionCollection.actions),
    selector: legacyFormatSelectorFactory(csfConnectionCollection.selectors),
})();

const excludedEntities = [
    entityTypes.milestone,
    entityTypes.plan_action,
    entityTypes.segment,
].join(',');

const promptDictionary = {
    description: 'You are about to remove this link.',
};

class CsfConnectionList extends Component {
    state = {
        sort: 'title,asc',
    };

    handleToggleSort = (sort) => {
        this.setState({
            sort: TableView.WithSort.defaultFormatSort(sort),
        });
    }

    handleRemove = (id) => {
        const { remove } = this.props;

        remove({ id });
    }

    renderTable = (promptProps, sortProps) => {
        const {
            parentId, parentType, userId, permissions
        } = this.props;
        const { sort } = this.state;

        const params = {
            [attrNames.primaryLinkId]: parentId,
            [attrNames.primaryLinkType]: parentType,
            [attrNames.sort]: sort,
            [attrNames.includes]: defaultRequestIncludes,
            [attrNames.type]: types.internal,
            [attrNames.exclude]: excludedEntities,
        };

        const tableProps = {
            toggleSort: sortProps.toggleSort,
            sorts: sortProps.sorts,
            permissions,
            userId,
            onRemove: id => promptProps.show(id, promptDictionary),
        };

        return (
            <Table
                paginationDisabled
                RendererComponent={TableView.ListRenderer}
                customRenderFunc={CsfConnectionTable}
                params={params}
                customRenderProps={tableProps}
            />
        );
    }

    render() {
        const { sort } = this.state;
        const { updating } = this.props;
        const components = [
            <PromptProvider onAccept={this.handleRemove} />,
            <TableView.WithSort onSortChange={this.handleToggleSort} sorts={sort} />,
        ];

        return (
            <>
                {updating
                    ? (
                        <div className="loading-overlay fixed">
                            <LoadingIndicator centered />
                        </div>
                    )
                    : (
                        <Composer components={components}>
                            {([prompt, sorts]) => this.renderTable(prompt, sorts)}
                        </Composer>
                    )
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    const { updating } = csfConnectionModel.selector(state);
    return {
        userId: selectUserId(state),
        updating,
    };
}

const mapDispatchToProps = {
    remove: csfConnectionModel.actions.remove.request,
};

CsfConnectionList.propTypes = {
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    permissions: PropTypes.object.isRequired,
    updating: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CsfConnectionList);
