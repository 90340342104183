import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import {
    TitleTagnameFields, OwnerSelect, CategorizationTagSelector,
} from '../../../shared/modules';
import { Editor, Row, Col, } from '../../../shared/components';
import { types } from '../../../shared/utils/entity-type';

import styles from './styles.module.css';

export default function ObjectiveForm({
    goalId, change, titles,
}) {
    return (
        <div className={styles.objectiveForm}>
            <TitleTagnameFields
                change={change}
                goalId={goalId}
                titleFieldLayoutRenderer={children => (
                    <Row>
                        <Col xs={12} md={8}>
                            {children}
                        </Col>
                        <Col xs={12} md={4}>
                            <OwnerSelect
                                id="subsegment-select-owner"
                                label="Owner"
                                placeholder="Select Owner"
                                goalId={goalId}
                                required
                            />
                        </Col>
                    </Row>
                )}
                titleFieldProps={{
                    autoFocus: true,
                    label: 'Name',
                    placeholder: `Name your ${titles[types.sub_segment]}`
                }}
                tagnameFieldProps={{
                    label: 'Tag Name',
                    placeholder: `Type in Tag Name for your ${titles[types.sub_segment]}`
                }}
            />

            <Field
                name="description"
                label="Target State"
                placeholder={`Type in ${titles[types.sub_segment]} Target State`}
                component={Editor}
                goal_id={goalId}
                controlsHidden
            />

            <Field
                name="tags"
                label="Tags"
                placeholder="Select tags"
                id="segment-tags-select"
                component={CategorizationTagSelector}
                goal_id={goalId}
                multi
            />
        </div>
    );
}

ObjectiveForm.propTypes = {
    goalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    change: PropTypes.func.isRequired,
    // currentBreakpoint: PropTypes.string.isRequired,
    titles: PropTypes.object.isRequired
};
