import { createAction } from 'redux-act';

export default function createActions(prefix) {
    const listPrefix = `${prefix}/get-list`;

    return {
        request: createAction(`${listPrefix}/REQUEST`),
        success: createAction(`${listPrefix}/SUCCESS`),
        error: createAction(`${listPrefix}/ERROR`),

        requestMore: createAction(`${listPrefix}/REQUEST_MORE`),
        requestMoreSuccess: createAction(`${listPrefix}/SUCCESS_MORE`),
        requestMoreError: createAction(`${listPrefix}/ERROR_MORE`),

        reset: createAction(`${listPrefix}/RESET`),
        updateRaw: createAction(`${prefix}/UPDATE_RAW`),
        add: createAction(`${prefix}/item/ADD`),
        addMultiple: createAction(`${prefix}/item/ADD_MULTIPLE`),
        addBulk: createAction(`${prefix}/item/ADD_BULK`),
        push: createAction(`${prefix}/item/PUSH`),
        replace: createAction(`${prefix}/item/REPLACE`),
        remove: createAction(`${prefix}/item/REMOVE`),
        update: createAction(`${prefix}/item/UPDATE`),
        setUpdatingItemId: createAction(`${prefix}/item/SET_UPDATING_ID`),
        setParams: createAction(`${prefix}/item/SET_PARAMS`),
        incrementCounter: createAction(`${listPrefix}/INCREMENT_COUNTER`),
        incrementNestedChildCounter: createAction(`${listPrefix}/INCREMENT_NESTED_CHILD_COUNTER`),
    };
}
