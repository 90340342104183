import React from 'react';
import ItemRenderer from './history-list-item-renderer';

export default function HistoryListItem(props) {
    return (
        <ItemRenderer
            {...props}
            className="history-item"
        />
    );
}
