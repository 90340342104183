import { Collection } from '../../../shared/entities-v2';

const apiUrl = '/integrations/type';
const nameCollection = 'settings-integrations-storage-collection';

export const settingsIntegrationsStorageCollection = new Collection({
    getApiUrl(params) {
        return `${apiUrl}/${params.slug}`;
    },
    name: nameCollection,
});
