import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Clickable, DropdownDeprecated, Icon } from '../../../shared/components';
import { sortItemByTitle as sortPlansByTitle } from '../../../modules/search/sort-facet';
import '../styles/filter-selector.css';

class FilterSelector extends Component {
    handleItemClick = (id) => {
        const { onChange } = this.props;

        onChange(id);
        this.dropdown.hide();
    }

    render() {
        const { currentPlanId } = this.props;

        const plansList = [...this.props.plansList].sort(sortPlansByTitle);
        plansList.unshift({ title: 'Everything', id: 0, value: 0 });

        const currentPlan = plansList.find(plan => plan.id === currentPlanId) || {};

        return (
            <DropdownDeprecated
                ref={(el) => { this.dropdown = el; }}
                className="header-search-filter-selector-options"
                containerClassName="header-search-filter-container"
                trigger={({ open }) => (
                    <>
                        <span className="search-filter-selector">
                            {currentPlan.title}
                        </span>
                        <Icon type="regular" name={open ? 'angle-up' : 'angle-down'} />
                    </>
                )}
            >
                {plansList.map(({ title, id }) => {
                    return (
                        <Clickable
                            action="set-filter"
                            className={classnames('filter-selector-option', {
                                active: id === currentPlan.id
                            })}
                            onClick={() => this.handleItemClick(id)}
                            key={id}
                        >
                            {title}
                        </Clickable>
                    );
                })}
            </DropdownDeprecated>
        );
    }
}

FilterSelector.defaultProps = {
    plansList: [],
    currentPlanId: undefined
};

FilterSelector.propTypes = {
    plansList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        }),
    ),
    currentPlanId: PropTypes.number,
    onChange: PropTypes.func.isRequired
};


const mapStateToProps = state => ({
    plansList: state.goalmapsList.items,
});

export default connect(mapStateToProps)(FilterSelector);
