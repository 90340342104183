import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.css';

export default function ContentSeparator({ className }) {
    return (
        <div className={cx('content-separator', className)} />
    );
}

ContentSeparator.defaultProps = {
    className: null,
};

ContentSeparator.propTypes = {
    className: PropTypes.string,
};
