import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { flow, take } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Image, Text } from '../../../../shared/components';
import { getIntegrationBySlug, getIntegrationIcon } from '../../../profile-preferences/integrations/entity';
import { attachmentStorageCollection } from '../attachment-models';
import AttachmentFilePicker from './attachment-file-picker';
import AttachmentFilePickerBreadcrumbs from './file-picker-breadcrumbs/attachment-file-picker-breadcrumbs';


const rootId = 'root';
const itemsPerPage = 49;
const root = {
    id: rootId,
    title: 'Root',
};

class AttachmentFilePickerContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            path: [root],
            selected: this.props.value.filter(item => item.storage === this.props.storageType),
        };
    }

    componentDidMount() {
        this.fetch();
    }

    get currentDirId() {
        const { path } = this.state;
        return path[path.length - 1].id;
    }

    fetch = () => {
        const { fetch, storageType } = this.props;

        fetch({
            storageType,
            folder_id: this.currentDirId,
            count: itemsPerPage,
        });
    }

    fetchMore = () => {
        const { fetchMore, cursor, storageType } = this.props;

        fetchMore({
            storageType,
            cursor,
            folder_id: this.currentDirId,
            count: itemsPerPage,
        });
    }

    handleDirSelect = (dir) => {
        this.setState({
            path: [...this.state.path, {
                id: dir.id,
                title: dir.name,
            }],
        }, this.fetch);
    }

    handleSelectionChange = (selected) => {
        this.setState({
            selected,
        });
    }

    handleBreadcrumbNav = (index) => {
        this.setState({
            path: take(this.state.path, index + 1),
        }, this.fetch);
    }

    handleAccept = () => {
        const { onAccept, storageType } = this.props;
        const { selected } = this.state;

        const value = selected.map(item => ({
            ...item,
            storage: storageType,
        }));

        onAccept(value);
    }

    render() {
        const {
            items,
            fetching,
            onCancel,
            integrationIcon,
            storageType,
            fetchingMore,
            cursor,
        } = this.props;
        const { selected, path } = this.state;

        return (
            <div className="file-picker-container">
                <div className="file-picker-section file-picker-header">
                    <AttachmentFilePickerBreadcrumbs
                        items={path}
                        onClick={this.handleBreadcrumbNav}
                        renderItemContent={(item) => {
                            if (item.id === rootId) {
                                return (
                                    <Image
                                        className="file-picker-breadcrumbs-root"
                                        src={integrationIcon}
                                        alt={storageType}
                                    />
                                );
                            }

                            return item.title;
                        }}
                    />

                    {!!selected.length && (
                        <Text size="small">
                            Selected files: <Text styleType="accent">{selected.length}</Text>
                        </Text>
                    )}
                </div>
                <div className="file-picker-content file-picker-section">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.fetchMore}
                        initialLoad={false}
                        hasMore={cursor !== ''}
                        useWindow={false}
                    >
                        <AttachmentFilePicker
                            items={items}
                            fetching={fetching}
                            fetchingMore={fetchingMore}
                            onSelectionChange={this.handleSelectionChange}
                            onDirSelect={this.handleDirSelect}
                            selected={this.state.selected}
                        />
                    </InfiniteScroll>
                </div>

                <div className="file-picker-section file-picker-actions">
                    <Button
                        styleType="success"
                        type="button"
                        onClick={this.handleAccept}
                        disabled={!selected.length}
                    >
                        Select
                    </Button>
                    {' '}
                    <Button onClick={onCancel} outline>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

AttachmentFilePickerContainer.defaultProps = {
    value: [],
    cursor: '',
};

AttachmentFilePickerContainer.propTypes = {
    fetch: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    storageType: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetchingMore: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    integrationIcon: PropTypes.string.isRequired,
    cursor: PropTypes.string,
    value: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    const branch = attachmentStorageCollection.selector(state);
    const integration = getIntegrationBySlug(state, ownProps.storageType);

    return {
        items: branch.items,
        fetching: branch.loading,
        fetchingMore: branch.loadingMore,
        cursor: branch.raw.cursor,
        integrationIcon: getIntegrationIcon(integration),
    };
}

const mapDispatchToProps = {
    fetch: attachmentStorageCollection.actions.request,
    fetchMore: attachmentStorageCollection.actions.requestMore,
};

export default flow(
    connect(mapStateToProps, mapDispatchToProps),
)(AttachmentFilePickerContainer);
