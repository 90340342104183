import { createReducer } from 'redux-act';
import u from 'updeep';
import * as actions from './network-status-actions';
import { reducerName } from './network-status-config';

const initialState = {
    connected: true
};

function handleSetStatus(state, { connected }) {
    return u(
        { connected },
        state
    );
}

const networkStatusReducer = createReducer({
    [actions.appOffline]: handleSetStatus,
    [actions.appOnline]: handleSetStatus,
}, initialState);

networkStatusReducer.path = reducerName;

export function getAppNetworkStatus(state) {
    return state[reducerName].connected;
}

export default networkStatusReducer;
