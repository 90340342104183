export const subPaths = Object.freeze({
    overview: 'overview',
    children: 'children',
});

export const subPathIndexes = Object.freeze({
    [subPaths.overview]: 0,
    [subPaths.children]: 1,
});

export const defaultSubPath = subPaths.overview;
export const defaultIndex = subPathIndexes[subPaths.overview];
