import { createSelector } from 'reselect';
import config from '../../../config';
import { applicationInitialState, applicationBranchName } from './application-settings-constants';
import applicationSettingsModel from './application-settings-model';

const defaultInactivityTimeout = 175 * 60;

const getMainBranch = state => state[applicationBranchName];

export const selectSortTypes = createSelector(getMainBranch, state => state.sortTypes);
export const selectCurrentBreakpoint = createSelector(getMainBranch, state => state.currentBreakpoint);
export const selectLastRoutingPath = createSelector(applicationSettingsModel.selectors.getExtensionState, state => state.lastRoutingPath);
export const selectApplicationSortTypeEntities = createSelector(selectSortTypes, sortTypes => sortTypes.entities);
export const selectApplicationData = createSelector(applicationSettingsModel.selectors.getAttributes, state => state);
export const selectTimezone = createSelector(applicationSettingsModel.selectors.getAttributes, state => state.timezone);
export const selectListViewType = createSelector(getMainBranch, state => state.listViewType);
export const selectPlanPageType = createSelector(getMainBranch, state => state.planPageType);
export const selectAccountInfo = createSelector(applicationSettingsModel.selectors.getAttributes, state => ({
    name: state.account_name,
    id: state.fusebill_customer_id,
}));

export const selectApplicationMFAState = createSelector(
    applicationSettingsModel.selectors.getAttributes,
    state => state.multi_factor_authentication,
);

export const selectDefaultCurrencyFormat = createSelector(
    applicationSettingsModel.selectors.getAttributes, data => data?.default_currency_format
);
export const getSavedRedirectPath = createSelector(getMainBranch, state => state.redirectPath);
export const selectPlanTreeNavSortTypes = createSelector(getMainBranch, state => state.planTreeNavSortType);
export const selectUserSessionTimeout = createSelector(
    applicationSettingsModel.selectors.getAttributes, getMainBranch,
    (applicationData, applicationPersistedState) => (
        applicationData?.user_session_timeout || applicationPersistedState?.user_session_timeout
    )
);

export const localStorageSelector = createSelector(
    getMainBranch, selectUserSessionTimeout,
    (state, userSessionTimeout) => ({
        listViewType: state.listViewType || applicationInitialState.listViewType,
        allowAccess: state.allowAccess,
        sortTypes: state.sortTypes,
        redirectPath: state.redirectPath,
        planTreeNavSortType: state.planTreeNavSortType,
        user_session_timeout: userSessionTimeout ?? defaultInactivityTimeout,
    })
);

export const selectApplicationSettingsPersist = createSelector(
    applicationSettingsModel.selectors.getAttributes, data => ({ data })
);

export const isMobileSelector = createSelector(
    getMainBranch,
    state => config.breakpoints[state.currentBreakpoint] < config.breakpoints.medium
);

export const isDesktopSelector = createSelector(
    getMainBranch,
    state => config.breakpoints[state.currentBreakpoint] >= config.breakpoints.medium
);

export const selectIsMinWidthXLarge = createSelector(
    getMainBranch,
    state => config.breakpoints[state.currentBreakpoint] >= config.breakpoints.xlarge
);

export const selectLoginFormCredentialsEnabled = createSelector(
    [applicationSettingsModel.selectors.getAttributes],
    data => data.login_form_credentials === 'active',
);

// TO DO: Move to utility functions
export const isTouchDeviceSelector = () => {
    return 'ontouchstart' in document.documentElement;
};
