import { flow } from 'lodash';
import createFormModal from '../../../../shared/modules/create-view/component';
import parseValidationMessage from '../../../../shared/modules/input-attachments/parse-validation';
import Permissions from '../../../permissions';
import { entityPermissionNames } from '../../../permissions/constants';
import withFilePickerState from '../file-picker/with-attachment-file-picker-state';
import AttachmentForm from '../form/attachment-form';
import AttachmentFormControls from '../form/attachment-form-controls';
import { attachmentCreateModel } from '../attachment-models';

const {
    actions, selector
} = attachmentCreateModel;

const formModal = createFormModal({
    actions,
    selector,
    formName: 'attachment-create',
    parseFormSubmitError: parseValidationMessage,
    ControlsComponent: AttachmentFormControls,
    mapStateToProps(state, ownProps) {
        const { parentId, parentEntityType, goalId } = ownProps;
        const planAttachmentPermissions = Permissions.selectors
            .selectEntityPermissions(state, goalId, Permissions.constants.entitySections.attachment);

        return {
            title: 'Attachments',
            draftAvailable: false,
            initialValues: {
                attachments: [],
                parentId,
                parentEntityType,
            },
            controlProps: {
                submitDisabled: ownProps.filePicker.visible,
            },
            filePicker: ownProps.filePicker,
            onHide: ownProps.filePicker.hide,
            canCreateAttachment: planAttachmentPermissions?.[entityPermissionNames.canCreate],
            canDestroyAttachment: planAttachmentPermissions?.[entityPermissionNames.canDestroy],
            canDestroyOwnAttachment: planAttachmentPermissions?.[entityPermissionNames.canDestroyOwn],
        };
    }
});

export default flow(
    formModal,
    withFilePickerState,
)(AttachmentForm);
