import React from 'react';
import PropTypes from 'prop-types';

export default function ActionSheetGroup({ children }) {
    return (
        <div className="action-sheet-group">
            {children}
        </div>
    );
}

ActionSheetGroup.defaultProps = {
    children: undefined,
};

ActionSheetGroup.propTypes = {
    children: PropTypes.node,
};
