import u from 'updeep';
import {
    takeEvery,
    put,
    select,
    take,
    call,
} from 'redux-saga/effects';
import { push, replace } from 'react-router-redux';
import { flow } from 'lodash';
import * as routeUtils from '../../routes/rules';
import * as goalmapActions from '../goalmap-list/actions';
import * as actions from './actions';
import { plansListMy } from '../plans-list-my/entity';
import { getNextStep, getUrl, getStepIndex } from './selector';
import { states } from '../goalmap-list/config';
import { getDetails, getGoalWizardStep } from '../goalmap-list/selector';
import { stepsTypes } from './constants';

function* removeGoalAndRedirect({ payload }) {
    if (payload.id) {
        yield put(goalmapActions.remove.request(payload));
        yield take(goalmapActions.remove.success.getType());
    }

    const action = flow(
        routeUtils.createUrl,
        push
    )(routeUtils.paths.dashboard);

    yield put(action);
}

function* createPlan(data) {
    const { wizard_settings } = data.payload.params;
    wizard_settings.step = getNextStep(wizard_settings.step).name;
    yield put(goalmapActions.create.request(data.payload));
    const { payload } = yield take(goalmapActions.create.success.getType());
    yield put(goalmapActions.fetchDetailsSuccess(payload));
    yield put(replace(getUrl(payload.id, stepsTypes.NAME_PLAN)));
    const { name } = getNextStep(stepsTypes.NAME_PLAN);
    yield put(push(getUrl(payload.id, name)));
}

function setStep(step, data) {
    const currentStep = getGoalWizardStep(data);

    if (!currentStep) {
        return data;
    }

    const currentStepIndex = getStepIndex(currentStep);
    const nextStepIndex = getStepIndex(step);

    if (currentStepIndex >= nextStepIndex) {
        return data;
    }

    return u({
        wizard_settings: { step },
    }, data);
}

function* handleRedirect({ step, data }) {
    const { id } = data;

    const targetUrl = getUrl(id, step);
    yield put(push(targetUrl));
}

function* updatePlan({ payload }) {
    const goalDetails = yield select(getDetails);
    const nextStepNavigation = getNextStep(getGoalWizardStep(payload.params));
    const dataToSend = setStep(nextStepNavigation.name, goalDetails);

    const updDataToSend = u(
        u.omit('wizard_settings', (payload.params || {})),
        dataToSend
    );

    yield put(goalmapActions.updateDetails.request({ params: updDataToSend }));
    const result = yield take(goalmapActions.updateDetails.success.getType());
    yield call(handleRedirect, {
        step: nextStepNavigation.name,
        data: result.payload,
    });
}

function* setWizardStepForward({ payload }) {
    const goalDetails = yield select(getDetails);
    const nextStep = getNextStep(payload.currentStep);

    const dataToSend = u({
        updateGoalInfo: true,
        send_member_invites: true,
        state: payload.activate ? states.active : states.draft,
    }, setStep(nextStep.name, goalDetails));

    yield put(goalmapActions.updateDetails.request({ params: dataToSend }));
    const result = yield take(goalmapActions.updateDetails.success.getType());

    if (nextStep.name === stepsTypes.COMPLETED) yield put(plansListMy.actions.fetch.request({}, { silent: true }));

    yield call(handleRedirect, {
        step: nextStep.name,
        data: result.payload,
    });
}

export default function* goalmapWizardSaga() {
    yield takeEvery(actions.createPlanAndRedirect.getType(), createPlan);
    yield takeEvery(actions.setWizardStepForward.getType(), setWizardStepForward);
    yield takeEvery(actions.updatePlanAndRedirect.getType(), updatePlan);
    yield takeEvery(actions.removeGoal.getType(), removeGoalAndRedirect);
}
