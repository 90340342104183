import { useEffect } from 'react';
import { connect } from 'react-redux';
import integrationCollection from './integrations-collection';

function ApplicationIntegrations({ fetchIntegrations }) {
    useEffect(() => {
        fetchIntegrations();
    }, []);

    return null;
}

const mapDispatchToProps = {
    fetchIntegrations: integrationCollection.actions.fetch.request,
};

export default connect(null, mapDispatchToProps)(ApplicationIntegrations);
