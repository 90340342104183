import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { isBoolean } from 'lodash';
import classnames from 'classnames';
import Form from '../../modules/form/component';
import Button from '../button/component';
import Icon from '../icon/component';
import componentClassNames from './classnames';

import './styles.css';
import { wait } from '../../utils/common';

export default function createInlineFieldForm(options) {
    class InlineFieldForm extends Component {
        /*
            Jira issue - ENVSC-1639. Android mobile issue fix.
            Delay was added to browser go through onChange / onBlur cycle
            in `Editor` component.
        */
        handleSubmit = (event) => {
            event.preventDefault();

            wait(150).then(() => this.form.submit());
        };

        handleCancel = (event) => {
            const { onCancel } = this.props;
            event.preventDefault();

            return onCancel(event);
        };

        handleSubmitSuccess = (response) => {
            const { onCancel, onSuccessSubmit } = this.props;

            onCancel(response);
            onSuccessSubmit(response);
        };

        onSubmit = (values, dispatch, props) => {
            const { onSubmit } = this.props;

            const upgradedValues = { ...values };

            if (!upgradedValues.params.id) {
                upgradedValues.params = { ...this.props.initialValues, ...upgradedValues.params };
            }

            onSubmit(upgradedValues, dispatch, props);
        };

        render() {
            const {
                children,
                disableSubmit,
                handleSubmit,
                submitting,
                onErrorSubmit,
                containerClassName,
                dirty
            } = this.props;

            const useNavigationBlocking = isBoolean(options.useNavigationBlocking)
                ? options.useNavigationBlocking
                : true;

            return (
                <Form
                    ref={(el) => { this.form = el; }}
                    className={classnames(componentClassNames.root, {
                        [containerClassName]: !!containerClassName,
                    })}
                    onSubmit={this.onSubmit}
                    onErrorSubmit={onErrorSubmit}
                    onSuccessSubmit={this.handleSubmitSuccess}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    useNavigationBlocking={useNavigationBlocking}
                >
                    {children}

                    <div className={componentClassNames.controls}>
                        <Button
                            type="submit"
                            onClick={this.handleSubmit}
                            styleType={Button.styleTypes.success}
                            size={Button.sizes.small}
                            disabled={disableSubmit}
                            loading={submitting}
                            outline
                        >
                            <Icon name="check" />
                        </Button>

                        <Button
                            styleType={Button.styleTypes.danger}
                            size={Button.sizes.small}
                            outline
                            disabled={submitting}
                            onClick={this.handleCancel}
                        >
                            <Icon name="times-circle" />
                        </Button>
                    </div>
                </Form>
            );
        }
    }

    InlineFieldForm.defaultProps = {
        children: undefined,
        containerClassName: undefined,
        onSuccessSubmit() {},
        onErrorSubmit() {},
        initialValues: {},
        disableSubmit: undefined,
    };

    InlineFieldForm.propTypes = {
        children: PropTypes.node,
        onSubmit: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        disableSubmit: PropTypes.bool,
        onSuccessSubmit: PropTypes.func,
        onErrorSubmit: PropTypes.func,
        containerClassName: PropTypes.string,
        initialValues: PropTypes.object,
        dirty: PropTypes.bool.isRequired
    };

    return reduxForm({
        form: options.formName,
        destroyOnUnmount: options.destroyOnUnmount,
        enableReinitialize: options.enableReinitialize || false,
    })(InlineFieldForm);
}
