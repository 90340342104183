import { remove } from 'lodash';

export const reasons = Object.freeze({
    badInput: 'badInput',
    customError: 'customError',
    patternMismatch: 'patternMismatch',
    rangeOverflow: 'rangeOverflow',
    rangeUnderflow: 'rangeUnderflow',
    stepMismatch: 'stepMismatch',
    tooLong: 'tooLong',
    tooShort: 'tooShort',
    typeMismatch: 'typeMismatch',
    valid: 'valid',
    valueMissing: 'valueMissing',
});

function getValidityReasons(obj) {
    const result = [];

    Object.keys(reasons).map((objKey) => {
        if (obj[objKey] === true) {
            result.push(objKey);
        }
        return null;
    });

    return result;
}

export function getValidityMessages(validity = {}, dictionary = {}, defaultMessage) {
    let reasonsArr = getValidityReasons(validity);

    if (!reasonsArr.length) {
        return [];
    }

    if (reasonsArr.includes(reasons.valueMissing) && reasonsArr.length > 1) {
        reasonsArr = remove(reasonsArr, reason => reason === reasons.valueMissing);
    }

    return reasonsArr.map(item => dictionary[item] || defaultMessage);
}
