import { slugs } from './config';
import { planTitle } from '../../shared/constants/titles';

export const deactivationConfirms = Object.freeze({
    [slugs.slack]: `This will deactivate Slack integration for all the ${planTitle}s`,
    // [slugs.googleDrive]: 'This will deactivate Google Drive integration for all users.'
});

export const titles = Object.freeze({
    [slugs.slack]: 'Slack',
    [slugs.googleDrive]: 'Google Drive',
    [slugs.dropbox]: 'Dropbox',
    [slugs.oneDrive]: 'OneDrive',
});
