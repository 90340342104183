import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubscriptionLimitProviders, SubscriptionContactSalesModalProvider } from '../subscription';

export default class IntegrationSubscriptionLimitProvider extends Component {
    renderWithLimit = (limit) => {
        const additionalText = 'If you want to get more integrations, please contact sales to upgrade your subscription plan.';
        const reachedLimitText = `You have used ${limit.used} of ${limit.value} available integrations. ${additionalText}`;
        const notAvailiableText = `Your subscription plan doesn't support integrations. ${additionalText}`;
        const text = limit.value > 0 ? reachedLimitText : notAvailiableText;

        return (
            <SubscriptionContactSalesModalProvider text={text}>
                {modalProps => this.props.children({
                    limit,
                    salesModal: modalProps,
                })}
            </SubscriptionContactSalesModalProvider>
        );
    }

    render() {
        return (
            <SubscriptionLimitProviders.Integrations>
                {this.renderWithLimit}
            </SubscriptionLimitProviders.Integrations>
        );
    }
}

IntegrationSubscriptionLimitProvider.defaultProps = {
    children() {}
};

IntegrationSubscriptionLimitProvider.propTypes = {
    children: PropTypes.func,
};
