export const PlanId = 'plan_id';
export const EntityId = 'entity_id';
export const EntityType = 'entity_type';
export const ParentEntityId = 'parent_id';
export const ParentEntityType = 'parent_entity_type';
export const SubPathMobile = 'sub_path';
// toDo maybe parent_type don't need but i got link with :parent_type
export const ParentType = 'parent_type';
export const teamId = 'team_id';

export const LegacyPlanId = 'id';
export const LegacyEntityId = 'detailsId';
export const LegacySegmentId = 'strategyMapId';
export const LegacyStrategyObjectiveId = 'segmentId';
export const LegacyTacticActionId = 'planId';
export const LegacySubPathMobile = 'subPath';
