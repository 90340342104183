import PropTypes from 'prop-types';
import React from 'react';
import createListOfItems from '../../../../shared/modules/list-view/component';
import DescriptionModal from '../description/history-description-container';
import historyCollection from '../history-collection';
import HistoryListItem from './history-list-item';
import './history-list.css';

const {
    selector,
    actions,
} = historyCollection;

const HistoryListComponent = createListOfItems({
    actions,
    selector,
})(HistoryListItem);

function HistoryList({
    parentId,
    parentEntityType,
    created_at,
    author,
    isAutoCreation,
    ...rest
}) {
    const extraItems = [
        {
            type: 'author', author, dateHidden: true, is_auto_creation: isAutoCreation,
        },
        { type: 'created_at', created_at, dateHidden: true },
    ];

    return (
        <div className="side-history-list">
            <DescriptionModal>
                {({ toggleDescrModal }) => (
                    <HistoryListComponent
                        {...rest}
                        itemProps={{
                            toggleDescrModal,
                        }}
                        paginationDisabled
                        params={{
                            includes: 'user.user_profile',
                            subject_id: parentId,
                            subject_type: parentEntityType,
                        }}
                        extraItems={extraItems}
                    />
                )}
            </DescriptionModal>
        </div>
    );
}

HistoryList.defaultProps = {
    created_at: undefined,
    author: '',
    isAutoCreation: undefined,
};

HistoryList.propTypes = {
    parentId: PropTypes.number.isRequired,
    parentEntityType: PropTypes.string.isRequired,
    created_at: PropTypes.string,
    author: PropTypes.string,
    isAutoCreation: PropTypes.bool,
};

export default HistoryList;
