import { createAction } from 'redux-act';
import createAsyncActions from '../../shared/actions/async';

const prefix = 'goalmap-list';

export const fetch = createAction(`${prefix}/FETCH`);
export const fetchSuccess = createAction(`${prefix}/FETCH_SUCCESS`);
export const fetchError = createAction(`${prefix}/FETCH_ERROR`);

export const fetchDetails = createAction(`${prefix}/FETCH_DETAILS`);
export const fetchDetailsSuccess = createAction(`${prefix}/FETCH_DETAILS_SUCCESS`);
export const fetchDetailsError = createAction(`${prefix}/FETCH_DETAILS_ERROR`);
export const detailsClear = createAction(`${prefix}/DETAILS_CLEAR`);

export const create = createAsyncActions(`${prefix}/create`);
export const updateDetails = createAsyncActions(`${prefix}/updateDetails`);
export const clearError = createAction(`${prefix}/clearError`);

export const updateState = createAsyncActions(`${prefix}/updateState`);
export const remove = createAsyncActions(`${prefix}/remove`);

export const memberRemove = createAsyncActions(`${prefix}/member/remove`);
export const memberActivate = createAsyncActions(`${prefix}/member/activate`);
export const memberUpdate = createAsyncActions(`${prefix}/member/update`);

export const membersAdd = createAsyncActions(`${prefix}/members/add`);
