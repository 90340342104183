import { takeEvery, put } from 'redux-saga/effects';
import { logout } from '../../authentication/actions';
import authChangeEmailModel from '../../authentication/change-email/change-email-model';
import {
    changeEmailModel,
    changeEmailSuccessModal,
    changeEmailUpdateSuccessModal,
} from './change-email-model';
import { emailUpdateSuccessModalAttribute } from '../constants';

function* handleUpdateSuccess() {
    yield put(changeEmailSuccessModal.actions.toggle());
    yield put(logout({
        query: {
            [emailUpdateSuccessModalAttribute]: 1,
        },
    }));
}

function* handleAuthUpdateSuccess() {
    yield put(changeEmailUpdateSuccessModal.actions.toggle());
}

export default function* changeEmailSaga() {
    yield takeEvery(changeEmailModel.actions.create.success, handleUpdateSuccess);
    yield takeEvery(authChangeEmailModel.actions.update.success, handleAuthUpdateSuccess);
}
