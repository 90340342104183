import React from 'react';
import Loadable from 'react-loadable';
import { flow } from 'lodash';
import * as navContextSetters from '../modules/plan-navigator/with-navigator-context';
import withScrollTop from '../shared/components/with-scroll-top';
import Loading from './components/loadable';
import { createUrl, paths, params } from './rules';

const toLegacySegmentParamsConfig = {
    [params.PlanId]: `:${params.LegacyPlanId}`,
    [params.EntityId]: `:${params.LegacySegmentId}`,
    [params.SubPathMobile]: `:${params.LegacySubPathMobile}`,
};

const toLegacyStrategyObjectiveParamsConfig = {
    [params.PlanId]: `:${params.LegacyPlanId}`,
    [params.EntityId]: `:${params.LegacyStrategyObjectiveId}`,
    [params.ParentEntityId]: `:${params.LegacyStrategyObjectiveId}`,
    [params.SubPathMobile]: `:${params.LegacySubPathMobile}`,
};

const toLegacyTacticActionParamsConfig = {
    [params.PlanId]: `:${params.LegacyPlanId}`,
    [params.EntityId]: `:${params.LegacyTacticActionId}`,
    [params.SubPathMobile]: `:${params.LegacySubPathMobile}`,
};

const toLegacyKPIParamsConfig = {
    [params.PlanId]: `:${params.LegacyPlanId}`,
    [params.EntityId]: `:${params.LegacyEntityId}`,
};

const SegmentDetails = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/goalmap-strategy-maps/details/index'),
    loading: Loading,
});

const StrategyObjectiveDetails = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/goalmap-segments/details'),
    loading: Loading,
});

const TacticActionDetails = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ '../modules/goalmap-plans-actions/details'),
    loading: Loading,
});

const KPIUpdate = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap-additional" */ '../modules/goalmap-milestones/update/kpi-update'),
    loading: Loading,
});

const KPIDetails = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap-additional" */ '../modules/goalmap-milestones/details'),
    loading: Loading,
});

export default [
    // Segment
    {
        path: createUrl(paths.planChildrenSegment, toLegacySegmentParamsConfig),
        component: flow(
            withScrollTop,
            navContextSetters.setSegmentContext,
        )(SegmentDetails),
    },
    {
        path: createUrl(paths.planChildrenSegmentMobile, toLegacySegmentParamsConfig),
        component: withScrollTop(SegmentDetails),
    },

    // Strategy/Objective
    {
        path: createUrl(paths.planChildrenStrategyObjective, toLegacyStrategyObjectiveParamsConfig),
        component: flow(
            withScrollTop,
            navContextSetters.setStrategyObjectiveContext,
        )(StrategyObjectiveDetails),
    },
    {
        path: createUrl(paths.planChildrenStrategyObjectiveMobile, toLegacyStrategyObjectiveParamsConfig),
        component: withScrollTop(StrategyObjectiveDetails),
    },

    // Tactic/Action
    {
        path: createUrl(paths.planChildrenTacticAction, toLegacyTacticActionParamsConfig),
        component: flow(
            withScrollTop,
            navContextSetters.setTacticActionContext,
        )(TacticActionDetails),
    },
    {
        path: createUrl(paths.planChildrenTacticActionMobile, toLegacyTacticActionParamsConfig),
        component: withScrollTop(TacticActionDetails),
    },

    // KPI
    {
        path: createUrl(paths.planChildrenKPI, toLegacyKPIParamsConfig),
        component: flow(
            withScrollTop,
            navContextSetters.setKPIContext,
        )(props => <KPIDetails contextLocation="plan_navigator" {...props} />),
    },
    {
        path: createUrl(paths.planChildrenKPIUpdate, toLegacyKPIParamsConfig),
        component: flow(
            withScrollTop,
            navContextSetters.setKPIContext,
        )(props => <KPIUpdate contextLocation="plan_navigator" {...props} />),
    },
];
