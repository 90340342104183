import React from 'react';
import PropTypes from 'prop-types';

import {
    Image,
    Icon,
    UserCard,
    Clickable,
    MetaInfo,
    Text,
} from '../../../../shared/components';
import LinkDownloadSecure from '../../../../shared/modules/link-download-secure/component';

import { getAttachmentStorageIcon } from '../../attachment';
import './progress-note-mobile-list-item.css';

export default function ProgressNotesMobileListItem({
    author,
    updated_at,
    attachments,
    onOptionsClick,
    children,
    allowActionSheet,
}) {
    return (
        <div className="progress-note-item">
            <div className="progress-note-item-header">
                <UserCard
                    firstName={author.first_name}
                    lastName={author.last_name}
                    avatar={author.image}
                />

                {allowActionSheet && (
                    <Clickable
                        action="progress-note-options"
                        className="progress-note-item-header-button"
                        onClick={onOptionsClick}
                    >
                        <Icon
                            name="ellipsis-h"
                            className="progress-note-item-header-icon"
                        />
                    </Clickable>
                )}
            </div>

            <div className="progress-note-item-body">
                {children}
            </div>

            <div className="progress-note-attachments">
                {attachments.map(item => (
                    <LinkDownloadSecure
                        className="attachment-item"
                        url={`/attachments/${item.pivot.id}`}
                        name={item.pivot.user_filename}
                        key={item.id}
                        downloadIconEnabled={false}
                    >
                        <div className="attachment-item-storage-container">
                            <Image
                                src={getAttachmentStorageIcon(item.storage_type)}
                                alt="storage-icon"
                                className="attachment-item-storage-icon"
                            />
                            {item.pivot.user_filename}
                        </div>
                        <Text
                            component={Icon}
                            name="arrow-to-bottom"
                            type={Icon.types.regular}
                            styleType="muted"
                            size="medium-large"
                        />
                    </LinkDownloadSecure>
                ))}
            </div>

            <div className="progress-note-item-footer">
                <MetaInfo
                    date={updated_at}
                />
            </div>
        </div>
    );
}

ProgressNotesMobileListItem.defaultProps = {
    attachments: [],
    children: undefined,
    allowActionSheet: false,
};

ProgressNotesMobileListItem.propTypes = {
    author: PropTypes.object.isRequired,
    updated_at: PropTypes.string.isRequired,
    onOptionsClick: PropTypes.func.isRequired,
    attachments: PropTypes.array,
    children: PropTypes.node,
    allowActionSheet: PropTypes.bool,
};
