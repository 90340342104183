import request from '../../../request';
import { Model } from '../../../shared/entities-v2';
import { asyncTypes as requestTypes } from '../../../shared/entities-v2/model/config';
import { safeClone } from '../../../shared/utils/object';
import { routerLocationChange } from '../../../shared/constants';
import * as Modules from '../../settings-users/modules';
import { applicationDataBranchName } from './application-settings-constants';
import createAsyncActions from '../../../shared/entities-v2/base/async-actions';
import formatRequestPayload from '../../../shared/entities-v2/utils/format-request-payload';
import { handleUpdateRequest, handleUpdateSuccess } from '../../../shared/entities-v2/model/reducer';

const apiUrl = '/settings';
const themeUrl = '/settings';
const logoUrl = '/settings/logo';
const name = applicationDataBranchName;

function saveLastRoutingPath(state, action) {
    return {
        ...state,
        extensionState: {
            ...state.extensionState,
            currentRoutingPath: `${action.pathname}${action.search}`,
            lastRoutingPath: state.extensionState?.currentRoutingPath,
        }
    };
}

class ApplicationSettingsModel extends Model {
    persistedData = {};

    setPersistedData = (data) => {
        this.persistedData = safeClone(data, true);
    }

    getPersistedData = () => {
        return this.persistedData;
    }

    // eslint-disable-next-line class-methods-use-this
    format(data, type) {
        if (type === requestTypes.update) {
            if (data.entityName === 'logo') {
                return Modules.buildFormData({ logo: data.logo });
            }
            this.setPersistedData(data);
            return { theme: data.theme, accent: data.accent };
        }
        return { ...data };
    }

    // eslint-disable-next-line class-methods-use-this
    update(data, options) {
        const formattedData = this.format(data, requestTypes.update);
        return request
            .post(data.entityName === 'logo' ? logoUrl : themeUrl, formattedData, options)
            .then(this.parse, this.parseError);
    }

    // eslint-disable-next-line class-methods-use-this
    parse = (data, type) => {
        if (type === requestTypes.fetch) {
            this.setPersistedData(data);
            return data;
        }

        if (data?.logo) {
            return { ...this.getPersistedData(), ...data };
        }

        return this.getPersistedData();
    }
}

const loginFormCredentialsToggle = createAsyncActions(`${name}/login-credentials-toggle`, formatRequestPayload);

const applicationSettingsModel = new ApplicationSettingsModel({
    getApiUrl: () => apiUrl,
    name,
    additionalArtifacts: {
        [Model.additionalArtifacts.actions]: {
            routerLocationChange,
            loginFormCredentialsToggle,
        },
        [Model.additionalArtifacts.reducers]: {
            [routerLocationChange]: saveLastRoutingPath,
            [loginFormCredentialsToggle.request]: handleUpdateRequest,
            [loginFormCredentialsToggle.success]: handleUpdateSuccess,
        },
        [Model.additionalArtifacts.initialState]: {
            currentRoutingPath: `${window.location.pathname}${window.location.search}`,
            lastRoutingPath: undefined,
        },
        [Model.additionalArtifacts.api]: {
            loginFormCredentialsToggle: (data) => {
                return request
                    .put(`${apiUrl}/login_form_credentials`, { value: data ? 'active' : 'inactive' })
                    .catch((err) => { throw err; });
            },
        }
    }
});

export default applicationSettingsModel;
