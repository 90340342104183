import u from 'updeep';
import request from '../../request';
import { createModal, List, createDetails } from '../../shared/entities';
// import widget from 'modules/widget-my-drafts/components';
import * as Modules from '../settings-users/modules';

const apiUrl = '/goals/search';
const uploadUsersApiUrl = '/notes';
const profileApiUrl = '/users';

export const createProgressNotes = createModal({
    apiUrl: uploadUsersApiUrl,
    name: 'create-progress-notes',
    extendAPI: {
        create: ({ params }) => {
            const { id, content } = params;
            return request.post(`/notes/${id}/draft`, { content })
                .then(response => u({ type: 'App\\ProgressNote', title: response.content }, response));
        }
    },
});


export const list = new List({
    apiUrl,
    name: 'searchGoalmap'
});


export const details = createDetails({
    apiUrl: profileApiUrl,
    name: 'user-profile-details-form',
    onUpdate: list.actions.update,
    onRemove: list.actions.remove,
    extendAPI: {
        update: ({ params }) => {
            if (params.user_profile.image && params.user_profile.image.length === 0) {
                delete params.user_profile.image;
            }
            const { user_id } = params.user_profile;
            const account_role = { ...params.account_role };
            params.account_role = account_role.id ? account_role.id : params.account_role;
            const data = Modules.buildFormData(params);
            return request.post(`/users/update/${user_id}`, data);
        },
        updateStatus: ({ params }) => {
            const { id } = params;
            return request.patch(`/users/${id}/invitation`);
        },
    }
});
