import { Model } from '../../../shared/entities-v2';
import { applicationSettingsModel } from '../../application';

const apiUrl = '/settings/account';
const nameModel = 'settings-account-configuration-model';

export const settingsAccountModel = new Model({
    getApiUrl: () => apiUrl,
    name: nameModel,
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onUpdateSuccess]: applicationSettingsModel.actions.fetch.request,
        }
    }
});
