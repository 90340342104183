import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { SelectUser } from '../../../shared/components';
import getMembers from './api';

function OwnerSelect({ goalId, input, ...rest }) {
    return (
        <SelectUser
            loadOptions={search =>
                getMembers({
                    goalId,
                    search,
                    related: 1,
                })
            }
            clearable={false}
            labelKey="title"
            valueKey="id"
            input={input}
            {...rest}
            filterOption={(option, filterValue) => {
                if (!filterValue) return option;
                return option.filter_hash
                    && option.filter_hash.toLowerCase().match(filterValue.toLowerCase());
            }}
        />
    );
}

OwnerSelect.propTypes = {
    goalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    input: PropTypes.shape({
        onChange: PropTypes.func,
    }).isRequired,
};

export default function OwnerSelectField(props) {
    return (
        <Field
            component={OwnerSelect}
            name="owner_id"
            format={(value) => {
                if (value === null || value === undefined) {
                    return 0;
                }

                return value;
            }}
            parse={(value) => {
                if (value === 0) {
                    return null;
                }

                return value;
            }}
            {...props}
        />
    );
}
