import React, { Component, Fragment } from 'react';
import 'quill-mention';
import PropTypes from 'prop-types';
import cx from 'classnames';
import uniqid from 'uniqid';
import { isUndefined, get, debounce } from 'lodash';
import ReactQuill, { Quill } from 'react-quill';
import isURL from 'is-url';
import uuid from 'uuid';
import NodeExtractor from '../node-extractor/component';
import InputContainer from '../input/container';
import Sticky from '../sticky/component';
import Clickable from '../clickable/component';
import Icon from '../icon/component';
import Button from '../button/component';
import composeHref from '../../../shared/utils/compose-href';
import getSuggestions from '../editor/api';
import { urlTypes, types } from '../../utils/entity-type';
import { getIconByTypeCode, } from '../../utils/entity';
import { exportToHTML, importFromHTML } from './utils';
import { attribute, formats } from './constants';
import IndentAttributor from './indent-attributor';

import 'react-quill/dist/quill.snow.css';
import './styles/mention-entry.css';
import './styles.css';
import { adjustColor, blend, makePalette } from '../../utils/color';

const Link = Quill.import('formats/link');
const builtInFunc = Link.sanitize;

Link.sanitize = function customSanitizeLinkInput(href) {
    if (href) {
        return builtInFunc.call(
            this,
            isURL(href) ? href : `https://${href}`
        );
    }

    return builtInFunc.call(this, false);
};

const colors = [
    '#980000', '#ff0000', '#ff9900', '#ffff00', '#00ff00',
    '#00ffff', '#4a86e8', '#0000ff', '#9900ff', '#ff00ff'
];

const fontOptions = [
    { value: 'Roboto', title: 'Roboto' },
    { value: 'Arial', title: 'Arial' },
    { value: 'Georgia', title: 'Georgia' },
    { value: 'Times New Roman', title: 'Times New Roman' },
    { value: 'Courier New', title: 'Courier New' },
];

const fontsWhitelist = fontOptions.map(item => item.value);

const defaultFontOption = 'Roboto';

export default class EditorQuill extends Component {
    static renderPaletteColor(color) {
        return <option value={color} />;
    }

    static drawGreyScaleColors() {
        const greyScaleColors = makePalette('#000000', 7);
        return greyScaleColors.map(c => EditorQuill.renderPaletteColor(c));
    }

    static drawColorPalettes(baseColors) {
        return baseColors.map((color) => {
            const paletteBaseColor = adjustColor(color, 0.4);
            const graduatedColors = makePalette(paletteBaseColor, 6, -0.6, 0.8);

            return (
                <Fragment>
                    {EditorQuill.renderPaletteColor(color)}
                    {
                        graduatedColors.reverse().map(c =>
                            EditorQuill.renderPaletteColor(blend(c, '#e3e3e3', 0)))
                    }
                </Fragment>
            );
        });
    }

    constructor(props) {
        super(props);

        this.id = uuid();

        this.state = {
            value: importFromHTML(props.input.value)
        };

        this.uniqueId = uniqid();

        this.mentionsConfig = {
            allowedChars: /^[A-Za-z\sÅÄÖåäöА-Яа-я0-9]*$/,
            mentionDenotationChars: ['@'],
            dataAttributes: ['id', 'name', 'title', 'denotationChar', 'link', 'target'],
            isolateCharacter: true,
            renderItem: (mention) => {
                const entityType = get(mention, attribute.TAGNAME_ENTITY_TYPE);
                const isUser = (entityType === urlTypes[types.user]);
                const entity = get(mention, attribute.TAGNAME_ENTITY);
                const icon = isUser
                    ? 'user'
                    : getIconByTypeCode(entity.type_code);

                const tagname = get(mention, attribute.TAG_NAME);
                const title = get(mention, attribute.TITLE);

                return `
                    <span class="mention-entity">
                        <span class="icon fal fa-${icon} mention-entity-icon"></span>
                        <span class="mention-entity-title">${title}</span>
                        <span title="@${tagname}" class="mention-entity-tagname">@${tagname}</span>
                    </span>
                `;
            },
            source: async (searchTerm, renderList) => {
                const suggestions = await this.fetchSuggestions({ value: searchTerm });

                renderList(suggestions.map((it) => {
                    const entityType = get(it, attribute.TAGNAME_ENTITY_TYPE);
                    const isUser = (entityType === urlTypes[types.user]);
                    const tagNameEntity = get(it, attribute.TAGNAME_ENTITY);

                    return {
                        ...it,
                        value: it.name,
                        ...!isUser
                            ? { link: composeHref(entityType, tagNameEntity) }
                            : {}
                    };
                }), searchTerm);
            }
        };

        this.handleChange = debounce(this.handleChange, 300);
        this.prepareAttributors();
    }

    componentDidMount() {
        const { autoFocus } = this.props;

        if (autoFocus) {
            setTimeout(this.focus, 15);
        }
    }

    prepareAttributors = () => {
        // font family
        const Font = Quill.import('attributors/style/font');
        Font.whitelist = fontsWhitelist;
        Quill.register(Font);

        // align text
        const AlignStyle = Quill.import('attributors/style/align');
        Quill.register(AlignStyle, true);

        // indent
        const levels = [0, 1, 2, 3, 4, 5];
        const multiplier = 40;
        const indentStyle = new IndentAttributor('indent', 'margin-left', {
            scope: Quill.import('parchment').Scope.BLOCK,
            whitelist: levels.map(value => `${value * multiplier}px`),
        });

        Quill.register(indentStyle, true);
    }

    fetchSuggestions = ({ value }) => {
        return new Promise((res) => {
            const { goal_id } = this.props;

            if (this.lastRequestPromise) {
                this.lastRequestCancelToken.cancel('Operation canceled by the user.');
            }

            const { cancelToken, promise } = getSuggestions(value, goal_id);

            this.lastRequestPromise = promise;
            this.lastRequestCancelToken = cancelToken;

            promise.then((response) => {
                res(response.data);
                this.lastRequestPromise = null;
                this.lastRequestCancelToken = null;
            }).catch(() => { });
        });
    }

    focus = () => {
        if (this.quill) {
            this.quill.getEditor().focus();
        }
    }

    handleFormatChange = (type, value) => {
        const quill = this.quill.getEditor();
        const formatData = quill.getFormat();

        quill.format(
            type,
            isUndefined(value) ? !formatData[type] : value
        );
    }

    handleChange = (content) => {
        const { input: { onChange } } = this.props;

        onChange(exportToHTML(content));
    }

    handleFocus = () => {
        const { onFocus } = this.props.input;
        return onFocus && onFocus();
    }

    handleBlur = () => {
        const { onBlur } = this.props.input;

        // It prevent input blur event in case if user do manipulations with toolbar
        if (this.preventBlur) return false;

        return onBlur && onBlur();
    }

    promptForLink = () => {
        this.href = null;
    }

    handleContainerClick = (event) => {
        if (event.target.id === this.uniqueId) {
            this.focus();
        }
    }

    handleUndo = () => {
        const quill = this.quill.getEditor();

        quill.history.undo();
    }

    handleRedo = () => {
        const quill = this.quill.getEditor();

        quill.history.redo();
    }

    handlePointerUp = () => {
        this.preventBlur = false;
    }

    handlePointerDown = () => {
        this.preventBlur = true;
    }

    renderEditorControls = () => {
        return (
            <div id={`toolbar-${this.id}`}>
                <span className="ql-formats">
                    <select className="ql-font" defaultValue={defaultFontOption} onChange={e => e.persist()}>
                        {fontOptions.map(item => (
                            <option
                                key={item.value}
                                value={item.value}
                            >
                                {item.title}
                            </option>
                        ))}
                    </select>
                    <select className="ql-header" defaultValue="" onChange={e => e.persist()}>
                        <option value="1" />
                        <option value="2" />
                        <option selected />
                    </select>
                </span>
                <span className="ql-formats">
                    <button className="ql-bold" type="button" />
                    <button className="ql-italic" type="button" />
                    <button className="ql-underline" type="button" />
                    <button className="ql-strike" type="button" />
                </span>
                <span className="ql-formats">
                    <select className="ql-color">
                        {EditorQuill.drawGreyScaleColors()}
                        {EditorQuill.drawColorPalettes(colors)}
                    </select>
                    <select className="ql-background">
                        {EditorQuill.drawGreyScaleColors()}
                        {EditorQuill.drawColorPalettes(colors)}
                    </select>
                    <button className="ql-script" value="sub" type="button" />
                    <button className="ql-script" value="super" type="button" />
                </span>
                <span className="ql-formats">
                    <button className="ql-list" value="ordered" type="button" />
                    <button className="ql-list" value="bullet" type="button" />
                    <button className="ql-indent" value="-1" type="button" />
                    <button className="ql-indent" value="+1" type="button" />
                    <select className="ql-align">
                        <option selected />
                        <option value="right" />
                        <option value="center" />
                        <option value="justify" />
                    </select>
                </span>
                <span className="ql-formats">
                    <button className="ql-link" type="button" />
                </span>
                <span className="ql-formats">
                    <button className="ql-clean" type="button" />
                </span>
                <span className="ql-formats">
                    <Clickable className="ql-undo editor-clickable-item" onClick={this.handleUndo}>
                        <svg className="editor-undo-redo" viewBox="0 0 18 18">
                            <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
                            <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
                        </svg>
                    </Clickable>
                    <Clickable className="ql-redo editor-clickable-item" onClick={this.handleRedo}>
                        <svg className="editor-undo-redo" viewBox="0 0 18 18">
                            <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
                            <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
                        </svg>
                    </Clickable>
                </span>
            </div>
        );
    }

    render() {
        const {
            onSave,
            onCancel,
            toolbarHidden,
            toolbarVisibleOnFocus,
            controlsHidden,
            input,
            meta,
            noSticky,
            placeholder,
            label,
            mentionsEnabled,
            wrapperClassName,
            wrapperErrorClassName,
            menuOffsetTop,
            menuOffsetBottom,
            menuConstraintTop,
            menuConstraintBottom,
            menuConstraintTopAlign,
            menuConstraintBottomAlign,
            containerClassName
        } = this.props;

        const withToolbar = !toolbarHidden || (toolbarVisibleOnFocus && meta.active);

        return (
            <NodeExtractor>
                {containerEl => (
                    <InputContainer
                        input={input}
                        label={label}
                        errors={meta.error}
                        touched={meta.touched}
                        className={cx('editor', {
                            'editor-focus': meta.active,
                            'editor-without-toolbar': !withToolbar
                        }, containerClassName)}
                    >
                        {/* eslint-disable */}
                        <div
                            id={this.uniqueId}
                            className={cx('input-editor-wrapper', {
                                [wrapperClassName]: !!wrapperClassName,
                                [wrapperErrorClassName]: (!!wrapperErrorClassName && (!!meta.error && meta.touched))
                            })}
                            onClick={this.handleContainerClick}
                            onMouseDown={this.handlePointerDown}
                            onMouseUp={this.handlePointerUp}
                            onTouchStart={this.handlePointerDown}
                            onTouchEnd={this.handlePointerUp}
                        >
                            {/* eslint-enable */}
                            {
                                (withToolbar && !noSticky)
                                    ? (
                                        <Sticky
                                            offsetTop={menuOffsetTop}
                                            offsetBottom={menuOffsetBottom}
                                            constraintTop={menuConstraintTop}
                                            constraintTopAlign={menuConstraintTopAlign}
                                            constraintBottom={
                                                menuConstraintBottom === false
                                                    ? menuConstraintBottom
                                                    : containerEl
                                            }
                                            constraintBottomAlign={menuConstraintBottomAlign}
                                        >
                                            {this.renderEditorControls()}
                                        </Sticky>
                                    )
                                    : null
                            }
                            {
                                (withToolbar && noSticky)
                                    ? this.renderEditorControls()
                                    : null
                            }
                            <ReactQuill
                                theme="snow"
                                ref={el => this.quill = el} // eslint-disable-line no-return-assign
                                placeholder={placeholder}
                                bounds={`#${this.uniqueId}`}
                                modules={{
                                    toolbar: {
                                        container: withToolbar ? `#toolbar-${this.id}` : null
                                    },
                                    history: {
                                        delay: 2000,
                                        maxStack: 500
                                    },
                                    mention: mentionsEnabled ? this.mentionsConfig : undefined
                                }}
                                formats={formats}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                defaultValue={this.state.value}
                                onChange={this.handleChange}
                            />

                            {
                                !controlsHidden
                                    ? (
                                        <div className="editor-save-controls">
                                            <Button
                                                styleType="success"
                                                outline
                                                className="editor-save-controls-item"
                                                action="editor-save"
                                                onClick={() =>
                                                    onSave({
                                                        [input.name]: () => {
                                                            return false;
                                                        }
                                                    })
                                                }
                                            >
                                                <Icon name="check" />
                                            </Button>

                                            <Button
                                                styleType="danger"
                                                outline
                                                className="editor-save-controls-item"
                                                action="editor-cancel"
                                                onClick={onCancel}
                                            >
                                                <Icon name="times-circle" />
                                            </Button>
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                    </InputContainer>
                )}
            </NodeExtractor>
        );
    }
}

EditorQuill.defaultProps = {
    toolbarHidden: false,
    controlsHidden: false,
    toolbarVisibleOnFocus: false,
    autoFocus: false,
    placeholder: undefined,
    noSticky: false,
    onSave() { },
    onCancel() { },
    mentionsEnabled: true,
    goal_id: undefined,
    label: undefined,
    wrapperClassName: undefined,
    wrapperErrorClassName: undefined,
    menuOffsetTop: 0,
    menuOffsetBottom: 100,
    menuConstraintTopAlign: undefined,
    menuConstraintBottomAlign: 'bottom-bottom',
    menuConstraintTop: undefined,
    menuConstraintBottom: undefined,
    containerClassName: undefined
};

EditorQuill.propTypes = {
    toolbarHidden: PropTypes.bool,
    toolbarVisibleOnFocus: PropTypes.bool,
    controlsHidden: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        name: PropTypes.string
    }).isRequired,
    meta: PropTypes.shape({
        active: PropTypes.bool,
        error: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
        touched: PropTypes.bool,
    }).isRequired,
    mentionsEnabled: PropTypes.bool,
    goal_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    label: PropTypes.string,
    noSticky: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    wrapperErrorClassName: PropTypes.string,
    menuOffsetTop: PropTypes.number,
    menuOffsetBottom: PropTypes.number,
    menuConstraintTop: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuConstraintBottom: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuConstraintTopAlign: PropTypes.string,
    menuConstraintBottomAlign: PropTypes.string,
    containerClassName: PropTypes.string,
};
