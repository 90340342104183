import PropTypes from 'prop-types';
import React from 'react';
import { omit } from 'lodash';
import { Field } from 'redux-form';
import moment from 'moment';
import {
    createFormModal, TitleTagnameFields, OwnerSelect, CategorizationTagSelector
} from '../../../shared/modules';
import {
    Editor, Row, Col, Toggle, WizardHeader, LoadingIndicator,
} from '../../../shared/components';
import { clone as entity } from '../entity';
import { types as entityTypes } from '../../../shared/utils/entity-type';
import { attributes, periodTargetAttributes } from '../constants';
import { isGlobal } from '../utils';
import './styles.css';

const {
    actions, selector,
} = entity;

function MilestoneCloneModal({
    change, goalId, titles, loading,
}) {
    const description = `Create a new ${titles.milestone} with the
        same configuration as the current entity has.`;

    return (
        <div className="milestones-clone-form">
            <WizardHeader
                className="milestone-clone-wizard-header"
                steps={[{ description }]}
                title={`Copy ${titles.milestone}`}
                currentStep={1}
                entity={{ type: entityTypes.milestone }}
            />
            {loading
                ? <LoadingIndicator centered />
                : (
                    <>
                        <TitleTagnameFields
                            change={change}
                            goalId={goalId}
                            titleFieldLayoutRenderer={children => (
                                <Row>
                                    <Col base={8}>
                                        {children}
                                    </Col>
                                    <Col base={4}>
                                        <OwnerSelect
                                            id="subsegment-select-owner"
                                            label="Owner"
                                            placeholder="Select Owner"
                                            goalId={goalId}
                                            required
                                        />
                                    </Col>
                                </Row>
                            )}
                            titleFieldProps={{
                                label: 'Name',
                                placeholder: `Name your ${titles.milestone}`,
                            }}
                            tagnameFieldProps={{
                                label: 'Tag Name',
                                placeholder: `Type in Tag Name for your ${titles.milestone}`,
                            }}
                        />
                        <Field
                            name="description"
                            label="Target State"
                            placeholder={`Type in ${titles.milestone} Target State`}
                            component={Editor}
                            controlsHidden
                            goal_id={goalId}
                        />
                        <Field
                            name="tags"
                            label="Tags"
                            placeholder="Select tags"
                            id="milestone-tags-select"
                            component={CategorizationTagSelector}
                            goal_id={goalId}
                        />
                        <div className="milestones-clone-form-toggle-wrapper">
                            <div className="milestones-clone-form-toggle-label">
                                Open Configuration after publishing
                            </div>
                            <Field
                                name="redirect_after_creating"
                                component={Toggle}
                            />
                        </div>
                    </>
                )}
        </div>
    );
}

MilestoneCloneModal.defaultProps = {
    loading: false,
};

MilestoneCloneModal.propTypes = {
    goalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    change: PropTypes.func.isRequired,
    titles: PropTypes.shape({
        milestone: PropTypes.string,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    initialValues: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    const periodTargets = ownProps.data[attributes.kpi_period_targets];
    const startedAt = ownProps.data[attributes.started_at];
    const targetAt = ownProps.data[attributes.target_at];

    const initialPeriodTargets = periodTargets?.filter(pt =>
        moment(pt[periodTargetAttributes.period_start]).isSameOrAfter(startedAt)
        && moment(pt[periodTargetAttributes.period_end]).isSameOrBefore(targetAt));

    return {
        initialValues: {
            ...omit(ownProps.data, isGlobal(ownProps.data)
                ? [
                    attributes.parent_entity_id,
                    attributes.parent_entity_type
                ]
                : []),
            redirect_to_navigation: ownProps.contextLocation === 'plan_navigator',
            [attributes.tag_name]: undefined,
            [attributes.data_points]: [],
            [attributes.kpi_period_targets]: initialPeriodTargets || undefined
        },
    };
};

const formName = 'milestone-clone';

const formModal = createFormModal({
    actions,
    selector,
    formName,
    enableReinitialize: true,
    mapStateToProps,
})(MilestoneCloneModal);

export default formModal;
