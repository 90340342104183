import PropTypes from 'prop-types';
import React from 'react';
import formatDate, { applyUTCOffset, fromNow } from './format';

export default function FormattedDate({ date, format, utcOffset }) {
    const withOffset = utcOffset
        ? applyUTCOffset(date)
        : date;

    return (
        <span title={formatDate(withOffset, format)}>{fromNow(withOffset)}</span>
    );
}

FormattedDate.defaultProps = {
    format: 'DD/MM/YY dddd',
    utcOffset: false,
};

FormattedDate.propTypes = {
    date: PropTypes.string.isRequired,
    format: PropTypes.string,
    utcOffset: PropTypes.bool,
};
