import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import ReactHighcharts from 'react-highcharts';
import HighchartsMore from 'highcharts-more';
import { generateConfig } from './utils';
import { GaugeType } from './constansts';
import { getCurrencyFormatSymbol } from '../../../modules/application/localizations/selectors';
import { attributes } from '../../../modules/goalmap-milestones/constants';
import * as configCreators from './configs';

import './styles.css';

HighchartsMore(ReactHighcharts.Highcharts);

export default function ChartGauge(props) {
    const {
        bounds, showValues, className, config,
    } = props;
    const currencySymbol = useSelector(state => getCurrencyFormatSymbol(state)(props?.[attributes.currency_format]));
    const gaugeConfig = config || generateConfig(
        bounds[0] < bounds[3] ? GaugeType.ASC : GaugeType.DESC,
        { ...props, currencySymbol }
    );

    return (
        <div
            id="ident"
            className={classnames('ident', className, {
                'hide-val': !showValues,
            })}
        >
            <ReactHighcharts config={gaugeConfig} />
        </div>
    );
}

ChartGauge.defaultProps = {
    className: '',
    showValues: true,
    size: 180,
    height: 308,
    backgroundColor: 'transparent',
    status: undefined,
    type: 'numerical',
    config: undefined,
    value: undefined,
    bounds: undefined,
};

ChartGauge.propTypes = {
    className: PropTypes.string,
    bounds: PropTypes.arrayOf(PropTypes.number),
    showValues: PropTypes.bool,

    /* eslint-disable react/no-unused-prop-types */
    value: PropTypes.number,
    size: PropTypes.number,
    height: PropTypes.number,
    backgroundColor: PropTypes.string,
    status: PropTypes.number,
    type: PropTypes.string,
    /* eslint-enable react/no-unused-prop-types */

    config: PropTypes.object,
};

ChartGauge.configCreators = configCreators;
