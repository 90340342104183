import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../shared/components';
import './styles.css';

export default function BadgePastDue({ text, withIcon }) {
    return (
        <div className="badge-past-due">
            { withIcon && <Icon name="clock" className="badge-past-due-icon" /> } {text}
        </div>
    );
}

BadgePastDue.defaultProps = {
    text: 'Health: Red -  Target Date Past Due',
    withIcon: true,
};

BadgePastDue.propTypes = {
    text: PropTypes.string,
    withIcon: PropTypes.bool,
};
