import './variables.css';

export const sizes = Object.freeze({
    small: 'small',
    smallMedium: 'small-medium',
    medium: 'medium',
    large: 'large',
});

export const styleTypes = Object.freeze({
    default: 'default',
    info: 'info',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
    accent: 'accent',
    brand: 'brand',
});

export const cssVariables = Object.freeze({
    transitionDuration: '--transition-duration',
    colorMischka: '--color-mischka',
    colorLeaf: '--color-leaf',
    colorLeafHalfOpacity: '--color-leaf-half-opacity',
    colorDarkSkyBlueDark: '--color-dark-sky-blue-dark',
    colorLightPaleMustard: '--color-light-pale-mustard',
    colorPurple: '--color-purple',
    colorPurpleHalfOpacity: '--color-purple-opacity',
    colorOrange: '--color-orange',
    colorOrangeHalfOpacity: '--color-orange-half-opacity',
    colorDarkSkyBlueLight: '--color-dark-sky-blue-light',
    colorDarkSkyBlueLightHalfOpacity: '--color-dark-sky-blue-light-half-opacity',
    colorMandyDark: '--color-mandy-dark',
    colorMandyDarkHalfOpacity: '--color-mandy-dark-half-opacity',
    colorPink: '--color-pink',
    colorPinkHalfOpacity: '--color-pink-half-opacity',
    workCenterEntityVerticalOffset: '--work-center-entity-vertical-offset',
    workCenterEntityHeight: '--work-center-entity-height',
    navPlanHeaderHeight: '--nav-plan-header-height',
});
