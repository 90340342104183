import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import { Button, Icon } from '../index';

import './styles.css';

export default function Calendar(props) {
    return (
        <div className={classNames('calendar', { [props.className]: !!props.className })}>
            <ReactDatePicker
                dayClassName={date => date.isSame(props.selectedDate) && 'calendar-selected-date'}
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                    <div className="calendar-header">
                        <div className="calendar-date">
                            {date.format('MMMM YYYY')}
                        </div>

                        <div className="calendar-buttons-wrapper">
                            <Button
                                className="calendar-nav-button"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                size={Button.sizes.small}
                            >
                                <Icon className="calendar-nav-icon" name="angle-left" />
                            </Button>

                            <Button
                                className="calendar-nav-button"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                size={Button.sizes.small}
                            >
                                <Icon className="calendar-nav-icon" name="angle-right" />
                            </Button>
                        </div>
                    </div>
                )}
                {...props}
            />
        </div>
    );
}

Calendar.defaultProps = {
    selectedDate: undefined,
    inline: true,
    className: '',
};

Calendar.propTypes = {
    selectedDate: PropTypes.object,
    inline: PropTypes.bool,
    className: PropTypes.string,
};
