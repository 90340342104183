import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../shared/components/loading-indicator/component';

export default function LoadingComponent({ error, retry, pastDelay }) {
    if (error) {
        return (
            <div>
                Error!
                <button
                    type="button"
                    onClick={retry}
                >
                    Retry
                </button>
            </div>
        );
    }

    if (pastDelay) {
        return <LoadingIndicator centered />;
    }

    return null;
}

LoadingComponent.defaultProps = {
    error: null,
};

LoadingComponent.propTypes = {
    error: PropTypes.object,
    retry: PropTypes.func.isRequired,
    pastDelay: PropTypes.bool.isRequired,
};
