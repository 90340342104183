import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectUser } from '../../../shared/components';
import * as utils from './utils';

export default class UserSelect extends Component {
    getUsers = (search) => {
        const {
            loadOptions,
            loadOptionsParams,
            parse,
        } = this.props;

        return loadOptions(search, loadOptionsParams).then(parse);
    }

    filterOption = (option, filterValue) => {
        const { filterOptionBy } = this.props;
        return utils.filterOption(option, filterValue, filterOptionBy);
    }

    render() {
        const {
            loadOptions,
            loadOptionsParams,
            format,
            parse,
            ...rest
        } = this.props;

        return (
            <SelectUser
                ref={(el) => { this.selector = el; }}
                loadOptions={this.getUsers}
                filterOption={this.filterOption}
                {...rest}
                autoload
                valueKey="value"
                labelKey="label"
                cache={false}
                multi
            />
        );
    }
}

UserSelect.utils = utils;

UserSelect.defaultProps = {
    loadOptionsParams: {},
    format(params) { return params; },
    parse: utils.parse,
    filterOptionBy: utils.defaultFilterOptionBy,
};

UserSelect.propTypes = {
    loadOptions: PropTypes.func.isRequired,
    loadOptionsParams: PropTypes.object,
    format: PropTypes.func,
    parse: PropTypes.func,
    filterOptionBy: PropTypes.arrayOf(PropTypes.string),
};
