import PropTypes from 'prop-types';
import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';
import classnames from 'classnames';
import { isMobileSelector } from '../../../modules/application/settings-data/application-settings-selectors';
import getAttachmentStorageIcon from '../../../modules/secondary-entities/attachment/attachment-storage-icon';
import { Icon } from '../../components';
import useLinkDownloadSecure from './use-link-download-secure';
import LinkDownloadSecureModal from './link-download-modal';
import './styles.css';

const LinkDownloadSecure = ({
    url, children, className, containerClassName, name, downloadIconEnabled, params, method, autoFetch, ...rest
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {
        fetch, fetching, href, slug
    } = useLinkDownloadSecure({ url, params, method });
    const isMobile = useSelector(isMobileSelector);
    const linkRef = useRef(null);


    const hideModal = () => setModalVisible(false);
    const showModal = () => setModalVisible(true);

    const handleFetchSuccess = () => {
        if (isMobile) {
            showModal();
        } else {
            linkRef.current.click();
        }
    };

    useEffect(() => {
        if (autoFetch) {
            fetch()
                .then(handleFetchSuccess)
                .catch(noop);
        }
    }, []);

    const handleDownloadClick = useCallback((event) => {
        event.preventDefault();

        fetch()
            .then(handleFetchSuccess)
            .catch(noop);
    }, [fetch, linkRef]);

    return (
        <span className={classnames('link-download-secure-container', containerClassName)} {...rest}>
            <a
                href="#secure-download"
                onClick={handleDownloadClick}
                className={classnames('link-download link-download-secure', {
                    [className]: !![className],
                    disabled: fetching,
                })}
            >
                {children}
                {
                    downloadIconEnabled
                        ? (
                            <Icon
                                name="cloud-download"
                                className="link-download-icon"
                            />
                        )
                        : null
                }
            </a>

            {isMobile ? (
                <LinkDownloadSecureModal
                    visible={modalVisible}
                    hide={hideModal}
                    icon={getAttachmentStorageIcon(slug)}
                    filename={name}
                    href={href}
                />
            ) : (
                <a
                    id={url}
                    href={href}
                    ref={linkRef}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'none',
                    }}
                >
                    {name}
                </a>
            )}
        </span>
    );
};

LinkDownloadSecure.defaultProps = {
    children: undefined,
    className: undefined,
    containerClassName: undefined,
    autoFetch: false,
    params: {},
    downloadIconEnabled: true,
    method: 'get',
    name: ''
};

LinkDownloadSecure.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string.isRequired,
    autoFetch: PropTypes.bool,
    params: PropTypes.object,
    downloadIconEnabled: PropTypes.bool,
    method: PropTypes.string,
};

export default LinkDownloadSecure;
