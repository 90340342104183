import { paths } from '../../routes/rules';
import { entityPermissionNames } from './constants';

const exceptionSystemPermissions = ['can-goal-create', 'can-manage-xRay', 'can-manage-reports', 'can-create', 'can-destroy', 'can-update'];
const isPermissionException = permissionName => exceptionSystemPermissions.some(name => name === permissionName);

export const getHaveUserPermissionForSystemSettings = (systemPermission) => {
    return Object.entries(systemPermission).some(([permissionName, value]) => {
        if (typeof value === 'object') return getHaveUserPermissionForSystemSettings(value);
        return (value === true && isPermissionException(permissionName) === false);
    });
};

const isNeededPermissions = permissionName =>
    (['can-change-settings', 'can-change-notifications'].some(name => name === permissionName));

export const getHaveUserPermissionForPlanSettings = (planPermissions = {}) => {
    return Object.entries(planPermissions).some(([permissionName, value]) =>
        (value === true && isNeededPermissions(permissionName) === true));
};

export const getPlanSettingsAllowedPath = (planPermissions = {}) =>
    (planPermissions[entityPermissionNames.canChangeSettings] ? paths.planSettings : paths.planSettingsNotifications);
