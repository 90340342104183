import { List, createModal, createDetails } from '../../../shared/entities';

const apiUrl = '/drivers';

export const csfCollection = new List({
    apiUrl,
    name: 'driver-list'
});

export const csfCreateModel = createModal({
    apiUrl,
    name: 'driver-create',
});

export const csfCopyModel = createModal({
    apiUrl,
    name: 'driver-copy',
});

export const csfModel = createDetails({
    apiUrl,
    name: 'driver-details',
    updateHTML: true,
});
