import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import {
    Editor, Col, Row, Input, Text,
} from '../../../../shared/components';
import PeriodSelector from '../../../goalmap-milestones/period-selector';
import { kpiTypes } from '../../../goalmap-milestones/constants';
import { attributes } from '../../constants';

class MilestoneDataPointForm extends Component {
    handlePeriodChange = (item) => {
        const { setPeriodStart, dispatch, form } = this.props;
        if (setPeriodStart) setPeriodStart(item.value);
        else {
            dispatch(change(form, attributes.period_start, item.value));
        }
    };

    render() {
        const {
            frequency, goal_id, type, isUpdateDataPoint
        } = this.props;

        return (
            <div className="milestone-data-point-form">
                <Row>
                    <Col xs={12} md={4}>
                        <PeriodSelector
                            name={attributes.period_start}
                            frequency={frequency}
                            placeholder="Period *"
                            onChange={this.handlePeriodChange}
                            label={(
                                <span className="milestone-data-point-form-label">
                                    Period <Text styleType="danger">*</Text>
                                </span>
                            )}
                            props={{
                                alwaysRenderLabel: true,
                            }}
                            disabled={isUpdateDataPoint}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Field
                            name={attributes.value}
                            label={(
                                <span className="milestone-data-point-form-label">
                                    Period Actual <Text styleType="danger">*</Text>
                                </span>
                            )}
                            placeholder="Actual Value"
                            component={Input}
                            type="number"
                            required
                            step="any"
                        />
                    </Col>
                    {type !== kpiTypes.static.type && (
                        <Col xs={12} md={4}>
                            <Field
                                name={attributes.period_target}
                                label={(
                                    <span className="milestone-data-point-form-label">
                                        Period Target <Text styleType="danger">*</Text>
                                    </span>
                                )}
                                placeholder="Period Target"
                                component={Input}
                                type="number"
                                required
                                step="any"
                                props={{
                                    alwaysRenderLabel: true,
                                }}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <Field
                            name={attributes.progress_note_content}
                            component={Editor}
                            props={{
                                label: 'Note',
                                toolbarHidden: true,
                                controlsHidden: true,
                                wrapperClassName: 'milestone-data-point-editor',
                                // alwaysRenderLabel: true,
                                goal_id,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

MilestoneDataPointForm.defaultProps = {
    setPeriodStart: undefined,
    isUpdateDataPoint: false,
};

MilestoneDataPointForm.propTypes = {
    frequency: PropTypes.string.isRequired,
    goal_id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    setPeriodStart: PropTypes.func,
    isUpdateDataPoint: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default MilestoneDataPointForm;
