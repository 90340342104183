import u from 'updeep';
import { deepFind } from '../../utils/object';
import { insertItemAtPosition, removeItemsAtPosition } from '../../utils/collection';
import transform from './move-transform';
import { getCoords } from './utils';
import { itemHasChildren } from '../../../modules/wizard-goalmap-tree/list/utils';

const isItemParentOfSourceItem = (itemUniqueId, sourceParentUniqueId) => itemUniqueId === sourceParentUniqueId;

const getUptatedTree = (items_, sourceItem, targetItem) => {
    const moveItems = items => items.map((item) => {
        if (item.uniqueId === targetItem.uniqueId) {
            // add sourceItem to TARGET.children and calling recursion
            return { ...item, children: moveItems([...item.children, sourceItem]) };
        }

        if (isItemParentOfSourceItem(item.uniqueId, sourceItem.parent_unique_id)) {
            return {
                ...item,
                // remove sourceItem from his PARENT.children and calling recursion
                children: moveItems(item.children.filter(el => el.uniqueId !== sourceItem.uniqueId))
            };
        }
        if (itemHasChildren(item)) {
            // calling recursion
            return { ...item, children: moveItems(item.children) };
        }

        return item;
    });

    return moveItems(items_);
};

export const handleMoveItem = (state, { sourceItem, targetItem, }) => {
    const items = getUptatedTree(state.items, sourceItem, targetItem);
    return u({ items }, state);
};


// toDo delete after test new local move
export const handleMoveItemOld = (state, {
    target, source, position, items,
}) => {
    let result = items ? [...items] : [...state.items];

    // extract path and index needed to insert new and remove old item
    const sourceCoords = getCoords(source);
    const targetCoords = getCoords(target, position);

    const parentTargetPath = [...targetCoords.path];
    parentTargetPath.splice(parentTargetPath.length - 1, 1);

    const sourceData = deepFind(result, source);
    const targetData = deepFind(result, parentTargetPath);

    if (!targetData || !sourceData) {
        return state;
    }

    // item object that will be moved
    const sourceItem = transform(
        sourceData,
        targetData,
    );

    result = u.updateIn(
        parentTargetPath,
        { children_class: sourceItem.class },
        result
    );

    if (targetCoords.path.length > sourceCoords.path.length) {
        // adding item to target array
        const targetResult = insertItemAtPosition(
            deepFind(result, targetCoords.path),
            sourceItem,
            targetCoords.index,
        );

        result = u.updateIn(
            targetCoords.path,
            targetResult,
            result
        );

        // removing source item
        const sourceResult = removeItemsAtPosition(
            deepFind(result, sourceCoords.path),
            sourceCoords.index,
        );

        result = u.updateIn(
            sourceCoords.path,
            sourceResult,
            result
        );
    } else {
        // removing source item
        const sourceResult = removeItemsAtPosition(
            deepFind(result, sourceCoords.path),
            sourceCoords.index,
        );

        result = u.updateIn(
            sourceCoords.path,
            sourceResult,
            result
        );

        // adding item to target array
        const targetResult = insertItemAtPosition(
            deepFind(result, targetCoords.path),
            sourceItem,
            targetCoords.index,
        );

        result = u.updateIn(
            targetCoords.path,
            targetResult,
            result
        );
    }

    return u({ items: result }, state);
};

function createMoveReducer(actions) {
    return {
        [actions.move]: handleMoveItem,
        [actions.returnPreviousState]: (state, { items }) => {
            return u({ items }, state);
        }
    };
}

export default createMoveReducer;
