export const reducerName = '@@sc-notifications';

export const transportStates = Object.freeze({
    pending: 'pending',
    opened: 'opened',
    closed: 'closed',
    error: 'error',
});

export const socketReadyStates = Object.freeze({
    pending: 0,
    open: 1,
    closing: 2,
    closed: 3,
});

export const visibilityStates = Object.freeze({
    visible: 'visible',
    hidden: 'hidden',
    prerender: 'prerender',
});

export const notificationPermissions = Object.freeze({
    granted: 'granted',
    denied: 'denied',
    unknown: 'default',
});

export const prefix = '@@sc-notifications';

export const attributes = Object.freeze({
    transport: 'transport',
    app: 'app',
    notification: 'notification',
    sync: 'sync',
});
