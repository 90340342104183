import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    reduxForm, Field, FieldArray, getFormSubmitErrors
} from 'redux-form';
import { noop } from 'lodash';
import {
    Modal,
    Button,
    LoadingIndicator,
    Input,
    Form,
    Text,
    ServicePage
} from '../../components';
import {
    ModalFormBody,
    ModalFormFooter,
    ModalFormHeader,
    ModalFormContainer
} from '../../components/modal-form';
import { wait } from '../../utils/common';
import { StickyContext } from '../../components/sticky/sticky-context';
import { stickyConf } from '../../components/sticky/config';
import { selectIsRestricted } from './team-section-selectors';
import TeamSectionMembers from './team-section-members';

import styles from './team-section-modal.module.css';

const TeamSectionModal = ({
    onHide, title, submitLabel, isFetching, initialValues, useRestrictions,
    onSubmit, handleSubmit, submitting, errors, fetchError, onCreateMember
}) => {
    const persistedTeamValue = initialValues.teamUsers || [];
    const isRestrictedState = useSelector(state => selectIsRestricted(state, persistedTeamValue));
    const isRestricted = useRestrictions ? isRestrictedState : false;

    const renderError = () => {
        return (
            <ServicePage
                icon="exclamation-triangle"
                title="You don't have permissions to access this Team"
            />
        );
    };

    return (
        <Form
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            onSuccessSubmit={onHide}
        >
            <ModalFormContainer>
                <ModalFormHeader hide={onHide}>
                    {title}
                </ModalFormHeader>
                <ModalFormBody>
                    <StickyContext.Provider value={stickyConf.modal}>
                        {!isFetching && !!fetchError && renderError()}

                        {isFetching && !fetchError && (
                            <LoadingIndicator centered />
                        )}

                        {!isFetching && !fetchError && (
                            <div>
                                <Field
                                    name="title"
                                    component={Input}
                                    label="Team name"
                                    disabled={isRestricted}
                                    placeholder="Name your Team"
                                />

                                {initialValues?.id && (
                                    <Input
                                        disabled
                                        name="author.id"
                                        label="Created By"
                                        input={{
                                            value: initialValues.author
                                                ? initialValues.author.userProfile.fullName
                                                : 'Undefined',
                                            onChange: noop
                                        }}
                                    />
                                )}

                                <FieldArray
                                    component={TeamSectionMembers}
                                    name="teamUsers"
                                    restrictedUpdates={isRestricted}
                                    persistedTeamValue={persistedTeamValue}
                                    errors={errors}
                                    onCreate={onCreateMember}
                                />

                                {isRestricted && (
                                    <Text
                                        className={styles.memberViewMessage}
                                        styleType="muted"
                                        component="p"
                                    >
                                        Note: Team Members may only add additional Team Members to a Team.
                                    </Text>
                                )}
                            </div>
                        )}
                    </StickyContext.Provider>
                </ModalFormBody>
                {!fetchError && (
                    <ModalFormFooter>
                        <Button
                            type="button"
                            onClick={onHide}
                            outline
                            disabled={submitting}
                        >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            styleType="success"
                            type="submit"
                            loading={submitting}
                        >
                            {submitLabel}
                        </Button>
                    </ModalFormFooter>
                )}
            </ModalFormContainer>
        </Form>
    );
};

TeamSectionModal.defaultProps = {
    fetchError: null,
};

TeamSectionModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    useRestrictions: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    onCreateMember: PropTypes.func.isRequired,
    fetchError: PropTypes.object,
};

const createModalForm = (name) => {
    const errorSelector = getFormSubmitErrors(name);

    const FormComponent = reduxForm({
        form: name,
        enableReinitialize: true,
    })(TeamSectionModal);

    const TeamSectionModalContainer = (props) => {
        const errors = useSelector(errorSelector);
        const ref = useRef(null);

        const onCreateMember = () => {
            wait(15).then(() => {
                const modalScrollable = ref.current?.getScrollable();
                const modalContent = ref.current?.getContent();

                if (modalContent && modalScrollable) {
                    modalScrollable.scrollTo(0, modalContent.offsetHeight);
                }
            });
        };

        return (
            <Modal
                visible={props.visible}
                onHide={props.onHide}
                modalContentClassName={styles.teamSectionModal}
                ref={ref}
            >
                <FormComponent {...props} errors={errors} onCreateMember={onCreateMember} />
            </Modal>
        );
    };

    TeamSectionModalContainer.propTypes = {
        visible: PropTypes.bool.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    return TeamSectionModalContainer;
};

export const TeamSectionUpdateModal = createModalForm('team-section-update');
export const TeamSectionCreateModal = createModalForm('team-section-create');

export default TeamSectionModal;
