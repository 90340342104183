import { CancelToken } from 'axios';
import u from 'updeep';
import request from '../../../request';
import config from '../../../config';

export default function get(searchQuery, goal_id) {
    const cancelToken = CancelToken.source();
    const promise = request.get('/tagnames/list', {
        params: {
            includes: 'tagname_entity',
            tag_name: searchQuery,
            goal_id,
            limit: config.defaultListLimit,
            page: 1,
        },
        cancelToken: cancelToken.token,
    }).then(response => ({
        data: response.data.map(item => u(
            { name: item.tag_name },
            item
        ))
    }));

    return {
        promise,
        cancelToken,
    };
}
