import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import Icon from '../../components/icon/component';
import Button from '../../components/button/component';
import { selectUserDetails } from '../../../modules/user/selectors';

import styles from './styles.module.css';

function ErrorBoundary({ children, user }) {
    const handleClickReport = (eventId) => {
        Sentry.showReportDialog({
            eventId,
            user: {
                email: user.email,
                name: user.full_name
            }
        });
    };

    const renderError = error => (
        <div className="container">
            <div className={styles.boundaryError}>
                <Icon
                    className={styles.boundaryErrorIcon}
                    name="exclamation-triangle"
                    type={Icon.types.solid}
                />
                <div className={styles.boundaryErrorTitle}>Oops..</div>
                <div className={styles.boundaryErrorDescription}>It looks like we’re having an issue</div>
                <div className={styles.boundaryErrorButtonWrapper}>
                    <Button
                        className={styles.boundaryErrorButton}
                        outline
                        onClick={() => handleClickReport(error.eventId)}
                    >
                        Report Issue
                    </Button>
                </div>
            </div>
        </div>
    );

    return (
        <Sentry.ErrorBoundary fallback={renderError}>
            {children}
        </Sentry.ErrorBoundary>
    );
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const user = selectUserDetails(state);

    return { user: user || {} };
}

export default connect(mapStateToProps)(ErrorBoundary);
