import request from '../../../request';
import { Model } from '../../../shared/entities-v2';
import { ModalPlugin } from '../../../shared/entities-v2/plugins';
import { } from '../../authentication/change-email/change-email-model';

const constants = Object.freeze({
    apiUrl: '/change_email/instructions',
    name: 'profile-email-change',
    modalName: 'profile-email-change-modal',
    successModalName: 'profile-email-change-success-modal',
    successUpdateModalName: 'profile-email-change-update-success-modal',
});

class ChangeEmailModel extends Model {
    create(data, options) {
        const formattedData = this.format(data);

        return request
            .get(constants.apiUrl, { params: formattedData }, options)
            .then(this.parse)
            .catch(this.parseError);
    }
}

export const changeEmailModel = new ChangeEmailModel({
    getApiUrl() { return constants.apiUrl; },
    name: constants.name,
    plugins: [
        new ModalPlugin(constants.modalName),
        new ModalPlugin(constants.successModalName),
        new ModalPlugin(constants.successUpdateModalName),
    ],
});

export const changeEmailModal = changeEmailModel
    .getPluginInstanse(constants.modalName);
export const changeEmailSuccessModal = changeEmailModel
    .getPluginInstanse(constants.successModalName);
export const changeEmailUpdateSuccessModal = changeEmailModel
    .getPluginInstanse(constants.successUpdateModalName);
