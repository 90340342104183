import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { RadioButton } from '../index';
import measures from './constants';
import './styles.css';

const measureOptions = [
    measures.numeric,
    measures.currency,
    measures.percentage,
];

function MeasureUnitSelector({
    onChange, name, disabled, label, input, className, labelClassName, ...rest
}) {
    return (
        <div className={cx('measure-unit-selector', {
            [className]: !!className
        })}
        >
            <div className={cx('measure-unit-selector-label', {
                [labelClassName]: !!labelClassName
            })}
            >
                {label}
            </div>
            <div className="measure-unit-selector-options">
                {measureOptions.map((option, idx) => {
                    const isActive = option.value === input.value;

                    return (
                        <RadioButton
                            className={cx('measure-unit-selector-radio', {
                                'checked': isActive,
                                'middle-item': idx === 1,
                            })}
                            key={option.value}
                            id={option.value}
                            disabled={disabled}
                            label={`${option.symbol} ${option.label}`}
                            input={input}
                            {...rest}
                            value={option.value}
                        />
                    );
                })}
            </div>
        </div>
    );
}

MeasureUnitSelector.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.node,
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
};

MeasureUnitSelector.defaultProps = {
    onChange() {},
    value: undefined,
    name: undefined,
    label: 'Unit of Measure *',
    disabled: false,
    className: undefined,
    labelClassName: undefined,
};

MeasureUnitSelector.measures = measures;

export default MeasureUnitSelector;
