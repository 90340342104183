import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    HTMLRenderer,
} from '../../../../shared/components';

export default function HistoryDescriptionModal({ visible, toggle, content }) {
    return (
        <Modal
            id="history-description"
            visible={visible}
        >
            <ModalHeader>
                <Button onClick={toggle}>Hide</Button>
            </ModalHeader>

            <ModalBody>
                <HTMLRenderer>
                    {content}
                </HTMLRenderer>
            </ModalBody>
        </Modal>
    );
}

HistoryDescriptionModal.defaultProps = {
    content: '',
};

HistoryDescriptionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    content: PropTypes.string,
};
