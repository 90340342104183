import { isEmpty } from 'lodash';
import { takeEvery, takeLatest, } from 'redux-saga/effects';
import Model from './model';
import getAdditionalSaga from '../base/additional-saga';
import createAsyncSaga from '../base/async-saga';

export default function createSaga({ actions, api, additionalTriggers }) {
    const updateSuccessSaga = getAdditionalSaga({ action: additionalTriggers?.[Model.events.onUpdateSuccess] });
    const createSuccessSaga = getAdditionalSaga({ action: additionalTriggers?.[Model.events.onCreateSuccess] });
    const removeSuccessSaga = getAdditionalSaga({ action: additionalTriggers?.[Model.events.onRemoveSuccess] });

    const fetch = createAsyncSaga({
        success: actions.fetch.success,
        error: actions.fetch.error,
    }, api.fetch);

    const create = createAsyncSaga({
        success: actions.create.success,
        error: actions.create.error
    }, api.create);

    const update = createAsyncSaga({
        success: actions.update.success,
        error: actions.update.error
    }, api.update);

    const remove = createAsyncSaga({
        success: actions.remove.success,
        error: actions.remove.error
    }, api.remove);

    return function* modelRootSaga() {
        yield takeLatest(actions.fetch.request().type, fetch);
        yield takeLatest(actions.update.request().type, update);
        yield takeLatest(actions.create.request().type, create);
        yield takeLatest(actions.remove.request().type, remove);

        yield takeEvery(actions.update.success().type, updateSuccessSaga);
        yield takeEvery(actions.create.success().type, createSuccessSaga);
        yield takeEvery(actions.remove.success().type, removeSuccessSaga);

        if (!isEmpty(api?.additionalApi)) {
            /* eslint-disable no-restricted-syntax */
            // eslint-disable-next-line guard-for-in
            for (const apiRequest in api.additionalApi) {
                if (actions?.[apiRequest]) {
                    yield takeLatest(actions[apiRequest].request().type, createAsyncSaga({
                        success: actions[apiRequest]?.success,
                        error: actions[apiRequest]?.error,
                    }, api.additionalApi[apiRequest]));
                }

                if (additionalTriggers?.[actions?.[apiRequest]?.success()?.type]) {
                    const saga = getAdditionalSaga({ action: additionalTriggers[actions[apiRequest].success().type] });
                    yield takeEvery(actions[apiRequest].success().type, saga);
                }
            }
        }
    };
}
