import { arrowOffset } from '../config';

const elbowSideWidth = 1;
export const arrowWidth = 5;

const x = elbowSideWidth;

export function getBounds({ parentCoords, childCoords, borderCoords }) {
    const leftBound = Math.min(parentCoords.x, childCoords.x);
    const topBound = Math.min(parentCoords.y, childCoords.y);
    const rightBound = Math.max(parentCoords.x, childCoords.x);
    const bottomBound = borderCoords
        ? borderCoords.y
        : Math.max(parentCoords.y, childCoords.y);
    const width = rightBound - leftBound;
    const height = bottomBound - topBound;

    return {
        leftBound,
        topBound,
        rightBound,
        bottomBound,
        width,
        height,
    };
}

export function generateArrowPath({
    bounds, parentCoords, childCoords
}) {
    const { width, height } = bounds;

    const x1 = (parentCoords.x < childCoords.x ? 0 : width);
    const y1 = 0;

    const x2 = (parentCoords.x < childCoords.x ? width : 0);
    const y2 = height;

    const offset = -25;
    const verticalOffset = 20;
    const rightToLeftOffsetCoords = `l ${offset} 0`;
    const topToBotOffsetCoords = `l 0 ${verticalOffset}`;

    const shouldUseOffset = (
        parentCoords.snapPoint === childCoords.snapPoint
    );

    const isElbowNeeded = Math.abs(x1 - x2) > x && Math.abs(y1 - y2) > x;
    const horizontalOffset = parentCoords.x < childCoords.x ? width : -width;
    const elbowWithLine = `${topToBotOffsetCoords} l ${horizontalOffset} ${0} L ${x2} ${y2}`;

    if (!isElbowNeeded && !shouldUseOffset) {
        return `M ${x1} ${y1} L ${x2} ${y2}`;
    }

    if (!isElbowNeeded) {
        return `M ${x1 - arrowOffset} ${y1} ${rightToLeftOffsetCoords || ''} l ${0} ${y2 - y1} L ${x2 - arrowOffset} ${y2}`;
    }

    return `
        M ${x1} ${y1} ${isElbowNeeded ? elbowWithLine : ''}
`;
}
