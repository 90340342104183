import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.css';

export default function WizardContent({ children, className }) {
    return (
        <div
            className={cx(styles.wizardContent, {
                [className]: !!className,
            })}
        >
            {children}
        </div>
    );
}

WizardContent.defaultProps = {
    children: null,
    className: undefined,
};

WizardContent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
