import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './outdate.css';

export default function OutDateFormatter({ children, isOutDated }) {
    return (
        <div className={cx('table-formatter-out-date', { 'table-formatter-is-out-dated': isOutDated })}>{children}</div>
    );
}

OutDateFormatter.propTypes = {
    children: PropTypes.node.isRequired,
    isOutDated: PropTypes.bool.isRequired,
};
