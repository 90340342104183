import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
    CategorizationTagSelector,
    OwnerSelect,
    TitleTagnameFields
} from '../../../../shared/modules';
import { Col, Editor, Row } from '../../../../shared/components';

export default function CsfForm({ change, goalId, titles }) {
    return (
        <div className="driver-create-form">
            <TitleTagnameFields
                change={change}
                goalId={goalId}
                titleFieldLayoutRenderer={children => (
                    <Row>
                        <Col base={8}>
                            {children}
                        </Col>
                        <Col base={4}>
                            <OwnerSelect
                                id="subsegment-select-owner"
                                label="Owner"
                                placeholder="Select Owner"
                                goalId={goalId}
                                required
                            />
                        </Col>
                    </Row>
                )}
                titleFieldProps={{
                    label: 'Name',
                    placeholder: `Name your ${titles.driver}`,
                }}
                tagnameFieldProps={{
                    label: 'Tag Name',
                    placeholder: `Type in Tag Name for your ${titles.driver}`,
                }}
            />

            <Field
                name="description"
                label="Target State"
                placeholder={`Type in ${titles.driver} Target State`}
                component={Editor}
                goal_id={goalId}
                controlsHidden
            />

            <Field
                name="tags"
                label="Tags"
                placeholder="Select tags"
                id="driver-tags-select"
                component={CategorizationTagSelector}
                goal_id={goalId}
            />
        </div>
    );
}

CsfForm.propTypes = {
    change: PropTypes.func.isRequired,
    goalId: PropTypes.number.isRequired,
    titles: PropTypes.object.isRequired
};
