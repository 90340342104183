import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applicationSettingsActions } from '../application';

export function withPersistPlanPage(path) {
    return (EnhancedComponent) => {
        class ComponentWithPersistedPage extends Component {
            componentDidMount() {
                const { setPlanPage } = this.props;

                setPlanPage(path);
            }

            render() {
                return (
                    <EnhancedComponent {...this.props} />
                );
            }
        }

        ComponentWithPersistedPage.propTypes = {
            setPlanPage: PropTypes.func.isRequired
        };

        const mapDispatchToProps = {
            setPlanPage: applicationSettingsActions.setPlanPageType
        };

        return connect(false, mapDispatchToProps)(ComponentWithPersistedPage);
    };
}
