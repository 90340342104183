import { connect } from 'react-redux';
import mapStateToProps from './contact-sales-mapper';
import createMailto from './create-mailto';

function SubscriptionSalesMailtoProvider({ children, user, account }) {
    return children({
        mailto: createMailto({ account, user }),
    });
}

export default connect(mapStateToProps)(SubscriptionSalesMailtoProvider);
