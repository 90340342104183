import update from 'updeep';
import { createReducer } from 'redux-act';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { updateInCollection, removeFromCollection, mergeInCollection } from '../../shared/utils/collection';
import * as actions from './actions';
import { defaultTitles } from './api';

const initialState = {
    fetching: false,
    items: [],
    pager: {},
    permissions: {},
    detailsError: null,
    detailsFetching: false,
    detailsUpdating: false,
    detailsUpdatingState: false,
    detailsRemoving: false,
    details: {},
    membersUpdating: false,
    membersUpdatingId: undefined,
    membersAdding: false
};

function startMemberUpdate(state, action) {
    return update(
        {
            membersUpdating: true,
            membersUpdatingId: action.id || action.member.user_id
        },
        state
    );
}

function memberUpdateSuccess(state, response) {
    return update(
        {
            membersUpdating: false,
            membersUpdatingId: undefined,
            details: response,
        },
        state
    );
}

function memberAddSuccess(state, response) {
    return update(
        {
            membersAdding: false,
            membersUpdating: false,
            membersUpdatingId: undefined,
            details: response,
        },
        state
    );
}

export default createReducer({
    [actions.fetch]: state => update({ fetching: true }, state),
    [actions.fetchSuccess]: (state, response) => update(
        {
            fetching: false,
            items: response.data,
            permissions: response.permissions,
        },
        state
    ),
    [actions.fetchDetails]: (state, params) => update(
        {
            detailsFetching: !params.silent,
            details: { id: undefined },
            detailsError: null,
        },
        state
    ),
    [actions.fetchDetailsSuccess]: (state, response) => update(
        {
            detailsFetching: false,
            details: response
        },
        state
    ),
    [actions.fetchDetailsError]: (state, response) => update(
        {
            detailsFetching: false,
            detailsError: get(response, ['response', 'data'], response),
        },
        state
    ),
    [actions.updateDetails.error]: (state, response) => update(
        {
            detailsError: get(response, ['response', 'data'], response),
        },
        state
    ),
    [actions.clearError]: state => (
        {
            ...state,
            detailsError: null,
        }
    ),
    [actions.detailsClear]: state => (
        {
            ...state,
            details: {},
            detailsError: null,
        }
    ),
    [actions.create.success]: (state, response) => update(
        {
            items: [
                response,
                ...state.items,
            ]
        },
        state
    ),
    [actions.updateDetails.request]: state => update(
        { detailsUpdating: true },
        state
    ),
    [actions.updateDetails.success]: (state, response) => {
        const updatedList = mergeInCollection(state.items, response.id, response);

        return update(
            {
                detailsUpdating: false,
                details: response,
                items: updatedList,
            },
            state
        );
    },

    // update state
    [actions.updateState.request]: state => update(
        { detailsUpdatingState: true },
        state
    ),
    [actions.updateState.success]: (state, response) => update(
        {
            detailsUpdatingState: false,
            details: response
        },
        state
    ),
    [actions.updateState.error]: state => update(
        { detailsUpdatingState: false },
        state
    ),

    // remove
    [actions.remove.request]: state => update(
        { detailsRemoving: true },
        state
    ),
    [actions.remove.success]: (state, response) => update(
        {
            items: removeFromCollection(state.items, response.id),
            detailsRemoving: false,
            details: {},
        },
        state
    ),
    [actions.remove.error]: state => update(
        { detailsRemoving: false },
        state
    ),
    [actions.membersAdd.request]: state => update(
        { membersAdding: true },
        state
    ),
    [actions.memberRemove.request]: startMemberUpdate,
    [actions.memberUpdate.request]: startMemberUpdate,
    [actions.memberActivate.request]: startMemberUpdate,

    [actions.memberUpdate.success]: memberUpdateSuccess,
    [actions.membersAdd.success]: memberAddSuccess,
    [actions.memberRemove.success]: (state, response) => {
        if (response && response.pivot) {
            return update(
                {
                    details: {
                        goal_members: updateInCollection(
                            state.details.goal_members,
                            response.id,
                            response
                        )
                    },
                    membersUpdating: false,
                    membersUpdatingId: undefined,
                },
                state
            );
        }

        return update(
            {
                details: {
                    goal_members: removeFromCollection(
                        state.details.goal_members,
                        state.membersUpdatingId,
                    )
                },
                membersUpdating: false,
                membersUpdatingId: undefined,
            },
            state,
        );
    },

    [actions.memberActivate.success]: (state, response) => update(
        {
            details: {
                goal_members: updateInCollection(
                    state.details.goal_members,
                    response.id,
                    response
                )
            },
            membersUpdating: false,
            membersUpdatingId: undefined,
        },
        state
    ),
}, initialState);

export function getCurrentGoal(state) {
    return state.goalmapsList.details;
}

export function getItemById(state, id) {
    if (state.goalmapsList.items) {
        return state.goalmapsList.items.find(item => item.id === Number(id));
    }

    return undefined;
}

export function getCurrentGoalmapMembers(state) {
    if (state.goalmapsList.details) {
        return state.goalmapsList.details.goal_members;
    }

    return [];
}

export function getTitlesByGoalId(state, id) {
    let goal;
    if (id) {
        goal = getItemById(state, id) || {};
    } else {
        goal = state.goalmapsList.details || {};
    }

    return (goal.settings || defaultTitles);
}

export function getDetailsError(state) {
    return state.goalmapsList.detailsError;
}

export function isMemberDeactivated(collaborator) {
    return collaborator.pivot.status === 0;
}

export const getGoalTitleByGoalId = createSelector(
    getItemById,
    goal => get(goal, 'title', ''),
);
