import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

export default function BannerContainer({ children, className }) {
    return (
        <div
            className={classnames('banner', {
                [className]: !!className,
            })}
        >
            {children}
        </div>
    );
}

BannerContainer.defaultProps = {
    children: undefined,
    className: undefined,
};

BannerContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
