import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field, SubmissionError } from 'redux-form';
import _ from 'lodash';
import { Button, Col, Row } from '../../components';
import { RoleSelect, UserSelect } from '../index';
import getUsers from './api';
import MemberValue from './member-value';
import { globalAccountTypes } from '../../../modules/user/constants';
import { safeClone } from '../../utils/object';

import './members-select-group.css';

function isEmpty(field) {
    return !field || (typeof field === 'object' && Object.keys(field).length === 0);
}

export default class MembersSelectGroup extends Component {
    state = {
        users: [],
        role: null,
        isInvalidUsersContains: false
    }

    get excludedUserIds() {
        const { currentValues } = this.props;

        return (currentValues.goal_members || []).map(user => user.user_id || _.get(user, 'user_profile.user_id'));
    }

    getLimitedParticipantsRolesIds() {
        const { roles } = this.props;

        return roles
            .find(role => role.name === globalAccountTypes.limitedParticipant).goal_roles
            .map(role => role.id);
    }

    getPlanRoles() {
        const { roles } = this.props;

        if (!roles.length) return [];

        let { users } = this.state;

        users = safeClone(users);

        const rolesMap = _.keyBy(roles, 'id');
        const usersRoles = _.uniq(users.map(user => user.user_account_role.id));

        const availableRoles = _.chain(usersRoles.map(roleId => rolesMap[roleId].goal_roles))
            .flatten()
            .uniqBy('id')
            .sortBy(['position'])
            .value();

        return safeClone(availableRoles);
    }

    validateRole = (users, role) => {
        const isLimitedExists = users.find(user =>
            user.user_account_role.name === globalAccountTypes.limitedParticipant);

        const isNotLimitedParticipantRole = role && !this.getLimitedParticipantsRolesIds().includes(role);
        const isInvalidUsersContains = Boolean(isNotLimitedParticipantRole && isLimitedExists);

        this.setState({ isInvalidUsersContains });
        this.setFieldsErrors({
            members_select: isInvalidUsersContains
                ? ['Users with Limited Participant system role can have only Viewer or Reviewer plan role.']
                : false
        });
    }

    setFieldsErrors = (errors) => {
        this.props.handleSubmit(() => {
            throw new SubmissionError(errors);
        })();
    };

    handleChangeUsers = (e, users) => {
        this.setState({ users });
        this.validateRole(users, this.state.role);
    }

    handleChangeRole = (e, role) => {
        this.setState({ role });
        this.validateRole(this.state.users, role);
    }

    renderMemberValue = (props) => {
        const { isInvalidUsersContains } = this.state;

        return (
            <MemberValue
                {...props}
                isDanger={
                    isInvalidUsersContains
                    && props.value.user_account_role.name === globalAccountTypes.limitedParticipant
                }
            />
        );
    }

    render() {
        const {
            goalId, currentValues, onAddMembers, loadOptions, filterOption, showLabels, loading
        } = this.props;
        const { isInvalidUsersContains } = this.state;

        return (
            <Row className={cx('members-select-group', { 'members-select-group-with-labels': showLabels })}>
                <Col base={6}>
                    <Field
                        name="members_select"
                        component={UserSelect}
                        label={showLabels ? 'Select user' : undefined}
                        props={{
                            placeholder: 'Select members',
                            loadOptions: loadOptions || getUsers,
                            loadOptionsParams: {
                                goalId,
                                unrelated: 1,
                                order_by: ['first_name'],
                                exclude: this.excludedUserIds,
                                status: '1,2,4',
                                limit: 'unlimited',
                            },
                            filterOption: filterOption || undefined
                        }}
                        valueComponent={props => this.renderMemberValue(props)}
                        onChange={this.handleChangeUsers}
                    />
                </Col>
                <Col base={4}>
                    <Field
                        name="role_select"
                        component={RoleSelect}
                        label={showLabels ? 'Select role' : undefined}
                        searchable={false}
                        options={this.getPlanRoles()}
                        type={1}
                        clearable={!isEmpty(currentValues.role_select)}
                        placeholder="Select role"
                        onChange={this.handleChangeRole}
                    />
                </Col>
                <Col className="members-select-group-button-wrapper" base={2}>
                    <div className="input-container">
                        <Button
                            styleType="success"
                            loading={loading}
                            disabled={(
                                ((currentValues.members_select
                                    && !currentValues.members_select.length)
                                    || !currentValues.role_select) || isInvalidUsersContains
                            )}
                            onClick={onAddMembers}
                            block
                        >
                            Add
                        </Button>
                    </div>
                </Col>
            </Row>
        );
    }
}

MembersSelectGroup.defaultProps = {
    roles: [],
    goalId: undefined,
    loadOptions: undefined,
    loading: false,
    showLabels: false,
    filterOption: undefined
};

MembersSelectGroup.propTypes = {
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
        members_select: PropTypes.array,
        role_select: PropTypes.number,
        goal_members: PropTypes.array,
    }).isRequired,
    roles: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    onAddMembers: PropTypes.func.isRequired,
    loadOptions: PropTypes.func,
    filterOption: PropTypes.func,
    showLabels: PropTypes.bool,
    loading: PropTypes.bool,
    goalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
