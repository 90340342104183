/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { debounce } from 'lodash';
import classnames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import {
    Button, Icon, Tooltip, PageSeparator, ButtonGroupCheckbox,
} from '../../components';
import { UrlDataBinder, withErrorBoundary, Select } from '../../modules';
import InputBase from '../../components/input/base';

import './styles.css';

const arrayTypes = [
    'location', 'type',
];

function parseUrlParam(name, value) {
    if (arrayTypes.includes(name)) {
        return value.split(',');
    }

    return value;
}

const minWidthForNormalViewSearchFormContainer = 870;

const isObservableElementHaveBigWidth = elements =>
    elements.length && elements[0].contentRect.width > minWidthForNormalViewSearchFormContainer;

class ListSearchForm extends Component {
    bindedValues = {};

    constructor(props) {
        super(props);

        this.handleChangeQuery = debounce(this.handleInputChange, 500);
        this.resizeCallbackDebounce = debounce(this.resizeCallback, 50);
        this.resizeElement = React.createRef();
        this.state = {
            isBigWidth: false
        };
    }

    componentDidMount() {
        this.resizeObserver = new ResizeObserver(this.resizeCallbackDebounce);
        this.resizeObserver.observe(this.resizeElement.current);
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    resizeCallback = (elements) => {
        const { notHideLabels } = this.props;

        if (isObservableElementHaveBigWidth(elements) || notHideLabels) {
            this.setState({ isBigWidth: true });
        } else {
            this.setState({ isBigWidth: false });
        }
    };

    handleInputChange = (name, value) => {
        const { onSubmit } = this.props;
        const formData = {
            ...this.bindedValues,
            [name]: value,
        };

        onSubmit(formData);
    };

    handleValueParsed = (value) => {
        const { currentValues, onInitialValueParsed, change } = this.props;
        const parameters = Object.keys(currentValues);

        parameters.forEach((key) => { change(key, value[key]); });
        onInitialValueParsed(value);
    }

    renderForm = (bindedValues) => {
        this.bindedValues = bindedValues;
        const {
            handleSubmit, title, create, sort, types, filter, query, children,
            titleComponent, titleClassName, hintText, titleHideable, isInactive,
        } = this.props;
        const { isBigWidth } = this.state;
        const TitleComponent = titleComponent;

        return (
            <div ref={this.resizeElement}>
                <form
                    className="list-search-form"
                    onSubmit={handleSubmit}
                >
                    <div className="list-search-form-title-container">
                        <TitleComponent
                            className={classnames('list-search-form-title', {
                                [titleClassName]: !!titleClassName,
                            })}
                        >
                            {(!isInactive && create.enabled && create.permitted) && (
                                <Tooltip
                                    tooltipTextClassName="list-search-form-tooltip-content"
                                    text={create.canCreateMessage}
                                    disabled={!create.canCreateMessage}
                                    position={Tooltip.position.top}
                                >
                                    <Button
                                        type="button"
                                        styleType="accent"
                                        size="small"
                                        round
                                        withShadow
                                        onClick={create.action}
                                    >
                                        <Icon name="plus" />
                                    </Button>
                                </Tooltip>
                            )}

                            {(!isInactive && create.enabled && !create.permitted) && (
                                <Tooltip
                                    tooltipTextClassName="list-search-form-tooltip-content"
                                    text={create.cantCreateMessage}
                                >
                                    <Button
                                        styleType="accent"
                                        size="small"
                                        round
                                        withShadow
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                    </Button>
                                </Tooltip>
                            )}
                            {(isBigWidth || !titleHideable) && <span className="list-search-form-title-text">{title}</span>}
                            {
                                (hintText && hintText.length)
                                    ? (
                                        <Tooltip
                                            className="list-search-form-tip"
                                            text={hintText}
                                        >
                                            <Icon className="list-search-form-tip-icon" name="info-circle" />
                                        </Tooltip>
                                    )
                                    : null
                            }
                        </TitleComponent>
                    </div>

                    <div
                        className={classnames('list-search-controls-group', {
                            'list-search-controls-group-extended': types.enabled,
                        })}
                    >
                        <div className="list-search-sections">
                            {types.enabled && (
                                <div className="list-search-form-section list-search-form-type-section">
                                    <Fragment>
                                        {isBigWidth && (
                                            <label>
                                                <span className="list-search-form-label-field">{types.label}:</span>
                                            </label>
                                        )}

                                        <div className="list-search-form-input list-search-form-type-input">
                                            <Field
                                                component={ButtonGroupCheckbox}
                                                name={types.name}
                                                options={types.options}
                                                renderItem={types.renderItem}
                                                onChange={(_, value) => this.handleInputChange(types.name, value)}
                                            />
                                        </div>
                                    </Fragment>
                                </div>
                            )}

                            <div className="list-search-form-section">
                                {sort.enabled && (
                                    <Fragment>
                                        {isBigWidth && (
                                            <label htmlFor="list-search-form-sort">
                                                <span className="list-search-form-label-field">Sort By:</span>
                                            </label>
                                        )}

                                        <Field
                                            name={sort.name}
                                            component={Select}
                                            id="list-search-form-sort"
                                            props={{
                                                containerClassName: 'list-search-form-input',
                                                options: sort.options,
                                                clearable: false,
                                                valueKey: 'value',
                                                labelKey: 'label',
                                            }}
                                            onChange={(_, value) => this.handleInputChange(sort.name, value)}
                                        />
                                    </Fragment>
                                )}
                            </div>

                            {filter.enabled && (
                                <div className="list-search-form-section">
                                    {isBigWidth && (
                                        <label htmlFor="list-search-form-filter">
                                            <span className="list-search-form-label-field">Show:</span>
                                        </label>
                                    )}

                                    <Field
                                        name={filter.name}
                                        component={Select}
                                        id="list-search-form-filter"
                                        props={{
                                            containerClassName: 'list-search-form-input',
                                            options: filter.options,
                                            clearable: false,
                                            valueKey: 'value',
                                            labelKey: 'label',
                                        }}
                                        onChange={(_, value) => this.handleInputChange(filter.name, value)}
                                    />
                                </div>
                            )}

                            {query.enabled && (
                                <div className="list-search-form-section">
                                    <Field
                                        name={query.name}
                                        component={InputBase}
                                        id="list-search-form-query"
                                        postfix={({ iconClassName }) => {
                                            return <Icon name="search" className={iconClassName} />;
                                        }}
                                        formGroupClassName={classnames({
                                            [query.formGroupClassName]: !!query.formGroupClassName,
                                        })}
                                        props={{
                                            containerClassName: classnames({
                                                'list-search-form-input': true,
                                                'list-search-no-label': true,
                                            }),
                                            placeholder: query.placeholder || 'Type text',
                                            className: 'list-search-form-input-for-search'
                                        }}
                                        onChange={(_, value) => this.handleChangeQuery(query.name, value)}
                                    />
                                </div>
                            )}
                            {children && (
                                <div className="list-search-children">
                                    {children}
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    render() {
        const { currentValues, resetPage } = this.props;
        const parameters = Object.keys(currentValues);

        return (
            <PageSeparator>
                <UrlDataBinder
                    resetPage={resetPage}
                    parameterName={parameters}
                    parameterParse={parseUrlParam}
                    value={currentValues}
                    onInitialValueParsed={this.handleValueParsed}
                >
                    {bindedValues => this.renderForm(bindedValues)}
                </UrlDataBinder>
            </PageSeparator>
        );
    }
}

ListSearchForm.defaultProps = {
    create: {
        enabled: false,
        permitted: false,
        action: undefined,
        cantCreateMessage: 'This action is not available for your role.',
        canCreateMessage: undefined
    },
    title: undefined,
    sort: {
        enabled: true,
        name: 'order_by',
        options: [],
    },
    filter: {
        enabled: true,
        name: 'filter',
        options: [],
    },
    types: {
        enabled: false,
        renderItem: item => item
    },
    query: {
        enabled: false,
        placeholder: 'Type text',
        formGroupClassName: undefined,
    },
    onInitialValueParsed: () => { },
    children: null,
    change: () => { },
    onSubmit: () => { },
    titleComponent: 'h2',
    titleClassName: undefined,
    resetPage: false,
    hintText: undefined,
    currentValues: {},
    initialValues: {},
    notHideLabels: false,
    titleHideable: true
};

ListSearchForm.propTypes = {
    create: PropTypes.shape({
        enabled: PropTypes.bool,
        permitted: PropTypes.bool,
        action: PropTypes.func,
        cantCreateMessage: PropTypes.string,
        canCreateMessage: PropTypes.string,
    }),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    titleHideable: PropTypes.bool,
    sort: PropTypes.shape({
        enabled: true,
        name: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            }),
        ),
    }),
    filter: PropTypes.shape({
        enabled: PropTypes.bool,
        name: PropTypes.string,
        options: PropTypes.array,
    }),
    types: PropTypes.shape({
        enabled: PropTypes.bool,
        name: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ),
        renderItem: PropTypes.func
    }),
    query: PropTypes.shape({
        enabled: PropTypes.bool,
        name: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        formGroupClassName: PropTypes.string,
    }),
    currentValues: PropTypes.object, // eslint-disable-line
    initialValues: PropTypes.object, // eslint-disable-line
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onInitialValueParsed: PropTypes.func,
    change: PropTypes.func,
    children: PropTypes.node,
    titleComponent: PropTypes.node,
    titleClassName: PropTypes.string,
    resetPage: PropTypes.bool,
    hintText: PropTypes.string,
    notHideLabels: PropTypes.bool,
    isInactive: PropTypes.bool.isRequired,
};

export default withErrorBoundary(ListSearchForm);
