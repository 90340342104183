import React from 'react';

const ZapierLogo = props => (
    <svg
        {...props}
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.99994 8.73397C10.0002 9.31088 9.89614 9.88309 9.69275 10.423C9.15268 10.6263 8.58034 10.7306 8.00325
            10.7307H7.9965C7.40225 10.7299 6.83287 10.6213 6.3075 10.4232C6.10398 9.88328 5.9998 9.31099 6 8.73397V8.72697C5.99972
            8.15016 6.10368 7.57805 6.30688 7.03822C6.84691 6.83448 7.41937 6.73021 7.99656 6.73047H8.00331C8.58044 6.73019
            9.15284 6.83443 9.69281 7.03816C9.89623 7.57794 10.0003 8.15007 10 8.72691V8.73391L9.99994 8.73397ZM15.8889
            7.39716H11.2191L14.5209 4.09509C14.2618 3.73104 13.9727 3.38925 13.6567 3.07328V3.07303C13.3407 2.7574 12.999
            2.46857 12.6351 2.20953L9.33306 5.51159V0.841844C8.89389 0.767964 8.44934 0.730711 8.004 0.730469L7.99575
            0.730469C7.54275 0.730719 7.09906 0.769219 6.66669 0.841844V5.51159L3.36462 2.20953C3.00071 2.4685 2.65916
            2.75754 2.34356 3.07359L2.34181 3.07484C2.02635 3.39042 1.73766 3.73168 1.47875 4.09509L4.78106 7.39716H0.111375C0.111375
            7.39716 0 8.27422 0 8.72772V8.73322C0 9.18672 0.0384375 9.63116 0.111375 10.0638H4.78113L1.47881 13.3658C1.99829 14.0948
            2.63562 14.7322 3.36462 15.2517L6.66669 11.9493V16.6193C7.10538 16.6929 7.54938 16.73 7.99419 16.7305H8.0055C8.45031
            16.7301 8.89432 16.6929 9.333 16.6193V11.9493L12.6354 15.2517C12.9992 14.9925 13.3408 14.7036 13.6567 14.3879L13.6574
            14.3872C13.973 14.0712 14.2618 13.7296 14.5209 13.3658L11.2186 10.0638H15.8889C15.9616 9.63191 15.9995 9.18878
            16 8.73628V8.72466C15.9995 8.27216 15.9616 7.82903 15.8889 7.39716Z"
        />
    </svg>
);

export default ZapierLogo;
