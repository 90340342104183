import { get } from 'lodash';
// import {  }
import uuid from 'uuid';

/* eslint-disable class-methods-use-this */
/**
 * Contains shared functionality of Model and Collection
 * @class
 * @memberof RESTEntities
 */
class BaseResource {
    /**
     * @lends BaseResource#

     * @constructs
     *
     * @param {Object} options
     * @param {string} options.apiUrl - REST API Resource URL
     * @param {Function} options.getApiUrl - Function that computes and returns REST API
     * Resource URL
     * @param {object} options.additionalArtifacts - Define additional actions and reducers
     * @param {object} additionalArtifacts.actions - Additional actions
     * @param {object} additionalArtifacts.reducers - Additional reducers
     */
    constructor(options = {}) {
        this.options = options;
        this.name = get(options, 'name', uuid());
        this.apiUrl = options.apiUrl;
        this.plugins = get(options, 'plugins', []);
        this.additionalArtifacts = get(options, 'additionalArtifacts', { actions: {}, reducers: {} });
    }

    /**
     * Parses `error` object after receiving it from REST API and before
     * setting it as error object to Redux Store
     * @function parseError
     * @memberof RESTEntities.BaseResource
     * @instance
     * @param {Object} error - Object to parse.
     * @returns {Promise<Object>} Parsed `error`
    */
    parseError(error) {
        const errorDescription = {
            ...error.response.data,
            status: error.response.status,
        };

        return Promise.reject(errorDescription);
    }
}

export default BaseResource;
