import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text, Icon, Clickable } from '../../../../shared/components';
import { defaultColor, colorValuesArray } from './config';

const rootClassName = 'structure-entity-description';

export default function StructureEntityDescription({
    description,
    color,
    icon,
    title,
    name,
    onEditStart,
    className
}) {
    const containerClassName = classnames(rootClassName, {
        [`${rootClassName}-${color}`]: true,
        [className]: !!className
    });

    return (
        <div className={containerClassName}>
            <div className={`${rootClassName}-header`}>
                <Icon name={icon} className={`${rootClassName}-icon`} />

                <Text
                    component="h5"
                    className={`${rootClassName}-title`}
                >
                    {title}
                </Text>

                <Clickable
                    className={`${rootClassName}-edit-button`}
                    onClick={event => onEditStart(name, event)}
                    action="set-edit-mode"
                >
                    <Icon name="pencil" />
                </Clickable>
            </div>

            <Text>{description}</Text>
        </div>
    );
}

StructureEntityDescription.defaultProps = {
    color: defaultColor,
    className: undefined,
    title: undefined
};

StructureEntityDescription.propTypes = {
    icon: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string,
    color: PropTypes.oneOf(colorValuesArray),
    name: PropTypes.string.isRequired,
    onEditStart: PropTypes.func.isRequired,
    className: PropTypes.string,
};
