import { takeEvery, put } from 'redux-saga/effects';
import { get } from 'lodash';
import csfRelatedLinkCollection from './csf-related-links-collection';
import { csfRelatedLinkCreateModel, csfRelatedLinkModel } from './csf-related-links-models';
import { relatedLinkParseItem, relatedLinkConstants } from '../../../secondary-entities/related-link';

const attrNames = relatedLinkConstants.attributeNames;

function* handleConnectionAdd({ payload }) {
    for (let i = 0; i < payload.length; i += 1) {
        const data = relatedLinkParseItem(payload[i]);
        const action = csfRelatedLinkCollection.actions.push(data);
        yield put(action);
    }
}

function* handleConnectionRemove({ payload }) {
    const hasRelatedLink = get(payload, attrNames.relatedLink);
    const idKey = hasRelatedLink
        ? attrNames.relatedLinkId
        : attrNames.id;
    const id = hasRelatedLink
        ? get(payload, [attrNames.relatedLink, attrNames.id])
        : get(payload, attrNames.id);

    yield put(csfRelatedLinkCollection.actions.remove({ id, idKey }));
}

export default function* csfConnectionSaga() {
    yield takeEvery(csfRelatedLinkCreateModel.actions.success, handleConnectionAdd);
    yield takeEvery(csfRelatedLinkModel.actions.remove.success, handleConnectionRemove);
}
