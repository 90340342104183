import { get, assign, omit } from 'lodash';
import request from '../../../request';
import parseItem from './related-link-parse-item';
import { apiUrl, types, attributeNames as attrNames } from './related-link-constants';

export default function apiCreate({ params }) {
    const apiUrlCreate = [
        apiUrl,
        get(params, attrNames.primaryLinkId),
        get(params, attrNames.primaryLinkType),
    ].join('/');

    const preparedParams = assign({}, params);

    if (preparedParams.type === types.internal) {
        delete preparedParams[attrNames.url];
    }

    const links = Array.isArray(preparedParams[attrNames.internalLink])
        ? preparedParams[attrNames.internalLink].map(link => ({
            [attrNames.type]: types.internal,
            [attrNames.relatedLinkId]: link.id,
            [attrNames.relatedLinkType]: link.related_link_type,
            [attrNames.title]: link.title,
            [attrNames.typeCode]: link.type_code,
        }))
        : [omit(preparedParams, [attrNames.internalLink, attrNames.primaryLinkId], preparedParams[attrNames.primaryLinkType])];

    return request.post(apiUrlCreate, {
        [attrNames.links]: [...links],
    }).then(response => response.map(parseItem));
}
