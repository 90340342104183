import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import HTMLRender from '../../../../shared/components/html-renderer/component';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from '../../../../shared/components/accordion';
import { ActionSheet, ActionSheetGroup, ActionSheetItem } from '../../../../shared/components/action-sheet';
import { Text, Prompt } from '../../../../shared/components';
import ListViewInfiniteScrollProvider from '../../../../shared/modules/list-view/infinite-scroll';
import isOwnEntity from '../../../../shared/utils/permissions';
import { entityState } from '../../../../shared/utils/entity';

import { selectUserId } from '../../../user/selectors';
import Permissions from '../../../permissions';

import ProgressNotesMobileListItem from './progress-note-mobile-list-item';
import ProgressNoteDetailsModalContainer from '../details/progress-note-details-modal-container';
import { progressNoteCollection, progressNoteModel } from '../progress-note-models';


const accordionId = 'accordion-item-pn';
const notePermissionSelector = Permissions.selectors.createPermissionSelector(Permissions.constants.entitySections.note);

class ProgressNoteMobileList extends Component {
    state = {
        promptVisible: false,
        actionsVisible: false,
        selectedItemId: undefined,
        actions: [],
    };

    getActions(id) {
        const { permissions, items, userId } = this.props;
        const item = find(items, { id });

        if (!item) {
            return [];
        }

        const isOwn = isOwnEntity(userId, item);
        const canUpdate = get(permissions, Permissions.keys.canUpdate);
        const canUpdateOwn = get(permissions, Permissions.keys.canUpdateOwn);
        const canDestroy = get(permissions, Permissions.keys.canDestroy);
        const canDestroyOwn = get(permissions, Permissions.keys.canDestroyOwn);
        const actions = [];

        if (canUpdate || (canUpdateOwn && isOwn)) {
            actions.push(
                <ActionSheetItem onClick={this.handleEdit}>
                    Edit Note
                </ActionSheetItem>
            );
        }

        if (canDestroy || (canDestroyOwn && isOwn)) {
            actions.push(
                <ActionSheetItem onClick={this.togglePrompt}>
                    <Text styleType="danger">Delete Note</Text>
                </ActionSheetItem>
            );
        }

        return actions;
    }

    toggleActionSheet = (id) => {
        const { actionsVisible } = this.state;

        this.setState({
            selectedItemId: id,
            actionsVisible: !actionsVisible,
            actions: this.getActions(id),
        });
    }

    togglePrompt = () => {
        const { actionsVisible } = this.state;

        if (actionsVisible) {
            this.setState({
                actionsVisible: false,
                promptVisible: true,
            });
        } else {
            this.setState({
                actionsVisible: false,
                promptVisible: false,
                selectedItemId: undefined,
            });
        }
    }

    handleRemove = () => {
        const { remove, removeNoteCollectionItem } = this.props;
        const { selectedItemId } = this.state;

        remove({
            id: selectedItemId,
            onSuccess() {
                removeNoteCollectionItem({ id: selectedItemId });
            },
        });
        this.togglePrompt();
    }

    handleEdit = () => {
        const { toggleDetails } = this.props;
        const { selectedItemId } = this.state;

        toggleDetails({ id: selectedItemId });
        this.toggleActionSheet();
    }

    renderList = ({ items }) => items.map(item => (
        <ProgressNotesMobileListItem
            key={item.id}
            updated_at={item.updated_at}
            author={item.author.user_profile}
            attachments={item.attachments}
            onOptionsClick={() => this.toggleActionSheet(item.id)}
            allowActionSheet={!!this.getActions(item.id).length}
        >
            <HTMLRender>
                {item.content}
            </HTMLRender>
        </ProgressNotesMobileListItem>
    ))

    render() {
        const {
            fetch,
            fetchMore,
            params,
            permissions,
            counter,
        } = this.props;
        const { actionsVisible, promptVisible, actions } = this.state;

        return (
            <Fragment>
                <AccordionItem uuid={accordionId}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <div className="progress-note-list-button">
                                <Text>Progress Notes</Text>
                                {!!counter && <Text className="progress-note-list-counter">{counter}</Text>}
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="progress-note-list-container">
                        <ListViewInfiniteScrollProvider
                            params={params}
                            selector={progressNoteCollection.selector}
                            fetch={fetch}
                            fetchMore={fetchMore}
                            emptyListClassName="progress-note-list-empty-message"
                        >
                            {this.renderList}
                        </ListViewInfiniteScrollProvider>
                    </AccordionItemPanel>
                </AccordionItem>

                <ActionSheet visible={actionsVisible}>
                    <ActionSheetGroup>
                        {actions}
                    </ActionSheetGroup>

                    <ActionSheetGroup>
                        <ActionSheetItem onClick={this.toggleActionSheet}>
                            Cancel
                        </ActionSheetItem>
                    </ActionSheetGroup>
                </ActionSheet>

                <ProgressNoteDetailsModalContainer
                    entityId={params.entity_id}
                    permissions={permissions}
                    readonly={false}
                    onRemove={this.handleRemove}
                />

                <Prompt
                    visible={promptVisible}
                    hide={this.togglePrompt}
                    description="You are about to delete this Progress Note."
                    onAccept={this.handleRemove}
                />
            </Fragment>
        );
    }
}


ProgressNoteMobileList.defaultProps = {
    counter: undefined,
};

ProgressNoteMobileList.propTypes = {
    fetch: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    remove: PropTypes.func.isRequired,
    removeNoteCollectionItem: PropTypes.func.isRequired,
    toggleDetails: PropTypes.func.isRequired,
    counter: PropTypes.number,
    permissions: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
};

ProgressNoteMobileList.defaultParams = {
    includes: 'author.user_profile,attachments',
    order_by: 'created_at,desc',
    state: entityState.active,
};

function mapStateToProps(state, ownProps) {
    const listBranch = progressNoteCollection.selector(state);

    return {
        counter: listBranch.counter,
        items: listBranch.items,
        userId: selectUserId(state),
        permissions: notePermissionSelector(state, ownProps.goalId)
    };
}

const mapDispatchToProps = {
    fetch: progressNoteCollection.actions.request,
    fetchMore: progressNoteCollection.actions.requestMore,
    remove: progressNoteModel.actions.remove.request,
    toggleDetails: progressNoteModel.actions.toggleModal,
    removeNoteCollectionItem: progressNoteCollection.actions.remove,
};


ProgressNoteMobileList.accordionId = accordionId;

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNoteMobileList);
