import { createSelector } from 'reselect';
import { progressNoteModel } from './progress-note-models';

export const selectAttachmentsAuthorId = createSelector(
    progressNoteModel.selector,
    details => details.data?.attachments && details.data.attachments.map(attachment => attachment?.pivot?.author_id),
);

export const selectHasAttachment = createSelector(
    progressNoteModel.selector,
    details => details.data?.attachments && details.data.attachments.length > 0,
);
