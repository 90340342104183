import { get } from 'lodash';
import * as ToastNotifications from '../../../modules/notifications/toast';
import createAPI from './api';
import createActions from './actions';
import createReducer, { createSelector } from './reducer';
import createSaga from './saga';

const defaultOptions = {
    apiUrl: '/drivers',
    name: 'drivers',
};

function createDetailsFeature(options = defaultOptions) {
    const api = {
        ...createAPI(options.apiUrl, options.updateHTML, options.format),
        ...options.extendAPI
    };

    const actions = createActions(options.name);
    const selector = createSelector(options.name);
    const reducer = createReducer(
        actions,
        options.extraActions,
        options.extraInitialState
    );

    const saga = createSaga(
        actions,
        api,
        options.onUpdate,
        options.onRemove,
        options.onErrorUpdateStatus,
        options.messages,
        selector,
    );

    return {
        api,
        actions,
        reducer,
        selector,
        saga,
        name: options.name,
    };
}

const errorUpdateStatusNotification = (payload, messages) => {
    return ToastNotifications.create({
        type: ToastNotifications.types.SERVICE,
        data: {
            type: ToastNotifications.serviceTypes.ERROR,
            text: get(messages, 'error') || get(payload, ['response', 'data', 'message'], 'Bad Request'),
        }
    });
};

createDetailsFeature.errorUpdateStatusNotification = errorUpdateStatusNotification;

export default createDetailsFeature;
