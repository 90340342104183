import { set } from 'lodash';
import { constants, changePasswordModel, changePasswordSuccessModal } from './change-password-model';

// eslint-disable-next-line import/prefer-default-export
export function selectChangePasswordPersistState(state) {
    const successModalState = changePasswordSuccessModal.selectors.selectBranch(state);
    const successModalPath = changePasswordModel.getPluginPath(constants.successModalName);

    return set({}, `${successModalPath}`, successModalState);
}
