export const attributes = Object.freeze({
    label: 'label',
    type: 'type',
    relatedEntityId: 'related_entity_id',
});

export const headerTitles = [
    { title: 'Label' },
    { title: 'Data Source' },
    { title: 'Actions' }
];
