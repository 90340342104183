import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default function ModalBody({ className, children }) {
    return (
        <div
            className={classnames('modal-body', {
                [className]: className
            })}
        >
            {children}
        </div>
    );
}

ModalBody.defaultProps = {
    className: undefined,
    children: undefined
};

ModalBody.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
