import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from '../input/base';
import Icon from '../icon/component';
import './styles.css';

export default class InputPassword extends Component {
    state = {
        passwordVisible: false,
    };

    togglePasswordVisibility = (event) => {
        event.preventDefault();
        const nextVisibilityState = !this.state.passwordVisible;

        this.setState({
            passwordVisible: nextVisibilityState,
        });

        this.input.focus();
    }

    renderPostfix = ({ iconClassName }) => {
        const { passwordVisible } = this.state;

        return (
            <a
                className="input-password-postfix-button"
                href="#switch-password-visibility"
                onClick={this.togglePasswordVisibility}
            >
                <Icon
                    name={classnames({
                        'eye': !passwordVisible,
                        'eye-slash': passwordVisible,
                    })}
                    className={iconClassName}

                />
            </a>
        );
    }

    render() {
        const { passwordVisible } = this.state;

        return (
            <Input
                {...this.props}
                ref={(el) => { this.input = el; }}
                type={classnames({
                    'password': !passwordVisible,
                    'text': passwordVisible,
                })}
                postfix={this.renderPostfix}
            />
        );
    }
}

InputPassword.propTypes = {

};
