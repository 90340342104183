import _ from 'lodash';
import {
    updateFacets, resetFacets, commitFacets
} from './actions';
import request from '../../request';
import { List } from '../../shared/entities';
import { safeClone } from '../../shared/utils/object';

export class SearchListEntity extends List {
    fetch(params) { // eslint-disable-line class-methods-use-this
        return request.post('/goals/improve_search', params);
    }
}

function parseKey(key) {
    return key.split('[').map(it => it.replace(']', ''));
}

function hasUncheckedChildren(items) {
    return !!Object.keys(items).find((itemId) => {
        return !items[itemId].is_checked;
    });
}

export default new SearchListEntity({
    apiUrl: '/goals/improve_search',
    name: 'search',
    extendReducer: {
        [updateFacets]: (state, { key, checked }) => {
            const [rootKey, index] = parseKey(key);
            let facets = safeClone(state.raw.facets);

            facets = _.set(facets, key, checked);

            const isChildChanged = !!key.match('items');

            if (isChildChanged) {
                if (!checked) {
                    facets = _.set(facets, `${rootKey}[${index}][is_checked]`, false);
                }

                if (checked && !hasUncheckedChildren(_.get(facets, `${rootKey}[${index}][items]`))) {
                    facets = _.set(facets, `${rootKey}[${index}][is_checked]`, true);
                }
            } else {
                const items = _.get(facets, `${rootKey}[${index}][items]`);
                if (items) {
                    Object.keys(items).forEach((itemId) => {
                        facets = _.set(facets, `${rootKey}[${index}[items][${itemId}][is_checked]`, checked);
                    });
                }
            }

            return {
                ...state,
                raw: {
                    ...state.raw,
                    facets
                }
            };
        },
        [resetFacets]: (state) => {
            return {
                ...state,
                raw: {
                    ...state.raw,
                    facets: safeClone(state.emptyFacets || {})
                }
            };
        },
        [commitFacets]: (state, facets) => {
            return {
                ...state,
                emptyFacets: safeClone(facets)
            };
        }
    }
});
