import auth0 from 'auth0-js';
import config from '../../config';

let service = null;

export function init() {
    const authConfig = config.auth0();

    service = new auth0.WebAuth({
        domain: authConfig.domain,
        clientID: authConfig.clientId,
        responseType: 'token',
    });
}

export function authorize(params) {
    return new Promise((resolve, reject) => {
        service.authorize(params, (err, result) => {
            if (err) {
                return reject(err);
            }

            return resolve(result);
        });
    });
}

export function loginWithCredentials({ email, password, redirectUri }) {
    return new Promise((resolve, reject) => {
        const data = {
            realm: 'Username-Password-Authentication',
            username: email,
            password,
            redirectUri,
        };

        service.login(data, (err, result) => {
            if (err) {
                return reject(err);
            }

            return resolve(result);
        });
    });
}

export function loginWithUniversalPage(params) {
    return service.authorize(params);
}

export function signupWithCredentials({ email, password, redirectUri }) {
    const login = () => {
        const data = {
            email,
            password,
            redirectUri,
        };

        return loginWithCredentials(data);
    };

    const signup = new Promise((resolve, reject) => {
        const data = {
            email,
            password,
            connection: 'Username-Password-Authentication',
        };

        service.signup(data, (err, result) => {
            if (err) {
                if (err.code === 'user_exists') {
                    return resolve();
                }

                return reject(err);
            }

            return resolve(result);
        });
    });

    return signup.then(login);
}

export function parseHash(hash) {
    return new Promise((resolve, reject) => {
        service.parseHash({ hash }, (err, result) => {
            if (err) {
                return reject(err);
            }

            if (result && result.accessToken && result.idToken) {
                return resolve(result);
            }

            return reject(new Error('Invalid parseHash response'));
        });
    });
}

export function logout(returnTo) {
    const authConfig = config.auth0();

    service.logout({
        clientID: authConfig.clientId,
        returnTo,
        federated: false,
    });
}
