import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../panel';
import GridContainer from '../grid/container';
import './styles.css';

export default function PageSeparator({ children }) {
    return (
        <Panel className="page-separator">
            <GridContainer>
                {children}
            </GridContainer>
        </Panel>
    );
}

PageSeparator.titleClassName = 'page-separator-title';
PageSeparator.addButtonClassName = 'page-separator-add-button';

PageSeparator.defaultProps = {
    children: undefined,
};

PageSeparator.propTypes = {
    children: PropTypes.node,
};
