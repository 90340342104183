import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './table-view-placeholder.css';

export default function TableViewPlaceholder({ children, className }) {
    return (
        <div
            className={cx('table-view-placeholder', className)}
        >
            {children}
        </div>
    );
}

TableViewPlaceholder.defaultProps = {
    children: undefined,
    className: undefined
};

TableViewPlaceholder.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
