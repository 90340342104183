import { Quill } from 'react-quill';

class IndentAttributor extends Quill.import('parchment').Attributor.Style {
    multiplier = 40;

    add(node, value) {
        return super.add(node, `${value * this.multiplier}px`);
    }

    value(node) {
        return parseFloat(super.value(node)) / this.multiplier || undefined;
    }
}

export default IndentAttributor;
