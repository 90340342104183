/** @namespace Utils.EntityHealth */
import { get as getCustomProp } from './css-custom-properties';

/**
 * Enum for health statuses
 * @typedef {number} HealthStatus
 * @memberof Utils.EntityHealth
 * @readonly
 * @enum {HealthStatus}
 */
export const statuses = {
    green: 1,
    yellow: 2,
    danger: 3,
    neutral: 4,
};

/**
 * Enum for health statuses colors
 * @typedef {string} HealthStatusColor
 * @memberof Utils.EntityHealth
 * @readonly
 * @enum {HealthStatusColor}
 */
const colorsByStatus = {
    1: '--color-success',
    2: '--color-warning',
    3: '--color-danger',
    4: '--color-neutral',
};

/**
 * Converts keys of an object to another case recursively. Returns new object.
 * @function getColorByStatus
 * @memberof Utils.EntityHealth
 * @param {HealthStatus} status - Numeric status from Back-End
 * @returns {String} Hex color
 */
export const getColorByStatus = status => getCustomProp(colorsByStatus[status]);
