import React from 'react';
import PropTypes from 'prop-types';

export default function SubscriptionLimitMessage({ message }) {
    return (
        <div className="subscription-limit-page-message">
            <p>{message}</p>
            <p>
                <a href="mailto:admin@sc.com">Contact Support</a> to upgrade your plan
            </p>
        </div>
    );
}

SubscriptionLimitMessage.propTypes = {
    message: PropTypes.string.isRequired,
};
