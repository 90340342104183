import { Collection } from '../../../../shared/entities-v2';

const apiUrl = '/sso/providers';
const ssoProviderCollectionName = 'settings-security-sso-provider-collection';

class SettingsSecuritySsoProviderCollection extends Collection {
    // eslint-disable-next-line class-methods-use-this
    parse(response) {
        return { data: response };
    }
}

const settingsSecuritySsoProviderCollection = new SettingsSecuritySsoProviderCollection({
    apiUrl,
    name: ssoProviderCollectionName,
});

export default settingsSecuritySsoProviderCollection;
