import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomInput from './custom-input';
import config from '../../../config';

function format(value) {
    if (!value) {
        return '';
    }

    return moment(value).format(config.shortDateFormat);
}

function formatToLocal(value) {
    if (!value) {
        return '';
    }

    return moment(value).format('YYYY-MM-DD');
}

export default function NativeInputDate(props) {
    const { input } = props;

    return (
        <CustomInput
            {...props}
            {...input}
            type="date"
            data-date={format(input.value)}
            value={formatToLocal(input.value)}
        />
    );
}

NativeInputDate.defaultProps = {
    input: {},
};

NativeInputDate.propTypes = {
    input: PropTypes.object,
};
