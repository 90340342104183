import u from 'updeep';
import { formatByType } from '../../../../modules/goalmap-milestones/utils';
import { roundDecimal } from '../../../utils/math';
import colors from '../../../styles/variables';
import {
    prepareOuterSections, prepareInnerSections, getSectionColorByValue, normalizeExceedingValue
} from './utils';
import {
    defaultHeight, defaultColor, defaultPivotRadius, defaultCurrencySymbol,
} from './constants';
import baseConfig from './base';
import { commasBetweenThousands } from '../../../utils/formatters';

const sectionColors = [
    colors['color-danger'],
    colors['color-warning'],
    colors['color-success'],
];

export default function ascending({
    bounds,
    value,
    type,
    showValues = true,
    backgroundColor = defaultColor,
    height = defaultHeight,
    size,
    range,
    radius = defaultPivotRadius,
    currencySymbol = defaultCurrencySymbol,
}) {
    const lowBound = bounds[0];
    const highBound = bounds[bounds.length - 1];
    const normalizedValue = normalizeExceedingValue(lowBound, highBound, value);

    const series = [{
        name: 'Value',
        data: [{
            y: normalizedValue,
            dataLabels: {
                formatter() {
                    return formatByType(commasBetweenThousands(roundDecimal(value)), type, currencySymbol);
                },
                color: showValues
                    ? getSectionColorByValue(bounds, sectionColors, normalizedValue, range)
                    : undefined,
            }
        }],
    }];

    const tickPositions = bounds.map(roundDecimal);

    return u({
        chart: u({
            backgroundColor,
            height,
        }, baseConfig.chart),
        pane: u({
            size,
        }, baseConfig.pane),
        plotOptions: {
            gauge: {
                pivot: {
                    radius,
                }
            }
        },
        yAxis: [
            u({
                min: lowBound,
                max: highBound,
                tickPositions,
                plotBands: [
                    ...prepareOuterSections(bounds, sectionColors),
                    ...prepareInnerSections(bounds, sectionColors, normalizedValue),
                ],
            }, baseConfig.yAxis),
        ],
        series,
    }, baseConfig);
}
