import React from 'react';
import Icon from '../../icon/component';

import '../option/styles.css';
import './styles.css';

export default function SelectUserOptionEmpty() {
    return (
        <div className="select-user-option select-user-option-empty">
            <div className="user-option-empty-icon-container">
                <Icon
                    name="user-friends"
                    className="user-option-empty-icon"
                />
            </div>

            <div className="user-option-info user-option-empty-info">
                <div className="user-option-name user-option-empty-name">Unassigned</div>
            </div>
        </div>
    );
}
