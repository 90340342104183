import { noop, isArray } from 'lodash';
import apiService from '../../../request';

const RecoverySuccessType = 'gd_recovery_succeed';

const parseAuth0Log = (response) => {
    if (isArray(response)) {
        return response[1]?.type === RecoverySuccessType;
    }

    return false;
};

const getIsRecoveryUsed = (userId) => {
    return apiService
        .get(`/multi_factor_authentications/users/${userId}/logs`)
        .then(parseAuth0Log)
        .catch(noop);
};

export default getIsRecoveryUsed;
