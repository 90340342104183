import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { unionWith, isEqual } from 'lodash';
import classnames from 'classnames';
import LoadingIndicator from '../../../../shared/components/loading-indicator/component';
import FilePickerItem from './file-picker-item/attachment-file-picker-item';
import './attachment-file-picker.css';

const keys = {
    Shift: 'shift',
};

function isSelectedItem(arr, id) {
    return !!arr.find(item => item.id === id);
}

function select(selected, ids) {
    return unionWith(selected, ids, isEqual);
}

function deselect(selected, ids) {
    return selected.filter(item => !isSelectedItem(ids, item.id));
}

function getRange(arr, start, end) {
    const clone = [...arr];
    let range;
    if (start < end) {
        range = clone.slice(start, end + 1);
    } else {
        range = clone.slice(end, start + 1);
    }

    return range;
}

const actions = { select, deselect };

export default class AttachmentFilePicker extends Component {
    state = {
        modifierTargetId: undefined,
        modifiers: {
            shift: false,
        }
    }

    componentDidMount = () => {
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    updateModifier = (key, value) => {
        this.setState({
            modifiers: {
                [key]: value,
            },
        });
    }

    handleKeyDown = (event) => {
        const { key } = event;

        if (key in keys) {
            this.updateModifier(keys[key], true);
        }
    }

    handleKeyUp = (event) => {
        const { key } = event;

        if (key in keys) {
            this.updateModifier(keys[key], false);
        }
    }

    handleSelect = (item) => {
        const { selected } = this.props;


        this.setState({
            modifierTargetId: item.id,
        });

        this.triggerChange([...selected, item]);
    }

    handleRemoveSelect = (item) => {
        const { selected } = this.props;
        const index = selected.findIndex(it => (it.id === item.id));
        const result = [...selected];
        result.splice(index, 1);

        this.setState({
            modifierTargetId: item.id,
        });

        this.triggerChange(result);
    }

    handleRangeSelect = (item) => {
        const { items, selected } = this.props;
        const { modifierTargetId } = this.state;
        const start = items.findIndex(it => it.id === modifierTargetId);
        const end = items.findIndex(it => it.id === item.id);
        const range = getRange(items, start, end);
        const action = isSelectedItem(selected, item.id)
            ? actions.deselect
            : actions.select;

        this.setState({
            modifierTargetId: item.id,
        });

        this.triggerChange(action(selected, range));
    }

    handleItemClick = (item) => {
        const { modifierTargetId, modifiers } = this.state;
        const { selected } = this.props;
        const isSelected = isSelectedItem(selected, item.id);

        if (modifiers.shift && modifierTargetId) {
            return this.handleRangeSelect(item);
        }

        if (isSelected) {
            return this.handleRemoveSelect(item);
        }

        return this.handleSelect(item);
    }

    triggerChange = (selected) => {
        const { onSelectionChange } = this.props;

        onSelectionChange(selected);
    }

    render() {
        const {
            selected, items, onDirSelect, fetching, fetchingMore
        } = this.props;
        const { modifierTargetId } = this.state;

        if (fetching) {
            return <LoadingIndicator centered />;
        }

        if (!fetching && !items.length) {
            return (
                <span>No Results</span>
            );
        }

        return (
            <div>
                {items.map((item) => {
                    const isSelected = isSelectedItem(selected, item.id);

                    return (
                        <FilePickerItem
                            {...item}
                            selected={isSelected}
                            title={item.name}
                            icon={classnames({
                                [FilePickerItem.icons[FilePickerItem.types.dir]]: item.is_folder,
                                [FilePickerItem.icons[FilePickerItem.types.file]]: !item.is_folder,
                            })}
                            target={item.id === modifierTargetId}
                            onClick={() => {
                                if (item.is_folder) {
                                    return onDirSelect(item);
                                }

                                return this.handleItemClick(item);
                            }}
                        />
                    );
                })}

                {fetchingMore && (<LoadingIndicator centered size="small" />)}
            </div>
        );
    }
}

AttachmentFilePicker.defaultProps = {
    items: [],
    onSelectionChange() { },
    fetching: false,
    selected: [],
    fetchingMore: false,
};

AttachmentFilePicker.propTypes = {
    items: PropTypes.array,
    fetching: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    onDirSelect: PropTypes.func.isRequired,
    selected: PropTypes.array,
    fetchingMore: PropTypes.bool,
};
