import { Collection } from '../../../shared/entities-v2';

const apiUrl = '/integrations/type';

const integrationPlanCollection = new Collection({
    getApiUrl(params) {
        return `${apiUrl}/${params.slug}`;
    },
    name: 'settings-integrations-goals',
    format(params) {
        delete params.slug;
        return params;
    }
});

export default integrationPlanCollection;
