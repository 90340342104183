import { concat, flow } from 'lodash';
import { typeCodes } from '../../../shared/utils/entity-type';
import { states } from '../../goalmap-list/config';
import { unassignedPlan } from '../constants';

const mapPlans = plans => plans.map((plan, id) => ({
    entity_id: plan.id, entity_type: 8, plan_color_index: id, plan_id: plan.id
}));
export const filterPlans = plans => plans.filter(plan => plan.state === states.active);
const addUnassigned = plans => concat(plans, [{
    entity_id: unassignedPlan.id,
    entity_type: typeCodes.plan,
    plan_color_index: null,
    plan_id: unassignedPlan.id,
}]);
export const preparePlansForFilters = flow([filterPlans, mapPlans, addUnassigned]);
