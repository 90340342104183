import React from 'react';
import { StickyContext } from './sticky-context';
import Sticky from './sticky';
import { mergeNonUndefinedProps } from '../../utils/object';

export default function StickyWithContext(props) {
    return (
        <StickyContext.Consumer>
            {contextProps => (
                <React.Fragment>
                    <Sticky {...mergeNonUndefinedProps(contextProps, props)} />
                </React.Fragment>
            )}
        </StickyContext.Consumer>
    );
}
