/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isEqual, debounce } from 'lodash';
import Input from '../../../shared/components/input/base';
import InputContainer from '../../../shared/components/input/container';
import Text from '../../../shared/components/text';
import { formatNumber } from '../../../shared/utils/formatters';
import { get as getCustomProperty } from '../../utils/css-custom-properties';
import { cssVariables } from '../../styles/constants';
import Handle from './handle';
import {
    computeFieldPositions, computeSegmentWidths, computeValueWidth
} from './positions';
import checkValidity from './validation';
import getOrder from './get-order';
import { config, modes, strategies } from './constants';
import { getActualValueCapped, getActualValueOffsetX } from './utils';

import './styles.css';

const postfix = ' %';

const transitionDurationTime = () => parseFloat(getCustomProperty(cssVariables.transitionDuration)) * 1000;

function formatValue(value) {
    return value.replace(postfix, '');
}

function parseValue(value) {
    if (Number.isNaN(value)) {
        return value;
    }

    const normalizedValue = Number(value);

    if (Number.isNaN(normalizedValue)) {
        return value;
    }

    if (Math.round(normalizedValue) !== normalizedValue) {
        return normalizedValue.toFixed(2);
    }

    return normalizedValue;
}

function parseValueInput(value) {
    return `${parseValue(value)}${postfix}`;
}

export default class ThresholdInput extends Component {
    constructor(props) {
        super(props);

        this.container = null;
        this.containerWidth = undefined;

        const readOnly = props.mode === modes.readonly;
        const thresholdValue = readOnly
            ? config[props.strategy].defaultVals
            : props.thresholds || props.input.value;

        this.state = {
            mounted: false,
            values: props.input.value,
            segments: !readOnly
                ? config[props.strategy].segments
                : config[props.strategy].readonlySegments,
            segmentWidths: computeSegmentWidths(
                thresholdValue, props.strategy, readOnly
            ),
            focusedInput: false,
            containerWidth: undefined,
            validationError: undefined,
            descOrder: getOrder(props.input.value),
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.recalculateContainerWidth();
        }, 15);

        window.addEventListener('resize', this.handleWindowResize);
        this.debouncedRecalculateContainerWidth = debounce(this.recalculateContainerWidth, transitionDurationTime());
    }

    componentWillUpdate(nextProps) {
        const nextOrder = getOrder(nextProps.input.value);

        if (nextOrder !== this.state.descOrder) {
            // eslint-disable-next-line react/no-will-update-set-state
            this.setState({
                descOrder: nextOrder,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.strategy !== this.props.strategy
            || !isEqual(prevProps.input.value, this.props.input.value)
            || !isEqual(prevProps.thresholds, this.props.thresholds)
        ) {
            this.updateData();
        }
        if (prevProps.isTreeOpen !== this.props.isTreeOpen) {
            this.debouncedRecalculateContainerWidth();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    get thresholdValue() {
        const { values } = this.state;
        const { thresholds, mode, strategy } = this.props;
        const readOnly = mode === modes.readonly;

        return readOnly
            ? config[strategy].defaultVals
            : values || thresholds;
    }

    handleWindowResize = () => {
        this.recalculateContainerWidth();
    };

    updateData = () => {
        const {
            strategy, input, mode, thresholds
        } = this.props;
        const readOnly = mode === modes.readonly;
        const thresholdValue = readOnly
            ? config[strategy].defaultVals
            : thresholds || input.value;

        this.setState({
            values: input.value,
            segments: !readOnly
                ? config[strategy].segments
                : config[strategy].readonlySegments,
            segmentWidths: computeSegmentWidths(thresholdValue, strategy, readOnly)
        });
    };

    handleOrderChange = (event) => {
        const { input } = this.props;

        this.setState({
            descOrder: event.target.checked,
        });

        this.triggerChange([...input.value].reverse());
    };

    handleInputChange = (event) => {
        const { input, strategy } = this.props;
        const { index } = event.target.dataset;
        const value = formatValue(event.target.value);

        const { focusedInput, values } = this.state;

        if (focusedInput) {
            const result = [...values];
            const newSuperRes = parseInt(value, 10);

            result[index] = value;
            if (strategy === strategies.between) {
                const diff = values[index] - newSuperRes;
                const symmetryIdx = result.length - 1 - index;
                result[symmetryIdx] = Number(result[symmetryIdx]) + diff;
            }

            return this.setState({
                validationError: checkValidity(result) || undefined,
                values: result,
            });
        }

        const result = [...input.value];
        result[index] = value;

        return this.triggerChange(result);
    };

    handleInputFocus = () => {
        const { input } = this.props;
        this.setState({
            focusedInput: true,
            values: input.value,
        });
    };

    handleInputBlur = () => {
        const { values } = this.state;

        this.triggerChange(values);

        this.setState({
            focusedInput: false,
        });
    };

    handleDrag = ({ delta, index }) => {
        const result = this.recalculateValues(delta, index);
        this.triggerChange(result, false, false);
    };

    handleDragStop = ({ delta, index }) => {
        // Waiting for the end of the animation to avoid freezes
        setTimeout(() => {
            const result = this.recalculateValues(delta, index);
            this.triggerChange(result);
        }, transitionDurationTime());
    };

    recalculateValues(delta, index) {
        const { values } = this.state;
        const valueWidth = computeValueWidth(values, this.state.containerWidth);
        const result = [...values];

        const diff = Math.round(delta / valueWidth);

        result[index] = Number(result[index]) + diff;
        if (this.props.strategy === strategies.between) {
            const symmetryIdx = result.length - 1 - index;
            result[symmetryIdx] = Number(result[symmetryIdx]) - diff;
        }

        return result;
    }

    triggerChange(value, commitValue = true, resetOnInvalid = true) {
        const { strategy, input } = this.props;
        const validationError = checkValidity(value);

        if (!validationError) {
            this.setState({
                values: value,
                segmentWidths: computeSegmentWidths(value, strategy)
            }, () => {
                if (!commitValue) return;

                input.onChange(value);
            });
        } else if (resetOnInvalid) {
            this.setState({
                validationError: undefined,
                values: input.value
            });
        }
    }

    recalculateContainerWidth() {
        if (!this.container || this.state.containerWidth === this.container.offsetWidth) {
            return false;
        }

        return this.setState({
            mounted: true,
            containerWidth: this.container.offsetWidth
        });
    }

    renderThresholdLabels = () => {
        const { labels, strategy } = this.props;
        const isBetween = strategy === strategies.between;

        return (
            <div
                className={cx('threshold-input-labels', {
                    between: isBetween,
                })}
            >
                {labels.map(label => (
                    <div key={label}>{label}</div>
                ))}
            </div>
        );
    };

    render() {
        const {
            mode, input, label, disabled, strategy, className, fieldWidth, anchorItemIndex,
            actualValue, valueLabel
        } = this.props;

        const {
            mounted, descOrder, segments, focusedInput, segmentWidths, validationError,
            containerWidth, values,
        } = this.state;

        const cntWidth = containerWidth || 100;
        const handlerPositions = computeFieldPositions(
            focusedInput ? input.value : this.thresholdValue,
            cntWidth,
            config.HANDLER_WIDTH_PX,
            descOrder,
            anchorItemIndex
        );
        const fieldPositions = computeFieldPositions(
            focusedInput ? input.value : this.thresholdValue,
            cntWidth,
            fieldWidth,
            descOrder,
            anchorItemIndex
        );

        const isReadOnly = mode === modes.readonly;
        const isBetweenTh = strategy === strategies.between;

        const halfFieldWidth = fieldWidth / 2;
        const halfFieldWidthPx = `${halfFieldWidth}px`;
        const gridStep = computeValueWidth(values, cntWidth);
        const handlerIndexes = config[strategy].handlerIdxs;
        const { disabledIndexes } = config[strategy];

        let actualValueCoords;

        if (actualValue !== undefined) {
            const cappedValue = getActualValueCapped(actualValue, input.value);
            const safeUnitTresholds = input.value;
            actualValueCoords = getActualValueOffsetX(cntWidth, safeUnitTresholds, cappedValue) - (config.HANDLER_WIDTH_PX / 2);
        }

        return (
            <InputContainer
                className={cx('threshold-input-container-new', {
                    [className]: !!className
                })}
                label={label}
                value={values}
            >
                {
                    disabled
                        ? <div className="threshold-input-overlay" />
                        : null
                }

                <div className="threshold-input-wrap">
                    {!isReadOnly && this.renderThresholdLabels()}

                    <div
                        className={cx('threshold-input-new', {
                            readonly: isReadOnly,
                        })}
                        style={{
                            paddingLeft: halfFieldWidthPx,
                            paddingRight: halfFieldWidthPx,
                        }}
                    >

                        <div
                            className={cx('threshold-input-bumpers',
                                {
                                    'between': isBetweenTh,
                                })}
                        />

                        <div
                            className={cx('threshold-input-bar', {
                                readonly: isReadOnly,
                            })}
                            ref={(el) => { this.container = el; }}
                        >
                            {
                                mounted
                                    ? (
                                        segments.map(({ color, idx }, index) => (
                                            <div
                                                key={idx}
                                                className="threshold-input-bar-segment"
                                                style={{
                                                    width: `${segmentWidths[index]}%`,
                                                    backgroundColor: color,
                                                }}
                                            />
                                        ))
                                    )
                                    : null
                            }
                        </div>
                        {
                            (mounted)
                                ? (
                                    <span>
                                        <div
                                            className="threshold-input-handlers"
                                            style={{
                                                top: halfFieldWidthPx,
                                                left: halfFieldWidthPx,
                                                right: halfFieldWidthPx,
                                                bottom: halfFieldWidthPx,
                                            }}
                                        >
                                            {!isReadOnly && handlerIndexes.map((el) => {
                                                const handlerDisabled = disabledIndexes.includes(el);

                                                return (
                                                    <Handle
                                                        key={el}
                                                        handleDrag={this.handleDrag}
                                                        handleDragStop={this.handleDragStop}
                                                        index={el}
                                                        disabled={handlerDisabled || isReadOnly}
                                                        grid={[
                                                            gridStep,
                                                            gridStep
                                                        ]}
                                                        style={{
                                                            width: `${config.HANDLER_WIDTH_PX}px`,
                                                            left: `${handlerPositions[el] - (config.HANDLER_WIDTH_PX / 2)}px`,
                                                            top: '-8px',
                                                            bottom: '-8px',
                                                        }}
                                                    />
                                                );
                                            })}
                                            {isReadOnly && actualValue !== undefined && (
                                                <Handle
                                                    className="actual-value"
                                                    key="actualValue"
                                                    handleDrag={this.handleDrag}
                                                    handleDragStop={this.handleDragStop}
                                                    index="actualValue"
                                                    disabled
                                                    grid={[
                                                        gridStep,
                                                        gridStep
                                                    ]}
                                                    style={{
                                                        width: `${config.HANDLER_WIDTH_PX * 0.75}px`,
                                                        left: `${actualValueCoords}px`,
                                                        backgroundColor: 'var(--color-accent)',
                                                        top: '-3px',
                                                        bottom: '-3px',
                                                    }}
                                                />
                                            )
                                            }
                                        </div>

                                        <div
                                            className="threshold-input-fields"
                                            style={{
                                                left: halfFieldWidthPx,
                                                right: halfFieldWidthPx,
                                            }}
                                        >
                                            {values.map((value, index) => {
                                                const disabledIndex = disabledIndexes.includes(index);

                                                if (!handlerIndexes.includes(index)) {
                                                    return null;
                                                }

                                                const width = `${fieldWidth}px`;
                                                const left = `${fieldPositions[index] - halfFieldWidth}px`;

                                                return (
                                                    <div
                                                        className={cx('threshold-input-field', {
                                                            'threshold-input-field-centered': isReadOnly,
                                                            'dim': (!isReadOnly && disabledIndex) || (isReadOnly && actualValue !== undefined), // blockedCenterInput && isReadOnly
                                                        })}
                                                        // eslint-disable-next-line max-len
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={index}
                                                        style={{
                                                            width,
                                                            'bottom': isReadOnly ? '-12px' : '-8px',
                                                            left,
                                                        }}
                                                    >
                                                        {
                                                            isReadOnly
                                                                ? (
                                                                    <span className="threshold-input-field-value">
                                                                        {formatNumber(value)}{!!valueLabel && valueLabel}
                                                                    </span>
                                                                )
                                                                : (
                                                                    <Input
                                                                        type="text"
                                                                        name={`treshold-input-${index}`}
                                                                        input={{
                                                                            value: parseValueInput(value),
                                                                            onChange: this.handleInputChange,
                                                                            onFocus: this.handleInputFocus,
                                                                            onBlur: this.handleInputBlur
                                                                        }}
                                                                        disabled={disabledIndex}
                                                                        data-index={index}
                                                                    />
                                                                )
                                                        }
                                                    </div>
                                                );
                                            })}
                                            {isReadOnly && actualValue !== undefined && (
                                                <div
                                                    className="threshold-input-field threshold-input-field-centered"
                                                    // eslint-disable-next-line max-len
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key="actualValue"
                                                    style={{
                                                        'width': `${fieldWidth}px`,
                                                        'top': isReadOnly ? '-12px' : '-8px',
                                                        'left': `${actualValueCoords - 0.85 * halfFieldWidth}px`,
                                                    }}
                                                >
                                                    <span className="threshold-input-field-value">
                                                        {formatNumber(actualValue)}{!!valueLabel && valueLabel}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </span>
                                )
                                : null
                        }
                    </div>
                </div>
                {
                    validationError
                        ? (
                            <Text
                                styleType="danger"
                                className="threshold-input-validation-error"
                            >
                                {validationError}
                            </Text>
                        )
                        : null
                }

            </InputContainer>
        );
    }
}

ThresholdInput.defaultProps = {
    label: undefined,
    disabled: false,
    mode: modes.edit,
    strategy: strategies.asc,
    labels: [],
    anchorItemIndex: 3,
    thresholds: undefined,
    className: undefined,
    fieldWidth: config.FIELD_WIDTH_PX,
    actualValue: undefined,
    isTreeOpen: false,
    valueLabel: undefined,
};

ThresholdInput.propTypes = {
    /**
        Value is array:
        [
            Number - low border of red section,
            Number - red and yellow intersection,
            Number - yellow and green intersection,
            Number - high border of green section,
        ]
    */
    input: PropTypes.shape({
        value: PropTypes.arrayOf(
            PropTypes.number,
        ),
        onChange: PropTypes.func,
    }).isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    mode: PropTypes.string,
    strategy: PropTypes.string,
    labels: PropTypes.array,
    anchorItemIndex: PropTypes.number,
    thresholds: PropTypes.array,
    fieldWidth: PropTypes.number,
    className: PropTypes.string,
    actualValue: PropTypes.number,
    isTreeOpen: PropTypes.bool,
    valueLabel: PropTypes.string,
};

ThresholdInput.modes = modes;
ThresholdInput.strategies = strategies;
