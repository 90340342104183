import ApplicationLayout from '../modules/application/layout/application-layout';
import auth from '../modules/authentication/routes';
import { paths as authPaths } from '../modules/authentication/config';
import authenticated from './authenticated';
import servicePages from './service-pages';
import { withStickyContext } from '../shared/components/sticky/sticky-context';
import { stickyConf } from '../shared/components/sticky/config';

function handleAppEnter(nextState, replace) {
    replace(`${authPaths.login}${nextState.location.search}`);
}

export default {
    path: '/',
    component: withStickyContext(stickyConf.default)(ApplicationLayout),
    indexRoute: { onEnter: handleAppEnter },
    childRoutes: [
        ...auth,
        ...authenticated,
        ...servicePages,
    ],
};
