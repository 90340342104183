import { map } from 'lodash';
import { createSelector } from 'reselect';
import u from 'updeep';
import apiService from '../../request';
import { List, createModal, createDetails } from '../../shared/entities';
import reducer from './dashboard-reducer';

function formatAvailableList(response = {}) {
    const items = Object.keys(response).map((name) => {
        const item = response[name];

        return {
            count: item.count,
            config: u({ title: item.title }, item.config),
            title: item.title,
            id: name,
        };
    });

    return { data: items };
}

export const dashboardWidgetCollection = new List({
    name: 'widget-list',
    apiUrl: '/widgets',
    parse(response) {
        return { data: response };
    },
    extendReducer: reducer,
});

export const dashboardWidgetsOrderModel = createDetails({
    name: 'widgets-order',
    apiUrl: '/widgets',
    extendAPI: {
        update(params) {
            const positions = params.items.reduce((accum, item) => {
                accum[item.id] = {
                    column: item.column,
                    position: item.position,
                };

                return accum;
            }, {});

            return apiService.put('/widgets/orders', { widgets: positions });
        },
    }
});

export const dashboardWidgetAvailableCollection = new List({
    name: 'widgets-available',
    apiUrl: '/widgets/types',
    parse: formatAvailableList,
});

export const dashboardWidgetCreateModel = createModal({
    name: 'widget-create',
    apiUrl: '/widgets',
    onSuccess: dashboardWidgetCollection.actions.add,
    extendAPI: {
        create: data => apiService.post('/widgets', data),
    }
});

export const selectWidgetOrderHash = createSelector(
    [dashboardWidgetCollection.selector],
    state => state.items.reduce((accum, item) => {
        accum += `${item.id}-${item.column}-${item.position}-`;
        return accum;
    }, '')
);

export const selectWidgetOptions = createSelector(
    [dashboardWidgetAvailableCollection.selector, dashboardWidgetCollection.selector],
    (optionState, widgetState) => {
        const options = optionState.items;
        const widgets = widgetState.items;

        const selectedWidgetTypes = map(widgets, widget => widget.type);

        return options.reduce((accum, item) => {
            const selectedWidgetsOfType = selectedWidgetTypes.filter(type => type === item.id);

            if (item.count > -1 && selectedWidgetsOfType.length >= item.count) {
                return accum;
            }

            accum.push(item);
            return accum;
        }, []);
    }
);
