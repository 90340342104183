import { takeEvery, put } from 'redux-saga/effects';
import get from 'lodash/get';
import * as goalmapActions from '../goalmap-list/actions';
import Notification from '../../../../sc-packages/notifications';
import permissionModel from './permission-model';

function* getNewPermissionsUserCreateNewPlan() {
    yield put(permissionModel.actions.fetch.request({}, { silent: true }));
}

function* getNewPermissionsUserIsMemberNewPlan({ payload }) {
    if (get(payload, 'data.author.id', 'id') !== get(payload, 'data.user.id', 'id')) {
        yield put(permissionModel.actions.fetch.request({}, { silent: true }));
    }
}

export default function* saga() {
    yield takeEvery(
        goalmapActions.create.success,
        getNewPermissionsUserCreateNewPlan
    );
    yield takeEvery(
        Notification.actions.transport.receive,
        getNewPermissionsUserIsMemberNewPlan
    );
}
