import { eventChannel } from 'redux-saga';

export const events = Object.freeze({
    master: '!sys.master',
    channelsRefresh: '!sys.channels.refresh',
    notificationRecieve: 'notification.recieve',
    notificationRemove: 'notification.remove',
});

export const channelName = 'sync-notifications';

export const actionTypes = Object.freeze({
    master: 'tab-change-master',
    secondary: 'tab-change-slave',
});

export default function initSync(live) {
    return eventChannel((emitter) => {
        const handleMaster = (data) => {
            const nodeId = data.node_id;
            const masterId = data.master_id;

            if (masterId === nodeId) {
                emitter({
                    type: actionTypes.master,
                });
            } else {
                emitter({
                    type: actionTypes.secondary,
                });
            }
        };

        const handleNotifications = (data) => {
            emitter({
                type: data.type,
                payload: data.payload,
            });
        };

        live.on(events.master, handleMaster);
        live.on(channelName, handleNotifications);

        return () => {
            live.off(events.master, handleMaster);
            live.off(channelName, handleNotifications);
        };
    });
}
