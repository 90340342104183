/* eslint-disable class-methods-use-this */
import u from 'updeep';
import { createAction } from 'redux-act';
import { remove } from 'lodash';
import request from '../../request';
import formatUserData from '../../shared/utils/formatters';
import { Collection, Model } from '../../shared/entities-v2';
import { ModalPlugin } from '../../shared/entities-v2/plugins';
import createAsyncActions from '../../shared/entities-v2/base/async-actions';
import formatRequestPayload from '../../shared/entities-v2/utils/format-request-payload';
import { asyncTypes as requestTypes } from '../../shared/entities-v2/model/config';
import { handleCreateRequest, } from '../../shared/entities-v2/model/reducer';
import { formatUserAssignmentsToAPI, isDraftPlanId } from './form/assignments/settings-user-assignments-mappers';
import * as Modules from './modules';
import { updateUserStatus } from './constants';

const apiUrl = '/users';
const uploadUsersApiUrl = '/users/csv';
const nameCollection = 'settings-user-list';
const nameModel = 'settings-users';

function formatCSVUploadUsers(users = []) {
    return users.reduce((accum, user) => {
        const successfull = user.status === 1;

        if (successfull) {
            accum.push(
                u({ user_profile: user, }, user)
            );
        }

        return accum;
    }, []);
}

const updateItemLocal = createAction(`${nameCollection}/item-update-local`);

const isChangeStatusItem = (type, id) => (type === updateUserStatus && id);
const isRemoveItem = (type, id) => (type === updateUserStatus && !id);

const updateItemLocalReducer = (state, { params, response }) => {
    const updateItems = items => items.map(item => (item.id === response.id ? response : item));

    if (isChangeStatusItem(params?.type, response?.id)) {
        return { ...state, items: updateItems(state.items), updatingItemId: undefined };
    }
    if (isRemoveItem(params?.type, response?.id)) {
        const items = state.items.filter(item => item.id !== params.id);
        return { ...state, items, updatingItemId: undefined };
    }
    // update item
    return { ...state, items: updateItems(state.items), updatingItemId: undefined };
};

export const settingsUserCollection = new Collection({
    apiUrl,
    name: nameCollection,
    additionalArtifacts: {
        actions: {
            updateItemLocal,
        },
        reducers: {
            [updateItemLocal]: updateItemLocalReducer,
        }
    }
});

class SettingsUsersModel extends Model {
    cachedUserAssignments = null

    getUserFormattedData = (data, type) => {
        const myParams = formatUserData(data);
        this.cachedUserAssignments = [...myParams.goals];
        myParams.account_role = myParams.user_account_role.id ? myParams.user_account_role.id : myParams.user_account_role;
        remove(myParams.goals, isDraftPlanId);
        myParams.goals = formatUserAssignmentsToAPI(myParams.goals);

        if (type === requestTypes.update && myParams.user_profile.image && myParams.user_profile.image.length === 0) {
            delete myParams.user_profile.image;
        }

        delete myParams.goal_roles;
        delete myParams.user_account_role;

        return Modules.buildFormData(myParams);
    }

    format(data, type) {
        if (type === requestTypes.create || type === requestTypes.update) {
            return this.getUserFormattedData(data, type);
        }

        return { ...data };
    }

    fetch({ id, ...rest }, options) {
        return request
            .get(`${apiUrl}/${id}`, { params: rest, ...options })
            .then(this.parse, this.parseError);
    }

    update(data, options) {
        const formattedData = this.format(data, requestTypes.update);
        const user_id = formattedData.get('id');
        return request
            .post(`${apiUrl}/update/${user_id}`, formattedData, options)
            .then(this.parse, this.parseError);
    }

    create(data, options) {
        const url = this.getApiUrlCreate(data);
        const formattedData = this.format(data, requestTypes.create);

        return request
            .post(url, formattedData, options)
            .then((response) => {
                const responseWithCache = u({ goals: this.cachedUserAssignments }, response);
                return this.parse(responseWithCache, requestTypes.create);
            })
            .catch(this.parseError);
    }
}

const sendInvitation = createAsyncActions(`${nameModel}/send-invitation`, formatRequestPayload);
const updateStatus = createAsyncActions(`${nameModel}/update-status`, formatRequestPayload);
const bulkUpload = createAsyncActions(`${nameModel}/bulk-upload`, formatRequestPayload);
const sendInvitationReducer = state => ({ ...state, });
const updateStatusReducer = state => ({ ...state, });
const bulkUploadReducer = state => ({ ...state, });

export const settingsUsersModel = new SettingsUsersModel({
    getApiUrl: () => apiUrl,
    name: nameModel,
    additionalArtifacts: {
        [Model.additionalArtifacts.actions]: {
            sendInvitation,
            updateStatus,
            bulkUpload,
        },
        [Model.additionalArtifacts.reducers]: {
            [sendInvitation.success]: sendInvitationReducer,
            [updateStatus.success]: updateStatusReducer,
            [bulkUpload.request]: handleCreateRequest,
            [bulkUpload.success]: bulkUploadReducer,
        },
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onCreateSuccess]: settingsUserCollection.actions.push,
            [Model.events.onUpdateSuccess]: settingsUserCollection.actions.updateItemLocal,
            [updateStatus.success]: settingsUserCollection.actions.updateItemLocal,
            [bulkUpload.success]: settingsUserCollection.actions.push,
        },
        [Model.additionalArtifacts.api]: {
            sendInvitation: (data) => {
                return request
                    .patch(`${apiUrl}/${data.id}/invitation`)
                    .catch((err) => { throw err; });
            },
            updateStatus: (data) => {
                return request
                    .put(`${apiUrl}/${data.id}/state/${data.state}`)
                    .catch((err) => { throw err; });
            },
            bulkUpload: params =>
                request.post(uploadUsersApiUrl, params,
                    {
                        validateStatus(status) {
                            return status === 200 || status === 201;
                        }
                    }).then(response => ({ ...response, items: formatCSVUploadUsers(response.data) }))
        }
    },
    plugins: [
        new ModalPlugin('settings-users-create-modal'),
        new ModalPlugin('settings-users-details-modal'),
        new ModalPlugin('settings-users-bulk-upload-modal'),
    ],
});

export const settingsUsersCreateModal = settingsUsersModel
    .getPluginInstanse('settings-users-create-modal');

export const settingsUsersDetailsModal = settingsUsersModel
    .getPluginInstanse('settings-users-details-modal');

export const settingsUsersBulkUploadModal = settingsUsersModel
    .getPluginInstanse('settings-users-bulk-upload-modal');
