import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { details as strategyObjectiveDetails } from '../../../../../modules/goalmap-segments/entity';
import Permissions from '../../../../../modules/permissions';
import { CardEditable as EntityViewBody, DetailsOwnerSection } from '../../../';
import { Icon, BadgeDraft } from '../../../../components';
import { getIconByTypeCode, isArchived as isArchivedEntity, isDraftEntity } from '../../../../utils/entity';
import { typeCodes as entityTypeCodes } from '../../../../utils/entity-type';
import { createHrefByTypeCode } from '../../../../utils/compose-href';
import { getEntityTypeTitle } from '../../utils';
import { createRightDrawerEntityDetailsView } from '../right-drawer-entity-details-view';
import EntityViewHeader from '../entity-view-header';
import RightDrawerTab from '../right-drawer-tab';

import styles from '../styles.module.css';

const { actions, selector } = strategyObjectiveDetails;

const StrategyObjectiveView = ({
    data, handleCloseWorkCenterRightDrawer, callbackOnEntityChange, isActiveTab, handleToggleTab, settings, ...rest
}) => {
    const {
        change, dispatch, onUpdateEntityDetails, canEditOwner, canUpdateEntity, onUpdateEntityTags, entityDescription, quillStickyProps,
    } = rest;

    const {
        id, goal_id, owner_id, author_id, title, description, tag_name, tags, owner, progress_status, type_code,
        health_manually, strategy_map_id,
    } = data;

    const isDraft = isDraftEntity(data);
    const isArchived = isArchivedEntity(data);

    const initialValues = {
        owner_id,
        id,
        goal_id,
        title,
        tag_name,
        description,
        strategy_map_id,
        author_id,
    };

    const formName = `right-drawer-entity-view-${id}`;
    const href = createHrefByTypeCode(type_code, { goal_id, id });

    return (
        <>
            <EntityViewHeader
                dispatch={dispatch}
                change={change}
                onUpdateEntityDetails={onUpdateEntityDetails}
                onSuccessSubmit={callbackOnEntityChange}
                canUpdateEntity={canUpdateEntity}
                health_manually={health_manually}
                handleCloseWorkCenterRightDrawer={handleCloseWorkCenterRightDrawer}
                href={href}
                progress_status={progress_status}
                initialValues={initialValues}
                title={title}
                formName={formName}
                settings={settings}
            />
            <div className={classnames(styles.rightDrawerEntityViewBody, styles.rightDrawerTabWrapper)}>
                <RightDrawerTab
                    handleToggleTab={handleToggleTab}
                    isActiveTab={isActiveTab}
                    shouldRenderChevron={!isDraft && !isArchived}
                />

                <div className={classnames(styles.rightDrawerTabContent, { [styles.visible]: isActiveTab })}>
                    <EntityViewBody
                        className={styles.rightDrawerEntityContent}
                        quillStickyProps={quillStickyProps}
                        isArchivedEntity={isArchived}
                        formName={formName}
                        isDropFieldOnVersionValidCheck
                        shouldRenderHeaderTitle={false}
                        entityTypeTitle={getEntityTypeTitle(entityDescription.planSettings, entityDescription.type)}
                        onSubmit={onUpdateEntityDetails}
                        onSuccessSubmit={callbackOnEntityChange}
                        onTagsSave={onUpdateEntityTags}
                        initialValues={initialValues}
                        editable={canUpdateEntity}
                        icon={getIconByTypeCode(type_code)}
                        entityId={id}
                        goal_id={goal_id}
                        title={title}
                        tagName={tag_name}
                        enableReinitialize
                        description={description}
                        isShowMoreDescription
                        destroyOnUnmount={false}
                        tags={tags}
                        planTitle={(
                            <div
                                className={styles.rightDrawerDetailsEntityPlanTitle}
                                style={{ backgroundColor: get(entityDescription, 'planColor', '#FFF3D6') }}
                            >
                                <Icon type="regular" className={styles.detailsEntityTypeIcon} name="layer-group" />
                                {entityDescription.planTitle}
                            </div>
                        )}
                        progressStatusInput={isDraft && <BadgeDraft />}
                        headerChildren={(
                            <>
                                <DetailsOwnerSection
                                    owner={owner}
                                    goal_id={goal_id}
                                    initialValues={initialValues}
                                    onSubmit={onUpdateEntityDetails}
                                    onSuccessSubmit={callbackOnEntityChange}
                                    editable={canEditOwner}
                                    enableReinitialize
                                    isCancelEditOnVersionValidCheck
                                />
                            </>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

StrategyObjectiveView.defaultProps = {
    handleCloseWorkCenterRightDrawer() {},
    callbackOnEntityChange() {},
};

StrategyObjectiveView.propTypes = {
    data: PropTypes.object.isRequired,
    handleCloseWorkCenterRightDrawer: PropTypes.func,
    callbackOnEntityChange: PropTypes.func,
    isActiveTab: PropTypes.bool.isRequired,
    entityDescription: PropTypes.object.isRequired,
    handleToggleTab: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
};

export default createRightDrawerEntityDetailsView({
    formName: 'right-drawer-strategy-objective-details-form',
    entityType: 'segment',
    actions,
    selector,
    permissionsSection: Permissions.constants.entitySections.strategy,
    mapStateToProps(state, ownProps) {
        return {
            entityType: ownProps.entityDescription.type === entityTypeCodes.strategy ? 'segment' : 'sub_segment',
        };
    },
})(StrategyObjectiveView);
