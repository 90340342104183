import React from 'react';
import PropTypes from 'prop-types';
import { DateFromNow } from '../../../shared/components';
import config from '../../../config';

export default function NotificationContent({
    component,
    full_name,
    message,
    created_at,
    ...rest
}) {
    const Component = component;
    return (
        <Component className="notifications-item-message" {...rest}>
            <div className="notifications-item-full-name">{full_name}</div>
            <div className="notifications-item-message-text">{message}</div>
            <div className="notifications-item-message-date">
                <DateFromNow
                    date={created_at}
                    format={`${config.shortDateFormat} ${config.timeFormat}`}
                    utcOffset
                />
            </div>
        </Component>
    );
}

NotificationContent.defaultProps = {
    component: 'div',
};

NotificationContent.propTypes = {
    full_name: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]),
};
