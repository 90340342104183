import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as api from './api';

function* getUser({ payload }) {
    try {
        const response = yield call(api.getProfile, payload);
        yield put({
            type: actions.getProfileSuccess.getType(),
            payload: response
        });
    } catch (err) {
        yield put({
            type: actions.getProfileError.getType(),
            payload: err
        });
    }
}

export function* userSaga() {
    yield takeEvery(actions.getProfile.getType(), getUser);
}
