import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Value } from 'react-select';

import './styles.css';

export default function MemberValue(props) {
    return (
        <span className={cx('member-value', { 'member-value-alert': props.isDanger })}>
            <Value {...props}>
                {props.children}
            </Value>
        </span>
    );
}

MemberValue.defaultProps = {
    isDanger: false
};

MemberValue.propTypes = {
    children: PropTypes.node.isRequired,
    isDanger: PropTypes.bool
};
