import { takeEvery, put, select } from 'redux-saga/effects';
import { registerSaga } from '../../../shared/entities/connector';
import { getCurrentGoal } from '../../../modules/goalmap-list/reducer';
import { remove as removePlanFromList } from '../../../modules/goalmap-list/actions';
import { recentPlansModel } from './entity';

function* localDeleteRecentPlansSaga() {
    const goal = yield select(getCurrentGoal);
    yield put({
        type: recentPlansModel.actions.localDeleteRecentPlansAction.getType(),
        payload: { id: goal.id, }
    });
}

function* saga() {
    yield takeEvery(removePlanFromList.success, localDeleteRecentPlansSaga);
}

// imported in ../container
registerSaga({
    name: 'recent-plans-additional-saga',
    saga,
});
