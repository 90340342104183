import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Text } from '../../../../shared/components';
import mapStateToProps, { propTypes } from '../../contact-sales-mapper';
import createMailtoHref from '../../create-mailto';
import './subscription-contact-sales-message.css';

function ContactSalesMessage({
    className,
    children,
    text,
    onClick,
    contactSalesVisible,
    user,
    account,
    centered
}) {
    return (
        <span
            className={classnames('contact-sales-message', {
                [className]: !!className,
                'centered': centered
            })}
        >
            {children && (
                children
            )}
            {text && (
                <Fragment>
                    <div>
                        <Text styleType="muted" size="small">{text}</Text>
                    </div>
                </Fragment>
            )}
            {contactSalesVisible && (
                <Text styleType="muted" size="small">
                    <a
                        href={createMailtoHref({ user, account })}
                        onClick={onClick}
                    >
                        Contact Support
                    </a>{' '}
                    to learn more
                </Text>
            )}
        </span>
    );
}

ContactSalesMessage.defaultProps = {
    className: undefined,
    contactSalesVisible: true,
    children: undefined,
    centered: false,
    onClick() {}
};

ContactSalesMessage.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    contactSalesVisible: PropTypes.bool,
    user: propTypes.user.isRequired,
    account: propTypes.account.isRequired,
    children: PropTypes.node,
    centered: PropTypes.bool
};

ContactSalesMessage.classNames = {
    withHeaderStyles: 'header-styles',
};

export default connect(mapStateToProps)(ContactSalesMessage);
