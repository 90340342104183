import React from 'react';
import PropTypes from 'prop-types';
import { createUltimatePagination } from 'react-ultimate-pagination';
import './styles.css';

const Page = ({
    isActive,
    onClick,
    disabled,
    value
}) => {
    return (
        <button
            className={`pagination-button page ${isActive ? 'active' : ''}`}
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <span className="pagination-button-label">{value}</span>
        </button>
    );
};

const Ellipsis = (props) => {
    return (
        <button className="pagination-button ellipsis" type="button" onClick={props.onClick} disabled={props.disabled}>
            <span className="icon fal fa-ellipsis-h" />
        </button>
    );
};

const FirstPageLink = (props) => {
    return (
        <button className="pagination-button first-page" type="button" onClick={props.onClick} disabled={props.disabled}>
            <span className="icon fal fa-chevron-double-left" />
        </button>
    );
};

const PreviousPageLink = (props) => {
    return (
        <button className="pagination-button previous-page" type="button" onClick={props.onClick} disabled={props.disabled}>
            <span className="icon fal fa-chevron-left" />
        </button>
    );
};

const NextPageLink = (props) => {
    return (
        <button className="pagination-button next-page" type="button" onClick={props.onClick} disabled={props.disabled}>
            <span className="icon fal fa-chevron-right" />
        </button>
    );
};

const LastPageLink = (props) => {
    return (
        <button className="pagination-button last-page" type="button" onClick={props.onClick} disabled={props.disabled}>
            <span className="icon fal fa-chevron-double-right" />
        </button>
    );
};


const Wrapper = (props) => {
    return <div className="pagination">{props.children}</div>;
};

const itemTypeToComponent = {
    'PAGE': Page,
    'ELLIPSIS': Ellipsis,
    'FIRST_PAGE_LINK': FirstPageLink,
    'PREVIOUS_PAGE_LINK': PreviousPageLink,
    'NEXT_PAGE_LINK': NextPageLink,
    'LAST_PAGE_LINK': LastPageLink
};

Page.propTypes = {
    isActive: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
};

Page.defaultProps = {
    disabled: undefined,
};

Ellipsis.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

Ellipsis.defaultProps = {
    disabled: undefined,
};

FirstPageLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

FirstPageLink.defaultProps = {
    disabled: undefined,
};

PreviousPageLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

PreviousPageLink.defaultProps = {
    disabled: undefined,
};

NextPageLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

NextPageLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

NextPageLink.defaultProps = {
    disabled: undefined,
};

LastPageLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

LastPageLink.defaultProps = {
    disabled: undefined,
};

Wrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ]).isRequired
};

const UltimatePagination = createUltimatePagination({
    itemTypeToComponent,
    WrapperComponent: Wrapper
});

UltimatePagination.defaultProps = {
    boundaryPagesRange: 1,
    siblingPagesRange: 0
};

export default UltimatePagination;
