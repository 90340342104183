import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { Input, Button, Icon, } from '../../../../../shared/components';
import { Select } from '../../../../../shared/modules';
import { attributes } from '../constants';

import styles from './data-source-item.module.css';

const DataSourcesItemFields = ({
    source, kpiList, kpiListFetching, onAdd, onRemove,
}) => {
    return (
        <div className={styles.dataSourceItemContainer}>
            <div className={styles.itemElement}>
                <Field
                    component={Input}
                    name={`${source}.${attributes.label}`}
                    placeholder="Data Source Label"
                    required
                    formGroupClassName={styles.itemInputGroup}
                />
            </div>
            <div className={classnames(styles.itemElement, styles.itemKpiSelector)}>
                <Field
                    component={Select}
                    name={`${source}.${attributes.relatedEntityId}`}
                    props={{
                        placeholder: 'Select Data Source',
                        options: kpiList,
                    }}
                    searchable
                    clearable={false}
                    required
                    containerClassName={styles.itemInputGroup}
                />
            </div>
            <div className={styles.itemElement}>
                <Button
                    type="button"
                    styleType="success"
                    outline
                    disabled={kpiListFetching}
                    className={styles.itemButton}
                    onClick={onAdd}
                >
                    <Icon name="check" />
                </Button>
                <Button
                    styleType="danger"
                    outline
                    type="button"
                    className={styles.itemButton}
                    onClick={onRemove}
                >
                    <Icon name="times" />
                </Button>
            </div>
        </div>
    );
};

DataSourcesItemFields.propTypes = {
    kpiList: PropTypes.array.isRequired,
    kpiListFetching: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default DataSourcesItemFields;
