import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TableViewSortIcon from './table-view-sort-icon';

import './table-view-header-cell.css';

export default function TableViewHeaderCell({
    children, className, align, onClick, sortDirection, sortable, noBorder, colspan,
}) {
    return (
        <th
            className={cx(
                'table-view-header-cell',
                {
                    [`table-view-header-cell-align-${align}`]: align,
                    [className]: className,
                    'table-view-header-cell-clickable': !!onClick,
                    'table-view-header-cell-no-border': noBorder
                }
            )}
            onClick={onClick}
            colSpan={colspan}
        >
            <div className="table-view-header-cell-content">
                {children}
            </div>
            {(sortable) && (
                <TableViewSortIcon sortDirection={sortDirection} />
            )}
        </th>
    );
}

TableViewHeaderCell.defaultProps = {
    children: undefined,
    className: undefined,
    align: undefined,
    onClick: undefined,
    sortDirection: undefined,
    sortable: false,
    noBorder: false,
    colspan: '1',
};

TableViewHeaderCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    align: PropTypes.string,
    onClick: PropTypes.func,
    sortDirection: PropTypes.string,
    sortable: PropTypes.bool,
    noBorder: PropTypes.bool,
    colspan: PropTypes.string,
};
