import { createSelector } from 'reselect';
import { types } from '../../../shared/utils/entity-type';
import Permissions from '../../permissions';

const { selectors, constants } = Permissions;
const actionPermissionSelector = selectors.createPermissionSelector(constants.entitySections.action);
const kpiPermissionSelector = selectors.createPermissionSelector(constants.entitySections.kpi);


export function createSelectAvailableEntities() {
    return createSelector([
        actionPermissionSelector,
        kpiPermissionSelector
    ], (actionPermissions, kpiPermissions) => (
        {
            [types.action]: actionPermissions['can-create'],
            [types.milestone]: kpiPermissions['can-create'],
        }
    ));
}
