import React, { Component } from 'react';
import { scrollTo } from '../../utils/dom';

export default function withScrollTop(EnhancedComponent) {
    return class WithScrollTop extends Component {
        componentDidMount() {
            scrollTo(0);
        }

        render() {
            return (
                <EnhancedComponent {...this.props} />
            );
        }
    };
}
