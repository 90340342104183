import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './styles.css';

export default function Clearfix({ className, ...rest }) {
    return (
        <div
            className={classnames('clearfix', {
                [className]: className
            })}
            {...rest}
        />
    );
}

Clearfix.defaultProps = {
    className: undefined
};

Clearfix.propTypes = {
    className: PropTypes.string,
};
