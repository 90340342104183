import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button } from '../../../../shared/components';
import { wait } from '../../../../shared/utils/common';

import Permissions from '../../../permissions';
import { applicationSettingsSelectors } from '../../../application';
import { states } from '../../../goalmap-list/config';
import { selectUserId } from '../../../user/selectors';
import { entityPermissionNames } from '../../../permissions/constants';
import { selectAttachmentsAuthorId, selectHasAttachment } from '../progress-note-model-selectors';
import { progressNoteModel } from '../progress-note-models';

const isUserAttachmenstOwner = (userId, attachmentsAuthorId) => attachmentsAuthorId.every(authorId => authorId === userId);
const canDelAttachments = (canDestroyAttachment, canDestroyOwnAttachment, userId, attachmentsAuthorId) =>
    (canDestroyAttachment || (isUserAttachmenstOwner(userId, attachmentsAuthorId) && canDestroyOwnAttachment));

function ProgressNoteDetailsFormControls({
    toggle, reset, onRemove, updating, id, entityType, entityId, hasAttachments, entityState, milestoneDataPoint, milestoneFrequency,
    disableSubmit, submitting, readonly, onStartEdit, isMobile, change, form, canCreateDraft, canUpdate, canDestroy, isDraft,
    canDestroyAttachment, canDestroyOwnAttachment, userId, attachmentsAuthorId, currentStateValue, onPublish, onUpdate,
}) {
    const persistDraft = () => {
        change('state', states.draft);
        onUpdate();
        wait(0).then(form.submit);
    };

    return (
        <Fragment>
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    toggle();
                    reset();
                }}
                outline
                disabled={updating || submitting}
                mobile
            >
                Cancel
            </Button>
            {(canDestroy && !isMobile) && (
                <Button
                    styleType="danger"
                    disabled={updating || submitting}
                    onClick={(event) => {
                        event.preventDefault();
                        onRemove({
                            id,
                            entityType,
                            entityId,
                        }, milestoneDataPoint, milestoneFrequency);
                    }}
                    mobile
                >
                    Delete
                </Button>
            )}
            {(canDestroy && hasAttachments && !isDraft && !isMobile
                && canDelAttachments(canDestroyAttachment, canDestroyOwnAttachment, userId, attachmentsAuthorId))
                && (
                    <Button
                        styleType="danger"
                        disabled={updating || submitting}
                        onClick={(event) => {
                            event.preventDefault();
                            onRemove({
                                id,
                                attachments: true,
                                entityType,
                                entityId,
                            });
                        }}
                        mobile
                    >
                        Delete with attachments
                    </Button>
                )}
            {(canCreateDraft && !entityState) && (
                <Button
                    outline
                    onClick={persistDraft}
                    type="button"
                    disabled={submitting}
                    mobile
                >
                    Save as Draft
                </Button>
            )}
            {isDraft && (
                <Button
                    styleType="success"
                    type="button"
                    onClick={persistDraft}
                    disabled={disableSubmit || submitting || updating}
                    loading={submitting && currentStateValue === states.draft}
                    mobile
                >
                    Save
                </Button>
            )}
            {canUpdate && (
                readonly ? (
                    <Button
                        styleType="accent"
                        onClick={(event) => {
                            event.preventDefault();
                            onStartEdit();
                        }}
                        mobile
                    >
                        Edit
                    </Button>
                ) : (
                    <Button
                        styleType="success"
                        type="submit"
                        disabled={disableSubmit || submitting || updating}
                        loading={submitting && currentStateValue === states.active}
                        mobile
                        onClick={() => {
                            if (canCreateDraft && isDraft) {
                                onPublish();
                            } else { onUpdate(); }
                        }}
                    >
                        {(canCreateDraft && isDraft) ? 'Publish ' : 'Save'}
                    </Button>
                )
            )}
        </Fragment>
    );
}

ProgressNoteDetailsFormControls.defaultProps = {
    entityType: undefined,
    milestoneDataPoint: undefined,
    milestoneFrequency: undefined,
    readonly: false,
    updating: false,
    entityId: undefined,
    entityState: undefined,
    form: undefined,
};

ProgressNoteDetailsFormControls.propTypes = {
    toggle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    updating: PropTypes.bool,
    id: PropTypes.number.isRequired,
    hasAttachments: PropTypes.bool.isRequired,
    permissions: PropTypes.shape({
        destroy: PropTypes.bool,
        destroy_own: PropTypes.bool,
        update: PropTypes.bool,
        update_own: PropTypes.bool,
    }).isRequired,
    entityType: PropTypes.string,
    entityId: PropTypes.number,
    entityState: PropTypes.number,
    milestoneDataPoint: PropTypes.shape({
        period_start: PropTypes.string,
    }),
    milestoneFrequency: PropTypes.string,
    disableSubmit: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    readonly: PropTypes.bool,
    onStartEdit: PropTypes.func.isRequired,
    form: PropTypes.object,
    change: PropTypes.func.isRequired,
    canDestroy: PropTypes.bool.isRequired,
    canUpdate: PropTypes.bool.isRequired,
    canCreateDraft: PropTypes.bool.isRequired,
    isDraft: PropTypes.bool.isRequired,
    canDestroyAttachment: PropTypes.bool.isRequired,
    canDestroyOwnAttachment: PropTypes.bool.isRequired,
    userId: PropTypes.number.isRequired,
    attachmentsAuthorId: PropTypes.array.isRequired,
    currentStateValue: PropTypes.number.isRequired,
    onPublish: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state, { permissions, entityState }) {
    const detailsBranch = progressNoteModel.selector(state);
    const goalId = detailsBranch.data?.goal_id;
    const planAttachmentPermissions = Permissions.selectors
        .selectEntityPermissions(state, goalId, Permissions.constants.entitySections.attachment);
    const isDraft = entityState === states.draft;
    return {
        milestoneDataPoint: detailsBranch && detailsBranch.data.milestone_data_point,
        milestoneFrequency: detailsBranch
            && detailsBranch.data.entity
            && detailsBranch.data.entity.frequency,
        isMobile: applicationSettingsSelectors.isMobileSelector(state),
        canDestroy: get(permissions, Permissions.keys.canDestroy) || isDraft,
        canUpdate: get(permissions, Permissions.keys.canUpdate) || isDraft,
        canDestroyAttachment: planAttachmentPermissions?.[entityPermissionNames.canDestroy],
        canDestroyOwnAttachment: planAttachmentPermissions?.[entityPermissionNames.canDestroyOwn],
        attachmentsAuthorId: selectAttachmentsAuthorId(state),
        userId: selectUserId(state),
        hasAttachments: selectHasAttachment(state),
        isDraft,
    };
}

export default connect(mapStateToProps)(ProgressNoteDetailsFormControls);
