import PropTypes from 'prop-types';
import variables from '../../styles/variables';

const colors = {
    danger: variables['color-danger'],
    success: variables['color-success'],
    default: '#686868',
};

export const propTypes = PropTypes.oneOf(Object.values(colors).concat(''));

export default colors;
