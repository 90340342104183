import React from 'react';
import { PropTypes } from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import {
    CheckBox, Input, Icon, PageSeparator, Text
} from '../../../shared/components';
import { Select } from '../../../shared/modules';
import { filterOptions, states } from './constants';
import styles from './styles.module.css';
import { planTitle } from '../../../shared/constants/titles';

function renderPostfix({ iconClassName }) {
    return <Icon name="search" className={iconClassName} />;
}

renderPostfix.propTypes = {
    iconClassName: PropTypes.string.isRequired,
};

function parseStateFieldValue(value) {
    if (value !== states.empty) {
        return value;
    }

    return null;
}

function formatStateFieldValue(value) {
    if (!value) {
        return states.empty;
    }

    return value;
}

function SettingsIntegrationStrategicPlanList() {
    return (
        <PageSeparator>
            <div className={styles.search}>
                <h2>Configure the Integration</h2>

                <form className={styles.searchForm}>
                    <Text bold>
                        <Field
                            component={CheckBox}
                            name="archive"
                            id="integration-list-archive"
                            label="Include Archive"
                            className={styles.searchFormCheckbox}
                        />
                    </Text>

                    <div className={styles.searchFormSection}>
                        <Text
                            className={styles.searchFormLabel}
                            component="label"
                            htmlFor="integration-list-filter"
                            bold
                        >
                            Show:
                        </Text>
                        <Field
                            component={Select}
                            name="filter"
                            id="integration-list-filter"
                            options={filterOptions}
                            valueKey="value"
                            labelKey="label"
                            clearable={false}
                            containerClassName={styles.searchFormInputContainer}
                            parse={parseStateFieldValue}
                            format={formatStateFieldValue}
                        />
                    </div>
                    <div className={styles.searchFormSection}>
                        <Field
                            name="search"
                            component={Input}
                            postfix={renderPostfix}
                            placeholder={`Search for ${planTitle}`}
                            formGroupClassName={classnames(
                                styles.searchFormInputContainer,
                                styles.searchFormInputSearch
                            )}
                        />
                    </div>
                </form>
            </div>
        </PageSeparator>
    );
}

export default SettingsIntegrationStrategicPlanList;
