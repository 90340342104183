/** @namespace Utils.Common */
import { cloneDeepWith } from 'lodash';

/**
 * Empty function
 * @function noop
 * @memberof Utils.Common
 */
export function noop() {}

/**
 * Remove specified keys for every object and included objects
 * @function omitDeep
 * @memberof Utils.Common
 * @param {array|object} collection Input array or object
 * @param {array} excludedKeys Array of keys which will be removed
 * @return {array|object} Deeply cloned input data without specified keys
 */
export function omitDeep(collection, excludedKeys) {
    function omitFn(value) {
        if (value && typeof value === 'object') {
            excludedKeys.forEach((key) => {
                delete value[key];
            });
        }
    }

    return cloneDeepWith(collection, omitFn);
}

/**
 * Promisify setTimeout
 * @function wait
 * @memberof Utils.Common
 * @param {number} delay=0 Delay before resolving promise
 * @return {Promise} Promisified setTimeout
 */
export function wait(delay = 0) {
    return new Promise(resolve => setTimeout(resolve, delay));
}

/**
 * Promisify async action
 * @function promisifyAsyncAction
 * @memberof Utils.Common
 * @param {function} actionCreator Delay before resolving promise
 * @param {data} data Delay before resolving promise
 * @return {Promise} Promisified setTimeout
 */
export function promisifyAsyncAction(actionCreator, data) {
    return new Promise((resolve, reject) => {
        actionCreator(data, { resolve, reject });
    });
}

/**
 * Promisify function and call it with debounce
 * @function promisifyAsyncAction
 * @memberof Utils.Common
 * @param {function} fn Function which will be promisified
 * @param {number} ms Delay before resolving promise
 * @return {Promise} Promisified function
 */
export const debouncePromise = (fn, ms = 0) => {
    let timeoutId;
    const pending = [];
    return (...args) =>
        new Promise((res, rej) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                const currentPending = [...pending];
                pending.length = 0;
                Promise.resolve(fn.apply(this, args)).then(
                    (data) => {
                        currentPending.forEach(({ resolve }) => resolve(data));
                    },
                    (error) => {
                        currentPending.forEach(({ reject }) => reject(error));
                    }
                );
            }, ms);
            pending.push({ resolve: res, reject: rej });
        });
};
