const CACHE_NAME = 'ENVISION_APP_CACHE';
export const DEFAULT_TTL = 24 * (60 * (60 * 1000));

export function makeResponse(result, ttl) {
    return new Response(JSON.stringify(result), {
        headers: { timestamp: `${Date.now()}`, ttl: `${ttl}` },
    });
}

export function isValidKey(keyRes) {
    const now = Date.now();
    const timestamp = keyRes.headers.get('timestamp') || now;
    const ttl = keyRes.headers.get('ttl') || 0;
    return +ttl + +timestamp >= now;
}

export async function removeCache(url) {
    const cache = await caches.open(CACHE_NAME);
    return cache.delete(url);
}

export async function getCache(url) {
    const cache = await caches.open(CACHE_NAME);
    const response = await cache.match(url);

    if (!response?.ok || !isValidKey(response)) {
        await removeCache(url);
        return null;
    }

    return response.clone().json();
}

export async function setCache(url, response, ttl) {
    const cache = await caches.open(CACHE_NAME);
    const promise = await cache.put(url, makeResponse(response, ttl));
    return promise;
}

export async function clearCache() {
    await caches.delete(CACHE_NAME);
}
