import { sagaMiddleware } from '../../../store/configure-store';
// import * as actions from './actions';

const attachedSagas = {};

export function registerSaga({ name, saga }) {
    if (!attachedSagas[name]) {
        attachedSagas[name] = saga;
        sagaMiddleware.run(saga);
    }
}
