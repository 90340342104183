/** @namespace Utils.DOM */

import animateScrollTo from 'animated-scroll-to';

/**
 * Triggers an event on specified node
 * @function triggerEvent
 * @memberof Utils.DOM
 * @param {string} type Event name
 * @param {HTMLDOMElement} node Node on which will be triggered event
 */
export function triggerEvent(type, node) {
    let event;

    if (document.createEvent) {
        event = document.createEvent('HTMLEvents');
        event.initEvent(type, true, true);
    } else {
        event = document.createEventObject();
        event.eventType = type;
    }

    event.eventName = type;

    if (document.createEvent) {
        node.dispatchEvent(event);
    } else {
        node.fireEvent(`on${event.eventType}`, event);
    }
}

/**
 * Triggers a mouse event on specified node
 * @function triggerMouseEvent
 * @memberof Utils.DOM
 * @param {string} type Mouse event name
 * @param {HTMLDOMElement} node Node on which will be triggered mouse event
 */
export function triggerMouseEvent(type, node) {
    return node.dispatchEvent(new MouseEvent(type));
}

/**
 * Add a class to an element
 * @function addClass
 * @memberof Utils.DOM
 * @param {HTMLDOMElement} element Element to which will be added class
 * @param {string} className Added class name
 */
export function addClass(element, className) {
    element.className += ` ${className}`; // eslint-disable-line no-param-reassign
}

/**
 * Remove a class from an element
 * @function removeClass
 * @memberof Utils.DOM
 * @param {HTMLDOMElement} element Element from which will be removed class
 * @param {className} className Removed class name
 */
export function removeClass(element, className) {
    element.className = element.className.replace(className, ''); // eslint-disable-line no-param-reassign
}

const isScrollable = (...props) => {
    let scrollable = false;

    Object.keys(props).map((objKey) => {
        const p = props[objKey];
        if (p === 'scroll' || p === 'auto') {
            scrollable = true;
        }

        return null;
    });

    return scrollable;
};

/**
 * Get first parent scrollable container contained specified element
 * @function findScrollableContainer
 * @memberof Utils.DOM
 * @param {HTMLDOMElement} el Validated file
 * @return {HTMLDOMElement} Found scrollable container
 */
export const findScrollableContainer = (el) => {
    let targetElement = el;

    // eslint-disable-next-line no-constant-condition
    while (true) {
        const { overflow, overflowX, overflowY } = getComputedStyle(targetElement);
        const parts = overflow.split(' ');
        const resOverflow = parts.length === 2 ? parts[1] : overflow;
        if (isScrollable(resOverflow, overflowX, overflowY) || targetElement.nodeName === 'BODY') {
            return targetElement;
        }
        targetElement = targetElement.parentElement;
    }
};

/**
 * Get window scroll on y axis
 * @function getScrollY
 * @memberof Utils.DOM
 * @return {number} Returns window scroll on y axis
 */
export function getScrollY() {
    return window.scrollY || window.pageYOffset;
}

/**
 * Get window scroll on x axis
 * @function getScrollX
 * @memberof Utils.DOM
 * @return {number} Returns window scroll on x axis
 */
export function getScrollX() {
    return window.scrollX || window.pageXOffset;
}

/**
 * Set scroll top with animation
 * @function animatedScrollTo
 * @memberof Utils.DOM
 * @param {Array} args Scroll arguments
 */
export function animatedScrollTo(...args) {
    animateScrollTo(...args);
}

/**
 * Set document scroll top
 * @function scrollTo
 * @memberof Utils.DOM
 * @param {number} pos=0 Scrolling position
 */
export function scrollTo(pos = 0) {
    document.body.scrollTop = pos;
    document.documentElement.scrollTop = pos;
}

/**
 * Check, if the element is in viewport
 * element must be not bigger than viewport
 * @function isElementInViewport
 * @memberof Utils.DOM
 * @param {HTMLDOMElement} el element to be checked
 * @return {boolean} true if element is in viewport
 */
export function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
