import { takeEvery, put, select } from 'redux-saga/effects';
import { get } from 'lodash';
import { getStringTypeByCode, typeCodes } from '../../shared/utils/entity-type';
import { actionTypes, createHrefByTypeCode, getQueryIdentifierByTag } from '../../shared/utils/compose-href';
import { selectCurrentPathname } from '../../shared/selectors/routing';
import buildQueryString from '../../shared/utils/buildQueryString';
import { success as primaryServiceCreate } from '../../shared/modules/primary-entity-create-service/primary-entity-create-service-actions';
import { details as milestoneDetails, clone as milistoneCopy } from '../goalmap-milestones/entity';
import { details as actionDetails, copy as actionCopy } from '../goalmap-plans-actions/entity';
import { details as segmentDetails, copy as segmentCopy } from '../goalmap-segments/entity';
import { copy as strategyMapCopy } from '../goalmap-strategy-maps/entity';
import { attachmentCreateModel } from '../secondary-entities/attachment';
import { progressNoteCreateModel } from '../secondary-entities/progress-note';
import { collaboratorCreateModel } from '../secondary-entities/collaborator';
import { relatedLinkCreateModel } from '../secondary-entities/related-link';
import * as ToastNotifications from '../notifications/toast';
import { states } from '../goalmap-list/config';
import { getTitlesFromPlanById, getGoalmaps } from '../goalmap-list/selector';
import quickAddModal from '../navigation-header/quick-add/quick-add-model';

const operationTypes = {
    copy: 'copy',
    create: 'create',
};

function getNonTypedTitle(name) {
    return `${name} been successfully added`;
}

function getEntityTitle(name, operationType) {
    switch (operationType) {
        case operationTypes.copy: return `${name} has been copied`;
        case operationTypes.create: return `${name} has been successfully created`;
        default: return `${name} has been successfully created`;
    }
}

function getNonTypedEntityTitle(entity) {
    if (entity.type_code === typeCodes.progressNote) {
        return entity.state === states.active
            ? getNonTypedTitle('Progress note has')
            : getNonTypedTitle('Draft has');
    }

    const titlesMap = {
        attachment_id: 'Attachment(s) have',
        watcher_id: 'Collaborator(s) have',
        primary_link_id: 'Related link has',
        related_link_id: 'Related link has'
    };

    const targetEntityId = Object.keys(titlesMap).find(key => get(entity, [0, key]));

    return getNonTypedTitle(titlesMap[targetEntityId]);
}

function* showNotification({ payload: entity, operationType = operationTypes.create }) {
    const titles = yield select(state => getTitlesFromPlanById(state, entity.goal_id));

    let title;
    let href;

    if (entity.type_code) {
        const plan = yield select(getGoalmaps);
        let withLink = true;

        if (plan?.details.state === states.draft) {
            withLink = false;
        }

        if (entity.type_code === typeCodes.progressNote) {
            const path = yield select(selectCurrentPathname);
            const queryParams = getQueryIdentifierByTag(actionTypes.NOTE_STORE, entity);

            href = `${path}${buildQueryString(queryParams)}`;
            title = getNonTypedEntityTitle(entity);
        } else {
            const entityTitle = titles[getStringTypeByCode(entity.type_code)] || 'Entity';
            title = getEntityTitle(entityTitle, operationType);
            href = withLink ? createHrefByTypeCode(entity.type_code, entity) : undefined;
        }
    } else {
        title = getNonTypedEntityTitle(entity);
    }


    yield put(ToastNotifications.create({
        type: ToastNotifications.types.SERVICE,
        data: {
            type: ToastNotifications.serviceTypes.SUCCESS,
            text: title,
            href,
        }
    }));
}

function* showNotificationEntityChangeWatchStatus({ payload }) {
    yield put(ToastNotifications.create({
        type: ToastNotifications.types.SERVICE,
        data: {
            type: ToastNotifications.serviceTypes.SUCCESS,
            text: payload?.watcher_id
                ? 'Collaborator(s) have been successfully added'
                : 'Collaborator(s) have been successfully removed'
        }
    }));
}

function* showNotificationCopyEntity(action) {
    yield showNotification({ ...action, operationType: operationTypes.copy });
}

export default function* saga() {
    yield takeEvery(
        [
            primaryServiceCreate.getType(),
            attachmentCreateModel.actions.success.getType(),
            collaboratorCreateModel.actions.success.getType(),
            relatedLinkCreateModel.actions.success.getType(),
            progressNoteCreateModel.actions.success.getType(),
            quickAddModal.actions.success.getType(),
        ],
        showNotification
    );
    yield takeEvery(
        [
            segmentDetails.actions.setWatchStatus.success.getType(),
            actionDetails.actions.setWatchStatus.success.getType(),
            milestoneDetails.actions.setWatchStatus.success.getType(),
        ],
        showNotificationEntityChangeWatchStatus
    );
    yield takeEvery(
        [
            segmentCopy.actions.success.getType(),
            milistoneCopy.actions.success.getType(),
            actionCopy.actions.success.getType(),
            strategyMapCopy.actions.success.getType(),
        ],
        showNotificationCopyEntity,
    );
}
