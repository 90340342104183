import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applicationSettingsSelectors } from '../../../modules/application';
import { ServicePage404 } from '../../../modules/service-pages';

function mapStateToProps(state) {
    return {
        isMobile: applicationSettingsSelectors.isMobileSelector(state),
    };
}

export default function withMobileRestriction(EnhancedComponent) {
    function WithMobileRestriction({ isMobile, ...rest }) {
        if (isMobile) {
            return <ServicePage404 />;
        }

        return (
            <EnhancedComponent
                {...rest}
            />
        );
    }

    WithMobileRestriction.propTypes = {
        isMobile: PropTypes.bool.isRequired,
    };

    return connect(mapStateToProps)(WithMobileRestriction);
}
