import React from 'react';
import PropTypes from 'prop-types';
import WizardEntitiesList from '../../wizard-entity/wizard-entities-list';
import { types } from '../../../shared/utils/entity-type';
import { getEntityData } from '../../../shared/utils/entity';

export default function StrategyChildrenList({
    titles, availableEntities
}) {
    const items = [
        {
            ...getEntityData(types.sub_segment, titles),
            disabled: !availableEntities.sub_segment
        },
        {
            ...getEntityData(types.action, titles),
            disabled: !availableEntities.action
        },
        {
            ...getEntityData(types.milestone, titles),
            disabled: !availableEntities.milestone
        },
    ];

    return (
        <WizardEntitiesList items={items} />
    );
}

StrategyChildrenList.propTypes = {
    titles: PropTypes.object.isRequired,
    availableEntities: PropTypes.object.isRequired,
};
