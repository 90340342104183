import { List, createModal, createDetails } from '../../../shared/entities';
import { apiUrl } from './related-link-constants';
import parseItem from './related-link-parse-item';
import apiCreate from './related-link-api-create';

export const relatedLinkCollection = new List({
    apiUrl,
    name: 'related-links-list',
    parseItem,
});

export const relatedLinkCreateModel = createModal({
    apiUrl,
    name: 'related-links-create',
    onSuccess: relatedLinkCollection.actions.addBulk,
    extendAPI: {
        create: apiCreate
    }
});

export const relatedLinkModel = createDetails({
    apiUrl,
    name: 'related-links-details',
});
