import { ServicePage404, ServicePage405 } from '../modules/service-pages';

export default [
    {
        path: '405',
        component: ServicePage405,
    },
    {
        path: '*',
        component: ServicePage404,
    }
];
