import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../shared/components';

const DataPointModalFormControls = ({
    toggle, reset, submitting, submitDisabled,
}) => {
    return (
        <>
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    toggle();
                    reset();
                }}
                outline
                disabled={submitting}
                mobile
            >
                Cancel
            </Button>
            <Button
                styleType="success"
                type="submit"
                disabled={submitDisabled}
                loading={submitting}
                mobile
            >
                Publish
            </Button>
        </>
    );
};

DataPointModalFormControls.defaultProps = {
    submitDisabled: undefined
};

DataPointModalFormControls.propTypes = {
    toggle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitDisabled: PropTypes.bool,
};

export default DataPointModalFormControls;
