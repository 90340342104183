import { Model } from '../../../shared/entities-v2';
import { apiKeyEndPoint } from './public-api-constants';

const constants = Object.freeze({
    branchName: 'public-api-key',
});

function getApiUrl() {
    return apiKeyEndPoint;
}

const publicApiKeyModel = new Model({
    name: constants.branchName,
    getApiUrl,
});

export default publicApiKeyModel;
