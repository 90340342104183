import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridContainer, Breadcrumbs, ModuleTitle,
} from '../../../shared/components';
import ApiDocumentation from './public-api-documentation';
import PublicApiKey from './public-api-key';
import publicApiKeyModel from './public-api-key-model';

import styles from './public-api-details.module.css';


const PublicApiDetails = ({ breadcrumbs, title, }) => {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const apiKeyData = useSelector(publicApiKeyModel.selectors.getAttributes);

    useEffect(() => {
        dispatch(publicApiKeyModel.actions.fetch.request());
    }, []);

    const handleGetNewApiKey = () => {
        dispatch(publicApiKeyModel.actions.create.request({}, { onSuccess: () => setModalVisible(true) }));
    };

    return (
        <GridContainer className={styles.publicApiDetailsContainer}>
            <ModuleTitle className={styles.apiDetailsTitle}>
                {title}
            </ModuleTitle>
            <Breadcrumbs items={breadcrumbs} />
            <PublicApiKey
                apiKey={apiKeyData.key}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                handleGetNewApiKey={handleGetNewApiKey}
            />
            <ApiDocumentation />
        </GridContainer>
    );
};

PublicApiDetails.defaultProps = {
    breadcrumbs: []
};

PublicApiDetails.propTypes = {
    title: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.object,
};

export default PublicApiDetails;
