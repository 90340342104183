import { Model } from '../../../shared/entities-v2';
import { ModalPlugin } from '../../../shared/entities-v2/plugins';

export const constants = Object.freeze({
    apiUrl: '/users/password',
    name: 'profile-password-change',
    formModalName: 'profile-password-change-modal',
    successModalName: 'profile-password-change-success-modal',
});

export const changePasswordModel = new Model({
    getApiUrl() { return constants.apiUrl; },
    name: constants.name,
    plugins: [
        new ModalPlugin(constants.formModalName),
        new ModalPlugin(constants.successModalName),
    ],
});

export const changePasswordModal = changePasswordModel
    .getPluginInstanse(constants.formModalName);

export const changePasswordSuccessModal = changePasswordModel
    .getPluginInstanse(constants.successModalName);
