import getOrder from './get-order';

function capValueWithinBorders(value, borderValues) {
    const min = Math.min(...borderValues);
    const max = Math.max(...borderValues);

    if (value < min) return min;
    if (value > max) return max;

    return value;
}

export function getActualValueCapped(value, bounds) {
    const borderValues = [bounds[0], bounds[bounds.length - 1]];
    return capValueWithinBorders(value, borderValues);
}

export function getActualValueOffset(value, bounds) {
    const isDesc = getOrder(bounds);
    const lowBound = bounds[0];

    if (isDesc) return Math.abs(lowBound - Math.abs(value));
    return Math.abs(lowBound + Math.abs(value));
}

function getSectionCount(boundsLength) {
    switch (boundsLength) {
        case 7:
            return 6;
        case 4:
        default:
            return 3;
    }
}

export function getActualValueOffsetX(containerWidth, bounds, value) {
    const isDesc = getOrder(bounds);
    const sectionCount = getSectionCount(bounds.length);
    const sectionWidth = containerWidth / sectionCount;

    return [...new Array(sectionCount)].reduce((accum, section, index) => {
        const leftValue = bounds[index];
        const rightValue = bounds[index + 1];

        const isValueInRange = isDesc
            ? value < leftValue && value >= rightValue
            : value > leftValue && value <= rightValue;

        const isValueAfterRange = isDesc
            ? value < rightValue
            : value > rightValue;

        if (isValueInRange) {
            const step = sectionWidth / 100;
            const percent = Math.abs(rightValue - leftValue) / 100;
            const delta = Math.abs(value - leftValue);
            const percentOffsetX = delta / percent;
            accum += step * percentOffsetX;
        } else if (isValueAfterRange) {
            accum += sectionWidth;
        }

        return accum;
    }, 0);
}
