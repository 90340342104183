import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: undefined,
    className: undefined,
};

export default function generator(RenderComponent, defaultClassName, displayName) {
    function EnhancedComponent({ children, className, ...rest }) {
        return (
            <RenderComponent
                className={classnames(defaultClassName, {
                    [className]: !!className,
                })}
                {...rest}
            >
                {children}
            </RenderComponent>
        );
    }

    EnhancedComponent.displayName = displayName;
    EnhancedComponent.defaultProps = defaultProps;
    EnhancedComponent.propTypes = propTypes;

    return EnhancedComponent;
}
