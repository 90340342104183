/** @namespace Utils.Date */
import moment from 'moment';
import flow from 'lodash/flow';
import config from '../../config';

export const dateNow = moment();
export const unsupportedMomentFormats = ['DD/MM/YYYY'];

/**
 * Get formatted period by updating frequency
 * @function getFormattedPeriod
 * @memberof Utils.Date
 * @param {string} frequency Updating frequency
 * @param {Object} value Formatted date
 * @return {string} Period with format related from frequency
 */
export function getFormattedPeriod(frequency, value) {
    const date = moment(value);

    switch (frequency) {
        case 'weekly':
            return `${date.startOf('week').format('MMM DD')} - ${date.endOf('week').format('MMM DD')}, ${date.format('YYYY')}`;
        case 'quarterly':
            return `Q${date.format('Q YYYY')}`;
        case 'annually':
            return date.format('YYYY');
        case 'monthly':
        default:
            return date.format('MMMM, YYYY');
    }
}

/**
 * Converts entity updating frequency to moment.js frequency
 * @function getFrequencyType
 * @memberof Utils.Date
 * @param {string} frequency Entity updating frequency
 * @return {string} moment.js frequency
 */
export function getFrequencyType(frequency) {
    switch (frequency) {
        case 'weekly':
            return 'weeks';
        case 'quarterly':
            return 'quarters';
        case 'annually':
            return 'years';
        case 'monthly':
        default:
            return 'months';
    }
}

/**
 * Dates sorting function
 * @function sortDates
 * @memberof Utils.Date
 * @param {Object} a First date
 * @param {Object} b Second date
 * @return {number} Difference
 */
export function sortDates(a, b) {
    return moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD');
}

/**
 * Get a list of the days of the month that contains the specified date
 * @function getMonthDays
 * @memberof Utils.Date
 * @param {Object} date
 * @return {Array} List of days in current month
 */
export function getMonthDays(date) {
    const monthStartDate = moment(date).startOf('month');
    const monthEndDate = moment(date).endOf('month');
    const dateStart = monthStartDate.clone().startOf('week');
    const dateEnd = monthEndDate.clone().endOf('week');
    const currentDate = dateStart.clone();
    const days = [];

    while (currentDate.isBetween(dateStart, dateEnd, undefined, '[]')) {
        days.push({
            date: currentDate.clone(),
            isCurrentMonth: currentDate.month() === date.month()
        });
        currentDate.add(1, 'days');
    }

    return days;
}

/**
 * Get a list of the days of the week that contains the specified date
 * @function getWeekDays
 * @memberof Utils.Date
 * @param {Object} date Target date
 * @return {Array<Object>} List of days in current week
 */
export function getWeekDays(date) {
    const weekStartDate = moment(date).startOf('week');
    const weekEndDate = moment(date).endOf('week');
    const currentDate = weekStartDate.clone();
    const days = [];

    while (currentDate.isBetween(weekStartDate, weekEndDate, undefined, '[]')) {
        days.push({ date: currentDate.clone() });
        currentDate.add(1, 'days');
    }

    return days;
}

/**
 * Get a list of the days between specified start date and end date
 * @function getDaysFromRange
 * @memberof Utils.Date
 * @param {Object} start Start date
 * @param {Object} end End date
 * @return {Array<Object>} List of days between start date and end date
 */
export function getDaysFromRange(start, end) {
    const currentDate = moment(start);
    const days = [];

    while (currentDate.isBetween(moment(start), moment(end), undefined, '[]')) {
        days.push(currentDate.clone());
        currentDate.add(1, 'days');
    }

    return days;
}

/**
 * Replace day of week
 * @function replaceDayOfWeek
 * @memberof Utils.Date
 * @param {String} value
 * @param {String} newSubStr
 * @return {String} Value without day of week
 */
export function replaceDayOfWeek(value, newSubStr = '') {
    if (moment.isMoment(value)) return value;
    return value ? value.replace(/((Monday)|(Tuesday)|(Wednesday)|(Thursday)|(Friday)|(Saturday)|(Sunday))/, newSubStr) : value;
}

/**
 * Сhecks if the date is valid
 * @function isValidDate
 * @memberof Utils.Date
 * @param {String} value
 * @return {boolean} True if the date is valid
 */
export function isValidDate(value) {
    const valueWithoutDayOfWeek = replaceDayOfWeek(value);
    const date = moment(valueWithoutDayOfWeek);
    return date.isValid() || unsupportedMomentFormats.some(format => moment(valueWithoutDayOfWeek, format).isValid());
}

/**
 * Return api formatted date
 * @function transformUnsupportedMomentFormats
 * @memberof Utils.Date
 * @param {String} format format of date
 * @param {String} value
 * @return {string} api formatted date
 */
function transformUnsupportedMomentFormats(format, date) {
    if (moment(date, format).isValid() && unsupportedMomentFormats.includes(format) && !moment.isMoment(date)) {
        return moment(date, format).format(config.apiResponseDateFormat);
    }
    return date;
}

/**
 * Return api formatted date
 * @function getApiFormattedDate
 * @memberof Utils.Date
 * @param {String} value
 * @return {string} api formatted date
 */
export function getApiFormattedDate(value, userDateFormat) {
    const date = flow(
        replaceDayOfWeek,
        transformUnsupportedMomentFormats.bind(this, userDateFormat),
    )(value);
    return moment(date).format(config.apiResponseDateFormat);
}
