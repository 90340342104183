import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import {
    applicationSettingsModel,
    applicationSettingsReducer,
    networkStatusReducer,
    serviceWorkerReducer,
    colorThemeReducer,
} from '../modules/application';
import navDrawerRight from '../modules/navigation-drawer-right/reducer';
import treeNavigation, { name as treeNavigationName } from '../modules/plan-navigator/reducer';
import { stateBranchName as userReducerName } from '../modules/user/config';
import user from '../modules/user/reducer';
import userUISettingsModel from '../modules/user/user-ui-settings-model';
import goalMapListReducer from '../modules/goalmap-list/reducer';
import { plansListMy } from '../modules/plans-list-my/entity';
import appIntegrationCollection from '../modules/application/integrations/integrations-collection';
import Notifications from '../../../sc-packages/notifications';
import navigationGoalmapTree, { name as navigationGoalmapTreeName } from '../modules/navigation-goalmap-tree/entity';
import navigationContextReducer, { name as navigationContextName } from '../modules/navigation-context/reducer';
import {
    details as strategyMapModel,
    list as strategyMapList,
    tree as strategyMapVisualization,
    activity as strategyMapActivity,
    create as strategyMapCreate,
    update as strategyMapUpdate,
    copy as strategyMapCopy,
} from '../modules/goalmap-strategy-maps/entity';
import linkDownloadSecureReducer, { name as linkDownloadSecureName } from '../shared/modules/link-download-secure/reducer';
import {
    list as segmentList,
    details as segmentDetails,
    create as segmentCreate,
    update as segmentUpdate,
    createChildren as segmentAddChildren,
    copy as segmentCopy,
} from '../modules/goalmap-segments/entity';
import {
    list as segmentChildrenList,
    create as segmentChildrenCreate,
    createObjectiveChildren as objectiveChildrenCreate,
    details as segmentChildrenDetails
} from '../modules/goalmap-segment-children/entity';
import {
    list as planList,
    details as planDetails,
    create as planCreate,
    update as planUpdate,
    detailsWizard as planWizardDetails,
    copy as planCopy,
} from '../modules/goalmap-plans-actions/entity';
import searchCollection from '../modules/search/entity';
import { notificationsHeaderCollection } from '../modules/navigation-header/notifications/entity';
import searchHeaderCollection from '../modules/navigation-header/search/entity';
import { list as navigationHeaderList, createProgressNotes } from '../modules/navigation-header/entity';
import profileModel from '../modules/profile/entity';
import teamCollection from '../modules/teams/teams-collection';
import teamModel from '../modules/teams/team-model';
import {
    notificationModel, notificationsDesktopCollection,
} from '../modules/notifications/entity';
import notificationToastCollection from '../modules/notifications/toast/notification-toast-collection';
import { settingsIntegrationsModel } from '../modules/settings-integrations/entity';
import { settingsIntegrationsStorageCollection } from '../modules/settings-integrations/storages/entity';
import { settingsRolesCollection, settingsRolesModel, } from '../modules/settings-roles/entity';
import {
    reducer as settingsRolesContainerStateReducer,
    config as settingsRolesContainerStateConfig,
} from '../modules/settings-roles/container-state';
import { settingsUserCollection, settingsUsersModel, } from '../modules/settings-users/entity';
import { settingsAccountDemoDataCollection, settingsAccountDemoDataModel } from '../modules/settings-account/demo-data';
import {
    csfRelatedLinkCollection, csfRelatedLinkCreateModel, csfRelatedLinkModel
} from '../modules/primary-entities/critical-success-factor/csf-related-link';
import {
    csfCollection, csfCreateModel, csfModel, csfCopyModel
} from '../modules/primary-entities/critical-success-factor';
import {
    list as entitiesMoveList,
    move as entityMoveDetails,
} from '../shared/modules/with-move-entity/entity';
import {
    details as goalmapSettingsNotificationsDetails,
} from '../modules/goalmap-settings-notifications/entity';
import { stateBranchName as authReducerName } from '../modules/authentication/config';
import authReducer from '../modules/authentication/reducer';
import loginModel from '../modules/authentication/login/login-model';
import socialMediaCollection from '../modules/authentication/social-media/social-media-collection';
import signupModel from '../modules/authentication/signup/signup-model';
import inviteTokenModel from '../modules/authentication/signup/invite-token-model';
import passwordPolicyCollection from '../modules/authentication/components/password-policy/password-policy-collection';
import resetPasswordRequestModel from '../modules/authentication/reset-password/reset-password-request-model';
import resetPasswordModel from '../modules/authentication/reset-password/reset-password-model';
import { settingsAccountModel } from '../modules/settings-account/profile/entity';
import goalSettingsTitlesModel from '../modules/goalmap-settings-titles/goalmap-settings-titles-model';
import goalWizarVMVModel from '../modules/goalmap-wizard/steps/vision-mission-values/vmv-info-model';
import quickAddModel from '../modules/navigation-header/quick-add/quick-add-model';
import integrationCommunicationModel from '../modules/settings-integrations/communication-details/communication-model';
import goalMilestoneWidgetModel from '../modules/goalmap-milestones/widgets/widgets-model';
import {
    list as archivedGoalMapsList,
    details as archivedGoalMapsDetails
} from '../modules/archived-goal-maps/entity';
import {
    attachmentCollection,
    attachmentModel,
    attachmentCreateModel,
    attachmentStorageCollection,
} from '../modules/secondary-entities/attachment';
import {
    progressNoteCollection,
    progressNoteCreateModel,
    progressNoteModel,
} from '../modules/secondary-entities/progress-note';
import { historyCollection } from '../modules/secondary-entities/history';
import {
    collaboratorCollection,
    collaboratorCreateModel,
    collaboratorModel,
} from '../modules/secondary-entities/collaborator';
import {
    relatedLinkCollection,
    relatedLinkCreateModel,
    relatedLinkModel,
    csfConnectionCollection,
    csfConnectionCreateModel,
    csfConnectionModel,
} from '../modules/secondary-entities/related-link';
import {
    list as profilePreferencesIntegrationsList,
    details as profilePreferencesIntegrationsDetails,
} from '../modules/profile-preferences/integrations/entity';
import {
    dashboardWidgetCollection,
    dashboardWidgetCreateModel,
    dashboardWidgetsOrderModel,
    dashboardWidgetAvailableCollection,
    dashboardWidgetMyWorkCollection,
    dashboardWidgetRemindersCollection,
    dashboardWidgetRemindersModel,
    dashboardWidgetDraftsCollection,
    dashboardWidgetActivityStreamCollection,
    dashboardWidgetActivityStreamModel,
} from '../modules/dashboard';
import {
    create as milestoneDataPointCreate,
    details as milestoneDataPointDetails,
    list as milestoneDataPointList,
    createOnMobile as milestoneDataPointCreateMobile,
} from '../modules/goalmap-milestones-breakdown/entity';
import {
    create as goalmapMilestonesCreate,
    clone as goalmapMilestonesClone,
    details as goalmapMilestonesDetails,
    list as goalmapMilestonesList,
    parentMilestones as goalmapParentMilestonesList
} from '../modules/goalmap-milestones/entity';
import {
    settingsAccountExportModel, settingsAccountExportCollection,
} from '../modules/settings-account/export/entity';
import { settingsAccountSubscriptionModel } from '../modules/settings-account/subscription/entity';
// import {
//     settingsTeamsCollection, settingsTeamsModel,
// } from '../modules/settings-teams/entity';
import settingsTeamCollection from '../modules/settings/teams/settings-team-collection';
import settingsTeamModel from '../modules/settings/teams/settings-team-model';
import { subscriptionLimitCollection } from '../modules/subscription';
import userNotifications from '../modules/user-notifications/entity';
import goalMemberRolesList from '../shared/modules/goal-member-roles/entity';
import teamMemberRolesList from '../shared/modules/team-member-roles/entity';
import settingsIntegrationsGoalList from '../modules/settings-integrations/goal-list/entity';
import { changePasswordModel } from '../modules/profile/change-password/change-password-model';
import { changeEmailModel } from '../modules/profile/change-email/change-email-model';
import authChangeEmailModel from '../modules/authentication/change-email/change-email-model';
// import {
//     driverRelatedLinkCollection,
//     driverRelatedLinkCreateModel,
//     driverRelatedLinkModel,
// } from '../modules/goalmap-drivers/driver-related-links';
import milestonePeriodTargetCollection from '../modules/goalmap-milestones/settings-form/period-target-collection';
import permissionModel from '../modules/permissions/permission-model';
import wizardGoalTreeCollection from '../modules/wizard-goalmap-tree/entity';
import { spMicroWidgetsModel } from '../modules/goalmap-strategy-maps/micro-widgets/entity';
import { recentPlansModel } from '../modules/navigation-header/plans-selector/entity';
import { plansOverviewList } from '../modules/plan/modules/plan-list-overview/entity';
import memberCollection from '../modules/plan/modules/members/collection';
import workCenterCollection from '../modules/work-center/work-center-collection';
import workCenterCustomFilterCollection from '../modules/work-center/custom-filters/collection';
import workCenterCustomFilterModelUpdate, { workCenterCustomFilterModelView } from '../modules/work-center/custom-filters/model';
import milestonesListMobileList from '../modules/goalmap-milestones/list-mobile/entity';
import serviceAuthModel from '../modules/authentication/service-auth/service-auth-model';
import credentialsLoginModel from '../modules/authentication/login/credentials-login-model';
import currentFormsReducer, { name as currentFormsReducerName } from '../shared/modules/form/reducer';
import { model as workCenterTimelineModel } from '../modules/work-center/timeline/model';
import { model as workCenterGridModel } from '../modules/work-center/grid/model';
import workCenterViewType, { name as workCenterViewTypeName } from '../modules/work-center/reducer';
import PrimaryEntityCreateService from '../shared/modules/primary-entity-create-service';
import PrimaryEntityCloneService from '../shared/modules/primary-entity-clone-service';
import appLocalizationsCollection from '../modules/application/localizations/localizations-collection';
import { navigationGoalMaplist } from '../modules/navigation-goalmap-selector/entity';
import publicApiKeyModel from '../modules/settings-integrations/public-api/public-api-key-model';
import workCenterDefaultFitlerModel from '../modules/work-center/work-center-default-filter-model';
import userTeamsCollection from '../modules/user/user-teams-collection';
import settingsSecuritySsoProviderModel from '../modules/settings/security/settings-security-sso-provider/entity';
import settingsSecuritySsoProviderCollection from '../modules/settings/security/settings-security-sso-list-provider/entity';
import settingsAccountMultiFactorAuthModel from '../modules/settings/security/settings-security-multi-factor-auth/entity';
import { preferencesAccountSecurityMFADisableModal, preferencesAccountSecurityMFARecoveryCodeModal } from
    '../modules/preferences/account-security/preferences-account-security-models';

const reducers = {
    [applicationSettingsReducer.branchName]: applicationSettingsReducer,
    [applicationSettingsModel.name]: applicationSettingsModel.reducer,
    [userReducerName]: user,
    [userUISettingsModel.name]: userUISettingsModel.reducer,
    navDrawerRight,
    [treeNavigationName]: treeNavigation,
    [colorThemeReducer.path]: colorThemeReducer,
    goalmapsList: goalMapListReducer,
    [plansListMy.name]: plansListMy.reducer,
    [Notifications.name]: Notifications.reducer,
    [navigationGoalmapTreeName]: navigationGoalmapTree.reducer,
    [navigationContextName]: navigationContextReducer,
    [strategyMapModel.name]: strategyMapModel.reducer,
    [strategyMapList.name]: strategyMapList.reducer,
    [strategyMapVisualization.name]: strategyMapVisualization.reducer,
    [linkDownloadSecureName]: linkDownloadSecureReducer,
    [segmentList.name]: segmentList.reducer,
    [segmentDetails.name]: segmentDetails.reducer,
    [segmentCreate.name]: segmentCreate.reducer,
    [segmentUpdate.name]: segmentUpdate.reducer,
    [segmentCopy.name]: segmentCopy.reducer,
    [segmentAddChildren.name]: segmentAddChildren.reducer,
    [segmentChildrenList.name]: segmentChildrenList.reducer,
    [segmentChildrenCreate.name]: segmentChildrenCreate.reducer,
    [objectiveChildrenCreate.name]: objectiveChildrenCreate.reducer,
    [segmentChildrenDetails.name]: segmentChildrenDetails.reducer,
    [planList.name]: planList.reducer,
    [planDetails.name]: planDetails.reducer,
    [planCreate.name]: planCreate.reducer,
    [planUpdate.name]: planUpdate.reducer,
    [planWizardDetails.name]: planWizardDetails.reducer,
    [planCopy.name]: planCopy.reducer,
    [searchCollection.name]: searchCollection.reducer,
    [notificationsHeaderCollection.name]: notificationsHeaderCollection.reducer,
    [searchHeaderCollection.name]: searchHeaderCollection.reducer,
    [profileModel.name]: profileModel.reducer,
    [teamCollection.name]: teamCollection.reducer,
    [teamModel.name]: teamModel.reducer,
    [notificationModel.name]: notificationModel.reducer,
    [notificationsDesktopCollection.name]: notificationsDesktopCollection.reducer,
    [notificationToastCollection.name]: notificationToastCollection.reducer,
    [networkStatusReducer.path]: networkStatusReducer,
    [settingsIntegrationsModel.name]: settingsIntegrationsModel.reducer,
    [settingsIntegrationsStorageCollection.name]: settingsIntegrationsStorageCollection.reducer,
    [settingsRolesCollection.name]: settingsRolesCollection.reducer,
    [settingsRolesModel.name]: settingsRolesModel.reducer,
    [settingsRolesContainerStateConfig.name]: settingsRolesContainerStateReducer,
    [settingsUserCollection.name]: settingsUserCollection.reducer,
    [settingsAccountDemoDataCollection.name]: settingsAccountDemoDataCollection.reducer,
    [settingsAccountDemoDataModel.name]: settingsAccountDemoDataModel.reducer,
    [entitiesMoveList.name]: entitiesMoveList.reducer,
    [authReducerName]: authReducer,
    [loginModel.name]: loginModel.reducer,
    [socialMediaCollection.name]: socialMediaCollection.reducer,
    [signupModel.name]: signupModel.reducer,
    [inviteTokenModel.name]: inviteTokenModel.reducer,
    [passwordPolicyCollection.name]: passwordPolicyCollection.reducer,
    [resetPasswordRequestModel.name]: resetPasswordRequestModel.reducer,
    [resetPasswordModel.name]: resetPasswordModel.reducer,
    [settingsAccountModel.name]: settingsAccountModel.reducer,
    [goalSettingsTitlesModel.name]: goalSettingsTitlesModel.reducer,
    [goalWizarVMVModel.name]: goalWizarVMVModel.reducer,
    [quickAddModel.name]: quickAddModel.reducer,
    [integrationCommunicationModel.name]: integrationCommunicationModel.reducer,
    [goalMilestoneWidgetModel.name]: goalMilestoneWidgetModel.reducer,
    [serviceWorkerReducer.path]: serviceWorkerReducer,
    [archivedGoalMapsList.name]: archivedGoalMapsList.reducer,
    [archivedGoalMapsDetails.name]: archivedGoalMapsDetails.reducer,

    [attachmentCollection.name]: attachmentCollection.reducer,
    [attachmentModel.name]: attachmentModel.reducer,
    [attachmentCreateModel.name]: attachmentCreateModel.reducer,
    [attachmentStorageCollection.name]: attachmentStorageCollection.reducer,

    [progressNoteCollection.name]: progressNoteCollection.reducer,
    [progressNoteModel.name]: progressNoteModel.reducer,
    [progressNoteCreateModel.name]: progressNoteCreateModel.reducer,

    [historyCollection.name]: historyCollection.reducer,

    [collaboratorCollection.name]: collaboratorCollection.reducer,
    [collaboratorCreateModel.name]: collaboratorCreateModel.reducer,
    [collaboratorModel.name]: collaboratorModel.reducer,

    [relatedLinkCollection.name]: relatedLinkCollection.reducer,
    [relatedLinkCreateModel.name]: relatedLinkCreateModel.reducer,
    [relatedLinkModel.name]: relatedLinkModel.reducer,
    [csfConnectionCollection.name]: csfConnectionCollection.reducer,
    [csfConnectionCreateModel.name]: csfConnectionCreateModel.reducer,
    [csfConnectionModel.name]: csfConnectionModel.reducer,

    [dashboardWidgetCollection.name]: dashboardWidgetCollection.reducer,
    [dashboardWidgetAvailableCollection.name]: dashboardWidgetAvailableCollection.reducer,
    [dashboardWidgetsOrderModel.name]: dashboardWidgetsOrderModel.reducer,
    [dashboardWidgetCreateModel.name]: dashboardWidgetCreateModel.reducer,
    [dashboardWidgetMyWorkCollection.name]: dashboardWidgetMyWorkCollection.reducer,
    [dashboardWidgetRemindersCollection.name]: dashboardWidgetRemindersCollection.reducer,
    [dashboardWidgetRemindersModel.name]: dashboardWidgetRemindersModel.reducer,
    [dashboardWidgetDraftsCollection.name]: dashboardWidgetDraftsCollection.reducer,
    [dashboardWidgetActivityStreamCollection.name]: dashboardWidgetActivityStreamCollection.reducer,
    [dashboardWidgetActivityStreamModel.name]: dashboardWidgetActivityStreamModel.reducer,

    [profilePreferencesIntegrationsList.name]: profilePreferencesIntegrationsList.reducer,

    [csfRelatedLinkCollection.name]: csfRelatedLinkCollection.reducer,
    [csfRelatedLinkCreateModel.name]: csfRelatedLinkCreateModel.reducer,
    [csfRelatedLinkModel.name]: csfConnectionModel.reducer,

    [csfCollection.name]: csfCollection.reducer,
    [csfModel.name]: csfModel.reducer,
    [csfCreateModel.name]: csfCreateModel.reducer,
    [csfCopyModel.name]: csfCopyModel.reducer,

    [milestoneDataPointCreate.name]: milestoneDataPointCreate.reducer,
    [milestoneDataPointCreateMobile.name]: milestoneDataPointCreateMobile.reducer,
    [milestoneDataPointDetails.name]: milestoneDataPointDetails.reducer,
    [milestoneDataPointList.name]: milestoneDataPointList.reducer,
    [goalmapMilestonesCreate.name]: goalmapMilestonesCreate.reducer,
    [goalmapParentMilestonesList.name]: goalmapParentMilestonesList.reducer,
    [goalmapMilestonesClone.name]: goalmapMilestonesClone.reducer,
    [goalmapMilestonesDetails.name]: goalmapMilestonesDetails.reducer,
    [goalmapMilestonesList.name]: goalmapMilestonesList.reducer,
    [entityMoveDetails.name]: entityMoveDetails.reducer,
    [goalmapSettingsNotificationsDetails.name]: goalmapSettingsNotificationsDetails.reducer,
    [strategyMapActivity.name]: strategyMapActivity.reducer,
    [strategyMapCreate.name]: strategyMapCreate.reducer,
    [strategyMapUpdate.name]: strategyMapUpdate.reducer,
    [strategyMapCopy.name]: strategyMapCopy.reducer,
    [navigationHeaderList.name]: navigationHeaderList.reducer,
    [createProgressNotes.name]: createProgressNotes.reducer,
    [profilePreferencesIntegrationsDetails.name]: profilePreferencesIntegrationsDetails.reducer,
    [settingsAccountExportModel.name]: settingsAccountExportModel.reducer,
    [settingsAccountExportCollection.name]: settingsAccountExportCollection.reducer,
    [settingsAccountSubscriptionModel.name]: settingsAccountSubscriptionModel.reducer,
    [settingsUsersModel.name]: settingsUsersModel.reducer,
    [settingsTeamCollection.name]: settingsTeamCollection.reducer,
    [settingsTeamModel.name]: settingsTeamModel.reducer,
    [userNotifications.name]: userNotifications.reducer,
    [goalMemberRolesList.name]: goalMemberRolesList.reducer,
    [appIntegrationCollection.name]: appIntegrationCollection.reducer,
    [teamMemberRolesList.name]: teamMemberRolesList.reducer,
    [settingsIntegrationsGoalList.name]: settingsIntegrationsGoalList.reducer,
    [subscriptionLimitCollection.name]: subscriptionLimitCollection.reducer,
    [changePasswordModel.name]: changePasswordModel.reducer,
    [changeEmailModel.name]: changeEmailModel.reducer,
    [authChangeEmailModel.name]: authChangeEmailModel.reducer,
    [milestonePeriodTargetCollection.name]: milestonePeriodTargetCollection.reducer,
    [permissionModel.name]: permissionModel.reducer,
    [wizardGoalTreeCollection.name]: wizardGoalTreeCollection.reducer,
    [spMicroWidgetsModel.name]: spMicroWidgetsModel.reducer,
    [recentPlansModel.name]: recentPlansModel.reducer,
    [plansOverviewList.name]: plansOverviewList.reducer,
    [memberCollection.name]: memberCollection.reducer,
    [workCenterCollection.name]: workCenterCollection.reducer,
    [workCenterCustomFilterCollection.name]: workCenterCustomFilterCollection.reducer,
    [milestonesListMobileList.name]: milestonesListMobileList.reducer,
    [serviceAuthModel.name]: serviceAuthModel.reducer,
    [credentialsLoginModel.name]: credentialsLoginModel.reducer,
    [workCenterCustomFilterModelUpdate.name]: workCenterCustomFilterModelUpdate.reducer,
    [workCenterCustomFilterModelView.name]: workCenterCustomFilterModelView.reducer,
    [currentFormsReducerName]: currentFormsReducer,
    [workCenterTimelineModel.name]: workCenterTimelineModel.reducer,
    [workCenterViewTypeName]: workCenterViewType,
    [PrimaryEntityCreateService.constants.name]: PrimaryEntityCreateService.reducer,
    [PrimaryEntityCloneService.constants.name]: PrimaryEntityCloneService.reducer,
    [appLocalizationsCollection.name]: appLocalizationsCollection.reducer,
    [navigationGoalMaplist.name]: navigationGoalMaplist.reducer,
    [publicApiKeyModel.name]: publicApiKeyModel.reducer,
    [workCenterGridModel.name]: workCenterGridModel.reducer,
    [workCenterDefaultFitlerModel.name]: workCenterDefaultFitlerModel.reducer,
    [userTeamsCollection.name]: userTeamsCollection.reducer,
    [settingsSecuritySsoProviderModel.name]: settingsSecuritySsoProviderModel.reducer,
    [settingsSecuritySsoProviderCollection.name]: settingsSecuritySsoProviderCollection.reducer,
    [settingsAccountMultiFactorAuthModel.name]: settingsAccountMultiFactorAuthModel.reducer,
    [preferencesAccountSecurityMFADisableModal.name]: preferencesAccountSecurityMFADisableModal.reducer,
    [preferencesAccountSecurityMFARecoveryCodeModal.name]: preferencesAccountSecurityMFARecoveryCodeModal.reducer,
};

const other = {
    routing: routerReducer,
    form: formReducer
};

export default function createReducer(async) {
    return combineReducers({
        ...reducers,
        ...other,
        ...async
    });
}
