import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { get, curry, bind } from 'lodash';
import {
    Clickable,
    StatusIndicator,
    TableView, Tooltip
} from '../../../../../shared/components';
import Icon from '../../../../../shared/components/icon/component';
import { getEntityPermissions } from '../../../../../shared/utils/entity';
import { noop } from '../../../../../shared/utils/common';

import { relatedLinkConstants } from '../../../../secondary-entities/related-link';
import '../../../../secondary-entities/related-link/csf-connection/list/csf-connection-table.css';

const curriedGet = curry(get, 2);
const { titleSortAttribute, attributePaths, attributeNames } = relatedLinkConstants;

export default class CsfRelactedLinkListTable extends Component {
    renderItem = (item) => {
        const { onRemove, userId, permissions } = this.props;
        const getItemProp = curriedGet(item);
        const relatedLink = getItemProp(attributeNames.relatedLink);
        const progressStatus = getItemProp(attributePaths.progressStatus);
        const title = getItemProp(attributePaths.title);
        const ownerFirstName = getItemProp(attributePaths.ownerFirstName);
        const ownerLastName = getItemProp(attributePaths.ownerLastName);
        const status = getItemProp(attributePaths.status);
        const id = getItemProp(attributePaths.id);
        const href = getItemProp(attributePaths.href);
        const handleRemove = bind(onRemove, null, id);
        const { canDestroy } = getEntityPermissions({ ...item, permissions }, userId);

        return (
            <TableView.Row key={id}>
                <TableView.Cell>
                    <StatusIndicator
                        status={progressStatus}
                        size={StatusIndicator.sizes.small}
                    />
                </TableView.Cell>
                <TableView.Cell>
                    <TableView.formatters.EntityIcon entity={relatedLink} />
                </TableView.Cell>

                <TableView.Cell
                    align={TableView.align.LEFT}
                >
                    <Link to={href}>{title}</Link>
                </TableView.Cell>
                <TableView.Cell align={TableView.align.LEFT}>
                    <TableView.formatters.Status status={status} />
                </TableView.Cell>
                <TableView.Cell align={TableView.align.LEFT}>
                    {ownerFirstName} {ownerLastName}
                </TableView.Cell>
                <TableView.Cell className="table-view-cell-overflow-visible">
                    <Tooltip
                        tooltipTextClassName="driver-csf-table-tooltip"
                        text="This action is not available for your role"
                        disabled={canDestroy}
                    >
                        <Clickable
                            action="remove-related-link"
                            onClick={canDestroy ? handleRemove : noop}
                        >
                            <Icon className="driver-csf-table-title-remove-icon" name="trash-alt" />
                        </Clickable>
                    </Tooltip>
                </TableView.Cell>
            </TableView.Row>
        );
    }

    render() {
        const {
            placeholder,
            toggleSort,
            sorts,
            items,
        } = this.props;

        const handleSortChange = bind(toggleSort, null, titleSortAttribute);

        return (
            <TableView
                className="driver-csf-table"
                placeholder={(placeholder) && (
                    <TableView.Placeholder>
                        {placeholder}
                    </TableView.Placeholder>
                )}
            >
                <TableView.Header>
                    <TableView.HeaderCell className="driver-csf-table-column-health">Health</TableView.HeaderCell>
                    <TableView.HeaderCell className="driver-csf-table-column-type">Type</TableView.HeaderCell>
                    <TableView.HeaderCell
                        className="driver-csf-table-column-title"
                        align={TableView.align.LEFT}
                        onClick={handleSortChange}
                        sortDirection={sorts[titleSortAttribute]}
                        sortable
                    >
                        Title
                    </TableView.HeaderCell>
                    <TableView.HeaderCell
                        align={TableView.align.LEFT}
                        className="driver-csf-table-column-status"
                    >
                        Status
                    </TableView.HeaderCell>
                    <TableView.HeaderCell
                        align={TableView.align.LEFT}
                        className="driver-csf-table-column-owner"
                    >
                        Owner
                    </TableView.HeaderCell>
                    <TableView.HeaderCell className="driver-csf-table-column-remove" />
                </TableView.Header>

                {items.map(this.renderItem)}
            </TableView>
        );
    }
}

CsfRelactedLinkListTable.defaultProps = {
    items: [],
    sorts: {},
    placeholder: undefined,
};

CsfRelactedLinkListTable.propTypes = {
    items: PropTypes.array,
    toggleSort: PropTypes.func.isRequired,
    sorts: PropTypes.object,
    placeholder: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    permissions: PropTypes.object.isRequired
};
