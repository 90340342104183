import moment from 'moment';
import { cssVariables } from '../../shared/styles/constants';
import { get as getCSSVarValue } from '../../shared/utils/css-custom-properties';
import { getFormattedPeriod } from '../../shared/utils/date';
import { typeCodes } from '../../shared/utils/entity-type';
import config from '../../config';

export const getMonthRangeFromDate = (date = moment(), viewType = 'calendar') => {
    return viewType === 'calendar'
        ? {
            start: moment(date).startOf('month').startOf('week').format(),
            end: moment(date).endOf('month').endOf('week').endOf('day')
                .format(),
        }
        : {
            start: moment(date).subtract(11, 'week').startOf('week').format(),
            end: moment(date).add(12, 'week').endOf('week').endOf('day')
                .format(),
        };
};

export const getWeekRangeFromDate = (date = moment(), viewType = 'calendar') => {
    return viewType === 'calendar'
        ? {
            start: moment(date).startOf('week').format(),
            end: moment(date).endOf('week').endOf('day').format(),
        }
        : {
            start: moment(date).subtract(2, 'week').startOf('week').format(),
            end: moment(date).add(3, 'week').endOf('week').endOf('day')
                .format(),
        };
};

export const getQuarterlyRangeFromDate = (date = moment()) => ({
    start: moment(date).subtract(3, 'Q').startOf('Q').endOf('day')
        .format(),
    end: moment(date).add(4, 'Q').endOf('Q').endOf('day')
        .format(),
});

export const entityHeight = (
    parseInt(getCSSVarValue(cssVariables.workCenterEntityHeight), 10)
    + parseInt(getCSSVarValue(cssVariables.workCenterEntityVerticalOffset), 10) * 2
);

export const getMonthsBetweenToFrom = (start, end, format = 'MMM, YYYY') =>
    Array.from({
        length: moment(end, 'MM.YYYY')
            .diff(moment(start, 'MM.YYYY'), 'month') + 1
    })
        .map((_, index) =>
            moment(start, 'MM.YYYY')
                .add(index, 'month')
                .format(format));

export const getQuarterlyBetweenToFrom = (start, end, format = 'Q, YYYY') => {
    return Array.from({
        length: moment(end, 'Q.YYYY')
            .diff(moment(start, 'Q.YYYY'), 'Q') + 1
    })
        .map((_, index) =>
            `Q${moment(start, 'Q.YYYY').add(index, 'Q').format(format)}`);
};

export const getWeeksBetweenToFrom = (start, end, format = 'MMM DD, YYYY') =>
    Array.from({ length: end.diff(start, 'week') + 1 })
        .map((_, index) =>
            moment(start)
                .add(index, 'week')
                .format(format),);

export const getDaysBetweenToFrom = (start, end, format = 'MMM DD, YYYY') =>
    Array.from({ length: end.diff(start, 'day') + 7 })
        .map((_, index) =>
            moment(start)
                .add(index, 'day')
                .format(format),);

export const getDaysOfWeekArray = () => Array(6)
    .fill(['S', 'M', 'T', 'W', 'T', 'F', 'S'])
    .flat();

// `titles` are separated as constants
const titles = Object.freeze({
    startPeriod: 'Start Period',
    startDate: 'Start Date',
    targetPeriod: 'Target Period',
    targetDate: 'Target Date',
});

// Several small utility functions
const formatDate = date => moment(date).format(config.shortDateFormat);
const formatRange = (frequency, date) => getFormattedPeriod(frequency, date);
export const dateNames = {
    startAt: 'start_at',
    targetAt: 'target_at'
};

// `prepareDates` function will return an array of objects with formatted titles and dates only for dates that exist.
// You can iterate over such an array and there will be fewer checks for dates existence in your render function.
// It will take away unnecessary complexity from the component and isolates business logic from UI.
// In case, if we need to add the special handling for more entities it will be much easier.
export function prepareDates(entityCode, startAt, targetAt, frequency) {
    const result = [];

    switch (entityCode) {
        case typeCodes.kpi:
            if (startAt) {
                result.push({
                    id: dateNames.startAt,
                    title: titles.startPeriod,
                    date: formatRange(frequency, startAt)
                });
            }
            if (targetAt) {
                result.push({
                    id: dateNames.targetAt,
                    title: titles.targetPeriod,
                    date: formatRange(frequency, targetAt)
                });
            }
            break;
        default:
            if (startAt) {
                result.push({
                    id: dateNames.startAt,
                    title: titles.startDate,
                    date: formatDate(startAt)
                });
            }
            if (targetAt) {
                result.push({
                    id: dateNames.targetAt,
                    title: titles.targetDate,
                    date: formatDate(targetAt)
                });
            }
            break;
    }

    return result;
}
