/** @namespace Utils.Clipboard */

/**
 * Copy input value to clipboard
 * @function copy
 * @memberof Utils.Clipboard
 * @param {string|number} str - Copied value to clipboard
 */
export function copy(str) { // eslint-disable-line import/prefer-default-export
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected = document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
}

/**
 * Copy value to clipboard
 * @function copy
 * @memberof Utils.Clipboard
 * @param {string|number} text - Copied value to clipboard
 */
export const copyToClipBoard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
