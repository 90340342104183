import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Icon from '../icon/component';
import '../icon/fontawesome/css/all.min.css';

const IconStack = ({
    size, className, children, ...rest
}) => {
    return (
        <span
            className={cx('fa-stack', {
                [`fa-${size}`]: size,
                [className]: className
            })}
            {...rest}
        >
            {children}
        </span>
    );
};

IconStack.defaultProps = {
    className: undefined,
    size: undefined
};

IconStack.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),
    children: PropTypes.arrayOf(PropTypes.instanceOf(Icon)).isRequired
};

export default IconStack;
