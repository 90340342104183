import request from '../../../request';
import { getEntitiesByType } from '../../../shared/utils/content-editor-entity-selector';

export default function createAPI(url, mentionsField = 'description', options) {
    return {
        async update(data) {
            let safeUrl = url;

            if (typeof url === 'function') {
                safeUrl = url(data);
            }

            const dataToSend = options.format(data);

            const content = dataToSend[mentionsField] || dataToSend.content || '';
            const mentions = getEntitiesByType(content, 'mention');
            const { tags } = dataToSend;

            if (tags) {
                const updatedTags = tags.map(item => (
                    item.title === item.id
                        ? { title: item.title }
                        : { id: item.id, title: item.title }
                ));

                await request.post(`${safeUrl}/${dataToSend.id}/tags`, { tags: updatedTags });
            }

            if (mentions && mentions.length) {
                dataToSend.mentions = mentions;
            }

            return request.put(`${safeUrl}/${dataToSend.id}`, dataToSend).then(options.parse);
        },
        getTagName(title) {
            return request.get(`${url}/tagname`, { params: title });
        },
    };
}
