import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
import { Button } from '../../components';
import { ServicePageMobileAccessError } from '../../../modules/service-pages';
import { set as setNavigationContextAction } from '../../../modules/navigation-context/actions';

class MobileDetailsView extends Component {
    componentDidMount() {
        const {
            children, params, entityType, setNavigationContext, entityTypeCode,
        } = this.props;

        if (children) {
            setNavigationContext({
                id: params.id,
                entityType,
                entityTypeCode,
            });
        }
    }

    handleBackClick = () => {
        const { navigateBack } = this.props;

        navigateBack();
    }

    render() {
        const { children } = this.props;

        return (
            <section className="details-view-mobile">
                {!children && (
                    <ServicePageMobileAccessError>
                        <Button
                            styleType="success"
                            onClick={this.handleBackClick}
                        >
                            Back
                        </Button>
                    </ServicePageMobileAccessError>
                )}

                {children}
            </section>
        );
    }
}

MobileDetailsView.defaultProps = {
    children: undefined,
};

MobileDetailsView.propTypes = {
    children: PropTypes.node,
    setNavigationContext: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    routeParams: PropTypes.shape({
        subPath: PropTypes.string,
    }).isRequired,
    entityType: PropTypes.string.isRequired,
    navigateBack: PropTypes.func.isRequired,
    entityTypeCode: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
    setNavigationContext: setNavigationContextAction,
    navigateBack: goBack,
};

export default connect(null, mapDispatchToProps)(MobileDetailsView);
