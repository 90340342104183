import { flow, get, isEmpty } from 'lodash';
import { formValueSelector } from 'redux-form';
import { withBreakpoints } from 'react-breakpoints';
import createDetailsFormModal from '../../../../shared/modules/details-form-view/component';
import parseValidationMessage from '../../../../shared/modules/input-attachments/parse-validation';
import { getEntityCode } from '../../../../shared/utils/entity-type';

import { withAttachmentFilePickerState } from '../../../secondary-entities/attachment';

import { withAppContext } from '../../../app-context/with-app-context';
import Permissions from '../../../permissions';
import { entityPermissionNames, isOwnEntity } from '../../../permissions/constants';
import { states } from '../../../goalmap-list/config';

import { subscriptionFields, subscriptionLimitSelectors } from '../../../subscription';
import Form from '../form/progress-note-form';
import { progressNoteModel } from '../progress-note-models';
import ProgressNotesDetailsFormControls from './progress-note-details-modal-controls';

import styles from './progress-note-details-modal.module.css';

const { actions, selector } = progressNoteModel;
const formName = 'progress-note-details-form';
const formSelector = formValueSelector('progress-note-details-form');
const { selectResolvedEntityPermissions } = Permissions.selectors;
const { entitySections } = Permissions.constants;

const getReadonly = (isDraft, permissions, forceReadonly) => {
    const canUpdate = get(permissions, entityPermissionNames.canUpdate);
    const isOwner = get(permissions, isOwnEntity);

    if (isDraft) {
        return false;
    }
    if (canUpdate && !isOwner && (forceReadonly || !forceReadonly)) {
        return forceReadonly;
    }

    return !canUpdate;
};


const ProgressNoteDetails = createDetailsFormModal({
    formOptions: {
        form: formName,
        enableReinitialize: true,
    },
    parseFormSubmitError: parseValidationMessage,
    entityType: 'driver',
    actions,
    selector,
    ControlsComponent: ProgressNotesDetailsFormControls,
    enableReinitialize: true,
    mapStateToProps(state, ownProps) {
        const currentBranch = selector(state);
        const goalId = currentBranch.data.goal_id;
        const permissions = selectResolvedEntityPermissions(state, goalId, entitySections.note, currentBranch.data);
        const draftLimits = subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.draft);
        const isDraft = currentBranch.data.state === states.draft;
        const readonly = getReadonly(isDraft, permissions, ownProps.readonly);

        return {
            title: 'Progress Note',
            // TODO: Revert crutch to "readonly: ownProps.readonly"
            // after merging https://gitlab.com/mpowrenvision/mpowr-envision-front/merge_requests/993
            readonly,
            goal_id: currentBranch.data.goal_id,
            controlProps: {
                ...ownProps.controlProps,
                entityId: ownProps.entityId,
                entityType: currentBranch.data.entity_type,
                entityState: currentBranch.data.state,
                onRemove: ownProps.onRemove,
                onStartEdit: ownProps.onStartEdit,
                disableSubmit: ownProps.filePicker.visible,
                // TODO: Revert crutch to "readonly: ownProps.readonly"
                // after merging https://gitlab.com/mpowrenvision/mpowr-envision-front/merge_requests/993
                readonly,
                canCreateDraft: draftLimits.value,
                currentStateValue: formSelector(state, 'state'),
            },
            permissions,
            parent: currentBranch.data.entity,
            parentEntityType: currentBranch.data.entity_type,
            currentState: currentBranch.data.state,
            currentValues: {
                current: formSelector(state, 'current'),
                deleted: formSelector(state, 'deleted'),
                parentId: formSelector(state, 'parentId'),
                parentEntityType: formSelector(state, 'parentEntityType'),
                entity: formSelector(state, 'entity')
            },
            initialValues: {
                entity: get(currentBranch, 'data.entity'),
                id: currentBranch.data.id,
                parentId: ownProps.entityId || get(currentBranch, 'data.entity.id'),
                parentEntityType: ownProps.parentEntityType || (
                    !isEmpty(currentBranch.data.entity)
                        ? getEntityCode(get(currentBranch, 'data.entity'))
                        : undefined
                ),
                state: states.active,
                content: currentBranch.data.content,
                current: currentBranch.data.attachments,
                attachments: [], // new attachments
                deleted: [], // removed attachments
            },
            onHide: ownProps.filePicker.hide,
            modalContentClassName: styles.modalMobileContent,
        };
    }
});

export default flow(
    ProgressNoteDetails,
    withAttachmentFilePickerState,
    withBreakpoints,
    withAppContext,
)(Form);
