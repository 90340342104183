import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm, } from 'redux-form';
import get from 'lodash/get';
import { isDraftEntity, isArchived as isArchivedEntity } from '../../../utils/entity';
import Permissions from '../../../../modules/permissions';
import { selectUserId } from '../../../../modules/user/selectors';
import { ServicePage404, ServicePage405 } from '../../../../modules/service-pages';
import * as ToastNotifications from '../../../../modules/notifications/toast';
import { getTitlesByGoalId } from '../../../../modules/goalmap-list/reducer';
import { LoadingIndicator, Button } from '../../../components';
import { getStringTypeByCode } from '../../../utils/entity-type';
import VersionConflictProvider from '../../version-conflict-provider';
import EntityProgressNotes from './entity-progress-notes';
import useEntityActions from '../use-entity-actions';
import { tabType } from '../constants';

import styles from './styles.module.css';

const stickyConstraintTop = '#nav-header';
const stickyOffsetTop = -56;

export const createRightDrawerEntityDetailsView = (options) => {
    return (WrappedComponent) => {
        const RightDrawerEntityDetails = (props) => {
            const {
                fetchEntityDetails, entityDescription, loadingEntityDetails, callbackOnEntityChange,
                dataEntityDetails, canViewEntity, errorEntityDetails, updatingEntityDetails, userId,
                hideVersionConflict, versioningConflict, updateEntityDetails, dataEntityDetails: { versioning },
                onUpdateEntityStatus, permissions, deleteEntity, hide
            } = props;

            const dispatch = useDispatch();
            const planTitles = useSelector(state => getTitlesByGoalId(state, get(dataEntityDetails, 'goal_id')));
            const typeCode = get(dataEntityDetails, 'type_code');
            const entityTypeTitle = get(planTitles, getStringTypeByCode(typeCode), 'Entity');
            const isDraft = isDraftEntity(dataEntityDetails);
            const isArchived = isArchivedEntity(dataEntityDetails);

            const [activeTab, setActiveTab] = useState(tabType.entityDetails);

            useEffect(() => {
                if (canViewEntity) fetchEntityDetails({ id: entityDescription.id });
                setActiveTab(tabType.entityDetails);
            }, [entityDescription.id, fetchEntityDetails, canViewEntity]);

            const { EntityActionsPrompt, actions } = useEntityActions({
                id: get(dataEntityDetails, 'id'),
                title: get(dataEntityDetails, 'title'),
                state: get(dataEntityDetails, 'state'),
                typeCode,
                planId: get(dataEntityDetails, 'goal_id'),
                data: dataEntityDetails,
                permissions,
                remove: deleteEntity,
                updateState: onUpdateEntityStatus,
                onEntityUpdate: callbackOnEntityChange,
                onRemove: hide,
            });

            const onUpdateStatusSuccess = (response) => {
                const action = ToastNotifications.create({
                    type: ToastNotifications.types.SERVICE,
                    data: {
                        type: ToastNotifications.serviceTypes.SUCCESS,
                        text: `${entityTypeTitle} has been published`,
                    }
                });

                dispatch(action);
                callbackOnEntityChange(response);
            };

            const handleStateChange = () => {
                onUpdateEntityStatus({
                    id: get(dataEntityDetails, 'id'),
                    state: 'active',
                    onSuccess: onUpdateStatusSuccess,
                });
            };

            useEffect(() => {
                setActiveTab(props.activeTab);
            }, [props.activeTab]);

            const handleToggleTab = (tabName) => {
                setActiveTab(prevState => (prevState === tabName ? tabType.entityDetails : tabName));
            };

            if (!loadingEntityDetails && errorEntityDetails) {
                return <ServicePage404 />;
            }

            if (!canViewEntity) {
                return <ServicePage405 />;
            }

            return (
                <>
                    <VersionConflictProvider
                        updateEntity={updateEntityDetails}
                        entityType={options.entityType}
                        entityVersioning={versioning}
                        onSuccessOverwrite={(data) => { callbackOnEntityChange(data); }}
                        visibleVersionConflictModal={versioningConflict}
                        hideVersionConflictModal={hideVersionConflict}
                    >
                        {versionConflict => (
                            <>
                                {(loadingEntityDetails || updatingEntityDetails) && (
                                    <div className="loading-overlay">
                                        <LoadingIndicator centered />
                                    </div>
                                )}
                                {(!loadingEntityDetails && dataEntityDetails.id) && (
                                    <div className={styles.rightDrawerEntityView}>
                                        <WrappedComponent
                                            data={dataEntityDetails}
                                            isActiveTab={activeTab === tabType.entityDetails}
                                            onUpdateEntityDetails={versionConflict.updateEntityWithVersionCheck}
                                            handleToggleTab={handleToggleTab}
                                            quillStickyProps={{
                                                menuConstraintTop: stickyConstraintTop,
                                                menuOffsetTop: stickyOffsetTop
                                            }}
                                            {...props}
                                            activeTab={activeTab}
                                            settings={actions}
                                        />

                                        {!isDraft && !isArchived && (
                                            <EntityProgressNotes
                                                isActiveTab={activeTab === tabType.entityNotesList}
                                                handleToggleTab={() => handleToggleTab(tabType.entityNotesList)}
                                                author={dataEntityDetails.author}
                                                created_at={dataEntityDetails.created_at}
                                                parentId={dataEntityDetails.id}
                                                goal_id={dataEntityDetails.goal_id}
                                                userId={userId}
                                                parentEntityType={dataEntityDetails.entity_type}
                                                callbackOnEntityChange={callbackOnEntityChange}
                                            />
                                        )}

                                        {isDraft && (
                                            <div className={styles.rightDrawerEntityViewFooter}>
                                                <Button
                                                    type="button"
                                                    styleType="success"
                                                    disabled={updatingEntityDetails}
                                                    onClick={handleStateChange}
                                                >
                                                    Publish
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </VersionConflictProvider>

                    <EntityActionsPrompt />
                </>
            );
        };

        RightDrawerEntityDetails.defaultProps = {
            errorEntityDetails: null,
            callbackOnEntityChange() { },
            versioningConflictEntityDetails: false,
        };

        RightDrawerEntityDetails.propTypes = {
            entityDescription: PropTypes.shape({
                id: PropTypes.number,
                type: PropTypes.number,
                goal_id: PropTypes.number,
                planTitle: PropTypes.string,
                planSettings: PropTypes.shape({
                    action: PropTypes.string,
                    driver: PropTypes.string,
                    goal: PropTypes.string,
                    milestone: PropTypes.string,
                    plan: PropTypes.string,
                    segment: PropTypes.string,
                    strategy_map: PropTypes.string,
                    sub_segment: PropTypes.string,
                }),
                planColor: PropTypes.string,
            }).isRequired,
            activeTab: PropTypes.string.isRequired,
            fetchEntityDetails: PropTypes.func.isRequired,
            loadingEntityDetails: PropTypes.bool.isRequired,
            updatingEntityDetails: PropTypes.bool.isRequired,
            dataEntityDetails: PropTypes.object.isRequired,
            versioningConflictEntityDetails: PropTypes.bool,
            canViewEntity: PropTypes.bool.isRequired,
            errorEntityDetails: PropTypes.bool,
            userId: PropTypes.number.isRequired,
            callbackOnEntityChange: PropTypes.func,
            hideVersionConflict: PropTypes.func.isRequired,
            versioningConflict: PropTypes.bool.isRequired,
            updateEntityDetails: PropTypes.func.isRequired,
            onUpdateEntityStatus: PropTypes.func.isRequired,
            canDestroyEntity: PropTypes.bool.isRequired,
            deleteEntity: PropTypes.func.isRequired,
            hide: PropTypes.func.isRequired,
            permissions: PropTypes.object.isRequired,
        };

        const mapStateToProps = (state, ownProps) => {
            const parentState = options.mapStateToProps(state, ownProps);
            const branchEntityDetails = options.selector(state);
            const dataEntityDetails = get(branchEntityDetails, 'data', {});
            const permissions = Permissions.selectors.selectResolvedEntityPermissions(
                state,
                ownProps.entityDescription.goal_id,
                options.permissionsSection,
                dataEntityDetails,
            );
            const canViewEntity = get(permissions, Permissions.keys.canView, false) || get(permissions, Permissions.keys.canViewOwn, false);
            const canUpdateEntity = get(permissions, Permissions.keys.canUpdate);
            const canDestroyEntity = get(permissions, Permissions.keys.canDestroy, false);
            const planPermissions = Permissions.selectors
                .selectEntityPermissions(state, ownProps.entityDescription.goal_id, Permissions.constants.entitySections.strategicPlan);

            return {
                permissions,
                loadingEntityDetails: get(branchEntityDetails, 'loading', false),
                updatingEntityDetails: get(branchEntityDetails, 'updating', false),
                errorEntityDetails: get(branchEntityDetails, 'error'),
                versioningConflict: get(branchEntityDetails, 'versioningConflict'),
                userId: selectUserId(state),
                dataEntityDetails,
                canViewEntity,
                canEditOwner: get(planPermissions, Permissions.keys.canUpdateOwner, false),
                canDestroyEntity,
                canUpdateEntity,
                ...parentState,
            };
        };

        const mapDispatchToProps = {
            fetchEntityDetails: options.actions.request,
            updateEntityDetails: options.actions.update.request,
            deleteEntity: options.actions.remove.request,
            onUpdateEntityTags: options.actions.updateTags.request,
            onUpdateEntityStatus: options.actions.updateStatus.request,
            hideVersionConflict: options.actions.hideVersionConflictModal,
        };

        return compose(
            reduxForm({ form: options.formName }, options.formOptions),
            connect(mapStateToProps, mapDispatchToProps)
        )(RightDrawerEntityDetails);
    };
};
