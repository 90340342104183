import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from '../index';

function MeasureRangeItem({
    label,
    value,
    active,
    onChange,
    showDivider,
    ...rest
}) {
    return (
        <>
            {
                showDivider
                    ? <div className="measure-range-devider">|</div>
                    : null
            }
            <div key={value} className="measure-range-selector-option">
                <RadioButton
                    input={{
                        value,
                        onChange
                    }}
                    value={active}
                    id={value}
                    label={label}
                    {...rest}
                />
            </div>
        </>
    );
}

MeasureRangeItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    active: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    showDivider: PropTypes.bool.isRequired,
};

MeasureRangeItem.defaultProps = {
    active: undefined
};

export default MeasureRangeItem;
