const shared = Object.freeze({
    eulaLinkText: 'Terms of Service',
    privacyPolicyLinkText: 'Privacy Policy',
    useSocialLogin: 'With Social Networks',
    placeholderEmail: 'Type in your Email',
});

export default Object.freeze({
    login: {
        title: 'Log In',
        buttonText: 'Log In',
        useSocialLogin: shared.useSocialLogin,
        useCreds: 'or use your existing MPOWR Envision credentials',
        forgotPassword: 'Don\'t remember your password?',
        eulaPrefix: 'By logging in, you agree to the',
        eulaSSOPrefix: 'By logging in, you agree to the',
        eulaLinkText: shared.eulaLinkText,
        privacyPolicyLinkText: shared.privacyPolicyLinkText,
        placeholderPassword: 'Type in your Password',
        placeholderEmail: shared.placeholderEmail,
        thirdPartyCookiesError: `
            Unable to configure login verification page.
            Please enable third-party cookies in your browser to login to MPOWR Envision.
        `,
    },
    signup: {
        title: 'Sign Up',
        buttonText: 'Sign Up',
        useSocialLogin: shared.useSocialLogin,
        useCreds: 'or create new MPOWR Envision credentials',
        eulaPrefix: 'I have read and agree to the',
        eulaLinkText: shared.eulaLinkText,
        placeholderPassword: 'Create Password',
        placeholderEmail: shared.placeholderEmail,
        privacyPolicyLinkText: shared.privacyPolicyLinkText,
        invalidInviteTokenMessage: `This invite link is no longer available because it has already
        been used, is expired or is invalid. If you need assistance please check with your administrator.`,
        genericError: 'We\'re sorry, something went wrong when attempting to sign up.',
        invalidEmailOrPwdError: 'Since you are an existing Envision user in another instance please use the same password',
    },
    forgotPassword: {
        title: 'Reset your password',
        placeholderEmail: shared.placeholderEmail,
        submit: 'Send Email',
        back: 'Back to Log In',
        descriptionLine1: 'Please enter your email address.',
        descriptionLine2: 'We will send you an email to reset your password.',
        success: 'You will receive password reset instructions if your email exists in the system',
        genericError: 'We\'re sorry, something went wrong when attempting to reset your password.',
    },
    changePassword: {
        title: 'Change Password',
        description: 'Enter a new password',
        placeholderPassword: 'Your new password',
        placeholderPasswordRepeat: 'Confirm your new password',
        submit: 'Submit',
        samePasswordMismatch: 'Please ensure the password and the confirmation are the same.',
        genericError: 'We\'re sorry, something went wrong when attempting to change password.',
        success: 'Your password has been changed. Please Log In with the new credentials',
    },
    changeEmail: {
        title: 'Change Email',
        submit: 'Submit',
        labelNewEmail: 'New Email',
        labelPasswordCurrent: 'Confirm your identity',
        placeholderPasswordCurrent: 'Current Password',
        authWithSocialLogin: 'Authenticate with your social network account to confirm your identity',
        placeholderPasswordNew1: 'Password associated with your new Email',
        placeholderPasswordNew2: 'New Password (Optional)',
    }
});
