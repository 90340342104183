import reset from './actions';
import { initialState as authInitialState } from '../../modules/authentication/reducer';
import { stateBranchName as authStateBranchName } from '../../modules/authentication/config';

export default function resetMiddleware() {
    return next => (reducer, initialState, enhancer) => {
        const enhanceReducer = (state, action) => {
            if (action.type === reset.getType()) {
                return reducer(
                    {
                        routing: state.routing,
                        form: state.form,
                        [authStateBranchName]: authInitialState,
                    },
                    action
                );
            }

            return reducer(state, action);
        };

        return next(enhanceReducer, initialState, enhancer);
    };
}
