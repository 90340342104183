import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const colorTypes = Object.freeze({
    neutral: 'neutral',
    info: 'info',
    // warning: 'warning',
    danger: 'danger',
    // success: 'success',
});

const borderTypes = Object.freeze({
    solid: 'borderSolid',
    dashed: 'borderDashed',
});

function Alert({
    children, colorType, borderType, className
}) {
    const colorTypeClassName = styles[colorType];
    const borderTypeClassName = styles[borderType];

    const classNames = classnames(
        styles.alert,
        colorTypeClassName,
        borderTypeClassName,
        className,
    );

    return (
        <div
            className={classNames}
            role="alert"
        >
            {children}
        </div>
    );
}

const typeArray = Object.values(colorTypes);
const borderTypeArray = Object.values(borderTypes);

Alert.colorTypes = colorTypes;
Alert.borderTypes = borderTypes;

Alert.defaultProps = {
    children: undefined,
    colorType: colorTypes.info,
    borderType: borderTypes.solid,
    className: undefined,
};

Alert.propTypes = {
    children: PropTypes.node,
    colorType: PropTypes.oneOf(typeArray),
    borderType: PropTypes.oneOf(borderTypeArray),
    className: PropTypes.string,
};

export default Alert;
