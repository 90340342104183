import request from '../../request';
import { logError } from './logger';

const apiUrl = '/web_notifications/devices';

export function create(id) {
    return request.post(`${apiUrl}/${id}`, { mode: 'dev' }).catch(logError);
}

export function remove(id) {
    return request.delete(`${apiUrl}/${id}`).catch(logError);
}
