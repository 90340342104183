import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router';
import './styles.css';

function renderSection({
    color,
    value,
    labelValue,
    labelTitle,
    labelTitleColor,
    href,
    onClick,
    section
}) {
    if (value <= 0) {
        return null;
    }

    const Component = href
        ? Link
        : 'span';

    return (
        <Component
            className="chart-health-bar-section"
            key={color}
            to={href}
            onClick={onClick}
            data-section={section}
            style={{
                width: `${value}%`,
                backgroundColor: color,
                display: 'inline-block',
                height: '32px',
            }}
        >
            <span style={{ color: labelTitleColor }}>
                <span className="chart-health-section-value">
                    {labelValue}
                </span>
                &nbsp;
                {labelTitle}
            </span>
        </Component>
    );
}

renderSection.defaultProps = {
    href: undefined,
    onClick: undefined,
    section: undefined,
};

renderSection.propTypes = {
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    labelValue: PropTypes.func.isRequired,
    labelTitle: PropTypes.func.isRequired,
    labelTitleColor: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    section: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.number,
    ])
};

export default function ChartHealthBar({ sections, className }) {
    return (
        <div
            className={classnames('chart-health-bar', {
                [className]: !!className,
            })}
        >
            {sections.map(renderSection)}
        </div>
    );
}

ChartHealthBar.defaultProps = {
    className: undefined,
};

ChartHealthBar.propTypes = {
    className: PropTypes.string,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            value: PropTypes.number,
            label: PropTypes.string,
            href: PropTypes.string,
        }),
    ).isRequired,
};
