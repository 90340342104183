import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { typeCodes } from '../../shared/utils/entity-type';
import * as routeParams from '../../routes/rules/params';
import { setContext } from './reducer';

const legacyIdParamByType = {
    [typeCodes.segment]: routeParams.LegacySegmentId,
    [typeCodes.strategy]: routeParams.LegacyStrategyObjectiveId,
    [typeCodes.objective]: routeParams.LegacyStrategyObjectiveId,
    [typeCodes.tactic]: routeParams.LegacyTacticActionId,
    [typeCodes.action]: routeParams.LegacyTacticActionId,
    [typeCodes.kpi]: routeParams.LegacyEntityId,
};

function withNavigatorContext(typeCodesArr) {
    return (EnhancedComponent) => {
        class WithNavigatorContext extends Component {
            componentDidMount() {
                const { params, setNavigatorContext } = this.props;

                setNavigatorContext({
                    id: this.getIdFromParams(params),
                    type_codes: typeCodesArr,
                });
            }

            componentDidUpdate(prevProps) {
                const { setNavigatorContext } = this.props;
                const prevId = this.getIdFromParams(prevProps.params);
                const currId = this.getIdFromParams(this.props.params);

                if (prevId !== currId) {
                    setNavigatorContext({
                        id: Number(currId),
                        type_codes: typeCodesArr,
                    });
                }
            }

            componentWillUnmount() {
                const { setNavigatorContext } = this.props;
                setNavigatorContext({
                    id: undefined,
                    type_codes: undefined
                });
            }

            getIdFromParams = (params) => {
                const legacyParam = legacyIdParamByType[typeCodesArr[0]];
                return Number(params[legacyParam]);
            }

            render() {
                return <EnhancedComponent {...this.props} />;
            }
        }

        const mapDispatchToProps = {
            setNavigatorContext: setContext,
        };

        WithNavigatorContext.propTypes = {
            params: PropTypes.object.isRequired,
            setNavigatorContext: PropTypes.func.isRequired,
        };

        return connect(null, mapDispatchToProps)(WithNavigatorContext);
    };
}

export const setSegmentContext = withNavigatorContext([typeCodes.segment]);
export const setStrategyObjectiveContext = withNavigatorContext([typeCodes.objective, typeCodes.strategy]);
export const setTacticActionContext = withNavigatorContext([typeCodes.tactic, typeCodes.action]);
export const setKPIContext = withNavigatorContext([typeCodes.kpi]);
