/** @namespace Utils.CssCustomProperties */
import request from '../../request';
import variables from '../../shared/styles/variables';

const support = window.CSS && window.CSS.supports && window.CSS.supports('--a', 0);

function setNative(name, value) {
    document.documentElement.style.setProperty(name, value);
}

function getStyleSheetHrefs(styleSheets) {
    return Object.keys(styleSheets).reduce((accum, index) => {
        const { href } = styleSheets[index];

        if (href && !accum.includes(href)) {
            accum.push(href);
        }

        return accum;
    }, []);
}

function setCssContent(elm, content) {
    if ('textContent' in elm) {
        elm.textContent = content;
    } else {
        elm.styleSheet.cssText = content;
    }
}

const dynamicCssVariables = [
    '--color-accent-light',
    '--color-accent-lighter',
    '--color-accent-dark',
    '--color-accent-darker',
    '--color-accent'
];

function setShim(name, value) {
    const hrefs = getStyleSheetHrefs(document.styleSheets);
    const head = document.getElementsByTagName('head')[0];
    const getPromises = [];

    return new Promise((res) => {
        hrefs.forEach((href) => {
            let elm = head.querySelector(`[replaced-styles="${href}"]`);
            const isElmExists = !!elm;

            if (!isElmExists) {
                elm = document.createElement('style');
                elm.setAttribute('type', 'text/css');
                elm.setAttribute('replaced-styles', href);
                head.appendChild(elm);
            }

            if (!elm.variables) {
                elm.variables = {};
            }
            elm.variables[name] = value;

            if (!isElmExists) {
                const promise = request.get(href)
                    .then((response) => {
                        const selectorsWithDynamicVariables = response.match(
                            // eslint-disable-next-line max-len
                            new RegExp(`[a-zA-Z0-9- #.>:*,()]{1,}{[a-zA-Z0-9- #%;:().,"\\\\]{1,}var\\((${dynamicCssVariables.join('|')})\\)[a-zA-Z0-9- #%;:().,"\\\\]{0,}}`, 'gi')
                        );

                        if (selectorsWithDynamicVariables) {
                            elm.rawContent = selectorsWithDynamicVariables.join('');
                            const regexp = new RegExp(`var\\(${name}\\)`, 'gi');
                            setCssContent(elm, elm.rawContent.replace(regexp, value));
                        }
                    });
                getPromises.push(promise);
            } else {
                if (elm.rawContent) {
                    let newCss = elm.rawContent;
                    Object.keys(elm.variables).forEach((key) => {
                        const regexp = new RegExp(`var\\(${key}\\)`, 'gi');
                        newCss = newCss.replace(regexp, elm.variables[key]);
                    });
                    setCssContent(elm, newCss);
                }

                getPromises.push(Promise.resolve());
            }
        });

        Promise.all(getPromises).then(res);
    });
}

/**
 * Get css variable value by variable name
 * @function get
 * @memberof Utils.CssCustomProperties
 * @param {string} name Variable name
 * @return {string} Variable value
 */
export function get(name) {
    if (support) {
        const style = getComputedStyle(document.body);
        return style.getPropertyValue(name);
    }

    return variables[name.replace('--', '')];
}

/**
 * Set css variable value by variable name
 * @function set
 * @memberof Utils.CssCustomProperties
 * @param {string} name Variable name
 * @param {string} value Variable value
 */
export default function set(name, value) {
    if (support) {
        return setNative(name, value);
    }

    return setShim(name, value);
}
