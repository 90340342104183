import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { flow } from 'lodash';
import {
    Clearfix, PromptProvider, GridContainer, GridListRender, ModuleTitle, Breadcrumbs, Text, IntegrationsError,
} from '../../../shared/components';
import appConfig from '../../../config';
import LoadingIndicator from '../../../shared/components/loading-indicator/component';
import { subscriptionFields, subscriptionLimitSelectors } from '../../subscription';
import { settingsIntegrationsModel } from '../entity';
import { settingsIntegrationsStorageCollection } from '../storages/entity';
import { createHref } from '../routes';
import { deactivationConfirms, titles } from '../dictionary';
import { storages } from '../config';
import { integrationAttributes as intgAttributes, integrationsTypes } from '../constants';
import IntegrationListItem from './item';
import Details from './details-connect';
import { fieldNames, formName } from './config';
import {
    selectCommunicationItems, selectStorageItems, selectZapierItems, selectPublicApiItems, selectItems, selectFetching,
} from './selectors';

import styles from './styles.module.css';

const formSelector = formValueSelector(formName);

function parseSwitchValue(value) {
    if (value === '') {
        return false;
    }

    return value;
}

function parseStorageResponse(title, { total_connections, total_files }) {
    return {
        description: `
This will remove all attachments that have been selected through this
integration. This will affect ${total_files} attachments and ${total_connections} users.
`
    };
}
const shouldRenderDisabledIntegrations = integrationsByType => integrationsByType.find(integration => integration.disabled === true);
const shouldRenderNoAvailableIntegrations = integrationsByType => integrationsByType.every(integration => integration.disabled === true);

class IntegrationList extends Component {
    state = {
        updatingId: undefined,
        updatingField: undefined,
        updatingSlug: undefined,
    };

    get integrationsByType() {
        const {
            communicationItems, storageItems, zapierItems, zapierIntegrationLimit, communicationIntegrationLimit,
            storageIntegrationLimit, publicApiItems, publicApiLimit
        } = this.props;
        const integrations = [
            {
                type: integrationsTypes.communication,
                title: 'Communication',
                items: communicationItems,
                disabled: !communicationIntegrationLimit,
            },
            {
                type: integrationsTypes.fileStorage,
                title: 'File Storage',
                items: storageItems,
                disabled: !storageIntegrationLimit,
            },
        ];

        if (!appConfig.zapierDisabled()) {
            integrations.push({
                type: integrationsTypes.zapier,
                title: 'Zapier Integration',
                items: zapierItems,
                disabled: !zapierIntegrationLimit,
            });
        }

        integrations.push({
            type: integrationsTypes.publicAPI,
            title: 'Public Api',
            items: publicApiItems,
            disabled: !publicApiLimit,
        });

        return integrations;
    }

    getItemProps = (item, showPrompt, disabled, configurable = true) => {
        const { currentValues, items } = this.props;
        const { updatingId } = this.state;
        const index = items.findIndex(it => it[intgAttributes.id] === item[intgAttributes.id]);
        const currentValue = currentValues[fieldNames.integrations][index];
        const enabled = currentValue && currentValue[fieldNames.switch];
        const updating = updatingId === item[intgAttributes.id];
        const name = `${fieldNames.integrations}.${index}.${fieldNames.switch}`;

        return {
            component: IntegrationListItem,
            name,
            onChange: (event, value) => this.handleChange(name, value, item, showPrompt),
            parse: parseSwitchValue,
            updating,
            disabled,
            configurable,
            props: {
                ...item,
                enabled,
                href: createHref[item[intgAttributes.slug]],
            },
        };
    }

    handleChange = (name, value, item, showPrompt) => {
        const params = {
            id: item[intgAttributes.id],
            is_active: value,
        };

        if (!value) {
            this.showPrompt(name, item[intgAttributes.slug], showPrompt, params);
        } else {
            this.handleUpdateState(params);
        }
    }

    showPrompt = (name, slug, show, params) => {
        if (storages.includes(slug)) {
            return this.setState({
                updatingField: name,
                updatingSlug: slug,
            }, () => show(params, null, {
                fetch: () => settingsIntegrationsStorageCollection.fetch({ slug }),
                parse: response => parseStorageResponse(titles[slug], response),
            }));
        }

        return this.setState({
            updatingField: name,
            updatingSlug: slug,
        }, () => show(params));
    }

    handleUpdateState = ({ id, is_active }) => {
        const { update } = this.props;

        this.setState({
            updatingId: id,
        });

        update({
            params: { id, is_active, },
        }, {
            onSuccess: this.handleUpdateStateSuccess,
            onError: this.handleUpdateStateError,
        });
    }

    handleUpdateStateError = () => {
        this.setState({
            updatingId: undefined,
            updatingSlug: undefined,
        });
    }

    handleUpdateStateSuccess = () => {
        this.setState({
            updatingId: undefined,
            updatingSlug: undefined,
        });
    }

    handlePromptDiscard = () => {
        const { updatingField } = this.state;
        const { change } = this.props;

        change(updatingField, true);

        this.setState({
            updatingField: undefined,
            updatingSlug: undefined,
        });
    }

    renderIntegrations({
        type, title, items, disabled
    }, prompt) {
        return (
            <div className={styles.settingsIntegrationListGroup}>
                <h3 className={styles.settingsIntegrationListGroupTitle}>
                    {title}
                </h3>

                <GridListRender
                    getItemProps={item =>
                        this.getItemProps(
                            item,
                            prompt.show,
                            disabled,
                            type !== integrationsTypes.zapier
                        )
                    }
                    rowClassName={styles.settingsIntegrationListRow}
                    ItemComponent={Field}
                    columns={3}
                    itemClassName={styles.integrationItemContainer}
                    updatingId={this.state.updatingId}
                    items={items}
                />
            </div>
        );
    }

    render() {
        const { fetching, breadcrumbs } = this.props;
        const { updatingSlug } = this.state;

        if (fetching) {
            return <LoadingIndicator centered />;
        }

        return (
            <GridContainer className={styles.settingsIntegrationListContainer}>
                <ModuleTitle>Integrations</ModuleTitle>
                <Clearfix />
                <PromptProvider
                    description={deactivationConfirms[updatingSlug]}
                    onAccept={this.handleUpdateState}
                    onCancel={this.handlePromptDiscard}
                >
                    {prompt => (
                        <div className={styles.settingsIntegrationList}>
                            <div className={styles.settingsIntegrationBreadcrumbs}>
                                <Breadcrumbs items={breadcrumbs} />
                            </div>
                            <div>
                                <Text className={styles.integrationListSubtitle}>Available Integrations</Text>
                                {shouldRenderNoAvailableIntegrations(this.integrationsByType)
                                    ? <Text className={styles.integrationsAvailable}>No integrations available</Text>
                                    : this.integrationsByType.map(
                                        integration => !integration.disabled && this.renderIntegrations(integration, prompt)
                                    )}
                            </div>
                            {shouldRenderDisabledIntegrations(this.integrationsByType) && (
                                <div className={styles.settingsIntegrationOther}>
                                    <Text className={styles.integrationListSubtitle}>Additional Integrations</Text>
                                    <IntegrationsError classNameIcon={styles.integrationLockIcon} />
                                    {this.integrationsByType.map(
                                        integration => integration.disabled && this.renderIntegrations(integration, prompt)
                                    )}
                                </div>
                            )}
                        </div>

                    )}
                </PromptProvider>
                <Details />
            </GridContainer>
        );
    }
}

IntegrationList.defaultProps = {
    currentValues: {
        [fieldNames.integrations]: [],
    },
    breadcrumbs: []
};

const valuesShape = {
    [fieldNames.integrations]: PropTypes.array,
};

IntegrationList.propTypes = {
    currentValues: PropTypes.shape(valuesShape),
    communicationItems: PropTypes.array.isRequired,
    storageItems: PropTypes.array.isRequired,
    zapierItems: PropTypes.array.isRequired,
    publicApiItems: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    breadcrumbs: PropTypes.array,
    zapierIntegrationLimit: PropTypes.bool.isRequired,
    communicationIntegrationLimit: PropTypes.bool.isRequired,
    storageIntegrationLimit: PropTypes.bool.isRequired,
    publicApiLimit: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const items = selectItems(state);
    const zapierIntegrationLimit = subscriptionLimitSelectors
        .getLimitByEntity(state, subscriptionFields.zapier_integration)?.value;
    const communicationIntegrationLimit = subscriptionLimitSelectors
        .getLimitByEntity(state, subscriptionFields.communication_integration)?.value;
    const storageIntegrationLimit = subscriptionLimitSelectors
        .getLimitByEntity(state, subscriptionFields.storage_integration)?.value;
    const publicApiLimit = subscriptionLimitSelectors
        .getLimitByEntity(state, subscriptionFields.public_api)?.value;

    return {
        currentValues: {
            [fieldNames.integrations]: formSelector(state, fieldNames.integrations) || [],
        },
        initialValues: {
            [fieldNames.integrations]: items,
        },
        fetching: selectFetching(state),
        items,
        communicationItems: selectCommunicationItems(state),
        storageItems: selectStorageItems(state),
        zapierItems: selectZapierItems(state),
        publicApiItems: selectPublicApiItems(state),
        zapierIntegrationLimit,
        communicationIntegrationLimit,
        storageIntegrationLimit,
        publicApiLimit,
    };
}

const mapDispatchToProps = {
    update: settingsIntegrationsModel.actions.update.request,
};

export default flow(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    }),
    connect(mapStateToProps, mapDispatchToProps),
)(IntegrationList);
