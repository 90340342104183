import { createSelector, defaultMemoize } from 'reselect';
import { get } from 'lodash';
import { Collection } from '../../../shared/entities-v2';
import { integrationAttributes } from '../../settings-integrations/constants';

export { slugs } from '../../settings-integrations/config';

const integrationsCollection = new Collection({
    apiUrl: '/integrations',
    name: 'application-integrations',
});

const selectIntegrationBySlug = defaultMemoize((slug) => {
    const findBySlug = item => get(item, integrationAttributes.slug) === slug;
    const getIntegration = items => items.find(findBySlug);

    return createSelector(
        integrationsCollection.selectors.getItems,
        getIntegration,
    );
});

export const selectIntegrationIsActive = defaultMemoize(slug =>
    createSelector(
        selectIntegrationBySlug(slug),
        integration => get(integration, integrationAttributes.is_active),
    ));


export default integrationsCollection;
