import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router';
import { getProactiveNotificationHref } from '../../../../modules/notifications/notification-utils';
import Notification from '../index';
import { Col, Row } from '../../index';
import './item.css';

export default function ProactiveNotification(props) {
    const {
        id, is_read, data, in_app_message, created_at, getLink, tag_name,
        onNotificationClick, updatingItemId, update, className, tooltipContentClassName
    } = props;

    return (
        <Link
            className={cx('notification-item proactive-notification', className)}
            key={id}
            to={getLink ? getLink(props) : getProactiveNotificationHref(tag_name, props)}
            onClick={() => onNotificationClick(id, is_read)}
        >
            <Notification.Container
                component={Row}
                id={id}
                is_read={is_read}
                updatingItemId={updatingItemId}
            >
                <Notification.Author
                    avatar={data.image}
                    first_name={data.author.user_profile.first_name}
                    last_name={data.author.user_profile.last_name}
                    component={Col}
                    base={2}
                />
                <Notification.Content
                    full_name={data.author.user_profile.full_name}
                    message={in_app_message.message}
                    created_at={created_at}
                    component={Col}
                    base={9}
                    xs={10}
                />
                <Notification.Status
                    handleStateChange={update}
                    id={id}
                    is_read={is_read}
                    component={Col}
                    base={1}
                    tooltipContentClassName={tooltipContentClassName}
                />
            </Notification.Container>
        </Link>
    );
}

ProactiveNotification.defaultProps = {
    onNotificationClick: () => { },
    updatingItemId: undefined,
    update: () => { },
    className: undefined,
    getLink: undefined,
    tooltipContentClassName: undefined
};

ProactiveNotification.propTypes = {
    onNotificationClick: PropTypes.func,
    updatingItemId: PropTypes.number,
    update: PropTypes.func,
    id: PropTypes.number.isRequired,
    is_read: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    in_app_message: PropTypes.object.isRequired,
    created_at: PropTypes.string.isRequired,
    className: PropTypes.string,
    getLink: PropTypes.func,
    tooltipContentClassName: PropTypes.string,
    tag_name: PropTypes.string.isRequired,
};
