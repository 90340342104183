import request from '../../../request';
import { List, createDetails } from '../../../shared/entities';

const apiUrl = '/user_file_storages';

export const list = new List({
    apiUrl,
    name: 'profile-integration-storages',
    parse(response) {
        return {
            data: response,
        };
    }
});

export const details = createDetails({
    apiUrl,
    name: 'profile-integration-storage-details',
    extendAPI: {
        update({ data }) {
            return request.post(apiUrl, {
                storage_type: data.slug,
                code: data.code,
                redirect_uri: data.redirect_uri,
            });
        },
        remove({ data }) {
            return request.delete(apiUrl, {
                data: { storage_type: data.slug },
            }).then(() =>
                request.get(`${apiUrl}/${data.slug}`, {
                    params: {
                        redirect_uri: data.redirect_uri,
                    }
                }));
        }
    },
});

function findIntegrationBySlug(items, slug) {
    return items.find(item => item.storage_type.slug === slug);
}

export function getIntegrationBySlug(state, slug) {
    const { items } = list.selector(state);
    return findIntegrationBySlug(items, slug);
}

export function getIntegrationIcon(item) {
    return item.storage_type.logo_url;
}
