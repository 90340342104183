import { createAction, createReducer } from 'redux-act';
import u from 'updeep';
import { get, bindAll, omit } from 'lodash';
import { takeEvery, select, put } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import queryString from 'query-string';
import { selectSearchQuery } from '../../../shared/selectors/routing';
import { GETParams as ServiceAuthGETParams, requiredGETParams } from './constants';

const errorDictionary = {
    missingParams: 'Missing Params',
    permissionDenied: 'Permission denied',
    authenticationError: 'Authentication error',
};

function isValidAttemptObject(object = {}) {
    if (!object) {
        return false;
    }

    const reqParams = Object.values(requiredGETParams);
    const getParams = Object.keys(object);

    return reqParams.filter(r => getParams.includes(r)).length === reqParams.length;
}

class ServiceAuthModelPlugin {
    name = 'service-auth-model-plugin'

    initialState = {
        data: null,
        error: null,
    }

    constructor() {
        bindAll(this, [
            'handleAttempt',
            'handleCallback',
            'handleError',
            'handleDeny',
        ]);
    }

    initialize = (parent) => {
        this.actions = this.getActions(parent.name);
        this.reducer = this.getReducer(parent.actions);
        this.selectors = this.getSelectors(parent.selectors);
        this.saga = this.getSaga();


        return {
            reducer: this.reducer,
            actions: this.actions,
            saga: this.saga,
            selectors: this.selectors,
        };
    }

    getActions = (parentName) => {
        return {
            attempt: createAction(`${parentName}/SERVICE_AUTH_ATTEMPT`),
            attemptSuccess: createAction(`${parentName}/SERVICE_AUTH_ATTEMPT_SUCCESS`),
            attemptError: createAction(`${parentName}/SERVICE_AUTH_ATTEMPT_ERROR`),
            callback: createAction(`${parentName}/SERVICE_AUTH_CALLBACK`),
            cleanup: createAction(`${parentName}/SERVICE_AUTH_CLEANUP`),
            allow: createAction(`${parentName}/SERVICE_AUTH_ALLOW`),
            deny: createAction(`${parentName}/SERVICE_AUTH_DENY`),
        };
    }

    getReducer = () => {
        const reducerMap = {
            [this.actions.attemptSuccess]: (state, action) => u({ data: action }, state),
            [this.actions.attemptError]: (state, action) => u({ data: null, error: action }, state),
            [this.actions.cleanup]: () => this.initialState,
        };

        return createReducer(reducerMap, this.initialState);
    }

    getSelectors = (parentSelectors) => {
        const getBranch = createSelector(parentSelectors.getPluginsState, root => get(root, this.name));
        const getAttemptData = createSelector(getBranch, root => root.data);
        const getAttemptError = createSelector(getBranch, root => root.error);
        const getAttemptInProgress = createSelector(
            [getAttemptData, selectSearchQuery],
            (storeAttemptData, queryAttemptData) => isValidAttemptObject(storeAttemptData) || isValidAttemptObject(queryAttemptData)
        );
        const getIsCallbackState = createSelector(
            [getAttemptData, selectSearchQuery],
            (attemptData, query) => isValidAttemptObject(attemptData) && query.code && query.oauth_type
        );

        return {
            getBranch,
            getAttemptData,
            getAttemptError,
            getAttemptInProgress,
            getIsCallbackState,
        };
    }

    * handleAttempt() {
        const storedAttemptObject = yield select(this.selectors.getAttemptData);

        if (storedAttemptObject) {
            return;
        }

        yield put(this.actions.cleanup());
        const attemptObject = yield select(selectSearchQuery);

        if (isValidAttemptObject(attemptObject)) {
            yield put(this.actions.attemptSuccess(attemptObject));
            return;
        }

        yield put(this.actions.attemptError(errorDictionary.missingParams));
    }

    * handleCallback() {
        const storedAttemptObject = yield select(this.selectors.getAttemptData);
        const query = yield select(selectSearchQuery);
        yield put(this.actions.cleanup());

        const redirectUri = get(storedAttemptObject, ServiceAuthGETParams.redirectUri);
        const nextQuery = queryString.stringify({
            ...omit(storedAttemptObject, [
                ServiceAuthGETParams.redirectUri,
                ServiceAuthGETParams.clientId,
            ]),
            code: query.code,
            oauth_type: query.oauth_type,
        });

        window.location.href = `${redirectUri}?${nextQuery}`;
    }

    * handleError() {
        const attemptObject = yield select(selectSearchQuery);
        const errorMessage = yield select(this.selectors.getAttemptError);
        yield put(this.actions.cleanup());
        const query = queryString.stringify({ error: errorMessage });
        const redirectUri = get(attemptObject, ServiceAuthGETParams.redirectUri);

        window.location.href = `${redirectUri}?${query}`;
    }

    * handleDeny() {
        const attemptObject = yield select(this.selectors.getAttemptData);
        const errorMessage = errorDictionary.permissionDenied;
        const query = queryString.stringify({ error: errorMessage });
        const redirectUri = get(attemptObject, ServiceAuthGETParams.redirectUri);

        window.location.href = `${redirectUri}?${query}`;
    }

    getSaga = () => {
        const {
            actions, handleAttempt, handleCallback, handleError,
            handleDeny
        } = this;

        return function* serviceAuthModelPluginSaga() {
            yield takeEvery(actions.attempt, handleAttempt);
            yield takeEvery(actions.allow, handleCallback);
            yield takeEvery(actions.deny, handleDeny);
            yield takeEvery(actions.attemptError, handleError);
        };
    }
}

export default ServiceAuthModelPlugin;
