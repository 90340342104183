import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import formatDate, { applyUTCOffset } from './format';
import { selectUserDateFormat } from '../../../modules/user/selectors';
import styles from './formatted-date.module.css';

function FormattedDate({
    date, userDateFormat, utcOffset, className,
}) {
    const withOffset = utcOffset
        ? applyUTCOffset(date)
        : date;

    return (
        <span className={classnames(styles.formattedDate, { [className]: !!className })}>
            {formatDate(withOffset, userDateFormat)}
        </span>
    );
}

FormattedDate.defaultProps = {
    utcOffset: false,
    className: undefined,
    date: undefined,
};

FormattedDate.propTypes = {
    date: PropTypes.string,
    utcOffset: PropTypes.bool,
    userDateFormat: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const mapStateToProps = state => ({
    userDateFormat: selectUserDateFormat(state),
});

export default connect(mapStateToProps)(FormattedDate);
