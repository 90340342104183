import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip } from '../../../shared/components';
import Clickable from '../clickable/component';

export default class NotificationStatus extends Component {
    handleReadClick = (event) => {
        event.stopPropagation();
        const {
            handleStateChange, id, is_read
        } = this.props;

        handleStateChange({ id, is_read });
    }

    render() {
        const { component, is_read, tooltipContentClassName } = this.props;
        const RenderComponent = component;

        return (
            <RenderComponent base={1} className="notifications-item-status">
                <Tooltip
                    className="is-read-tooltip"
                    tooltipTextClassName={cx('notifications-item-status-tooltip-content', tooltipContentClassName)}
                    text={is_read ? 'Mark as Unread' : 'Mark as Read'}
                >
                    <Clickable
                        className="notification-read-control"
                        onClick={this.handleReadClick}
                        action="mark-notification-read"
                        component="span"
                    >
                        <div className="circle empty">
                            <div
                                className={cx({
                                    'circle-empty-fill': is_read,
                                    'circle-fill': !is_read,
                                })}
                            />
                        </div>
                    </Clickable>
                </Tooltip>
            </RenderComponent>
        );
    }
}

NotificationStatus.defaultProps = {
    component: 'div',
    tooltipContentClassName: ''
};

NotificationStatus.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]),
    id: PropTypes.number.isRequired,
    handleStateChange: PropTypes.func.isRequired,
    is_read: PropTypes.bool.isRequired,
    tooltipContentClassName: PropTypes.string
};
