import React from 'react';
import PropTypes from 'prop-types';
import Text from '../text';
import Icon from '../icon/component';
import { errorFieldDataAttribute } from '../../modules/form/constants';

function InputErrorMessage({ errors, touched }) {
    if (!errors.length) {
        return null;
    }

    const containerProps = {
        [errorFieldDataAttribute]: !!errors,
    };

    return (
        <div className="input-error-message" {...containerProps}>
            {touched && errors.map(error => error && (
                <span className="input-error-message-item" key={error}>
                    <Text
                        component={Icon}
                        name="times-circle"
                        type={Icon.types.solid}
                        styleType="danger"
                        className="input-error-message-icon"
                    />

                    {error} <br />
                </span>
            ))}
        </div>
    );
}

InputErrorMessage.defaultProps = {
    errors: [],
    touched: true,
};

InputErrorMessage.propTypes = {
    errors: PropTypes.array,
    touched: PropTypes.bool
};

export default InputErrorMessage;
