import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Icon } from '../../components';
import Item from './item';
import './styles.css';

export default function Breadcrumbs({
    items, children, itemContentRenderer, restrictions
}) {
    return (
        <nav className="breadcrumbs-container">
            <div className="breadcrumbs-container-icon-wrapper breadcrumbs-container-home-wrapper">
                <Link to="/dashboard">
                    <Icon className="breadcrumbs-container-icon" type={Icon.types.solid} name="home" />
                </Link>
            </div>
            {items.map((item, index) => (
                <Item
                    key={item.id || index}
                    index={index}
                    total={items.length}
                    contentRenderer={itemContentRenderer}
                    restrictions={restrictions}
                    {...item}
                />
            ))}

            {children && (
                <div className="breadcrumbs-additional">
                    {children}
                </div>
            )}
        </nav>
    );
}

Breadcrumbs.defaultProps = {
    items: [],
    children: undefined,
    restrictions: {},
    itemContentRenderer: undefined,
};

Breadcrumbs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        }),
    ),
    children: PropTypes.node,
    restrictions: PropTypes.oneOfType(
        [PropTypes.object, PropTypes.array]
    ), // eslint-disable-line
    itemContentRenderer: PropTypes.func,
};
