import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router';
import { getAddingTooltipByEntity } from '../../../modules/wizard-goalmap-tree/list/utils';
import { getChildrenCount } from '../../utils/entity';
import { createHrefByTypeCode } from '../../utils/compose-href';
import {
    Button, StatusIndicator, TableView, Badge, GridContainer, Tooltip, Icon,
} from '../index';

import './styles.css';

function getUrlType(item) {
    return createHrefByTypeCode(item.type_code, item);
}

export default function EntitiesTableView({
    items, sorts, placeholder, itemProps, titles,
}) {
    return (
        <GridContainer>
            <TableView
                className="goalmap-segments-table-view entities-table-view"
                placeholder={(placeholder) && (
                    <TableView.Placeholder>
                        {placeholder}
                    </TableView.Placeholder>
                )}
            >
                <TableView.Header>
                    <TableView.HeaderCell className="entities-table-view-column-health">Health</TableView.HeaderCell>
                    <TableView.HeaderCell
                        className="entities-table-view-column-title"
                        align={TableView.align.LEFT}
                        sortDirection={sorts.title}
                    >
                        Title
                    </TableView.HeaderCell>
                    <TableView.HeaderCell
                        align={TableView.align.LEFT}
                        className="entities-table-view-column-owner"
                    >
                        Owner
                    </TableView.HeaderCell>
                    <TableView.HeaderCell className="entities-table-view-column-children" />
                </TableView.Header>
                {items.map((item) => {
                    const childrenCount = getChildrenCount(item);
                    return (
                        <TableView.Row>
                            <TableView.Cell className="entities-table-view-cell">
                                <StatusIndicator
                                    status={item.progress_status}
                                    size="small"
                                />
                            </TableView.Cell>
                            <TableView.Cell align={TableView.align.LEFT} className="entities-table-view-cell">
                                <Link to={getUrlType(item)}>
                                    {item.title}
                                </Link>
                            </TableView.Cell>
                            <TableView.Cell align={TableView.align.LEFT} className="entities-table-view-cell">
                                {_.get(item, 'owner.user_profile.first_name')}{' '}
                                {_.get(item, 'owner.user_profile.last_name')}
                            </TableView.Cell>
                            <TableView.Cell className="entities-table-view-cell">
                                <Fragment>
                                    {childrenCount > 0 && (
                                        <Badge theme={Badge.theme.ROUND_ACCENT}>
                                            {childrenCount}
                                        </Badge>
                                    )}

                                    {(!childrenCount && itemProps.canCreate) && (
                                        <Tooltip
                                            text={getAddingTooltipByEntity({ entity_type: item.type_code }, titles)}
                                            position={Tooltip.position.top}
                                        >
                                            <Button
                                                className="segment-children-list-item-add"
                                                styleType={Button.styleTypes.accent}
                                                size={Button.sizes.small}
                                                onClick={() =>
                                                    itemProps.onAdd({ parent_id: item.id }, item)
                                                }
                                                withShadow
                                                round
                                            >
                                                <Icon name="plus" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Fragment>
                            </TableView.Cell>
                        </TableView.Row>
                    );
                })}
            </TableView>
        </GridContainer>
    );
}

EntitiesTableView.defaultProps = {
    items: [],
    sorts: {},
    itemProps: {},
    placeholder: undefined
};

EntitiesTableView.propTypes = {
    items: PropTypes.array,
    sorts: PropTypes.object,
    placeholder: PropTypes.string,
    itemProps: PropTypes.object,
    titles: PropTypes.object.isRequired,
};
