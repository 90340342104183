import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect, { Async } from 'react-select';
import classnames from 'classnames';
import ReactSelectSticky, { AsyncSticky } from '../../components/react-select-sticky/component';
import { applicationSettingsSelectors } from '../../../modules/application';
import { debouncePromise } from '../../utils/common';
import Icon from '../../components/icon/component';
import InputContainer from '../../components/input/container';

import './styles.css';
import 'react-select/dist/react-select.css';

class Select extends Component {
    constructor(args) {
        super(args);

        this.state = {
            errors: [],
            isInitializedWithOptions: false
        };
        this.debouncedHandleLoadOptions = debouncePromise(this.handleLoadOptions, 500);
    }

    componentDidMount() {
        if (this.el.select) {
            if (typeof this.el.select.input.getInput === 'function') {
                this.realInput = this.el.select.input.getInput();
            } else {
                const [input] = this.el.select.input.getElementsByTagName('input');
                this.realInput = input;
            }
        }

        if (this.realInput) {
            this.realInput.addEventListener('invalid', this.onInvalid);
        }

        if (this.props.autoFocus && this.el) {
            this.el.focus();
        }
    }

    componentWillUnmount() {
        if (this.realInput) {
            this.realInput.removeEventListener('invalid', this.onInvalid);
        }
    }

    onInvalid = (event) => {
        event.preventDefault();

        const clone = this.state.errors.slice();
        clone.push(event.target.validationMessage);

        this.setState({
            errors: clone
        });
    }

    getMergedErrors = () => {
        const mergedErrors = [
            ...this.state.errors,
            ...(Array.isArray(this.props.meta.error) ? this.props.meta.error : [])
        ];

        return [...new Set(mergedErrors)];
    }

    dropValidationState = () => {
        this.setState({
            errors: []
        });
    };

    handleLoadOptions = (...args) => {
        const { loadOptions, options } = this.props;
        const { isInitializedWithOptions } = this.state;

        if ((options && options.length) && !isInitializedWithOptions) {
            this.setState({ isInitializedWithOptions: true });
            return Promise.resolve({ options });
        }

        return loadOptions(...args);
    };

    isSticky() {
        const { sticky, isMobile } = this.props;
        return sticky && !isMobile;
    }

    render() {
        const {
            input,
            multi,
            className,
            defaultParams,
            placeholder,
            loadOptions,
            valueKey,
            labelKey,
            value,
            label,
            tooltip,
            entity,
            changeHandler,
            component,
            containerClassName,
            alwaysRenderLabel,
            sticky,
            options,
            horizontalLabel,
            noMargin,
            tooltipTextClassName,
            shouldSetOnBlur,
            ...rest
        } = this.props;

        const isSticky = this.isSticky();


        let RenderComponent = isSticky ? ReactSelectSticky : ReactSelect;

        if (loadOptions) {
            RenderComponent = isSticky ? AsyncSticky : Async;
        }

        if (component) {
            RenderComponent = component;
        }

        const selectValue = input ? input.value : value;
        const errors = this.getMergedErrors();

        return (
            <InputContainer
                errors={errors}
                className={containerClassName}
                label={label}
                tooltip={tooltip}
                tooltipTextClassName={tooltipTextClassName}
                horizontalLabel={horizontalLabel}
                noMargin={noMargin}
            >
                <RenderComponent
                    {...input}
                    ref={(el) => { this.el = el; return null; }}
                    placeholder={placeholder}
                    valueKey={valueKey}
                    labelKey={labelKey}
                    multi={multi}
                    className={classnames('input-select', {
                        [className]: className,
                        'sticky-dropdown': isSticky,
                        'input-error': !!errors.length,
                    })}
                    onChange={(newValue) => {
                        const safeOnChange = input
                            ? input.onChange
                            : changeHandler;

                        if (!newValue) {
                            safeOnChange({});
                        } else if (multi || entity === 'object') {
                            safeOnChange(newValue);
                        } else {
                            safeOnChange(newValue[valueKey]);

                            if (input && input.onBlur) {
                                input.onBlur(newValue[valueKey]);
                            }
                        }
                        this.dropValidationState();
                    }}
                    {...(shouldSetOnBlur && { onBlur: () => {} })}
                    arrowRenderer={({ isOpen }) => (
                        <Icon
                            name={isOpen ? 'chevron-up' : 'chevron-down'}
                        />
                    )}
                    loadOptions={this.debouncedHandleLoadOptions}
                    {...rest}
                    value={selectValue}
                    options={options}
                    inputProps={{
                        className: 'select-test',
                        onInvalid: this.onInvalid
                    }}
                />
            </InputContainer>
        );
    }
}

Select.defaultProps = {
    multi: false,
    className: undefined,
    defaultParams: undefined,
    placeholder: undefined,
    loadOptions: undefined,
    valueKey: 'id',
    labelKey: 'title',
    changeHandler() {},
    input: undefined,
    value: undefined,
    entity: 'value',
    meta: {},
    sticky: true,
    component: undefined,
    containerClassName: undefined,
    alwaysRenderLabel: false,
    label: undefined,
    tooltip: undefined,
    tooltipTextClassName: undefined,
    isMobile: false,
    options: [],
    stickyOffsetTop: undefined,
    stickyOffsetBottom: undefined,
    stickyConstraintTop: undefined,
    stickyConstraintBottom: undefined,
    stickyConstraintTopAlign: undefined,
    stickyConstraintBottomAlign: undefined,
    horizontalLabel: false,
    noMargin: false,
    shouldSetOnBlur: true,
    autoFocus: false,
};

Select.propTypes = {
    className: PropTypes.string,
    input: PropTypes.object,
    sticky: PropTypes.bool,
    stickyOffsetTop: PropTypes.number,
    stickyOffsetBottom: PropTypes.number,
    stickyConstraintTop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    stickyConstraintBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    stickyConstraintTopAlign: PropTypes.string,
    stickyConstraintBottomAlign: PropTypes.string,
    meta: PropTypes.shape({
        error: PropTypes.oneOf([
            PropTypes.array,
            PropTypes.string,
        ])
    }),
    loadOptions: PropTypes.func,
    multi: PropTypes.bool,
    alwaysRenderLabel: PropTypes.bool,
    changeHandler: PropTypes.func,
    defaultParams: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    placeholder: PropTypes.node,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    entity: PropTypes.oneOf([
        'value', 'object'
    ]),
    options: PropTypes.array,
    isMobile: PropTypes.bool,
    component: PropTypes.node,
    containerClassName: PropTypes.string,
    label: PropTypes.node,
    tooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    tooltipTextClassName: PropTypes.string,
    horizontalLabel: PropTypes.bool,
    noMargin: PropTypes.bool,
    shouldSetOnBlur: PropTypes.bool,
    autoFocus: PropTypes.bool,
};


function mapStateToProps(state) {
    return {
        isMobile: applicationSettingsSelectors.isMobileSelector(state),
    };
}

export default connect(mapStateToProps)(Select);
