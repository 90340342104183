import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import config from '../../../config';
import StatusIndicator from '../status-indicator/component';
import DropdownDeprecated from '../dropdown/deprecated/component';
import Clickable from '../clickable/component';
import Icon from '../icon/component';
import './styles.css';

const { statusMap } = config;

export default class StatusInput extends Component {
    render() {
        const { className, value, onChange } = this.props;
        const safeValue = value || 1;
        const restValues = { ...statusMap };
        delete restValues[safeValue];

        return (
            <DropdownDeprecated
                ref={(el) => { this.dropdown = el; }}
                containerClassName={
                    classnames('status-input-container', {
                        [className]: className
                    })
                }
                trigger={() => (
                    <StatusIndicator
                        className="status-input-selected-value"
                        status={safeValue}
                    >
                        <Icon name="chevron-down" />
                    </StatusIndicator>
                )}
                className="status-input"
            >
                {
                    Object.keys(restValues).map(keyName => (
                        <Clickable
                            className={classnames('status-item', {
                                active: value === keyName
                            })}
                            action="change-status"
                            key={keyName}
                            onClick={() => {
                                this.dropdown.hide();
                                onChange(keyName);
                            }}
                        >
                            <StatusIndicator
                                status={keyName}
                            />
                        </Clickable>
                    ))
                }
            </DropdownDeprecated>
        );
    }
}

StatusInput.defaultProps = {
    className: undefined
};

StatusInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};
