import { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

const directions = {
    up: 'up',
    down: 'down',
};

export default class ScrollProvider extends Component {
    constructor(args) {
        super(args);

        this.throttledHandleScroll = throttle(this.handleScroll, 150);
    }

    state = {
        direction: undefined,
        offset: 0,
    }

    componentDidMount() {
        document.addEventListener('scroll', this.throttledHandleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.throttledHandleScroll);
    }

    computeDirection = (offset, prevOffset, prevDirection) => {
        const delta = Math.abs(offset - prevOffset);

        if (delta < 10) {
            return prevDirection;
        }

        return (offset > prevOffset)
            ? directions.down
            : directions.up;
    }

    handleScroll = (event) => {
        const { scrollTop } = event.target.scrollingElement;
        const direction = this.computeDirection(scrollTop, this.state.offset, this.state.direction);

        this.setState({
            offset: scrollTop,
            direction,
        });
    }

    render() {
        return this.props.children({
            offset: this.state.offset,
            direction: this.state.direction,
        });
    }
}

ScrollProvider.directions = directions;

ScrollProvider.propTypes = {
    children: PropTypes.func.isRequired,
};
