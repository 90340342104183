import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, getFormSubmitErrors } from 'redux-form';
import Composer from 'react-composer';
import moment from 'moment';
import cx from 'classnames';
import {
    get, debounce, omit, pick,
} from 'lodash';
import {
    Row, Col, Input, InputErrorMessage, Text, MeasureUnitSelector, ThresholdsParamsSection, Icon,
    GridContainer, LoadingIndicator, Toggle,
} from '../../../shared/components';
import { KpiTypeSelectorItem, Select } from '../../../shared/modules';
import { getFrequencyType } from '../../../shared/utils/date';
import { promisifyAsyncAction } from '../../../shared/utils/common';
import { normalizeNumberValue } from '../../../shared/utils/form';
import config from '../../../config';
import { SubscriptionLimitProviders } from '../../subscription';
import ContactSalesMailtoProvider from '../../subscription/contact-sales-mailto-provider';
import { getVisibleTreeNavigation } from '../../plan-navigator/reducer';
import { getCurrenciesFormatsWithLabel } from '../../application/localizations/selectors';
import { applicationSettingsSelectors } from '../../application';
import PeriodSelector from '../period-selector';
import {
    attributes, kpiTypes, rangeOfMeasure, states, operations, chartTypes, types as typesOfMeasure,
} from '../constants';
import { computeDefaultTresholdValue, getThresholdLabels, } from '../utils';
import periodTargetCollection from './period-target-collection';
import {
    linearKpiValuesChanged, getRangeOfMeasureLinear, additionalFieldChanged,
    periodTargetsChanged, isLinearFieldsValid, isPeriodTargetRelatedValuesValid,
    isDataSourceClearNeeded, isAdvCalcSourcesChanged, getCurrencyFromAdvCalcSourceEntity,
    isAdvCalcSourcesIsValid, isPeriodTargetsValid
} from './utils';
import { PeriodTargetsLinear, PeriodTargetsStepped, PeriodTargetsStatic } from './period-targets';
import DataSources from './data-sources/data-sources';

import '../styles/settings-form.css';


function renderTargetValueLabel(kpiType) {
    return kpiType === kpiTypes.static.type ? 'Target Value' : (<span>Overall&nbsp;Target&nbsp;Value</span>);
}

const shouldSetAdvCalcOperation = (advCalcEnabled, prevAdvCalcEnabled, advCalcOperation) =>
    advCalcEnabled !== prevAdvCalcEnabled && !advCalcOperation;

const kpiTypesArray = [
    kpiTypes.static,
    kpiTypes.linear,
    kpiTypes.stepped,
];

const components = [
    <SubscriptionLimitProviders.KPITypes />,
    <SubscriptionLimitProviders.KPIAdvCalc />
];

class MilestoneSettingsForm extends Component {
    constructor(props) {
        super(props);

        this.debouncedUpdatePeriodTargets = debounce(this.updatePeriodTargets, 500);
    }

    componentDidUpdate(prevProps) {
        const { currentValues, change, defaultCurrencyCode, } = this.props;
        const isStatic = get(currentValues, attributes.kpi_type) === kpiTypes.static.type;
        const isAdvCalcEnabled = get(currentValues, attributes.advanced_calculation_enabled);
        const prevAdvCalcEnabled = prevProps.currentValues?.[attributes.advanced_calculation_enabled];
        const advCalcDataSources = get(currentValues, attributes.advanced_calculation_sources);

        if (linearKpiValuesChanged(currentValues, prevProps.currentValues)) {
            const currentRange = get(currentValues, attributes.range_of_measure);
            const nextRange = getRangeOfMeasureLinear(
                get(currentValues, attributes.start_value),
                get(currentValues, attributes.target_value)
            );

            if (nextRange !== currentRange) {
                change(attributes.range_of_measure, nextRange);
                this.handleRangeChange(nextRange);
            }
        }

        if (isDataSourceClearNeeded(prevProps.currentValues, currentValues)) {
            change(attributes.advanced_calculation_sources, []);
        }

        const isAdditionalFieldsChanged = additionalFieldChanged(
            currentValues,
            prevProps.currentValues
        );
        const isPeriodTargetChanged = periodTargetsChanged(
            currentValues,
            prevProps.currentValues
        );

        if (isAdditionalFieldsChanged && !isStatic && isAdvCalcSourcesIsValid(advCalcDataSources, isAdvCalcEnabled)) {
            const isDraft = get(currentValues, attributes.state) === states.draft;
            const omitPeriodTargets = isDraft;
            this.debouncedUpdatePeriodTargets(omitPeriodTargets);
        }

        if (isPeriodTargetChanged && !isStatic) {
            this.debouncedUpdatePeriodTargets();
        }

        if (shouldSetAdvCalcOperation(isAdvCalcEnabled, prevAdvCalcEnabled, currentValues[attributes.advanced_calculation_type])) {
            const nextOperation = isAdvCalcEnabled ? operations.sum : null;
            change(attributes.advanced_calculation_type, nextOperation);
        }

        if (isAdvCalcEnabled && isAdvCalcSourcesChanged(prevProps.currentValues, currentValues)) {
            change(attributes.currency_format, getCurrencyFromAdvCalcSourceEntity(currentValues) || defaultCurrencyCode);
        }
    }

    updatePeriodTargets = (omitPeriodTargets) => {
        const {
            fetchPeriodTargets, currentValues, formatData
        } = this.props;

        if (
            !isLinearFieldsValid(currentValues)
            || !isPeriodTargetRelatedValuesValid(currentValues)
            || !isPeriodTargetsValid(currentValues)
        ) {
            return;
        }

        let dataToSend = currentValues;

        if (omitPeriodTargets) {
            dataToSend = omit(dataToSend, attributes.kpi_period_targets);
        }

        if (get(currentValues, attributes.state) === states.draft) {
            dataToSend = omit(dataToSend, attributes.id);
        }

        promisifyAsyncAction(fetchPeriodTargets, formatData(dataToSend))
            .then(this.updatePeriodTargetsSuccess);
    }

    updatePeriodTargetsSuccess = (response) => {
        const { change } = this.props;
        const data = get(response, 'data');

        change(attributes.kpi_period_targets, data);
    }

    handleFrequencyChange = (e, value) => {
        const { change } = this.props;
        const type = getFrequencyType(value);
        const newStartDate = moment()
            .startOf(type)
            .format(config.apiResponseDateFormat);

        const newTargetAt = moment()
            .add(3, type)
            .endOf(type)
            .format(config.apiResponseDateFormat);

        change(attributes.target_at, newTargetAt);
        change(attributes.started_at, newStartDate);
    }

    handleRangeChange = (value) => {
        const { change } = this.props;
        const newThresholds = computeDefaultTresholdValue(value);

        change(attributes.range_of_measure, value);
        change(
            attributes.thresholds,
            newThresholds
        );
    }

    handleThresholdChange = (value) => {
        const { change } = this.props;
        change(attributes.thresholds, value);
    }

    handleTypeChange = (event, nextType) => {
        const { change, currentValues } = this.props;
        const range = get(currentValues, attributes.range_of_measure);

        if (nextType === kpiTypes.linear.type && range === rangeOfMeasure.between) {
            switch (range) {
                case rangeOfMeasure.between:
                    change(attributes.range_of_measure, rangeOfMeasure.asc);
                    change(attributes.thresholds, computeDefaultTresholdValue(rangeOfMeasure.asc));
                    break;
                case rangeOfMeasure.desc:
                    change(attributes.target_value, 0);
                    change(attributes.start_value, 100);
                    break;
                case rangeOfMeasure.asc:
                default:
                    change(attributes.target_value, 100);
                    change(attributes.start_value, 0);
                    break;
            }
        }
        switch (nextType) {
            case kpiTypes.static.type:
            case kpiTypes.linear.type:
                change(attributes.kpi_default_chart_type, chartTypes.linear);
                break;
            case kpiTypes.stepped.type:
                change(attributes.kpi_default_chart_type, chartTypes.bar);
                break;
            default:
                change(attributes.kpi_default_chart_type, chartTypes.linear);
                break;
        }
    }

    handleMeauserUnitChange = (value) => {
        const { change, defaultCurrencyCode, currentValues, } = this.props;
        if (value === typesOfMeasure.monetary) {
            change(attributes.currency_format, currentValues[attributes.currency_format] || defaultCurrencyCode);
        }
    }

    renderWithProviders = ([kpiLimit, advCalcLimit]) => {
        const { editingDisabled, disableAdvancedCalcKPI } = this.props;
        const allowedTypes = get(kpiLimit, 'value', []);
        const advCalcIsAllowed = get(advCalcLimit, 'value', false);
        const hasRestrictedTypes = allowedTypes.length < kpiTypesArray.length;

        return (
            <>
                <Row className="milestone-settings-type">
                    {kpiTypesArray.map((item) => {
                        const isRestricted = !allowedTypes.includes(item.type);

                        return (
                            <Col base={4} key={item.type}>
                                <Field
                                    name={attributes.kpi_type}
                                    component={KpiTypeSelectorItem}
                                    props={{
                                        type: item.type,
                                        description: item.description,
                                        label: item.label,
                                        disabled: editingDisabled || isRestricted,
                                        restricted: isRestricted,
                                    }}
                                    onChange={this.handleTypeChange}
                                />
                            </Col>
                        );
                    })}

                    {editingDisabled && (
                        <Col>
                            <Text
                                component="p"
                                className="milestone-settings-type-info"
                            >
                                <Text
                                    className="milestone-settings-type-info-icon"
                                    component={Icon}
                                    name="info-circle"
                                /> Some settings may not be adjusted when data points exist or when the KPI
                                is a data source for another KPI
                            </Text>
                        </Col>
                    )}
                </Row>
                <Row>

                    <Col md={12}>
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <label
                            className="milestone-settings-title"
                            htmlFor={attributes.advanced_calculation_enabled}
                        >
                            <span className="milestone-settings-title-text">
                                Advanced Calculation
                            </span>
                            {
                                !advCalcIsAllowed
                                    ? <Icon className="milestone-settings-title-icon" name="lock-alt" />
                                    : (
                                        <Field
                                            id={attributes.advanced_calculation_enabled}
                                            component={Toggle}
                                            name={attributes.advanced_calculation_enabled}
                                            disabled={editingDisabled || disableAdvancedCalcKPI}
                                        />
                                    )
                            }
                        </label>
                    </Col>
                    {(hasRestrictedTypes || !advCalcIsAllowed) && (
                        <Col base={12}>
                            <ContactSalesMailtoProvider>
                                {({ mailto }) => (
                                    <Text styleType="muted" component="p">
                                        <Text
                                            component={Icon}
                                            name="poll-h"
                                            styleType="muted"
                                            type={Icon.types.solid}
                                        /> Not all options are available with your current plan.&nbsp;
                                        <Text
                                            component="a"
                                            href={mailto}
                                            underline
                                        >
                                            Contact Support
                                        </Text> to learn more.
                                    </Text>
                                )}
                            </ContactSalesMailtoProvider>
                        </Col>
                    )}
                </Row>
            </>
        );
    }

    renderAdvancedCalculationOptions = () => {
        const {
            currentValues, formSubmitErrors, editingDisabled, change,
        } = this.props;

        return (
            <Fragment>
                <DataSources
                    title="Data sources"
                    currentValues={pick(currentValues, [
                        attributes.advanced_calculation_type,
                        attributes.advanced_calculation_sources,
                        attributes.kpi_type,
                        attributes.id,
                        attributes.started_at,
                        attributes.target_at,
                    ])}
                    disabled={editingDisabled}
                    change={change}
                />
                <p>
                    <InputErrorMessage
                        errors={get(
                            formSubmitErrors,
                            attributes.advanced_calculation_sources
                        )}
                    />
                </p>
            </Fragment>
        );
    }

    render() {
        const {
            change, currentValues, startOfPeriod, editingDisabled, className, fetchingPeriodTargets,
            isTreeOpen, currenciesFormats, formSubmitErrors
        } = this.props;

        const kpiType = get(currentValues, attributes.kpi_type);
        const advCalcEnabled = get(currentValues, attributes.advanced_calculation_enabled);

        const thresholdCurrentValues = {
            range: get(currentValues, attributes.range_of_measure),
            thresholds: get(currentValues, attributes.thresholds),
            kpiType,
        };

        return (
            <div className={cx('milestone-settings-wrap', className)}>
                <div className="milestone-settings-section">
                    <GridContainer>
                        <Composer components={components}>
                            {this.renderWithProviders}
                        </Composer>
                    </GridContainer>
                </div>

                {advCalcEnabled && (
                    <div className="milestone-settings-section">
                        <GridContainer>
                            <Row>
                                <Col base={12}>
                                    {this.renderAdvancedCalculationOptions()}
                                </Col>
                            </Row>
                        </GridContainer>
                    </div>
                )}

                <div className="milestone-settings-section">
                    <GridContainer>
                        <Row className="milestone-settings">
                            <Col base={4}>
                                <Field
                                    name={attributes.type}
                                    component={MeasureUnitSelector}
                                    disabled={editingDisabled || advCalcEnabled}
                                    props={{
                                        label: (
                                            <span>Unit of Measure <Text styleType="danger">*</Text></span>
                                        ),
                                        className: 'input-container',
                                        labelClassName: 'input-container-label'
                                    }}
                                    onChange={this.handleMeauserUnitChange}
                                />
                            </Col>
                            <Col base={4}>
                                <Field
                                    name={attributes.frequency}
                                    component={Select}
                                    onChange={this.handleFrequencyChange}
                                    props={{
                                        options: [
                                            { value: 'weekly', label: 'Weekly' },
                                            { value: 'monthly', label: 'Monthly' },
                                            { value: 'quarterly', label: 'Quarterly' },
                                            { value: 'annually', label: 'Annually' },
                                        ],
                                        labelKey: 'label',
                                        valueKey: 'value',
                                        clearable: false,
                                        label: (
                                            <span>Update Frequency <Text styleType="danger">*</Text></span>
                                        ),
                                        placeholder: 'Select frequency of measure',
                                    }}
                                    disabled={editingDisabled || advCalcEnabled}
                                />
                            </Col>
                            <Col base={4}>
                                <Field
                                    name={attributes.required_from}
                                    component={Select}
                                    onChange={(e, value) => {
                                        change(attributes.required_from, value);
                                    }}
                                    props={{
                                        options: [
                                            { value: 'first', label: 'First day of the period' },
                                            { value: 'last', label: 'Last day of the period' },
                                        ],
                                        labelKey: 'label',
                                        valueKey: 'value',
                                        clearable: false,
                                        label: (
                                            <span>Update Reminder <Text styleType="danger">*</Text></span>
                                        ),
                                        placeholder: 'Update required from',
                                    }}
                                />
                            </Col>
                        </Row>
                        {currentValues?.type === typesOfMeasure.monetary && (
                            <Row>
                                <Col base={4}>
                                    <Field
                                        name={attributes.currency_format}
                                        label="Currency"
                                        component={Select}
                                        disabled={editingDisabled || advCalcEnabled}
                                        props={{
                                            options: currenciesFormats,
                                            clearable: false,
                                            labelKey: 'label',
                                            valueKey: 'code',
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col base={4}>
                                <PeriodSelector
                                    name={attributes.started_at}
                                    label={(
                                        <span>Start Period <Text styleType="danger">*</Text></span>
                                    )}
                                    frequency={currentValues[attributes.frequency]}
                                    startOfPeriod={startOfPeriod}
                                />
                            </Col>

                            <Col base={4}>
                                <PeriodSelector
                                    name={attributes.target_at}
                                    frequency={currentValues[attributes.frequency]}
                                    label={(
                                        <span>Target Period <Text styleType="danger">*</Text></span>
                                    )}
                                />
                            </Col>

                            <Col base={4}>
                                <Row>
                                    {kpiType === kpiTypes.linear.type && (
                                        <Col base={6}>
                                            <Field
                                                name={attributes.start_value}
                                                type="number"
                                                component={Input}
                                                required
                                                step="any"
                                                props={{
                                                    label: (
                                                        <span>Starting Value <Text styleType="danger">*</Text></span>
                                                    ),
                                                    alwaysRenderLabel: true,
                                                    className: 'target-value-input-component',
                                                    formGroupClassName: 'target-value-input'
                                                }}
                                                normalize={normalizeNumberValue}
                                                disabled={advCalcEnabled}
                                            />
                                        </Col>
                                    )}

                                    {kpiType !== kpiTypes.stepped.type && (
                                        <Col base={6}>
                                            <Field
                                                name={attributes.target_value}
                                                type="number"
                                                component={Input}
                                                required
                                                step="any"
                                                props={{
                                                    label: (
                                                        <span>
                                                            {renderTargetValueLabel(kpiType)}&nbsp;<Text styleType="danger">*</Text>
                                                        </span>
                                                    ),
                                                    alwaysRenderLabel: true,
                                                    className: 'target-value-input-component',
                                                    formGroupClassName: 'target-value-input'
                                                }}
                                                normalize={normalizeNumberValue}
                                                disabled={advCalcEnabled}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </GridContainer>
                </div>

                <div className="milestone-settings-section milestone-settings-section-white">
                    <GridContainer>
                        <Row>
                            <Col base={12}>
                                <ThresholdsParamsSection
                                    title={(
                                        <span>Configure Threshold Parameters <Text styleType="danger">*</Text></span>
                                    )}
                                    currentValues={thresholdCurrentValues}
                                    thresholdLabels={getThresholdLabels(kpiType)}
                                    onRangeChange={this.handleRangeChange}
                                    onThresholdChange={this.handleThresholdChange}
                                    isTreeOpen={isTreeOpen}
                                />
                            </Col>
                        </Row>
                    </GridContainer>
                </div>

                <GridContainer>
                    {kpiType === kpiTypes.static.type && (
                        <PeriodTargetsStatic
                            thresholds={currentValues[attributes.thresholds]}
                            rangeOfMeasure={currentValues[attributes.range_of_measure]}
                            item={currentValues}
                            isTreeOpen={isTreeOpen}
                        />
                    )}

                    {kpiType !== kpiTypes.static.type && (
                        <Row className="milestone-settings-period-targets-container">
                            <Col base={12}>
                                {fetchingPeriodTargets && (
                                    <div className="loading-overlay">
                                        <LoadingIndicator centered />
                                    </div>
                                )}

                                {kpiType === kpiTypes.linear.type && (
                                    <PeriodTargetsLinear
                                        periodTargets={currentValues[attributes.kpi_period_targets]}
                                        startDate={currentValues[attributes.started_at]}
                                        endDate={currentValues[attributes.target_at]}
                                        frequency={currentValues[attributes.frequency]}
                                        targetValue={currentValues[attributes.target_value]}
                                        thresholds={currentValues[attributes.thresholds]}
                                        rangeOfMeasure={currentValues[attributes.range_of_measure]}
                                        disabled={advCalcEnabled}
                                        isTreeOpen={isTreeOpen}
                                    />
                                )}

                                {kpiType === kpiTypes.stepped.type && (
                                    <>
                                        <PeriodTargetsStepped
                                            periodTargets={currentValues[attributes.kpi_period_targets]}
                                            startDate={currentValues[attributes.started_at]}
                                            endDate={currentValues[attributes.target_at]}
                                            frequency={currentValues[attributes.frequency]}
                                            targetValue={currentValues[attributes.target_value]}
                                            thresholds={currentValues[attributes.thresholds]}
                                            rangeOfMeasure={currentValues[attributes.range_of_measure]}
                                            disabled={advCalcEnabled}
                                            isTreeOpen={isTreeOpen}
                                        />
                                        <p>
                                            <InputErrorMessage
                                                errors={get(
                                                    formSubmitErrors,
                                                    attributes.kpi_period_targets
                                                )}
                                            />
                                        </p>
                                    </>
                                )}
                            </Col>
                        </Row>
                    )}
                </GridContainer>
            </div>
        );
    }
}

MilestoneSettingsForm.defaultProps = {
    editingDisabled: false,
    className: undefined,
    disableAdvancedCalcKPI: false,
    formatData(data) { return data; },
    formSubmitErrors: undefined
};

MilestoneSettingsForm.propTypes = {
    currentValues: PropTypes.shape({
        [attributes.frequency]: PropTypes.string,
        [attributes.type]: PropTypes.string,
        [attributes.thresholds]: PropTypes.array,
        [attributes.range_of_measure]: PropTypes.string,
        [attributes.advanced_calculation_type]: PropTypes.string,
        [attributes.advanced_calculation_enabled]: PropTypes.bool,
        [attributes.advanced_calculation_sources]: PropTypes.array,
        [attributes.currency_format]: PropTypes.string,
    }).isRequired,
    change: PropTypes.func.isRequired,
    editingDisabled: PropTypes.bool,
    startOfPeriod: PropTypes.bool.isRequired,
    className: PropTypes.string,
    fetchPeriodTargets: PropTypes.func.isRequired,
    fetchingPeriodTargets: PropTypes.bool.isRequired,
    formSubmitErrors: PropTypes.object,
    formatData: PropTypes.func,
    disableAdvancedCalcKPI: PropTypes.bool,
    isTreeOpen: PropTypes.bool.isRequired,
    currenciesFormats: PropTypes.array.isRequired,
    defaultCurrencyCode: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    const formErrorSelector = getFormSubmitErrors(ownProps.formName);
    return {
        fetchingPeriodTargets: periodTargetCollection.selectors.getFetchingState(state),
        currenciesFormats: getCurrenciesFormatsWithLabel(state),
        isTreeOpen: getVisibleTreeNavigation(state),
        formSubmitErrors: formErrorSelector(state),
        defaultCurrencyCode: applicationSettingsSelectors.selectDefaultCurrencyFormat(state),
    };
}

const mapDispatchToProps = {
    fetchPeriodTargets: periodTargetCollection.actions.fetch.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneSettingsForm);
