import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ContentSeparator } from '../index';
import './styles.css';

export default function ContentBlock({
    children, className, contentClassName, title, description, noSeparator
}) {
    return (
        <div className={cx('content-block', className, { 'no-separator': noSeparator })}>
            { title && (
                <div className="content-block-title">
                    {title}
                </div>
            )}
            { description && (
                <div className="content-block-description">
                    {description}
                </div>
            )}
            <div className={cx('content-block-content', contentClassName)}>
                {children}
            </div>
            { !noSeparator && (
                <ContentSeparator className="content-block-separator" />
            )}
        </div>
    );
}

ContentBlock.defaultProps = {
    title: undefined,
    description: undefined,
    noSeparator: false,
    className: undefined,
    contentClassName: undefined
};

ContentBlock.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    noSeparator: PropTypes.bool
};
