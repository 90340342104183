import moment from 'moment';

const format = date => moment(date).format('MM/DD');
const isCanAddEl = (elements, formattedDate, el) => (formattedDate in elements && !elements[formattedDate].includes(el));

class DateContentMap {
    elements = {}

    constructor(datesArray) {
        datesArray.forEach((date) => {
            this.elements[format(date)] = [];
        });
    }

    getDateContent = (date) => {
        return this.elements[format(date)];
    }

    addToDateContent = (date, el) => {
        const formattedDate = format(date);
        if (isCanAddEl(this.elements, formattedDate, el)) {
            this.elements[formattedDate].push(el);
        }
    }

    static getDateContentFromObject(object, date) {
        return object[format(date)];
    }

    getDatesWithContent = () => this.elements;
}

export default DateContentMap;
