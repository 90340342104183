import { get } from 'lodash';

function getData(payload) {
    return get(payload, ['data'], {});
}

function getOptions(payload) {
    return get(payload, ['options'], {});
}

export default function parseRequestPayload(payload = {}) {
    return {
        data: getData(payload),
        options: getOptions(payload),
    };
}
