import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, Tooltip, Clickable } from '../../../shared/components';
import './styles.css';

export default class PanelWithAction extends Component {
    renderControls() {
        const {
            controls, onActionClick, tooltip, disabled, iconName, controlsClassName
        } = this.props;

        if (controls === false) return null;

        if (controls) {
            return (
                <div className={cx('panel-with-action-icon-wrapper', {
                    [controlsClassName]: !!controlsClassName
                })}
                >{controls}
                </div>
            );
        }

        return (
            <div className={cx('panel-with-action-icon-wrapper', {
                [controlsClassName]: !!controlsClassName
            })}
            >
                <Tooltip text={tooltip} disabled={!tooltip}>
                    <Clickable
                        onClick={() => {
                            if (disabled) return;
                            onActionClick();
                        }}
                    >
                        <Icon
                            className="panel-with-action-icon"
                            name={iconName}
                        />
                    </Clickable>
                </Tooltip>
            </div>
        );
    }

    render() {
        const {
            children, className, disabled
        } = this.props;

        return (
            <div className={cx('panel-with-action', className, { 'panel-with-action-disabled': disabled })}>
                <div className="panel-with-action-content">
                    {children}
                </div>

                {this.renderControls()}
            </div>
        );
    }
}

PanelWithAction.defaultProps = {
    onActionClick: () => {},
    className: '',
    controlsClassName: undefined,
    tooltip: '',
    disabled: false,
    controls: undefined,
    iconName: undefined
};

PanelWithAction.propTypes = {
    children: PropTypes.node.isRequired,
    iconName: PropTypes.string,
    onActionClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    controlsClassName: PropTypes.string,
    tooltip: PropTypes.string,
    controls: PropTypes.node
};
