import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Icon from '../icon/component';
import './styles.css';

function CheckBox({
    id,
    input,
    label,
    meta,
    disabled,
    onChange,
    className,
    ...rest
}) {
    const safeOnChange = get(input, 'onChange', onChange);
    const checked = !!get(input, 'value');

    return (
        <div
            className={classnames('checkbox', {
                [className]: !!className,
                disabled,
            })}
        >
            <label
                className="checkbox-label"
                htmlFor={id}
            >
                <input
                    id={id}
                    type="checkbox"
                    checked={checked}
                    className="checkbox-native-input"
                    disabled={disabled}
                    {...input}
                    {...rest}
                    onChange={(event) => {
                        safeOnChange(event.target.checked);
                    }}
                />

                <Icon
                    className={input.value ? 'checkbox-icon-check-square' : 'checkbox-icon-square'}
                    name={input.value ? 'check-square' : 'square'}
                    type={input.value ? Icon.types.solid : Icon.types.regular}
                />

                <span className="checkbox-label-text">{label}</span>
            </label>

            {(!!meta.error && !!meta.error.length) && (
                <div className="input-error-message">
                    {
                        (meta.error || []).map(error => (
                            <span key={error}>
                                {error} <br />
                            </span>
                        ))
                    }
                </div>
            )}
        </div>
    );
}

CheckBox.defaultProps = {
    meta: {
        error: [],
    },
    disabled: false,
    name: '',
    text: '',
    onChange() { },
    className: undefined,
    input: {},
    value: undefined
};

CheckBox.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    name: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    input: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number
    ]),
    meta: PropTypes.shape({
        error: PropTypes.array,
    }),
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default CheckBox;
