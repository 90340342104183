import React from 'react';
import './styles.css';

export default function WidgetNoResultsMessage() {
    return (
        <p className="widget-no-results">
            No Results
        </p>
    );
}
