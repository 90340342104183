import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    Button,
    Modal,
    GridContainer,
    Col,
    Row,
} from '../../components';
import {
    ModalFormContainer,
    ModalFormHeader,
    ModalFormBody,
    ModalFormFooter,
} from '../modal-form';
import './styles.css';

function ModalSuccess({
    hide,
    title,
    children,
    onApplyButtonClick,
    applyButtonTitle,
    modalClassName,
    visible,
}) {
    return (
        <Modal
            visible={visible}
            className={classnames('success-modal', modalClassName)}
            hide={hide}
        >
            <ModalFormContainer>
                <ModalFormHeader
                    hide={hide}
                >
                    {title}
                </ModalFormHeader>
                <ModalFormBody className="success-modal-body">
                    {children}
                </ModalFormBody>
                <ModalFormFooter>
                    <GridContainer>
                        <Row>
                            <Col className="success-modal-controls" base={12}>
                                <Button
                                    onClick={onApplyButtonClick || hide}
                                    className="success-modal-submit"
                                    styleType="success"
                                    type="button"
                                    disabled={false}
                                    loading={false}
                                    block
                                >
                                    {applyButtonTitle}
                                </Button>
                            </Col>
                        </Row>
                    </GridContainer>
                </ModalFormFooter>
            </ModalFormContainer>
        </Modal>
    );
}

ModalSuccess.propTypes = {
    visible: PropTypes.bool,
    hide: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.any,
    onApplyButtonClick: PropTypes.func,
    modalClassName: PropTypes.string,
    applyButtonTitle: PropTypes.string,
};

ModalSuccess.defaultProps = {
    title: '',
    children: null,
    onApplyButtonClick: null,
    visible: false,
    modalClassName: '',
    applyButtonTitle: 'Got it!'
};

ModalSuccess.classNames = {
    bodyIcon: 'success-modal-icon',
};

export default ModalSuccess;
