import React, { Component } from 'react';
import { withBreakpoints } from 'react-breakpoints';
import PropTypes from 'prop-types';

class MediaQueryRender extends Component {
    renderView(View) {
        const {
            breakpoints,
            currentBreakpoint,
            mobile,
            desktop,
            preventPassChildren,
            ...rest
        } = this.props;

        if (preventPassChildren) {
            delete rest.children;
        }

        if (!View) {
            return null;
        }

        return <View {...rest} />;
    }

    render() {
        const {
            breakpoints,
            currentBreakpoint,
            mobile,
            desktop,
        } = this.props;

        const isMobile = breakpoints[currentBreakpoint] < breakpoints.medium;
        const view = isMobile ? mobile : desktop;

        return this.renderView(view);
    }
}

MediaQueryRender.defaultProps = {
    mobile: undefined,
    desktop: undefined,
    preventPassChildren: false
};

MediaQueryRender.propTypes = {
    breakpoints: PropTypes.object.isRequired,
    currentBreakpoint: PropTypes.string.isRequired,
    mobile: PropTypes.func,
    desktop: PropTypes.func,
    preventPassChildren: PropTypes.bool
};

export default withBreakpoints(MediaQueryRender);
