import React, { Fragment } from 'react';
import { isObject } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { tabType } from '../constants';
import { Button, Clickable, Icon } from '../../../components';
import { noop } from '../../../utils/common';

import styles from './styles.module.css';

export default function RightDrawerTab({
    handleToggleTab, isActiveTab, onClickCreate, createButton, title, iconName, icon,
    shouldRenderChevron
}) {
    const CreateButton = createButton;
    const IconComponent = icon;

    return (
        <Clickable
            className={classnames(styles.rightDrawerTab, { [styles.active]: isActiveTab })}
            action="open-right-drawer-tab"
            onClick={() => handleToggleTab(tabType.entityDetails)}
            component="div"
        >
            <div className={styles.rightDrawerTabInner}>
                <div className={styles.tabInfoGroup}>
                    {IconComponent && (
                        <IconComponent />
                    )}
                    {!IconComponent && (
                        <Icon
                            className={styles.tabInfoTabIcon}
                            name={iconName}
                            type={Icon.types.regular}
                        />
                    )}
                    <span>{title}</span>
                </div>
                {(createButton === true) && (
                    <Fragment>
                        <Clickable
                            onClick={onClickCreate}
                            noPropagation
                            component="div"
                        >
                            <Button
                                styleType="accent"
                                size="small"
                                round
                                withShadow
                                className={classnames('create-button', styles.tabAddButton)}
                            >
                                <Icon name="plus" />
                            </Button>
                        </Clickable>
                        <div className={styles.tabButtonDivider} />
                    </Fragment>
                )}
                {isObject(createButton) && <CreateButton />}
                {shouldRenderChevron && (
                    <div className={styles.rightDrawerTabWrapper}>
                        <Icon className={styles.tabArrowIcon} name="chevron-right" />
                    </div>
                )}
            </div>
        </Clickable>
    );
}

RightDrawerTab.defaultProps = {
    onClickCreate: noop,
    createButton: false,
    title: 'Details',
    iconName: 'info-circle',
    icon: undefined,
    shouldRenderChevron: true,
};

RightDrawerTab.propTypes = {
    handleToggleTab: PropTypes.func.isRequired,
    isActiveTab: PropTypes.bool.isRequired,
    onClickCreate: PropTypes.func,
    createButton: PropTypes.bool,
    title: PropTypes.string,
    iconName: PropTypes.string,
    icon: PropTypes.node,
    shouldRenderChevron: PropTypes.bool,
};
