import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, reset } from 'redux-form';
import {
    Form,
    InputPassword,
    Button,
    Modal,
    GridContainer,
    Col,
    Row,
    Text,
} from '../../../shared/components';
import {
    ModalFormContainer,
    ModalFormHeader,
    ModalFormBody,
    ModalFormFooter,
} from '../../../shared/components/modal-form';
import { PasswordWithPolicy, renderPasswordInputPrefix } from '../../authentication/components';
import './styles.css';

const formName = 'profile-change-password';

const ChangePasswordFields = ({
    onSubmit, handleSubmit, handleHide, requestError, submitting
}) => {
    return (
        <Form
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            className="change-password-form"
        >
            <ModalFormContainer>
                <ModalFormHeader
                    hide={handleHide}
                >
                    Change Password
                </ModalFormHeader>
                <ModalFormBody>
                    <GridContainer>
                        {requestError && !!requestError.message && (
                            <Row>
                                <Col base={12}>
                                    <Text styleType="danger" component="p">{requestError.message}</Text>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col base={12}>
                                <Field
                                    label="Old Password *"
                                    component={InputPassword}
                                    name="password"
                                    prefix={renderPasswordInputPrefix}
                                    type="password"
                                    required
                                />
                            </Col>
                            <Col base={12}>
                                <Field
                                    label="New Password *"
                                    name="new_password"
                                    prefix={renderPasswordInputPrefix}
                                    component={PasswordWithPolicy}
                                    required
                                />
                            </Col>
                        </Row>
                    </GridContainer>
                </ModalFormBody>
                <ModalFormFooter>
                    <GridContainer>
                        <Row>
                            <Col className="change-password-form-controls" base={12}>
                                <Button
                                    type="button"
                                    onClick={handleHide}
                                    disabled={submitting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="change-password-form-submit"
                                    styleType="success"
                                    type="submit"
                                    loading={submitting}
                                    block
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </GridContainer>
                </ModalFormFooter>
            </ModalFormContainer>
        </Form>
    );
};

ChangePasswordFields.defaultProps = {
    requestError: null,
};

ChangePasswordFields.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleHide: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    requestError: PropTypes.object,
};

const reduxFormConfig = {
    form: formName,
};
const ChangePasswordForm = reduxForm(reduxFormConfig)(ChangePasswordFields);

class ChangePassword extends Component {
    handleHide = () => {
        const { hide, formReset } = this.props;

        formReset(formName);
        hide();
    }

    render() {
        const {
            visible, onSubmit, requestError
        } = this.props;

        return (
            <Modal
                id="password-change"
                className="password-change-request-modal"
                visible={visible}
                hide={this.handleHide}
            >
                <ChangePasswordForm
                    onSubmit={onSubmit}
                    handleHide={this.handleHide}
                    requestError={requestError}
                />
            </Modal>
        );
    }
}

ChangePassword.defaultProps = {
    requestError: null,
};

ChangePassword.propTypes = {
    hide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    requestError: PropTypes.object,
    formReset: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    formReset: reset,
};

export default connect(null, mapDispatchToProps)(ChangePassword);
