import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UrlDataBinder } from '../../modules';
import SelectBase from './component';

export default class SelectUrlBinded extends Component {
    handleParseQuery = (value) => {
        if (value) {
            this.props.changeHandler(value);

            // redux form field change
            if (this.props.input) {
                this.props.input.onChange(value);
            }
        }
    };

    render() {
        const { queryParameterName, value } = this.props;

        return (
            <UrlDataBinder
                value={value}
                onInitialValueParsed={this.handleParseQuery}
                parameterName={queryParameterName}
            >
                {bindedValue => <SelectBase {...this.props} value={bindedValue} />}
            </UrlDataBinder>
        );
    }
}

SelectUrlBinded.defaultProps = {
    changeHandler: () => {},
    input: undefined,
};

SelectUrlBinded.propTypes = {
    queryParameterName: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    changeHandler: PropTypes.func,
    input: PropTypes.shape({
        onChange: PropTypes.func,
    })
};
