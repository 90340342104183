import { createSelector } from 'reselect';
import { hasActiveChildrenTypes } from '../../../shared/utils/entity';
import { types, typeCodes } from '../../../shared/utils/entity-type';
import Permissions from '../../permissions';

const { selectors, constants } = Permissions;

const objectivePermissionSelector = selectors.createPermissionSelector(constants.entitySections.objective);
const actionPermissionSelector = selectors.createPermissionSelector(constants.entitySections.action);
const kpiPermissionSelector = selectors.createPermissionSelector(constants.entitySections.kpi);


export function createSelectAvailableEntities(entity) {
    const hasPlanActions = hasActiveChildrenTypes(entity, [typeCodes.tactic, typeCodes.action]);
    const hasObjectives = hasActiveChildrenTypes(entity, [typeCodes.objective]);
    const hasMilestones = hasActiveChildrenTypes(entity, [typeCodes.kpi]);

    return createSelector([
        objectivePermissionSelector,
        actionPermissionSelector,
        kpiPermissionSelector
    ], (objectivePermissions, actionPermissions, kpiPermissions) => (
        {
            [types.sub_segment]: objectivePermissions['can-create'] && !hasPlanActions && !hasMilestones,
            [types.action]: actionPermissions['can-create'] && !hasObjectives,
            [types.milestone]: kpiPermissions['can-create'] && !hasObjectives
        }
    ));
}
