import { createAction } from 'redux-act';

const prefix = 'authentication';
const changeEmailPrefix = 'change-email';

export const authenticate = createAction(`${prefix}/AUTHENTICATE`);
export const logout = createAction(`${prefix}/LOGOUT`);
export const checkAuthentication = createAction(`${prefix}/CHECK_AUTHENTICATION`);
export const initAuth0Service = createAction(`${prefix}/INIT_AUTH0_SERVICE`);
export const setLoginAttempt = createAction(`${prefix}/SET_SOCIAL_LOGIN_ATTEMPT`);

export const setSignupAttempt = createAction(`${prefix}/SET_SIGNUP_ATTEMPT`);
export const signupCallback = createAction(`${prefix}/SIGNUP_CALLBACK`);

export const setSocialSignupAttempt = createAction(`${prefix}/SET_SOCIAL_SIGNUP_ATTEMPT`);
export const socialSignupCallback = createAction(`${prefix}/SOCIAL_SIGNUP_CALLBACK`);
export const setSocialChangeEmailAttempt = createAction(`${prefix}/${changeEmailPrefix}/SET_ATTEMPT_STATE`);
export const socialAuthCallback = createAction(`${prefix}/SOCIAL_AUTH_CALLBACK`);
export const startSessionTimers = createAction(`${prefix}/START_SESSION_TIMERS`);
export const setRecoveryUsed = createAction(`${prefix}/SET_RECOVERY_USED`);
export const setExpiringSSOProviderCheck = createAction(`${prefix}/SET_EXPIRING_SSO_PROVIDERS_CHECK`);
export const redirectToUniversalLogin = createAction(`${prefix}/REDIRECT_TO_UNIVERSAL_LOGIN`);
