export default {
    'goal_map': 'Plan',
    'type': 'Type',
    'goal_maps': 'Plans',
    'drivers': 'Critical Success Factor',
    'milestones': 'Key Performance Indicator',
    'strategies': 'Segment',
    'segments': 'Strategy',
    'subsegments': 'Objective',
    'plan': 'Tactic',
    'action': 'Action Item',

    'strategy_insights': 'Plan Overview',
    'vision': 'Vision, Mission, Values',
    'my_goals': 'My Plans',
    'my_team': 'My Teams',
    'goal_health': 'Strategy Health',

    'widget_interval': 'Data Refresh Interval',
    'widget_limit': 'Display Limit',
};
