import createAPI from './api';
import createActions from './actions';
import createReducer, { createSelector } from './reducer';
import createSaga from './saga';

function format(data) {
    return { ...data.params };
}

export default function createFeature(options) {
    const api = {
        ...createAPI(options.apiUrl, options.mentionsField, {
            parse: options.parse,
            format: options.format || format,
            getRequestMethod: options.getRequestMethod,
        }),
        ...options.extendAPI,
    };
    const actions = createActions(options.name);
    const reducer = createReducer(actions);
    const selector = createSelector(options.name);
    const saga = createSaga(actions, api, options.onSuccess);

    return {
        api,
        actions,
        reducer,
        selector,
        saga,
        name: options.name,
    };
}

createFeature.method = {
    post: 'post',
    patch: 'patch'
};
