import { reduxForm, formValueSelector } from 'redux-form';
import ListSearchForm from '../../../shared/modules/list-search-container';
import { values, fields, initialValues as fdInitialValues } from './form-data';
import dict from './dictionary';

const { isActive } = fields;
const formName = 'storage-search';

export const filterOptions = [
    { value: values[isActive].all, label: dict.filterLabelAll },
    { value: values[isActive].active, label: dict.filterLabelActive },
    { value: values[isActive].inactive, label: dict.filterLabelInactive },
];

export const initialValues = {
    [isActive]: fdInitialValues[isActive],
};

export const formSelector = formValueSelector(formName);

export default reduxForm({
    form: formName,
})(ListSearchForm);
