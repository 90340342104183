import React from 'react';
import PropTypes from 'prop-types';
import { getIconName } from '../../../utils/entity';
import Icon from '../../icon/component';
import './entity-icon.css';

export default function EntityIconFormatter({ entity }) {
    const icon = getIconName(entity);

    return (
        <Icon
            className="table-formatter-entity-icon"
            name={icon}
            alt="Entity Icon"
        />
    );
}

EntityIconFormatter.propTypes = {
    entity: PropTypes.object.isRequired
};
