import u from 'updeep';
import { pick } from 'lodash';
import request from '../../request';
import {
    List, createModal, createDetails, updateModal
} from '../../shared/entities';
import config from '../../config';
import { states } from '../goalmap-list/config';
import { entityState } from '../../shared/utils/entity';

const listName = 'plan-list';
const apiUrl = '/plans';
const apiUrlActions = '/actions';

export const list = new List({
    getApiUrl({ parentId, goalId, parentTypeCode }) {
        return `/goals/${goalId}/children/${parentTypeCode}/${parentId}`;
    },
    name: listName,
    format(params = {}) {
        const filter = params.filter || {};

        const order_by = params.order_by
            ? params.order_by
            : undefined;

        const result = u({
            order_by,
            filter: undefined,
            ...filter,
            limit: config.defaultListLimit,
        }, params);

        return result;
    },
    parseItem(item) {
        return {
            ...item,
            ...item.entity,
        };
    }
});

export const create = createModal({
    apiUrl: apiUrlActions,
    name: 'plan-create',
    extendAPI: {
        create({ params }) {
            return request.post(apiUrlActions, { state: entityState.active, ...params });
        }
    }
});

export const copy = createModal({
    apiUrl: apiUrlActions,
    name: 'plan-copy',
    extendAPI: {
        create({ params }) {
            return request.post(apiUrlActions, { state: entityState.active, ...params });
        }
    }
});

export const update = updateModal({
    apiUrl: '/plans',
    name: 'plan-update',
    onSuccess: list.actions.update,
});

export const details = createDetails({
    apiUrl,
    name: 'plan-details',
    updateHTML: true,
    onErrorUpdateStatus: createDetails.errorUpdateStatusNotification,
    messages: {
        error: 'To activate this entity you should first activate the item on the higher level'
    },
    extraActions: {
        [list.actions.add](state, action) {
            if (action.state !== states.draft) {
                return u.updateIn(
                    ['data', 'health_manually'],
                    false,
                    state,
                );
            }

            return state;
        },
    },
    format(data) {
        const result = pick(data, [
            'segment_id',
            'parent_id',
            'tag_name',
            'state',
            'title',
            'description',
            'status',
            'type',
            'owner_id',
            'frequency',
            'priority',
            'sensitivity',
            'progress_status',
            'target_date',
            'start_date',
            'closed_date',
        ]);

        if (result.parent_id === 0) {
            delete result.parent_id;
        }

        return result;
    }
});

export const detailsWizard = createDetails({
    apiUrl,
    name: 'plan-details-wizard',
    updateHTML: true
});
