import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import {
    Icon,
    Text,
    Image,
    Tooltip,
    Clickable
} from '../../../../../shared/components';
import iconLocal from './local.svg';
import './styles.css';

const errorText = 'There is no connection with the storage service at the moment';

export default function FileSourceSelectorItem({
    title,
    icon,
    children,
    disabled,
    onClick,
    isUnavailable,
}) {
    return (
        <Clickable
            onClick={!disabled ? onClick : noop}
            action="select-file"
            className={classnames('file-source-selector-item', {
                disabled,
            })}
        >
            <Image
                src={icon}
                alt={title}
                className="file-source-selector-item-icon"
            />

            <br />

            <Text size="large" className="file-source-selector-item-title">{title}</Text>

            {children}

            {isUnavailable && (
                <span className="file-source-tip-container">
                    <Tooltip text={errorText} component="span">
                        <Text
                            component={Icon}
                            className="file-source-tip-icon"
                            name="info"
                            styleType="danger"
                            size="small"
                        />
                    </Tooltip>
                </span>
            )}
        </Clickable>
    );
}

FileSourceSelectorItem.defaultProps = {
    icon: iconLocal,
    children: undefined,
    disabled: false,
    isUnavailable: undefined,
};

FileSourceSelectorItem.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    isUnavailable: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
