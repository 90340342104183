import { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getByIndexVirtual } from '../../utils/collection';

const directions = {
    NONE: 'none',
    ASC: 'asc',
    DESC: 'desc',
};

function defaultFormatSort(sortData) {
    return Object.entries(sortData)
        .filter(([, val]) => val !== directions.NONE)
        .map(([key, val]) => {
            return `${key},${val}`;
        }).join('|');
}

function defaultParseSortRules(sortData) {
    return sortData.split('|').reduce((accum, it) => {
        const [title, rule] = it.split(',');
        accum[title] = rule;
        return accum;
    }, {});
}

export default class WithSort extends Component {
    constructor(props) {
        super(props);

        this.state = { sorts: props.parseSortRules(props.sorts) };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (_.isEqual(nextProps.sorts, prevState.sorts)) {
            return {
                sorts: nextProps.parseSortRules(nextProps.sorts)
            };
        }

        return null;
    }

    get sortParams() {
        const { sorts } = this.state;
        const { externalSorts } = this.props;

        return { ...sorts, ...externalSorts };
    }

    get sortString() {
        const { sorts } = this.state;
        const { externalSorts, formatSort } = this.props;

        return formatSort({ ...sorts, ...externalSorts });
    }

    getNextSort(type) {
        const { allowedTypes } = this.props;
        const idx = allowedTypes.indexOf(type);
        return getByIndexVirtual(allowedTypes, idx + 1);
    }

    toggleSort = (field) => {
        const { sorts } = this.state;
        const { onlyOneActive, allowedTypes } = this.props;
        const curSortType = sorts[field] || allowedTypes[0];
        const nextSortType = this.getNextSort(curSortType);

        this.setState({
            sorts: {
                ...(onlyOneActive ? {} : sorts),
                [field]: nextSortType
            }
        }, this.triggerSortChange);
    };

    triggerSortChange = () => {
        const { onSortChange } = this.props;
        const { sorts } = this.state;

        onSortChange(sorts, this.sortString);
    }

    render() {
        const { children } = this.props;

        return children({
            toggleSort: this.toggleSort,
            sorts: this.sortParams,
            sortString: this.sortString
        });
    }
}

WithSort.direction = directions;
WithSort.defaultFormatSort = defaultFormatSort;
WithSort.defaultParseSortRules = defaultParseSortRules;

WithSort.defaultProps = {
    externalSorts: [],
    onlyOneActive: true,
    formatSort: defaultFormatSort,
    parseSortRules: defaultParseSortRules,
    allowedTypes: [
        WithSort.direction.NONE,
        WithSort.direction.ASC,
        WithSort.direction.DESC
    ],
    onSortChange() { },
    sorts: '',
    children: undefined
};

WithSort.propTypes = {
    externalSorts: PropTypes.array,
    children: PropTypes.func,
    onlyOneActive: PropTypes.bool,
    formatSort: PropTypes.func,
    parseSortRules: PropTypes.func,
    allowedTypes: PropTypes.array,
    onSortChange: PropTypes.func,
    sorts: PropTypes.string
};
