import { Collection } from '../../shared/entities-v2';
import { keysToCamelCase } from '../../shared/utils/object';
import { apiUrl } from './teams-constants';

class TeamCollection extends Collection {
    parseItem = (data) => {
        return keysToCamelCase(data);
    }
}

const teamCollection = new TeamCollection({
    apiUrl: `${apiUrl}/my`,
    name: 'profile-teams',
});

export default teamCollection;
