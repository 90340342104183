import PropTypes from 'prop-types';
import React from 'react';
import { getIconByTypeCode, } from '../../../shared/utils/entity';
import StatusIndicator from '../status-indicator/component';
import Icon from '../icon/component';

import './styles.css';

export default function SelectOptionEntity({
    title, tag_name, progress_status, type_code,
}) {
    const isTagName = String(tag_name).length;
    const goalClass = !isTagName ? 'goal-entity' : null;
    const icon = getIconByTypeCode(type_code);

    return (
        <div className="select-option-entity">
            <Icon
                name={icon}
                className="select-option-entity-icon"
                fixedWidth
            />

            <div className={`select-option-enity-info ${goalClass}`}>
                {title}
                {Number.isInteger(progress_status) ? (
                    <span className="select-option-entity-progress-status">
                        <StatusIndicator
                            className="select-option-progress-status"
                            status={progress_status}
                        />
                    </span>
                ) : null}
                {isTagName ? (
                    <div className="select-option-entity-tagname">@{tag_name}</div>
                ) : null}
            </div>
        </div>
    );
}

SelectOptionEntity.propTypes = {
    title: PropTypes.string.isRequired,
    tag_name: PropTypes.string.isRequired,
    progress_status: PropTypes.number.isRequired,
    type_code: PropTypes.number.isRequired,
};
