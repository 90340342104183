import { get } from 'lodash';
import u from 'updeep';
import ChartGauge from '../../shared/components/chart-gauge/component';
import {
    attributes, rangeOfMeasure, kpiTypes, periodTargetAttributes, types
} from './constants';
import {
    getLatestDataPoint, getCurrentValue, parseThresholdsByType, parseThresholds
} from './utils';

const { configCreators } = ChartGauge;

function getPeriodTargetByStartDate(arr, startDate) {
    return arr.find(it => get(it, periodTargetAttributes.period_start) === startDate);
}

function collectBoundsByKpiType(data) {
    const kpiType = get(data, attributes.kpi_type);
    const latestDataPoint = getLatestDataPoint(data);
    const periodTarget = getPeriodTargetByStartDate(
        get(data, attributes.kpi_period_targets, []),
        get(latestDataPoint, periodTargetAttributes.period_start)
    );
    const thresholds = parseThresholds(data);

    switch (kpiType) {
        case kpiTypes.linear.type:
            return parseThresholdsByType(
                types.numeric,
                thresholds,
                get(periodTarget, periodTargetAttributes.overall_target_value),
            );
        case kpiTypes.stepped.type:
            return parseThresholdsByType(
                types.numeric,
                thresholds,
                get(periodTarget, periodTargetAttributes.target_value),
            );
        case kpiTypes.static.type:
        default:
            return parseThresholdsByType(
                types.numeric,
                thresholds,
                get(data, attributes.target_value),
            );
    }
}

const configCreatorsByRange = {
    [rangeOfMeasure.asc]: ChartGauge.configCreators.ascending,
    [rangeOfMeasure.desc]: ChartGauge.configCreators.descending,
    [rangeOfMeasure.between]: ChartGauge.configCreators.between,
};

export function createGaugeConfig(data, defaults) {
    const rangeMeasure = get(data, attributes.range_of_measure);
    const type = get(data, attributes.type);
    const configCreator = get(configCreatorsByRange, rangeMeasure, configCreators.ascending);
    const latestDataPoint = getLatestDataPoint(data);

    if (!latestDataPoint) {
        return configCreators.placeholder(defaults);
    }

    const options = u({
        bounds: collectBoundsByKpiType(data),
        value: getCurrentValue(data),
        type,
        range: rangeMeasure,
        currencySymbol: data.currencySymbol,
    }, defaults);

    return configCreator(options);
}
