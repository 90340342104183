// import React from 'react';
// import PropTypes from 'prop-types';
import strings from './strings';

export default function Dictionary({ name }) {
    return strings[name] || '';
}

// Dictionary.defaultProps = {
//     children: undefined,
// };

// Dictionary.propTypes = {
//     children: PropTypes.node,
// };
