import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

export default function TabLink({ to, children }) {
    return (
        <Link
            className="react-tabs__tab"
            activeClassName="react-tabs__tab--selected"
            to={to}
        >
            {children}
        </Link>
    );
}

TabLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};
