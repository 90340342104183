import {
    isMobile,
    isTablet,
    isSafari,
    isIE,
} from 'mobile-device-detect';
import { timeout } from 'promise-timeout';
import {
    takeEvery,
    call,
    take,
    select,
    put,
    all,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import request from '../../request';
import { applicationSettingsModel } from '../application';
import {
    getAppId,
    callOneSignalAPI,
    getUserId,
    unregisterPushClient,
    logRegistrationState,
    registerOnBeforeUnload,
} from './utils';
import { notificationsHeaderCollection } from '../navigation-header/notifications/entity';
// import { click as handleNotificationClick } from '../notifications/toast-actions';
import { getDefaultParams as getDefaultParamsNotification } from '../navigation-header/notifications/selectors';
import { authenticate } from '../authentication/actions';
import { getAccessToken } from '../authentication/selectors';
import { selectUserDetails } from '../user/selectors';
import * as api from './api';
import { logWarning, logError } from './logger';

const actions = Object.freeze({
    subscriptionActive: 'subscriptionActive',
    subscriptionInactive: 'subscriptionInactive',
    notificationDisplay: 'notificationDisplay',
    notificationDismiss: 'notificationDismiss',
    notificationClick: 'notificationClick',
});

const events = Object.freeze({
    subscriptionChange: 'subscriptionChange',
    notificationDisplay: 'notificationDisplay',
    notificationDismiss: 'notificationDismiss',
});

function* registerPushClient() {
    const userData = yield select(selectUserDetails);
    const accessToken = yield select(getAccessToken);

    callOneSignalAPI((OneSignal) => {
        OneSignal.setExternalUserId(userData.guid);
        if (isSafari) {
            OneSignal.showSlidedownPrompt();
        } else {
            OneSignal.showNativePrompt();
        }
    });

    const userDeviceId = yield call(getUserId);

    if (!isIE && !isMobile && !isTablet && userDeviceId) {
        registerOnBeforeUnload({
            token: accessToken,
            baseUrl: request.service.defaults.baseURL,
        });
        try {
            yield call(api.remove, userDeviceId);
        } catch (error) {
            logError(error);
        }
    }

    if (!isIE && (isMobile || isTablet) && userDeviceId) {
        try {
            yield call(api.create, userDeviceId);
        } catch (error) {
            logError(error);
        }
    }
}

export function* handleLogout() {
    if (isIE || isMobile || isTablet) {
        return;
    }

    try {
        const userId = yield timeout(getUserId(), 1000);

        yield all([
            call(api.remove, userId),
            call(unregisterPushClient),
        ]);
    } catch (error) {
        logError('[OneSignal]: Unregister failed', error);
    }
}

function registrationChannel() {
    return eventChannel((emitter) => {
        function handleSubscriptionChange(status) {
            if (status) {
                emitter({ type: actions.subscriptionActive });
            } else {
                emitter({ type: actions.subscriptionInactive });
            }
        }

        function handleNotificationDismiss(data) {
            emitter({
                type: actions.notificationDismiss,
                payload: data,
            });
        }

        function handleNotificationDisplay(data) {
            emitter({
                type: actions.notificationDisplay,
                payload: data,
            });
        }

        callOneSignalAPI((OneSignal) => {
            OneSignal.on(events.subscriptionChange, handleSubscriptionChange);
            OneSignal.on(events.notificationDismiss, handleNotificationDismiss);
            OneSignal.on(events.notificationDisplay, handleNotificationDisplay);
        });

        return () => { };
    });
}

function* createRegistrationChannel() {
    if (isIE) {
        return;
    }

    const channel = registrationChannel();

    while (true) {
        const { type } = yield take(channel);
        const userId = yield call(getUserId);

        switch (type) {
            case actions.subscriptionActive:
                /* eslint-disable no-case-declarations */
                const isMobileStrategy = (isMobile || isTablet);
                const userDeviceId = yield call(getUserId);
                const accessToken = yield select(getAccessToken);

                logWarning('[OneSignal]: Subscription Active');

                if (isMobileStrategy) {
                    try {
                        yield call(api.create, userDeviceId);
                    } catch (error) {
                        logError(error);
                    }
                } else {
                    registerOnBeforeUnload({
                        token: accessToken,
                        baseUrl: request.service.defaults.baseURL,
                    });
                }
                break;
            case actions.subscriptionInactive:
                logWarning('[OneSignal]: Subscription Inactive');

                try {
                    yield call(api.remove, userId);
                } catch (error) {
                    logError(error);
                }
                break;
            case actions.notificationDisplay:
                // eslint-disable-next-line no-case-declarations
                const params = yield select(getDefaultParamsNotification);

                yield put({
                    type: notificationsHeaderCollection.actions.fetch.request.getType(),
                    payload: params,
                });
                break;
            default:
                break;
        }
    }
}


function* initOneSignal(action) {
    const appId = getAppId(action.payload.response);

    if (!isIE) {
        callOneSignalAPI((OneSignal) => {
            if (!OneSignal.initialized) {
                try {
                    OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' };
                    OneSignal.SERVICE_WORKER_PATH = './OneSignalSDKWorker.js';
                    OneSignal.init({
                        appId,
                        notifyButton: {
                            enable: false,
                        },
                        autoResubscribe: true,
                        allowLocalhostAsSecureOrigin: true,
                        notificationClickHandlerMatch: 'origin',
                        notificationClickHandlerAction: 'focus',
                    });
                } catch (err) {
                    logError('Could not initiate OneSignal');
                }
            }
        });
    }

    const accessToken = yield select(getAccessToken);

    if (accessToken) {
        yield call(registerPushClient);
    } else {
        yield takeEvery(authenticate, registerPushClient);
    }
}

export default function* pushNotificationsSaga() {
    window.logOneSignalRegistrationState = logRegistrationState;

    if (process.env.NODE_ENV !== 'development') {
        yield takeEvery(applicationSettingsModel.actions.fetch.success.getType(), initOneSignal);

        yield call(createRegistrationChannel);
    }
}
