import { get } from 'lodash';

export const defaultFilterOptionBy = ['label', 'tag_name'];

export function filterOption(option, filterValue, filterOptionBy = defaultFilterOptionBy) {
    if (!filterValue) {
        return true;
    }

    const safeFilterValue = filterValue.toLowerCase();

    const filteredByAttr = filterOptionBy.find((attrName) => {
        const value = option[attrName];

        if (!value) {
            return false;
        }

        return value.toLowerCase().includes(safeFilterValue);
    });

    return !!filteredByAttr;
}

export function parseItem(data) {
    return {
        value: data.id,
        label: get(data, ['user_profile', 'full_name']),
        user_id: data.id,
        id: data.id,
        first_name: get(data, ['user_profile', 'first_name']),
        last_name: get(data, ['user_profile', 'last_name']),
        tag_name: get(data, ['user_profile', 'tag_name']),
        image: get(data, ['user_profile', 'image']),
        user_account_role: data.user_account_role,
        user_profile: data.user_profile,
    };
}

export function parse(response) {
    return {
        options: (response.data || response).map(parseItem)
    };
}
