import React from 'react';
import PropTypes from 'prop-types';
import {
    Icon, StatusIndicator, Text, Clickable,
} from '../../../../../shared/components';
import * as routeUtils from '../../../../../routes/rules';
import styles from './csf-list-mobile.module.css';

const CsfListItemMobile = ({
    title, progress_status, goal_id, id, navigate,
}) => {
    const handleItemClick = () => {
        const href = routeUtils.createUrl(routeUtils.paths.planCSFDetails, {
            [routeUtils.params.PlanId]: goal_id,
            [routeUtils.params.EntityId]: id,
        });
        navigate(href);
    };

    return (
        <div className={styles.csfItemContainer}>
            <Clickable className={styles.csfItemTitle} onClick={handleItemClick}>
                <Icon
                    name="trophy-alt"
                    type="regular"
                    fixedWidth
                    className={styles.csfItemIcon}
                />
                <StatusIndicator
                    size="small"
                    status={progress_status}
                    className={styles.csfItemStatus}
                />
                <Text styleType="dark-light" className={styles.csfItemText}>{title}</Text>
            </Clickable>
        </div>
    );
};

CsfListItemMobile.propTypes = {
    title: PropTypes.string.isRequired,
    progress_status: PropTypes.number.isRequired,
    goal_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default CsfListItemMobile;
