import { take, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as actions from './network-status-actions';

function detectConnNotifChannel() {
    return eventChannel((emitter) => {
        function callBackOffline() {
            emitter({
                type: actions.appOffline.getType(),
                payload: { connected: false },
            });
        }
        function callBackOnline() {
            emitter({
                type: actions.appOnline.getType(),
                payload: { connected: true },
            });
        }

        window.addEventListener('offline', callBackOffline);
        window.addEventListener('online', callBackOnline);

        return () => {
            document.removeEventListener('offline', callBackOffline);
            document.removeEventListener('online', callBackOnline);
        };
    });
}

export default function* detectConnNotifSaga(action) {
    const channel = detectConnNotifChannel(action);

    while (true) {
        const channelAction = yield take(channel);
        yield put(channelAction);
    }
}
