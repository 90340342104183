import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PanelWithAction, Prompt } from '../../../shared/components';
import { GoalMember } from '../../../shared/modules';
import './styles.css';

export default class MemberPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmVisible: false,
            confirmText: undefined,
            confirmCallback: undefined,
        };
    }

    handleClickAction = () => {
        const {
            status,
            onRemoveUser,
            onActivateUser,
        } = this.props;
        const inactive = (status === 0 || status === 3);

        if (!inactive) {
            onRemoveUser();
        } else {
            onActivateUser();
        }
    };

    render() {
        const { has_activities, status, disabled } = this.props;
        const inactive = (status === 0 || status === 3);
        const activeUserIcon = has_activities ? 'user-alt-slash' : 'trash-alt';
        const iconName = inactive ? 'user-check' : activeUserIcon;
        const activeUserTooltip = has_activities ? 'Deactivate' : 'Delete';
        const tooltip = inactive ? 'Activate' : activeUserTooltip;

        return (
            <PanelWithAction
                className={cx('member-details-pane', { 'member-details-pane-deactivated': inactive })}
                iconName={iconName}
                tooltip={tooltip}
                disabled={disabled}
                onActionClick={this.handleClickAction}
            >
                <GoalMember {...this.props} withoutActions />

                <Prompt
                    visible={this.state.confirmVisible}
                    description={this.state.confirmText}
                    onAccept={this.state.confirmCallback}
                    hide={() => this.setState({ confirmVisible: false })}
                />
            </PanelWithAction>
        );
    }
}

MemberPane.defaultProps = {
    params: {},
    page: undefined,
    has_activities: false,
    disabled: false,
    fetchUsers() {},
    onRemoveError() {},
    setUpdatingId() {},
    updateMemberStatus() {},
    onActivateUser() {},
    status: undefined
};

MemberPane.propTypes = {
    onActivateUser: PropTypes.func,
    has_activities: PropTypes.bool,
    onRemoveUser: PropTypes.func.isRequired,
    status: PropTypes.number,
    updateMemberStatus: PropTypes.func,
    id: PropTypes.number.isRequired,
    setUpdatingId: PropTypes.func,
    onRemoveError: PropTypes.func,
    fetchUsers: PropTypes.func,
    params: PropTypes.object,
    disabled: PropTypes.bool,
    page: PropTypes.number
};
