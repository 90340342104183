import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
    Icon, Clickable, Input, Text,
} from '../../../components/';
import createInlineFieldForm from '../../../components/inline-field-form/component';

import styles from './styles.module.css';

const FormComponent = createInlineFieldForm({
    formName: 'right-drawer-entity-title-inline',
    destroyOnUnmount: false,
    enableReinitialize: true,
});

const EntityTitleInlineform = ({
    title, canUpdateEntity, initialValues, onSubmit, onSuccessSubmit,
    isEditingTitle, setIsEditingTitle
}) => {
    const formProps = {
        initialValues,
        onSubmit,
        onCancel: () => setIsEditingTitle(false),
        onSuccessSubmit,
        onSubmitSuccess: () => setIsEditingTitle(false),
    };

    return (
        <>
            {isEditingTitle
                ? (
                    <FormComponent {...formProps}>
                        <Field
                            name="title"
                            component={Input}
                            autoFocus
                            noMargin
                        />
                    </FormComponent>
                )
                : (
                    <Clickable
                        action="edit-header"
                        onClick={() => setIsEditingTitle(true)}
                        className={styles.rightDrawerEntityViewTitle}
                    >
                        <Text bold size="xlarge" overflowEllipsis title={title}>{title}</Text>
                        {canUpdateEntity && <Icon name="pen" type="regular" />}
                    </Clickable>
                )
            }
        </>
    );
};

EntityTitleInlineform.defaultProps = {
    onSuccessSubmit() {},
};

EntityTitleInlineform.propTypes = {
    title: PropTypes.string.isRequired,
    canUpdateEntity: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSuccessSubmit: PropTypes.func,
    isEditingTitle: PropTypes.bool.isRequired,
    setIsEditingTitle: PropTypes.func.isRequired,
};

export default EntityTitleInlineform;
