import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { isPositionStickySupported } from '../../utils/support-check';
import { findScrollableContainer } from '../../utils/dom';

export default class Sticky extends Component {
    static isBody(el) {
        return el.nodeName === 'BODY';
    }

    static getScrollListenerTarget(containedEl) {
        const el = findScrollableContainer(containedEl);
        if (Sticky.isBody(el)) {
            return window;
        }

        return el;
    }

    static getBoundingClientRect(el) {
        if (!el.getBoundingClientRect) {
            return { top: 0, bottom: window.innerHeight };
        }

        return el.getBoundingClientRect();
    }

    componentDidMount() {
        const {
            constraintTop, constraintBottom, constraintTopAlign,
            constraintBottomAlign, bypassNativeStickyCheck
        } = this.props;

        this.stickySupportedPrefix = isPositionStickySupported();
        this.stickyWrapperEl = ReactDOM.findDOMNode(this); //eslint-disable-line

        const [topConstraintAlign, topTargetAlign] = constraintTopAlign.split('-');
        const [bottomConstraintAlign, bottomTargetAlign] = constraintBottomAlign.split('-');

        this.updateConstraints(constraintTop, constraintBottom);
        this.topTargetAlign = topTargetAlign;
        this.topConstraintAlign = topConstraintAlign;
        this.bottomTargetAlign = bottomTargetAlign;
        this.bottomConstraintAlign = bottomConstraintAlign;
        this.useNativeSticky = !!this.stickySupportedPrefix && !bypassNativeStickyCheck;

        if (this.useNativeSticky) {
            this.stickyWrapperEl.style.position = this.stickySupportedPrefix;
        }

        this.scrollableCnt = Sticky.getScrollListenerTarget(this.stickyWrapperEl);
        this.scrollableCnt.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    componentWillReceiveProps(nextProps) {
        const { constraintTop, constraintBottom } = nextProps;
        this.updateConstraints(constraintTop, constraintBottom);
    }

    componentWillUnmount() {
        this.scrollableCnt.removeEventListener('scroll', this.handleScroll);
    }

    setTranslate = (offset) => {
        this.stickyWrapperEl.style.transform = `translate3d(0, ${offset}px, 0)`;
    };

    handleScroll = () => {
        this.doProcess();

        this.stopAnimation();
        this.startUpdatingInterval();
    };

    updatePosition = () => {
        this.doProcess();
        this.updatingAnimation = window.requestAnimationFrame(this.updatePosition);
    };

    processCustomStickyOffset() {
        const { offsetBottom, offsetTop } = this.props;
        let boundingCnt;
        let positionTop = 0;
        let positionBottom = 0;

        this.restore();

        let itemOffsetData = Sticky.getBoundingClientRect(this.stickyWrapperEl);

        if (this.constraintTop) {
            boundingCnt = Sticky.getBoundingClientRect(this.constraintTop);
            positionTop = Math.max(
                0,
                boundingCnt[this.topConstraintAlign] - itemOffsetData[this.topTargetAlign] + offsetTop // eslint-disable-line max-len
            );
        }

        if (positionTop > 0) {
            this.setTranslate(positionTop);
        }

        itemOffsetData = Sticky.getBoundingClientRect(this.stickyWrapperEl);

        if (this.constraintBottom) {
            boundingCnt = Sticky.getBoundingClientRect(this.constraintBottom);
            positionBottom = Math.min(
                0,
                boundingCnt[this.bottomConstraintAlign] - itemOffsetData[this.bottomTargetAlign] - offsetBottom // eslint-disable-line max-len
            );
        }
        this.setTranslate(positionBottom + positionTop);
    }

    stopAnimation() {
        cancelAnimationFrame(this.updatingAnimation);
        clearTimeout(this.stopUpdatingTimer);
    }

    startUpdatingInterval() {
        const recalculationTime = 3000;

        this.updatePosition();
        this.stopUpdatingTimer = setTimeout(() => {
            this.stopAnimation();
        }, recalculationTime);
    }

    processNativeStickyOffset() {
        const { offsetBottom, offsetTop } = this.props;
        const itemOffsetData = Sticky.getBoundingClientRect(this.stickyWrapperEl);

        if (this.constraintTop) {
            const boundingCnt = Sticky.getBoundingClientRect(this.constraintTop);
            const positionTop = Math.max(
                0,
                boundingCnt[this.topConstraintAlign] - (this.topTargetAlign === 'top' ? 0 : itemOffsetData.height) + offsetTop // eslint-disable-line max-len
            );
            this.stickyWrapperEl.style.top = `${positionTop}px`;
        }

        if (this.constraintBottom) {
            const boundingCnt = Sticky.getBoundingClientRect(this.constraintBottom);
            const positionBottom = Math.max(
                0,
                boundingCnt[this.bottomTargetAlign] - (this.bottomTargetAlign === 'top' ? 0 : itemOffsetData.height) + offsetBottom // eslint-disable-line max-len
            );
            this.stickyWrapperEl.style.bottom = `${positionBottom}px`;
        }
    }

    doProcess() {
        if (this.useNativeSticky) {
            this.processNativeStickyOffset();
        } else {
            this.processCustomStickyOffset();
        }
    }

    updateConstraints(constraintTop, constraintBottom) {
        if (constraintTop !== false) {
            this.constraintTop = (typeof constraintTop === 'string'
                ? document.querySelector(constraintTop)
                : constraintTop) || {};
        }

        if (constraintBottom !== false) {
            this.constraintBottom = (typeof constraintBottom === 'string'
                ? document.querySelector(constraintBottom)
                : constraintBottom) || {};
        }
    }

    restore() {
        this.stickyWrapperEl.style.transform = 'translate3d(0, 0, 0)';
    }

    render() {
        return this.props.children;
    }
}

Sticky.defaultProps = {
    offsetTop: 80,
    offsetBottom: 10,
    constraintTopAlign: 'top-top',
    constraintBottomAlign: 'bottom-bottom',
    constraintTop: undefined,
    constraintBottom: undefined,
    bypassNativeStickyCheck: false
};

Sticky.propTypes = {
    /**
     Selector of a top element which will be used as limiter for sticky element
     */
    constraintTop: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /**
     Selector of a bottom element which will be used as limiter for sticky element
     */
    constraintBottom: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /**
     Offset from top of top constraint element.
     Increase sticky element anchor from top on entered value.
     */
    offsetTop: PropTypes.number,
    /**
     Offset from bottom of bottom constraint element.
     Increase sticky element anchor from bottom on entered value.
     */
    offsetBottom: PropTypes.number,
    /**
     (custom sticky only) Target constraint point of sticky element and top constraint.
     For example bottom-top means that top of sticky element will adjoin to bottom of constraint
     */
    constraintTopAlign: PropTypes.oneOf(['bottom-bottom', 'top-top', 'bottom-top', 'top-bottom']),
    /**
     (custom sticky only) Target constraint point of sticky element and bottom constraint.
     For example top-bottom means that bottom of sticky element will adjoin to top of constraint
     */
    constraintBottomAlign: PropTypes.oneOf(['bottom-bottom', 'top-top', 'bottom-top', 'top-bottom']),
    /**
     Modified node by sticky component
     */
    children: PropTypes.node.isRequired,
    /**
     Prevent native usage possibility
     */
    bypassNativeStickyCheck: PropTypes.bool
};
