import PropTypes from 'prop-types';
import React from 'react';
import { SelectUser } from '../../../../shared/components';

export default function CollaboratorSelector({ goalId, ...rest }) {
    return (
        <SelectUser
            clearable={false}
            labelKey="title"
            valueKey="id"
            {...rest}
        />
    );
}

CollaboratorSelector.propTypes = {
    goalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};
