import { Collection } from '../../../shared/entities-v2';
import { keysToCamelCase } from '../../../shared/utils/object';
import { apiUrl } from './settings-team-constants';

class SettingsTeamCollection extends Collection {
    parseItem = (data) => {
        return keysToCamelCase(data);
    }
}

const settingsTeamCollection = new SettingsTeamCollection({
    apiUrl,
    name: 'settings-teams',
});

export default settingsTeamCollection;
