import { get } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import { selectHash, selectSearchQuery } from '../../../shared/selectors/routing';
import socialMediaCollection from '../social-media/social-media-collection';
import { getIsSocialSignupAttempt, getIsAuthorizedState } from '../selectors';
import credentialsLoginModel from './credentials-login-model';
import loginModel from './login-model';

const isLoggingIn = state => loginModel.selector(state).loading;
const now = moment();
const expiringDate = moment(now).add(21, 'day');

export const selectIsLoggingIn = createSelector(
    [credentialsLoginModel.selectors.getStateFetching, isLoggingIn],
    (credentialsLogin, login) => credentialsLogin || login
);

export const selectLoginErrorMessage = createSelector(
    [loginModel.selector],
    loginState => get(loginState, ['error', 'data', 'message'])
);

export const selectIsSocialAuthProcess = createSelector(
    [getIsAuthorizedState, getIsSocialSignupAttempt, selectSearchQuery, selectHash],
    (isAuthorized, isSocialSignupAttempt, query, hash) => {
        if (isAuthorized) return false;

        const isSSOCallbackState = !!query.access_token && !!query.oauth_type;
        const isHashParseState = !!hash;

        return isSSOCallbackState || isHashParseState || isSocialSignupAttempt;
    }
);

export const selectSSOProviders = createSelector(
    [socialMediaCollection.selector],
    collectionState => collectionState.items
);

export const selectHasExpiredProvider = createSelector(
    [selectSSOProviders],
    providers => !!providers.find(provider => (
        now.isSameOrAfter(provider.certificate_expiry_at)
    ))
);

export const selectSSOAuthErrors = createSelector(
    [selectSearchQuery],
    query => query['errors[]'] || []
);

export const selectExpiringSSOProviders = createSelector(
    [selectSSOProviders],
    providers => providers.reduce((accum, provider) => {
        const isExpiring = expiringDate.isSameOrAfter(provider.certificate_expiry_at);

        if (isExpiring) {
            const daysToExpiry = moment(provider.certificate_expiry_at)
                .endOf('day')
                .diff(now, 'days');

            accum.push({
                id: provider.id,
                title: provider.title,
                daysToExpiry: Math.max(0, daysToExpiry),
            });
        }

        return accum;
    }, [])
);
