import { createReducer } from 'redux-act';
import u from 'updeep';
import * as actions from './primary-entity-clone-service-actions';

const initialState = {
    parentData: {},
    params: {},
    fetchingParentData: false,
};

const setParentData = (state, payload) => {
    return u({ parentData: payload.data }, state);
};

const resetParentData = () => initialState;

const reducerMap = {
    [actions.error]: resetParentData,
    [actions.success]: resetParentData,
    [actions.setParentData]: setParentData,
};

export default createReducer(reducerMap, initialState);
