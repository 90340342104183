import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bind } from 'lodash';
import { ModalSuccess, Icon, Text } from '../../../shared/components';
import { routing } from '../../../shared/modules';
import { getIsAuthorizedState } from '../../authentication/selectors';
import {
    changeEmailModel,
    changeEmailModal,
    changeEmailSuccessModal,
    changeEmailUpdateSuccessModal,
} from './change-email-model';
import ChangeEmailModal from './change-email-modal';
import { selectSuccessUpdateModalVisible } from './selectors';
import { emailUpdateSuccessModalAttribute } from '../constants';

function ProfileChangeEmailService({
    modalVisible,
    toggleModal,
    onSubmit,
    successModalVisible,
    toggleSuccessUpdateModal,
    successUpdateModalVisible,
    hideModal,
}) {
    const bindedHideModal = bind(hideModal, null, { attribute: emailUpdateSuccessModalAttribute });

    return (
        <Fragment>
            <ChangeEmailModal
                onSubmit={onSubmit}
                visible={modalVisible}
                hide={toggleModal}
            />

            <ModalSuccess
                title="Change email"
                visible={successModalVisible}
                hide={bindedHideModal}
            >
                <p>Further instructions have been sent to the new email address.</p>
                <p>Please check Spam folder if it doesn&apos;t arrive in your inbox.</p>
            </ModalSuccess>

            <ModalSuccess
                title="Your email was updated"
                visible={successUpdateModalVisible}
                hide={toggleSuccessUpdateModal}
            >
                <Text
                    component={Icon}
                    styleType="success"
                    name="check-circle"
                    type={Icon.types.solid}
                    className={ModalSuccess.classNames.bodyIcon}
                />
                <p>Your email has been successfully updated</p>
            </ModalSuccess>
        </Fragment>
    );
}

ProfileChangeEmailService.defaultProps = {
    successModalVisible: false
};

ProfileChangeEmailService.propTypes = {
    modalVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    successModalVisible: PropTypes.bool,
    toggleSuccessUpdateModal: PropTypes.func.isRequired,
    successUpdateModalVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const isAuthorized = getIsAuthorizedState(state);

    return {
        modalVisible: changeEmailModal.selectors.isVisible(state),
        successModalVisible: selectSuccessUpdateModalVisible(state) && !isAuthorized,
        successUpdateModalVisible: changeEmailUpdateSuccessModal.selectors.isVisible(state),
    };
}

const mapDispatchToProps = {
    toggleModal: changeEmailModal.actions.toggle,
    toggleSuccessModal: changeEmailSuccessModal.actions.toggle,
    toggleSuccessUpdateModal: changeEmailUpdateSuccessModal.actions.toggle,
    onSubmit: changeEmailModel.actions.create.request,
    hideModal: routing.actions.removeQueryAttribute,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileChangeEmailService);
