import { takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

function registerSaga({ payload }) {
    payload.sagaMiddleware.run(payload.saga);
}

export default function* saga() {
    yield takeEvery(actions.registerSaga.getType(), registerSaga);
}
