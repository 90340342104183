import {
    call, put, race, take, takeEvery
} from 'redux-saga/effects';
import { get, noop } from 'lodash';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { typeCodes } from '../../utils/entity-type';
import { copy as cloneSegment } from '../../../modules/goalmap-strategy-maps/entity';
import { copy as cloneStrategy } from '../../../modules/goalmap-segments/entity';
import { copy as clonePlan } from '../../../modules/goalmap-plans-actions/entity';
import { clone as cloneKPI } from '../../../modules/goalmap-milestones/entity';
import { csfCopyModel as cloneCSF } from '../../../modules/primary-entities/critical-success-factor/csf-models';
import * as actions from './primary-entity-clone-service-actions';

function getModalAction(typeCode) {
    switch (typeCode) {
        case typeCodes.kpi:
            return cloneKPI.actions.toggle;
        case typeCodes.driver:
            return cloneCSF.actions.toggle;
        case typeCodes.segment:
            return cloneSegment.actions.toggle;
        case typeCodes.objective:
        case typeCodes.strategy:
            return cloneStrategy.actions.toggle;
        case typeCodes.action:
        case typeCodes.tactic:
            return clonePlan.actions.toggle;
        default:
            return noop;
    }
}

function* handleCloneStart(action) {
    yield call(implementPromiseAction, action, function* initializeResolver() {
        const { typeCode, data } = action.payload;

        const modalAction = getModalAction(typeCode);

        yield put(actions.setParentData({ data }));
        yield put(modalAction());

        const { success, error } = yield race({
            success: take(actions.success),
            error: take(actions.error),
        });

        if (error) {
            const reason = get(error, 'payload.reason');
            return Promise.reject(new Error(reason));
        }

        return yield Promise.resolve(success);
    });
}

function* primaryEntityCloneSaga() {
    yield takeEvery(actions.clone, handleCloneStart);
}

export default primaryEntityCloneSaga;
