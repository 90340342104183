import { slugs } from '../../profile-preferences/integrations/config';

export default function formatAttachments(arr) {
    return arr.reduce((accum, item) => {
        const storage = item.storage || slugs.local;
        const file = item.storage ? item.id : item;

        if (storage in accum) {
            accum[storage].push(file);
        } else {
            accum[storage] = [file]; // eslint-disable-line no-param-reassign
        }

        return accum;
    }, {});
}
