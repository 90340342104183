import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { flow, curryRight } from 'lodash';
import * as routeUtils from '../../../routes/rules';
import { applicationSettingsSelectors } from '../../../modules/application';
import { joinGetParams } from '../../utils/url';

function TagLink({
    item,
    goal_id,
    isMobile,
    dispatch,
    ...rest
}) {
    const query = queryString.stringify({
        tag_id: item.id,
        tag_title: item.title,
        goal_id,
    });

    const curriedJoinGetParams = curryRight(joinGetParams)(query);
    const composeSearchHref = flow(
        routeUtils.createUrl,
        curriedJoinGetParams,
    );

    const desktopHref = composeSearchHref(routeUtils.paths.search);
    const mobileHref = composeSearchHref(routeUtils.paths.searchMobile, {
        [routeUtils.params.PlanId]: goal_id,
    });

    return (
        <Link
            to={isMobile ? mobileHref : desktopHref}
            {...rest}
        />
    );
}

TagLink.defaultProps = {
    goal_id: undefined
};

TagLink.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
    }).isRequired,
    goal_id: PropTypes.number,
    isMobile: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isMobile: applicationSettingsSelectors.isMobileSelector(state)
    };
}

export default connect(mapStateToProps)(TagLink);
