import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import createFormModal from '../../../../shared/modules/create-view/component';
import { getCurrentGoalmapMembers, isMemberDeactivated } from '../../../goalmap-list/reducer';
import { collaboratorCreateModel } from '../collaborator-models';
import CollaboratorSelector from './collaborator-selector';

function prepareOptions(members, collaborators) {
    return members
        .filter((member) => {
            const isCollaborator = collaborators.some(collaborator =>
                collaborator.user_id === member.id);
            const isDeactivated = isMemberDeactivated(member);

            return !isCollaborator && !isDeactivated;
        })
        .map(item => ({
            ...item.user_profile,
            id: item.id,
            title: `${item.user_profile.first_name} ${item.user_profile.last_name}`
        }));
}

function CollaboratorCreateForm({ members, collaborators }) {
    const options = prepareOptions(members, collaborators);

    return (
        <Field
            name="users"
            component={CollaboratorSelector}
            props={{
                multi: true,
                options,
            }}
        />
    );
}

CollaboratorCreateForm.propTypes = {
    collaborators: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        }),
    ).isRequired,
    members: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        }),
    ).isRequired,
};

const {
    actions, selector
} = collaboratorCreateModel;

const CollaboratorCreateModal = createFormModal({
    actions,
    selector,
    formName: 'collaborators-create',
    mapStateToProps(state, ownProps) {
        const { parentId, parentEntityType } = ownProps;
        const members = getCurrentGoalmapMembers(state);
        const currentCollaborators = state['collaborators-list'] ? state['collaborators-list'].items : [];

        return {
            title: 'Collaborators',
            draftAvailable: false,
            members,
            collaborators: currentCollaborators,
            initialValues: {
                users: [],
                watcher_id: parentId,
                watcher_type: parentEntityType,
            }
        };
    }
})(CollaboratorCreateForm);

export default CollaboratorCreateModal;
