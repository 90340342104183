import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UrlDataBinder } from '../../modules';
import RoleSelect from './component';

export default class RoleSelectUrlBinded extends Component {
    handleParseQuery = (value) => {
        if (value) {
            this.props.changeHandler(value);
        }
    };

    render() {
        const { queryParameterName, value } = this.props;

        return (
            <UrlDataBinder
                value={value}
                onInitialValueParsed={this.handleParseQuery}
                parameterName={queryParameterName}
            >
                {bindedValue => <RoleSelect {...this.props} value={bindedValue} />}
            </UrlDataBinder>
        );
    }
}

RoleSelectUrlBinded.defaultProps = {
    changeHandler: () => {}
};

RoleSelectUrlBinded.propTypes = {
    queryParameterName: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    changeHandler: PropTypes.func
};
