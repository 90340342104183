import { omit, isNumber } from 'lodash';
import objectToFormData from 'object-to-formdata';
import request from '../../../request';
import { List, createModal, createDetails } from '../../../shared/entities';
import normalizeEntityType, { getCodeByType } from '../../../shared/utils/entity-type';
import formatAttachments from './attachment-format';

const apiUrl = '/attachments';

const formatEntityType = (entityType) => {
    return isNumber(entityType)
        ? entityType
        : getCodeByType(normalizeEntityType(entityType));
};

export const attachmentCollection = new List({
    apiUrl,
    name: 'attachment-list',
    format({ page, entity_id, entity_type }) {
        return {
            entity_id,
            type_code: formatEntityType(entity_type),
            page: page || 1,
        };
    },
});

export const attachmentCreateModel = createModal({
    apiUrl,
    name: 'attachment-create',
    onSuccess: attachmentCollection.actions.addBulk,
    extendAPI: {
        create: ({ params }) => {
            const { parentId, parentEntityType, attachments } = params;
            const result = formatAttachments(attachments);

            return request.post(
                `${apiUrl}/${parentId}/${formatEntityType(parentEntityType)}`,
                objectToFormData({ attachments: result }, { indices: true, })
            );
        }
    }
});

export const attachmentModel = createDetails({
    apiUrl,
    name: 'attachment-details',
    onRemove: [
        attachmentCollection.actions.remove,
        // progressNotesList.actions.request,
    ],
});

export const attachmentStorageCollection = new List({
    getApiUrl(params) {
        return `/external/${params.storageType}/navigate`;
    },
    name: 'storage-file-list',
    format(params) {
        return omit(params, ['storageType']);
    },
});
