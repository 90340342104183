import { get } from 'lodash';
import { get as getCustomProp } from '../../shared/utils/css-custom-properties';
import { isMilestone, } from '../../shared/utils/entity-type';
import { formatHealth } from '../goalmap-plans-actions/utils';
import hexToRgba from '../../shared/utils/color';

const childrenSelectorsByLevel = {
    0(item) { return item.strategy_maps; },
    1(item) { return item.segments; },
    2(item) { return (item.children || item.plans_actions); },
    3(item) { return (item.plans_actions || item.actions); },
    4(item) { return (item.plans_actions || item.actions); },
    5(item) { return (item.plans_actions || item.actions); },
};

export const colorsByStatus = {
    1: '--color-success',
    2: '--color-warning',
    3: '--color-danger',
    4: '--color-neutral',
};

export function getColorByStatus(status) {
    return getCustomProp(colorsByStatus[status]);
}

function getChildrenByLevel(data, level) {
    return childrenSelectorsByLevel[level](data) || [];
}

function getItemInfo(item, id, parent = '') {
    const formattedItem = formatHealth(item);
    let color;

    const isUpdateNeeded = get(item, 'is_need_updated');

    const progressStatus = formattedItem.display_progress_status || formattedItem.progress_status;

    if (isMilestone(item) && isUpdateNeeded) {
        color = getCustomProp(colorsByStatus[3]);
    } else if (colorsByStatus[progressStatus]) {
        color = getColorByStatus(progressStatus);
    } else {
        color = getCustomProp('--color-neutral');
    }

    color = hexToRgba(color, 0.8);

    return {
        id,
        name: id === '0.0' ? formattedItem.title : 'Back',
        parent,
        value: 1,
        color,
        details: formattedItem,
    };
}

function flatten(accum, data, index, array, level = 1, parent = '0.0') {
    const currentIndex = (accum.indexes[level] || index) + 1;
    accum.indexes[level] = currentIndex; // eslint-disable-line no-param-reassign

    const currentId = `${level}.${currentIndex}`;
    const children = getChildrenByLevel(data, level);

    accum.arr.push(getItemInfo(data, currentId, parent));

    if (children && children.length) {
        children.map((it, i, arr) =>
            flatten(
                accum,
                it,
                i,
                arr,
                level + 1,
                currentId
            ));
    }

    return accum;
}

export default function parse(data) {
    let result = [];
    const children = getChildrenByLevel(data, 0) || [];

    result.push(getItemInfo(data, '0.0', ''));

    const flattenedChildren = children.reduce(flatten, {
        indexes: {},
        arr: [],
    });

    result = result.concat(flattenedChildren.arr);

    return result;
}
