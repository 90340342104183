import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

export default function Popup({
    children, content, className, component, disabled, classNameContent,
}) {
    const Component = component;
    return (
        <Component
            className={classnames('popup', {
                [className]: !!className,
            })}
        >
            {children}

            {(content && !disabled) && (
                <span className={classnames('popup-content', {
                    [classNameContent]: !!classNameContent,
                })}
                >{content}
                </span>
            )}
        </Component>
    );
}

Popup.defaultProps = {
    className: undefined,
    classNameContent: undefined,
    children: undefined,
    content: undefined,
    component: 'div',
    disabled: false
};

Popup.propTypes = {
    component: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    classNameContent: PropTypes.string,
    content: PropTypes.node,
    disabled: PropTypes.bool
};
