import { name } from './config';
import { settingsRolesCollection } from '../entity';

export function getBranch(state) {
    return state[name];
}

export function getSelectedItemId(state) {
    return getBranch(state).selectedItemId;
}

export function getIsCreationState(state) {
    return getBranch(state).create;
}

export function getRoleById(state, id) {
    const roles = settingsRolesCollection.selectors.getItems(state);

    return roles.find(role => role.id === id);
}

export function getIsRemovePromptVisible(state) {
    return getBranch(state).removePromptVisible;
}

export function getRemoveRoleId(state) {
    return getBranch(state).removeItemId;
}

export function getSelectedItem(state) {
    const selectedItemId = getSelectedItemId(state);

    return getRoleById(state, selectedItemId);
}

export function getDuplicateItemId(state) {
    return getBranch(state).duplicateItemId;
}

export function getRemoveItem(state) {
    const removeItemId = getRemoveRoleId(state);

    return getRoleById(state, removeItemId);
}

export function getDuplicateItem(state) {
    const removeItemId = getDuplicateItemId(state);

    return getRoleById(state, removeItemId);
}

export function getDirtyFormNavigationId(state) {
    return getBranch(state).dirtyFormNavigationId;
}
