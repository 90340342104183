import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col } from '../../../../shared/components';

const defaultProps = {
    Component: 'div',
    children: undefined,
};

const propTypes = {
    children: PropTypes.node,
    Component: PropTypes.node, // eslint-disable-line react/no-unused-prop-types
};

export function PeriodTargetItem({ children, Component }) {
    return (
        <Component className="period-target-item">
            {children}
        </Component>
    );
}

PeriodTargetItem.defaultProps = defaultProps;
PeriodTargetItem.propTypes = propTypes;

export function PeriodTargetContainer({ children, Component }) {
    return (
        <Component className="period-target-section">
            {children}
        </Component>
    );
}

PeriodTargetContainer.defaultProps = defaultProps;
PeriodTargetContainer.propTypes = propTypes;

export function PeriodTargetItemCol({
    children, centered, className, ...rest
}) {
    return (
        <Col
            className={classnames('period-target-item-col', {
                'period-target-item-col-centered': centered,
                [className]: !!className
            })}
            {...rest}
        >
            {children}
        </Col>
    );
}

PeriodTargetItemCol.defaultProps = {
    children: undefined,
    centered: false,
    className: undefined,
};

PeriodTargetItemCol.propTypes = {
    children: PropTypes.node,
    centered: PropTypes.bool,
    className: PropTypes.string,
};

export function PeriodTargetItemTitle({ children }) {
    return (
        <h4 className="period-target-item-title">
            {children}
        </h4>
    );
}

PeriodTargetItemTitle.defaultProps = defaultProps;
PeriodTargetItemTitle.propTypes = propTypes;
