import ascendingConfig from './configs/ascending';
import descendingConfig from './configs/descending';
import betweenConfig from './configs/between';
import colors from '../../styles/variables';
import { GaugeType, HealthColors } from './constansts';

export function generateConfig(type, data) {
    switch (type) {
        case GaugeType.ASC: return ascendingConfig(data);
        case GaugeType.DESC: return descendingConfig(data);
        case GaugeType.BETWEEN: return betweenConfig(data);
        default: return ascendingConfig(data);
    }
}

export function calculateLabelColor(status) {
    let result;

    switch (status) {
        case 1:
            result = colors[HealthColors.SUCCESS];
            break;

        case 2:
            result = colors[HealthColors.WARNING];
            break;

        case 3:
            result = colors[HealthColors.DANGER];
            break;
        default:
            result = colors[HealthColors.ACCENT];
    }

    return result;
}
