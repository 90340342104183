import React from 'react';
import PropTypes from 'prop-types';
import { getBounds, generateArrowPath, arrowWidth } from './utils';
import './styles.css';

export default function StructureRelationArrow({
    parentCoords,
    childCoords,
    borderCoords,
    color,
    strokeDasharray,
}) {
    const bounds = getBounds({ parentCoords, childCoords, borderCoords });
    const path = generateArrowPath({
        bounds, parentCoords, childCoords, borderCoords
    });

    const {
        leftBound,
        topBound,
        width,
        height,
    } = bounds;

    return (
        <div
            className="relation-arrow-container"
            style={{
                top: `${topBound}px`,
                left: `${leftBound}px`,
                width: `${width}px`,
                height: `${height}px`,
            }}
        >
            <svg>
                <defs>
                    <marker
                        id="arrowhead"
                        markerWidth={arrowWidth}
                        markerHeight={arrowWidth}
                        refX={arrowWidth - 1}
                        refY={arrowWidth / 2}
                        orient="auto"
                        fill={color}
                    >
                        <polygon points={`0 0, ${arrowWidth} ${arrowWidth / 2}, 0 ${arrowWidth}`} />
                    </marker>
                </defs>

                <path
                    d={path}
                    stroke={color}
                    strokeDasharray={strokeDasharray}
                    fill="transparent"
                    strokeWidth="2"
                    markerEnd="url(#arrowhead)"
                />
            </svg>
        </div>

    );
}

const coordsShape = {
    x: PropTypes.number,
    y: PropTypes.number,
};

StructureRelationArrow.defaultProps = {
    color: 'var(--color-mischka)',
    strokeDasharray: undefined,
    borderCoords: undefined,
};

StructureRelationArrow.propTypes = {
    parentCoords: PropTypes.shape(coordsShape).isRequired,
    childCoords: PropTypes.shape(coordsShape).isRequired,
    borderCoords: PropTypes.shape(coordsShape),
    color: PropTypes.string,
    strokeDasharray: PropTypes.string,
};
