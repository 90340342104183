import u from 'updeep';
import request from '../../../request';
import { getEntitiesByType } from '../../../shared/utils/content-editor-entity-selector';

function validateVersion({ id, type, version }) {
    return request.get('/versioning', { params: { id, class: type } })
        .then(response => response.versioning === version);
}

function defaultFormatFn(options) {
    return options;
}

export default function createAPI(url, updateHTML, format = defaultFormatFn) {
    function update({ id, ...rest }) {
        let safeUrl = url;

        if (typeof url === 'function') {
            safeUrl = url(rest);
        }

        let params = format(rest);

        if (updateHTML) {
            const html = rest.description || rest.title;
            const mentions = getEntitiesByType(html, 'mention');

            params = u(
                { mentions },
                params,
            );
        }

        return request.put(`${safeUrl}/${id}`, params);
    }

    function updateWithVersionValidation(args) {
        const {
            params,
            versioning,
            type,
            ...restArgs
        } = args;
        const { id, ...rest } = (params || restArgs);

        if (!versioning) {
            return update({ id, ...rest });
        }

        return new Promise((resolve, reject) =>
            validateVersion({
                id,
                type,
                version: versioning,
            }).then((valid) => {
                if (!valid) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return reject({
                        versioning: 'Version conflict'
                    });
                }

                return update({ id, ...rest }).then(resolve, reject);
            }, reject));
    }

    return {
        get({ id, ...rest }) {
            return request.get(`${url}/${id}`, { params: rest }).then(details =>
                new Promise((resolve) => {
                    const content = details.description || details.content || '';

                    resolve(
                        u({ description: content }, details)
                    );
                }));
        },
        update: updateWithVersionValidation,
        updateTags(options) {
            const { id, tags } = options.params;

            return request.post(`${url}/${id}/tags`, {
                tags: (tags || []).map(item => (
                    item.title === item.id
                        ? { title: item.title }
                        : { id: item.id, title: item.title }
                ))
            });
        },
        updateStatus(options) {
            const { id, state } = options;
            return request.put(`${url}/${id}/state/${state}`);
        },
        remove(data) {
            let safeUrl = url;
            const obj = data.params || data;

            if (typeof url === 'function') {
                safeUrl = url(obj);
            }

            return request.delete(`${safeUrl}/${obj.id}`);
        },
        setWatchStatus({ id, type, status }) {
            if (status) {
                return request.post('collaborators/watch', {
                    watcher_id: id,
                    watcher_type: type,
                });
            }

            return request.delete(`collaborators/${id}/${type}`);
        },
    };
}
