import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/component';

export default function ActionSheetItem({ children, ...rest }) {
    return (
        <Button
            className="action-sheet-item"
            block
            {...rest}
        >
            {children}
        </Button>
    );
}

ActionSheetItem.defaultProps = {
    children: undefined,
};

ActionSheetItem.propTypes = {
    children: PropTypes.node,
};
