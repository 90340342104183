import u from 'updeep';
import { formatByType } from '../../../../modules/goalmap-milestones/utils';
import { roundDecimal } from '../../../utils/math';
import colors from '../../../styles/variables';
import {
    prepareOuterSections, prepareInnerSections, getSectionColorByValue
} from './utils';
import {
    defaultHeight, defaultColor, defaultPivotRadius, defaultCurrencySymbol,
} from './constants';
import baseConfig from './base';
import { commasBetweenThousands } from '../../../utils/formatters';

const sectionColors = [
    colors['color-danger'],
    colors['color-warning'],
    colors['color-success'],
];

function normalizeExceedingValue(lowBound, highBound, value) {
    if (value < highBound) return highBound;
    if (value > lowBound) return lowBound;
    return value;
}

export default function descending({
    bounds,
    value,
    type,
    showValues = true,
    backgroundColor = defaultColor,
    height = defaultHeight,
    size,
    range,
    radius = defaultPivotRadius,
    currencySymbol = defaultCurrencySymbol,
}) {
    const lowBound = bounds[0];
    const highBound = bounds[bounds.length - 1];
    const safeValue = normalizeExceedingValue(lowBound, highBound, value) || lowBound;

    const series = [{
        name: 'Value',
        data: [{
            y: safeValue,
            dataLabels: {
                formatter() {
                    return formatByType(commasBetweenThousands(roundDecimal(value)), type, currencySymbol);
                },
                color: showValues
                    ? getSectionColorByValue(bounds, sectionColors, safeValue, range)
                    : undefined,
            }
        }],
    }];

    const tickPositions = bounds.map(roundDecimal);

    return u({
        chart: u({
            backgroundColor,
            height,
        }, baseConfig.chart),
        pane: u({
            size,
        }, baseConfig.pane),
        plotOptions: {
            gauge: {
                pivot: {
                    radius,
                }
            }
        },
        yAxis: [
            u({
                min: highBound,
                max: lowBound,
                tickPositions,
                reversed: true,
                labels: {
                    distance: 20,
                },
                plotBands: [
                    ...prepareOuterSections(bounds, sectionColors, safeValue),
                    ...prepareInnerSections(bounds, sectionColors, safeValue, range),
                ],
            }, baseConfig.yAxis),
        ],
        series
    }, baseConfig);
}
