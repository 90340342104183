import { all } from 'redux-saga/effects';
import { userSaga } from '../modules/user/saga';
import userUISettingsModel from '../modules/user/user-ui-settings-model';
import goalmapListSaga from '../modules/goalmap-list/saga';
import { plansListMy } from '../modules/plans-list-my/entity';
import plansListMySaga from '../modules/plans-list-my/saga';
import goalmapWizardSaga from '../modules/goalmap-wizard/saga';
import milestoneWizardSaga from '../modules/goalmap-milestones/create/saga';
import entitiesConnectorSaga from '../shared/entities/connector/saga';
import Notifications from '../../../sc-packages/notifications';
import navigationGoalmapTree from '../modules/navigation-goalmap-tree/entity';
import {
    details as strategyMapModel,
    list as strategyMapList,
    tree as strategyMapVisualization,
    activity as strategyMapActivity,
    create as strategyMapCreate,
    update as strategyMapUpdate,
    copy as strategyMapCopy,
} from '../modules/goalmap-strategy-maps/entity';
import {
    list as segmentList,
    details as segmentDetails,
    create as segmentCreate,
    update as segmentUpdate,
    createChildren as segmentAddChildren,
    copy as segmentCopy,
} from '../modules/goalmap-segments/entity';
import {
    list as segmentChildrenList,
    create as segmentChildrenCreate,
    createObjectiveChildren as objectiveChildrenCreate,
    details as segmentChildrenDetails
} from '../modules/goalmap-segment-children/entity';
import {
    list as planList,
    details as planDetails,
    create as planCreate,
    update as planUpdate,
    detailsWizard as planWizardDetails,
    copy as planCopy,
} from '../modules/goalmap-plans-actions/entity';
import searchCollection from '../modules/search/entity';
import searchSaga from '../modules/search/saga';
import { notificationsHeaderCollection } from '../modules/navigation-header/notifications/entity';
import searchHeaderCollection from '../modules/navigation-header/search/entity';
import profileModel from '../modules/profile/entity';
import teamCollection from '../modules/teams/teams-collection';
import teamModel from '../modules/teams/team-model';
import {
    notificationModel, notificationsDesktopCollection, saga as notificationUpdateSaga,
} from '../modules/notifications/entity';
import notificationToastCollection from '../modules/notifications/toast/notification-toast-collection';
import navigationContextSaga from '../modules/navigation-context/saga';
import mobileNotificationSaga from '../modules/notifications/mobile-notification-saga';
import { settingsIntegrationsModel } from '../modules/settings-integrations/entity';
import { settingsIntegrationsStorageCollection } from '../modules/settings-integrations/storages/entity';
import settingsIntegrationsGoalList from '../modules/settings-integrations/goal-list/entity';
import {
    list as profilePreferencesIntegrationsList,
    details as profilePreferencesIntegrationsDetails,
} from '../modules/profile-preferences/integrations/entity';
import { settingsRolesCollection, settingsRolesModel, } from '../modules/settings-roles/entity';
import {
    saga as settingsRolesContainerStateSaga
} from '../modules/settings-roles/container-state';
import { settingsUserCollection, settingsUsersModel, } from '../modules/settings-users/entity';
import { settingsAccountDemoDataCollection, settingsAccountDemoDataModel } from '../modules/settings-account/demo-data';
import pushNotificationsSaga from '../modules/push-notifications/saga';
import {
    csfRelatedLinkCollection, csfRelatedLinkModel, csfRelatedLinkSaga, csfRelatedLinkCreateModel
} from '../modules/primary-entities/critical-success-factor/csf-related-link';
import {
    csfCollection, csfModel, csfCreateModel, csfSaga, csfCopyModel
} from '../modules/primary-entities/critical-success-factor';
import {
    list as entitiesMoveList,
    move as entityMoveDetails,
} from '../shared/modules/with-move-entity/entity';
import {
    details as goalmapSettingsNotificationsDetails,
} from '../modules/goalmap-settings-notifications/entity';
import authenticationSaga from '../modules/authentication/saga';
import loginModel from '../modules/authentication/login/login-model';
import socialMediaCollection from '../modules/authentication/social-media/social-media-collection';
import authenticationTabSyncSaga from '../modules/authentication/tab-sync-saga';
import signupModel from '../modules/authentication/signup/signup-model';
import inviteTokenModel from '../modules/authentication/signup/invite-token-model';
import passwordPolicyCollection from '../modules/authentication/components/password-policy/password-policy-collection';
import resetPasswordRequestModel from '../modules/authentication/reset-password/reset-password-request-model';
import resetPasswordModel from '../modules/authentication/reset-password/reset-password-model';
import { settingsAccountModel } from '../modules/settings-account/profile/entity';
import goalSettingsTitlesModel from '../modules/goalmap-settings-titles/goalmap-settings-titles-model';
import goalSettingsTitlesSaga from '../modules/goalmap-settings-titles/goalmap-settings-titles-sync';
import goalWizarVMVModel from '../modules/goalmap-wizard/steps/vision-mission-values/vmv-info-model';
import quickAddModel from '../modules/navigation-header/quick-add/quick-add-model';
import integrationCommunicationModel from '../modules/settings-integrations/communication-details/communication-model';
import goalmapMailstoneWidgetsModel from '../modules/goalmap-milestones/widgets/widgets-model';
import {
    list as archivedGoalMapsList,
    details as archivedGoalMapsDetails
} from '../modules/archived-goal-maps/entity';
import {
    attachmentCollection,
    attachmentModel,
    attachmentCreateModel,
    attachmentStorageCollection,
} from '../modules/secondary-entities/attachment';
import {
    progressNoteCollection,
    progressNoteCreateModel,
    progressNoteModel,
    progressNoteSaga,
} from '../modules/secondary-entities/progress-note';
import { historyCollection } from '../modules/secondary-entities/history';
import {
    collaboratorSaga,
    collaboratorCollection,
    collaboratorCreateModel,
    collaboratorModel,
} from '../modules/secondary-entities/collaborator';
import {
    relatedLinkSaga,
    relatedLinkCollection,
    relatedLinkCreateModel,
    relatedLinkModel,
    csfConnectionCollection,
    csfConnectionCreateModel,
    csfConnectionModel,
    csfConnectionSaga,
} from '../modules/secondary-entities/related-link';
import {
    dashboardWidgetCollection,
    dashboardWidgetCreateModel,
    dashboardWidgetsOrderModel,
    dashboardWidgetAvailableCollection,
    dashboardWidgetMyWorkCollection,
    dashboardWidgetRemindersCollection,
    dashboardWidgetRemindersModel,
    dashboardWidgetDraftsCollection,
    dashboardWidgetActivityStreamCollection,
    dashboardWidgetActivityStreamModel,
} from '../modules/dashboard';
import {
    create as milestoneDataPointCreate,
    details as milestoneDataPointDetails,
    list as milestoneDataPointList,
    createOnMobile as milestoneDataPointCreateMobile,
} from '../modules/goalmap-milestones-breakdown/entity';
import {
    create as goalmapMilestonesCreate,
    clone as goalmapMilestonesClone,
    details as goalmapMilestonesDetails,
    list as goalmapMilestonesList,
    parentMilestones as goalmapParentMilestonesList,
    saga as goalmapMilestonesSaga,
} from '../modules/goalmap-milestones/entity';
import { list as navigationHeaderList, createProgressNotes } from '../modules/navigation-header/entity';
import {
    settingsAccountExportModel, settingsAccountExportCollection,
} from '../modules/settings-account/export/entity';
import { settingsAccountSubscriptionModel } from '../modules/settings-account/subscription/entity';
// import {
//     settingsTeamsCollection, settingsTeamsModel,
// } from '../modules/settings-teams/entity';
import settingsTeamCollection from '../modules/settings/teams/settings-team-collection';
import settingsTeamModel from '../modules/settings/teams/settings-team-model';
import { subscriptionLimitCollection, subscriptionSaga } from '../modules/subscription';
import userNotifications from '../modules/user-notifications/entity';
import goalMemberRolesList from '../shared/modules/goal-member-roles/entity';
import {
    applicationSettingsModel,
    networkStatusSaga,
    serviceWorkerSaga,
    colorThemeSaga,
} from '../modules/application';
import appIntegrationCollection from '../modules/application/integrations/integrations-collection';
import teamMemberRolesList from '../shared/modules/team-member-roles/entity';
import { changePasswordModel } from '../modules/profile/change-password/change-password-model';
import { changeEmailModel } from '../modules/profile/change-email/change-email-model';
import changePasswordSaga from '../modules/profile/change-password/change-password-saga';
import changeEmailSaga from '../modules/profile/change-email/change-email-saga';
import authChangeEmailModel from '../modules/authentication/change-email/change-email-model';
import routingSaga from '../shared/modules/routing/saga';
import milestonePeriodTargetCollection from '../modules/goalmap-milestones/settings-form/period-target-collection';
import permissionModel from '../modules/permissions/permission-model';
import wizardGoalTreeCollection from '../modules/wizard-goalmap-tree/entity';
import permissionsSaga from '../modules/permissions/saga';
import tacticChildrenCreateSaga from '../modules/goalmap-plans-actions/create/saga';
import { spMicroWidgetsModel } from '../modules/goalmap-strategy-maps/micro-widgets/entity';
import { recentPlansModel } from '../modules/navigation-header/plans-selector/entity';
import { plansOverviewList } from '../modules/plan/modules/plan-list-overview/entity';
import memberCollection from '../modules/plan/modules/members/collection';
import workCenterCollection from '../modules/work-center/work-center-collection';
import workCenterCustomFilterCollection from '../modules/work-center/custom-filters/collection';
import workCenterCustomFilterModelUpdate, { workCenterCustomFilterModelView } from '../modules/work-center/custom-filters/model';
import workCenterCustomFilterSaga from '../modules/work-center/custom-filters/saga';
import { model as workCenterGridModel } from '../modules/work-center/grid/model';
import serviceAuthModel from '../modules/authentication/service-auth/service-auth-model';
import credentialsLoginModel from '../modules/authentication/login/credentials-login-model';
import milestoneDataPointSaga from '../modules/goalmap-milestones-breakdown/list-saga';
import milestonesListMobileList from '../modules/goalmap-milestones/list-mobile/entity';
import segmentChildrenListSaga from '../modules/goalmap-segment-children/list/saga';
import segmentsListSaga from '../modules/goalmap-segments/list/saga';
import plansActionsListSaga from '../modules/goalmap-plans-actions/list/saga';
import { model as workCenterTimelineModel } from '../modules/work-center/timeline/model';
import workCenterSaga from '../modules/work-center/saga';
import planSaga from '../modules/plan/plan-saga';
import primaryEntityCreateSaga from '../shared/modules/primary-entity-create-service/primary-entity-create-service-saga';
import primaryEntityCloneSaga from '../shared/modules/primary-entity-clone-service/primary-entity-clone-service-saga';
import appLocalizationsCollection from '../modules/application/localizations/localizations-collection';
import { navigationGoalMaplist } from '../modules/navigation-goalmap-selector/entity';
import publicApiKeyModel from '../modules/settings-integrations/public-api/public-api-key-model';
import workCenterDefaultFitlerModel from '../modules/work-center/work-center-default-filter-model';
import userTeamsCollection from '../modules/user/user-teams-collection';
import settingsSecuritySsoProviderModel from '../modules/settings/security/settings-security-sso-provider/entity';
import settingsSecuritySsoProviderCollection from '../modules/settings/security/settings-security-sso-list-provider/entity';
import settingsAccountMultiFactorAuthModel from '../modules/settings/security/settings-security-multi-factor-auth/entity';
import { preferencesAccountSecurityMFADisableModal, preferencesAccountSecurityMFARecoveryCodeModal } from
    '../modules/preferences/account-security/preferences-account-security-models';

export default function* rootSaga() {
    yield all([
        userSaga(),
        userUISettingsModel.saga(),
        goalmapListSaga(),
        plansListMy.saga(),
        plansListMySaga(),
        goalmapWizardSaga(),
        milestoneWizardSaga(),
        colorThemeSaga(),
        entitiesConnectorSaga(),
        Notifications.saga(),
        networkStatusSaga(),
        navigationGoalmapTree.saga(),
        strategyMapModel.saga(),
        strategyMapList.saga(),
        strategyMapVisualization.saga(),
        segmentList.saga(),
        segmentDetails.saga(),
        segmentCreate.saga(),
        segmentUpdate.saga(),
        segmentCopy.saga(),
        segmentAddChildren.saga(),
        segmentChildrenList.saga(),
        segmentChildrenCreate.saga(),
        objectiveChildrenCreate.saga(),
        segmentChildrenDetails.saga(),
        planList.saga(),
        planDetails.saga(),
        planCreate.saga(),
        planUpdate.saga(),
        planCopy.saga(),
        planWizardDetails.saga(),
        searchCollection.saga(),
        searchSaga(),
        notificationsHeaderCollection.saga(),
        searchHeaderCollection.saga(),
        profileModel.saga(),
        teamCollection.saga(),
        teamModel.saga(),
        notificationModel.saga(),
        notificationsDesktopCollection.saga(),
        notificationToastCollection.saga(),
        notificationUpdateSaga(),
        navigationContextSaga(),
        mobileNotificationSaga(),
        settingsIntegrationsModel.saga(),
        settingsIntegrationsStorageCollection.saga(),
        settingsRolesCollection.saga(),
        settingsRolesModel.saga(),
        settingsRolesContainerStateSaga(),
        settingsUserCollection.saga(),
        settingsAccountDemoDataCollection.saga(),
        settingsAccountDemoDataModel.saga(),
        pushNotificationsSaga(),
        entitiesMoveList.saga(),
        loginModel.saga(),
        authenticationSaga(),
        socialMediaCollection.saga(),
        authenticationTabSyncSaga(),
        signupModel.saga(),
        inviteTokenModel.saga(),
        passwordPolicyCollection.saga(),
        resetPasswordRequestModel.saga(),
        resetPasswordModel.saga(),
        settingsAccountModel.saga(),
        goalSettingsTitlesModel.saga(),
        goalSettingsTitlesSaga(),
        goalWizarVMVModel.saga(),
        quickAddModel.saga(),
        integrationCommunicationModel.saga(),
        goalmapMailstoneWidgetsModel.saga(),
        archivedGoalMapsList.saga(),
        archivedGoalMapsDetails.saga(),

        attachmentCollection.saga(),
        attachmentModel.saga(),
        attachmentCreateModel.saga(),
        attachmentStorageCollection.saga(),

        progressNoteCollection.saga(),
        progressNoteModel.saga(),
        progressNoteCreateModel.saga(),
        progressNoteSaga(),

        historyCollection.saga(),

        collaboratorSaga(),
        collaboratorCollection.saga(),
        collaboratorModel.saga(),
        collaboratorCreateModel.saga(),

        relatedLinkSaga(),
        relatedLinkCollection.saga(),
        relatedLinkCreateModel.saga(),
        relatedLinkModel.saga(),
        csfConnectionCollection.saga(),
        csfConnectionCreateModel.saga(),
        csfConnectionModel.saga(),
        csfConnectionSaga(),

        dashboardWidgetCollection.saga(),
        dashboardWidgetCreateModel.saga(),
        dashboardWidgetsOrderModel.saga(),
        dashboardWidgetAvailableCollection.saga(),
        dashboardWidgetMyWorkCollection.saga(),
        dashboardWidgetRemindersCollection.saga(),
        dashboardWidgetRemindersModel.saga(),
        dashboardWidgetDraftsCollection.saga(),
        dashboardWidgetActivityStreamCollection.saga(),
        dashboardWidgetActivityStreamModel.saga(),

        csfRelatedLinkCollection.saga(),
        csfRelatedLinkCreateModel.saga(),
        csfRelatedLinkModel.saga(),
        csfRelatedLinkSaga(),

        csfCollection.saga(),
        csfCreateModel.saga(),
        csfCopyModel.saga(),
        csfModel.saga(),
        csfSaga(),

        milestoneDataPointCreate.saga(),
        milestoneDataPointCreateMobile.saga(),
        milestoneDataPointDetails.saga(),
        milestoneDataPointList.saga(),
        goalmapMilestonesCreate.saga(),
        goalmapMilestonesClone.saga(),
        goalmapMilestonesDetails.saga(),
        goalmapMilestonesList.saga(),
        goalmapParentMilestonesList.saga(),
        entityMoveDetails.saga(),
        goalmapSettingsNotificationsDetails.saga(),
        strategyMapActivity.saga(),
        strategyMapCreate.saga(),
        strategyMapUpdate.saga(),
        strategyMapCopy.saga(),
        navigationHeaderList.saga(),
        createProgressNotes.saga(),
        profilePreferencesIntegrationsList.saga(),
        profilePreferencesIntegrationsDetails.saga(),
        settingsAccountExportModel.saga(),
        settingsAccountExportCollection.saga(),
        settingsAccountSubscriptionModel.saga(),
        settingsUsersModel.saga(),
        settingsTeamCollection.saga(),
        settingsTeamModel.saga(),
        subscriptionLimitCollection.saga(),
        userNotifications.saga(),
        goalMemberRolesList.saga(),
        applicationSettingsModel.saga(),
        teamMemberRolesList.saga(),
        settingsIntegrationsGoalList.saga(),
        subscriptionSaga(),
        goalmapMilestonesSaga(),
        serviceWorkerSaga(),
        changePasswordModel.saga(),
        changePasswordSaga(),
        changeEmailModel.saga(),
        changeEmailSaga(),
        authChangeEmailModel.saga(),
        routingSaga(),
        milestonePeriodTargetCollection.saga(),
        permissionModel.saga(),
        wizardGoalTreeCollection.saga(),
        permissionsSaga(),
        tacticChildrenCreateSaga(),
        spMicroWidgetsModel.saga(),
        recentPlansModel.saga(),
        plansOverviewList.saga(),
        memberCollection.saga(),
        workCenterCollection.saga(),
        workCenterCustomFilterCollection.saga(),
        workCenterCustomFilterModelUpdate.saga(),
        workCenterCustomFilterModelView.saga(),
        serviceAuthModel.saga(),
        credentialsLoginModel.saga(),
        milestoneDataPointSaga(),
        milestonesListMobileList.saga(),
        workCenterCustomFilterSaga(),
        appIntegrationCollection.saga(),
        segmentChildrenListSaga(),
        segmentsListSaga(),
        plansActionsListSaga(),
        workCenterTimelineModel.saga(),
        workCenterSaga(),
        planSaga(),
        primaryEntityCreateSaga(),
        primaryEntityCloneSaga(),
        appLocalizationsCollection.saga(),
        navigationGoalMaplist.saga(),
        publicApiKeyModel.saga(),
        workCenterGridModel.saga(),
        workCenterDefaultFitlerModel.saga(),
        userTeamsCollection.saga(),
        settingsSecuritySsoProviderModel.saga(),
        settingsSecuritySsoProviderCollection.saga(),
        settingsAccountMultiFactorAuthModel.saga(),
        preferencesAccountSecurityMFADisableModal.saga(),
        preferencesAccountSecurityMFARecoveryCodeModal.saga(),
    ]);
}
