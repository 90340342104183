import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { GridContainer, Text, Icon } from '../../index';
import { entityType as milestoneEntityType, locations as milestoneLocation } from '../../../../modules/goalmap-milestones/constants';

import styles from './styles.module.css';

const progressItemsOffsetPx = 3;

const isRenderMilestoneLocationDescription = (entityType, entityLocation, currentStep) =>
    (entityType === milestoneEntityType && !(entityLocation === milestoneLocation.plan && currentStep === 1));

class WizardHeader extends Component {
    get currentStepIndex() {
        return this.props.currentStep - 1;
    }

    get currentStep() {
        return this.props.steps[this.currentStepIndex] || {};
    }

    renderProgress = (it, index) => {
        const { steps } = this.props;
        const style = {
            width: `calc(${100 / steps.length}% - ${progressItemsOffsetPx}px)`,
        };

        return (
            <div
                key={index}
                style={style}
                className={cx(styles.wizardHeaderProgressItem, {
                    [styles.active]: this.currentStepIndex >= index,
                })}
            />
        );
    }

    render() {
        const {
            title, className, steps, currentStep, entity,
        } = this.props;
        const totalSteps = steps.length;
        const entityLocation = get(entity, 'location', milestoneLocation.plan);

        return (
            <div
                className={cx(styles.wizardHeader, {
                    [className]: !!className,
                })}
            >
                <GridContainer>
                    <div className={styles.wizardHeaderTitleContainer}>
                        <Text
                            component="h1"
                            size="large"
                            className={styles.wizardHeaderTitle}
                        >
                            {title}
                        </Text>
                        <Text>Step {currentStep} of {totalSteps}</Text>
                    </div>

                    <div className={styles.wizardHeaderProgressContainer}>
                        {steps.map(this.renderProgress)}
                    </div>

                    <div className={styles.wizardHeaderDescriptionContainer}>
                        <Text className={styles.wizardHeaderDescription}>
                            {this.currentStep.description}
                        </Text>
                        {isRenderMilestoneLocationDescription(entity.type, entityLocation, currentStep) && (
                            <div>
                                <Icon
                                    name={entityLocation === milestoneLocation.plan ? 'layer-group' : 'globe'}
                                    type={Icon.types.regular}
                                    className={styles.wizardHeaderDescriptionKpiIcon}
                                />
                                <Text>{entityLocation === milestoneLocation.plan ? 'Plan Level' : 'Global Level'}</Text>
                            </div>
                        )}
                    </div>
                </GridContainer>
            </div>
        );
    }
}

WizardHeader.defaultProps = {
    className: undefined,
    currentStep: 1,
};

WizardHeader.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    currentStep: PropTypes.number,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
            ]),
        }),
    ).isRequired,
    entity: PropTypes.shape({
        type: PropTypes.string,
        location: PropTypes.string,
    }).isRequired,
};

export default WizardHeader;
