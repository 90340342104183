import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './table-view-cell.css';

export default function TableViewCell({
    children, className, align, colspan, noBorder, overflowVisible
}) {
    return (
        <td
            colSpan={colspan}
            className={cx(
                'table-view-cell',
                align && `table-view-cell-align-${align}`,
                className,
                {
                    'table-view-cell-no-border': noBorder,
                    'table-view-cell-overflow-visible': overflowVisible,
                }
            )}
        >
            {children}
        </td>
    );
}

TableViewCell.defaultProps = {
    children: undefined,
    className: undefined,
    align: undefined,
    colspan: '1',
    noBorder: false,
    overflowVisible: false,
};

TableViewCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    align: PropTypes.string,
    colspan: PropTypes.string,
    noBorder: PropTypes.bool,
    overflowVisible: PropTypes.bool,
};
