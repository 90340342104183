import React from 'react';
import PropTypes from 'prop-types';
import { GridContainer, Icon } from '../.';
import './styles.css';

export default function ErrorPanel({ children }) {
    return (
        <div className="error-panel">
            <GridContainer className="error-panel">
                <Icon className="error-panel-icon" name="exclamation-circle" />
                {children}
            </GridContainer>
        </div>
    );
}

ErrorPanel.defaultProps = {
    children: undefined
};

ErrorPanel.propTypes = {
    children: PropTypes.node
};
