import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import {
    Icon,
    LoadingIndicator,
    Image,
    MetaInfo,
    UserCard,
    Text,
    Clickable
} from '../../../../shared/components';
import { LinkDownloadSecure } from '../../../../shared/modules';
import isOwnEntity from '../../../../shared/utils/permissions';
import Permissions from '../../../permissions';
import getStorageIcon from '../attachment-storage-icon';

import styles from './attachment-item.module.css';

export default function AttachmentItem(props) {
    const {
        id,
        user_filename,
        attachment,
        author,
        updated_at,
        onRemove,
        updatingItemId,
        permissions,
        userId,
    } = props;

    const isOwn = isOwnEntity(userId, props);
    const canDestroy = get(permissions, Permissions.keys.canDestroy);
    const canDestroyOwn = get(permissions, Permissions.keys.canDestroyOwn) && isOwn;
    const destroyAllowed = canDestroy || canDestroyOwn;

    return (
        <div className={styles.attachmentItem}>
            {
                updatingItemId === id
                && (
                    <div className="loading-overlay">
                        <LoadingIndicator size="small" centered />
                    </div>
                )
            }
            <UserCard
                firstName={author.user_profile.first_name}
                lastName={author.user_profile.last_name}
                avatar={author.user_profile.image}
            />
            <div className={styles.attachmentDownloadDeleteContainer}>
                <LinkDownloadSecure
                    className={styles.attachmentDownloadLink}
                    containerClassName={styles.attachmentDownloadLinkContainer}
                    url={`/attachments/${id}`}
                    name={user_filename}
                    downloadIconEnabled={false}
                >
                    <div className={styles.attachmentStorageContainer}>
                        <Image
                            src={getStorageIcon(attachment.storage_type)}
                            alt="storage-icon"
                            className={styles.attachmentStorageIcon}
                        />
                        {user_filename}
                    </div>
                    <Text
                        component={Icon}
                        name="arrow-to-bottom"
                        type={Icon.types.regular}
                        styleType="muted"
                        size="medium-large"
                    />
                </LinkDownloadSecure>
                {destroyAllowed && (
                    <Clickable onClick={() => onRemove(id)}>
                        <Icon
                            name="trash-alt"
                            type="regular"
                            className={styles.attachmentRemoveIcon}
                        />
                    </Clickable>
                )}
            </div>
            <MetaInfo date={updated_at || attachment.created_at} />
        </div>
    );
}

AttachmentItem.defaultProps = {
    updatingItemId: undefined,
    userId: undefined
};

AttachmentItem.propTypes = {
    attachment: PropTypes.shape({
        created_at: PropTypes.string,
        filename: PropTypes.string,
        storage_type: PropTypes.string
    }).isRequired,
    author: PropTypes.shape({
        user_profile: PropTypes.object,
    }).isRequired,
    user_filename: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
    updatingItemId: PropTypes.number,
    permissions: PropTypes.shape({
        destroy: PropTypes.bool,
        destroy_own: PropTypes.bool,
    }).isRequired,
    userId: PropTypes.number,
};
