import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { last } from 'lodash';
import config from '../../../config';

class NavigationPrompt extends Component {
    componentDidMount() {
        const { router } = this.props;

        this.remove = router.setRouteLeaveHook(
            last(router.routes),
            this.routerWillLeave
        );

        window.addEventListener('beforeunload', this.handleWindowUnload);
    }

    componentWillUnmount() {
        this.remove();
        window.removeEventListener('beforeunload', this.handleWindowUnload);
    }

    handleWindowUnload = (e) => {
        const { when } = this.props;

        if (when) {
            e.returnValue = '';
        }
    };

    routerWillLeave = () => {
        const { when, message } = this.props;

        if (when) {
            return message;
        }

        return undefined;
    };

    render() {
        return null;
    }
}

NavigationPrompt.defaultProps = {
    message: config.navigationBlockMessage
};

NavigationPrompt.propTypes = {
    router: PropTypes.object.isRequired,
    when: PropTypes.bool.isRequired,
    message: PropTypes.string
};

export default withRouter(NavigationPrompt);
