import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoadingIndicator } from '../../../shared/components';
import entity from './entity';

class RoleList extends Component {
    state = { afterLoad: false };

    componentDidMount() {
        const { silent, type } = this.props;

        this.props.fetch({ type, silent });
    }

    componentWillReceiveProps(nextProps) {
        const { loading } = this.props;

        if (!nextProps.loading && loading) {
            this.setState({ afterLoad: true });
        }
    }

    render() {
        const {
            children, loading, ...rest
        } = this.props;

        const { afterLoad } = this.state;

        if (loading && !afterLoad) {
            return (<LoadingIndicator centered />);
        }

        if (typeof children === 'function') {
            return children({
                ...rest,
                loading,
            });
        }

        return children;
    }
}

RoleList.defaultProps = {
    children: undefined,
    silent: false,
    type: 1
};

RoleList.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func
    ]),
    loading: PropTypes.bool.isRequired,
    silent: PropTypes.bool,
    fetch: PropTypes.func.isRequired,
    type: PropTypes.number,
};

function mapStateToProps(state) {
    const branch = entity.selector(state);

    return {
        loading: branch.loading,
        items: branch.items,
    };
}

const actions = {
    fetch: entity.actions.request,
};

const connected = connect(
    mapStateToProps,
    actions,
)(RoleList);

export default connected;
