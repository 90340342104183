import request from '../../request';
import { List, createDetails, createModal } from '../../shared/entities';
import { getEntitiesByType } from '../../shared/utils/content-editor-entity-selector';
import * as actions from './actions';

const apiUrl = '/milestones_datapoints';

export const list = new List({
    apiUrl,
    name: 'milestone-data-point-list',
    extendReducer: {
        [actions.setProcessedItemPeriod]: (state, periodStart) => {
            return {
                ...state,
                processedItemPeriodStart: periodStart
            };
        }
    }
});

const emptyValue = '<p></p>';

function formatPNContent(data) {
    const mentions = getEntitiesByType(data.progress_note_content || emptyValue, 'mention');

    return {
        ...data,
        progress_note_mentions: mentions,
        progress_note_content: data.progress_note_content === emptyValue
            ? undefined
            : data.progress_note_content,
    };
}

export const create = createModal({
    apiUrl,
    name: 'milestone-data-point-create',
    onSuccess: list.actions.add,
    format({ params }) {
        return formatPNContent(params);
    },
    getRequestMethod({ params }) {
        return params.data_points
            ? 'patch'
            : 'post';
    },
});

export const createOnMobile = createModal({
    apiUrl,
    name: 'milestone-data-point-create-mobile',
    onSuccess: list.actions.add,
    format({ params }) {
        return formatPNContent(params);
    },
    getRequestMethod({ params }) {
        return params.data_points
            ? 'patch'
            : 'post';
    },
});

export const details = createDetails({
    apiUrl,
    name: 'milestone-data-point-details',
    onUpdate: list.actions.update,
    onRemove: list.actions.remove,
    extendAPI: {
        update({ params }) {
            const result = formatPNContent(params);
            return request.put(`${apiUrl}/${params.id}`, result);
        }
    }
});

function emptyComponent() {
    return null;
}

export const ConnectCreate = emptyComponent;

export const ConnectDetails = () => null;
