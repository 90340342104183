import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '../avatar/component';
import Text from '../text';
import './styles.css';

export default function UserCard({
    avatar,
    firstName,
    lastName,
    tagName,
    className,
}) {
    return (
        <div
            className={classnames('user-card', {
                [className]: !!className,
            })}
        >
            <Avatar
                src={avatar}
                first_name={firstName}
                last_name={lastName}
                className="user-card-avatar"
            />

            <div className="user-card-info">
                <Text overflowEllipsis>{firstName} {lastName}</Text>
                {tagName && (
                    <Text
                        overflowEllipsis
                        styleType="muted"
                        size="small"
                    >
                        @{tagName}
                    </Text>
                )}
            </div>
        </div>
    );
}

UserCard.defaultProps = {
    avatar: undefined,
    firstName: undefined,
    lastName: undefined,
    tagName: undefined,
    className: undefined,
};

UserCard.propTypes = {
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    tagName: PropTypes.string,
    className: PropTypes.string,
};
