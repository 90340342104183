export const attributes = Object.freeze({
    id: 'id',
    goal_milestone_id: 'goal_milestone_id',
    author_id: 'author_id',
    progress_note_id: 'progress_note_id',
    period_start: 'period_start',
    period_end: 'period_end',
    value: 'value',
    trend: 'trend',
    progress_status: 'progress_status',
    created_at: 'created_at',
    updated_at: 'updated_at',
    period_target_value: 'period_target_value',
    overall_progress: 'overall_progress',
    target_progress: 'target_progress',
    period_target: 'period_target',
    progress_note_content: 'progress_note_content',
    projected_progress: 'projected_progress',
    data_points: 'data_points',
    period_actual: 'period_actual',
    health: 'health',
    title: 'title',
    progress_note: 'progress_note',
    empty: 'empty',
    is_auto_creation: 'is_auto_creation',
});

export const createFormName = 'milestone-data-point-form';
export const createFormNameOnMobile = 'milestone-data-point-form-on-mobile';

export const editFormName = 'milestone-data-point-edit-form';

export const filterTypes = {
    missing: 'missing',
    all: 'all'
};
