import { takeEvery, put, select } from 'redux-saga/effects';
import createAsyncSaga from '../../../shared/sagas/async';
import { types as notificationTypes } from '../../../modules/notifications/toast/constants';
import notificationActions from '../../../modules/notifications/toast-actions';
import { getToastById } from '../../../modules/notifications/selectors';

export default function createSaga(actions, api, updateSucces, removeSuccess, onErrorUpdateStatus, messages = {}, selector) {
    const get = createAsyncSaga(actions, api.get);
    const update = createAsyncSaga(actions.update, api.update);
    const updateTags = createAsyncSaga(actions.updateTags, api.updateTags);
    const updateStatus = createAsyncSaga(actions.updateStatus, api.updateStatus);
    const remove = createAsyncSaga(actions.remove, api.remove);
    const setWatchStatus = createAsyncSaga(actions.setWatchStatus, api.setWatchStatus);

    function* updateSuccessSaga({ payload }) {
        if (updateSucces) {
            if (Array.isArray(updateSucces)) {
                for (let i = 0; i < updateSucces.length; i += 1) {
                    yield put({
                        type: updateSucces[i].getType(),
                        payload
                    });
                }

                return;
            }

            yield put({
                type: updateSucces.getType(),
                payload
            });
        }
    }

    function* removeSuccessSaga({ payload }) {
        if (removeSuccess) {
            if (Array.isArray(removeSuccess)) {
                for (let i = 0; i < removeSuccess.length; i += 1) {
                    yield put({
                        type: removeSuccess[i].getType(),
                        payload
                    });
                }

                return;
            }

            yield put({
                type: removeSuccess.getType(),
                payload
            });
        }
    }

    function* onErrorUpdateStatusSaga({ payload }) {
        if (onErrorUpdateStatus) {
            if (Array.isArray(onErrorUpdateStatus)) {
                for (let i = 0; i < onErrorUpdateStatus.length; i += 1) {
                    yield put(onErrorUpdateStatus[i](payload, messages));
                }
                return;
            }

            yield put(onErrorUpdateStatus(payload, messages));
        }
    }

    function* entityUpdateSaga({ payload }) {
        if (payload.type !== notificationTypes.MESSAGE) {
            return;
        }

        const { data } = yield select(state => getToastById(state, payload.id));
        const branch = yield select(selector);

        if (!data || !branch) {
            return;
        }

        const updated = data.in_app_message.entity;
        const isEntityNotification = !!data.in_app_message.entity;
        const current = branch.data;

        if (isEntityNotification
            && updated.type_code === current.type_code
            && updated.id === current.id
        ) {
            yield put({
                type: actions.request.getType(),
                payload: {
                    id: current.id,
                }
            });
        }
    }

    return function* listSaga() {
        yield takeEvery(actions.request.getType(), get);
        yield takeEvery(actions.update.request.getType(), update);
        yield takeEvery(actions.updateTags.request.getType(), updateTags);
        yield takeEvery(actions.updateStatus.request.getType(), updateStatus);
        yield takeEvery(actions.remove.request.getType(), remove);
        yield takeEvery(actions.setWatchStatus.request.getType(), setWatchStatus);

        yield takeEvery(actions.update.success.getType(), updateSuccessSaga);
        yield takeEvery(actions.updateStatus.success.getType(), updateSuccessSaga);
        yield takeEvery(actions.remove.success.getType(), removeSuccessSaga);
        yield takeEvery(notificationActions.click.getType(), entityUpdateSaga);
        yield takeEvery(actions.updateStatus.error.getType(), onErrorUpdateStatusSaga);
    };
}
