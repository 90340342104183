import { createModal, createDetails } from '../../../../shared/entities';
import { relatedLinkAPICreate, relatedLinkConstants } from '../../../secondary-entities/related-link';

export const csfRelatedLinkCreateModel = createModal({
    apiUrl: relatedLinkConstants.apiUrl,
    name: 'driver-related-link-create-model',
    extendAPI: {
        create: relatedLinkAPICreate,
    }
});

export const csfRelatedLinkModel = createDetails({
    apiUrl: relatedLinkConstants.apiUrl,
    name: 'driver-related-link-model',
});
