import axios from 'axios';
import { uniqueId } from 'lodash';
import { wait } from './shared/utils/common';
import * as cacheUtils from './shared/utils/cache';
import config from './config';

class APIService {
    constructor() {
        this.service = axios.create();
        this.requests = {};
        this.blocking = {};
        this.blockingPromise = null;
        this.currentCachingUrls = {};

        ['get', 'post', 'put', 'delete', 'patch'].forEach((key) => {
            this[key] = (...props) => this.handleRequest(key, ...props);
            this.blocking[key] = (...props) =>
                this.handleBlockingRequest(key, ...props);
        });

        this.isCancel = axios.isCancel;
    }

    processError = (error) => {
        return {
            response: {
                data: error.response.data,
            },
        };
    };

    setDefaults = () => {
        if (process.env.NODE_ENV === 'development') {
            this.service.defaults.baseURL = `${config.apiHost()}${config.apiUrl()}`;
        } else {
            this.service.defaults.baseURL = config.apiUrl();
        }

        this.service.defaults.headers.common['X-Requested-With'] =
            'XMLHttpRequest';
        this.service.defaults.headers.common['Content-Type'] =
            'application/x-www-form-urlencoded';
        this.service.interceptors.response.use(
            (response) =>
                response.data.result ? response.data.result : response.data,
            (e) => {
                throw e;
            }
        );
    };

    setAuthHeader = (token) => {
        if (!token) return;
        this.service.defaults.headers.common.Authorization = `Bearer ${token}`;
    };

    removeAuthHeader = () => {
        delete this.service.defaults.headers.common.Authorization;
    };

    immediateRequest = (method, ...rest) => {
        const promise = this.service[method](...rest);
        const id = uniqueId();

        this.requests[id] = promise;

        const removePromise = () => {
            delete this.requests[id];
        };

        const handleRequestSuccess = (response) => {
            removePromise();
            return response;
        };

        const handleRequestError = (error) => {
            removePromise();
            return Promise.reject(this.processError(error));
        };

        return promise.then(handleRequestSuccess, handleRequestError);
    };

    delayRequest = (...requestDescription) => {
        const promise = new Promise((resolve, reject) => {
            const handleFinally = () => {
                this.immediateRequest(...requestDescription).then(
                    resolve,
                    reject
                );
            };

            this.blockingPromise.finally(handleFinally);
        });

        return promise;
    };

    handleRequest = (method, ...rest) => {
        if (this.blockingPromise) {
            return this.delayRequest(method, ...rest);
        }

        return this.immediateRequest(method, ...rest);
    };

    removeBlockingPromise = () => {
        wait(0).then(() => {
            this.blockingPromise = null;
        });
    };

    handleBlockingRequest = (method, ...rest) => {
        const promise = new Promise((resolve, reject) => {
            const runningRequests = Object.entries(this.requests);

            const successHandler = (response) => {
                this.removeBlockingPromise();
                resolve(response);
            };
            const errorHandler = (error) => {
                this.removeBlockingPromise();
                reject(this.processError(error));
            };

            const callService = () => {
                this.service[method](...rest).then(
                    successHandler,
                    errorHandler
                );
            };

            if (runningRequests.length) {
                const promises = runningRequests.map(([, prom]) => prom);
                return Promise.all(promises).finally(callService);
            }

            return callService();
        });

        this.blockingPromise = promise;

        return promise;
    };

    emulateError = (errorObj) => {
        return new Promise((_, reject) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
                response: {
                    data: { errors: errorObj },
                },
            });
        });
    };

    cachedFetch(url, options) {
        const cachePromise = cacheUtils.getCache(url);
        let requestPromise;

        if (!(url in this.currentCachingUrls)) {
            requestPromise = this.get(url, options).then((response) => {
                cacheUtils.setCache(url, response, cacheUtils.DEFAULT_TTL);
                delete this.currentCachingUrls[url];
                return response;
            });
            this.currentCachingUrls[url] = requestPromise;
        } else {
            requestPromise = this.currentCachingUrls[url];
        }

        return [cachePromise, requestPromise];
    }
}

const apiService = new APIService();

if (process.env.NODE_ENV !== 'production') {
    window.apiService = apiService;
}

export default apiService;
