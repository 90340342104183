import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { types } from '../../../shared/utils/entity-type';
import createModalWizard from '../../wizard-entity/wizard-modal';
import { getDetailsTitles } from '../../goalmap-list/selector';
import { create as createSegmentModel, details as segmentModel } from '../entity';
import SegmentForm from '../form';
import WizardForm from './wizard';

const SegmentModalWizard = createModalWizard({
    name: 'segment-wizard-form-create',
    actions: {
        create: createSegmentModel.actions.request,
        update: segmentModel.actions.update.request,
        updateTags: segmentModel.actions.updateTags.request,
        remove: segmentModel.actions.remove.request,
        fetch: segmentModel.actions.request,
        toggle: createSegmentModel.actions.toggle,
    },
    selectors: {
        selectModalVisible: state => createSegmentModel.selector(state).modalVisible,
        selectFetchingState: state => segmentModel.selector(state).loading,
        selectAttributes: state => segmentModel.selector(state).data,
        titleSelector: titles => get(titles, types.strategy_map),
    },
})(WizardForm);

const SegmentModalWizardRenderer = props => <SegmentModalWizard {...props} />;

function mapStateToProps(state) {
    const titles = getDetailsTitles(state);
    const steps = [{
        ContentComponent: SegmentForm,
        description: `Name your ${titles.strategy_map}, Assign Owner, Add Target State`
    }];

    return { steps };
}

export default connect(mapStateToProps)(SegmentModalWizardRenderer);
