import { find } from 'lodash';
import urlJoin from 'url-join';
import { steps, rootPath } from './constants';

export function getStepIndex(stepName) {
    return steps.indexOf(find(steps, { name: stepName }));
}

export function getNextStep(stepName) {
    return steps[getStepIndex(stepName) + 1];
}

function getStepsCount() {
    return steps.length;
}

export function getStepPercentage(stepName) {
    return getStepIndex(stepName) * (100 / getStepsCount());
}

export function getUrl(id, stepName) {
    if (!id) {
        return rootPath;
    }

    if (!stepName) {
        return urlJoin(rootPath, id.toString());
    }

    return urlJoin(rootPath, id.toString(), stepName);
}
