import queryString from 'query-string';
import { get } from 'lodash';
import { NotificationTypes } from './constants';

const getSearchHref = q => `/goalmaps/search?${queryString.stringify(q)}`;
const getMobileSearchHref = (planId, q) => `/plan/${planId}/search?${queryString.stringify(q)}`;
const getWorkCenterHref = q => `/work-center/grid?${queryString.stringify(q)}`;

export const getProactiveNotificationHrefMobile = (planId, type) => {
    switch (type) {
        case NotificationTypes.PastDue:
            return getMobileSearchHref(planId, {
                pageTitle: 'Reminders',
                reminders: 'blue',
                goal_id: null,
            });
        case NotificationTypes.UpdateRequired:
        default:
            return getMobileSearchHref(planId, {
                pageTitle: 'Reminders',
                reminders: 'red',
                goal_id: null,
            });
    }
};

export const mapWorkCenterParamsToType = ({ calendar_type_filter }) => {
    switch (calendar_type_filter) {
        case 'past_due':
            return NotificationTypes.PastDue;
        case 'update_required':
        default:
            return NotificationTypes.UpdateRequired;
    }
};

export const getMobileNotificationRedirectHref = (planId, params) => {
    const type = mapWorkCenterParamsToType(params);
    return getProactiveNotificationHrefMobile(planId, type);
};

const getTeamId = (data) => {
    return get(data, 'data.model.id', get(data, 'model.id'));
};

export const getProactiveNotificationHref = (type, data) => {
    switch (type) {
        case NotificationTypes.UpdateRequired:
            return getWorkCenterHref({
                quick_filter: 'my_work',
                calendar_type_filter: 'update_required',
                entity_state_filter: 1,
                work_center_override: 'reminder',
            });
        case NotificationTypes.PastDue:
            return getWorkCenterHref({
                quick_filter: 'my_work',
                calendar_type_filter: 'past_due',
                entity_state_filter: 1,
                work_center_override: 'reminder',
            });
        case NotificationTypes.LeaderNewTeamUser:
        case NotificationTypes.NewTeamUser:
            return `/teams?team-id=${getTeamId(data)}`;
        default:
            return getSearchHref({
                pageTitle: 'Reminders',
                reminders: data.color,
                goal_id: data.goals,
            });
    }
};
