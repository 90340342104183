import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
    Text,
    Icon,
    RadioButton,
} from '../../../../shared/components';
import './styles.css';

function TypeSelectorItem({
    type,
    label,
    disabled,
    restricted,
    description,
    comingSoon,
    ...rest
}) {
    return (
        <div className={cx('type-selector-item', { 'disabled': disabled })}>
            <div className="type-selector-item-top">
                <RadioButton
                    label={label}
                    id={type}
                    value={type}
                    disabled={disabled}
                    className="type-selector-item-radio"
                    {...rest}
                />
                {
                    restricted
                        ? <Icon className="type-selector-item-icon" name="lock-alt" />
                        : null
                }
                {
                    comingSoon
                        ? (
                            <span className="type-selector-item-soon">
                                <Icon className="type-selector-item-soon-icon" name="clock" />Coming Soon
                            </span>
                        )
                        : null
                }
            </div>
            <Text className="type-selector-item-description">
                {description}
            </Text>
        </div>
    );
}

TypeSelectorItem.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    restricted: PropTypes.bool,
    active: PropTypes.bool,
    comingSoon: PropTypes.bool,
};

TypeSelectorItem.defaultProps = {
    disabled: false,
    restricted: false,
    active: false,
    comingSoon: false,
};

export default TypeSelectorItem;
