/* eslint-disable */
/** @namespace Utils.Color */

/**
 * Convert color from hex format to rgba
 * @function hexToRgba
 * @memberof Utils.Color
 * @param {string} hex color value in hex format
 * @param {float} alpha alpha channel value, range 0.0 .. 1.0
 * @return {string} color in rgba format
 */
export default function hexToRgba(hex, alpha) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${parseFloat(alpha)})`;
    }
    return null;
}

/**
 * Convert color from hex format to rgba
 * @function rgbToHex
 * @memberof Utils.Color
 * @param {string} rgb color value in rgb format
 * @return {string} color in hex format
 */
export function rgbToHex(rgb) {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? `#${
        (`0${parseInt(rgb[1], 10).toString(16)}`).slice(-2)
    }${(`0${parseInt(rgb[2], 10).toString(16)}`).slice(-2)
    }${(`0${parseInt(rgb[3], 10).toString(16)}`).slice(-2)}` : '';
}

/**
 * Lighten, darken, blends color
 * @function pSBC
 * @memberof Utils.Color
 * @param {number} p - coefficient blackout/whitening, value range (-1.0 .. 1.0)
 * @param {string} c0 - color HEX or RGB. 'rgb(12,33,55)' or '#658972'
 * @param {(string|boolean)} c1 - color HEX or RGB. Use this param with (boolean)false value for simple blackout/whitening color processing, or use Color for color mixing with param c0
 * @param {boolean} l - Algorithm 'Log'(false) or 'Linear'(true)
 * @return {string} Processed color in hex or rgb format (related by input format)
 */
export function pSBC(p, c0, c1, l) {
    function pSBCr(d) {
        let n = d.length;
        const x = {};

        if (n > 9) {
            [r, g, b, a] = d = d.split(','), n = d.length;
            if (n < 3 || n > 4) {
                return null;
            }
            x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1;
        } else {
            if (n == 8 || n == 6 || n < 4) {
                return null;
            }
            if (n < 6) {
                d = `#${d[1]}${d[1]}${d[2]}${d[2]}${d[3]}${d[3]}${n > 4 ? d[4] + d[4] : ''}`;
            }
            d = i(d.slice(1), 16);
            if (n == 9 || n == 5) {
                x.r = d >> 24 & 255;
                x.g = d >> 16 & 255;
                x.b = d >> 8 & 255;
                x.a = m((d & 255) / 0.255) / 1000;
            } else {
                x.r = d >> 16;
                x.g = d >> 8 & 255;
                x.b = d & 255, x.a = -1;
            }
        }
        return x;
    }

    let r; let g; let b; let P; let f; let t; let h; let
        i = parseInt;
    let m = Math.round;
    let a = typeof (c1) === 'string';

    if (typeof (p) !== 'number' || p < -1 || p > 1 || typeof (c0) !== 'string' || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) {
        return null;
    }

    h = c0.length > 9;
    h = a ? c1.length > 9 ? true : c1 == 'c' ? !h : false : h;
    f = pSBCr(c0);
    P = p < 0, t = c1 && c1 != 'c' ? pSBCr(c1) : P ? {
        r: 0, g: 0, b: 0, a: -1
    } : {
        r: 255, g: 255, b: 255, a: -1
    };
    p = P ? p * -1 : p;
    P = 1 - p;
    if (!f || !t) {
        return null;
    }
    if (l) {
        r = m(P * f.r + p * t.r);
        g = m(P * f.g + p * t.g);
        b = m(P * f.b + p * t.b);
    } else {
        r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
        g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
        b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
    }
    a = f.a;
    t = t.a;
    f = a >= 0 || t >= 0;
    a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;

    if (h) {
        return `rgb${f ? 'a(' : '('}${r},${g},${b}${f ? `,${m(a * 1000) / 1000}` : ''})`;
    }
    return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)}`;
}

/**
 * Adjust color lightening
 * @function adjustColor
 * @memberof Utils.Color
 * @param {string} color - HEX or RGB value. 'rgb(12,33,55)' or '#658972'
 * @param {number} amount - Value range whitening (0.0 .. 1.0); Value range blackout (-0.0 .. -1.0)
 * @return {string} Processed color in hex or rgb format (related by input format)
 */
export function adjustColor(color, amount) {
    return pSBC(amount, color, false, true);
}

/**
 * Blends color
 * @function blend
 * @memberof Utils.Color
 * @param {string} color - color HEX or RGB. 'rgb(12,33,55)' or '#658972'
 * @param {(string|boolean)} blendColor - color HEX or RGB. Use this param with (boolean)false value for simple blackout/whitening color processing, or use Color for color mixing with param c0
 * @param {number} amount - coefficient blackout/whitening, value range (-1.0 .. 1.0)
 * @return {string} Processed color in hex or rgb format (related by input format)
 */
export function blend(color, blendColor, amount) {
    return pSBC(amount, color, blendColor, true);
}

/**
 * Makes palette of color shades
 * @function makePalette
 * @memberof Utils.Color
 * @param {string} mainColor - Color which will be shaded
 * @param {number} graduateCount - Count of generated shades
 * @param {number} [startValue=0] - Max shade brightness, value range (0.0 .. 1.0)
 * @param {number} [endValue=1] - Max shade darkness, value range (0.0 .. 1.0)
 * @return {Array} Array of shades
 */
export function makePalette(mainColor, graduateCount, startValue = 0, endValue = 1) {
    const colors = [];
    const graduateStep = (endValue - startValue) / graduateCount;
    let currentStep = startValue;

    for (let i = 0; i < graduateCount; i++) {
        colors.push(adjustColor(mainColor, currentStep));
        currentStep += graduateStep;
    }

    return colors;
}
