/** @namespace Utils.Collection */
import u from 'updeep';

/**
 * Clone input collection
 * @function createClone
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @return {Array} Cloned input collection
 */
export function createClone(collection = []) {
    return [...collection];
}

/**
 * Find item index in collection by key and value
 * @function findIndex
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {any} value - The value by which the search will be carried
 * @param {string} [key="id"] - The key in object by which the search will be equal value
 * @return {number} Index of found item in collection
 */
export function findIndex(collection, value, key = 'id') {
    return collection.findIndex(item => item[key] === value);
}

/**
 * Find item in collection by key and value
 * @function find
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {any} value - The value by which the search will be carried
 * @param {string} [key="id"] - The key in object by which the search will be equal value
 * @return {Object} Found item in collection
 */
export function find(collection = [], value, key = 'id') {
    return collection.find(item => item[key] === value);
}

/**
 * Remove item from collection by key and value
 * @function removeFromCollection
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {any} value - The value by which the search will be carried
 * @param {string} [key="id"] - The key in object by which the search will be equal value
 * @return {Array} Cloned collection without found item
 */
export function removeFromCollection(collection, value, key) {
    const clone = createClone(collection);
    const index = findIndex(collection, value, key);

    if (index || index === 0) {
        clone.splice(index, 1);
    }

    return clone;
}

/**
 * Update item in collection found by key and value
 * @function updateInCollection
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {any} value - The value by which the search will be carried
 * @param {Object} newValue - New item by which will be changed found item
 * @param {string} [key="id"] - The key in object by which the search will be equal value
 * @return {Array} Cloned collection with updated item
 */
export function updateInCollection(collection, value, newValue, key = 'id') {
    const clone = createClone(collection);
    const index = findIndex(collection, value, key);

    if (index || index === 0) {
        clone[index] = newValue;
    }

    return clone;
}

/**
 * Merge item in collection with item found by key and value
 * @function mergeInCollection
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {any} value - The value by which the search will be carried
 * @param {Object} newValue - New value which will be merged with found item
 * @param {string} [key="id"] - The item in object by which the search will be equal value
 * @return {Array} Cloned collection with merged item
 */
export function mergeInCollection(collection, value, newValue, key = 'id') {
    const clone = createClone(collection);
    const index = findIndex(collection, value, key);

    if (index || index === 0) {
        clone[index] = u(newValue, clone[index]);
    }

    return clone;
}

/**
 * Prepend one item to collection
 * @function prependToCollection
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {Object} newValue - New item which will be added to collection
 * @return {Array} Cloned collection with new item
 */
export function prependToCollection(collection, newValue) {
    if (newValue.index) {
        const newCollection = [...collection];
        newCollection.splice(newValue.index, 0, newValue);
        return newCollection;
    }
    return [newValue, ...collection];
}

/**
 * Prepend multiple items to collection
 * @function prependMultipleToCollection
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {Object} newValue - New item which will be added to collection
 * @return {Array} Cloned collection with new item
 */
export function prependMultipleToCollection(collection, newValue) {
    // newValue.forEach((item) => {
    //    item.id = item.user_profile.user_id;
    // });
    return newValue.concat(...collection);
}

/**
 * Prepend multiple items to collection
 * @function prependMultipleToCollection
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {Array} items - New item which will be added to collection
 * @return {Array} Cloned collection with new item
 */
export function prependArrayToCollection(collection, items = []) {
    return [...items, ...collection];
}

/**
 * Insert item to target position in collection
 * @function insertItemAtPosition
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {Object} item - New item which will be added to collection
 * @param {number} position - Position in where item will be added
 * @return {Array} Cloned collection with inserted item
 */
export function insertItemAtPosition(collection, item, position) {
    const clone = createClone(collection);
    clone.splice(position, 0, item);

    return clone;
}

/**
 * Remove items at target position in collection
 * @function removeItemsAtPosition
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {Object} position - Position in where item will be removed
 * @param {number} count - Items count removed from position
 * @return {Array} Cloned collection without removed items
 */
export function removeItemsAtPosition(collection, position, count = 1) {
    const clone = createClone(collection);
    clone.splice(position, count);

    return clone;
}

/**
 * Filter items in collection
 * @function push
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {any} value - The value which will be added in collection
 * @return {Array} Cloned collection with added items
 */
export function push(collection = [], value) {
    const clone = createClone(collection);
    clone.push(value);
    return clone;
}

/**
 * Get item by any index (-Infinity to Infinity) from array, represented as infinite repeated array in both directions
 * @function getByIndexVirtual
 * @memberof Utils.Collection
 * @param {Array} collection - Collection of items
 * @param {number} index - The value which will be added in collection
 * @return {any} Item by index
 */
export function getByIndexVirtual(collection, index) {
    return collection[index >= 0
        ? index % collection.length
        : collection.length + (index % collection.length)
    ];
}
