import { reduceNumberThousandToK } from '../../../utils/formatters';

export default {
    chart: {
        renderTo: 'ident',
        type: 'gauge'
    },
    pane: {
        startAngle: -90,
        endAngle: 90,
        // size,
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    title: {
        text: ''
    },
    plotOptions: {
        gauge: {
            dataLabels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontFamily: 'Roboto, sans-serif',
                    color: 'var(--color-regent-grey)'
                }
            }
        }
    },
    yAxis: {
        tickPosition: 'outside',
        lineColor: '#000',
        lineWidth: 0,
        minorTickPosition: 'outside',
        tickColor: '#000',
        minorTickColor: '#000',
        tickLength: 0,
        minorTickLength: 0,
        labels: {
            distance: 20,
            formatter() {
                return reduceNumberThousandToK(this.value);
            }
        },
    }
};
