import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import classNames from 'classnames';
import './style.css';

const ChipsItem = ({
    name,
    label,
    value,
    count,
    input,
    type,
    className,
    color,
    ...rest
}) => {
    const id = uuid();
    const idValue = `chips-item-${id}`;
    const itemValue = value || input?.value;
    const itemName = name || input?.name;

    return (
        <div className={classNames('chips-item', { [className]: !!className })}>
            <input
                className="chips-item-input"
                id={idValue}
                type={type}
                name={itemName}
                value={itemValue}
                {...input}
                {...rest}
            />
            <label
                className="chips-item-label"
                htmlFor={idValue}
            >
                <span className="chips-item-label-text">{label}&nbsp;-&nbsp;</span>
                <span className={classNames('chips-item-label-count', {
                    [color]: !!color,
                })}
                >{count}
                </span>
            </label>
        </div>
    );
};

ChipsItem.defaultProps = {
    name: '',
    value: '',
    label: '',
    className: '',
    count: 0,
    input: {},
    type: 'radio',
    color: '',
};

ChipsItem.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    className: PropTypes.string,
    count: PropTypes.number,
    color: PropTypes.string,
    input: PropTypes.object,
    type: PropTypes.oneOf(['radio', 'checkbox']),
};

export default ChipsItem;
