import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { replace } from 'react-router-redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Compose from 'react-composer';
import {
    get, flow, debounce, noop,
} from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';
import { applicationSettingsSelectors } from '../../../modules/application';
import { getPlanTitle } from '../../../modules/goalmap-list/selector';
import navigationTreeList from '../../../modules/navigation-goalmap-tree/entity';
import { historyCollection } from '../../../modules/secondary-entities/history';
import { subscriptionLimitCollection, SubscriptionLimitProviders } from '../../../modules/subscription';
import RightDrawer from '../../../modules/navigation-drawer-right/container';
import { ServicePage404, ServicePage405, ServicePage429 } from '../../../modules/service-pages';
import Permissions from '../../../modules/permissions';
import { collaboratorCollection } from '../../../modules/secondary-entities/collaborator';
import { selectUserDetails, selectUserId } from '../../../modules/user/selectors';
import { asyncTypes as requestTypes } from '../../entities-v2/model/config';
import {
    LoadingIndicator, PageSettings, IconButton, Prompt,
} from '../../components';
import { getPathname } from '../../utils/url';
import { entityState, isArchived } from '../../utils/entity';
import MediaQueryRender from '../media-query-render';
import VersionConflictProvider from '../version-conflict-provider';
import { withErrorBoundary } from '../error-boundary';
import getRedirectUrl from './get-redirect-url';
import DetailsViewMobile from './mobile';

import './styles.css';

function EmptyComponent({
    children,
    ...rest
}) {
    return children(rest);
}

const isDraftEntityCanPublish = (state, canPublishDraft) => state === entityState.draft && canPublishDraft;

export default function createDetailsView(options) {
    const rightDrawerOptions = options.rightDrawer || {};

    return (EnhancedComponent) => {
        class DetailsView extends Component {
            constructor(args) {
                super(args);

                this.state = {
                    confirmText: undefined,
                    confirmVisible: false,
                };
                this.resizeCallbackDebounce = debounce(this.resizeCallback, 50);
                this.resizeElement = React.createRef();
            }

            componentDidMount() {
                const { data, params, canViewEntity } = this.props;
                this.resizeObserver = new ResizeObserver(this.resizeCallbackDebounce);

                if (this.resizeElement.current) {
                    this.resizeObserver.observe(this.resizeElement.current);
                }

                if (canViewEntity && data.id && Number(params.id) === data.id) {
                    return this.fetch('silent');
                }

                if (canViewEntity) return this.fetch();

                return false;
            }

            componentWillReceiveProps(nextProps) {
                const {
                    params, data, setContextQuickAdd, goalId, setQuickAddSuccessCallback,
                } = nextProps;

                if (JSON.stringify(data) !== JSON.stringify(this.props.data)) {
                    setQuickAddSuccessCallback(() => this.fetch(true));
                    setContextQuickAdd({
                        entity_id: params.id,
                        entity_type: data.entity_type,
                        entity_title: data.title,
                        goal_id: goalId,
                    });
                }
            }

            componentDidUpdate(prevProps) {
                const { params } = this.props;

                if (params.id !== prevProps.params.id) {
                    this.fetch();
                }
            }

            componentWillUnmount() {
                const {
                    resetDetails, setQuickAddSuccessCallback, setContextQuickAdd,
                } = this.props;
                if (options.resetOnUnmount !== false) {
                    resetDetails();
                }
                if (this.resizeObserver) {
                    this.resizeObserver.disconnect();
                }
                setContextQuickAdd({});
                setQuickAddSuccessCallback(noop);
            }

            resizeCallback = (elements) => {
                if (elements.length && elements[0].contentRect.width) {
                    this.setState({ detailsViewContainerWidth: elements[0].contentRect.width });
                }
            };

            getSettingsOptions = (archiveLimit, draftLimit, additionalParams) => {
                const {
                    data,
                    onUpdateStatus,
                    permissions,
                    callbackUpdateTreeAfterUpdateEntity,
                    onStatusUpdated
                } = this.props;

                const {
                    state,
                    id,
                } = data;
                const activeText = state === entityState.draft ? 'Publish' : 'Activate';

                const settings = [];
                const canCreate = get(permissions, Permissions.keys.canCreate);
                const canDestroy = get(permissions, Permissions.keys.canDestroy);
                const canChangeState = (archiveLimit && archiveLimit.value) && get(permissions, Permissions.keys.canDestroy);
                const canPublishDraft = draftLimit && draftLimit.value && canCreate;

                if (canCreate && additionalParams.copy && !isArchived({ state })) {
                    settings.push({
                        title: 'Copy',
                        className: 'page-settings-copy',
                        action() {
                            if (
                                additionalParams
                                && additionalParams.cloneCheckout
                            ) {
                                const canClone = additionalParams.cloneCheckout();

                                if (canClone) {
                                    additionalParams.onClone();
                                }
                            }
                        }
                    });
                }

                if (canChangeState || isDraftEntityCanPublish(state, canPublishDraft)) {
                    const callUpdateStatus = () => onUpdateStatus({
                        id,
                        state: state === 1
                            ? 'archive'
                            : 'active',
                        onSuccess: flow(callbackUpdateTreeAfterUpdateEntity, onStatusUpdated)
                    });

                    settings.push({
                        title: state === 1
                            ? 'Archive'
                            : activeText,
                        action() {
                            if (
                                state === 1
                                && additionalParams
                                && additionalParams.archiveCheckout
                            ) {
                                const canUpdateState = additionalParams.archiveCheckout();
                                if (canUpdateState) {
                                    return callUpdateStatus();
                                }

                                return null;
                            }

                            if (
                                (['Activate', 'Publish'].indexOf(activeText) !== -1)
                                && additionalParams
                                && additionalParams.activateCheckout
                            ) {
                                const canUpdateState = additionalParams.activateCheckout();
                                if (canUpdateState) {
                                    return callUpdateStatus();
                                }

                                return null;
                            }

                            return callUpdateStatus();
                        }
                    });
                }

                if (canDestroy) {
                    settings.push({
                        title: 'Delete',
                        className: 'page-settings-remove',
                        action: () => {
                            if (
                                additionalParams
                                && additionalParams.deleteCheckout
                            ) {
                                const canDelete = additionalParams.deleteCheckout();
                                if (canDelete) {
                                    return this.openConfirmWindow();
                                }

                                return null;
                            }

                            return this.openConfirmWindow();
                        },
                    });
                }

                return settings;
            }

            renderSettingsMenu = (params) => {
                const { is_watching } = this.props.data;

                return (
                    <div className="settings-menu">
                        {
                            !options.disableWatch
                            && (
                                <IconButton
                                    icon="eye"
                                    className="settings-menu-watch"
                                    active={is_watching}
                                    onClick={this.updateWatchStatus}
                                />
                            )
                        }

                        <Compose
                            components={[
                                <SubscriptionLimitProviders.Archive />,
                                <SubscriptionLimitProviders.Draft />,
                                <EmptyComponent {...params} />
                            ]}
                        >
                            {this.renderSettings}
                        </Compose>
                    </div>
                );
            }

            updateWatchStatus = () => {
                const { onUpdateWatchStatus, fetchCollaborationsList } = this.props;
                const { id, is_watching } = this.props.data;

                return onUpdateWatchStatus({
                    id,
                    type: options.entityType,
                    status: !is_watching,
                    onSuccess: () => {
                        this.fetch(true);
                        fetchCollaborationsList({
                            includes: 'user.user_profile',
                            watcher_id: id,
                            watcher_type: options.entityType,
                        });
                    }
                });
            }

            openConfirmWindow = () => {
                this.setState({
                    confirmVisible: true,
                    confirmText: `You are about to delete ${this.props.data.title}`,
                });
            }

            fetch = (silent = false) => {
                const {
                    getDetails, params, redirectOnError, navigate
                } = this.props;
                getDetails({
                    ...params,
                    silent,
                    onError() {
                        if (redirectOnError) {
                            navigate(redirectOnError);
                        }
                    }
                });
            }

            renderSettings = ([archive, draft, checkout]) => {
                const settingsOptions = this.getSettingsOptions(archive, draft, checkout);

                if (!settingsOptions.length) {
                    return null;
                }

                return (
                    <PageSettings
                        className="settings-menu-settings"
                        options={settingsOptions}
                    />
                );
            }

            handleOnCreateSuccessCopyEntity = (copyEntity) => {
                const { callbackUpdateTreeAfterUpdateEntity, } = this.props;
                if (copyEntity.state === entityState.active) {
                    callbackUpdateTreeAfterUpdateEntity();
                }
            };

            render() {
                const {
                    loading, data, updating, goalId, versioningConflict, hideVersionConflict, ownerEditAllowed,
                    fetchError, permissions, navigate, onRemove, currentUserId, triggerUpdateState,
                    redirectUrl, callbackUpdateTreeAfterUpdateEntity, relatedLinkProps,
                    canViewEntity, onUpdate, updateHistoryList
                } = this.props;

                if (!loading && fetchError) {
                    if (fetchError.code === 405) {
                        return <ServicePage405 />;
                    }
                    if (fetchError.code === 429) {
                        return <ServicePage429 />;
                    }
                    return <ServicePage404 />;
                }

                if (!canViewEntity) {
                    return <ServicePage405 />;
                }

                const { id, versioning } = data;

                const author = (data.author && data.author.user_profile) ? data.author.user_profile.full_name : '';
                const editAllowed = get(permissions, Permissions.keys.canUpdate);

                return (
                    <VersionConflictProvider
                        updateEntity={onUpdate}
                        entityType={options.entityType}
                        entityVersioning={versioning}
                        onSuccessOverwrite={() => {
                            this.fetch();
                            callbackUpdateTreeAfterUpdateEntity();
                            updateHistoryList({
                                includes: 'user.user_profile',
                                subject_id: id,
                                subject_type: options.entityType,
                            });
                        }}
                        onSuccessUpdate={() => {
                            callbackUpdateTreeAfterUpdateEntity();
                            updateHistoryList({
                                includes: 'user.user_profile',
                                subject_id: id,
                                subject_type: options.entityType,
                            });
                        }}
                        onDiscardOverwrite={this.fetch}
                        visibleVersionConflictModal={versioningConflict}
                        hideVersionConflictModal={hideVersionConflict}
                    >
                        {versionConflict => (
                            <div className="details-page-container" ref={this.resizeElement}>
                                {
                                    (!loading && data.id && !rightDrawerOptions.disabled)
                            && (
                                <SubscriptionLimitProviders.History>
                                    {(limitProps) => {
                                        const exclude = rightDrawerOptions.exclude || [];
                                        if (limitProps.value === false) {
                                            exclude.push('history');
                                        }

                                        return (
                                            <RightDrawer
                                                created_at={data.created_at || ''}
                                                author={author}
                                                parentId={id}
                                                fetchParent={this.fetch}
                                                parentEntityTypeCode={data.entity_type}
                                                parentEntityState={data.state}
                                                parentEntityType={options.entityType}
                                                relatedLinkProps={relatedLinkProps}
                                                goalId={goalId}
                                                exclude={exclude}
                                                updateDetails={triggerUpdateState}
                                                userId={currentUserId}
                                                counters={get(data, 'secondary_entity_counters', {})}
                                                isAutoCreation={data?.is_auto_creation}
                                            />
                                        );
                                    }}
                                </SubscriptionLimitProviders.History>
                            )
                                }

                                <div className="util-preserve-sidebar">
                                    {(loading || !data.id || updating) && (
                                        <div className="loading-overlay fixed">
                                            <LoadingIndicator centered />
                                        </div>
                                    )}

                                    {(!loading && data.id) && (
                                        <EnhancedComponent
                                            {...this.props}
                                            canEdit={editAllowed}
                                            canEditOwner={ownerEditAllowed}
                                            onUpdate={versionConflict.updateEntityWithVersionCheck}
                                            renderSettings={this.renderSettingsMenu}
                                            permissions={permissions}
                                            detailsViewContainerWidth={this.state.detailsViewContainerWidth}
                                            handleOnCreateSuccessCopyEntity={this.handleOnCreateSuccessCopyEntity}
                                        />
                                    )}

                                    <Prompt
                                        visible={this.state.confirmVisible}
                                        description={this.state.confirmText}
                                        onAccept={() => onRemove({
                                            id,
                                            onSuccess() {
                                                callbackUpdateTreeAfterUpdateEntity(requestTypes.remove);
                                                return navigate(redirectUrl);
                                            }
                                        })}
                                        hide={() => this.setState({ confirmVisible: false })}
                                    />
                                </div>
                            </div>
                        )}
                    </VersionConflictProvider>
                );
            }
        }

        DetailsView.defaultProps = {
            versioning: undefined,
            fetchError: undefined,
            lastRoutingPath: undefined,
            redirectOnError: undefined,
            routes: [],
            goalSettings: false,
            user: {},
            subscriptionRestrictions: [],
            relatedLinkProps: {},
            onStatusUpdated() {},
            callbackUpdateTreeAfterUpdateEntity() {},
        };

        DetailsView.propTypes = {
            getDetails: PropTypes.func.isRequired,
            params: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }).isRequired,
            loading: PropTypes.bool.isRequired,
            updating: PropTypes.bool.isRequired,
            data: PropTypes.shape({
                id: PropTypes.number,
                is_watching: PropTypes.bool,
                title: PropTypes.string,
                parent_id: PropTypes.number,
                state: PropTypes.number,
                author: PropTypes.object,
                created_at: PropTypes.string,
                versioning: PropTypes.string,
                entity_type: PropTypes.string,
                is_auto_creation: PropTypes.bool,
            }).isRequired,
            onUpdateStatus: PropTypes.func.isRequired,
            onStatusUpdated: PropTypes.func,
            onRemove: PropTypes.func.isRequired,
            onUpdateWatchStatus: PropTypes.func.isRequired,
            onUpdate: PropTypes.func.isRequired,
            redirectUrl: PropTypes.string.isRequired,
            navigate: PropTypes.func.isRequired,
            goalId: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            updateHistoryList: PropTypes.func.isRequired,
            versioning: PropTypes.string,
            versioningConflict: PropTypes.bool.isRequired,
            hideVersionConflict: PropTypes.func.isRequired,
            fetchError: PropTypes.shape({
                code: PropTypes.number,
            }),
            permissions: PropTypes.object.isRequired,
            lastRoutingPath: PropTypes.string,
            currentUserId: PropTypes.number.isRequired,
            triggerUpdateState: PropTypes.func.isRequired,
            redirectOnError: PropTypes.string,
            routes: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    createHref: PropTypes.func,
                }),
            ),
            goalSettings: PropTypes.bool,
            resetDetails: PropTypes.func.isRequired,
            user: PropTypes.object,
            entityType: PropTypes.string.isRequired,
            subscriptionRestrictions: PropTypes.array,
            relatedLinkProps: PropTypes.object,
            callbackUpdateTreeAfterUpdateEntity: PropTypes.func,
            canViewEntity: PropTypes.bool.isRequired,
            fetchCollaborationsList: PropTypes.func.isRequired,
            setQuickAddSuccessCallback: PropTypes.func.isRequired,
            setContextQuickAdd: PropTypes.func.isRequired,
            ownerEditAllowed: PropTypes.bool.isRequired,
        };

        function DetailsRender(props) {
            return (
                <MediaQueryRender
                    {...props}
                    entityType={options.entityType}
                    desktop={DetailsView}
                    mobile={DetailsViewMobile}
                />
            );
        }

        function mapStateToProps(state, ownProps) {
            const branch = options.selector(state);
            const parentState = options.mapStateToProps(state, ownProps);
            const lastRoutingPath = applicationSettingsSelectors.selectLastRoutingPath(state);
            const redirectUrl = getRedirectUrl(
                ownProps.location.pathname,
                lastRoutingPath && getPathname(lastRoutingPath),
                (parentState.listHref || ownProps.listHref)
            );
            const goalId = Number(ownProps.params.id);
            const userId = selectUserId(state);
            const permissions = Permissions.selectors.selectResolvedEntityPermissions(
                state,
                goalId,
                options.permissionsSection,
                branch.data,
            );
            const canViewEntity = get(permissions, Permissions.keys.canView, false) || get(permissions, Permissions.keys.canViewOwn, false);
            const planPermissions = Permissions.selectors
                .selectEntityPermissions(state, goalId, Permissions.constants.entitySections.strategicPlan);
            return {
                ...parentState,
                ...branch,
                fetchError: branch.error,
                versioning: branch.data.versioning,
                permissions,
                canViewEntity,
                goalId,
                redirectOnError: ownProps.location.query.error_redirect,
                currentUserId: userId,
                user: selectUserDetails(state),
                redirectUrl,
                routes: ownProps.routes,
                goalSettings: ownProps.route.goalSettings,
                routeParams: ownProps.params,
                subscriptionRestrictions: subscriptionLimitCollection.selectors.getItems(state),
                planTitle: getPlanTitle(state),
                itemsTreeNavigation: navigationTreeList.selectors.getAttribute(state, 'data'),
                ownerEditAllowed: planPermissions?.[Permissions.keys.canUpdateOwner],
            };
        }

        const formComponent = reduxForm({
            form: options.formName
        }, options.formOptions)(DetailsRender);

        const connectedComponent = connect(
            mapStateToProps,
            {
                getDetails: options.actions.request,
                onUpdate: options.actions.update.request,
                triggerUpdateState: options.actions.update.success,
                onUpdateTags: options.actions.updateTags.request,
                onUpdateStatus: options.actions.updateStatus.request,
                onRemove: options.actions.remove.request,
                hideVersionConflict: options.actions.hideVersionConflictModal,
                onUpdateWatchStatus: options.actions.setWatchStatus.request,
                resetDetails: options.actions.reset,
                clearUpdatingError: options.actions.clearError,
                updateHistoryList: historyCollection.actions.request,
                navigate: replace,
                fetchCollaborationsList: collaboratorCollection.actions.request,
            }
        )(formComponent);

        return withErrorBoundary(connectedComponent);
    };
}
