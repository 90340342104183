import u from 'updeep';
import { get } from 'lodash';
import { attributes, integrationAttributeBySlug } from '../config';

export function getUpdateStateData(data, slug, state) {
    const integrationObjAttr = integrationAttributeBySlug[slug];

    return u({
        [integrationObjAttr]: {
            [attributes.is_active]: state,
        },
        [attributes.goal_id]: get(data, [integrationObjAttr, attributes.goal_id]),
        [attributes.url]: get(data, [integrationObjAttr, attributes.url]),
        [attributes.sensitivity]: get(data, [integrationObjAttr, attributes.sensitivity]),
        [attributes.is_active]: state ? 1 : 0,
        slug,
    }, data);
}
