import * as localStorageUtils from '../../../shared/utils/local-storage';

function isIos() {
    return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
}

function isInstalledApp() {
    return navigator.standalone || window.standalone;
}

function getHasSeenFlag() {
    return JSON.parse(localStorageUtils.get('is-banner-seen'));
}

export function setHasSeenFlag() {
    return localStorageUtils.set('is-banner-seen', JSON.stringify(true));
}

export function isBannerNeeded() {
    return isIos() && !isInstalledApp() && !getHasSeenFlag();
}
