import request from '../../../request';

export default function getUsers() {
    return request.get(
        '/teams/roles'
    ).then(
        (response) => {
            return {
                options: response,
            };
        }
    );
}
