/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { get } from 'lodash';
import { asyncTypes } from './config';

const getRoot = branchName => state => get(state, branchName, {});
const getBranch = branchName => state => get(state, [branchName, 'model']);
const getPlugins = branchName => state => get(state, [branchName, 'plugins']);

// const mapExtraSelectors = (branchName, extraSelectors) =>
//     Object.keys(extraSelectors).reduce((result, key) => {
//         result[key] = createSelector(
//             getBranch(branchName),
//             extraSelectors[key]
//         );

//         return result;
//     }, {});

function asyncStateSelector(state, name) {
    return get(state, ['asyncState', name], false);
}

function errorSelector(state, name) {
    return get(state, ['errors', name], null);
}

const selectors = {
    getAttributes(state) {
        return get(state, 'attributes', {});
    },
    getParams(state) {
        return get(state, 'params', {});
    },

    getFetchParams(state) {
        return get(state, 'fetchParams', {});
    },

    getStateFetching(state) {
        return asyncStateSelector(state, asyncTypes.fetch);
    },
    getStateUpdating(state) {
        return asyncStateSelector(state, asyncTypes.update);
    },
    getStateCreating(state) {
        return asyncStateSelector(state, asyncTypes.create);
    },
    getStateRemoving(state) {
        return asyncStateSelector(state, asyncTypes.remove);
    },

    getErrorFetch(state) {
        return errorSelector(state, asyncTypes.fetch);
    },
    getErrorUpdate(state) {
        return errorSelector(state, asyncTypes.update);
    },
    getErrorRemove(state) {
        return errorSelector(state, asyncTypes.remove);
    },
    getErrorCreate(state) {
        return errorSelector(state, asyncTypes.create);
    },

    getExtensionState(state) {
        return get(state, 'extensionState', {});
    },
};

export default function createSelectors({ branchName }) {
    const getAttributes = createSelector(
        getBranch(branchName),
        selectors.getAttributes,
    );

    const getAttribute = (state, path) => {
        const attributes = getAttributes(state);
        return get(attributes, path);
    };

    const getParams = createSelector(
        getBranch(branchName),
        selectors.getParams,
    );

    const getFetchParams = createSelector(
        getBranch(branchName),
        selectors.getFetchParams,
    );

    const getStateFetching = createSelector(
        getBranch(branchName),
        selectors.getStateFetching
    );

    const getStateUpdating = createSelector(
        getBranch(branchName),
        selectors.getStateUpdating
    );

    const getStateCreating = createSelector(
        getBranch(branchName),
        selectors.getStateCreating
    );

    const getStateRemoving = createSelector(
        getBranch(branchName),
        selectors.getStateRemoving
    );

    const getErrorFetch = createSelector(
        getBranch(branchName),
        selectors.getErrorFetch,
    );

    const getErrorCreate = createSelector(
        getBranch(branchName),
        selectors.getErrorCreate,
    );

    const getErrorUpdate = createSelector(
        getBranch(branchName),
        selectors.getErrorUpdate,
    );

    const getErrorRemove = createSelector(
        getBranch(branchName),
        selectors.getErrorRemove,
    );

    const getExtensionState = createSelector(
        getBranch(branchName),
        selectors.getExtensionState,
    );

    return {
        getRoot: getRoot(branchName),
        getBranch: getBranch(branchName),
        getAttributes,
        getAttribute,
        getParams,
        getFetchParams,
        getPluginsState: getPlugins(branchName),
        getExtensionState,

        getStateFetching,
        getStateUpdating,
        getStateCreating,
        getStateRemoving,

        getErrorFetch,
        getErrorCreate,
        getErrorUpdate,
        getErrorRemove,
    };
}
