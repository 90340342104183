import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import createListOfItems from '../../../../shared/modules/list-view/component';
import { Prompt } from '../../../../shared/components';
import { collaboratorCollection, collaboratorModel } from '../collaborator-models';
import CollaboratorItem from './collaborator-list-item';

const {
    actions,
    selector
} = collaboratorCollection;

const CollaboratorList = createListOfItems({
    actions,
    selector,
})(CollaboratorItem);

class CollaboratorListContainer extends Component {
    constructor(args) {
        super(args);

        this.state = {
            promptVisible: false,
            promptAction() {},
        };
    }

    onRemove = (id, removeUserId) => {
        const {
            showDetailsModal, removeItem, watcher_id, watcher_type
        } = this.props;
        this.setState({
            promptVisible: true,
            promptAction: () => {
                showDetailsModal({ id });
                removeItem({
                    watcher_id,
                    watcher_type,
                    id: removeUserId,
                    idKey: 'user_id',
                    onSuccess: this.onRemoveSuccess,
                });
            }
        });
    }

    onRemoveSuccess = () => {
        const { onDeleteSuccess } = this.props;
        onDeleteSuccess();
    }

    render() {
        const {
            showDetailsModal, removeItem, updatingItemId, watcher_id, watcher_type,
            userId, permissions, ...rest
        } = this.props;

        return (
            <div className="side-collaborators-list">
                <CollaboratorList
                    {...rest}
                    paginationDisabled
                    params={{
                        includes: 'user.user_profile',
                        watcher_id,
                        watcher_type,
                    }}
                    itemProps={{
                        permissions,
                        userId,
                        updatingItemId,
                        onRemove: this.onRemove
                    }}
                />

                <Prompt
                    visible={this.state.promptVisible}
                    hide={() => this.setState({ promptVisible: false })}
                    description="You are about to remove this collaborator."
                    onAccept={this.state.promptAction}
                />
            </div>
        );
    }
}


CollaboratorListContainer.defaultProps = {
    updatingItemId: undefined,
    params: undefined,
    onDeleteSuccess() {},
};

CollaboratorListContainer.propTypes = {
    showDetailsModal: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    updatingItemId: PropTypes.number,
    watcher_id: PropTypes.number.isRequired,
    watcher_type: PropTypes.string.isRequired,
    permissions: PropTypes.object.isRequired,
    onDeleteSuccess: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    const detailsState = collaboratorModel.selector(state);

    return {
        updatingItemId: detailsState && detailsState.modalItemId,
        watcher_id: ownProps.parentId,
        watcher_type: ownProps.parentEntityType,
    };
}

const connectedComponent = connect(
    mapStateToProps,
    {
        showDetailsModal: collaboratorModel.actions.toggleModal,
        removeItem: collaboratorModel.actions.remove.request,
    }
)(CollaboratorListContainer);

export default connectedComponent;
