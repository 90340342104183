import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import getStatusColor from '../status-indicator/status-color';
import './styles.css';

export default function HealthIndicatorVertical({ status, className }) {
    const style = {
        backgroundColor: getStatusColor(status),
    };

    return (
        <span
            className={classnames('health-indicator-vertical', {
                [className]: !!className,
            })}
            style={style}
        />
    );
}

HealthIndicatorVertical.defaultProps = {
    className: undefined,
    status: null,
};

HealthIndicatorVertical.propTypes = {
    className: PropTypes.string,
    status: PropTypes.number,
};
