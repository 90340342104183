const measures = {
    percentage: {
        symbol: '%',
        label: 'Percentage',
        value: 'percentage', // used for backend
    },
    numeric: {
        symbol: '#',
        label: 'Numeric',
        value: 'numerical', // used for backend
    },
    currency: {
        symbol: '$',
        label: 'Currency',
        value: 'monetary', // used for backend
    }
};

export default measures;
