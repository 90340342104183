import u from 'updeep';
import { ModelCollectionSyncPlugin, ModelNotificationsPlugin } from '../../../shared/entities-v2/plugins';
import { TeamModel } from '../../../shared/modules/team-section';
import settingsTeamCollection from './settings-team-collection';
import { apiUrl } from './settings-team-constants';

const settingsTeamModel = new TeamModel({
    apiUrl,
    name: 'settings-team-model',
    plugins: [
        new ModelCollectionSyncPlugin({
            name: 'settings-team-model-collection-sync',
            collection: settingsTeamCollection,
        }),
        new ModelNotificationsPlugin({
            name: 'settings-team-model-notifications',
            dictionary: u({
                success: {
                    create: 'The Team has been created',
                    update: 'The Team has been updated',
                    remove: 'The Team has been deleted',
                }
            }, ModelNotificationsPlugin.dictionary)
        })
    ],
});

export default settingsTeamModel;
