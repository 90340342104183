import { slugs } from '../../profile-preferences/integrations/config';
import local from './file-source/file-source-item/local.svg';
import googleDrive from './file-source/file-source-item/google-drive.svg';
import dropbox from './file-source/file-source-item/drop-box.svg';
import onDrive from './file-source/file-source-item/onedrive.svg';
import box from './file-source/file-source-item/box.svg';

const icons = {
    [slugs.local]: local,
    [slugs['google-drive']]: googleDrive,
    [slugs['drop-box']]: dropbox,
    [slugs['one-drive']]: onDrive,
    [slugs.box]: box,
};

export default function getStorageIcon(slug) {
    if (slug in icons) {
        return icons[slug];
    }

    return icons[slugs.local];
}
