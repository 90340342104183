import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
    formatNumber, formatByType, getCurrentValue, parseCurrentValue, formatByTypeCapitalized
} from '../../../../modules/goalmap-milestones/utils';
import { getFormattedPeriod } from '../../../utils/date';
import { createGaugeConfig } from '../../../../modules/goalmap-milestones/gauge-config-creators';
import { calculateLabelColor } from '../../../components/chart-gauge/utils';
import { ChartGauge, Text, } from '../../../../shared/components';
import { kpiTypes, attributes, types as typesOfMeasure } from '../../../../modules/goalmap-milestones/constants';
import PastDueBadge from '../../../../modules/goalmap-plans-actions/badge-past-due';
import { getCurrencyFormatSymbol } from '../../../../modules/application/localizations/selectors';

import styles from './styles.module.css';

const KpiParameter = (props) => {
    const { children, textLabel } = props;
    return (
        <div>
            <Text>{textLabel}</Text>
            <Text styleType="dark-light">&nbsp;{children}</Text>
        </div>
    );
};

KpiParameter.propTypes = {
    textLabel: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

const KpiCardBody = ({ data }) => {
    const currencySymbol = useSelector(state => getCurrencyFormatSymbol(state)(data?.[attributes.currency_format]));
    const renderTargetValueLabel = (kpiType) => {
        return kpiType === kpiTypes.static.type ? 'Target Value' : 'Overall Target Value';
    };

    const requiredFromLabel = get(data, attributes.required_from) === 'first'
        ? 'First day of period'
        : 'Last day of period';

    const frequency = get(data, attributes.frequency);
    const kpiType = get(data, attributes.kpi_type);
    const target_at = get(data, attributes.target_at);
    const is_need_updated = get(data, attributes.is_need_updated);
    const is_past_due = get(data, attributes.is_past_due, false);
    const dataPoints = get(data, attributes.data_points, []);
    const type = get(data, attributes.type);
    const started_at = get(data, attributes.started_at);
    const value = getCurrentValue(data);

    return (
        <>
            <div className={styles.rightDrawerKpiCardBodyUpdateNeeded}>
                {is_need_updated && <PastDueBadge text="Update Needed" withIcon={false} />}
                {is_past_due && <PastDueBadge text="Target Date Past Due" />}
            </div>
            <div className={styles.rightDrawerKpiInfoContainer}>
                <div className={styles.rightDrawerKpiCardBodyInfo}>
                    <KpiParameter textLabel="Unit Of Measure:">{formatByTypeCapitalized(type)}</KpiParameter>
                    {type === typesOfMeasure.monetary
                        && <KpiParameter textLabel="Currency Type:">{data?.[attributes.currency_format]}</KpiParameter>
                    }
                    <KpiParameter textLabel="Update Frequency:">{frequency}</KpiParameter>
                    <KpiParameter textLabel="Update Reminder:">{requiredFromLabel}</KpiParameter>
                    <KpiParameter textLabel="Start Period:">{getFormattedPeriod(frequency, started_at)}</KpiParameter>
                    <KpiParameter textLabel="Target Period:">{getFormattedPeriod(frequency, target_at)}</KpiParameter>
                    {kpiType !== kpiTypes.stepped.type && (
                        <KpiParameter textLabel={`${renderTargetValueLabel(kpiType)}:`}>
                            {formatNumber(get(data, attributes.target_value))}
                        </KpiParameter>
                    )}
                </div>
                <div className={styles.rightDrawerKpiGauge}>
                    <ChartGauge
                        height={120}
                        config={createGaugeConfig({ ...data, currencySymbol }, { height: 120, })}
                        showValues={false}
                    />
                    <span className={styles.rightDrawerKpiGaugeValue}>
                        {dataPoints.length
                            ? (
                                <span style={{ color: calculateLabelColor(data.progress_status) }}>
                                    {formatByType(parseCurrentValue(value), data.type, currencySymbol)}
                                </span>
                            )
                            : 'No Value'}
                    </span>
                </div>
            </div>
        </>
    );
};

KpiCardBody.propTypes = {
    data: PropTypes.object.isRequired,
};

export default KpiCardBody;
