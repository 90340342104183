import React from 'react';
import PropTypes from 'prop-types';
import { ServicePage } from '../../shared/components';

export default function ServicePageMobileAccessError({ children }) {
    return (
        <ServicePage
            icon="browser"
            title="KPI and CSF are not available on mobile"
            description="Please use web version to access these entities"
        >
            {children}
        </ServicePage>
    );
}

ServicePageMobileAccessError.propTypes = {
    children: PropTypes.node.isRequired,
};
