import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './spinner.css';

const elements = [...new Array(12)].map((item, index) => index);

export default function ButtonSpinner({ className }) {
    return (
        <span
            className={classnames('lds-spinner', {
                [className]: !!className,
            })}
        >
            {elements.map(el => <span key={el} />)}
        </span>
    );
}

ButtonSpinner.defaultProps = {
    className: undefined,
};

ButtonSpinner.propTypes = {
    className: PropTypes.string,
};
