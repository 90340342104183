import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { VersionConflictModal } from '../../components';

export default class VersionConflictProvider extends Component {
    constructor() {
        super();
        this.lastSubmitData = {};
    }

    updateEntityWithVersionCheck = (args) => {
        const {
            entityVersioning, entityType, updateEntity, onSuccessUpdate, additionalParams
        } = this.props;
        this.lastSubmitData = get(args, 'params')
            ? { ...args, params: { ...additionalParams, ...args.params } }
            : { params: { ...additionalParams, ...args } };
        const onSuccessFromArgs = get(args, 'onSuccess');
        updateEntity({
            ...this.lastSubmitData,
            type: entityType,
            versioning: entityVersioning,
            onSuccess: (data) => {
                if (onSuccessFromArgs) onSuccessFromArgs(data);
                else onSuccessUpdate(data);
            },
        });
    }

    overwriteEntityDetails = () => {
        const { updateEntity, onSuccessOverwrite, entityType } = this.props;
        const onSuccessFromArgs = get(this.lastSubmitData, 'onSuccess');
        updateEntity({
            ...this.lastSubmitData,
            type: entityType,
            onSuccess: (data) => {
                if (onSuccessFromArgs) onSuccessFromArgs(data);
                else onSuccessOverwrite(data);
                this.lastSubmitData = {};
            },
        });
    }

    handleHideVersionConflict = () => {
        const { hideVersionConflictModal, onDiscardOverwrite } = this.props;
        onDiscardOverwrite();
        hideVersionConflictModal();
        this.lastSubmitData = {};
    }

    render() {
        const { children, visibleVersionConflictModal, hideVersionConflictModal } = this.props;
        return (
            <>
                {children({
                    updateEntityWithVersionCheck: this.updateEntityWithVersionCheck,
                })}
                <VersionConflictModal
                    onCancel={hideVersionConflictModal}
                    onOverwrite={this.overwriteEntityDetails}
                    onDiscard={this.handleHideVersionConflict}
                    visible={visibleVersionConflictModal}
                />
            </>
        );
    }
}

VersionConflictProvider.defaultProps = {
    entityVersioning: undefined,
    onSuccessOverwrite() {},
    onSuccessUpdate() {},
    onDiscardOverwrite() {},
    additionalParams: {},
};

VersionConflictProvider.propTypes = {
    children: PropTypes.func.isRequired,
    entityVersioning: PropTypes.string,
    entityType: PropTypes.string.isRequired,
    updateEntity: PropTypes.func.isRequired,
    hideVersionConflictModal: PropTypes.func.isRequired,
    visibleVersionConflictModal: PropTypes.bool.isRequired,
    onSuccessOverwrite: PropTypes.func,
    onSuccessUpdate: PropTypes.func,
    onDiscardOverwrite: PropTypes.func,
    additionalParams: PropTypes.object,
};
