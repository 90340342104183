import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
    Icon, StatusIndicator, Text, Clickable,
} from '../../../../../shared/components';
import styles from './csf-connection-list-item-mobile.module.css';

const CsfConnectionListItemMobile = ({
    href, title, progress_status, icon_name, onRemove
}) => {
    return (
        <div className={styles.itemConnectedToDriverContainer}>
            <Link className={styles.itemTitle} to={href}>
                <Icon
                    name={icon_name}
                    type="regular"
                    fixedWidth
                    className={styles.itemIcon}
                />
                <StatusIndicator
                    size="small"
                    status={progress_status}
                    className={styles.itemStatus}
                />
                <Text styleType="dark-light" className={styles.itemText}>{title}</Text>
            </Link>
            <Clickable onClick={onRemove}>
                <Icon
                    name="trash-alt"
                    type="regular"
                    fixedWidth
                    className={styles.itemIconTrash}
                />
            </Clickable>
        </div>
    );
};

CsfConnectionListItemMobile.propTypes = {
    title: PropTypes.string.isRequired,
    progress_status: PropTypes.number.isRequired,
    href: PropTypes.string.isRequired,
    icon_name: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default CsfConnectionListItemMobile;
