import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/component';
import styles from './badge-entity-type.module.css';

export default function BadgeEntityType({ icon, text }) {
    return (
        <div className={styles.badgeEntityType}>
            <Icon type={Icon.types.regular} name={icon} className={styles.badgeEntityTypeIcon} /> {text}
        </div>
    );
}

BadgeEntityType.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};
