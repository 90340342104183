export default {
    usageSummaryTitle: 'Usage Summary',
    totalAmount: 'Total amount of connected users',
    totalLinked: 'Total files linked',

    userListTitle: 'Users',
    filterLabelAll: 'All',
    filterLabelActive: 'Active',
    filterLabelInactive: 'Inactive',
};
