import { Model } from '../../../shared/entities-v2';
import ServiceAuthModelPlugin from './service-auth-model-plugin';

export const serviceAuthModelPlugin = new ServiceAuthModelPlugin();

const serviceAuthModel = new Model({
    name: 'service-auth-model',
    apiUrl: '/login',
    plugins: [
        serviceAuthModelPlugin
    ],
});

export default serviceAuthModel;
