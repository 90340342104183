import { get, isEqual, pick } from 'lodash';
import { isEmpty as isEmptyString } from '../../../shared/utils/string';
import {
    attributes, kpiTypes, rangeOfMeasure
} from '../constants';
import { getPeriodTargetValueAttributeByKpi } from '../utils';


function comparePeriodTargetValues(currentValues, prevValues) {
    if (currentValues.length !== prevValues.length) {
        return false;
    }

    return !!currentValues.find((item, index) => {
        const currentValue = Number(get(item, attributes.target_value, 0));
        const prevValue = Number(get(prevValues, [index, attributes.target_value], 0));

        return currentValue !== prevValue;
    });
}

function collectAdditionalValues(values) {
    return {
        [attributes.kpi_type]: get(values, attributes.kpi_type),
        [attributes.start_value]: get(values, attributes.start_value),
        [attributes.target_value]: get(values, attributes.target_value),
        [attributes.started_at]: get(values, attributes.started_at),
        [attributes.target_at]: get(values, attributes.target_at),
        [attributes.advanced_calculation_type]: get(values, attributes.advanced_calculation_type),
        [attributes.advanced_calculation_enabled]:
            get(values, attributes.advanced_calculation_enabled),
        [attributes.advanced_calculation_sources]:
            get(values, attributes.advanced_calculation_sources),
    };
}

export const isAdvCalcSourcesIsValid = (advCalcSources = [], advCalcEnabled = true) =>
    (advCalcEnabled === false || (advCalcSources.length > 0 && advCalcSources.every(source => source?.related_entity_id)));

export function additionalFieldChanged(currentValues, prevValues) {
    const additionalValuesChanged = !isEqual(
        collectAdditionalValues(currentValues),
        collectAdditionalValues(prevValues)
    );

    return additionalValuesChanged;
}

export function periodTargetsChanged(currentValues, prevValues) {
    const kpiType = get(currentValues, attributes.kpi_type);

    return comparePeriodTargetValues(
        get(currentValues, attributes.kpi_period_targets),
        get(prevValues, attributes.kpi_period_targets),
        getPeriodTargetValueAttributeByKpi(kpiType),
    );
}

export function isLinearFieldsValid(currentValues) {
    const kpiType = get(currentValues, attributes.kpi_type);
    const startValue = get(currentValues, attributes.start_value);
    const targetValue = get(currentValues, attributes.target_value);

    const isLinear = kpiType === kpiTypes.linear.type;
    const linearFieldsValid = (
        Number(startValue) !== Number(targetValue)
        && !isEmptyString(startValue)
        && !isEmptyString(targetValue)
    );

    return isLinear ? linearFieldsValid : true;
}

export function linearKpiValuesChanged(currentValues, prevValues) {
    const kpiType = get(currentValues, attributes.kpi_type);

    if (kpiType !== kpiTypes.linear.type) {
        return false;
    }

    const startValueChanged = (
        get(currentValues, attributes.start_value) !== get(prevValues, attributes.start_value)
    );
    const targetValueChanged = (
        get(currentValues, attributes.target_value) !== get(prevValues, attributes.target_value)
    );

    return startValueChanged || targetValueChanged;
}

export function getRangeOfMeasureLinear(startValue, targetValue) {
    if (startValue > targetValue) return rangeOfMeasure.desc;
    return rangeOfMeasure.asc;
}

export function isPeriodTargetRelatedValuesValid(currentValues) {
    const isAdvancedCalculation = get(currentValues, attributes.advanced_calculation_enabled);
    if (!isAdvancedCalculation) return true;

    const dataSources = get(currentValues, attributes.advanced_calculation_sources);
    if (isAdvancedCalculation && dataSources.length < 2) return false;

    return true;
}

export function isPeriodTargetsValid(currentValues) {
    const periodTargets = get(currentValues, attributes.kpi_period_targets, []);

    return !periodTargets.find(item => isEmptyString(item[attributes.target_value]));
}

export function isDataSourceClearNeeded(prevValues, currentValues) {
    const compareAttributes = [
        attributes.advanced_calculation_enabled,
        attributes.advanced_calculation_type,
        attributes.kpi_type,
    ];

    const prevValuesExist = Object
        .entries(pick(prevValues, compareAttributes))
        .filter(([, value]) => !!value)
        .length;

    if (prevValuesExist) {
        return !isEqual(
            pick(prevValues, compareAttributes),
            pick(currentValues, compareAttributes),
        );
    }

    return false;
}

export function formatAdvancedCalculationFields(data) {
    const isEnabled = get(data, attributes.advanced_calculation_enabled);

    if (!isEnabled) {
        return {
            [attributes.advanced_calculation_sources]: undefined,
            [attributes.advanced_calculation_type]: undefined,
        };
    }

    return {};
}

export const isAdvCalcSourcesChanged = (prevCurrenValues, currentValues) =>
    !isEqual(prevCurrenValues[attributes.advanced_calculation_sources], currentValues[attributes.advanced_calculation_sources]);

export const getCurrencyFromAdvCalcSourceEntity = currentValues =>
    currentValues?.[attributes.advanced_calculation_sources][0]?.related_entity?.[attributes.currency_format];
