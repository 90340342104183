import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
    Avatar, Icon, LoadingIndicator, FormattedDate, Tooltip
} from '../../../shared/components';
import { Select } from '../../../shared/modules';
import { user as userPropTypes } from '../../../modules/goalmap-list/proptypes';

import './styles.css';

function getTitle(item) {
    return item.display_name || item.title;
}

export default class Member extends Component {
    state = {
        selectedItem: null
    };

    componentWillMount() {
        this.updateRole(this.props);
    }

    changeRoleWrapper = (selectedItemObj) => {
        const { onChangeRole } = this.props;
        const { selectedItem } = this.state;

        if (selectedItem.id === selectedItemObj.id) {
            return;
        }

        onChangeRole(selectedItemObj);
        this.setState({
            selectedItem: selectedItemObj
        });
    };

    updateRole(props) {
        const { role_id, roles } = props;
        const selectedItem = roles.find(item => (item.id === role_id));

        this.setState({
            selectedItem
        });
    }

    render() {
        const {
            avatar,
            first_name,
            last_name,
            role_id,
            tag_name,
            onRemoveUser,
            isRoleNeeded,
            is_account_owner,
            showDetailsModal,
            onActivateUser,
            updating,
            login_at,
            status,
            canChangeRole,
            canRemove,
            canRemoveLocally,
            roles,
            avatar_image_url,
            withoutActions,
            disabled,
            user_profile = {},
            avatarProps,
        } = this.props;

        const { selectedItem = {} } = this.state;
        const currentRole = roles.find(item => (item.id === role_id)) || {};
        const currentRoleTitle = getTitle(currentRole);
        const inactive = (status === 0 || status === 3);
        const avatarImg = avatar || avatar_image_url || (user_profile ? user_profile.image : null);

        // dinamically check what label should be used
        const labelKey = Object.keys(selectedItem).filter((key) => {
            return (['display_name', 'title'].indexOf(key) >= 0);
        })[0] || 'display_name';

        return (
            <div
                className={classnames('member-list-item', {
                    inactive
                })}
            >
                <div className="member-list-item-inner">
                    {(updating) && (
                        <div className="loading-overlay">
                            <LoadingIndicator size="small" centered />
                        </div>
                    )}

                    <div className="member-list-item-user-info">
                        <div className="avatar-group">
                            <Avatar
                                src={avatarImg}
                                className="member-avatar"
                                alt="Member avatar"
                                first_name={first_name}
                                last_name={last_name}
                                {...avatarProps}
                            />

                            {(!inactive && canRemove && !withoutActions) && (
                                <button className="avatar-overlay util-fit" type="button" onClick={onRemoveUser}>
                                    <Icon name="times-circle" />
                                </button>
                            )}

                            {(!inactive && canRemoveLocally && !withoutActions) && (
                                <button className="avatar-overlay util-fit" type="button" onClick={onRemoveUser}>
                                    <Icon name="times-circle" />
                                </button>
                            )}

                            {(inactive && canRemove && !withoutActions) && (
                                <button className="avatar-overlay util-fit" type="button" onClick={onActivateUser}>
                                    <Icon name="bolt" />
                                </button>
                            )}
                        </div>

                        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                        <div
                            className="member-info"
                            onClick={() => showDetailsModal({ id: this.props.user_id })}
                        >
                            {/* eslint-enable jsx-a11y/click-events-have-key-events */}
                            {/* eslint-enable jsx-a11y/no-static-element-interactions */}
                            <div className={classnames('member-name', { 'account-owner': is_account_owner, })}>
                                {first_name} {last_name}&nbsp;{(is_account_owner) && (
                                    <Tooltip className="account-owner-tooltip" text="Account Owner">
                                        <Icon className="member-account-owner" name="key" />
                                    </Tooltip>
                                )}
                            </div>
                            <div className="member-tag">
                                @{tag_name}
                            </div>
                            {(!canChangeRole) && (
                                <div className="member-role">
                                    {currentRoleTitle}
                                </div>
                            )}
                            {(login_at) && (
                                <div className="member-login-date">
                                    Last login:&nbsp;
                                    <FormattedDate date={login_at} />
                                </div>
                            )}
                        </div>
                    </div>
                    {(isRoleNeeded && canChangeRole) && (
                        <Select
                            className="member-role-dropdown"
                            onChange={this.changeRoleWrapper}
                            options={roles}
                            value={selectedItem}
                            disabled={disabled || inactive}
                            valueKey="id"
                            labelKey={labelKey}
                            clearable={false}
                        />
                    )}
                </div>
            </div>
        );
    }
}

Member.defaultProps = {
    onRemoveUser() {},
    pivot: {},
    isRoleNeeded: true,
    withoutActions: false,
    roles: [],
    canRemove: false,
    showDetailsModal: () => {},
    onActivateUser() {},
    onChangeRole() {},
    auth0_status: undefined,
};

Member.propTypes = {
    ...userPropTypes,
    pivot: PropTypes.shape({
        goal_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),
    showDetailsModal: PropTypes.func,
    canRemove: PropTypes.bool,
    roles: PropTypes.array,
    isRoleNeeded: PropTypes.bool,
    withoutActions: PropTypes.bool,
    onRemoveUser: PropTypes.func,
    onChangeRole: PropTypes.func,
    onActivateUser: PropTypes.func,
    auth0_status: PropTypes.number,
};
