import React from 'react';
import PropTypes from 'prop-types';
import { Text, Tooltip, Panel } from '../../../../shared/components';
import CsfRelatedLinkList from './csf-related-link-list/csf-related-link-list';
import CsfRelatedLinkCreate from './csf-related-link-create/csf-related-link-create';
import { getEntityStatus } from '../../../../shared/utils/entity';

import './csf-related-links-section.css';

export default function CsfRelatedLinksSection({
    parentId, parentType, parentState, disabled, permissions, canCreateRelatedLink
}) {
    const tooltipText = !canCreateRelatedLink
        ? 'This action is not available for your role.'
        : `This action is not available for ${getEntityStatus(parentState)} entities.`;
    return (
        <div className="driver-related-links-section">
            <Panel className="page-separator">
                <Text className="driver-related-links-title" component="h2" size="xlarge" styleType="dark-light" bold>
                    <Tooltip text={tooltipText} disabled={!disabled}>
                        <CsfRelatedLinkCreate
                            disabled={disabled}
                            parentId={parentId}
                            parentType={parentType}
                        />
                    </Tooltip>
                    Connection(s)
                </Text>
            </Panel>
            <CsfRelatedLinkList
                parentId={parentId}
                parentType={parentType}
                permissions={permissions}
            />
        </div>
    );
}

CsfRelatedLinksSection.defaultProps = {
    disabled: false,
};

CsfRelatedLinksSection.propTypes = {
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    permissions: PropTypes.object.isRequired,
    parentState: PropTypes.number.isRequired,
    canCreateRelatedLink: PropTypes.bool.isRequired,
};
