export const fields = Object.freeze({
    hasActivities: 'has_activities',
    status: 'status',
    planId: 'plan_id',
    roleId: 'role_id',
    uniqueId: 'uniqueId',
    plan: 'plan',
    role: 'role',
});

export const updateUserFields = Object.freeze({
    planId: 'goal_id',
    roleId: 'role_id',
    status: 'status',
});

export const statuses = Object.freeze({
    active: 1,
    inactive: 0,
});

export const parentField = 'goals';

export const draftPlanId = -1;

export const defaultAccountRoleId = 1;
