import React from 'react';
import { createUrl, paths as routePaths } from '../../routes/rules';
import withScrollTop from '../../shared/components/with-scroll-top';
import { slugs, titles } from './config';
import Container from './index';
import List from './list';
import GoalList from './goal-list';
import CommunicationDetails from './communication-details';
import StorageDetails from './storages';
import PublicApiDetails from './public-api/public-api-details';

const listPath = createUrl(routePaths.settingsIntegrations);

const generateBreadcrumbs = (...addedCrumbs) => {
    const crumbs = [{ title: 'Integrations', href: '/settings/integrations' }];

    if (addedCrumbs) {
        crumbs.push(...addedCrumbs);
    }

    return crumbs;
};

const paths = Object.freeze({
    list: listPath,
    [slugs.slack]: `${listPath}/slack`,
    slackForm: `${listPath}/slack/:goalId`,
    [slugs.msTeams]: `${listPath}/ms-teams`,
    msTeamsForm: `${listPath}/ms-teams/:goalId`,
    [slugs.googleDrive]: `${listPath}/google-drive`,
    [slugs.dropbox]: `${listPath}/dropbox`,
    [slugs.oneDrive]: `${listPath}/one-drive`,
    [slugs.box]: `${listPath}/box`,
    [slugs.publicAPI]: `${listPath}/public-api`,
});

export const createHref = {
    list: () => paths.list,
    [slugs.slack]: () => paths[slugs.slack],
    slackForm: ({ goalId }) => `${paths.slack}/${goalId}`,
    [slugs.msTeams]: () => paths[slugs.msTeams],
    msTeamsForm: ({ goalId }) => `${paths[slugs.msTeams]}/${goalId}`,
    [slugs.googleDrive]: () => paths[slugs.googleDrive],
    [slugs.dropbox]: () => paths[slugs.dropbox],
    [slugs.oneDrive]: () => paths[slugs.oneDrive],
    [slugs.box]: () => paths[slugs.box],
    [slugs.publicAPI]: () => paths[slugs.publicAPI]
};

const crumbs = {
    [slugs.slack]: { title: `${titles.slack} Integration`, href: paths[slugs.slack] },
    [slugs.msTeams]: { title: `${titles.msTeams} Integration`, href: paths[slugs.msTeams] },
    [slugs.googleDrive]: { title: titles.googleDrive, href: paths[slugs.googleDrive] },
    [slugs.dropbox]: { title: titles.dropbox, href: paths[slugs.dropbox] },
    [slugs.oneDrive]: { title: titles.oneDrive, href: paths[slugs.oneDrive] },
    [slugs.box]: { title: titles.box, href: paths[slugs.box] },
    [slugs.publicAPI]: { title: titles.publicAPI, href: paths[slugs.publicAPI] },
    configuration: { title: 'Configuration' }
};

export default {
    path: paths.list,
    component: Container,
    indexRoute: {
        component: () => <List breadcrumbs={generateBreadcrumbs()} />,
    },
    childRoutes: [
        {
            path: paths[slugs.slack],
            indexRoute: {
                component: props => (
                    <GoalList
                        slug={slugs.slack}
                        breadcrumbs={generateBreadcrumbs(crumbs[slugs.slack])}
                        createHrefFn={createHref.slackForm}
                        {...props}
                    />
                ),
            },
            childRoutes: [
                {
                    path: paths.slackForm,
                    component: props => (
                        <CommunicationDetails
                            slug={slugs.slack}
                            breadcrumbs={generateBreadcrumbs(crumbs[slugs.slack], crumbs.configuration)}
                            redirectUrl={paths[slugs.slack]}
                            {...props}
                        />
                    ),
                    pageType: 'goal',
                }
            ]
        },
        {
            path: paths[slugs.msTeams],
            indexRoute: {
                component: props => (
                    <GoalList
                        slug={slugs.msTeams}
                        breadcrumbs={generateBreadcrumbs(crumbs[slugs.msTeams])}
                        createHrefFn={createHref.msTeamsForm}
                        {...props}
                    />
                ),
            },
            childRoutes: [
                {
                    path: paths.msTeamsForm,
                    component: props => (
                        <CommunicationDetails
                            slug={slugs.msTeams}
                            breadcrumbs={generateBreadcrumbs(crumbs[slugs.msTeams], crumbs.configuration)}
                            redirectUrl={paths[slugs.msTeams]}
                            {...props}
                        />
                    ),
                    pageType: 'goal',
                }
            ]
        },
        {
            path: paths[slugs.googleDrive],
            component: props => (
                <StorageDetails
                    slug={slugs.googleDrive}
                    breadcrumbs={generateBreadcrumbs(crumbs[slugs.googleDrive])}
                    title={titles.googleDrive}
                    {...props}
                />
            ),
        },
        {
            path: paths[slugs.dropbox],
            component: props => (
                <StorageDetails
                    slug={slugs.dropbox}
                    breadcrumbs={generateBreadcrumbs(crumbs[slugs.dropbox])}
                    title={titles.dropbox}
                    {...props}
                />
            ),
        },
        {
            path: paths[slugs.oneDrive],
            component: props => (
                <StorageDetails
                    slug={slugs.oneDrive}
                    breadcrumbs={generateBreadcrumbs(crumbs[slugs.oneDrive])}
                    title={titles.oneDrive}
                    {...props}
                />
            ),
        },
        {
            path: paths[slugs.box],
            component: props => (
                <StorageDetails
                    slug={slugs.box}
                    breadcrumbs={generateBreadcrumbs(crumbs[slugs.box])}
                    title={titles.box}
                    {...props}
                />
            ),
        },
        {
            path: paths[slugs.publicAPI],
            component: withScrollTop(props => (
                <PublicApiDetails
                    slug={slugs.publicAPI}
                    breadcrumbs={generateBreadcrumbs(crumbs[slugs.publicAPI])}
                    title={titles.publicAPI}
                    {...props}
                />
            )),
        },
    ],
};
