import PropTypes from 'prop-types';
import React from 'react';
import ServiceMessageModal from '../serviceMessageModal/component';
import Button from '../button/component';
import './styles.css';

export default function VersionConflictModal({
    onCancel,
    onDiscard,
    onOverwrite,
    visible,
}) {
    return (
        <ServiceMessageModal
            visible={visible}
            hide={onCancel}
            className="version-conflict-modal"
        >
            <h3 className="version-conflict-modal-title">
                Another user has been editing this entity.
            </h3>

            <div>

                <Button onClick={onOverwrite} styleType="success">
                    Apply my changes
                </Button>

                <Button onClick={onDiscard} styleType="success">
                    Discard my changes
                </Button>

                <Button onClick={onCancel} outline>
                    Cancel
                </Button>
            </div>
        </ServiceMessageModal>
    );
}

VersionConflictModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onOverwrite: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};
