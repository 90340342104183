export default function VisibleItemsCalculator({
    children, containerHeight, scrollTop, itemHeight, itemsCount
}) {
    const indexesMap = {};
    const invisibleTopCount = Math.floor(scrollTop / itemHeight);
    const visibleItemsCount = Math.ceil(containerHeight / itemHeight);

    for (let i = invisibleTopCount; i <= invisibleTopCount + visibleItemsCount; i += 1) {
        indexesMap[i] = true;
    }

    return children({
        visibleIndexesMap: indexesMap,
        visibleCount: visibleItemsCount,
        invisibleTopCount,
        invisibleBottomCount: Math.max(itemsCount - (invisibleTopCount + visibleItemsCount), 0)
    });
}
