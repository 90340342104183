import logo from '../app/modules/navigation-header/images/logo.svg';
import favicon from '../favicons/favicon.png';

export default {
    auth0() {
        return window.appConfig.auth0;
    },
    apiUrl() {
        return window.appConfig.apiUrl;
    },
    apiHost() {
        return window.appConfig.apiHost;
    },
    wsUrl() {
        return window.appConfig.wsUrl;
    },
    attachmentBaseUrl() {
        return window.appConfig.attachmentBaseUrl;
    },
    sentryDsn() {
        return window.appConfig.sentryDsn;
    },
    sentryEnabled() {
        return window.appConfig.sentryEnabled;
    },
    sentryIgnoreErrors: [
        'ResizeObserver loop limit exceeded',
        'TypeError: t.__timer_handlers__[e.data.id] is not a function',
    ],
    zapierDisabled() {
        return window.appConfig.zapierDisabled;
    },
    colorTheme: {
        swatch: ['#4285f4', '#2499c4', '#df4957', '#6bb332', '#6772DE'],
    },
    origin: window.location.origin,
    hostname: window.location.host,
    gtmId: 'GTM-55825KM',
    defaultNotificationIcon: favicon,
    localStorageStateKey: 'supplycore-app-state',
    apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
    apiResponseDateFormat: 'YYYY-MM-DD',
    startEndTimePeriods: {
        start: 'startOf',
        end: 'endOf',
    },
    timeFormat: 'hh:mm A',
    dateFormat: 'MM/DD/YYYY dddd',
    shortDateFormat: 'MM/DD/YYYY',
    shortDateFormatCutYear: 'MM/DD/YY',
    defaultErrorMessage: 'Error occured during request.',
    supportEmail: 'support@mpowr.com',
    helpCenterLink:
        'https://mpowrsoftware.atlassian.net/servicedesk/customer/portals',
    navigationBlockMessage: 'Changes you made may not be saved',
    notifications: {
        headerListMaxLength: 5,
        count: 3,
        delay: 7000,
    },
    defaultLogo: logo,
    logoRecomendedDimensions: {
        width: 260,
        height: 60,
    },
    statusMap: {
        1: 'success',
        2: 'warning',
        3: 'danger',
        4: 'neutral',
    },
    statusNames: {
        closed: 'Closed',
        pending: 'Pending',
        progress: 'In Progress',
        hold: 'On Hold',
        cancelled: 'Cancelled',
    },
    attachments: {
        maxFileSizeMb: 15,
        extensions: [
            'jpeg',
            'jpg',
            'png',
            'gif',
            'docx',
            'doc',
            'pdf',
            'xls',
            'xlsx',
            'pptx',
            'pps',
            'ppsx',
            'pages',
            'numbers',
            'odt',
            'ods',
            'odp',
        ],
        mimeTypes: [
            'image/gif',
            'image/jpeg',
            'image/jpg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'application/pdf',
            'application/excel',
            'application/vnd.ms-excel',
            'application/x-excel',
            'application/x-msexcel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'application/x-iwork-pages-sffpages',
            'application/x-iwork-numbers-sffnumbers',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.presentation',
            'application/mspowerpoint',
            'application/powerpoint',
            'application/x-mspowerpoint',
        ],
    },
    dndEntitiesExpandTimeout: 1000,
    breakpoints: {
        xsmall: 320,
        small: 480,
        medium: 1024,
        large: 1200,
        xlarge: 1366,
        xxlarge: 1920,
    },
    isNetworkError(error) {
        return (error || {}).message === 'Network Error';
    },
    defaultListLimit: 10,
    wizardVMVContent: {
        apiUrl: 'https://mpowr.com/wp-json/wp/v2/pages/',
        slugs: {
            mission: 'mission-content',
            vision: 'vision-content',
            values: 'values-content',
        },
    },
    highchartsTurboTreshold: 0,
};
