/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { InputColor } from '../../../shared/components';
import * as styleUtils from '../../../shared/style-utils';
import * as actions from './color-theme-actions';
import { themes } from './color-theme-constants';
import { selectAccentColor, selectColorTheme } from './color-theme-selectors';
import './color-theme-form.css';

function ColorThemeForm({
    setTheme,
    setAccentColor,
    currentValues,
    setSettings,
    data,
    accentColor,
    disabled
}) {
    return (
        <div className="color-theme-form">
            <label
                htmlFor="input-theme-light"
                className={classnames('color-theme-input', {
                    active: currentValues.theme === themes.LIGHT,
                    [styleUtils.inactive]: disabled,
                })}
            >
                <Field
                    component="input"
                    type="radio"
                    name="theme"
                    id="input-theme-light"
                    value={themes.LIGHT}
                    onChange={() => {
                        setTheme({ theme: themes.LIGHT });
                        setSettings({ ...data, theme: themes.LIGHT, entityName: 'theme' });
                    }}
                />

                Light
            </label>

            <label
                htmlFor="input-theme-dark"
                className={classnames('color-theme-input dark', {
                    active: currentValues.theme === themes.DARK,
                    [styleUtils.inactive]: disabled,
                })}
            >
                <Field
                    component="input"
                    type="radio"
                    name="theme"
                    id="input-theme-dark"
                    value={themes.DARK}
                    onChange={() => {
                        setTheme({ theme: themes.DARK });
                        setSettings({ ...data, theme: themes.DARK, entityName: 'theme' });
                    }}
                />

                Dark
            </label>

            <InputColor
                name="accent"
                className="color-theme-picker"
                onChangeCustom={(color) => {
                    setAccentColor({
                        color
                    });
                    setSettings({ ...data, accent: color, entityName: 'theme' });
                }}
                accentColor={accentColor}
                disabled={disabled}
            />
        </div>
    );
}

ColorThemeForm.defaultProps = {
    currentValues: {
        theme: 'dark'
    },
    disabled: false,
};

ColorThemeForm.propTypes = {
    setAccentColor: PropTypes.func.isRequired,
    setTheme: PropTypes.func.isRequired,
    setSettings: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    currentValues: PropTypes.shape({
        theme: PropTypes.string,
    }),
    accentColor: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

const formName = 'color-theme';
const formSelector = formValueSelector(formName);

function mapStateToProps(state) {
    return {
        currentValues: {
            theme: formSelector(state, 'theme')
        },
        initialValues: {
            accent: selectAccentColor(state),
            theme: selectColorTheme(state),
        },
        accentColor: selectAccentColor(state),
    };
}

const actionsMap = {
    setTheme: actions.setTheme,
    setAccentColor: actions.setAccentColor,
};

const formComponent = reduxForm({
    form: formName
})(ColorThemeForm);

export default connect(mapStateToProps, actionsMap)(formComponent);
