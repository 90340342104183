import { Collection } from '../../shared/entities-v2';
import { name } from './constants';

function formatSettings(item) {
    return {
        ...item,
        settings: JSON.parse(item.settings)
    };
}

class PlansListMyCollection extends Collection {
    parse = (response) => {
        return {
            data: response.map(formatSettings)
        };
    }

    format = (params) => {
        return {
            state: [1, 4],
            status: [1],
            ...params
        };
    }
}

export const plansListMy = new PlansListMyCollection({
    apiUrl: '/goals/my',
    name
});
