import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import {
    Text, HTMLRenderer, PageTitle, EditableContent, Editor, Clickable, Icon, Input, TextTruncated,
    StatusIndicator, BadgeArchive, BadgeEntityType
} from '../../../shared/components';
import { isArchived, getIconByTypeCode } from '../../../shared/utils/entity';
import createInlineFieldForm from '../../../shared/components/inline-field-form/component';
import styles from './styles.module.css';
import { typeCodes } from '../../../shared/utils/entity-type';

const Form = createInlineFieldForm({
    formName: 'plan-edit-form',
    enableReinitialize: true
});

const PlanTitle = ({
    planTitle, activeFieldName, editAllowed, setActiveFieldName, updatePlanDetails, planId, progressStatus
}) => {
    const statusIndicator = (
        <StatusIndicator
            className={styles.planDetailsProgressStatus}
            status={progressStatus || StatusIndicator.statuses.neutral}
        />
    );

    return (
        <>
            {(activeFieldName !== 'title' && !editAllowed) && (
                <PageTitle>
                    <div className={styles.planDetailsTitleContainer}>
                        {statusIndicator}
                        <span>{planTitle}</span>
                    </div>
                </PageTitle>
            )}
            {(activeFieldName !== 'title' && editAllowed) && (
                <Clickable
                    className="plan-info-details-edit-title"
                    action="edit-title"
                    onClick={() => setActiveFieldName('title')}
                >
                    <PageTitle>
                        <div className={styles.planDetailsTitleContainer}>
                            {statusIndicator}
                            <span>{planTitle}</span>
                            <Icon className={styles.planDetailsTitleIcon} type="regular" name="pen" />
                        </div>
                    </PageTitle>
                </Clickable>
            )}
            {activeFieldName === 'title' && (
                <PageTitle className={styles.planDetailsTitleFormContainer}>
                    {statusIndicator}

                    <Form
                        containerClassName={styles.planDetailsTitleForm}
                        onCancel={() => setActiveFieldName('')}
                        onSubmit={updatePlanDetails}
                        initialValues={{
                            id: planId, title: planTitle, updateTitle: true,
                        }}
                    >
                        <Field
                            name="title"
                            component={Input}
                            props={{
                                autoFocus: true,
                            }}
                        />
                    </Form>
                </PageTitle>
            )}
        </>
    );
};

PlanTitle.propTypes = {
    planTitle: PropTypes.string.isRequired,
    planId: PropTypes.string.isRequired,
    updatePlanDetails: PropTypes.func.isRequired,
    editAllowed: PropTypes.bool.isRequired,
    activeFieldName: PropTypes.string.isRequired,
    setActiveFieldName: PropTypes.func.isRequired,
    progressStatus: PropTypes.number.isRequired,
};

export const PlanVisionMissionValues = ({
    activeFieldName, showReadMore, planDetails, editAllowed, updatePlanDetails,
    setActiveFieldName, handleSetShowReadMore, planId, containerClassName, onSuccessSubmit, quillStickyProps
}) => {
    return (
        <div className={classnames(styles.planVisionMissionValues, { [containerClassName]: containerClassName })}>
            {['vision', 'mission', 'values'].map(name => (
                <div className={styles.planDetailsSubtitleEditable} key={name}>
                    {(activeFieldName === name) && (
                        <Form
                            containerClassName="plan-details-form"
                            onCancel={() => setActiveFieldName('')}
                            onSubmit={updatePlanDetails}
                            initialValues={{
                                id: planId, name, goal_details: planDetails
                            }}
                            onSuccessSubmit={onSuccessSubmit}
                        >
                            <Field
                                name={`goal_details[]${name}`}
                                component={Editor}
                                props={{
                                    controlsHidden: true,
                                    autoFocus: true,
                                    goal_id: planId,
                                    ...quillStickyProps,
                                }}
                            />
                        </Form>
                    )}
                    {(activeFieldName !== name && editAllowed) && (
                        <EditableContent
                            title={(
                                <Text size="large" styleType="dark" bold className={styles.planDetailsSubtitle}>{name}</Text>
                            )}
                            onClick={() => setActiveFieldName(name)}
                        >
                            {showReadMore[name]
                                ? (
                                    <TextTruncated
                                        className={styles.planDetailsText}
                                        text={planDetails[name]}
                                        readMore
                                        onClick={() => handleSetShowReadMore(name, false)}
                                    />
                                )
                                : (
                                    <Fragment>
                                        <HTMLRenderer className={styles.planDetailsText}>
                                            {planDetails[name]}
                                        </HTMLRenderer>
                                        <Clickable
                                            onClick={() => handleSetShowReadMore(name, true)}
                                            className={classnames('text-truncated-link', styles.planDetailsText)}
                                        >
                                            Hide text{' '}
                                            <Text
                                                component={Icon}
                                                size="small"
                                                type="solid"
                                                styleType="accent"
                                                name="chevron-right"
                                            />
                                        </Clickable>
                                    </Fragment>
                                )
                            }
                        </EditableContent>
                    )}

                    {(activeFieldName !== name && !editAllowed) && (
                        <>
                            <Text size="large" styleType="dark" bold className={styles.planDetailsSubtitleNotEditable}>{name}</Text>
                            <HTMLRenderer className={styles.planDetailsText}>{planDetails[name]}</HTMLRenderer>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

PlanVisionMissionValues.defaultProps = {
    containerClassName: undefined,
    onSuccessSubmit: undefined,
    quillStickyProps: {},
};

PlanVisionMissionValues.propTypes = {
    handleSetShowReadMore: PropTypes.func.isRequired,
    planId: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    updatePlanDetails: PropTypes.func.isRequired,
    editAllowed: PropTypes.bool.isRequired,
    activeFieldName: PropTypes.string.isRequired,
    setActiveFieldName: PropTypes.func.isRequired,
    showReadMore: PropTypes.object.isRequired,
    planDetails: PropTypes.object.isRequired,
    containerClassName: PropTypes.string,
    onSuccessSubmit: PropTypes.func,
    quillStickyProps: PropTypes.object,
};

const PlanDetails = ({
    planTitle, planDetails, state, updatePlanDetails, planId, editAllowed, progressStatus, entityTypeTitle
}) => {
    const [activeFieldName, setActiveFieldName] = useState('');
    const [showReadMore, setShowReadMore] = useState({ vision: true, mission: true, values: true });

    const handleSetShowReadMore = (name, status) => {
        setShowReadMore({ ...showReadMore, [name]: status });
    };

    const isArchivedEntity = isArchived({ state });

    return (
        <div className={styles.planDetailsContainer}>
            <PlanTitle
                planTitle={planTitle}
                planId={planId}
                editAllowed={editAllowed}
                activeFieldName={activeFieldName}
                setActiveFieldName={setActiveFieldName}
                updatePlanDetails={updatePlanDetails}
                progressStatus={progressStatus}
            />

            <div className={styles.planDetailsMeta}>
                <BadgeEntityType icon={getIconByTypeCode(typeCodes.plan)} text={entityTypeTitle} />
                &nbsp;
                {isArchivedEntity && (<BadgeArchive />)}
            </div>

            <PlanVisionMissionValues
                activeFieldName={activeFieldName}
                showReadMore={showReadMore}
                planDetails={planDetails}
                editAllowed={editAllowed}
                updatePlanDetails={updatePlanDetails}
                planId={planId}
                setActiveFieldName={setActiveFieldName}
                handleSetShowReadMore={handleSetShowReadMore}
            />
        </div>
    );
};

PlanDetails.defaultProps = {
    planDetails: {}
};

PlanDetails.propTypes = {
    planTitle: PropTypes.string.isRequired,
    planId: PropTypes.string.isRequired,
    planDetails: PropTypes.object,
    updatePlanDetails: PropTypes.func.isRequired,
    editAllowed: PropTypes.bool.isRequired,
    progressStatus: PropTypes.number.isRequired,
    state: PropTypes.number.isRequired,
    entityTypeTitle: PropTypes.string.isRequired,
};

export default PlanDetails;
