import u from 'updeep';
import { formValueSelector } from 'redux-form';
import dictionary from './dictionary';

export const paths = Object.freeze({
    login: 'login',
    signup: 'signup',
    passwordReset: 'password-change',
    passwordResetRequest: 'password-reset-request',
    emailChange: 'change-email',
    emailChangeCallback: 'change-email-callback',
    serviceAuth: 'service-auth',
    serviceAuthConsent: 'service-auth-consent',
});

export const fieldNames = Object.freeze({
    email: 'email',
    password: 'password',
    passwordConfirm: 'password_confirm',
    passwordNew: 'new_password',
    oauthType: 'oauth_type',
    inviteToken: 'invite_token',
    eula: 'eula',
    emailNew: 'email_new',
    code: 'code',
    token: 'token',
    currentEmail: 'user.email',
});

export const changeEmailFormTypes = Object.freeze({
    auth0: 'auth0',
    social: 'social',
});

export const providers = Object.freeze({
    auth0: 'auth0',
    facebook: 'facebook',
    linkedIn: 'linkedin',
    google: 'google',
});

export const auth0providers = Object.freeze({
    [providers.google]: 'google-oauth2',
    [providers.facebook]: 'facebook',
    [providers.linkedIn]: 'linkedin',
});

export const stateBranchName = 'authentication';
export const widgetFormName = 'authentication-form';
export const defaultRedirectURL = '/dashboard';
export const eulaHref = 'https://www.mpowr.com/mpowr-envision-terms-of-service';
export const privacyPolicyHref = 'https://www.mpowr.com/privacy-policy';
export const widgetFormValueSelector = formValueSelector(widgetFormName);
export const refreshTokenTimeSec = 30;

export function composeLoginResponse(authData, userData) {
    const authResponse = {
        access_token: authData.accessToken,
        access_token_expired: authData.accessTokenExpired,
        access_token_refresh_time: authData.accessTokenRefreshTime,
        access_token_expired_at: authData.accessTokenExpiredAt,
        access_token_refresh_time_at: authData.accessTokenRefreshTimeAt,
        auth0_status: authData.auth0Status,
        expired_token: authData.expiredToken,
        lastLoginTime: authData.lastLoginTime,
    };

    const userResponse = {
        ...userData,
        user_profile: userData
    };

    return u(userResponse, authResponse);
}

export const resetPasswordSuccessParam = 'request-password-success';
export const changePasswordSuccessParam = 'change-password-success';
export const resetPasswordTokenParam = 'token';

export function isResetPasswordSuccess(query) {
    return resetPasswordSuccessParam in query;
}

export function isChangePasswordSuccess(query) {
    return changePasswordSuccessParam in query;
}

export function validatePasswordChangeForm(values) {
    const errors = {};
    const password = values[fieldNames.password];
    const passwordConfirm = values[fieldNames.passwordConfirm];

    if (!!passwordConfirm && password !== passwordConfirm) {
        errors[fieldNames.password] = [dictionary.changePassword.samePasswordMismatch];
    }

    return errors;
}

export function getIsAllowedUnauthorizedPage(currentPath) {
    return !!Object.values(paths).find(path => currentPath.includes(path));
}

export function parseQueryErrors(query) {
    const parseQueryErrorReducer = (accum, item) => {
        const [key, val] = item;
        if (key.includes('errors[')) {
            accum.push(val);
        }

        return accum;
    };

    return Object
        .entries(query)
        .reduce(parseQueryErrorReducer, []);
}
