import React, { Component } from 'react';
import PropTypes from 'prop-types';
import initSentry from '../../../sentry';
import { LoadingIndicator } from '../../../shared/components';
import apiService from '../../../request';
import { ServicePageConnectionError } from '../../service-pages';

const { APP_VERSION } = process.env;

export default class ConfigLoader extends Component {
    state = {
        loaded: false,
        error: false,
    };

    componentDidMount() {
        apiService
            .get(`/configjs.json?ver=${APP_VERSION}`, { baseURL: '' })
            .then(this.handleConfigLoadSuccess, this.handleConfigLoadError);
    }

    handleConfigLoadSuccess = (response) => {
        window.appConfig = response.data;
        apiService.setDefaults();
        initSentry();

        this.setState({
            loaded: true,
        });
    }

    handleConfigLoadError = () => {
        this.setState({
            error: true,
        });
    }

    render() {
        if (this.state.error) {
            return <ServicePageConnectionError />;
        }

        if (this.state.loaded) {
            return this.props.children;
        }

        return <LoadingIndicator centered />;
    }
}

ConfigLoader.propTypes = {
    children: PropTypes.node.isRequired,
};
