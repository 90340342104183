import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classnames from 'classnames';
import {
    Button,
    Icon, Tooltip,
} from '../../../shared/components';
import * as routeUtils from '../../../routes/rules';
import PlansSelectorHeader from '../plans-selector/plans-selector-header';

const isActiveLocation = (locationPathname, location) => (locationPathname.indexOf(location) !== -1);

const buttons = [
    {
        title: 'dashboard',
        url: routeUtils.createUrl(routeUtils.paths.dashboard),
        Component: null,
        disabled: false
    },
    {
        title: 'work center',
        url: routeUtils.createUrl(routeUtils.paths.workCenter),
        Component: null,
        disabled: false
    }
];

const NavigationButtons = ({ locationPathname }) => {
    return (
        <>
            {buttons.map((button) => {
                const Component = button.Component || Fragment;

                return (
                    <Component key={button.title}>
                        <Link to={button.url} activeClassName="button-active">
                            <Tooltip
                                className="nav-header-button-tooltip"
                                tooltipTextClassName="nav-header-button-tooltip-content"
                                position={Tooltip.position.bottom}
                                disabled={!button.disabled}
                                text="Coming soon"
                            >
                                <Button className="nav-header-button" disabled={!button.url}>
                                    <div className="hav-header-button-content-container">
                                        <span>{button.title}</span>
                                        {button.Component && (
                                            <Icon name="angle-down" className="nav-header-button-icon" />
                                        )}
                                    </div>
                                </Button>
                            </Tooltip>
                        </Link>
                    </Component>
                );
            })}
            {/* toDo check all variant routes when we must make plane active */}
            <PlansSelectorHeader>
                <div className={classnames({ 'button-active': isActiveLocation(locationPathname, 'plan') })}>
                    <Button className="nav-header-button">
                        <div className="hav-header-button-content-container">
                            <span>plans</span>
                            <Icon name="angle-down" className="nav-header-button-icon" />
                        </div>
                    </Button>
                </div>
            </PlansSelectorHeader>
        </>
    );
};

NavigationButtons.propTypes = {
    locationPathname: PropTypes.string.isRequired,
};

export default NavigationButtons;
