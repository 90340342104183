import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { colors, defaultColor, colorValuesArray } from './config';
import './styles.css';

// eslint-disable-next-line react/prefer-stateless-function
export default class StructureEntity extends Component {
    render() {
        const {
            children,
            color,
            large,
            inactive,
            className: entityClassName,
            ...rest
        } = this.props;
        const classNames = classnames('structure-entity', {
            [`structure-entity-${color}`]: true,
            'structure-entity-inactive': inactive,
            'structure-entity-large': large,
            [entityClassName]: !!entityClassName
        });

        return (
            <div
                className={classNames}
                {...rest}
            >
                {children}
            </div>
        );
    }
}

StructureEntity.defaultProps = {
    children: undefined,
    color: defaultColor,
    large: false,
    inactive: false,
    className: undefined,
};

StructureEntity.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(colorValuesArray),
    large: PropTypes.bool,
    inactive: PropTypes.bool,
    className: PropTypes.string,
};

StructureEntity.rowClassName = 'structure-entity-row';
StructureEntity.colors = colors;
