import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { typeCodes as entityTypesCodes } from '../../utils/entity-type';
import PrimaryEntityCloneService from '../primary-entity-clone-service';
import {
    CriticalSuccessFactorView, StrategyObjectiveView, SegmentView, PlanView, KpiView, TacticActionView
} from './components/entity-view';
import { tabType } from './constants';
import styles from './styles.module.css';

const RightDrawerEntityDetails = ({
    visible, hide, entityDescription, callbackOnEntityChange, handleCloseWorkCenterRightDrawer, activeTab
}) => {
    let EntityViewComponent;
    switch (entityDescription.type) {
        case entityTypesCodes.kpi:
            EntityViewComponent = KpiView;
            break;
        case entityTypesCodes.action:
        case entityTypesCodes.tactic:
            EntityViewComponent = TacticActionView;
            break;
        case entityTypesCodes.plan:
            EntityViewComponent = PlanView;
            break;
        case entityTypesCodes.segment:
            EntityViewComponent = SegmentView;
            break;
        case entityTypesCodes.objective:
        case entityTypesCodes.strategy:
            EntityViewComponent = StrategyObjectiveView;
            break;
        case entityTypesCodes.driver:
            EntityViewComponent = CriticalSuccessFactorView;
            break;
        default:
            EntityViewComponent = PlanView;
    }
    return (
        <div className={classnames(styles.rightDrawerEntityViewContainer, { [styles.visible]: visible })}>
            {(entityDescription.id && visible)
                && (
                    <EntityViewComponent
                        hide={hide}
                        entityDescription={entityDescription}
                        activeTab={activeTab}
                        callbackOnEntityChange={callbackOnEntityChange}
                        handleCloseWorkCenterRightDrawer={handleCloseWorkCenterRightDrawer}
                    />
                )
            }

            <PrimaryEntityCloneService.ServiceComponent
                onCreateSuccess={callbackOnEntityChange}
            />
        </div>
    );
};

RightDrawerEntityDetails.defaultProps = {
    activeTab: tabType.entityDetails,
};

RightDrawerEntityDetails.propTypes = {
    visible: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    entityDescription: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        goal_id: PropTypes.number,
        planTitle: PropTypes.string,
        planSettings: PropTypes.shape({
            action: PropTypes.string,
            driver: PropTypes.string,
            goal: PropTypes.string,
            milestone: PropTypes.string,
            plan: PropTypes.string,
            segment: PropTypes.string,
            strategy_map: PropTypes.string,
            sub_segment: PropTypes.string,
        }),
        planColor: PropTypes.string,
        progress_note_id: PropTypes.oneOfType([
            PropTypes.oneOf([undefined]),
            PropTypes.number,
        ]),
    }).isRequired,
    activeTab: PropTypes.string,
    callbackOnEntityChange: PropTypes.func.isRequired,
    handleCloseWorkCenterRightDrawer: PropTypes.func.isRequired,
};

export default RightDrawerEntityDetails;
