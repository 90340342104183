import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { debounce, isEmpty } from 'lodash';
import {
    DropdownDeprecated,
    LoadingIndicator,
    Input,
    Icon
} from '../../../shared/components';
import * as routeUtils from '../../../routes/rules';
import { recentPlansModel } from './entity';
import { applicationSettingsSelectors } from '../../application';
import { selectPlanCreatePermission } from '../../permissions/selectors';
import SubMenu from './sub-menu';
import './saga';
import '../styles/plan-selector.css';
import { plansListMy } from '../../plans-list-my/entity';

const filteredPlansListAfterSearch = (allPlansList = [], searchValue) =>
    (isEmpty(allPlansList) ? [] : allPlansList.filter(plan => plan.title.toLowerCase().match(searchValue.toLowerCase())));

const getRecentPlanList = (recentPlansList = []) => (isEmpty(recentPlansList) ? [] : recentPlansList);

class PlansSelectorHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSubMenuIndex: null,
            searchValue: '',
        };
        this.refInputBase = React.createRef();
        this.refDropdown = React.createRef();
        this.setVisibleOnMouseEventDebounce = debounce(this.setVisibleOnMouseEvent, 100);
    }

    componentDidMount() {
        this.props.fetchRecentPlans({ limit: 5 });
    }

    handleDropdownHide = () => {
        this.setState({ activeSubMenuIndex: null, searchValue: '' });
        this.refDropdown.current.hide();
    };

    setVisibleOnClick = (index) => {
        this.setState({ activeSubMenuIndex: index });
    };

    setVisibleOnMouseEvent = (index, status) => {
        this.setState({ activeSubMenuIndex: status ? index : null });
    };

    onChangeInputSearch = (e) => {
        e.preventDefault();
        const { value } = e.target;
        this.setState({ searchValue: value });
    }

    handlePostfixClick = () => {
        this.setState({ searchValue: '' });
        this.refInputBase.current.input.focus();
    }

    render() {
        const {
            fetchingRecentPlans, fetchingAllPlansList, isTouchDevice, recentPlansList, allPlansList, canCreatePlan
        } = this.props;
        const { activeSubMenuIndex, searchValue } = this.state;

        const plansList = searchValue ? filteredPlansListAfterSearch(allPlansList, searchValue) : getRecentPlanList(recentPlansList);

        return (
            <DropdownDeprecated
                ref={this.refDropdown}
                containerClassName="nav-header-plan-selector"
                triggerClassName="nav-header-plan-selector-trigger"
                triggerActiveClassName="active"
                trigger={() => (
                    this.props.children
                )}
            >
                <div className="dropdown-content">
                    <div className="dropdown-content-item">
                        <Input
                            ref={this.refInputBase}
                            autoFocus
                            autoComplete="off"
                            placeholder="Search"
                            name="plans-input"
                            value={this.state.searchValue}
                            onChange={this.onChangeInputSearch}
                            formGroupClassName="plan-selector-input-form-group"
                            containerClassName="plan-selector-input-container"
                            className="plan-selector-input"
                            postfix={() => (<Icon type="regular" name="times" onClick={this.handlePostfixClick} />)}
                            prefix={() => (<Icon type="regular" name="search" />)}
                        />
                    </div>
                    <div className="dropdown-content-item">
                        <span className="title-text-plans-list">{searchValue !== '' ? 'search result' : 'recent'}</span>
                    </div>
                    {(fetchingRecentPlans && fetchingAllPlansList)
                        ? <LoadingIndicator size="small" centered />
                        : plansList.slice(0, 5).map((plan, index) => {
                            return (
                                <div key={plan.id} className="dropdown-content-item">
                                    <SubMenu
                                        visible={activeSubMenuIndex === index}
                                        isTouchDevice={isTouchDevice}
                                        planId={plan.id}
                                        handleDropdownHide={this.handleDropdownHide}
                                        onClick={() => this.setVisibleOnClick(index)}
                                        onMouseOver={() => this.setVisibleOnMouseEventDebounce(index, true)}
                                        onMouseLeave={() => this.setVisibleOnMouseEventDebounce(index, false)}
                                        onFocus={() => this.setVisibleOnMouseEventDebounce(index, true)}
                                        onBlur={() => this.setVisibleOnMouseEventDebounce(index, false)}
                                    >
                                        {plan.title}
                                    </SubMenu>
                                </div>
                            );
                        })}
                    <div className="dropdown-content-bottom-link-container">
                        <Link
                            to={routeUtils.createUrl(routeUtils.paths.planList)}
                            className="dropdown-content-item"
                            onClick={this.handleDropdownHide}
                        >
                            View All Plans
                        </Link>
                        {canCreatePlan && (
                            <Link
                                to={routeUtils.createUrl(routeUtils.paths.planCreate)}
                                className="dropdown-content-item"
                                onClick={this.handleDropdownHide}
                            >
                                Create New Plan
                            </Link>
                        )}
                    </div>
                </div>
            </DropdownDeprecated>
        );
    }
}


PlansSelectorHeader.defaultProps = {
    fetchingAllPlansList: true,
    isTouchDevice: false,
    canCreatePlan: null,
    allPlansList: undefined
};

PlansSelectorHeader.propTypes = {
    children: PropTypes.node.isRequired,
    isTouchDevice: PropTypes.bool,
    allPlansList: PropTypes.array,
    fetchingAllPlansList: PropTypes.bool,
    recentPlansList: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    fetchingRecentPlans: PropTypes.bool.isRequired,
    fetchRecentPlans: PropTypes.func.isRequired,
    canCreatePlan: PropTypes.bool,
};

const mapStateToProps = state => ({
    isTouchDevice: applicationSettingsSelectors.isTouchDeviceSelector(),
    allPlansList: plansListMy.selectors.getRaw(state).data,
    fetchingAllPlansList: plansListMy.selectors.getFetchingState(state),
    recentPlansList: recentPlansModel.selectors.getRaw(state),
    fetchingRecentPlans: recentPlansModel.selectors.getFetchingState(state),
    canCreatePlan: selectPlanCreatePermission(state),
});

const mapDispatchToProps = {
    fetchRecentPlans: recentPlansModel.actions.fetch.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansSelectorHeader);
