import { get } from 'lodash';
import { periodTargetAttributes } from '../../constants';
import { roundDecimal } from '../../../../shared/utils/math';
// import { frequencyToDateFormatters } from '../../utils';

// export function getRange(startDate, endDate, frequency) {
//     const result = [];
//     const measurement = frequencyToMeasurement[frequency];
//     let currentDate = startDate;

//     while (moment(currentDate).isBefore(endDate)) {
//         result.push(currentDate);
//         currentDate = moment(currentDate)
//             .add(1, measurement);
//     }

//     return result;
// }

// export function formatRange(range, frequency) {
//     return range.map(item => frequencyToDateFormatters[frequency](item));
// }

// export function getFormattedRange(startDate, endDate, frequency) {
//     const range = getRange(startDate, endDate, frequency);
//     return formatRange(range, frequency);
// }

export function formatPeriodTargetValue(val) {
    if (typeof val !== 'number') {
        return null;
    }
    return roundDecimal(val);
}

export function formatPeriodTargetsToApi(values = []) {
    return values.map(item => ({
        [periodTargetAttributes.period_start]: get(item, periodTargetAttributes.period_start),
        [periodTargetAttributes.target_value]: get(item, periodTargetAttributes.target_value),
    }));
}
