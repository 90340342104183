import { Collection, Model } from '../../../shared/entities-v2';

const apiUrl = '/export/goals/versions';
const nameCollection = 'settings-account-export-collection';
const nameModel = 'settings-account-export-model';

export const settingsAccountExportCollection = new Collection({
    apiUrl,
    name: nameCollection,
});

export const settingsAccountExportModel = new Model({
    apiUrl,
    name: nameModel,
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onCreateSuccess]: settingsAccountExportCollection.actions.push,
            [Model.events.onRemoveSuccess]: settingsAccountExportCollection.actions.remove,
        }
    }
});
