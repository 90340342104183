import { createSelector } from 'reselect';
import { selectUserId } from '../../../modules/user/selectors';
import { typeCodes } from '../../utils/entity-type';
import { entityState } from '../../utils/entity';
import { name } from './primary-entity-create-constants';

const getRoot = state => state[name];

export const selectParentData = createSelector(getRoot, branchState => branchState.parentData);
const selectParams = createSelector(getRoot, branchState => branchState.params);

export const selectInitialValues = createSelector(
    [selectParentData, selectParams, selectUserId],
    (parentData, params, userId) => {
        const { id, typeCode } = params;

        const defaultInitialValues = {
            goal_id: params.planId,
            author_id: userId,
            owner_id: userId,
            state: entityState.draft,
        };

        switch (typeCode) {
            case typeCodes.segment:
                return {
                    ...defaultInitialValues,
                    strategy_map_id: id,
                };
            case typeCodes.action:
            case typeCodes.tactic:
            case typeCodes.objective:
            case typeCodes.strategy:
            default:
                return defaultInitialValues;
        }
    }
);
