import urlJoin from 'url-join';
import u from 'updeep';
import { Collection } from '../../../../shared/entities-v2';

class PlanMemberCollection extends Collection {
    format = params => u({ id: undefined }, params)
}

const planMemberCollection = new PlanMemberCollection({
    name: 'plan-member-collection',
    getApiUrl({ id }) {
        return urlJoin('goals', id.toString(), 'members_pagination');
    },
});

export default planMemberCollection;
