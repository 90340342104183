import { get } from 'lodash';
import { entityPermissionNames } from './constants';

function isOwner(userId, entity) {
    return (
        userId === get(entity, 'author_id')
        || userId === get(entity, 'owner_id')
    );
}

export default function createPermissionResolver(permissions, userId) {
    return (entityType, entity) => {
        const entityPermissions = get(permissions, entityType);

        if (!entity) {
            return entityPermissions;
        }

        const isOwnEntity = isOwner(userId, entity);

        const canUpdateEntityType = get(entityPermissions, entityPermissionNames.canUpdate, false);
        const canUpdateOwnEntityType = get(entityPermissions, entityPermissionNames.canUpdateOwn, false);

        const canDestroyEntityType = get(entityPermissions, entityPermissionNames.canDestroy, false);
        const canDestroyOwnEntityType = get(entityPermissions, entityPermissionNames.canDestroyOwn, false);

        const canUpdateOwn = canUpdateOwnEntityType && isOwnEntity;
        const canDestroy = canDestroyEntityType || (canDestroyOwnEntityType && isOwnEntity);
        const canUpdate = canUpdateEntityType || canUpdateOwn;

        return {
            ...entityPermissions,
            [entityPermissionNames.canUpdate]: canUpdate,
            [entityPermissionNames.canDestroy]: canDestroy,
            isOwnEntity
        };
    };
}
