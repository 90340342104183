import { Model } from '../../../shared/entities-v2';

const constants = Object.freeze({
    branchName: 'goalmap-milestones-kpi-widgets',
});


function getApiUrl() {
    return 'milestones/microwidgets';
}

const widgetModel = new Model({
    name: constants.branchName,
    getApiUrl,
});

export default widgetModel;
