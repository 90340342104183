export const strategies = Object.freeze({
    active: 'active',
    draft: 'draft',
});

export const params = Object.freeze({
    page: 'page',
    entityId: 'entityId',
    entityType: 'entityType'
});

export const formName = 'milestone-create';
