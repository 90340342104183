import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UrlDataBinder } from '../../modules';
import InputBase from './base';

export default class InputUrlBinded extends Component {
    handleParseQuery = (value) => {
        if (value) {
            this.props.onChange({ target: { value } });
        }
    };

    render() {
        const { queryParameterName, value } = this.props;

        return (
            <UrlDataBinder
                value={value}
                onInitialValueParsed={this.handleParseQuery}
                parameterName={queryParameterName}
            >
                {bindedValue => <InputBase {...this.props} value={bindedValue} />}
            </UrlDataBinder>
        );
    }
}

InputUrlBinded.defaultProps = {
    onChange: () => {}
};

InputUrlBinded.propTypes = {
    queryParameterName: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func
};
