import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Prompt from './component';

export default class PromptProvider extends Component {
    state = {
        visible: false,
        params: this.props.params,
        description: undefined,
        title: undefined,
        asyncFetching: false,
    };

    show = (params, dictionary = {}, asyncDictionary) => {
        if (!asyncDictionary) {
            return this.setState({
                visible: true,
                params,
                description: dictionary.description,
                title: dictionary.title,
            });
        }

        return this.asyncGetDictionary(params, asyncDictionary);
    }

    hide = () => {
        this.setState({
            visible: false,
            params: null,
            description: undefined,
            title: undefined,
        });
    }

    handleAccept = () => {
        const { params } = this.state;
        this.props.onAccept(params);
    }

    asyncGetDictionary = async (params, async) => {
        this.setState({
            visible: true,
            asyncFetching: true,
            params,
        });

        try {
            const result = await async.fetch(params || async.params);
            const parseResult = await async.parse(result);
            this.asyncGetDictionarySuccess(parseResult);
        } catch (error) {
            this.asyncGetDictionaryError(error);
        }
    }

    asyncGetDictionarySuccess = ({ title, description }) => {
        this.setState({
            visible: true,
            asyncFetching: false,
            title,
            description,
        });
    }

    asyncGetDictionaryError = () => {
        this.setState({
            visible: true,
            asyncFetching: false,
        });
    }

    render() {
        const {
            params, children, description, title, ...rest
        } = this.props;

        return (
            <Fragment>
                {children({
                    show: this.show,
                    hide: this.hide,
                })}

                <Prompt
                    {...rest}
                    title={title || this.state.title}
                    description={description || this.state.description}
                    visible={this.state.visible}
                    hide={this.hide}
                    onAccept={this.handleAccept}
                    fetching={this.state.asyncFetching}
                />
            </Fragment>
        );
    }
}

PromptProvider.types = Prompt.types;

PromptProvider.defaultProps = {
    params: null,
    description: undefined,
    title: undefined,
    onAccept() {},
    asyncDictionary: null,
    children() {},
};

PromptProvider.propTypes = {
    children: PropTypes.func,
    onAccept: PropTypes.func,
    params: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    description: PropTypes.string,
    title: PropTypes.string,
    asyncDictionary: PropTypes.shape({
        fetch: PropTypes.func,
        parse: PropTypes.func,
        params: PropTypes.any,
    }),
};
