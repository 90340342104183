import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { SelectOptionEntity } from '../../../shared/components';
import { Select } from '../../../shared/modules';
import getLinks from './api';

import './styles.css';

export default function RelatedLinkSelect({ params, className, ...rest }) {
    return (
        <Select
            className={cx('related-link-select', {
                [className]: !!className,
            })}
            loadOptions={search => getLinks(params, search)}
            optionRenderer={entity => (
                <SelectOptionEntity
                    entity_type={entity.type_code}
                    title={entity.title}
                    tag_name={entity.tag_name}
                    {...entity}
                    progress_status={null}
                />
            )}
            labelKey="title"
            clearable={false}
            {...rest}
        />
    );
}

RelatedLinkSelect.defaultProps = {
    params: {},
    className: undefined,
};

RelatedLinkSelect.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
        primary_link_type: PropTypes.string,
        exclude: PropTypes.string,
    }),
    className: PropTypes.string,
};
