import u from 'updeep';
import { createReducer } from 'redux-act';

const initialState = {
    modalVisible: false,
    uploadModalVisible: false,
    keepModalVisible: false,
    loading: false,
    info: {},
    error: null,
};

export default function createReducerFn({
    request,
    success,
    error,
    clearError,
    toggle,
    setVisibilityState,
    setKeepModalVisible,
}) {
    return createReducer({
        [toggle]: state => u(
            {
                modalVisible: !state.modalVisible,
                uploadModalVisible: !state.uploadModalVisible,
            },
            state
        ),
        [setVisibilityState]: (state, isVisible) => u(
            {
                modalVisible: isVisible,
                uploadModalVisible: isVisible,
            },
            state
        ),
        [request]: state => u(
            {
                loading: true,
                error: null,
            },
            state
        ),
        [success]: state => u(
            {
                loading: false,
                modalVisible: !!(state.modalVisible && state.keepModalVisible),
            },
            state
        ),
        [error]: (state, action) => {
            return u(
                {
                    loading: false,
                    error: action.response,
                    uploadModalVisible: !state.uploadModalVisible,
                    info: {
                        title: 'Error',
                        content: 'Something went wrong ):',
                        className: 'error'
                    }
                },
                state
            );
        },
        [clearError]: (state) => {
            return u(
                {
                    error: null,
                },
                state
            );
        },
        [setKeepModalVisible]: (state, action) => u(
            {
                keepModalVisible: action.state,
            },
            state,
        ),
        '@@router/LOCATION_CHANGE': state => u(
            { modalVisible: false },
            state
        ),
    }, initialState);
}

export function createSelector(name) {
    return state => state[name];
}
