import appConfig from '../../../config';

export const apiKeyEndPoint = '/public_api';
export const getApiDocumentationUrl = () => `${appConfig.apiHost()}/api/public/apidoc/#api-Overview-OverviewIntroduction`;

export const publicApiTexts = {
    description: 'Use this key in your application to make APl calls to MPOWR Envision.',
    notification: 'This API Key should be stored securely and kept private. Never share your API Key with an untrusted third party.',
    promtTitle: 'Are you sure you want to generate a new API Key?',
    promtDescription: 'Any existing API integrations will stop working, and you will not be able to recover your current API Key.',
    successCopyApiKey: 'The API Key has been copied.',
    successGenerateApiKey: 'The new API Key has been successfully generated. The old key is no longer valid.',
};
