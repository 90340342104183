/* eslint-disable no-constant-condition */
import { eventChannel } from 'redux-saga';
import {
    call,
    put,
    take,
    fork,
    race,
    all,
    select
} from 'redux-saga/effects';
import tabex from 'tabex';
import { getAccessToken } from '../../../source/app/modules/authentication/selectors';
import Socket from './socket';
import actions from './actions';
import initSync, { events as syncEvents, actionTypes, channelName as syncChannelName } from './tab-sync-channel';
import visibilityChannel from './visibility-channel';
import * as notifications from './notifications-saga';
import { transportStates } from '../config';
import { getMasterTabState } from './selectors';

function initWebsocket({ socket, token }) {
    return eventChannel((emitter) => {
        const onInitializeStart = () => emitter({
            type: actions.transport.setState.getType(),
            payload: {
                state: transportStates.pending,
            },
        });

        const handleNotification = (message) => {
            emitter({
                type: actions.transport.receive.getType(),
                payload: message,
            });
        };

        const handleClose = () => emitter({
            type: actions.transport.setState.getType(),
            payload: {
                state: transportStates.closed,
            },
        });

        const handleInitialize = () => {
            emitter({
                type: actions.transport.setState.getType(),
                payload: {
                    state: transportStates.opened,
                },
            });
        };

        const handleInitializeError = error => emitter({
            type: actions.transport.setState.getType(),
            payload: {
                state: transportStates.error,
                error,
            },
        });

        onInitializeStart();

        socket.initialize({
            token,
            onInitialize: handleInitialize,
            onInitializeError: handleInitializeError,
            onNotification: handleNotification,
            onClose: handleClose,
        });

        return () => socket.close();
    });
}

function* sendMessage(socket) {
    while (true) {
        const data = yield take(actions.transport.send.getType());
        socket.send(data.payload.type, data.payload.data);
    }
}

function* watchChannel(channel) {
    while (true) {
        const action = yield take(channel);
        yield put(action);
    }
}

function* openWSChannel(socket) {
    // TO DO: next line as a configurable selector
    const token = yield select(getAccessToken);
    const channel = yield call(initWebsocket, { socket, token });

    yield race({
        task: all([
            call(watchChannel, channel),
            call(sendMessage, socket),
        ]),
    });
}

function* watchSyncChannel({ channel, socket }) {
    while (true) {
        const action = yield take(channel);

        if (action.type === actionTypes.master) {
            yield put({
                type: actions.app.setMasterTabState.getType(),
                payload: {
                    isMasterTab: true,
                }
            });

            yield fork(openWSChannel, socket);
        }

        if (action.type === actionTypes.secondary && !!socket.getInstance()) {
            yield put({
                type: actions.app.setMasterTabState.getType(),
                payload: {
                    isMasterTab: false,
                }
            });

            channel.close();
        }

        if (action.type === syncEvents.notificationRecieve) {
            yield put({
                type: actions.sync.receiveAdd.getType(),
                payload: action.payload,
            });
        }

        if (action.type === syncEvents.notificationRemove) {
            yield put({
                type: actions.sync.receiveRemove.getType(),
                payload: action.payload,
            });
        }
    }
}

function* watchRemove(sync) {
    while (true) {
        const action = yield take(actions.sync.sendRemove.getType());
        sync.emit(syncChannelName, {
            type: syncEvents.notificationRemove,
            payload: action.payload
        });
    }
}

function* watchTransportReceive({
    parse,
    sync,
    shouldTriggerNotificationSelector,
}) {
    while (true) {
        const action = yield take(actions.transport.receive.getType());
        const isMasterTab = yield select(getMasterTabState);
        const shouldTriggerNotification = yield select(shouldTriggerNotificationSelector);

        if (shouldTriggerNotification) {
            yield fork(notifications.create, parse(action.payload));
        }

        if (isMasterTab) {
            sync.emit(syncChannelName, {
                type: syncEvents.notificationRecieve,
                payload: action.payload
            });
        }
    }
}

function* startSync(options) {
    const sync = tabex.client();
    const socket = new Socket({
        url: options.url,
    });

    // const shouldShowNotification = yield select(options.shouldTriggerNotification);
    // const shouldRequestPermissions = yield select(options.shouldRequestNotificationPermissions);

    // if (shouldRequestPermissions) {
    //     yield call(notifications.checkPermissions);
    // }

    const syncChannel = yield call(initSync, sync);
    const visChannel = yield call(visibilityChannel);

    yield race({
        task: all([
            call(watchSyncChannel, { channel: syncChannel, socket }),
            call(watchChannel, visChannel),
            call(watchTransportReceive, {
                parse: options.parse,
                sync,
                shouldTriggerNotificationSelector: options.shouldTriggerNotification,
            }),
            call(watchRemove, sync),
        ]),
    });
}

export default function* wsSagas() {
    const action = yield take(actions.initialize.getType());
    yield fork(startSync, action.payload);
}
