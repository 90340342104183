import PropTypes from 'prop-types';
import React from 'react';

export default function Pluralize({ one, other, value }) {
    if (value === 1) {
        return (
            <span>{one}</span>
        );
    }

    return (
        <span>
            {other}
        </span>
    );
}

Pluralize.defaultProps = {
    one: undefined,
    other: undefined,
};

Pluralize.propTypes = {
    one: PropTypes.string,
    other: PropTypes.string,
    value: PropTypes.number.isRequired,
};
