import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field } from 'redux-form';
import { Select } from '../../../shared/modules';
import { getFormattedPeriod, getFrequencyType } from '../../../shared/utils/date';
import { formatValue } from '../utils';

const now = moment();

function createOptions(frequency, startOfPeriod) {
    const past = 50;
    const future = 50;
    const type = getFrequencyType(frequency);

    const period = startOfPeriod ? 'startOf' : 'endOf';

    const pastOptions = [...new Array(past)]
        .map((item, index) => moment(now).subtract(index, type)[period](type))
        .reverse();

    const futureOptions = [...new Array(future)]
        .map((item, index) => moment(now).add(index, type)[period](type));

    futureOptions.shift();

    return [
        ...pastOptions,
        ...futureOptions,
    ].map(item => ({
        value: formatValue(item),
        label: getFormattedPeriod(frequency, item),
    }));
}

export default function PeriodSelector({
    name,
    label,
    frequency,
    placeholder,
    startOfPeriod,
    disabled,
    options,
    onChange
}) {
    options = options.length ? options : createOptions(frequency, startOfPeriod);

    const extraOptions = {};
    if (onChange) {
        extraOptions.onChange = onChange;
    }

    return (
        <Field
            name={name}
            disabled={disabled}
            component={Select}
            props={{
                label,
                placeholder: placeholder || label,
                options,
                labelKey: 'label',
                valueKey: 'value',
                clearable: false,
                alwaysRenderLabel: true,
                ...extraOptions
            }}
            format={formatValue}
        />
    );
}

PeriodSelector.defaultProps = {
    name: 'target_at',
    label: 'Select Target Period *',
    placeholder: undefined,
    startOfPeriod: false,
    disabled: false,
    options: [],
    onChange: undefined,
    frequency: undefined,
};

PeriodSelector.propTypes = {
    frequency: PropTypes.string,
    label: PropTypes.node,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    startOfPeriod: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    onChange: PropTypes.func
};
