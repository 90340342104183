export const stateBranchName = 'subscription-limit-collection';
const infiniteValue = -1;

export function isInfinite(total) {
    return total === infiniteValue;
}

export const fields = {
    type: 'type',
    value: 'value',
    used: 'used',
    maxValue: 'maxValue',
    key: 'key',
    defaultValue: 'defaultValue'
};

export const attributes = {
    type: 'type',
    value: 'value',
    used: 'used',
    maxValue: 'maxValue',
    isMaxValue: 'isMaxValue',
    canCreate: 'canCreate',
    isUnlimitedValue: 'isUnlimitedValue',
    defaultValue: 'defaultValue',
};

const limitTypes = {
    counter: 'counter_limit',
    size: 'size_limit',
    availability: 'availability_limit',
    period: 'period_limit',
    typeArray: 'type_array',
};

export const features = {
    users: 'env-prd-user',
    goals: 'env-prd-plans',
    attachment: 'env-prd-size',
    driver: 'env-prd-csf',
    history: 'env-prd-audit',
    archive: 'env-prd-archive',
    draft: 'env-prd-draft',
    move: 'env-prd-move',
    gui_customization: 'env-prd-custom',
    goal_export: 'env-prd-export',
    integrations: 'env-prd-apps',
    roles_customization: 'env-prd-roles',
    milestones: 'env-prd-kpi',
    milestones_external_link: 'env-prd-external',
    milestones_kpi_types: 'env-prd-kpi-types',
    milestones_kpi_adv_calc: 'env-prd-kpi-ac',
    digest_reports: 'env-prd-digest',
    charts: 'env-prd-kpi-charts',
    limited_participant: 'env-prd-roles-limited-participant',
    work_center_filters: 'env-prd-work-center-filters',
    work_center_timeline_view: 'env-prd-work-center-timeline-view',
    zapier_integration: 'env-prd-zapier',
    storage_integration: 'env-prd-integrations-storage',
    communication_integration: 'env-prd-integrations-communication',
    public_api: 'env-prd-public-api',
    user_session_timeout: 'env-prd-user-session-timeout',
    multi_factor_authentication: 'env-prd-multi-factor-authentication',
    sso_saml: 'env-prd-sso-saml',
};

export const periodValues = {
    daily: 'Daily',
    weekly: 'Weekly',
};

const limitTypesByFeature = {
    [features.users]: limitTypes.counter,
    [features.goals]: limitTypes.counter,
    [features.attachment]: limitTypes.size,
    [features.driver]: limitTypes.availability,
    [features.history]: limitTypes.availability,
    [features.archive]: limitTypes.availability,
    [features.draft]: limitTypes.availability,
    [features.move]: limitTypes.availability,
    [features.gui_customization]: limitTypes.availability,
    [features.goal_export]: limitTypes.availability,
    [features.integrations]: limitTypes.counter,
    [features.roles_customization]: limitTypes.availability,
    [features.milestones]: limitTypes.availability,
    [features.milestones_external_link]: limitTypes.availability,
    [features.milestones_kpi_types]: limitTypes.typeArray,
    [features.digest_reports]: limitTypes.period,
    [features.charts]: limitTypes.availability,
    [features.user_session_timeout]: limitTypes.availability,
};

function getCanCreate(branch) {
    const type = limitTypesByFeature[branch.key];
    const value = branch.fieldCounters[fields.value];
    const used = branch.fieldCounters[fields.used];

    if (type === limitTypes.availability) {
        return value;
    }

    if (type === limitTypes.counter) {
        if (isInfinite(value)) {
            return true;
        }

        return (used < value);
    }

    return true;
}

export const empty = {};

export function getLimit(items = [], name) {
    const limit = items.find(item => item.key === name);

    if (!limit) {
        return empty;
    }

    return {
        [attributes.value]: Number(limit.fieldCounters[fields.value]) === -1 ? Infinity : limit.fieldCounters[fields.value],
        [attributes.used]: limit.fieldCounters[fields.used],
        [attributes.isMaxValue]: limit.fieldCounters[fields.maxValue],
        [attributes.canCreate]: getCanCreate(limit),
        [attributes.isUnlimitedValue]: isInfinite(limit.fieldCounters[fields.value]),
        [attributes.defaultValue]: limit[fields.defaultValue],
    };
}
