import React from 'react';
import { connect } from 'react-redux';
import { Icon, Text } from '../../../../shared/components';
import contactSalesMapper, { propTypes } from '../../../../modules/subscription/contact-sales-mapper';
import createMailto from '../../../../modules/subscription/create-mailto';

const rootClassName = 'structure-entity-contact-sales';

function ContactSalesMessage({ user, account }) {
    return (
        <Text
            component="div"
            className={rootClassName}
            styleType="muted"
        >
            <Icon
                name="poll-h"
                type={Icon.types.solid}
                className={`${rootClassName}-icon`}
            />
            Not available.{' '}
            <a href={createMailto({ user, account })}>Contact Support</a>{' '}
            to upgrade your plan
        </Text>
    );
}

ContactSalesMessage.propTypes = propTypes;

export default connect(contactSalesMapper)(ContactSalesMessage);
