import { find } from 'lodash';
import { createSelector } from 'reselect';
import { selectUserId } from '../../../modules/user/selectors';
import { roles } from './team-section-constants';

export const isRestricted = (userId, teamUsers) => {
    return find(teamUsers, { userId })?.teamRoleId !== roles.leader;
};

export const selectIsRestricted = createSelector(
    [selectUserId, (state, teamUsers) => teamUsers],
    isRestricted
);
