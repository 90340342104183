import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { colorVariants } from '../../../shared/prop-types/theme';
import { sizes } from '../../styles/constants';
import './styles.css';

export default function Badge({
    children, className, inverse, styleType, size, theme
}) {
    return (
        <span
            className={classnames('badge', {
                [className]: className,
                [`badge-type-${styleType}`]: true,
                [`badge-size-${size}`]: true,
                'badge-inverse': inverse,
                [`badge-theme-${theme}`]: theme
            })}
        >
            {children}
        </span>
    );
}

function formatCounter(num) {
    if (num > 99) {
        return '99+';
    }

    return num;
}

Badge.formatCounter = formatCounter;
Badge.sizes = sizes;

Badge.theme = {
    ROUND_ACCENT: 'round-accent'
};

Badge.colors = colorVariants;

Badge.defaultProps = {
    styleType: 'default',
    size: sizes.medium,
    children: undefined,
    className: undefined,
    theme: undefined,
    inverse: false,
};

Badge.propTypes = {
    styleType: PropTypes.oneOf(colorVariants),
    className: PropTypes.string,
    children: PropTypes.node,
    inverse: PropTypes.bool,
    size: PropTypes.string,
    theme: PropTypes.string
};
