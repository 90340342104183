export const userStatuses = Object.freeze({
    pendingConfirmation: 1,
    activated: 2,
    deactivated: 3,
    pending: 4,
});

export const updateUserStatus = 'updateStatus';

export const filterUserStatuses = Object.freeze({
    active: userStatuses.activated.toString(),
    deactivated: userStatuses.deactivated.toString(),
    pending: userStatuses.pending.toString(),
    pendingConfirmation: userStatuses.pendingConfirmation.toString(),
});

export const userStatusOptions = [
    { id: filterUserStatuses.active, display_name: 'Active Users' },
    { id: filterUserStatuses.deactivated, display_name: 'Inactive Users' },
    { id: filterUserStatuses.pending, display_name: 'Pending Invitation' },
    { id: filterUserStatuses.pendingConfirmation, display_name: 'Pending Confirmation' }
];

export const formName = 'settings-users-create';
export const detailsEntityName = 'settings-users-details';
export const userMFAResetSuccessText = 'Two-factor authentication settings have been reset for the user';
