import { get } from 'lodash';
import { defaultTitles } from '../../utils/entity';
import { getStringTypeByCode } from '../../utils/entity-type';

export const getEntityTypeTitle = (planSettings, typeCode) => {
    if (planSettings) {
        const stringType = getStringTypeByCode(typeCode);
        return get(planSettings, stringType);
    }

    return get(defaultTitles, typeCode);
};
