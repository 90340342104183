import { createAction } from 'redux-act';
import u from 'updeep';
import { omit } from 'lodash';
import config from '../../../config';
import { Collection } from '../../../shared/entities-v2';

const apiUrl = '/desktop_notifications';
const collectionName = 'notifications-header-collection';

export const counterActions = {
    increment: createAction(`${collectionName}/counter/INCREMENT`),
    decrement: createAction(`${collectionName}/counter/DECREMENT`),
    update: createAction(`${collectionName}/counter/UPDATE`),
};

export const listActions = {
    markAllRead: createAction(`${collectionName}/list/MARK_ALL_READ`),
};

function handleCounterIncrement(state) {
    return u.updateIn(
        ['raw', 'total_unread'],
        state.raw.total_unread + 1,
        state
    );
}

function handleCounterDecrement(state) {
    return u.updateIn(
        ['raw', 'total_unread'],
        state.raw.total_unread - 1,
        state
    );
}

function handleCounterUpdate(state, action) {
    return u.updateIn(
        ['raw', 'total_unread'],
        action.counter,
        state
    );
}

function handleAdd(state) {
    if (state.items.length >= config.notifications.headerListMaxLength) {
        const newList = [...state.items];
        newList.pop();

        return u.updateIn(
            ['items'],
            newList,
            state,
        );
    }

    return state;
}

export function handleMarkAllRead(state) {
    const items = [...state.items].map(item => u({ is_read: true }, item));
    return u({ items }, state);
}

class NotificationCollection extends Collection {
    format = params => omit(params, ['silent'])
}

export const notificationsHeaderCollection = new NotificationCollection({
    apiUrl,
    name: collectionName,
    additionalArtifacts: {
        [Collection.additionalArtifacts.reducers]: {
            [counterActions.increment]: handleCounterIncrement,
            [counterActions.decrement]: handleCounterDecrement,
            [counterActions.update]: handleCounterUpdate,
            [listActions.markAllRead]: handleMarkAllRead,
            onAdd: handleAdd,
        }
    }
});
