import {
    partialRight, sortBy, forEach, flow, map, find
} from 'lodash';
import u from 'updeep';

const sortByPosition = partialRight(sortBy, ['position']);

export function createWidgetMap(widgets, columns) {
    const widgetMap = map([...new Array(columns)], () => ([]));
    const remaining = [];

    const mapWidgetsToColumns = partialRight(forEach, (widget) => {
        const columnIndex = widget.column - 1;
        const column = widgetMap[columnIndex];

        if (column) {
            column.push(widget.id);
        } else {
            remaining.push(widget.id);
        }
    });

    flow(sortByPosition, mapWidgetsToColumns)(widgets);

    return {
        widgetMap,
        remaining,
    };
}

export function insertRemainingWidgets(widgetMap, remaining) {
    const insertColumn = widgetMap[widgetMap.length - 1];

    forEach(remaining, (id) => {
        insertColumn.push(id);
    });
}

export function widgetMapToState(widgets, widgetMap) {
    const result = [];

    forEach(widgetMap, (column, columnIndex) => {
        forEach(column, (id, index) => {
            const widget = find(widgets, { id });
            const nextWidgetState = u({
                column: columnIndex + 1,
                position: index + 1
            }, widget);
            result.push(nextWidgetState);
        });
    });

    return result;
}
