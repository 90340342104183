import React from 'react';
import PropTypes from 'prop-types';
import { TableView } from '../../components';
import TeamSectionItem from './team-section-item';
import { columns } from './team-section-constants';
import styles from './team-section.module.css';

const TeamSectionList = ({
    items, onTeamRemove, sortValue, setSortValue, onUpdateModalShow, userId, useRestrictions
}) => {
    const isCollectionFetching = false;
    const noResults = false;

    const renderRow = (data) => {
        return (
            <TeamSectionItem
                key={data.id}
                onRemove={onTeamRemove}
                onEdit={onUpdateModalShow}
                userId={userId}
                useRestrictions={useRestrictions}
                {...data}
            />
        );
    };

    return (
        <TableView.WithSort
            sorts={sortValue}
            onSortChange={(_, str) => setSortValue(str)}
            allowedTypes={[TableView.WithSort.direction.ASC, TableView.WithSort.direction.DESC]}
        >
            {({ toggleSort, sorts }) => (
                <TableView className={styles.teamSectionList}>
                    <TableView.Header>
                        {columns.map(column => (
                            <TableView.HeaderCell
                                key={column.key}
                                align={column.align}
                                className={column.className}
                                sortable={column.sortable}
                                onClick={column.sortable
                                    ? () => toggleSort(column.sortField)
                                    : undefined}
                                sortDirection={column.sortable
                                    ? sorts[column.sortField]
                                    : undefined}
                            >
                                {column.label}
                            </TableView.HeaderCell>
                        ))}
                    </TableView.Header>
                    {!isCollectionFetching && !noResults && (
                        <TableView.Body>
                            {items.map(renderRow)}
                        </TableView.Body>
                    )}
                </TableView>
            )}
        </TableView.WithSort>

    );
};

TeamSectionList.propTypes = {
    items: PropTypes.array.isRequired,
    onTeamRemove: PropTypes.func.isRequired,
    onUpdateModalShow: PropTypes.func.isRequired,
    sortValue: PropTypes.string.isRequired,
    setSortValue: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    useRestrictions: PropTypes.bool.isRequired,
};

export default TeamSectionList;
