import { createReducer } from 'redux-act';
import u from 'updeep';
import { name as reducerName, updatingState } from './service-worker-constants';
import actions from './service-worker-actions';

const initialState = {
    updatingState: updatingState.default,
    lastUpdateTime: null,
    reloadAfterNavigate: false,
    backgroundUpdating: false
};

function handleSetUpdatingState(state, { value, time = null, backgroundUpdating }) {
    const newState = {
        updatingState: value,
        lastUpdateTime: time,
        backgroundUpdating
    };

    return u(newState, state);
}

function handleSetReloadAfterNavigate(state, reload) {
    const newState = {
        reloadAfterNavigate: reload
    };

    return u(newState, state);
}

const serviceWorkerReducer = createReducer({
    [actions.setUpdatingState]: handleSetUpdatingState,
    [actions.setReloadAfterNavigate]: handleSetReloadAfterNavigate,
}, initialState);

serviceWorkerReducer.path = reducerName;

export default serviceWorkerReducer;
