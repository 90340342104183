import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import u from 'updeep';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classnames from 'classnames';
import {
    DropdownDeprecated, Icon, Badge, Text
} from '../../../shared/components';
import NotificationsFactory from '../../../shared/components/notifications/items/notifications-factory';
import config from '../../../config';
import { notificationModel } from '../../notifications/entity';
import { getUnreadCount, getDefaultParams } from './selectors';
import { notificationsHeaderCollection } from './entity';

import './saga';
import './styles.css';

class NotificationsHeader extends Component {
    componentDidMount() {
        this.fetch();
    }

    fetch = (opts = {}) => {
        const { defaultParams, fetch } = this.props;

        fetch(u(opts, defaultParams));
    }

    hide = () => this.dropdown.hide();

    markAllRead = (event) => {
        event.preventDefault();
        this.props.markAllRead();
    }

    handleNotificationClick = (id, is_read) => {
        const { update } = this.props;

        if (!is_read) {
            update({ id, is_read });
        }

        this.hide();
    };

    handleHide = () => {
        this.fetch({}, { silent: true });
    };

    render() {
        const {
            items,
            update,
            updatingItemId,
            unreadCount,
            updatingMarkAllRead,
            tooltipContentClassName
        } = this.props;

        return (
            <DropdownDeprecated
                ref={(el) => { this.dropdown = el; }}
                className="nav-header-notifications-content"
                containerClassName="nav-header-notifications"
                triggerActiveClassName="active"
                onHide={this.handleHide}
                trigger={() => (
                    <Fragment>
                        <Icon
                            name="bell"
                            className="nav-header-notifications-icon"
                            type="solid"
                        />

                        {!!unreadCount && (
                            <Badge
                                styleType="danger"
                                className="nav-header-notifications-badge"
                                size={Badge.sizes.small}
                            >
                                {Badge.formatCounter(unreadCount)}
                            </Badge>
                        )}
                    </Fragment>
                )}
            >
                <div className="nav-header-notification-container">
                    <div className="nav-header-notification-header">
                        <Text
                            component="h3"
                            className="nav-header-notification-title"
                        >
                            Notifications
                        </Text>
                        {!!unreadCount && (
                            <Text
                                component="a"
                                href="#mark-read"
                                onClick={this.markAllRead}
                                className={classnames('nav-header-notification-mark-all-read', {
                                    disabled: updatingMarkAllRead,
                                })}
                                size="small"
                            >
                                Mark all as Read
                            </Text>
                        )}
                    </div>

                    <div>
                        {items.map((item, index) => {
                            if (index >= config.notifications.headerListMaxLength) {
                                return null;
                            }

                            return (
                                <NotificationsFactory
                                    key={item.id}
                                    onNotificationClick={this.handleNotificationClick}
                                    updatingItemId={updatingItemId}
                                    update={update}
                                    className="nav-header-notifications-item"
                                    tooltipContentClassName={tooltipContentClassName}
                                    {...item}
                                />
                            );
                        })}
                    </div>

                    {!items.length && (
                        <Text component="p" className="nav-header-notification-empty">
                            Nothing here yet.
                        </Text>
                    )}

                    {!!items.length && (
                        <Link
                            to="/notifications"
                            onClick={this.hide}
                            className="nav-header-notification-see-all"
                        >
                            See All
                        </Link>
                    )}
                </div>
            </DropdownDeprecated>
        );
    }
}

NotificationsHeader.defaultProps = {
    updatingItemId: undefined,
    unreadCount: 0,
    tooltipContentClassName: undefined,
};

NotificationsHeader.propTypes = {
    defaultParams: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    markAllRead: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    updatingItemId: PropTypes.number,
    unreadCount: PropTypes.number,
    updatingMarkAllRead: PropTypes.bool.isRequired,
    tooltipContentClassName: PropTypes.string,
};

const mapStateToProps = state => ({
    unreadCount: getUnreadCount(state),
    defaultParams: getDefaultParams(state),
    items: notificationsHeaderCollection.selectors.getItems(state),
    updatingItemId: notificationsHeaderCollection.selectors.getUpdatingItemId(state),
    updatingMarkAllRead: notificationModel.selectors.getStateUpdating(state),
});

const mapDispatchToProps = {
    fetch: notificationsHeaderCollection.actions.fetch.request,
    sync: notificationsHeaderCollection.actions.update,
    update: notificationModel.actions.update.request,
    markAllRead: notificationModel.actions.markAllNotificationsRead.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsHeader);
