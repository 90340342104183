import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.css';

export default function WizardContainer({ children, className }) {
    return (
        <div
            className={cx(styles.componentWizard, {
                [className]: !!className,
            })}
        >
            {children}
        </div>
    );
}

WizardContainer.defaultProps = {
    children: null,
    className: undefined,
};

WizardContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
