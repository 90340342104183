export const paths = {
    healthWarning: 'health_warning',
    healthBar: 'health',
    mostActive: 'most_active',
    pastDue: 'past_due',
    progressNotes: 'progress_notes',
    mostUpdated: 'most_updated',
    topUsers: 'top_users',
};

export const availableWidgetPaths = [
    paths.healthWarning,
    paths.healthBar,
    paths.mostActive,
    paths.pastDue,
    paths.progressNotes,
    paths.mostUpdated,
    paths.topUsers
];
