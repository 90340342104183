import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton, Text, } from '../../../../../shared/components';
import styles from './data-source-item.module.css';

const DataSourceItemContent = ({
    showContentIcon, title, disabled, onEdit, onRemove, label,
}) => {
    return (
        <div className={styles.dataSourceItemContainer}>
            <div className={styles.itemElement}>
                <Text title={label} overflowEllipsis bold>
                    {label}
                </Text>
            </div>
            <div className={styles.itemElement}>
                {showContentIcon && (<Icon name="analytics" className={styles.itemIcon} />)}
                <Text title={title} overflowEllipsis className={styles.itemTitle}>
                    {title}
                </Text>
            </div>
            <div className={styles.itemElement}>
                <IconButton
                    icon="pen"
                    onClick={onEdit}
                    action="Edit source item"
                    disabled={disabled}
                    iconClassName={styles.itemIcon}
                />
                <IconButton
                    icon="trash-alt"
                    onClick={onRemove}
                    action="Remove source item"
                    disabled={disabled}
                    iconClassName={styles.itemIcon}
                />
            </div>
        </div>
    );
};

DataSourceItemContent.defaultProps = {
    showContentIcon: true,
    disabled: false,
};

DataSourceItemContent.propTypes = {
    showContentIcon: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
};

export default DataSourceItemContent;
