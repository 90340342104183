import u from 'updeep';
import { createReducer } from 'redux-act';
import * as actions from './actions';

export const initialState = {
    data: {
        accessToken: undefined,
        accessTokenExpired: undefined,
        accessTokenRefreshTime: undefined,
        auth0Status: undefined,
        expiredToken: undefined,
        lastLoginTime: undefined,
    },
    signupAttempt: {
        inviteToken: undefined,
        email: undefined,
    },
    socialSignupAttempt: {
        inviteToken: undefined,
        provider: undefined,
        email: undefined,
        href: undefined,
    },
    socialChangeEmailAttempt: {
        token: undefined,
        provider: undefined,
        prevEmail: undefined,
        nextEmail: undefined,
        nextPassword: undefined,
    },
    authenticated: false,
    recoveryUsed: false,
    expiringSSOProviderCheck: false,
};

function mapResponseToState(response) {
    return {
        accessToken: response.access_token,
        accessTokenExpired: response.access_token_expired,
        accessTokenRefreshTime: response.access_token_refresh_time,
        accessTokenExpiredAt: response.access_token_expired_at,
        accessTokenRefreshTimeAt: response.access_token_refresh_time_at,
        auth0Status: response.auth0_status,
        expiredToken: response.expired_token,
        lastLoginTime: response.lastLoginTime,
    };
}

function handleAuthenticate(state, action = {}) {
    return u({
        data: mapResponseToState(action),
        authenticated: true,
    }, state);
}

function handleLogout() {
    return initialState;
}

function handleSocialChangeEmailAttempt(state, action = {}) {
    const data = action.data || {};

    return u({
        socialChangeEmailAttempt: {
            token: data.token,
            provider: data.provider,
            prevEmail: data.prevEmail,
            nextEmail: data.nextEmail,
            prevPassword: data.prevPassword,
            nextPassword: data.nextPassword,
        },
    }, state);
}

function handleSetIsRecoveryUsed(state, action) {
    return u({ recoveryUsed: action }, state);
}

function handleSetExpiringSSOProviderCheck(state, action) {
    return u({ expiringSSOProviderCheck: action }, state);
}

function handleSignupAttempt(state, action = {}) {
    const data = action || {};

    return u({
        signupAttempt: {
            inviteToken: data.inviteToken,
            email: data.email,
        }
    }, state);
}

const reducerMap = {
    [actions.authenticate]: handleAuthenticate,
    [actions.logout]: handleLogout,
    [actions.setSignupAttempt]: handleSignupAttempt,
    [actions.setSocialChangeEmailAttempt]: handleSocialChangeEmailAttempt,
    [actions.setRecoveryUsed]: handleSetIsRecoveryUsed,
    [actions.setExpiringSSOProviderCheck]: handleSetExpiringSSOProviderCheck,
};

export default createReducer(reducerMap, initialState);
