import { takeEvery } from 'redux-saga/effects';
import setCustomPropertyValue from '../../../shared/utils/css-custom-properties';
import * as actions from './color-theme-actions';
import { adjustColor } from '../../../shared/utils/color';

async function setAccentColor({ payload }) {
    await setCustomPropertyValue('--color-accent-light', adjustColor(payload.color, 0.44));
    await setCustomPropertyValue('--color-accent-lighter', adjustColor(payload.color, 0.88));
    await setCustomPropertyValue('--color-accent', payload.color);
    await setCustomPropertyValue('--color-accent-dark', adjustColor(payload.color, -0.44));
    await setCustomPropertyValue('--color-accent-darker', adjustColor(payload.color, -0.88));
}

export default function* colorThemeSaga() {
    yield takeEvery(actions.setAccentColor.getType(), setAccentColor);
}
