import {
    takeEvery, put, select, take, call
} from 'redux-saga/effects';
import { progressNoteCollection } from '../secondary-entities/progress-note';
import {
    create as dataPointCreate, details as dataPointDetails, createOnMobile as dataPointCreateOnMobile,
} from './entity';
import { details as milestoneDetails } from '../goalmap-milestones/entity';
import * as actions from './actions';
import { typeCodes } from '../../shared/utils/entity-type';

function* updateMilestoneDetails() {
    const detailsState = yield select(milestoneDetails.selector);

    yield put({
        type: milestoneDetails.actions.request.getType(),
        payload: {
            id: detailsState.data.id,
            silent: true,
        },
    });

    yield put({
        type: progressNoteCollection.actions.request.getType(),
        payload: {
            entity_id: detailsState.data.id,
            entity_type: typeCodes.kpi,
            includes: 'author.user_profile,attachments'
        },
    });

    yield take(milestoneDetails.actions.success);
}

function* createDataPoint(data) {
    yield put(actions.setProcessedItemPeriod(data.payload.params.period_start));
    yield put(dataPointCreate.actions.request(data.payload));
    yield take(dataPointCreate.actions.success);
    yield call(updateMilestoneDetails);
    yield put(actions.setProcessedItemPeriod(null));
}

function* createDataPointMobile(data) {
    yield put(actions.setProcessedItemPeriod(data.payload.params.period_start));
    yield put(dataPointCreateOnMobile.actions.request(data.payload));
    yield take(dataPointCreateOnMobile.actions.success);
    yield call(updateMilestoneDetails);
    yield put(actions.setProcessedItemPeriod(null));
}

function* updateDataPoint(data) {
    yield put(actions.setProcessedItemPeriod(data.payload.params.period_start));
    yield put(dataPointDetails.actions.update.request(data.payload));
    yield take(dataPointDetails.actions.update.success);
    yield call(updateMilestoneDetails);
    yield put(actions.setProcessedItemPeriod(null));
}

function* destroyDataPoint(data) {
    yield put(actions.setProcessedItemPeriod(data.payload.period_start));
    yield put(dataPointDetails.actions.remove.request(data.payload));
    yield take(dataPointDetails.actions.remove.success);
    yield call(updateMilestoneDetails);
    yield put(actions.setProcessedItemPeriod(null));
}


export default function* saga() {
    yield takeEvery(
        actions.createDataPoint.getType(),
        createDataPoint
    );

    yield takeEvery(
        actions.createDataPointOnMobile.getType(),
        createDataPointMobile
    );

    yield takeEvery(
        actions.updateDataPoint.getType(),
        updateDataPoint
    );

    yield takeEvery(
        actions.destroyDataPoint.getType(),
        destroyDataPoint
    );
}
