export const entityType = 'plan_action';

export const actionsAttributes = {
    id: 'id',
    title: 'title',
    description: 'description',
    entity_type: 'entity_type',
    author_id: 'author_id',
    owner_id: 'owner_id',
    type: 'type',
    segment_id: 'segment_id',
    parent_id: 'parent_id',
    start_date: 'start_date',
    target_date: 'target_date',
    closed_date: 'closed_date',
    frequency: 'frequency',
    priority: 'priority',
    sensitivity: 'sensitivity',
    status: 'status',
    state: 'state',
    progress_status: 'progress_status',
    tag_name: 'tag_name',
    children: 'children',
    tags: 'tags',
    entity_children_counters: 'entity_children_counters',
    segment: 'segment',
    actions: 'actions',
};

export const defaultInitialValues = [
    actionsAttributes.id,
    actionsAttributes.title,
    actionsAttributes.description,
    actionsAttributes.author_id,
    actionsAttributes.owner_id,
    actionsAttributes.type,
    actionsAttributes.start_date,
    actionsAttributes.target_date,
    actionsAttributes.closed_date,
    actionsAttributes.frequency,
    actionsAttributes.priority,
    actionsAttributes.sensitivity,
    actionsAttributes.status,
    actionsAttributes.state,
    actionsAttributes.progress_status,
    actionsAttributes.tag_name,
    actionsAttributes.tags,
];

export const mobileTacticChildrenListTitle = 'Strategic Activity';
