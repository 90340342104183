import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Select } from '../../../shared/modules';
import getRoles from './api';

// eslint-disable-next-line react/prefer-stateless-function
export default class RoleSelect extends Component {
    render() {
        const { type, extraOptions, options } = this.props;
        let selectOptions = options;

        if (extraOptions && options) {
            selectOptions = [...extraOptions, ...options];
        }

        return (
            <Select
                loadOptions={options ? undefined : () => getRoles(type, extraOptions)}
                labelKey="display_name"
                searchable
                {...this.props}
                options={selectOptions}
            />
        );
    }
}

RoleSelect.defaultProps = {
    type: 2,
    extraOptions: undefined,
    accountRole: {},
    customOptions: {},
    options: undefined
};

RoleSelect.propTypes = {
    type: PropTypes.number,
    accountRole: PropTypes.object,
    customOptions: PropTypes.object,
    extraOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            name: PropTypes.string,
            display_name: PropTypes.string,
        }),
    ),
    options: PropTypes.array,
};
