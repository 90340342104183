import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';
import { SubmissionError } from 'redux-form';
import { get, flow } from 'lodash';
import { triggerEvent } from '../../utils/dom';
import { NavigationPrompt } from '../index';
import * as actions from './actions';
import { errorFieldDataAttribute } from './constants';
import { noop } from '../../utils/common';
import { withErrorBoundary } from '../error-boundary';

class Form extends Component {
    constructor(props) {
        super(props);

        this.formId = uuidv1();
    }

    componentDidUpdate(prevProps) {
        const {
            dirty, submitting, addFormDirtyState, useNavigationBlocking
        } = this.props;

        if (prevProps.submitting !== submitting) {
            const errorEl = document.querySelector(`[${errorFieldDataAttribute}=true]`);
            if (errorEl) errorEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        if (useNavigationBlocking && prevProps.dirty !== dirty) {
            addFormDirtyState({ formId: this.formId, dirty });
        }
    }

    componentWillUnmount() {
        const { removeFormDirtyState } = this.props;

        removeFormDirtyState(this.formId);
    }

    // interop of redux-form and redux-saga
    // provides Promise instance needed for redux-form, which is then
    // being resolved/rejected within saga
    onSubmit = (formData) => {
        const { onSubmit, onSuccessSubmit } = this.props;

        const submitPromise = new Promise((resolve, reject) => {
            return onSubmit({
                params: formData,
                resolve,
                reject,
            }, { resolve, reject });
        });

        return submitPromise
            .then(onSuccessSubmit)
            .catch(this.handleSubmitError);
    };

    handleSubmitError = (error) => {
        const { onErrorSubmit, parseError, errorObjectGetter } = this.props;

        onErrorSubmit(error);
        const message = errorObjectGetter(error);

        if (message) {
            const errorEl = document.querySelector(`input[name="${Object.keys(message)[0]}"]`);
            if (errorEl) errorEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            throw new SubmissionError(parseError(message));
        } else {
            console.error('Unhandled form submission error', error); // eslint-disable-line no-console
        }
    };

    getFormElement = () => this.form;

    reportValidity = () => this.form.reportValidity();

    // seems it doesn't work for nested forms
    submit = () => {
        triggerEvent('submit', this.form);
    };

    render() {
        const {
            children,
            handleSubmit,
            onSubmit,
            onSuccessSubmit,
            onErrorSubmit,
            parseError,
            dirty,
            useNavigationBlocking,
            submitting,
            ...rest
        } = this.props;

        return (
            <Fragment>
                {useNavigationBlocking && (
                    <NavigationPrompt when={dirty && !submitting} />
                )}
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        return handleSubmit(this.onSubmit)(event);
                    }}
                    ref={(el) => { this.form = el; }}
                    {...rest}
                >
                    {children}
                </form>
            </Fragment>
        );
    }
}

Form.defaultProps = {
    children: undefined,
    useNavigationBlocking: undefined,
    submitting: false,
    dirty: false,
    onSuccessSubmit() {},
    onErrorSubmit() {},
    parseError(error) { return error; },
    errorObjectGetter(error) {
        return get(error, ['response', 'data', 'errors'], get(error, ['data', 'message'])) || error?.errors;
    },
    onSubmit: noop,
    handleSubmit: noop
};

Form.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    parseError: PropTypes.func,
    onSuccessSubmit: PropTypes.func,
    onErrorSubmit: PropTypes.func,
    errorObjectGetter: PropTypes.func,
    children: PropTypes.node,
    dirty: PropTypes.bool,
    useNavigationBlocking: PropTypes.bool,
    submitting: PropTypes.bool,
    addFormDirtyState: PropTypes.func.isRequired,
    removeFormDirtyState: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    addFormDirtyState: actions.addFormDirtyState,
    removeFormDirtyState: actions.removeFormDirtyState,
};

export default flow(
    connect(
        null,
        mapDispatchToProps,
        null,
        { forwardRef: true }
    ),
    withErrorBoundary
)(Form);
