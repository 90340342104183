import moment from 'moment';
import {
    get, keyBy, last, isFinite
} from 'lodash';
import { attributes } from './constants';
import { kpiTypes, periodTargetAttributes } from '../goalmap-milestones/constants';
import { getFrequencyType, getFormattedPeriod } from '../../shared/utils/date';
import { Average } from '../../shared/utils/average';
import { getNumber, processNumber } from '../../shared/utils/math';

function findItem(arr, date, frequency) {
    const start = moment(date).startOf(frequency);

    return arr.find(item => (
        moment(get(item, attributes.period_start)).isSame(start, 'day')
    ));
}

function fill(frequency, arr = []) {
    const formattedFrequency = getFrequencyType(frequency);

    if (arr.length < 2) {
        return arr;
    }

    const max = (arr[0] || {})[attributes.period_start];
    const min = (arr[arr.length - 1] || {})[attributes.period_start];

    const diff = moment(max).diff(moment(min), formattedFrequency) + 1;

    return [...new Array(diff)].map((item, index) => {
        const targetDate = moment(max).subtract(index, formattedFrequency);
        const match = findItem(
            [...arr],
            targetDate
        );

        const periodStart = targetDate.startOf(formattedFrequency).format('YYYY-MM-DD HH:mm:ss');

        if (match) {
            return { ...match, [attributes.title]: getFormattedPeriod(frequency, periodStart) };
        }

        return {
            [attributes.id]: `empty-${index}`,
            [attributes.empty]: true,
            [attributes.period_start]: periodStart,
            [attributes.title]: getFormattedPeriod(frequency, periodStart),
        };
    });
}

function sortByDate(array) {
    return [...array].sort(
        (prev, cur) => (
            moment(
                get(prev, attributes.period_start)
            ).isAfter(moment(
                get(cur, attributes.period_start)
            )) ? -1 : 1
        )
    );
}

function isDataPoint(item) {
    return !item.empty && item.id;
}

function getPeriodTargetProp(periodTargetsMap, date, prop) {
    return get(periodTargetsMap, `${date}.${prop}`);
}

function processLinear(dataPoints, periodTargets) {
    const periodTargetsMap = keyBy(periodTargets, attributes.period_start);

    const average = new Average();
    let calculatedOverallProgress = 0;
    let calculatedTargetProgress = 0;
    let prevPeriodTarget = 0;
    let lastDataPointDate = false;

    dataPoints.reverse();

    dataPoints = dataPoints.map((it) => {
        const periodTarget = get(it, attributes.period_target);
        const targetProgress = get(it, attributes.target_progress);
        const overallTargetProgress = get(it, attributes.overall_progress);
        const periodStart = get(it, attributes.period_start);
        const periodTargetProgress = getPeriodTargetProp(
            periodTargetsMap,
            periodStart,
            periodTargetAttributes.overall_target_value
        );

        let projectedProgress = null;

        prevPeriodTarget = periodTarget
            || getPeriodTargetProp(
                periodTargetsMap,
                periodStart,
                periodTargetAttributes.target_value
            )
            || null;

        calculatedOverallProgress = getNumber(overallTargetProgress, calculatedOverallProgress);
        calculatedTargetProgress = getNumber(targetProgress, periodTargetProgress, calculatedTargetProgress);

        if (isDataPoint(it)) {
            lastDataPointDate = periodStart;
            const progressRel = calculatedOverallProgress / calculatedTargetProgress;
            average.add(isFinite(progressRel) ? progressRel : 0);
        }

        if (
            !isDataPoint(it)
            && moment(periodStart).isAfter(lastDataPointDate)
        ) {
            projectedProgress = calculatedTargetProgress * average.get();
        }

        return {
            [attributes.overall_progress]: processNumber(calculatedOverallProgress),
            [attributes.target_progress]: processNumber(calculatedTargetProgress),
            [attributes.period_target]: processNumber(prevPeriodTarget),
            [attributes.projected_progress]: processNumber(projectedProgress),
            ...it,
        };
    });

    dataPoints.reverse();
    return dataPoints;
}

function processStepped(dataPoints, periodTargets) {
    const periodTargetsMap = keyBy(periodTargets, attributes.period_start);

    dataPoints.reverse();
    dataPoints = dataPoints.map((it) => {
        return {
            [attributes.period_target]: get(
                periodTargetsMap,
                `${get(it, attributes.period_start)}.${periodTargetAttributes.target_value}`
            ) || null,
            ...it,
        };
    });
    dataPoints.reverse();
    return dataPoints;
}

export default function formatDataPointList(
    frequency = 'monthly', arr = [], filter, type, periodTargets, withPeriodTargetsCaps, measureRange
) {
    let result = [...arr];
    let targetsAfterDataPoints = [];
    let targetsBeforeDataPoints = [];

    result = sortByDate(result);
    result = fill(frequency, result);

    if (withPeriodTargetsCaps) {
        const lastDataPoint = get(last(arr), attributes.period_start);
        const filterPeriodTargets = (direction, dataPoint) =>
            sortByDate(
                periodTargets.filter(
                    it => moment(get(it, attributes.period_start))[direction](dataPoint)
                )
            );

        targetsAfterDataPoints = fill(frequency, filterPeriodTargets('isAfter', lastDataPoint));
        targetsBeforeDataPoints = fill(frequency, filterPeriodTargets('isBefore', lastDataPoint));
    }

    result = [...targetsAfterDataPoints, ...result, ...targetsBeforeDataPoints];

    if (filter) {
        result = result.filter(filter);
    }

    switch (type) {
        case kpiTypes.linear.type: return processLinear(result, periodTargets, measureRange);
        case kpiTypes.stepped.type: return processStepped(result, periodTargets);
        default: return result;
    }
}
