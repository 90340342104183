import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../../shared/components';
import { wait } from '../../../../shared/utils/common';

import { states } from '../../../goalmap-list/config';
import styles from './progress-note-create-modal-controls.module.css';

export default function ProgressNotesCreateFormControls({
    toggle, reset, submitting, submitDisabled, change, form, canCreateDraft,
}) {
    return (
        <div className={styles.progressNotesCreateControls}>
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    toggle();
                    reset();
                }}
                outline
                disabled={submitting}
                mobile
                className={styles.controlsButton}
            >
                Cancel
            </Button>
            {canCreateDraft && (
                <Button
                    outline
                    onClick={() => {
                        change('state', states.draft);
                        wait(0).then(form.submit);
                    }}
                    type="button"
                    disabled={submitting || submitDisabled}
                    mobile
                    className={styles.controlsButton}
                >
                    Save as Draft
                </Button>
            )}
            <Button
                styleType="success"
                type="submit"
                disabled={submitDisabled}
                loading={submitting}
                mobile
                className={styles.controlsButton}
            >
                Publish
            </Button>
        </div>
    );
}

ProgressNotesCreateFormControls.defaultProps = {
    form: undefined,
};

ProgressNotesCreateFormControls.propTypes = {
    toggle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
    canCreateDraft: PropTypes.bool.isRequired,
    form: PropTypes.object,
    change: PropTypes.func.isRequired,
};
