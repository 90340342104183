import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isBoolean, noop } from 'lodash';
import * as Notifications from '../../../notifications/toast';
import { messages } from '../progress-note-utils';
import ProgressNotesPopup from './progress-note-details-modal';

const getReadonlyProp = readonly => (isBoolean(readonly) ? readonly : true);

const ProgressNoteDetailsModalContainer = (props) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState(undefined);
    const [readonly, setReadonly] = useState(getReadonlyProp(props.readonly));

    useEffect(() => {
        setReadonly(props.readonly);
    }, [props.readonly]);

    const handleEditStart = () => {
        setReadonly(false);
    };

    const handleModalHide = () => {
        setReadonly(getReadonlyProp(props.readonly));
        if (props.onPopupClose) props.onPopupClose();
    };

    const onUpdate = () => {
        setMessage(messages.update);
    };

    const onPublish = () => {
        setMessage(messages.publish);
    };

    const onSubmitSuccess = (response) => {
        const action = Notifications.create({
            type: Notifications.types.SERVICE,
            data: {
                type: Notifications.serviceTypes.SUCCESS,
                text: message,
            }
        });

        dispatch(action);
        if (props.handleSuccessSubmit) props.handleSuccessSubmit(response);
    };

    return (
        <ProgressNotesPopup
            onStartEdit={handleEditStart}
            onHideEnd={handleModalHide}
            {...props}
            handleSuccessSubmit={onSubmitSuccess}
            controlProps={{
                ...props.controlProps,
                onPublish,
                onUpdate,
            }}
            readonly={readonly}
        />
    );
};

ProgressNoteDetailsModalContainer.defaultProps = {
    controlProps: {},
    handleSuccessSubmit: noop,
};

ProgressNoteDetailsModalContainer.propTypes = {
    readonly: PropTypes.bool.isRequired,
    onPopupClose: PropTypes.func.isRequired,
    onFetchError: PropTypes.func.isRequired,
    handleSuccessSubmit: PropTypes.func,
    controlProps: PropTypes.object,
};

export default ProgressNoteDetailsModalContainer;
