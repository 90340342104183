import { get, pick } from 'lodash';
import composeHref from '../../../shared/utils/compose-href';
import { getStringTypeByCode } from '../../../shared/utils/entity-type';
import * as routeUtils from '../../../routes/rules';
import { formatValue, isGlobal } from '../utils';
import { attributes, dataSourceAttributes } from '../constants';
import Permissions from '../../permissions';
import isOwnEntity from '../../../shared/utils/permissions';

export function findPeriodTargetByDate(periodTargets, date) {
    return periodTargets.find(
        it => formatValue(it.period_end) === date
    );
}

export function getRemoveRedirectUrl(data, contextLocation) {
    const goalId = get(data, attributes.goal_id);
    const parentEntityId = get(data, attributes.parent_entity_id);
    const parentEntityType = get(data, attributes.parent_entity_type);

    if (isGlobal(data) || contextLocation === 'global') {
        return routeUtils.createUrl(routeUtils.paths.planKPIList, {
            [routeUtils.params.PlanId]: goalId,
        });
    }

    const stringType = getStringTypeByCode(parentEntityType);

    return composeHref(stringType, {
        id: parentEntityId,
        goal_id: goalId,
    });
}


export const getInitialValues = (data) => {
    return pick(data, [
        attributes.owner_id,
        attributes.id,
        attributes.goal_id,
        attributes.title,
        attributes.tag_name,
        attributes.description,
        attributes.author_id,
        attributes.progress_bar,
        attributes.target_at,
        attributes.bound_left,
        attributes.bound_right,
        attributes.threshold_left,
        attributes.threshold_right,
        attributes.frequency,
        attributes.target_value,
        attributes.type,
        attributes.started_at,
        attributes.required_from,
        attributes.kpi_type,
        attributes.range_of_measure,
        attributes.health_tolerance_bound,
        attributes.health_tolerance_threshold,
        attributes.health_tolerance_value,
        attributes.kpi_period_targets,
        attributes.kpi_default_chart_type,
        attributes.kpi_charts,
        attributes.start_value,
        attributes.advanced_calculation_enabled,
        attributes.advanced_calculation_sources,
        attributes.advanced_calculation_type,
        attributes.parent_entity_id,
        attributes.parent_entity_type,
        attributes.currency_format,
    ]);
};

export const isCanCreateDataPoint = (permissions, data, userId) => {
    const canUpdate = get(permissions, Permissions.keys.canUpdate);
    const canUpdateOwn = get(permissions, Permissions.keys.canUpdateOwn);
    const resolvedCanUpdate = canUpdate || (canUpdateOwn && isOwnEntity(userId, data));

    if (canUpdate) { return true; }

    if (!canUpdate && !canUpdateOwn) { return false; }

    const advEnabled = get(data, attributes.advanced_calculation_enabled);

    if (!advEnabled) { return resolvedCanUpdate; }

    const sources = get(data, attributes.advanced_calculation_sources);
    const hasNotUpdatableSource = !!sources.find(
        src => !isOwnEntity(userId, src[dataSourceAttributes.related_entity])
    );

    return canUpdate && !hasNotUpdatableSource;
};

export const checkKpiSubscriptionLimitBeforeChangeState = (isActive, limit, { show: showModal }) => {
    const { used, value } = limit;

    if (!isActive && (typeof value !== 'boolean') && (Number(used) >= Number(value))) {
        showModal();
        return false;
    }
    return true;
};
