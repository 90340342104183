import { put, } from 'redux-saga/effects';

export default function getAdditionalSaga({ action }) {
    return function* additionalSaga({ payload }) {
        if (action) {
            if (Array.isArray(action)) {
                for (let i = 0; i < action.length; i += 1) {
                    yield put({
                        type: action[i].getType(),
                        payload
                    });
                }

                return;
            }

            yield put({
                type: action.getType(),
                payload
            });
        }
    };
}
