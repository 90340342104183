import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
    Icon, Col, Row, Text, TableView, Input,
} from '../../../../shared/components';
import {
    kpiTypes, dataSourceAttributes, attributes as KPIAttributes,
} from '../../../goalmap-milestones/constants';
import KPIDataPointFormTableItem from '../kpi-data-point-form-table-item';
import { titlesByOperation, calculateTotalByOperation, } from '../../utils';
import { roundDecimal } from '../../../../shared/utils/math';
import { PeriodActualField, PeriodTargetField, PeriodNoteFields } from './advanced-calc-form-fields';

const valueKeys = {
    value: 'value',
    period_actual: 'period_actual',
    period_target: 'period_target',
};

class MilestoneDataPointForm extends Component {
    get isStaticKPI() {
        return this.props.type === kpiTypes.static.type;
    }

    renderTable = () => {
        const { advCalcSources } = this.props;

        if (!advCalcSources.length) {
            return null;
        }

        return (
            <Col base={12}>
                <span className="milestone-data-point-form-table-label">
                    Period Actuals <Text styleType="danger">*</Text>
                </span>
                <TableView
                    className="data-point-table"
                >
                    <TableView.Header>
                        <TableView.HeaderCell className="data-point-table-column data-point-table-column-label">
                            Label
                        </TableView.HeaderCell>
                        <TableView.HeaderCell className="data-point-table-column data-point-table-column-data-source">
                            Data Source
                        </TableView.HeaderCell>
                        <TableView.HeaderCell className="data-point-table-column data-point-table-column-actual-value">
                            Period Actual
                        </TableView.HeaderCell>
                        {!this.isStaticKPI && (
                            <TableView.HeaderCell className="data-point-table-column data-point-table-column-actual-value">
                                Period Target
                            </TableView.HeaderCell>
                        )}
                    </TableView.Header>
                    <tbody>
                        {advCalcSources.map(this.renderTableRow)}
                        {this.renderSummaryRow()}
                    </tbody>
                </TableView>
            </Col>
        );
    }

    renderTableRow = (source, index) => {
        const { initialValues, isUpdateDataPoint } = this.props;
        const label = get(source, dataSourceAttributes.label);
        const sourceKPI = get(source, dataSourceAttributes.related_entity);
        const sourceKPITitle = get(sourceKPI, KPIAttributes.title);

        return (
            <KPIDataPointFormTableItem
                key={source.id}
                columns={[
                    label,
                    (
                        <>
                            <Icon className="data-source-icon" name="analytics" type="regular" />
                            {sourceKPITitle}
                        </>
                    ),
                    <PeriodActualField
                        index={index}
                        initialValues={initialValues}
                        isUpdateDataPoint={isUpdateDataPoint}
                        className="data-source-actual-value-input"
                        formGroupClassName="data-source-actual-value-input-wrap"
                    />,
                    this.isStaticKPI
                        ? null
                        : (
                            <PeriodTargetField
                                index={index}
                                initialValues={initialValues}
                                isUpdateDataPoint={isUpdateDataPoint}
                                formGroupClassName="data-source-actual-value-input-wrap"
                                className="data-source-actual-value-input"
                            />
                        )
                ]}
            />
        );
    }

    renderSummaryRow = () => {
        const { advCalcOperation, currentValues } = this.props;
        const { dataPoints = [] } = currentValues;

        const calculationFunc = calculateTotalByOperation[advCalcOperation];

        const valueTotal = calculationFunc(dataPoints, valueKeys.value);
        const periodTargetTotal = calculationFunc(dataPoints, valueKeys.period_target);

        return (
            <KPIDataPointFormTableItem
                className="summary-table-item"
                columns={[
                    (<span className="summary-table-item-label">{titlesByOperation[advCalcOperation]}</span>),
                    <span />,
                    <Input
                        disabled
                        input={{
                            value: roundDecimal(valueTotal),
                        }}
                        formGroupClassName="data-source-actual-value-input-wrap"
                        className="data-source-actual-value-input"
                    />,
                    this.isStaticKPI
                        ? null
                        : (
                            <Input
                                disabled
                                input={{
                                    value: roundDecimal(periodTargetTotal),
                                }}
                                formGroupClassName="data-source-actual-value-input-wrap"
                                className="data-source-actual-value-input"
                            />
                        )
                ]}
            />
        );
    }

    render() {
        const {
            frequency, goal_id, isUpdateDataPoint, setPeriodStart
        } = this.props;
        return (
            <div className="milestone-data-point-form">
                <PeriodNoteFields
                    frequency={frequency}
                    setPeriodStart={setPeriodStart}
                    isUpdateDataPoint={isUpdateDataPoint}
                    goal_id={goal_id}
                />
                <Row>
                    {this.renderTable()}
                </Row>
            </div>
        );
    }
}

MilestoneDataPointForm.defaultProps = {
    setPeriodStart() {},
};

MilestoneDataPointForm.propTypes = {
    frequency: PropTypes.string.isRequired,
    goal_id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    advCalcOperation: PropTypes.string.isRequired,
    advCalcSources: PropTypes.array,
    currentValues: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    setPeriodStart: PropTypes.func,
    isUpdateDataPoint: PropTypes.bool,
};

MilestoneDataPointForm.defaultProps = {
    advCalcSources: [],
    isUpdateDataPoint: false,
};

export default MilestoneDataPointForm;
