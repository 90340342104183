import { createAction } from 'redux-act';

function createMoveActions(prefix) {
    return {
        move: createAction(`${prefix}/MOVE`),
        update: createAction(`${prefix}/UPDATE`),
        returnPreviousState: createAction(`${prefix}/RETURN_PREVIOUS_STATE`),
    };
}

export default createMoveActions;
