import { Collection } from '../../shared/entities-v2';

class UserTeamsCollection extends Collection {
    parse = (response) => {
        return {
            data: response,
            pager: {}
        };
    }
}

const userTeamsCollection = new UserTeamsCollection({
    name: 'user-teams-collection',
    apiUrl: '/user/teams',
});

export default userTeamsCollection;
