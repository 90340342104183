import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { ServicePage, Button } from '../../shared/components';

export default function ServicePage429({ children }) {
    return (
        <ServicePage
            icon="exclamation-triangle"
            title="Unable to load the page due to excessive number of requests. Please try again later"
        >
            <Button
                styleType="success"
                component={Link}
                to="/dashboard"
            >
                Back to Dashboard
            </Button>
            {children}
        </ServicePage>
    );
}

ServicePage429.propTypes = {
    children: PropTypes.node.isRequired,
};
