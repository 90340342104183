import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getItemById } from '../../../modules/goalmap-list/reducer';

function EntityTitle({ title }) {
    return (
        <span>{title}</span>
    );
}

EntityTitle.defaultProps = {
    title: undefined,
};

EntityTitle.propTypes = {
    title: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    let title = '';

    if (ownProps.goalId) {
        const goalDetails = getItemById(state, ownProps.goalId);
        title = goalDetails && goalDetails.settings[ownProps.type];
    } else {
        const goalDetails = state.goalmapsList.details;
        title = goalDetails && goalDetails.settings && goalDetails.settings[ownProps.type];
    }

    return {
        title: (title || ownProps.type),
    };
}

export default connect(
    mapStateToProps,
)(EntityTitle);
