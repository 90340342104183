import u from 'updeep';

export default function parseValidationMessage(error) {
    const attachmentErrors = Object.keys(error).reduce((accum, item) => {
        if (item.indexOf('attachments') > -1) {
            const current = error[item];
            const index = item.split('.')[2];

            accum[Number(index)] = current.map(message => // eslint-disable-line no-param-reassign
                message.replace(item, 'attachment'));

            return accum;
        }

        return accum;
    }, {});

    return u(
        {
            attachments: attachmentErrors,
        },
        error
    );
}
