import { createAction } from 'redux-act';

import { Model } from '../../../../shared/entities-v2';
import createAsyncActions from '../../../../shared/entities-v2/base/async-actions';
import { handleUpdateRequest, handleUpdateSuccess } from '../../../../shared/entities-v2/model/reducer';
import { ModalPlugin } from '../../../../shared/entities-v2/plugins';
import request from '../../../../request';
import settingsSecuritySsoProviderCollection from '../settings-security-sso-list-provider/entity';
import formatRequestPayload from '../../../../shared/entities-v2/utils/format-request-payload';

const modelName = 'settings-security-sso-provider';
const pluginName = 'settings-security-sso-provider-modal';
const apiUrl = '/sso/providers';

const providerToggle = createAsyncActions(`${modelName}/provider-toggle`, formatRequestPayload);
const refreshCertificates = createAsyncActions(`${modelName}/provider-refresh-certificates`, formatRequestPayload);
const downloadMetadata = createAsyncActions(`${modelName}/provider-download-metadata`, formatRequestPayload);
const clearMedata = createAction(`${modelName}/provider-clear-metadata`);

const handleMetadataSuccessReducer = (state, payload) => {
    return { ...state, extensionState: { ...state.extensionState, metadata: payload.response } };
};
const handleClearMetadataReducer = (state) => {
    return { ...state, extensionState: { ...state.extensionState, metadata: null } };
};

const settingsSecuritySsoProviderModel = new Model({
    apiUrl,
    name: modelName,
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onCreateSuccess]: settingsSecuritySsoProviderCollection.actions.unshift,
            [Model.events.onRemoveSuccess]: settingsSecuritySsoProviderCollection.actions.remove,
            [Model.events.onUpdateSuccess]: settingsSecuritySsoProviderCollection.actions.update,
            [providerToggle.success]: settingsSecuritySsoProviderCollection.actions.fetch.request,
        },
        [Model.additionalArtifacts.actions]: {
            providerToggle,
            refreshCertificates,
            downloadMetadata,
            clearMedata,
        },
        [Model.additionalArtifacts.reducers]: {
            [providerToggle.request]: handleUpdateRequest,
            [refreshCertificates.request]: handleUpdateRequest,
            [providerToggle.success]: handleUpdateSuccess,
            [downloadMetadata.success]: handleMetadataSuccessReducer,
            [clearMedata]: handleClearMetadataReducer,
        },
        [Model.additionalArtifacts.api]: {
            providerToggle: (data) => {
                return request
                    .put(`${apiUrl}/${data.id}/${data.state}`)
                    .catch((err) => { throw err; });
            },
            refreshCertificates: (data) => {
                return request
                    .put(`${apiUrl}/${data.id}/certificate`)
                    .catch((err) => { throw err; });
            },
            downloadMetadata: (data) => {
                return request
                    .get(`${apiUrl}/${data.id}/metadata`)
                    .catch((err) => { throw err; });
            },
        },
        [Model.additionalArtifacts.initialState]: {
            metadata: null,
        },
    },
    plugins: [
        new ModalPlugin(pluginName),
    ],
});

export const settingsSecuritySsoProviderCreateModal = settingsSecuritySsoProviderModel
    .getPluginInstanse(pluginName);

export default settingsSecuritySsoProviderModel;
