import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const sizes = Object.freeze({
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
});

const legacySizes = {
    base: 'base',
    small: 'small',
    medium: 'meduim',
    large: 'large',
};

const sizePostfixes = {
    [sizes.xs]: '',
    [sizes.sm]: '-sm',
    [sizes.md]: '-md',
    [sizes.lg]: '-lg',
    [sizes.xl]: '-xl',
};

const legacySizeMap = {
    [sizes.xs]: legacySizes.base,
    [sizes.sm]: legacySizes.small,
    [sizes.md]: legacySizes.medium,
    [sizes.lg]: legacySizes.large,
};

function formatValues(props) {
    return Object.values(sizes).reduce((accum, size) => {
        if (size in legacySizeMap) {
            const legacySize = legacySizeMap[size];
            if (props[legacySize]) {
                accum[size] = props[legacySize]; // eslint-disable-line no-param-reassign
                return accum;
            }
        }

        accum[size] = props[size]; // eslint-disable-line no-param-reassign
        return accum;
    }, {});
}

function hasValue(value) {
    if (value === undefined || value === null) {
        return false;
    }

    return true;
}

export default function Col(props) {
    const {
        children,
        className,
        base,
        small,
        medium,
        large,
        xs,
        sm,
        md,
        lg,
        xl,
        xsOffset,
        smOffset,
        mdOffset,
        lgOffset,
        xlOffset,
        ...rest
    } = props;
    const colSize = formatValues(props);

    return (
        <div
            className={classnames('col', {
                [`col${sizePostfixes[sizes.xs]}-${colSize[sizes.xs]}`]: hasValue(colSize[sizes.xs]),
                [`col${sizePostfixes[sizes.sm]}-${colSize[sizes.sm]}`]: hasValue(colSize[sizes.sm]),
                [`col${sizePostfixes[sizes.md]}-${colSize[sizes.md]}`]: hasValue(colSize[sizes.md]),
                [`col${sizePostfixes[sizes.lg]}-${colSize[sizes.lg]}`]: hasValue(colSize[sizes.lg]),
                [`col${sizePostfixes[sizes.xl]}-${colSize[sizes.xl]}`]: hasValue(colSize[sizes.xl]),

                [`offset${sizePostfixes[sizes.xs]}-${xsOffset}`]: hasValue(xsOffset),
                [`offset${sizePostfixes[sizes.sm]}-${smOffset}`]: hasValue(smOffset),
                [`offset${sizePostfixes[sizes.md]}-${mdOffset}`]: hasValue(mdOffset),
                [`offset${sizePostfixes[sizes.lg]}-${lgOffset}`]: hasValue(lgOffset),
                [`offset${sizePostfixes[sizes.xl]}-${xlOffset}`]: hasValue(xlOffset),

                [className]: !!className
            })}
            {...rest}
        >
            {children}
        </div>
    );
}

Col.defaultProps = {
    children: undefined,
    className: undefined,
    base: undefined,
    small: undefined,
    medium: undefined,
    large: undefined,
    xs: undefined,
    sm: undefined,
    md: undefined,
    lg: undefined,
    xl: undefined,
    xsOffset: undefined,
    smOffset: undefined,
    mdOffset: undefined,
    lgOffset: undefined,
    xlOffset: undefined,
};

Col.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    /**
        Deprecated
    */
    base: PropTypes.number,
    /**
        Deprecated
    */
    small: PropTypes.number,
    /**
        Deprecated
    */
    medium: PropTypes.number,
    /**
        Deprecated
    */
    large: PropTypes.number,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xsOffset: PropTypes.number,
    smOffset: PropTypes.number,
    mdOffset: PropTypes.number,
    lgOffset: PropTypes.number,
    xlOffset: PropTypes.number,
};
