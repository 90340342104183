import { createSelector } from 'reselect';
import {
    pick, isEmpty, get, flow
} from 'lodash';
import { getFormValues } from 'redux-form';
import { getPlansItems } from '../plans-list-my/selectors';
import { getMobileNotificationRedirectHref } from '../notifications/notification-utils';
import {
    selectPlanColorMap as selectPlanColorMapTimeline, selectPlans as selectTimelinePlans,
} from './timeline/model/timeline-model-selectors';
import { selectPlans as selectGridPlans, selectPlanColorMap as selectPlanColorMapGrid } from './grid/model/grid-model-selectors';
import workCenterCollection from './work-center-collection';
import workCenterCustomFilterModelUpdate from './custom-filters/model';
import {
    colors, defaultColor, filtersFormName, filtersInitialValues, quickFilterValues, requestParams, workCenterViewTypes,
} from './constants';
import { mapItemsByDates } from './mappers';
import { preparePlansForFilters } from './sidebar/work-center-sidebar-utils';

const sortPlansByTitle = (a, b) => (`${a.title}`).localeCompare(b.title);

const getPlans = (workCenterList, planList) => {
    const reducer = (accum, item) => {
        const findPlan = plan => plan.id === item.goal_id;
        const plan = planList.find(findPlan);
        const isUnique = !accum.find(findPlan);

        if (plan && isUnique) {
            accum.push(
                pick(plan, ['id', 'title', 'settings'])
            );
        }

        return accum;
    };

    return workCenterList
        .reduce(reducer, [])
        .sort(sortPlansByTitle);
};

const planColorReducer = (accum, item, index) => {
    const color = colors[index];
    accum[item.id] = color || defaultColor;
    return accum;
};

const customFilterPlanColorReducer = (accum, item) => {
    const color = colors[item.plan_color_index];
    accum[item.plan_id] = color;
    return accum;
};

const getPlanColorMap = (plans, filters, customFilter) => {
    const customFiltersPlans = get(customFilter, 'filters.plan.value');

    if (!isEmpty(customFiltersPlans) && filters?.quick_filter === 'custom') {
        return customFiltersPlans.reduce(customFilterPlanColorReducer, {});
    }

    return plans.reduce(planColorReducer, {});
};

export const selectPlans = createSelector(
    [workCenterCollection.selectors.getItems, getPlansItems],
    getPlans
);

export function createSelectorPlans(type) {
    switch (type) {
        case workCenterViewTypes.calendar:
            return selectPlans;
        case workCenterViewTypes.grid:
            return selectGridPlans;
        case workCenterViewTypes.timeline:
        default:
            return selectTimelinePlans;
    }
}

export const selectPlanColorMap = createSelector(
    [selectPlans, getFormValues(filtersFormName), workCenterCustomFilterModelUpdate.selectors.getAttributes],
    getPlanColorMap
);

export function createSelectorPlanColorMap(type) {
    switch (type) {
        case workCenterViewTypes.calendar:
            return selectPlanColorMap;
        case workCenterViewTypes.grid:
            return selectPlanColorMapGrid;
        case workCenterViewTypes.timeline:
        default:
            return selectPlanColorMapTimeline;
    }
}

export const selectMappedItemIdsByDate = createSelector(
    [workCenterCollection.selectors.getItems, getFormValues(filtersFormName)],
    (items, filterValues) => mapItemsByDates(filterValues, items),
);

export const selectFilterParamsPersistState = createSelector(
    [workCenterCollection.selectors.getBranch],
    data => ({
        ...data, items: [], raw: {}, error: null,
    }),
);

const normalizeEntityTypeFilter = filtersPersistState =>
    (get(filtersPersistState, requestParams.entityType, []).length === 3
        ? { ...filtersPersistState, entity_type_filter: [] }
        : filtersPersistState);

const normalizeCustomFilter = filtersPersistState =>
    (get(filtersPersistState, requestParams.quickFilter, '') !== quickFilterValues.custom
        ? { ...filtersPersistState, custom_filter: {} }
        : filtersPersistState);


export const getInitialValues = (filtersPersistState) => {
    if (isEmpty(filtersPersistState)) return filtersInitialValues;
    return flow(normalizeEntityTypeFilter, normalizeCustomFilter)(filtersPersistState);
};

export const selectEmptyFilterData = createSelector(
    [getPlansItems],
    (plans) => {
        const preparedPlans = preparePlansForFilters(plans);

        return {
            filters: {
                plan: { value: preparedPlans }
            }
        };
    }
);

export const selectMobileRedirectHref = createSelector(
    [getPlansItems, (_, params) => params],
    (plans, params) => getMobileNotificationRedirectHref(plans[0]?.id, params)
);
