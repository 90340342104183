/** @namespace Utils.SupportCheck */

/**
 * Check support of sticky positioning
 * @function isPositionStickySupported
 * @memberof Utils.SupportCheck
 * @return {boolean} True if sticky positioning is support
 */
// eslint-disable-next-line import/prefer-default-export
export function isPositionStickySupported() {
    const prefix = ['sticky', '-o-sticky', '-webkit-sticky', '-moz-sticky', '-ms-sticky'];
    const test = document.head.style;

    for (let i = 0; i < prefix.length; i += 1) {
        test.position = prefix[i];
    }

    return prefix.includes(test.position) && test.position;
}
