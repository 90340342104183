import React from 'react';
import PropTypes from 'prop-types';
import {
    Accordion as ReactAccordion,
    AccordionItem as ReactAccordionItem,
    AccordionItemHeading as ReactAccordionItemHeading,
    AccordionItemButton as ReactAccordionItemButton,
    AccordionItemPanel as ReactAccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import classnames from 'classnames';
import Icon from '../icon/component';
import './styles.css';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classNameIcon: PropTypes.string,
};

const defaultProps = {
    children: undefined,
    className: undefined,
    classNameIcon: undefined,
};

function generator(RenderComponent, defaultClassName, displayName) {
    function EnhancedComponent({ children, className, ...rest }) {
        return (
            <RenderComponent
                className={classnames(defaultClassName, {
                    [className]: !!className,
                })}
                {...rest}
            >
                {children}
            </RenderComponent>
        );
    }

    EnhancedComponent.displayName = displayName;
    EnhancedComponent.defaultProps = defaultProps;
    EnhancedComponent.propTypes = propTypes;

    return EnhancedComponent;
}

const AccordionItemButtonGenerated = generator(ReactAccordionItemButton, 'accordion-item-button', 'AccordionItemButton');

export const Accordion = generator(ReactAccordion, 'accordion', 'Accordion');
export const AccordionItem = generator(ReactAccordionItem, 'accordion-item', 'AccordionItem');
export const AccordionItemHeading = generator(ReactAccordionItemHeading, 'accordion-item-heading', 'AccordionItemHeading');
export const AccordionItemPanel = generator(ReactAccordionItemPanel, 'accordion-item-panel', 'AccordionItemPanel');

export function AccordionItemButton({
    children, className, classNameIcon, ...rest
}) {
    return (
        <AccordionItemState>
            {({ expanded }) => (
                <AccordionItemButtonGenerated
                    {...rest}
                    className={classnames({
                        [className]: !!className,
                        'accordion-item-button-expanded': expanded,
                    })}
                >
                    {children}
                    <Icon
                        className={classnames('accordion-item-button-icon', {
                            [classNameIcon]: !!classNameIcon,
                        })}
                        name={expanded ? 'chevron-down' : 'chevron-right'}
                    />
                </AccordionItemButtonGenerated>
            )}
        </AccordionItemState>
    );
}

AccordionItemButton.defaultProps = defaultProps;
AccordionItemButton.propTypes = propTypes;
