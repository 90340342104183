import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import createListOfItems from '../../../../shared/modules/list-view/component';
import { Prompt } from '../../../../shared/components';

import { progressNoteCollection } from '../../progress-note/progress-note-models';
import { attachmentCollection, attachmentModel } from '../attachment-models';

import AttachmentItem from './attachment-item';

const {
    actions, selector,
} = attachmentCollection;

const AttachmentsList = createListOfItems({
    actions,
    selector,
    mapStateToProps: (state, ownProps) => ({
        params: {
            entity_id: ownProps.parentId,
            entity_type: ownProps.parentEntityType,
        }
    })
})(AttachmentItem);

class AttachmentsContainer extends Component {
    constructor(args) {
        super(args);

        this.state = {
            promptVisible: false,
            promptAction() {},
        };
    }

    onRemove = (id) => {
        const { showDetailsModal, removeItem } = this.props;

        this.setState({
            promptVisible: true,
            promptAction: () => {
                showDetailsModal({ id });
                removeItem({
                    id,
                    onSuccess: this.onRemoveSuccess,
                });
            }
        });
    }

    onRemoveSuccess = () => {
        const {
            getProgressNotes, parentEntityTypeCode, parentEntityType, parentId, onDeleteSuccess,
        } = this.props;

        getProgressNotes({
            entity_id: parentId,
            entity_type: parentEntityTypeCode || parentEntityType,
            includes: 'author.user_profile,attachments',
            order_by: 'created_at,desc',
            state: 1,
        });
        onDeleteSuccess();
    }

    render() {
        const {
            showDetailsModal,
            removeItem,
            updatingItemId,
            permissions,
            userId,
            getProgressNotes,
            ...rest
        } = this.props;

        return (
            <div className="side-attachment-list">
                <AttachmentsList
                    {...rest}
                    paginationDisabled
                    itemProps={{
                        permissions,
                        updatingItemId,
                        userId,
                        onRemove: this.onRemove,
                    }}
                />

                <Prompt
                    visible={this.state.promptVisible}
                    hide={() => this.setState({ promptVisible: false })}
                    description="You are about to delete this attachment."
                    onAccept={this.state.promptAction}
                />
            </div>
        );
    }
}


AttachmentsContainer.defaultProps = {
    updatingItemId: undefined,
    params: {},
    userId: undefined,
    onDeleteSuccess() {},
};

AttachmentsContainer.propTypes = {
    showDetailsModal: PropTypes.func.isRequired,
    getProgressNotes: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    userId: PropTypes.number,
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    updatingItemId: PropTypes.number,
    permissions: PropTypes.shape({
        destroy: PropTypes.bool,
        destroy_own: PropTypes.bool,
    }).isRequired,
    parentId: PropTypes.number.isRequired,
    parentEntityType: PropTypes.string.isRequired,
    onDeleteSuccess: PropTypes.func,
    parentEntityTypeCode: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
    const detailsState = attachmentModel.selector(state);

    return {
        updatingItemId: detailsState && detailsState.modalItemId,
    };
}

const connectedComponent = connect(
    mapStateToProps,
    {
        showDetailsModal: attachmentModel.actions.toggleModal,
        removeItem: attachmentModel.actions.remove.request,
        getProgressNotes: progressNoteCollection.actions.request
    }
)(AttachmentsContainer);

export default connectedComponent;
