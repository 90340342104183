import u from 'updeep';
import { isInfinite, fields } from './subscription-config';
import { findIndex } from '../../shared/utils/collection';

function modifyCounter(state, action, increment) {
    const currentIndex = findIndex(state.items, action.feature, fields.key);
    const currentItem = state.items[currentIndex];

    const currentValue = currentItem.fieldCounters[fields.used];
    const total = currentItem.fieldCounters[fields.value];

    if (isInfinite(total)) {
        return state;
    }

    return u.updateIn(
        ['items', currentIndex],
        {
            fieldCounters: {
                [fields.used]: currentValue + increment
            },
        },
        state
    );
}

export function incrementCounter(state, action) {
    return modifyCounter(state, action, 1);
}

export function decrementCounter(state, action) {
    return modifyCounter(state, action, -1);
}
