import PropTypes from 'prop-types';
import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import classnames from 'classnames';
import { noop } from '../../utils/common';

export default class HTMLRenderer extends Component {
    constructor(args) {
        super(args);

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.root.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        this.root.removeEventListener('click', this.handleClick);
    }

    handleClick(event) {
        if (event.target.getAttribute('target') !== '_blank') {
            event.preventDefault();
            const href = event.target.getAttribute('href');

            if (href && href !== '' && href !== '#') {
                this.props.navigate(href);
            }
        }
    }

    render() {
        const { children, className } = this.props;

        return (
            <span
                ref={(el) => { this.root = el; }}
                className={classnames({
                    [className]: !!className
                })}
            >
                {renderHTML(children || '')}
            </span>
        );
    }
}

HTMLRenderer.defaultProps = {
    children: '',
    className: undefined,
    navigate: noop
};

HTMLRenderer.propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
    navigate: PropTypes.func,
};
