import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import {
    TitleTagnameFields,
    OwnerSelect,
    CategorizationTagSelector,
} from '../../../shared/modules';
import {
    Editor, GridContainer, Row, Col,
} from '../../../shared/components';
import { attributes } from '../constants';

export default function MilestoneCreatePage1({
    change,
    goalId,
    title,
}) {
    return (
        <GridContainer>
            <Row>
                <Col>
                    <div className="milestones-create-form">
                        <TitleTagnameFields
                            change={change}
                            goalId={goalId}
                            titleFieldLayoutRenderer={children => (
                                <Row>
                                    <Col base={8}>
                                        {children}
                                    </Col>
                                    <Col base={4}>
                                        <OwnerSelect
                                            id="milestone-select-owner"
                                            label="Owner"
                                            placeholder="Select Owner"
                                            goalId={goalId}
                                            required
                                        />
                                    </Col>
                                </Row>
                            )}
                            titleFieldProps={{
                                label: 'Name',
                                placeholder: `Name your ${title}`,
                            }}
                            tagnameFieldProps={{
                                label: 'Tag Name',
                                placeholder: `Type in Tag Name for your ${title}`,
                            }}
                        />

                        <Field
                            name={attributes.description}
                            label="Target State"
                            placeholder={`Type in ${title} Target State`}
                            component={Editor}
                            controlsHidden
                            goal_id={goalId}
                        />

                        <Field
                            name={attributes.tags}
                            label="Tags"
                            placeholder="Select tags"
                            id="milestone-tags-select"
                            component={CategorizationTagSelector}
                            goal_id={goalId}
                        />
                    </div>
                </Col>
            </Row>
        </GridContainer>
    );
}

MilestoneCreatePage1.defaultProps = {

};

MilestoneCreatePage1.propTypes = {
    goalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    change: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    currentValues: PropTypes.shape({
        frequency: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
};
