import React, { useEffect, Fragment, useState, } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, PromptProvider, } from '../../../../../shared/components';
import { operationsData } from '../../../constants';
import { getOperationDataById } from '../../../utils';
import { availableLabels, getDefaultLabel, getOptListKpiFilteredByAddedDataSource, } from '../utils';
import DataSourcesItemFields from '../data-source-item/data-source-item-fields';
import DataSourceItemContent from '../data-source-item/data-source-item-content';
import { headerTitles } from '../constants';

import styles from './kpi-data-sources-list.module.css';

const getKpiTitle = (kpiList, kpiId) => kpiList && kpiList.find(kpi => kpi.id === kpiId).title;

const isDataSourceFilled = (filledDataSourcesList, dataSourceId, index) =>
    (dataSourceId && filledDataSourcesList.find(id => id === `${dataSourceId}-${index}`));

const getFilledDataSourcesList = currentValueAdvCalcSources =>
    (currentValueAdvCalcSources.length
        ? currentValueAdvCalcSources.map((source, position) => `${source.related_entity_id}-${position}`)
        : []);

const shouldShowAddButton = (sourcesLength, operationMaxLength) => (sourcesLength < operationMaxLength) && (operationMaxLength > 2);

const KpiDataSourcesList = ({
    fields, disabled, kpiList, kpiListFetching, currentValueAdvCalcSources, advCalcType,
}) => {
    const [filledDataSourcesList, setFilledDataSourcesList] = useState(getFilledDataSourcesList(currentValueAdvCalcSources));

    const handleAddField = (label) => {
        fields.push({ label, type: 'kpi', });
    };

    useEffect(() => {
        if (fields.length === 0) {
            handleAddField(availableLabels[0]);
            handleAddField(availableLabels[1]);
        }
    }, [fields.length]);

    const addFilledDataSourceItem = (index) => {
        if (currentValueAdvCalcSources[index].related_entity_id) {
            setFilledDataSourcesList(
                prevFilledDataSourcesList =>
                    // we can add the same kpi couple times (the same id), we need set his position
                    [...prevFilledDataSourcesList, `${currentValueAdvCalcSources[index].related_entity_id}-${index}`]
            );
        }
    };

    const removeFilledDataSourceItem = (index, isRemoveField = false) => {
        setFilledDataSourcesList(() => {
            const filteredList = filledDataSourcesList.filter(
                id => id !== `${currentValueAdvCalcSources[index].related_entity_id}-${index}`
            );
            return isRemoveField
                ? filteredList.map((indexStr) => {
                    const [id, position] = indexStr.split('-');
                    // when we remove the field, we have to change the position of the elements underneath
                    if (position > index) return `${id}-${position - 1}`;
                    return indexStr;
                })
                : filteredList;
        });
    };

    const handleRemoveField = (index) => {
        removeFilledDataSourceItem(index, true);
        fields.remove(index);
    };

    const operationData = getOperationDataById(advCalcType, operationsData) || {};
    const isShowAddButton = shouldShowAddButton(currentValueAdvCalcSources.length, operationData.maxLength);
    return (
        <>
            {isShowAddButton && (
                <div className={styles.kpiDataSourcesAdd}>
                    Add Data Source
                    <Button
                        className={styles.kpiDataSourceAddButton}
                        type="button"
                        styleType="accent"
                        round
                        withShadow
                        size={Button.sizes.small}
                        onClick={() => handleAddField(getDefaultLabel(currentValueAdvCalcSources))}
                        disabled={disabled}
                    >
                        <Icon name="plus" />
                    </Button>
                </div>
            )}
            <div className={styles.kpiDataSourcesListContainer}>
                <div className={styles.listHeaderContainer}>
                    {headerTitles.map(headerElement => (
                        <span key={headerElement.title} className={styles.listHeaderTitle}>
                            {headerElement.title}
                        </span>
                    ))}
                </div>
                {fields.map((source, index) => (
                    <Fragment key={`${source}`}>
                        {/* toDo check unique key */}
                        {isDataSourceFilled(filledDataSourcesList, currentValueAdvCalcSources[index]?.related_entity_id, index)
                            ? (
                                <PromptProvider onAccept={() => handleRemoveField(index)}>
                                    {prompt => (
                                        <DataSourceItemContent
                                            title={getKpiTitle(kpiList, currentValueAdvCalcSources[index].related_entity_id)}
                                            label={currentValueAdvCalcSources[index].label}
                                            disabled={disabled}
                                            onEdit={() => removeFilledDataSourceItem(index)}
                                            onRemove={prompt.show}
                                        />
                                    )}
                                </PromptProvider>
                            )
                            : (
                                <DataSourcesItemFields
                                    source={source}
                                    kpiList={kpiList
                                        ? getOptListKpiFilteredByAddedDataSource(kpiList, currentValueAdvCalcSources)
                                        : []}
                                    kpiListFetching={kpiListFetching}
                                    fields={fields}
                                    index={index}
                                    onAdd={() => addFilledDataSourceItem(index)}
                                    onRemove={() => handleRemoveField(index)}
                                />
                            )}
                    </Fragment>
                ))}
            </div>
        </>
    );
};

KpiDataSourcesList.defaultProps = {
    kpiList: null,
    advCalcType: null,
};

KpiDataSourcesList.propTypes = {
    fields: PropTypes.object.isRequired, // redux-form, the fields object is a "pseudo-array"
    kpiList: PropTypes.array,
    kpiListFetching: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    currentValueAdvCalcSources: PropTypes.array.isRequired,
    advCalcType: PropTypes.string,
};

export default KpiDataSourcesList;
