import update from 'updeep';
import { createReducer } from 'redux-act';
import { authenticate, logout } from '../authentication/actions';
import { settingsUsersModel } from '../settings-users/entity';
import * as actions from './actions';

const initialState = {
    fetching: true,
    data: {},
    fetchError: null
};

function onProfileGetSuccess(state, response) {
    return update(
        {
            fetching: false,
            data: {
                ...response.user_profile,
                guid: response.guid,
                permissions: response.permissions,
                roles: response.roles,
                email: response.email,
                auth_provider: response.auth_provider,
            }
        },
        state,
    );
}

function onUserUpdate(state, { response }) {
    return update(
        {
            data: {
                ...response.user_profile,
                guid: response.guid,
                permissions: response.permissions,
                roles: response.roles || state.data.roles,
                email: response.email,
            }
        },
        state,
    );
}

function onUpdateUserMFAState(state, payload) {
    return update({
        data: {
            auth_provider: {
                user_metadata: {
                    use_mfa: payload,
                },
            },
        },
    }, state);
}

function onSettingsUserUpdate(state, response) {
    if (state.data.id === response.id) {
        return update(
            {
                data: {
                    ...response.user_profile,
                    guid: response.guid,
                    permissions: response.permissions,
                    roles: response.roles,
                    email: response.email,
                }
            },
            state,
        );
    }
    return state;
}

function handleLogout() {
    return initialState;
}

export default createReducer({
    [actions.getProfileSuccess]: onProfileGetSuccess,
    [actions.updateUser]: onUserUpdate,
    [actions.updateUserMFAState]: onUpdateUserMFAState,
    [authenticate]: onProfileGetSuccess,
    [settingsUsersModel.actions.update.success]: onSettingsUserUpdate,
    [logout]: handleLogout,
}, initialState);
