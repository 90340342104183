import { createSelector, defaultMemoize } from 'reselect';
import { get, orderBy } from 'lodash';
import { name } from './constants';
import { plansListMy } from './entity';

function getPlans(state) {
    return get(state, name, {});
}

export function getPlansItems(state) {
    return getPlans(state).items;
}

export const selectMyPlansOrderBy = defaultMemoize((attribute = 'title') => {
    return createSelector(
        [plansListMy.selectors.getItems],
        items => orderBy(items, el => el?.[attribute]?.toLowerCase())
    );
});
