/** @namespace Utils.ComposeHref */
import querystring from 'querystring';
import * as routeUtils from '../../routes/rules';
import normalizeType, { typeCodes } from './entity-type';
import { isGlobal } from '../../modules/goalmap-milestones/utils';

/**
 * Generate url to entity page by entity and normalized literal type of it
 * @function getTypedUrl
 * @memberof Utils.ComposeHref
 * @param {Object} entity Entity for which link will be created
 * @param {string} safeType Entity type
 * @return {string} Link to entity page
 */
// deprecated, use getTypedUrlByEntityType instead
function getTypedUrl(entity, safeType) {
    const defaultReplacerConfig = {
        [routeUtils.params.PlanId]: entity.goal_id,
        [routeUtils.params.EntityId]: entity.id,
    };

    switch (safeType) {
        case 'goalmap':
        case 'goals':
        case 'goal':
            return routeUtils.createUrl(routeUtils.paths.planNavigator, {
                [routeUtils.params.PlanId]: entity.id
            });

        case 'strategy_map':
        case 'strategy_maps':
        case 'strategymaps':
            return routeUtils.createUrl(routeUtils.paths.planChildrenSegment, defaultReplacerConfig);

        case 'segment':
        case 'segments':
        case 'sub_segment':
            return routeUtils.createUrl(routeUtils.paths.planChildrenStrategyObjective, defaultReplacerConfig);

        case 'milestone':
        case 'milestones': {
            if (isGlobal(entity)) {
                return routeUtils.createUrl(routeUtils.paths.planKPIDetails, defaultReplacerConfig);
            }
            return routeUtils.createUrl(routeUtils.paths.planChildrenKPI, defaultReplacerConfig);
        }

        case 'driver':
        case 'drivers':
            return routeUtils.createUrl(routeUtils.paths.planCSFDetails, defaultReplacerConfig);

        case 'plan_action':
        case 'plans':
        case 'plan_actions':
        case 'plans_actions':
            return routeUtils.createUrl(routeUtils.paths.planChildrenTacticAction, defaultReplacerConfig);

        default:
            return routeUtils.createUrl(routeUtils.paths.dashboard);
    }
}

/**
 * Generate url to entity page by entity and numeric type of it
 * @function getTypedUrlByEntityType
 * @memberof Utils.ComposeHref
 * @param {Object} entity Entity for which link will be created
 * @param {EntityTypeCode} type Normalized entity type
 * @return {string} Link to entity page
 */
function getTypedUrlByEntityType(entity, type) {
    const defaultReplacerConfig = {
        [routeUtils.params.PlanId]: entity.goal_id,
        [routeUtils.params.EntityId]: entity.id,
    };

    switch (type) {
        case typeCodes.plan:
            return routeUtils.createUrl(routeUtils.paths.planNavigator, {
                [routeUtils.params.PlanId]: entity.id
            });

        case typeCodes.segment:
            return routeUtils.createUrl(routeUtils.paths.planChildrenSegment, defaultReplacerConfig);

        case typeCodes.strategy:
        case typeCodes.objective:
            return routeUtils.createUrl(routeUtils.paths.planChildrenStrategyObjective, defaultReplacerConfig);

        case typeCodes.kpi: {
            if (isGlobal(entity)) {
                return routeUtils.createUrl(routeUtils.paths.planKPIDetails, defaultReplacerConfig);
            }
            return routeUtils.createUrl(routeUtils.paths.planChildrenKPI, defaultReplacerConfig);
        }

        case typeCodes.driver:
            return routeUtils.createUrl(routeUtils.paths.planCSFDetails, defaultReplacerConfig);

        case typeCodes.action:
        case typeCodes.tactic:
            return routeUtils.createUrl(routeUtils.paths.planChildrenTacticAction, defaultReplacerConfig);

        default:
            return routeUtils.createUrl(routeUtils.paths.dashboard);
    }
}

/**
 * Enum for action types.
 * @typedef {string} ActionType
 * @memberof Utils.ComposeHref
 * @readonly
 * @enum {ActionType}
 */
export const actionTypes = {
    NOTE_UPDATED: 'note_updated',
    NOTE_USER_MENTIONED: 'note_user_mentioned',
    NOTE_STORE: 'note_store'
};

/**
 * Generate url with query parameters to entity page by entity and literal type
 * @function createHref
 * @memberof Utils.ComposeHref
 * @param {string} type Entity type
 * @param {Object} entity Entity which link will be created
 * @param {Object} query Query parameters added to link
 * @return {string} Link to entity page
 */
// deprecated, use createHrefByTypeCode instead
export default function createHref(type, entity, query) {
    const safeType = normalizeType(type);
    const url = [getTypedUrl(entity, safeType)];

    if (query) {
        url.push(querystring.stringify(query));
    }

    return url.join('?');
}

/**
 * Generate url with query parameters to entity page by entity and numeric type
 * @function createHrefByTypeCode
 * @memberof Utils.ComposeHref
 * @param {EntityTypeCode} type Entity type
 * @param {Object} entity Entity for which link will be created
 * @param {Object} query Query parameters added to link
 * @return {string} Link to entity page
 */
export function createHrefByTypeCode(type, entity, query = undefined) {
    const url = [getTypedUrlByEntityType(entity, type)];

    if (query) {
        url.push(querystring.stringify(query));
    }

    return url.join('?');
}

/**
 * Get query parameter identifier for specified entity and action type for this entity
 * @function getQueryIdentifierByTag
 * @memberof Utils.ComposeHref
 * @param {ActionType} type Action type
 * @param {Object} entity Entity action for which will be processed
 * @return {Object|boolean} Target query parameter
 */
export function getQueryIdentifierByTag(type, entity) {
    if (entity.url_type === 'progress_notes') {
        switch (type) {
            case actionTypes.NOTE_UPDATED:
            case actionTypes.NOTE_USER_MENTIONED:
            case actionTypes.NOTE_STORE:
                return { progress_note_id: entity.id };
            default: return false;
        }
    }
    return false;
}
