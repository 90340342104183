export const slugs = Object.freeze({
    googleDrive: 'google-drive',
    dropbox: 'drop-box',
    oneDrive: 'one-drive',
    box: 'box',
    msTeams: 'ms-teams',
    slack: 'slack',
    zapier: 'zapier',
    publicAPI: 'public-api',
});

export const titles = {
    googleDrive: 'Google Drive',
    box: 'Box',
    dropbox: 'Dropbox',
    oneDrive: 'OneDrive',
    msTeams: 'MS Teams',
    slack: 'Slack',
    publicAPI: 'Public Api',
};

export const storages = [
    slugs.googleDrive,
    slugs.dropbox,
    slugs.oneDrive,
    slugs.box,
];

export const attributes = Object.freeze({
    title: 'title',
    slug: 'slug',
    slack_integration: 'slack_integration',
    ms_teams_integration: 'ms_teams_integration',
    is_active: 'is_active',
    url: 'url',
    sensitivity: 'sensitivity',
    goal_id: 'goal_id',
});

export const integrationAttributes = Object.freeze({
    isActive: 'is_active',
});

export const integrationAttributeBySlug = Object.freeze({
    [slugs.slack]: attributes.slack_integration,
    [slugs.msTeams]: attributes.ms_teams_integration,
});
