import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './table-view-header.css';

export default function TableViewHeader({ children, className }) {
    return (
        <thead>
            <tr className={cx('table-view-header', className)}>
                {children}
            </tr>
        </thead>
    );
}

TableViewHeader.defaultProps = {
    children: undefined,
    className: undefined,
};

TableViewHeader.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
