import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ServicePage404, ServicePage405 } from '../../../modules/service-pages';
import { LoadingIndicator, } from '../../components';

export default class TableRenderer extends Component {
    render() {
        const {
            items,
            fetching,
            itemProps,
            className,
            loadingIndicatorProps,
            error,
            hideOnError,
            toggleDetailsModal,
            getItemProps,
            customRenderFunc,
            customRenderProps,
            pagination,
            lazyPagination
        } = this.props;

        const CustomRender = customRenderFunc;

        const displayedMessage = error && error.code === 400 && 'Error occurred during request, please refresh page.';
        const placeholder = !items.length && (
            customRenderProps?.noResultText ? customRenderProps.noResultText : 'No results'
        );

        if (!fetching && error) {
            if (error) {
                if (hideOnError) return null;
                return displayedMessage;
            }

            if (error.code === 405) {
                return <ServicePage405 />;
            }

            if (error.code !== 400) {
                return <ServicePage404 />;
            }
        }

        if (!fetching && placeholder) {
            return placeholder;
        }

        return (
            <div
                className={
                    cx('list-container', {
                        [className]: className
                    })
                }
            >
                <span>
                    {pagination}
                    <div className={cx('list-container-table-wrapper', { 'list-container-fetching': fetching })}>
                        <CustomRender
                            items={items}
                            itemProps={itemProps}
                            getItemProps={getItemProps}
                            toggleDetailsModal={toggleDetailsModal}
                            {...customRenderProps}
                        />
                        { fetching && (
                            <LoadingIndicator
                                className="list-container-table-loading-indicator"
                                centered
                                {...loadingIndicatorProps}
                            />
                        )}
                    </div>
                    {pagination}
                    {lazyPagination}
                </span>
            </div>
        );
    }
}

TableRenderer.defaultProps = {
    items: [],
    itemProps: {},
    fetching: false,
    className: undefined,
    loadingIndicatorProps: {},
    error: undefined,
    hideOnError: false,
    getItemProps() {
        return {};
    },
    uniqueIdAttribute: 'tag_name',
    customRenderFunc: null,
    customRenderProps: {},
    toggleDetailsModal: () => {},
    pagination: undefined,
    lazyPagination: undefined
};

TableRenderer.propTypes = {
    items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    customRenderProps: PropTypes.object,
    fetching: PropTypes.bool,
    itemProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    toggleDetailsModal: PropTypes.func,
    loadingIndicatorProps: PropTypes.shape({
        centered: PropTypes.bool,
        size: PropTypes.string,
    }),
    error: PropTypes.shape({
        code: PropTypes.number,
    }),
    hideOnError: PropTypes.bool,
    getItemProps: PropTypes.func,
    uniqueIdAttribute: PropTypes.string,
    customRenderFunc: PropTypes.func,
    pagination: PropTypes.node,
    lazyPagination: PropTypes.node
};
