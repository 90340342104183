import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import {
    isValidDate, replaceDayOfWeek, getApiFormattedDate, unsupportedMomentFormats,
} from '../../utils/date';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

export default class DatePicker extends Component {
    get selected() {
        const { input, userDateFormat } = this.props;
        if (!input.value) return null;
        return (unsupportedMomentFormats.includes(userDateFormat) && moment(input.value, userDateFormat).isValid())
            ? moment(input.value, userDateFormat)
            : moment(replaceDayOfWeek(input.value));
    }

    handleChange = (value) => {
        const { input, userDateFormat } = this.props;

        if (value) {
            const format = getApiFormattedDate(value, userDateFormat);
            input.onChange(format);
        } else {
            input.onChange(null);
        }
    };

    handleSelect = (value, event) => {
        if (event.type === 'click') {
            this.handleChange(value);
        }
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            const { value } = e.target;
            if (isValidDate(value)) {
                this.handleChange(value);
            }
        }
    };

    handleOutsideClick = () => {
        this.picker.cancelFocusInput();
    };

    render() {
        const {
            calendarClassName, input, placeholder, userDateFormat, ...rest
        } = this.props;

        return (
            <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="datepicker"
                calendarClassName={classnames('datepicker', {
                    [calendarClassName]: calendarClassName
                })}
                {...rest}
                {...input}
                onKeyDown={this.onKeyDown}
                onSelect={this.handleSelect}
                onChange={() => {}}
                dateFormat={userDateFormat}
                placeholderText={placeholder}
                selected={this.selected}
                value={this.selected}
                ref={(el) => { this.picker = el; }}
                onClickOutside={this.handleOutsideClick}
            />
        );
    }
}

DatePicker.defaultProps = {
    calendarClassName: undefined,
    placeholder: undefined,
};

DatePicker.propTypes = {
    placeholder: PropTypes.string,
    calendarClassName: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        onChange: PropTypes.func,
    }).isRequired,
    userDateFormat: PropTypes.string.isRequired,
};
