import { put, takeEvery, select } from 'redux-saga/effects';
import { details as strategyDetails } from '../../goalmap-segments/entity';
import { list as segmentChildrenList } from '../entity';
import { states } from '../../goalmap-list/config';

function* updateList({ payload }) {
    if (payload.state === states.archived) {
        const { params } = yield select(segmentChildrenList.selector);
        yield put({
            type: segmentChildrenList.actions.request.getType(),
            payload: params
        });
    }
}

export default function* saga() {
    yield takeEvery(strategyDetails.actions.updateStatus.success.getType(), updateList);
}
