import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Modal from '../modal/component';
import Clickable from '../clickable/component';
import Icon from '../icon/component';
import './styles.css';

function ServiceMessageModalCloseButton(props) {
    return (
        <Clickable
            action="hide-modal"
            className="modal-prompt-close-button"
            {...props}
        >
            <Icon name="times" className="modal-prompt-close-button-icon" />
        </Clickable>
    );
}

export default function ServiceMessageModal(props) {
    const {
        className,
        modalContentClassName,
        visible,
        hide,
        children,
    } = props;
    return (
        <Modal
            visible={visible}
            hide={hide}
            className={classnames('modal-prompt', {
                [className]: className
            })}
            modalContentClassName={modalContentClassName}
        >
            {children}
        </Modal>
    );
}

ServiceMessageModal.CloseButton = ServiceMessageModalCloseButton;

ServiceMessageModal.defaultProps = {
    className: undefined,
    modalContentClassName: undefined,
    children: null,
    visible: false,
    hide() { },
};

ServiceMessageModal.propTypes = {
    className: PropTypes.string,
    modalContentClassName: PropTypes.string,
    visible: PropTypes.bool,
    hide: PropTypes.func,
    children: PropTypes.node,
};
