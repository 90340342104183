import { takeEvery, select, put } from 'redux-saga/effects';
import { push, replace } from 'react-router-redux';
import * as localStorageUtils from '../../shared/utils/local-storage';
import { updateGetParameter } from '../../shared/utils/url';
import { authenticate, logout } from '../authentication/actions';
import { setParametersAndNavigate } from './actions';
import { applicationSettingsSelectors } from '../application';
import { localStorageKey } from './config';

function* restorePreviousContext() {
    const isMobile = yield select(applicationSettingsSelectors.isMobileSelector);
    const previousPath = localStorageUtils.get(localStorageKey);
    localStorageUtils.remove(localStorageKey);

    if (isMobile && previousPath && previousPath !== 'undefined') {
        yield put(push(previousPath));
    }
}

function* storePreviousContext() {
    const path = yield select(applicationSettingsSelectors.selectLastRoutingPath);

    localStorageUtils.set(localStorageKey, path);
}

function* setAndNavigate({ payload }) {
    const { parameter, value, isPush = false } = payload;
    const path = yield select(applicationSettingsSelectors.selectLastRoutingPath);
    const newLink = updateGetParameter(path, parameter, value);
    yield put([replace, push][Number(isPush)](newLink));
}

export default function* navigationContextSaga() {
    yield takeEvery(logout.getType(), storePreviousContext);
    yield takeEvery(authenticate.getType(), restorePreviousContext);
    yield takeEvery(setParametersAndNavigate.getType(), setAndNavigate);
}
