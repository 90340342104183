import moment from 'moment';
import { typeCodes } from '../../shared/utils/entity-type';
import { getMonthRangeFromDate } from './utils';

export const ITEM_HEIGHT = 40;
export const filtersFormName = 'work-center-filters';
export const customFiltersFormName = 'work-center-custom-filter';

export const colors = [
    '#DDE3FC',
    '#F7EED8',
    '#FFEBD9',
    '#F2E3DE',
    '#F7ECEF',
    '#FEF0FF',
    '#E6F9FF',
    '#E3F8EF',
    '#EDF3FF',
    '#F0EDFE',
    '#FFF3D6',
    '#E3E7F1',
];
export const defaultColor = colors[11];

export const filterOptionValues = Object.freeze({
    updateRequired: 'update_required',
    // updateFrequency: 'update_frequency',
    draft: 'draft',
    targetDate: 'target_date',
    closedDate: 'closed_date',
    startDate: 'start_date',
    currentHealth: 'current_health',
    historicalHealth: 'historical_health',
    pastDue: 'past_due',
    current: 'current',
    due: 'due_this_week',
    comingDue: 'due_next_30_days',
    startingSoon: 'starting_soon',
    notStarted: 'not_started',
    updatedRecently: 'updated_recently',
    allActive: 'all_active',
    allInactive: 'all_inactive',
});

export const filterOptionValueTitles = Object.freeze({
    [filterOptionValues.updateRequired]: 'Update Required',
    [filterOptionValues.draft]: 'All',
    [filterOptionValues.targetDate]: 'Target Date',
    [filterOptionValues.closedDate]: 'Closed Date',
    [filterOptionValues.startDate]: 'Start Date',
    [filterOptionValues.currentHealth]: 'Current Health',
    [filterOptionValues.historicalHealth]: 'Historical Health',
    [filterOptionValues.pastDue]: 'Past Due',
    [filterOptionValues.current]: 'Current',
    [filterOptionValues.due]: 'Due This Week',
    [filterOptionValues.comingDue]: 'Due Next 30 Days',
    [filterOptionValues.startingSoon]: 'Starting Soon',
    [filterOptionValues.notStarted]: 'Not Started',
    [filterOptionValues.updatedRecently]: 'Updated Recently',
    [filterOptionValues.allActive]: 'All',
    [filterOptionValues.allInactive]: 'All',
});

export const requestParams = Object.freeze({
    startDate: 'start_date',
    endDate: 'end_date',
    periodType: 'period_type',
    filterType: 'calendar_type_filter',
    entityType: 'entity_type_filter',
    quickFilter: 'quick_filter',
    collaboration: 'collaboration',
    customFilter: 'custom_filter',
    customFilterId: 'custom_filter_id',
    customFilterConfig: 'custom_filter_config',
    state: 'entity_state_filter',
});

export const states = Object.freeze({
    active: 1,
    inactive: 5,
    draft: 4,
});

export const defaultFilterOptionValuesByState = Object.freeze({
    [states.active]: filterOptionValues.allActive,
    [states.inactive]: filterOptionValues.allInactive,
    [states.draft]: filterOptionValues.draft,
});

export const typeOptions = [
    {
        icon: 'chess-queen-alt',
        value: typeCodes.tactic.toString(),
        tooltip: 'Tactic',
    },
    {
        icon: 'check-circle',
        value: typeCodes.action.toString(),
        tooltip: 'Action Item',
    },
    {
        icon: 'analytics',
        value: typeCodes.kpi.toString(),
        tooltip: 'Key Performance Indicator',
    },
];

export const extendedTypeOptions = [
    {
        icon: 'puzzle-piece',
        value: typeCodes.segment.toString(),
        tooltip: 'Segment',
    },
    {
        icon: 'user-chart',
        value: typeCodes.strategy.toString(),
        tooltip: 'Strategy',
    },
    {
        icon: 'bullseye-arrow',
        value: typeCodes.objective.toString(),
        tooltip: 'Objective',
    },
    {
        icon: 'check-circle',
        value: typeCodes.action.toString(),
        tooltip: 'Action Item',
    },
    {
        icon: 'analytics',
        value: typeCodes.kpi.toString(),
        tooltip: 'Key Performance Indicator',
    },
    {
        icon: 'trophy-alt',
        value: typeCodes.driver.toString(),
        tooltip: 'Critical Success Factor',
    },
    {
        icon: 'clipboard-list-check',
        value: typeCodes.progressNote.toString(),
        tooltip: 'Progress Note',
    },
];

const formatTypeOption = (option) => ({
    icon: option.icon,
    value: option.value.toString(),
    tooltip: option.tooltip,
});

export const getTypeOptions = (filterValue) => {
    let options;

    switch (filterValue) {
        case filterOptionValues.draft:
            options = extendedTypeOptions;
            break;
        default:
            options = typeOptions;
            break;
    }

    return options.map(formatTypeOption);
};

export const periodTypes = Object.freeze({
    quarterly: 'quarterly',
    month: 'month',
    week: 'week',
});

export const quickFilterValues = Object.freeze({
    myWork: 'my_work',
    myTeams: 'my_teams',
    custom: 'custom',
    default: 'default',
});

export const calendarFilterOptions = [
    { label: 'Update Frequency', value: filterOptionValues.updateRequired }, // we show Frequency but use for back updateRequired
    { label: 'Target Date', value: filterOptionValues.targetDate },
    { label: 'Start Date', value: filterOptionValues.startDate },
    { label: 'Closed Date', value: filterOptionValues.closedDate },
];

export const timelineFilterOptions = [
    { label: 'Current Health', value: filterOptionValues.currentHealth },
    { label: 'Historical Health', value: filterOptionValues.historicalHealth },
];

export const gridFilterOptions = [
    { label: 'Update Required', value: filterOptionValues.updateRequired },
    { label: 'Past Due', value: filterOptionValues.pastDue },
    { label: 'Current', value: filterOptionValues.current },
    { label: 'Coming Due', value: filterOptionValues.comingDue },
    { label: 'Starting Soon', value: filterOptionValues.startingSoon },
    { label: 'Not Started', value: filterOptionValues.notStarted },
    { label: 'Updated Recently', value: filterOptionValues.updatedRecently },
    { label: 'All active', value: filterOptionValues.allActive },
    { label: 'All Inactive', value: filterOptionValues.allInactive },
    { label: 'Draft', value: filterOptionValues.draft },
    { label: 'Due This Week', value: filterOptionValues.due },
];

export const periodOptionsCalendar = [
    { label: 'Month', value: periodTypes.month },
    { label: 'Week', value: periodTypes.week },
];

export const periodOptionsTimeline = [
    { label: 'Quarter', value: periodTypes.quarterly },
    { label: 'Month', value: periodTypes.month },
    { label: 'Week', value: periodTypes.week },
];

const initialRange = getMonthRangeFromDate(moment());

export const filtersInitialValues = {
    [requestParams.state]: states.active,
    [requestParams.entityType]: [],
    [requestParams.periodType]: periodTypes.week,
    [requestParams.filterType]: filterOptionValues.allActive,
    [requestParams.quickFilter]: quickFilterValues.default,
    // [requestParams.collaboration]: false,
    [requestParams.startDate]: initialRange.start,
    [requestParams.endDate]: initialRange.end,
    [requestParams.customFilterConfig]: {},
};

export const workCenterViewTypes = {
    calendar: 'calendar',
    timeline: 'timeline',
    grid: 'grid',
};

export const workCenterDateFormats = {
    default: 'MMM DD, YYYY',
    periodTypeMonth: 'MMMM YYYY',
};

export const workCenterTableViewTypes = {
    plan: 'plan',
    owner: 'owner',
};

export const tableViewTypesOptions = [
    { label: 'Plan', value: workCenterTableViewTypes.plan },
    { label: 'Owner', value: workCenterTableViewTypes.owner },
];

export const tableViewElelementLevels = {
    parent: 1,
    subParent: 2,
    child: 3,
};

export const tableViewItemAttributes = {
    title: 'title',
    health: 'progressStatus',
    owner: 'owner',
    location: 'location',
    status: 'status',
    start_date: 'startAt',
    target_date: 'targetAt',
    closed_date: 'closedDate',
    last_update: 'updatedAt',
    priority: 'priority',
    frequency: 'frequency',
    target_value: 'targetValue',
    current_value: 'currentValue',
    trend: 'trend',
    created_by: 'createdBy',
    description: 'description',
    created_date: 'createdAt',
    plan: 'plan',
    type_code: 'type_code',
};

export const unassignedPlan = {
    id: 'unassigned',
    title: 'Unassigned',
};

export const overrideQueryKey = 'work_center_override';

export const overrideQueryValues = {
    owner: 'owner',
    team: 'team',
    draft: 'draft',
    reminder: 'reminder',
};
