import u from 'updeep';
import request from '../../../request';

export default function getUsers(search, { teamId, ...rest }) {
    let apiUrl = '/users';
    let params = u({ q: search }, rest);

    if (teamId) {
        apiUrl = `/teams/${teamId}/members`;
        params = u({ tag_name: search }, rest);
    }

    return request.get(apiUrl, { params });
}
