import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import {
    LoadingIndicator, Icon, Avatar, Clickable
} from '../../../../shared/components';
import Permissions from '../../../permissions';

import styles from './collaborator-list-item.module.css';

export default function CollaboratorListItem({
    id,
    updatingItemId,
    onRemove,
    user,
    permissions,
    can_watching,
}) {
    const canDestroy = get(permissions, Permissions.keys.canDestroy);

    return (
        <div className={styles.collaboratorItem}>
            {(updatingItemId === id) && (
                <div className="loading-overlay">
                    <LoadingIndicator size="small" centered />
                </div>
            )}

            <div className={styles.collaboratorContent}>
                <Avatar
                    classNameContainer={styles.collaboratorAvatar}
                    size={Avatar.sizes.small}
                    src={user.user_profile.image}
                    first_name={user.user_profile.first_name}
                    last_name={user.user_profile.last_name}
                />
                <div className={styles.collaboratorInfo}>
                    <div className={styles.collaboratorInfoUserName}>
                        {user.user_profile.first_name} {user.user_profile.last_name}
                    </div>
                    <div className={styles.collaboratorInfoTagName}>@{user.user_profile.tag_name}</div>
                    { !can_watching && (
                        <div className={styles.collaboratorInfoWarning}>
                            <Icon
                                className={styles.collaboratorInfoWarningIcon}
                                name="exclamation-circle"
                            />
                            This user does not have permissions to view this item
                        </div>
                    )}
                </div>
            </div>

            { canDestroy && (
                <Clickable
                    styleType="danger"
                    outline
                    onClick={() => onRemove(id, user.id)}
                >
                    <Icon
                        name="trash-alt"
                        className={styles.collaboratorRemoveButtonIcon}
                    />
                </Clickable>
            )}
        </div>
    );
}

CollaboratorListItem.defaultProps = {
    updatingItemId: undefined,
    can_watching: true,
};

CollaboratorListItem.propTypes = {
    can_watching: PropTypes.bool,
    id: PropTypes.number.isRequired,
    updatingItemId: PropTypes.number,
    onRemove: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.number,
        user_profile: PropTypes.object,
    }).isRequired,
    permissions: PropTypes.object.isRequired,
};
