import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './styles.css';

export default function ProgressBar({
    className,
    dividerClassName,
    progress,
    styleType,
    dividersCount,
    children,
    indeterminate
}) {
    const width = indeterminate ? 40 : progress;
    const dividers = [];
    const step = 100 / (dividersCount + 1);

    for (let i = 1; i <= dividersCount; i += 1) {
        dividers.push(
            <div className={classnames(dividerClassName, 'progress-bar-divider')} style={{ left: `${step * i}%` }}>
                <div className="progress-bar-divider-inner" />
            </div>
        );
    }

    return (
        <span
            className={classnames('progress-bar', {
                'progress-bar-content-available': !!children,
                [`progress-bar-style-${styleType}`]: styleType,
                [className]: className,
            })}
        >
            <div
                className={classnames('progress-bar-fill', {
                    'progress-bar-fill-indeterminate': indeterminate,
                })}
                style={{
                    width: `${width}%`
                }}
            >
                <span className="progress-bar-label">
                    {children}
                </span>
            </div>
            {dividers}
        </span>
    );
}

ProgressBar.defaultProps = {
    className: undefined,
    dividerClassName: undefined,
    children: undefined,
    indeterminate: false,
    dividersCount: 0,
    styleType: undefined
};

ProgressBar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dividerClassName: PropTypes.string,
    progress: PropTypes.number.isRequired,
    styleType: PropTypes.string,
    indeterminate: PropTypes.bool,
    dividersCount: PropTypes.number
};
