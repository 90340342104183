import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isBool from '../../../shared/utils/bool';
import './styles.css';

const Toggle = (props) => {
    const {
        id,
        name,
        checked,
        disabled,
        onChange,
        className,
        input
    } = props;

    let isChecked = false;

    if (isBool(checked) && checked) {
        isChecked = checked;
    } else if (isBool(input.value) || input.value === 1) {
        isChecked = input.value;
    }

    return (
        <label
            className={classnames('switch', {
                [className]: className,
                disabled,
            })}
            htmlFor={id}
        >
            <input
                type="checkbox"
                checked={isChecked}
                className="checkbox-custom"
                id={id}
                name={name}
                disabled={disabled}
                onChange={onChange || input.onChange}
                {...input}
            />
            <span className="slider round" />
        </label>
    );
};

Toggle.defaultProps = {
    className: undefined,
    input: {},
    checked: false,
    disabled: false,
    onChange() {},
    id: undefined,
    name: undefined
};

Toggle.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

// from legacy component there is an empty string reported as a value
// when it should be `false`
Toggle.parseValue = (value) => {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') {
        if (value === '') return false;
        return true;
    }

    return value;
};

export default Toggle;
