/* eslint-disable class-methods-use-this */
import { createAction } from 'redux-act';
import u from 'updeep';
import { get, cloneDeep, flow } from 'lodash';
import Model from '../../shared/entities-v2/model/model';
import ModelCachePlugin from '../../shared/entities-v2/plugins/model-cache-plugin/model-cache-plugin';
import parseData from '../../shared/modules/with-move-entity/parse';
import { findPathToItem } from '../../shared/modules/with-move-entity/utils';
import { handleMoveItem } from '../../shared/modules/with-move-entity/base-reducer';

export const name = 'navigation-goalmap-tree';
export const cachePluginName = `${name}-cache`;

export const nodeUpdate = createAction(`${name}/NODE_UPDATE`);
export const nodeAdd = createAction(`${name}/NODE_ADD`);
export const nodeMove = createAction(`${name}/NODE_MOVE`);
export const nodeReturnPreviousState = createAction(
    `${name}/NODE_RETURN_PREVIOUS_STATE`
);

function handleNodeUpdate(state, { context, response }) {
    const path = findPathToItem(
        state.items,
        context.entityTypeCode,
        context.id
    );
    return u.updateIn(['items', ...path], response, state);
}

function handleNodeAdd(state, { context, response }) {
    const path = findPathToItem(
        state.items,
        context.entityTypeCode,
        context.id
    );
    const children = get(state.items, [...path, 'children']);
    const result = [...children, response];

    return u.updateIn(['items', ...path, 'children'], result, state);
}

function handleNodeReturnPreviousState(state, { items }) {
    return u({ items }, state);
}

class TreeModel extends Model {
    parse(response) {
        return {
            response: {
                data: flow(
                    parseData,
                    cloneDeep
                )(response),
            }
        };
    }
}

const treeModel = new TreeModel({
    name,
    getApiUrl(params) {
        return `/goals/${params.id}/tree`;
    },
    plugins: [
        new ModelCachePlugin({ name: cachePluginName }),
    ],
    additionalArtifacts: {
        [Model.additionalArtifacts.reducers]: {
            [nodeUpdate]: handleNodeUpdate,
            [nodeAdd]: handleNodeAdd,
            [nodeMove]: handleMoveItem,
            [nodeReturnPreviousState]: handleNodeReturnPreviousState,
        },
    },
});

export const treeModelCachePlugin = treeModel.getPluginInstanse(cachePluginName);

export default treeModel;
