import config from '../../../config';
import { createUrl, paths } from '../../../routes/rules';

export const redirectPath = createUrl(paths.preferencesStorageServices);
export const redirectUri = `${config.origin}${redirectPath}`;

export const slugs = Object.freeze({
    'local': 's3',
    'google-drive': 'google-drive',
    'drop-box': 'drop-box',
    'one-drive': 'one-drive',
    'box': 'box',
});
