import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash';

import CreateSegmentModal from '../../../modules/goalmap-strategy-maps/create/modal';
import CreateStrategyModal from '../../../modules/goalmap-segments/create/modal';
import CreateStrategyChildrenModal from '../../../modules/goalmap-segment-children/create/modal';
import CreateObjectiveChildrenModal from '../../../modules/goalmap-segment-children/create-wizard-objective-children/modal';
import CreateTacticChildrenModal from '../../../modules/goalmap-plans-actions/create/modal';
import CreateGlobalKPI from '../../../modules/goalmap-milestones/create/modal';
import { CsfCreateModal } from '../../../modules/primary-entities/critical-success-factor';

import { selectInitialValues, selectParentData } from './primary-entity-create-service-selectors';
import { success, error } from './primary-entity-create-service-actions';
import { reasons } from './primary-entity-create-constants';

const sensitivities = {
    1: 'standard',
    2: 'restricted',
    3: 'confidential',
};

function PrimaryEntityCreateService({ planId, onCreateSuccess }) {
    const dispatch = useDispatch();
    const initialValues = useSelector(selectInitialValues);
    const parentData = useSelector(selectParentData);

    const handleCreateSuccess = (response) => {
        dispatch(success(response));
        onCreateSuccess(response);
    };

    const handleCancelRemove = useCallback(() => {
        dispatch(error({ reason: reasons.removed }));
    }, [dispatch]);

    const handleCancel = useCallback(() => {
        dispatch(error({ reason: reasons.cancelled }));
    }, [dispatch]);

    return (
        <Fragment>
            <CreateSegmentModal
                goalId={planId}
                initialValues={initialValues}
                onFinished={handleCreateSuccess}
                onCancel={handleCancel}
                submitTitle="Publish"
            />

            <CreateStrategyModal
                initialValues={initialValues}
                goalId={planId}
                onFinished={handleCreateSuccess}
                onCancel={handleCancel}
                submitTitle="Publish"
            />

            <CreateStrategyChildrenModal
                sensitivities={sensitivities}
                goalId={planId}
                parentEntityData={parentData}
                onFinished={handleCreateSuccess}
                onRemoveSuccess={handleCancelRemove}
                onCancel={handleCancel}
                submitTitle="Publish"
            />

            <CreateObjectiveChildrenModal
                sensitivities={sensitivities}
                goalId={planId}
                parentEntityData={parentData}
                onFinished={handleCreateSuccess}
                onRemoveSuccess={handleCancelRemove}
                onCancel={handleCancel}
                submitTitle="Publish"
            />

            <CreateTacticChildrenModal
                sensitivities={sensitivities}
                goalId={planId}
                parentEntityData={parentData}
                onFinished={handleCreateSuccess}
                onRemoveSuccess={handleCancelRemove}
                onCancel={handleCancel}
                submitTitle="Publish"
            />

            <CreateGlobalKPI
                goal_id={planId}
                goalId={planId}
                onFinished={handleCreateSuccess}
                onRemoveSuccess={handleCancelRemove}
                onCancel={handleCancel}
                submitTitle="Publish"
            />

            <CsfCreateModal
                goalId={planId}
                initialValues={initialValues}
                onFinished={handleCreateSuccess}
                onRemoveSuccess={handleCancelRemove}
                onCancel={handleCancel}
                submitTitle="Publish"
            />
        </Fragment>
    );
}

PrimaryEntityCreateService.defaultProps = {
    onCreateSuccess: noop,
};

PrimaryEntityCreateService.propTypes = {
    planId: PropTypes.number.isRequired,
    onCreateSuccess: PropTypes.func,
};

export default PrimaryEntityCreateService;
