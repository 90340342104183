import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './form-group.css';

export default function FormGroup({
    id,
    label,
    children,
    className,
    labelClassName,
    containerClassName,
    ...rest
}) {
    return (
        <div
            className={classnames('input-container', {
                [containerClassName]: containerClassName
            })}
            {...rest}
        >
            {label && (
                // eslint-disable-next-line jsx-a11y/label-has-for
                <label
                    htmlFor={id}
                    className={classnames('input-label', {
                        [labelClassName]: labelClassName
                    })}
                >
                    {label}
                </label>
            )}

            {children}
        </div>

    );
}

FormGroup.defaultProps = {
    id: undefined,
    containerClassName: undefined,
    labelClassName: undefined,
    className: undefined,
    children: undefined,
    label: undefined,
};

FormGroup.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    className: PropTypes.string,
};
