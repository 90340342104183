import React, { Fragment, useContext, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon, Clickable } from '../../../shared/components';
import { AppContext } from '../../app-context/component';
import QuickAddModal from './quick-add-modal';
import quickAddModel from './quick-add-model';

import './styles.css';

function NavigationHeaderQuickAdd({ canCreateDraft, toggle, }) {
    const context = useContext(AppContext);
    const { quickAddSuccessCallback, } = context;

    return (
        <Fragment>
            <Clickable
                action="show-progress-note-create-modal"
                className="nav-header-quick-add"
                onClick={toggle}
            >
                <Button
                    className="nav-header-quick-add-button"
                    styleType={Button.styleTypes.accent}
                    size={Button.sizes.medium}
                >
                    <Icon type="solid" name="plus-circle" className="nav-header-quick-add-icon" />
                    <span className="nav-header-quick-add-label">Quick Add</span>
                </Button>
            </Clickable>

            <div style={{ display: 'none' }}>
                <QuickAddModal
                    assignNeeded
                    canCreateDraft={canCreateDraft}
                    onCreateSuccess={quickAddSuccessCallback}
                />
            </div>
        </Fragment>
    );
}

NavigationHeaderQuickAdd.propTypes = {
    toggle: PropTypes.func.isRequired,
    canCreateDraft: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    toggle: quickAddModel.actions.toggle,
};

export default connect(null, mapDispatchToProps)(NavigationHeaderQuickAdd);
