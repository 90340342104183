import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Media } from 'react-breakpoints';
import classnames from 'classnames';

import DeprecatedRouteRedirectService from '../../../routes/deprecated-routes-redirect';
import ColorThemeController from '../color-theme/color-theme-controller';
import NavigationHeader from '../../navigation-header/container';
import * as colorThemeActions from '../color-theme/color-theme-actions';

import { LoadingIndicator } from '../../../shared/components';
import { ErrorBoundary } from '../../../shared/modules';
import AppUpdatingPopup from '../components/app-updating-popup/component';
import AppUpdatingBubble from '../components/app-updating-buble/component';
import { getIsAuthorizedState, getIsAuthenticationView } from '../../authentication/selectors';
import { initAuth0Service, checkAuthentication } from '../../authentication/actions';
import AuthenticationRecoveryUsedModal from '../../authentication/recovery-used-message/authentication-recovery-used-modal';
import SSOProvidersExpirationModal from '../../authentication/sso-provider-expiration-modal/sso-provider-expiration-modal';
import {
    getServiceWorkerUpdatingState,
    getIsServiceWorkerUpdatingInBackground
} from '../service-worker/service-worker-selectors';
import serviceWorkerActions from '../service-worker/service-worker-actions';
import { updatingState } from '../service-worker/service-worker-constants';
import ChangePasswordService from '../../profile/change-password/change-password-service';
import ChangeEmailService from '../../profile/change-email/change-email-service';
import InstallableAppIosBanner from '../installable-app-ios-banner/installable-app-ios-banner';
import applicationSettingsModel from '../settings-data/application-settings-model';
import permissionModel from '../../permissions/permission-model';
import ApplicationIntegrations from '../integrations/integrations';
import ApplicationLocalizations from '../localizations/localizations';
import NetworkStatus from '../network-status/network-status';

import './application-layout.css';

class ApplicationLayout extends Component {
    componentWillMount() {
        const {
            getSettings,
            setTheme,
            setAccentColor,
            initAuth,
            checkAuth,
            initServiceWorker
        } = this.props;

        initAuth();
        checkAuth();

        getSettings({}, {
            onSuccess(response) {
                setTheme({
                    theme: response.theme,
                });

                setAccentColor({
                    color: response.accent,
                });

                if (process.env.NODE_ENV !== 'development') {
                    initServiceWorker(response.onesignal_app_id);
                }
            }
        });
    }

    renderUnauthorizedContent = () => this.props.children;

    renderAuthorizedContent = ({ breakpoints, currentBreakpoint }) => {
        const { permissions } = this.props;

        if (isEmpty(permissions)) return null;

        const isMobile = breakpoints[currentBreakpoint] < breakpoints.medium;

        return isMobile
            ? this.renderMobile()
            : this.renderDesktop();
    }

    renderDesktop() {
        const { children, location } = this.props;

        return (
            <Fragment>
                <AuthenticationRecoveryUsedModal />
                <SSOProvidersExpirationModal />
                <ApplicationIntegrations />
                <ApplicationLocalizations />
                <NavigationHeader location={location} />
                <div className="app-content">
                    <ErrorBoundary>
                        {children}
                    </ErrorBoundary>
                </div>
            </Fragment>
        );
    }

    renderMobile() {
        const { children } = this.props;

        return (
            <Fragment>
                <ApplicationLocalizations />
                {children}
                <InstallableAppIosBanner />
            </Fragment>
        );
    }

    render() {
        const {
            isAuthorized, appUpdatingState, router, location, fetchingPermission, isAppBackgroundUpdating,
            isAuthenticationView,
        } = this.props;

        const contentRender = isAuthorized
            ? this.renderAuthorizedContent
            : this.renderUnauthorizedContent;

        return (
            <ColorThemeController
                id={classnames({ 'app-container': !isAuthenticationView })}
            >
                {!isAppBackgroundUpdating && (
                    <AppUpdatingPopup visible={appUpdatingState === updatingState.updating} />
                )}
                {isAppBackgroundUpdating && (
                    <AppUpdatingBubble state={appUpdatingState} />
                )}
                <DeprecatedRouteRedirectService
                    router={router}
                    location={location}
                />
                <NetworkStatus />
                <Media>
                    {mediaProps => (
                        <Fragment>
                            {(fetchingPermission) ? (
                                <div className="loading-overlay fixed">
                                    <LoadingIndicator
                                        centered
                                        size="large"
                                    />
                                </div>
                            ) : contentRender(mediaProps)}

                            <ChangePasswordService />
                            <ChangeEmailService />
                        </Fragment>
                    )}
                </Media>
            </ColorThemeController>
        );
    }
}

ApplicationLayout.defaultProps = {
    children: undefined,
    appUpdatingState: undefined,
    isAppBackgroundUpdating: undefined
};

ApplicationLayout.propTypes = {
    children: PropTypes.node,
    getSettings: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    setTheme: PropTypes.func.isRequired,
    setAccentColor: PropTypes.func.isRequired,
    initAuth: PropTypes.func.isRequired,
    checkAuth: PropTypes.func.isRequired,
    initServiceWorker: PropTypes.func.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    appUpdatingState: PropTypes.string,
    fetchingPermission: PropTypes.bool.isRequired,
    isAppBackgroundUpdating: PropTypes.bool,
    isAuthenticationView: PropTypes.bool.isRequired,
    permissions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        isAuthorized: getIsAuthorizedState(state),
        appUpdatingState: getServiceWorkerUpdatingState(state),
        isAppBackgroundUpdating: getIsServiceWorkerUpdatingInBackground(state),
        fetchingPermission: permissionModel.selectors.getStateFetching(state),
        isAuthenticationView: getIsAuthenticationView(state),
        permissions: permissionModel.selectors.getAttributes(state),
    };
}

const mapDispatchToProps = {
    getSettings: applicationSettingsModel.actions.fetch.request,
    setTheme: colorThemeActions.setTheme,
    setAccentColor: colorThemeActions.setAccentColor,
    initAuth: initAuth0Service,
    checkAuth: checkAuthentication,
    initServiceWorker: serviceWorkerActions.initialize,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLayout);
