import { takeEvery, put } from 'redux-saga/effects';
import request from '../../request';
import { Collection, Model } from '../../shared/entities-v2';
import createAsyncActions from '../../shared/entities-v2/base/async-actions';
import { handleUpdateRequest, handleUpdateSuccess, } from '../../shared/entities-v2/model/reducer';
import formatRequestPayload from '../../shared/entities-v2/utils/format-request-payload';
import { listActions, handleMarkAllRead } from '../navigation-header/notifications/entity';

const apiUrl = '/desktop_notifications';
const notificationModelName = 'notification-model';
const notificationsCollectionName = 'notifications-desktop-collection';

const markAllNotificationsRead = createAsyncActions(`${notificationModelName}/mark-all-notifications-read`, formatRequestPayload);

export const notificationsDesktopCollection = new Collection({
    getApiUrl: () => apiUrl,
    name: notificationsCollectionName,
    additionalArtifacts: {
        [Collection.additionalArtifacts.reducers]: {
            [markAllNotificationsRead.success]: handleMarkAllRead,
        }
    }
});

class NotificationModel extends Model {
    update(data, options) {
        const action = data.is_read ? 'unread' : 'read';
        return request.put(`${apiUrl}/${data.id}/${action}`, data.params, options)
            .then(response => ({ ...response.data, total_unread: response.total_unread }), this.parseError);
    }
}

export const notificationModel = new NotificationModel({
    getApiUrl: () => apiUrl,
    name: notificationModelName,
    additionalArtifacts: {
        [Model.additionalArtifacts.actions]: {
            markAllNotificationsRead,
        },
        [Model.additionalArtifacts.reducers]: {
            [markAllNotificationsRead.request]: handleUpdateRequest,
            [markAllNotificationsRead.success]: handleUpdateSuccess,
        },
        [Model.additionalArtifacts.api]: {
            markAllNotificationsRead: () => {
                return request
                    .put(`${apiUrl}/read`)
                    .catch((err) => { throw err; });
            },
        }
    }
});

export function* saga() {
    yield takeEvery(
        notificationModel.actions.update.request,
        function* statusUpdateStart(action) {
            yield put({
                type: notificationsDesktopCollection.actions.setUpdatingItemId.getType(),
                payload: { id: action.payload.id }
            });
        }
    );

    yield takeEvery(
        notificationModel.actions.update.success,
        function* statusUpdateSuccess({ payload }) {
            yield put({
                type: notificationsDesktopCollection.actions.setUpdatingItemId.getType(),
                payload: { id: undefined }
            });

            yield put({
                type: notificationsDesktopCollection.actions.update.getType(),
                payload,
            });
        }
    );

    yield takeEvery(
        notificationModel.actions.markAllNotificationsRead.success,
        function* markAllReadSaga() {
            yield put({
                type: listActions.markAllRead.getType(),
            });
        }
    );
}
