import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { flow, get } from 'lodash';
import {
    GridContainer,
    Form,
    Row,
    Col,
    Text,
} from '../../../shared/components';
import { getIconByType as getEntityIcon } from '../../../shared/utils/entity';
import { types as entityTypes } from '../../../shared/utils/entity-type';
import { subscriptionFields, subscriptionLimitSelectors, SubscriptionLimitProviders } from '../../../modules/subscription';
import { snapPoints, getSnapPointCoordinates } from './config';
import ContactSalesMessage from './entity/contact-sales-message';
import StructureEntity from './entity/structure-entity';
import StructureEntityDescription from './entity/structure-entity-description';
import StructureEntityTitleField from './entity/structure-entity-title-field';
import StructureRelationArrow from './relation-arrow/structure-relation-arrow';
import dictionary from './dictionary';
import './styles.css';
import { hasDifferentProps } from '../../utils/object';

const refKeys = Object.freeze({
    strategicPlan: 'strategic-plan',
    segment1: 'segment-1',
    segment2: 'segment-2',

    strategy1: 'strategy-1',
    strategy2: 'strategy-2',
    strategy3: 'strategy-3',
    strategy4: 'strategy-4',

    objective1: 'objective-1',
    objective2: 'objective-2',

    tactic1: 'tactic-1',
    tactic2: 'tactic-2',

    action1: 'action-1',
    action2: 'action-2',
    action3: 'action-3',
    action4: 'action-4',

    kpi: 'kpi',
    kpi1: 'kpi1',
    kpi2: 'kpi2',
    csf: 'csf',

    tacticActionItemKpiSection: 'tactic-action-item-kpi-section',

    strategicPlanDetails: 'strategic-plan-details',
    segmentDetails: 'segment-details',
    strategyDetails: 'strategy-details',
    objectiveDetails: 'objective-details',
    tacticDetails: 'tactic-details',
    actionDetails: 'action-details',
    kpiDetails: 'kpi-details',
    csfDetails: 'csf-details',
});

const formName = 'goalmap-structure';
const formSelector = formValueSelector(formName);

const relations = Object.freeze([
    {
        parent: refKeys.strategicPlan,
        child: refKeys.segment1,
        parentSnapPoint: snapPoints.bottomHalfLeftCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.strategicPlan,
        child: refKeys.kpi2,
        parentSnapPoint: snapPoints.left,
        childSnapPoint: snapPoints.left,
    },
    {
        parent: refKeys.strategicPlan,
        child: refKeys.csf,
        parentSnapPoint: snapPoints.left,
        childSnapPoint: snapPoints.left,
    },
    {
        parent: refKeys.strategicPlan,
        child: refKeys.segment2,
        parentSnapPoint: snapPoints.bottomHalfRightCenter,
        childSnapPoint: snapPoints.topCenter,
    },

    {
        parent: refKeys.segment1,
        child: refKeys.strategy1,
        parentSnapPoint: snapPoints.bottomHalfLeftCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.segment1,
        child: refKeys.strategy2,
        parentSnapPoint: snapPoints.bottomHalfRightCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.segment2,
        child: refKeys.strategy3,
        parentSnapPoint: snapPoints.bottomHalfLeftCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.segment2,
        child: refKeys.strategy4,
        parentSnapPoint: snapPoints.bottomHalfRightCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.strategy1,
        child: refKeys.objective1,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.strategy2,
        child: refKeys.objective2,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.strategy3,
        child: refKeys.action1,
        border: refKeys.tacticActionItemKpiSection,
        borderSnapPoint: snapPoints.top,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.strategy4,
        child: refKeys.kpi,
        border: refKeys.tacticActionItemKpiSection,
        borderSnapPoint: snapPoints.top,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.objective1,
        child: refKeys.action2,
        border: refKeys.tacticActionItemKpiSection,
        borderSnapPoint: snapPoints.top,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.objective2,
        child: refKeys.kpi1,
        border: refKeys.tacticActionItemKpiSection,
        borderSnapPoint: snapPoints.top,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.tactic1,
        child: refKeys.action2,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
    {
        parent: refKeys.tactic1,
        child: refKeys.kpi1,
        parentSnapPoint: snapPoints.bottomCenter,
        childSnapPoint: snapPoints.topCenter,
    },
]);

function getTitle(currentValues, type) {
    return get(currentValues, ['settings', type]);
}

class GoalMapStructure extends Component {
    entityRefs = {};

    state = {
        editingField: undefined
    };

    componentDidMount() {
        this.forceUpdate();
        this.startListenResize();
    }

    componentDidUpdate(prevProps) {
        if (hasDifferentProps(
            prevProps,
            this.props,
            ['currentValues', 'kpiLimits', 'csfLimits'],
            true
        )) {
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        this.stopListenResize();
    }

    handleWindowResize = () => {
        this.forceUpdate();
    };

    storeRef = (element) => {
        if (!element) {
            return;
        }

        const refKey = element.dataset.refkey;
        this.entityRefs[refKey] = element;
    }

    handleEditStart = (field) => {
        const { submitting } = this.props;

        if (submitting) {
            return;
        }

        this.reInitializeForm();

        this.setState({
            editingField: field,
        });
    }

    handleEditEnd = () => {
        this.setState({
            editingField: undefined,
        });
    }

    handleEditCancel = () => {
        this.reInitializeForm();
        this.handleEditEnd();
    }

    startListenResize() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    stopListenResize() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    reInitializeForm() {
        const { initialize, initialValues } = this.props;

        initialize(initialValues);
    }

    renderRelation = (item) => {
        const {
            parent, child, parentSnapPoint, childSnapPoint, borderSnapPoint, border,
        } = item;
        const { topOffset } = this.props;

        if (parent in this.entityRefs && child in this.entityRefs) {
            const parentCoords = getSnapPointCoordinates(this.entityRefs[parent], parentSnapPoint, topOffset);
            const childCoords = getSnapPointCoordinates(this.entityRefs[child], childSnapPoint, topOffset);
            const borderCoords = (border && border in this.entityRefs)
                ? getSnapPointCoordinates(this.entityRefs[border], borderSnapPoint, topOffset)
                : undefined;

            return (
                <StructureRelationArrow
                    key={`${parent}-${child}`}
                    parentCoords={parentCoords}
                    childCoords={childCoords}
                    borderCoords={borderCoords}
                    strokeDasharray={item.strokeDasharray}
                    color={item.color}
                />
            );
        }

        return null;
    }

    render() {
        const {
            currentValues,
            submitting,
            onSubmit,
            handleSubmit,
            dirty
        } = this.props;

        const { editingField } = this.state;

        return (
            <div className="goalmap-structure">
                <Form
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    onSuccessSubmit={this.handleEditEnd}
                    dirty={dirty}
                    useNavigationBlocking
                >
                    <div className="goalmap-structure-section">
                        <GridContainer className="goalmap-structure-section-content">
                            <Row>
                                <Col>
                                    <Text component="h5" className="goalmap-structure-section-title">
                                        Required Hierarchy <Text styleType="danger">*</Text>
                                    </Text>
                                </Col>
                            </Row>
                            <Row className={StructureEntity.rowClassName}>
                                <Col md={8}>
                                    <div
                                        data-refkey={refKeys.strategicPlan}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity
                                            large
                                            color={StructureEntity.colors.purple}
                                        >
                                            {getTitle(currentValues, entityTypes.goal)}
                                        </StructureEntity>
                                    </div>
                                </Col>

                                <Col md={3} mdOffset={1}>
                                    <div
                                        data-refkey={refKeys.strategicPlanDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.goal && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.goal}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            color={StructureEntity.colors.purple}
                                            icon={getEntityIcon(entityTypes.goal)}
                                            name={entityTypes.goal}
                                            title={getTitle(currentValues, entityTypes.goal)}
                                            description={dictionary.strategicPlan}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className={StructureEntity.rowClassName}>
                                <Col md={4}>
                                    <div
                                        data-refkey={refKeys.segment1}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.green}>
                                            {getTitle(currentValues, entityTypes.strategy_map)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div
                                        data-refkey={refKeys.segment2}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.green}>
                                            {getTitle(currentValues, entityTypes.strategy_map)}
                                        </StructureEntity>
                                    </div>
                                </Col>

                                <Col md={3} mdOffset={1}>
                                    <div
                                        data-refkey={refKeys.segmentDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.strategy_map && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.strategy_map}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            color={StructureEntity.colors.green}
                                            icon={getEntityIcon(entityTypes.strategy_map)}
                                            name={entityTypes.strategy_map}
                                            title={getTitle(
                                                currentValues,
                                                entityTypes.strategy_map
                                            )}
                                            description={dictionary.segment}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className={StructureEntity.rowClassName}>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.strategy1}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.orange}>
                                            {getTitle(currentValues, entityTypes.segment)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.strategy2}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.orange}>
                                            {getTitle(currentValues, entityTypes.segment)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.strategy3}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.orange}>
                                            {getTitle(currentValues, entityTypes.segment)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.strategy4}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.orange}>
                                            {getTitle(currentValues, entityTypes.segment)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={3} mdOffset={1}>
                                    <div
                                        data-refkey={refKeys.strategyDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.segment && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.segment}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            color={StructureEntity.colors.orange}
                                            icon={getEntityIcon(entityTypes.segment)}
                                            name={entityTypes.segment}
                                            title={getTitle(currentValues, entityTypes.segment)}
                                            description={dictionary.strategy}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </GridContainer>
                    </div>

                    <div className="goalmap-structure-section">
                        <GridContainer className="goalmap-structure-section-content">
                            <Row>
                                <Col>
                                    <Text component="h5" className="goalmap-structure-section-title">
                                        Optional Hierarchy
                                    </Text>
                                </Col>
                            </Row>
                            <Row className={StructureEntity.rowClassName}>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.objective1}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.yellow}>
                                            {getTitle(currentValues, entityTypes.sub_segment)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.objective2}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.yellow}>
                                            {getTitle(currentValues, entityTypes.sub_segment)}
                                        </StructureEntity>
                                    </div>
                                </Col>

                                <Col md={3} mdOffset={5}>
                                    <div
                                        data-refkey={refKeys.objectiveDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.sub_segment && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.sub_segment}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            color={StructureEntity.colors.yellow}
                                            icon={getEntityIcon(entityTypes.sub_segment)}
                                            name={entityTypes.sub_segment}
                                            title={getTitle(currentValues, entityTypes.sub_segment)}
                                            description={dictionary.objective}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </GridContainer>
                    </div>

                    <div
                        data-refkey={refKeys.tacticActionItemKpiSection}
                        ref={this.storeRef}
                        className="goalmap-structure-section"
                    >
                        <GridContainer className="goalmap-structure-section-content tactic-ai-kpi-section-content">
                            <Row>
                                <Col>
                                    <Text component="h5" className="goalmap-structure-section-title">
                                        Strategic Activities
                                    </Text>
                                </Col>
                            </Row>
                            <Row className={StructureEntity.rowClassName}>
                                <Col md={2} mdOffset={1}>
                                    <div
                                        data-refkey={refKeys.tactic1}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.red}>
                                            {getTitle(currentValues, entityTypes.plan)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={2} mdOffset={1}>
                                    <div
                                        data-refkey={refKeys.action1}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.blue}>
                                            {getTitle(currentValues, entityTypes.action)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.kpi}
                                        ref={this.storeRef}
                                    >
                                        <SubscriptionLimitProviders.KPI>
                                            {({ value }) => (
                                                <Fragment>
                                                    <StructureEntity
                                                        inactive={!value}
                                                        color={StructureEntity.colors.breakerBay}
                                                    >
                                                        {getTitle(
                                                            currentValues,
                                                            entityTypes.milestone
                                                        )}
                                                    </StructureEntity>

                                                    {!value && (
                                                        <ContactSalesMessage />
                                                    )}
                                                </Fragment>
                                            )}
                                        </SubscriptionLimitProviders.KPI>
                                    </div>
                                </Col>
                                <Col md={3} mdOffset={1}>
                                    <div
                                        data-refkey={refKeys.tacticDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.plan && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.plan}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            color={StructureEntity.colors.red}
                                            icon={getEntityIcon(entityTypes.plan)}
                                            name={entityTypes.plan}
                                            title={getTitle(currentValues, entityTypes.plan)}
                                            description={dictionary.tactic}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className={StructureEntity.rowClassName}>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.action2}
                                        ref={this.storeRef}
                                    >
                                        <StructureEntity color={StructureEntity.colors.blue}>
                                            {getTitle(currentValues, entityTypes.action)}
                                        </StructureEntity>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div
                                        data-refkey={refKeys.kpi1}
                                        ref={this.storeRef}
                                    >
                                        <SubscriptionLimitProviders.KPI>
                                            {({ value }) => (
                                                <Fragment>
                                                    <StructureEntity
                                                        inactive={!value}
                                                        color={StructureEntity.colors.breakerBay}
                                                    >
                                                        {getTitle(
                                                            currentValues,
                                                            entityTypes.milestone
                                                        )}
                                                    </StructureEntity>

                                                    {!value && (
                                                        <ContactSalesMessage />
                                                    )}
                                                </Fragment>
                                            )}
                                        </SubscriptionLimitProviders.KPI>
                                    </div>
                                </Col>
                                <Col md={3} mdOffset={5}>
                                    <div
                                        data-refkey={refKeys.actionDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.action && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.action}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            color={StructureEntity.colors.blue}
                                            icon={getEntityIcon(entityTypes.action)}
                                            name={entityTypes.action}
                                            title={getTitle(currentValues, entityTypes.action)}
                                            description={dictionary.action}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </GridContainer>
                    </div>

                    <div className="goalmap-structure-section">
                        <GridContainer className="goalmap-structure-section-content">
                            <Row>
                                <Col>
                                    <Text component="h5" className="goalmap-structure-section-title">
                                        Global Activities
                                    </Text>
                                </Col>
                            </Row>
                            <Row className={StructureEntity.rowClassName}>
                                {/* <Col md={1} /> */}
                                <Col md={4}>
                                    <div
                                        className="structure-entity-kpi-wrapper"
                                        data-refkey={refKeys.kpi2}
                                        ref={this.storeRef}
                                    >
                                        <SubscriptionLimitProviders.KPI>
                                            {({ value }) => (
                                                <Fragment>
                                                    <StructureEntity
                                                        inactive={!value}
                                                        color={StructureEntity.colors.breakerBay}
                                                    >
                                                        {getTitle(
                                                            currentValues,
                                                            entityTypes.milestone
                                                        )}
                                                    </StructureEntity>

                                                    {!value && (
                                                        <ContactSalesMessage />
                                                    )}
                                                </Fragment>
                                            )}
                                        </SubscriptionLimitProviders.KPI>
                                    </div>
                                </Col>

                                <Col md={3} mdOffset={5}>
                                    <div
                                        className="structure-entity-description-wrapper"
                                        data-refkey={refKeys.kpiDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.milestone && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field kpi-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.milestone}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            className="structure-entity-description-kpi"
                                            color={StructureEntity.colors.breakerBay}
                                            icon={getEntityIcon(entityTypes.milestone)}
                                            name={entityTypes.milestone}
                                            title={getTitle(currentValues, entityTypes.milestone)}
                                            description={dictionary.kpi}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className={StructureEntity.rowClassName}>
                                <Col md={4}>
                                    <div
                                        data-refkey={refKeys.csf}
                                        ref={this.storeRef}
                                    >
                                        <SubscriptionLimitProviders.CSF>
                                            {({ value }) => (
                                                <Fragment>
                                                    <StructureEntity
                                                        inactive={!value}
                                                        color={StructureEntity.colors.trendyPink}
                                                    >
                                                        {getTitle(
                                                            currentValues,
                                                            entityTypes.driver
                                                        )}
                                                    </StructureEntity>

                                                    {!value && (
                                                        <ContactSalesMessage />
                                                    )}
                                                </Fragment>
                                            )}
                                        </SubscriptionLimitProviders.CSF>
                                    </div>
                                </Col>

                                <Col md={3} mdOffset={5}>
                                    <div
                                        data-refkey={refKeys.csfDetails}
                                        ref={this.storeRef}
                                    >
                                        {editingField === entityTypes.driver && (
                                            <StructureEntityTitleField
                                                className="goalmap-structure-title-field"
                                                submitting={submitting}
                                                name={`settings.${entityTypes.driver}`}
                                                onCancel={this.handleEditCancel}
                                            />
                                        )}

                                        <StructureEntityDescription
                                            color={StructureEntity.colors.trendyPink}
                                            icon={getEntityIcon(entityTypes.driver)}
                                            name={entityTypes.driver}
                                            title={getTitle(currentValues, entityTypes.driver)}
                                            description={dictionary.csf}
                                            onEditStart={this.handleEditStart}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </GridContainer>
                    </div>

                    {relations.map(this.renderRelation)}
                </Form>
            </div>
        );
    }
}

GoalMapStructure.defaultProps = {
    topOffset: 0,
};

GoalMapStructure.propTypes = {
    currentValues: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    titles: PropTypes.object.isRequired, // eslint-disable-line
    goalId: PropTypes.number.isRequired, // eslint-disable-line
    dirty: PropTypes.bool.isRequired,
    topOffset: PropTypes.number,
};

function mapStateToProps(state, props) {
    const { titles, goalId } = props;

    return {
        kpiLimits: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.milestones),
        csfLimits: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.driver),
        initialValues: {
            id: goalId,
            settings: titles,
        },
        currentValues: {
            settings: formSelector(state, 'settings'),
        }
    };
}

export default flow(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    }),
    connect(mapStateToProps),
)(GoalMapStructure);
