import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../../shared/components';

function createRenderInputPrefix(iconName) {
    function RenderInputPrefix({ iconClassName }) {
        return (
            <Icon
                className={iconClassName}
                name={iconName}
            />
        );
    }

    RenderInputPrefix.propTypes = {
        iconClassName: PropTypes.string.isRequired,
    };

    return RenderInputPrefix;
}

export const renderEmailInputPrefix = createRenderInputPrefix('envelope-open-text');
export const renderPasswordInputPrefix = createRenderInputPrefix('lock-alt');
