import * as actions from './primary-entity-clone-service-actions';
import { name, isCancelled, isRemoved } from './primary-entity-clone-service-constants';
import reducer from './primary-entity-clone-service-reducer';
import ServiceComponent from './primary-entity-clone-service';

const PrimaryEntityCloneService = {
    reducer,
    actions,
    ServiceComponent,
    constants: { name },
    utils: { isCancelled, isRemoved }
};

export default PrimaryEntityCloneService;
