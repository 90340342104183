import { Collection } from '../../shared/entities-v2';
import request from '../../request';
import { dateNow, } from '../../shared/utils/date';
import { filterOptionValues, requestParams, } from './constants';

const collectionName = 'work-center-calendar-collection';
const apiUrl = '/work_center/calendar';

const parseItem = item => ({
    ...item,
    uniqueId: `${item.id}-${item.type_code}`
});

const isUpdateRequiredAndDateInPast = params =>
    params?.[requestParams.filterType] === filterOptionValues.updateRequired && dateNow.isAfter(params?.[requestParams.endDate]);

class WorkCenterCollection extends Collection {
    // eslint-disable-next-line class-methods-use-this
    parse(response) {
        const items = response.items || response;
        return { data: items.map(parseItem) };
    }

    fetch(params, options) {
        const formattedParams = this.format(params);
        const url = this.getApiUrl(params);

        if (isUpdateRequiredAndDateInPast(params)) {
            return Promise.resolve().then(() => this.parse({ items: [] }));
        }
        return request
            .post(url, formattedParams, options)
            .then(this.parse, this.parseError);
    }
}

const workCenterCollection = new WorkCenterCollection({
    name: collectionName,
    apiUrl,
});

export default workCenterCollection;
