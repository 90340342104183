import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './styles.css';

export default function Clickable({
    children,
    className,
    onClick,
    action,
    active,
    component,
    disabled,
    noPropagation,
    withDisabledStyle,
    ...rest
}) {
    const RenderComponent = component;

    return (
        <RenderComponent
            href={`#${action}`}
            role="button"
            className={classnames('clickable', {
                'clickable-disabled': withDisabledStyle && disabled,
                [className]: className,
                active
            })}
            onClick={(event) => {
                event.preventDefault();

                if (noPropagation) {
                    event.stopPropagation();
                }

                if (disabled) return false;
                return onClick(event);
            }}
            {...rest}
        >
            {children}
        </RenderComponent>
    );
}

Clickable.defaultProps = {
    component: 'a',
    children: undefined,
    className: undefined,
    active: false,
    disabled: false,
    onClick() {},
    action: '',
    noPropagation: false,
    withDisabledStyle: false,
};

Clickable.propTypes = {
    component: PropTypes.string,
    children: PropTypes.node,
    action: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    noPropagation: PropTypes.bool,
    withDisabledStyle: PropTypes.bool,
};
