import { name } from './service-worker-constants';

function getBranch(state) {
    return state[name];
}

export function getServiceWorkerUpdatingState(state) {
    return getBranch(state).updatingState;
}

export function selectLastUpdateTime(state) {
    return getBranch(state).lastUpdateTime;
}

export function selectIsReloadAfterNavigate(state) {
    return getBranch(state).reloadAfterNavigate;
}

export function getIsServiceWorkerUpdatingInBackground(state) {
    return getBranch(state).backgroundUpdating;
}

export function selectPersistShape(state) {
    return {
        lastUpdateTime: selectLastUpdateTime(state),
    };
}
