import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export default function WizardButtonsLeftGroup({ children }) {
    return (
        <div className={styles.wizardButtonsLeftGroup}>
            {children}
        </div>
    );
}

WizardButtonsLeftGroup.defaultProps = {
    children: undefined
};

WizardButtonsLeftGroup.propTypes = {
    children: PropTypes.node
};
