import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import EntityTypeSelector from './entity-type-selector';

export default function WizardEntitiesList({ items }) {
    return (
        <div className="wizard-entities-list">
            {items.map(item => (
                <Field
                    name="entity_type"
                    component={EntityTypeSelector}
                    props={item}
                />
            ))}
        </div>
    );
}

WizardEntitiesList.propTypes = {
    items: PropTypes.array.isRequired,
};
