import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import GridContainer from '../grid/container';
import Modal from '../modal/component';
import './styles.css';

export default function ActionSheet({ children, className, visible }) {
    if (!visible) {
        return null;
    }

    return (
        <Modal
            visible={visible}
            modalContentClassName="action-sheet-modal-content"
        >
            <div
                className={classnames('action-sheet', {
                    [className]: !!className,
                })}
            >
                <div className="action-sheet-overlay" />

                <GridContainer className="action-sheet-content">
                    {children}
                </GridContainer>
            </div>
        </Modal>
    );
}

ActionSheet.defaultProps = {
    children: undefined,
    className: undefined,
};

ActionSheet.propTypes = {
    visible: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
};
