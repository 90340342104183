import { createAction } from 'redux-act';

function createAsyncActions(prefix) {
    return {
        request: createAction(`${prefix}/REQUEST`),
        success: createAction(`${prefix}/SUCCESS`),
        error: createAction(`${prefix}/ERROR`),
    };
}

export default function createActions(prefix) {
    const listPrefix = `${prefix}/get-details`;

    return {
        request: createAction(`${listPrefix}/REQUEST`),
        success: createAction(`${listPrefix}/SUCCESS`),
        error: createAction(`${listPrefix}/ERROR`),
        clearError: createAction(`${listPrefix}/clear-error`),
        update: createAsyncActions(`${prefix}/update`),
        updateTags: createAsyncActions(`${prefix}/update-tags`),
        updateStatus: createAsyncActions(`${prefix}/update-status`),
        setWatchStatus: createAsyncActions(`${prefix}/watch`),
        remove: createAsyncActions(`${prefix}/remove`),
        toggleModal: createAction(`${listPrefix}/modal/TOGGLE`),
        hideVersionConflictModal: createAction(`${listPrefix}/version-conflict/HIDE`),
        mergeWith: createAction(`${prefix}/MERGE_WITH`),
        reset: createAction(`${prefix}/RESET`),
        initializeWith: createAction(`${prefix}/INITIALIZE_WITH`),
    };
}
