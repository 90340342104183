import u from 'updeep';
import get from 'lodash/get';
import { formatHealth } from '../../../modules/goalmap-plans-actions/utils';
import { entityState, getIconByTypeId } from '../../utils/entity';
import { typeCodes } from '../../utils/entity-type';

const ROOT_PATH = 'strategy_maps';
const childrenSelectorsByLevel = {
    1(item) { return item.segments; },
    2(item) { return (item.children || item.plans_actions); },
    3(item) { return (item.plans_actions || item.actions); },
    4(item) { return (item.plans_actions || item.actions); },
    5(item) { return (item.plans_actions || item.actions); },
};

function getChildrenByLevel(data, level) {
    return childrenSelectorsByLevel[level](data) || [];
}

function createUniqueId(id, entityType) {
    return [id, entityType].join('-');
}

export function computePath(index, level, prevPath) {
    return (level === 1)
        ? [index]
        : [...prevPath, 'children', index];
}

function computePathForPlanNavigator(uniqueId, prevPath = []) {
    return [...prevPath, uniqueId];
}

function computeBreadcrumbsForPlanNavigator(data, prevBreadcrumbs = []) {
    return [...prevBreadcrumbs, { id: data.id, title: data.title, entity_type: data.entity_type }];
}

function parseLevelRecursive(data, index, array, level = 1, prevPath, prevBreadcrumbs, prevParentTypeId = 6, parent_unique_id = 0) {
    const uniqueId = createUniqueId(data.id, data.entity_type);
    const path = computePathForPlanNavigator(uniqueId, prevPath);
    const breadcrumbs = computeBreadcrumbsForPlanNavigator(data, prevBreadcrumbs);
    const parentTypeId = get(data, 'entity_type');
    const icon_name = getIconByTypeId(data.entity_type);
    let children = getChildrenByLevel(data, level);
    let hasDeepActiveChildren = false;
    const isActive = data.entity_type === typeCodes.kpi
        ? data.state !== entityState.inactive
        : !['cancelled', 'closed'].includes(data.status);

    if (children.length) {
        children = children.map((...args) =>
            parseLevelRecursive(...args, level + 1, path, breadcrumbs, parentTypeId, uniqueId));

        hasDeepActiveChildren = children.some(it => it.hasDeepActiveChildren || it.isActive);
    }

    return u({
        ...(children.length && { expanded: false }),
        movable: !(level === 1),
        children,
        uniqueId,
        path,
        icon_name,
        breadcrumbs,
        parent_entity_type: prevParentTypeId,
        parent_unique_id,
        hasDeepActiveChildren,
        isActive,
        level
    }, formatHealth(data));
}

export default function parseData(raw) {
    return (raw[ROOT_PATH] || []).map(parseLevelRecursive);
}
