import { createAction } from 'redux-act';
import createAsyncActions from '../base/async-actions';
import formatRequestPayload from '../utils/format-request-payload';

export default function createActions({ name, additionalActions }) {
    return {
        fetch: createAsyncActions(`${name}/FETCH`, formatRequestPayload),
        create: createAsyncActions(`${name}/CREATE`, formatRequestPayload),
        update: createAsyncActions(`${name}/UPDATE`, formatRequestPayload),
        remove: createAsyncActions(`${name}/REMOVE`, formatRequestPayload),
        reset: createAction(`${name}/RESET`),
        setParams: createAction(`${name}/SET_PARAMS`),
        ...additionalActions,
    };
}
