export const fields = Object.freeze({
    isActive: 'is_active',
});

export const values = Object.freeze({
    [fields.isActive]: {
        all: '',
        active: 'true',
        inactive: 'false',
    },
});

export const initialValues = Object.freeze({
    [fields.isActive]: values[fields.isActive].all,
});

// export function formatParams(params) {
//     const result = { ...params };

//     delete result.slug;
//     if (result.is_active === '') {
//         delete result.is_active;
//     }

//     return result;
// }
