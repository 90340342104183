export const globalSections = Object.freeze({
    system: 'system',
    strategicPlans: 'strategic-plans',
});

export const entitySections = Object.freeze({
    strategicPlan: 'strategic-plan',
    csf: 'critical-success-factor',
    kpi: 'key-performance-indicator',
    note: 'note',
    attachment: 'attachment',
    collaborator: 'collaborator',
    relatedLink: 'related-link',
    segment: 'segment',
    strategy: 'strategy',
    objective: 'objective',
    tactic: 'tactic',
    action: 'action',
});

export const systemPermissionNames = Object.freeze({
    canChangeGui: 'can-change-gui',
    canChangeGuiTheme: 'can-change-gui-theme',
    canChangeGuiLogo: 'can-update-gui-logo',
    canGoalArchive: 'can-goal-archive',
    canGoalCreate: 'can-goal-create',
    canManageReports: 'can-manage-reports',
    canManageRoles: 'can-manage-roles',
    canManageUsers: 'can-manage-users',
    canManageXRay: 'can-manage-xRay',
});

export const entityPermissionNames = Object.freeze({
    canView: 'can-view',
    canViewOwn: 'can-view-own',
    canCreate: 'can-create',
    canUpdate: 'can-update',
    canUpdateOwn: 'can-update-own',
    canDestroy: 'can-destroy',
    canDestroyOwn: 'can-destroy-own',
    canChangeState: 'can-change-state',
    canChangeStateOwn: 'can-change-state-own',

    canChangeNotifications: 'can-change-notifications',
    canChangeSettings: 'can-change-settings',
    canManageUsers: 'can-manage-users',
    canMove: 'can-move',
    canArchive: 'can-archive',
    canUpdateOwner: 'can-update-owner',

    sensitivityLevel: 'sensitivity-level',
});

export const isOwnEntity = 'isOwnEntity';
