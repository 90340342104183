import React, { useState } from 'react';
import {
    Text, Modal, FormattedDate, Clickable, Icon,
} from '../../../shared/components';
import { ModalFormHeader, } from '../../../shared/components/modal-form';
import { getApiDocumentationUrl } from './public-api-constants';
import publicApiChangelog from './public-api-changelog.json';

import styles from './public-api-details.module.css';

const PublicApiDocumentation = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const apiDocumentationUrl = getApiDocumentationUrl();

    return (
        <>
            <div className={styles.apiDocSubtitleContainer}>
                <div>
                    <Text className={styles.apiDocSubtitle} styleType="dark-light">API Documentation</Text>
                    <a
                        href={apiDocumentationUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Icon className={styles.apiDocIcon} name="external-link-square" />
                    </a>
                </div>
                <Clickable onClick={() => setModalVisible(true)}>
                    <Text styleType="brand">History of Updates</Text>
                </Clickable>
            </div>
            <div className={styles.apiDocIframeContainer}>
                <iframe
                    className={styles.apiDocIframe}
                    title="MPOWR Envision REST API"
                    src={apiDocumentationUrl}
                    loading="lazy"
                    sandbox
                />
            </div>
            <Modal
                visible={modalVisible}
                modalContentClassName={styles.apiDocModalContent}
            >
                <ModalFormHeader hide={() => setModalVisible(false)}>
                    History of Updates
                </ModalFormHeader>
                {publicApiChangelog.map(changeLog => (
                    <div className={styles.apiDocChangeLogItem} key={changeLog.version}>
                        <Text weight="boldest">Version {changeLog.version},&nbsp;</Text>
                        <FormattedDate date={changeLog.date} className={styles.apiDocChangeDate} />
                        <ul>
                            {changeLog.changelist.map((changes, id) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <li key={id}>{changes}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default PublicApiDocumentation;
