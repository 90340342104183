import React from 'react';
import PropTypes from 'prop-types';
import EntityNotification from './entity-notification';
import ProactiveNotification from './proactive-notification';

export default function NotificationsFactory(props) {
    const { in_app_message } = props;
    const NotificationComponent = in_app_message.entity
        ? EntityNotification
        : ProactiveNotification;

    return (
        <NotificationComponent {...props} />
    );
}

NotificationsFactory.propTypes = {
    in_app_message: PropTypes.object.isRequired
};
