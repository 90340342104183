/** @namespace Utils.Bool */

/**
 * Check value is boolean
 * @function isBool
 * @memberof Utils.Bool
 * @param {any} value - Checked value
 * @return {boolean} Checking result
 */
export default function isBool(val) {
    return typeof val === 'boolean';
}
