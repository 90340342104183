import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { fetchDetails, updateDetails, remove } from '../../../../../modules/goalmap-list/actions';
import { getGoalmaps, getDetails } from '../../../../../modules/goalmap-list/selector';
import Permissions from '../../../../../modules/permissions';
import { DetailsOwnerSection } from '../../../';
import { PlanVisionMissionValues } from '../../../../../modules/plan-navigator/components/plan-details';
import { createHrefByTypeCode } from '../../../../utils/compose-href';
import { MetaInfo } from '../../../../components';
import BadgeArchived from '../../../../components/badge-archived/component';
import { getIconByTypeCode, isArchived } from '../../../../utils/entity';
import { getStringTypeByCode } from '../../../../utils/entity-type';
import { createRightDrawerEntityDetailsView } from '../right-drawer-entity-details-view';
import EntityViewHeader from '../entity-view-header';
import RightDrawerTab from '../right-drawer-tab';

import styles from '../styles.module.css';

const PlanView = ({
    data, handleCloseWorkCenterRightDrawer, callbackOnEntityChange, isActiveTab, handleToggleTab, ...rest
}) => {
    const {
        change, dispatch, onUpdateEntityDetails, canEditOwner, canUpdateEntity, entityDescription, quillStickyProps
    } = rest;

    const {
        id, title, goal_details, progress_status, type_code, health_manually, owner, settings
    } = data;

    const [activeFieldName, setActiveFieldName] = useState('');
    const [showReadMore, setShowReadMore] = useState({ vision: true, mission: true, values: true });

    const handleSetShowReadMore = (name, status) => {
        setShowReadMore({ ...showReadMore, [name]: status });
    };

    const initialValues = { id, title, updateTitle: true, };
    const formName = `right-drawer-entity-view-${id}`;
    const href = createHrefByTypeCode(type_code, { goal_id: id, id });

    return (
        <>
            <EntityViewHeader
                dispatch={dispatch}
                change={change}
                onUpdateEntityDetails={onUpdateEntityDetails}
                onSuccessSubmit={callbackOnEntityChange}
                canUpdateEntity={canUpdateEntity}
                health_manually={health_manually}
                handleCloseWorkCenterRightDrawer={handleCloseWorkCenterRightDrawer}
                href={href}
                progress_status={progress_status}
                initialValues={initialValues}
                title={title}
                formName={formName}
            />

            <div className={classnames(styles.rightDrawerEntityViewBody, styles.withAdditionalTab, styles.rightDrawerTabWrapper)}>
                <RightDrawerTab handleToggleTab={handleToggleTab} isActiveTab={isActiveTab} />

                <div className={classnames(styles.rightDrawerTabContent, { [styles.visible]: isActiveTab })}>
                    <div className={styles.rightDrawerPlanEntityType}>
                        <MetaInfo
                            icon={getIconByTypeCode(type_code)}
                            itemType={settings[getStringTypeByCode(entityDescription.type)]}
                        />
                        {isArchived(data) && <BadgeArchived />}
                    </div>
                    <div className={styles.rightDrawerPlanOwner}>
                        <DetailsOwnerSection
                            owner={owner}
                            entity_id={id}
                            goal_id={id}
                            initialValues={{ owner_id: owner.id, id, title }}
                            onSubmit={onUpdateEntityDetails}
                            onSuccessSubmit={callbackOnEntityChange}
                            enableReinitialize
                            isCancelEditOnVersionValidCheck
                            editable={canEditOwner}
                        />
                    </div>
                    <PlanVisionMissionValues
                        quillStickyProps={quillStickyProps}
                        activeFieldName={activeFieldName}
                        showReadMore={showReadMore}
                        planDetails={goal_details}
                        editAllowed={canUpdateEntity}
                        updatePlanDetails={onUpdateEntityDetails}
                        planId={id}
                        setActiveFieldName={setActiveFieldName}
                        handleSetShowReadMore={handleSetShowReadMore}
                        onSuccessSubmit={callbackOnEntityChange}
                        containerClassName={styles.rightDrawerPlanVisionMissionValues}
                    />
                </div>
            </div>
        </>
    );
};

PlanView.defaultProps = {
    handleCloseWorkCenterRightDrawer() {},
    callbackOnEntityChange() {},
};

PlanView.propTypes = {
    data: PropTypes.object.isRequired,
    handleCloseWorkCenterRightDrawer: PropTypes.func,
    callbackOnEntityChange: PropTypes.func,
    isActiveTab: PropTypes.bool.isRequired,
    entityDescription: PropTypes.object.isRequired,
    handleToggleTab: PropTypes.func.isRequired
};

export default createRightDrawerEntityDetailsView({
    formName: 'right-drawer-plan-details-form',
    entityType: 'goal',
    actions: {
        request: fetchDetails,
        update: { request: updateDetails.request },
        remove,
        updateTags: { request: () => {} },
        updateStatus: { request: () => {} },
        hideVersionConflictModal: () => {},
    },
    selector: () => ({}),
    permissionsSection: Permissions.constants.entitySections.strategicPlan,
    mapStateToProps(state, ownProps) {
        const goalmapsListBranch = getGoalmaps(state);
        const dataEntityDetails = getDetails(state);
        const permissions = Permissions.selectors.selectResolvedEntityPermissions(
            state,
            ownProps.entityDescription.goal_id,
            Permissions.constants.entitySections.strategicPlan,
            dataEntityDetails,
        );
        const canViewEntity = get(permissions, Permissions.keys.canView, false) || get(permissions, Permissions.keys.canViewOwn, false);
        const canUpdateEntity = get(permissions, Permissions.keys.canUpdate);
        const canEditOwner = get(permissions, Permissions.keys.canUpdateOwner);
        return {
            loadingEntityDetails: get(goalmapsListBranch, 'detailsFetching', false),
            updatingEntityDetails: get(goalmapsListBranch, 'detailsUpdating', false),
            errorEntityDetails: get(goalmapsListBranch, 'detailsError'),
            dataEntityDetails,
            canViewEntity,
            canEditOwner,
            canUpdateEntity,
            versioningConflict: false,
        };
    },
})(PlanView);
