import u from 'updeep';
import { createReducer } from 'redux-act';
import { flow, get } from 'lodash';
import { asyncTypes } from './config';
import parseRequestPayload from '../utils/parse-request-payload';

export const initialState = {
    attributes: {},
    fetchParams: {},
    asyncState: {
        [asyncTypes.fetch]: false,
        [asyncTypes.update]: false,
        [asyncTypes.remove]: false,
        [asyncTypes.create]: false,
    },
    errors: {
        [asyncTypes.fetch]: null,
        [asyncTypes.update]: null,
        [asyncTypes.remove]: null,
        [asyncTypes.create]: null,
    },
    extensionState: {},
};

function updateAsyncState(type, value) {
    return u({
        asyncState: { [type]: value }
    });
}

function updateError(type, value) {
    return u({
        errors: { [type]: value }
    });
}

function updateAttributes(newData) {
    return u({
        attributes: u.constant(newData),
    });
}

function updateFetchParams(params) {
    return u({
        fetchParams: u.constant(params),
    });
}

export function handleFetchRequest(state, payload) {
    const { options, data } = parseRequestPayload(payload);

    return flow(
        updateFetchParams(data),
        updateAsyncState(asyncTypes.fetch, options.silent ? state.asyncState.fetch : true),
        updateError(asyncTypes.fetch, null),
    )(state);
}

function handleFetchSuccess(state, payload) {
    const response = get(payload, 'response', {});

    return flow(
        updateAttributes(response),
        updateAsyncState(asyncTypes.fetch, false),
        updateError(asyncTypes.fetch, null),
    )(state);
}

function handleFetchError(state, payload) {
    const response = get(payload, 'response', {});

    return flow(
        updateAsyncState(asyncTypes.fetch, false),
        updateError(asyncTypes.fetch, response),
    )(state);
}

function handleSetParams(state, payload) {
    return updateFetchParams(payload)(state);
}

export function handleCreateRequest(state) {
    return flow(
        updateAsyncState(asyncTypes.create, true),
        updateError(asyncTypes.create, null),
    )(state);
}

export function handleCreateSuccess(state, payload) {
    const response = get(payload, 'response', {});

    return flow(
        updateAttributes(response),
        updateAsyncState(asyncTypes.create, false),
        updateError(asyncTypes.create, null),
    )(state);
}

function handleCreateError(state, payload) {
    const response = get(payload, 'response', {});

    return flow(
        updateAsyncState(asyncTypes.create, false),
        updateError(asyncTypes.create, response),
    )(state);
}

export function handleUpdateRequest(state) {
    return flow(
        updateAsyncState(asyncTypes.update, true),
        updateError(asyncTypes.update, null),
    )(state);
}

export function handleUpdateSuccess(state, payload) {
    const response = get(payload, 'response', {});
    return flow(
        updateAttributes(response),
        updateAsyncState(asyncTypes.update, false),
        updateError(asyncTypes.update, null),
    )(state);
}

function handleUpdateError(state, payload) {
    const response = get(payload, 'response', {});

    return flow(
        updateAsyncState(asyncTypes.update, false),
        updateError(asyncTypes.update, response),
    )(state);
}

function handleRemoveRequest(state) {
    return flow(
        updateAsyncState(asyncTypes.remove, true),
        updateError(asyncTypes.remove, null),
    )(state);
}

function handleRemoveSuccess(state) {
    return flow(
        updateAttributes({}),
        updateAsyncState(asyncTypes.remove, false),
        updateError(asyncTypes.remove, null),
    )(state);
}

function handleRemoveError(state, payload) {
    const response = get(payload, 'response', {});

    return flow(
        updateAsyncState(asyncTypes.remove, false),
        updateError(asyncTypes.remove, response),
    )(state);
}

function handleReset(additionalInitialState) {
    return {
        ...initialState,
        extensionState: { ...initialState.extensionState, ...additionalInitialState, },
    };
}

export default function createModelReducers({ actions, additionalReducers = {}, additionalInitialState = {} }) {
    const reducerMap = {
        [actions.fetch.request]: handleFetchRequest,
        [actions.fetch.success]: handleFetchSuccess,
        [actions.fetch.error]: handleFetchError,
        [actions.reset]: () => handleReset(additionalInitialState),
        [actions.setParams]: handleSetParams,
        [actions.create.request]: handleCreateRequest,
        [actions.create.success]: handleCreateSuccess,
        [actions.create.error]: handleCreateError,
        [actions.update.request]: handleUpdateRequest,
        [actions.update.success]: handleUpdateSuccess,
        [actions.update.error]: handleUpdateError,
        [actions.remove.request]: handleRemoveRequest,
        [actions.remove.success]: handleRemoveSuccess,
        [actions.remove.error]: handleRemoveError,
    };

    return createReducer({ ...reducerMap, ...additionalReducers }, {
        ...initialState,
        extensionState: { ...initialState.extensionState, ...additionalInitialState, },
    });
}
