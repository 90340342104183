import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import {
    Row, Col, InputDate, StatusInput, ProgressStatusInput,
} from '../../../shared/components';
import { Select } from '../../../shared/modules';
import { createSensitivityOptions } from '../../goalmap-plans-actions/utils';
import { isValidDate, getApiFormattedDate } from '../../../shared/utils/date';
import { selectUserDateFormat } from '../../user/selectors';

import styles from './action-fields.module.css';

export default function ActionFields({
    sensitivities,
    dispatch,
    change,
    currentValues,
    smallScreen
}) {
    const userDateFormat = useSelector(selectUserDateFormat);
    const parseDate = (value) => {
        if (isEmpty(value)) return value;
        return isValidDate(value) ? getApiFormattedDate(value, userDateFormat) : null;
    };

    const sensitivityOptions = createSensitivityOptions(sensitivities);

    return (
        <div className={styles.actionFields}>
            <Row>
                <Col xs={12} md={4}>
                    <Field
                        name="frequency"
                        id="plan-frequency-select"
                        label="Frequency"
                        placeholder="Select Frequency"
                        component={Select}
                        searchable={false}
                        clearable={false}
                        options={[
                            { id: 'daily', title: 'Daily' },
                            { id: 'weekly', title: 'Weekly' },
                            { id: 'monthly', title: 'Monthly' },
                            { id: 'quarterly', title: 'Quarterly' },
                        ]}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Field
                        name="priority"
                        id="plan-priority-select"
                        label="Priority"
                        placeholder="Select Priority"
                        component={Select}
                        searchable={false}
                        clearable={false}
                        options={[
                            { id: 'low', title: 'Low' },
                            { id: 'medium', title: 'Medium' },
                            { id: 'high', title: 'High' },
                        ]}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Field
                        name="sensitivity"
                        id="plan-sensitivity-select"
                        label="Sensitivity"
                        placeholder="Select Sensitivity"
                        component={Select}
                        searchable={false}
                        clearable={false}
                        options={sensitivityOptions}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    <Field
                        name="start_date"
                        label="Start Date"
                        placeholder="Select Start Date"
                        component={InputDate}
                        props={{
                            isClearable: true,
                        }}
                        fixedHeight
                        parse={parseDate}
                        autoComplete="off"
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Field
                        name="target_date"
                        label="Target Date"
                        placeholder="Select Target Date"
                        component={InputDate}
                        props={{
                            isClearable: true,
                        }}
                        fixedHeight
                        parse={parseDate}
                        autoComplete="off"
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Field
                        name="closed_date"
                        label="Closed Date"
                        placeholder="Select Closed Date"
                        component={InputDate}
                        props={{
                            isClearable: true,
                        }}
                        fixedHeight
                        parse={parseDate}
                        autoComplete="off"
                    />
                </Col>
                {
                    smallScreen
                        ? (
                            <Col xs={12} md={4}>
                                <Row>
                                    <Col>
                                        <div className="input-container">

                                            <span className="input-container-label">Status</span>
                                            <ProgressStatusInput
                                                className="progress-status-inverse"
                                                onChange={(value) => {
                                                    dispatch(change('status', value));
                                                }}
                                                value={currentValues.status}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-container">
                                            <span className="input-container-label">Health</span>
                                            <StatusInput
                                                value={currentValues.progress_status}
                                                className="health-status-inverse"
                                                onChange={(value) => {
                                                    dispatch(change('progress_status', value));
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                        )
                        : null
                }

            </Row>
        </div>
    );
}

ActionFields.defaultProps = {
    currentValues: {
        status: 'pending',
        progress_status: '4'
    }
};

ActionFields.propTypes = {
    sensitivities: PropTypes.object, // eslint-disable-line
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
        status: PropTypes.string,
        progress_status: PropTypes.number
    }),
    smallScreen: PropTypes.bool.isRequired
};
