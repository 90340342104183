import { get } from 'lodash';
import moment from 'moment';
import { attributes as KPIAttributes, dataSourceAttributes, periodTargetAttributes } from '../goalmap-milestones/constants';
import { getPeriodFromDataPoint } from '../goalmap-milestones/utils';
import { operations } from '../goalmap-milestones/components/data-source-operations-select/constants';
import { attributes } from './constants';
import { roundDecimal } from '../../shared/utils/math';
import { getFrequencyType } from '../../shared/utils/date';
import config from '../../config';

export const titlesByOperation = Object.freeze({
    [operations.sum]: 'SUM',
    [operations.difference]: 'DIFFERENCE',
    [operations.average]: 'AVERAGE',
    [operations.quotient]: 'QUOTIENT',
    [operations.quotientPercentage]: 'QUOTIENT PERCENTAGE',
    [operations.product]: 'PRODUCT',
});

export const calculateTotalByOperation = Object.freeze({
    [operations.sum]: (arr, key) => arr
        .reduce((accum, current) => { return accum + (Number(current[key]) || 0); }, 0),
    [operations.difference]: (arr, key) => arr
        .reduce((accum, current, id) => (id === 0 ? accum : accum - (Number(current[key]) || 0)), arr[0]?.[key] || 0),
    [operations.average]: (arr, key) => (
        arr.reduce((accum, current) => { return accum + (Number(current[key]) || 0); }, 0) / arr.length
    ),
    [operations.quotient]: ([a, b], key) => {
        if (!a?.[key] || !b?.[key]) {
            return 0;
        }

        return Number(a[key]) / Number(b[key]);
    },
    [operations.quotientPercentage]: ([a, b], key) => {
        if (!a?.[key] || !b?.[key]) {
            return 0;
        }

        return (Number(a[key]) / Number(b[key])) * 100;
    },
    [operations.product]: (arr, key) => (
        arr.reduce((accum, current) => { return accum * (Number(current[key]) || 0); }, 1)
    ),
});

export function getDataSourcesPoints(sources, periodStart) {
    const findPoint = item => getPeriodFromDataPoint(item).to === periodStart;

    return sources.map((item) => {
        const dataPoints = get(item, [
            dataSourceAttributes.related_entity,
            KPIAttributes.data_points
        ], []);
        const periodTargets = get(item, [
            dataSourceAttributes.related_entity,
            KPIAttributes.kpi_period_targets,
        ], []);

        const currentDataPoint = dataPoints.find(findPoint);
        const currentPeriodTarget = periodTargets.find(findPoint);

        if (currentDataPoint) {
            return {
                [attributes.id]: get(currentDataPoint, attributes.id),
                [attributes.goal_milestone_id]: get(currentDataPoint, attributes.goal_milestone_id),
                [attributes.value]: roundDecimal(get(currentDataPoint, attributes.value)),
                [attributes.period_target]: roundDecimal(get(currentDataPoint, attributes.period_target)),
            };
        }

        if (currentPeriodTarget) {
            return {
                [attributes.goal_milestone_id]: get(item, [
                    dataSourceAttributes.related_entity,
                    KPIAttributes.id
                ]),
                [attributes.period_target]: roundDecimal(get(currentPeriodTarget, periodTargetAttributes.target_value)),
            };
        }

        return {
            [attributes.goal_milestone_id]: get(item, [
                dataSourceAttributes.related_entity,
                KPIAttributes.id
            ]),
        };
    });
}

export function composeInitialValuesCreate(id, periodStart, isAdvancedCalculation, advCalcSources, periodTarget) {
    return {
        [attributes.goal_milestone_id]: id,
        [attributes.period_start]: periodStart,
        [attributes.progress_note_content]: '',
        [attributes.data_points]: isAdvancedCalculation
            ? getDataSourcesPoints(advCalcSources, periodStart)
            : undefined,
        [attributes.period_target]: periodTarget,
    };
}

export const formatPeriodStart = (period_start, frequency) =>
    moment(period_start)
        .endOf(getFrequencyType(frequency))
        .format(config.apiResponseDateFormat);

export const formattedPeriodStartValue = (missingPeriods, startPeriods, frequency,) => {
    let periodStartValue;
    if (missingPeriods.length) {
        periodStartValue = missingPeriods[0].from;
    } else if (startPeriods.length) {
        periodStartValue = startPeriods[0].from;
    } else periodStartValue = moment();
    return formatPeriodStart(periodStartValue, frequency);
};
