import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Icon } from '../../components';
import './styles.css';

const iconName = {
    exclamationTriangle: 'exclamation-triangle',
    eyeSlash: 'eye-slash',
    wifiSlash: 'wifi-slash',
    browser: 'browser',
};

export default function ServicePage({
    className, icon, title, description, children
}) {
    return (
        <div
            className={classnames('service-page', {
                [className]: !!className
            })}
        >
            <div className="icon-cover-out">
                <div className="icon-cover-in">
                    <Icon name={icon} type="solid" className="service-page-icon" />
                </div>
            </div>
            <h1 className="service-page-title">{title}</h1>
            <p className="service-page-description">{description}</p>
            {children}
        </div>
    );
}

ServicePage.defaultProps = {
    className: undefined,
    children: undefined,
    description: undefined
};

ServicePage.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.oneOf([
        iconName.exclamationTriangle,
        iconName.eyeSlash,
        iconName.wifiSlash,
        iconName.browser,
    ]).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    children: PropTypes.node,
};
