import React from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import createModalWizard from '../../wizard-entity/wizard-modal';
import { parse } from '../../goalmap-milestones/create/utils';
import { subscriptionLimitSelectors } from '../../subscription';
import { create as createStrategyChildrenModel, details as strategyChildrenModel } from '../entity';
import { createSelectAvailableEntities } from '../create-wizard/selectors';
import { getScreensByType, getInitialValuesSelectorByType, getFirstAvailableEntity } from '../create-wizard/utils';
import SegmentChildrenWizardForm from './wizard';
import { getDetailsTitles } from '../../goalmap-list/selector';
import { defaultAvailableEntities } from '../constants';

const formName = 'strategy-children-wizard-form-create';

const StrategyChildrenModalWizard = createModalWizard({
    name: formName,
    removeOnCancel: true,
    actions: {
        create: createStrategyChildrenModel.actions.request,
        update: strategyChildrenModel.actions.update.request,
        updateTags: strategyChildrenModel.actions.updateTags.request,
        remove: strategyChildrenModel.actions.remove.request,
        fetch: strategyChildrenModel.actions.request,
        toggle: createStrategyChildrenModel.actions.toggle,
        keepModalVisible: createStrategyChildrenModel.actions.setKeepModalVisible,
    },
    selectors: {
        selectModalVisible: state => createStrategyChildrenModel.selector(state).modalVisible,
        selectFetchingState: state => strategyChildrenModel.selector(state).loading,
        selectAttributes: state => strategyChildrenModel.selector(state).data,
        titleSelector: (titles, type) => get(titles, type, ''),
        getSubmitSteps: () => [2]
    },
    parseData: parse,
})(SegmentChildrenWizardForm);

const StrategyChildrenModalWizardRenderer = props => <StrategyChildrenModalWizard {...props} disableAdvancedCalcKPI={false} />;

const formSelector = formValueSelector(formName);
const selectFormValues = getFormValues(formName);

function mapStateToProps(state, ownProps) {
    const availableEntitiesSelector = createSelectAvailableEntities(ownProps.parentEntityData);
    const availableEntities = isEmpty(ownProps.parentEntityData)
        ? defaultAvailableEntities
        : availableEntitiesSelector(state, ownProps.goalId);
    const selectedType = formSelector(state, 'entity_type');
    const currentValues = selectFormValues(state);
    const titles = getDetailsTitles(state);
    const steps = getScreensByType(selectedType, titles);
    const canCreateDraft = subscriptionLimitSelectors.canCreateDraft(state);
    const initialValuesSelector = getInitialValuesSelectorByType(selectedType);
    const initialValues = initialValuesSelector(state, ownProps.parentEntityData, canCreateDraft);
    initialValues.entity_type = selectedType || getFirstAvailableEntity(availableEntities);
    return {
        initialValues,
        availableEntities,
        steps,
        currentValues,
    };
}

export default connect(mapStateToProps)(StrategyChildrenModalWizardRenderer);
