export const colors = Object.freeze({
    grey: 'grey',
    purple: 'purple',
    green: 'green',
    orange: 'orange',
    yellow: 'yellow',
    red: 'red',
    blue: 'blue',
    breakerBay: 'breaker-bay',
    trendyPink: 'trendy-pink',
});

export const colorValuesArray = Object.values(colors);

export const defaultColor = colors.grey;
