import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, ServiceMessageModal, Text } from '../../../shared/components';
import { selectExpiringSSOProviderCheck } from '../selectors';
import { selectExpiringSSOProviders } from '../login/selectors';

const getDaysToExpiryColorStyle = (daysToExpiry) => {
    if (daysToExpiry < 7) {
        return 'danger';
    }

    return 'default';
};

const SSOProvidersExpirationModal = () => {
    const expiringSSOProvidersCheck = useSelector(selectExpiringSSOProviderCheck);
    const expiringProviders = useSelector(selectExpiringSSOProviders);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (expiringSSOProvidersCheck && expiringProviders.length) {
            setVisible(true);
        }
    }, [expiringProviders]);

    const hide = () => setVisible(false);

    return (
        <ServiceMessageModal
            visible={visible}
        >
            {expiringProviders.map((provider) => {
                const isExpired = provider.daysToExpiry < 1;

                if (isExpired) {
                    return (
                        <Text component="p">
                            The certificate for the Enterprise Single Sign-on provider, {provider.title}, has expired.
                        </Text>
                    );
                }

                return (
                    <Text component="p">
                        The certificate for the Enterprise Single Sign-on provider, {provider.title}, will expire in&nbsp;
                        <Text
                            component="span"
                            styleType={getDaysToExpiryColorStyle(provider.daysToExpiry)}
                        >
                            {provider.daysToExpiry}
                        </Text> day(s).
                    </Text>
                );
            })}

            <p>Please contact your system administrator and ask them to update this certificate.</p>
            <Button onClick={hide}>OK</Button>
        </ServiceMessageModal>
    );
};

export default SSOProvidersExpirationModal;
