import React from 'react';
import PropTypes from 'prop-types';

import { ServiceMessageModal, Button, Image } from '../../components';
import styles from './link-download-modal.module.css';

const LinkDownloadSecureModal = ({
    visible, hide, icon, filename, href
}) => {
    const isDataValid = !!icon && !!filename && !!href;

    return (
        <ServiceMessageModal
            hide={hide}
            visible={visible}
        >
            <ServiceMessageModal.CloseButton onClick={hide} />

            {isDataValid && (
                <>
                    <div className={styles.attachmentDownloadModalTitle}>
                        <Image
                            src={icon}
                            alt="storage-icon"
                            className={styles.storageTypeIcon}
                        />
                        <h3>{filename}</h3>
                    </div>

                    <Button
                        component="a"
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        href={href}
                        styleType="success"
                        onClick={hide}
                    >
                        Open attachment
                    </Button>
                </>
            )}
        </ServiceMessageModal>
    );
};

LinkDownloadSecureModal.defaultProps = {
    icon: undefined,
    filename: undefined,
    href: undefined,
};

LinkDownloadSecureModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,

    icon: PropTypes.string,
    filename: PropTypes.string,
    href: PropTypes.string,
};

export default LinkDownloadSecureModal;
