import {
    useRef, useLayoutEffect, useState, useCallback
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';

const useResizeObserver = () => {
    const [observerEntry, setObserverEntry] = useState({});
    const [node, setNode] = useState(null);
    const observer = useRef(null);

    const disconnect = useCallback(() => observer.current?.disconnect(), []);

    const observe = useCallback(() => {
        observer.current = new ResizeObserver(debounce(([entry]) => setObserverEntry(entry), 100));
        if (node) observer.current.observe(node);
    }, [node]);

    useLayoutEffect(() => {
        observe();
        return () => disconnect();
    }, [disconnect, observe]);

    return [setNode, observerEntry];
};

export default useResizeObserver;
