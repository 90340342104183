import request from '../../../request';
import { List, createModal, createDetails } from '../../../shared/entities';

const apiUrl = '/collaborators';

export const collaboratorCollection = new List({
    apiUrl,
    name: 'collaborators-list',
});

export const collaboratorCreateModel = createModal({
    apiUrl,
    name: 'collaborators-create',
    onSuccess: collaboratorCollection.actions.replace,
    extendAPI: {
        create({ params }) {
            return request.post(apiUrl, {
                ...params,
                users: params.users.map(item => item.id)
            });
        }
    }
});

export const collaboratorModel = createDetails({
    apiUrl,
    name: 'collaborators-details',
    onRemove: collaboratorCollection.actions.remove,
    extendAPI: {
        remove(params) {
            const { watcher_id, watcher_type, id } = params;
            return request.patch(apiUrl, {
                users: [id],
                watcher_id,
                watcher_type
            });
        }
    }
});
