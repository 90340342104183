import { Collection } from '../../../shared/entities-v2';
import { keysToCamelCase } from '../../../shared/utils/object';

class SettingsAccountDemoDataCollection extends Collection {
    parse = (response) => {
        return {
            data: response,
        };
    }

    parseItem = keysToCamelCase
}

const settingsAccountDemoDataCollection = new SettingsAccountDemoDataCollection({
    apiUrl: '/demo_data/plans',
    name: 'settings-account-demo-data-collection',
    idKey: 'slug'
});

export default settingsAccountDemoDataCollection;
