import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Clickable from '../../clickable/component';
import Icon from '../../icon/component';
import './styles.css';

export default function ModalFormHeader({ children, className, hide }) {
    return (
        <div
            className={classnames('modal-form-header', {
                [className]: !!className,
            })}
        >
            <span>{children}</span>
            {hide && (
                <Clickable
                    onClick={hide}
                    action="hide-modal"
                >
                    <Icon
                        name="times"
                        className="modal-form-header-icon"
                    />
                </Clickable>
            )}
        </div>
    );
}

ModalFormHeader.defaultProps = {
    className: undefined,
    children: undefined,
    hide: undefined,
};

ModalFormHeader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    hide: PropTypes.func,
};
