/* eslint-disable consistent-return */
import {
    useState, useEffect, useRef, useImperativeHandle
} from 'react';

export const useHoverIntent = (options) => {
    const {
        ref, sensitivity = 6, interval = 100, timeout = 0, isMobile = false,
    } = options;
    const intentRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);

    let x = 0;
    let y = 0;
    let pX = 0;
    let pY = 0;
    let timer = 0;

    const delay = () => {
        if (timer) {
            clearTimeout(timer);
        }
        return setIsHovering(false);
    };

    const tracker = (e) => {
        x = e.clientX;
        y = e.clientY;
    };

    const compare = (e) => {
        if (timer) {
            clearTimeout(timer);
        }
        if (Math.abs(pX - x) + Math.abs(pY - y) < sensitivity) {
            return isMobile ? setIsHovering(false) : setIsHovering(true);
        }
        pX = x;
        pY = y;
        timer = window.setTimeout(() => compare(e), interval);
    };

    const dispatchOver = (e) => {
        if (timer) {
            clearTimeout(timer);
        }
        if (intentRef.current) {
            intentRef.current.removeEventListener('mousemove', tracker, false);
        }
        if (!isHovering) {
            pX = e.clientX;
            pY = e.clientY;
            if (intentRef.current) {
                intentRef.current.addEventListener('mousemove', tracker, false);
            }
            timer = window.setTimeout(() => compare(e), interval);
        }
    };

    const dispatchOut = (e) => {
        if (timer) {
            clearTimeout(timer);
        }
        if (intentRef.current) {
            intentRef.current.removeEventListener('mousemove', tracker, false);
        }
        if (isHovering) {
            timer = window.setTimeout(() => delay(e), timeout);
        }
    };

    useEffect(() => {
        const currentRef = intentRef.current;
        if (currentRef) {
            currentRef.addEventListener('mouseover', dispatchOver, false);
            currentRef.addEventListener('mouseout', dispatchOut, false);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('mouseover', dispatchOver, false);
                currentRef.removeEventListener('mouseout', dispatchOut, false);
            }
        };
    });

    useImperativeHandle(ref, () => intentRef.current, [intentRef]);

    return [isHovering, intentRef, setIsHovering];
};
