import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../../shared/components';
import logoSvg from './images/logo.svg';
import gearSvg from './images/gear.svg';
import './styles.css';

export default function AppUpdatingPopup({ visible }) {
    return (
        <Modal className="app-updating-popup" visible={visible}>
            <div className="app-updating-popup-content">
                <div className="app-updating-popup-icon-wrapper">
                    <img className="app-updating-popup-gear" src={gearSvg} alt="" />
                    <img className="app-updating-popup-logo" src={logoSvg} alt="" />
                </div>
                <div className="app-updating-popup-descr">
                    Please wait while we update MPOWR Envision, it will only take
                    a few seconds and we promise it will be worth your wait.
                </div>
                <div className="app-updating-popup-info">
                    The page will be refreshed automatically when complete.
                </div>
            </div>
        </Modal>
    );
}

AppUpdatingPopup.defaultProps = {
    visible: false
};

AppUpdatingPopup.propTypes = {
    visible: PropTypes.bool
};
