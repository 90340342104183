import { getScrollX, getScrollY } from '../../utils/dom';

export const snapPoints = Object.freeze({
    topCenter: 'top-center',
    bottomCenter: 'bottom-center',
    bottomHalfLeftCenter: 'bottom-half-left-center',
    bottomHalfRightCenter: 'bottom-half-right-center',
    left: 'left',
    right: 'right',
    top: 'top',
});

export const arrowOffset = 5;

export function getSnapPointCoordinates(element, snapPoint, paramTopOffset = 0) {
    const {
        top,
        left,
        width,
        height,
    } = element.getBoundingClientRect();

    const topOffset = top - paramTopOffset + getScrollY();
    const leftOffset = left + getScrollX();

    switch (snapPoint) {
        case snapPoints.left:
            return {
                x: leftOffset,
                y: topOffset + (height / 2),
                snapPoint: snapPoints.left
            };
        case snapPoints.right:
            return {
                x: leftOffset + width,
                y: topOffset + (height / 2),
                snapPoint: snapPoints.right
            };
        case snapPoints.top:
            return {
                x: leftOffset + width,
                y: topOffset - arrowOffset,
                snapPoint: snapPoints.top
            };
        case snapPoints.topCenter:
            return {
                x: leftOffset + (width / 2),
                y: topOffset - arrowOffset,
                snapPoint: snapPoints.topCenter
            };
        case snapPoints.bottomHalfLeftCenter:
            return {
                x: leftOffset + (width / 4) - arrowOffset,
                y: topOffset + height + arrowOffset,
                snapPoint: snapPoints.bottomHalfLeftCenter
            };
        case snapPoints.bottomHalfRightCenter:
            return {
                x: leftOffset + ((width / 4) * 3) + arrowOffset,
                y: topOffset + height + arrowOffset,
                snapPoint: snapPoints.bottomHalfRightCenter
            };
        case snapPoints.bottomCenter:
        default:
            return {
                x: leftOffset + (width / 2),
                y: topOffset + height + arrowOffset,
                snapPoint: snapPoints.bottomCenter
            };
    }
}
