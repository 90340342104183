import queryString from 'query-string';
import request from '../../../request';
import { createDetails } from '../../../shared/entities';

function validateToken(params) {
    const query = queryString.stringify(params);
    return request.get(`/invite_token?${query}`);
}

export default createDetails({
    apiUrl: '/invite_token',
    name: 'invite-token',
    updateHTML: false,
    extendAPI: {
        get: validateToken,
    }
});
