import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    WizardContent, LoadingIndicator, GridContainer, Text,
} from '../../../shared/components';
import {
    WizardFormSlot, WizardFormInfo,
} from '../../../shared/components/wizard';
import { getEntityData } from '../../../shared/utils/entity';
import { getStringTypeByCode } from '../../../shared/utils/entity-type';
import styles from '../../wizard-entity/wizard-modal/styles.module.css';

const getNormalaizedEntityType = entity_type => (Number.isInteger(entity_type)
    ? getStringTypeByCode(entity_type) : entity_type);

function TacticChildrenWizard({
    fetching,
    Content,
    formProps,
    titles,
    ...rest
}) {
    const { initialValues = {} } = rest;
    const entityData = initialValues.entity_type
        ? getEntityData(getNormalaizedEntityType(initialValues.entity_type), titles)
        : null;
    return (
        <Fragment>
            {entityData && (
                <WizardFormInfo
                    label={entityData.label}
                    innerContainerClassName={styles.modalBodyWizardSection}
                    description={(
                        <div>
                            {entityData.description}{' '}
                            <Text
                                component="a"
                                href={entityData.infoLink}
                                underline
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Click here
                            </Text>{' '}
                            to learn more.
                        </div>
                    )}
                    icon={entityData.icon}
                />
            )}
            <WizardContent className={styles.modalBodyWizardSection}>
                <GridContainer>
                    <WizardFormSlot>
                        {fetching && <LoadingIndicator centered />}
                        {(!fetching && Content) && <Content {...formProps} {...rest} titles={titles} />}
                    </WizardFormSlot>
                </GridContainer>
            </WizardContent>
        </Fragment>
    );
}

TacticChildrenWizard.propTypes = {
    fetching: PropTypes.bool.isRequired,
    Content: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func
    ]).isRequired,
    formProps: PropTypes.object.isRequired,
    titles: PropTypes.object.isRequired,
};

export default TacticChildrenWizard;
