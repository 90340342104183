import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThresholdInput } from '../../../../shared/components';
import { periodTargetAttributes as PTAttrs, types } from '../../constants';
import { parseThresholdsByType } from '../../utils';
import { PeriodTargetContainer } from './period-target-layout';
import { roundDecimal } from '../../../../shared/utils/math';

export default function PeriodTargetsStatic({
    thresholds, rangeOfMeasure, item, isTreeOpen
}) {
    const targetValue = roundDecimal(get(item, PTAttrs.target_value));
    const currentThresholds = parseThresholdsByType(
        types.numeric,
        thresholds,
        targetValue
    );

    return (
        <PeriodTargetContainer Component="section">
            <ThresholdInput
                label="Projected Health Tolerance"
                className="static-projected-health-threshold"
                strategy={rangeOfMeasure}
                fieldWidth={36}
                input={{
                    value: currentThresholds,
                }}
                mode={ThresholdInput.modes.readonly}
                isTreeOpen={isTreeOpen}
            />
        </PeriodTargetContainer>
    );
}

PeriodTargetsStatic.propTypes = {
    item: PropTypes.object.isRequired,
    rangeOfMeasure: PropTypes.string.isRequired,
    thresholds: PropTypes.array.isRequired,
    isTreeOpen: PropTypes.bool.isRequired,
};
