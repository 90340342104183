import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Composer from 'react-composer';
import { mapActionsToLegacyFormat, legacyFormatSelectorFactory } from '../../../../../shared/entities-v2/collection/legacy';
import createListOfItems from '../../../../../shared/modules/list-view/component';
import {
    TableView, PromptProvider
} from '../../../../../shared/components';
import { relatedLinkConstants } from '../../../../secondary-entities/related-link';
import { selectUserId } from '../../../../user/selectors';
import csfRelatedLinkCollection from '../csf-related-links-collection';
import { csfRelatedLinkModel } from '../csf-related-links-models';
import CsfRelatedLinkListTable from './csf-related-link-list-table';
import './csf-related-link-list.css';


const Table = createListOfItems({
    actions: mapActionsToLegacyFormat(csfRelatedLinkCollection.actions),
    selector: legacyFormatSelectorFactory(csfRelatedLinkCollection.selectors),
})();

const { types, defaultRequestIncludes, attributeNames: attrNames } = relatedLinkConstants;

class CsfRelatedLinkList extends Component {
    state = {
        sort: 'title,asc',
    };

    handleToggleSort = (sort) => {
        this.setState({
            sort: TableView.WithSort.defaultFormatSort(sort),
        });
    };

    handleRemove = (id) => {
        const { remove } = this.props;

        remove({ id });
    };

    renderTable = (promptProps, sortProps) => {
        const {
            parentId, parentType, userId, permissions
        } = this.props;
        const { sort } = this.state;

        const params = {
            [attrNames.primaryLinkId]: parentId,
            [attrNames.primaryLinkType]: parentType,
            [attrNames.sort]: sort,
            [attrNames.includes]: defaultRequestIncludes,
            [attrNames.type]: types.internal,
        };

        const tableProps = {
            toggleSort: sortProps.toggleSort,
            sorts: sortProps.sorts,
            permissions,
            userId,
            onRemove: id => promptProps.show(id, {
                description: 'You are about to remove this link.',
            }),
        };

        return (
            <Table
                paginationDisabled
                RendererComponent={TableView.ListRenderer}
                customRenderFunc={CsfRelatedLinkListTable}
                params={params}
                customRenderProps={tableProps}
            />
        );
    };

    render() {
        const { sort } = this.state;

        const components = [
            <PromptProvider onAccept={this.handleRemove} />,
            <TableView.WithSort onSortChange={this.handleToggleSort} sorts={sort} />,
        ];

        return (
            <div className="driver-related-link-list">
                <Composer components={components}>
                    {([prompt, sorts]) => this.renderTable(prompt, sorts)}
                </Composer>
            </div>
        );
    }
}

CsfRelatedLinkList.propTypes = {
    remove: PropTypes.func.isRequired,
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    permissions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        userId: selectUserId(state)
    };
}

const mapDispatchToProps = {
    remove: csfRelatedLinkModel.actions.remove.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CsfRelatedLinkList);
