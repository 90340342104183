/** @namespace Utils.Entity */
import { isArray, get, isNil } from 'lodash';
import u from 'updeep';
import Dictionary from '../modules/dictionary';
import {
    getTypeByEntity, types, getStringTypeByCode, typeCodes
} from './entity-type';
import isOwnEntity from './permissions';

/**
 * @typedef {Object} Entity
 */

/**
 * Get entity status by code
 * @function getEntityStatus
 * @memberof Utils.Entity
 * @param {EntityState} state Entity status in numeric format
 * @return {string} Entity status in literal format
 */
export function getEntityStatus(state) {
    switch (state) {
        case 1: return 'Active';
        case 2: return 'Destroyed';
        case 3: return 'Archived';
        case 4: return 'Draft';

        default: return '';
    }
}

/**
 * Enum for entity progress statuses.
 * @typedef {number} ProgressStatus
 * @memberof Utils.Entity
 * @readonly
 * @enum {ProgressStatus}
 */
export const progressStatuses = {
    healthy: 1,
    atRisk: 2,
    critical: 3,
    idle: 4,
};

/**
 * Enum for entity states.
 * @typedef {number} EntityState
 * @memberof Utils.Entity
 * @readonly
 * @enum {EntityState}
 */
export const entityState = Object.freeze({
    active: 1,
    destroyed: 2,
    archived: 3,
    draft: 4,
    inactive: 5
});

/**
 * Enum for entity status.
 * @typedef {string} EntityStatus
 * @memberof Utils.Entity
 * @readonly
 * @enum {EntityStatus}
 */
export const entityStatus = Object.freeze({
    closed: 'closed',
    pending: 'pending',
    progress: 'progress',
    hold: 'hold',
    cancelled: 'cancelled',
});

/**
 * Checks are equal two entities or not
 * @function isEqual
 * @memberof Utils.Entity
 * @param {Entity} a First entity
 * @param {Entity} b Second entity
 * @return {boolean} True if entities are equal
 */
export function isEqual(a, b) {
    return (a.id === b.id && a.entity_type === b.entity_type);
}

/**
 * Enum for entity icons.
 * @memberof Utils.Entity
 * @typedef {string} EntityIcon
 * @readonly
 * @enum {EntityIcon}
 */
const icons = Object.freeze({
    [types.goal]: 'layer-group',
    [types.driver]: 'trophy-alt',
    [types.milestone]: 'analytics',

    [types.strategy_map]: 'puzzle-piece',
    [types.segment]: 'user-chart',
    [types.sub_segment]: 'bullseye-arrow',

    [types.progress_note]: 'file-alt',
    [types.attachment]: 'paperclip',

    [types.plan]: 'chess-king-alt',
    [types.action]: 'check-circle',
});

/**
 * Enum for entity icons by type codes.
 * @memberof Utils.Entity
 * @typedef {string} EntityIconTypeCode
 * @readonly
 * @enum {EntityIconTypeCode}
 */
const iconsByTypeCodes = Object.freeze({
    [typeCodes.action]: 'check-circle',
    [typeCodes.tactic]: 'chess-king-alt',
    [typeCodes.kpi]: 'analytics',
    [typeCodes.objective]: 'bullseye-arrow',
    [typeCodes.strategy]: 'user-chart',
    [typeCodes.segment]: 'puzzle-piece',
    [typeCodes.driver]: 'trophy-alt',
    [typeCodes.plan]: 'layer-group',
    [typeCodes.progressNote]: 'file-alt',
    [typeCodes.attachment]: 'paperclip',
});

/**
 * Get icon name of an entity
 * @function getIconName
 * @memberof Utils.Entity
 * @param {Entity} entity
 * @return {EntityIcon} Icon of a specified entity
 */
export function getIconName(entity) {
    const type = getTypeByEntity(entity);

    return icons[type];
}

/**
 * Get icon name of an entity by type
 * @function getIconByType
 * @memberof Utils.Entity
 * @param {EntityType} type Entity type
 * @return {EntityIcon} Icon for a specified entity type
 */
export function getIconByType(type) {
    return icons[type];
}

/**
 * Get icon name of an entity by type code
 * @function getIconByTypeCode
 * @memberof Utils.Entity
 * @param {EntityTypeCode} typeCode Entity type code
 * @return {EntityIcon} Icon for a specified type code
 */
export function getIconByTypeCode(typeCode) {
    return iconsByTypeCodes[typeCode];
}

// TODO: remove duplicated logic of function getIconByTypeCode
/**
 * Get icon name of an entity by type code
 * @function getIconByTypeId
 * @memberof Utils.Entity
 * @param {EntityTypeCode} entityTypeCode Entity type code
 * @return {EntityIcon} Icon for a specified type code
 */
export function getIconByTypeId(entityTypeCode) {
    return icons[getStringTypeByCode(entityTypeCode)];
}

/**
 * Check if an entity is archived
 * @function isActive
 * @memberof Utils.Entity
 * @param {Entity} entity Checked entity
 * @return {boolean} True if the entity is active
 */
export function isActive(entity) {
    return entity.state === entityState.active;
}

/**
 * Check if an entity is archived
 * @function isArchived
 * @memberof Utils.Entity
 * @param {Entity} entity Checked entity
 * @return {boolean} True if the entity is archived
 */
export function isArchived(entity) {
    return entity.state === entityState.archived;
}

/**
 * Check if an entity is draft
 * @function isDraftEntity
 * @memberof Utils.Entity
 * @param {Entity} entity Checked entity
 * @return {boolean} True if the entity is archived
 */
export function isDraftEntity(entity) {
    return entity.state === entityState.draft;
}

/**
 * Check if an entity is closed
 * @function isClosed
 * @memberof Utils.Entity
 * @param {Entity} entity Checked entity
 * @return {boolean} True if the entity is closed
 */
export function isClosed(entity) {
    return entity.status === entityStatus.closed;
}

/**
 * Check if an entity is inactive
 * @function isInactive
 * @memberof Utils.Entity
 * @param {Entity} entity Checked entity
 * @return {boolean} True if the entity is inactive
 */
export function isInactive(entity) {
    return entity.state === entityState.inactive;
}

/**
 * Check permissions for manipulation with children entities
 * @function getEntityPermissions
 * @memberof Utils.Entity
 * @param {Entity} entity Checked entity
 * @param {number} userId Checked user
 * @return {Object} Object with permissions for checking possibilities to manipulate with children entities
 */
export function getEntityPermissions(entity, userId) {
    const { permissions } = entity;
    const isOwn = isOwnEntity(userId, entity);

    return {
        canCreate: permissions && permissions['can-create'],
        canUpdate: permissions && (permissions['can-update'] || (permissions['can-update-own'] && isOwn)),
        canDestroy: permissions && (permissions['can-destroy'] || (permissions['can-destroy-own'] && isOwn)),
        canCreateRelatedLinks: permissions && permissions['can-create-related_links'],
        canDestroyRelatedLinks: permissions
            && (permissions['can-destroy-related_links'] || (permissions['can-destroy-own-related_links'] && isOwn))
    };
}

/**
 * Get description for create/edit entities in wizard
 * @function getEntityData
 * @memberof Utils.Entity
 * @param {EntityType} type Type of description
 * @param {Object} titles Titles of entities
 * @return {Object} Description data object
 */
export function getEntityData(type, titles) {
    switch (type) {
        case types.strategy_map: {
            return {
                label: titles[type],
                description: `The first level of your plan. You can segment in a variety of ways:
                    by department, by shared values, by industries served, by customer benefits,
                    by business unit etc. You decide.`,
                icon: getIconByType(type),
                type: types.strategy_map,
                // eslint-disable-next-line max-len
                infoLink: 'https://www2.mpowr.com/Define-segments-template?utm_source=PlanWizard'
            };
        }
        case types.segment: {
            return {
                label: titles[type],
                description: `What is the method by which you will accomplish important goals
                    within each segment? This is a required level and should include both the method
                    and the broad goal.`,
                icon: getIconByType(type),
                type: types.segment,
                // eslint-disable-next-line max-len
                infoLink: 'https://www2.mpowr.com/planning-strategies-template?utm_source=PlanWizard'
            };
        }
        case types.sub_segment: {
            return {
                label: titles[type],
                description: `Strategy broken down into individual goals that are clear and
                    measurable. This is not a required level but if used, objectives should have the
                    target goal within the statement.`,
                icon: getIconByType(type),
                type: types.sub_segment,
                // eslint-disable-next-line max-len
                infoLink: 'https://www2.mpowr.com/objectives-template?utm_source=PlanWizard'
            };
        }
        case types.plan: {
            return {
                label: titles[type],
                description: `The specific accomplishment that should happen in order to
                    successfully achieve the objectives or strategies.`,
                icon: getIconByType(type),
                type: types.plan,
                // eslint-disable-next-line max-len
                infoLink: 'https://www2.mpowr.com/Tactics-action-items-template?utm_source=PlanWizard'
            };
        }
        case types.action: {
            return {
                label: titles[type],
                description: `A task you must accomplish in order to successfully complete an
                    objective or strategy.`,
                icon: getIconByType(type),
                type: types.action,
                // eslint-disable-next-line max-len
                infoLink: 'https://www2.mpowr.com/Tactics-action-items-template?utm_source=PlanWizard'
            };
        }
        case types.milestone: {
            return {
                label: titles[type],
                description: `A specific measurement that allows you to understand if your
                    organization is on the right track towards accomplishing your goals.`,
                icon: getIconByType(type),
                type: types.milestone,
                // eslint-disable-next-line max-len
                infoLink: 'https://mpowr.com/types-of-key-performance-indicators/?utm_source=PlanWizard'
            };
        }
        case types.driver: {
            return {
                label: titles[type],
                description: `An overall element of your organization that you must get right if
                    you are to stay true to your mission. These may be linked to one or many KPIs,
                    Objectives, Tactics and Action Items.`,
                icon: getIconByType(type),
                type: types.driver,
                // eslint-disable-next-line max-len
                infoLink: 'https://mpowrsoftware.atlassian.net/servicedesk/customer/portal/5/article/98304379'
            };
        }
        default: {
            return {
                label: titles[type],
                description: '',
                icon: getIconByType(type)
            };
        }
    }
}

/**
 * Get initial values for entities forms
 * @function getInitialValues
 * @memberof Utils.Entity
 * @param {EntityType} data Source entity
 * @param {Object} attributes Attributes list
 * @return {Object} Object with initial values for form
 */
export function getInitialValues(data, attributes) {
    return (isArray(attributes) ? attributes : Object.values(attributes))
        .reduce((acc, attr) => {
            acc[attr] = data[attr];
            return acc;
        }, {});
}

/**
 * Get active children entities types of specified entity
 * @function getActiveChildrenTypes
 * @memberof Utils.Entity
 * @param {Entity} entity Source entity
 * @return {Array<EntityTypeCode>} Active children entities types
 */
// duplicate of collectExistingEntityCodes()
function getActiveChildrenTypes(entity) {
    const counters = entity.entity_children_counters || {};
    return Object.entries(counters).filter(([, entityCounters]) => {
        return ['active', 'archive', 'draft', 'inactive'].find(state => entityCounters[state] > 0);
    }).map(([type]) => Number(type));
}

function getCounterStateAttr(state) {
    switch (state) {
        case 1: return 'active';
        case 2: return 'inactive';
        case 3: return 'archive';
        case 4: return 'draft';

        default: return null;
    }
}

export function incrementChildrenCounter(srcCounters, typeCode, state) {
    const stateAttr = getCounterStateAttr(state);

    return u({
        [typeCode]: {
            [stateAttr]: get(srcCounters, [typeCode, stateAttr], 0) + 1,
        }
    }, srcCounters);
}

/**
 * Get count of active and inactive children in specified entity
 * @function getChildrenCount
 * @memberof Utils.Entity
 * @param {Entity} entity Source entity
 * @return {number} Count of active and inactive children
 */
export function getChildrenCount(entity) {
    if ('entity_children_counters' in entity) {
        const counters = entity.entity_children_counters || {};
        let count = 0;

        Object.entries(counters).filter(([, entityCounters]) => {
            return ['active', 'inactive'].forEach((state) => {
                const value = entityCounters[state];

                if (!isNil(value)) {
                    count += value;
                }
            });
        });

        return count;
    }

    return entity.child_count;
}

/**
 * Check if entity has active children of specified types
 * @function hasActiveChildrenTypes
 * @memberof Utils.Entity
 * @param {Entity} entity Source entity
 * @param {Array<EntityTypeCode>} checkedEntityTypes Checked entities types
 * @return {boolean} True if entity has active children of specified types
 */
export function hasActiveChildrenTypes(entity, checkedEntityTypes) {
    return !!getActiveChildrenTypes(entity).find(type => checkedEntityTypes.includes(type));
}

/**
 * Get allowed adding entity type
 * @function getAllowedEntityType
 * @memberof Utils.EntityType
 * @param {Entity} entity Source entity
 * @return {EntityType|null} True if entity is objective
 */
export function getAllowedEntityType(entity) {
    if (entity.entity_type === typeCodes.strategy) {
        if (hasActiveChildrenTypes(entity, [typeCodes.objective])) {
            return types.sub_segment;
        }
        if (hasActiveChildrenTypes(entity, [typeCodes.action, typeCodes.plan, typeCodes.kpi])) {
            return types.plan;
        }
    }

    return null;
}

export const defaultTitles = {
    [typeCodes.driver]: Dictionary({ name: 'drivers' }),
    [typeCodes.kpi]: Dictionary({ name: 'milestones' }),
    [typeCodes.segment]: Dictionary({ name: 'strategies' }),
    [typeCodes.strategy]: Dictionary({ name: 'segments' }),
    [typeCodes.objective]: Dictionary({ name: 'subsegments' }),
    [typeCodes.tactic]: Dictionary({ name: 'plan' }),
    [typeCodes.action]: Dictionary({ name: 'action' }),
};
