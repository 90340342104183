import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Clickable from '../clickable/component';
import Icon from '../icon/component';
import './styles.css';

export default function EditableContent({
    title,
    onClick,
    children,
    className,
    editable
}) {
    return (
        <div
            className={classnames('editable-content', {
                [className]: !!className,
            })}
        >
            <h4 className="editable-content-title">
                {title}
                {editable && (
                    <Clickable
                        className="editable-content-action"
                        action="edit-content"
                        onClick={onClick}
                    >
                        <Icon
                            name="pen"
                            type="regular"
                            className="editable-content-icon"
                        />
                    </Clickable>
                )}
            </h4>

            {children}
        </div>
    );
}

EditableContent.defaultProps = {
    children: undefined,
    title: 'Target State',
    className: undefined,
    editable: true,
};

EditableContent.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node,
    title: PropTypes.oneOfType(
        [PropTypes.string, PropTypes.object]
    ),
    className: PropTypes.string,
    editable: PropTypes.bool,
};
