import { createAction } from 'redux-act';

const prefix = 'user';

export const getProfile = createAction(`${prefix}/PROFILE_GET`);
export const getProfileSuccess = createAction(`${prefix}/PROFILE_GET_SUCCESS`);
export const getProfileError = createAction(`${prefix}/PROFILE_GET_ERROR`);

export const updateUser = createAction(`${prefix}/UPDATE_USER`);
export const updateUserMFAState = createAction(`${prefix}/UPDATE_USER_MFA_STATE`);
