import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
    attributes,
    kpiTypes
} from '../../../modules/goalmap-milestones/constants';
import { ThresholdInput, MeasureRangeSelector } from '../index';
import './styles.css';

function getAvailableMeasures(kpiType) {
    const measures = [
        MeasureRangeSelector.measures.ascending,
        MeasureRangeSelector.measures.descending,
    ];

    if (kpiType !== kpiTypes.linear.type) {
        measures.push(MeasureRangeSelector.measures.between);
    }

    return measures;
}

function ThresholdParamsSection({
    title,
    currentValues,
    onThresholdChange,
    onRangeChange,
    thresholdLabels,
    isTreeOpen
}) {
    const { range, thresholds } = currentValues;
    const measures = getAvailableMeasures(currentValues.kpiType);

    if (!range || !thresholds) {
        return null;
    }

    return (
        <div className="threshold-params-section">
            <h1 className="threshold-params-section-title">
                { title }
            </h1>
            <div className="threshold-params-section-content">
                <Field
                    component={MeasureRangeSelector}
                    name={attributes.range_of_measure}
                    disabled={currentValues.kpiType === kpiTypes.linear.type}
                    props={{
                        name: attributes.range_of_measure,
                        value: range,
                        onChange: onRangeChange,
                        measures,
                    }}
                />
                <Field
                    component={ThresholdInput}
                    name={attributes.thresholds}
                    props={{
                        strategy: range,
                        labels: thresholdLabels,
                        isTreeOpen,
                        input: {
                            value: thresholds,
                            onChange: onThresholdChange
                        }
                    }}
                />
            </div>
        </div>
    );
}

ThresholdParamsSection.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    currentValues: PropTypes.object,
    onThresholdChange: PropTypes.func,
    onRangeChange: PropTypes.func,
    thresholdLabels: PropTypes.array.isRequired,
    isTreeOpen: PropTypes.bool.isRequired,
};

ThresholdParamsSection.defaultProps = {
    title: 'Configure Threshold Parameters',
    currentValues: {},
    onThresholdChange() {},
    onRangeChange() {},
};

export default ThresholdParamsSection;
