import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { ServicePage, Button } from '../../shared/components';

export default function ServicePage404({ children }) {
    return (
        <ServicePage
            icon="exclamation-triangle"
            title="The page is not found"
            description="The link you clicked may be broken or the page may have been removed"
        >
            <Button
                styleType="success"
                component={Link}
                to="/dashboard"
            >
                Back to Dashboard
            </Button>
            {children}
        </ServicePage>
    );
}

ServicePage404.defaultProps = {
    children: null,
};

ServicePage404.propTypes = {
    children: PropTypes.node,
};
