/* eslint-disable class-methods-use-this */
import u from 'updeep';
import { get, bindAll } from 'lodash';
import request from '../../../request';
import createActions from './actions';
import createReducer from './reducer';
import createSaga from './saga';
import createSelectors from './selectors';
import BaseResource from '../base/resource';
import * as legacyUtils from './legacy';

/**
 * The main purpose of Collection is to store lists of resources.
 * @class
 * @augments BaseResource
 * @memberof RESTEntities
 *
 * @description To use instatiated collection you need to attach `reducer` and `saga` to
 * application store.
 *
 * @example <caption>Collection instatiation and attachment to Redux Store</caption>
 * const userCollection = new Collection({
 *   apiUrl: '/users',
 *   name: 'user-list'
 * });
 *
 * combineReducers({
 *   ...otherReducers,
 *   [userCollection.name]: userCollection.reducer,
 * });
 *
 * function* rootSaga() {
 *   yield [
 *      ...otherSagas,
 *      userCollection.saga(),
 *   ];
 * }
 */
class Collection extends BaseResource {
    constructor(options = {}) {
        super(options);

        this.idKey = options.idKey || 'id';

        bindAll(this, [
            'getApiUrl',
            'fetch',
            'format',
            'parse',
            'parsePager',
            'parseItem',
        ]);

        /**
         * @type {Object}
         * Object that contains all Action Creators
         */
        this.actions = createActions({
            prefix: this.name,
            additionalActions: this.additionalArtifacts.actions,
        });

        /**
         * @type {Function}
         * Root reducer of Model
         */
        this.reducer = createReducer(
            this.actions,
            { idKey: this.idKey },
            this.additionalArtifacts.reducers,
        );

        /**
         * @type {Object}
         * Object with all available selectors
         */
        this.selectors = createSelectors(this.name);

        /**
         * @type {Function}
         * Root saga of Model
         */
        this.saga = createSaga(this.actions, this.fetch);
    }

    /**
     * Creates Resource List API URL based on `params`. If `options.getApiUrl` is
     * specified then it will call this function with `params` as an argument and will
     * expect it to return a string. Will be used for [fetch]{@link Collection#fetch}.
     * @function getApiUrl
     * @memberof RESTEntities.Collection
     * @instance
     * @param {Object} params - Object to send to server.
     * @returns {string} API URL string.
    */
    getApiUrl(params) {
        return this.apiUrl || this.options.getApiUrl(params);
    }

    /**
     * Retreives REST API Resource list and saves it to Redux Store
     * @async
     * @function fetch
     * @memberof RESTEntities.Collection
     * @instance
     * @param {Object} params - Data to send to server. Will be
     * converted using `toDataURI` function
     * @param {Object} options - Request options
     * @returns {Promise<Object>} Server response
     *
     * @example <caption>To trigger fetching you have to dispatch an action.</caption>
     * store.dispatch(userCollection.actions.fetch.request({ page: 1 }));
    */
    fetch(params, options) {
        const formattedParams = this.format(params);
        const url = this.getApiUrl(params);

        return request
            .get(url, { params: formattedParams, ...options })
            .then(this.parse, this.parseError);
    }

    /**
     * Formats `params` object before sending to server via [fetch]{@link Collection#fetch}
     * @function format
     * @memberof RESTEntities.Collection
     * @instance
     * @param {Object} params - Object to format.
     * @returns {Object} Formatted `params`
    */
    format(params) {
        return params;
    }

    /**
     * Parses `data` object after retreiving it from REST API and before
     * setting it as attributes to Redux Store.
     * Applies [Collection.parseItem]{@link Collection#parseItem} to each element in the list;
     * @function parse
     * @memberof RESTEntities.Collection
     * @instance
     * @param {Object} data - Object to parse.
     * @returns {Object} Parsed `data`
    */
    parse(response) {
        const items = get(response, 'data', []).map(this.parseItem);
        const pager = this.parsePager(items, response);

        return u({ data: items, pager }, response);
    }

    parsePager(parsedItems, response) {
        const counter = response.counter || parsedItems.length;

        return {
            counter,
            current: response.current_page,
            perPage: response.per_page,
            totalItems: response.total,
            totalPages: response.last_page,
        };
    }

    /**
     * Parses list item.
     * @function parseItem
     * @memberof RESTEntities.Collection
     * @instance
     * @param {Object} data - Object to parse.
     * @returns {Object} Parsed `data`
    */
    parseItem(data) {
        return data;
    }
}

Collection.legacyUtils = legacyUtils;

Collection.additionalArtifacts = {
    actions: 'actions',
    reducers: 'reducers',
};

export default Collection;
