import request from '../../request';
import { List, createDetails } from '../../shared/entities';

const apiUrl = '/goals/search';

export const list = new List({
    apiUrl,
    name: 'archived-goalmap-list'
});

export const details = createDetails({
    apiUrl,
    name: 'archived-goalmap-list-details',
    onUpdate: list.actions.remove,
    onRemove: list.actions.remove,
    extendAPI: {
        updateStatus: (params) => {
            const { id, state } = params;
            return request.put(`/goals/${id}/state/${state}`);
        }
    }
});
