import u from 'updeep';
import { flow, filter, get } from 'lodash';
import { createReducer } from 'redux-act';
import { updateInCollection } from '../../utils/collection';
import parseRequestPayload from '../utils/parse-request-payload';

const pagerInitialState = {
    current: 1,
    counter: 0,
    perPage: 0,
    totalItems: 0,
    totalPages: 1,
};

const initialState = {
    items: [],
    params: {},
    pager: pagerInitialState,
    asyncState: {
        fetch: false,
        fetchLazy: false,
    },
    error: null,
    raw: {},
    updatingItemId: undefined,
};

function handleUpdatingItemId(state, payload) {
    return u({ updatingItemId: payload.id, }, state,);
}

function removeUpdatingItemId(state) {
    return u({ updatingItemId: undefined, }, state,);
}

function updateAsyncState(type, value) {
    return u({ asyncState: { [type]: value } });
}

function updateItems(items) {
    return u({ items });
}

function updateParams(params) {
    return u({ params: u.constant(params) });
}

function updatePager(response) {
    return u({
        pager: {
            current: response.current_page,
            totalPages: response.last_page,
            perPage: response.per_page,
            totalItems: response.total,
        },
    });
}

function updatePagerTotalItems(totalItems) {
    return u({
        pager: {
            totalItems
        }
    });
}

function updateRaw(raw) {
    return u({ raw });
}

function updateError(error) {
    return u({ error });
}

function getIdFromPayload(payload, idKey) {
    return payload[idKey] || payload.id || payload?.params?.id || payload;
}

function handleFetchRequest(state, payload) {
    const { options, data } = parseRequestPayload(payload);

    if (options.silent) {
        return updateParams(data)(state);
    }

    return flow(
        updateParams(data),
        updateAsyncState('fetch', true),
        updateItems(initialState.items),
        updateError(null),
    )(state);
}

function handleFetchSuccess(state, payload) {
    return flow(
        updateAsyncState('fetch', false),
        updateItems(payload.response.data),
        updateRaw(payload.response),
        updatePager(payload.response),
        updateError(null),
    )(state);
}

function handleFetchError(state, payload) {
    return flow(
        updateAsyncState('fetch', false),
        updateAsyncState('fetchLazy', false),
        updateError(payload),
    )(state);
}

function handleFetchLazyRequest(state, payload) {
    const { data } = parseRequestPayload(payload);

    return flow(
        updateAsyncState('fetchLazy', true),
        updateParams(data),
        updateError(null),
    )(state);
}

function handleFetchLazySucces(state, payload) {
    return flow(
        updateAsyncState('fetchLazy', false),
        updateItems([...state.items, ...payload.response.data]),
        updatePager(payload.response),
        updateRaw(payload.response),
        updateError(null),
    )(state);
}

function handleReset() {
    return initialState;
}

function handlePush(state, payload) {
    const items = payload?.response?.items || payload?.response || payload;
    return flow(
        updateItems(Array.isArray(items) ? [...items, ...state.items] : [items, ...state.items]),
        updatePagerTotalItems(state.pager.totalItems + (Array.isArray(items) ? items.length : 1)),
    )(state);
}

function handleRemove(state, payload, _, idKey) {
    const safeIdKey = payload.idKey || idKey;
    const id = getIdFromPayload(payload?.response || payload, safeIdKey);
    const items = filter([...state.items], item => get(item, safeIdKey) !== id);
    return flow(
        updateItems(items),
        updatePagerTotalItems(state.pager.totalItems - 1),
    )(state);
}

function handleUpdate(state, payload, _, idKey) {
    const item = payload?.response || payload;

    if (payload?.params?.state === 'destroy') {
        return handleRemove(state, payload.params, null, idKey);
    }

    const nextItems = updateInCollection(state.items, item[idKey], item, idKey);

    return flow(
        updateItems(nextItems),
        removeUpdatingItemId,
    )(state);
}

function handleUnshift(state, payload) {
    const items = payload?.response?.items || payload?.response || payload;
    return flow(
        updateItems(Array.isArray(items) ? [...state.items, ...items] : [...state.items, items]),
        updatePagerTotalItems(state.pager.totalItems + (Array.isArray(items) ? items.length : 1)),
    )(state);
}

function createReducerFn(actions, options, additionalReducers = {}) {
    const { idKey } = options;

    const reducerMap = {
        [actions.fetch.request]: handleFetchRequest,
        [actions.fetch.success]: handleFetchSuccess,
        [actions.fetch.error]: handleFetchError,

        [actions.fetchLazy.request]: handleFetchLazyRequest,
        [actions.fetchLazy.success]: handleFetchLazySucces,
        [actions.fetchLazy.error]: handleFetchError,

        [actions.push]: handlePush,
        [actions.reset]: handleReset,
        [actions.remove]: (...args) => handleRemove(...args, idKey),
        [actions.update]: (...args) => handleUpdate(...args, idKey),
        [actions.unshift]: handleUnshift,
        [actions.setUpdatingItemId]: handleUpdatingItemId,
    };

    return createReducer({ ...reducerMap, ...additionalReducers }, initialState);
}

export default createReducerFn;
