import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import {
    Image, Toggle, Button, Icon,
} from '../../../shared/components';

import styles from './item.module.css';

export default function IntegrationListItem({
    id,
    title,
    logo_url,
    description,
    enabled,
    input,
    meta,
    href,
    updating,
    disabled,
    configurable
}) {
    return (
        <div
            className={cx(styles.integrationListItem, {
                [styles.updating]: updating,
                [styles.configurable]: configurable
            })}
        >
            <div className={styles.integrationListItemHeader}>
                <div className={styles.integrationListItemIconContainer}>
                    <Image
                        src={logo_url}
                        alt={title}
                        className={styles.integrationListItemIcon}
                    />
                    <h2 className={styles.integrationListItemTitle}>{title}</h2>
                </div>
                {disabled
                    ? <Icon className={styles.integrationItemLockIcon} name="lock-alt" type={Icon.types.regular} />
                    : (
                        <Toggle
                            id={`integration-${id}`}
                            checked={input.value}
                            {...input}
                            {...meta}
                            disabled={disabled}
                        />
                    )
                }
            </div>
            <p className={styles.integrationListItemDescription}>{description}</p>
            {configurable && (
                <Button
                    className={styles.integrationListItemConfButton}
                    to={href}
                    component={Link}
                    styleType={Button.styleTypes.default}
                    disabled={!enabled || disabled}
                    outline
                >
                    Configure
                </Button>
            )}
        </div>
    );
}

IntegrationListItem.defaultProps = {
    enabled: false,
    updating: false,
    disabled: false,
    configurable: true,
    href: undefined
};

IntegrationListItem.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    href: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    updating: PropTypes.bool,
    disabled: PropTypes.bool,
    configurable: PropTypes.bool,
};
