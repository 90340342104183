import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { noop } from 'lodash';
import * as routeUtils from '../../../routes/rules';
import {
    LoadingIndicator, TableView, Prompt, Text
} from '../../../shared/components';
import { localDeleteRecentPlansAction } from '../../navigation-header/plans-selector/entity';
import Permissions from '../../permissions';
import { selectUserId } from '../../user/selectors';
import SettingsAccountDemoDataItem from './settings-account-demo-data-item';
import settingsAccountDemoDataCollection from './settings-account-demo-data-collection';
import settingsAccountDemoDataModel from './settings-account-demo-data-model';
import styles from './settings-account-demo-data.module.css';
import assignUser from './api';

const SettingsAccountDemoData = () => {
    const dispatch = useDispatch();
    const [promptVisible, setPromptVisible] = useState(false);
    const [isUpdatingRole, setUpdatingRole] = useState(false);
    const [promptId, setPromptId] = useState();
    const isFetching = useSelector(settingsAccountDemoDataCollection.selectors.getFetchingState);
    const isUpdating = useSelector(settingsAccountDemoDataModel.selectors.getStateUpdating);
    const isCreating = useSelector(settingsAccountDemoDataModel.selectors.getStateCreating);
    const items = useSelector(settingsAccountDemoDataCollection.selectors.getItems);
    const userId = useSelector(selectUserId);
    const globalState = useSelector(state => state);

    useEffect(() => {
        const action = settingsAccountDemoDataCollection.actions.fetch.request();
        dispatch(action);
    }, []);

    const handleUpdateSuccess = (onSuccess = noop) => {
        const data = {};
        const options = { silent: true, onSuccess };
        const action = Permissions.model.actions.fetch.request(data, options);

        dispatch(action);
    };

    const handleInstall = (slug) => {
        const data = { type: slug };
        const options = { onSuccess: handleUpdateSuccess };
        const action = settingsAccountDemoDataModel.actions.create.request(data, options);

        dispatch(action);
    };

    const handleReset = (id) => {
        const handleSuccess = () => {
            handleUpdateSuccess();
            dispatch(localDeleteRecentPlansAction({ id }));
        };
        const data = { id };
        const options = { onSuccess: handleSuccess };
        const action = settingsAccountDemoDataModel.actions.update.request(data, options);

        dispatch(action);
    };

    const redirect = (planId) => {
        const href = routeUtils.createUrl(routeUtils.paths.planOverview, {
            [routeUtils.params.PlanId]: planId
        });

        dispatch(push(href));
    };

    const handleViewPlan = (planId) => {
        const hasPermissions = !!Permissions.selectors.selectStrategicPlanPermissions(globalState, planId);

        if (hasPermissions) {
            redirect(planId);
            return;
        }

        setPromptId(planId);
        setPromptVisible(true);
    };

    const handlePromptAccept = () => {
        const onRoleUpdateSuccess = () => {
            setUpdatingRole(false);
            setPromptVisible(false);
            setPromptId(undefined);
            redirect(promptId);
        };

        setUpdatingRole(true);
        assignUser(userId, promptId)
            .then(() => handleUpdateSuccess(onRoleUpdateSuccess))
            .catch(() => setUpdatingRole(false));
    };

    const handlePromptCancel = () => {
        setPromptVisible(false);
        setPromptId(undefined);
    };

    const renderItem = (item) => {
        return (
            <SettingsAccountDemoDataItem
                key={item.id}
                onInstall={handleInstall}
                onReset={handleReset}
                onView={handleViewPlan}
                {...item}
            />
        );
    };

    const renderBody = () => {
        return (
            <TableView.Body>
                {items.map(renderItem)}
            </TableView.Body>
        );
    };

    const renderHeader = () => {
        return (
            <TableView.Header>
                <TableView.HeaderCell
                    align={TableView.align.LEFT}
                    className={styles.cellIndustry}
                >
                    Plan Name
                </TableView.HeaderCell>
                <TableView.HeaderCell
                    align={TableView.align.LEFT}
                    className={styles.cellIndustry}
                >
                    Plan Type
                </TableView.HeaderCell>
                <TableView.HeaderCell
                    align={TableView.align.LEFT}
                >
                    Description
                </TableView.HeaderCell>
                <TableView.HeaderCell
                    align={TableView.align.CENTER}
                    className={styles.cellActions}
                >
                    Actions
                </TableView.HeaderCell>
            </TableView.Header>
        );
    };

    if (isFetching) {
        return <LoadingIndicator centered />;
    }

    return (
        <div className={styles.settingsAccountDemoData}>
            {(isCreating || isUpdating) && (
                <div className="loading-overlay">
                    <LoadingIndicator centered />
                </div>
            )}

            <TableView>
                {renderHeader()}
                {renderBody()}
            </TableView>

            <Prompt
                visible={promptVisible}
                onAccept={handlePromptAccept}
                onCancel={handlePromptCancel}
                isAccepting={isUpdatingRole}
                isTitle={false}
                description={(
                    <Text className={styles.settingsAccountDemoDataPromptDescription}>
                        You are not currently a member of this plan. Would you like to add yourself to gain access to this plan now?
                    </Text>
                )}
            />
        </div>
    );
};

export default SettingsAccountDemoData;
