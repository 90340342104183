import { takeLatest, put, select } from 'redux-saga/effects';
import { isDirty, submit, stopSubmit } from 'redux-form';
import { get } from 'lodash';
import * as actions from './actions';
import { getFirstRole } from './config';
import { storeCreationFormInitialValues, formName } from '../config';
import {
    settingsRolesCollection, settingsRolesModel,
} from '../entity';
import { getSelectedItemId, getDirtyFormNavigationId, getDuplicateItemId } from './selectors';

function* handleFetchSuccess(action) {
    const roles = get(action, ['payload', 'response', 'data']);

    if (roles.length) {
        const firstRole = getFirstRole(roles);
        storeCreationFormInitialValues(firstRole);

        yield put({
            type: actions.setSelectedItemId.getType(),
            payload: {
                id: firstRole.id,
            },
        });
    }
}

function* handleCreateSuccess(action) {
    const id = get(action, ['payload', 'response', 'id']);

    yield put({
        type: actions.setCreateState.getType(),
        payload: undefined,
    });

    yield put({
        type: actions.setSelectedItemId.getType(),
        payload: { id },
    });
}

function* handleRemoveSuccess(action) {
    const removedItemId = action.payload.id;
    const selectedRoleId = yield select(getSelectedItemId);
    const duplicateItemId = yield select(getDuplicateItemId);
    const roles = yield select(settingsRolesCollection.selectors.getItems);
    const firstRole = getFirstRole(roles);

    yield put({
        type: actions.setRemoveItemId.getType(),
        payload: {
            id: undefined,
        },
    });

    if (removedItemId === duplicateItemId) {
        yield put({
            type: actions.setDuplicateItemId.getType(),
            payload: {
                id: undefined,
            },
        });
    }

    if ((selectedRoleId === removedItemId || removedItemId === duplicateItemId) && roles.length) {
        yield put({
            type: actions.setSelectedItemId.getType(),
            payload: {
                id: firstRole.id,
            },
        });
    }
}

function* handleSetSelectedItemId(action) {
    const isDirtyForm = yield select(isDirty(formName));

    if (!isDirtyForm) {
        yield put({
            type: actions.setSelectedItemId.getType(),
            payload: action.payload,
        });
    } else {
        yield put({
            type: actions.setDirtyFormNavigationId.getType(),
            payload: action.payload,
        });
    }
}

function* handleTriggerFormSubmit() {
    yield put(submit(formName));
}

function* handleUpdateSuccess() {
    const dirtyFormNavigationId = yield select(getDirtyFormNavigationId);

    if (dirtyFormNavigationId) {
        yield put({
            type: actions.setSelectedItemId.getType(),
            payload: {
                id: dirtyFormNavigationId,
            },
        });
    }
}

function* handleUpdateError(action) {
    const errors = get(action, ['payload', 'response', 'data', 'message']);
    const dirtyFormNavigationId = yield select(getDirtyFormNavigationId);

    if (dirtyFormNavigationId) {
        yield put({
            type: actions.setDirtyFormNavigationId.getType(),
            payload: {
                id: undefined,
            },
        });
    }

    if (errors) {
        yield put(stopSubmit(formName, errors));
    }
}

function* handleResetCreateState() {
    const roles = yield select(settingsRolesCollection.selectors.getItems);

    const firstRole = getFirstRole(roles);

    yield put({
        type: actions.setSelectedItemId.getType(),
        payload: {
            id: firstRole.id,
        },
    });
}

export default function* SettingsRolesContainerStateSaga() {
    yield takeLatest(settingsRolesCollection.actions.fetch.success.getType(), handleFetchSuccess);
    yield takeLatest(settingsRolesModel.actions.remove.success.getType(), handleRemoveSuccess);
    yield takeLatest(settingsRolesModel.actions.update.success.getType(), handleUpdateSuccess);
    yield takeLatest(settingsRolesModel.actions.update.error.getType(), handleUpdateError);
    yield takeLatest(settingsRolesModel.actions.create.success.getType(), handleCreateSuccess);
    yield takeLatest(actions.setSelectedItemIdRequest, handleSetSelectedItemId);
    yield takeLatest(actions.triggerFormSubmit, handleTriggerFormSubmit);
    yield takeLatest(actions.resetCreateState, handleResetCreateState);
}
