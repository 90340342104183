import React from 'react';
import { render } from 'react-dom';
import { isMobile } from 'mobile-device-detect';
import TagManager from 'react-gtm-module';

import ApplicationContainer from './modules/application/application-container';
import initAuthenticationServiceStatus from './modules/authentication/service-status/authentication-service-status';
import * as localStorageUtils from './shared/utils/local-storage';
import { normalizeVhUnit } from './shared/utils/normalize-vh-unit';
import config from './config';

const tagManagerArgs = {
    gtmId: config.gtmId,
};

TagManager.initialize(tagManagerArgs);
initAuthenticationServiceStatus();

normalizeVhUnit();

const { APP_VERSION } = process.env;
window.APP_VERSION = APP_VERSION;

function renderApp(props = {}) {
    render(
        <ApplicationContainer {...props} />,
        document.getElementById('root')
    );
}

if (isMobile || localStorageUtils.get('allowDesktop', true)) {
    renderApp({
        cookiesError: !localStorageUtils.enabled,
    });

    if (module.hot) {
        module.hot.accept('./modules/application/application-container', () => {
            renderApp({
                cookiesError: !localStorageUtils.enabled,
            });
        });
    }
}
