import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { InlineForm, AdvancedCalculationForm, AdvancedCalculationFormMobile } from '../form';
import { applicationSettingsSelectors } from '../../application';
import createFormModal from '../../../shared/modules/create-view/component';
import { roundDecimal } from '../../../shared/utils/math';
import { create as entity, createOnMobile as entityCreateOnMobile } from '../entity';
import { createFormName, createFormNameOnMobile, attributes as dataPointAttributes } from '../constants';
import * as actionsDataPointList from '../actions';
import { composeInitialValuesCreate, formattedPeriodStartValue } from '../utils';
import { findPeriodTargetByDate } from '../../goalmap-milestones/details/utils';
import { kpiTypes, attributes as kpiAttributes, operations } from '../../goalmap-milestones/constants';
import DataPointModalFormControls from './data-point-modal-form-control';
import '../styles/create.css';
import '../styles/form.css';

const { actions, selector } = entity;
const { actions: actionsOnMobile, selector: selectorOnMobile } = entityCreateOnMobile;

const getPeriodData = ({ type, periodTargets, createFormPeriodStart }) => {
    const isStaticKpi = type === kpiTypes.static.type;
    const formattedStartPeriod = createFormPeriodStart;
    const periodTarget = findPeriodTargetByDate(periodTargets, formattedStartPeriod);
    const targetValue = get(periodTarget, 'target_value');
    return {
        periodStart: createFormPeriodStart,
        periodTarget: isStaticKpi ? null : roundDecimal(targetValue),
    };
};

const MilestoneDataPointCreate = (props) => {
    const AdvCalcForm = props.isMobile ? AdvancedCalculationFormMobile : AdvancedCalculationForm;
    const Form = props.advCalcEnabled ? AdvCalcForm : InlineForm;

    return (
        <Form {...props} />
    );
};

MilestoneDataPointCreate.defaultProps = {
    advCalcEnabled: false,
};

MilestoneDataPointCreate.propTypes = {
    advCalcEnabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = formName => (state, ownProps) => {
    const {
        data, createFormPeriodStart, setPeriodStart, controlHidden = false
    } = ownProps;

    const milestone_id = get(data, kpiAttributes.id);
    const frequency = get(data, kpiAttributes.frequency);
    const advCalcEnabled = get(data, kpiAttributes.advanced_calculation_enabled, false);
    const advCalcSources = get(data, kpiAttributes.advanced_calculation_sources, []);
    const advCalcOperation = get(data, kpiAttributes.advanced_calculation_type) || operations.sum;
    const periodTargets = get(data, kpiAttributes.kpi_period_targets, []);
    const type = get(data, kpiAttributes.kpi_type);
    const goal_id = get(data, kpiAttributes.goal_id);

    const initialValues = composeInitialValuesCreate(
        milestone_id,
        createFormPeriodStart,
        advCalcEnabled,
        advCalcSources,
        getPeriodData({ createFormPeriodStart, type, periodTargets }).periodTarget,
    );
    const formSelector = formValueSelector(formName);
    return {
        initialValues,
        currentValues: {
            periodStart: formSelector(state, dataPointAttributes.period_start),
            dataPoints: formSelector(state, dataPointAttributes.data_points),
        },
        advCalcSources,
        advCalcEnabled,
        advCalcOperation,
        frequency,
        goal_id,
        milestone_id,
        type,
        setPeriodStart,
        title: 'Add Data Point',
        modalContentClassName: advCalcEnabled ? 'modal-data-point-adv-calc' : 'modal-data-point-simple',
        controlHidden,
        isMobile: applicationSettingsSelectors.isMobileSelector(state),
    };
};

const formModal = createFormModal({
    actions: { ...actions, request: actionsDataPointList.createDataPoint },
    selector,
    formName: createFormName,
    enableReinitialize: true,
    ControlsComponent: DataPointModalFormControls,
    mapStateToProps: mapStateToProps(createFormName),
});

const formModalMobile = createFormModal({
    actions: { ...actionsOnMobile, request: actionsDataPointList.createDataPointOnMobile },
    selector: selectorOnMobile,
    formName: createFormNameOnMobile,
    enableReinitialize: true,
    ControlsComponent: DataPointModalFormControls,
    mapStateToProps: mapStateToProps(createFormNameOnMobile),
});

const withKpiDataPointCreatePeriodState = (WrappedComponent) => {
    return (props) => {
        // eslint-disable-next-line react/prop-types
        const { missingPeriods, startPeriods, frequency } = props;
        const [createFormPeriodStart, setCreateFormPeriodStart] = useState(
            () => formattedPeriodStartValue(missingPeriods, startPeriods, frequency)
        );
        useEffect(() => {
            setCreateFormPeriodStart(() => formattedPeriodStartValue(missingPeriods, startPeriods, frequency));
        }, [missingPeriods, startPeriods]);
        return (
            <WrappedComponent
                {...props}
                createFormPeriodStart={createFormPeriodStart}
                setPeriodStart={setCreateFormPeriodStart}
            />
        );
    };
};

export const MilestoneDataPointCreateModal = formModal(MilestoneDataPointCreate);
export const MilestoneDataPointCreateModalMobile = withKpiDataPointCreatePeriodState(formModalMobile(MilestoneDataPointCreate));
