import { createReducer } from 'redux-act';
import u from 'updeep';
import * as actions from './primary-entity-create-service-actions';

const initialState = {
    parentData: {},
    params: {},
    fetchingParentData: false,
};

const setFetchingStateStart = state => u({
    fetchingParentData: true,
}, state);

const setParentData = (state, payload) => {
    return u({
        fetchingParentData: false,
        parentData: payload.data,
        params: payload.params,
    }, state);
};

const resetParentData = () => initialState;

const reducerMap = {
    [actions.error]: resetParentData,
    [actions.success]: resetParentData,
    [actions.create.trigger]: setFetchingStateStart,
    [actions.setParentData]: setParentData,
};

export default createReducer(reducerMap, initialState);
