import { createSelector } from 'reselect';
import { stateBranchName } from './config';
import { getDateFormats } from '../application/localizations/selectors';
import config from '../../config';
import { roleTypes, globalAccountTypes } from './constants';

export function getUserBranch(state) {
    return state[stateBranchName];
}

function getUserDetails(state) {
    return state.data;
}

export function getUserId(data) {
    return data.user_id;
}

function getUserTagName(data) {
    return data.tag_name;
}

function getIsAccountOwner(data) {
    return data.is_account_owner;
}

function getRoles(data) {
    return data.roles || [];
}

function getAccountRole(roles) {
    return roles.find(item => item.type === roleTypes.global) || {};
}

function getAuthProvider(state) {
    return state.auth_provider;
}

function getIsAdmin(accountRole) {
    return accountRole.name === globalAccountTypes.globalAdmin;
}

export const selectUserDetails = createSelector(getUserBranch, getUserDetails);

export const selectUserId = createSelector(selectUserDetails, getUserId);

export const selectUserTagName = createSelector(selectUserDetails, getUserTagName);

const selectRoles = createSelector(selectUserDetails, getRoles);

export const selectAccountRole = createSelector(selectRoles, getAccountRole);

export const selectIsAdmin = createSelector(selectAccountRole, getIsAdmin);

export const selectIsAccountOwner = createSelector(selectUserDetails, getIsAccountOwner);

export const selectAuthProvider = createSelector(selectUserDetails, getAuthProvider);

export const selectUserDateFormat = createSelector(
    selectUserDetails, getDateFormats,
    (userData, localizationDateFormats) => {
        if (localizationDateFormats?.length > 0) {
            const userDateFormatObj = localizationDateFormats.find(date => date.code === userData.date_format);
            return userDateFormatObj?.format_js;
        }
        return config.shortDateFormat;
    }
);
