import * as constants from './constants';
import permissionModel from './permission-model';
import * as permissionSelectors from './selectors';
import createPermissionResolver from './permission-resolver';
import * as utils from './utils';

export default {
    constants,
    keys: {
        ...constants.entityPermissionNames,
        ...constants.systemPermissionNames,
    },
    model: permissionModel,
    selectors: permissionSelectors,
    createResolver: createPermissionResolver,
    utils,
};
