import { eventChannel } from 'redux-saga';
import actions from './actions';
import { visibilityStates } from '../config';

function getHiddenProp() {
    const prefixes = ['webkit', 'moz', 'ms', 'o'];

    if ('hidden' in document) return 'hidden';

    for (let i = 0; i < prefixes.length; i + 1) {
        if ((`${prefixes[i]}Hidden`) in document) {
            return `${prefixes[i]}Hidden`;
        }
    }

    return null;
}

function addListener(cb) {
    const visProp = getHiddenProp();
    if (visProp) {
        const evtname = `${visProp.replace(/[H|h]idden/, '')}visibilitychange`;
        document.addEventListener(evtname, cb);
    }
}

function removeListener(cb) {
    const visProp = getHiddenProp();
    if (visProp) {
        const evtname = `${visProp.replace(/[H|h]idden/, '')}visibilitychange`;
        document.removeEventListener(evtname, cb);
    }
}

export default function visibilityChannel() {
    return eventChannel((emitter) => {
        const handleVisiblityChange = () => emitter({
            type: actions.app.setVisibilityState.getType(),
            payload: {
                isVisible: document.visibilityState === visibilityStates.visible,
            },
        });

        addListener(handleVisiblityChange);

        return () => {
            removeListener(handleVisiblityChange);
        };
    });
}
