import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import classnames from 'classnames';
import Modal from '../modal/component';
import Button from '../button/component';
import LoadingIndicator from '../loading-indicator/component';
import './styles.css';

const promptTypes = Object.freeze({
    confirmation: 'confirmation',
    dialog: 'dialog',
});

export default function Prompt({
    className,
    visible,
    hide,
    description,
    onAccept,
    onCancel,
    buttonText,
    isDescription,
    title,
    isTitle,
    isConfirmation,
    fetching,
    isAccepting,
    hideOnAccept,
    type,
}) {
    const handleHide = useCallback(() => {
        onCancel();
        hide();
    }, [hide, onCancel]);

    const handleAccept = useCallback(() => {
        onAccept();
        if (hideOnAccept || isConfirmation) {
            hide();
        }
    }, [hide, onAccept]);

    return (
        <Modal
            visible={visible}
            hide={handleHide}
            className={classnames('modal-prompt', {
                [className]: className
            })}
        >
            <div className="modal-prompt-description">
                {isDescription && (<><span className="modal-prompt-description-text">{description}</span><br /></>)}
                {isTitle && (<h3 className="modal-prompt-title">{title}</h3>)}
            </div>

            {fetching && (
                <div className="loading-overlay">
                    <LoadingIndicator
                        size="small"
                        centered
                    />
                </div>
            )}

            <div className="modal-prompt-controls">
                {type === promptTypes.dialog && (
                    <>
                        <Button
                            className="modal-prompt-button"
                            outline
                            onClick={handleHide}
                        >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            className="modal-prompt-button"
                            styleType="success"
                            loading={isAccepting}
                            onClick={handleAccept}
                        >
                            {buttonText}
                        </Button>
                    </>
                )}

                {type === promptTypes.confirmation && (
                    <Button
                        className="modal-prompt-button"
                        styleType="success"
                        onClick={handleAccept}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
        </Modal>
    );
}

Prompt.types = promptTypes;

Prompt.defaultProps = {
    className: undefined,
    description: undefined,
    buttonText: 'Confirm',
    onCancel() { },
    onAccept() { },
    hide: () => { },
    isDescription: true,
    title: 'Are you sure?',
    isTitle: true,
    isConfirmation: false,
    fetching: false,
    isAccepting: false,
    hideOnAccept: true,
    type: promptTypes.dialog,
};

Prompt.propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    hide: PropTypes.func,
    description: PropTypes.node,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    buttonText: PropTypes.string,
    isDescription: PropTypes.bool,
    title: PropTypes.string,
    isTitle: PropTypes.bool,
    isConfirmation: PropTypes.bool,
    fetching: PropTypes.bool,
    isAccepting: PropTypes.bool,
    hideOnAccept: PropTypes.bool,
    type: PropTypes.string,
};
