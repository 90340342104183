import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './styles.css';
import './fontawesome/css/all.min.css';

const types = {
    solid: 'solid',
    regular: 'regular',
    light: 'light',
};

const prefixes = {
    [types.solid]: 'fas',
    [types.regular]: 'far',
    [types.light]: 'fal',
};

export default function Icon({
    name, type, className, stack, fixedWidth, ...rest
}) {
    return (
        <span
            className={classnames('icon', {
                [`${prefixes[type]} fa-${name}`]: name,
                [className]: className,
                'fa-fw': fixedWidth,
                [`fa-stack-${stack}x`]: !!stack,
            })}
            {...rest}
        />

    );
}

Icon.defaultProps = {
    className: undefined,
    type: types.regular,
    stack: undefined,
    fixedWidth: false,
};

Icon.types = types;

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.oneOf([
        types.solid,
        types.regular,
        types.light,
    ]),
    stack: PropTypes.number,
    fixedWidth: PropTypes.bool,
};
