import moment from 'moment';
import {
    omit, sortBy, curryRight, flow, pickBy, get, cloneDeep, orderBy, concat, find, reject
} from 'lodash';
import { typeCodes } from '../../../../shared/utils/entity-type';
import appConfig from '../../../../config';
import {
    typeOptions, extendedTypeOptions, quickFilterValues, filterOptionValues
} from '../../constants';
import { iterator } from '../../table-grid-timeline-utils';
import { getRangeDiffUnit } from '../utils';
import { DateRangeUnit } from './timeline-model-types';

const rangeExtendParamsByUnit = {
    [DateRangeUnit.week]: [4, 'weeks'],
    [DateRangeUnit.month]: [16, 'weeks'],
    [DateRangeUnit.quarterly]: [48, 'weeks'],
};

const sortByTitle = curryRight(sortBy, 2)(['title']);

/**
 * Takes the original date range and returns an extended range based
 * on range unit
 * @function extendRange
 * @memberof WorkCenterTimeline.Utils
 * @param {WorkCenterTimeline.DateRangeUnit} unit - Source unit
 * @param {WorkCenterTimeline.DateRange} range - Source range
 * @returns {WorkCenterTimeline.DateRange} Extended range
 */
export const extendRange = (unit, range) => {
    const extendParams = rangeExtendParamsByUnit[unit];

    return {
        from: moment(range.from)
            .subtract(...extendParams)
            .startOf('week')
            .startOf('day'),
        to: moment(range.to)
            .add(...extendParams)
            .endOf('week')
            .endOf('day'),
    };
};

/**
 * Takes start and end dates and converts them into DateRange format
 * @function toRangeFormat
 * @memberof WorkCenterTimeline.Utils
 * @param {Date} from - Start Date
 * @param {Date} to - Target Date
 * @returns {WorkCenterTimeline.DateRange} Range
 */
const toDateRangeFormat = (from, to) => {
    return { from, to };
};

/**
 * Checks if `range1` will fit within `range2`
 * @function isRangeWithin
 * @memberof WorkCenterTimeline.Utils
 * @param {WorkCenterTimeline.DateRange} range1 - Original range
 * @param {WorkCenterTimeline.DateRange} range2 - Extended range
 * @returns {WorkCenterTimeline.DateRange} Range
 */
export const isRangeWithin = (range1, range2) => {
    return moment(range1.from).isSameOrAfter(range2.from) && moment(range1.to).isSameOrBefore(range2.to);
};

/**
 * @function getDateRangeFromParams
 * @memberof WorkCenterTimeline.Utils
 * @param {WorkCenterTimeline.TimelineModelFetchParams} params
 * @returns {WorkCenterTimeline.DateRange} Range
 */
export const getDateRangeFromParams = params => toDateRangeFormat(params.data.startDate, params.data.endDate);

/**
 * Part of param preparation to test on equality to determine if cache is valid
 * @function omitRangeFromParams
 * @memberof WorkCenterTimeline.Utils
 * @param {WorkCenterTimeline.TimelineModelFetchParams} params
 * @returns {Object} Params without range
 */
const omitRangeFromParams = params => omit(params.data, ['startDate', 'endDate']);

const pickByDefined = curryRight(pickBy)(el => el !== undefined);

const transformFilterValue = (params) => {
    const nextParams = cloneDeep(params);
    const customFilterId = get(nextParams, ['data', 'customFilter', 'id']);

    if (customFilterId) {
        nextParams.data.customFilterId = get(nextParams, ['data', 'customFilter', 'id']);
        nextParams.data.quickFilter = quickFilterValues.custom;
        delete nextParams.data.customFilter;
    }

    return nextParams;
};

/**
 * Prepare params to test on equality to determine if cache is valid
 * @function prepareStaticParamsForComparison
 * @memberof WorkCenterTimeline.Utils
 * @param {WorkCenterTimeline.TimelineModelFetchParams} params
 * @returns {Object} Params without range
 */
export const prepareStaticParamsForComparison = flow(transformFilterValue, omitRangeFromParams, pickByDefined);

export const orderChildren = (children, attribute, direction) => orderBy(
    children,
    [el => iterator(el, attribute)],
    direction
);

const mapParent = ({
    parent, parentId = 'id', sortedChildren, direction, attribute
}) => {
    const children = sortedChildren.reduce((accum, child) => {
        if (child.parent.id === parent[parentId] && parent.typeCode === child.parent.typeCode) {
            accum.push(child);
            return accum;
        }
        return accum;
    }, []);

    const orderedСhildrens = orderChildren(children, attribute, direction);

    return {
        ...parent,
        children: orderedСhildrens,
    };
};

const groupGlobalChildren = (globalChildren) => {
    const initialValue = {
        csf: [],
        kpi: [],
        other: [],
    };

    return globalChildren.reduce((accum, item) => {
        switch (item.typeCode) {
            case typeCodes.driver:
                accum.csf.push(item);
                break;
            case typeCodes.kpi:
                accum.kpi.push(item);
                break;
            default:
                accum.other.push(item);
                break;
        }

        return accum;
    }, initialValue);
};

const prepareParents = (parents, children, parent = {}) => {
    const getUniqueId = el => `${el.id}-${el.typeCode}`;

    const childrenAsParentMap = parents.reduce((accum, cur) => {
        const childRecord = find(children, { id: cur.id, typeCode: cur.typeCode });

        if (childRecord) {
            accum.push(getUniqueId(childRecord));
        }

        return accum;
    }, []);

    const filterChildrenAsParent = (chldrn) => {
        return reject(chldrn, (child) => {
            return childrenAsParentMap.includes(getUniqueId(child));
        });
    };

    return parents.reduce((accum, cur) => {
        if (!parent.id && cur.parentEntityType === typeCodes.plan) {
            accum.push({
                ...cur,
                children: prepareParents(parents, children, {
                    id: cur.id,
                    typeCode: cur.typeCode,
                }),
            });
        }

        if (cur.parentEntityId === parent.id && cur.parentEntityType === parent.typeCode) {
            const hasNestedChildren = parents.find(el => el.parentEntityId === cur.id && el.parentEntityType === cur.typeCode);
            const childRecord = children.find(el => cur.id === el.id && cur.typeCode === el.typeCode);
            const filteredChildren = filterChildrenAsParent(cur.children);

            accum.push({
                ...cur,
                ...(childRecord || {}),
                children: !cur.children.length || hasNestedChildren
                    ? [
                        ...filteredChildren,
                        ...prepareParents(parents, children, {
                            id: cur.id,
                            typeCode: cur.typeCode,
                        }),
                    ]
                    : filteredChildren,
            });
        }

        return accum;
    }, []);
};

export const composePlanTree = (globalParents, globalChildren, parents, children, sortByAttribute) => {
    const [attribute, direction] = sortByAttribute.split(',');

    const sortedChildren = sortBy(children, [child => child.title.toLowerCase()]);
    const composedParents = parents.map(parent => mapParent({
        parent, parents, parentId: 'id', sortedChildren, direction, attribute
    }));
    const preparedParents = prepareParents(composedParents, children);
    const groupedGlobalChildren = groupGlobalChildren(globalChildren);
    const composedGlobals = [];

    globalParents.forEach((parent) => {
        if (parent.globalParentTypeCode === typeCodes.kpi && groupedGlobalChildren.kpi.length) {
            composedGlobals.push(mapParent({
                parent,
                parentId: 'goalId',
                sortedChildren: orderChildren(groupedGlobalChildren.kpi, attribute, direction),
                direction,
                attribute
            }));
        }

        if (parent.globalParentTypeCode === typeCodes.driver && groupedGlobalChildren.csf.length) {
            composedGlobals.push(mapParent({
                parent,
                parentId: 'goalId',
                sortedChildren: orderChildren(groupedGlobalChildren.csf, attribute, direction),
                direction,
                attribute
            }));
        }
    });

    return concat(
        orderChildren(groupedGlobalChildren.other, attribute, direction),
        sortByTitle(concat(composedGlobals, preparedParents))
    );
};

export const prepareEntityTypeFilter = (entityTypeFilter, typeFilter) => {
    const options = typeFilter === filterOptionValues.draft
        ? extendedTypeOptions
        : typeOptions;

    return (entityTypeFilter?.length === 0
        ? options.map(option => option.value)
        : entityTypeFilter);
};

export const getHistoricalHealthParams = (data, params) => {
    const dateRange = getDateRangeFromParams(params);
    const extendedDateRange = extendRange(params.meta.dateRangeUnit, dateRange);
    return {
        start_date: extendedDateRange.from.format(appConfig.apiDateFormat),
        end_date: extendedDateRange.to.format(appConfig.apiDateFormat),
        entities: data.items.reduce((accum, item) => {
            if (item.type_code !== typeCodes.kpi) {
                accum.push({
                    id: item.id,
                    type_code: item.type_code,
                });
            }

            return accum;
        }, []),
        period_type: getRangeDiffUnit(params.meta.dateRangeUnit),
    };
};
