import { flow } from 'lodash';
import createFormModal from '../../../../shared/modules/create-view/component';
import parseValidationMessage from '../../../../shared/modules/input-attachments/parse-validation';
import { getCurrentGoal } from '../../../../modules/goalmap-list/reducer';
import { withAttachmentFilePickerState } from '../../../secondary-entities/attachment';
import { subscriptionFields, subscriptionLimitSelectors } from '../../../subscription';
import { progressNoteCreateModel } from '../progress-note-models';
import ProgressNoteForm from '../form/progress-note-form';
import ProgressNotesCreateFormControls from './progress-note-create-modal-controls';

const { actions, selector } = progressNoteCreateModel;

const formName = 'progress-note-create';

const formModal = createFormModal({
    actions,
    selector,
    formName,
    parseFormSubmitError: parseValidationMessage,
    updateHtml: true,
    ControlsComponent: ProgressNotesCreateFormControls,
    enableReinitialize: false,
    mapStateToProps(state, ownProps) {
        const { parentId, parentEntityType } = ownProps;
        const draftLimits = subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.draft);

        return {
            title: 'Progress Note',
            goal_id: getCurrentGoal(state).id || ownProps.goal_id,
            draftAvailable: false,
            initialValues: {
                content: '',
                attachments: [],
                parentId,
                parentEntityType,
                state: 1
            },
            controlProps: {
                submitDisabled: ownProps.filePicker.visible,
                canCreateDraft: draftLimits.value,
            },
            onHide: ownProps.filePicker.hide,
        };
    }
});

export default flow(
    formModal,
    withAttachmentFilePickerState,
)(ProgressNoteForm);
