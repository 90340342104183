import u from 'updeep';
import { createReducer } from 'redux-act';
import isEmpty from 'lodash/isEmpty';
import * as actions from './application-settings-actions';
import { safeClone } from '../../../shared/utils/object';
import { applicationInitialState, applicationBranchName } from './application-settings-constants';

function handleSetAccess(state, { accessRights }) {
    return u(
        { allowAccess: accessRights },
        state
    );
}

function saveRedirectPath(state, path) {
    return u(
        { redirectPath: path },
        state,
    );
}

function resetRedirectPath(state) {
    return u(
        { redirectPath: undefined },
        state,
    );
}

function handleSetBreakpoint(state, { breakpoint }) {
    return u(
        { currentBreakpoint: breakpoint },
        state
    );
}

function handleSaveListViewType(state, { type }) {
    return u(
        { listViewType: type },
        state
    );
}

function handleSaveSortType(state, payload) {
    const payloadCl = safeClone(payload);

    Object.keys(payloadCl).forEach((key) => {
        payloadCl[key] = payloadCl[key]
            || state.sortTypes[key]
            || applicationInitialState.sortTypes[key];
    });

    return u(
        {
            sortTypes: u(
                { ...payloadCl },
                state.sortTypes
            )
        },
        state
    );
}

function handleSetCurrentPlanPageType(state, payload) {
    return u(
        {
            planPageType: payload
        },
        state
    );
}

function handleSavePlanTreeNavSortType(state, payload) {
    if (isEmpty(payload)) return state;
    return { ...state, planTreeNavSortType: { ...payload } };
}

const applicationSettingsReducer = createReducer({
    [actions.persistListSortType]: handleSaveSortType,
    [actions.persistListViewType]: handleSaveListViewType,
    [actions.setAccess]: handleSetAccess,
    [actions.setBreakpoint]: handleSetBreakpoint,
    [actions.saveRedirectPath]: saveRedirectPath,
    [actions.resetRedirectPath]: resetRedirectPath,
    [actions.setPlanPageType]: handleSetCurrentPlanPageType,
    [actions.persistPlanTreeNavSortType]: handleSavePlanTreeNavSortType,
}, applicationInitialState);

applicationSettingsReducer.branchName = applicationBranchName;

export default applicationSettingsReducer;
