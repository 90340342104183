import { viewTypes } from '../../../shared/components/list/list-view-toggle-constants';
import { paths } from '../../../routes/rules';

export const applicationDataBranchName = 'application-data';
export const applicationBranchName = 'application';
export const applicationInitialState = {
    listViewType: viewTypes.LIST,
    sortTypes: {
        entities: 'target_date,asc',
        users: 'first_name,asc',
        kpi: 'is_need_updated,asc',
    },
    redirectPath: undefined,
    allowAccess: {
        mobile: false,
        desktop: false
    },
    planPageType: paths.planOverview,
    currentBreakpoint: undefined,
    planTreeNavSortType: {
        show: 0,
        sortBy: 'title,asc',
    },
};
