import React, { Component } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ReactHighChart from 'react-highcharts';
import HighchartsExporting from 'highcharts-exporting';

import './styles.css';
import '../../styles/chart-tooltip.css';

HighchartsExporting(ReactHighChart.Highcharts);

export default class ChartBase extends Component {
    constructor(args) {
        super(args);

        this.state = {
            config: this.transformConfig(),
        };

        this.chart = null;
    }

    shouldComponentUpdate(nextProps) {
        if (!_.isEqual(nextProps.config, this.props.config)) {
            return true;
        }

        return false;
    }

    setSize(newSize) {
        const chart = this.chart.getChart();
        chart.setSize(
            _.get(newSize, 'width'),
            _.get(newSize, 'height', '320'),
            _.get(newSize, 'animation', { defer: 0, duration: 150, easing: 'cubic-bezier(0,0,.2,1)' })
        );
    }


    update = (updates) => {
        const chart = this.chart.getChart();
        chart.update(updates, true, true, true);
    };

    handleItemClick = (event) => {
        const { onItemClick } = this.props;

        if (event && event.point) {
            onItemClick(event.point.node);
        }
    };

    // adding react-like API for event listeners
    transformConfig = () => {
        const { config, type } = this.props;

        return {
            ...config,
            chart: {
                backgroundColor: 'transparent',
                ...config.chart,
                type
            },
        };
    };

    render() {
        const { className } = this.props;
        const { config } = this.state;

        return (
            <div className={cx('base-chart', className)}>
                <ReactHighChart
                    ref={(el) => { this.chart = el; }}
                    config={config}
                />
            </div>
        );
    }
}

ChartBase.defaultProps = {
    config: {},
    data: [],
    onItemClick() {},
    className: undefined,
    type: 'spline'
};

ChartBase.propTypes = {
    config: PropTypes.object, // eslint-disable-line
    data: PropTypes.array, // eslint-disable-line
    onItemClick: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string
};
