import { put, takeEvery, select } from 'redux-saga/effects';
import { list, details } from '../entity';
import { states } from '../../goalmap-list/config';

function* updateList({ payload }) {
    if (payload.state === states.archived) {
        const { params } = yield select(list.selector);

        yield put({
            type: list.actions.request.getType(),
            payload: params
        });
    }
}

export default function* saga() {
    yield takeEvery(details.actions.updateStatus.success.getType(), updateList);
}
