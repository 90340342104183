import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createListOfItems } from '../../../shared/modules';
import {
    Text, GridContainer, Breadcrumbs, ModuleTitle,
} from '../../../shared/components';
import { legacyFormatSelectorFactory, mapActionsToLegacyFormat } from '../../../shared/entities-v2/collection/legacy';
import { FilesInfoTable } from '../user-list/files-info-table';
import { fields, initialValues } from './form-data';
import SearchForm, { filterOptions, initialValues as searchInitialValues, formSelector } from './search-form';
import dict from './dictionary';
import { settingsIntegrationsStorageCollection } from './entity';

import './styles.css';

const StorageDetailsList = createListOfItems({
    actions: mapActionsToLegacyFormat(settingsIntegrationsStorageCollection.actions),
    selector: legacyFormatSelectorFactory(settingsIntegrationsStorageCollection.selectors),
})();

class StorageDetailsContainer extends Component {
    state = {
        [fields.isActive]: initialValues[fields.isActive],
    };

    handleSearchChange = (data) => {
        this.setState(data);
    }

    render() {
        const {
            totalConnections, totalFiles, currentValues, slug, title, breadcrumbs, ...rest
        } = this.props;

        return (
            <div className="settings-integration-details">
                <GridContainer className="settings-integration-details-header-group">
                    <div className="settings-integration-details-breadcrumbs-group">
                        <ModuleTitle className="settings-integration-details-module-title">
                            {title}
                        </ModuleTitle>
                        <div className="settings-integration-breadcrumbs">
                            <Breadcrumbs items={breadcrumbs} />
                        </div>
                    </div>
                    <div className="settings-integration-info-group">
                        <h3 className="settings-integration-title">{dict.usageSummaryTitle}</h3>
                        <Text
                            className="settings-integration-title-count"
                            component="p"
                            styleType="muted"
                        >
                            {dict.totalAmount}: {totalConnections}
                        </Text>
                        <Text
                            className="settings-integration-title-count"
                            component="p"
                            styleType="muted"
                        >
                            {dict.totalLinked}: {totalFiles}
                        </Text>
                    </div>
                </GridContainer>

                <SearchForm
                    create={{
                        enabled: false,
                    }}
                    sort={{
                        enabled: false,
                    }}
                    filter={{
                        enabled: true,
                        name: fields.isActive,
                        options: filterOptions,
                    }}
                    title="Integration Status"
                    initialValues={searchInitialValues}
                    currentValues={currentValues}
                    onSubmit={this.handleSearchChange}
                    titleClassName="settings-integration-details-title"
                />

                <GridContainer>
                    <StorageDetailsList
                        {...rest}
                        params={{
                            slug,
                            [fields.isActive]: this.state[fields.isActive],
                        }}
                        customRenderFunc={FilesInfoTable}
                    />
                </GridContainer>
            </div>
        );
    }
}

StorageDetailsContainer.defaultProps = {
    totalConnections: 0,
    totalFiles: 0,
    breadcrumbs: []
};

StorageDetailsContainer.propTypes = {
    totalConnections: PropTypes.number,
    totalFiles: PropTypes.number,
    currentValues: PropTypes.shape({
        [fields.isActive]: PropTypes.string,
    }).isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.object,
};

const mapStateToProps = state => ({
    totalConnections: settingsIntegrationsStorageCollection.selectors.getRaw(state)?.total_connections,
    totalFiles: settingsIntegrationsStorageCollection.selectors.getRaw(state)?.total_files,
    currentValues: {
        [fields.isActive]: formSelector(state, fields.isActive),
    },
});

export default connect(mapStateToProps)(StorageDetailsContainer);
