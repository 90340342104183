/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { fork, all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { find, bindAll } from 'lodash';
import request from '../../../request';
import BaseResource from '../base/resource';
import createActions from './actions';
import createReducer from './reducer';
import createSelectors from './selectors';
import createSaga from './saga';
import { asyncTypes as requestTypes } from './config';

/**
 * The main purpose of Model is to keep your data stored and synced with RESTful API.
 * Design your models as the atomic reusable objects containing all of the helpful
 * functions for manipulating their particular bit of data. Models should be able to be
 * passed around throughout your app, and used anywhere that bit of data is needed.
 * @class
 * @augments BaseResource
 * @memberof RESTEntities
 *
 * @description To use instatiated model you need to attach `reducer` and `saga` to
 * application store.
 *
 * @example <caption>Model instatiation and attachment to Redux Store</caption>
 * const userModel = new Model({
 *   apiUrl: '/user',
 *   name: 'user'
 * });
 *
 * combineReducers({
 *   ...otherReducers,
 *   [userModel.name]: userModel.reducer,
 * });
 *
 * function* rootSaga() {
 *   yield [
 *      ...otherSagas,
 *      userModel.saga(),
 *   ];
 * }
 */
class Model extends BaseResource {
    /**
     * @lends Model#

     * @constructs
     *
     * @param {Object} options
     * @param {string} options.apiUrl - REST API Resource URL
     * @param {Function} options.getApiUrl - Function that computes and returns REST API
     * Resource URL
     * @param {Array} options.plugins - Collection of plugins to extend functionality
     * of the Model
     * @param {string} options.idKey - Define unique Resource identifier key
     */
    constructor(options) {
        super(options);

        /**
         * @type {string}
         * @description Represents unique Resource identifier attribute name
         * @default id
         */
        this.idKey = options.idKey || 'id';
        this.id = null;

        bindAll(this, [
            'parse',
            'format',
            'formatParams',
            'getApiUrl',
            'getApiUrlCreate',
            'fetch',
            'create',
            'update',
            'remove',
        ]);

        /**
         * @type {RESTEntities.ModelApi}
         * Object that contains all api requests
         */
        this.api = {
            ...this.sagaApi,
            additionalApi: { ...this.additionalArtifacts.api },
        };

        /**
         * @type {RESTEntities.ModelActions}
         * Object that contains all Action Creators
         */
        this.actions = createActions({
            name: this.name,
            additionalActions: this.additionalArtifacts.actions,
        });
        /**
         * @type {Function}
         * Root saga of Model
         */
        const saga = createSaga({
            actions: this.actions,
            api: this.api,
            additionalTriggers: this.additionalArtifacts.triggers,
        });

        /**
         * @type {Object}
         * Object with all available selectors
         */
        this.selectors = createSelectors({
            branchName: this.name,
        });

        const reducer = createReducer({
            actions: this.actions,
            additionalReducers: this.additionalArtifacts.reducers,
            additionalInitialState: this.additionalArtifacts.initialState,
        });

        this.initPlugins();

        this.saga = this.createRootSaga(saga);

        /**
         * @type {Function}
         * Root reducer of Model
         */
        this.reducer = combineReducers({
            model: reducer,
            plugins: this.pluginReducers,
        });
    }

    get sagaApi() {
        return {
            fetch: this.fetch,
            update: this.update,
            create: this.create,
            remove: this.remove,
        };
    }

    createRootSaga(saga) {
        const pluginSagas = this.plugins.reduce((accum, plugin) => {
            if (!plugin.saga) {
                return accum;
            }

            accum[plugin.name] = plugin.saga;
            return accum;
        }, {});

        return function* modelRootSaga() {
            yield fork(saga);
            const sagaKeys = Object.keys(pluginSagas);

            if (sagaKeys.length) {
                const sagas = {};

                for (let i = 0; i < sagaKeys.length; i += 1) {
                    const key = sagaKeys[i];
                    const currentSaga = pluginSagas[key];
                    sagas[key] = yield fork(currentSaga);
                }

                yield all(sagas);
            }
        };
    }

    get pluginReducers() {
        if (!this.plugins.length) {
            return null;
        }

        const reducers = this.plugins.reduce((accum, plugin) => {
            if (!plugin.reducer) {
                return accum;
            }

            accum[plugin.name] = plugin.reducer;
            return accum;
        }, {});

        if (!Object.keys(reducers).length) {
            return null;
        }

        return combineReducers(reducers);
    }

    initPlugins() {
        this.plugins.forEach((plugin) => plugin.initialize(this));
    }

    getPluginInstanse(name) {
        return find(this.plugins, { name });
    }

    getPluginPath(name) {
        if (this.getPluginInstanse(name)) {
            return `plugins.${name}`;
        }

        throw Error(`Plugin "${name}" not found on model ${this.name}`);
    }

    /**
     * Creates Resource API URL based on `params`/`data`. If `options.getApiUrl` is
     * specified then it will call this function with `params` as an argument and will
     * expect it to return a string. Will be used for [fetch]{@link Model#fetch},
     * [update]{@link Model#update} and [remove]{@link Model#remove}
     * @function getApiUrl
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} params - Object to send to server.
     * @returns {string} API URL string.
     */
    getApiUrl(params) {
        if ('getApiUrl' in this.options) {
            return this.options.getApiUrl(params);
        }

        return `${this.options.apiUrl}/${params[this.idKey]}`;
    }

    /**
     * Creates Resource API URL based on `params`/`data`. If `options.getApiUrl` is
     * specified then it will call this function with `params` as an argument and will
     * expect it to return a string. Will be used for [create]{@link Model#create}.
     * @function getApiUrlCreate
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} params - Object to send to server.
     * @returns {string} API URL string.
     */
    getApiUrlCreate(data) {
        if ('getApiUrl' in this.options) {
            return this.options.getApiUrl(data);
        }

        return this.options.apiUrl;
    }

    /**
     * Formats `params` object before sending to server via [fetch]{@link Model#fetch}
     * @function formatParams
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} params - Object to format.
     * @returns {Object} Formatted `params`
     */
    formatParams(params) {
        const result = { ...params };
        delete result[this.idKey];

        return result;
    }

    /**
     * Formats `data` object before sending to server via [update]{@link Model#update},
     * [create]{@link Model#create} or [remove]{@link Model#remove}
     * @function format
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} data - Object to format.
     * @param {String} type - type of operation
     * @returns {Object} Formatted `data`
     */
    format(data, type = requestTypes.update) {
        if (
            [
                requestTypes.update,
                requestTypes.create,
                requestTypes.remove,
            ].includes(type)
        ) {
            return { ...data };
        }
        return { ...data };
    }

    /**
     * Parses `data` object after retreiving it from REST API and before
     * setting it as attributes to Redux Store
     * @function parse
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} data - Object to parse.
     * @param {String} type - type of operation
     * @returns {Object} Parsed `data`
     */
    parse(data, type = requestTypes.fetch) {
        return data;
    }

    /**
     * Retreives REST API Resource and saves attributes into Redux Store
     * @async
     * @function fetch
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} params - Data to send to server. Will be
     * converted using `toDataURI` function
     * @param {Object} options - Request options
     * @returns {Promise<Object>} Server response
     *
     * @example <caption>To trigger fetching you have to dispatch an action.</caption>
     * store.dispatch(userModel.actions.fetch.request({ id: 1 }));
     */
    fetch(params, options) {
        const url = this.getApiUrl(params);
        const formattedParams = this.formatParams(params);
        this.id = params[this.idKey];

        if (formattedParams[this.idKey]) {
            delete formattedParams[this.idKey];
        }

        if (typeof formattedParams.options === 'object') {
            delete formattedParams.options;
        }

        return request
            .get(url, { params: formattedParams, ...options })
            .then((response) => this.parse(response, requestTypes.fetch))
            .catch(this.parseError);
    }

    /**
     * Creates REST API Resource and stores it into Redux Store
     * @async
     * @function create
     * @memberof Model
     * @instance
     * @param {Object} data - Data to send to server.
     * @param {Object} options - Request options.
     * @returns {Promise<Object>} Server response
     *
     * @example <caption>To trigger create you have to dispatch an action.</caption>
     * const data = {
     *   first_name: 'John',
     *   last_name: 'Doe',
     *   email: 'johndoe@email.com',
     * };
     *
     * store.dispatch(userModel.actions.create.request(data));
     */
    create(data, options) {
        const url = this.getApiUrlCreate(data);
        const formattedData = this.format(data, requestTypes.create);

        return request
            .post(url, formattedData, options)
            .then((response) => this.parse(response, requestTypes.create))
            .catch(this.parseError);
    }

    /**
     * Updates REST API Resource and merges response with existing attributes in Redux Store.
     * @async
     * @function update
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} data - Data to send to server.
     * @param {Object} options - Request options.
     * @returns {Promise<Object>} Server response
     *
     * @example <caption>To trigger update you have to dispatch an action.</caption>
     * const data = {
     *   first_name: 'John',
     *   last_name: 'Doe',
     *   email: 'johndoe2@email.com',
     * };
     *
     * store.dispatch(userModel.actions.update.request(data));
     */
    update(data, options) {
        const formattedData = this.format(data, requestTypes.update);
        const url = this.getApiUrl(data);

        if (formattedData.id) {
            delete formattedData.id;
        }

        return request
            .put(url, formattedData, options)
            .then((response) => this.parse(response, requestTypes.update))
            .catch(this.parseError);
    }

    /**
     * Deletes REST API Resource and removes attributes from Redux Store
     * @async
     * @function remove
     * @memberof RESTEntities.Model
     * @instance
     * @param {Object} data - Data to send to server.
     * @param {Object} options - Request options
     * @returns {Promise<Object>} Server response
     *
     * @example <caption>To trigger deletion you have to dispatch an action.</caption>
     * store.dispatch(userModel.actions.remove.request({ id: 1 }));
     */
    remove(data, options) {
        const formattedData = this.format(data, requestTypes.remove);
        const url = this.getApiUrl(data);

        return request
            .delete(url, { ...formattedData, ...options })
            .catch(this.parseError);
    }
}
Model.events = {
    onCreateSuccess: 'onCreateSuccess',
    onUpdateSuccess: 'onUpdateSuccess',
    onRemoveSuccess: 'onRemoveSuccess',
};

Model.additionalArtifacts = {
    actions: 'actions',
    reducers: 'reducers',
    triggers: 'triggers',
    initialState: 'initialState',
    api: 'api',
};

Model.requestTypes = requestTypes;

export default Model;
