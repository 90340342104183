import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Select } from '../../../shared/modules';
import getRoles from './api';

export default class TeamRoleSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            fullOptions: [],
            filteredOptions: [],
        };
    }

    componentDidMount() {
        const { type, extraOptions } = this.props;
        getRoles(type, extraOptions)
            .then((data) => {
                this.setState({
                    fullOptions: data.options,
                    options: data.options,
                    filteredOptions: data.options.filter(item => item.id === 8),
                });
            });
    }

    componentWillReceiveProps(nextProps) {
        const { accountRole } = nextProps;

        if (accountRole) {
            if (accountRole.id === 3) {
                const { filteredOptions } = this.state;
                this.setState({
                    options: filteredOptions
                });
            } else {
                const { fullOptions } = this.state;
                this.setState({
                    options: fullOptions
                });
            }
        }
    }

    render() {
        const { options } = this.state;
        const { type, extraOptions, customOptions } = this.props;
        return (
            <div>
                <Select
                    options={options}
                    loadOptions={() => getRoles(type, extraOptions, customOptions)}
                    labelKey="title"
                    searchable
                    {...this.props}
                />
            </div>
        );
    }
}

TeamRoleSelect.defaultProps = {
    type: 2,
    extraOptions: undefined,
    accountRole: {},
    customOptions: {},
};

TeamRoleSelect.propTypes = {
    type: PropTypes.number,
    accountRole: PropTypes.object,
    customOptions: PropTypes.object,
    extraOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            display_name: PropTypes.string,
        }),
    ),
};
