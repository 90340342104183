import { createSelector } from 'reselect';
import { get } from 'lodash';
import { selectUserId } from '../user/selectors';
import permissionModel from './permission-model';
import { globalSections, systemPermissionNames } from './constants';
import createPermissionResolver from './permission-resolver';

export const baseSelector = permissionModel.selectors.getAttributes;

const getPropsId = (_, id) => id;
const getPropsType = (_, id, type) => type;
const getPropsEntity = (_, id, type, data) => data;

export const selectStrategicPlanPermissions = createSelector(
    [baseSelector, getPropsId],
    (permissions, id) => get(permissions, [globalSections.strategicPlans, id]),
);

export const selectEntityPermissions = createSelector(
    [selectStrategicPlanPermissions, getPropsType],
    (permissions, type) => get(permissions, type, {}),
);

export const selectResolvedEntityPermissions = createSelector(
    [
        selectStrategicPlanPermissions,
        selectUserId,
        getPropsType,
        getPropsEntity,
    ],
    (permissions, userId, type, entity) => {
        const resolver = createPermissionResolver(permissions, userId);
        return resolver(type, entity);
    }
);

export function createPermissionSelector(type) {
    return createSelector(
        [baseSelector, getPropsId],
        (permissions, id) => get(permissions, [globalSections.strategicPlans, id, type], {})
    );
}

export const selectSystemPermissions = createSelector(
    baseSelector,
    permissions => get(permissions, globalSections.system),
);

export const selectPlanCreatePermission = createSelector(
    selectSystemPermissions,
    permissions => get(permissions, systemPermissionNames.canGoalCreate, null),
);
