import u from 'updeep';
import { pick } from 'lodash';
import request from '../../../request';
import { Model } from '../../../shared/entities-v2';
import { ModalPlugin, ModelNotificationsPlugin, ModelCollectionSyncPlugin } from '../../../shared/entities-v2/plugins';
import StrategicPlanCollection from '../goal-list/entity';
import { attributes } from '../config';

const constants = Object.freeze({
    branchName: 'integration-communication-model',
    modalName: 'integration-communication-modal',
    notificationPluginName: 'integration-communication-notifications',
    collectionSyncPluginName: 'integration-communication-collection-sync',
});

function getApiUrl(data) {
    return `integrations/${data.goal_id || data.id}/${data.slug}`;
}

function formatData(data) {
    return pick(data, [
        attributes.is_active,
        attributes.sensitivity,
        attributes.url,
    ]);
}

class CommunicationModel extends Model {
    update(data, options) {
        const formattedData = this.format(data);
        const url = `integrations/${data.goal_id}/${data.slug}`;

        return request
            .put(url, formattedData, options)
            .then(this.parse)
            .catch(this.parseError);
    }

    format = formatData
}

const communicationModel = new CommunicationModel({
    name: constants.branchName,
    getApiUrl,
    plugins: [
        new ModalPlugin(constants.modalName),
        new ModelNotificationsPlugin({
            name: constants.notificationPluginName,
            dictionary: u.updateIn(
                ['success', 'update'],
                'The integration has been successfully configured',
                ModelNotificationsPlugin.dictionary
            ),
        }),
        new ModelCollectionSyncPlugin({
            name: constants.collectionSyncPluginName,
            collection: StrategicPlanCollection,
            optimisticUpdates: true,
        }),
    ],
});

export default communicationModel;
