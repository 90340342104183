import { Model } from '../../shared/entities-v2';
import { asyncTypes as requestTypes } from '../../shared/entities-v2/model/config';
import request from '../../request';

const apiUrl = '/work_center/custom_filters/default_filter';
const updateApiUrl = '/work_center/custom_filters/default_filter/update';
const modelName = 'work-center-default-filter-model';

class WorkCenterDefaultFitlerModel extends Model {
    update(data, options) {
        return request
            .put(`${updateApiUrl}/${data.id}`, options)
            .then(response => this.parse(response, requestTypes.update))
            .catch(this.parseError);
    }
}

const workCenterDefaultFitlerModel = new WorkCenterDefaultFitlerModel({
    getApiUrl: () => apiUrl,
    name: modelName,
});

export default workCenterDefaultFitlerModel;
