import { takeEvery, put } from 'redux-saga/effects';
import get from 'lodash/get';
import Notification from '../../../../sc-packages/notifications';
import { plansListMy } from './entity';

function* getNewMyPlansListIsMemberNewPlan({ payload }) {
    if (get(payload, 'data.author.id', 'id') !== get(payload, 'data.user.id', 'id')) {
        yield put(plansListMy.actions.fetch.request({}, { silent: true }));
    }
}

export default function* saga() {
    yield takeEvery(
        Notification.actions.transport.receive,
        getNewMyPlansListIsMemberNewPlan
    );
}
