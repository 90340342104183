import PropTypes from 'prop-types';
import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { selectCurrentLocation } from '../../../shared/selectors/routing';
import { Icon, Clickable, DropdownFixed } from '../../../shared/components';
import { noop } from '../../../shared/utils/common';
import { isArchived, isDraftEntity } from '../../../shared/utils/entity';
import Permissions from '../../permissions';
import { AppContext } from '../../app-context/component';
import { getNavigatorContext } from '../../plan-navigator/reducer';
import { itemPropTypes } from '../proptypes';
import NavigationDrawerCounter from './navigation-drawer-counter';

import '../styles/item.css';

const isNeedOpenProgressNote = (isMountedRightDrawerLists, location) =>
    (isMountedRightDrawerLists === false && location.search.includes('progress_note_id'));

function NavigationDrawerItem({
    id, active, icon, title, onClick, controlHeightPx, contentHeightPx, listComponent, isAutoCreation,
    createComponent, parentId, parentEntityType, parentEntityState, permissions, userId, counter, updateDetails,
    relatedLinkProps, created_at, author, rightDrawerVisible, fetchParent, parentEntityTypeCode, goalId,
    isDrawerOpen, isProgressNoteItem
}) {
    const [isMountedRightDrawerLists, setIsMountedRightDrawerLists] = useState(false);
    const { setDeleteProgressNoteOnSuccessCallback, setUpdateProgressNoteOnSuccessCallback, } = useContext(AppContext);
    const location = useSelector(selectCurrentLocation);
    const navigationContext = useSelector(getNavigatorContext);

    const isContextEntityDraft = isDraftEntity(navigationContext);
    const isContextEntityArchived = isArchived(navigationContext);
    const shouldDisableCreatePNButton = isProgressNoteItem && (isContextEntityDraft || isContextEntityArchived);

    const hasCreatePermissions = useMemo(
        () => createComponent && get(permissions, Permissions.keys.canCreate),
        [createComponent, permissions],
    );

    useEffect(() => {
        if (isMountedRightDrawerLists === false && rightDrawerVisible === true) setIsMountedRightDrawerLists(true);
    }, [rightDrawerVisible]);

    useEffect(() => {
        if (isNeedOpenProgressNote(isMountedRightDrawerLists, location)) setIsMountedRightDrawerLists(true);
    }, [location.search]);

    useEffect(() => {
        setDeleteProgressNoteOnSuccessCallback(() => fetchParent(true));
        setUpdateProgressNoteOnSuccessCallback(() => fetchParent(true));
        return () => {
            setDeleteProgressNoteOnSuccessCallback(noop);
            setUpdateProgressNoteOnSuccessCallback(noop);
        };
    }, []);

    const onCreateSuccess = () => {
        fetchParent(true);
    };

    const onDeleteSuccess = () => {
        fetchParent(true);
    };

    const handleSuccessSubmit = () => {
        fetchParent(true);
    };

    const ListComponent = listComponent;
    const CreateFormComponent = createComponent;

    const renderButton = () => (
        <Clickable
            className="control"
            action="nav-right-set-active-item"
            onClick={() => onClick(id)}
            style={{
                height: `${controlHeightPx}px`
            }}
        >
            <div className="icon-counter-wrap">
                <Icon
                    className="nav-right-item-icon"
                    name={icon}
                    type={Icon.types.regular}
                />
                <NavigationDrawerCounter counter={counter} />
            </div>
            <div className="nav-right-item-title">
                {title}
            </div>
            {
                (hasCreatePermissions && !shouldDisableCreatePNButton)
                    ? (
                        <CreateFormComponent
                            className="control-add-button"
                            parentId={parentId}
                            parentEntityType={parentEntityType}
                            goalId={goalId}
                            updateDetails={updateDetails}
                            {...relatedLinkProps}
                            onCreateSuccess={onCreateSuccess}
                        />
                    )
                    : null
            }
        </Clickable>
    );

    return (
        <div className={classnames('nav-right-item', { active })}>
            {isDrawerOpen
                ? renderButton()
                : (
                    <DropdownFixed
                        triggerEvent={DropdownFixed.events.mouseenter}
                        position={DropdownFixed.position.left}
                        trigger={renderButton()}
                        theme={DropdownFixed.themes.dark}
                        content
                    >
                        {title}
                    </DropdownFixed>
                )
            }


            <div
                className="content"
                style={{
                    height: active ? `${contentHeightPx}px` : 0
                }}
            >
                {isProgressNoteItem && isContextEntityDraft && (
                    <p className="nav-right-item-note-text">Please publish this draft item to add Progress Notes.</p>
                )}
                {(listComponent && isMountedRightDrawerLists)
                    ? (
                        <ListComponent
                            created_at={created_at}
                            author={author}
                            permissions={permissions}
                            parentId={parentId}
                            parentEntityType={parentEntityType}
                            parentEntityTypeCode={parentEntityTypeCode}
                            parentEntityState={parentEntityState}
                            userId={userId}
                            updateDetails={updateDetails}
                            isAutoCreation={isAutoCreation}
                            {...relatedLinkProps}
                            onDeleteSuccess={onDeleteSuccess}
                            handleSuccessSubmit={handleSuccessSubmit}
                        />
                    )
                    : null
                }
            </div>
        </div>
    );
}

NavigationDrawerItem.defaultProps = {
    counter: 0,
    updateDetails() { },
    created_at: undefined,
    author: undefined,
    isAutoCreation: undefined,
};

NavigationDrawerItem.propTypes = {
    ...itemPropTypes,
    controlHeightPx: PropTypes.number.isRequired,
    contentHeightPx: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    parentEntityType: PropTypes.string.isRequired,
    parentEntityTypeCode: PropTypes.number.isRequired,
    parentId: PropTypes.number.isRequired,
    updateDetails: PropTypes.func,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
    }).isRequired,
    counter: PropTypes.number,
    created_at: PropTypes.string,
    author: PropTypes.string,
    fetchParent: PropTypes.func.isRequired,
    isAutoCreation: PropTypes.bool,
    isDrawerOpen: PropTypes.bool.isRequired,
    isProgressNoteItem: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        permissions: (ownProps.permissionSelector && ownProps.permissionSelector(state, ownProps.goalId)) || {},
    };
}

export default connect(mapStateToProps)(NavigationDrawerItem);
