import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button } from '../../../shared/components';
import { states } from '../../goalmap-list/config';
import { wait } from '../../../shared/utils/common';

export default function FormControls({
    toggle,
    reset,
    change,
    form,
    submitting,
    submitDisabled,
    canCreateDraft,
}) {
    return (
        <Fragment>
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    toggle();
                    reset();
                }}
                outline
                disabled={submitting}
                mobile
            >
                Cancel
            </Button>
            {canCreateDraft && (
                <Button
                    outline
                    onClick={() => {
                        change('state', states.draft);
                        wait(0).then(form.submit);
                    }}
                    type="button"
                    disabled={submitting || submitDisabled}
                    mobile
                >
                    Save as Draft
                </Button>
            )}
            <Button
                styleType="success"
                type="submit"
                disabled={submitDisabled}
                loading={submitting}
                mobile
            >
                Publish
            </Button>
        </Fragment>
    );
}

FormControls.defaultProps = {
    form: undefined,
};

FormControls.propTypes = {
    toggle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
    canCreateDraft: PropTypes.bool.isRequired,
    form: PropTypes.object,
    change: PropTypes.func.isRequired,
};
