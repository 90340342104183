import request from '../../request';
import { createDetails } from '../../shared/entities';
import { getProfileSuccess } from '../user/actions';

const apiUrl = '/users/notifications';

const details = createDetails({
    apiUrl,
    name: 'user-notifications',
    extendAPI: {
        update({ params }) {
            return request.put(`${apiUrl}`, params);
        }
    },
    onUpdate: getProfileSuccess,
});

export default details;
