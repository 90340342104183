import request from '../../../../request';
import { createDetails, List } from '../../../../shared/entities';
import formatEntityType from './entity-type';

export const widgetActivityStreamCollection = new List({
    name: 'activity-feed-list',
    apiUrl: '/widgets/activities',
});

// TO DO: Use collaborator model instead
export const widgetActivityStreamModel = createDetails({
    name: 'activity-feed-watch',
    extendAPI: {
        update({ id, status, entityType }) {
            const watcher_type = formatEntityType(entityType);

            if (status === 1) {
                return request.post('/collaborators/watch', {
                    watcher_id: id,
                    watcher_type,
                });
            }

            return request.delete(`/collaborators/${id}/${watcher_type}`);
        }
    }
});

export function findActivityFeedItemsBySubjectId(items = [], id) {
    return (items.filter(item => item.subject.id === id) || []);
}
