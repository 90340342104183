import u from 'updeep';
import request from '../../request';
import { getEntitiesByType } from '../../shared/utils/content-editor-entity-selector';
import Dictionary from '../../shared/modules/dictionary';

export const defaultTitles = {
    driver: Dictionary({ name: 'drivers' }),
    milestone: Dictionary({ name: 'milestones' }),
    strategy_map: Dictionary({ name: 'strategies' }),
    segment: Dictionary({ name: 'segments' }),
    sub_segment: Dictionary({ name: 'subsegments' }),
    plan: Dictionary({ name: 'plan' }),
    action: Dictionary({ name: 'action' }),
};

export function formatSettings(response) {
    let parsedSettings = {};

    try {
        parsedSettings = JSON.parse(response.settings);
    } catch (err) {
        parsedSettings = defaultTitles;
    }

    try {
        response.wizard_settings = JSON.parse(response.wizard_settings);
    } catch (err) {
        response.wizard_settings = {};
    }

    delete parsedSettings.plan_action;

    const settings = u(parsedSettings, defaultTitles);

    response.settings = settings;
    return response;
}

export function getList(params) {
    return request
        .get('/goals', {
            params: {
                ...params,
                limit: 50,
            },
        })
        .then((response) =>
            u({ data: (response.data || []).map(formatSettings) }, response)
        );
}

export function getDetails({ id, ...rest }) {
    return request.get(`/goals/${id}`, { params: rest }).then(formatSettings);
}

function getMentions(htmlString) {
    if (htmlString) {
        return getEntitiesByType(htmlString, 'mention');
    }

    return [];
}

export function create(data) {
    const { goal_details, wizard_settings } = data.params;

    if (wizard_settings) {
        data.params.wizard_settings = JSON.stringify(wizard_settings);
    }
    const mission = getMentions(goal_details.mission); // getEntitiesByType(mission, 'mention');
    const values = getMentions(goal_details.values, 'mention');
    const vision = getMentions(goal_details.vision, 'mention');

    data.params.mentions = Array.from(
        new Set([...mission, ...values, ...vision])
    );
    return request.post('/goals', data.params).then(formatSettings);
}

export function updateDetails({ params }) {
    const { id, ...rest } = params;

    if (!rest.goal_details || rest.updateTitle || rest.updateGoalInfo) {
        let data = rest;
        delete data.updateTitle;
        delete data.updateGoalInfo;

        if (data.settings) {
            data = u({ settings: JSON.stringify(data.settings) }, data);
        }

        if (data.wizard_settings) {
            data = u(
                { wizard_settings: JSON.stringify(data.wizard_settings) },
                data
            );
        }

        return request.put(`/goals/${id}`, data).then(formatSettings);
    }

    let { mission, values, vision } = rest.goal_details;

    mission = getMentions(mission);
    values = getMentions(values);
    vision = getMentions(vision);

    rest.mentions = Array.from(new Set([...mission, ...values, ...vision]));

    if (rest.wizard_settings) {
        rest.wizard_settings = JSON.stringify(rest.wizard_settings);
    }

    return request.put(`/goals/${id}/details`, rest).then(formatSettings);
}

export function updateState({ id, state }) {
    return request.put(`/goals/${id}/state/${state}`).then(formatSettings);
}

export function remove({ id }) {
    return request.delete(`/goals/${id}`);
}

function memberStatusUpdate({ status, goal_id, id }) {
    return request.put(`/users/${id}/state/${status}`, { goal_id });
}

export function memberRemove(data) {
    return memberStatusUpdate({ status: 'destroy', ...data });
}

export function memberActivate(data) {
    return memberStatusUpdate({ status: 'active', ...data });
}

export function memberUpdate({ goalId, member }) {
    return request
        .put(`/goals/${goalId}/members`, {
            goal_members: [member],
        })
        .then(formatSettings);
}

export function membersAdd({ params }) {
    const { goalId, goal_members } = params;
    return request
        .post(`/goals/${goalId}/members`, { goal_members })
        .then(formatSettings);
}

export function cachedGetTree(id) {
    return request.cachedFetch(`/goals/${id}/tree`);
}
