import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, withRouter } from 'react-router';
import Button from '../button/component';

import styles from './styles.module.css';
import { Tooltip } from '../index';

function LinksToggleButtons({
    options, styleType, size, buttonsClassName, className, handleClick,
}) {
    return (
        <div className={cx(styles.linksToggleButtons, className)}>
            {options.map((opts) => {
                return (
                    <Link key={opts.tooltip} className={styles.linksToggleLink} to={opts.href} onClick={() => handleClick(opts)}>
                        <Tooltip text={opts.tooltip}>
                            <Button
                                className={cx(styles.linksToggleButton, buttonsClassName)}
                                styleType={opts.isActive ? styleType : LinksToggleButtons.styleTypes.default}
                                size={size}
                                outline={!opts.isActive}
                            >
                                {opts.content}
                            </Button>
                        </Tooltip>
                    </Link>
                );
            })}
        </div>
    );
}

LinksToggleButtons.styleTypes = Button.styleTypes;
LinksToggleButtons.sizes = Button.sizes;

LinksToggleButtons.defaultProps = {
    styleType: LinksToggleButtons.styleTypes.accent,
    size: LinksToggleButtons.sizes.medium,
    buttonsClassName: undefined,
    className: undefined,
    handleClick() {},
};

LinksToggleButtons.propTypes = {
    options: PropTypes.array.isRequired,
    styleType: PropTypes.string,
    size: PropTypes.string,
    buttonsClassName: PropTypes.string,
    className: PropTypes.string,
    handleClick: PropTypes.func,
};

export default withRouter(LinksToggleButtons);
