/** @namespace Utils.LocalStorage */

function test() {
    const testStr = 'test';
    const localStorageExists = 'localStorage' in window;

    if (!localStorageExists) {
        return false;
    }

    try {
        localStorage.setItem(testStr, testStr);
        localStorage.removeItem(testStr);
        return true;
    } catch (e) {
        return false;
    }
}

// eslint-disable-next-line jest/no-disabled-tests
export const enabled = test();

/**
 * Sets value to local storage by key
 * @function set
 * @memberof Utils.LocalStorage
 * @param {string} key Key for added item
 * @param {string|number} value Added value
 */
export function set(key, value) {
    if (enabled) {
        return localStorage.setItem(key, value);
    }

    return undefined;
}

/**
 * Gets value from local storage by key
 * @function get
 * @memberof Utils.LocalStorage
 * @param {string} key Key by which will be got value
 * @param {any} defaultValue Default value returned if localstorage is disabled
 * @return {boolean} Value by key or default value if localstorage is disabled
 */
export function get(key, defaultValue) {
    if (enabled) {
        return localStorage.getItem(key);
    }

    return defaultValue;
}

/**
 * Removes value from local storage by key
 * @function remove
 * @memberof Utils.LocalStorage
 * @param {string} key Key by which will be removed value
 */
export function remove(key) {
    if (enabled) {
        localStorage.removeItem(key);
    }
}
