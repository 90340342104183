import axios from 'axios';
import appConfig from '../../../../config';
import { createDetails } from '../../../../shared/entities';

const { apiUrl, slugs } = appConfig.wizardVMVContent;

export default createDetails({
    name: 'goalmap-wizard-vmv-content',
    updateHTML: false,
    extendAPI: {
        get() {
            return Promise.all([
                axios.get(apiUrl, { params: { slug: slugs.mission } }),
                axios.get(apiUrl, { params: { slug: slugs.vision } }),
                axios.get(apiUrl, { params: { slug: slugs.values } }),
            ]).then(([mission, vision, values]) => {
                return {
                    mission: mission.data[0].content.rendered,
                    vision: vision.data[0].content.rendered,
                    values: values.data[0].content.rendered,
                };
            });
        }
    }
});
