import PropTypes from 'prop-types';
import React from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation, EditableInput } from 'react-color/lib/components/common';
import cx from 'classnames';
import config from '../../../config';
import * as styleUtils from '../../style-utils';
import ColorSwatch from './swatch';
import DropdownDeprecated from '../dropdown/deprecated/component';
import Icon from '../icon/component';
import Button from '../button/component';

function ColorPicker({
    input, meta, hex, onChange, disabled, className, ...rest
}) {
    const trigger = () => (
        <div
            className={cx('input-color-toggle', {
                [styleUtils.inactive]: disabled,
            })}
        >
            <div className="input-color-info-group">
                <div className="input-color-info-title">
                    Custom Color
                </div>
                <span
                    className="input-color-value-text"
                    style={{
                        color: hex
                    }}
                >
                    {hex}
                </span>
            </div>
            <Button
                className="input-color-palette-btn"
                styleType={Button.styleTypes.accent}
            >
                <Icon name="palette" type={Icon.types.solid} />
            </Button>
        </div>
    );

    return (
        <div className={cx('input-color', className)}>
            {disabled && trigger()}
            {!disabled && (
                <DropdownDeprecated
                    trigger={trigger}
                >
                    <div className="input-color-selector">
                        <div className="left-side">
                            <div className="saturation-selector">
                                <Saturation
                                    {...rest}
                                    hex={hex}
                                    onChange={onChange}
                                />
                            </div>

                            <div className="hue-selector">
                                <Hue
                                    {...rest}
                                    hex={hex}
                                    onChange={onChange}
                                />
                            </div>

                            <EditableInput
                                {...rest}
                                value={hex}
                                onChange={onChange}
                            />
                        </div>

                        <div className="right-side">
                            <ColorSwatch
                                options={config.colorTheme.swatch}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </DropdownDeprecated>
            )}
        </div>
    );
}

ColorPicker.defaultProps = {
    disabled: false,
    className: undefined,
    hex: undefined,
    onChange() {},
};

ColorPicker.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    hex: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

export default CustomPicker(ColorPicker);
