import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { OwnerSelect, TitleTagnameFields, CategorizationTagSelector } from '../../../shared/modules';
import { Col, Editor, Row, } from '../../../shared/components';

export default function StrategyMapForm({
    change, goalId, titles,
}) {
    return (
        <div className="strategy-map-create-form">
            <TitleTagnameFields
                change={change}
                goalId={goalId}
                titleFieldLayoutRenderer={children => (
                    <Row>
                        <Col base={8}>
                            {children}
                        </Col>
                        <Col base={4}>
                            <OwnerSelect
                                id="subsegment-select-owner"
                                placeholder="Select Owner"
                                label="Owner"
                                goalId={goalId}
                                required
                            />
                        </Col>
                    </Row>
                )}
                titleFieldProps={{
                    autoFocus: true,
                    label: 'Name',
                    placeholder: `Name your ${titles.strategy_map}`,
                }}
                tagnameFieldProps={{
                    label: 'Tag Name',
                    placeholder: `Type in Tag Name for your ${titles.strategy_map}`
                }}
            />

            <Field
                name="description"
                placeholder={`Type in ${titles.strategy_map} Target State`}
                label="Target State"
                component={Editor}
                goal_id={goalId}
                controlsHidden
            />

            <Field
                name="tags"
                label="Tags"
                placeholder="Select tags"
                component={CategorizationTagSelector}
                goal_id={goalId}
            />
        </div>
    );
}

StrategyMapForm.defaultProps = {
    titles: {},
};

StrategyMapForm.propTypes = {
    change: PropTypes.func.isRequired,
    goalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    titles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
