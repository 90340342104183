import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Draggable from 'react-draggable';

import { Icon, Clickable } from '../../shared/components';
import { getDetails as getGoalDetails } from '../goalmap-list/selector';

import { progressNoteCollection, ProgressNoteCreateModal, ProgressNoteList } from '../secondary-entities/progress-note';
import { AttachmentCreateModal, attachmentCollection } from '../secondary-entities/attachment';
import AttachmentList from '../secondary-entities/attachment/list/attachment-list';
import { CollaboratorList, CollaboratorCreateModal, collaboratorCollection } from '../secondary-entities/collaborator';
import { RelatedLinkCreateModal, RelatedLinkList, relatedLinkCollection } from '../secondary-entities/related-link';
import { HistoryList } from '../secondary-entities/history';
import Permissions from '../permissions';

import { getActiveItemIndex } from './reducer';
import { itemPropTypes } from './proptypes';
import {
    hide as hideAction,
    show as showAction,
    setActive as setActiveAction,
    setRootWidth as setRootWidthAction
} from './actions';
import NavigationDrawerItem from './components/navigation-drawer-item';
import './styles/main.css';

const SIDEBAR_VISIBLE_OFFSET = 48;
const HANDLE_BORDER_WIDTH = 1;
const MAX_WIDTH = 680;
const MIN_WIDTH = 258;

const { createPermissionSelector } = Permissions.selectors;
const { entitySections } = Permissions.constants;

const componentsMap = {
    'progress-notes': {
        list: ProgressNoteList,
        create: ProgressNoteCreateModal,
        selector: progressNoteCollection.selector,
        permissionSelector: createPermissionSelector(entitySections.note)
    },
    'attachments': {
        list: AttachmentList,
        create: AttachmentCreateModal,
        selector: attachmentCollection.selector,
        permissionSelector: createPermissionSelector(entitySections.attachment),
    },
    'collaborators': {
        list: CollaboratorList,
        create: CollaboratorCreateModal,
        selector: collaboratorCollection.selector,
        permissionSelector: createPermissionSelector(entitySections.collaborator),
    },
    'related-links': {
        list: RelatedLinkList,
        create: RelatedLinkCreateModal,
        selector: relatedLinkCollection.selector,
        permissionSelector: createPermissionSelector(entitySections.relatedLink),
    },
    'history': {
        list: HistoryList,
    },
};

const icons = {
    'progress-notes': 'clipboard-list-check',
    'attachments': 'paperclip',
    'collaborators': 'user-friends',
    'related-links': 'link',
    'history': 'history',
};

class NavigationDrawerRight extends Component {
    constructor(args) {
        super(args);

        this.state = {
            dragging: false,
            localDeltaX: 0,
            height: 0
        };

        this.initialWidth = undefined;
        this.root = null;
    }

    componentDidMount() {
        this.initialWidth = this.props.rootWidthPx;

        // timeout is necessary to create CSS transition
        if (this.root) {
            this.timeout = setTimeout(() => {
                this.setState({
                    height: this.root.offsetHeight
                });
            }, 0);
        }
    }

    componentWillUnmount() {
        this.hide();
        if (this.timeout) clearTimeout(this.timeout);
    }

    setActive = (id) => {
        const { show, setActive } = this.props;

        show();
        setActive({ id });
    }

    hide = () => {
        const { hide, setActive } = this.props;

        hide();
        setActive({ id: undefined });
    }

    render() {
        const {
            visible, items, rootWidthPx, activeItemId, itemHeightPx, setRootWidth, activeItemIndex, parentId,
            parentEntityType, parentEntityState, userId, exclude, counters, updateDetails, relatedLinkProps, created_at,
            author, goalId, parentEntityTypeCode, fetchParent, isAutoCreation,
        } = this.props;

        const visibleItems = items.filter(item => !item.hidden || !exclude.includes(item.id));
        const contentHeightPx = this.state.height - (itemHeightPx * visibleItems.length);

        return (
            <span>
                <aside
                    id="nav-drawer-right"
                    ref={(root) => { this.root = root; }}
                    className={classnames({
                        'nav-drawer-visible': visible
                    })}
                    style={{
                        width: this.state.dragging
                            ? `${rootWidthPx + this.state.localDeltaX}px`
                            : `${rootWidthPx}px`,
                        transform: visible
                            ? 'translateX(0)'
                            : `translateX(${rootWidthPx - SIDEBAR_VISIBLE_OFFSET}px)`
                    }}
                >
                    {
                        visible
                            ? (
                                <Clickable
                                    action="hide-right-nav-sidebar"
                                    className="hide-button"
                                    onClick={this.hide}
                                >
                                    <Icon name="arrow-to-right" />
                                </Clickable>
                            )
                            : null
                    }

                    {items.map((item) => {
                        if (item.hidden || exclude.includes(item.id)) {
                            return null;
                        }

                        return (
                            <NavigationDrawerItem
                                {...item}
                                isDrawerOpen={visible}
                                key={item.id}
                                active={activeItemId === item.id}
                                controlHeightPx={itemHeightPx}
                                contentHeightPx={contentHeightPx}
                                onClick={this.setActive}
                                listComponent={componentsMap[item.id].list}
                                createComponent={componentsMap[item.id].create}
                                permissionSelector={componentsMap[item.id].permissionSelector}
                                parentId={parentId}
                                fetchParent={fetchParent}
                                parentEntityType={
                                    item.id === 'progress-notes'
                                        ? parentEntityTypeCode
                                        : parentEntityType
                                }
                                parentEntityTypeCode={parentEntityTypeCode}
                                parentEntityState={parentEntityState}
                                goalId={goalId}
                                userId={userId}
                                created_at={created_at}
                                author={author}
                                counter={counters[item.id]}
                                updateDetails={updateDetails}
                                relatedLinkProps={relatedLinkProps}
                                icon={icons[item.id]}
                                rightDrawerVisible={visible}
                                isAutoCreation={isAutoCreation}
                                isProgressNoteItem={item.id === 'progress-notes'}
                            />
                        );
                    })}
                </aside>

                <Draggable
                    axis="x"
                    bounds={{
                        left: (this.initialWidth * 2) - MAX_WIDTH,
                        right: (this.initialWidth * 2) - MIN_WIDTH
                    }}
                    defaultPosition={{
                        x: rootWidthPx
                    }}
                    onStart={() =>
                        this.setState({
                            dragging: true
                        })
                    }
                    onStop={() => {
                        setRootWidth({
                            width: rootWidthPx + this.state.localDeltaX
                        });

                        this.setState({
                            dragging: false,
                            localDeltaX: 0
                        });
                    }}
                    onDrag={(event, { deltaX }) =>
                        this.setState({
                            localDeltaX: this.state.localDeltaX - deltaX
                        })
                    }
                >
                    <span
                        className={classnames('nav-drawer-right-resize-handle', { visible })}
                        style={{
                            top: activeItemIndex || activeItemIndex === 0
                                ? `${(activeItemIndex * itemHeightPx) + (contentHeightPx / 2)}px`
                                : '0px',
                            right: this.state.dragging
                                ? `${rootWidthPx + this.state.localDeltaX - HANDLE_BORDER_WIDTH}px`
                                : `${rootWidthPx - HANDLE_BORDER_WIDTH}px`
                        }}
                    />
                </Draggable>
            </span>

        );
    }
}

NavigationDrawerRight.defaultProps = {
    activeItemId: undefined,
    activeItemIndex: undefined,
    exclude: [],
    counters: {},
    items: [],
    relatedLinkProps: {},
    updateDetails() {},
    userId: undefined,
    created_at: undefined,
    goalId: undefined,
    author: undefined,
    parentEntityTypeCode: undefined,
    isAutoCreation: undefined,
};

NavigationDrawerRight.propTypes = {
    visible: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    activeItemId: PropTypes.string,
    rootWidthPx: PropTypes.number.isRequired,
    setRootWidth: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
    itemHeightPx: PropTypes.number.isRequired,
    activeItemIndex: PropTypes.number,
    parentId: PropTypes.number.isRequired,
    parentEntityType: PropTypes.string.isRequired,
    parentEntityState: PropTypes.number.isRequired,
    created_at: PropTypes.string,
    author: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape(itemPropTypes),
    ),
    userId: PropTypes.number,
    exclude: PropTypes.arrayOf(
        PropTypes.string,
    ),
    counters: PropTypes.shape({

    }),
    updateDetails: PropTypes.func,
    relatedLinkProps: PropTypes.object,
    goalId: PropTypes.number,
    parentEntityTypeCode: PropTypes.number,
    fetchParent: PropTypes.func.isRequired,
    isAutoCreation: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        goalId: getGoalDetails(state).id,
        visible: state.navDrawerRight.visible,
        items: state.navDrawerRight.items,
        activeItemId: state.navDrawerRight.activeItemId,
        itemHeightPx: state.navDrawerRight.itemHeightPx,
        rootWidthPx: state.navDrawerRight.rootWidthPx,
        currentState: state,
        activeItemIndex: getActiveItemIndex(
            state.navDrawerRight.items,
            state.navDrawerRight.activeItemId
        ),
    };
}

const mapDispatchToProps = {
    hide: hideAction,
    show: showAction,
    setActive: setActiveAction,
    setRootWidth: setRootWidthAction
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDrawerRight);
