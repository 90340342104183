import { createModal } from '../../../shared/entities';
import { apiCreate } from '../../secondary-entities/progress-note/progress-note-config';

export default createModal({
    name: 'quick-add-progress-note-create',
    updateHTML: true,
    extendAPI: {
        create: apiCreate,
    }
});
