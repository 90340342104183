import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import subscriptionCollection from './subscription-limit-collection';

class Subscription extends Component {
    componentDidMount() {
        this.props.fetch();
    }

    render() {
        return null;
    }
}

Subscription.propTypes = {
    fetch: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    fetch: subscriptionCollection.actions.fetch.request,
};

const withConnect = connect(null, mapDispatchToProps)(Subscription);

export default withConnect;
