import React from 'react';
import { AppContextConsumer } from './component';

export const withAppContext = (WrappedComponent) => {
    return (props) => {
        return (
            <AppContextConsumer>
                {({
                    addDraftItem,
                    contextQuickAdd,
                    setQuickAddSuccessCallback,
                    setContextQuickAdd,
                    setUpdateProgressNoteOnSuccessCallback,
                    setDeleteProgressNoteOnSuccessCallback
                }) => (
                    <WrappedComponent
                        appContextAddItem={addDraftItem}
                        contextQuickAdd={contextQuickAdd}
                        setQuickAddSuccessCallback={setQuickAddSuccessCallback}
                        setContextQuickAdd={setContextQuickAdd}
                        setUpdateProgressNoteOnSuccessCallback={setUpdateProgressNoteOnSuccessCallback}
                        setDeleteProgressNoteOnSuccessCallback={setDeleteProgressNoteOnSuccessCallback}
                        {...props}
                    />
                )}
            </AppContextConsumer>
        );
    };
};
