import Text from '../text';
import generator from '../generator';

export const CardHeader = generator('header', 'card-header', 'CardHeader');
export const CardHeaderMainSection = generator('div', 'card-header-main', 'CardHeaderMainSection');
export const CardHeaderAdditionalSection = generator('div', 'card-header-additional', 'CardHeaderAdditionalSection');
export const CardHeaderTitleContainer = generator('div', 'card-header-title-container', 'CardHeaderTitleContainer');
export const CardHeaderTitle = generator('h3', 'card-header-title', 'CardHeaderTitle');
export const CardHeaderTagname = generator(Text, 'card-header-tagname', 'CardHeaderTagname');
export const CardHeaderType = generator('div', 'card-header-type', 'CardHeaderType');
