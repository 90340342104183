import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SubscriptionLimitProviders } from '../../../modules/subscription';
import { StickyContext } from '../../components/sticky/sticky-context';
import { Form, Button } from '../../components';
import { ErrorBoundary } from '../../modules';
import {
    ModalFormContainer,
    ModalFormHeader,
    ModalFormBody,
    ModalFormFooter,
} from '../../components/modal-form';
import { get as getCustomProperty } from '../../utils/css-custom-properties';
import { stickyConf } from '../../components/sticky/config';
import { wait } from '../../utils/common';

const limitAttrs = SubscriptionLimitProviders.Draft.attributes;

export default class FormContent extends Component {
    hide = () => {
        const {
            toggle, reset, onHide, onHideEnd
        } = this.props;

        onHide();
        toggle();
        reset();

        setTimeout(() => {
            onHideEnd();
        }, parseFloat(getCustomProperty('--transition-duration')) * 1000);
    }

    handleSuccessSubmit = (response) => {
        const { onCreateSuccess, appContextAddItem } = this.props;
        appContextAddItem(response);
        onCreateSuccess(response);
        // this.reinitializeWith();
    }

    handleCancelClick = (event) => {
        event.preventDefault();
        this.hide();
    }

    handleSubmitAsDraftClick = () => {
        const { change } = this.props;

        change('state', 4);
        wait(0).then(this.form.submit);
    }

    renderSubmitAsDraft = (limit) => {
        const { submitting } = this.props;
        const available = limit[limitAttrs.value];

        if (!available) {
            return null;
        }

        return (
            <Button
                type="button"
                outline
                disabled={submitting}
                onClick={this.handleSubmitAsDraftClick}
                mobile
            >
                Save as draft
            </Button>
        );
    }

    render() {
        const {
            handleSubmit,
            onSubmit,
            // handleSuccessSubmit,
            // formRef,
            dirty,
            submitting,
            // onHide,
            title,
            change,
            reset,
            isMobile,
            controlProps,
            submitTitle,
            currentStateValue,
            // onCancelClick,
            draftAvailable,
            // renderSubmitAsDraft,
            EnhancedComponent,
            ControlsComponent,
            modalFormContainerClassName,
            parseFormSubmitError,
            errorObjectGetter,
            ...rest
        } = this.props;

        return (
            <Form
                name="entity-create-form"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                onSuccessSubmit={this.handleSuccessSubmit}
                parseError={parseFormSubmitError}
                errorObjectGetter={errorObjectGetter}
                ref={(el) => { this.form = el; }}
                dirty={dirty}
                submitting={submitting}
                useNavigationBlocking
            >
                <ModalFormContainer className={modalFormContainerClassName}>
                    <ModalFormHeader
                        hide={this.hide}
                    >
                        {title}
                    </ModalFormHeader>

                    <ModalFormBody>
                        <StickyContext.Provider value={stickyConf.modal}>
                            <ErrorBoundary>
                                <EnhancedComponent
                                    {...rest}
                                    submitting={submitting}
                                    change={change}
                                    reset={reset}
                                    isMobile={isMobile}
                                />
                            </ErrorBoundary>
                        </StickyContext.Provider>
                    </ModalFormBody>

                    <ModalFormFooter>
                        {!!ControlsComponent && (
                            <ControlsComponent
                                submitting={submitting}
                                toggle={this.hide}
                                reset={reset}
                                change={change}
                                form={this.form}
                                {...controlProps}
                            />
                        )}

                        {!ControlsComponent && (
                            <Fragment>
                                <Button
                                    type="button"
                                    onClick={this.handleCancelClick}
                                    outline
                                    disabled={currentStateValue !== 4 && submitting}
                                    loading={currentStateValue === 4 && submitting}
                                    mobile
                                >
                                    Cancel
                                </Button>

                                {draftAvailable && !isMobile && (
                                    <SubscriptionLimitProviders.Draft>
                                        {this.renderSubmitAsDraft}
                                    </SubscriptionLimitProviders.Draft>
                                )}

                                <Button
                                    styleType="success"
                                    type="submit"
                                    disabled={currentStateValue === 4 && submitting}
                                    loading={currentStateValue !== 4 && submitting}
                                    mobile
                                >
                                    {submitTitle}
                                </Button>
                            </Fragment>
                        )}
                    </ModalFormFooter>
                </ModalFormContainer>
            </Form>
        );
    }
}

FormContent.defaultProps = {
    children: undefined,
    className: undefined,
    title: undefined,
    draftAvailable: true,
    initialValues: {},
    onCreateSuccess() { },
    controlHidden: false,
    controlProps: {},
    onHide() { },
    onHideEnd() { },
    currentStateValue: undefined,
    submitTitle: 'Publish',
    creationDisabled: false,
    modalFormContainerClassName: undefined,
    parseFormSubmitError: error => error,
    appContextAddItem() {},
    errorObjectGetter: undefined,
    visible: false,
    ControlsComponent: undefined
};

FormContent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    visible: PropTypes.bool,
    reset: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    draftAvailable: PropTypes.bool,
    onCreateSuccess: PropTypes.func,
    controlHidden: PropTypes.bool,
    controlProps: PropTypes.object,
    onHide: PropTypes.func,
    onHideEnd: PropTypes.func,
    title: PropTypes.string,
    currentStateValue: PropTypes.number,
    isMobile: PropTypes.bool.isRequired,
    submitTitle: PropTypes.string,
    creationDisabled: PropTypes.bool,
    dirty: PropTypes.bool.isRequired,
    EnhancedComponent: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
    ControlsComponent: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]),
    modalFormContainerClassName: PropTypes.string,
    parseFormSubmitError: PropTypes.func,
    appContextAddItem: PropTypes.func,
    errorObjectGetter: PropTypes.func,
};
