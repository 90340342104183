import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { get, flow } from 'lodash';
import * as routeUtils from '../rules';
import { isParamString, parseParam } from './utils';
import { deprecatedRouteMap } from './constants';
import { joinGetParams } from '../../shared/utils/url';

const pathNamePath = ['location', 'pathname'];

class DeprecatedRouteRedirectService extends Component {
    componentDidMount() {
        this.handleRedirect();
    }

    componentDidUpdate(prevProps) {
        const currentPathname = get(this.props, pathNamePath);
        const prevPathname = get(prevProps, pathNamePath);

        if (currentPathname !== prevPathname) {
            this.handleRedirect();
        }
    }

    get preparedPath() {
        const { pathname } = this.props.location;

        const path = pathname.split('/');
        path.splice(0, 1);

        return path;
    }

    handleRedirect = () => {
        const { navigate, location } = this.props;
        const match = deprecatedRouteMap.find(this.match);

        if (match) {
            const params = this.getParams(match);
            const { search } = location;
            const url = routeUtils.createUrl(match.to, params);

            flow(
                joinGetParams,
                navigate
            )(url, search);
        }
    }

    match = ({ from }) => {
        const mismatchFind = (section, index) => {
            const pathSection = this.preparedPath[index];
            const isParam = isParamString(section);

            if (isParam) {
                return false;
            }

            return pathSection !== section;
        };

        if (this.preparedPath.length) {
            const mismatch = from.find(mismatchFind);

            return !mismatch;
        }

        return false;
    }

    getParams = ({ from }) => {
        const getParamsReducer = (accum, section, index) => {
            const fromSection = from[index];
            const isParam = isParamString(fromSection);

            if (isParam) {
                const paramName = parseParam(fromSection);
                accum[paramName] = section;
            }

            return accum;
        };

        return this.preparedPath.reduce(getParamsReducer, {});
    }

    render() {
        return null;
    }
}

DeprecatedRouteRedirectService.propTypes = {
    router: PropTypes.shape({
        routes: PropTypes.array,
        params: PropTypes.object,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        params: PropTypes.object,
    }).isRequired,
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    navigate: replace,
};

export default connect(null, mapDispatchToProps)(DeprecatedRouteRedirectService);
