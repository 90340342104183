import { createSelector } from 'reselect';
import { integrationsCollection } from '../../application';
import { integrationsTypes, integrationAttributes as intgAttributes } from '../constants';

export const selectFetching = integrationsCollection.selectors.getFetchingState;

export const selectItems = integrationsCollection.selectors.getItems;

export const selectCommunicationItems = createSelector(
    integrationsCollection.selectors.getItems,
    items => items.filter(it => it[intgAttributes.group_type] === integrationsTypes.communication),
);

export const selectStorageItems = createSelector(
    integrationsCollection.selectors.getItems,
    items => items.filter(it => it[intgAttributes.group_type] === integrationsTypes.fileStorage),
);

export const selectZapierItems = createSelector(
    integrationsCollection.selectors.getItems,
    items => items.filter(it => it[intgAttributes.group_type] === integrationsTypes.zapier),
);

export const selectPublicApiItems = createSelector(
    integrationsCollection.selectors.getItems,
    items => items.filter(it => it[intgAttributes.group_type] === integrationsTypes.publicAPI),
);
