import { createAction } from 'redux-act';
import createAsyncAction from '../base/async-actions';
import formatRequestPayload from '../utils/format-request-payload';

export default function createActions({ prefix, additionalActions = {} }) {
    return {
        fetch: createAsyncAction(`${prefix}/FETCH`, formatRequestPayload),
        fetchLazy: createAsyncAction(`${prefix}/FETCH_LAZY`, formatRequestPayload),
        reset: createAction(`${prefix}/RESET`),
        unshift: createAction(`${prefix}/item/UNSHIFT`),
        push: createAction(`${prefix}/item/PUSH`),
        replace: createAction(`${prefix}/item/REPLACE`),
        remove: createAction(`${prefix}/item/REMOVE`),
        update: createAction(`${prefix}/item/UPDATE`),
        ...additionalActions,
        // merge: createAction(`${prefix}/item/MERGE`),
        setUpdatingItemId: createAction(`${prefix}/item/SET_UPDATING_ID`),
    };
}
