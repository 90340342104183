import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Input } from '../../components';
import getTagName from './api';

export default class TitleFields extends Component {
    constructor(args) {
        super(args);

        this.state = {
            tagNameLoading: false,
            lastName: undefined,
        };
    }

    handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            this.generateTag(event, event.target.value);
        }
    }

    generateTag = (event, value) => {
        const { change, goalId } = this.props;

        if (
            !value
            || !value.trim()
            || value === this.state.lastName
            || this.state.tagNameLoading
        ) {
            return;
        }

        this.setState({
            tagNameLoading: true
        });

        getTagName({ title: value, goal_id: goalId }).then(({ tag_name }) => {
            change('tag_name', tag_name);
            this.setState({
                tagNameLoading: false,
                lastName: value
            });
        },
        () => {
            this.setState({ tagNameLoading: false });
        });
    }

    render() {
        const {
            titleFieldProps,
            tagnameFieldProps,
            titleFieldLayoutRenderer,
            tagnameFieldLayoutRenderer,
            ContainerComponent,
        } = this.props;

        return (
            <ContainerComponent>
                {titleFieldLayoutRenderer(
                    <Field
                        name="title"
                        placeholder="Name your Segment"
                        id="segment-name-input"
                        component={Input}
                        {...titleFieldProps}
                        onKeyUp={this.handleKeyUp}
                        onBlur={this.generateTag}
                    />
                )}
                {tagnameFieldLayoutRenderer(
                    <Field
                        name="tag_name"
                        placeholder="Type in Tag for your Segment"
                        id="segment-tagname-input"
                        component={Input}
                        {...tagnameFieldProps}
                        disabled={this.state.tagNameLoading}
                    />
                )}
            </ContainerComponent>
        );
    }
}

function defaultFieldRenderer(children) {
    return children;
}

TitleFields.defaultProps = {
    titleFieldProps: {},
    tagnameFieldProps: {},
    titleFieldLayoutRenderer: defaultFieldRenderer,
    tagnameFieldLayoutRenderer: defaultFieldRenderer,
    ContainerComponent: 'div',
};

TitleFields.propTypes = {
    titleFieldProps: PropTypes.object,
    tagnameFieldProps: PropTypes.object,
    titleFieldLayoutRenderer: PropTypes.func,
    tagnameFieldLayoutRenderer: PropTypes.func,
    change: PropTypes.func.isRequired,
    goalId: PropTypes.number.isRequired,
    ContainerComponent: PropTypes.node,
};
