import { get } from 'lodash';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { types } from '../../../shared/utils/entity-type';
import createModalWizard from '../../wizard-entity/wizard-modal';
import { getDetailsTitles } from '../../goalmap-list/selector';
import { subscriptionLimitSelectors } from '../../subscription';
import { parse, mapInitialCreateValuesMilestone } from './utils';
import { create as createKPI, details as KPIModel } from '../entity';
import MilestoneCreatePage1 from './milestone-create-page1';
import MilestoneCreatePage2 from './milestone-create-page2';
import WizardForm from './wizard';

const formName = 'global-kpi-create';

const GlobalKPIModalWizard = createModalWizard({
    name: formName,
    removeOnCancel: true,
    actions: {
        create: createKPI.actions.request,
        update: KPIModel.actions.update.request,
        updateTags: KPIModel.actions.updateTags.request,
        remove: KPIModel.actions.remove.request,
        fetch: KPIModel.actions.request,
        toggle: createKPI.actions.toggle,
        keepModalVisible: createKPI.actions.setKeepModalVisible,
    },
    selectors: {
        selectModalVisible: state => createKPI.selector(state).modalVisible,
        selectFetchingState: state => KPIModel.selector(state).loading,
        selectAttributes: state => KPIModel.selector(state).data,
        titleSelector: titles => get(titles, types.milestone),
        getSubmitSteps: () => [1]
    },
    parseData: parse,
})(WizardForm);

const selectFormValues = getFormValues(formName);
const getSubmitSteps = () => [1];
const getSteps = (titles) => {
    return [
        {
            ContentComponent: MilestoneCreatePage1,
            description: `Name your ${get(titles, types.milestone)}, assign Owner, add Target State`
        },
        {
            ContentComponent: MilestoneCreatePage2,
            description: `Select type of ${get(titles, types.milestone)} and configure it`
        },
    ];
};

function mapStateToProps(state, ownProps) {
    const currentValues = selectFormValues(state);
    const titles = getDetailsTitles(state);
    const canCreateDraft = subscriptionLimitSelectors.canCreateDraft(state);

    return {
        initialValues: mapInitialCreateValuesMilestone(state, ownProps, canCreateDraft),
        steps: getSteps(titles),
        getSubmitSteps,
        currentValues,
        titles,
        isGlobalKpi: true,
    };
}

export default connect(mapStateToProps)(GlobalKPIModalWizard);
