import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function NotificationContainer({
    component,
    id,
    updatingItemId,
    is_read,
    children,
    ...rest
}) {
    const Component = component;
    return (
        <Component
            className={
                classnames('notifications-item', {
                    'is-read': is_read,
                    'disabled': updatingItemId === id,
                })
            }
            {...rest}
        >
            {children}
        </Component>
    );
}

NotificationContainer.defaultProps = {
    component: 'div',
    updatingItemId: undefined,
    children: undefined,
};

NotificationContainer.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]),
    id: PropTypes.number.isRequired,
    updatingItemId: PropTypes.number,
    is_read: PropTypes.bool.isRequired,
    children: PropTypes.node,
};
