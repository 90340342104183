/** @namespace Utils.Object */
import _, { camelCase, snakeCase } from 'lodash';
import { isGlobal } from '../../modules/goalmap-milestones/utils';
import { composeUniqueId } from '../../modules/work-center/table-grid-timeline-utils';
import { typeCodes } from './entity-type';

/**
 * Get value from object by path
 * @function deepFind
 * @memberof Utils.Object
 * @param {Object} obj Specified value
 * @param {Array<string|number>} path Path to value in object
 * @return {any} Value from object by path
 */
export function deepFind(obj, path) { // eslint-disable-line import/prefer-default-export
    let current = obj;
    let i;

    for (i = 0; i < path.length; i += 1) {
        if (current[path[i]] === undefined) {
            return undefined;
        }

        current = current[path[i]];
    }
    return current;
}

/**
 * Check if two objects are equal
 * @function isEqual
 * @memberof Utils.Object
 * @param {Object} obj1 First object
 * @param {Object} obj2 Second object
 * @return {boolean} True if objects are equal
 */
export function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * Deep object cloning
 * @function safeClone
 * @memberof Utils.Object
 * @param {Object} obj Cloned object
 * @param {Object} saveFunctions Is cloning with functions saving
 * @return {boolean} Cloned object
 */
export function safeClone(obj, saveFunctions) {
    if (saveFunctions) {
        return _.cloneDeep(obj);
    }

    return JSON.parse(JSON.stringify(obj));
}

/**
 * Checks if two objects have different specified props
 * @function hasDifferentProps
 * @memberof Utils.Object
 * @param {Object} obj1 First compared object
 * @param {Object} obj2 Second compared object
 * @param {Array<string|number>} propsList List of compared props
 * @param {boolean} useEqual to use isEqual for properties comparing
 * @return {boolean} True if two objects have any different specified props
 */
export function hasDifferentProps(obj1, obj2, propsList, useEqual = false) {
    for (let i = 0; i < propsList.length; i += 1) {
        const prop = propsList[i];
        if (useEqual ? !_.isEqual(obj1[prop], obj2[prop]) : obj1[prop] !== obj2[prop]) {
            return true;
        }
    }

    return false;
}

/**
 * Checks if two objects have different specified props
 * @function hasDifferentProps
 * @memberof Utils.Object
 * @param {Object} propsTo Destination object
 * @param {Object} propsFrom Source object
 * @return {boolean} Object with merged props that have not undefined values
 */
export function mergeNonUndefinedProps(propsTo, propsFrom) {
    const props = safeClone(propsTo);
    Object.entries(propsFrom).forEach(([key, value]) => {
        if (value === undefined) return;

        props[key] = value;
    });

    return props;
}

/**
 * Converts keys of an object to another case recursively. Returns new object.
 * @function keysToCase
 * @memberof Utils.Object
 * @param {Function} toCaseFn - Function that will convert string to another case.
 * @param {Object} obj - Object to convert.
 * @returns {Object}
 */
function keysToCase(toCaseFn, obj) {
    const toCaseReducer = (accum, entry) => {
        const [key, value] = entry;

        if (_.isArray(value)) {
            accum[toCaseFn(key)] = value.map((item) => {
                if (_.isObject(item)) {
                    return keysToCase(toCaseFn, item);
                }

                return item;
            });
        } else if (_.isObject(value)) {
            accum[toCaseFn(key)] = keysToCase(toCaseFn, value);
        } else {
            accum[toCaseFn(key)] = value;
        }

        return accum;
    };

    return Object
        .entries(obj)
        .reduce(toCaseReducer, {});
}

/**
 * Converts keys of an object to camel case recursively. Returns new object.
 * @function keysToCamelCase
 * @memberof Utils.Object
 * @param {Object} obj - Object to convert.
 * @returns {Object}
 */
export const keysToCamelCase = _.curry(keysToCase)(camelCase);

/**
 * Converts keys of an object to snake case recursively. Returns new object.
 * @function keysToSnakeCase
 * @memberof Utils.Object
 * @param {Object} obj - Object to convert.
 * @returns {Object}
 */
export const keysToSnakeCase = _.curry(keysToCase)(snakeCase);

/**
 * Add unique ids to entity item
 * @function addUniqueKey
 * @memberof Utils.Object
 * @param {Object} entity - Entity for unique id adding.
 * @returns {Object}
 */
export const addUniqueKeys = (entity) => {
    const isGlobalKPI = isGlobal(entity.entity) && entity.type_code === typeCodes.kpi;
    const isGlobalCSF = entity.type_code === typeCodes.driver;
    const extendedData = { parent: { ...entity.parent } };
    extendedData.unique_id = `${entity.id}-${entity.type_code}`;
    extendedData.parent.unique_id = isGlobalKPI || isGlobalCSF
        ? composeUniqueId(entity.goal_id, entity.type_code)
        : entity.parent.id;

    return { ...entity, ...extendedData };
};
