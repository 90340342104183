import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class ClickOutside extends Component {
    componentDidMount() {
        this.el = ReactDOM.findDOMNode(this);

        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = (e) => {
        const { onClick } = this.props;

        if (!this.el || !this.el.contains(e.target)) {
            onClick();
        }
    }

    render() {
        const { children } = this.props;

        return children;
    }
}

ClickOutside.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired
};
