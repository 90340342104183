import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import cx from 'classnames';
import { Row, Col } from '../../../shared/components';
import GridContainer from '../../../shared/components/grid/container';
import './style.css';

export default class GridListRender extends Component {
    renderRow = (item, rowIndex) => {
        const { columns, rowClassName } = this.props;
        const rowIndexIncreased = rowIndex + 1;

        return (
            <Row key={rowIndex} className={cx('custom-list-row', rowClassName)}>
                {item.map((cellItem, cellIndex) => {
                    const cellIndexIncreased = cellIndex + 1;
                    // eslint-disable-next-line max-len
                    const currentCellIndex = rowIndexIncreased * columns - (columns - cellIndexIncreased) - 1;
                    return this.renderCol(cellItem, currentCellIndex);
                })}
            </Row>
        );
    };

    renderCol = (item, index) => {
        const {
            itemProps,
            getItemProps,
            toggleDetailsModal,
            ItemComponent,
            columns
        } = this.props;
        const columnWidth = 12 / columns;

        return (
            <Col
                key={item.id}
                xs={columnWidth}
                md={columnWidth}
                lg={columnWidth}
                xl={columnWidth}
                className="custom-list-col"
            >
                <ItemComponent
                    {...item}
                    {...itemProps}
                    {...getItemProps(item, index)}
                    index={index}
                    toggleDetailsModal={toggleDetailsModal}
                />
            </Col>
        );
    };

    render() {
        const { items, columns, containerClassName } = this.props;
        const chunkedItems = chunk(items, columns);

        return (
            <GridContainer
                className={cx('grid-list-render', containerClassName)}
            >
                {chunkedItems.map(this.renderRow)}
            </GridContainer>
        );
    }
}

GridListRender.defaultProps = {
    columns: 2,
    toggleDetailsModal: undefined,
    getItemProps() {
        return undefined;
    },
    itemProps: {},
    rowClassName: undefined,
    containerClassName: undefined
};

GridListRender.propTypes = {
    items: PropTypes.array.isRequired,
    itemProps: PropTypes.object,
    getItemProps: PropTypes.func,
    toggleDetailsModal: PropTypes.func,
    ItemComponent: PropTypes.func.isRequired,
    columns: PropTypes.number,
    rowClassName: PropTypes.string,
    containerClassName: PropTypes.string,
};
