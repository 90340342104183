import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Field } from 'redux-form';
import {
    Icon,
    Clickable,
    Input,
    createInlineFieldForm,
    EditableContent,
    Editor,
    HTMLRenderer,
    Text,
    MetaInfo,
    TextTruncated,
} from '../../../shared/components';
import BadgeArchived from '../../../shared/components/badge-archived/component';
import { CategorizationTagSelector } from '../../../shared/modules';
import * as Card from '../../../shared/components/card';
import { isEmptyHTML } from '../../../shared/utils/editor-value';
import './styles.css';
import { safeClone } from '../../utils/object';
import { withErrorBoundary } from '../error-boundary';

class CardEditable extends Component {
    constructor(args) {
        super(args);

        const { formName, destroyOnUnmount, enableReinitialize } = args;

        this.FormComponent = createInlineFieldForm({
            formName,
            destroyOnUnmount,
            enableReinitialize
        });

        this.state = {
            editingField: undefined,
            isShowMoreDescription: this.props.isShowMoreDescription,
        };
    }

    setField = (field) => {
        const { editable } = this.props;

        if (!editable) return;

        this.setState({
            editingField: field
        });
    }

    getFormProps = () => {
        const {
            initialValues, onSubmit, formProps, onSuccessSubmit, isDropFieldOnVersionValidCheck
        } = this.props;

        return {
            initialValues,
            onSubmit,
            onSuccessSubmit,
            onCancel: this.dropField,
            onSubmitSuccess: isDropFieldOnVersionValidCheck ? this.dropField : () => {},
            ...formProps
        };
    }

    dropField = () => {
        this.setField(undefined);
    }

    handleRemoveTag = (id) => {
        const { onTagsSave, entityId, onSuccessSubmit } = this.props;
        const tags = safeClone(this.props.tags);
        const removedTagIdx = tags.findIndex(tag => tag.id === id);
        tags.splice(removedTagIdx, 1);

        return onTagsSave({ params: { id: entityId, tags }, onSuccess: onSuccessSubmit });
    }

    handleChangeIsShowMoreDescription = () => {
        this.setState(prevState => ({ isShowMoreDescription: !prevState.isShowMoreDescription }));
    };

    renderHeader = () => {
        const {
            title, tagName, headerChildren, titleChildren, editable, icon, entityTypeTitle, progressStatusInput,
            shouldRenderHeaderTitle, kpiType, headerIcons, planTitle, isArchivedEntity, isDraftEntity
        } = this.props;
        const { editingField } = this.state;
        const isEditingTitle = editingField === 'title';
        const isEditingTag = editingField === 'tag';
        const { FormComponent } = this;
        const formProps = this.getFormProps();

        return (
            <Card.CardHeader>
                <Card.CardHeaderMainSection>
                    {shouldRenderHeaderTitle && (
                        <Card.CardHeaderTitleContainer className={classnames({ 'is-editing': isEditingTitle })}>
                            {titleChildren}

                            <Card.CardHeaderTitle>
                                {
                                    isEditingTitle
                                        ? (
                                            <FormComponent {...formProps}>
                                                <Field
                                                    name="title"
                                                    component={Input}
                                                    autoFocus
                                                    noMargin
                                                />
                                            </FormComponent>
                                        )
                                        : (
                                            <Clickable
                                                action="edit-header"
                                                onClick={() => this.setField('title')}
                                                className="card-header-link"
                                            >
                                                <span>{title}</span>
                                                {
                                                    editable && (
                                                        <span className="card-header-edit">
                                                            <Icon
                                                                className="card-header-edit-icon"
                                                                name="pen"
                                                                type="regular"
                                                            />
                                                        </span>
                                                    )
                                                }
                                            </Clickable>
                                        )

                                }
                            </Card.CardHeaderTitle>
                        </Card.CardHeaderTitleContainer>
                    )}

                    <Card.CardHeaderTagname>
                        {!isEditingTag && (
                            <Text
                                component={Clickable}
                                action="edit-header"
                                onClick={() => this.setField('tag')}
                                className="card-header-tagname-text"
                            >
                                @{tagName}
                            </Text>
                        )}

                        {isEditingTag && (
                            <FormComponent {...formProps}>
                                <Field
                                    name="tag_name"
                                    component={Input}
                                    noMargin
                                    autoFocus
                                />
                            </FormComponent>
                        )}
                        {!!headerIcons && headerIcons}
                    </Card.CardHeaderTagname>

                    <Card.CardHeaderType>
                        <div className="details-entity-type">
                            <Icon type="regular" className="details-entity-type-icon" name={icon} />
                            <div className="details-entity-type-text">
                                {kpiType ? `${entityTypeTitle}: ${kpiType}` : entityTypeTitle}
                            </div>
                        </div>
                        {progressStatusInput}
                        {isArchivedEntity && <BadgeArchived />}
                        {isDraftEntity && (
                            <div className="details-entity-type draft">
                                <Icon type="regular" className="details-entity-type-icon draft" name="file-archive" />Draft
                            </div>
                        )}
                        {planTitle}
                    </Card.CardHeaderType>
                </Card.CardHeaderMainSection>

                {!!headerChildren && (
                    <Card.CardHeaderAdditionalSection>
                        {headerChildren}
                    </Card.CardHeaderAdditionalSection>
                )}
            </Card.CardHeader>
        );
    }

    renderBody = () => {
        const {
            description, goal_id, prependBody, editable, detailsForm, quillStickyProps,
        } = this.props;

        const { editingField, isShowMoreDescription } = this.state;
        const isEditing = editingField === 'description';
        const { FormComponent } = this;
        const formProps = this.getFormProps();
        const isEmptyValue = isEmptyHTML(description);

        return (
            <Fragment>
                <div className="details-entity-prepend-body">
                    {prependBody}
                </div>

                <Card.CardBody>
                    {detailsForm}
                    {isEditing && (
                        <FormComponent {...formProps}>
                            <Field
                                name="description"
                                component={Editor}
                                props={{
                                    controlsHidden: true,
                                    goal_id,
                                    ...quillStickyProps,
                                }}
                            />
                        </FormComponent>
                    )}

                    {!isEditing && (
                        <EditableContent
                            className="card-body-editable"
                            editable={editable}
                            onClick={() => this.setField('description')}
                        >
                            {(!isEmptyValue) && (
                                <Fragment>
                                    {!isShowMoreDescription ? (
                                        <HTMLRenderer>
                                            {description}
                                        </HTMLRenderer>
                                    ) : (
                                        <TextTruncated
                                            text={description}
                                            readMore
                                            onClick={this.handleChangeIsShowMoreDescription}
                                        />
                                    )}
                                </Fragment>
                            )}
                            {(isEmptyValue) && (
                                <Text
                                    className="card-body-editable-placeholder"
                                    component="p"
                                    styleType="muted"
                                >
                                    No Target State
                                </Text>
                            )}
                        </EditableContent>
                    )}
                </Card.CardBody>
            </Fragment>
        );
    }

    renderFooter = () => {
        const {
            tags,
            onTagsSave,
            initialValues,
            goal_id,
            editable,
            onSuccessSubmit
        } = this.props;

        const { FormComponent } = this;
        const isEditing = this.state.editingField === 'tags';

        return (
            <Card.CardFooter>
                <MetaInfo
                    tags={tags}
                    goal_id={goal_id}
                    tagsHidden={isEditing}
                    onEditTags={() => this.setField('tags')}
                    onRemoveTag={this.handleRemoveTag}
                    editable={editable}
                />

                {isEditing && (
                    <FormComponent
                        containerClassName="card-editable-tag-selector"
                        onSubmit={onTagsSave}
                        onCancel={this.dropField}
                        onSuccessSubmit={onSuccessSubmit}
                        initialValues={{
                            id: initialValues.id,
                            tags
                        }}
                    >
                        <Field
                            name="tags"
                            component={CategorizationTagSelector}
                            goal_id={goal_id}
                            autoFocus
                            openOnFocus
                        />
                    </FormComponent>
                )}
            </Card.CardFooter>
        );
    }

    render() {
        const {
            className,
        } = this.props;

        return (
            <Card.CardContainer
                className={
                    classnames('card-editable', {
                        [className]: className
                    })
                }
            >
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderFooter()}
            </Card.CardContainer>
        );
    }
}

CardEditable.defaultProps = {
    className: undefined,
    title: undefined,
    // updating: false,
    tagName: undefined,
    headerChildren: undefined,
    titleChildren: undefined,
    bodyProps: {},
    footerProps: {},
    initialValues: {},
    description: '',
    tags: [],
    tagsHidden: false,
    prependBody: undefined,
    formProps: {},
    destroyOnUnmount: true,
    enableReinitialize: false,
    editable: true,
    progressStatusInput: undefined,
    planTitle: undefined,
    detailsForm: undefined,
    shouldRenderHeaderTitle: true,
    isShowMoreDescription: false,
    kpiType: undefined,
    headerIcons: undefined,
    onSuccessSubmit() {},
    isDropFieldOnVersionValidCheck: false,
    isArchivedEntity: false,
    isDraftEntity: false,
    quillStickyProps: {},
};

CardEditable.propTypes = {
    formName: PropTypes.string.isRequired,
    className: PropTypes.string,
    tagName: PropTypes.string,
    // updating: PropTypes.bool,
    title: PropTypes.string,
    headerChildren: PropTypes.node,
    prependBody: PropTypes.node,
    titleChildren: PropTypes.node,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        }),
    ),
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        goal_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        title: PropTypes.string,
        description: PropTypes.string,
        tags: PropTypes.array,
        author_id: PropTypes.number,
        tag_name: PropTypes.string,
    }),
    onTagsSave: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    tagsHidden: PropTypes.bool,
    goal_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    entityId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    formProps: PropTypes.object,
    bodyProps: PropTypes.object,
    footerProps: PropTypes.object,
    destroyOnUnmount: PropTypes.bool,
    enableReinitialize: PropTypes.bool,
    editable: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    entityTypeTitle: PropTypes.string.isRequired,
    progressStatusInput: PropTypes.node,
    planTitle: PropTypes.node,
    detailsForm: PropTypes.node,
    shouldRenderHeaderTitle: PropTypes.bool,
    isShowMoreDescription: PropTypes.bool,
    kpiType: PropTypes.string,
    headerIcons: PropTypes.node,
    onSuccessSubmit: PropTypes.func,
    isDropFieldOnVersionValidCheck: PropTypes.bool,
    isArchivedEntity: PropTypes.bool,
    isDraftEntity: PropTypes.bool,
    quillStickyProps: PropTypes.object,
};

export default withErrorBoundary(CardEditable);
