import u from 'updeep';
import apiService from '../../../request';
import { Model } from '../../../shared/entities-v2';
import { ModelNotificationsPlugin } from '../../../shared/entities-v2/plugins';
import { keysToCamelCase, keysToSnakeCase } from '../../../shared/utils/object';
import settingsAccountDemoDataCollection from './settings-account-demo-data-collection';

const baseApiUrl = '/demo_data/plans';

class SettingsAccountDemoDataModel extends Model {
    parse = keysToCamelCase

    format = keysToSnakeCase

    create(data, options) {
        const url = [baseApiUrl, data.type].join('/');

        return apiService
            .post(url, null, options)
            .then(response => this.parse(response, Model.requestTypes.create))
            .catch(this.parseError);
    }

    update(data, options) {
        const url = this.getApiUrl(data);

        return apiService
            .put(url, null, options)
            .then(response => this.parse(response, Model.requestTypes.update))
            .catch(this.parseError);
    }
}

const settingsAccountDemoDataModel = new SettingsAccountDemoDataModel({
    apiUrl: baseApiUrl,
    name: 'settings-account-demo-data-model',
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onUpdateSuccess]: settingsAccountDemoDataCollection.actions.update,
            [Model.events.onCreateSuccess]: settingsAccountDemoDataCollection.actions.update,
        }
    },
    plugins: [
        new ModelNotificationsPlugin({
            name: 'settings-account-demo-data-model-notifications',
            dictionary: u({
                success: {
                    create: 'The Demo Plan has been added',
                    update: 'The Demo Plan has been reset to default',
                }
            }, ModelNotificationsPlugin.dictionary)
        }),
    ]
});

export default settingsAccountDemoDataModel;
