import u from 'updeep';
import request from '../../../request';
import createActions from '../list/actions';
import createReducer, { createSelector } from '../list/reducer';
import createSaga from '../list/saga';

const defaultOptions = {
    apiUrl: '/drivers',
    name: 'drivers',
};

export default class List {
    constructor(options = defaultOptions) {
        this.options = options;

        this.fetch = this.fetch.bind(this);
        this.getApiUrl = options.getApiUrl || this.getApiUrl.bind(this);
        this.parse = options.parse || this.parse.bind(this);
        this.parseItem = options.parseItem || this.parseItem.bind(this);
        this.format = options.format || this.format.bind(this);

        this.actions = createActions(options.name);
        this.reducer = createReducer(
            this.actions,
            options.extendReducer,
            { ignoreDraftMark: options.ignoreDraftMark }
        );

        this.selector = createSelector(options.name);
        this.saga = createSaga(this.actions, this.fetch);
        this.name = options.name;
    }

    getApiUrl(params) {
        return this.options.apiUrl || this.options.getApiUrl(params);
    }

    fetch(params) {
        const url = this.getApiUrl(params);
        const formattedParams = this.format(params);

        return request
            .get(url, { params: formattedParams })
            .then(this.parse);
    }

    parse(response) {
        return u(
            { data: response.data.map(this.parseItem) },
            response
        );
    }

    parseItem(response) { // eslint-disable-line class-methods-use-this
        return response;
    }

    format(params) { // eslint-disable-line class-methods-use-this
        return params;
    }
}
