import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { createFormModal } from '../../../../shared/modules';
import { WizardHeader, LoadingIndicator, } from '../../../../shared/components';
import { types } from '../../../../shared/utils/entity-type';
import { csfCopyModel } from '../csf-models';
import CSFForm from '../form/csf-form';

import styles from '../../../../shared/components/wizard/wizard-header/styles.module.css';

const CSFFormWithWizardHeader = (props) => {
    const { titles, loading } = props;

    const description = `Create a new ${titles.driver} with the same configuration as the current entity has.`;
    return (
        <>
            <WizardHeader
                className={styles.wizardHeaderInModal}
                steps={[{ description }]}
                title={`Copy ${titles.driver}`}
                currentStep={1}
                entity={{ type: types.driver }}
            />
            {loading
                ? <LoadingIndicator centered />
                : <CSFForm {...props} />
            }
        </>
    );
};

CSFFormWithWizardHeader.defaultProps = {
    loading: false,
};

CSFFormWithWizardHeader.propTypes = {
    titles: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = (_state, ownProps) => {
    return ({
        initialValues: omit(ownProps.data, [
            'tag_name'
        ]),
    });
};

const formName = 'segment-copy';

const formModal = createFormModal({
    actions: csfCopyModel.actions,
    selector: csfCopyModel.selector,
    formName,
    enableReinitialize: true,
    mapStateToProps,
})(CSFFormWithWizardHeader);

export default formModal;
