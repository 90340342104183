import React, { Fragment } from 'react';
import { Text } from '../../../shared/components';

function MSTeamsIntegrationInstructions() {
    const logoLink = (
        <Text
            component="a"
            underline
            href="https://mpowrenvisionimages.s3.us-east-2.amazonaws.com/On-Boarding/Envision_ICON.png"
            target="_blank"
            rel="noopener noreferrer"
        >
            link
        </Text>
    );

    const learnMoreLink = (
        <Text
            component="a"
            underline
            href="https://mpowrsoftware.atlassian.net/servicedesk/customer/article/691830902"
            target="_blank"
            rel="noopener noreferrer"
        >
            Click here
        </Text>
    );

    return (
        <Fragment>
            <Text component="h2">
                Instructions
            </Text>

            <Text component="p">
                WARNING Sending activity out of MPOWR Envision and into Microsoft Teams bypasses the
                security controls in MPOWR Envision. Anyone who has access to your Microsoft Team will
                be able to see the activity, regardless of whether they have access to that information in MPOWR Envision.
            </Text>

            <Text className="instructions-block-title" bold>
                Create an incoming webhook in Microsoft Teams
            </Text>

            <ul>
                <li>Open Microsoft Teams and select Store.</li>
                <li>Select Incoming Webhook.</li>
                <li>Enter your Microsoft Team and click Install.</li>
                <li>Select the channel where you want to receive notifications and click Set up.</li>
                <li>Name your webhook (e.g. Envision Updates).</li>
                <li>Download the MPOWR Envision avatar using this {logoLink} and upload it as the webhook image.</li>
                <li>Click Create.</li>
                <li>Copy the webhook URL.</li>
            </ul>

            <Text className="instructions-block-title" bold>
                Configure the integration in MPOWR Envision
            </Text>

            <ul>
                <li>Select the Plan you want to initiate Teams notifications and click Configure.</li>
                <li>Paste the URL into the Input Webhook URL field.</li>
                <li>Select the Sensitivity level to control the sensitivity of notifications being sent into Teams</li>
                <li>Make sure the Notifications toggle is On and click Save when ready to activate the integration.</li>
            </ul>

            <Text className="instructions-block-link" component="p">
                {learnMoreLink} to learn more.
            </Text>
        </Fragment>
    );
}

export default MSTeamsIntegrationInstructions;
