import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import MeasureRangeItem from './measure-range-item';
import measureRanges from './constants';
import './styles.css';

function MeasureRangeSelector({
    name,
    value,
    label,
    onChange,
    className,
    measures,
    disabled,
}) {
    return (
        <div className={cx('measure-range-selector', {
            [className]: !!className
        })}
        >
            <div className="measure-range-selector-label">
                {label}
            </div>
            <div className="measure-range-selector-options">
                {measures
                    .map((measure, idx) => {
                        const showDivider = idx !== 0;

                        return (
                            <MeasureRangeItem
                                key={measure.value}
                                showDivider={showDivider}
                                onChange={() => onChange(measure.value, name)}
                                value={measure.value}
                                active={value}
                                disabled={disabled}
                                {...measure}
                            />
                        );
                    })}
            </div>
        </div>
    );
}

MeasureRangeSelector.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    measures: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
};

MeasureRangeSelector.defaultProps = {
    label: 'Range Of Measure',
    className: undefined,
    disabled: false,
};

MeasureRangeSelector.measures = measureRanges;

export default MeasureRangeSelector;
