import { get as getCustomProp } from '../../../shared/utils/css-custom-properties';

const modes = Object.freeze({
    edit: 'edit',
    readonly: 'readonly'
});

const strategies = Object.freeze({
    asc: 'asc',
    desc: 'desc',
    between: 'between'
});

const colors = {
    danger: getCustomProp('color-danger') || '#fe646c',
    warning: getCustomProp('color-warning') || '#fad679',
    success: getCustomProp('color-success') || '#43c895',
    transparrent: 'transparent'
};

const SEGMENTS = [
    {
        idx: 0,
        color: colors.danger,
    },
    {
        idx: 1,
        color: colors.warning,
    },
    {
        idx: 2,
        color: colors.success,
    },
    {
        idx: 3,
        color: colors.warning,
    },
    {
        idx: 4,
        color: colors.danger,
    },
];

const READONLY_SEGMENTS = [
    {
        idx: 0,
        color: colors.danger,
    },
    {
        idx: 1,
        color: colors.transparrent,
    },
    {
        idx: 2,
        color: colors.warning,
    },
    {
        idx: 3,
        color: colors.transparrent,
    },
    {
        idx: 4,
        color: colors.success,
    },
    {
        idx: 5,
        color: colors.transparrent,
    },
    {
        idx: 6,
        color: colors.warning,
    },
    {
        idx: 7,
        color: colors.transparrent,
    },
    {
        idx: 8,
        color: colors.danger,
    },
];

const config = {
    between: {
        defaultVals: [0, 33, 66, 100, 133, 166, 200],
        handlerIdxs: [1, 2, 3, 4, 5],
        disabledIndexes: [3],
        segments: SEGMENTS.concat(),
        readonlySegments: READONLY_SEGMENTS.concat()
    },
    asc: {
        defaultVals: [0, 33, 66, 100],
        handlerIdxs: [1, 2, 3],
        disabledIndexes: [3],
        segments: SEGMENTS.slice(0, 3),
        readonlySegments: READONLY_SEGMENTS.slice(0, 6)
    },
    desc: {
        defaultVals: [100, 66, 33, 0],
        handlerIdxs: [1, 2, 3],
        disabledIndexes: [3],
        segments: [...SEGMENTS].slice(0, 3),
        readonlySegments: [...READONLY_SEGMENTS].slice(0, 6)
    },
    FIELD_WIDTH_PX: 76,
    HANDLER_WIDTH_PX: 6,
};

export {
    modes,
    config,
    strategies,
};
