export default {
    strategicPlan: `Your mutually agreed to roadmap for accomplishing your organizational vision
        while adhering to a common set of values and a collective purpose.`,
    segment: `The first level of your plan. You can segment in a variety of ways: by department, 
        by shared values, by industries served, by customer benefits, 
        by business unit etc. You decide.`,
    strategy: `What is the method by which you will accomplish important goals within each segment?
        This is a required level and should include both the method and the broad goal.`,
    objective: `Strategy broken down into individual goals that are clear and measurable. 
        This is not a required level but if used, objectives should have the target goal within the statement.`,
    tactic: `The specific accomplishment that should happen in order 
        to successfully achieve the objectives or strategies.`,
    action: `A task you must accomplish in order to successfully 
        complete an objective or strategy.`,
    kpi: `A specific measurement that allows you to understand if your organization is on 
        the right track towards accomplishing your goals.`,
    csf: `An overall element of your organization that you must get right if you are to stay 
        true to your mission. These may be linked to one or many KPIs, 
        Objectives, Tactics and Action Items.`,
};
