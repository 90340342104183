import { takeEvery, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { types } from '../../../shared/utils/entity-type';
import { create as createAction, details as actionModel } from '../entity';
import { create as createKPI, details as KPIModel, format as formatKPI } from '../../goalmap-milestones/entity';
import * as actions from './actions';

function* handleChildCreate(action) {
    const entityType = get(action, ['payload', 'params', 'entity_type']);
    const entityId = get(action, ['payload', 'params', 'id']);
    let nextActionRequest;
    const { payload } = action;

    switch (entityType) {
        case types.milestone:
            nextActionRequest = entityId
                ? KPIModel.actions.update.request
                : createKPI.actions.request;
            payload.params = formatKPI(payload.params);
            break;

        case types.action:
        default:
            nextActionRequest = entityId
                ? actionModel.actions.update.request
                : createAction.actions.request;
            break;
    }

    yield put(nextActionRequest(payload));
}

function* handleChildRemove(action) {
    const entityType = get(action, ['payload', 'params', 'entity_type']);
    let nextActionRequest;
    const { payload } = action;

    switch (entityType) {
        case types.milestone:
            nextActionRequest = KPIModel.actions.remove.request;
            break;

        case types.action:
        default:
            nextActionRequest = createAction.actions.remove.request;
            break;
    }

    yield put(nextActionRequest(payload));
}

function* tacticChildrenCreateSaga() {
    yield takeEvery(actions.createChild, handleChildCreate);
    yield takeEvery(actions.removeChild, handleChildRemove);
}

export default tacticChildrenCreateSaga;
