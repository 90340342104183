import { TableView } from '../../components';
import styles from './team-section.module.css';

export const roles = Object.freeze({
    leader: 1,
    member: 2,
});

export const roleOptions = [
    { value: roles.leader, label: 'Leader' },
    { value: roles.member, label: 'Member' },
];

export const restrictedRoleOptions = [
    { value: roles.member, label: 'Member' },
];

export const columns = [
    {
        key: 1,
        label: 'Team Name',
        className: styles.name,
        sortable: true,
        sortField: 'title',
        align: TableView.align.LEFT,
    },
    {
        key: 2,
        label: 'Team Leaders',
        align: TableView.align.LEFT,
    },
    {
        key: 3,
        label: 'Team Members',
        className: styles.members,
        align: TableView.align.LEFT,
    },
    {
        key: 4,
        label: 'Created',
        sortable: true,
        sortField: 'created_at',
        align: TableView.align.CENTER,
    },
    {
        key: 5,
        label: 'Actions',
        align: TableView.align.CENTER,
    },
];

export const queryFields = {
    teamId: 'team-id'
};

export const usersApi = {
    endpoint: '/users/list',
    params: {
        limit: 'unlimited',
        order_by: ['first_name,asc', 'last_name,asc']
    }
};
