import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, Button, IconButton } from '../../../../shared/components';
import ServiceModalProvider from '../../../../shared/components/serviceMessageModal/provider';
import mapStateToProps, { propTypes } from '../../contact-sales-mapper';
import createMailtoHref from '../../create-mailto';
import './subscription-contact-sales-modal.css';

function ContactSalesModalContent({
    text, hide, user, account
}) {
    return (
        <div className="contact-sales-modal-content">
            <IconButton
                icon="times-circle"
                action="Close Modal"
                onClick={hide}
                className="contact-sales-modal-hide"
            />

            <Text component="p">
                {text}
            </Text>

            <div className="contact-sales-modal-controls">
                <Button onClick={hide} styleType="success" component="a" href={createMailtoHref({ user, account })}>
                    Contact Support
                </Button>
            </div>
        </div>
    );
}

ContactSalesModalContent.propTypes = {
    text: PropTypes.string.isRequired,
    hide: PropTypes.func.isRequired,
    user: propTypes.user.isRequired,
    account: propTypes.account.isRequired,
};

function ContactSalesModalProvider({ children, ...rest }) {
    const { hide: popupHide, visible } = rest;
    return (
        <ServiceModalProvider
            renderContent={({ hide }) => <ContactSalesModalContent {...rest} hide={hide} />}
            visible={visible}
            hide={popupHide}
        >
            {children}
        </ServiceModalProvider>
    );
}

ContactSalesModalProvider.propTypes = {
    children: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps
)(ContactSalesModalProvider);
