import { register } from './actions';

export default function dynamicStoreMiddleware(createReducers) {
    const reducers = {};

    return () => next => (action) => {
        if (action.type === register.getType()) {
            reducers[action.payload.name] = action.payload.reducer;
            action.payload.store.replaceReducer(
                createReducers(reducers)
            );
        }

        return next(action);
    };
}
