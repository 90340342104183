import { Model } from '../../shared/entities-v2';
import { integrationsCollection } from '../application';

const apiUrl = '/integrations';
const nameModel = 'settings-integrations-model';

export const settingsIntegrationsModel = new Model({
    apiUrl,
    name: nameModel,
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onUpdateSuccess]: integrationsCollection.actions.update,
        },
    },
});
