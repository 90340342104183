import { createSelector } from 'reselect';
import { get } from 'lodash';
import { selectSearchQuery } from '../../../shared/selectors/routing';
import { emailUpdateSuccessModalAttribute } from '../constants';

function getSuccessUpdateModalVisible(query = {}) {
    return get(query, emailUpdateSuccessModalAttribute);
}

export const selectSuccessUpdateModalVisible = createSelector(
    selectSearchQuery,
    getSuccessUpdateModalVisible,
);
