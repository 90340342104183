import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { FormattedDate, Icon, } from '../../../../shared/components';
import config from '../../../../config';
import {
    formatFieldName, getValueRenderType, checkIfSpecialType, getSpecialTypeField,
    sensitivities, states, progressStatuses,
} from '../history-utils';

import './history-list-item.css';

function findDescr(item) {
    return (
        item === 'description'
        || item === 'mission'
        || item === 'vision'
        || item === 'values'
    );
}

export default function HistoryListItemRenderer({
    user,
    type,
    fields,
    updated,
    updated_at,
    created_at,
    author,
    original,
    className,
    dateHidden,
    toggleDescrModal,
    is_auto_creation,
}) {
    const { first_name, last_name } = get(user, 'user_profile', {});
    const specialType = checkIfSpecialType(type);
    const descrUpdated = fields.find(findDescr);

    return (
        <div
            className={classnames({
                [className]: className
            })}
        >
            <div>
                {specialType && (
                    <span className="history-item-special-wrap">
                        {first_name} {last_name} updated{' '}
                        <b className="history-item-special-field">
                            {getSpecialTypeField(type)}s
                        </b>{dateHidden && '.'}
                    </span>
                )}

                {(!specialType && type === 'created_at' && created_at) && (
                    <span>
                        Creation Date:{' '}
                        <b>
                            <FormattedDate date={created_at} />
                        </b>
                    </span>
                )}

                {(!specialType && type === 'author') && (
                    <span>Author: <b>{author}</b></span>
                )}

                {(!specialType && type === 'created') && (
                    <span>Created by <b>{first_name} {last_name}</b>.</span>
                )}

                {(!specialType && ['created', 'created_at', 'author'].indexOf(type) === -1) && (
                    <span>
                        {first_name} {last_name} {type}{' '}
                        {fields.map((item, index) => {
                            const valueRenderType = getValueRenderType(item);
                            const formattedFieldName = formatFieldName(item);
                            const updatedValue = updated[item];
                            const isValidDate = (valueRenderType === 'date' && updatedValue);
                            return (
                                <span key={item}>
                                    <span>
                                        <b className="history-item-field">
                                            {formattedFieldName}
                                        </b>
                                    </span>
                                    <span>
                                        {valueRenderType === 'user' && (() => {
                                            if (updated.owner === null) {
                                                return (
                                                    <span>
                                                        {' '}to <b>Unassigned</b>
                                                    </span>
                                                );
                                            }

                                            if (updated.owner) {
                                                return (
                                                    <span>
                                                        {' '}to{' '}
                                                        <b>
                                                            {updated.owner.user_profile.first_name}{' '}
                                                            {updated.owner.user_profile.last_name}
                                                        </b>
                                                    </span>
                                                );
                                            }

                                            return null;
                                        })}

                                        {valueRenderType === 'progress_status' && (
                                            <span> to <b>{progressStatuses[updatedValue]}</b></span>
                                        )}

                                        {valueRenderType === 'value' && (
                                            <span> to <b className="history-item-field">{updatedValue}</b></span>
                                        )}

                                        {valueRenderType === 'status' && (
                                            <span> to <b>{config.statusNames[updatedValue]}</b></span>
                                        )}

                                        {valueRenderType === 'state' && (
                                            <span> to <b>{states[updatedValue]}</b></span>
                                        )}

                                        {valueRenderType === 'date' && (
                                            <span> to{' '}
                                                {isValidDate ? (
                                                    <b><FormattedDate date={updatedValue} /></b>
                                                ) : <b>Blank</b>}
                                            </span>
                                        )}

                                        {index < fields.length - 1 && (', ')}

                                        {valueRenderType === 'sensitivity' && (
                                            <span>
                                                {' '}to <b>{sensitivities[updatedValue]}</b>
                                            </span>
                                        )}
                                    </span>


                                </span>
                            );
                        })}{dateHidden && ('.')}
                    </span>
                )}

                {!dateHidden && (
                    <span>
                        {' '} on <FormattedDate date={updated_at} />.{' '}
                    </span>
                )}

                {descrUpdated && (
                    <a
                        href="#show-previos-description"
                        className="history-item-previous-version"
                        onClick={(event) => {
                            event.preventDefault();
                            toggleDescrModal(original[descrUpdated]);
                        }}
                        role="button"
                    >
                        {' '}View previous {formatFieldName(descrUpdated)}
                    </a>
                )}
            </div>
            {is_auto_creation && <Icon className="history-item-icon" name="broadcast-tower" />}
        </div>
    );
}

HistoryListItemRenderer.defaultProps = {
    fields: [],
    updated: {},
    user: {},
    className: undefined,
    dateHidden: false,
    original: {},
    author: undefined,
    created_at: undefined,
    updated_at: undefined,
};

HistoryListItemRenderer.propTypes = {
    user: PropTypes.shape({
        user_profile: PropTypes.object,
    }),
    type: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.string,
    ),
    updated: PropTypes.shape({
        description: PropTypes.string,
        owner: PropTypes.object,
    }),
    original: PropTypes.shape({
        description: PropTypes.string,
    }),
    updated_at: PropTypes.string,
    created_at: PropTypes.string,
    author: PropTypes.string,
    className: PropTypes.string,
    dateHidden: PropTypes.bool,
    toggleDescrModal: PropTypes.func.isRequired,
    is_auto_creation: PropTypes.bool.isRequired,
};
