import moment from 'moment';
import u from 'updeep';
import {
    get, cloneDeep, isEmpty, find, reject
} from 'lodash';
import { getDaysFromRange } from '../../shared/utils/date';
import { typeCodes } from '../../shared/utils/entity-type';
import { requiredFromValues, frequencyToMeasurement } from '../goalmap-milestones/constants';
import { requestParams, filterOptionValues, unassignedPlan } from './constants';
import DateContentMap from './date-content-map';

const daysCounts = {
    daily: 1,
    weekly: 7,
    monthly: 30,
    quarterly: 90,
    annually: 365
};

// useDaysEqual is needed for backend periods offsets compatibility
const getNextUpdateDate = (frequency, lastUpdateDate, useDaysEqual) => {
    switch (frequency) {
        case 'daily':
            return moment(lastUpdateDate).add(1, 'day');
        case 'weekly':
            return moment(lastUpdateDate).add(7, 'days');
        case 'quarterly': {
            const params = useDaysEqual ? [90, 'days'] : [3, 'months'];

            return moment(lastUpdateDate).add(...params);
        }
        case 'annually': {
            const params = useDaysEqual ? [365, 'days'] : [1, 'year'];

            return moment(lastUpdateDate).add(...params);
        }
        case 'monthly':
        default: {
            const params = useDaysEqual ? [30, 'days'] : [1, 'month'];

            return moment(lastUpdateDate).add(...params);
        }
    }
};

function createRecurringItem({
    boundaries, item, dateContentMap
}) {
    const { start, end } = boundaries;

    let startCheckDay = start;
    if (!item.is_need_updated) {
        const durationFromStart = ['pending', 'hold'].includes(item.status)
            ? moment.duration(moment.max(moment(), moment(item.start_at).startOf('day')).diff(start)).as('days')
            : moment.duration(moment().diff(start)).as('days');
        const daysInPeriod = daysCounts[item.frequency];
        startCheckDay = moment(start).add(
            Math.floor(durationFromStart / daysInPeriod) * daysInPeriod,
            'days'
        );
    }

    const today = moment().endOf('day');
    let nextUpdateDate = getNextUpdateDate(item.frequency, startCheckDay, true);
    while (nextUpdateDate.isBetween(moment(startCheckDay).startOf('day'), moment(end).endOf('day'), undefined, '[]')) {
        if (nextUpdateDate.isAfter(today)) {
            dateContentMap.addToDateContent(nextUpdateDate, item.uniqueId);
        }
        nextUpdateDate = getNextUpdateDate(item.frequency, nextUpdateDate, true);
    }
}

function createRecurringKPIItem({
    boundaries, item, dateContentMap, requiredFrom, period
}) {
    const { start, end } = boundaries;

    let nextUpdateDate = start.clone();
    const measure = frequencyToMeasurement[item.frequency];
    const startCheckBound = moment(start).startOf('month');
    const endCheckBound = moment(end).endOf(measure);
    const currentDay = moment().startOf('day');
    const getBetweenCheckParams = startBound => [startBound, endCheckBound, undefined, '[]'];
    const dataPoints = item.entity.data_points || [];
    const dates = getDaysFromRange(period.startDate, period.endDate);
    const dataPointsMap = new DateContentMap(dates);

    dataPoints.forEach(dp => dataPointsMap.addToDateContent(moment(dp.period_start), dp));

    while (nextUpdateDate.isBetween(...getBetweenCheckParams(startCheckBound))) {
        const boundMethod = requiredFrom === requiredFromValues.first ? 'startOf' : 'endOf';
        const targetCellDate = nextUpdateDate.clone()[boundMethod](measure);
        const hasDataPointInPeriod = () => {
            const dataPointsInPeriod = dataPointsMap.getDateContent(moment(targetCellDate).startOf(measure));

            return dataPointsInPeriod && dataPointsInPeriod.length;
        };

        if (
            targetCellDate.isBetween(...getBetweenCheckParams(start))
            && targetCellDate.isSameOrAfter(item.start_at)
            && !targetCellDate.startOf('day').isSame(currentDay)
            && !hasDataPointInPeriod()
        ) {
            dateContentMap.addToDateContent(targetCellDate, item.uniqueId);
        }

        nextUpdateDate = getNextUpdateDate(item.frequency, nextUpdateDate);
    }
}

const isOfActionTacticType = item => item.type_code === typeCodes.action || item.type_code === typeCodes.tactic;
const isOfKPIType = item => item.type_code === typeCodes.kpi;

const mapUpdateRequiredItems = (items, dateContentMap, period) => {
    const today = moment();

    const mapUpdateRequiredItemsMapper = (item) => {
        if (isOfActionTacticType(item)) {
            let lastUpdateDate = moment(item.updated_at);
            if (item.is_need_updated) {
                dateContentMap.addToDateContent(today, item.uniqueId);
                lastUpdateDate = today;
            }

            createRecurringItem({
                boundaries: { start: lastUpdateDate, end: period.endDate },
                item,
                dateContentMap,
                period
            });
        }

        if (isOfKPIType(item)) {
            if (item.is_need_updated) {
                dateContentMap.addToDateContent(today, item.uniqueId);
            }

            createRecurringKPIItem({
                boundaries: { start: today, end: period.endDate },
                item,
                dateContentMap,
                requiredFrom: item.entity.required_from,
                period
            });
        }
    };

    return items.forEach(mapUpdateRequiredItemsMapper);
};

const mapTargetDateItems = (items, dateContentMap) => {
    const targetDateItemsMapper = (item) => {
        if (item.entity.required_from) {
            const boundMethod = item.entity.required_from === requiredFromValues.first ? 'startOf' : 'endOf';
            const measure = frequencyToMeasurement[item.frequency];

            dateContentMap.addToDateContent(
                moment(item.target_at)[boundMethod](measure),
                item.uniqueId
            );
        } else {
            dateContentMap.addToDateContent(item.target_at, item.uniqueId);
        }
    };

    return items.forEach(targetDateItemsMapper);
};

const mapStartDateItems = (items, dateContentMap) =>
    items.forEach(item => dateContentMap.addToDateContent(item.start_at, item.uniqueId));

const mapCloseDateItems = (items, dateContentMap) =>
    items.forEach(item => dateContentMap.addToDateContent(item.entity?.closed_date, item.uniqueId));

export const mapItemsByDates = (filters, items) => {
    const filterType = get(filters, requestParams.filterType);
    const startDate = get(filters, requestParams.startDate);
    const endDate = get(filters, requestParams.endDate);

    if (!filterType || !startDate || !endDate) {
        return {};
    }

    const dates = getDaysFromRange(startDate, endDate);
    const dateContentMap = new DateContentMap(dates);

    switch (filterType) {
        case filterOptionValues.targetDate:
            mapTargetDateItems(items, dateContentMap);
            break;
        case filterOptionValues.startDate:
            mapStartDateItems(items, dateContentMap);
            break;
        case filterOptionValues.closedDate:
            mapCloseDateItems(items, dateContentMap);
            break;
        case filterOptionValues.updateRequired:
        default:
            mapUpdateRequiredItems(items, dateContentMap, { startDate, endDate });
            break;
    }

    return dateContentMap.getDatesWithContent();
};

const filterWithoutValue = filter => filter && typeof filter === 'object' && (!filter.value || filter.value?.length === 0);

export const formatUnassignedPlan = (data, rootParam = requestParams.customFilterConfig) => {
    let result = { ...data };
    const planValue = get(result, `${rootParam}.plan.value`, []);
    const hasUnassigned = !!find(planValue, { plan_id: unassignedPlan.id });

    if (hasUnassigned) {
        const nextPlanValue = reject(planValue, { plan_id: unassignedPlan.id });

        if (nextPlanValue.length) {
            result = u.updateIn(`${rootParam}.plan.value`, nextPlanValue, result);
        } else {
            result = u.updateIn(`${rootParam}.plan`, undefined, result);
        }
    }

    result = u.updateIn(`${rootParam}.include_unassigned`, hasUnassigned, result);

    return result;
};

export const normalizeFilterValues = (filterValues, property = requestParams.customFilterConfig) => {
    const normalizeFilters = cloneDeep(filterValues);
    Object.keys(normalizeFilters[property]).forEach((key) => {
        if (filterWithoutValue(normalizeFilters[property][key])) {
            delete normalizeFilters[property][key];
        }
    });
    return normalizeFilters;
};

export const formatFilterData = (data, emptyData) => {
    const result = normalizeFilterValues(data);

    if (isEmpty(result[requestParams.customFilterConfig])) {
        return {
            ...result,
            [requestParams.customFilterConfig]: emptyData,
        };
    }

    return formatUnassignedPlan(result);
};
