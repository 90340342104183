import React, { Component } from 'react';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from '../../utils/common';
import './styles.css';

const defaultAppendLocation = () => document.body;

export default class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            arrow: null
        };

        this.dropdownInst = null;
    }

    handleCreate = (inst) => {
        this.dropdownInst = inst;
    }

    setArrow = (arrow) => {
        this.setState({ arrow });
    }

    hide() {
        this.dropdownInst.hide();
    }

    show() {
        this.dropdownInst.show();
    }

    render() {
        const {
            children, trigger, className, component, disabled, position, align,
            dropdownContentClassName, arrowClassName, duration, onHide, onShow, appendTo, usePortal,
            triggerEvent, theme,
        } = this.props;
        const { arrow } = this.state;

        const WrapComponent = component;

        return (
            <Tippy
                reference={this.dropdown}
                className={classnames('dropdown-fixed-container', `theme-${theme}`)}
                appendTo={usePortal ? appendTo : 'parent'}
                interactive
                hideOnClick
                onCreate={this.handleCreate}
                content={(
                    <div
                        className={classnames('dropdown-fixed-content', {
                            [dropdownContentClassName]: !!dropdownContentClassName
                        })}
                    >
                        {children}
                        <div
                            className={classnames('dropdown-fixed-arrow', {
                                [arrowClassName]: !!arrowClassName
                            })}
                            ref={this.setArrow}
                        />
                    </div>
                )}
                maxWidth={false}
                placement={[position, align].filter(val => val).join('-')}
                offset={0}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'arrow',
                            options: {
                                element: arrow
                            },
                        },
                    ],
                }}
                onShow={onShow}
                onHide={onHide}
                trigger={triggerEvent}
                delay={[0, null]}
                disabled={disabled}
                duration={duration}
            >
                <WrapComponent
                    className={classnames('dropdown-fixed-trigger', {
                        [className]: !!className
                    })}
                >
                    {trigger}
                </WrapComponent>
            </Tippy>
        );
    }
}

Dropdown.position = {
    auto: 'auto',
    top: 'top',
    right: 'right',
    bottom: 'bottom',
    left: 'left',
};

Dropdown.align = {
    center: undefined,
    start: 'start',
    end: 'end'
};

Dropdown.events = {
    mouseenter: 'mouseenter',
    focus: 'focus',
    click: 'click',
    focusin: 'focusin',
    manual: 'manual',
};

Dropdown.themes = {
    default: 'default',
    dark: 'dark',
};

Dropdown.defaultProps = {
    className: undefined,
    dropdownContentClassName: undefined,
    arrowClassName: undefined,
    children: undefined,
    trigger: undefined,
    component: 'div',
    disabled: false,
    position: Dropdown.position.bottom,
    align: Dropdown.align.center,
    duration: 200,
    onHide: noop,
    onShow: noop,
    appendTo: defaultAppendLocation,
    usePortal: false,
    triggerEvent: Dropdown.events.click,
    theme: Dropdown.themes.default,
};

Dropdown.propTypes = {
    component: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    dropdownContentClassName: PropTypes.string,
    arrowClassName: PropTypes.string,
    trigger: PropTypes.node,
    disabled: PropTypes.bool,
    position: PropTypes.string,
    align: PropTypes.string,
    duration: PropTypes.number,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    appendTo: PropTypes.func,
    usePortal: PropTypes.bool,
    triggerEvent: PropTypes.string,
    theme: PropTypes.string,
};
