import { get } from 'lodash';
import request from '../../../request';
import { Model } from '../../../shared/entities-v2';
import { fieldNames } from '../config';

const constants = Object.freeze({
    name: 'auth-change-email',
    apiUrlFetch: '/change_email/auth_providers',
    apiUrlUpdate: '/change_email/new_email',
});

class ChangeEmailModel extends Model {
    update(data, options) {
        const formattedData = this.format(data);
        const changeEmailRequest = (code) => {
            delete formattedData[fieldNames.currentEmail];

            if (code) {
                formattedData[fieldNames.code] = code;
            }

            if (!formattedData[fieldNames.passwordNew]) {
                formattedData[fieldNames.passwordNew] = formattedData[fieldNames.password];
            }

            return request
                .put(constants.apiUrlUpdate, formattedData, options);
        };

        if (get(formattedData, fieldNames.email) && get(formattedData, fieldNames.email)) {
            const params = {
                email: get(formattedData, fieldNames.currentEmail),
                password: get(formattedData, fieldNames.password),
            };

            return request
                .get('/authorization_code', { params })
                .then(response => changeEmailRequest(response.code))
                .then(this.parse, this.parseError);
        }

        return changeEmailRequest()
            .then(this.parse, this.parseError);
    }
}

export default new ChangeEmailModel({
    name: constants.name,
    getApiUrl() { return constants.apiUrlFetch; },
});
