import { createReducer } from 'redux-act';
import u from 'updeep';
import { defaultSubPath } from '../../shared/modules/details-view-mobile/config';
import * as actions from './actions';

export { name } from './config';

const initialState = {
    id: undefined,
    entityType: undefined,
    entityTypeCode: undefined,
    tab: defaultSubPath,
    goalId: undefined,
};

function handleSet(state, action) {
    return u({
        id: Number(action.id),
        entityType: action.entityType,
        entityTypeCode: action.entityTypeCode,
        tab: action.tab || defaultSubPath,
        goalId: Number(action.goalId),
    }, state);
}

function handleSetTab(state, action) {
    return u({
        tab: action.tab || defaultSubPath,
    }, state);
}

function handleUnset() {
    return initialState;
}

const reducerMap = {
    [actions.set]: handleSet,
    [actions.unset]: handleUnset,
    [actions.setTab]: handleSetTab,
};

export default createReducer(reducerMap, initialState);
