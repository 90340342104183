/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isUndefined, } from 'lodash';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { details as kpiDetails } from '../../../../modules/goalmap-milestones/entity';
import { attributes, } from '../../../../modules/goalmap-milestones/constants';
import { getCurrencyFormatSymbol } from '../../../../modules/application/localizations/selectors';
import { attributes as dataPointAttributes } from '../../../../modules/goalmap-milestones-breakdown/constants';
import { getCantCreateDataPointMessage } from '../../../../modules/goalmap-milestones/utils';
import {
    Text, StatusIndicator, ReadMore, Icon, Clickable, Button, Tooltip
} from '../../../components';
import { roundDecimal } from '../../../utils/math';
import { formatValueForDataPoints, } from '../../../utils/formatters';
import { noop } from '../../../utils/common';

import styles from './kpi-data-points-list.module.css';

const DataPointParam = ({
    children, textLabel, styleType, bold, currencySymbol, kpiType,
}) => {
    if (isUndefined(children)) return null;
    return (
        <div>
            <Text>{textLabel}&nbsp;</Text>
            <Text styleType={styleType} bold={bold}>
                {typeof children === 'number'
                    ? formatValueForDataPoints(kpiType, children, currencySymbol)
                    : children
                }
            </Text>
        </div>
    );
};

DataPointParam.defaultProps = {
    styleType: 'dark-light',
    bold: false,
    children: undefined,
    currencySymbol: undefined,
    kpiType: undefined,
};

DataPointParam.propTypes = {
    textLabel: PropTypes.string.isRequired,
    children: PropTypes.node,
    styleType: PropTypes.string,
    bold: PropTypes.bool,
    currencySymbol: PropTypes.string,
    kpiType: PropTypes.string,
};

const getTrendStyle = (trend) => {
    if (!trend) return 'dark-light';
    return trend > 0 ? 'success' : 'danger';
};


const KpiDataPointsList = ({
    data_points, progressNoteShowDetailsModal, canCreateDataPoint, handleCreateDataPointForMissedPeriod,
    handleEditDataPoint, classNameList, classNameItem, kpiTypeLimit, isKpiActive,
}) => {
    const { data: kpiData } = useSelector(kpiDetails.selector);
    const currencySymbol = useSelector(state => getCurrencyFormatSymbol(state)(kpiData?.[attributes.currency_format]));

    const onReadMoreClick = (progressNoteId) => {
        progressNoteShowDetailsModal({ id: progressNoteId });
    };

    const cantCreateMessage = getCantCreateDataPointMessage(kpiTypeLimit, isKpiActive);
    const canCreateEditKpiDataPoint = canCreateDataPoint && kpiTypeLimit && isKpiActive;

    return (
        <div className={classnames(styles.kpiDataPointsList, { [classNameList]: !!classNameList, })}>
            {data_points.length === 0
                ? <span className={styles.noDataPoints}>No Data Points</span>
                : data_points.map(data_point => (
                    <Fragment key={data_point.id}>
                        {get(data_point, dataPointAttributes.empty, false)
                            ? (
                                <div className={classnames(styles.kpiDataPointItem, { [classNameItem]: !!classNameItem, })}>
                                    <DataPointParam textLabel="Period:" bold>{get(data_point, dataPointAttributes.title, '-')}</DataPointParam>
                                    <Tooltip
                                        text={cantCreateMessage}
                                        disabled={canCreateEditKpiDataPoint}
                                    >
                                        <Button
                                            outline
                                            size="small"
                                            round
                                            onClick={canCreateEditKpiDataPoint
                                                ? () => handleCreateDataPointForMissedPeriod(data_point.period_start)
                                                : noop}
                                            className={styles.kpiDataPointAddButton}
                                        >
                                            <Icon name="plus" type={Icon.types.regular} className={styles.kpiDataPointAddIcon} />
                                        </Button>
                                    </Tooltip>
                                </div>
                            )
                            : (
                                <div className={classnames(styles.kpiDataPointItem, { [classNameItem]: !!classNameItem, })}>
                                    <div>
                                        <DataPointParam textLabel="Period:" bold>{get(data_point, dataPointAttributes.title)}</DataPointParam>
                                        <DataPointParam textLabel="Period Actual:" currencySymbol={currencySymbol} kpiType={kpiData.type}>
                                            {get(data_point, dataPointAttributes.period_actual)}
                                        </DataPointParam>
                                        <DataPointParam textLabel="Period Target:" currencySymbol={currencySymbol} kpiType={kpiData.type}>
                                            {get(data_point, dataPointAttributes.period_target)}
                                        </DataPointParam>
                                        <DataPointParam textLabel="Overall Progress:" currencySymbol={currencySymbol} kpiType={kpiData.type}>
                                            {get(data_point, dataPointAttributes.overall_progress)}
                                        </DataPointParam>
                                        <DataPointParam textLabel="Target Progress:" currencySymbol={currencySymbol} kpiType={kpiData.type}>
                                            {get(data_point, dataPointAttributes.target_progress)}
                                        </DataPointParam>
                                        <div className={styles.kpiDataPointHealth}>
                                            <Text>Health:&nbsp;</Text>
                                            <StatusIndicator
                                                status={get(data_point, dataPointAttributes.health, 4)}
                                                size="small"
                                            />
                                        </div>
                                        <DataPointParam textLabel="Trend:" styleType={getTrendStyle(data_point.trend)}>
                                            {data_point.trend
                                                ? (
                                                    <>
                                                        {data_point.trend > 0 ? '▲' : '▼'}&nbsp;
                                                        {Math.abs(roundDecimal(get(data_point, dataPointAttributes.trend, 0)))}%
                                                    </>
                                                )
                                                : '-'
                                            }
                                        </DataPointParam>
                                        <DataPointParam textLabel="Note:">
                                            <div className={styles.kpiDataPointNote}>
                                                <ReadMore
                                                    characterLimit={24}
                                                    onReadMoreClick={() => onReadMoreClick(get(data_point, dataPointAttributes.progress_note_id))}
                                                    text={get(data_point, [dataPointAttributes.progress_note, 'content'], '-')}
                                                />
                                                {data_point?.[dataPointAttributes.is_auto_creation]
                                                    && <Icon className={styles.kpiDataPointNoteIcon} name="broadcast-tower" />
                                                }
                                            </div>
                                        </DataPointParam>
                                    </div>
                                    <Tooltip
                                        text={cantCreateMessage}
                                        disabled={canCreateEditKpiDataPoint}
                                        className={styles.kpiDataPointTooltip}
                                    >
                                        <Clickable
                                            action="edit-kpi-data-point"
                                            onClick={canCreateEditKpiDataPoint
                                                ? () => handleEditDataPoint(data_point)
                                                : noop}
                                        >
                                            <Icon name="pen" type={Icon.types.regular} className={styles.kpiDataPointEditIcon} />
                                        </Clickable>
                                    </Tooltip>
                                </div>
                            )}
                    </Fragment>
                ))}
        </div>
    );
};

KpiDataPointsList.defaultProps = {
    classNameList: undefined,
    classNameItem: undefined,
};

KpiDataPointsList.propTypes = {
    data_points: PropTypes.array.isRequired,
    progressNoteShowDetailsModal: PropTypes.func.isRequired,
    canCreateDataPoint: PropTypes.bool.isRequired,
    handleCreateDataPointForMissedPeriod: PropTypes.func.isRequired,
    handleEditDataPoint: PropTypes.func.isRequired,
    classNameList: PropTypes.string,
    classNameItem: PropTypes.string,
    kpiTypeLimit: PropTypes.bool.isRequired,
    isKpiActive: PropTypes.bool.isRequired,
};

export default KpiDataPointsList;
