import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import config from '../../../config';
import ProgressStatus from '../progress-status/component';
import DropdownDeprecated from '../dropdown/deprecated/component';
import Clickable from '../clickable/component';
import Icon from '../icon/component';
import Tooltip from '../tooltip/component';

import './styles.css';

const statusMapAction = config.statusNames;
const statusMapKpi = { active: 'Active', inactive: 'Inactive' };


const prepareStatusMap = (value, isKpi) => {
    return Object
        .keys(isKpi ? statusMapKpi : statusMapAction)
        .filter(item => item !== value)
        .map(item => ({
            value: item,
            label: isKpi ? statusMapKpi[item] : statusMapAction[item],
        }));
};

export default class ProgressStatusInput extends Component {
    render() {
        const {
            value, onChange, className, isKpi, disabled, ...rest
        } = this.props;
        const preparedStatuses = prepareStatusMap(value, isKpi);
        return (
            <>
                { disabled
                    ? (
                        <Tooltip
                            disabled={!disabled}
                            text="This action is not available for your role."
                        >
                            <ProgressStatus className="progress-status-input-value">
                                {isKpi ? statusMapKpi[value] : statusMapAction[value]}
                            </ProgressStatus>
                        </Tooltip>
                    )
                    : (
                        <DropdownDeprecated
                            ref={(el) => { this.dropdown = el; }}
                            containerClassName={
                                classnames({
                                    [className]: className
                                })
                            }
                            trigger={({ open }) => (
                                <ProgressStatus className="progress-status-input-value">
                                    {isKpi ? statusMapKpi[value] : statusMapAction[value]}
                                    <Icon
                                        name={open ? 'chevron-up' : 'chevron-down'}
                                        className="progress-status-input-value-icon"
                                        type={Icon.types.regular}
                                    />
                                </ProgressStatus>
                            )}
                            className="progress-status-input"
                            {...rest}
                        >
                            {preparedStatuses.map(item => (
                                <Clickable
                                    className={classnames('progress-status-item progress-status-label', {
                                        active: value === item.value
                                    })}
                                    action="change-progress-status"
                                    key={item.value}
                                    onClick={() => {
                                        this.dropdown.hide();
                                        onChange(item.value);
                                    }}
                                >
                                    {item.label}
                                </Clickable>
                            ))}
                        </DropdownDeprecated>
                    )
                }
            </>
        );
    }
}

ProgressStatusInput.defaultProps = {
    className: undefined,
    isKpi: false,
};

ProgressStatusInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isKpi: PropTypes.bool,
    disabled: PropTypes.bool.isRequired,
};
