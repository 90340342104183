export const globalAccountTypes = Object.freeze({
    globalAdmin: 'account_admin',
    participant: 'account_participant',
    limitedParticipant: 'account_limited_participant',
});

export const roleTypes = Object.freeze({
    global: 1,
    local: 2,
});

export const planRoleNames = Object.freeze({
    leader: 'goal_leader',
});

export const userStatuses = Object.freeze({
    new: 1,
    active: 2,
    destroy: 3,
});
