import { Collection } from '../../../../shared/entities-v2';

class PasswordWithPolicy extends Collection {
    // eslint-disable-next-line class-methods-use-this
    parse(response) {
        return { ...response, data: response?.rules || [] };
    }
}

const passworPolicyCollection = new PasswordWithPolicy({
    apiUrl: '/password_policy',
    name: 'password-policy-collection',
});

export default passworPolicyCollection;
