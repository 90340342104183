import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const types = Object.freeze({
    error: 'error',
    success: 'success',
});

const typesArr = Object.values(types);

const typeClassNames = Object.freeze({
    [types.error]: 'auth-widget-message-error',
    [types.success]: 'auth-widget-message-success',
});

export default function AuthWidgetMessage({ type, children }) {
    const typeClassName = typeClassNames[type];

    return (
        <div
            className={classnames('auth-widget-message', {
                [typeClassName]: true,
            })}
        >
            {children}
        </div>
    );
}

AuthWidgetMessage.defaultProps = {
    type: types.success,
};

AuthWidgetMessage.propTypes = {
    type: PropTypes.oneOf(typesArr),
    children: PropTypes.node.isRequired,
};

AuthWidgetMessage.types = types;
