const base = {
    'color-white': '#fff',
    'color-black': '#000',

    'color-nasty-green': '#47c796',
    'color-nasty-green-light': '#61E1B0',
    'color-nasty-green-dark': '#2EAE7D',
    'color-leaf': '#6bb332',
    'color-leaf-half-opacity': 'rgba(107, 179, 50, 0.5)',

    'color-light-mustard': '#ffd708',
    'color-light-mustard-light': '#FFF122',
    'color-light-mustard-dark': '#E6BE00',
    'color-light-pale-mustard': '#C1CF46',
    'color-light-grey-blue': '#a4aab8',

    'color-orange': '#F79421',
    'color-orange-half-opacity': 'rgba(247, 148, 33, 0.5)',

    'color-mandy': '#fd646c',
    'color-mandy-light': '#FF7E86',
    'color-mandy-dark': '#E44B53',
    'color-mandy-dark-half-opacity': 'rgba(228, 75, 83, 0.5)',
    'color-mandy-lighter': '#fd646c',

    'color-pink': '#d96ac7',
    'color-pink-half-opacity': 'rgba(217, 106, 199, 0.5)',

    'color-rouge': '#b2142b',
    'color-azure': '#00a1f1',
    'color-dodger-blue': '#4285f4',
    'color-dodger-blue-dark': '#3e81f4',
    'color-dark-sky-blue': '#4990e2',
    'color-dark-sky-blue-light': '#5f9de6',
    'color-dark-sky-blue-light-half-opacity': 'rgba(95, 157, 230, 0.5)',
    'color-dark-sky-blue-dark': '#3383de',
    'color-ocean-blue': '#0077b5',
    'color-denim-blue': '#3b5998',

    'color-purple': '#8D54F5',
    'color-purple-half-opacity': 'rgba(141, 84, 245, 0.5)',

    'color-mischka': '#ccd1de',
    'color-warm-grey': '#a4aab8',
    'color-warm-grey-light': '#BEC4D2',
    'color-warm-grey-dark': '#8B919F',
    'color-boulder': '#83858a',

    'color-iron': '#d6d9de',
    'color-athens-grey': '#fafafb',
    'color-athens-gray-dark': '#f4f5f7',
    'color-charcoal-grey': '#4a4d54',
    'color-slate': '#49556f',
    'color-slate-light': '#515e75',
    'color-charcoal-dark': '#363d45',

    'base-border-radius': '3px',
    'base-text-color': '#4f5259',
    'base-font-size': '15px',
    'base-font-weight': '300',
    'base-font-weight-bold': '500',
    'base-font-weight-thin': '100',
    'base-font-family': '\'Roboto\', \'Helvetica\', \'Segoe UI\', Tahoma, Geneva, Verdana, sans-serif',
    'base-icon-font-family': 'Font Awesome 5 Pro',

    'nav-header-height': '60px',
    'nav-header-zindex': '56',
    'dashboard-column-zindex': '50',

    'nav-sidebar-width': '250px',
    'nav-sidebar-zindex': '57',

    'nav-right-sidebar-width': '240px',
    'nav-right-sidebar-preview-size': '60px',
    'nav-right-sidebar-zindex': '55',

    'transition-duration': '.15s',
    'transition-easing': 'ease-in-out',

    'icon-size': '12px',

    'grid-column-width': '60px',
    'grid-gutter-width': '20px',

    'avatar-default-size': '40px',

    'modal-zindex': '60',

    'modal-overlay-opacity': '.6',
};

const colorTheme = base['color-black'];
base['color-accent'] = '#6772DE';

module.exports = Object.assign({
    /* Color by Type */
    'color-default': base['color-warm-grey'],
    'color-default-active': base['color-warm-grey-dark'],
    'color-neutral': base['color-warm-grey'],
    'color-neutral-active': base['color-warm-grey-dark'],
    'color-success': base['color-nasty-green'],
    'color-success-active': base['color-nasty-green-dark'],
    'color-info': base['color-dark-sky-blue'],
    'color-info-active': base['color-dark-sky-blue-dark'],
    'color-danger': base['color-mandy-lighter'],
    'color-danger-active': base['color-mandy-dark'],
    'color-warning': base['color-light-mustard'],
    'color-warning-active': base['color-light-mustard-dark'],
    'color-accent': '#6772DE',
    'color-accent-light': '#a2adff',
    'color-accent-lighter': '#dee9ff',
    'color-accent-dark': '#2c37a3',
    'color-accent-darker': '#000067',
    'color-theme': colorTheme,
    'content-bg-color': '#fcfcfc',

    'nav-header-bg-color': colorTheme,
    // 'nav-header-accent-color': colorAccent,
    'nav-header-secondary-bg-color': base['color-charcoal-grey'],
    'nav-header-text-color': base['color-white'],
    'nav-sidebar-bg-color': base['color-slate'],
    'nav-sidebar-text-color': base['color-iron'],
    // 'nav-sidebar-active-color': colorAccent,
    'nav-right-sidebar-bg-color': base['color-slate'],
    'nav-right-sidebar-text-color': base['color-white'],
    'icon-color': base['color-warm-grey'],
    'input-font-family': base['base-font-family'],
    'input-font-weight': base['base-font-weight'],
    'input-border-color': base['color-iron'],
    'input-border-radius': base['base-border-radius'],
    // 'input-focus-border-color': colorAccent,
    'button-border-radius': base['base-border-radius'],
    // 'avatar-default-border-color': colorAccent,
    // 'avatar-default-tint-color': colorAccent,
    'autocomplete-option-text-color': base['color-charcoal-grey'],
    'autocomplete-options-bg-color': base['color-iron'],
    'modal-overlay-bg-color': base['color-charcoal-grey'],

    'light-theme-nav-header-bg-color': base['color-white'],
    'light-theme-nav-header-secondary-bg-color': base['color-iron'],
    'light-theme-nav-header-text-color': base['color-warm-grey'],
    'light-theme-nav-sidebar-bg-color': base['color-white'],
    'light-theme-nav-sidebar-active-color': base['color-accent'],
    'light-theme-nav-right-sidebar-bg-color': base['color-white'],
    'light-theme-nav-right-sidebar-text-color': '#9b9b9b',

    'grid-gutter-halfwidth': '10px',
    'work-center-entity-height': '24px',
    'work-center-entity-vertical-offset': '2px',
}, base);
