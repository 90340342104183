import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pull } from 'lodash';
import ButtonGroupSelectorContainer from './index';

import styles from './styles.module.css';

class ButtonGroupSelectorCheckbox extends Component {
    handleChange = (event) => {
        const { input } = this.props;
        const { name, checked } = event.target;
        const nextValue = [...input.value];

        if (!checked) {
            pull(nextValue, name);
        } else {
            nextValue.push(name);
        }

        input.onChange(nextValue);
    }

    renderOption = (option, index) => {
        const { input, renderItem, containerClassName, } = this.props;
        const id = `${option.value}-${index}`;
        const checked = input.value.includes(option.value);

        return (
            <ButtonGroupSelectorContainer
                key={option.value}
                htmlFor={id}
                active={checked}
                icon={option.icon}
                option={option}
                renderItem={renderItem}
                containerClassName={containerClassName}
            >
                <input
                    id={id}
                    type="checkbox"
                    checked={checked}
                    name={option.value}
                    onChange={this.handleChange}
                />
            </ButtonGroupSelectorContainer>
        );
    }

    render() {
        const { options } = this.props;

        return (
            <div className={styles.buttonGroupSelector}>
                {options.map((option, index) => this.renderOption(option, index))}
            </div>
        );
    }
}

ButtonGroupSelectorCheckbox.defaultProps = {
    renderItem: item => item,
    containerClassName: undefined,
};

ButtonGroupSelectorCheckbox.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            value: PropTypes.string
        }),
    ).isRequired,
    input: PropTypes.shape({
        value: PropTypes.array,
        onChange: PropTypes.func,
    }).isRequired,
    renderItem: PropTypes.func,
    containerClassName: PropTypes.string,
};

export default ButtonGroupSelectorCheckbox;
