import u from 'updeep';
import { sortBy } from 'lodash';
import { Collection } from '../../../shared/entities-v2';

class WorkCenterCustomFilterCollection extends Collection {
    parse = (response) => {
        const nextData = sortBy(response.data, ['title']);
        return u.updateIn('data', nextData, response);
    }
}

const workCenterCustomFilterCollection = new WorkCenterCustomFilterCollection({
    apiUrl: '/work_center/custom_filters',
    name: 'work-center-custom-filter-collection',
});

export default workCenterCustomFilterCollection;
