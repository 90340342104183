import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, isArray } from 'lodash';
import queryString from 'query-string';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { selectCurrentLocation } from '../../selectors/routing';
import { updateGetParameter } from '../../utils/url';
import { noop } from '../../utils/common';

class UrlDataBinder extends Component {
    constructor(props) {
        super(props);

        const { parameterName, value, location } = props;
        const parameterValues = isArray(parameterName)
            ? { ...(value || {}), ...this.getQueryValue(location.search, parameterName) }
            : this.getQueryValue(location.search, parameterName) || value;

        this.state = {
            value: parameterName
                ? parameterValues
                : value
        };
    }

    componentDidMount() {
        if (this.state.value) {
            this.props.onInitialValueParsed(this.state.value);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            !nextProps.parameterName
            || isEqual(nextProps.value, this.state.value)
        ) {
            return;
        }

        this.setState({
            value: nextProps.value
        });
        this.setQueryValue(
            nextProps.parameterName,
            nextProps.value
        );
    }

    componentDidUpdate(prevProps) {
        const { location, parameterName, onValueParsed } = this.props;
        if (prevProps.location.search.trimEnd() !== location.search.trimEnd()) {
            const value = this.getQueryValue(location.search, parameterName) || '';
            if (value) onValueParsed(value);
        }
    }

    setQueryValue = (parameterName, value) => {
        const { location, resetPage } = this.props;
        let newHref = location.pathname + location.search;
        const curHref = newHref;

        if (Array.isArray(parameterName)) {
            parameterName.forEach((param) => {
                newHref = updateGetParameter(newHref, param, value[param]);
            });
        } else {
            newHref = updateGetParameter(newHref, parameterName, value);
        }

        if (resetPage && location.query.page && location.query.page !== '1') {
            newHref = updateGetParameter(newHref, 'page', '1');
        }

        if (newHref !== curHref) {
            this.props.replace(newHref);
        }
    }

    getQueryValue = (searchString, parameterName) => {
        const { parameterParse } = this.props;
        const parameters = queryString.parse(searchString);

        if (Array.isArray(parameterName)) {
            return parameterName.reduce((result, pr) => {
                const value = parameters[pr];

                if (value) {
                    result[pr] = parameterParse(pr, value);
                }
                return result;
            }, {});
        }

        return parameters[parameterName];
    }


    render() {
        const { children } = this.props;

        if (children) {
            return children(this.state.value);
        }

        return null;
    }
}

UrlDataBinder.defaultProps = {
    value: undefined,
    children: undefined,
    resetPage: false,
    onInitialValueParsed: noop,
    onValueParsed: noop,
    parameterParse(value) { return value; },
};

UrlDataBinder.propTypes = {
    parameterName: PropTypes.oneOfType(
        [PropTypes.string, PropTypes.object, PropTypes.array]
    ).isRequired,
    onInitialValueParsed: PropTypes.func,
    onValueParsed: PropTypes.func,
    children: PropTypes.func,
    value: PropTypes.any,
    location: PropTypes.object.isRequired,
    replace: PropTypes.func.isRequired,
    parameterParse: PropTypes.func,
    resetPage: PropTypes.bool
};

export default connect(
    state => ({
        location: selectCurrentLocation(state),
    }),
    { replace }
)(UrlDataBinder);
