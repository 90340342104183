import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const styleTypes = Object.freeze({
    success: 'success',
    error: 'error'
});

export default function Panel({
    children, className, styleType, ...rest
}) {
    return (
        <div
            className={classnames('panel', {
                [`panel-${styleType}`]: !!styleType,
                [className]: !!className,
            })}
            {...rest}
        >
            {children}
        </div>
    );
}

Panel.styleTypes = styleTypes;

Panel.defaultProps = {
    children: undefined,
    className: undefined,
    styleType: undefined,
};

Panel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    styleType: PropTypes.string,
};
