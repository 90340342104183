import * as localStorageUtils from '../../shared/utils/local-storage';

const loggerLocalStorageKey = 'push-log-enabled';

function logger(type, ...args) {
    if (localStorageUtils.get(loggerLocalStorageKey) === '1') {
        // eslint-disable-next-line no-console
        console[type](...args);
    }
}

export function logWarning(...args) {
    return logger('warn', ...args);
}

export function logError(...args) {
    return logger('error', ...args);
}
