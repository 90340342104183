import apiService from '../../../request';
import { getEntitiesByType } from '../../../shared/utils/content-editor-entity-selector';

const defaultRequestMethod = () => 'post';

export default function createAPI(url, mentionsField = 'description', options) {
    function prepareData(data) {
        const dataToSend = options.format(data);

        const content = dataToSend[mentionsField] || dataToSend.content || '';
        const mentions = getEntitiesByType(content, 'mention');
        const { tags } = dataToSend;

        if (tags) {
            dataToSend.tags = tags.map(item => (
                item.title === item.id
                    ? { title: item.title }
                    : { id: item.id, title: item.title }
            ));
        }

        if (mentions && mentions.length) {
            dataToSend.mentions = mentions;
        }

        return dataToSend;
    }

    return {
        create(data) {
            const method = (options.getRequestMethod || defaultRequestMethod)(data);
            const dataToSend = prepareData(data);
            return apiService[method](url, dataToSend)
                .then(options.parse);
        },
        getTagName(title) {
            return apiService.get(`${url}/tagname`, { params: title });
        },
    };
}
