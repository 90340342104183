import update from 'updeep';
import { createReducer } from 'redux-act';
import * as actions from './actions';

const initialState = {
    visible: false,
    rootWidthPx: 680,
    activeItemId: undefined,
    itemHeightPx: 49,
    items: [
        {
            title: 'Progress Notes',
            id: 'progress-notes',
        },
        {
            title: 'Attachments',
            id: 'attachments',
        },
        {
            title: 'Related Links',
            id: 'related-links',
        },
        {
            title: 'Collaborators',
            id: 'collaborators',
        },
        {
            title: 'History',
            id: 'history',
        }
    ]
};

export default createReducer({
    [actions.toggle]: state => update(
        { visible: !state.visible },
        state
    ),
    [actions.show]: state => update(
        { visible: true },
        state
    ),
    [actions.hide]: state => update(
        { visible: false },
        state
    ),
    [actions.setActive]: (state, { id }) => update(
        { activeItemId: id },
        state
    ),
    [actions.setRootWidth]: (state, { width }) => update(
        { rootWidthPx: width },
        state
    ),
}, initialState);

export function getActiveItemIndex(items = [], activeId) {
    return items.findIndex(item => (item.id === activeId));
}

export function localStorageSlicer(state) {
    return {
        ...initialState,
        rootWidthPx: state.navDrawerRight.rootWidthPx
    };
}
