import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import ColorPicker from './picker';
import './styles.css';

function ColorInput({ input, onChangeCustom, ...rest }) {
    return (
        <ColorPicker
            input={input}
            {...rest}
            onChangeComplete={(data) => {
                input.onChange(data.hex);
                onChangeCustom(data.hex);
                return data;
            }}
            color={input.value}
        />
    );
}

ColorInput.defaultProps = {
    onChangeCustom() {},
};

ColorInput.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    onChangeCustom: PropTypes.func,
};

export default function InputColor(props) {
    return (
        <Field
            {...props}
            component={ColorInput}
            color="#000000"
        />
    );
}
