import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../icon/component';
import styles from './styles.module.css';

export default function StatusBadge({
    children, theme, icon, className
}) {
    return (
        <div className={cx(styles.statusBadge, className, styles[`status-badge-theme-${theme}`])}>
            { icon && (
                <Icon className={styles.statusBadgeIcon} name={icon} />
            )}
            <div className={styles.statusBadgeBody}>{children}</div>
        </div>
    );
}

StatusBadge.themes = {
    danger: 'danger',
    accent: 'accent',
    info: 'info',
};

StatusBadge.defaultProps = {
    className: undefined,
    children: undefined,
    theme: StatusBadge.themes.accent,
    icon: undefined
};

StatusBadge.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    theme: PropTypes.string,
    icon: PropTypes.string
};
