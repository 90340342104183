/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';
import { formValueSelector, } from 'redux-form';
import { get, } from 'lodash';
import createFormModal from '../../../../../shared/modules/create-view/component';
import {
    GridContainer, Row, Button, Col,
} from '../../../../../shared/components';
import { csfConnectionCreateModel } from '../csf-connection-models';
import { types, attributeNames as attrNames } from '../../related-link-constants';
import { CsfRelatedLinkForm } from '../../../../primary-entities/critical-success-factor/csf-related-link';

import styles from './csf-connection-create-modal.module.css';

function DriverConnectionCreateFooter({ toggle, submitting }) {
    return (
        <GridContainer>
            <Row>
                <Col className={styles.createCsfControls} base={12}>
                    <Button
                        className={styles.createCsfControl}
                        onClick={toggle}
                        type="button"
                        block
                        styleType="default"
                        outline
                    >
                        Cancel
                    </Button>
                    <Button
                        className={styles.createCsfControl}
                        styleType="success"
                        type="submit"
                        disabled={submitting}
                        loading={submitting}
                        block
                    >
                        Connect
                    </Button>
                </Col>
            </Row>
        </GridContainer>
    );
}

DriverConnectionCreateFooter.propTypes = {
    toggle: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

const {
    actions, selector
} = csfConnectionCreateModel;


const formName = 'related-links-create-csf';
const formSelector = formValueSelector(formName);

const formModal = createFormModal({
    actions,
    selector,
    formName,
    errorObjectGetter: error => get(error, ['response', 'data', 'message']),
    parseFormSubmitError: message => (Array.isArray(message) ? { 'internal_link': [...message] } : { 'internal_link': [message] }),
    ControlsComponent: DriverConnectionCreateFooter,
    modalClassName: styles.createCsfModal,
    enableReinitialize: true,
    modalFormContainerClassName: styles.createCsfModalFormContainer,
    mapStateToProps(state, ownProps) {
        return {
            title: 'Connect to a Critical Success Factor',
            draftAvailable: false,
            currentValues: {
                [attrNames.primaryLinkType]: formSelector(state, attrNames.primaryLinkType) || ownProps.parentType,
                [attrNames.primaryLinkId]: formSelector(state, attrNames.primaryLinkId) || ownProps.parentId,
                [attrNames.internalLink]: formSelector(state, attrNames.internalLink) || [],
            },
            initialValues: {
                [attrNames.type]: types.internal,
                [attrNames.relatedLinkId]: undefined,
                [attrNames.relatedLinkType]: undefined,
                [attrNames.primaryLinkId]: ownProps.parentId,
                [attrNames.primaryLinkType]: ownProps.parentType,
                [attrNames.internalLink]: [],
            },
            creationDisabled: ownProps.disabled,
            placeholder: 'Select Critical Success Factor...',
            exclude: 'milestones,plans_actions,segments',
        };
    }
})(CsfRelatedLinkForm);

export default formModal;
