import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Text, Tooltip } from '../../../../shared/components';
import { EntityTitle } from '../../../../shared/modules';
import { SubscriptionLimitProviders } from '../../../subscription';
import { getEntityStatus } from '../../../../shared/utils/entity';
import { entityTypes } from '../related-link-constants';
import CsfConnectionList from './list/csf-connection-list';
import CsfConnectionCreate from './create/csf-connection-create-modal';

import './csf-connection-section.css';

export default class CsfConnectionSection extends Component {
    renderWithSubscription = (limits) => {
        const {
            parentId,
            parentType,
            className,
            disabled,
            permissions,
            parentState,
            canCreateCSF
        } = this.props;

        if (!limits.value) {
            return null;
        }

        const driverConnectionTooltipText = !canCreateCSF
            ? 'This action is not available for your role.'
            : `This action is not available for ${getEntityStatus(parentState)} entities.`;

        return (
            <div className={cx('driver-connection-section', className)}>
                <Text className="driver-connection-section-title" component="h2" size="xlarge" styleType="dark-light" bold>
                    <Tooltip text={driverConnectionTooltipText} disabled={!disabled}>
                        <CsfConnectionCreate
                            disabled={disabled}
                            parentId={parentId}
                            parentType={parentType}
                        />
                    </Tooltip>
                    <EntityTitle type={entityTypes.driver} /><span>&nbsp;Connection(s)</span>
                </Text>

                <CsfConnectionList
                    parentId={parentId}
                    parentType={parentType}
                    permissions={permissions}
                />
            </div>
        );
    }

    render() {
        return (
            <SubscriptionLimitProviders.CSF>
                {limits => this.renderWithSubscription(limits)}
            </SubscriptionLimitProviders.CSF>
        );
    }
}

CsfConnectionSection.defaultProps = {
    className: undefined,
    disabled: false,
    canCreateCSF: false,
};

CsfConnectionSection.propTypes = {
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    permissions: PropTypes.object.isRequired,
    parentState: PropTypes.number.isRequired,
    canCreateCSF: PropTypes.bool,
};
