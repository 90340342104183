import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CloneKPIModal from '../../../modules/goalmap-milestones/clone/container';
import CloneSegmentModal from '../../../modules/goalmap-strategy-maps/copy/index';
import CloneStrategyModal from '../../../modules/goalmap-segments/copy/index';
import CloneTacticModal from '../../../modules/goalmap-plans-actions/copy/index';
import CloneCSFModal from '../../../modules/primary-entities/critical-success-factor/copy/csf-copy-modal';

import { selectInitialValues, selectPlanId } from './primary-entity-clone-service-selectors';

const PrimaryEntityCloneService = ({ onCreateSuccess }) => {
    const initialValues = useSelector(selectInitialValues);
    const planId = useSelector(selectPlanId);

    return (
        <>
            <CloneKPIModal
                title="Copy Entity"
                data={initialValues}
                goalId={planId}
                onCreateSuccess={onCreateSuccess}
                controlHidden
            />

            <CloneSegmentModal
                title="Copy Entity"
                data={initialValues}
                onCreateSuccess={onCreateSuccess}
                goalId={planId}
                controlHidden
                withWizardHeader
            />

            <CloneStrategyModal
                title="Copy Entity"
                data={initialValues}
                onCreateSuccess={onCreateSuccess}
                goalId={planId}
                controlHidden
                withWizardHeader
            />

            <CloneTacticModal
                title="Copy Entity"
                data={initialValues}
                onCreateSuccess={onCreateSuccess}
                goalId={planId}
                controlHidden
                withWizardHeader
            />

            <CloneCSFModal
                title="Copy Entity"
                data={initialValues}
                onCreateSuccess={onCreateSuccess}
                goalId={planId}
                controlHidden
            />
        </>
    );
};

PrimaryEntityCloneService.propTypes = {
    onCreateSuccess: PropTypes.func.isRequired,
};

export default PrimaryEntityCloneService;
