import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';

export default function createAsyncSaga(actions, request) {
    return function* asyncSaga({ payload = {} }) {
        const {
            data = {},
            options = {},
        } = payload;

        const {
            resolve,
            reject,
            onSuccess,
            onError,
        } = options;

        try {
            // TO DO: remove `data.params`, it is deprecated and used inside of `Form` component
            const response = yield call(request, get(data, 'params', data));

            if (resolve) { resolve(response); }

            yield put(actions.success({
                response,
                params: data,
                options,
            }));

            if (onSuccess) { onSuccess(response); }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error in promise', err);

            if (reject) { reject(err); }

            yield put(actions.error({
                response: err,
                params: data,
                options,
            }));

            if (onError) { onError(err); }
        }
    };
}
