import { takeLatest } from 'redux-saga/effects';
import createAsyncSaga from '../base/async-saga';

export default function createSaga(actions, api) {
    const getList = createAsyncSaga({
        success: actions.fetch.success,
        error: actions.fetch.error,
    }, api);

    const getListLazy = createAsyncSaga({
        success: actions.fetchLazy.success,
        error: actions.fetchLazy.error,
    }, api);

    return function* listSaga() {
        yield takeLatest(actions.fetch.request, getList);
        yield takeLatest(actions.fetchLazy.request, getListLazy);
    };
}
