/* eslint-disable max-len */
export const frequencies = Object.freeze({
    monthly: 'monthly',
    weekly: 'weekly',
    quarterly: 'quarterly',
    annually: 'annually',
});

export const frequencyToMeasurement = Object.freeze({
    [frequencies.monthly]: 'month',
    [frequencies.weekly]: 'week',
    [frequencies.quarterly]: 'quarter',
    [frequencies.annually]: 'year',
});

export const types = Object.freeze({
    numeric: 'numerical',
    monetary: 'monetary',
    percentage: 'percentage',
});

export const typesLabels = Object.freeze({
    numerical: '# Numeric',
    monetary: 'Currency',
    percentage: '% Percentage',
});

export const requiredFrom = Object.freeze({
    firstDay: 'first',
    lastDay: 'last',
});

export const states = Object.freeze({
    active: 1,
    archived: 3,
    draft: 4,
    inactive: 5
});

export const statesToString = Object.freeze({
    [states.draft]: 'draft',
    [states.active]: 'active',
    [states.inactive]: 'inactive',
    [states.archived]: 'archived',
});

export const stateTitles = Object.freeze({
    [states.draft]: 'Draft',
    [states.active]: 'Active',
    [states.inactive]: 'Inactive',
    [states.archived]: 'Archived',
});

export const entityType = 'milestone';

export const rangeOfMeasure = Object.freeze({
    asc: 'asc',
    desc: 'desc',
    between: 'between'
});

export const rangeOfMeasureTitles = Object.freeze({
    [rangeOfMeasure.asc]: 'Ascending',
    [rangeOfMeasure.desc]: 'Descending',
    [rangeOfMeasure.between]: 'Between',
});

export const attributes = Object.freeze({
    id: 'id',
    thresholds: 'thresholds',
    type: 'type',
    frequency: 'frequency',
    state: 'state',
    description: 'description',
    tags: 'tags',
    progress_bar: 'progress_bar',
    progress_status: 'progress_status',
    author_id: 'author_id',
    goal_id: 'goal_id',
    target_at: 'target_at',
    owner_id: 'owner_id',
    bound_left: 'bound_left',
    threshold_left: 'threshold_left',
    threshold_right: 'threshold_right',
    bound_right: 'bound_right',
    target_value: 'target_value',
    started_at: 'started_at',
    required_from: 'required_from',
    title: 'title',
    tag_name: 'tag_name',
    data_points_number: 'data_points_number',
    kpi_type: 'kpi_type',
    range_of_measure: 'range_of_measure',
    health_tolerance_threshold: 'health_tolerance_threshold',
    health_tolerance_bound: 'health_tolerance_bound',
    kpi_period_targets: 'kpi_period_targets',
    health_tolerance_value: 'health_tolerance_value',
    data_points: 'data_points',
    owner: 'owner',
    frequency_current_period: 'frequency_current_period',
    is_need_updated: 'is_need_updated',
    is_past_due: 'is_past_due',
    current_data_point_value: 'current_data_point_value',
    target_period: 'target_period',
    permissions: 'permissions',
    start_value: 'start_value',
    kpi_default_chart_type: 'kpi_default_chart_type',
    kpi_charts: 'kpi_charts',
    advanced_calculation_type: 'advanced_calculation_operation',
    advanced_calculation_sources: 'advanced_calculation_sources',
    advanced_calculation_enabled: 'advanced_calculation_enabled',
    parent_entity_id: 'parent_entity_id',
    parent_entity_type: 'parent_entity_type',
    updated_at: 'updated_at',
    entity_type: 'entity_type',
    currency_format: 'currency_format',
    target_date: 'target_date',
    type_code: 'type_code',
});

export const periodTargetAttributes = Object.freeze({
    title: 'title',
    period_end: 'period_end',
    period_start: 'period_start',
    target_value: 'target_value',
    overall_progress: 'overall_progress',
    target_progress: 'target_progress',
    overall_target_value: 'overall_target_value',
    health_tolerance_bound: 'health_tolerance_bound',
    health_tolerance_threshold: 'health_tolerance_threshold',
    health_tolerance_bound_mirror: 'health_tolerance_bound_mirror',
    health_tolerance_threshold_mirror: 'health_tolerance_threshold_mirror',
});

export const dataPointAttributes = Object.freeze({
    period_actual: 'period_actual',
    period_target: 'period_target',
    period_start: 'period_start',
    value: 'value',
    overall_progress: 'overall_progress'
});

export const kpiTypes = Object.freeze({
    static: {
        type: 'static',
        label: 'Static',
        description: `Working towards the same target during each period of measure.
        Example: I want to sell 100 units every month.`
    },
    linear: {
        type: 'linear',
        label: 'Linear',
        description: `
            Working towards an end target over time.
            Example: I want to sell 1000 units this year and see the progress each month towards 1000 units.
        `
    },
    stepped: {
        type: 'stepped',
        label: 'Stepped',
        description: `
            Similar to Static but you can have unique targets during each period of measure.
            Example: I want to sell 100 units in February but in March I want to sell 150 units.
        `
    },
});

export const chartTypes = Object.freeze({
    linear: 'linear',
    bar: 'bar',
    mixed: 'mixed',
    data_sources: 'data_sources'
});

export const chartTimelineType = Object.freeze({
    spline: 'spline',
    column: 'column',
    plotLine: 'plot_line'
});

export const thresholdLabels = {
    healthTolerance: 'Health Tolerance',
    overallTargetProgress: 'Overall Target Progress',
    periodTarget: 'Period Target',
    targetValue: 'Target Value',
};

export const chartConfigAttributes = Object.freeze({
    name: 'name',
    type: 'type',
    is_available: 'is_available',
    is_default: 'is_default'
});

export const operations = Object.freeze({
    sum: 'sum',
    difference: 'difference',
    average: 'average',
    quotient: 'quotient',
    quotientPercentage: 'quotient_percentage',
    product: 'product',
});

export const labelsForOperations = Object.freeze({
    [operations.sum]: 'Sum',
    [operations.difference]: 'Difference',
    [operations.average]: 'Average',
    [operations.quotient]: 'Quotient',
    [operations.quotientPercentage]: 'Quotient Percentage',
    [operations.product]: 'Product',
});

export const dataSourceAttributes = Object.freeze({
    id: 'id',
    label: 'label',
    type: 'type',
    related_entity_id: 'related_entity_id',
    related_entity: 'related_entity',
});

// export const dataSourceTypes = Object.freeze({
//     kpi: 'kpi',
//     manual: 'manual',
// });

export const requiredFromValues = Object.freeze({
    first: 'first',
    last: 'last',
});

export const operationsData = [
    {
        title: 'SUM = A+B+C...',
        id: operations.sum,
        maxLength: 6,
        useCap: true
    },
    {
        title: 'DIFFERENCE = A - B',
        id: operations.difference,
        maxLength: 2,
        useCap: true
    },
    {
        title: 'AVERAGE = (A+B+C...) / n',
        id: operations.average,
        maxLength: 6,
        useCap: true
    },
    {
        title: 'QUOTIENT = A / B',
        id: operations.quotient,
        maxLength: 2,
        useCap: true
    },
    {
        title: 'QUOTIENT2 = (A / B) * 100%',
        id: operations.quotientPercentage,
        maxLength: 2,
        useCap: true
    },
    {
        title: 'PRODUCT = A * B',
        id: operations.product,
        maxLength: 2,
        useCap: true
    },
];

export const locations = Object.freeze({
    global: 'global',
    plan: 'plan',
});

export const chartStep = 1;
