import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { find, get, without } from 'lodash';
import classnames from 'classnames';
import {
    TableView, SelectUser, Button, Clickable, Icon, Text
} from '../../components';
import { useApiGet } from '../../hooks/useApiGet';
import usePrevious from '../../hooks/use-previous-state';
import { keysToCamelCase } from '../../utils/object';
import { Select } from '..';
import {
    usersApi, roleOptions, restrictedRoleOptions, roles
} from './team-section-constants';
import styles from './team-section-modal.module.css';

const mapUserToOption = user => ({
    value: user.id,
    first_name: user.userProfile.firstName,
    last_name: user.userProfile.lastName,
    tag_name: user.userProfile.tagName,
    image: user.userProfile.image,
    title: user.userProfile.fullName,
});

const parseUserOptions = (data = []) => {
    return data
        .map(keysToCamelCase)
        .map(mapUserToOption);
};

const getFilteredOptions = (options, currentOption, selectedOptions) => {
    const filteredSelectedOptions = without(selectedOptions, currentOption.userId);
    return options.filter(option => !filteredSelectedOptions.includes(option.value));
};

const isFullValue = (value = {}) => !!value.userId && !!value.teamRoleId;

const TeamSectionMembers = ({
    fields, errors, restrictedUpdates, persistedTeamValue, onCreate
}) => {
    const api = useApiGet(usersApi.endpoint, usersApi.params);
    const values = fields.getAll();
    const prevValues = usePrevious(values);
    const selectedUsers = values.map(value => value.userId);

    const userOptions = useMemo(() => {
        return api.fetching ? [] : parseUserOptions(api.data.data);
    }, [api.data, api.fetching]);

    const createRow = useCallback(() => {
        fields.push({});
    }, [fields]);

    useEffect(() => {
        if (values.length > prevValues?.length) {
            onCreate();
        }
    }, [values, onCreate]);

    useEffect(() => {
        const lastRecord = values[values.length - 1];
        const prevLastRecord = prevValues?.[prevValues.length - 1];
        const recordsExist = lastRecord && prevLastRecord && values.length === prevValues.length;
        const valueCompleted = isFullValue(lastRecord) && !isFullValue(prevLastRecord);

        if (recordsExist && valueCompleted) {
            createRow();
        }
    }, [values, createRow]);

    useEffect(() => {
        values.forEach((value, index) => {
            if (!!value.userId && !value.teamRoleId) {
                fields.remove(index);
                fields.insert(index, {
                    userId: value.userId,
                    teamRoleId: roles.member,
                });
            }
        });
    }, [values, fields]);

    const renderErrors = (arr = []) => (
        arr.map(error => (
            <Text key={error} styleType="danger" className={styles.error}>
                {error}
            </Text>
        ))
    );

    const renderRow = (member, index) => {
        const value = fields.get(index);
        const filteredUserOptions = getFilteredOptions(userOptions, value, selectedUsers);
        const isPersisted = !!find(persistedTeamValue, { userId: value.userId });
        const userError = get(errors, `teamUsers.${index}.userId`);
        const roleError = get(errors, `teamUsers.${index}.teamRoleId`);

        return (
            <TableView.Row key={index}>
                <TableView.Cell
                    overflowVisible
                    align={TableView.align.LEFT}
                    className={classnames(styles.selectCell, styles.column)}
                >
                    <Field
                        name={`${member}.userId`}
                        component={SelectUser}
                        disabled={restrictedUpdates && isPersisted}
                        props={{
                            clearable: false,
                            options: filteredUserOptions,
                            valueKey: 'value',
                            containerClassName: styles.select,
                            sticky: true,
                        }}
                    />

                    {renderErrors(userError)}
                </TableView.Cell>
                <TableView.Cell
                    overflowVisible
                    align={TableView.align.LEFT}
                    className={classnames(styles.selectCell, styles.column)}
                >
                    <Field
                        name={`${member}.teamRoleId`}
                        component={Select}
                        disabled={restrictedUpdates && isPersisted}
                        props={{
                            clearable: false,
                            options: restrictedUpdates && !isPersisted
                                ? restrictedRoleOptions
                                : roleOptions,
                            valueKey: 'value',
                            labelKey: 'label',
                            containerClassName: styles.select,
                            sticky: true
                        }}
                    />

                    {renderErrors(roleError)}
                </TableView.Cell>
                <TableView.Cell
                    overflowVisible
                    className={styles.columnActions}
                >
                    <Clickable
                        action="remove-team"
                        onClick={() => fields.remove(index)}
                        disabled={restrictedUpdates && isPersisted}
                        withDisabledStyle
                    >
                        <Icon name="trash-alt" className={styles.actionIcon} />
                    </Clickable>
                </TableView.Cell>
            </TableView.Row>
        );
    };

    return (
        <div className={styles.teamSectionModalMembers}>
            <h3 className={styles.title}>
                <Button
                    type="button"
                    styleType="accent"
                    round
                    withShadow
                    size={Button.sizes.small}
                    onClick={createRow}
                    className={styles.createMember}
                >
                    <Icon name="plus" />
                </Button>
                Team Members
            </h3>

            {renderErrors(errors.teamUsers)}

            <TableView noResults={!fields.length && !api.fetching} isLoading={api.fetching}>
                <TableView.Header>
                    <TableView.HeaderCell
                        align={TableView.align.LEFT}
                        className={styles.column}
                    >
                        User
                    </TableView.HeaderCell>
                    <TableView.HeaderCell
                        align={TableView.align.LEFT}
                        className={styles.column}
                    >
                        Role
                    </TableView.HeaderCell>
                    <TableView.HeaderCell
                        className={styles.columnActions}
                    >
                        Delete
                    </TableView.HeaderCell>
                </TableView.Header>

                <TableView.Body>
                    {!api.fetching && fields.map(renderRow)}
                </TableView.Body>
            </TableView>
        </div>
    );
};

TeamSectionMembers.defaultProps = {
    errors: {}
};

TeamSectionMembers.propTypes = {
    fields: PropTypes.array.isRequired,
    errors: PropTypes.object,
    restrictedUpdates: PropTypes.bool.isRequired,
    persistedTeamValue: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default TeamSectionMembers;
