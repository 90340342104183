import { createModal, createDetails } from '../../../../shared/entities';
import { apiUrl } from '../related-link-constants';
import apiCreate from '../related-link-api-create';

export const csfConnectionCreateModel = createModal({
    apiUrl,
    name: 'csf-connection-create-model',
    extendAPI: {
        create: apiCreate,
    }
});

export const csfConnectionModel = createDetails({
    apiUrl,
    name: 'csf-connection-model',
});
