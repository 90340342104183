import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../icon/component';
import styles from './styles.module.css';

export default function WizardFormInfo({
    description, icon, label, innerContainerClassName
}) {
    return (
        <div className={cx(styles.wizardFormInfo)}>
            <div
                className={cx(styles.wizardFormInfoInner, 'container', {
                    [innerContainerClassName]: !!innerContainerClassName,
                })}
            >
                <div className={styles.wizardFormInfoLeftPart}>
                    <Icon className={styles.wizardFormInfoIcon} name={icon} />
                    <div className={styles.wizardFormInfoLabel}>
                        {label}
                    </div>
                </div>
                <div className={styles.wizardFormInfoRightPart}>
                    <div className={styles.wizardFormInfoDescription}>
                        {description}
                    </div>
                </div>
            </div>
        </div>
    );
}

WizardFormInfo.defaultProps = {
    innerContainerClassName: undefined,
    label: undefined
};

WizardFormInfo.propTypes = {
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string,
    innerContainerClassName: PropTypes.string,
};
