import { createSelector } from 'reselect';
import queryString from 'query-string';

function getState(state) {
    return state.routing.locationBeforeTransitions;
}

function getSearchQuery(data) {
    const searchString = data.search;

    try {
        return queryString.parse(searchString);
    } catch (error) {
        return {};
    }
}

export const selectCurrentLocation = createSelector(
    getState,
    state => state,
);

export const selectCurrentPathname = createSelector(
    getState,
    state => state.pathname,
);

export const selectHash = createSelector(
    getState,
    state => state.hash
);

export const selectSearchString = createSelector(
    getState,
    state => state.search
);

export const selectSearchQuery = createSelector(getState, getSearchQuery);
