import { Collection } from '../../shared/entities-v2';
import { stateBranchName } from './subscription-config';
import * as actions from './subscription-actions';
import * as reducers from './subscription-limit-reducers';

class SubscriptionLimitCollection extends Collection {
    // eslint-disable-next-line class-methods-use-this
    parse(response) {
        return { data: response };
    }
}

const subscriptionLimitCollection = new SubscriptionLimitCollection({
    name: stateBranchName,
    apiUrl: '/fusebills/limits',
    additionalArtifacts: {
        [Collection.additionalArtifacts.reducers]: {
            [actions.incrementCounter]: reducers.incrementCounter,
            [actions.decrementCounter]: reducers.decrementCounter,
        }
    }
});

export default subscriptionLimitCollection;
