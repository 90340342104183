import get from 'lodash/get';
import queryString from 'query-string';
/** @namespace Utils.Url */

/**
 * Checks if link is absolute
 * @function isAbsoluteLink
 * @memberof Utils.Url
 * @param {string} link Checked link
 * @return {boolean} True if link is absolute
 */
export function isAbsoluteLink(link) {
    return /^https?:\/\//i.test(link);
}

/**
 * Checks if url is base64
 * @function isBase64
 * @memberof Utils.Url
 * @param {string} url Checked url
 * @return {boolean} True if link is absolute
 */
export function isBase64(url) {
    // eslint-disable-next-line no-useless-escape
    return /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/i.test(url);
}

/**
 * Checks if there is a specified parameter in the link
 * @function isExistGetParameter
 * @memberof Utils.Url
 * @param {string} url Source url
 * @param {string} param Checked parameter
 * @return {boolean} True if parameter is available in the url
 */
export function isExistGetParameter(url, param) {
    const [searchStr] = url.split('?');
    const queryGroups = searchStr ? searchStr.split('&') : [];

    let res = false;

    Object.keys(queryGroups).map((objKey) => {
        const [key] = queryGroups[objKey].split('=');
        if (key === param) {
            res = true;
        }

        return null;
    });

    return res;
}

/**
 * Updates get parameter in an url
 * @function isExistGetParameter
 * @memberof Utils.Url
 * @param {string} url Source url
 * @param {string} param Updated parameter name
 * @param {string} value Updated parameter value
 * @return {string} url string with updated get paramenter
 */
export function updateGetParameter(url, param, value) {
    const [origin, searchStr] = url.split('?');
    const queryGroups = searchStr ? searchStr.split('&') : [];
    const newKvp = [];
    let isParamExist = false;

    Object.keys(queryGroups).map((objKey) => {
        const [key, ...paramValue] = queryGroups[objKey].split('=');

        if (key === param) {
            isParamExist = true;
            if (value) {
                newKvp.push(`${param}=${value}`);
            }
        } else {
            newKvp.push(`${key}=${paramValue.join('=')}`);
        }

        return null;
    });

    if (!isParamExist && value) {
        newKvp.push(`${param}=${value}`);
    }

    return `${origin}?${newKvp.join('&')}`;
}

/**
 * Get path name from url
 * @function getPathname
 * @memberof Utils.Url
 * @param {string} path Source url
 * @return {string} Return pathname
 */
export function getPathname(path = '') {
    return path.split('?')[0];
}

/**
 * Concatenate url with get parameters string
 * @function joinGetParameters
 * @memberof Utils.Links
 * @param {string} url Source url
 * @param {string} paramString Source's get parameters
 * @return {string} Return url with get parameters
 */
export function joinGetParameters(url, paramString = '') {
    if (paramString.length) {
        return [url, paramString].join('?');
    }

    return url;
}

/**
 * Checks if get parameters is empty
 * @function isGetParamsEmpty
 * @memberof Utils.Url
 * @param {string} params Get parameters which will be checked
 * @return {boolean} True if get parameters is empty
 */
export function isGetParamsEmpty(params) {
    return !params || params === '';
}

function urlStringContainsGetParams(url) {
    return url.includes('?');
}

function prepareParamsString(params) {
    return params.replace('?', '');
}

/**
 * Concatenate url with get parameters object
 * @function joinGetParams
 * @memberof Utils.Url
 * @param {string} url Source url
 * @param {Object} params Source's get parameters
 * @return {string} Return url with get parameters
 */
export function joinGetParams(url, params) {
    const preparedParams = prepareParamsString(params);
    if (urlStringContainsGetParams(url)) {
        const paramsString = isGetParamsEmpty(preparedParams) ? '' : `&${preparedParams}`;
        return `${url}${paramsString}`;
    }

    const paramsString = isGetParamsEmpty(params) ? '' : `?${preparedParams}`;
    return `${url}${paramsString}`;
}

export function removeParamFromUrl(url, parameter) {
    return url
        .replace(new RegExp(`[?&]${parameter}=[^&#]*(#.*)?$`), '$1')
        .replace(new RegExp(`([?&])${parameter}=[^&]*&`), '$1');
}

/**
 * Concatenate pathname and search
 * @function getLocationHref
 * @memberof Utils.Url
 * @param {Object} location represent where the app is now
 * @return {string} Return url without hostname
 */
export const getLocationHref = (location) => {
    const pathname = get(location, 'pathname', '');
    const search = get(location, 'search', '');

    return `${pathname}${search}`;
};

export const getHrefByLocation = (location, query) => {
    const nextQuery = queryString.stringify({
        ...location.query,
        ...query
    });

    return joinGetParams(location.pathname, nextQuery);
};
