import { eventChannel } from 'redux-saga';
import {
    take,
    call,
    fork,
    race,
    all,
} from 'redux-saga/effects';
import tabex from 'tabex';
import * as authActions from './actions';
import { handleLogout } from './saga';

const syncChannelName = 'sync-auth';
const syncChannelActions = Object.freeze({
    logout: 'logout',
});

function initSyncChannel(sync) {
    return eventChannel((emitter) => {
        function handleEvents(data) {
            emitter(data);
        }

        sync.on(syncChannelName, handleEvents);

        return () => sync.off(syncChannelName, handleEvents);
    });
}

function* watcher(pattern, cb, options) {
    while (true) {
        const action = yield take(pattern);
        yield fork(cb, action, options);
    }
}

function* handleSyncChannelEvent(action) {
    if (action === syncChannelActions.logout) {
        yield call(handleLogout, {});
    }
}

function syncLogout(action, sync) {
    sync.emit(syncChannelName, syncChannelActions.logout);
}


export default function* authenticationTabSyncSaga() {
    const sync = tabex.client();
    const channel = yield call(initSyncChannel, sync);

    yield race({
        task: all([
            call(watcher, channel, handleSyncChannelEvent),
            call(watcher, authActions.logout.getType(), syncLogout, sync),
        ])
    });
}
