/**
 * @typedef {Object} DateRange
 * @memberof WorkCenterTimeline
 * @prop {Date} from - Start date of period
 * @prop {Date} to - End date of period
 */

/**
 * @typedef {'week' | 'month'}
 * @name DateRangeUnit
 * @memberof WorkCenterTimeline
 * @readonly
 */

export const DateRangeUnit = {
    week: 'week',
    month: 'month',
    quarterly: 'quarterly',

};

/**
 * @typedef {Object} TimelineModelFetchParams
 * @memberof WorkCenterTimeline
 * @prop {Object} data
 * @prop {Date} data.startDate - Start date of period
 * @prop {Date} data.endDate - End date of period
 * @prop {Array} data.entityTypeFilter - Entity types to include
 * @prop {Boolean} data.collaboration - Include collaboration entities
 * @prop {String} data.calendarTypeFilter - Filter type
 * @prop {String} [data.quickFilter]
 * @prop {Object} meta
 * @prop {WorkCenterTimeline.DateRangeUnit} meta.dateRangeUnit
 * @prop {boolean} meta.forceApiFetch
 */

/**
 * @typedef {Object} APITimelineModelFetchParams
 * @memberof WorkCenterTimeline
 * @prop {Date} start_date - Start date of period
 * @prop {Date} end_date - End date of period
 * @prop {Array} entity_type_filter - Entity types to include
 * @prop {Boolean} collaboration - Include collaboration entities
 * @prop {String} calendar_type_filter - Filter type
 * @prop {Object} custom_filter_config - Filter config object
 */

export const TimelineModelParamKeys = Object.freeze({
    startDate: 'start_date',
    endDate: 'end_date',
    entityTypeFilter: 'entity_type_filter',
    collaboration: 'collaboration',
    calendarTypeFilter: 'calendar_type_filter',
    customFilterConfig: 'custom_filter_config',
});

/**
 * @typedef {Object} TimelineModelFetchResponse
 * @memberof WorkCenterTimeline
 * @prop {Array} items - Array of items
 * @prop {Array} parents - Array of parent items
 * @prop {Array} owners - Array of owner items
 * @prop {Array} historicalHealth - Array of history items
 */

export const TimelineModelFetchResponse = Object.freeze({
    items: 'items',
    parents: 'parents',
    owners: 'owners',
    historicalHealth: 'historicalHealth',
});

/**
 * @typedef {Array} TimelineTree
 * @memberof WorkCenterTimeline
 */

/**
 * @typedef {Object} TimelineTreeNode
 * @memberof WorkCenterTimeline
 * @prop {Array} children
 */

/**
 * @typedef {Object} TimelineItemPlan
 * @memberof WorkCenterTimeline
 * @prop {number} id
 * @prop {number} typeCode
 * @prop {string} title
 */

/**
 * @typedef {Object} TimelineItemParent
 * @memberof WorkCenterTimeline
 * @prop {number} id
 * @prop {number} typeCode
 * @prop {number} goalId
 * @prop {string} title
 */

/**
 * @typedef {Object} TimelineItemChild
 * @memberof WorkCenterTimeline
 */
