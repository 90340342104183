import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withBreakpoints } from 'react-breakpoints';
import { flow } from 'lodash';
import { setBreakpoint as setBreakpointAction } from '../settings-data/application-settings-actions';

class Breakpoints extends Component {
    constructor({ currentBreakpoint, setBreakpoint }) {
        super();

        setBreakpoint({
            breakpoint: currentBreakpoint,
        });
    }

    componentDidUpdate(prevProps) {
        const { currentBreakpoint, setBreakpoint } = this.props;

        if (prevProps.currentBreakpoint !== currentBreakpoint) {
            setBreakpoint({
                breakpoint: currentBreakpoint,
            });
        }
    }

    render() {
        return null;
    }
}

Breakpoints.propTypes = {
    currentBreakpoint: PropTypes.string.isRequired,
    setBreakpoint: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setBreakpoint: setBreakpointAction,
};

export default flow(
    withBreakpoints,
    connect(null, mapDispatchToProps),
)(Breakpoints);
