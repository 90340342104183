import PropTypes from 'prop-types';
import React from 'react';
import './csf-page-container.css';

export default function CsfPageContainer({ children }) {
    return (
        <div id="goalmap-drivers">
            {children}
        </div>
    );
}

CsfPageContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
