import Loadable from 'react-loadable';
import Loading from '../../routes/components/loadable';
import { createUrl, paths } from '../../routes/rules';
import { steps, stepsTypes } from './constants';

const GoalMapWizard = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ './index'),
    loading: Loading,
});

const GMWizardAddGoalUsers = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ './steps/add-goal-users'),
    loading: Loading,
});

const GMWizardVisionMissionValues = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ './steps/vision-mission-values'),
    loading: Loading,
});

const GMWizardStructure = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ './structure'),
    loading: Loading,
});

const GMWizardBuild = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ './steps/build-plan'),
    loading: Loading,
});

const GMWizardComplete = Loadable({
    loader: () => import(/* webpackChunkName: "goalmap" */ './steps/final'),
    loading: Loading,
});

const goalMapWizardSteps = {
    [stepsTypes.NAME_PLAN]: GMWizardAddGoalUsers,
    [stepsTypes.STRUCTURE]: GMWizardStructure,
    [stepsTypes.VMV]: GMWizardVisionMissionValues,
    [stepsTypes.BUILD_PLAN]: GMWizardBuild,
    [stepsTypes.COMPLETED]: GMWizardComplete,
};

export default {
    path: createUrl(paths.planCreate),
    component: GoalMapWizard,
    indexRoute: {
        component: goalMapWizardSteps[stepsTypes.NAME_PLAN],
    },
    childRoutes: steps.map(step => ({
        path: `${createUrl(paths.planCreate)}/:id/${step.name}`,
        component: goalMapWizardSteps[step.name],
    })),
};
