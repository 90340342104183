import config from '../../config';
import dictionary from './subscription-dictionary';

const br = '%0D%0A';

export default function createMailto({ account, user }) {
    const workPhone = user.workPhone ? `User Work Phone: ${user.workPhone}${br}` : '';
    const mobilePhone = user.mobilePhone ? `User Mobile Phone: ${user.mobilePhone}${br}` : '';

    return `
mailto:${config.supportEmail}
?subject=[${dictionary.supportEmailSubject}]
&body=
${dictionary.supportEmailBody}${br}${br}
Account Name: ${account.name}${br}
Account Number: ${account.id}${br}
User First Name: ${user.firstName || user.first_name}${br}
User Last Name: ${user.lastName || user.last_name}${br}
User Email: ${user.email}${br}
${workPhone}
${mobilePhone}
${br}
    `;
}
