import { takeEvery, put } from 'redux-saga/effects';
import { registerSaga } from '../../../shared/entities/connector';
import { notificationModel, } from '../../notifications/entity';
import * as ToastNotifications from '../../notifications/toast';
import { notificationsHeaderCollection, counterActions } from './entity';

function* saga() {
    yield takeEvery(
        notificationModel.actions.update.request,
        function* statusUpdateStart(action) {
            yield put({
                type: notificationsHeaderCollection.actions.setUpdatingItemId.getType(),
                payload: { id: action.payload.id }
            });
        }
    );

    yield takeEvery(
        notificationModel.actions.update.success,
        function* statusUpdateSuccess({ payload }) {
            yield put({
                type: notificationsHeaderCollection.actions.setUpdatingItemId.getType(),
                payload: { id: undefined }
            });

            yield put({
                type: notificationsHeaderCollection.actions.update.getType(),
                payload,
            });

            yield put({
                type: counterActions.update.getType(),
                payload: {
                    counter: payload?.response?.total_unread,
                },
            });
        }
    );

    yield takeEvery(
        ToastNotifications.create.getType(),
        function* updateCounter({ payload }) {
            if (payload.type === ToastNotifications.types.MESSAGE) {
                yield put({
                    type: counterActions.increment.getType(),
                });
            }
        }
    );

    yield takeEvery(
        notificationModel.actions.markAllNotificationsRead.success.getType(),
        function* updateCounter() {
            yield put({
                type: counterActions.update.getType(),
                payload: {
                    counter: 0,
                },
            });
        }
    );
}

// imported in ./index
registerSaga({
    name: 'nav-header-notifications-list-status-update',
    saga,
});
