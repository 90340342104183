import uniqid from 'uniqid';
import u from 'updeep';
import { find, flow } from 'lodash';

import {
    fields, updateUserFields, statuses, draftPlanId, defaultAccountRoleId
} from './settings-user-assignments-constants';

export const formatUserAssignmentsToAPI = (assignments) => {
    return assignments.map(assignment => ({
        [updateUserFields.planId]: assignment[fields.planId],
        [updateUserFields.roleId]: assignment[fields.roleId],
        [updateUserFields.status]: assignment[fields.status],
    }));
};

export const generateAssignment = () => ({
    [fields.uniqueId]: uniqid(),
    [fields.planId]: undefined,
    [fields.roleId]: undefined,
    [fields.status]: statuses.active,
    [fields.hasActivities]: false,
    [fields.plan]: {},
    [fields.role]: {}
});

export const generateDraftAssignment = (plan = {}) => {
    const planId = plan.id || draftPlanId;

    return {
        [fields.uniqueId]: planId,
        [fields.planId]: planId,
        [fields.roleId]: undefined,
        [fields.status]: statuses.active,
        [fields.hasActivities]: false,
        [fields.plan]: {
            id: planId,
            title: plan.title,
        },
        [fields.role]: {}
    };
};

const formatAssignment = assignment => u({ [fields.uniqueId]: uniqid() }, assignment);
const getPlanRoles = (accountRoles, accountRoleId) => (
    find(accountRoles, { id: accountRoleId })?.goal_roles || getPlanRoles(accountRoles, defaultAccountRoleId)
);
const getPlanRoleId = role => role.id;
const formatPlanRoleOptions = planRoles => planRoles.map(role => ({ id: role.id, title: role.display_name }));
export const isDraftPlanId = assignment => assignment[fields.planId] === draftPlanId;

export const formatAssignmentListResponse = response => response.map(formatAssignment);
export const getFormattedPlanRoles = flow(getPlanRoles, formatPlanRoleOptions);
export const getPlanRoleIds = (accountRoles, accountRoleId) => getPlanRoles(accountRoles, accountRoleId).map(getPlanRoleId);
