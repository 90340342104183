import {
    takeEvery, select, race, take, put
} from 'redux-saga/effects';

export default function createSagas({
    modelActions, collectionActions, collectionSelectors, optimisticUpdates
}) {
    function* handleCreateRequest(action) {
        const { data } = action.payload;

        if (optimisticUpdates) {
            yield put(collectionActions.create(data));

            const [, error] = yield race([
                take(modelActions.create.success),
                take(modelActions.create.error)
            ]);

            if (error) {
                yield put(collectionActions.remove(data));
            }
        } else {
            const [success] = yield race([
                take(modelActions.create.success),
                take(modelActions.create.error)
            ]);

            if (success) {
                yield put(collectionActions.create(success.payload.response));
            }
        }
    }

    function* handleUpdateRequest(action) {
        const { data } = action.payload;

        if (optimisticUpdates) {
            const backup = yield select(state => collectionSelectors.getItemById(state, data.id));
            yield put(collectionActions.update(data));

            const [, error] = yield race([
                take(modelActions.update.success),
                take(modelActions.update.error)
            ]);

            if (error && backup) {
                yield put(collectionActions.update(backup));
            }
        } else {
            const { payload } = yield take(modelActions.update.success);
            yield put(collectionActions.update(payload.response));
        }
    }

    function* handleRemoveRequest(action) {
        const { data } = action.payload;

        if (optimisticUpdates) {
            const backup = yield select(state => collectionSelectors.getItemById(state, data.id));
            yield put(collectionActions.remove(data));

            const [, error] = yield race([
                take(modelActions.remove.success),
                take(modelActions.remove.error)
            ]);

            if (error) {
                yield put(collectionActions.create(backup));
            }
        } else {
            yield put(collectionActions.remove(data));
        }
    }

    function* modelCollectionUpdatesSaga() {
        yield takeEvery(modelActions.update.request, handleUpdateRequest);
        yield takeEvery(modelActions.create.request, handleCreateRequest);
        yield takeEvery(modelActions.remove.request, handleRemoveRequest);
    }

    return modelCollectionUpdatesSaga;
}
