export const exportToHTML = (content) => {
    const cnt = document.createElement('div');
    cnt.innerHTML = content;
    cnt.classList.add('ql-editor');

    return cnt.outerHTML;
};

function getDraftJsMentions(cnt) {
    return Array.prototype.filter.call(
        cnt.querySelectorAll('a') || [],
        (it) => {
            return it.textContent[0] === '@' && it.dataset.entity === 'mention';
        }
    );
}

function replaceWithQuillMentions(cnt, mentionsDest) {
    mentionsDest.forEach((mn) => {
        const { name, id } = mn.dataset;
        const mentionCnt = document.createElement('span');
        const href = mn.getAttribute('href');
        const link = `<a href="${href}" target="_blank">${name}</a>`;

        mentionCnt.setAttribute('class', 'mention');
        mentionCnt.setAttribute('data-denotation-char', '@');
        mentionCnt.setAttribute('data-id', id);
        mentionCnt.setAttribute('data-name', name);
        mentionCnt.setAttribute('data-value', link);
        mentionCnt.setAttribute('data-link', href);

        mentionCnt.innerHTML = `
            <span contenteditable="false">
                <span class="ql-mention-list-char">@</span>
                ${link}
            </span>
        `;
        mn.replaceWith(mentionCnt);
    });
}

export function importFromHTML(html) {
    const cnt = document.createElement('div');
    cnt.innerHTML = html;

    // process old 'draft js' library mentions
    const draftJsMentions = getDraftJsMentions(cnt);
    if (draftJsMentions.length) {
        replaceWithQuillMentions(cnt, draftJsMentions);
    }

    return cnt.innerHTML;
}

const isEditorContainsOnlySpaces = value => value.replace(/\s/g, '') === '<div class="ql-editor"><p></p></div>';
export const isEmptyEditor = value =>
    (value === '<div class="ql-editor"><p><br></p></div>' || isEditorContainsOnlySpaces(value));
