import moment from 'moment';
import { get } from 'lodash';
import { getColorByStatus, statuses } from '../../../shared/utils/entity-health';
import { getAvailablePeriods, getMissingPeriods } from '../../goalmap-milestones/utils';
import { getFrequencyType } from '../../../shared/utils/date';
import { filterOptionValues, periodTypes } from '../constants';
import { get as getCSSValue } from '../../../shared/utils/css-custom-properties';
import { isClosed, isInactive } from '../../../shared/utils/entity';

const pastDueColor = getColorByStatus(statuses.danger);
const historyNotAvailableColor = getCSSValue('--color-porcelain');

const getRangeDiff = (range, unit = 'day') => moment(range.to).diff(range.from, unit);
export const getRangeDiffUnit = (parentUnit) => {
    switch (parentUnit) {
        case periodTypes.week: return 'day';
        case periodTypes.month: return 'week';
        case periodTypes.quarterly: return 'month';
        default: return 'day';
    }
};

const normalizeBarSections = barSections => barSections.reduce((acc, section) => {
    if (!acc.length) {
        acc.push({ ...section });
        return acc;
    }
    if (acc.slice(-1)[0].color === section.color) {
        acc.slice(-1)[0].width += section.width;
    } else {
        acc.push({ ...section });
    }
    return acc;
}, []);

/**
 * @typedef {Object} BarComputeMeta
 * @memberof WorkCenterTimeline
 * @prop {WorkCenterTimeline.DateRange} meta.range - date range
 * @prop {number} meta.width - container width
 * @prop {WorkCenterTimeline.DateRangeUnit} meta.rangeUnit - range unit
 */

/**
 * @typedef {Object} BarPosition
 * @memberof WorkCenterTimeline
 * @prop {number} offset
 * @prop {number} width
*/

/**
 * @typedef {Object} BarSection
 * @prop {number} width
 * @prop {string} color
 */

/**
 * @function computeCellWidth
 * @memberof WorkCenterTimeline.Utils
 * @param {WorkCenterTimeline.DateRange} range - date range
 * @param {number} width - total width of container in pixels
 * @param {number} - day width
 */
export const computeCellWidth = (range, unit, width) => {
    const diffUnit = getRangeDiffUnit(unit);
    const rangeDiff = getRangeDiff(range, diffUnit) + 1;

    return (width / rangeDiff);
};

const isRangeOutOfBounds = (range, bounds) => {
    if (range.from.isBefore(bounds.from) && range.to.isBefore(bounds.from)) {
        return true;
    }

    if (range.from.isAfter(bounds.to) && range.to.isAfter(bounds.to)) {
        return true;
    }

    return false;
};

export const getActionDates = (entity) => {
    return ['startDate', 'closedDate', 'targetDate']
        .map(key => entity[key])
        .filter(date => !!date)
        .map(date => moment(date))
        .filter(date => date.isValid());
};

export const getActionRange = (dates, isPastDue, bounds, unit, entityClosed) => {
    const min = moment.min(dates);
    const max = moment.max(dates);

    const isOutOfBounds = isRangeOutOfBounds({
        from: min,
        to: isPastDue ? moment.max([moment(), max]) : max
    }, bounds);

    if (isOutOfBounds) {
        return null;
    }

    const from = min.isBetween(bounds.from, bounds.to)
        ? min
        : bounds.from;

    const to = isPastDue && !entityClosed
        ? moment.min([
            moment.max([moment(), max]),
            bounds.to
        ])
        : moment.min([max, bounds.to]);

    return {
        from,
        to: to.endOf(unit)
    };
};

const getActionBarPosition = (range, meta) => {
    const { from, to } = range;
    const cellWidth = computeCellWidth(meta.range, meta.rangeUnit, meta.width);
    const rangeDiffUnit = getRangeDiffUnit(meta.rangeUnit);

    const width = (getRangeDiff({ from, to }, rangeDiffUnit) + 1) * cellWidth;
    const offset = getRangeDiff({
        from: rangeDiffUnit === 'month' ? moment(meta.range.from).startOf('month') : meta.range.from,
        to: from
    }, rangeDiffUnit) * cellWidth;

    return { width, offset };
};

/**
 * @function computeActionBarPosition
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} entity
 * @param {boolean} entity.isPastDue
 * @param {string} [entity.startDate]
 * @param {string} [entity.closedDate]
 * @param {string} [entity.targetDate]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {WorkCenterTimeline.BarPosition|null}
 */
const computeActionBarPosition = (entity, meta) => {
    const dates = getActionDates(entity);
    const rangeDiffUnit = getRangeDiffUnit(meta.rangeUnit);
    const entityClosed = isClosed(entity);
    const range = getActionRange(dates, entity.isPastDue, meta.range, rangeDiffUnit, entityClosed);

    if (!range) {
        return null;
    }

    return getActionBarPosition(range, meta);
};

/**
 * @function computeActionBarSections
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} entity
 * @param {boolean} entity.isPastDue
 * @param {string} [entity.startDate]
 * @param {string} [entity.closedDate]
 * @param {string} [entity.targetDate]
 * @param {number} [entity.progressStatus]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {BarSection[]}
 */
const computeActionBarSections = (entity, meta) => {
    const dates = getActionDates(entity);
    const rangeDiffUnit = getRangeDiffUnit(meta.rangeUnit);
    const entityClosed = isClosed(entity);
    const range = getActionRange(dates, entity.isPastDue, meta.range, rangeDiffUnit, entityClosed);
    const barPosition = getActionBarPosition(range, meta);
    const color = getColorByStatus(entity.progressStatus);
    const targetDate = moment(entity.targetDate);
    const targetDateWithOffset = targetDate.add(1, 'day');
    const cellWidth = computeCellWidth(meta.range, meta.rangeUnit, meta.width);

    if (!entity.isPastDue || targetDate.isAfter(meta.range.to)) {
        return [
            { width: barPosition.width, color },
        ];
    }

    if (targetDate.isBefore(meta.range.from)) {
        return [
            { width: barPosition.width, color: pastDueColor },
        ];
    }

    const diff = Math.abs(range.to.diff(range.from, rangeDiffUnit));

    const widths = [...new Array(diff + 1)]
        .reduce((accum, _, i) => {
            const date = range.from.add(i);
            const itRange = {
                from: date.startOf(rangeDiffUnit),
                to: date.endOf(rangeDiffUnit),
            };

            if (targetDateWithOffset.isBetween(itRange.from, itRange.to) || targetDate.isBefore(itRange.from)) {
                accum.pastDueWidth += cellWidth;
            } else {
                accum.healthWidth += cellWidth;
            }

            return accum;
        }, { healthWidth: 0, pastDueWidth: 0 });

    return [
        { width: widths.healthWidth, color },
        { width: widths.pastDueWidth, color: pastDueColor },
    ];
};

const isRangeAfterCurrentPeriod = rangeFrom => moment(rangeFrom).isAfter(moment());

const isDateInRange = (period = moment(), range) =>
    period.isBetween(range.from, moment(range.to).endOf('day'), undefined, '[]');

const isTargetDateInThePast = (rangeTo, targetDate, rangeDiffUnit) =>
    (targetDate.isBefore(rangeTo) && targetDate.isBefore(moment().startOf(rangeDiffUnit)));

/**
 * @function computeActionBarSectionsHistoricalHealth
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} entity
 * @param {boolean} entity.isPastDue
 * @param {string} [entity.startDate]
 * @param {string} [entity.closedDate]
 * @param {string} [entity.targetDate]
 * @param {number} [entity.progressStatus]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {BarSection[]}
 */
const computeActionBarSectionsHistoricalHealth = (entity, meta, history = []) => {
    const dates = getActionDates(entity);
    const rangeDiffUnit = getRangeDiffUnit(meta.rangeUnit);
    const entityClosed = isClosed(entity);
    const range = getActionRange(dates, entity.isPastDue, meta.range, rangeDiffUnit, entityClosed);
    const barPosition = getActionBarPosition(range, meta);
    const color = getColorByStatus(entity.progressStatus);
    const targetDate = moment(entity.targetDate);
    const cellWidth = computeCellWidth(meta.range, meta.rangeUnit, meta.width);

    if (targetDate.isBefore(meta.range.from)) {
        return [
            { width: barPosition.width, color: pastDueColor },
        ];
    }

    const barSections = [...new Array(range.to.diff(range.from, rangeDiffUnit) + 1)]
        .reduce((accum, _, i) => {
            const date = moment(range.from.format('YYYY-MM-DD')).add(i, rangeDiffUnit);
            const itRange = {
                from: date.startOf(rangeDiffUnit).format('YYYY-MM-DD'),
                to: date.endOf(rangeDiffUnit).format('YYYY-MM-DD'),
            };
            const targetDateInPast = isTargetDateInThePast(itRange.to, targetDate, rangeDiffUnit);

            if (targetDateInPast) {
                accum.push({ width: cellWidth, color: pastDueColor });
                return accum;
            }

            if (isRangeAfterCurrentPeriod(itRange.from) && !targetDateInPast) {
                accum.push({ width: cellWidth, color: getColorByStatus(4) });
                return accum;
            }

            if (isDateInRange(moment(), itRange) && !targetDateInPast) {
                accum.push({ width: cellWidth, color });
                return accum;
            }

            if (history.length === 0) {
                accum.push({ width: cellWidth, color });
            } else {
                const h = history.reduce((acc, histItem) => {
                    if (moment(itRange.to).diff(moment(histItem.updatedAt).startOf('day')) >= 0) {
                        acc = histItem;
                    }
                    return acc;
                }, null);
                accum.push({ width: cellWidth, color: h ? getColorByStatus(h.health) : historyNotAvailableColor });
            }

            return accum;
        }, []);

    return normalizeBarSections(barSections);
};

/**
 * @function computeActionBarComponents
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} entity
 * @param {boolean} entity.isPastDue
 * @param {string} [entity.startDate]
 * @param {string} [entity.closedDate]
 * @param {string} [entity.targetDate]
 * @param {number} [entity.progressStatus]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {Array}
 */
const computeActionBarComponents = (entity, meta) => {
    const targetDate = moment(entity.targetDate);
    const rangeDiffUnit = getRangeDiffUnit(meta.rangeUnit);

    if (!entity.targetDate || isRangeOutOfBounds({ from: targetDate, to: targetDate }, meta.range)) {
        return [];
    }

    const dates = getActionDates(entity);
    const entityClosed = isClosed(entity);
    const range = getActionRange(dates, entity.isPastDue, meta.range, rangeDiffUnit, entityClosed);
    const cellWidth = computeCellWidth(meta.range, meta.rangeUnit, meta.width);
    const diff = targetDate.diff(moment(range.from).startOf(rangeDiffUnit), rangeDiffUnit);

    return [{
        offset: diff * cellWidth,
        width: cellWidth,
        component: 'TimelineBarIcon',
    }];
};


const getStartPeriodFirstDay = startAt => startAt;
const getTargetPeriodLastDay = (targetAt, requiredFrom, frequency) =>
    (requiredFrom === 'first'
        ? moment(targetAt).endOf(getFrequencyType(frequency)).format('YYYY-MM-DD')
        : targetAt);

const getOldestDataPointFirstDay = (data_points = []) => (data_points.length
    ? data_points.reduce((acc, data_point) =>
        (moment(data_point.periodStart).isSameOrBefore(acc) ? data_point.periodStart : acc),
    data_points[0].periodStart)
    : null);

const getRecentDataPointLastDay = (data_points = []) =>
    (data_points.length
        ? data_points.reduce((acc, data_point) =>
            (moment(acc).isSameOrBefore(data_point.periodEnd) ? data_point.periodEnd : acc),
        data_points[0].periodEnd)
        : null);

const getMinDate = (startPeriodFirstDay, oldestDataPointFirstDay) =>
    (oldestDataPointFirstDay === null
        ? moment(startPeriodFirstDay).format('YYYY-MM-DD')
        : moment.min(moment(startPeriodFirstDay), moment(oldestDataPointFirstDay)).format('YYYY-MM-DD'));

const getMaxDate = (targetPeriodLastDay, recentDataPointLastDay) =>
    (recentDataPointLastDay === null
        ? moment(targetPeriodLastDay).format('YYYY-MM-DD')
        : moment.max(moment(targetPeriodLastDay), moment(recentDataPointLastDay)).format('YYYY-MM-DD'));

const getFrequencyCurrentPeriod = (rangeTo, item) => {
    return moment(rangeTo).isAfter(moment(Object.values(item.entity.frequencyCurrentPeriod)[0].to))
        ? {
            currenPeriod: {
                from: moment(rangeTo).subtract(1, getFrequencyType(item.frequency)).format('YYYY-MM-DD'),
                to: rangeTo,
            }
        }
        : item.entity.frequencyCurrentPeriod;
};

const getColorIsMissingPeriodInFuture = (missingPeriods, color, item) =>
    ((moment(missingPeriods[0].from).isAfter(moment()) && !item.isNeedUpdated)
        ? color
        : getColorByStatus(statuses.danger));

const getColorIsNeedUpdate = (item, color) =>
    (item.isNeedUpdated
        ? getColorByStatus(statuses.danger)
        : color);

const isMissingPeriodBeforeMetaRangeFrom = (missingPeriodsFromStartAt, meta) =>
    moment(missingPeriodsFromStartAt[0].from).isBefore(moment(meta.range.from));

export const getKpiDateRange = (item, meta) => {
    const entityInactive = isInactive(item);
    // The left-most point of the bar of KPI is defined by either of the following:
    // first day of Start period
    const startPeriodFirstDay = getStartPeriodFirstDay(item.startAt);
    // first day of a period of the oldest data point, if this period precedes the Start period.
    const oldestDataPointFirstDay = getOldestDataPointFirstDay(item.entity.dataPoints);
    const from = moment.max(
        getMinDate(startPeriodFirstDay, oldestDataPointFirstDay),
        meta.range.from,
    );

    // The right-most point of the bar of KPI is defined by either of the following:
    // last day of the Target period
    const targetPeriodLastDay = getTargetPeriodLastDay(item.targetAt, item.entity.requiredFrom, item.frequency);
    // last day of a period of the most recent data point, if this period goes after the Target period
    const recentDataPointLastDay = getRecentDataPointLastDay(item.entity.dataPoints);
    // maxDate between target date and recent data point date
    const maxDate = getMaxDate(
        targetPeriodLastDay,
        recentDataPointLastDay
    );
    // last day of the current period, if Target period is in the past and there are no data points added for the future periods.
    const lastDayOfCurrentPeriod = item.entity.requiredFrom === 'first'
        ? Object.values(item.entity.frequencyCurrentPeriod)[0].to
        : moment(Object.values(item.entity.frequencyCurrentPeriod)[0].from).subtract(1, 'day');

    const to = item.isNeedUpdated && !entityInactive
        ? moment.min(
            getMaxDate(maxDate, lastDayOfCurrentPeriod),
            meta.range.to
        )
        : moment.min(maxDate, meta.range.to);

    return {
        from: moment.isMoment(from) ? from.format('YYYY-MM-DD') : from,
        to: moment.isMoment(to) ? to.format('YYYY-MM-DD') : to,
    };
};

/**
 * @function computeKpiBarPosition
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} item
 * @param {boolean} item.isNeedUpdated
 * @param {array} [item.entity.dataPoints]
 * @param {object} [item.entity.frequencyCurrentPeriod]
 * @param {string} [item.entity.requiredFrom]
 * @param {string} [item.targetAt]
 * @param {string} [item.startAt]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {WorkCenterTimeline.BarPosition|null}
 */
const computeKpiBarPosition = (item, meta) => {
    const range = getKpiDateRange(item, meta);
    if (moment(range.to).isBefore(moment(range.from))) return null;
    return getActionBarPosition(range, meta);
};

/**
 * @function computeKpiBarSections
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} item
 * @param {boolean} item.isNeedUpdated
 * @param {array} [item.entity.dataPoints]
 * @param {object} [item.entity.frequencyCurrentPeriod]
 * @param {string} [item.entity.requiredFrom]
 * @param {string} [item.targetAt]
 * @param {string} [item.startAt]
 * @param {string} [item.frequency]
 * @param {number} [item.entity.progressStatus]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {Array}
 */
const computeKpiBarSections = (item, meta) => {
    const range = getKpiDateRange(item, meta);
    const barPosition = getActionBarPosition(range, meta);
    const rangeToPlusFrequencyPeriod = moment(range.to)
        .add(1, getFrequencyType(item.frequency))
        .endOf(getFrequencyType(item.frequency))
        .format('YYYY-MM-DD');

    const startPeriodFirstDay = getStartPeriodFirstDay(item.startAt);
    const oldestDataPointFirstDay = getOldestDataPointFirstDay(item.entity.dataPoints);
    const availablePeriodsFromStartAt = getAvailablePeriods(
        item.frequency,
        getMinDate(startPeriodFirstDay, oldestDataPointFirstDay),
        rangeToPlusFrequencyPeriod,
    );

    const missingPeriodsFromStartAt = getMissingPeriods(
        item.entity.dataPoints,
        availablePeriodsFromStartAt,
        getFrequencyCurrentPeriod(rangeToPlusFrequencyPeriod, item),
    );

    const currentProgressStatus = get(item.entity, 'dataPoints.0.progressStatus', item.progressStatus);
    const color = getColorByStatus(currentProgressStatus);

    if (!missingPeriodsFromStartAt.length || !item.isNeedUpdated) {
        return [
            { width: barPosition.width, color },
        ];
    }

    const cellWidth = computeCellWidth(meta.range, meta.rangeUnit, meta.width);

    const availablePeriods = getAvailablePeriods(
        item.frequency,
        range.from,
        rangeToPlusFrequencyPeriod,
    );

    const missingPeriods = getMissingPeriods(
        item.entity.dataPoints,
        availablePeriods,
        getFrequencyCurrentPeriod(rangeToPlusFrequencyPeriod, item),
    );

    if ((availablePeriodsFromStartAt[0].from === missingPeriodsFromStartAt[0].from)
        || (!missingPeriods.length && missingPeriodsFromStartAt.length)
        || isMissingPeriodBeforeMetaRangeFrom(missingPeriodsFromStartAt, meta)) {
        return [
            {
                width: barPosition.width,
                color: getColorIsNeedUpdate(item, color)
            },
        ];
    }

    const getCellRangeUnit = (rangeUnit) => {
        switch (rangeUnit) {
            case periodTypes.week: return 'day';
            case periodTypes.quarterly: return 'month';
            case periodTypes.month:
            default: return 'week';
        }
    };

    const cellRangeUnit = getCellRangeUnit(meta.rangeUnit);
    const firstMissingPeriod = moment(missingPeriods[0].from);

    let leftSectionCells = 0;
    let index = 0;
    let shouldBreak = false;

    while (!shouldBreak) {
        const nextRangeFrom = moment(range.from).add(index, cellRangeUnit).startOf(cellRangeUnit);
        const isRangeBeforeMissingPeriod = nextRangeFrom.isBefore(firstMissingPeriod);

        if (isRangeBeforeMissingPeriod) {
            leftSectionCells += 1;
        }

        shouldBreak = !isRangeBeforeMissingPeriod;
        index += 1;
    }

    return [
        {
            width: cellWidth * leftSectionCells,
            color,
        },
        {
            width: barPosition.width - (cellWidth * leftSectionCells),
            color: getColorIsMissingPeriodInFuture(missingPeriods, color, item)
        },
    ];
};

const isRangeAfterMissedPeriod = (missingPeriodsArr, itRangeFrom) =>
    (!!missingPeriodsArr.length && moment(missingPeriodsArr[0].from).isSameOrBefore(moment(itRangeFrom)));

const isRangeIncludedInDataPoint = (itRange, dataPoint, rangeDiffUnit) =>
    (moment(itRange.from).isSameOrAfter(moment(dataPoint.periodStart).startOf(rangeDiffUnit))
    && moment(itRange.to).isSameOrBefore(moment(dataPoint.periodEnd).format('YYYY-MM-DD')));

const getColorHistoricalHealth = item =>
    (item.isNeedUpdated
        ? getColorByStatus(statuses.danger)
        : getColorByStatus(statuses.neutral));

const isLastRangeInBar = (source, index) => source.length - 1 === index;
const canTakeColorFromPreviousRange = (item, dataPoint, accum) => (item.frequency !== 'weekly' && !dataPoint && !!accum.length);
const isRangeBeforeKpiStartDateAndNoDataPoint = (item, dataPoint, rangeTo) => !dataPoint && moment(rangeTo).isBefore(item.startAt);

/**
 * @function computeKpiBarSectionsHistoricalHealth
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} item
 * @param {boolean} item.isNeedUpdated
 * @param {array} [item.entity.dataPoints]
 * @param {object} [item.entity.frequencyCurrentPeriod]
 * @param {string} [item.entity.requiredFrom]
 * @param {string} [item.targetAt]
 * @param {string} [item.startAt]
 * @param {string} [item.frequency]
 * @param {number} [item.entity.progressStatus]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {Array}
 */
const computeKpiBarSectionsHistoricalHealth = (item, meta) => {
    const range = getKpiDateRange(item, meta);
    const rangeToPlusFrequencyPeriod = moment(range.to)
        .add(1, getFrequencyType(item.frequency))
        .endOf(getFrequencyType(item.frequency))
        .format('YYYY-MM-DD');

    const cellWidth = computeCellWidth(meta.range, meta.rangeUnit, meta.width);

    const availablePeriods = getAvailablePeriods(
        item.frequency,
        item.startAt,
        rangeToPlusFrequencyPeriod,
    );

    const missingPeriods = getMissingPeriods(
        item.entity.dataPoints,
        availablePeriods,
        getFrequencyCurrentPeriod(rangeToPlusFrequencyPeriod, item),
    );

    const rangeDiffUnit = getRangeDiffUnit(meta.rangeUnit);

    const barSections = [...new Array(moment(range.to).diff(range.from, rangeDiffUnit) + 1)]
        .reduce((accum, _, i, source) => {
            const date = moment(range.from).add(i, rangeDiffUnit);
            const itRange = {
                from: date.startOf(rangeDiffUnit).format('YYYY-MM-DD'),
                to: date.endOf(rangeDiffUnit).format('YYYY-MM-DD'),
            };

            const dp = item.entity.dataPoints.find(
                (dataPoint) => {
                    if (isRangeAfterMissedPeriod(missingPeriods, itRange.from) && item.isNeedUpdated) return false;
                    return isRangeIncludedInDataPoint(itRange, dataPoint, rangeDiffUnit);
                }
            );

            if (isLastRangeInBar(source, i) && canTakeColorFromPreviousRange(item, dp, accum)) {
                accum.push({ width: cellWidth, color: accum.slice(-1)[0].color });
                return accum;
            }

            if (isRangeBeforeKpiStartDateAndNoDataPoint(item, dp, itRange.to)) {
                accum.push({ width: cellWidth, color: getColorByStatus(statuses.neutral) });
                return accum;
            }

            if (dp) {
                accum.push({ width: cellWidth, color: getColorByStatus(dp.progressStatus) });
            } else {
                accum.push({ width: cellWidth, color: getColorHistoricalHealth(item) });
            }

            return accum;
        }, []);

    return normalizeBarSections(barSections);
};

/**
 * @function computeKpiBarComponents
 * @memberof WorkCenterTimeline.Utils
 * @param {Object} item
 * @param {boolean} item.isNeedUpdated
 * @param {array} [item.entity.dataPoints]
 * @param {object} [item.entity.frequencyCurrentPeriod]
 * @param {string} [item.entity.requiredFrom]
 * @param {string} [item.targetAt]
 * @param {string} [item.startAt]
 * @param {string} [item.frequency]
 * @param {number} [item.entity.progressStatus]
 * @param {WorkCenterTimeline.BarComputeMeta} meta
 * @returns {Array}
 */
const computeKpiBarComponents = (item, meta) => {
    const targetDate = item.entity.requiredFrom === 'first'
        ? moment(item.targetAt).startOf(getFrequencyType(item.frequency))
        : moment(item.targetAt).endOf(getFrequencyType(item.frequency));

    if (isRangeOutOfBounds({ from: targetDate, to: targetDate }, meta.range)) {
        return [];
    }

    const range = getKpiDateRange(item, meta);
    const cellWidth = computeCellWidth(meta.range, meta.rangeUnit, meta.width);
    const rangeDiffUnit = getRangeDiffUnit(meta.rangeUnit);
    const from = moment(range.from).startOf(rangeDiffUnit);
    const diff = targetDate.diff(from, getRangeDiffUnit(meta.rangeUnit));

    return [{
        offset: diff * cellWidth,
        width: cellWidth,
        component: 'TimelineBarIcon',
    }];
};

export const barComputes = {
    action: {
        computePosition: computeActionBarPosition,
        computeSections: calendar_type_filter => (calendar_type_filter === filterOptionValues.historicalHealth
            ? computeActionBarSectionsHistoricalHealth
            : computeActionBarSections),
        computeSectionsHistoricalHealth: computeActionBarSectionsHistoricalHealth,
        computeComponents: computeActionBarComponents
    },
    kpi: {
        computePosition: computeKpiBarPosition,
        computeSections: calendar_type_filter => (calendar_type_filter === filterOptionValues.historicalHealth
            ? computeKpiBarSectionsHistoricalHealth
            : computeKpiBarSections),
        computeComponents: computeKpiBarComponents,
    }
};
