import request from '../../../request';

export default function getLinks({ id, ...rest }, search) {
    return request.get(`/related_links/${id}`,
        {
            params: {
                ...rest,
                ...(search ? { title: search } : undefined),
            }
        }).then(response => ({ options: response }));
}
