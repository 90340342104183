/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import {
    takeLatest, put, call, select
} from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { get as _get } from 'lodash';
import subscriptionLimitCollection from './subscription-limit-collection';
import * as actions from './subscription-actions';
import * as userEntities from '../settings-users/entity';
import * as milestoneEntities from '../goalmap-milestones/entity';
import { formName as createMilestoneFormName } from '../goalmap-milestones/create/constants';
import { getKpiSubscription } from './subscription-limit-selectors';
import { getCurrentPlanIsDemo } from '../goalmap-list/selector';
import { settingsIntegrationsModel } from '../settings-integrations/entity';
import { isActiveIntegration } from '../settings-integrations/selectors';
import { features } from './subscription-config';

function* fetchLimits() {
    return yield put({
        type: subscriptionLimitCollection.actions.fetch.request.getType(),
        payload: { data: {}, options: { silent: true } },
    });
}

const map = [
    {
        action: userEntities.settingsUsersModel.actions.create.success.getType(),
        getReaction: fetchLimits
    },
    {
        action: userEntities.settingsUsersModel.actions.remove.success.getType(),
        getReaction: fetchLimits
    },
    {
        action: userEntities.settingsUsersModel.actions.update.success.getType(),
        getReaction: fetchLimits
    },
    {
        action: userEntities.settingsUsersModel.actions.updateStatus.success.getType(),
        getReaction: fetchLimits
    },
    {
        action: userEntities.settingsUsersModel.actions.bulkUpload.success.getType(),
        getReaction: fetchLimits
    },
    {
        action: milestoneEntities.create.actions.success.getType(),
        getReaction: function* () {
            const kpuSubscrValue = yield select(getKpiSubscription);
            const formState = yield select(getFormValues(createMilestoneFormName));
            const isDemoPlan = yield select(getCurrentPlanIsDemo);

            if (typeof kpuSubscrValue !== 'boolean' && formState.state !== 4 && !isDemoPlan) {
                yield put({
                    type: actions.incrementCounter.getType(),
                    payload: { feature: features.milestones }
                });
            }
        },
    },
    {
        action: milestoneEntities.details.actions.remove.success.getType(),
        getReaction: function* () {
            const kpuSubscrValue = yield select(state => getKpiSubscription(state));
            const isDemoPlan = yield select(getCurrentPlanIsDemo);

            if (typeof kpuSubscrValue !== 'boolean' && !isDemoPlan) {
                yield put({
                    type: actions.decrementCounter.getType(),
                    payload: { feature: features.milestones },
                });
            }
        },
    },
    { // UPDATE STATE AFTER TOGGLING KPI
        action: milestoneEntities.details.actions.updateStatus.success.getType(),
        getReaction: function* (response) {
            const pl = { feature: features.milestones };
            const isActive = response.payload.state === 1;
            const isDemoPlan = yield select(getCurrentPlanIsDemo);

            if (isDemoPlan) return;

            if (isActive) {
                yield put({
                    type: actions.incrementCounter.getType(),
                    payload: pl,
                });
            } else {
                yield put({
                    type: actions.decrementCounter.getType(),
                    payload: pl,
                });
            }
        },
    },
    { // UPDATE STATE AFTER RECEIVE LIMIT ERROR ON MILESTONE CREATION
        action: milestoneEntities.create.actions.error.getType(),
        getReaction: function* (response) {
            const isLimitError = _get(response, 'payload.response.data.details.key') === features.milestones;
            const pl = { feature: features.milestones };
            const isDemoPlan = yield select(getCurrentPlanIsDemo);

            if (!isLimitError || isDemoPlan) return;

            yield put({
                type: actions.incrementCounter.getType(),
                payload: pl,
            });
        },
    },
    { // UPDATE STATE AFTER RECEIVE LIMIT ERROR ON MILESTONE STATUS UPDATE
        action: milestoneEntities.details.actions.updateStatus.error.getType(),
        getReaction: function* (response) {
            const isLimitError = _get(response, 'payload.response.data.details.key') === features.milestones;
            const pl = { feature: features.milestones };
            const isDemoPlan = yield select(getCurrentPlanIsDemo);

            if (!isLimitError || isDemoPlan) return;

            yield put({
                type: actions.incrementCounter.getType(),
                payload: pl,
            });
        },
    },
    {
        action: settingsIntegrationsModel.actions.update.success.getType(),
        getReaction: function* ({ payload }) {
            const pl = { feature: features.integrations };

            const isActive = isActiveIntegration(payload);

            if (isActive) {
                yield put({
                    type: actions.incrementCounter.getType(),
                    payload: pl,
                });
            } else {
                yield put({
                    type: actions.decrementCounter.getType(),
                    payload: pl,
                });
            }
        },
    }
];

function* subscriptionRestrictionsSaga() {
    for (let i = 0; i < map.length; i += 1) {
        const item = map[i];

        yield takeLatest(item.action, function* saga(response) {
            yield call(item.getReaction, response);
        });
    }
}

export default subscriptionRestrictionsSaga;
