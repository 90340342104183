/** @namespace Utils.WithDndContext */
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

/**
 * The DndProvider component provides React-DnD capabilities to our application
 * @function withDndProvider
 * @memberof Utils.Links
 * @param {Object} WrappedComponent Component which will be wrapped
 * @return {Object} Wrapped component
 */
const withDndProvider = (WrappedComponent) => {
    return (props) => {
        return (
            <DndProvider backend={HTML5Backend}>
                <WrappedComponent {...props} />
            </DndProvider>
        );
    };
};

export default withDndProvider;
