import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { push } from 'react-router-redux';
import u from 'updeep';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setParametersAndNavigate } from '../../../modules/navigation-context/actions';
import { selectCurrentLocation } from '../../selectors/routing';
import * as objectUtils from '../../utils/object';
import { updateGetParameter } from '../../utils/url';
import { Pagination, PaginationLazy } from '../../components';
import { withErrorBoundary } from '../error-boundary';
import ListRenderer from './list-renderer';

import './styles.css';

const defaultOptions = {
    mapStateToProps() { },
};

function defaultResetPaginationCheck(prevProps, props) {
    const { currentPage, params } = props;
    const { params: prevParams } = prevProps;

    const safeParams = objectUtils.safeClone(params);
    const safePrevParams = objectUtils.safeClone(prevParams);

    delete safeParams.page;
    delete safePrevParams.page;

    const isEqual = _.isEqual(safeParams, safePrevParams);

    return !isEqual && currentPage !== 1;
}

export default function createListOfItems(options = defaultOptions) {
    const {
        actions,
        selector,
    } = options;

    return (ItemComponent) => {
        class ListView extends Component {
            componentDidMount() {
                const { currentPage, autoFetch, preventFetchOnMount } = this.props;

                if (autoFetch && !preventFetchOnMount) {
                    this.fetchList(currentPage);
                }
            }

            componentDidUpdate(prevProps) {
                const {
                    params,
                    autoFetch,
                    currentPage,
                    paginationDisabled,
                    fetchOnParamsChange,
                    isResetPaginationNeeded,
                    pathName,
                    pager,
                } = this.props;

                if (!autoFetch) {
                    return null;
                }

                if (isResetPaginationNeeded(prevProps, this.props)) {
                    return this.setPage(1);
                }

                if (this.isGoingToPreviousPageNeeded(this.props)) {
                    if (pathName) {
                        return this.setPage(Math.max(Math.min(pager.current, pager.totalPages), 1));
                    }
                }

                if (
                    (!paginationDisabled && currentPage !== prevProps.currentPage)
                    || (fetchOnParamsChange && !objectUtils.isEqual(prevProps.params, params))
                ) {
                    return this.fetchList(currentPage);
                }

                return null;
            }

            componentWillUnmount() {
                const { resetList, resetOnUnmount } = this.props;

                if (resetOnUnmount) {
                    resetList();
                }
            }

            getUniqueId = (item) => {
                const { uniqueIdAttribute } = this.props;

                if (item[uniqueIdAttribute]) {
                    return item[uniqueIdAttribute];
                }

                return item.id;
            }

            setPage(page) {
                const {
                    pathName,
                    navigate,
                    search
                } = this.props;

                navigate(updateGetParameter(`${pathName}${search}`, 'page', page));
            }

            fetchList = (page) => {
                const { getList, params, currentPage } = this.props;

                getList(
                    u({ page: page || currentPage }, params)
                );
            }

            isGoingToPreviousPageNeeded = (props) => {
                const { currentPage, pager } = props;

                return currentPage > pager.totalPages && pager.totalPages > 1;
            }

            fetchListMore = (page) => {
                const { getListMore, params } = this.props;

                getListMore(
                    u({ page }, params)
                );
            }

            handlePaginationClick = (page) => {
                this.setPage(page);
            }

            renderPagination() {
                const {
                    paginationDisabled,
                    currentPage,
                    pager
                } = this.props;

                const onePage = pager.totalItems <= pager.perPage;
                const showTopBotPagination = (
                    (!paginationDisabled)
                    && !onePage
                );

                if (!showTopBotPagination || !pager.totalPages) return null;

                return (
                    <Pagination
                        currentPage={Math.min(currentPage, pager.totalPages)}
                        totalPages={pager.totalPages || 1}
                        onChange={this.handlePaginationClick}
                    />
                );
            }

            renderLazyPagination() {
                const {
                    paginationDisabled,
                    loadMoreDisabled,
                    currentPage,
                    pager,
                    fetchingMore
                } = this.props;

                const onePage = pager.totalItems <= pager.perPage;
                const showLazyPagination = (
                    paginationDisabled
                    && !onePage
                );

                if (!showLazyPagination || loadMoreDisabled) return null;

                return (
                    <PaginationLazy
                        currentPage={currentPage || 0}
                        totalPages={pager.totalPages || 1}
                        getMore={this.fetchListMore}
                        loading={fetchingMore}
                    />
                );
            }

            render() {
                const { RendererComponent } = this.props;

                return (
                    <RendererComponent
                        {...this.props}
                        itemComponent={ItemComponent}
                        pagination={this.renderPagination()}
                        getUniqueId={this.getUniqueId}
                        lazyPagination={this.renderLazyPagination()}
                    />
                );
            }
        }

        ListView.defaultProps = {
            params: {},
            items: [],
            pager: {
                perPage: 0,
                totalItems: 0,
            },
            itemProps: {},
            prepend: null,
            append: null,
            currentPage: 1,
            className: undefined,
            emptyScreen: undefined,
            paginationDisabled: false,
            loadMoreDisabled: false,
            fetchingMore: false,
            fetchOnParamsChange: true,
            loadingIndicatorProps: {},
            error: undefined,
            hideOnError: false,
            autoFetch: true,
            itemClassName: undefined,
            wrapperClassName: undefined,
            getItemProps() {
                return {};
            },
            isResetPaginationNeeded: defaultResetPaginationCheck,
            uniqueIdAttribute: 'tag_name',
            resetOnUnmount: true,
            customRenderFunc: null,
            customRenderProps: {},
            toggleDetailsModal: () => { },
            preventFetchOnMount: false,
            currentValues: {},
            pathName: undefined,
            search: '',
            RendererComponent: ListRenderer
        };

        ListView.propTypes = {
            items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
            pager: PropTypes.shape({
                totalPages: PropTypes.number,
                perPage: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string
                ]),
                totalItems: PropTypes.number,
                current: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string
                ]),
            }),
            customRenderProps: PropTypes.object,
            fetchingMore: PropTypes.bool,
            getList: PropTypes.func.isRequired,
            getListMore: PropTypes.func.isRequired,
            resetList: PropTypes.func.isRequired,
            params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
            itemProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
            prepend: PropTypes.node,
            append: PropTypes.node,
            currentPage: PropTypes.number,
            pathName: PropTypes.string,
            className: PropTypes.string,
            emptyScreen: PropTypes.node,
            toggleDetailsModal: PropTypes.func,
            isResetPaginationNeeded: PropTypes.func,
            paginationDisabled: PropTypes.bool,
            loadMoreDisabled: PropTypes.bool,
            fetchOnParamsChange: PropTypes.bool,
            loadingIndicatorProps: PropTypes.shape({
                centered: PropTypes.bool,
                size: PropTypes.string,
            }),
            error: PropTypes.shape({
                code: PropTypes.number,
            }),
            hideOnError: PropTypes.bool,
            autoFetch: PropTypes.bool,
            getItemProps: PropTypes.func,
            itemClassName: PropTypes.string,
            wrapperClassName: PropTypes.string,
            uniqueIdAttribute: PropTypes.string,
            search: PropTypes.string,
            resetOnUnmount: PropTypes.bool,
            customRenderFunc: PropTypes.func,
            currentValues: PropTypes.object,
            preventFetchOnMount: PropTypes.bool,
            navigate: PropTypes.func.isRequired,
            RendererComponent: PropTypes.func
        };

        function mapStateToProps(state, ownProps) {
            const location = selectCurrentLocation(state);
            const safeQuery = location.query;
            const queries = { ...safeQuery };
            delete queries.page;
            const currentList = selector(state);
            const currentPage = !ownProps.paginationDisabled && location.query.page
                ? Number(location.query.page)
                : Number(currentList.pager.current || 1);
            const pathName = !ownProps.paginationDisabled
                ? location.pathname
                : undefined;

            const items = (ownProps.extraItems && ownProps.extraItems.length)
                ? [
                    ...currentList.items,
                    ...ownProps.extraItems,
                ]
                : currentList.items;

            return u(
                (
                    options.mapStateToProps
                        ? options.mapStateToProps(state, ownProps)
                        : {}
                ),
                {
                    items,
                    fetching: currentList.loading,
                    fetchingMore: currentList.loadingMore,
                    pager: currentList.pager,
                    error: currentList.error,
                    currentPage,
                    pathName,
                    search: location.search
                },
            );
        }

        return _.flow(
            connect(
                mapStateToProps,
                {
                    getList: actions.request,
                    getListMore: actions.requestMore,
                    resetList: actions.reset,
                    navigate: push,
                    setAndNavigate: setParametersAndNavigate
                }, null, { forwardRef: true }
            ),
            withErrorBoundary
        )(ListView);
    };
}

function createMapStateToPropsFromCollection(selectors) {
    return function mapStateToProps(state) {
        const items = selectors.getItems(state);
        const fetching = selectors.getFetchingState(state);
        const fetchingMore = selectors.getFetchingLazyState(state);
        const pager = selectors.getPager(state);
        const error = selectors.getError(state);

        return {
            items,
            fetching,
            fetchingMore,
            pager,
            error,
        };
    };
}

createListOfItems.createMapStateToPropsFromCollection = createMapStateToPropsFromCollection;
