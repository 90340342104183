import u from 'updeep';
import { List } from '../../../../shared/entities';
import config from '../../../../config';

const apiUrl = '/goals';

export const plansOverviewList = new List({
    apiUrl,
    name: 'plans-overview-list',
    format(params) {
        const filter = params.filter
            ? {
                [params.filter.split('=')[0]]: params.filter.split('=')[1]
            } : undefined;

        const result = u({
            ...filter,
            filter: undefined,
            limit: config.defaultListLimit,
        }, params);

        return result;
    },
});
