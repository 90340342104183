import * as actions from './primary-entity-create-service-actions';
import { name, isCancelled, isRemoved } from './primary-entity-create-constants';
import reducer from './primary-entity-create-service-reducer';
import ServiceComponent from './primary-entity-create-service';

const PrimaryEntityCreateService = {
    reducer,
    actions,
    ServiceComponent,
    constants: { name },
    utils: { isCancelled, isRemoved }
};

export default PrimaryEntityCreateService;
