import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function GridContainer({
    children, className, ...rest
}) {
    return (
        <div
            className={cx('container', className)}
            {...rest}
        >
            {children}
        </div>
    );
}

GridContainer.defaultProps = {
    children: undefined,
    className: undefined
};

GridContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
