import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import renderIf from 'render-if';
import { DayPickerRangeController } from 'react-dates';
import config from '../../../config';
import Icon from '../icon/component';
import Clickable from '../clickable/component';
import { parse, format } from './utils';
import { DropdownFixed } from '../index';

import './styles.css';
import 'react-dates/lib/css/_datepicker.css';

export default class DatePicker extends Component {
    constructor(args) {
        super(args);

        const { input } = this.props;

        this.state = {
            startDate: format(input.value && input.value.startDate),
            endDate: format(input.value && input.value.endDate),
            focusedInput: null,
            visible: false,
            key: null, // fix for resolving problem with not correct DayPickerRangeController working
        };
    }

    handleDatesChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate,
        });
    }

    handleFormattedDatesChange = ({ startDate, endDate }) => {
        this.setState({
            startDate: format(startDate),
            endDate: format(endDate),
        });
    }

    handleFocusedInput = (focusedInput) => {
        this.setState({
            // Force the focusedInput to always be truthy so that dates are always selectable
            focusedInput: !focusedInput ? 'startDate' : focusedInput,
        });
    }

    handleVisibilityChange = (visible) => {
        if (visible && !this.state.key) {
            setTimeout(() => {
                this.setState({ key: new Date() });
            }, 0);
        }

        if (!visible && this.state.visible) {
            this.props.onHide();
        }

        this.setState({
            visible,
        });
    }

    handleTriggerClick = (event, type) => {
        if (this.state.visible) {
            event.stopPropagation();
        }

        this.handleFocusedInput(type);
    }

    apply = (event) => {
        event.preventDefault();
        const { input } = this.props;
        const { startDate, endDate } = this.state;

        input.onChange({
            startDate: parse(startDate),
            endDate: parse(endDate),
        });

        this.dropdown.hide();
    }

    cancel = (event) => {
        event.preventDefault();
        const { input, onCancel } = this.props;

        this.handleDatesChange({
            startDate: format(input.value && input.value.startDate),
            endDate: format(input.value && input.value.endDate),
        });

        this.dropdown.hide();
        onCancel();
    }

    render() {
        const { input, meta, ...rest } = this.props;
        const {
            focusedInput, visible, startDate, endDate, key
        } = this.state;

        return (
            <span>
                <DropdownFixed
                    ref={(el) => { this.dropdown = el; }}
                    onShow={() => this.handleVisibilityChange(true)}
                    onHide={() => this.handleVisibilityChange(false)}
                    usePortal
                    className="input-date-range-container"
                    dropdownContentClassName="input-date-range-container-dropdown"
                    trigger={(
                        <div className="input-date-range-trigger">
                            <Clickable
                                className={classnames('input-date-range-trigger-part', {
                                    active: visible && focusedInput === 'startDate'
                                })}
                                onClick={event =>
                                    this.handleTriggerClick(event, 'startDate')
                                }
                            >
                                <span className="input-date-range-trigger-part-label">
                                    From
                                </span>
                                <div className="input-date-range-value-container">
                                    <span className="input-date-range-value">
                                        {renderIf(startDate)(
                                            moment(startDate).format(config.shortDateFormat)
                                        )}
                                    </span>
                                    <Icon name="calendar-alt" className="input-date-range-icon" />
                                </div>
                            </Clickable>
                            <Clickable
                                className={classnames('input-date-range-trigger-part', {
                                    active: visible && focusedInput === 'endDate'
                                })}
                                onClick={event =>
                                    this.handleTriggerClick(event, 'endDate')
                                }
                            >
                                <span className="input-date-range-trigger-part-label">
                                    To
                                </span>
                                <div className="input-date-range-value-container">
                                    <span className="input-date-range-value">
                                        {renderIf(endDate)(
                                            moment(endDate).format(config.shortDateFormat)
                                        )}
                                    </span>
                                    <Icon name="calendar-alt" className="input-date-range-icon" />
                                </div>
                            </Clickable>
                        </div>
                    )}
                >
                    <div>
                        {key && (
                            <DayPickerRangeController
                                key={key}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.handleDatesChange}
                                focusedInput={focusedInput}
                                onFocusChange={this.handleFocusedInput}
                                numberOfMonths={2}
                                renderDayContents={day => (
                                    <span>{day.format('D')}</span>
                                )}
                                transitionDuration={0}
                                navPrev={(
                                    <Icon name="arrow-left" />
                                )}
                                navNext={(
                                    <Icon name="chevron-right" />
                                )}
                                {...rest}
                            />
                        )}

                        <div className="input-date-range-controls">
                            <a
                                href="#apply-date"
                                className="input-date-range-apply"
                                onClick={this.apply}
                            >
                                Apply
                            </a>
                            <a
                                href="#cancel-date"
                                className="input-date-range-cancel"
                                onClick={this.cancel}
                            >
                                Cancel
                            </a>
                        </div>
                    </div>
                </DropdownFixed>
            </span>
        );
    }
}

DatePicker.defaultProps = {
    hideKeyboardShortcutsPanel: true,
    onHide() {},
    onCancel() {},
};

DatePicker.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.object,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.array,
    }).isRequired,
    onHide: PropTypes.func,
    onCancel: PropTypes.func,
    hideKeyboardShortcutsPanel: PropTypes.bool
};
