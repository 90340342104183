export const formName = 'security-settings-multi-factor-auth-form';

export const fieldNames = {
    multiFactorAuth: 'multi_factor_auth',
};

export const multiFactorAuthType = {
    active: 'active',
    inactive: 'inactive',
};

export const multiFactorAuthModalText = {
    turnOn: 'Enabling this option will make Two-Factor Authentication mandatory (2FA) for all users. ',
    turnOff: `Disabling this option will no longer make Two-Factor Authentication (2FA) mandatory for all users.
    Please Note: Users will still be able to configure 2FA for their personal accounts,
    and nothing will be changed for those who have already configured it.`,
    reset: `This will reset Two-Factor Authentication (2FA) for this user.
    They will be required to re-configure 2FA the next time they log in to the system.`,
};
