import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.css';

export default function InputRadio({
    id,
    label,
    input,
    disabled,
    className,
    value,
    checked,
    labelClassName,
    radioClassName,
    title,
    checkedClassName,
    ...rest
}) {
    return (
        <div
            className={cx('radio', {
                disabled,
                [className]: !!className,
                'radio-without-label': !label
            })}
        >
            <input
                className={cx('radio-custom', radioClassName)}
                type="radio"
                id={id}
                disabled={disabled}
                value={value}
                {...rest}
                {...input}
                onChange={e => input.onChange(value, e)}
                checked={checked || input.value === value}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label
                className={cx('radio-custom-label',
                    {
                        [labelClassName]: !!labelClassName,
                        [checkedClassName]: !!checkedClassName && (checked || input.value === value)
                    })}
                title={title}
                htmlFor={id}
            >
                {label}
            </label>
        </div>
    );
}

InputRadio.defaultProps = {
    disabled: false,
    className: undefined,
    checked: false,
    labelClassName: undefined,
    radioClassName: undefined,
    checkedClassName: undefined,
    title: undefined,
    value: undefined
};

InputRadio.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    label: PropTypes.node.isRequired,
    input: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
            PropTypes.string
        ]),
        onChange: PropTypes.func,
    }).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    radioClassName: PropTypes.string,
    checkedClassName: PropTypes.string,
    checked: PropTypes.bool,
    title: PropTypes.string,
};
