import request from '../../request';
import formatUserData from '../../shared/utils/formatters';
import { Model } from '../../shared/entities-v2';
import { asyncTypes as requestTypes } from '../../shared/entities-v2/model/config';
import * as Modules from '../settings-users/modules';
import * as userActions from '../user/actions';
import { ModelNotificationsPlugin } from '../../shared/entities-v2/plugins';

const profileApiUrl = '/users/profile';
const modelName = 'user-profile-model';
const pluginName = 'user-profile-model-notifications';

class UserPofilelModel extends Model {
    // eslint-disable-next-line class-methods-use-this
    update(data, options) {
        const formattedData = this.format(data, requestTypes.update);
        return request
            // toDo for update /users/profile we use POST on backend, in Model we use PUT
            .post(profileApiUrl, formattedData, options)
            .then(this.parse, this.parseError);
    }

    // eslint-disable-next-line class-methods-use-this
    format(data, type) {
        if (type === requestTypes.update) {
            const formattedData = formatUserData(data);
            if (formattedData.user_profile.image && formattedData.user_profile.image.length === 0) {
                delete formattedData.user_profile.image;
            }
            return Modules.buildFormData(formattedData);
        }
        return { ...data };
    }
}

const userPofilelModel = new UserPofilelModel({
    getApiUrl: () => profileApiUrl,
    name: modelName,
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onUpdateSuccess]: userActions.updateUser,
        }
    },
    plugins: [
        new ModelNotificationsPlugin({
            name: pluginName,
            getActions: actions => ([
                actions.update.success,
            ]),
            dictionary: {
                ...ModelNotificationsPlugin.dictionary,
                success: {
                    ...ModelNotificationsPlugin.dictionary.success,
                    update: 'The changes have been successfully saved',
                }
            },
        }),
    ],
});

export default userPofilelModel;
