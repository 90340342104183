import urlJoin from 'url-join';

function createUrl(path, meta = {}) {
    let result = urlJoin(path);

    Object
        .entries(meta)
        .forEach(([key, value]) => {
            result = result.replace(`:${key}`, value);
        });

    return `/${result}`;
}

export default createUrl;
