import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, GridContainer } from '../../index';

import styles from './styles.module.css';

export default function WizardFooter({
    children, className, contentWrapperClassName, error
}) {
    return (
        <div
            className={cx(styles.wizardFooter, {
                [className]: !!className,
            })}
        >
            {error && (
                <div className={styles.wizardFooterError}>
                    <div className={cx(styles.wizardFooterErrorInner, 'container')}>
                        <Icon
                            className={styles.wizardFooterErrorIcon}
                            name="exclamation-circle"
                            type={Icon.types.solid}
                        />
                        <div>
                            {error}
                        </div>
                    </div>
                </div>
            )}
            <div
                className={cx(styles.wizardFooterContentWrapper, {
                    [contentWrapperClassName]: !!contentWrapperClassName,
                })}
            >
                <GridContainer className={styles.wizardFooterContent}>
                    {children}
                </GridContainer>
            </div>
        </div>
    );
}

WizardFooter.defaultProps = {
    children: null,
    className: undefined,
    contentWrapperClassName: undefined,
    error: undefined
};

WizardFooter.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    error: PropTypes.string,
    contentWrapperClassName: PropTypes.string,
};

WizardFooter.classNames = {
    control: 'wizard-footer-control',
    controlToLeft: 'wizard-footer-control-to-left',
};
