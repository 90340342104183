import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import queryString from 'query-string';
import { defaultRedirectURL } from '../../config';
import { getIsAuthorizedState, getAccessToken } from '../../selectors';
import { applicationSettingsActions, applicationSettingsSelectors } from '../../../application';
import { selectSearchQuery } from '../../../../shared/selectors/routing';
import { joinGetParams } from '../../../../shared/utils/url';

function mapStateToProps(state) {
    return {
        isAuthorized: getIsAuthorizedState(state),
        accessToken: getAccessToken(state),
        redirectUrl: applicationSettingsSelectors.getSavedRedirectPath(state),
        searchQuery: selectSearchQuery(state)
    };
}

const mapDispatchToProps = {
    navigate: push,
    resetRedirectUrl: applicationSettingsActions.resetRedirectPath,
};

function prepareRedirectUrl(string) {
    const hasQueryString = !!string.split('?').length;

    if (hasQueryString) {
        return string.split('?')[0];
    }

    return string;
}

function getQueryPart(url) {
    const [, searchStr] = url.split('?');

    return searchStr;
}

export default function withAuthorizedRedirect(EnhancedComponent) {
    class WithAuthorizedRedirect extends Component {
        state = {
            mounted: false,
        };

        componentDidMount() {
            const {
                isAuthorized,
                accessToken,
                navigate,
                redirectUrl,
                searchQuery,
                resetRedirectUrl,
            } = this.props;

            if (isAuthorized && !!accessToken) {
                // commonly used on user auth
                const redirectSearchQuery = queryString.parse(getQueryPart(redirectUrl));

                const query = queryString.stringify({
                    ...searchQuery,
                    ...redirectSearchQuery,
                    code: undefined,
                    oauth_type: undefined,
                });

                const preparedRedirectUrl = prepareRedirectUrl(redirectUrl);
                navigate(joinGetParams(preparedRedirectUrl, query));
                resetRedirectUrl();
            }

            this.setState({ mounted: true });
        }

        componentDidUpdate(prevProps) {
            const {
                isAuthorized,
                navigate,
                redirectUrl,
                searchQuery,
                resetRedirectUrl
            } = this.props;

            if (!prevProps.isAuthorized && isAuthorized) {
                // commonly used on user auth
                const redirectSearchQuery = queryString.parse(getQueryPart(redirectUrl));

                const query = queryString.stringify({
                    ...searchQuery,
                    ...redirectSearchQuery,
                    code: undefined,
                    oauth_type: undefined,
                });

                const preparedRedirectUrl = prepareRedirectUrl(redirectUrl);
                navigate(joinGetParams(preparedRedirectUrl, query));
                resetRedirectUrl();
            }
        }

        render() {
            if (!this.state.mounted) {
                return null;
            }

            return <EnhancedComponent {...this.props} />;
        }
    }

    WithAuthorizedRedirect.defaultProps = {
        accessToken: undefined,
        searchQuery: {},
        redirectUrl: defaultRedirectURL,
    };

    WithAuthorizedRedirect.propTypes = {
        isAuthorized: PropTypes.bool.isRequired,
        navigate: PropTypes.func.isRequired,
        resetRedirectUrl: PropTypes.func.isRequired,
        searchQuery: PropTypes.object,
        accessToken: PropTypes.string,
        redirectUrl: PropTypes.string,
    };

    return connect(mapStateToProps, mapDispatchToProps)(WithAuthorizedRedirect);
}
