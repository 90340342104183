import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import createModalWizard from '../../wizard-entity/wizard-modal';
import { parse } from '../../goalmap-milestones/create/utils';
import { subscriptionLimitSelectors } from '../../subscription';
import { createObjectiveChildren as createObjectiveChildrenModel, details as strategyChildrenModel } from '../entity';
import { createSelectAvailableEntities } from './selectors';
import { getScreensByType, getInitialValuesSelectorByType } from './utils';
import { getFirstAvailableEntity } from '../create-wizard/utils';
import ObjectiveChildrenWizardForm from './wizard';
import { typeCodes } from '../../../shared/utils/entity-type';
import { getDetailsTitles } from '../../goalmap-list/selector';

const formName = 'objective-children-wizard-form-create';

const ObjectiveChildrenModalWizard = createModalWizard({
    name: formName,
    removeOnCancel: true,
    actions: {
        create: createObjectiveChildrenModel.actions.request,
        update: strategyChildrenModel.actions.update.request,
        updateTags: strategyChildrenModel.actions.updateTags.request,
        remove: strategyChildrenModel.actions.remove.request,
        fetch: strategyChildrenModel.actions.request,
        toggle: createObjectiveChildrenModel.actions.toggle,
        keepModalVisible: createObjectiveChildrenModel.actions.setKeepModalVisible,
    },
    selectors: {
        selectModalVisible: state => createObjectiveChildrenModel.selector(state).modalVisible,
        selectFetchingState: state => strategyChildrenModel.selector(state).loading,
        selectAttributes: state => strategyChildrenModel.selector(state).data,
        titleSelector: (titles, type) => get(titles, type, ''),
        getSubmitSteps: () => [2]
    },
    parseData: parse,
})(ObjectiveChildrenWizardForm);

const ObjectiveChildrenModalWizardRenderer = props => <ObjectiveChildrenModalWizard {...props} disableAdvancedCalcKPI={false} />;

const formSelector = formValueSelector(formName);
const selectFormValues = getFormValues(formName);

function mapStateToProps(state, ownProps) {
    const availableEntitiesSelector = createSelectAvailableEntities(ownProps.parentEntityData);
    const availableEntities = availableEntitiesSelector(state, ownProps.goalId);
    const selectedType = formSelector(state, 'entity_type');
    const currentValues = selectFormValues(state);
    const titles = getDetailsTitles(state);
    const steps = getScreensByType(selectedType, titles);
    const canCreateDraft = subscriptionLimitSelectors.canCreateDraft(state);
    const initialValuesSelector = getInitialValuesSelectorByType(selectedType);
    const initialValues = initialValuesSelector(state, ownProps.parentEntityData, canCreateDraft);
    initialValues.entity_type = selectedType || getFirstAvailableEntity(availableEntities);
    initialValues.parent_entity_type = typeCodes.objective;

    return {
        initialValues,
        availableEntities,
        steps,
        currentValues,
    };
}

export default connect(mapStateToProps)(ObjectiveChildrenModalWizardRenderer);
