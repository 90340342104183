/** @namespace Utils.ContentEditorEntitySelector */

/**
 * Generate url with query parameters to entity page by entity and literal type
 * @function getEntitiesByType
 * @memberof Utils.ContentEditorEntitySelector
 * @param {string} HTMLString Processed HTML string
 * @param {string} type Editor entity type
 * @return {Array} Entities list from HTML
 */
export function getEntitiesByType(HTMLString, type) {
    const div = document.createElement('div');
    div.innerHTML = HTMLString;
    let items = [];

    if (type === 'mention') {
        items = Array.prototype.map.call(
            div.querySelectorAll('.mention'),
            it => it.dataset.id
        );
    }

    return items;
}
