/* eslint-disable class-methods-use-this */
import { isEqual, cloneDeep } from 'lodash';
import { Model } from '../../../../shared/entities-v2';
import request from '../../../../request';
import { filterOptionValues } from '../../constants';
import {
    extendRange, isRangeWithin, getDateRangeFromParams,
    prepareStaticParamsForComparison, getHistoricalHealthParams
} from './timeline-model-utils';
import { keysToCamelCase, keysToSnakeCase } from '../../../../shared/utils/object';

const defaultCache = {
    response: null,
    params: null,
};

/**
 * Timeline Model implements additional logic on top of the Model:
 * - Extended range fetching.
 * - Cache that is based on request params comparison.
 *
 * @class
 * @augments RESTEntities.Model
 * @memberof WorkCenterTimeline
 */
class TimelineModel extends Model {
    cache = defaultCache

    /**
     * Preserves last fetched data and params to enable the advanced logic
     * of caching data for extended range of dates
     * @method storeCache
     * @memberof WorkCenterTimeline.TimelineModel
     * @instance
     * @param {WorkCenterTimeline.TimelineModelFetchResponse} response - API Response object
     * @param {WorkCenterTimeline.TimelineModelFetchParams} params - API Request params
     * @returns {WorkCenterTimeline.TimelineModelFetchResponse} API Response object
     */
    storeCache = (response, params) => {
        this.cache.response = response;
        this.cache.params = params;

        return response;
    }

    /**
     * Checks if TimelineModel should fetch data or simply return cached data
     * @method isValidCache
     * @memberof WorkCenterTimeline.TimelineModel
     * @instance
     * @param {WorkCenterTimeline.TimelineModelFetchParams} nextParams - API Request params
     * @returns {boolean}
     */
    isValidCache = (nextParams) => {
        const { params } = this.cache;

        if (!params || nextParams.meta.forceApiFetch) {
            return false;
        }

        const isRangeValid = isRangeWithin(
            getDateRangeFromParams(nextParams),
            extendRange(
                params.meta.dateRangeUnit,
                getDateRangeFromParams(params)
            ),
        );

        const isStaticParamsValid = isEqual(
            prepareStaticParamsForComparison(nextParams),
            prepareStaticParamsForComparison(params),
        );

        return isRangeValid && isStaticParamsValid;
    }

    /**
     * @method formatParams
     * @memberof WorkCenterTimeline.TimelineModel
     * @instance
     * @param {WorkCenterTimeline.TimelineModelFetchParams} params
     * @returns {WorkCenterTimeline.APITimelineModelFetchParams}
     */
    formatParams(params) {
        const nextParams = cloneDeep(params);

        const dateRange = getDateRangeFromParams(nextParams);
        const extendedDateRange = extendRange(params.meta.dateRangeUnit, dateRange);

        nextParams.data.startDate = extendedDateRange.from.format('YYYY-MM-DD HH:mm:ss');
        nextParams.data.endDate = extendedDateRange.to.format('YYYY-MM-DD HH:mm:ss');
        nextParams.data.calendarTypeFilter = filterOptionValues.currentHealth;

        return keysToSnakeCase(nextParams.data);
    }

    /**
     * Overridden `fetch` method of the Model class. Will store the last fetch state, and restore it if client requests
     * more data within an extended range
     * @async
     * @method fetch
     * @memberof WorkCenterTimeline.TimelineModel
     * @instance
     * @param {WorkCenterTimeline.TimelineModelFetchParams} params - Data to send to server.
     * Will be converted using `toDataURI` function
     * @param {Object} options - Request options
     * @returns {Promise<WorkCenterTimeline.TimelineModelFetchResponse>} Server response
    */
    fetch(params, options) {
        const clonedParams = keysToCamelCase(cloneDeep(params));

        const fetchRequest = this.isValidCache(clonedParams)
            ? this.fromCache
            : this.fromAPI;

        return fetchRequest(clonedParams, options)
            .then(this.parse)
            .catch(this.parseError);
    }

    fetchHistoricalHealth = async (data, params) => {
        const dataToSend = getHistoricalHealthParams(data, params);
        if (!dataToSend.entities.length) {
            this.storeCache({ ...data, historicalHealth: [] }, params);
            return ({ ...data, historicalHealth: [] });
        }
        const { items } = await request.post('/entities_health/historical', dataToSend);
        this.storeCache({ ...data, historicalHealth: items }, params);
        return {
            ...data,
            historicalHealth: items,
        };
    }

    /**
     * Fetches and returns data from the Backend API
     * @async
     * @method fromAPI
     * @memberof WorkCenterTimeline.TimelineModel
     * @instance
     * @param {WorkCenterTimeline.TimelineModelFetchParams} params - Data to send to server.
     * Will be converted using `toDataURI` function
     * @param {Object} options - Request options
     * @returns {Promise<WorkCenterTimeline.TimelineModelFetchResponse>} Server response
    */
    fromAPI = (params, options) => {
        this.cache = defaultCache;
        const url = this.options.apiUrl;
        const formattedParams = this.formatParams(params);

        if (typeof formattedParams.options === 'object') {
            delete formattedParams.options;
        }

        const promise = request.post(url, formattedParams, options);

        if (params.data.calendarTypeFilter === filterOptionValues.historicalHealth) {
            return promise.then(response => this.fetchHistoricalHealth(response, params));
        }

        return promise
            .then((response) => {
                this.storeCache(response, params);
                return response;
            });
    }

    /**
     * Returns data from the Cache
     * @async
     * @method fromCache
     * @memberof WorkCenterTimeline.TimelineModel
     * @instance
     * @returns {Promise<WorkCenterTimeline.TimelineModelFetchResponse>} Cache response
    */
    fromCache = () => {
        const { response } = this.cache;
        return Promise.resolve(response);
    }

    /**
     * Reset the Cache
     * @async
     * @method resetCache
     * @memberof WorkCenterTimeline.TimelineModel
     * @instance
    */
    resetCache = () => {
        this.cache.response = null;
        this.cache.params = null;
    }
}

const workCenterTimelineModel = new TimelineModel({
    apiUrl: '/work_center/timeline',
    name: 'work-center-timeline-model',
});

export default workCenterTimelineModel;
