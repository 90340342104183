import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import classnames from 'classnames';
import {
    Icon,
    Input,
} from '../../../shared/components';
import buildQueryString from '../../../shared/utils/buildQueryString';
import * as routeUtils from '../../../routes/rules';
import * as actions from '../../search/actions';
import { subscriptionFields, subscriptionLimitSelectors } from '../../subscription';
import FilterSelector from './filter-selector';

const RenderPostfix = ({ onClick }) => {
    return <Icon type="regular" name="times" onClick={onClick} />;
};

RenderPostfix.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const RenderPrefix = ({ onClick }) => {
    return <Icon type="regular" name="search" onClick={onClick} />;
};

RenderPrefix.propTypes = {
    onClick: PropTypes.func.isRequired,
};


class SearchForm extends Component {
    constructor(props) {
        super(props);

        const { query } = props.location;
        const goal_id = parseInt(query.goal_id, 10);
        const { q } = query;
        this.wrapperRef = React.createRef();
        this.refInputBase = React.createRef();
        this.state = {
            query: q || '',
            goal_id: query.goal_id ? goal_id : 0,
            filter: 'Everything',
        };
    }

    componentDidMount() {
        this.refInputBase.current.input.focus();
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keyup', this.handleEscPress);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keyup', this.handleEscPress);
    }

    handleClickOutside = (e) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            this.props.handleClickOutside();
        }
    }

    handleEscPress = (e) => {
        if (e.key === 'Escape') {
            this.props.handleClickOutside();
        }
    }

    setQueryValue = (event) => {
        this.setState({
            query: event.target.value,
        });
    }

    setFilterValue = (value) => {
        this.setState({
            goal_id: value
        });
    }

    getGoalMaps = (e) => {
        e.preventDefault();
        const {
            navigate, resetFacets
        } = this.props;
        const { query, goal_id, filter } = this.state;
        const data = {
            filter,
            goal_id,
            q: query.trim(),
        };

        if (goal_id === 0) {
            delete data.goal_id;
        }
        const href = routeUtils.createUrl(routeUtils.paths.search);
        const queryString = buildQueryString(data);
        resetFacets();

        navigate(`${href}${queryString}`);
    }

    handlePostfixClick = () => {
        this.setState({ query: '' });
        this.refInputBase.current.input.value = '';
        this.refInputBase.current.input.focus();
    }

    render() {
        const { goal_id, query } = this.state;
        const { draftLimits, visible } = this.props;
        return (
            <form
                ref={this.wrapperRef}
                className={classnames('nav-header-search-form', {
                    'no-quick-add-button': !draftLimits.value,
                    visible,
                })}
                onSubmit={this.getGoalMaps}
            >
                <div className="search-form-input-wrapper">
                    <Input
                        ref={this.refInputBase}
                        placeholder="Search"
                        name="header-search-input"
                        type="text"
                        onChange={this.setQueryValue}
                        value={query}
                        postfix={() => (<RenderPostfix onClick={this.handlePostfixClick} />)}
                        prefix={() => (<RenderPrefix onClick={this.getGoalMaps} />)}
                        containerClassName="search-form-input-container"
                        formGroupClassName="search-form-input-form-group"
                        inputPrefixClassName="search-form-input-prefix"
                    />
                </div>
                <FilterSelector
                    currentPlanId={goal_id}
                    onChange={this.setFilterValue}
                />
            </form>
        );
    }
}

SearchForm.propTypes = {
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetFacets: PropTypes.func.isRequired,
    handleClickOutside: PropTypes.func.isRequired,
    draftLimits: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    draftLimits: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.draft),
});

const mapDispatchToProps = {
    navigate: push,
    resetFacets: actions.resetFacets
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
