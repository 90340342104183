import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import createListOfItems from '../../../../shared/modules/list-view/component';
import { Prompt } from '../../../../shared/components';
import { getTitlesByGoalId } from '../../../goalmap-list/reducer';
import { relatedLinkCollection, relatedLinkModel } from '../related-link-models';
import RelatedLinkItem from './related-link-list-item';
import {
    entityTypes, attributeNames as attrNames, defaultRequestIncludes, defaultSortingField
} from '../related-link-constants';


const {
    actions, selector
} = relatedLinkCollection;

const RelatedLinksList = createListOfItems({
    actions,
    selector,
})(RelatedLinkItem);

const excludedEntities = entityTypes.driver;

class RelatedLinkListContainer extends Component {
    constructor(args) {
        super(args);

        this.state = {
            promptVisible: false,
            promptAction() { },
        };
    }

    onRemove = (id) => {
        const {
            showDetailsModal, removeItem, primary_link_id, primary_link_type,
        } = this.props;
        this.setState({
            promptVisible: true,
            promptAction: () => {
                showDetailsModal({ id });
                removeItem({
                    primary_link_id,
                    primary_link_type,
                    id,
                    onSuccess: this.onRemoveSuccess
                });
            }
        });
    }

    onRemoveSuccess = (response) => {
        const {
            updateDetails, onDeleteSuccess,
        } = this.props;

        if (response && response.primary_link) {
            updateDetails(response.primary_link);
        }
        onDeleteSuccess();
    }

    render() {
        const {
            showDetailsModal, removeItem, updatingItemId, primary_link_id, primary_link_type,
            goalId, userId, permissions, updateDetails, defaultParams, titles, ...rest
        } = this.props;

        return (
            <div className="side-related-link-list">
                <RelatedLinksList
                    {...rest}
                    paginationDisabled
                    params={{
                        [attrNames.includes]: defaultRequestIncludes,
                        [attrNames.primaryLinkId]: primary_link_id,
                        [attrNames.primaryLinkType]: primary_link_type,
                        [attrNames.exclude]: excludedEntities,
                        ...defaultParams,
                        [attrNames.sort]: defaultSortingField
                    }}
                    itemProps={{
                        userId,
                        permissions,
                        goalId,
                        updatingItemId,
                        titles,
                        onRemove: this.onRemove
                    }}
                />

                <Prompt
                    visible={this.state.promptVisible}
                    hide={() => this.setState({ promptVisible: false })}
                    description="You are about to remove this link."
                    onAccept={this.state.promptAction}
                />
            </div>
        );
    }
}


RelatedLinkListContainer.defaultProps = {
    updatingItemId: undefined,
    params: undefined,
    defaultParams: {},
    goalId: undefined,
    onDeleteSuccess() {},
};

RelatedLinkListContainer.propTypes = {
    showDetailsModal: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    updatingItemId: PropTypes.number,
    primary_link_id: PropTypes.number.isRequired,
    primary_link_type: PropTypes.string.isRequired,
    goalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    permissions: PropTypes.shape({
        destroy: PropTypes.bool,
        destroy_own: PropTypes.bool,
    }).isRequired,
    updateDetails: PropTypes.func.isRequired,
    defaultParams: PropTypes.object,
    titles: PropTypes.object.isRequired,
    onDeleteSuccess: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    const detailsState = relatedLinkModel.selector(state);

    return {
        titles: getTitlesByGoalId(state, state.goalmapsList.details.goal_id),
        goalId: state.goalmapsList.details.id,
        updatingItemId: detailsState && detailsState.modalItemId,
        primary_link_id: ownProps.parentId,
        primary_link_type: ownProps.parentEntityType,
    };
}

const connectedComponent = connect(
    mapStateToProps,
    {
        showDetailsModal: relatedLinkModel.actions.toggleModal,
        removeItem: relatedLinkModel.actions.remove.request,
    }
)(RelatedLinkListContainer);

export default connectedComponent;
