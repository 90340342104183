import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Button } from '../../components';
import {
    UserSelect,
    TeamRoleSelect,
    GoalMemberPane,
    TeamMemberRoles
} from '../index';
import getUsers from './api';
import './styles.css';

class TeamMemberSelect extends Component {
    constructor(args) {
        super(args);

        this.state = {
            currentMemberId: null
        };
        this.localMembers = [];
    }

    onAddMembers = (event) => {
        event.preventDefault();

        const { currentValues, change } = this.props;
        const { team_users, members_select, team_role_select } = currentValues;
        const membersToAdd = members_select.map((item) => {
            return {
                ...item,
                team_role_id: team_role_select,
                user_profile: {
                    first_name: item.first_name,
                    last_name: item.last_name,
                    tag_name: item.tag_name,
                    team_role_id: team_role_select,
                    user_id: item.user_id,
                    image: item.image
                },
                pivot: {
                    team_role_id: team_role_select,
                    user_id: item.user_id
                },
                local: true
            };
        });

        change('team_users', [
            ...team_users,
            ...membersToAdd
        ]);

        this.localMembers = membersToAdd;
        change('members_select', null);
        change('team_role_select', null);
    }

    onRemoveMember = (index) => {
        const { currentValues, change } = this.props;
        const { team_users } = currentValues;
        const result = cloneDeep(team_users);
        result.splice(index, 1);


        change('team_users', result);
    }

    get excludedUserIds() {
        const { currentValues } = this.props;

        return (currentValues.team_users || []).map(user => user.id);
    }

    filterOption = (option, filterString) => {
        return (
            UserSelect.utils.filterOption(option, filterString)
            && !this.excludedUserIds.includes(option.value)
        );
    }

    renderMember = (item, index) => {
        const {
            teamRoles, currentValues
        } = this.props;
        const { team_users } = currentValues;
        const { currentMemberId } = this.state;

        const { user_profile } = item;
        const { pivot } = item;
        return (
            <Col key={item.id} md={6}>
                <div className="member-pane-wrapper">
                    <GoalMemberPane
                        {...item}
                        login_at={false}
                        roles={teamRoles}
                        role_id={pivot.team_role_id}
                        teamRoles={teamRoles}
                        key={item.id}
                        isRoleNeeded
                        canChangeRole
                        first_name={user_profile.first_name}
                        last_name={user_profile.last_name}
                        tag_name={user_profile.tag_name}
                        team_role_id={item.pivot.team_role_id}
                        onRemoveUser={() => this.onRemoveMember(index)}
                        updating={currentMemberId === item.id}
                        onChangeRole={(role) => {
                            const { change } = this.props;

                            const targetMemberIdx = team_users.findIndex(
                                tu => tu.pivot.user_id === item.pivot.user_id
                            );

                            const newTeamUsersData = [...team_users];

                            newTeamUsersData[targetMemberIdx] = {
                                ...item,
                                pivot: {
                                    ...item.pivot,
                                    team_role_id: role.id
                                }
                            };
                            change('team_users', newTeamUsersData);
                            this.setState({
                                currentMemberId: null
                            });
                        }}
                        canRemoveLocally
                    />
                </div>
            </Col>
        );
    }

    render() {
        const { currentValues } = this.props;

        return (
            <span className="team-members-select">
                <Row>
                    <Col base={6}>
                        <Field
                            name="members_select"
                            component={UserSelect}
                            props={{
                                label: 'Select members',
                                placeholder: 'Select members',
                                loadOptions: getUsers,
                                loadOptionsParams: {
                                    limit: 'unlimited',
                                    order_by: ['first_name,asc', 'last_name,asc'],
                                    status: 2,
                                },
                                matchProp: 'label',
                                valueKey: 'id',
                                filterOption: this.filterOption,
                            }}
                        />
                    </Col>
                    <Col base={4}>
                        <Field
                            name="team_role_select"
                            component={TeamRoleSelect}
                            searchable={false}
                            clearable={false}
                            label="Select role"
                            placeholder="Select role"
                        />
                    </Col>
                    <Col base={2}>
                        <div className="member-select-add">
                            <Button
                                styleType="success"
                                disabled={(
                                    (currentValues.members_select
                                        && !currentValues.members_select.length)
                                    || !currentValues.team_role_select
                                )}
                                size={Button.sizes.medium}
                                onClick={this.onAddMembers}
                                block
                            >
                                Add
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row className="members-list-wrapper">
                    <Col base={12}>
                        <div className="members-list-title">Selected members</div>
                    </Col>
                    <Col base={12}>
                        <TeamMemberRoles>
                            <Row className="members-list">
                                {!currentValues.team_users || !currentValues.team_users.length
                                    ? (<p className="no-members-placeholder">No members added yet</p>)
                                    : currentValues.team_users.map(this.renderMember)}
                            </Row>
                        </TeamMemberRoles>
                    </Col>
                </Row>
            </span>
        );
    }
}

TeamMemberSelect.defaultProps = {
    roles: [],
    goalId: undefined,
    teamRoles: [],
};

TeamMemberSelect.propTypes = {
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
        members_select: PropTypes.array,
        team_role_select: PropTypes.number,
        team_users: PropTypes.array,
    }).isRequired,
    roles: PropTypes.array,
    goalId: PropTypes.number,
    teamRoles: PropTypes.array,
};

function mapStateToProps(state) {
    const rolesBranch = state['goal-role-list'];
    const teamsBranch = state['team-role-list'];
    return {
        roles: rolesBranch && rolesBranch.items,
        teamRoles: teamsBranch && teamsBranch.items,
    };
}

export default connect(mapStateToProps, null)(TeamMemberSelect);
