import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { selectUserId } from '../../../../modules/user/selectors';
import { Prompt } from '../../../components';
import { noop } from '../../../utils/common';
import Permissions from '../../../../modules/permissions';
import { attributes, states, } from '../../../../modules/goalmap-milestones/constants';
import MilestoneDataPointEditModal from '../../../../modules/goalmap-milestones-breakdown/edit';
import { MilestoneDataPointCreateModal } from '../../../../modules/goalmap-milestones-breakdown/create';
import formatList from '../../../../modules/goalmap-milestones-breakdown/format-list';
import { formattedPeriodStartValue, formatPeriodStart } from '../../../../modules/goalmap-milestones-breakdown/utils';
import { missingPeriodsSelector, startPeriodsSelector, } from '../../../../modules/goalmap-milestones/selectors';
import { progressNoteModel } from '../../../../modules/secondary-entities/progress-note';
import { isCanCreateDataPoint } from '../../../../modules/goalmap-milestones/details/utils';
import { create as createDataPoint, details as detailsDataPoint } from '../../../../modules/goalmap-milestones-breakdown/entity';
import * as actionsDataPointList from '../../../../modules/goalmap-milestones-breakdown/actions';
import { subscriptionFields, subscriptionLimitSelectors } from '../../../../modules/subscription';
import { getAdvKpiLimit, isAdvKpiHasAllActiveSources } from '../../../../modules/goalmap-milestones/utils';

const withKpiDataPointModel = () => {
    return (WrappedComponent) => {
        const DataPoints = (props) => {
            const {
                data, permissions, userId, openCreateDataPointModal, missingPeriods, startPeriods,
                removeDataPoint, initializeEditDataPointModal, openEditDataPointModal, isVisibleCreateDataFormModal,
                onDataPointsUpdated, onCreateSuccess, advKpiIsAllowed, kpiTypeLimit,
            } = props;

            const frequency = get(data, attributes.frequency);
            const periodTargets = get(data, attributes.kpi_period_targets);
            const type = get(data, attributes.kpi_type);
            const data_points = get(data, attributes.data_points, []);

            const canCreateDataPoint = isCanCreateDataPoint(permissions, data, userId);

            const [createFormPeriodStart, setCreateFormPeriodStart] = useState(
                () => formattedPeriodStartValue(missingPeriods, startPeriods, frequency)
            );
            const [dataPointIdForRemove, setDataPointIdForRemove] = useState(null);

            useEffect(() => {
                if (!isVisibleCreateDataFormModal) {
                    setCreateFormPeriodStart(() => formattedPeriodStartValue(missingPeriods, startPeriods, frequency));
                }
            }, [missingPeriods, startPeriods, isVisibleCreateDataFormModal]);

            const handleCreateDataPointForMissedPeriod = useCallback((missed_period_start) => {
                setCreateFormPeriodStart(formatPeriodStart(missed_period_start, frequency));
                openCreateDataPointModal();
            }, [frequency, openCreateDataPointModal]);

            const handleEditDataPoint = useCallback((data_point) => {
                initializeEditDataPointModal(data_point);
                openEditDataPointModal();
            }, [initializeEditDataPointModal, openEditDataPointModal]);

            const handleDataPointRemove = useCallback((data_point_id) => {
                setDataPointIdForRemove(data_point_id);
            }, []);

            const handleHidePrompt = useCallback(() => {
                setDataPointIdForRemove(null);
            }, []);

            const dataPointsWithMissedPoint = formatList(
                frequency,
                data_points,
                null,
                type,
                periodTargets || []
            );

            const advKpiLimit = getAdvKpiLimit(data?.advanced_calculation_enabled, advKpiIsAllowed);

            return (
                <>
                    <WrappedComponent
                        {...props}
                        handleCreateDataPoint={openCreateDataPointModal}
                        handleEditDataPoint={handleEditDataPoint}
                        handleCreateDataPointForMissedPeriod={handleCreateDataPointForMissedPeriod}
                        setCreateFormPeriodStart={setCreateFormPeriodStart}
                        createFormPeriodStart={createFormPeriodStart}
                        canCreateDataPoint={canCreateDataPoint}
                        dataPointsWithMissedPoint={dataPointsWithMissedPoint}
                        kpiTypeLimit={kpiTypeLimit.includes(data.kpi_type) && advKpiLimit}
                        isKpiActive={data.state === states.active}
                        isAdvKpiHasAllActiveSources={isAdvKpiHasAllActiveSources(data[attributes.advanced_calculation_sources])}
                    />
                    {canCreateDataPoint && (
                        <MilestoneDataPointEditModal
                            data={data}
                            handleDataPointRemove={handleDataPointRemove}
                            onDataPointsUpdated={onDataPointsUpdated}
                        />
                    )}
                    {canCreateDataPoint && (
                        <MilestoneDataPointCreateModal
                            data={data}
                            setPeriodStart={setCreateFormPeriodStart}
                            createFormPeriodStart={createFormPeriodStart}
                            onCreateSuccess={onCreateSuccess}
                            controlHidden
                        />
                    )}
                    <Prompt
                        visible={!!dataPointIdForRemove}
                        description="Are you sure you want to delete data-point?"
                        onAccept={() => {
                            removeDataPoint(dataPointIdForRemove);
                            onDataPointsUpdated();
                        }}
                        hide={handleHidePrompt}
                    />
                </>
            );
        };

        DataPoints.defaultProps = {
            missingPeriods: [],
            startPeriods: [],
            onDataPointsUpdated: noop,
            onCreateSuccess: noop,
        };

        DataPoints.propTypes = {
            data: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            userId: PropTypes.number.isRequired,
            missingPeriods: PropTypes.array,
            startPeriods: PropTypes.array,
            openCreateDataPointModal: PropTypes.func.isRequired,
            openEditDataPointModal: PropTypes.func.isRequired,
            removeDataPoint: PropTypes.func.isRequired,
            initializeEditDataPointModal: PropTypes.func.isRequired,
            isVisibleCreateDataFormModal: PropTypes.bool.isRequired,
            onDataPointsUpdated: PropTypes.func,
            onCreateSuccess: PropTypes.func,
            advKpiIsAllowed: PropTypes.bool.isRequired,
            kpiTypeLimit: PropTypes.array.isRequired,
        };

        const mapStateToProps = (state, ownProps) => {
            const permissions = Permissions.selectors.selectResolvedEntityPermissions(
                state,
                ownProps.data.goal_id,
                Permissions.constants.entitySections.kpi,
                ownProps.data,
            );
            return {
                permissions,
                userId: selectUserId(state),
                missingPeriods: missingPeriodsSelector(state),
                startPeriods: startPeriodsSelector(state),
                isVisibleCreateDataFormModal: createDataPoint.selector(state).modalVisible,
                kpiTypeLimit: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.milestones_kpi_types)?.value || [],
                advKpiIsAllowed:
                    subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.milestones_kpi_adv_calc)?.value || false,
            };
        };

        const mapDispatchToProps = {
            progressNoteShowDetailsModal: progressNoteModel.actions.toggleModal,
            openCreateDataPointModal: createDataPoint.actions.toggle,
            openEditDataPointModal: detailsDataPoint.actions.toggleModal,
            initializeEditDataPointModal: detailsDataPoint.actions.initializeWith,
            removeDataPoint: actionsDataPointList.destroyDataPoint,
        };

        return connect(mapStateToProps, mapDispatchToProps)(DataPoints);
    };
};

export default withKpiDataPointModel;
