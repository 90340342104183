/** @namespace Utils.Permissions */

import { isMilestone, typeCodes } from './entity-type';
import { hasActiveChildrenTypes } from './entity';

/**
 * Enum for action permissions keys
 * @typedef {string} PermissionKey
 * @memberof Utils.Permissions
 * @readonly
 * @enum {PermissionKey}
 */
const permissionKeys = Object.freeze({
    entity: {
        canCreate: 'can-create',
        canUpdate: 'can-update',
        canUpdateOwn: 'can-update-own',
        canDestroy: 'can-destroy',
        canDestroyOwn: 'can-destroy-own',
    },
    notes: {
        canCreate: 'can-create-notes',
        canUpdate: 'can-update-notes',
        canUpdateOwn: 'can-update-own-notes',
        canDestroy: 'can-destroy-notes',
        canDestroyOwn: 'can-destroy-own-notes',
    },
    attachments: {
        canCreate: 'can-create-attachments',
        canUpdate: 'can-update-attachments',
        canUpdateOwn: 'can-update-own-attachments',
        canDestroy: 'can-destroy-attachments',
        canDestroyOwn: 'can-destroy-own-attachments',
    },
});

/**
 * Checks if user is owner of entity
 * @function isOwnEntity
 * @memberof Utils.Permissions
 * @param {number} userId Specified value
 * @param {Entity} details Path to value in object
 * @return {any} True if user is owner of entity
 */
export default function isOwnEntity(userId, details) {
    return (userId === details.author_id || userId === details.owner_id);
}

/**
 * Get permissions for manipulating with children entities with accounting user and entity
 * @function parsePermissions
 * @memberof Utils.Permissions
 * @param {Object} permissions Entity type global permissions
 * @param {Entity} entity Entity for which will be checked permissions
 * @param {number} userId User id for which will be checked permissions
 * @param {PermissionKey} type Permission key
 * @return {Object} Returns object permissions for manipulating with children entities with accounting user and entity
 */
export function parsePermissions(permissions = {}, entity, userId, type = 'entity') {
    const perms = permissions;
    const keys = permissionKeys[type];

    return {
        create: perms[keys.canCreate],
        update: !!(perms[keys.canUpdate] || (perms[keys.canUpdateOwn] && isOwnEntity(userId, entity))),
        update_own: !!(perms[keys.canUpdateOwn] && isOwnEntity(userId, entity)),
        destroy: !!(perms[keys.canDestroy] || (perms[keys.canDestroyOwn] && isOwnEntity(userId, entity))),
        destroy_own: !!(perms[keys.canDestroyOwn] && isOwnEntity(userId, entity)),
    };
}

const entityChildrenTypes = {
    [typeCodes.plan]: [typeCodes.segment],
    [typeCodes.segment]: [typeCodes.strategy],
    [typeCodes.strategy]: [typeCodes.objective, typeCodes.action, typeCodes.kpi, typeCodes.tactic],
    [typeCodes.objective]: [typeCodes.action, typeCodes.kpi],
    [typeCodes.tactic]: [typeCodes.action, typeCodes.kpi],
    [typeCodes.action]: [typeCodes.action, typeCodes.kpi],
    [typeCodes.kpi]: [typeCodes.action, typeCodes.kpi],
};

const entityChildrenTypesGroups = {
    [typeCodes.strategy]: [[typeCodes.objective], [typeCodes.action, typeCodes.kpi, typeCodes.tactic]]
};

function hasAtLeastOneCanCreate(permissions, childrenTypeCodes) {
    return !!childrenTypeCodes.find(type => permissions[type]['can-create']);
}

export function canCreateChildren(entitiesPermissionsMap, entityType, childrenCounters) {
    const childrenTypes = entityChildrenTypes[entityType];

    if (!childrenTypes || isMilestone({ entity_type: entityType })) return false;

    const availableTypesGroups = entityChildrenTypesGroups[entityType] || [entityChildrenTypes[entityType]];

    let acceptedTypes = availableTypesGroups.find(group =>
        hasActiveChildrenTypes({ entity_children_counters: childrenCounters }, group));

    if (!acceptedTypes) {
        acceptedTypes = childrenTypes;
    }

    return hasAtLeastOneCanCreate(entitiesPermissionsMap, acceptedTypes);
}
