import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { formValueSelector, } from 'redux-form';
import { createFormModal, } from '../../../shared/modules';
import { WizardHeader, LoadingIndicator, } from '../../../shared/components';
import { types as entityTypes } from '../../../shared/utils/entity-type';
import styles from '../../../shared/components/wizard/wizard-header/styles.module.css';
import { applicationSettingsSelectors } from '../../application';
import { copy as createCopyActionTacticModalEntity } from '../entity';
import ActionForm from '../form/index';
import { actionsAttributes } from '../constants';

const ActionFormWithWizardHeader = (props) => {
    const { titles, loading } = props;
    const description = `Create a new ${titles.action} with the
        same configuration as the current entity has.`;
    return (
        <>
            <WizardHeader
                className={styles.wizardHeaderInModal}
                steps={[{ description }]}
                title={`Copy ${titles.action}`}
                currentStep={1}
                entity={{ type: entityTypes.action }}
            />
            {loading ? <LoadingIndicator centered /> : <ActionForm {...props} />}
        </>
    );
};

ActionFormWithWizardHeader.defaultProps = {
    loading: false,
};

ActionFormWithWizardHeader.propTypes = {
    titles: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};
const formName = 'action-tactic-copy';

const formSelector = formValueSelector(formName);

const mapStateToProps = (state, ownProps) => {
    const initialValues = {
        ...omit(ownProps.data, [
            actionsAttributes.entity_children_counters,
            actionsAttributes.id,
            actionsAttributes.actions,
            actionsAttributes.segment,
            actionsAttributes.type,
        ]),
        [actionsAttributes.tag_name]: undefined,
        type: 2,
    };
    return {
        currentValues: {
            start_date: formSelector(state, 'start_date'),
            end_date: formSelector(state, 'end_date'),
            status: formSelector(state, 'status'),
            progress_status: formSelector(state, 'progress_status'),
            description: formSelector(state, 'description'),
            tag_name: formSelector(state, 'tag_name'),
            sensitivity: formSelector(state, 'sensitivity'),
            sensitivities: formSelector(state, 'sensitivities'),
        },
        initialValues,
        currentBreakpoint: applicationSettingsSelectors.selectCurrentBreakpoint(state),
    };
};

const formModal = createFormModal({
    actions: createCopyActionTacticModalEntity.actions,
    selector: createCopyActionTacticModalEntity.selector,
    formName,
    enableReinitialize: true,
    mapStateToProps,
})(ActionFormWithWizardHeader);

export default formModal;
