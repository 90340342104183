import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { get } from 'lodash';
import {
    Input, Row, ThresholdInput, Text
} from '../../../../shared/components';
import { attributes, periodTargetAttributes as PTAttrs, types } from '../../constants';
import { frequencyToDateFormatters, parseThresholdsByType } from '../../utils';
import {
    PeriodTargetItem, PeriodTargetItemCol, PeriodTargetContainer, PeriodTargetItemTitle
} from './period-target-layout';
import { formatPeriodTargetValue } from './utils';

export default function PeriodTargetsStepped({
    frequency, periodTargets, rangeOfMeasure, thresholds, disabled, isTreeOpen
}) {
    const formatter = frequencyToDateFormatters[frequency];

    return (
        <PeriodTargetContainer Component="section">
            <Row>
                <PeriodTargetItemCol base={3}>
                    <PeriodTargetItemTitle>
                        Period Targets
                    </PeriodTargetItemTitle>
                </PeriodTargetItemCol>
                <PeriodTargetItemCol base={8} mdOffset={1}>
                    <PeriodTargetItemTitle>
                        Projected Health Tolerance
                    </PeriodTargetItemTitle>
                </PeriodTargetItemCol>
            </Row>

            {!!periodTargets.length && periodTargets.map((item, index) => {
                const formattedDate = formatter(get(item, PTAttrs.period_start));
                const name = `${attributes.kpi_period_targets}[${index}].${PTAttrs.target_value}`;
                const value = get(item, PTAttrs.target_value, 0);
                const currentThresholds = parseThresholdsByType(
                    types.numeric,
                    thresholds,
                    value
                );

                return (
                    <PeriodTargetItem key={item.title} Component={Row}>
                        <PeriodTargetItemCol base={1}>
                            <Text className="period-target-item-date" bold>
                                {formattedDate}:
                            </Text>
                        </PeriodTargetItemCol>
                        <PeriodTargetItemCol base={2}>
                            <Field
                                component={Input}
                                name={name}
                                type="number"
                                formGroupClassName="period-target-item-input"
                                format={formatPeriodTargetValue}
                                required
                                disabled={disabled}
                            />
                        </PeriodTargetItemCol>
                        <PeriodTargetItemCol base={8} mdOffset={1}>
                            <ThresholdInput
                                strategy={rangeOfMeasure}
                                thresholds={thresholds}
                                fieldWidth={50}
                                input={{
                                    value: currentThresholds,
                                }}
                                mode={ThresholdInput.modes.readonly}
                                isTreeOpen={isTreeOpen}
                            />
                        </PeriodTargetItemCol>
                    </PeriodTargetItem>
                );
            })}
        </PeriodTargetContainer>
    );
}

PeriodTargetsStepped.defaultProps = {
    periodTargets: [],
    disabled: false,
};

PeriodTargetsStepped.propTypes = {
    periodTargets: PropTypes.array,
    frequency: PropTypes.string.isRequired,
    rangeOfMeasure: PropTypes.string.isRequired,
    thresholds: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    isTreeOpen: PropTypes.bool.isRequired,
};
