import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Draggable from 'react-draggable';

export default function ThresholdInputHandle({
    grid, style, index, handleDrag, handleDragStop, disabled, className
}) {
    return (
        <Draggable
            axis="x"
            grid={grid}
            disabled={disabled}
            defaultPosition={{
                x: 0
            }}
            onDrag={(e, { deltaX }) => {
                handleDrag({
                    index,
                    delta: deltaX,
                });
            }}
            onStop={(e, { deltaX }) => {
                handleDragStop({
                    index,
                    delta: deltaX
                });
            }}
        >
            <div
                className={cx('threshold-input-handler', {
                    [className]: !!className
                })}
                style={style}
            />
        </Draggable>
    );
}

ThresholdInputHandle.defaultProps = {
    style: {},
    disabled: false,
    className: undefined
};

ThresholdInputHandle.propTypes = {
    grid: PropTypes.arrayOf(
        PropTypes.number,
    ).isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.shape({

    }),
    className: PropTypes.string,
    index: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    handleDrag: PropTypes.func.isRequired,
    handleDragStop: PropTypes.func.isRequired,
};
