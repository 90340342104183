import React, {
    forwardRef, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useResizeObserver from '../../hooks/use-resize-observer';
import { Icon } from '../index';
import './style.css';

const directions = {
    left: 'left',
    right: 'right',
};

const WIDTH_COMPARISON_OFFSET = 5;

const HorizontalScrollContainer = ({ children, className }, ref) => {
    const scrollRef = ref || useRef(null);
    const [setNode, observerEntry] = useResizeObserver();

    const [state, setState] = useState({
        isButtonsVisible: false,
        isLeftButtonActive: false,
        isRightButtonActive: false,
    });

    const setScrollLeft = direction => () => {
        if (direction === directions.left && scrollRef.current) {
            scrollRef.current.scrollLeft -= 300;
        }
        if (direction === directions.right && scrollRef.current) {
            scrollRef.current.scrollLeft += 300;
        }
    };

    const handleUpdateState = ({ target }) => {
        const { scrollLeft } = target;
        const { scrollWidth } = target;
        const { clientWidth } = target;

        if (scrollWidth - clientWidth > WIDTH_COMPARISON_OFFSET) {
            setState(prevState => ({ ...prevState, isButtonsVisible: true }));
        } else {
            setState(prevState => ({ ...prevState, isButtonsVisible: false }));
        }

        if (scrollLeft > 0) {
            setState(prevState => ({ ...prevState, isLeftButtonActive: true }));
        } else {
            setState(prevState => ({ ...prevState, isLeftButtonActive: false }));
        }

        if (scrollLeft + clientWidth < scrollWidth) {
            setState(prevState => ({ ...prevState, isRightButtonActive: true }));
        } else {
            setState(prevState => ({ ...prevState, isRightButtonActive: false }));
        }
    };

    useEffect(() => {
        setNode(scrollRef.current);

        if (scrollRef.current) {
            handleUpdateState({ target: scrollRef.current });
            scrollRef.current.addEventListener('scroll', handleUpdateState);
        }

        return () => {
            // eslint-disable-next-line no-unused-expressions
            scrollRef.current && scrollRef.current.removeEventListener('scroll', handleUpdateState);
        };
    }, [scrollRef]);

    useEffect(() => {
        if (observerEntry.target) {
            handleUpdateState(observerEntry);
        }
    }, [observerEntry]);

    return (
        <div className={classNames('horizontal-scroll-container', { [className]: !!className })}>
            {state.isButtonsVisible && (
                <button
                    type="button"
                    disabled={!state.isLeftButtonActive}
                    className={classNames('horizontal-scroll-container-btn left', {
                        active: state.isLeftButtonActive,
                        disabled: !state.isLeftButtonActive,
                    })}
                    onClick={setScrollLeft(directions.left)}
                >
                    <Icon className="horizontal-scroll-container-btn-icon" name="chevron-left" />
                </button>
            )}
            <div ref={scrollRef} className="horizontal-scroll-wrapper">{children}</div>
            {state.isButtonsVisible && (
                <button
                    type="button"
                    disabled={!state.isRightButtonActive}
                    className={classNames('horizontal-scroll-container-btn right', {
                        active: state.isRightButtonActive,
                        disabled: !state.isRightButtonActive,
                    })}
                    onClick={setScrollLeft(directions.right)}
                >
                    <Icon className="horizontal-scroll-container-btn-icon" name="chevron-right" />
                </button>
            )}
        </div>
    );
};

HorizontalScrollContainer.defaultProps = {
    className: '',
};

HorizontalScrollContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default forwardRef(HorizontalScrollContainer);
