import PropTypes from 'prop-types';

export const types = Object.freeze({
    MESSAGE: 'MESSAGE',
    SERVICE: 'SERVICE',
});

export const serviceTypes = Object.freeze({
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
});

export const sharedPropTypes = Object.freeze({
    onMount: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
});

export const dataShapes = Object.freeze({
    [types.MESSAGE]: PropTypes.shape({
        text: PropTypes.string,
        user: PropTypes.shape({
            avatar: PropTypes.string,
            full_name: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
        }),
    }),
});
