import React from 'react';
import ReactSelect, { Async } from 'react-select';
import Sticky from '../sticky/component';
import 'react-select/dist/react-select.css';

export default class ReactSelectSticky extends ReactSelect {
    renderOuter(...args) {
        const {
            stickyOffsetTop, stickyOffsetBottom, stickyConstraintTop,
            stickyConstraintBottom, stickyConstraintTopAlign, stickyConstraintBottomAlign
        } = this.props;

        return (
            <Sticky
                offsetTop={stickyOffsetTop}
                offsetBottom={stickyOffsetBottom}
                constraintTop={stickyConstraintTop}
                constraintBottom={stickyConstraintBottom}
                constraintTopAlign={stickyConstraintTopAlign}
                constraintBottomAlign={stickyConstraintBottomAlign}
                bypassNativeStickyCheck
            >
                { super.renderOuter(...args) }
            </Sticky>
        );
    }
}

export class AsyncSticky extends Async {}

AsyncSticky.defaultProps = {
    ...Async.defaultProps,
    children: (props) => {
        return React.createElement(ReactSelectSticky, props);
    }
};
