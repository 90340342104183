import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHighChart from 'react-highcharts';
import HighchartsSunburst from 'highcharts-sunburst';
import HighchartsExporting from 'highcharts-exporting';

import './styles.css';

HighchartsExporting(ReactHighChart.Highcharts);
HighchartsSunburst(ReactHighChart.Highcharts);

export default class ChartSunburst extends Component {
    constructor(args) {
        super(args);

        this.state = {
            config: this.transformConfig(),
        };

        this.chart = null;
    }

    shouldComponentUpdate(nextProps) {
        if (JSON.stringify(nextProps.config) !== JSON.stringify(this.props.config)) {
            return true;
        }

        return false;
    }

    handleItemClick = (event) => {
        const { onItemClick } = this.props;

        if (event && event.point) {
            onItemClick(event.point.node);
        }
    }

    update = (updates) => {
        const chart = this.chart.getChart();
        chart.update(updates);
    }

    // adding react-like API for event listeners
    transformConfig = () => {
        const { config } = this.props;

        return {
            ...config,
            chart: { height: 470 },
            plotOptions: {
                sunburst: {
                    events: {
                        click: this.handleItemClick,
                    }
                }
            },
        };
    }

    render() {
        const { config } = this.state;
        return (
            <ReactHighChart
                ref={(el) => { this.chart = el; }}
                config={config}
            />
        );
    }
}

ChartSunburst.defaultProps = {
    config: {},
    onItemClick() {},
};

ChartSunburst.propTypes = {
    config: PropTypes.object,
    onItemClick: PropTypes.func,
};
