import { Collection } from '../../../../shared/entities-v2';
import { relatedLinkConstants, relatedLinkParseItem } from '../../../secondary-entities/related-link';

class CsfRelatedLinksCollection extends Collection {
    parseItem = item => relatedLinkParseItem(item)
}

const csfRelatedLinkCollection = new CsfRelatedLinksCollection({
    apiUrl: relatedLinkConstants.apiUrl,
    name: 'driver-related-links-collection',
});

export default csfRelatedLinkCollection;
