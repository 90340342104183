import PropTypes from 'prop-types';
import React from 'react';
import {
    Icon, Transition
} from '../../../shared/components';
import SearchForm from './search-form';
import '../styles/search-form.css';

const SearchContainer = ({
    location, isActive, handleClickOutside, handleClickActiveSearch
}) => {
    return (
        <>
            <Transition
                className="nav-header-element-transition"
                visibleClassName="nav-header-element-transition-visible"
                visible={isActive}
            >
                {isActive && <SearchForm location={location} handleClickOutside={handleClickOutside} visible={isActive} />}
            </Transition>
            {!isActive && <Icon name="search" className="nav-header-search-icon" onClick={handleClickActiveSearch} />}
        </>
    );
};

SearchContainer.propTypes = {
    location: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    handleClickOutside: PropTypes.func.isRequired,
    handleClickActiveSearch: PropTypes.func.isRequired,
};

export default SearchContainer;
