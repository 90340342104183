import request from '../../../request';

export default function getLinks({ id, ...rest }, search) {
    return request.get('/notes/draft_connections', {
        params: {
            ...rest,
            limit: 15,
            q: search,
        }
    }).then(response => ({ options: response.data }));
}
