import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classnames from 'classnames';
import {
    Clickable, Icon, Text, StatusInput, StatusIndicator, PageSettings
} from '../../../../shared/components';
import EntityTitleInlineform from './entity-title-inlineform';

import styles from './styles.module.css';

const EntityViewHeader = ({
    dispatch, change, onUpdateEntityDetails, canUpdateEntity, health_manually, handleCloseWorkCenterRightDrawer,
    href, progress_status, initialValues, title, formName, onSuccessSubmit, isGlobalEntity, settings,
}) => {
    const [isEditingTitle, setIsEditingTitle] = useState(false);

    return (
        <div className={styles.rightDrawerEntityViewHeader}>
            <div className={styles.rightDrawerEntityViewButtons}>
                <Link to={href}>
                    <Text styleType="dark-light" underline>
                        {isGlobalEntity ? 'Open the details page' : 'Open in Navigator'}
                    </Text>
                </Link>
                <PageSettings options={settings} />
                <Clickable onClick={handleCloseWorkCenterRightDrawer}>
                    <Icon type="regular" name="times" />
                </Clickable>
            </div>
            <div className={styles.rightDrawerEntityViewTitleContainer}>
                {(health_manually && canUpdateEntity)
                    ? (
                        <StatusInput
                            value={progress_status}
                            className={classnames('driver-progress-status', {
                                [styles.rightDrawerEntityViewHeaderActiveStatus]: isEditingTitle,
                            })}
                            onChange={(value) => {
                                dispatch(change(formName, 'progress_status', value));
                                onUpdateEntityDetails({
                                    params: {
                                        ...initialValues,
                                        progress_status: value
                                    },
                                    onSuccess: onSuccessSubmit,
                                });
                            }}
                        />
                    )
                    : (
                        <StatusIndicator
                            className={classnames('driver-progress-status', {
                                [styles.rightDrawerEntityViewHeaderActiveStatus]: isEditingTitle,
                            })}
                            status={progress_status || 1}
                        />
                    )}
                <EntityTitleInlineform
                    title={title}
                    initialValues={initialValues}
                    onSubmit={onUpdateEntityDetails}
                    onSuccessSubmit={onSuccessSubmit}
                    canUpdateEntity={canUpdateEntity}
                    isEditingTitle={isEditingTitle}
                    setIsEditingTitle={setIsEditingTitle}
                />
            </div>
        </div>
    );
};

EntityViewHeader.defaultProps = {
    dispatch() {},
    change() {},
    health_manually: false,
    onSuccessSubmit() {},
    isGlobalEntity: false,
};

EntityViewHeader.propTypes = {
    dispatch: PropTypes.func,
    change: PropTypes.func,
    onUpdateEntityDetails: PropTypes.func.isRequired,
    canUpdateEntity: PropTypes.bool.isRequired,
    health_manually: PropTypes.bool,
    handleCloseWorkCenterRightDrawer: PropTypes.func.isRequired,
    href: PropTypes.string.isRequired,
    progress_status: PropTypes.number.isRequired,
    initialValues: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    onSuccessSubmit: PropTypes.func,
    isGlobalEntity: PropTypes.bool,
    settings: PropTypes.array.isRequired,
};

export default EntityViewHeader;
