import * as routeUtils from '../rules';
import { formatParam } from './utils';

const { PlanId, EntityId } = routeUtils.params;

const sections = {
    plan: 'goalmap',
    kpi: 'milestones',
    csf: 'drivers',
    segment: 'strategy-maps',
    strategy: 'segments',
    tactic: 'plans_actions',
};

const planPath = [sections.plan, formatParam(PlanId)];

export const deprecatedRouteMap = [
    // Global
    {
        from: ['profile', 'preferences'],
        to: routeUtils.paths.preferences,
    },
    {
        from: ['goalmaps', 'search'],
        to: routeUtils.paths.search,
    },

    // Plan
    {
        from: [...planPath, 'info'],
        to: routeUtils.paths.plan,
    },
    {
        from: [...planPath, 'strategy-maps'],
        to: routeUtils.paths.planOverview,
    },

    // KPI
    {
        from: [...planPath, sections.kpi],
        to: routeUtils.paths.planKPIList,
    },
    {
        from: [...planPath, sections.kpi, formatParam(EntityId)],
        to: routeUtils.paths.planKPIDetails,
    },

    // CSF
    {
        from: [...planPath, sections.csf],
        to: routeUtils.paths.planCSFList,
    },
    {
        from: [...planPath, sections.csf, formatParam(EntityId)],
        to: routeUtils.paths.planCSFDetails,
    },

    // Segment
    {
        from: [...planPath, sections.segment, formatParam(EntityId)],
        to: routeUtils.paths.planChildrenSegment,
    },

    // Strategy/Objective
    {
        from: [...planPath, sections.strategy, formatParam(EntityId)],
        to: routeUtils.paths.planChildrenStrategyObjective,
    },

    // Tactic/Action
    {
        from: [...planPath, sections.tactic, formatParam(EntityId)],
        to: routeUtils.paths.planChildrenTacticAction,
    },
];
