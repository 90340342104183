import { call, put, takeEvery, takeLatest, fork } from 'redux-saga/effects';
import createAsyncSaga from '../../shared/sagas/async';
import { recentPlansModel } from '../navigation-header/plans-selector/entity';
import * as actions from './actions';
import * as api from './api';

function* getList({ payload }) {
    try {
        const response = yield call(api.getList, payload);
        yield put({
            type: actions.fetchSuccess.getType(),
            payload: response,
        });
    } catch (err) {
        yield put({
            type: actions.fetchError.getType(),
            payload: err,
        });
    }
}

function* getDetails({ payload }) {
    delete payload.silent;
    try {
        const response = yield call(api.getDetails, payload);
        yield put({
            type: actions.fetchDetailsSuccess.getType(),
            payload: response,
        });
    } catch (err) {
        yield put({
            type: actions.fetchDetailsError.getType(),
            payload: err,
        });
    }
}

function* getRecentPlansDetails({ payload }) {
    const { response } = payload;

    // eslint-disable-next-line no-restricted-syntax
    for (const plan of response) {
        yield fork(api.cachedGetTree, plan.id);
    }
}

const create = createAsyncSaga(actions.create, api.create);
const updateDetails = createAsyncSaga(actions.updateDetails, api.updateDetails);
const updateState = createAsyncSaga(actions.updateState, api.updateState);
const remove = createAsyncSaga(actions.remove, api.remove);
const memberRemove = createAsyncSaga(actions.memberRemove, api.memberRemove);
const memberUpdate = createAsyncSaga(actions.memberUpdate, api.memberUpdate);
const memberActivate = createAsyncSaga(
    actions.memberActivate,
    api.memberActivate
);
const membersAdd = createAsyncSaga(actions.membersAdd, api.membersAdd);

export default function* goalmapListSaga() {
    yield takeLatest(actions.fetch.getType(), getList);
    yield takeEvery(actions.fetchDetails.getType(), getDetails);
    yield takeEvery(actions.create.request.getType(), create);
    yield takeEvery(actions.updateDetails.request.getType(), updateDetails);
    yield takeEvery(actions.updateState.request.getType(), updateState);
    yield takeEvery(actions.remove.request.getType(), remove);
    yield takeEvery(actions.memberRemove.request.getType(), memberRemove);
    yield takeEvery(actions.memberUpdate.request.getType(), memberUpdate);
    yield takeEvery(actions.memberActivate.request.getType(), memberActivate);
    yield takeEvery(actions.membersAdd.request.getType(), membersAdd);
    yield takeEvery(
        recentPlansModel.actions.fetch.success,
        getRecentPlansDetails
    );
}
