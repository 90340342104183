import { createSelector, defaultMemoize, } from 'reselect';
import {
    get, reject, uniqWith, isEqual, omit, difference,
} from 'lodash';
import { keysToCamelCase, addUniqueKeys } from '../../../../shared/utils/object';
import { typeCodes } from '../../../../shared/utils/entity-type';
import { initialState } from '../../../../shared/entities-v2/model/reducer';
import {
    workCenterTableViewTypes, states, filterOptionValues, filterOptionValueTitles
} from '../../constants';
import {
    addAuthorFullName,
    addOwnerFullName, collectItems, getChildrenByPlan, getItemsByOwner, getPlanColorMap, getGridPlans, getTreeForTable,
} from '../../table-grid-timeline-utils';
import { getPlansItems as selectUserPlans } from '../../../plans-list-my/selectors';
import { prepareGridTypeFilter, sortGridTypeFilters } from '../utils';
import workCenterGridModel from './grid-model';
import { GridModelFetchResponse } from './gird-model-types';

const selectChildren = createSelector(
    [workCenterGridModel.selectors.getAttributes],
    data => get(data, GridModelFetchResponse.items, [])
        .map(addUniqueKeys)
        .map(addOwnerFullName(data[GridModelFetchResponse.owners]))
        .map(addAuthorFullName(data[GridModelFetchResponse.owners]))
        .map(keysToCamelCase)
);

const selectParents = createSelector(
    [workCenterGridModel.selectors.getAttributes],
    data => get(data, GridModelFetchResponse.parents, [])
        .map(keysToCamelCase)
);

const selectOwners = createSelector(
    [workCenterGridModel.selectors.getAttributes, selectChildren],
    (data, childrens) => {
        const owners = get(data, GridModelFetchResponse.owners, []).map(keysToCamelCase);
        return childrens.some(child => child.ownerId === null)
            ? [...owners, { id: null, firstName: 'Unassigned', lastName: '' }]
            : owners;
    }
);

const getGridFilterValuesByState = (state) => {
    switch (state) {
        case states.inactive:
            return [filterOptionValues.allInactive];
        case states.draft:
            return [filterOptionValues.draft];
        case states.active:
        default:
            return [
                filterOptionValues.allActive,
                filterOptionValues.updateRequired,
                filterOptionValues.pastDue,
                filterOptionValues.due,
                filterOptionValues.current,
                filterOptionValues.comingDue,
                filterOptionValues.startingSoon,
                filterOptionValues.notStarted,
                filterOptionValues.updatedRecently,
            ];
    }
};

const compareFilterValuesEquality = (local, remote) => {
    return !difference(local, remote).length;
};

const getSlugs = arr => arr.map(i => i.slug);

export const selectGridTypeFilters = createSelector(
    [workCenterGridModel.selectors.getAttributes, (_, state) => state],
    (data, state) => {
        const localFilterValues = getGridFilterValuesByState(Number(state));
        const remoteFilterValues = get(data, GridModelFetchResponse.calendarTypeFilters, []);

        if (!compareFilterValuesEquality(localFilterValues, getSlugs(remoteFilterValues))) {
            return localFilterValues.map(slug => ({
                slug,
                title: filterOptionValueTitles[slug],
                count: '...',
            }))
                .map(prepareGridTypeFilter)
                .map(keysToCamelCase);
        }

        return sortGridTypeFilters(get(data, GridModelFetchResponse.calendarTypeFilters, []))
            .map(prepareGridTypeFilter)
            .map(keysToCamelCase);
    }
);

export const selectItems = createSelector(
    [workCenterGridModel.selectors.getAttributes],
    data => get(data, GridModelFetchResponse.items)
);

export const selectHasResults = createSelector(
    selectChildren,
    children => !!children.length
);

/**
 * @function selectPlans
 * @memberof WorkCenterTimeline.Selectors
 * @param {Object} state - application state
 * @returns {Object[]} Array of Plans
 */
export const selectPlans = createSelector(
    [selectParents],
    getGridPlans
);

const selectPlanParents = createSelector(
    selectParents,
    parents => uniqWith(
        reject(parents, { typeCode: typeCodes.plan }),
        isEqual
    ),
);

const selectTree = defaultMemoize(sortByAttribute =>
    createSelector(
        [selectUserPlans, selectPlans, selectPlanParents, selectChildren, () => sortByAttribute],
        getTreeForTable
    ));

export const selectItemsArray = defaultMemoize(sortByAttribute =>
    createSelector(
        [selectTree(sortByAttribute)],
        items => collectItems(items, 1, [])
    ));

export const selectPlanColorMap = createSelector(
    [selectPlans],
    getPlanColorMap
);


export const createSelectChildrenByPlan = createSelector(
    [selectChildren, selectParents],
    getChildrenByPlan
);

const selectItemsByOwner = defaultMemoize(sortByAttribute =>
    createSelector(
        [selectPlans, selectPlanParents, selectChildren, selectOwners, () => sortByAttribute],
        getItemsByOwner
    ));

export const selectGridItems = defaultMemoize((tableViewType, sortByAttribute) => {
    switch (tableViewType) {
        case workCenterTableViewTypes.owner: return selectItemsByOwner(sortByAttribute);
        case workCenterTableViewTypes.plan:
        default: return selectItemsArray(sortByAttribute);
    }
});

export const selectFilterParamsPersistStateGrid = createSelector(
    [workCenterGridModel.selectors.getRoot],
    data => ({
        ...data, model: { ...data.model, ...omit(initialState, 'fetchParams') },
    })
);
