import u from 'updeep';
import moment from 'moment';
import { get } from 'lodash';
import { capitalizeFirstLetter } from '../../shared/utils/string';
import { typeCodes, getEntityCode } from '../../shared/utils/entity-type';
import { isPastDue } from './selectors';
import { selectUserId } from '../user/selectors';
import { states } from '../goalmap-list/config';
import { actionsAttributes } from './constants';
import { getInitialValues } from '../../shared/utils/entity';

export function createSensitivityOptions(items = {}) {
    return Object.keys(items).map(name => ({
        id: items[name],
        title: capitalizeFirstLetter(items[name]),
    }));
}

export function formatHealth(data) {
    if (isPastDue(data)) {
        return u({
            display_progress_status: 3,
        }, data);
    }

    return data;
}

const defaultInitialValues = {
    type: 1,
    start_date: moment(),
    frequency: 'weekly',
    priority: 'medium',
    sensitivity: 'standard',
    progress_status: 4,
    status: 'pending',
    state: states.draft,
    title: '',
    description: '',
    tags: []
};

export function mapInitialCreateValuesActionFromParent(state, data) {
    const userId = selectUserId(state);
    const entityTypeCode = getEntityCode(data);
    const isOfSegmentType = (
        entityTypeCode === typeCodes.objective
        || entityTypeCode === typeCodes.strategy
    );

    return {
        ...getInitialValues(defaultInitialValues, actionsAttributes),
        [actionsAttributes.type]: 2,
        [actionsAttributes.author_id]: userId,
        [actionsAttributes.owner_id]: userId,
        [actionsAttributes.segment_id]: isOfSegmentType ? get(data, actionsAttributes.id) : undefined,
        [actionsAttributes.parent_id]: !isOfSegmentType ? get(data, actionsAttributes.id) : undefined,
    };
}
