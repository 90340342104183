import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, IconButton } from '../../components';
import styles from './team-section.module.css';


const TeamSectionSearch = ({ searchValue, setSearchValue }) => {
    const clearSearchValue = useCallback(() => setSearchValue(''), [setSearchValue]);

    const renderPrefix = useCallback(({ iconClassName }) => (
        <Icon
            type="regular"
            name="search"
            className={iconClassName}
        />
    ), []);

    const renderPostfix = useCallback(({ iconClassName, value }) => {
        const hasValue = !!value;
        if (!hasValue) {
            return null;
        }

        return (
            <IconButton
                type="regular"
                icon="times"
                iconClassName={iconClassName}
                onClick={clearSearchValue}
            />
        );
    }, [clearSearchValue]);

    return (
        <Input
            autoFocus
            placeholder="Search for Team"
            name="teams-search"
            containerClassName={styles.teamSectionSearch}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            inputPrefixClassName={styles.inputPrefix}
            prefix={renderPrefix}
            postfix={renderPostfix}
        />
    );
};

TeamSectionSearch.propTypes = {
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired,
};

export default TeamSectionSearch;
