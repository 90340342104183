import { reducerName } from './config';
import actions from './entity/actions';
import reducer from './entity/reducer';
import saga from './entity/saga';
import * as selectors from './entity/selectors';

export default {
    name: reducerName,
    actions,
    reducer,
    saga,
    selectors,
};
