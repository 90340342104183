import moment from 'moment';
import { get } from 'lodash';
import { details as objectiveModel } from '../goalmap-segments/entity';
import { selectUserId } from '../user/selectors';
// import { states } from '../goalmap-list/config';
import { objectiveAttributes } from './constants';
import { getInitialValues } from '../../shared/utils/entity';

const defaultInitialValues = {
    type: 1,
    // parent_id,
    // segment_id: segment_id || parent_id,
    start_date: moment(),
    frequency: 'weekly',
    priority: 'medium',
    sensitivity: 'standard',
    progress_status: '4',
    status: 'pending',
    // state: states.draft,
    title: '',
    description: '',
    tags: []
};

export function mapInitialCreateValuesObjective(state, ownProps) {
    const userId = selectUserId(state);

    return {
        ...getInitialValues(defaultInitialValues, objectiveAttributes),
        [objectiveAttributes.author_id]: userId,
        [objectiveAttributes.owner_id]: userId,
        [objectiveAttributes.segment_id]: get(ownProps, objectiveAttributes.segment_id) || get(ownProps, objectiveAttributes.parent_id),
        [objectiveAttributes.parent_id]: get(ownProps, objectiveAttributes.parent_id),
    };
}


export function mapInitialCreateValuesObjectiveFromParent(state, data) {
    const userId = selectUserId(state);

    return {
        ...getInitialValues(defaultInitialValues, objectiveAttributes),
        [objectiveAttributes.author_id]: userId,
        [objectiveAttributes.owner_id]: userId,
        [objectiveAttributes.parent_id]: get(data, objectiveAttributes.id),
    };
}

export function mapInitialUpdateValuesObjective(state, ownProps, id) {
    const objectiveData = objectiveModel.selector(state).data;

    return {
        ...getInitialValues(objectiveData, objectiveAttributes),
        id
    };
}
