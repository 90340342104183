import request from '../../request';
import { createDetails } from '../../shared/entities';

export default createDetails({
    name: 'goalmap-settings-titles',
    updateHTML: false,
    extendAPI: {
        update({ params }) {
            let transformedSettings = null;

            try {
                transformedSettings = JSON.stringify(params.settings);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }

            return request.put(`/goals/${params.id}/settings`, {
                settings: transformedSettings,
            });
        },
    },
});
