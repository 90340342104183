import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ValueChange from './value-change';
import colors, { propTypes as colorPropTypes } from './colors';
import './styles.css';

export default function MicroWidget({
    children,
    className,
    title,
    value,
    customRender,
    subTitle,
    trend,
    color,
}) {
    return (
        <div
            className={classnames('micro-widget', {
                [className]: !!className,
            })}
        >
            <h3 className="micro-widget-title">
                <span className="micro-widget-title-text">
                    {title}
                    {
                        subTitle
                            ? (
                                <small className="micro-widget-subtitle">
                                    {subTitle}
                                </small>
                            )
                            : null
                    }
                </span>
            </h3>
            {
                customRender
                    ? (<div className="custom-render-container">{children}</div>)
                    : (
                        <>
                            <div className="micro-widget-value" style={{ color }}>
                                {value}
                            </div>
                            {
                                trend.enabled
                                    ? (
                                        <ValueChange
                                            {...trend}
                                        />
                                    )
                                    : null
                            }
                        </>
                    )
            }
        </div>
    );
}

MicroWidget.colors = colors;

MicroWidget.defaultProps = {
    className: undefined,
    value: 0,
    children: undefined,
    customRender: false,
    title: undefined,
    subTitle: undefined,
    color: colors.default,
    trend: ValueChange.defaultProps,
};

MicroWidget.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    customRender: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    subTitle: PropTypes.string,
    color: colorPropTypes,
    trend: PropTypes.shape(ValueChange.propTypes),
};
