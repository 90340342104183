import request from '../../../../request';
import { List, createDetails } from '../../../../shared/entities';

function getDetails(params) {
    const paramsToSend = {};

    if (params.goal_id) {
        paramsToSend.goal_id = params.goal_id;
    }

    if (params.owner_id) {
        paramsToSend.owner_id = params.owner_id;
    }

    return request
        .get('/widgets/reminders', { params: paramsToSend })
        .then(response => response.data);
}

export const dashboardWidgetRemindersModel = createDetails({
    apiUrl: '/widgets/reminders',
    name: 'my-reminders-details',
    updateHTML: false,
    extendAPI: {
        get: getDetails,
    }
});


export default new List({
    name: 'my-reminders-list',
    apiUrl: '/widgets/reminders',
});
