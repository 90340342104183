import { takeEvery, select, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import queryString from 'querystring';
import { joinGetParameters } from '../../utils/url';
import { selectCurrentPathname, selectSearchQuery } from '../../selectors/routing';
import * as actions from './actions';

function* handleRemoveQueryAttribute({ payload }) {
    const pathname = yield select(selectCurrentPathname);
    const query = yield select(selectSearchQuery);

    delete query[payload.attribute];

    const nextQuery = queryString.stringify(query);

    yield put(push(joinGetParameters(pathname, nextQuery)));
}

function* routingSaga() {
    yield takeEvery(actions.removeQueryAttribute, handleRemoveQueryAttribute);
}

export default routingSaga;
