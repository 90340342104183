import React, { useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Permissions from '../../../modules/permissions';
import * as ToastNotifications from '../../../modules/notifications/toast';
import { getTitlesByGoalId } from '../../../modules/goalmap-list/reducer';
import { getStringTypeByCode } from '../../utils/entity-type';
import { Prompt, Text } from '../../components';
import {
    isArchived as isArchivedEntity, entityState, isDraftEntity, isActive as isActiveEntity
} from '../../utils/entity';
import PrimaryEntityCloneService from '../primary-entity-clone-service';

const getEntityStateUpdateText = (state) => {
    switch (state) {
        case entityState.draft:
            return 'Publish';
        case entityState.archived:
            return 'Activate';
        case entityState.active:
        default:
            return 'Archive';
    }
};

const getEntityStateUpdateValue = (state) => {
    switch (state) {
        case entityState.draft:
        case entityState.archived:
            return 'active';
        case entityState.active:
        default:
            return 'archive';
    }
};

const getErrorMessage = (error) => {
    return get(error, 'response.data.message', 'Something went wrong.');
};

const useEntityActions = ({
    id, title, state, planId, typeCode, data, permissions, remove, updateState, onRemove, onEntityUpdate
}) => {
    const [promptVisible, setPromptVisible] = useState(false);
    const dispatch = useDispatch();

    const hidePrompt = () => setPromptVisible(false);
    const planTitles = useSelector(appState => getTitlesByGoalId(appState, planId));
    const entityTypeTitle = planTitles[getStringTypeByCode(typeCode)] || 'Entity';

    const showNotification = (type, text) => {
        const action = ToastNotifications.create({
            type: ToastNotifications.types.SERVICE,
            data: {
                type,
                text,
            }
        });

        dispatch(action);
    };

    const handleRemove = useCallback(() => {
        const handleRemoveSuccess = () => {
            hidePrompt();
            onRemove();
            onEntityUpdate();
            showNotification(
                ToastNotifications.serviceTypes.SUCCESS,
                `${entityTypeTitle} has been deleted`
            );
        };

        const handleRemoveError = (error) => {
            hidePrompt();
            showNotification(
                ToastNotifications.serviceTypes.ERROR,
                getErrorMessage(error)
            );
        };

        remove({
            id,
            onSuccess: handleRemoveSuccess,
            onError: handleRemoveError,
        });
    }, [id, remove, hidePrompt, onRemove, onEntityUpdate]);

    const handleCopy = useCallback(() => {
        const action = PrimaryEntityCloneService.actions.clone({
            typeCode,
            data,
        });

        dispatch(action);
    }, [dispatch, typeCode, data]);

    const handleStateChange = useCallback(() => {
        const handleStateChangeSuccess = (response) => {
            const message = response.state === entityState.archived
                ? `${entityTypeTitle} has been archived`
                : `${entityTypeTitle} has been published`;

            onEntityUpdate();
            showNotification(
                ToastNotifications.serviceTypes.SUCCESS,
                message
            );
        };

        const handleStateChangeError = (error) => {
            showNotification(
                ToastNotifications.serviceTypes.ERROR,
                getErrorMessage(error)
            );
        };

        updateState({
            id,
            state: getEntityStateUpdateValue(state),
            onSuccess: handleStateChangeSuccess,
            onError: handleStateChangeError,
        });
    }, [id, state]);

    const EntityActionsPrompt = () => {
        return (
            <Prompt
                description={`You are about to delete ${title}`}
                visible={promptVisible}
                hide={hidePrompt}
                onAccept={handleRemove}
            />
        );
    };

    const actions = useMemo(() => {
        const result = [];
        const canDestroy = get(permissions, Permissions.keys.canDestroy, false);
        const canCreate = get(permissions, Permissions.keys.canCreate, false);
        const canUpdate = get(permissions, Permissions.keys.canUpdate, false);
        const isArchived = isArchivedEntity({ state });
        const isDraft = isDraftEntity({ state });
        const isActive = isActiveEntity({ state });

        if (canCreate && !isArchived) {
            result.push({
                title: <Text>Copy</Text>,
                action: handleCopy,
            });
        }

        if ((isActive && canDestroy) || ((isArchived || isDraft) && canUpdate)) {
            const updateStateText = getEntityStateUpdateText(state);

            result.push({
                title: <Text>{updateStateText}</Text>,
                action: handleStateChange,
            });
        }

        if (canDestroy) {
            result.push({
                title: <Text styleType="danger">Delete</Text>,
                action: () => setPromptVisible(true),
            });
        }

        return result;
    }, [permissions, state]);

    return {
        EntityActionsPrompt,
        actions,
    };
};

export default useEntityActions;
