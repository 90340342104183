import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default function Row({ children, className, ...rest }) {
    return (
        <div
            className={classnames('row', { [className]: className })}
            {...rest}
        >
            {children}
        </div>
    );
}

Row.defaultProps = {
    children: undefined,
    className: undefined
};

Row.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
