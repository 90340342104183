import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const elevations = {
    none: 'none',
    small: 'small',
    medium: 'medium',
    large: 'large',
};

export default function Paper({
    children, className, elevation, ...rest
}) {
    return (
        <div
            className={classnames('paper', {
                [`paper-elevation-${elevation}`]: true,
                [className]: !!className,
            })}
            {...rest}
        >
            {children}
        </div>
    );
}

Paper.elevations = elevations;

Paper.defaultProps = {
    elevation: elevations.small,
    children: undefined,
    className: undefined,
};

Paper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    elevation: PropTypes.oneOf([
        elevations.none,
        elevations.small,
        elevations.medium,
        elevations.large,
    ]),
};
