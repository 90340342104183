import { Map } from 'immutable';
import { createReducer } from 'redux-act';
import { transportStates, attributes } from '../config';
import actions from './actions';

const initialState = Map({
    [attributes.transport]: Map({
        state: transportStates.closed,
        error: null,
    }),
    [attributes.app]: Map({
        isVisible: true,
        isMasterTab: false,
    }),
});

function setTransportState(state, payload) {
    return state.withMutations(map => map
        .setIn([attributes.transport, 'state'], payload.state)
        .setIn([attributes.transport, 'error'], payload.error || null)
    );
}

function setVisibilityState(state, payload) {
    return state.withMutations(map => map
        .setIn([attributes.app, 'isVisible'], payload.isVisible)
    );
}

function setMasterTabState(state, payload) {
    return state.withMutations(map => map
        .setIn([attributes.app, 'isMasterTab'], payload.isMasterTab)
    );
}

export default createReducer({
    [actions.transport.setState]: setTransportState,
    [actions.app.setVisibilityState]: setVisibilityState,
    [actions.app.setMasterTabState]: setMasterTabState,
}, initialState);
