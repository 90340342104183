import { createSelector } from 'reselect';
import { getTitlesByGoalId } from '../goalmap-list/reducer';
import { typeCodes } from '../../shared/utils/entity-type';

export function isPastDue(data = {}) {
    return data.is_past_due;
}

export const getTypeOptions = createSelector(
    getTitlesByGoalId,
    titles => ([
        {
            icon: 'check-circle',
            value: typeCodes.action.toString(),
            tooltip: `${titles.plan}/${titles.action}`,
        },
        {
            icon: 'analytics',
            value: typeCodes.kpi.toString(),
            tooltip: titles.milestone,
        },
    ])
);
