import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon, RadioButton, Text } from '../../../shared/components';
import { noop } from '../../../shared/utils/common';

import styles from './entity-type-selector.module.css';

export default function EntityTypeSelector({
    type, label, selected, input, icon, disabled, description, infoLink, onChange, ...rest
}) {
    return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label
            className={cx(styles.entityTypeSelector, {
                [styles.entityTypeSelectorDisabled]: disabled,
                [styles.entityTypeSelectorSelected]: input.value === type
            })}
            htmlFor={type}
        >
            <div className={styles.entityTypeSelectorRadioWrapper}>
                <RadioButton
                    id={type}
                    value={type}
                    disabled={disabled}
                    className={styles.entityTypeSelectorRadio}
                    {...rest}
                    label={false}
                    input={input}
                />
            </div>
            <div className={styles.entityTypeSelectorDescriptionWrapper}>
                <Icon className={styles.entityTypeSelectorDescIcon} name={icon} />
                <div className={styles.entityTypeSelectorDescContent}>
                    <div className={styles.entityTypeSelectorDescTitle}>
                        {label}
                    </div>
                    <div className={styles.entityTypeSelectorDescription}>
                        {description}{' '}
                        {infoLink && (
                            <Fragment>
                                <Text
                                    className={styles.entityTypeSelectorInfoLink}
                                    component="a"
                                    href={infoLink}
                                    underline
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Click here
                                </Text>{' '}
                                to learn more.
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </label>
    );
}

EntityTypeSelector.defaultProps = {
    disabled: false,
    restricted: false,
    active: false,
    comingSoon: false,
    infoLink: undefined,
    selected: false,
    onChange: noop
};

EntityTypeSelector.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    restricted: PropTypes.bool,
    active: PropTypes.bool,
    comingSoon: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onChange: PropTypes.func,
    input: PropTypes.object.isRequired,
    infoLink: PropTypes.string
};
