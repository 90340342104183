import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TableView } from '../../../shared/components';

export default function KpiDataPointFormTableItem({
    id, className, columns
}) {
    return (
        <TableView.Row
            className={cx('data-point-table-item', {
                [className]: !!className
            })}
            key={id}
        >
            {columns.map((item, index) => {
                if (!item) return null;

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableView.Cell className="data-point-table-item-cell" key={index}>
                        {item}
                    </TableView.Cell>
                );
            })}
        </TableView.Row>
    );
}

KpiDataPointFormTableItem.defaultProps = {
    className: undefined,
};

KpiDataPointFormTableItem.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    className: PropTypes.string,
    columns: PropTypes.array.isRequired,
};
