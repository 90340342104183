import React from 'react';
import Icon from '../icon/component';
import styles from './styles.module.css';

export default function BadgeArchived() {
    return (
        <div className={styles.badgeArchived}>
            <Icon type={Icon.types.regular} name="file-archive" className={styles.badgeArchivedIcon} /> Archived
        </div>
    );
}
