import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Permissions from '.';

export const withPlanSettingsPermissions = (EnhancedComponent) => {
    const WithPlanPermission = ({ planPermissions, ...rest }) => {
        const canMove = get(planPermissions, Permissions.constants.entityPermissionNames.canMove);
        const canChangeSettings = get(planPermissions, Permissions.constants.entityPermissionNames.canChangeSettings);
        const canChangeNotifications = get(planPermissions, Permissions.constants.entityPermissionNames.canChangeNotifications);

        return (
            <EnhancedComponent
                {...rest}
                canMove={canMove}
                canChangeSettings={canChangeSettings}
                canChangeNotifications={canChangeNotifications}
            />
        );
    };

    WithPlanPermission.propTypes = {
        planPermissions: PropTypes.object.isRequired,
    };

    const mapStateToProps = (state, ownProps) => {
        const planId = Number(ownProps.params.id);
        return {
            planPermissions:
                Permissions.selectors.selectEntityPermissions(state, planId, Permissions.constants.entitySections.strategicPlan),
        };
    };

    return connect(mapStateToProps)(WithPlanPermission);
};
