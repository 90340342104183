import React from 'react';
import PropTypes from 'prop-types';

import { LinkDownloadSecure } from '../../../../shared/modules';

const AttachmentAutomatedDownload = ({ download }) => {
    return (
        <LinkDownloadSecure
            containerClassName="util-hidden"
            name="attachment-download"
            url={download}
            autoFetch
        />
    );
};

AttachmentAutomatedDownload.propTypes = {
    download: PropTypes.string.isRequired,
};

export default AttachmentAutomatedDownload;
