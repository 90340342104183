import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../icon/component';
import FormattedDate from '../date/component';
import Clickable from '../clickable/component';
import Text from '../text';
import TagLink from '../../modules/tag-link';
import './styles.css';

const dates = [
    { prop: 'start_date', label: 'Start Date' },
    { prop: 'target_date', label: 'Target Date' },
    { prop: 'closed_date', label: 'Closed Date' },
];

export default function MetaInfo(props) {
    const {
        owner,
        author,
        date,
        start_date,
        closed_date,
        target_date,
        tags,
        editable,
        tagsHidden,
        isMetaInfoTagsSeparator,
        goal_id,
        onEditTags,
        onRemoveTag,
        emptyTagsPlaceholder,
        className,
        removable,
        icon,
        itemType
    } = props;

    return (
        <div className={cx('meta-info-container', className)}>
            {!!owner && (
                <span className="meta-info-item-wrapper">
                    <span className="meta-info meta-info-tags-border-line">
                        <Icon name="user" /> {owner}
                    </span>
                </span>
            )}

            {!!author && (
                <span className="meta-info-item-wrapper">
                    <span className="meta-info meta-info-tags-border-line">
                        <Icon name="pencil" /> {author}
                    </span>
                </span>
            )}

            {date && (
                <span className="meta-info-item-wrapper">
                    <span className="meta-info meta-info-tags-border-line">
                        <Icon name="calendar-alt" /> <FormattedDate date={date} />
                    </span>
                </span>
            )}

            {(start_date || closed_date || target_date) && dates.map((item) => {
                if (!props[item.prop]) {
                    return null;
                }

                return (
                    <span key={item.label} className="meta-info-item-wrapper">
                        <span className="meta-info meta-info-tags-border-line">
                            <Icon name="calendar-alt" /> <b>{item.label}:</b>
                            <FormattedDate date={props[item.prop]} />
                        </span>
                    </span>
                );
            })}

            {(tags && tags.length > 0 && isMetaInfoTagsSeparator && !tagsHidden) && (
                <span className="meta-info-tags-separator">&nbsp;</span>
            )}

            {!tagsHidden && (
                <Fragment>
                    {(tags || []).map((item, idx) => (
                        <span key={item.id} className="meta-info-item-wrapper">
                            <div className="meta-info meta-info-tags-border-dashed">
                                <div className="meta-info-inner">
                                    <Icon name="tags" />
                                    <TagLink
                                        item={item}
                                        goal_id={goal_id}
                                        className="card-footer-tags-item"
                                    >
                                        {`${item.title}`}
                                    </TagLink>

                                    {(editable || removable) && (
                                        <Fragment>
                                            <span className="tag-controls-separator" />
                                            <Clickable
                                                className="tags-remove-button"
                                                action="remove-tags"
                                                onClick={e => onRemoveTag(item.id, e)}
                                            >
                                                <Icon className="tags-remove-button-icon" name="times" />
                                            </Clickable>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                            {(editable && idx >= tags.length - 1) && (
                                <Clickable
                                    className="tags-edit-button"
                                    action="edit-tags"
                                    onClick={onEditTags}
                                >
                                    <Icon className="tags-edit-button-icon" name="plus-circle" /> Add tag
                                </Clickable>
                            )}
                        </span>
                    ))}

                    {(editable && !tags.length) && (
                        <span className="meta-info-item-wrapper">
                            <div className="meta-info-inner">
                                <Clickable
                                    className="tags-edit-button tags-edit-button-single no-divider"
                                    action="edit-tags"
                                    onClick={onEditTags}
                                >
                                    <Icon className="tags-edit-button-icon" name="plus-circle" /> Add tag
                                </Clickable>
                            </div>
                        </span>
                    )}
                </Fragment>
            )}

            {((!tags || (tags && !tags.length)) && emptyTagsPlaceholder) && (
                <div className="meta-info-container-placeholder">
                    {emptyTagsPlaceholder}
                </div>
            )}

            {(itemType && icon) && (
                <span className="meta-info meta-info-item-type">
                    <Icon name={icon} /> <Text overflowEllipsis>{itemType}</Text>
                </span>
            )}
        </div>
    );
}

MetaInfo.defaultProps = {
    owner: undefined,
    author: undefined,
    date: undefined,
    start_date: undefined,
    closed_date: undefined,
    target_date: undefined,
    tags: [],
    editable: false,
    goal_id: undefined,
    isMetaInfoTagsSeparator: false,
    tagsHidden: false,
    emptyTagsPlaceholder: '',
    className: undefined,
    removable: false,
    onEditTags: () => {},
    onRemoveTag: () => {},
    icon: undefined,
    itemType: undefined,
};

MetaInfo.propTypes = {
    owner: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.string,
    start_date: PropTypes.string,
    closed_date: PropTypes.string,
    target_date: PropTypes.string,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        })
    ),
    editable: PropTypes.bool,
    goal_id: PropTypes.number,
    isMetaInfoTagsSeparator: PropTypes.bool,
    tagsHidden: PropTypes.bool,
    onEditTags: PropTypes.func,
    onRemoveTag: PropTypes.func,
    emptyTagsPlaceholder: PropTypes.string,
    className: PropTypes.string,
    removable: PropTypes.bool,
    icon: PropTypes.string,
    itemType: PropTypes.string,
};
