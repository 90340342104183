/** @namespace Utils.Formatters */

import { flow } from 'lodash';
import { roundDecimal } from './math';
import { types, } from '../../modules/goalmap-milestones/constants';

/**
 * Adds commas between thousands
 * @function commasBetweenThousands
 * @memberof Utils.Formatters
 * @param {number} num Number that will be formatted
 * @return {string} Formatted number
 */
export function commasBetweenThousands(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/**
 * Round number decimal and represents long number in readable format
 * @function formatNumber
 * @memberof Utils.Formatters
 * @param {number} value Number that will be formatted
 * @return {string} Formatted number
 */
export const formatNumber = flow(
    roundDecimal,
    commasBetweenThousands
);


/**
 * Detects if reducing thousands of the number to K is needed
 * @function shouldReduceNumberThousandToK
 * @memberof Utils.Formatters
 * @param {number} value Number
 * @return {boolean}
 */
export function shouldReduceNumberThousandToK(num) {
    return Math.abs(num) > 999;
}

/**
 * Formats number and replaces thousands with `k` symbol
 * @function reduceNumberThousandsToK
 * @memberof Utils.Formatters
 * @param {number} value Number that will be formatted
 * @return {string} Formatted number
 */
export function reduceNumberThousandToK(num) {
    if (shouldReduceNumberThousandToK(num)) {
        return `${commasBetweenThousands(Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)))}k`;
    }

    return Math.sign(num) * Math.abs(num);
}

/**
 * Format user profile data
 * @function formatUserData
 * @memberof Utils.Formatters
 * @param {Object} params Profile data that will be formatted
 * @return {Object} Formatted profile data
 */
export default function formatUserData(params) {
    const myParams = { ...params };
    if (myParams.user_profile.phone_mobile) {
        myParams.user_profile.phone_mobile = myParams.user_profile.phone_mobile.toString();
    }
    if (myParams.user_profile.phone_work) {
        myParams.user_profile.phone_work = myParams.user_profile.phone_work.toString();
    }
    if (myParams.user_profile.phone_mobile && !myParams.user_profile.phone_mobile.includes('+')) {
        myParams.user_profile.phone_mobile = `+${myParams.user_profile.phone_mobile}`;
    }
    if (myParams.user_profile.phone_work && !myParams.user_profile.phone_work.includes('+')) {
        myParams.user_profile.phone_work = `+${myParams.user_profile.phone_work}`;
    }
    return myParams;
}

/**
 * Add currency to value
 * @function formatCurrency
 * @memberof Utils.Formatters
 * @param {number} value Value which will by formatted
 * @param {string} prefix Added prefix
 * @return {string} Value with currency
 */
export function formatCurrency(value = 0, prefix = '$') {
    return `${prefix}${value.toFixed(2)}`;
}

/**
 * create composite function for to format the value
 * @function formatValue
 * @memberof Utils.Formatters
 * @return {func} Returns the new composite function
 */
export const formatValue = flow(roundDecimal, commasBetweenThousands);

/**
 * make format the value and add unit of measure according to kpi of measure
 * @function formatValueForDataPoints
 * @memberof Utils.Formatters
 * @param {string} kpiTypeOfMeasure KPI type of measure
 * @param {number} value Value which will by formatted
 * @param {string} currencySymbol Currency symbol
 * @return {string} formatted Value with unit of measure
 */
export function formatValueForDataPoints(kpiTypeOfMeasure, value, currencySymbol = '$') {
    switch (kpiTypeOfMeasure) {
        case types.monetary: return `${currencySymbol} ${formatValue(value)}`;
        case types.percentage: return `${formatValue(value)} %`;
        default: return formatValue(value);
    }
}

export const tooltipFormatter = {
    thousandsCommaSeparator(unitOfMeasure, isMonetaryKpi) {
        const pointY = commasBetweenThousands(this.point.y);
        return `
            <div class='tooltip-container'>
                <div style="text-align: center; font-size:14px; color: ${this.point.marker.fillColor}">
                    ${isMonetaryKpi
        ? `${unitOfMeasure} ${pointY}`
        : `${pointY} ${unitOfMeasure || ''}`
}
                </div>
                <div style="display: ${this.point.tooltipData.trend ? 'block' : 'none'};
                    text-align: center; font-size:14px; color: ${this.point.tooltipData.trendColor}"
                >
                    ${this.point.tooltipData.trendArrowHtml}
                    ${Math.abs(this.point.tooltipData.trend)}%
                </div>
                <div style="font-size: 11px; color: grey; text-align: center;">${this.point.tooltipData.date}</div>
            </div>`;
    }
};
