import u from 'updeep';
import { createReducer } from 'redux-act';
import { get, find } from 'lodash';
import {
    removeFromCollection,
    updateInCollection,
    prependToCollection,
    prependMultipleToCollection,
    prependArrayToCollection,
    mergeInCollection,
    push as pushToCollection,
} from '../../utils/collection';
import { incrementChildrenCounter } from '../../utils/entity';

const initialState = {
    items: [],
    pager: {
        current: 1,
        totalItems: 0,
        cursor: null,
    },
    params: {},
    loading: false,
    loadingMore: false,
    error: null,
    updatingItemId: undefined,
    lastFetchTime: undefined,
    raw: {},
};

function onRequest(state, action = {}) {
    const { silent, ...restParams } = action;

    if (silent) {
        return u({
            params: restParams,
        }, state);
    }

    return u(
        {
            items: [],
            loading: true,
            error: null,
            pager: {},
            raw: {},
            params: restParams,
        },
        state
    );
}

function updatePagerData(state, newItems) {
    const totalItems = state.pager.totalItems - 1;
    const totalPages = Math.ceil(totalItems / state.pager.perPage);
    const isEmptyPage = !newItems.length;
    const isNeedMoveToPreviousPage = state.pager.current > 1 && isEmptyPage;
    const newCurrentPage = isNeedMoveToPreviousPage
        ? Math.max(totalPages, 1)
        : state.pager.current;

    return {
        current: newCurrentPage,
        perPage: state.pager.perPage,
        totalItems,
        totalPages
    };
}

function onUpdateRaw(state, action) {
    return u.updateIn(
        'raw',
        action.data,
        state,
    );
}

function onIncrementNestedChildCounter(state, action) {
    const item = find(state.items, { id: action.id });

    if ('entity_children_counters' in item) {
        const counters = get(item, 'entity_children_counters', {});
        const nextCounters = incrementChildrenCounter(counters, action.typeCode, action.state);
        const nextItem = u.updateIn('entity_children_counters', nextCounters, item);

        return u({
            items: mergeInCollection(state.items, action.id, nextItem)
        }, state);
    }

    const nextItem = u({ child_count: get(item, 'child_count', 0) + 1 }, item);

    return u({
        items: mergeInCollection(state.items, action.id, nextItem)
    }, state);
}

export default function createReducerFn({
    request,
    success,
    error,
    requestMore,
    requestMoreSuccess,
    requestMoreError,
    add,
    addMultiple,
    addBulk,
    push,
    replace,
    reset,
    update,
    remove,
    setUpdatingItemId,
    setParams,
    updateRaw,
    incrementCounter,
    incrementNestedChildCounter,
}, additionalReducers = {}, options) {
    return createReducer({
        [add]: (state, action) => {
            let newState = state;

            if (additionalReducers.onAdd) {
                newState = additionalReducers.onAdd(state, action);
            }

            if (!options.ignoreDraftMark && 'state' in action && action.state === 4) {
                return newState;
            }

            return u(
                {
                    items: prependToCollection(newState.items, action),
                    counter: state.counter + 1,
                },
                newState,
            );
        },
        [addMultiple]: (state, action) => {
            const { data } = action;

            if (data) {
                return u(
                    {
                        items: prependMultipleToCollection(state.items, data),
                        counter: state.counter + data.length,
                    },
                    state,
                );
            }

            return state;
        },
        [addBulk]: (state, action) => u(
            {
                items: prependArrayToCollection(state.items, action),
                counter: state.counter + action.length,
            },
            state,
        ),
        [push]: (state, action) => u(
            {
                items: pushToCollection(state.items, action),
                counter: state.counter + 1,
            },
            state,
        ),
        [replace]: (state, action) => u(
            {
                items: action,
                counter: action.length,
            },
            state,
        ),
        [setUpdatingItemId]: (state, action) => u(
            {
                updatingItemId: action.id,
            },
            state,
        ),
        [setParams]: (state, action) => u(
            {
                params: action,
            },
            state,
        ),
        [remove]: (state, action) => {
            let newState = state;
            const idKey = action.idKey ? action.idKey : 'id';

            if (additionalReducers.onRemove) {
                newState = additionalReducers.onRemove(state, action);
            }
            const newItems = removeFromCollection(newState.items, action.id, idKey);
            return u(
                {
                    items: newItems,
                    updatingItemId: undefined,
                    counter: state.counter - 1,
                    pager: updatePagerData(state, newItems),
                },
                newState,
            );
        },
        [update]: (state, action) => {
            if (action.state === 'destroy') {
                const newItems = removeFromCollection(state.items, action.id);

                return u(
                    {
                        items: newItems,
                        updatingItemId: undefined,
                        counter: state.counter - 1,
                        pager: updatePagerData(state, newItems),
                    },
                    state
                );
            }

            if (action.merge_update) {
                return u(
                    {
                        items: mergeInCollection(state.items, action.id, action, action.idKey),
                        updatingItemId: undefined
                    },
                    state
                );
            }

            return u(
                {
                    items: updateInCollection(state.items, action.id, action, action.idKey),
                    updatingItemId: undefined
                },
                state
            );
        },
        [request]: onRequest,
        [success]: (state, response) => {
            const list = response.data || response;
            const counter = response.total || list.length;
            // clearing `state.raw` to prevent object merge
            const newState = u({ raw: null }, state);

            return u(
                {
                    loading: false,
                    items: response.data || response,
                    lastFetchTime: new Date(),
                    counter,
                    pager: {
                        current: response.current_page,
                        perPage: response.per_page,
                        totalItems: response.total,
                        totalPages: response.last_page,
                        cursor: response.cursor,
                    },
                    permissions: response.permissions,
                    raw: response,
                },
                newState
            );
        },
        [error]: (state, response) => u(
            {
                loading: false,
                error: get(response, ['response', 'data'], response),
            },
            state
        ),
        [reset]: () => initialState,

        [requestMore]: state => u(
            {
                loadingMore: true,
                error: null,
            },
            state
        ),

        [requestMoreSuccess]: (state, response) => {
            const list = response.data || response;
            const counter = response.total || list.length;

            return u(
                {
                    loadingMore: false,
                    items: state.items.concat(response.data),
                    counter,
                    pager: {
                        current: Number(response.current_page),
                        perPage: response.per_page,
                        totalItems: response.total,
                        totalPages: response.last_page,
                        cursor: response.cursor,
                    },
                    permissions: response.permissions,
                    raw: response,
                },
                state
            );
        },
        [requestMoreError]: (state, response) => u(
            {
                loadingMore: false,
                error: get(response, ['response', 'data'], response),
            },
            state
        ),
        [incrementCounter](state) {
            const result = u.updateIn(
                'pager.totalItems',
                state.items.length,
                state
            );
            return result;
        },
        [updateRaw]: onUpdateRaw,
        [incrementNestedChildCounter]: onIncrementNestedChildCounter,
        ...additionalReducers,
    }, initialState);
}

export function createSelector(name) {
    return state => state[name];
}
