import moment from 'moment';
import { get } from 'lodash';
import config from '../../../config';
import { selectUserId } from '../../user/selectors';
import { parseThresholds, computeDefaultTresholdValue } from '../utils';
import {
    types,
    frequencies,
    requiredFrom,
    attributes,
    kpiTypes,
    rangeOfMeasure,
    operations,
    periodTargetAttributes as PTAttrs,
    entityType,
} from '../constants';
import { getInitialValues, entityState } from '../../../shared/utils/entity';
import { getEntityCode } from '../../../shared/utils/entity-type';

const defaultThresholdValue = computeDefaultTresholdValue(rangeOfMeasure.asc);

const targetInitialValue = moment()
    .add(3, 'months')
    .endOf('month')
    .format(config.apiResponseDateFormat);

const startPeriodInitialValue = moment()
    .endOf('month')
    .format(config.apiResponseDateFormat);

const defaultInitialValues = {
    [attributes.progress_bar]: JSON.stringify({}),
    [attributes.target_at]: targetInitialValue,
    [attributes.thresholds]: defaultThresholdValue,
    [attributes.type]: types.numeric,
    [attributes.frequency]: frequencies.monthly,
    [attributes.target_value]: 100,
    [attributes.started_at]: startPeriodInitialValue,
    [attributes.required_from]: requiredFrom.firstDay,
    [attributes.kpi_type]: kpiTypes.static.type,
    [attributes.range_of_measure]: rangeOfMeasure.asc,
    [attributes.health_tolerance_threshold]: defaultThresholdValue[2],
    [attributes.health_tolerance_bound]: defaultThresholdValue[1],
    [attributes.tags]: [],
    [attributes.start_value]: 0,
    [attributes.description]: '',
    [attributes.advanced_calculation_type]: operations.sum,
    [attributes.advanced_calculation_enabled]: false,
    [attributes.advanced_calculation_sources]: [],
    // [attributes.parent_entity_type]: locations.global
};

export function parse(data) {
    const thresholds = parseThresholds(data);

    return {
        ...data,
        [attributes.thresholds]: thresholds,
    };
}

export function mapInitialValuesUpdate(milestoneAttributes) {
    return {
        [attributes.id]: get(milestoneAttributes, attributes.id),
        [attributes.progress_bar]: get(milestoneAttributes, attributes.progress_bar),
        [attributes.author_id]: get(milestoneAttributes, attributes.author_id),
        [attributes.goal_id]: get(milestoneAttributes, attributes.goal_id),
        [attributes.owner_id]: get(milestoneAttributes, attributes.owner_id),
        [attributes.thresholds]: parseThresholds(milestoneAttributes),
        [attributes.bound_left]: get(milestoneAttributes, attributes.bound_left, 0),
        [attributes.threshold_left]: get(milestoneAttributes, attributes.threshold_left),
        [attributes.threshold_right]: get(milestoneAttributes, attributes.threshold_right),
        [attributes.bound_right]: get(milestoneAttributes, attributes.bound_right),
        [attributes.type]: get(milestoneAttributes, attributes.type),
        [attributes.frequency]: get(milestoneAttributes, attributes.frequency),
        [attributes.target_value]: get(milestoneAttributes, attributes.target_value),
        [attributes.started_at]: get(milestoneAttributes, attributes.started_at),
        [attributes.target_at]: get(milestoneAttributes, attributes.target_at),
        [attributes.target_period]: get(milestoneAttributes, attributes.target_period),
        [attributes.required_from]: get(milestoneAttributes, attributes.required_from),
        [attributes.title]: get(milestoneAttributes, attributes.title),
        [attributes.tag_name]: get(milestoneAttributes, attributes.tag_name),
        [attributes.description]: get(milestoneAttributes, attributes.description),
        [attributes.kpi_type]: get(milestoneAttributes, attributes.kpi_type),
        [attributes.kpi_period_targets]: get(milestoneAttributes, attributes.kpi_period_targets),
        [attributes.range_of_measure]: get(milestoneAttributes, attributes.range_of_measure),
        [attributes.health_tolerance_threshold]:
            get(milestoneAttributes, attributes.health_tolerance_threshold),
        [attributes.health_tolerance_bound]:
            get(milestoneAttributes, attributes.health_tolerance_bound),
        [attributes.health_tolerance_value]:
            get(milestoneAttributes, attributes.health_tolerance_value),
        [attributes.tags]:
            get(milestoneAttributes, attributes.tags),
        [attributes.state]: get(milestoneAttributes, attributes.state),
        [attributes.start_value]: get(milestoneAttributes, attributes.start_value) || 0,
        [attributes.advanced_calculation_enabled]:
            get(milestoneAttributes, attributes.advanced_calculation_enabled),
        [attributes.advanced_calculation_type]: get(
            milestoneAttributes,
            attributes.advanced_calculation_type,
            get(defaultInitialValues, attributes.advanced_calculation_type),
        ),
        [attributes.advanced_calculation_sources]: get(
            milestoneAttributes,
            attributes.advanced_calculation_sources,
            get(defaultInitialValues, attributes.advanced_calculation_sources)
        ),
        [attributes.parent_entity_id]: get(milestoneAttributes, attributes.parent_entity_id),
        [attributes.parent_entity_type]: get(milestoneAttributes, attributes.parent_entity_type),
        [attributes.currency_format]: get(milestoneAttributes, attributes.currency_format),
    };
}

export function mapInitialCreateValuesMilestone(state, ownProps, canCreateDraft) {
    const userId = selectUserId(state);

    return {
        ...getInitialValues(defaultInitialValues, attributes),
        [attributes.author_id]: userId,
        [attributes.goal_id]: get(ownProps, attributes.goal_id),
        [attributes.owner_id]: userId,
        [attributes.start_value]: get(defaultInitialValues, attributes.start_value) || 0,
        [attributes.kpi_period_targets]: get(defaultInitialValues, attributes.kpi_period_targets, [])
            .map(it => ({
                ...it,
                [PTAttrs.target_value]: get(it, PTAttrs.target_value, 0)
            })),
        [attributes.thresholds]: get(defaultInitialValues, attributes.thresholds),
        [attributes.parent_entity_id]: get(ownProps, attributes.parent_entity_id),
        [attributes.parent_entity_type]: get(
            ownProps,
            attributes.parent_entity_type,
            defaultInitialValues[attributes.parent_entity_type]
        ),
        [attributes.state]: canCreateDraft ? entityState.draft : entityState.active,
        [attributes.entity_type]: entityType,
    };
}

export function mapInitialCreateValuesMilestoneFromParent(state, data, canCreateDraft) {
    const userId = selectUserId(state);

    return {
        ...getInitialValues(defaultInitialValues, attributes),
        [attributes.author_id]: userId,
        [attributes.goal_id]: get(data, attributes.goal_id),
        [attributes.owner_id]: userId,
        [attributes.start_value]: get(defaultInitialValues, attributes.start_value) || 0,
        [attributes.kpi_period_targets]: get(defaultInitialValues, attributes.kpi_period_targets, [])
            .map(it => ({
                ...it,
                [PTAttrs.target_value]: get(it, PTAttrs.target_value, 0)
            })),
        [attributes.thresholds]: get(defaultInitialValues, attributes.thresholds),
        [attributes.parent_entity_id]: get(data, attributes.id),
        [attributes.parent_entity_type]: getEntityCode(data),
        [attributes.state]: canCreateDraft ? entityState.draft : entityState.active,
    };
}
