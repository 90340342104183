import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { findScrollableContainer } from '../../utils/dom';
import { isSupportsPassive, supportedWheelEvent } from '../../utils/detection';

const exclusions = [
    el => el.nodeName === 'BODY',
    el => el.classList.contains('Select-menu'),
];

export default class BodyScrollLock extends Component {
    static validateScrollPosition(delta, scrollTop, clientHeight, scrollHeight) {
        return (
            delta === 0
            || (delta < 0 && scrollTop === 0)
            || (delta > 0 && (scrollTop + clientHeight) === scrollHeight)
        );
    }

    constructor(props) {
        super(props);

        this.scrollableContainer = null;
        this.prevClientY = null;
        this.updateScrollableContainerNeeded = false;
    }

    componentDidMount() {
        this.containerEl = findDOMNode(this);
        const opts = isSupportsPassive() ? { passive: false } : false;

        this.containerEl.addEventListener(supportedWheelEvent(), this.handleMouseWheel, opts);
        this.containerEl.addEventListener('touchmove', this.handleTouch, opts);
        this.containerEl.addEventListener('touchstart', this.registerCoord);
    }

    componentDidUpdate() {
        this.updateScrollableContainerNeeded = true;
    }

    componentWillUnmount() {
        this.containerEl.removeEventListener(supportedWheelEvent(), this.handleMouseWheel);
        this.containerEl.removeEventListener('touchmove', this.handleTouch);
        this.containerEl.removeEventListener('touchstart', this.registerCoord);
    }

    getClientTouchYDelta = (e) => {
        const touch = e.changedTouches[0];

        return this.prevClientY - touch.clientY;
    }

    getClientWheelYDelta = (e) => {
        return e.deltaY;
    }

    setTouchPrevPosition = (e) => {
        this.prevClientY = e.changedTouches[0].clientY;
    }

    handleMouseWheel = (e) => {
        this.handleInteraction(e, this.getClientWheelYDelta);
    }

    handleTouch = (e) => {
        this.handleInteraction(e, this.getClientTouchYDelta, this.setTouchPrevPosition);
    }

    registerCoord = (e) => {
        const touch = e.changedTouches[0];
        this.prevClientY = touch.clientY;
    }

    handleInteraction = (e, clientYDiffGetter, prevPositionSetter) => {
        const delta = clientYDiffGetter(e);

        if (prevPositionSetter) prevPositionSetter(e);

        const container = this.updateScrollableContainer(e.target);

        if (this.shouldPreventDefault(e, container, delta)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    shouldPreventDefault(e, container, delta) {
        const shouldBeExcluded = !!exclusions.find(comparator => comparator(this.scrollableContainer));

        if (shouldBeExcluded) return false;

        const { scrollTop, clientHeight, scrollHeight } = container;

        return e.cancelable && BodyScrollLock.validateScrollPosition(delta, scrollTop, clientHeight, scrollHeight);
    }

    updateScrollableContainer(target) {
        const container = (this.scrollableContainer && !this.updateScrollableContainerNeeded)
            ? this.scrollableContainer
            : findScrollableContainer(target);

        this.scrollableContainer = container;
        this.updateScrollableContainerNeeded = false;

        return container;
    }

    render() {
        return this.props.children;
    }
}

BodyScrollLock.propTypes = {
    children: PropTypes.func.isRequired,
};
