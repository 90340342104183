import _ from 'lodash';
import { strategies } from './constants';

export function computeSegmentWidths(values, strategy, readOnly) {
    let currentVals = values;
    if (strategy === strategies.between) {
        const midIdx = (values.length - 1) / 2;
        currentVals = values.slice(0, midIdx).concat(values.slice(midIdx + 1));
    }
    const delta = values[values.length - 1] - values[0];
    const percent = delta / 100;

    const result = currentVals.reduce((accum, value, index, arr) => {
        if (index === 0) {
            return accum;
        }

        const width = (value - arr[index - 1]) / percent;

        if (readOnly && index !== currentVals.length - 1) {
            accum.push(width - 1);
            accum.push(1);
        } else {
            accum.push(width);
        }

        return accum;
    }, []);

    return result;
}

export function computeValueWidth(values, containerWidth, descOrder) {
    const delta = descOrder
        ? values[0] - values[values.length - 1]
        : values[values.length - 1] - values[0];

    const result = containerWidth / delta;

    return result;
}

export function computeFieldPositions(
    values,
    containerWidth,
    fieldWidth,
    descOrder,
    anchorItemIndex,
) {
    const valueWidth = computeValueWidth(values, containerWidth, descOrder);
    const result = [];
    const leftPartValues = values.slice(0, anchorItemIndex + 1);
    const rightPartValues = values.slice(anchorItemIndex, values.length);
    const startValue = values[0];

    let prevPos = Infinity;

    for (let i = leftPartValues.length - 1; i >= 0; i -= 1) {
        const value = leftPartValues[i];
        const pos = Math.abs(value - startValue) * valueWidth;

        if (((pos + fieldWidth) >= prevPos)) {
            result.unshift(prevPos - fieldWidth);
            prevPos -= fieldWidth;
        } else {
            result.unshift(pos);
            prevPos = pos;
        }
    }

    let prevPrevPos = -Infinity;
    for (let i = 0; i < rightPartValues.length; i += 1) {
        const value = rightPartValues[i];
        const pos = Math.abs(value - startValue) * valueWidth;

        if (((pos - fieldWidth) <= prevPrevPos)) {
            result.push(prevPrevPos + fieldWidth);
            prevPrevPos += fieldWidth;
        } else {
            result.push(pos);
            prevPrevPos = pos;
        }
    }

    return _.uniq(result);
}
