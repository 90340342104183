import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Text from '../text';
import LoadingIndicator from '../loading-indicator/component';
import TableViewHeader from './table-view-header';
import TableViewBody from './table-view-body';
import TableViewRow from './table-view-row';
import TableViewCell from './table-view-cell';
import TableViewHeaderCell from './table-view-header-cell';
import TableViewPlaceholder from './table-view-placeholder';
import WithSort from './with-sort';
import ListRenderer from './table-renderer';

import FormatterEntityIcon from './formatters/entity-icon';
import FormatterStatus from './formatters/status';
import FormatterOutDate from './formatters/outdate';
import FormatterUser from './formatters/user';

import './styles.css';

export default function TableView({
    children, className, placeholder, isLoading, noResults
}) {
    return (
        <div className={cx('table-view', className)}>
            <table className="table-view-grid">
                {children}
            </table>

            {noResults && <Text className="table-view-no-results">No Results</Text>}

            {isLoading && <LoadingIndicator centered />}

            {placeholder}
        </div>
    );
}

TableView.Header = TableViewHeader;
TableView.HeaderCell = TableViewHeaderCell;
TableView.Body = TableViewBody;
TableView.Row = TableViewRow;
TableView.Cell = TableViewCell;
TableView.Placeholder = TableViewPlaceholder;
TableView.WithSort = WithSort;
TableView.ListRenderer = ListRenderer;
TableView.formatters = {
    EntityIcon: FormatterEntityIcon,
    Status: FormatterStatus,
    OutDate: FormatterOutDate,
    User: FormatterUser
};

TableView.align = {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
};

TableView.defaultProps = {
    children: undefined,
    className: undefined,
    placeholder: undefined,
    isLoading: false,
    noResults: false,
};

TableView.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    isLoading: PropTypes.bool,
    noResults: PropTypes.bool,
};
