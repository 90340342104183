import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '../../../shared/components';

export default function NotificationAuthor({
    component,
    avatar,
    first_name,
    last_name,
    ...rest
}) {
    const Component = component;
    return (
        <Component className="notifications-item-avatar-container" {...rest}>
            <Avatar
                src={avatar}
                className="notifications-item-avatar"
                first_name={first_name}
                last_name={last_name}
            />
        </Component>
    );
}

NotificationAuthor.defaultProps = {
    component: 'div',
};

NotificationAuthor.propTypes = {
    avatar: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]),
};
