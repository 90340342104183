import PropTypes from 'prop-types';
import React from 'react';
import { isBoolean, isString } from 'lodash';
import cx from 'classnames';
import Icon from '../icon/component';
import Tooltip from '../tooltip/component';
import InputErrorMessage from './error-message';

export default function InputContainer({
    children,
    errors,
    touched,
    label,
    tooltip,
    className,
    description,
    noMargin,
    horizontalLabel,
    tooltipTextClassName
}) {
    return (
        <div
            className={cx('input-container', {
                'no-margin': noMargin,
                'input-container-hor-label': horizontalLabel,
                [className]: className
            })}
        >
            {label && (
                <span className={cx('input-container-label')}>
                    <span className="input-container-label-wrap">
                        {label}
                    </span>
                    {tooltip && (
                        <Tooltip
                            className="input-container-tooltip"
                            tooltipTextClassName={tooltipTextClassName}
                            text={tooltip}
                            disabled={(isBoolean(tooltip) && tooltip) || (isString(tooltip) && !tooltip)}
                        >
                            <Icon className="input-container-tooltip-icon" type={Icon.types.regular} name="info-circle" />
                        </Tooltip>
                    )}
                </span>
            )}

            <div className="input-wrap">
                {children}
            </div>

            {!!description && (
                <div className="input-container-description">
                    {description}
                </div>
            )}

            <InputErrorMessage touched={touched} errors={errors} />
        </div>
    );
}

InputContainer.defaultProps = {
    errors: [],
    children: undefined,
    tooltip: undefined,
    tooltipTextClassName: undefined,
    className: undefined,
    label: undefined,
    description: undefined,
    noMargin: false,
    touched: true,
    horizontalLabel: false
};

InputContainer.propTypes = {
    children: PropTypes.node,
    errors: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    tooltipTextClassName: PropTypes.string,
    tooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    label: PropTypes.node,
    className: PropTypes.string,
    noMargin: PropTypes.bool,
    touched: PropTypes.bool,
    horizontalLabel: PropTypes.bool,
};
