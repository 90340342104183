import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Select } from '../../../shared/modules';
import { getActiveGoalmapsItems, getGoalmapsItems } from '../../../modules/goalmap-list/selector';

function GoalMapSelect({
    goals, options, filteredOptions, extraOptions, ...rest
}) {
    let resultOptions = [];
    const myOptions = goals.length ? goals : options;
    resultOptions = myOptions.reduce((arr, item) => {
        if (!filteredOptions.includes(item.id)) {
            arr.push({ id: item.id, title: item.title, state: item.state });
        }
        return arr;
    }, []);
    resultOptions = [
        ...extraOptions,
        ...resultOptions,
    ];
    return (
        <Select
            options={resultOptions.length ? resultOptions : false}
            cache={false}
            {...rest}
        />
    );
}

GoalMapSelect.defaultProps = {
    options: [],
    filteredOptions: [],
    extraOptions: [],
    goals: [],
    activeOnly: false
};

GoalMapSelect.propTypes = {
    filteredOptions: PropTypes.arrayOf(
        PropTypes.number,
    ),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            display_name: PropTypes.string,
        }),
    ),
    extraOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            display_name: PropTypes.string,
        }),
    ),
    goals: PropTypes.array,
    activeOnly: PropTypes.bool
};

function mapStateToProps(state, { activeOnly }) {
    return {
        options: activeOnly
            ? getActiveGoalmapsItems(state)
            : getGoalmapsItems(state),
    };
}

export default connect(
    mapStateToProps
)(GoalMapSelect);
