import { createReducer, createAction } from 'redux-act';
import u from 'updeep';

export const name = 'tree-navigation';
const prefix = 'tree-navigation';

export const toggle = createAction(`${prefix}/TOGGLE`);
export const hide = createAction(`${prefix}/HIDE`);
export const setContext = createAction(`${prefix}/SET_CONTEXT`);
export const setContextState = createAction(`${prefix}/SET_CONTEXT_STATE`);

const initialState = {
    visible: true,
    context: {
        id: undefined,
        type_codes: undefined,
        state: undefined,
    },
};

export default createReducer({
    [toggle]: state => u({ visible: !state.visible }, state),
    [hide]: state => u({ visible: false }, state),
    [setContext]: (state, action) => u({ context: action }, state),
    [setContextState]: (state, action) => u({ context: { state: action } }, state),
}, initialState);

export const selectStateNavigatorTreePersistState = state => state[name];

export const getVisibleTreeNavigation = state => state[name].visible;

export const getNavigatorContext = state => state[name].context;
