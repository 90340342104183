import { Component } from 'react';
import PropTypes from 'prop-types';
import { wait } from '../../utils/common';

export default class DeferredListRenderer extends Component {
    state = {
        mounted: false
    }

    componentDidMount() {
        wait(0).then(() => this.setState({ mounted: true }));
    }

    render() {
        const { children, initialCount, items } = this.props;
        const { mounted } = this.state;
        return children(mounted ? items : items.slice(0, initialCount));
    }
}

DeferredListRenderer.defaultProps = {
    initialCount: 10
};

DeferredListRenderer.propTypes = {
    children: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    initialCount: PropTypes.number
};
