import React from 'react';
import PropTypes from 'prop-types';

export default function NavigationDrawerCounter({ counter }) {
    if (!counter) {
        return null;
    }

    return (
        <span className="nav-right-item-counter">
            {counter}
        </span>
    );
}

NavigationDrawerCounter.propTypes = {
    counter: PropTypes.number.isRequired,
};
