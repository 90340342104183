import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import {
    Col, Form, InputDate, Row,
} from '../../../shared/components';
import { Select } from '../../../shared/modules';
import { createSensitivityOptions } from '../utils';
import { isValidDate, getApiFormattedDate } from '../../../shared/utils/date';
import '../styles/details-form.css';

const fieldsDate = [
    { name: 'start_date', label: 'Start Date' },
    { name: 'target_date', label: 'Target Date' },
    { name: 'closed_date', label: 'Closed Date' }
];
const fieldsParams = [
    {
        name: 'frequency',
        id: 'plan-frequency-select',
        label: 'Frequency',
        options: [
            { id: 'daily', title: 'Daily' },
            { id: 'weekly', title: 'Weekly' },
            { id: 'monthly', title: 'Monthly' },
            { id: 'quarterly', title: 'Quarterly' },
        ]
    },
    {
        name: 'priority',
        id: 'plan-priority-select',
        label: 'Priority',
        options: [
            { id: 'low', title: 'Low' },
            { id: 'medium', title: 'Medium' },
            { id: 'high', title: 'High' },
        ]
    },
    {
        name: 'sensitivity',
        id: 'plan-sensitivity-select',
        label: 'Sensitivity',
        options: []
    },
];

class PlanDetailsForm extends Component {
    componentDidUpdate(prevProps) {
        const { initialValues, initialize, initialized } = this.props;
        if (initialized === false && prevProps.initialized === false) {
            initialize(initialValues);
        }
    }

    submit = () => {
        setTimeout(() => this.submitButton.click(), 0);
    }

    onKeyDown = (e) => {
        const { key, target } = e;
        if (key === 'Enter' && !isValidDate(target?.value)) {
            e.preventDefault();
        }
    };

    parseDate = (value) => {
        const { userDateFormat } = this.props;
        if (isEmpty(value)) return value;
        return isValidDate(value) ? getApiFormattedDate(value, userDateFormat) : null;
    }

    render() {
        const {
            onSubmit, handleSubmit, editable, sensitivities, fieldContainer, isWorkCenterRightDrawer,
        } = this.props;
        const sensitivityOptions = createSensitivityOptions(sensitivities);
        const FieldContainer = fieldContainer;

        return (
            <Form
                className="plan-details-form"
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                autoComplete="off"
                onKeyDown={this.onKeyDown}
            >
                <>
                    {!isWorkCenterRightDrawer ? (
                        <Row className="plan-details-form-fields-wrapper">
                            <Col className="plan-details-form-fields-group" base={12}>
                                <Row className="plan-details-form-field-row">
                                    {fieldsDate.map(field => (
                                        <Col xs={12} md={4} lg={4} key={field.name}>
                                            <FieldContainer>
                                                <Field
                                                    name={field.name}
                                                    label={field.label}
                                                    formGroupClassName="plan-details-form-group"
                                                    component={InputDate}
                                                    onChange={this.submit}
                                                    parse={this.parseDate}
                                                    props={{
                                                        alwaysRenderLabel: true,
                                                        disabled: !editable,
                                                        isClearable: true,
                                                    }}
                                                />
                                            </FieldContainer>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                            <Col className="plan-details-form-fields-group" base={12}>
                                <Row className="plan-details-form-field-row">
                                    {fieldsParams.map(field => (
                                        <Col xs={12} md={4} lg={4} key={field.name}>
                                            <FieldContainer>
                                                <Field
                                                    name={field.name}
                                                    id={field.id}
                                                    label={field.label}
                                                    placeholder={field.label}
                                                    formGroupClassName="plan-details-form-group"
                                                    component={Select}
                                                    searchable={false}
                                                    clearable={false}
                                                    options={field.options.length ? field.options : sensitivityOptions}
                                                    props={{
                                                        alwaysRenderLabel: true,
                                                    }}
                                                    disabled={!editable}
                                                    onChange={this.submit}
                                                />
                                            </FieldContainer>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                        <div className="work-center-plan-details-form-fields-container">
                            {fieldsDate.map(field => (
                                <div
                                    className={
                                        classnames(
                                            'work-center-plan-details-form-field-item',
                                            { 'target-date-field': field.name === 'target_date' }
                                        )}
                                    key={field.name}
                                >
                                    <FieldContainer>
                                        <Field
                                            name={field.name}
                                            label={field.label}
                                            formGroupClassName="plan-details-form-group"
                                            component={InputDate}
                                            onChange={this.submit}
                                            parse={this.parseDate}
                                            props={{
                                                alwaysRenderLabel: true,
                                                disabled: !editable,
                                                isClearable: true,
                                            }}
                                        />
                                    </FieldContainer>
                                </div>
                            ))}
                            {fieldsParams.map(field => (
                                <div className="work-center-plan-details-form-field-item" key={field.name}>
                                    <FieldContainer>
                                        <Field
                                            name={field.name}
                                            id={field.id}
                                            label={field.label}
                                            placeholder={field.label}
                                            formGroupClassName="plan-details-form-group"
                                            component={Select}
                                            searchable={false}
                                            clearable={false}
                                            options={field.options.length ? field.options : sensitivityOptions}
                                            props={{
                                                alwaysRenderLabel: true,
                                            }}
                                            disabled={!editable}
                                            onChange={this.submit}
                                        />
                                    </FieldContainer>
                                </div>
                            ))}
                        </div>
                    )}
                </>
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={(el) => { this.submitButton = el; }}
                />
            </Form>
        );
    }
}

PlanDetailsForm.defaultProps = {
    sensitivities: {},
    fieldContainer: Fragment,
    isWorkCenterRightDrawer: false,
};

PlanDetailsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    sensitivities: PropTypes.object, // eslint-disable-line
    fieldContainer: PropTypes.oneOfType([
        PropTypes.symbol,
        PropTypes.func,
    ]),
    isWorkCenterRightDrawer: PropTypes.bool,
    initialValues: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
    userDateFormat: PropTypes.string.isRequired,
};

export default reduxForm({
    form: 'plan-details-form',
    enableReinitialize: true,
})(PlanDetailsForm);
