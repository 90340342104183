/* eslint-disable class-methods-use-this */
import { Model } from '../../../../shared/entities-v2';
import request from '../../../../request';

const apiUrl = '/work_center/grid';
const modelName = 'work-center-grid-model';

class GridModel extends Model {
    /**
     * Overridden `fetch` method of the Model class.
     * @async
     * @method fetch
     * @memberof WorkCenterGrid.GridModel
     * @instance
     * @param {WorkCenterGrid.GridModelFetchParams} params
     * @param {Object} options - Request options
     * @returns {Promise<WorkCenterGrid.GridModelFetchResponse>} Server response
    */
    fetch(params, options) {
        const formattedParams = this.formatParams(params);
        return request.post(apiUrl, formattedParams, options);
    }
}

const workCenterGridModel = new GridModel({
    apiUrl,
    name: modelName,
});

export default workCenterGridModel;
