import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'react-router';
import {
    TableView, Button, Toggle, Text, BadgeArchive
} from '../../../shared/components';
import { isIntegrationConfigured, isIntegrationActivated, isArchived } from '../selectors';

import styles from './styles.module.css';
import { planTitle } from '../../../shared/constants/titles';

function IntegrationGoalsTableView({
    items, placeholder, itemProps
}) {
    const { slug, createHref, onChange } = itemProps;

    return (
        <TableView
            className={styles.list}
            placeholder={(placeholder) && (
                <TableView.Placeholder>
                    {placeholder}
                </TableView.Placeholder>
            )}
        >
            <TableView.Header>
                <TableView.HeaderCell
                    align={TableView.align.LEFT}
                    className={styles.listItemTitle}
                >
                    {planTitle} Title
                </TableView.HeaderCell>
                <TableView.HeaderCell>Action</TableView.HeaderCell>
                <TableView.HeaderCell>Integration Status</TableView.HeaderCell>
                <TableView.HeaderCell>Activated</TableView.HeaderCell>
            </TableView.Header>

            <TableView.Body>
                {items.map((item) => {
                    const title = get(item, 'title');
                    const fieldName = `integration-goal-${item.id}`;
                    const isConfigured = isIntegrationConfigured(item, slug);
                    const isActivated = isIntegrationActivated(item, slug);
                    const isArchivedState = isArchived(item);
                    const href = createHref({
                        integrationId: slug,
                        goalId: item.id,
                    });

                    return (
                        <TableView.Row key={item.id} disabled={isArchivedState}>
                            <TableView.Cell
                                align={TableView.align.LEFT}
                                className={styles.listItemTitle}
                            >
                                <div className={styles.listItem}>
                                    <Text styleType={isArchivedState ? 'muted' : undefined}>{title}</Text>
                                    {isArchivedState && <BadgeArchive />}
                                </div>
                            </TableView.Cell>
                            <TableView.Cell>
                                <Button
                                    outline
                                    component={Link}
                                    to={href}
                                    disabled={isArchivedState}
                                >
                                    Configure
                                </Button>
                            </TableView.Cell>
                            <TableView.Cell>
                                <Text styleType={isConfigured ? 'success' : 'muted'}>
                                    {isConfigured ? 'Configured' : 'Not Configured'}
                                </Text>
                            </TableView.Cell>
                            <TableView.Cell>
                                <Toggle
                                    name={fieldName}
                                    disabled={!isConfigured || isArchivedState}
                                    input={{
                                        onChange: event => onChange(event, item.id), // event => onChange(event, props),
                                        value: isActivated,
                                    }}
                                />
                            </TableView.Cell>
                        </TableView.Row>
                    );
                })}
            </TableView.Body>
        </TableView>
    );
}

IntegrationGoalsTableView.defaultProps = {
    items: [],
    itemProps: {},
    placeholder: undefined,
};

IntegrationGoalsTableView.propTypes = {
    items: PropTypes.array,
    placeholder: PropTypes.string,
    itemProps: PropTypes.shape({
        onChange: PropTypes.func,
        createHref: PropTypes.func,
        slug: PropTypes.string,
    }),
};

export default IntegrationGoalsTableView;
