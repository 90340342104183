import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import KpiDataPointsList from './kpi-data-points-list';
import withKpiDataPointModel from './with-kpi-data-point-model';
import RightDrawerTab from './right-drawer-tab';

import styles from './styles.module.css';

const EntityDataPoint = ({
    handleToggleTab, isActiveTab, progressNoteShowDetailsModal, canCreateDataPoint, dataPointsWithMissedPoint,
    handleCreateDataPointForMissedPeriod, handleEditDataPoint, handleCreateDataPoint, kpiTypeLimit, isKpiActive,
    isAdvKpiHasAllActiveSources,
}) => {
    const canCreateKpiDataPoint = canCreateDataPoint && kpiTypeLimit && isKpiActive && isAdvKpiHasAllActiveSources;
    return (
        <div className={styles.rightDrawerTabWrapper}>
            <RightDrawerTab
                handleToggleTab={handleToggleTab}
                isActiveTab={isActiveTab}
                title="Data Points"
                iconName="chart-bar"
                createButton={canCreateKpiDataPoint}
                onClickCreate={handleCreateDataPoint}
            />
            <div className={classnames(styles.rightDrawerTabContent, styles.withAdditionalTab, { [styles.visible]: isActiveTab })}>
                <KpiDataPointsList
                    data_points={dataPointsWithMissedPoint}
                    progressNoteShowDetailsModal={progressNoteShowDetailsModal}
                    canCreateDataPoint={canCreateDataPoint}
                    handleCreateDataPointForMissedPeriod={handleCreateDataPointForMissedPeriod}
                    handleEditDataPoint={handleEditDataPoint}
                    kpiTypeLimit={kpiTypeLimit}
                    isKpiActive={isKpiActive}
                />
            </div>
        </div>
    );
};


EntityDataPoint.propTypes = {
    handleToggleTab: PropTypes.func.isRequired,
    isActiveTab: PropTypes.bool.isRequired,
    progressNoteShowDetailsModal: PropTypes.func.isRequired,
    canCreateDataPoint: PropTypes.bool.isRequired,
    dataPointsWithMissedPoint: PropTypes.array.isRequired,
    handleCreateDataPointForMissedPeriod: PropTypes.func.isRequired,
    handleEditDataPoint: PropTypes.func.isRequired,
    handleCreateDataPoint: PropTypes.func.isRequired,
    kpiTypeLimit: PropTypes.bool.isRequired,
    isKpiActive: PropTypes.bool.isRequired,
    isAdvKpiHasAllActiveSources: PropTypes.bool.isRequired,
};

export default withKpiDataPointModel()(EntityDataPoint);
