import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Clickable from '../clickable/component';
import Icon from '../icon/component';
import './styles.css';

export default function BannerHeader({ children, className, onClose }) {
    return (
        <div
            className={classnames('banner-header', {
                [className]: !!className,
            })}
        >
            {children}

            <Clickable
                action="hide-banner"
                className="banner-close-button"
                onClick={onClose}
            >
                <Icon name="times" className="banner-close-icon" />
            </Clickable>
        </div>
    );
}

BannerHeader.defaultProps = {
    children: undefined,
    className: undefined,
};

BannerHeader.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
