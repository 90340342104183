import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon } from '../../../shared/components';
import { getAppNetworkStatus } from './network-status-reducer';
import './network-status.css';

function NetworkStatus({ appNetworkStatus }) {
    return (
        <Fragment>
            {(!appNetworkStatus) && (
                <div className="disconnect-notification-mobile">
                    <Icon
                        className=""
                        name="wifi-slash"
                        type="solid"
                    />
                    <span className="text-label">
                        No internet connection
                    </span>
                </div>
            )}
        </Fragment>
    );
}

NetworkStatus.propTypes = {
    appNetworkStatus: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        appNetworkStatus: getAppNetworkStatus(state),
    };
}

export default connect(mapStateToProps)(NetworkStatus);
