import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/component';
import Text from '../../text';

import './user.css';

export default function UserFormatter({
    avatar, firstName, lastName, tagName
}) {
    return (
        <div className="user-info-formatter">
            <Avatar
                src={avatar}
                first_name={firstName || ' '}
                last_name={lastName || ' '}
                className="user-info-formatter-avatar"
            />

            <span className="user-info-formatter-user-name">
                <Text styleType="dark">{firstName} {lastName}</Text><br />
                <Text className="user-info-formatter-tag-name" size="small">@{tagName}</Text>
            </span>
        </div>
    );
}


UserFormatter.propTypes = {
    avatar: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    tagName: PropTypes.string.isRequired
};
