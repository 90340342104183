import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Text, Icon, Input, Button, ModalSuccess, Prompt,
} from '../../../shared/components';
import { copyToClipBoard } from '../../../shared/utils/clipboard';
import { publicApiTexts } from './public-api-constants';

import styles from './public-api-details.module.css';

const PublicApiKey = ({
    apiKey, modalVisible, handleGetNewApiKey, setModalVisible,
}) => {
    const [copyModalVisible, setCopyModalVisible] = useState(false);
    const [promtVisible, setPromtVisible] = useState(false);
    const handleModalHide = () => {
        copyToClipBoard(apiKey);
        setModalVisible(false);
    };

    const handleCopyKey = () => {
        copyToClipBoard(apiKey);
        setCopyModalVisible(true);
    };

    return (
        <>
            <Text className={styles.apiDetailsSubtitle} styleType="dark-light">API Key</Text>
            <Text className={styles.apiKeyDescription} styleType="dark-light">
                {publicApiTexts.description}
            </Text>
            <div className={styles.apiKeyNotification}>
                <Icon className={styles.apiKeyIcon} name="exclamation-triangle" type="regular" />
                <Text>{publicApiTexts.notification}</Text>
            </div>
            <Input
                value={apiKey}
                postfix={() => (<Icon type="regular" name="copy" onClick={handleCopyKey} />)}
                formGroupClassName={styles.generatedFieldContainer}
                className={styles.generatedField}
                inputPostfixClassName={styles.generatedFieldPostfix}
            />
            <ModalSuccess
                title="API Key Copied"
                visible={copyModalVisible}
                hide={() => setCopyModalVisible(false)}
            >
                <>
                    <Text className={styles.apiKeySuccessRoundModal} styleType="success">
                        <Icon className={styles.successIcon} name="check" />
                    </Text>
                    <Text align="center">{publicApiTexts.successCopyApiKey}<br /></Text>
                </>
            </ModalSuccess>
            <Button outline onClick={() => setPromtVisible(true)} className={styles.apiKeyButton}>
                Generate New API Key
            </Button>
            <Prompt
                visible={promtVisible}
                title={publicApiTexts.promtTitle}
                description={publicApiTexts.promtDescription}
                onAccept={handleGetNewApiKey}
                hide={() => setPromtVisible(false)}
            />
            <ModalSuccess
                title="API Key Generated"
                visible={modalVisible}
                hide={handleModalHide}
                applyButtonTitle="Copy New Key"
            >
                <>
                    <Text
                        className={styles.apiKeySuccessRoundModal}
                        styleType="success"
                    >
                        <Icon className={styles.successIcon} name="check" />
                    </Text>
                    <Text align="center">{publicApiTexts.successGenerateApiKey}</Text>
                </>
            </ModalSuccess>
        </>
    );
};

PublicApiKey.defaultProps = {
    apiKey: undefined,
};

PublicApiKey.propTypes = {
    apiKey: PropTypes.string,
    modalVisible: PropTypes.bool.isRequired,
    handleGetNewApiKey: PropTypes.func.isRequired,
    setModalVisible: PropTypes.func.isRequired,
};

export default PublicApiKey;
