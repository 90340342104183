import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import renderIf from 'render-if';
import { flow, get } from 'lodash';
import isUrl from 'is-url';
import { Dictionary } from '../../../shared/modules';
import {
    Form, Input, Button, Toggle, LoadingIndicator, Text, ButtonGroupRadio, ModuleTitle, Breadcrumbs
} from '../../../shared/components';
import goalCollection from '../goal-list/entity';
import { slugs } from '../../settings-integrations/config';
import communicationModel from './communication-model';
import MSTeamsInstructions from './instructions-teams';
import SlackInstructions from './instructions-slack';

import './styles.css';
import styles from '../goal-list/styles.module.css';

const titleBySlug = Object.freeze({
    [slugs.slack]: 'Slack',
    [slugs.msTeams]: 'MS Teams',
});

const instructionsBySlug = {
    [slugs.msTeams]: MSTeamsInstructions,
    [slugs.slack]: SlackInstructions,
};

function errorObjectGetter(response) {
    return get(response, 'errors');
}

class IntegrationCommunicationForm extends Component {
    componentDidMount() {
        const {
            fetch, goalId, fetchGoalCollection, slug
        } = this.props;

        fetch({ goal_id: goalId, slug });
        fetchGoalCollection({ slug });
    }

    componentDidUpdate(prevProps) {
        const { currentValues } = this.props;

        if (!isUrl(prevProps.currentValues.url) && isUrl(currentValues.url)) {
            this.handleWebhookUrlChange();
        }
    }

    handleSuccessSubmit = () => {
        const { redirectUrl } = this.props;
        this.props.navigate(redirectUrl);
    }

    handleWebhookUrlChange = () => {
        const { change } = this.props;

        change('is_active', 1);
    }

    getStrategicPlan = items => items.find(el => el.id === Number(this.props.goalId));

    render() {
        const {
            submitting, reset, currentValues, handleSubmit, onSubmit, fetching, loading, items, slug, breadcrumbs,
        } = this.props;

        const strategicPlan = this.getStrategicPlan(items);
        const plansName = Dictionary({ name: 'plan' });
        const actionsName = Dictionary({ name: 'action' });
        const integrationTitle = titleBySlug[slug];
        const InstructionsComponent = instructionsBySlug[slug];

        if (fetching && loading) {
            return <LoadingIndicator centered />;
        }

        return (
            <div className="goalmap-settings-integration-slack container">
                <div className="goalmap-settings-integration-left-block">
                    <ModuleTitle
                        className="goalmap-settings-integration-title"
                    >
                        {integrationTitle} Configuration
                    </ModuleTitle>
                    <div className={styles.settingsIntegrationBreadcrumbs}>
                        <Breadcrumbs items={breadcrumbs} />
                    </div>

                    <Form
                        onSubmit={onSubmit}
                        handleSubmit={handleSubmit}
                        onSuccessSubmit={this.handleSuccessSubmit}
                        errorObjectGetter={errorObjectGetter}
                    >
                        <div className="integration-form-section">
                            <Text className="integration-form-section-subtitle" component="h4">
                                Configure {integrationTitle} Integration for
                                <Text className="name-strategic-plane">
                                    {strategicPlan && ` ${get(strategicPlan, 'title', ' Strategic plan')}`}
                                </Text>
                            </Text>
                            <Text className="integration-form-section-block-title" bold>
                                Input Webhook URL
                            </Text>
                            <Text className="text">
                                To get this URL visit the Incoming WebHooks page in the {integrationTitle} Directory.
                            </Text>
                            <Field
                                containerClassName="channel-url-field"
                                name="url"
                                component={Input}
                                placeholder={`Type in Webhook URL for ${integrationTitle} channel`}
                                required
                            />
                            <Text className="integration-form-section-block-title" bold>
                                Sensitivity Level
                            </Text>
                            <Text className="text">
                                {renderIf(currentValues.sensitivity === 1)(
                                    `Does not send notifications for ${plansName} or
                                        ${actionsName} with Restricted or Confidential sensitivity`
                                )}

                                {renderIf(currentValues.sensitivity === 2)(
                                    `Does not send notifications for ${plansName} or
                                         ${actionsName} with Confidential sensitivity`
                                )}

                                {renderIf(currentValues.sensitivity === 3)(
                                    `Will send notifications for all ${plansName} or
                                         ${actionsName} regardless of their Sensitivity Level`
                                )}
                            </Text>
                            <Field
                                name="sensitivity"
                                component={ButtonGroupRadio}
                                props={{
                                    className: 'goalmap-settings-integration-btns-group',
                                    options: [
                                        { value: 1, label: 'Standard' },
                                        { value: 2, label: 'Restricted' },
                                        { value: 3, label: 'Confidential' },
                                    ]
                                }}
                            />

                            <Text className="integration-form-section-block-title" bold>
                                Notifications
                            </Text>
                            <div>
                                <Text component="h4" className="toggle-label">
                                    Turn On/Off Notifications
                                </Text>
                                <Field
                                    name="is_active"
                                    id="integration-form-toggle"
                                    className="toggle"
                                    component={Toggle}
                                    parse={value => (value ? 1 : 0)}
                                />
                            </div>
                            <hr className="line" />
                            <div>
                                <Button
                                    type="button"
                                    styleType="no-style"
                                    className="reset-button"
                                    disabled={submitting}
                                    onClick={reset}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="submit"
                                    className="success-button"
                                    styleType="success"
                                    loading={submitting}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="goalmap-settings-integration-right-block">
                    <div className="integration-form-section-instructions">
                        <InstructionsComponent />
                    </div>
                </div>
            </div>
        );
    }
}

IntegrationCommunicationForm.defaultProps = {
    currentValues: {},
    initialValues: {},
    breadcrumbs: []
};

IntegrationCommunicationForm.propTypes = {
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
        sensitivity: PropTypes.string,
        url: PropTypes.string,
    }),
    fetching: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    goalId: PropTypes.string.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchGoalCollection: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        sensitivity: PropTypes.number,
        url: PropTypes.string,
        is_active: PropTypes.number,
        slug: PropTypes.string,
    }),
    navigate: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array,
};

const formName = 'goalmap-integration-communication';
const currentFormSelector = formValueSelector(formName);

function mapStateToProps(state, ownProps) {
    const goalId = get(ownProps, ['params', 'goalId']);
    const attributes = communicationModel.selectors.getAttributes(state);
    const fetching = communicationModel.selectors.getStateFetching(state);
    const items = goalCollection.selectors.getItems(state);
    const loading = goalCollection.selectors.getFetchingState(state);

    return {
        goalId,
        fetching,
        items,
        loading,
        initialValues: {
            slug: ownProps.slug,
            id: attributes.id,
            goal_id: attributes.goal_id,
            sensitivity: Number(attributes.sensitivity),
            url: attributes.url,
            is_active: attributes.is_active,
        },
        currentValues: {
            sensitivity: currentFormSelector(state, 'sensitivity'),
            url: currentFormSelector(state, 'url'),
        },
    };
}

const mapDispatchToProps = {
    fetch: communicationModel.actions.fetch.request,
    onSubmit: communicationModel.actions.update.request,
    navigate: push,
    fetchGoalCollection: goalCollection.actions.fetch.request,
};

const reduxFormConfig = {
    form: formName,
    enableReinitialize: true,
};

export default flow(
    reduxForm(reduxFormConfig),
    connect(mapStateToProps, mapDispatchToProps),
)(IntegrationCommunicationForm);
