import _ from 'lodash';
import getOrder from './get-order';

const rules = [
    {
        validator(values) {
            return !_.isUndefined(values.find(value => !/^\d+$/.test(String(value))));
        },
        message: 'Entry must be a valid number.'
    },
    {
        validator(values) {
            const first = values[0];
            const last = values[values.length - 1];

            return (first === last);
        },
        message: 'Difference between Left and Right bounds should be at least 2',
    },
    {
        validator(values) {
            const descOrder = getOrder(values);
            let invalidItem;

            if (descOrder) {
                invalidItem = values.find((item, index, arr) => {
                    if (index === 0) {
                        return false;
                    }

                    return (Number(item) >= Number(arr[index - 1]));
                });
            } else {
                invalidItem = values.find((item, index, arr) => {
                    if (index === 0) {
                        return false;
                    }

                    return (Number(item) <= Number(arr[index - 1]));
                });
            }

            return invalidItem !== undefined;
        },
        message: 'Invalid entry. Value not within range.'
    }
];

export default function checkValidity(values) {
    return (rules.find(rule => !!rule.validator(values)) || {}).message;
}
