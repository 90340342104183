import moment from 'moment';
import { createSelector } from 'reselect';
import { selectSearchQuery } from '../../shared/selectors/routing';
import { serviceAuthModelPlugin } from './service-auth/service-auth-model';
import {
    stateBranchName,
    refreshTokenTimeSec,
    parseQueryErrors,
} from './config';
import { initialState } from './reducer';

function getBranch(state) {
    return state[stateBranchName];
}

export function getAuthenticationData(state) {
    return getBranch(state).data;
}

export function getIsAuthorizedState(state) {
    return getBranch(state).authenticated;
}

export function getAccessToken(state) {
    return getAuthenticationData(state).accessToken;
}

export function getSocialSignupAttempt(state) {
    return getBranch(state).socialSignupAttempt;
}

export function getSocialChangeEmailAttempt(state) {
    return getBranch(state).socialChangeEmailAttempt || {};
}

export function getSignupAttempt(state) {
    return getBranch(state).signupAttempt;
}

export function getIsSignupAttempt(state) {
    return (
        !!getBranch(state).signupAttempt?.inviteToken &&
        !!getBranch(state).signupAttempt?.email
    );
}

export function getIsSocialSignupAttempt(state) {
    const signupState = getSocialSignupAttempt(state);

    return signupState.inviteToken && signupState.provider;
}

export function getIsSocialChangeEmailAttempt(state) {
    return !!getSocialChangeEmailAttempt(state).token;
}

export function getAuthenticationPersistShape(state) {
    return {
        ...initialState,
        signupAttempt: getBranch(state).signupAttempt,
        socialSignupAttempt: getSocialSignupAttempt(state),
        socialChangeEmailAttempt: getSocialChangeEmailAttempt(state),
        data: getAuthenticationData(state),
    };
}

export const getIsAuthenticationView = createSelector(
    [
        getIsAuthorizedState,
        serviceAuthModelPlugin.selectors.getAttemptInProgress,
    ],
    (isAuthorized, isServiceAuthAttempt) =>
        !isAuthorized || isServiceAuthAttempt
);

export const getTokenRefreshDelay = createSelector(
    [getAuthenticationData],
    (authData) => {
        const { lastLoginTime, accessTokenExpired } = authData;
        const now = moment();
        const expirationTime = moment(lastLoginTime).add(
            accessTokenExpired,
            'seconds'
        );
        const delta = expirationTime.diff(now, 'seconds') - refreshTokenTimeSec;

        return delta < refreshTokenTimeSec ? 0 : delta;
    }
);

export const selectIsRecoveryUsed = createSelector(
    [getBranch],
    (state) => state.recoveryUsed
);

export const selectExpiringSSOProviderCheck = createSelector(
    [getBranch],
    (state) => state.expiringSSOProviderCheck
);

export const selectQueryErrors = createSelector(
    [selectSearchQuery],
    parseQueryErrors
);
