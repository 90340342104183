/* eslint-disable class-methods-use-this */
import u from 'updeep';
import moment from 'moment';
import config from '../../config';
import {
    List,
    createModal,
    updateModal,
    createDetails,
} from '../../shared/entities';
import { Model } from '../../shared/entities-v2';
import { ModelCachePlugin } from '../../shared/entities-v2/plugins';
import parseTree from './parse-tree';

const apiUrl = '/strategy_maps';

function formatActivityParams(params) {
    return u(
        {
            from: moment(params.from).format(config.apiDateFormat),
            to: moment(params.to).format(config.apiDateFormat),
        },
        params
    );
}

export const list = new List({
    apiUrl,
    name: 'strategy-map-list',
    format(params) {
        const clone = { ...params };
        delete clone.silent;

        return clone;
    },
});

export const create = createModal({
    apiUrl,
    name: 'strategy-map-create',
    onSuccess: list.actions.add,
});

export const copy = createModal({
    apiUrl,
    name: 'strategy-map-copy',
    onSuccess: list.actions.add,
});

export const update = updateModal({
    apiUrl,
    name: 'strategy-map-update',
    onSuccess: list.actions.update,
});

export const details = createDetails({
    apiUrl,
    name: 'strategy-map-details',
    updateHTML: true,
    onErrorUpdateStatus: createDetails.errorUpdateStatusNotification,
    messages: {
        error: 'To activate this entity you should first activate the item on the higher level',
    },
});

class TreeModel extends Model {
    parse(response) {
        return { response: { data: parseTree(response) } };
    }
}

export const tree = new TreeModel({
    name: 'strategy-map-visualization',
    getApiUrl(params) {
        return `/goals/${params.id}/tree`;
    },
    plugins: [
        new ModelCachePlugin({ name: 'strategy-map-visualization-cache' }),
    ],
});

export const treeCacheModel = tree.getPluginInstanse(
    'strategy-map-visualization-cache'
);

export const activity = new List({
    apiUrl: '/strategy_maps/activity',
    name: 'strategy-map-activity',
    format: formatActivityParams,
    parse(response) {
        const result = Object.keys(response).reduce((accum, key) => {
            const arr = response[key];
            const amount = arr.length - 12;

            if (amount > 0) {
                arr.splice(0, amount);
            }

            accum[key] = arr; // eslint-disable-line
            return accum;
        }, {});

        return { data: result };
    },
});
