import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { change } from 'redux-form';
import {
    Icon, Col, Row, Text, TableView, Input,
} from '../../../../shared/components';
import {
    kpiTypes, dataSourceAttributes, attributes as KPIAttributes,
} from '../../../goalmap-milestones/constants';
import { titlesByOperation, calculateTotalByOperation, } from '../../utils';
import { roundDecimal } from '../../../../shared/utils/math';
import { PeriodActualField, PeriodTargetField, PeriodNoteFields } from './advanced-calc-form-fields';
import { attributes } from '../../constants';
import styles from './adv-calc-form-mobile.module.css';

const valueKeys = {
    value: 'value',
    period_actual: 'period_actual',
    period_target: 'period_target',
};

class MilestoneDataPointFormMobile extends Component {
    get isStaticKPI() {
        return this.props.type === kpiTypes.static.type;
    }

    handlePeriodChange = (value) => {
        const { setPeriodStart, dispatch, form } = this.props;
        if (setPeriodStart) setPeriodStart(value);
        else {
            dispatch(change(form, attributes.period_start, value));
        }
    };

    renderDataPointTable = (source, index) => {
        const { initialValues, isUpdateDataPoint } = this.props;

        const label = get(source, dataSourceAttributes.label);
        const sourceKPI = get(source, dataSourceAttributes.related_entity);
        const sourceKPITitle = get(sourceKPI, KPIAttributes.title);

        return (
            <TableView
                className={styles.dataPointTableMobile}
                key={source.id}
            >
                <TableView.Header>
                    <TableView.HeaderCell className={styles.tableHeaderLabel} align="left">
                        Label
                    </TableView.HeaderCell>
                    <TableView.HeaderCell align="left">
                        {label}
                    </TableView.HeaderCell>
                </TableView.Header>
                <tbody>
                    <TableView.Row>
                        <TableView.Cell className={styles.tableCell}>
                            Data Source
                        </TableView.Cell>
                        <TableView.Cell className={styles.tableCell}>
                            <div className={styles.kpiTitle}>
                                <Icon className={styles.kpiTitleIcon} name="analytics" type="regular" />
                                {sourceKPITitle}
                            </div>
                        </TableView.Cell>
                    </TableView.Row>
                    <TableView.Row>
                        <TableView.Cell className={styles.tableCell}>
                            Period Actual
                        </TableView.Cell>
                        <TableView.Cell className={styles.tableCell}>
                            <PeriodActualField
                                index={index}
                                initialValues={initialValues}
                                isUpdateDataPoint={isUpdateDataPoint}
                                className={styles.actualValueInput}
                            />
                        </TableView.Cell>
                    </TableView.Row>
                    {!this.isStaticKPI && (
                        <TableView.Row>
                            <TableView.Cell className={styles.tableCell}>
                                Period Target
                            </TableView.Cell>
                            <TableView.Cell className={styles.tableCell}>
                                <PeriodTargetField
                                    index={index}
                                    initialValues={initialValues}
                                    isUpdateDataPoint={isUpdateDataPoint}
                                    className={styles.actualValueInput}
                                />
                            </TableView.Cell>
                        </TableView.Row>
                    )}
                </tbody>
            </TableView>
        );
    }

    renderSummaryTable = () => {
        const { advCalcOperation, currentValues } = this.props;
        const { dataPoints = [] } = currentValues;

        const calculationFunc = calculateTotalByOperation[advCalcOperation];

        const valueTotal = calculationFunc(dataPoints, valueKeys.value);
        const periodTargetTotal = calculationFunc(dataPoints, valueKeys.period_target);

        return (
            <TableView className={styles.dataPointTableMobile}>
                <TableView.Header>
                    <TableView.HeaderCell colspan="2" align="left">
                        {titlesByOperation[advCalcOperation]}
                    </TableView.HeaderCell>
                </TableView.Header>
                <tbody>
                    <TableView.Row>
                        <TableView.Cell className={styles.tableCell}>
                            Period Actual
                        </TableView.Cell>
                        <TableView.Cell className={styles.tableCell}>
                            <Input
                                disabled
                                input={{
                                    value: roundDecimal(valueTotal),
                                }}
                                className={styles.actualValueInput}
                            />
                        </TableView.Cell>
                    </TableView.Row>
                    {!this.isStaticKPI && (
                        <TableView.Row>
                            <TableView.Cell className={styles.tableCell}>
                                Period Target
                            </TableView.Cell>
                            <TableView.Cell className={styles.tableCell}>
                                <Input
                                    disabled
                                    input={{
                                        value: roundDecimal(periodTargetTotal),
                                    }}
                                    className={styles.actualValueInput}
                                />
                            </TableView.Cell>
                        </TableView.Row>
                    )}
                </tbody>
            </TableView>
        );
    }

    render() {
        const {
            frequency, goal_id, isUpdateDataPoint, advCalcSources,
        } = this.props;
        return (
            <div className="milestone-data-point-form">
                <PeriodNoteFields
                    frequency={frequency}
                    setPeriodStart={this.handlePeriodChange}
                    isUpdateDataPoint={isUpdateDataPoint}
                    goal_id={goal_id}
                />
                <Row>
                    <Col base={12} className={styles.dataPointsTablesWrapper}>
                        <span className={styles.dataPointTableTitle}>
                            Period Actuals <Text styleType="danger">*</Text>
                        </span>
                        {advCalcSources.length && (
                            <>
                                {advCalcSources.map(this.renderDataPointTable)}
                                {this.renderSummaryTable()}
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

MilestoneDataPointFormMobile.defaultProps = {
    setPeriodStart: undefined,
    advCalcSources: [],
    isUpdateDataPoint: false,
};

MilestoneDataPointFormMobile.propTypes = {
    frequency: PropTypes.string.isRequired,
    goal_id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    advCalcOperation: PropTypes.string.isRequired,
    advCalcSources: PropTypes.array,
    currentValues: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    setPeriodStart: PropTypes.func,
    isUpdateDataPoint: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default MilestoneDataPointFormMobile;
