// eslint-disable-next-line import/prefer-default-export
import { isPositionStickySupported } from '../../utils/support-check';

const isPopupHeaderSticky = isPositionStickySupported();

export const stickyConf = {
    default: {
        offsetTop: 10,
        constraintTop: '#nav-header',
        constraintTopAlign: 'bottom-top',
        offsetBottom: 10,
        constraintBottom: undefined,
        constraintBottomAlign: 'bottom-bottom'
    },
    withPlanSubHeader: {
        offsetTop: 10,
        constraintTop: '#sub-header-plan',
        constraintTopAlign: 'bottom-top',
        offsetBottom: 10,
        constraintBottom: undefined,
        constraintBottomAlign: 'bottom-bottom'
    },
    modal: {
        offsetTop: 10,
        constraintTop: isPopupHeaderSticky ? '.modal-form-header' : undefined,
        constraintTopAlign: isPopupHeaderSticky ? 'bottom-top' : 'top-top',
        offsetBottom: 10,
        constraintBottom: undefined,
        constraintBottomAlign: 'bottom-bottom'
    },
};
