const operations = {
    sum: 'sum',
    difference: 'difference',
    average: 'average',
    quotient: 'quotient',
    quotientPercentage: 'quotient_percentage',
    product: 'product',
};

export { operations };
