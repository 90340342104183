import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { features, attributes } from './subscription-config';
import { getLimitByEntity as selector } from './subscription-limit-selectors';
import SubscriptionLimitMessage from './components/subscription-limit-message';

function SubscriptionLimitProvider({
    children,
    notAllowedMessageEnabled,
    notAllowedMessage,
    ...rest
}) {
    if (notAllowedMessageEnabled && !rest[attributes.value]) {
        return (
            <SubscriptionLimitMessage message={notAllowedMessage} />
        );
    }

    return children(rest);
}

SubscriptionLimitProvider.attributes = attributes;

SubscriptionLimitProvider.defaultProps = {
    notAllowedMessageEnabled: false,
    notAllowedMessage: undefined,
};

SubscriptionLimitProvider.propTypes = {
    children: PropTypes.func.isRequired,
    notAllowedMessageEnabled: PropTypes.bool,
    notAllowedMessage: PropTypes.string,
};

function createProvider(entity) {
    function mapStateToProps(state) {
        return selector(state, entity);
    }

    return connect(mapStateToProps)(SubscriptionLimitProvider);
}

export const Users = createProvider(features.users);
export const Plans = createProvider(features.goals);
export const SettingsGUI = createProvider(features.gui_customization);
export const History = createProvider(features.history);
export const AttachmentSize = createProvider(features.attachment);
export const Roles = createProvider(features.roles_customization);
export const Draft = createProvider(features.draft);
export const PlanExport = createProvider(features.goal_export);
export const ExternalLink = createProvider(features.milestones_external_link);
export const Integrations = createProvider(features.integrations);
export const DigestReports = createProvider(features.digest_reports);
export const Archive = createProvider(features.archive);
export const CSF = createProvider(features.driver);
export const KPI = createProvider(features.milestones);
export const KPITypes = createProvider(features.milestones_kpi_types);
export const KPIAdvCalc = createProvider(features.milestones_kpi_adv_calc);
export const LimitedParticipants = createProvider(features.limited_participant);
export const UserSessionTimeout = createProvider(features.user_session_timeout);
