import { get } from 'lodash';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { types } from '../../../../shared/utils/entity-type';
import createModalWizard from '../../../wizard-entity/wizard-modal';
import { getDetailsTitles } from '../../../goalmap-list/selector';
import { csfModel, csfCreateModel } from '../csf-models';
import CSFPage1 from '../form/csf-form';
import CsfCreateWizard from './csf-create-wizard';


const formName = 'global-csf-create';

const CsfCreateModalWizard = createModalWizard({
    name: formName,
    removeOnCancel: true,
    actions: {
        create: csfCreateModel.actions.request,
        update: csfModel.actions.update.request,
        updateTags: csfModel.actions.updateTags.request,
        remove: csfModel.actions.remove.request,
        fetch: csfModel.actions.request,
        toggle: csfCreateModel.actions.toggle,
        keepModalVisible: csfCreateModel.actions.setKeepModalVisible,
    },
    selectors: {
        selectModalVisible: state => csfCreateModel.selector(state).modalVisible,
        selectFetchingState: state => csfModel.selector(state).loading,
        selectAttributes: state => csfModel.selector(state).data,
        titleSelector: titles => get(titles, types.driver),
        getSubmitSteps: () => [1]
    },
})(CsfCreateWizard);

const selectFormValues = getFormValues(formName);
const getSubmitSteps = () => [1];
const getSteps = (titles) => {
    return [
        {
            ContentComponent: CSFPage1,
            description: `Name your ${titles[types.driver]}, assign Owner, add Target State`,
        },
    ];
};

function mapStateToProps(state) {
    const currentValues = selectFormValues(state);
    const titles = getDetailsTitles(state);

    return {
        steps: getSteps(titles),
        getSubmitSteps,
        currentValues,
        titles,
    };
}

export default connect(mapStateToProps)(CsfCreateModalWizard);
