import { push } from 'react-router-redux';
import queryString from 'query-string';
import {
    takeEvery,
    put,
    select,
    take,
} from 'redux-saga/effects';
import * as routeUtils from '../../../routes/rules';
import { subscriptionLimitSelectors } from '../../subscription';
import * as actions from './actions';
import { params, strategies } from './constants';
import { details as milestoneModel } from '../entity';
import { states, statesToString } from '../constants';

function isLastStep(wizardPage, stepsCount) {
    return wizardPage >= stepsCount;
}

function getNextPageHref(goalId, id, { strategy, wizardPage, stepsCount }) {
    if (strategy === strategies.draft) {
        return routeUtils.createUrl(routeUtils.paths.planKPIList, {
            [routeUtils.params.PlanId]: goalId,
        });
    }

    if (isLastStep(wizardPage, stepsCount) && strategy === strategies.active) {
        return routeUtils.createUrl(routeUtils.paths.planKPIDetails, {
            [routeUtils.params.PlanId]: goalId,
            [routeUtils.params.EntityId]: id,
        });
    }

    const query = queryString.stringify({
        [params.page]: wizardPage + 1,
        [params.entityId]: id,
    });

    const href = routeUtils.createUrl(routeUtils.paths.planKPICreate, {
        [routeUtils.params.PlanId]: goalId,
    });

    return `${href}?${query}`;
}

// function* createMilestoneAndRedirect(data) {
//     yield put(milestoneCreateModel.actions.request(data.payload));
//     const { payload } = yield take(milestoneCreateModel.actions.success);
//     yield put(push(getNextPageHref(payload.goal_id, payload.id, data.payload.stateData)));
// }

function* updateMilestoneAndRedirect(data) {
    const userCanCreateDraft = yield select(subscriptionLimitSelectors.canCreateDraft);
    yield put(milestoneModel.actions.update.request(data.payload));
    const { payload } = yield take(milestoneModel.actions.update.success);

    if (
        userCanCreateDraft
        && payload.state !== states.active
        && data.payload.stateData.strategy === strategies.active
        && data.payload.stateData.wizardPage !== 1
    ) {
        yield put(milestoneModel.actions.updateStatus.request({
            id: payload.id,
            state: statesToString[states.active],
            resolve: data.resolve,
            reject: data.reject,
        }));
        yield take(milestoneModel.actions.updateStatus.success);
    }
    yield put(push(getNextPageHref(payload.goal_id, payload.id, data.payload.stateData)));
}

function* removeMilestoneAndRedirect({ payload }) {
    if (payload.id) {
        yield put(milestoneModel.actions.remove.request(payload));
        yield take(milestoneModel.actions.remove.success);
    }

    const href = routeUtils.createUrl(routeUtils.paths.planKPIList, {
        [routeUtils.params.PlanId]: payload.goalId,
    });

    yield put(push(href));
}

export default function* milestoneWizardSaga() {
    // yield takeEvery(actions.createMilestoneAndRedirect, createMilestoneAndRedirect);
    yield takeEvery(actions.updateMilestoneAndRedirect, updateMilestoneAndRedirect);
    yield takeEvery(actions.removeMilestoneAndRedirect, removeMilestoneAndRedirect);
}
