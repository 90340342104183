import React from 'react';
import ErrorBoundary from './component';

export function withErrorBoundary(Component) {
    return React.forwardRef((props, ref) => {
        return (
            <ErrorBoundary>
                <Component {...props} ref={ref} />
            </ErrorBoundary>
        );
    });
}
