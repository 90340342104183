import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import FileDrop from 'react-file-drop';
import { connect } from 'react-redux';
import {
    Editor, HTMLRenderer, LoadingIndicator, Text, Icon,
} from '../../../../shared/components';
import { ProgressNotesConnectionsSelect, MediaQueryRender } from '../../../../shared/modules';
import { removeFromCollection, prependToCollection } from '../../../../shared/utils/collection';
import validateSize from '../../../../shared/utils/files';
import { SubscriptionLimitProviders } from '../../../subscription';
import { attachmentFilePickerShape, AttachmentFileSource } from '../../../secondary-entities/attachment';
import { applicationSettingsSelectors } from '../../../application';
import Permissions from '../../../permissions';
import { entityPermissionNames } from '../../../permissions/constants';
import { required } from '../progress-note-utils';

import './progress-note-form.css';
import './progress-note-form-file-drop.css';

class ProgressNoteForm extends Component {
    constructor(props) {
        super(props);

        this.attachmentsField = React.createRef();
        this.validate = React.createRef();

        this.state = {
            hideQuill: false,
            currentState: this.props.currentState,
        };
    }

    static getDerivedStateFromProps(props, state) {
        // After save draft with Assign Location, we change unassigned state, and re-render Quill
        // with mentions: true, and we get console error - TypeError: Cannot read property 'innerHTML' of null.
        // For avoid this we hide Quill when we change unassigned state before close modal window.

        if (state.currentState !== props.currentState) {
            return { hideQuill: true };
        }
        return null;
    }

    handleDrop = (files) => {
        files = [...files];
        const fileSource = this.attachmentsField.current.getRenderedComponent();
        fileSource.dropzoneComponent.current.dropzone.current.handleFiles(files);
    }

    handleAssignLocation = (event, value) => {
        const { change } = this.props;

        change('state', 1);
        change('entity_id', value.id);
        change('entity_type', value.entity_type);
        change('goal_id', value.goal_id);
    };

    handleAttachmentRemove = (item) => {
        const { currentValues, change } = this.props;

        change(
            'current',
            removeFromCollection(currentValues.current, item.id),
        );

        change(
            'deleted',
            prependToCollection(currentValues.deleted, item.pivot.id)
        );
    };


    handleDragOverDropArea = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    renderField = (limitProps) => {
        const {
            filePicker, currentValues, dirty, readonly, canCreateAttachment, canDestroyAttachment, canDestroyOwnAttachment,
        } = this.props;

        // hack for fixing infinite field reinitialization
        if (!this.validate.current) {
            this.validate.current = value => validateSize(value, limitProps.value);
        }

        return (
            <Field
                ref={this.attachmentsField}
                name="attachments"
                component={AttachmentFileSource}
                extraItems={currentValues.current}
                onRemove={this.handleAttachmentRemove}
                validate={this.validate.current}
                fileSizeLimit={limitProps.value}
                readonly={readonly}
                filePicker={filePicker}
                dirtyForm={dirty}
                canCreateAttachment={canCreateAttachment}
                canDestroyAttachment={canDestroyAttachment}
                canDestroyOwnAttachment={canDestroyOwnAttachment}
                forwardRef
            />
        );
    }

    renderAttachments = () => {
        return (
            <SubscriptionLimitProviders.AttachmentSize>
                {limitProps => this.renderField(limitProps)}
            </SubscriptionLimitProviders.AttachmentSize>
        );
    };

    renderDescription() {
        const {
            goal_id, assignNeeded, currentState, initialValues, readonly, noSticky,
        } = this.props;
        const unassigned = currentState === 4 || assignNeeded;

        if (readonly || this.state.hideQuill) {
            return (
                <Fragment>
                    <div className="progress-note-label">Description</div>
                    <div className="progress-note-description">
                        <HTMLRenderer>
                            {initialValues.content}
                        </HTMLRenderer>
                    </div>
                </Fragment>
            );
        }

        return (
            <Field
                name="content"
                label="Description"
                placeholder="Type in Progress Note Description"
                component={Editor}
                noSticky={noSticky}
                mentionsEnabled
                goal_id={goal_id}
                autoFocus={!unassigned}
                controlsHidden
                validate={required}
                wrapperErrorClassName="input-editor-wrapper-error"
            />
        );
    }

    render() {
        const {
            submitting, currentState, breakpoints, currentBreakpoint, assignNeeded, readonly, aclRestrictionText, isMobile,
            canCreateAttachment,
        } = this.props;

        const unassigned = currentState === 4 || assignNeeded;

        return (
            <div className="progress-note-create-form">
                {(!readonly && !isMobile && canCreateAttachment) && (
                    <div className="react-file-drop">
                        <FileDrop
                            onDrop={this.handleDrop}
                            onDragOver={this.handleDragOverDropArea}
                        />
                    </div>
                )}
                {submitting && (
                    <div className="loading-overlay">
                        <LoadingIndicator />
                    </div>
                )}

                {aclRestrictionText && (
                    <div className="progress-note-acl-restriction-hint">
                        <Text styleType="info">
                            <Text
                                component={Icon}
                                name="info-circle"
                                styleType="accent"
                            />{' '}
                            {aclRestrictionText}
                        </Text>
                    </div>
                )}

                {unassigned && (
                    <Field
                        name="entity"
                        component={ProgressNotesConnectionsSelect}
                        label="Assign Location"
                        props={{
                            entity: 'object',
                        }}
                        onChange={this.handleAssignLocation}
                    />
                )}

                { this.renderDescription() }

                <MediaQueryRender
                    breakpoints={breakpoints}
                    currentBreakpoint={currentBreakpoint}
                    desktop={this.renderAttachments}
                />
            </div>
        );
    }
}

ProgressNoteForm.defaultProps = {
    currentValues: {},
    assignNeeded: false,
    goal_id: undefined,
    currentState: undefined,
    readonly: false,
    initialValues: {},
    noSticky: false,
    aclRestrictionText: undefined,
    dirty: undefined
};

ProgressNoteForm.propTypes = {
    change: PropTypes.func.isRequired,
    currentState: PropTypes.number,
    currentValues: PropTypes.shape({
        current: PropTypes.array,
        deleted: PropTypes.array,
    }),
    goal_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    submitting: PropTypes.bool.isRequired,
    filePicker: attachmentFilePickerShape.isRequired,
    dirty: PropTypes.bool,
    readonly: PropTypes.bool,
    breakpoints: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    currentBreakpoint: PropTypes.string.isRequired,
    assignNeeded: PropTypes.bool,
    noSticky: PropTypes.bool,
    aclRestrictionText: PropTypes.string,
    isMobile: PropTypes.bool.isRequired,
    canCreateAttachment: PropTypes.bool.isRequired,
    canDestroyAttachment: PropTypes.bool.isRequired,
    canDestroyOwnAttachment: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    const planId = ownProps.goal_id || ownProps.currentValues?.goal_id || ownProps.contextQuickAdd?.goal_id;
    const planAttachmentPermissions = Permissions.selectors
        .selectEntityPermissions(state, planId, Permissions.constants.entitySections.attachment);

    return {
        isMobile: applicationSettingsSelectors.isMobileSelector(state),
        canCreateAttachment: planId ? planAttachmentPermissions?.[entityPermissionNames.canCreate] : true,
        canDestroyAttachment: planId ? planAttachmentPermissions?.[entityPermissionNames.canDestroy] : true,
        canDestroyOwnAttachment: planId ? planAttachmentPermissions?.[entityPermissionNames.canDestroyOwn] : true,
    };
}

export default connect(mapStateToProps)(ProgressNoteForm);
