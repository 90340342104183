import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { partition } from 'lodash';
import {
    TableView, FormattedDate, Avatar, Tooltip, Clickable, Icon
} from '../../components';
import { roles } from './team-section-constants';
import { isRestricted } from './team-section-utils';
import styles from './team-section.module.css';

const TeamSectionItem = (props) => {
    const [leaders, members] = partition(props.teamUsers, { pivot: { teamRoleId: roles.leader } });
    const handleRemove = useCallback(() => {
        props.onRemove(props.id);
    }, [props.onRemove, props.id]);

    const handleEdit = useCallback(() => {
        props.onEdit(props.id);
    }, [props.onEdit, props.id]);

    const renderUser = (user) => {
        const fullName = `${user.userProfile.firstName} ${user.userProfile.lastName}`;

        return (
            <Tooltip
                key={user.id}
                text={fullName}
                className={styles.avatarTooltip}
                position={Tooltip.position.top}
            >
                <Avatar
                    size={Avatar.sizes.small}
                    src={user.userProfile.avatarImageUrl}
                    first_name={user.userProfile.firstName}
                    last_name={user.userProfile.lastName}
                />
            </Tooltip>
        );
    };

    return (
        <TableView.Row className={styles.teamSectionItem}>
            <TableView.Cell align={TableView.align.LEFT} overflowVisible>
                <Clickable
                    action="edit-team"
                    onClick={handleEdit}
                    active
                >
                    {props.title}
                </Clickable>
            </TableView.Cell>
            <TableView.Cell align={TableView.align.LEFT} overflowVisible>
                {leaders.map(renderUser)}
            </TableView.Cell>
            <TableView.Cell align={TableView.align.LEFT} overflowVisible>
                {members.map(renderUser)}
            </TableView.Cell>
            <TableView.Cell align={TableView.align.CENTER}>
                <FormattedDate
                    date={props.createdAt}
                />
            </TableView.Cell>
            <TableView.Cell align={TableView.align.CENTER}>
                <Tooltip text="Edit Team" position={Tooltip.position.top}>
                    <Clickable
                        action="edit-team"
                        onClick={handleEdit}
                        className={styles.control}
                        withDisabledStyle
                    >
                        <Icon className={styles.controlIcon} name="pen" />
                    </Clickable>
                </Tooltip>
                <Tooltip text="Delete Team" position={Tooltip.position.top}>
                    <Clickable
                        action="delete-team"
                        onClick={handleRemove}
                        className={styles.control}
                        disabled={props.useRestrictions ? isRestricted(props.teamUsers, props.userId) : false}
                        withDisabledStyle
                    >
                        <Icon className={styles.controlIcon} name="trash-alt" />
                    </Clickable>
                </Tooltip>
            </TableView.Cell>
        </TableView.Row>
    );
};

TeamSectionItem.propTypes = {
    teamUsers: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    useRestrictions: PropTypes.bool.isRequired,
};

export default TeamSectionItem;
