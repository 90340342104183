import { Model } from '../../shared/entities-v2';
import { ModelNotificationsPlugin } from '../../shared/entities-v2/plugins';
import { keysToCamelCase, keysToSnakeCase } from '../../shared/utils/object';

class UserSettingsUIModel extends Model {
    slugs = {
        dashboardColumns: 'dashboard_columns',
    }

    attributes = {
        id: 'id',
        userId: 'user_id',
        slug: 'slug',
        value: 'value',
        createdAt: 'created_at',
        updatedAt: 'updated_at',
    }

    parse = (data) => {
        const result = keysToCamelCase(data);
        result.value = Number(result.value);
        return result;
    }

    formatParams = params => keysToSnakeCase(params)

    format = (params) => {
        const result = keysToSnakeCase(params);
        result.value = result.value.toString();
        return result;
    }
}

const userUISettingsModel = new UserSettingsUIModel({
    getApiUrl() { return '/user/ui_settings'; },
    name: 'user-ui-settings',
    plugins: [
        new ModelNotificationsPlugin({
            name: 'user-ui-settings/notifications',
            getActions: actions => ([
                actions.update.error,
            ]),
        })
    ]
});

export default userUISettingsModel;
