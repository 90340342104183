import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applicationSettingsSelectors } from '../../../modules/application';
import { selectUserDateFormat } from '../../../modules/user/selectors';
import { DatePicker } from '../index';
import CustomInput from './custom-input';
import NativeInputDate from './native';

import './styles.css';

function InputDate(props) {
    const {
        input, isClearable, meta, isMobile, userDateFormat, ...rest
    } = props;

    if (isMobile) {
        return (
            <NativeInputDate
                isClearable={isClearable}
                meta={meta}
                input={input}
                {...rest}
            />
        );
    }

    return (
        <DatePicker
            className="input-date-container"
            customInput={(
                <CustomInput
                    isClearable
                    input={input}
                    meta={meta}
                    {...rest}
                />
            )}
            input={input}
            isClearable={false}
            userDateFormat={userDateFormat}
            {...rest}
        />
    );
}

InputDate.defaultProps = {
    isClearable: false,
};

InputDate.propTypes = {
    isClearable: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
        ]),
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.array,
    }).isRequired,
    isMobile: PropTypes.bool.isRequired,
    userDateFormat: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    isMobile: applicationSettingsSelectors.isMobileSelector(state),
    userDateFormat: selectUserDateFormat(state),
});

export default connect(mapStateToProps)(InputDate);
