import { put, takeEvery, select } from 'redux-saga/effects';
import { details as StrategyMapModel } from '../../goalmap-strategy-maps/entity';
import { list as segmentList } from '../entity';

import { states } from '../../goalmap-list/config';

function* updateList({ payload }) {
    if (payload.state === states.archived) {
        const { params } = yield select(segmentList.selector);

        yield put({
            type: segmentList.actions.request.getType(),
            payload: params
        });
    }
}

export default function* saga() {
    yield takeEvery(StrategyMapModel.actions.updateStatus.success.getType(), updateList);
}
