/**
 * Get errors from response
 * @function getErrors
 * @memberof RESTEntities
 * @param {any} errors - value [obj, array, string]
 * @return {array} Array of string result
 */
export const getErrors = (errors) => {
    if (typeof errors === 'object') {
        return Object.keys(errors).map(key => errors[key]).flat();
    }
    if (Array.isArray(errors)) {
        return errors;
    }
    return [errors];
};
