import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../../../shared/components';
import logo from './logo.png';
import { WidgetSection } from '../widget-layout';
import './styles.css';

export default function AuthWidgetContainer({ children }) {
    return (
        <div className="auth-page">
            <div className="auth-widget">
                <WidgetSection className="auth-widget-logo-container">
                    <Image
                        src={logo}
                        alt="MPOWR Envision logo"
                        className="auth-widget-logo"
                    />
                </WidgetSection>

                {children}
            </div>
        </div>
    );
}

AuthWidgetContainer.defaultProps = {
    children: undefined,
};

AuthWidgetContainer.propTypes = {
    children: PropTypes.node,
};
