import PropTypes from 'prop-types';
import React from 'react';
import Clickable from '../clickable/component';
import './swatch.css';

export default function ColorSwatch({ options, onChange }) {
    return (
        <div className="color-swatch">
            {options.map(item => (
                <Clickable
                    key={item}
                    action="Set color"
                    onClick={event => onChange({
                        hex: item,
                        source: 'hex'
                    }, event)}
                    className="color-swatch-item"
                    style={{
                        backgroundColor: item
                    }}
                />
            ))}
        </div>
    );
}

ColorSwatch.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
};
