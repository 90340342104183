import { createAction } from 'redux-act';

export default function createActions(prefix) {
    return {
        toggle: createAction(`${prefix}/create/MODAL_TOGGLE`),
        setVisibilityState: createAction(`${prefix}/create/SET_VISIBILITY_STATE`),
        request: createAction(`${prefix}/create/REQUEST`),
        success: createAction(`${prefix}/create/SUCCESS`),
        error: createAction(`${prefix}/create/ERROR`),
        clearError: createAction(`${prefix}/create/CLEAR_ERROR`),
        setKeepModalVisible: createAction(`${prefix}/create/SET_KEEP_MODAL_VISIBLE`),
    };
}
