import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './skeleton.module.css';

const alignments = {
    left: 'left',
    right: 'right',
};

const SkeletonLine = ({ width, align }) => {
    const style = {
        width,
    };

    return (
        <div
            className={classnames(styles.skeletonLine, {
                [styles.skeletonLineAlignLeft]: align === alignments.left,
                [styles.skeletonLineAlignRight]: align === alignments.right,
            })}
            style={style}
        />
    );
};

SkeletonLine.alignments = alignments;

SkeletonLine.defaultProps = {
    align: alignments.left,
};

SkeletonLine.propTypes = {
    width: PropTypes.string.isRequired,
    align: PropTypes.oneOf(
        Object.values(alignments)
    ),
};

const SkeletonRow = ({ children }) => <div className={styles.skeletonRow}>{children}</div>;

SkeletonRow.propTypes = {
    children: PropTypes.node.isRequired,
};

const Skeleton = ({ children }) => {
    return (
        <div className={styles.skeleton}>
            {children}
        </div>
    );
};

Skeleton.propTypes = {
    children: PropTypes.node.isRequired,
};

Skeleton.SkeletonLine = SkeletonLine;
Skeleton.SkeletonRow = SkeletonRow;


export default Skeleton;
