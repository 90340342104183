import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {
    Image,
    Text,
    Banner,
    BannerHeader,
} from '../../../shared/components';
import { isBannerNeeded, setHasSeenFlag } from './installable-app-ios-banner-utils';
import logo from './images/logo.png';
import plus from './images/plus.png';
import share from './images/share.png';
import './installable-app-ios-banner.css';

export default class InstallableAppIosBanner extends Component {
    constructor(args) {
        super(args);

        this.state = {
            isNeeded: isBannerNeeded(),
        };
    }

    handleHide = () => {
        setHasSeenFlag();
        this.setState({
            isNeeded: false,
        });
    }

    render() {
        const { isNeeded } = this.state;

        if (!isNeeded) {
            return null;
        }

        return (
            <Banner className="installable-app-banner">
                <BannerHeader
                    onClose={this.handleHide}
                >
                    <Image
                        src={logo}
                        alt="Envision logo"
                        className="installable-app-banner-logo"
                    />

                    <Text>
                        Would you like to add Envision to your Home Screen?
                    </Text>
                </BannerHeader>

                <Text className="installable-app-banner-info">
                    Tap{' '}
                    <Image
                        src={share}
                        alt="share"
                        className="installable-app-banner-icon"
                    />
                    and then{' '}
                    <Image
                        src={plus}
                        alt="plus"
                        className="installable-app-banner-icon"
                    /> to Add to Home Screen
                </Text>
            </Banner>
        );
    }
}
