import { types } from '../../../shared/utils/entity-type';

import ObjectiveChildrenList from './items-list';
import PlanActionForm from '../../goalmap-plans-actions/form';
import MilestoneCreatePage1 from '../../goalmap-milestones/create/milestone-create-page1';
import MilestoneCreatePage2 from '../../goalmap-milestones/create/milestone-create-page2';

import { mapInitialCreateValuesActionFromParent } from '../../goalmap-plans-actions/utils';
import { mapInitialCreateValuesMilestoneFromParent } from '../../goalmap-milestones/create/utils';


export function getScreensByType(type, titles) {
    switch (type) {
        case types.action:
            return [
                {
                    ContentComponent: ObjectiveChildrenList,
                    description: 'Select type of entity'
                },
                {
                    ContentComponent: PlanActionForm,
                    description: `Name your ${titles[types.action]}, assign Owner, add Target State`
                }
            ];
        case types.milestone:
        default:
            return [
                {
                    ContentComponent: ObjectiveChildrenList,
                    description: 'Select type of entity'
                },
                {
                    ContentComponent: MilestoneCreatePage1,
                    description: `Name your ${titles[types.milestone]}, assign Owner, add Target State`
                },
                {
                    ContentComponent: MilestoneCreatePage2,
                    description: `Select type of ${titles[types.milestone]} and configure it`
                }
            ];
    }
}

const emptySelector = () => ({});

export function getInitialValuesSelectorByType(type) {
    switch (type) {
        case types.action:
            return mapInitialCreateValuesActionFromParent;
        case types.milestone:
            return mapInitialCreateValuesMilestoneFromParent;
        default:
            return emptySelector;
    }
}
