import PropTypes from 'prop-types';

export const user = {
    name: PropTypes.string,
    id: PropTypes.number,
    email: PropTypes.string,
    user_profile: PropTypes.shape({
        title: PropTypes.string,
        photo: PropTypes.string,
        tag_name: PropTypes.string,
    }),
};
