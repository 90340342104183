import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import Composer from 'react-composer';
import { flow } from 'lodash';
import { createListOfItems } from '../../../shared/modules';

import {
    Alert, PromptProvider, GridContainer, Row, Col, Text, ModuleTitle, Breadcrumbs
} from '../../../shared/components';
import TableRenderer from '../../../shared/components/table-view/table-renderer';
import SubscriptionLimitProvider from '../subscription-limit';
import communicationModel from '../communication-details/communication-model';
import { slugs } from '../config';
import { getUpdateStateData } from './utils';
import IntegrationGoalsTableView from './table-view';
import SearchForm from './search';
import list from './entity';
import { paramNames } from './constants';
import styles from './styles.module.css';
import { planTitle } from '../../../shared/constants/titles';

const SettingsIntegrationGoals = createListOfItems({
    actions: {
        request: list.actions.fetch.request,
        requestMore: list.actions.fetchLazy.request,
        reset: list.actions.reset,
    },
    selector: list.selectors.getBranch,
    mapStateToProps: createListOfItems.createMapStateToPropsFromCollection(list.selectors),
})();

const promptProviderProps = {
    title: ' ',
    isConfirmation: true,
    buttonText: 'Got it!',
};

const titleBySlug = Object.freeze({
    [slugs.slack]: 'Slack',
    [slugs.msTeams]: 'MS Teams',
});

function SettingsIntegrationGoalsContainer(props) {
    const {
        createHrefFn, slug, update, items, params, currentValues, breadcrumbs
    } = props;
    const title = titleBySlug[slug];
    const handleChange = useCallback((event, id) => {
        const { checked } = event.target;
        const itemData = items.find(item => item.id === id);
        const dataToSend = getUpdateStateData(itemData, slug, checked);

        update(dataToSend);
    }, [items]);

    const paramsToSend = {
        ...params,
        ...currentValues
    };

    return (
        <Fragment>
            <div className="container">
                <ModuleTitle>{title} Integration</ModuleTitle>
                <div className={styles.settingsIntegrationBreadcrumbs}>
                    <Breadcrumbs items={breadcrumbs} />
                </div>
            </div>
            <GridContainer>
                <Row>
                    <Col base={12}>
                        <Alert
                            colorType={Alert.colorTypes.info}
                            borderType={Alert.borderTypes.dashed}
                            className={styles.alert}
                        >
                            <Row>
                                <Col base={4}>
                                    <Text bold>
                                        Select a {planTitle} and configure the integration.
                                        You will receive notifications to {title} on the following actions:
                                    </Text>
                                </Col>
                                <Col base={4}>
                                    <ul className={styles.alertList}>
                                        <li>A new Action Item is created.</li>
                                        <li>A new Key Performance Indicator is created.</li>
                                        <li>Status of Tactic / Action Item is updated.</li>
                                    </ul>
                                </Col>
                                <Col base={4}>
                                    <ul className={styles.alertList}>
                                        <li>A new Progress Note is added.</li>
                                        <li>A new data point is added to a Key Performance Indicator.</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Alert>
                    </Col>
                </Row>
            </GridContainer>

            <SearchForm />

            <GridContainer>
                <Row>
                    <Col base={12}>
                        {!!params.slug && (
                            <SettingsIntegrationGoals
                                {...props}
                                params={paramsToSend}
                                itemProps={{
                                    slug,
                                    createHref: createHrefFn,
                                    onChange: handleChange,
                                }}
                                RenderComponent={TableRenderer}
                                customRenderFunc={IntegrationGoalsTableView}
                            />
                        )}
                    </Col>
                </Row>
            </GridContainer>
        </Fragment>
    );
}

SettingsIntegrationGoalsContainer.defaultProps = {
    currentValues: {},
    breadcrumbs: []
};

SettingsIntegrationGoalsContainer.propTypes = {
    update: PropTypes.func.isRequired,
    createHrefFn: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    subscription: PropTypes.object.isRequired,
    prompt: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    currentValues: PropTypes.object,
    breadcrumbs: PropTypes.array,
};

const formName = 'settings-integrations-strategic-plan-search';
const formSelector = getFormValues(formName);

const mapDispatchToProps = {
    update: communicationModel.actions.update.request,
};

function mapStateToProps(state, ownProps) {
    const items = list.selectors.getItems(state);

    return {
        items,
        currentValues: formSelector(state),
        params: {
            [paramNames.slug]: ownProps.slug,
        },
    };
}

const reduxFormConfig = {
    form: formName,
    enableReinitialize: true,
};

const ConnectedSettingsIntegrationGoals = flow(
    reduxForm(reduxFormConfig),
    connect(mapStateToProps, mapDispatchToProps),
)(SettingsIntegrationGoalsContainer);

function renderSettingsIntegrationGoalsWithProviders(props) {
    return (
        <Composer
            components={[
                <PromptProvider {...promptProviderProps} />,
                <SubscriptionLimitProvider />,
            ]}
        >
            {([prompt, subscription]) => (
                <ConnectedSettingsIntegrationGoals
                    prompt={prompt}
                    subscription={subscription}
                    {...props}
                />
            )}
        </Composer>
    );
}

export default renderSettingsIntegrationGoalsWithProviders;
