import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from '../../shared/utils/common';
import { progressNoteCollection } from '../secondary-entities/progress-note';
import { attachmentCollection } from '../secondary-entities/attachment';

const AppContext = React.createContext();
const { Provider, Consumer } = AppContext;

class AppContextProvider extends Component {
    state = {
        draftItem: null,
        draftItemThatNeedDelete: null,
        draftItemThatNeedUpdate: null,
        callbackFunction: noop,
        deleteProgressNoteOnSuccessCallback: noop,
        updateProgressNoteOnSuccessCallback: noop,
    };

    addDraftItem = (item) => {
        this.setState({ draftItem: item });
    };

    addDraftItemThatNeedDelete = (item) => {
        this.setState({ draftItemThatNeedDelete: item });
    }

    addDraftItemThatNeedUpdate = (item) => {
        this.setState({ draftItemThatNeedUpdate: item });
    }

    quickAddSuccessCallback = (data) => {
        const { attachmentCollectionRequest, progressNoteCollectionAdd, } = this.props;
        const { contextQuickAdd } = this.state;

        this.state.callbackFunction(data);

        if ((data.entity_id && Number(contextQuickAdd.entity_id) === Number(data.entity_id)) || !data.entity_id) {
            progressNoteCollectionAdd(data);
        }

        attachmentCollectionRequest(data);
    }

    setQuickAddSuccessCallback = (callbackFunction = noop) => {
        this.setState({ callbackFunction });
    }

    setContextQuickAdd = (data = {}) => {
        this.setState({ contextQuickAdd: { ...data } });
    }

    setDeleteProgressNoteOnSuccessCallback = (callbackFunction = noop) => {
        this.setState({ deleteProgressNoteOnSuccessCallback: callbackFunction });
    }

    setUpdateProgressNoteOnSuccessCallback = (callbackFunction = noop) => {
        this.setState({ updateProgressNoteOnSuccessCallback: callbackFunction });
    }

    updateProgressNoteSuccessCallback = (data) => {
        this.addDraftItemThatNeedUpdate(data);
        this.state.updateProgressNoteOnSuccessCallback(data);
    }

    deleteProgressNoteSuccessCallback = ({ id }) => {
        const { progressNoteCollectionDelete } = this.props;
        progressNoteCollectionDelete({ id });
        this.state.deleteProgressNoteOnSuccessCallback();
    };

    render() {
        return (
            <Provider value={{
                draftItem: this.state.draftItem,
                draftItemThatNeedDelete: this.state.draftItemThatNeedDelete,
                draftItemThatNeedUpdate: this.state.draftItemThatNeedUpdate,
                addDraftItem: this.addDraftItem,
                addDraftItemThatNeedDelete: this.addDraftItemThatNeedDelete,
                quickAddSuccessCallback: this.quickAddSuccessCallback,
                setQuickAddSuccessCallback: this.setQuickAddSuccessCallback,
                setContextQuickAdd: this.setContextQuickAdd,
                contextQuickAdd: this.state.contextQuickAdd,
                deleteProgressNoteSuccessCallback: this.deleteProgressNoteSuccessCallback,
                updateProgressNoteSuccessCallback: this.updateProgressNoteSuccessCallback,
                setDeleteProgressNoteOnSuccessCallback: this.setDeleteProgressNoteOnSuccessCallback,
                setUpdateProgressNoteOnSuccessCallback: this.setUpdateProgressNoteOnSuccessCallback,
            }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

AppContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    attachmentCollectionRequest: PropTypes.func.isRequired,
    progressNoteCollectionAdd: PropTypes.func.isRequired,
    progressNoteCollectionDelete: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        progressNoteCollectionAdd: (...args) => dispatch(progressNoteCollection.actions.add(...args)),
        progressNoteCollectionDelete: (...args) => dispatch(progressNoteCollection.actions.remove(...args)),
        attachmentCollectionRequest: (...args) => dispatch(attachmentCollection.actions.request(...args)),
    };
}

const appContextProvider = connect(null, mapDispatchToProps)(AppContextProvider);

export { appContextProvider as AppContextProvider, AppContext, Consumer as AppContextConsumer };
