import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../icon/component';

import styles from './styles.module.css';

function ButtonGroupSelectorContainer({
    children, active, icon, tooltip, tooltipOpts, renderItem, option, containerClassName, ...rest
}) {
    return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label
            className={classnames(styles.buttonGroupSelectorContainer, {
                [styles.active]: active,
                [containerClassName]: !!containerClassName,
            })}
            {...rest}
        >
            {renderItem(
                <div className={styles.buttonGroupSelectorItem}>
                    <Icon name={icon} className={styles.buttonGroupSelectorContainerIcon} />
                    <span className={styles.hidden}>
                        {children}
                    </span>
                </div>,
                option
            )}
        </label>
    );
}

ButtonGroupSelectorContainer.defaultProps = {
    children: undefined,
    active: false,
    icon: 'plus',
    tooltip: undefined,
    tooltipOpts: {},
    renderItem: item => item,
    containerClassName: undefined,
};

ButtonGroupSelectorContainer.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipOpts: PropTypes.object,
    renderItem: PropTypes.func,
    option: PropTypes.object.isRequired,
    containerClassName: PropTypes.string,
};

export default ButtonGroupSelectorContainer;
