import request from '../../../request';

export function get(search, goal_id) {
    const params = search
        ? { title: `*${search}*`, goal_id }
        : { goal_id };

    return request.get(
        '/tags',
        { params }
    ).then(response => (
        {
            options: response.data,
            complete: !response.data.length
        }
    ));
}
