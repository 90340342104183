import Loadable from 'react-loadable';
import Loading from '../../routes/components/loadable';
import { paths } from './config';

const LoginPage = Loadable({
    loader: () => import(/* webpackChunkName: "auth" */ './login/login'),
    loading: Loading,
});

const SignupPage = Loadable({
    loader: () => import(/* webpackChunkName: "auth" */ './signup/signup'),
    loading: Loading,
});

const PasswordResetRequestPage = Loadable({
    loader: () => import(/* webpackChunkName: "auth" */ './reset-password/reset-password-request'),
    loading: Loading,
});

const PasswordResetPage = Loadable({
    loader: () => import(/* webpackChunkName: "auth" */ './reset-password/reset-password'),
    loading: Loading,
});

const EmailChangePage = Loadable({
    loader: () => import(/* webpackChunkName: "auth" */ './change-email/change-email'),
    loading: Loading,
});

const EmailChangeCallback = Loadable({
    loader: () => import(/* webpackChunkName: "auth" */ './change-email/change-email-callback'),
    loading: Loading,
});

const ServiceAuthPage = Loadable({
    loader: () => import(/* webpackChunkName: "auth" */ './service-auth/service-auth'),
    loading: Loading,
});

export default [
    {
        path: paths.login,
        component: LoginPage,
    },
    {
        path: paths.signup,
        component: SignupPage,
    },
    {
        path: paths.passwordResetRequest,
        component: PasswordResetRequestPage,
    },
    {
        path: paths.passwordReset,
        component: PasswordResetPage,
    },
    {
        path: paths.emailChange,
        component: EmailChangePage,
    },
    {
        path: paths.emailChangeCallback,
        component: EmailChangeCallback,
    },
    {
        path: paths.serviceAuth,
        component: ServiceAuthPage,
    }
];
