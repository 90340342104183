import request from '../../../request';
import { Model } from '../../../shared/entities-v2';

const apiUrl = '/multi_factor_authentications/users';

class PreferencesAccountSecurityMFADisableModal extends Model {
    // eslint-disable-next-line class-methods-use-this
    update(data) {
        const { userId, formData } = data;
        return request.put(`${apiUrl}/${userId}/state`, formData);
    }

    // eslint-disable-next-line class-methods-use-this
    remove(data) {
        const { userId } = data;
        return request.post(`${apiUrl}/${userId}/device_reset`);
    }
}

class PreferencesAccountSecurityMFARecoveryCodeModal extends Model {
    // eslint-disable-next-line class-methods-use-this
    update(data) {
        const { userId } = data;
        return request.post(`${apiUrl}/${userId}/recovery_code`);
    }
}

export const preferencesAccountSecurityMFADisableModal = new PreferencesAccountSecurityMFADisableModal({
    apiUrl,
    name: 'multi-factor-authentication-model',
});

export const preferencesAccountSecurityMFARecoveryCodeModal = new PreferencesAccountSecurityMFARecoveryCodeModal({
    apiUrl,
    name: 'multi-factor-authentication-recovery-code-model',
});
