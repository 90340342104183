import u from 'updeep';
import request from '../../../request';

export default function getMembers({ goalId, search, related }) {
    const normalizedSearchValue = search.trim();
    const searching = !!normalizedSearchValue;
    const params = searching
        ? {
            tag_name: normalizedSearchValue,
            related,
            order_by: 'first_name',
        }
        : {
            related,
            order_by: 'first_name',
        };

    return request.get(
        `/goals/${goalId}/members`,
        { params }
    ).then((response) => {
        const options = response.map(item => u(
            {
                title: `${item.user_profile.first_name} ${item.user_profile.last_name}`,
                filter_hash: `${item.user_profile.first_name} ${item.user_profile.last_name} ${item.user_profile.tag_name}`,
                first_name: item.user_profile.first_name,
                last_name: item.user_profile.last_name,
                tag_name: item.user_profile.tag_name,
                image: item.user_profile.image,
            },
            item
        ));

        options.unshift({
            id: 0,
            title: 'Unassigned',
            last_name: '',
            tag_name: '',
            unassigned: true,
        });

        return {
            options,
        };
    });
}
