import u from 'updeep';
import { deepFind } from '../../utils/object';
import { removeItemsAtPosition } from '../../utils/collection';
import { computePath } from './parse';

export function getCoords(path, position) {
    let index = path[path.length - 1];
    const resultPath = removeItemsAtPosition(path, path.length - 1);

    if (position && position === 'below') {
        index += 1;
    }

    if (position && position === 'middle') {
        resultPath.push(index);
        resultPath.push('children');
        index = 0;
    }

    return {
        path: resultPath,
        index,
    };
}

export function getItemByPath(items, sourcePath, position) {
    const currentPath = [...sourcePath];

    if (position && position !== 'middle') {
        currentPath.splice(currentPath.length - 2, 2);
    }

    return deepFind(items, currentPath);
}

function traverseTree(root, cb) {
    function walk(node) {
        cb(node);

        (node.children || []).forEach(walk);
    }

    walk(root);
}

function computePathRecursive(item, index, level, prevPath) {
    let { children } = item;
    const path = computePath(index, level, prevPath);

    if (children?.length) {
        children = children.map((childItem, childIndex) =>
            computePathRecursive(childItem, childIndex, level + 1, path));
    }

    return u({
        path,
        children,
    }, item);
}

export function normalizeType(type) {
    if (type === 'action') {
        return 'plan_action';
    }

    if (type === 'segments') {
        return 'segment';
    }

    return type;
}

export function findPathToItem(items, type, id) {
    let result;
    const itemsWithPath = [...items].map((item, index) =>
        computePathRecursive(item, index, 1, []));

    itemsWithPath.forEach(root =>
        traverseTree(
            root,
            (item) => {
                const itemType = item.class
                    ? normalizeType(item.class)
                    : normalizeType(item.url_type);

                if ((item.entity_type === type || normalizeType(type) === itemType) && item.id === id) {
                    result = item.path;
                }
            }
        ));

    return result;
}

export function findItem(items = [], type, id) {
    if (!type || !id) {
        return undefined;
    }

    let result;

    items.forEach(root =>
        traverseTree(
            root,
            (item) => {
                const itemType = item.class
                    ? normalizeType(item.class)
                    : normalizeType(item.url_type);

                if (itemType === normalizeType(type) && item.id === id) {
                    result = item;
                }
            }
        ));

    return result;
}
