import {
    takeEvery, take, call, put, fork, select
} from 'redux-saga/effects';
import moment from 'moment';
import * as serviceWorkerUtils from '../../../../service-worker/runtime';
import { events } from '../../../../service-worker/register-events';
import { hasActiveDirtyForms } from '../../../shared/modules/form/selectors';
import { wait } from '../../../shared/utils/common';
import actions from './service-worker-actions';
import { selectIsReloadAfterNavigate, selectLastUpdateTime } from './service-worker-selectors';
import { updatingState } from './service-worker-constants';

const timeout = 60 * 1000;
const lastUpdateTimeout = 10;

function validateLastUpdateTime(lastUpdateTime) {
    return lastUpdateTime && moment(lastUpdateTime).isValid();
}

function getIsUpdateAllowed(lastUpdateTime) {
    if (!validateLastUpdateTime(lastUpdateTime)) {
        return true;
    }

    const now = moment();
    const updateTimeWithTimeout = moment(lastUpdateTime)
        .add(lastUpdateTimeout, 'minutes');

    return moment(now)
        .isAfter(updateTimeWithTimeout);
}

function* scheduleUpdateState() {
    yield wait(timeout);
    yield put(actions.setUpdatingState({
        value: updatingState.updated,
        time: moment().toString(),
    }));
}

function* handleServiceWorkerInitialize({ payload }) {
    const eventsChannel = yield call(serviceWorkerUtils.init, payload);
    let prevUpdateAllowed = false;

    if (!eventsChannel) return;

    while (true) {
        const event = yield take(eventsChannel);
        const lastUpdateTime = yield select(selectLastUpdateTime);
        const isUpdateAllowed = getIsUpdateAllowed(lastUpdateTime);
        const hasDirtyForms = yield select(hasActiveDirtyForms);

        switch (event.type) {
            case events.onUpdating:
                prevUpdateAllowed = isUpdateAllowed;
                if (isUpdateAllowed) {
                    yield put(actions.setUpdatingState({
                        value: updatingState.updating,
                        time: moment().toString(),
                        backgroundUpdating: hasDirtyForms
                    }));
                    yield fork(scheduleUpdateState);
                }

                break;
            case events.onUpdateFailed:
                yield put(actions.setUpdatingState({
                    value: updatingState.default,
                    time: validateLastUpdateTime(lastUpdateTime) ? lastUpdateTime : null,
                    backgroundUpdating: hasDirtyForms
                }));

                break;
            case events.onUpdated:
                yield put(actions.setUpdatingState({
                    value: updatingState.updated,
                    time: moment().toString(),
                    backgroundUpdating: hasDirtyForms
                }));

                if (hasDirtyForms) {
                    yield put(actions.setReloadAfterNavigate(true));
                    break;
                }

                if (prevUpdateAllowed) {
                    window.location.reload();
                }

                break;
            default:
                break;
        }
    }
}

function* handleRouterChange() {
    const reloadAfterNavigate = yield select(selectIsReloadAfterNavigate);

    if (reloadAfterNavigate) {
        window.location.reload();
    }
}

export default function* saga() {
    yield takeEvery(actions.initialize.getType(), handleServiceWorkerInitialize);
    yield takeEvery('@@router/LOCATION_CHANGE', handleRouterChange);
}
