import * as ReactTabs from 'react-tabs';
import TabLink from './tab-link';
import './styles.css';

export const {
    Tab, Tabs, TabList, TabPanel
} = ReactTabs;

export { TabLink };

export const TabDefaultClassNames = {
    root: 'react-tabs',
    list: 'react-tabs__tab-list',
    tab: 'react-tabs__tab',
};
