import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setAccentColor as setAccentColorAction } from './color-theme-actions';
import { themes } from './color-theme-constants';
import { selectAccentColor, selectColorTheme } from './color-theme-selectors';

class ColorThemeController extends Component {
    componentWillMount() {
        const { accentColor, setAccentColor } = this.props;
        setAccentColor({
            color: accentColor
        });
    }

    render() {
        const {
            children,
            accentColor,
            theme,
            setAccentColor,
            className,
            ...rest
        } = this.props;

        return (
            <div
                {...rest}
                className={classnames('color-theme-root', {
                    [`color-theme-${theme}`]: true,
                    [className]: className,
                })}
            >
                {children}
            </div>
        );
    }
}

ColorThemeController.defaultProps = {
    children: undefined,
    className: undefined,
};

ColorThemeController.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    accentColor: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(Object.values(themes)).isRequired,
    setAccentColor: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        accentColor: selectAccentColor(state),
        theme: selectColorTheme(state),
    };
}

export default connect(
    mapStateToProps,
    {
        setAccentColor: setAccentColorAction
    }
)(ColorThemeController);
