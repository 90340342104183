import { Component } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class ColumnsCalc extends Component {
    state = {
        width: 0
    }

    componentDidMount() {
        this.el = ReactDOM.findDOMNode(this);

        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(this.el);
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    handleResize = () => {
        const rect = this.el?.getBoundingClientRect();
        this.setState({ width: rect?.width || 0 });
    }

    render() {
        const { children, maxWidth } = this.props;
        const { width } = this.state;
        const columnsCount = Math.round(width / maxWidth);

        return children(columnsCount);
    }
}

ColumnsCalc.propTypes = {
    maxWidth: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired
};
