import u from 'updeep';
import { createAction } from 'redux-act';
import request from '../../../request';
import { updateInCollection } from '../../../shared/utils/collection';
import { availableWidgetPaths, paths } from './constants';
import { Model } from '../../../shared/entities-v2';

const apiUrl = '/micro_widgets';
const name = 'sp-micro-widgets-model';
export const updateWidgetItem = createAction(`${name}/SET_WATCH_STATUS`);

function applyUpdateToWidgetList(arr, result) {
    return updateInCollection(arr, result.id, result);
}

function updateWidgetItemReducer(state, action) {
    const mostActive = state.attributes[paths.mostActive];
    const recentlyUpdated = state.attributes[paths.mostUpdated].data;

    return u(
        {
            attributes: {
                [paths.mostActive]: applyUpdateToWidgetList(mostActive, action),
                [paths.mostUpdated]: {
                    data: applyUpdateToWidgetList(recentlyUpdated, action),
                }
            }
        },
        state
    );
}

class SpMicroWidgetsModel extends Model {
    // eslint-disable-next-line class-methods-use-this
    fetch(params) {
        if (!params?.goal_id) return Promise.resolve().then(() => {});
        return new Promise(async (res) => {
            const widgetsData = await Promise.all(availableWidgetPaths.map(path =>
                request.get(`/micro_widgets/${path}`, { params })));

            res(availableWidgetPaths.reduce((acc, cur, idx) => {
                acc[cur] = widgetsData[idx];

                return acc;
            }, {}));
        });
    }
}

export const spMicroWidgetsModel = new SpMicroWidgetsModel({
    getApiUrl: () => apiUrl,
    name,
    additionalArtifacts: {
        [Model.additionalArtifacts.actions]: {
            updateWidgetItem,
        },
        [Model.additionalArtifacts.reducers]: {
            [updateWidgetItem]: updateWidgetItemReducer,
        },
    }
});
