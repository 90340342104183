import { find } from '../../shared/utils/collection';

function getToastList(state) {
    return state['notification-toast-list'].list;
}

export function getToastById(state, id) {
    const items = getToastList(state);
    return find(items, id);
}
