import { takeEvery, takeLatest, put } from 'redux-saga/effects';
import createAsyncSaga from '../../sagas/async';

export default function createSaga(actions, api, onSuccess) {
    const create = createAsyncSaga(actions, api.create);
    function* successSaga({ payload }) {
        if (onSuccess) {
            if (Array.isArray(onSuccess)) {
                for (let i = 0; i < onSuccess.length; i += 1) {
                    yield put({
                        type: onSuccess[i].getType(),
                        payload
                    });
                }

                return;
            }

            yield put({
                type: onSuccess.getType(),
                payload
            });
        }
    }
    // const getTagName = createAsyncSaga(actions, api.getTagName);

    return function* listSaga() {
        yield takeEvery(actions.request.getType(), create);
        yield takeLatest(actions.success.getType(), successSaga);
        // yield takeEvery(actions.getTagName.request.getType(), getTagName);
    };
}
