import {
    call, fork, put, race, take, takeEvery
} from 'redux-saga/effects';
import { get } from 'lodash';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { typeCodes } from '../../utils/entity-type';
import apiService from '../../../request';
import { create as createSegment } from '../../../modules/goalmap-strategy-maps/entity';
import { create as createStrategy } from '../../../modules/goalmap-segments/entity';
import { create as createAction } from '../../../modules/goalmap-plans-actions/entity';
import { create as createSegmentChildren, createObjectiveChildren } from '../../../modules/goalmap-segment-children/entity';
import { create as createGlobalKPI } from '../../../modules/goalmap-milestones/entity';
import { csfCreateModel } from '../../../modules/primary-entities/critical-success-factor';
import * as actions from './primary-entity-create-service-actions';

const getApiUrl = (typeCode) => {
    switch (typeCode) {
        case typeCodes.plan:
            return '/goals';
        case typeCodes.segment:
            return '/strategy_maps';
        case typeCodes.strategy:
        case typeCodes.objective:
            return '/segments';
        case typeCodes.tactic:
        case typeCodes.action:
            return '/plans';
        default:
            return null;
    }
};

function getModalAction(parentTypeCode, globalEntityCode) {
    if (globalEntityCode) {
        switch (globalEntityCode) {
            case typeCodes.kpi:
                return createGlobalKPI.actions.toggle;
            default:
                return csfCreateModel.actions.toggle;
        }
    }

    switch (parentTypeCode) {
        case typeCodes.plan:
            return createSegment.actions.toggle;
        case typeCodes.segment:
            return createStrategy.actions.toggle;
        case typeCodes.strategy:
            return createSegmentChildren.actions.toggle;
        case typeCodes.objective:
            return createObjectiveChildren.actions.toggle;
        case typeCodes.action:
        case typeCodes.tactic:
            return createAction.actions.toggle;
        default:
            return null;
    }
}

function* fetchParentData(id, typeCode, planId) {
    const baseUrl = getApiUrl(typeCode);
    const url = `${baseUrl}/${id}`;
    const parentData = yield call(apiService.get, url);

    yield put(actions.setParentData({
        data: parentData,
        params: { id, typeCode, planId }
    }));
}


function* handleCreateStart(action) {
    yield call(implementPromiseAction, action, function* initializeResolver() {
        const {
            parentId, parentTypeCode, planId, globalEntityCode
        } = action.payload;
        const modalAction = getModalAction(parentTypeCode, globalEntityCode);

        yield fork(fetchParentData, parentId, parentTypeCode, planId);
        yield put(modalAction());

        const { success, error } = yield race({
            success: take(actions.success),
            error: take(actions.error),
        });

        if (error) {
            const reason = get(error, 'payload.reason');
            return Promise.reject(new Error(reason));
        }

        return yield Promise.resolve(success);
    });
}

function* primaryEntityCreateSaga() {
    yield takeEvery(actions.create, handleCreateStart);
}

export default primaryEntityCreateSaga;
