import { takeEvery, put, take } from 'redux-saga/effects';
import u from 'updeep';
import { get } from 'lodash';
import { push } from 'react-router-redux';
import config from '../../config';
import { List, createModal, createDetails } from '../../shared/entities';
import * as routeUtils from '../../routes/rules';
import { formatAdvancedCalculationFields } from './settings-form/utils';
import { formatPeriodTargetsToApi } from './settings-form/period-targets/utils';
import { attributes, states } from './constants';
import { formatThresholds, deleteEmptyAdvCalcDataSource, } from './utils';

const apiUrl = '/milestones';

export function format(data) {
    const params = get(data, 'params', data);
    const newThresholds = formatThresholds(params);
    const deletedEmptyAdvCalcDataSource = deleteEmptyAdvCalcDataSource(params[attributes.advanced_calculation_sources]);
    const objToMergeWith = {
        thresholds: undefined,
        target_period: undefined,
        [attributes.kpi_period_targets]: formatPeriodTargetsToApi(
            get(params, attributes.kpi_period_targets),
        ),
        [attributes.advanced_calculation_sources]: deletedEmptyAdvCalcDataSource,
        ...newThresholds,
        ...formatAdvancedCalculationFields(params),
    };

    return {
        ...params,
        ...objToMergeWith
    };
}

export const parentMilestones = new List({
    apiUrl,
    name: 'milestone-parents',
    getApiUrl(payload) {
        return `/datasources/${payload.id}/milestones`;
    },
    parse(response) {
        return {
            data: response
        };
    }
});

export const details = createDetails({
    apiUrl,
    name: 'milestone-details',
    updateHTML: true,
    format,
    onErrorUpdateStatus: createDetails.errorUpdateStatusNotification,
    messages: {
        error: 'Operation failed'
    },
});

export const list = new List({
    apiUrl,
    name: 'milestone-list',
    format(params) {
        const filter = params.filter
            ? {
                [params.filter.split('=')[0]]: params.filter.split('=')[1]
            } : undefined;

        const order_by = params.order_by
            ? [params.order_by]
            : undefined;

        const result = u({
            order_by,
            ...filter,
            filter: undefined,
            limit: config.defaultListLimit,
        }, params);

        return result;
    },
    extendReducer: {
        [details.actions.updateStatus.error]: (state, response) => {
            return {
                ...state,
                updateError: get(response, ['response'], response),
            };
        },
        [details.actions.clearError]: (state) => {
            return {
                ...state,
                updateError: undefined,
            };
        }
    }
});

export const create = createModal({
    apiUrl,
    name: 'milestone-create',
    format,
});

export const clone = createModal({
    apiUrl,
    name: 'milestone-clone',
    format
});

export function* saga() {
    yield takeEvery(
        details.actions.updateStatus.request,
        function* statusUpdateStart(action) {
            yield put({
                type: list.actions.setUpdatingItemId.getType(),
                payload: { id: action.payload.id }
            });
        }
    );

    yield takeEvery(
        details.actions.updateStatus.success,
        function* statusUpdateSuccess(action) {
            yield put({
                type: list.actions.setUpdatingItemId.getType(),
                payload: { id: undefined }
            });
            yield put({
                type: list.actions.updateRaw.getType(),
                payload: { data: action.payload },
            });
        }
    );

    yield takeEvery(
        details.actions.updateStatus.error,
        function* statusUpdateSuccess() {
            yield put({
                type: list.actions.setUpdatingItemId.getType(),
                payload: { id: undefined }
            });
        }
    );

    yield takeEvery(
        clone.actions.request,
        // eslint-disable-next-line consistent-return
        function* cloneRequest(data) {
            const { redirect_after_creating, redirect_to_navigation } = data.payload.params;
            const { payload } = yield take(clone.actions.success);

            if (payload.state === states.draft) return false;

            const updateRedirectPath = redirect_to_navigation
                ? routeUtils.paths.planChildrenKPIUpdate
                : routeUtils.paths.planKPIUpdate;

            const href = routeUtils.createUrl(updateRedirectPath, {
                [routeUtils.params.PlanId]: payload.goal_id,
                [routeUtils.params.EntityId]: payload.id,
            });

            if (redirect_after_creating) return yield put(push(href));
        }
    );

    yield takeEvery(
        details.actions.request,
        function* fetchParentMilestones(payload) {
            if (!payload.id) {
                return;
            }

            yield put({
                type: parentMilestones.actions.request.getType(),
                payload
            });
        }
    );

    yield takeEvery(
        details.actions.reset,
        function* resetParentMilestonesList() {
            yield put(parentMilestones.actions.reset());
        }
    );
}
