/** @namespace Utils.Detection */

/**
 * Detect if browser is Internet Explorer or Edge
 * @function isIEorEdge
 * @memberof Utils.Detection
 * @return {boolean} Internet Explorer or Edge browser detection result
 */
export default function isIEorEdge() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0) {
        // If Internet Explorer 10 or older, return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    return false;
}

/**
 * Detect if browser supports passive events
 * @function isSupportsPassive
 * @memberof Utils.Detection
 * @return {boolean} True if browser supports passive events
 */
export function isSupportsPassive() {
    let supportsPassive = false;

    try {
        window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
            // eslint-disable-next-line getter-return
            get() {
                supportsPassive = true;
            }
        }));
        // eslint-disable-next-line no-empty
    } catch (e) {
    }

    return supportsPassive;
}

/**
 * Detect which wheel event browser supports
 * @function isSupportsPassive
 * @memberof Utils.Detection
 * @return {boolean} True if browser supports passive events
 */
export function supportedWheelEvent() {
    return 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
}
