import PropTypes from 'prop-types';
import { applicationSettingsSelectors } from '../application';
import { selectUserDetails } from '../user/selectors';

export default function contactSalesMapStateToProps(state) {
    const user = selectUserDetails(state);

    return {
        account: applicationSettingsSelectors.selectAccountInfo(state),
        user: {
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            mobilePhone: user.phone_mobile,
            workPhone: user.phone_work,
        },
    };
}

export const propTypes = {
    account: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    }),
    showContactSupport: PropTypes.bool,
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        mobilePhone: PropTypes.string,
        workPhone: PropTypes.string,
    }),
};
