import request from '../../request';
import { Collection, Model } from '../../shared/entities-v2';

const apiUrl = '/roles';
const nameCollection = 'settings-roles-collection';
const nameModel = 'settings-roles-model';

class SettingsRolesCollection extends Collection {
    // eslint-disable-next-line class-methods-use-this
    format() {
        return { type: 2 };
    }
}

export const settingsRolesCollection = new SettingsRolesCollection({
    apiUrl,
    name: nameCollection,
});

class SettingsRolesModel extends Model {
    // eslint-disable-next-line class-methods-use-this
    remove({ id }) {
        return request.delete(`${apiUrl}/${id}`)
            .then(() => ({ id }))
            .catch(this.parseError);
    }
}

export const settingsRolesModel = new SettingsRolesModel({
    apiUrl,
    name: nameModel,
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onCreateSuccess]: settingsRolesCollection.actions.unshift,
            [Model.events.onUpdateSuccess]: settingsRolesCollection.actions.update,
            [Model.events.onRemoveSuccess]: settingsRolesCollection.actions.remove,
        },
    },
});
