/** @namespace Utils.EntityType */
import { get, isNil } from 'lodash';

/**
 * Enum for entity types
 * @typedef {string} EntityType
 * @memberof Utils.EntityType
 * @readonly
 * @enum {EntityType}
 */
export const types = Object.freeze({
    goal: 'goal',
    milestone: 'milestone',
    driver: 'driver',
    strategy_map: 'strategy_map',
    segment: 'segment',
    sub_segment: 'sub_segment',
    plan: 'plan',
    action: 'action',
    plan_action: 'plan_action',
    user_profile: 'user_profile',
    progress_note: 'progress_note',
    attachment: 'attachment',
    user: 'user',
    data_point: 'data_point',
});

/**
 * Enum for entity url types
 * @typedef {string} EntityUrlType
 * @memberof Utils.EntityType
 * @readonly
 * @enum {EntityUrlType}
 */
export const urlTypes = Object.freeze({
    [types.goal]: 'goals',
    [types.milestone]: 'milestones',
    [types.driver]: 'drivers',
    [types.strategy_map]: 'strategy_maps',
    [types.segment]: 'segments',
    [types.sub_segment]: 'segments',
    [types.plan]: 'plans',
    [types.action]: 'plans_actions',
    [types.progress_note]: 'progress_notes',
    [types.attachment]: 'attachments',
    [types.user]: 'user_profile',
    [types.data_point]: 'milestone_data_points',
});

/**
 * Enum for entity counter states
 * @typedef {string} EntityCounterState
 * @memberof Utils.EntityType
 * @readonly
 * @enum {EntityCounterState}
 */
const entityCounterStates = Object.freeze({
    active: 'active',
    archive: 'archive',
    destroy: 'destroy',
    draft: 'draft',
    inactive: 'inactive',
});

/**
 * Get active children entities types of specified entity
 * @function collectExistingEntityCodes
 * @memberof Utils.Entity
 * @param {Object} counters Source entity counters
 * @return {Array<EntityTypeCode>} Active children entities types
 */
// duplicate of getActiveChildrenTypes()
export function collectExistingEntityCodes(counters = {}) {
    const checkStates = [
        entityCounterStates.active,
        entityCounterStates.archive,
        entityCounterStates.draft,
        entityCounterStates.inactive,
    ];

    return Object
        .entries(counters)
        .filter(([, item]) =>
            !!checkStates
                .find(state => item[state] > 0))
        .map(([key]) => Number(key));
}

/**
 * Enum for entity type codes
 * @typedef {number} EntityTypeCode
 * @memberof Utils.EntityType
 * @readonly
 * @enum {EntityTypeCode}
 */
export const typeCodes = Object.freeze({
    action: 1, // t. plan_actions, type = 2, parent_id = foreign key on tactic
    tactic: 2, // t. plan_actions, type = 1, segment_id foreign key on strategy/objective(Segment)
    kpi: 3, // t. goal_milestones, parent_entity_id
    objective: 4, // old name = sub-segment, new - strategy // t. segments, parent_id = foreign key on segment
    strategy: 5, // t. segments, strategy_map_id = $parentId, parent_id = 0
    segment: 6, // t. strategy_maps
    driver: 7,
    plan: 8,
    attachment: 9,
    progressNote: 10,
});

/**
 * Check if entity is segment
 * @function isSegment
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is segment
 */
export const isSegment = ({ entity_type, type_code }) => entity_type === typeCodes.segment || type_code === typeCodes.segment;

/**
 * Check if entity is strategy
 * @function isStrategy
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is strategy
 */
export const isStrategy = ({ entity_type, type_code }) => entity_type === typeCodes.strategy || type_code === typeCodes.strategy;

/**
 * Check if entity is objective
 * @function isObjective
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is objective
 */
export const isObjective = ({ entity_type, type_code }) => entity_type === typeCodes.objective || type_code === typeCodes.objective;

/**
 * Check if entity is tactic
 * @function isTactic
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is tactic
 */
export const isTactic = ({ entity_type, type_code }) => entity_type === typeCodes.tactic || type_code === typeCodes.tactic;

/**
 * Check if entity is action
 * @function isAction
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is action
 */
export const isAction = ({ entity_type, type_code }) => entity_type === typeCodes.action || type_code === typeCodes.action;

/**
 * Check if entity is kpi
 * @function isMilestone
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is kpi
 */
export const isMilestone = ({ entity_type, type_code }) => entity_type === typeCodes.kpi || type_code === typeCodes.kpi;

/**
 * Check if entity is plan
 * @function isPlan
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is plan
 */
export const isPlan = ({ entity_type, type_code }) => entity_type === typeCodes.plan || type_code === typeCodes.plan;

/**
 * Check if entity is progress note
 * @function isProgressNote
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity type is plan
 */
export const isProgressNote = ({ entity_type, type_code }) =>
    entity_type === typeCodes.progressNote || type_code === typeCodes.progressNote;

/**
 * Compare url type with type
 * @function compareUrlType
 * @memberof Utils.EntityType
 * @param {Entity} data Source entity
 * @param {EntityType} type Type of entity
 * @return {boolean} True if entity type is equal specified type
 */
function compareUrlType(data, type) {
    return data.url_type === urlTypes[type];
}

/**
 * Check if parent is available
 * @function compareParent
 * @memberof Utils.EntityType
 * @param {Entity} data Source entity
 * @return {boolean} True if entity has parent
 */
function compareParent(data) {
    return !!data.parent_id;
}

const selectors = {
    [types.goal]: data => compareUrlType(data, types.goal),
    [types.driver]: data => compareUrlType(data, types.driver),
    [types.milestone]: data => compareUrlType(data, types.milestone),
    [types.strategy_map]: data => compareUrlType(data, types.strategy_map),
    [types.segment]: (data) => {
        if (compareUrlType(data, types.segment) && !compareParent(data)) {
            return true;
        }

        return false;
    },
    [types.sub_segment]: (data) => {
        if (compareUrlType(data, types.segment) && compareParent(data)) {
            return true;
        }

        return false;
    },
    [types.plan]: (data) => {
        if (
            (compareUrlType(data, types.plan) || compareUrlType(data, types.action))
            && (data.child_count > 0 || (isNil(data.child_count) && get(data, ['actions', 'length']) > 0))
        ) {
            return true;
        }

        return false;
    },
    [types.action]: (data) => {
        if (
            (compareUrlType(data, types.plan) || compareUrlType(data, types.action))
            && !data.child_count
        ) {
            return true;
        }

        return false;
    },
    [types.user_profile]: (data) => {
        if (data.full_name) {
            return true;
        }

        return false;
    },
    [types.progress_note]: data => compareUrlType(data, types.progress_note),
    [types.attachment]: data => compareUrlType(data, types.attachment),
};

/**
 * Get type of entity
 * @function getTypeByEntity
 * @memberof Utils.EntityType
 * @param {Entity} entity Source entity
 * @return {EntityType} Returns entity type
 */
export function getTypeByEntity(entity) {
    if (!Object.keys(entity).length) {
        return '';
    }

    return Object.keys(types).find(key => (selectors[key] ? selectors[key](entity) : undefined));
}

export function getCodeByType(type) {
    switch (type) {
        case types.milestone:
            return typeCodes.kpi;
        // This is needed to handle a case with abnormal entity type
        // Should be fixed with Tech Debt ticket https://mpowrsoftware.atlassian.net/browse/ENVSC-6601
        case 'plan_action':
        case types.plan:
            return typeCodes.tactic;
        case types.action:
            return typeCodes.action;
        case types.segment:
            return typeCodes.strategy;
        case types.sub_segment:
            return typeCodes.objective;
        case types.goal:
            return typeCodes.plan;
        case types.driver:
            return typeCodes.driver;
        case types.strategy_map:
        default:
            return typeCodes.segment;
    }
}

/**
 * Get type code of entity
 * @function getEntityCode
 * @memberof Utils.EntityType
 * @param {Entity} entity Source entity
 * @return {EntityTypeCode} Returns entity type
 */
export function getEntityCode(entity) {
    const type = getTypeByEntity(entity);
    return getCodeByType(type);
}


/**
 * Get entity type by entity type code
 * @function getStringTypeByCode
 * @memberof Utils.EntityType
 * @param {EntityTypeCode} code Converted entity type code
 * @return {EntityTypeCode} Returns entity type
 */
export function getStringTypeByCode(code) {
    switch (code) {
        case typeCodes.segment:
            return types.strategy_map;
        case typeCodes.strategy:
            return types.segment;
        case typeCodes.objective:
            return types.sub_segment;
        case typeCodes.tactic:
            return types.plan;
        case typeCodes.kpi:
            return types.milestone;
        case typeCodes.driver:
            return types.driver;
        case typeCodes.action:
            return types.action;
        case typeCodes.progressNote:
            return types.progress_note;
        case typeCodes.attachment:
            return types.attachment;
        case typeCodes.goal:
        default:
            return types.goal;
    }
}

/**
 * Normalize entity type
 * @function normalizeType
 * @memberof Utils.EntityType
 * @param {string} type Prefixed entity type
 * @return {string} Normalized entity type
 */
export default function normalizeType(type = '') {
    let result = type.replace('App\\', '').toLowerCase();

    if (result === 'milestones') {
        return 'milestone';
    }

    if (result === 'drivers') {
        return 'driver';
    }

    if (result === 'planaction' || result === 'plans_action' || result === 'plan' || result === 'plans_actions') {
        result = 'plan_action';
    }

    if (result === 'strategymap' || result === 'strategy_maps') {
        result = 'strategy_map';
    }

    if (result === 'segments') {
        result = 'segment';
    }

    return result;
}

/**
 * Check if entity is objective by url type
 * @function isObjectiveByUrl
 * @memberof Utils.EntityType
 * @param {Entity} item Source entity
 * @return {boolean} True if entity is objective
 */
export const isObjectiveByUrl = item => (getTypeByEntity(item) === types.sub_segment);

/**
 * Check if entity can have Progress Note
 * @function canHaveProgressNotes
 * @memberof Utils.EntityType
 * @param {EntityTypeCode} code entity type code
 * @return {boolean} True if entity can have Progress Note
 */
export const canHaveProgressNotes = type_code =>
    [typeCodes.action, typeCodes.tactic, typeCodes.kpi, typeCodes.objective, typeCodes.strategy,
        typeCodes.segment, typeCodes.driver, typeCodes.plan].includes(type_code);

/**
 * Check if entity can have Collaborators
 * @function canHaveCollaborators
 * @memberof Utils.EntityType
 * @param {EntityTypeCode} code entity type code
 * @return {boolean} True if entity can have Collaborators
 */
export const canHaveCollaborators = type_code =>
    [typeCodes.action, typeCodes.tactic, typeCodes.kpi, typeCodes.objective, typeCodes.strategy, typeCodes.driver].includes(type_code);
