import React, { Component } from 'react';

export default function withFilePickerState(EnhancedComponent) {
    return class FilePickerStateProvider extends Component {
        state = {
            visible: false,
        };

        toggle = () => {
            this.setState({
                visible: !this.state.visible,
            });
        }

        show = () => {
            this.setState({
                visible: true,
            });
        }

        hide = () => {
            this.setState({
                visible: false,
            });
        }

        render() {
            return (
                <EnhancedComponent
                    {...this.props}
                    filePicker={{
                        visible: this.state.visible,
                        toggle: this.toggle,
                        show: this.show,
                        hide: this.hide,
                    }}
                />
            );
        }
    };
}
