import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const weights = {
    boldest: 'boldest',
    bold: 'bold',
    regular: 'regular',
    light: 'light',
};

export default function Text({
    children,
    component,
    className,
    styleType,
    size,
    bold,
    italic,
    underline,
    overflowEllipsis,
    weight,
    capitalize,
    align,
    ...rest
}) {
    const Component = component;

    return (
        <Component
            className={classnames({
                [`text-color-${styleType}`]: !!styleType,
                [className]: !!className,
                [`text-size-${size}`]: !!size,
                [`text-weight-${weight}`]: !!weight,
                [`text-align-${align}`]: !!align,
                'text-weight-bold': bold,
                'text-style-italic': italic,
                'text-decoration-underline': underline,
                'text-overflow-ellipsis': overflowEllipsis,
                'text-capitalize': capitalize,
            })}
            {...rest}
        >
            {children}
        </Component>
    );
}

Text.defaultProps = {
    children: undefined,
    className: undefined,
    styleType: undefined,
    component: 'span',
    size: 'medium',
    bold: false,
    italic: false,
    underline: false,
    overflowEllipsis: false,
    weight: undefined,
    capitalize: false,
    align: undefined,
};

Text.propTypes = {
    children: PropTypes.node,
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func
    ]),
    className: PropTypes.string,
    styleType: PropTypes.string,
    size: PropTypes.oneOf([
        'small',
        'medium',
        'medium-large',
        'large',
        'xlarge',
        'xxlarge',
    ]),
    align: PropTypes.oneOf([
        'center',
        'right',
        'left',
    ]),
    weight: PropTypes.oneOf([
        weights.bold,
        weights.light,
        weights.regular,
        weights.boldest,
    ]),
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    overflowEllipsis: PropTypes.bool,
    capitalize: PropTypes.bool,
};

Text.weights = weights;
