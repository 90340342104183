import {
    put, select, takeEvery, call, race, take, fork, delay
} from 'redux-saga/effects';
import { get } from 'lodash';
import { push, replace } from 'react-router-redux';
import queryString from 'query-string';
import { joinGetParameters } from '../../shared/utils/url';
import { wait } from '../../shared/utils/common';
import * as ToastNotification from '../notifications/toast';
import { getMobileNotificationRedirectHref } from '../notifications/notification-utils';
import { getPlansItems } from '../plans-list-my/selectors';
import { prepareEntityTypeFilter } from './timeline/model/timeline-model-utils';
import { workCenterViewTypes, overrideQueryValues } from './constants';
import workCenterTimelineModel from './timeline/model/timeline-model';
import workCenterCollection from './work-center-collection';
import workCenterGridModel from './grid/model/grid-model';
import customFilterModel, { selectFilterData } from './custom-filters/model';
import workCenterDefaultFitlerModel from './work-center-default-filter-model';
import { selectEmptyFilterData } from './selectors';
import { formatFilterData } from './mappers';
import {
    workCenterFetch, workCenterRedirect, workCenterViewTypeChange,
    workCenterMobileRedirect, workCenterReset, workCenterOverride,
    workCenterNotificationOverride
} from './actions';
import { selectUserId } from '../user/selectors';
import { workCenterFilterOverrides } from './work-center-redirect';

function* workCenterFetchSaga({ payload }) {
    const { filterValues, workCenterView } = payload;

    const emptyFilterData = yield select(selectEmptyFilterData);

    const data = {
        ...formatFilterData(filterValues, emptyFilterData),
        entity_type_filter: prepareEntityTypeFilter(filterValues.entity_type_filter, filterValues.calendar_type_filter),
    };

    switch (workCenterView) {
        case workCenterViewTypes.timeline:
            yield put(workCenterTimelineModel.actions.fetch.request({
                data,
                meta: {
                    dateRangeUnit: filterValues.period_type,
                    ...get(payload, 'params', {}),
                },
            }));
            break;
        case workCenterViewTypes.grid:
            yield put(workCenterGridModel.actions.fetch.request(data));
            break;
        case workCenterViewTypes.calendar:
        default: yield put(workCenterCollection.actions.fetch.request(data));
    }
}

function* handleWorkCenterOverride({ payload }) {
    let filterData = yield select(selectFilterData);

    if (!filterData) {
        const action = workCenterDefaultFitlerModel.actions.fetch.request();
        yield put(action);
        const { success, error } = yield race({
            success: take(workCenterDefaultFitlerModel.actions.fetch.success),
            error: take(workCenterDefaultFitlerModel.actions.fetch.error),
        });

        if (error) {
            yield put(ToastNotification.create({
                type: ToastNotification.serviceTypes.ERROR,
                text: 'Could not initialize Work Center. Please, try again'
            }));
            return;
        }

        const response = success.payload?.response;
        filterData = { ...response, type: response.filter_type };
    }

    const nextFilterData = payload.override(payload.data, filterData);
    yield put(customFilterModel.additionalArtifacts.actions.setFilterData(nextFilterData));
}

function* handleWorkCenterRedirect(action) {
    const { payload } = action;

    yield call(handleWorkCenterOverride, action);

    const query = payload.query && queryString.stringify(payload.query);
    const href = joinGetParameters('/work-center/grid', query);

    yield put(workCenterViewTypeChange(workCenterViewTypes.grid));
    yield put(push(href));
}

function* handleWorkCenterNotificationOverride({ payload }) {
    const { type, onComplete } = payload;

    if (type === overrideQueryValues.reminder) {
        const ownerId = yield select(selectUserId);

        const data = {
            data: {
                planId: null,
                ownerId,
            },
            override: workCenterFilterOverrides.reminder,
        };

        yield call(handleWorkCenterOverride, { payload: data });
        onComplete();
        return;
    }

    onComplete();
}

function* handleMobileRedirect({ payload }) {
    const plans = yield select(getPlansItems);

    if (plans.length) {
        const href = getMobileNotificationRedirectHref(plans[0].id, payload);
        yield put(replace(href));
    } else {
        yield call(wait, 1500);
        yield call(handleMobileRedirect, { payload });
    }
}

const fieldComparators = {
    isEqual: (prev, next) => prev !== next,
    isNextEnabled: (prev, next) => !!next,
};

const shouldSkipDelayFieldComparators = {
    'filterValues.entity_state_filter': fieldComparators.isEqual,
    'filterValues.start_date': fieldComparators.isEqual,
    'filterValues.end_date': fieldComparators.isEqual,
    'filterValues.calendar_type_filter': fieldComparators.isEqual,
    'workCenterView': fieldComparators.isEqual,
    'params.forceApiFetch': fieldComparators.isNextEnabled,
};

const shouldSkipDelay = (prev, next) => {
    return !!Object
        .entries(shouldSkipDelayFieldComparators)
        .find(([field, comparator]) => comparator(get(prev, field), get(next, field)));
};

const workCenterDebounce = (pattern, task, ...args) => fork(function* customDebounceCallback() {
    let prevValues = null;

    while (true) {
        let action = yield take(pattern);

        while (true) {
            let ms = 1500;

            if (!prevValues || shouldSkipDelay(prevValues, action.payload)) {
                ms = 150;
            }

            prevValues = action.payload;

            const { debounced, latestAction } = yield race({
                debounced: delay(ms),
                latestAction: take(pattern)
            });

            if (latestAction) {
                const nextValues = latestAction.payload;
                if (shouldSkipDelay(prevValues, nextValues)) {
                    yield fork(task, ...args, latestAction);
                    prevValues = nextValues;
                    break;
                }
            }

            if (debounced) {
                yield fork(task, ...args, action);
                break;
            }

            action = latestAction;
        }
    }
});

function* handleReset() {
    yield put(workCenterTimelineModel.actions.reset());
    yield put(workCenterGridModel.actions.reset());
    yield put(workCenterCollection.actions.reset());
}

export default function* saga() {
    yield workCenterDebounce(
        workCenterFetch.getType(),
        workCenterFetchSaga
    );

    yield takeEvery(workCenterNotificationOverride, handleWorkCenterNotificationOverride);
    yield takeEvery(workCenterOverride, handleWorkCenterOverride);
    yield takeEvery(workCenterRedirect, handleWorkCenterRedirect);
    yield takeEvery(workCenterMobileRedirect, handleMobileRedirect);
    yield takeEvery(workCenterReset, handleReset);
}
