/**
 * @typedef {Object} GridModelFetchResponse
 * @memberof WorkCenterGrid
 * @prop {Array} items - Array of items
 * @prop {Array} parents - Array of parent items
 * @prop {Array} owners - Array of owner items
 * @prop {Array} calendar_type_filters - Array of filter counters
 */

export const GridModelFetchResponse = Object.freeze({
    items: 'items',
    parents: 'parents',
    owners: 'owners',
    calendarTypeFilters: 'calendar_type_filters',
});

/**
 * @typedef {Object} APIGridModelFetchParams
 * @memberof WorkCenterGrid
 * @prop {Array} entity_type_filter - Entity types to include
 * @prop {Boolean} collaboration - Include collaboration entities
 * @prop {String} calendar_type_filter - Filter type
 * @prop {Object} custom_filter_config - Filter config object
 */

export const GridModelParamKeys = Object.freeze({
    entityTypeFilter: 'entity_type_filter',
    collaboration: 'collaboration',
    calendarTypeFilter: 'calendar_type_filter',
    customFilterConfig: 'custom_filter_config',
});
