import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import {
    Text,
    Icon,
    Clickable
} from '../index';
import { isEmptyHTML } from '../../utils/editor-value';

import './styles.css';
import HTMLRenderer from '../html-renderer/component';
import { wait } from '../../utils/common';

export default class TextTruncated extends PureComponent {
    state = {
        truncated: false,
        handled: false,
    };

    handleReflow = (event) => {
        const { handled } = this.state;

        if (handled) {
            return;
        }

        wait(15).then(() => this.setState({
            truncated: event.clamped,
            handled: true,
        }));
    }

    renderHTML() {
        const {
            className, text, truncate, maxLines, ellipsis
        } = this.props;

        if (!truncate) {
            return (
                <HTMLRenderer className={className}>
                    {text}
                </HTMLRenderer>
            );
        }

        return (
            <HTMLEllipsis
                className={cx('description-content', {
                    [className]: !!className,
                })}
                unsafeHTML={text}
                maxLine={truncate ? maxLines : null}
                ellipsis={ellipsis}
                basedOn="letters"
                onReflow={this.handleReflow}
            />
        );
    }

    render() {
        const { truncated } = this.state;
        const {
            text, href, readMore, placeholder, onClick, classNameLink, classNameIcon
        } = this.props;

        return (
            <Fragment>
                {(!isEmptyHTML(text)) && (
                    this.renderHTML()
                )}

                {(isEmptyHTML(text)) && (
                    <div className="no-description-content">{placeholder}</div>
                )}

                {readMore && truncated && !onClick && (
                    <Link
                        to={href}
                        className={cx('text-truncated-link', {
                            [classNameLink]: !!classNameLink,
                        })}
                    >
                        Read more{' '}
                        <Text
                            component={Icon}
                            size="small"
                            type="solid"
                            styleType="accent"
                            name="chevron-right"
                            className={classNameIcon}
                        />
                    </Link>
                )}

                {readMore && truncated && onClick && (
                    <Clickable
                        onClick={onClick}
                        className={cx('text-truncated-link', {
                            [classNameLink]: !!classNameLink,
                        })}
                    >
                        Read more{' '}
                        <Text
                            component={Icon}
                            size="small"
                            type="solid"
                            styleType="accent"
                            name="chevron-right"
                            className={classNameIcon}
                        />
                    </Clickable>
                )}
            </Fragment>
        );
    }
}

TextTruncated.defaultProps = {
    text: '',
    maxLines: 4,
    ellipsis: '...',
    readMore: true,
    className: undefined,
    classNameLink: undefined,
    classNameIcon: undefined,
    onClick: undefined,
    href: '',
    placeholder: 'No description',
    truncate: true
};

TextTruncated.propTypes = {
    text: PropTypes.string,
    maxLines: PropTypes.number,
    ellipsis: PropTypes.string,
    href: PropTypes.string,
    readMore: PropTypes.bool,
    className: PropTypes.string,
    classNameLink: PropTypes.string,
    classNameIcon: PropTypes.string,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    truncate: PropTypes.bool
};
