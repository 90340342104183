import * as routeUtils from '../../routes/rules';
import { planTitle } from '../../shared/constants/titles';

export const stepsTypes = {
    NAME_PLAN: 'name_plan',
    STRUCTURE: 'structure',
    VMV: 'vmv',
    BUILD_PLAN: 'build_plan',
    COMPLETED: 'completed'
};

export const rootPath = routeUtils.createUrl(routeUtils.paths.planCreate);

export const steps = [
    {
        name: stepsTypes.NAME_PLAN,
        title: 'Name Plan and Add Users',
        description: `Name your ${planTitle}, Assign Plan Owner, and Add Users.`
    },
    {
        name: stepsTypes.STRUCTURE,
        title: 'Structure and Terminology',
        description: 'View the Structure of the Plan and change titles if needed. If the titles work for your Plan, please click Next.'
    },
    {
        name: stepsTypes.VMV,
        title: 'Vision, Mission and Values',
        description: 'Enter your Vision, Mission, and Values or if creating from scratch use the content on the right for assistance.'
    },
    {
        name: stepsTypes.BUILD_PLAN,
        title: 'Build Plan',
        description: 'Click the “+” to start building your Plan. You can also edit, move, and delete entities on this step.'
    },
    {
        name: stepsTypes.COMPLETED,
        title: 'Completed',
        description: 'Verify Plan Owner, Activate Email Notifications, Digest Summary Report and See it Happen!'
    },
];

export const PLAN_NAVIGATION_BUILD_MODE = 'PLAN_NAVIGATION_BUILD_MODE';
