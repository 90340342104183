/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import he from 'he';
import { Clickable, Text, Icon } from '../index';
import './styles.css';

const ELLIPSES = '…';
const SHOW_LESS_TEXT = 'Show Less';
const SHOW_MORE_TEXT = 'Read More';

class ReadMore extends Component {
  state = {
      showingAll: false
  }

  componentDidMount() {
      this.props.onContentChange();
  }

  toggleReadMore = () => {
      this.setState({
          showingAll: !this.state.showingAll
      });
  }

  getReadMoreParts = ({ cleanedText, characterLimit }) => {
      let teaserText;
      let remainingText = '';

      const isTextBiggerThanLimit = cleanedText.length > characterLimit;

      if (cleanedText) {
          teaserText = cleanedText.length > characterLimit
              ? cleanedText.slice(0, characterLimit).trim()
              : cleanedText;

          if (isTextBiggerThanLimit) {
              remainingText = cleanedText.slice(characterLimit);
          }
      }

      return {
          teaserText,
          remainingText
      };
  };

  getText = ({
      showingAll, cleanedText, characterLimit
  }) => {
      const {
          teaserText,
          remainingText
      } = this.getReadMoreParts({ cleanedText, characterLimit });

      if (!showingAll && cleanedText && cleanedText.length > characterLimit) {
          return (
              <span>
                  {(teaserText || '').replace(/\s*$/, '')}
                  <span className="read-more__text--remaining read-more__text--hide">
                      {remainingText}
                  </span>{ELLIPSES}
              </span>
          );
      }

      return (
          <span>
              {teaserText}
              <span className="read-more__text--remaining read-more__text--show">
                  {remainingText}
              </span>
          </span>
      );
  }

  getActionButton = ({ showingAll, showLessButton }) => {
      if (showingAll && !showLessButton) {
          return;
      }

      const { onReadMoreClick } = this.props;

      const buttonText = showingAll ? SHOW_LESS_TEXT : SHOW_MORE_TEXT;

      return (
          <Clickable
              onClick={onReadMoreClick || this.toggleReadMore}
              className="read-more__button"
          >
              {buttonText}{' '}<Text
                  component={Icon}
                  size="small"
                  type="solid"
                  styleType="accent"
                  name="chevron-right"
              />
          </Clickable>
      );
  }

  render() {
      const {
          text, characterLimit, showLessButton, numberOfLines, lineHeight, className,
      } = this.props;

      // eslint-disable-next-line no-useless-escape
      const cleanedText = text ? he.decode(text.replace(/<[^>]+>/g, '')) : '';

      const maxHeight = numberOfLines * lineHeight;
      const style = {
          lineHeight,
          maxHeight: `${maxHeight}em`
      };
      const { showingAll } = this.state;
      const textToDisplay = this.getText({
          showingAll, cleanedText, characterLimit
      });
      const actionButton = this.getActionButton({ showingAll, showLessButton });

      const readMoreToShow = cleanedText.length >= characterLimit;

      return (
          <div className={cx('read-more', { [className]: !!className })} style={style}>
              {textToDisplay} {readMoreToShow ? actionButton : null}
          </div>
      );
  }
}

ReadMore.propTypes = {
    text: PropTypes.string,
    numberOfLines: PropTypes.number,
    lineHeight: PropTypes.number,
    showLessButton: PropTypes.bool,
    characterLimit: PropTypes.number,
    onContentChange: PropTypes.func,
    onReadMoreClick: PropTypes.func,
    className: PropTypes.string,
};

ReadMore.defaultProps = {
    numberOfLines: 2,
    text: '',
    lineHeight: 1.4,
    showLessButton: false,
    characterLimit: 55,
    onContentChange() {},
    onReadMoreClick: null,
    className: undefined,
};

export default ReadMore;
