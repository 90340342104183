import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import config from '../../../../../config';
import { details as actionDetails } from '../../../../../modules/goalmap-plans-actions/entity';
import DetailsForm from '../../../../../modules/goalmap-plans-actions/details/form';
import PastDueBadge from '../../../../../modules/goalmap-plans-actions/badge-past-due';
import Permissions from '../../../../../modules/permissions';
import { selectUserDateFormat } from '../../../../../modules/user/selectors';
import { CardEditable as EntityViewBody, DetailsOwnerSection } from '../../../';
import { ProgressStatusInput, Icon, BadgeDraft } from '../../../../components';
import { getIconByTypeCode, isArchived as isArchivedEntity, isDraftEntity } from '../../../../utils/entity';
import { getStringTypeByCode, isAction } from '../../../../utils/entity-type';
import { createHrefByTypeCode } from '../../../../utils/compose-href';
import { createRightDrawerEntityDetailsView } from '../right-drawer-entity-details-view';
import EntityViewHeader from '../entity-view-header';
import RightDrawerTab from '../right-drawer-tab';

import styles from '../styles.module.css';

const { actions, selector } = actionDetails;

const TacticActionView = ({
    data, handleCloseWorkCenterRightDrawer, callbackOnEntityChange, isActiveTab, handleToggleTab, settings, ...rest
}) => {
    const userDateFormat = useSelector(selectUserDateFormat);
    const {
        change, dispatch, onUpdateEntityDetails, canEditOwner, canUpdateEntity, onUpdateEntityTags, entityDescription, quillStickyProps,
    } = rest;

    const {
        id, goal_id, owner_id, type, title, description, tag_name, tags, owner, target_date, closed_date,
        parent_id, segment_id, progress_status, frequency, priority, sensitivity, start_date, status,
        sensitivities, is_past_due, type_code, health_manually,
    } = data;

    const isDraft = isDraftEntity(data);
    const isArchived = isArchivedEntity(data);

    const detailsFormValues = {
        title, tag_name, description, progress_status, status,
    };

    const topFormValues = {
        frequency,
        priority,
        sensitivity,
        target_date: target_date
            ? moment(target_date).format(config.apiResponseDateFormat)
            : null,
        closed_date: closed_date
            ? moment(closed_date).format(config.apiResponseDateFormat)
            : null,
        start_date: start_date ? moment(start_date).format(config.apiResponseDateFormat) : null,
    };

    const initialValues = {
        id, owner_id, type, ...topFormValues, ...detailsFormValues,
    };

    if (parent_id === 0) {
        initialValues.segment_id = segment_id;
    } else {
        initialValues.parent_id = parent_id;
    }
    const formName = `right-drawer-entity-view-${id}`;
    const href = createHrefByTypeCode(type_code, { goal_id, id });
    const entityTitleByPlan = get(
        entityDescription.planSettings,
        getStringTypeByCode(entityDescription.type),
        isAction({ entity_type: entityDescription.type }) ? 'Action' : 'Tactic'
    );

    return (
        <>
            <EntityViewHeader
                dispatch={dispatch}
                change={change}
                onUpdateEntityDetails={onUpdateEntityDetails}
                onSuccessSubmit={callbackOnEntityChange}
                canUpdateEntity={canUpdateEntity}
                health_manually={health_manually}
                handleCloseWorkCenterRightDrawer={handleCloseWorkCenterRightDrawer}
                href={href}
                progress_status={progress_status}
                initialValues={initialValues}
                title={title}
                formName={formName}
                settings={settings}
            />
            <div className={classnames(styles.rightDrawerEntityViewBody, styles.rightDrawerTabWrapper)}>
                <RightDrawerTab
                    handleToggleTab={handleToggleTab}
                    isActiveTab={isActiveTab}
                    shouldRenderChevron={!isDraft && !isArchived}
                />

                <div className={classnames(styles.rightDrawerTabContent, { [styles.visible]: isActiveTab })}>
                    <EntityViewBody
                        className={styles.rightDrawerEntityContent}
                        quillStickyProps={quillStickyProps}
                        isArchivedEntity={isArchived}
                        formName={formName}
                        isDropFieldOnVersionValidCheck
                        shouldRenderHeaderTitle={false}
                        entityTypeTitle={entityTitleByPlan}
                        onSubmit={onUpdateEntityDetails}
                        onSuccessSubmit={callbackOnEntityChange}
                        onTagsSave={onUpdateEntityTags}
                        initialValues={initialValues}
                        editable={canUpdateEntity}
                        icon={getIconByTypeCode(type_code)}
                        entityId={id}
                        goal_id={goal_id}
                        title={title}
                        tagName={tag_name}
                        enableReinitialize
                        description={description}
                        isShowMoreDescription
                        destroyOnUnmount={false}
                        tags={tags}
                        progressStatusInput={(
                            <>
                                {isDraft && <BadgeDraft />}
                                <ProgressStatusInput
                                    value={status}
                                    disabled={!canUpdateEntity}
                                    onChange={(value) => {
                                        dispatch(change('plan-details-form', 'status', value));
                                        onUpdateEntityDetails({
                                            params: {
                                                ...initialValues,
                                                status: value
                                            },
                                            onSuccess: callbackOnEntityChange,
                                        });
                                    }}
                                />
                            </>
                        )}
                        planTitle={(
                            <div
                                className={styles.rightDrawerDetailsEntityPlanTitle}
                                style={{ backgroundColor: get(entityDescription, 'planColor', '#FFF3D6') }}
                            >
                                <Icon type="regular" className={styles.detailsEntityTypeIcon} name="layer-group" />
                                {entityDescription.planTitle}
                            </div>
                        )}
                        detailsForm={(
                            <DetailsForm
                                initialValues={initialValues}
                                onSubmit={({ params, ...restArgs }) => {
                                    onUpdateEntityDetails({
                                        params: {
                                            ...params,
                                            ...detailsFormValues,
                                        },
                                        ...restArgs,
                                    });
                                }}
                                onSubmitSuccess={callbackOnEntityChange}
                                editable={canUpdateEntity}
                                sensitivities={sensitivities}
                                isWorkCenterRightDrawer
                                userDateFormat={userDateFormat}
                            />
                        )}
                        headerChildren={(
                            <>
                                <DetailsOwnerSection
                                    owner={owner}
                                    goal_id={goal_id}
                                    initialValues={initialValues}
                                    onSubmit={onUpdateEntityDetails}
                                    onSuccessSubmit={callbackOnEntityChange}
                                    editable={canEditOwner}
                                    enableReinitialize
                                    isCancelEditOnVersionValidCheck
                                />
                            </>
                        )}
                        prependBody={(
                            <>
                                {is_past_due && <PastDueBadge />}
                            </>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

TacticActionView.defaultProps = {
    handleCloseWorkCenterRightDrawer() { },
    callbackOnEntityChange() { },
};

TacticActionView.propTypes = {
    data: PropTypes.object.isRequired,
    handleCloseWorkCenterRightDrawer: PropTypes.func,
    callbackOnEntityChange: PropTypes.func,
    isActiveTab: PropTypes.bool.isRequired,
    handleToggleTab: PropTypes.func.isRequired,
    entityDescription: PropTypes.object.isRequired,
    settings: PropTypes.array.isRequired,
};

export default createRightDrawerEntityDetailsView({
    formName: 'right-drawer-tactic-action-details-form',
    entityType: 'plan_action',
    actions,
    selector,
    permissionsSection: Permissions.constants.entitySections.tactic,
    mapStateToProps() { return {}; },
})(TacticActionView);
