export function mapActionsToLegacyFormat(actions) {
    return {
        request: actions.fetch.request,
        requestMore: actions.fetchLazy.request,
        reset: actions.reset,
    };
}

export function legacyFormatSelectorFactory(selectors) {
    return function legacyFormatSelector(state) {
        return {
            items: selectors.getItems(state),
            loading: selectors.getFetchingState(state),
            loadingMore: selectors.getFetchingLazyState(state),
            pager: selectors.getPager(state),
            error: selectors.getError(state),
        };
    };
}
