import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import config from '../../../config';
import Image from '../image/component';
import { isAbsoluteLink, isBase64 } from '../../utils/url';
import { get as getCustomProp } from '../../../shared/utils/css-custom-properties';
import './styles.css';
import { adjustColor } from '../../utils/color';

const defaultAvatar = 'https://placehold.it/50x50';
const accentColor = '--color-accent';

function getInitials(full_name, first_name, last_name) {
    if (full_name && (!first_name || !last_name)) {
        return `${((full_name.split(' ')[0])[0])}${((full_name.split(' ')[1])[0])}`;
    }
    return `${((first_name)[0])}${((last_name)[0])}`;
}

const presenceStyleTypes = Object.freeze({
    success: 'success',
    warning: 'warning',
    danger: 'danger',
});

export default class Avatar extends Component {
    constructor(args) {
        super(args);

        this.onError = this.onError.bind(this);

        this.state = {
            error: false,
        };
    }

    static getAvatarUrl(src) {
        return (isAbsoluteLink(src) || isBase64(src)) ? src : `${config.attachmentBaseUrl()}/${src}`;
    }

    componentDidUpdate(prevProps) {
        if (this.props.src !== prevProps.src) {
            this.dropError();
        }
    }

    onError() {
        this.setState({
            error: true,
        });
    }

    dropError() {
        this.setState({
            error: false,
        });
    }

    renderPresence = () => {
        const { presenceStyleType } = this.props;

        if (!presenceStyleType) {
            return null;
        }

        return (
            <span
                className={classnames('avatar-presence', {
                    [`avatar-presence-${presenceStyleType}`]: true
                })}
            />
        );
    }

    render() {
        const {
            className,
            classNameContainer,
            borderless,
            src,
            full_name,
            first_name,
            last_name,
            highFont,
            children,
            size,
            ...rest
        } = this.props;

        const {
            error,
        } = this.state;

        const mainColorValue = getCustomProp(accentColor);
        const noAvatarCss = {
            backgroundColor: adjustColor(mainColorValue, 0.88),
            border: `1px solid ${mainColorValue}`,
            color: `${mainColorValue}`
        };

        if (!src || error) {
            return (
                <div className={classnames('user-avatar image-container', {
                    [classNameContainer]: classNameContainer,
                }, `avatar-size-${size}`)}
                >
                    <div
                        style={noAvatarCss}
                        className={classnames('no-avatar', {
                            'avatar-borderless': borderless,
                            'no-avatar-high-font': !!highFont,
                            [className]: className,
                        })}
                    >
                        {children || getInitials(full_name, first_name, last_name)}
                    </div>

                    {this.renderPresence()}
                </div>
            );
        }

        return (
            <div className={classnames('user-avatar image-container', {
                [classNameContainer]: classNameContainer,
            }, `avatar-size-${size}`)}
            >
                <Image
                    className={classnames('avatar', {
                        'avatar-borderless': borderless,
                        [className]: className,
                    })}
                    src={
                        !src || error
                            ? defaultAvatar
                            : Avatar.getAvatarUrl(src)
                    }
                    {...rest}
                    onError={this.onError}
                />

                {this.renderPresence()}
            </div>
        );
    }
}

Avatar.presenceStyleTypes = presenceStyleTypes;
Avatar.sizes = {
    small: 'small',
    medium: 'medium'
};

Avatar.defaultProps = {
    className: undefined,
    classNameContainer: undefined,
    borderless: false,
    src: undefined,
    full_name: undefined,
    first_name: undefined,
    last_name: undefined,
    userStatus: undefined,
    userList: undefined,
    highFont: undefined,
    children: undefined,
    presenceStyleType: undefined,
    size: Avatar.sizes.medium
};

Avatar.propTypes = {
    className: PropTypes.string,
    classNameContainer: PropTypes.string,
    borderless: PropTypes.bool,
    src: PropTypes.string,
    full_name: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    userStatus: PropTypes.number,
    userList: PropTypes.bool,
    highFont: PropTypes.bool,
    children: PropTypes.node,
    presenceStyleType: PropTypes.oneOf([
        presenceStyleTypes.danger,
        presenceStyleTypes.warning,
        presenceStyleTypes.success,
    ]),
    size: PropTypes.string
};
