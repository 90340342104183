import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import Permissions from '../../../../../modules/permissions';
import { subscriptionFields, subscriptionLimitSelectors, SubscriptionContactSalesModalProvider } from '../../../../../modules/subscription';
import { details as kpiDetails } from '../../../../../modules/goalmap-milestones/entity';
import { attributes, entityType, states } from '../../../../../modules/goalmap-milestones/constants';
import { hasLimitErrorOfType, } from '../../../../../modules/goalmap-milestones/selectors';
import { getInitialValues, checkKpiSubscriptionLimitBeforeChangeState } from '../../../../../modules/goalmap-milestones/details/utils';
import {
    getLocationIcon, isGlobal as isGlobalKpi,
} from '../../../../../modules/goalmap-milestones/utils';
import { CardEditable as EntityViewBody, DetailsOwnerSection, } from '../../../';
import { ProgressStatusInput, Icon, BadgeDraft } from '../../../../components';
import { getIconByTypeCode, isArchived as isArchivedEntity, isDraftEntity } from '../../../../utils/entity';
import * as routeUtils from '../../../../../routes/rules';
import { createHrefByTypeCode } from '../../../../utils/compose-href';
import { createRightDrawerEntityDetailsView } from '../right-drawer-entity-details-view';
import EntityViewHeader from '../entity-view-header';
import KpiCardBody from '../kpi-card-body';
import EntityDataPoint from '../entity-data-point';
import { tabType } from '../../constants';
import RightDrawerTab from '../right-drawer-tab';

import styles from '../styles.module.css';

const { actions, selector } = kpiDetails;

const KpiView = ({
    data, handleCloseWorkCenterRightDrawer, callbackOnEntityChange, isActiveTab, handleToggleTab, activeTab, settings, ...rest
}) => {
    const [isActive, setIsActive] = useState();
    const {
        onUpdateEntityDetails, onUpdateEntityTags, onUpdateEntityStatus, kpiLimit, hasLimitError,
        canUpdateEntity, canEditOwner, entityDescription, quillStickyProps,
    } = rest;
    const { isMaxValue, used, value } = kpiLimit;
    const {
        id, goal_id, title, description, tag_name, tags, progress_status, type_code, advanced_calculation_enabled
    } = data;
    const isDraft = isDraftEntity(data);
    const isArchived = isArchivedEntity(data);
    const initialValues = getInitialValues(data);

    useLayoutEffect(() => {
        setIsActive(data.state === states.active);
    }, []);

    const handleProgressStatusChange = (modalProps) => {
        const isCanChangeKpiState = checkKpiSubscriptionLimitBeforeChangeState(isActive, kpiLimit, modalProps);

        if (isCanChangeKpiState) {
            onUpdateEntityStatus({
                id: get(data, attributes.id),
                state: isActive ? 'inactive' : 'active',
                onSuccess: callbackOnEntityChange,
            });
            setIsActive(!isActive);
        }
    };

    const KPI = get(entityDescription.planSettings, entityType, 'KPI');
    const text = `
    You have used ${used} of ${value}
    available ${KPI}`;
    const addon = ', please contact support to upgrade your plan';

    const href = isGlobalKpi(data)
        ? routeUtils.createUrl(routeUtils.paths.planKPIDetails, {
            [routeUtils.params.PlanId]: goal_id,
            [routeUtils.params.EntityId]: id,
        })
        : createHrefByTypeCode(type_code, { goal_id, id });
    const formName = `right-drawer-entity-view-${id}`;

    return (
        <>
            <EntityViewHeader
                onUpdateEntityDetails={onUpdateEntityDetails}
                canUpdateEntity={canUpdateEntity}
                onSuccessSubmit={callbackOnEntityChange}
                handleCloseWorkCenterRightDrawer={handleCloseWorkCenterRightDrawer}
                href={href}
                progress_status={progress_status}
                initialValues={initialValues}
                title={title}
                formName={formName}
                isGlobalEntity={isGlobalKpi(data)}
                settings={settings}
            />
            <div className={classnames(styles.rightDrawerEntityViewBody, styles.withAdditionalTab, styles.rightDrawerTabWrapper)}>
                <RightDrawerTab
                    handleToggleTab={handleToggleTab}
                    isActiveTab={isActiveTab}
                    shouldRenderChevron={!isDraft && !isArchived}
                />

                <div className={classnames(styles.rightDrawerTabContent, styles.withAdditionalTab, { [styles.visible]: isActiveTab })}>
                    <SubscriptionContactSalesModalProvider
                        text={isMaxValue ? text : (text + addon)}
                        visible={hasLimitError}
                    >
                        {(modalProps) => {
                            return (
                                <EntityViewBody
                                    className={styles.rightDrawerEntityContent}
                                    quillStickyProps={quillStickyProps}
                                    isArchivedEntity={isArchived}
                                    formName={formName}
                                    isDropFieldOnVersionValidCheck
                                    shouldRenderHeaderTitle={false}
                                    entityTypeTitle={KPI}
                                    kpiType={get(data, attributes.kpi_type)}
                                    onSubmit={onUpdateEntityDetails}
                                    onSuccessSubmit={callbackOnEntityChange}
                                    onTagsSave={onUpdateEntityTags}
                                    initialValues={initialValues}
                                    icon={getIconByTypeCode(data)}
                                    entityId={id}
                                    goal_id={goal_id}
                                    title={title}
                                    tagName={tag_name}
                                    enableReinitialize
                                    description={description}
                                    destroyOnUnmount={false}
                                    tags={tags}
                                    editable={canUpdateEntity}
                                    started_at={get(data, attributes.started_at)}
                                    required_from={get(data, attributes.required_from, 'first')}
                                    isShowMoreDescription
                                    headerIcons={(
                                        <div className={styles.rightDrawerKpiCardHeaderIconContainer}>
                                            {advanced_calculation_enabled && (
                                                <div className={styles.rightDrawerKpiCardHeaderAdvanced}>
                                                    <Icon
                                                        name="calculator"
                                                        type={Icon.types.regular}
                                                    />
                                                    <div className={styles.rightDrawerKpiCardHeaderAdvancedText}>Advanced</div>
                                                </div>
                                            )}
                                            <Icon
                                                name={getLocationIcon(data)}
                                                type={Icon.types.regular}
                                            />
                                        </div>
                                    )}
                                    progressStatusInput={(
                                        <>
                                            {isDraft && <BadgeDraft />}
                                            <ProgressStatusInput
                                                value={isActive ? 'active' : 'inactive'}
                                                onChange={() => handleProgressStatusChange(modalProps)}
                                                isKpi
                                            />
                                        </>
                                    )}
                                    planTitle={(
                                        <div
                                            className={styles.rightDrawerDetailsEntityPlanTitle}
                                            style={{ backgroundColor: get(entityDescription, 'planColor', '#FFF3D6') }}
                                        >
                                            <Icon type="regular" className={styles.detailsEntityTypeIcon} name="layer-group" />
                                            {entityDescription.planTitle}
                                        </div>
                                    )}
                                    headerChildren={(
                                        <DetailsOwnerSection
                                            owner={get(data, attributes.owner)}
                                            entity_id={id}
                                            goal_id={goal_id}
                                            initialValues={initialValues}
                                            onSubmit={onUpdateEntityDetails}
                                            onSuccessSubmit={callbackOnEntityChange}
                                            enableReinitialize
                                            isCancelEditOnVersionValidCheck
                                            editable={canEditOwner}
                                        />
                                    )}
                                    prependBody={
                                        <KpiCardBody data={data} />
                                    }
                                />
                            );
                        }}
                    </SubscriptionContactSalesModalProvider>
                </div>
            </div>
            {!isDraft && (
                <EntityDataPoint
                    isActiveTab={activeTab === tabType.entityDataPoint}
                    handleToggleTab={() => handleToggleTab(tabType.entityDataPoint)}
                    onDataPointsUpdated={callbackOnEntityChange}
                    onCreateSuccess={callbackOnEntityChange}
                    data={data}
                />
            )}
        </>
    );
};

KpiView.defaultProps = {
    handleCloseWorkCenterRightDrawer() { },
    callbackOnEntityChange() { },
};

KpiView.propTypes = {
    data: PropTypes.object.isRequired,
    handleCloseWorkCenterRightDrawer: PropTypes.func,
    callbackOnEntityChange: PropTypes.func,
    isActiveTab: PropTypes.bool.isRequired,
    entityDescription: PropTypes.object.isRequired,
    handleToggleTab: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    settings: PropTypes.array.isRequired,
};

export default createRightDrawerEntityDetailsView({
    formName: 'right-drawer-kpi-details-form',
    entityType: 'milestone',
    actions,
    selector,
    permissionsSection: Permissions.constants.entitySections.kpi,
    mapStateToProps(state) {
        const detailsBranch = selector(state);
        const updateError = get(detailsBranch, 'updateError');
        return {
            hasLimitError: hasLimitErrorOfType({ data: updateError }, subscriptionFields.milestones),
            kpiLimit: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.milestones),
        };
    },
})(KpiView);
