import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import u from 'updeep';
import { wait } from '../../shared/utils/common';
import { typeCodes } from '../../shared/utils/entity-type';
import { workCenterRedirect, workCenterNotificationOverride } from './actions';
import {
    requestParams, filterOptionValues, states, overrideQueryKey
} from './constants';

export const workCenterFilterOverrides = {
    default: (_, state) => state,
    owner: (ownerId, state) => u({
        filters: u.constant({
            team: { value: [] },
            plan: { value: [] },
            owner: { value: [ownerId], collaboration: false },
            tag: { value: [] }
        })
    }, state),
    team: (teamId, state) => u.updateIn('filters', u.constant({
        team: { value: [teamId] },
        plan: { value: [] },
        owner: { value: [], collaboration: false },
        tag: { value: [] }
    }), state),
    draft: (userId, state) => u({
        [requestParams.state]: states.draft,
        filters: u.constant({
            team: { value: [] },
            plan: { value: [] },
            owner: { value: [userId], collaboration: false },
            tag: { value: [] }
        })
    }, state),
    reminder: ({ planId, ownerId }, state) => {
        const ownerValue = ownerId ? [ownerId] : [];
        const planValue = planId ? [{
            entity_id: planId,
            entity_type: typeCodes.plan,
            plan_color_index: 0,
            plan_id: planId,
        }] : [];

        return u({
            [requestParams.state]: states.active,
            filters: u.constant({
                team: { value: [] },
                plan: { value: planValue },
                owner: { value: ownerValue, collaboration: state?.owner?.collaboration || false },
                tag: { value: [] }
            })
        }, state);
    }
};

export const workCenterApplyFilterAndRedirect = workCenterRedirect;

export const workCenterQueryKeys = {
    filterType: requestParams.filterType,
    quickFilter: requestParams.quickFilter,
    state: requestParams.state,
};

export const workCenterFilterTypeValues = filterOptionValues;
export const workCenterStates = states;

export const withNotificationOverride = (EnhancedComponent) => {
    const WithNotificationOverride = (props) => {
        const { location } = props;
        const [overrideComplete, setOverrideComplete] = useState(false);
        const dispatch = useDispatch();
        const overrideType = location.query[overrideQueryKey];

        const onOverrideComplete = () => {
            wait(0).then(() => setOverrideComplete(true));
        };

        useEffect(() => {
            if (overrideType) {
                setOverrideComplete(false);
                dispatch(workCenterNotificationOverride({
                    type: overrideType,
                    onComplete: onOverrideComplete,
                }));
            } else {
                setOverrideComplete(true);
            }
        }, [overrideType]);

        return overrideComplete ? <EnhancedComponent {...props} /> : null;
    };

    WithNotificationOverride.propTypes = {
        location: PropTypes.object.isRequired,
    };

    return WithNotificationOverride;
};
