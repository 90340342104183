import { takeEvery, put } from 'redux-saga/effects';
import { logout } from '../../authentication/actions';
import { changePasswordModel, changePasswordSuccessModal } from './change-password-model';

function* handleUpdateSuccess() {
    yield put(changePasswordSuccessModal.actions.toggle());
    yield put(logout());
}

export default function* changePasswordSaga() {
    yield takeEvery(changePasswordModel.actions.update.success, handleUpdateSuccess);
}
