import {
    differenceWith, isEqual, get, find, flow, isUndefined,
} from 'lodash';
import moment from 'moment';
import appConfig from '../../config';
import { attributes as dataPointAttributes } from '../goalmap-milestones-breakdown/constants';
import { typeCodes, collectExistingEntityCodes } from '../../shared/utils/entity-type';
import {
    frequencyToMeasurement, rangeOfMeasure, attributes, frequencies, types, kpiTypes,
    typesLabels,
    thresholdLabels,
    periodTargetAttributes,
    requiredFromValues,
    states
} from './constants';
import { roundDecimal } from '../../shared/utils/math';
import { commasBetweenThousands } from '../../shared/utils/formatters';

export function formatPeriods(periods) {
    return Object.values(periods);
}

export const frequencyToDateFormatters = Object.freeze({
    [frequencies.monthly](date) { return moment(date).format('MMM, YYYY'); },
    [frequencies.quarterly](date) {
        const formattedDate = moment(date).format('Q, YYYY');
        return `Q${formattedDate}`;
    },
    [frequencies.annually](date) { return moment(date).format('YYYY'); },
    [frequencies.weekly](date) {
        const firstDay = moment(date)
            .startOf(frequencyToMeasurement[frequencies.weekly])
            .format('MM/DD');
        const lastDay = moment(date)
            .endOf(frequencyToMeasurement[frequencies.weekly])
            .format('MM/DD');
        const year = moment(date).format('YYYY');

        return `${firstDay} - ${lastDay}, ${year}`;
    },
});

export function getPeriodFromDataPoint(dataPoint) {
    return {
        from: moment(dataPoint.period_start || dataPoint.periodStart).format(appConfig.apiResponseDateFormat),
        to: moment(dataPoint.period_end || dataPoint.periodEnd).format(appConfig.apiResponseDateFormat),
    };
}

export function filterPeriodBeforeCurrent(item, currentPeriod) {
    const formattedFrom = moment(item.from);

    return (
        formattedFrom.isSame(currentPeriod.from)
        || formattedFrom.isBefore(currentPeriod.from)
    );
}

export function getAvailablePeriods(frequency, start_date, end_date) {
    if (!frequency || !start_date || !end_date) {
        return [];
    }

    const measurement = frequencyToMeasurement[frequency];
    const diff = moment(end_date).endOf(measurement)
        .diff(moment(start_date).startOf(measurement), measurement);

    return [...new Array(Math.abs(diff))].map((it, i) => {
        const currentDate = moment(start_date)
            .add(i, measurement);

        const from = currentDate
            .startOf(measurement)
            .format(appConfig.apiResponseDateFormat);

        const to = currentDate
            .endOf(measurement)
            .format(appConfig.apiResponseDateFormat);

        return {
            from, to
        };
    });
}

const getNextStartPeriodFromExist = (existingPeriods, measurement) => (existingPeriods.length > 0
    ? [{
        from: moment(existingPeriods.slice(-1)[0].from).add(1, measurement).format(appConfig.apiResponseDateFormat),
        to: moment(existingPeriods.slice(-1)[0].to).add(1, measurement).format(appConfig.apiResponseDateFormat),
    }]
    : []);

export function getMissingPeriods(dataPoints = [], periods = [], currentPeriod = {}) {
    const existingPeriods = dataPoints.map(getPeriodFromDataPoint);
    const formattedCurrentPeriod = formatPeriods(currentPeriod)[0];
    const missingPeriods = differenceWith(periods, existingPeriods, isEqual);
    return missingPeriods.filter(item => filterPeriodBeforeCurrent(item, formattedCurrentPeriod));
}

export function getStartPeriods(dataPoints = [], periods = [], frequency) {
    const existingPeriods = dataPoints.map(getPeriodFromDataPoint);
    const missingPeriods = differenceWith(periods, existingPeriods, isEqual);
    const measurement = frequencyToMeasurement[frequency];
    return missingPeriods.length > 0 ? missingPeriods : getNextStartPeriodFromExist(existingPeriods, measurement);
}

export function formatByType(value, type, currencySymbol = '$') {
    if (type === types.percentage) {
        return `${value} %`;
    }
    if (type === types.monetary) {
        return `${currencySymbol} ${value}`;
    }
    if (type === types.numerical) {
        return value;
    }
    return value;
}

export function formatByTypeCapitalized(type) {
    return typesLabels[type];
}

export function computeDefaultTresholdValue(range) {
    switch (range) {
        case rangeOfMeasure.desc:
            return [200, 130, 110, 100];
        case rangeOfMeasure.between:
            return [0, 70, 90, 100, 110, 130, 200];
        case rangeOfMeasure.asc:
        default:
            return [0, 70, 90, 100];
    }
}

export function formatThresholds(params) {
    const thresholds = get(params, attributes.thresholds);
    const range = get(params, attributes.range_of_measure);
    const value = get(params, attributes.health_tolerance_value);


    if (!thresholds) {
        return {};
    }

    let threshold;
    let bound;

    switch (range) {
        case rangeOfMeasure.asc:
        case rangeOfMeasure.between:
        default:
            [, bound, threshold] = thresholds;
            break;

        case rangeOfMeasure.desc:
            [, bound, threshold] = thresholds;
            break;
    }

    return {
        [attributes.health_tolerance_threshold]: threshold,
        [attributes.health_tolerance_bound]: bound,
        [attributes.health_tolerance_value]: value,
    };
}

function calculatedThresholdsForZero(thresholds) {
    return thresholds.map(th => -(100 - th));
}

export function parseThresholdsByType(type, thresholds, targetValue) {
    if (targetValue === 0) {
        return calculatedThresholdsForZero(thresholds);
    }

    switch (type) {
        case types.numeric:
        case types.monetary:
            if (targetValue < 0) {
                return thresholds.map((item) => {
                    const startingValue = targetValue * 2;

                    return startingValue + -(startingValue - targetValue) * (item / 100);
                });
            }

            return thresholds.map(item => (targetValue / 100) * item);
        case types.percentage:
        default:
            return thresholds;
    }
}

export function parseThresholds(params) {
    const range = get(params, attributes.range_of_measure);
    const bound = get(params, attributes.health_tolerance_bound);
    const threshold = get(params, attributes.health_tolerance_threshold);
    const value = get(params, attributes.health_tolerance_value);

    switch (range) {
        case rangeOfMeasure.between:
            return [0, bound, threshold, value, 200 - threshold, 200 - bound, 200];
        case rangeOfMeasure.desc:
            return [200, bound, threshold, value];
        case rangeOfMeasure.asc:
        default:
            return [0, bound, threshold, value];
    }
}

function getDataPointValueAttributeByKpiType(type) {
    switch (type) {
        case kpiTypes.linear.type:
            return dataPointAttributes.overall_progress;
        case kpiTypes.static.type:
        case kpiTypes.stepped.type:
        default:
            return dataPointAttributes.value;
    }
}

export function getPeriodTargetValueAttributeByKpi(type) {
    switch (type) {
        case kpiTypes.linear.type:
            return periodTargetAttributes.overall_target_value;
        case kpiTypes.static.type:
        case kpiTypes.stepped.type:
        default:
            return periodTargetAttributes.target_value;
    }
}

export function getLatestDataPoint(data) {
    const dataPoints = get(data, attributes.data_points);
    return dataPoints[dataPoints.length - 1];
}

export function getCurrentValue(data) {
    const range = get(data, attributes.range_of_measure);
    const kpiType = get(data, attributes.kpi_type);

    const lastDataPoint = getLatestDataPoint(data);
    const thresholds = parseThresholds(data);
    const valueAttribute = getDataPointValueAttributeByKpiType(kpiType);

    switch (range) {
        case rangeOfMeasure.desc:
            return get(lastDataPoint, valueAttribute, thresholds[thresholds.length - 1]);
        case rangeOfMeasure.asc:
        case rangeOfMeasure.between:
        default:
            return get(lastDataPoint, valueAttribute, thresholds[0]);
    }
}

export function formatValue(value) {
    return moment(value)
        .format(appConfig.apiResponseDateFormat);
}

export const formatNumber = flow(
    roundDecimal,
    commasBetweenThousands
);

export function getThresholdLabels(kpiType) {
    switch (kpiType) {
        case kpiTypes.linear.type:
            return [thresholdLabels.healthTolerance, thresholdLabels.overallTargetProgress];
        case kpiTypes.static.type:
            return [thresholdLabels.healthTolerance, thresholdLabels.targetValue];
        case kpiTypes.stepped.type:
        default:
            return [thresholdLabels.healthTolerance, thresholdLabels.periodTarget];
    }
}

export function validateForm(values) {
    const errors = {};
    const kpiType = get(values, attributes.kpi_type);
    const isLinear = kpiType === kpiTypes.linear.type;

    if (isLinear) {
        const startValue = get(values, attributes.start_value);
        const targetValue = get(values, attributes.target_value);

        if (startValue === targetValue) {
            errors[attributes.start_value] = ['Starting Value and Overall Target Value fields can not be equal'];
        }
    }

    return errors;
}

export function getOperationsOptions(operationsData) {
    return operationsData.map(it => ({ id: it.id, title: it.title }));
}

export function getOperationDataById(id, operationsData) {
    return operationsData.find(it => it.id === id);
}

export function prepareDataSources(prevData, curData) {
    const initialDataSources = get(prevData, attributes.advanced_calculation_sources);
    const currentDataSources = get(curData, attributes.advanced_calculation_sources);

    const nextDataSources = currentDataSources.map((source) => {
        const exists = !!find(initialDataSources, { id: source.id });

        return {
            ...source,
            id: exists ? source.id : undefined,
        };
    });

    return nextDataSources;
}

export function isGlobal(entity) {
    return get(entity, attributes.parent_entity_type) === typeCodes.plan;
}

export function getLocationIcon(entity) {
    return isGlobal(entity) ? 'globe' : 'layer-group';
}

export function showTypeFilter(typeCounters = {}) {
    const existingEntityCodes = collectExistingEntityCodes(typeCounters);
    let result = false;

    if (
        (existingEntityCodes.includes(typeCodes.action)
        || existingEntityCodes.includes(typeCodes.tactic)
        || existingEntityCodes.includes(typeCodes.kpi)
        )
    ) {
        result = true;
    }

    return result;
}

export function getTargetDate(data) {
    return get(data, attributes.target_date);
}

export function getClosedDate(data) {
    const state = get(data, attributes.state);
    const dataPoints = get(data, attributes.data_points);
    const requiredFrom = get(data, attributes.required_from);

    if (state === states.inactive && dataPoints.length) {
        const latestDataPoint = getLatestDataPoint(data);

        switch (requiredFrom) {
            case requiredFromValues.first:
                return get(latestDataPoint, dataPointAttributes.period_start);
            case requiredFromValues.last:
            default:
                return get(latestDataPoint, dataPointAttributes.period_end);
        }
    }

    return null;
}

export function isUpdateNeeded(data) {
    return get(data, attributes.is_need_updated);
}

export function parseCurrentValue(value) {
    if (!value) {
        return 0;
    }
    const result = roundDecimal(value);
    /* eslint-disable */
    return isNaN(result) ? 0 : commasBetweenThousands(result);
    /* eslint-enable */
}

export const getTargetValueForSteppedKpi = (item) => {
    const kpiPeriodTargets = item?.kpi_period_targets || [];
    const periodTargetLastDataPoint = item?.data_points.slice(-1)[0]?.period_target;
    if (isUndefined(periodTargetLastDataPoint)) {
        return roundDecimal(kpiPeriodTargets[0]?.overall_target_value);
    }
    return roundDecimal(periodTargetLastDataPoint);
};

export const deleteEmptyAdvCalcDataSource = advCalcDataSourceList =>
    (advCalcDataSourceList?.length > 0
        ? advCalcDataSourceList.filter(source => source?.related_entity_id)
        : advCalcDataSourceList);

export const isAdvKpiHasAllActiveSources = (advCalcDataSourceList = []) =>
    advCalcDataSourceList.every(source => source.state === 1);

export const getCantCreateDataPointMessage = (kpiTypeLimit, isKpiActive) => {
    if (!kpiTypeLimit) return 'This action is not available for your current subscription.';
    if (!isKpiActive) return 'This action is not available for inactive kpi.';
    return 'This action is not available for your role';
};

export const getAdvKpiLimit = (isAdvancedKpi, advKpiIsAllowed) => (isAdvancedKpi && advKpiIsAllowed) || isAdvancedKpi === false;
