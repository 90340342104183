import { get } from 'lodash';
import { createSelector } from 'reselect';
import { details as milestoneModel, parentMilestones as parentMilestonesModel } from './entity';
import { getMissingPeriods, getAvailablePeriods, getStartPeriods } from './utils';
import { attributes, locations } from './constants';
import { typeCodes } from '../../shared/utils/entity-type';
import { getTitlesByGoalId } from '../goalmap-list/reducer';

export function hasLimitErrorOfType(error, type) {
    return get(error, 'data.details.key') === type;
}

export function isGlobalKpi(entity) {
    return get(entity, attributes.parent_entity_type) === typeCodes.plan;
}

const getData = createSelector(
    milestoneModel.selector,
    state => state.data,
);

export const isSourceForMilestonesSelector = createSelector(
    parentMilestonesModel.selector, getData,
    (parentKpiData, kpiDetails) =>
        !!parentKpiData.items.length && kpiDetails?.[attributes.advanced_calculation_enabled] === false
);

const getPeriods = createSelector(
    getData,
    data => getAvailablePeriods(
        data.frequency,
        data.started_at,
        data.target_at
    ),
);

const getFrequency = createSelector(
    getData,
    data => data.frequency,
);

const getDataPoints = createSelector(
    getData,
    data => data.data_points,
);

const getCurrentPeriod = createSelector(
    getData,
    data => data.frequency_current_period,
);

export const parentMilestonesSelector = createSelector(
    parentMilestonesModel.selector,
    state => state.items
);

export const missingPeriodsSelector = createSelector(
    getDataPoints,
    getPeriods,
    getCurrentPeriod,
    getMissingPeriods,
);

export const startPeriodsSelector = createSelector(
    getDataPoints,
    getPeriods,
    getFrequency,
    getStartPeriods,
);

export const getLocationOptions = createSelector(
    getTitlesByGoalId,
    titles => ([
        {
            icon: 'layer-group',
            value: locations.plan,
            tooltip: `Plan ${titles.milestone}`
        },
        {
            icon: 'globe',
            value: locations.global,
            tooltip: `Global ${titles.milestone}`
        },
    ])
);
