import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, Clickable } from '../';
import './styles.css';

export default class ExpandablePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    handleToggleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const {
            title, className, titleClassName, children
        } = this.props;
        const { expanded } = this.state;

        return (
            <div className={cx('expandable-panel', className)}>
                <div className={cx('expandable-panel-header-wrapper', titleClassName)}>
                    <div className="expandable-panel-header-title">{title}</div>
                    <Clickable action="" onClick={this.handleToggleExpand}>
                        <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
                    </Clickable>
                </div>
                {expanded && (
                    <div className="expandable-panel-container">{children}</div>
                )}
            </div>
        );
    }
}

ExpandablePanel.defaultProps = {
    title: undefined,
    className: undefined,
    titleClassName: undefined
};

ExpandablePanel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
};
