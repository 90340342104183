import React from 'react';
import PropTypes from 'prop-types';
import { ServicePage } from '../../shared/components';

export default function ServicePageConnectionError({ children }) {
    return (
        <ServicePage
            icon="wifi-slash"
            title="No internet connection"
        >
            {children}
        </ServicePage>
    );
}

ServicePageConnectionError.propTypes = {
    children: PropTypes.node.isRequired,
};
