
import { createReducer } from 'redux-act';
import { workCenterViewTypes } from './constants';
import { workCenterViewTypeChange } from './actions';

export const name = 'work-center-view-type';

const initialState = {
    viewType: workCenterViewTypes.grid,
};

export default createReducer({
    [workCenterViewTypeChange]: (state, payload) => ({ ...state, viewType: payload }),
}, initialState);

export const selectWorkCenterViewTypePersistState = state => state[name];

export const getWorkCenterViewType = state => state[name].viewType;
