export const outerSection = Object.freeze({
    innerRadius: '95%',
    outerRadius: '100%',
});

export const innerSection = Object.freeze({
    innerRadius: '60%',
    outerRadius: '90%',
});

export const defaultColor = 'transparent';
export const defaultHeight = 309;
export const defaultPivotRadius = 5;
export const defaultCurrencySymbol = '$';
