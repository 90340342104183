import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ServicePage404 } from '../../../modules/service-pages';
import { Icon, Text, } from '..';
import ContactSalesMailtoProvider from '../../../modules/subscription/contact-sales-mailto-provider';
import styles from './styles.module.css';

const IntegrationsError = ({
    error, description, className, classNameIcon,
}) => {
    if (error?.code === 404) {
        return <ServicePage404 />;
    }
    return (
        <div className={cx({ [className]: !!className })}>
            <ContactSalesMailtoProvider>
                {({ mailto }) => (
                    <>
                        <Icon
                            className={cx(styles.integrationLockIcon, { [classNameIcon]: !!classNameIcon, })}
                            name="lock-alt"
                            type={Icon.types.regular}
                        />
                        <Text>
                            {description}&nbsp;
                            <Text
                                component="a"
                                href={mailto}
                                underline
                            >
                                Contact Support
                            </Text> to learn more.
                        </Text>
                    </>
                )}
            </ContactSalesMailtoProvider>
        </div>
    );
};

IntegrationsError.defaultProps = {
    description: 'The following integrations are not available for your current subscription.',
    className: undefined,
    classNameIcon: undefined,
    error: undefined,
};

IntegrationsError.propTypes = {
    error: PropTypes.object,
    description: PropTypes.string,
    className: PropTypes.string,
    classNameIcon: PropTypes.string,
};

export default IntegrationsError;
