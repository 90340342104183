/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, Field } from 'redux-form';
import ReactSelect from 'react-select';
import createFormModal from '../../../../../shared/modules/create-view/component';
import { relatedLinkConstants, } from '../../../../secondary-entities/related-link';
import { RelatedLinkSelect } from '../../../../../shared/modules';
import { Text, Icon, } from '../../../../../shared/components';
import { getIconByTypeCode } from '../../../../../shared/utils/entity';
import { csfRelatedLinkCreateModel } from '../csf-related-links-models';

import styles from './csf-related-link-create.module.css';

const formName = 'driver-related-link-create-form';
const formSelector = formValueSelector(formName);

const { types, attributeNames: attrNames, entityTypesPlural } = relatedLinkConstants;

const ValueRenderer = ({ option }) => {
    return (
        <>
            <Icon
                name={getIconByTypeCode(option?.type_code)}
                className={styles.linkIconEntityType}
                fixedWidth
            />
            <Text className={styles.linkText}>{option?.title}</Text>
        </>
    );
};

ValueRenderer.propTypes = {
    option: PropTypes.object.isRequired,
};

const valueRenderer = option => <ValueRenderer option={option} />;

export const CsfRelatedLinkForm = ({
    currentValues, exclude, placeholder,
}) => {
    return (
        <div className={styles.relatedLinkForm}>
            <label className={styles.linkLabel}>Select Connection(s)</label>
            <Field
                name="internal_link"
                component={RelatedLinkSelect}
                required
                props={{
                    className: styles.linkSelect,
                    params: {
                        id: currentValues.primary_link_id,
                        primary_link_type: currentValues.primary_link_type,
                        exclude,
                    },
                    entity: 'object',
                    placeholder,
                    component: ReactSelect.Async,
                    multi: true,
                    valueRenderer,
                }}
            />
        </div>
    );
};

CsfRelatedLinkForm.propTypes = {
    currentValues: PropTypes.shape({
        primary_link_id: PropTypes.number,
        primary_link_type: PropTypes.string,
        internal_link: PropTypes.array,
    }).isRequired,
    exclude: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
};


export default createFormModal({
    actions: csfRelatedLinkCreateModel.actions,
    selector: csfRelatedLinkCreateModel.selector,
    formName,
    enableReinitialize: true,
    modalFormContainerClassName: styles.createRelatedLinkModalFormContainer,
    errorObjectGetter: error => error?.response?.data?.message,
    parseFormSubmitError: message => (Array.isArray(message) ? { 'internal_link': [...message] } : { 'internal_link': [message] }),
    mapStateToProps(state, ownProps) {
        return {
            title: 'Add Connection',
            internalLinkTitle: 'Select a Connection',
            draftAvailable: false,
            currentValues: {
                [attrNames.primaryLinkType]: formSelector(state, attrNames.primaryLinkType) || ownProps.parentType,
                [attrNames.primaryLinkId]: formSelector(state, attrNames.primaryLinkId) || ownProps.parentId,
                [attrNames.internalLink]: formSelector(state, attrNames.internalLink) || [],
            },
            initialValues: {
                [attrNames.type]: types.internal,
                [attrNames.primaryLinkId]: ownProps.parentId,
                [attrNames.primaryLinkType]: ownProps.parentType,
                [attrNames.internalLink]: [],
            },
            allowedTypes: {
                [types.internal]: true,
                [types.external]: false,
            },
            creationDisabled: ownProps.disabled,
            placeholder: 'Select a Connection',
            exclude: entityTypesPlural.driver,
        };
    }
})(CsfRelatedLinkForm);
