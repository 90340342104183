const ruleSlugs = Object.freeze({
    nonEmpty: 'non-empty',
    stringLength: 'string-len',
    alphaNumeric: 'alpha_numeric',
    alphaNumericLowerCase: 'alpha_numeric_lowercase',
    alphaNumericUpperCase: 'alpha_numeric_uppercase',
    alphaNumericNumbers: 'alpha_numeric_numbers',
    specialCharacters: 'special-characters',
});

const tests = Object.freeze({
    length: value => value.length,
    minLength: (value, minLength) => value.length >= minLength,
    lowerCase: value => !!value.match(/[a-z]/),
    upperCase: value => !!value.match(/[A-Z]/),
    numbers: value => !!value.match(/[0-9]/),
    specialChars: value => !!value.match(/[!@#$%^&*]/),
});

export const validationTests = Object.freeze({
    [ruleSlugs.nonEmpty]: tests.length,
    [ruleSlugs.stringLength]: (value, rule) => tests.minLength(value, rule.value),
    [ruleSlugs.alphaNumeric]: value => (
        tests.lowerCase(value)
        && tests.upperCase(value)
        && tests.numbers(value)
    ),
    [ruleSlugs.alphaNumericLowerCase]: tests.lowerCase,
    [ruleSlugs.alphaNumericUpperCase]: tests.upperCase,
    [ruleSlugs.alphaNumericNumbers]: tests.numbers,
    [ruleSlugs.specialCharacters]: tests.specialChars,
});

export function getRuleByName(rules = [], name) {
    return rules.find(rule => rule.name === name);
}

export const commonGroup = [
    ruleSlugs.nonEmpty,
    ruleSlugs.stringLength,
];

export const specialGroup = [
    ruleSlugs.alphaNumericLowerCase,
    ruleSlugs.alphaNumericUpperCase,
    ruleSlugs.alphaNumericNumbers,
    ruleSlugs.specialCharacters,
];

export const specialGroupLabels = Object.freeze({
    [ruleSlugs.alphaNumericLowerCase]: 'Lower case letters (a-z)',
    [ruleSlugs.alphaNumericUpperCase]: 'Upper case letters (A-Z)',
    [ruleSlugs.alphaNumericNumbers]: 'Numbers (i.e. 0-9)',
    [ruleSlugs.specialCharacters]: 'Special characters (e.g. !@#$%^&*)',
});

const specialGroupMinTestsPass = 3;
export const specialGroupParent = ruleSlugs.alphaNumeric;
export function getSpecialGroupMessage(rules) {
    const hasSpecialCharactersRule = !!getRuleByName(rules, ruleSlugs.specialCharacters);
    if (hasSpecialCharactersRule) {
        return `
            Contain at least ${specialGroupMinTestsPass} of the following ${specialGroup.length} types of characters:
        `;
    }

    return 'Should contain:';
}

export function isSpecialRuleExists(rules, name) {
    if (name === ruleSlugs.specialCharacters) {
        return !!getRuleByName(rules, ruleSlugs.specialCharacters);
    }

    return !!getRuleByName(rules, ruleSlugs.alphaNumeric);
}

export function validateSpecialGroupParent(rules, value) {
    const testsToCheck = specialGroup.reduce((accum, item) => {
        if (isSpecialRuleExists(rules, item)) {
            accum.push(validationTests[item]);
        }

        return accum;
    }, []);

    const passedTests = testsToCheck.reduce((accum, item) => {
        if (item(value)) {
            accum.push(true);
        }

        return accum;
    }, []);

    return passedTests.length >= specialGroupMinTestsPass;
}
