import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from './component';

export default class PromptProvider extends Component {
    constructor(args) {
        super(args);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = {
            visible: args.visible,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { visible } = this.props;
        if (nextProps.visible && nextProps.visible !== visible) {
            this.setState({ visible: nextProps.visible });
        }
    }

    show() {
        this.setState({
            visible: true,
        });
        this.props.show();
    }

    hide() {
        this.setState({
            visible: false,
        });
        this.props.hide();
    }

    render() {
        const { children, renderContent, ...rest } = this.props;

        return (
            <span>
                {this.props.children({
                    show: this.show,
                    hide: this.hide,
                })}

                <Modal
                    {...rest}
                    visible={this.state.visible}
                    hide={this.hide}
                >
                    {renderContent({ hide: this.hide })}
                </Modal>
            </span>
        );
    }
}

PromptProvider.defaultProps = {
    show: () => {},
    hide: () => {},
    visible: false
};

PromptProvider.propTypes = {
    children: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    renderContent: PropTypes.func.isRequired,
    hide: PropTypes.func,
    show: PropTypes.func,
};
