import Portal from 'react-portal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import classnames from 'classnames';
import { get as getCustomProperty } from '../../utils/css-custom-properties';
import { BodyScrollLock } from '../body-scroll-lock';
import { wait } from '../../utils/common';

import './styles.css';

class Modal extends Component {
    constructor(args) {
        super(args);

        this.state = {
            mounted: this.props.visible,
            visibleClassAdded: false
        };

        this.scrollable = React.createRef();
        this.contentBlock = React.createRef();
    }

    componentDidMount() {
        const { closeOnESC } = this.props;

        if (closeOnESC) {
            document.addEventListener('keyup', this.handleEscPress);
        }
    }

    componentDidUpdate(prevProps) {
        const { visible } = this.props;

        if (!prevProps.visible && visible) {
            this.show();
        }

        if (prevProps.visible && !this.props.visible) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                visibleClassAdded: false,
            });
        }
    }

    componentWillUnmount() {
        if (this.props.closeOnESC) {
            document.removeEventListener('keyup', this.handleEscPress);
        }
    }

    show = () => {
        this.setState({
            mounted: true
        });
    }

    hide = () => {
        const { onHide, onHideEnd } = this.props;

        this.setState({
            mounted: false
        });

        onHide();

        setTimeout(() => {
            onHideEnd();
        }, parseFloat(getCustomProperty('--transition-duration')) * 1000);
    }

    handlePortalOpen = () => {
        wait(15).then(() => this.setState({
            visibleClassAdded: true,
        }));
    }

    getScrollable = () => {
        return this.scrollable;
    }

    getContent = () => {
        return this.contentBlock;
    }

    onTransitionEnd = (event) => {
        const { className } = event.target;
        const { visible, onShow } = this.props;

        if (className && className.includes('modal-content')) {
            return undefined;
        }

        if (!visible) {
            return this.hide();
        }

        return onShow();
    }

    handleOverlayClick = (event) => {
        if (this.overlay && event.target.className === this.overlay.className) {
            this.props.hide();
        }
    }

    handleEscPress = (event) => {
        if (this.state.mounted) {
            if (event.key === 'Escape') {
                this.props.hide();
            }
        }
    }

    render() {
        const {
            children,
            hide,
            visible,
            onShow,
            onHide,
            closeOnESC,
            className,
            modalContentClassName,
            modalContentOuterClassName,
            alignCentered,
            ...rest
        } = this.props;
        const { mounted, visibleClassAdded } = this.state;

        return (
            <ReactReduxContext.Consumer>
                {context => (
                    <Portal
                        isOpened={mounted}
                        onOpen={this.handlePortalOpen}
                    >
                        <Provider store={context.store}>
                            <BodyScrollLock>
                                <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                                    className={classnames('modal', {
                                        'modal-visible': visibleClassAdded,
                                        'align-centered': alignCentered,
                                        [className]: className,
                                    })}
                                    onTransitionEnd={this.onTransitionEnd}
                                    onMouseDown={this.handleOverlayClick}
                                    ref={(el) => { this.overlay = el; }}
                                >
                                    <div className="modal-overlay" />
                                    <div
                                        className={classnames('modal-content-outer', {
                                            [modalContentOuterClassName]: !!modalContentOuterClassName,
                                        })}
                                        ref={(el) => { this.scrollable = el; }}
                                    >
                                        <div
                                            ref={(el) => { this.contentBlock = el; }}
                                            className={classnames('modal-content', {
                                                [modalContentClassName]: !!modalContentClassName,
                                            })}
                                            {...rest}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                </div>
                            </BodyScrollLock>
                        </Provider>
                    </Portal>
                )}
            </ReactReduxContext.Consumer>
        );
    }
}

Modal.defaultProps = {
    onShow() { },
    onHide() { },
    onHideEnd() { },
    closeOnESC: true,
    className: undefined,
    modalContentClassName: undefined,
    modalContentOuterClassName: undefined,
    hide() { },
    visible: false,
    alignCentered: false,
    children: undefined,
};

Modal.propTypes = {
    children: PropTypes.node,
    visible: PropTypes.bool,
    alignCentered: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    onHideEnd: PropTypes.func,
    hide: PropTypes.func,
    closeOnESC: PropTypes.bool,
    className: PropTypes.string,
    modalContentClassName: PropTypes.string,
    modalContentOuterClassName: PropTypes.string,
};

export default Modal;
