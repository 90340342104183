import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, } from 'redux-form';
import { useApiGet } from '../../../../shared/hooks/useApiGet';
import { Row, Col, Text } from '../../../../shared/components';
import { Select } from '../../../../shared/modules';
import { attributes, operationsData, } from '../../constants';
import { getOperationsOptions, } from '../../utils';
import { collectRelatedValues, } from './utils';
import KpiDataSourcesList from './lists/kpi-data-sources-list';

import styles from './data-sources.module.css';

const shouldUpdateKpiValues = advCalcDataSources =>
    advCalcDataSources.length > 0 && advCalcDataSources.some(source => source?.related_entity_id);

const prepareDataSourcesForUtil = (advCalcDataSources, kpiList) => {
    return advCalcDataSources.map((source) => {
        const kpiItem = kpiList.find(kpi => kpi.id === source.related_entity_id);
        return { ...source, ...(kpiItem && { related_entity: kpiItem }) };
    });
};

const DataSources = ({
    title, disabled, currentValues, change,
}) => {
    const { id, kpi_type } = currentValues;
    const result = useApiGet(`/milestones/${id}/datasources`, { kpi_type }, [id, kpi_type]);
    const advCalcDataSources = currentValues[attributes.advanced_calculation_sources];

    useEffect(() => {
        if (shouldUpdateKpiValues(advCalcDataSources) && result.data) {
            const valuesObjForUpdate = collectRelatedValues({
                ...currentValues,
                [attributes.advanced_calculation_sources]: prepareDataSourcesForUtil(advCalcDataSources, result.data)
            });
            Object.entries(valuesObjForUpdate).map(([key, value]) => change(key, value));
        }
    }, [currentValues[attributes.advanced_calculation_sources], result.data]);

    return (
        <div className={styles.dataSources}>
            {title && (
                <h1 className={styles.dataSourcesTitle}>{title}</h1>
            )}
            <Row>
                <Col base={6}>
                    <Field
                        component={Select}
                        name={attributes.advanced_calculation_type}
                        label={(<>Operation <Text styleType="danger">*</Text></>)}
                        options={getOperationsOptions(operationsData)}
                        clearable={false}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <div className={styles.dataSourcesListWrapper}>
                <FieldArray
                    component={KpiDataSourcesList}
                    name={attributes.advanced_calculation_sources}
                    disabled={disabled}
                    kpiList={result.data}
                    kpiListFetching={result.fetching}
                    currentValueAdvCalcSources={currentValues[attributes.advanced_calculation_sources]}
                    advCalcType={currentValues[attributes.advanced_calculation_type]}
                />
            </div>
        </div>
    );
};

DataSources.defaultProps = {
    disabled: false,
};

DataSources.propTypes = {
    disabled: PropTypes.bool,
    currentValues: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
};

export default DataSources;
