import { notificationsHeaderCollection } from './entity';
import config from '../../../config';
import { selectUserId } from '../../user/selectors';

export function getUnreadCount(state) {
    return notificationsHeaderCollection.selectors.getRaw(state)?.total_unread;
}

export function getDefaultParams(state) {
    return {
        user_id: selectUserId(state),
        order_by: 'is_read,asc|created_at,desc',
        limit: config.notifications.headerListMaxLength,
    };
}
