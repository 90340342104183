import React from 'react';

export const StickyContext = React.createContext({
    constraintTop: undefined
});

export function withStickyContext(contextConfig) {
    return (EnhancedComponent) => {
        return function ComponentWithStickyContext(props) {
            return (
                <StickyContext.Provider value={contextConfig}>
                    <EnhancedComponent {...props} />
                </StickyContext.Provider>
            );
        };
    };
}
