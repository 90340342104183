import * as Sentry from '@sentry/react';
import config from './config';

function initSentry() {
    if (config.sentryEnabled()) {
        Sentry.init({
            dsn: config.sentryDsn(),
            release: window.APP_VERSION,
            ignoreErrors: config.sentryIgnoreErrors
        });
    }
}

export default initSentry;
