import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, reset } from 'redux-form';
import get from 'lodash/get';
import {
    Form,
    Input,
    Button,
    Modal,
} from '../../../shared/components';
import {
    ModalFormContainer,
    ModalFormHeader,
    ModalFormBody,
    ModalFormFooter,
} from '../../../shared/components/modal-form';
import './styles.css';

const formName = 'profile-change-email';

const getErrorsMsgFromRequst = error => get(error, 'errors.email', []);

const getObjErrorsForReduxForm = error => ({ email: error });

const ChangeEmailFields = ({
    onSubmit, handleSubmit, handleHide, submitting
}) => {
    return (
        <Form
            className="change-email-form"
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            errorObjectGetter={getErrorsMsgFromRequst}
            parseError={getObjErrorsForReduxForm}
        >
            <ModalFormContainer className="email-change-request-modal-form-container">
                <ModalFormHeader
                    hide={handleHide}
                >
                    Change Email
                </ModalFormHeader>
                <ModalFormBody>
                    <Field
                        label="New Email *"
                        component={Input}
                        type="email"
                        name="email"
                    />
                    <span>
                        Further instructions will be sent to the new email address
                    </span>
                </ModalFormBody>
                <ModalFormFooter>
                    <Button
                        type="button"
                        onClick={handleHide}
                    >
                        Cancel
                    </Button>
                    <Button
                        styleType="success"
                        type="submit"
                        disabled={false}
                        loading={submitting}
                    >
                        Submit
                    </Button>
                </ModalFormFooter>
            </ModalFormContainer>
        </Form>
    );
};

ChangeEmailFields.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleHide: PropTypes.func.isRequired,
};

const reduxFormConfig = {
    form: formName,
};

const ChangeEmailForm = reduxForm(reduxFormConfig)(ChangeEmailFields);

class ChangeEmailModal extends Component {
    handleHide = () => {
        const { hide, formReset } = this.props;

        formReset(formName);
        hide();
    }

    render() {
        const {
            visible, onSubmit,
        } = this.props;

        return (
            <Modal
                className="email-change-request-modal"
                modalContentClassName="email-change-request-modal-content"
                visible={visible}
                hide={this.handleHide}
            >
                <ChangeEmailForm onSubmit={onSubmit} handleHide={this.handleHide} />
            </Modal>
        );
    }
}

ChangeEmailModal.defaultProps = {
    errorChangeEmail: null,
};

ChangeEmailModal.propTypes = {
    hide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formReset: PropTypes.func.isRequired,
    errorChangeEmail: PropTypes.shape({
        errors: PropTypes.shape({
            email: PropTypes.array,
        }),
        message: PropTypes.string,
        status: PropTypes.number,
    }),
};

const mapDispatchToProps = {
    formReset: reset,
};

export default connect(null, mapDispatchToProps)(ChangeEmailModal);
