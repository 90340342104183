/** @namespace Utils.Math */
import _ from 'lodash';

/**
 * Get value inside diapason or bound value
 * @function fromRange
 * @memberof Utils.Math
 * @param {number} value Specified value
 * @param {number} startDiapasonVal Lower bound value
 * @param {number} endDiapasonVal Highest bound value
 * @return {number} Value inside diapason or bound value
 */
export function fromRange( // eslint-disable-line import/prefer-default-export
    value, startDiapasonVal, endDiapasonVal
) {
    return Math.min(endDiapasonVal, Math.max(value, startDiapasonVal));
}

/**
 * Round value with specified decimals
 * @function roundDecimal
 * @memberof Utils.Math
 * @param {number} value Specified value
 * @param {number} decimals Lower bound value
 * @return {number} Rounded value
 */
export function roundDecimal(value, decimals = 2) {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

/**
 * Check if value is number
 * @function isNumber
 * @memberof Utils.Math
 * @param {number} value Specified value
 * @param {number} value True if value is number
 */
export function isNumber(value) {
    return _.isNumber(value) && !_.isNaN(value);
}

/**
 * Get first number from array
 * @function getNumber
 * @memberof Utils.Math
 * @param {Array<any>} args Array of any values
 * @return {number} Returns first number from value
 */
export function getNumber(...args) {
    return args.find(arg => isNumber(arg));
}

/**
 * Get value if it's number, in else returns null
 * @function processNumber
 * @memberof Utils.Math
 * @param {any} value Array of any values
 * @return {number|null} Returns value if it's number, in else returns null
 */
export function processNumber(value) {
    return isNumber(value) ? value : null;
}
