import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../modules/user/selectors';
import {
    Row, Col, TableView, Button, Icon, PromptProvider
} from '../../components';
import TeamSectionList from './team-section-list';
import TeamSectionSearch from './team-section-search';
import { TeamSectionCreateModal, TeamSectionUpdateModal } from './team-section-modal';
import styles from './team-section.module.css';

const TeamSection = ({
    title, titleClassName, useRestrictions, searchValue, setSearchValue, sortValue, setSortValue, onTeamRemove,
    TeamCollectionRender, params,
    updateModalVisible, onUpdateModalShow, onUpdateModalHide,
    createModalVisible, onCreateModalShow, onCreateModalHide,
    onTeamUpdate, onTeamCreate, updateFormInitialValues, detailsError, detailsFetching,
    createFormInitialValues
}) => {
    const userId = useSelector(selectUserId);
    return (
        <>
            <Row className={styles.teamSectionHeader}>
                <Col base={3}>
                    <h1 className={classnames(styles.title, titleClassName)}>
                        <Button
                            type="button"
                            styleType="accent"
                            round
                            withShadow
                            size={Button.sizes.small}
                            onClick={onCreateModalShow}
                        >
                            <Icon name="plus" />
                        </Button>
                        &nbsp;
                        {title}
                    </h1>
                </Col>
                <Col base={4} smOffset={5}>
                    <TeamSectionSearch
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <PromptProvider onAccept={onTeamRemove} description="You are about to delete this team.">
                        {prompt => (
                            <TeamCollectionRender
                                params={params}
                                RendererComponent={TableView.ListRenderer}
                                customRenderFunc={TeamSectionList}
                                customRenderProps={{
                                    onTeamRemove: prompt.show,
                                    sortValue,
                                    setSortValue,
                                    onUpdateModalShow,
                                    userId,
                                    useRestrictions,
                                }}
                            />
                        )}
                    </PromptProvider>
                </Col>
            </Row>

            <TeamSectionUpdateModal
                title="Edit Team"
                submitLabel="Save"
                initialValues={updateFormInitialValues}
                isFetching={detailsFetching}
                visible={updateModalVisible}
                onHide={onUpdateModalHide}
                onSubmit={onTeamUpdate}
                useRestrictions={useRestrictions}
                fetchError={detailsError}
            />

            <TeamSectionCreateModal
                title="Add Team"
                submitLabel="Create Team"
                initialValues={createFormInitialValues}
                visible={createModalVisible}
                onHide={onCreateModalHide}
                onSubmit={onTeamCreate}
                useRestrictions={false}
            />
        </>
    );
};

TeamSection.defaultProps = {
    detailsError: null,
};

TeamSection.propTypes = {
    title: PropTypes.string.isRequired,
    titleClassName: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    onTeamRemove: PropTypes.func.isRequired,
    sortValue: PropTypes.string.isRequired,
    setSortValue: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    TeamCollectionRender: PropTypes.func.isRequired,
    updateModalVisible: PropTypes.bool.isRequired,
    onUpdateModalShow: PropTypes.func.isRequired,
    onUpdateModalHide: PropTypes.func.isRequired,
    createModalVisible: PropTypes.bool.isRequired,
    onCreateModalShow: PropTypes.func.isRequired,
    onCreateModalHide: PropTypes.func.isRequired,
    onTeamUpdate: PropTypes.func.isRequired,
    onTeamCreate: PropTypes.func.isRequired,
    updateFormInitialValues: PropTypes.object.isRequired,
    detailsFetching: PropTypes.bool.isRequired,
    useRestrictions: PropTypes.bool.isRequired,
    createFormInitialValues: PropTypes.object.isRequired,
    detailsError: PropTypes.object,
};

export default TeamSection;
