import { createSelector } from 'reselect';

const selectors = {
    getId(_, id) {
        return id;
    },
    getItems(state) {
        return state.items;
    },
    getItemById(items, id) {
        return items.find(item => item.id === id);
    },
    getParams(state) {
        return state.params;
    },
    getFetchingState(state) {
        return state.asyncState.fetch;
    },
    getFetchingLazyState(state) {
        return state.asyncState.fetchLazy;
    },
    getRaw(state) {
        return state.raw;
    },
    getError(state) {
        return state.error;
    },
    getPager(state) {
        return state.pager;
    },
    getUpdatingItemId(state) {
        return state.updatingItemId;
    }
};

export default function createSelectors(branchName) {
    function getBranch(state) {
        return state[branchName] || {};
    }

    const getItems = createSelector(
        getBranch,
        selectors.getItems,
    );

    const getItemById = createSelector(
        [getItems, selectors.getId],
        selectors.getItemById
    );

    const getParams = createSelector(
        getBranch,
        selectors.getParams,
    );

    const getFetchingState = createSelector(
        getBranch,
        selectors.getFetchingState,
    );

    const getFetchingLazyState = createSelector(
        getBranch,
        selectors.getFetchingLazyState,
    );

    const getRaw = createSelector(
        getBranch,
        selectors.getRaw,
    );

    const getError = createSelector(
        getBranch,
        selectors.getError,
    );

    const getPager = createSelector(
        getBranch,
        selectors.getPager
    );

    const getUpdatingItemId = createSelector(
        getBranch,
        selectors.getUpdatingItemId
    );


    return {
        getBranch,
        getItems,
        getItemById,
        getParams,
        getFetchingState,
        getFetchingLazyState,
        getRaw,
        getError,
        getPager,
        getUpdatingItemId,
    };
}
