import {
    get, find, filter, reject, pick, uniq, sortBy, curryRight, isEmpty, omit, orderBy, uniqueId, intersection
} from 'lodash';
import { isMilestone, typeCodes } from '../../shared/utils/entity-type';
import { keysToCamelCase } from '../../shared/utils/object';
import { states } from '../goalmap-list/config';
import { composePlanTree, orderChildren } from './timeline/model/timeline-model-utils';
import {
    colors, defaultColor, tableViewElelementLevels, tableViewItemAttributes, workCenterTableViewTypes,
    unassignedPlan
} from './constants';

export const isParentLevel = level => level === tableViewElelementLevels.parent;
export const isSubParentLevel = level => level === tableViewElelementLevels.subParent;
export const isChildLevel = (item) => !!item.parent?.id;

const isOwnerBackgroundColor = (level, tableViewType) =>
    (isParentLevel(level) && tableViewType === workCenterTableViewTypes.owner);

export const getBackgroundColor = (bgColor = 'transparent', tableViewType, level) => {
    if (tableViewType === workCenterTableViewTypes.plan) {
        if (isParentLevel(level)) {
            return { backgroundColor: bgColor };
        }

        return { backgroundColor: 'transparent' };
    }

    if (isChildLevel(level)) {
        return { backgroundColor: 'transparent' };
    }

    if (isSubParentLevel(level) && tableViewType === workCenterTableViewTypes.plan) {
        return { backgroundColor: 'transparent' };
    }

    return (isOwnerBackgroundColor(level, tableViewType)
        ? { backgroundColor: 'var(--color-pale-grey)' }
        : { backgroundColor: bgColor }
    );
};

export const getTableItemTitle = (item) => {
    if (item?.title) return item.title;
    return item.id === null ? item?.firstName : `Owned by ${item?.firstName} ${item?.lastName}`;
};

export const getCounter = (item, tableViewType, selectChildrenByPlan) =>
    (isParentLevel(item.level) && tableViewType === workCenterTableViewTypes.plan
        ? selectChildrenByPlan(item.id).length
        : item?.counter);

export const getFilteredItems = (items, collapsedItems) => {
    const collapsedItemsArray = Object
        .entries(collapsedItems)
        .filter(([, value]) => value)
        .map(([key]) => key);

    return items.filter((item) => {
        if (intersection(item.parents, collapsedItemsArray).length) {
            return false;
        }

        return true;
    });
};

export const isCollapsed = (item, collapsedItems) => {
    return collapsedItems[item.uniqueId];
};

export const composeUniqueId = (planId, typeCode) => {
    switch (typeCode) {
        case typeCodes.driver:
            return `${planId}-csf`;
        case typeCodes.kpi:
            return `${planId}-kpi`;
        default:
            return planId;
    }
};

export const getTreeForTable = (userPlans, plans, parents, children, sortByAttribute = 'name,desc') => {
    const [attribute, direction] = sortByAttribute.split(',');
    const unassignedItems = orderChildren(filter(children, child => !child.goalId), attribute, direction);

    const mapPlan = (plan) => {
        const settings = find(userPlans, { id: plan.id })?.settings;

        const globalParents = [
            {
                typeCode: typeCodes.plan,
                id: composeUniqueId(plan.id, typeCodes.kpi),
                goalId: plan.id,
                title: `Global ${get(settings, 'milestone', 'Key Performance Indicator')}`,
                globalParentTypeCode: typeCodes.kpi,
            },
            {
                typeCode: typeCodes.plan,
                id: composeUniqueId(plan.id, typeCodes.driver),
                goalId: plan.id,
                title: get(settings, 'driver', 'Critical Success Factor'),
                globalParentTypeCode: typeCodes.driver,
            },
        ];

        const filterByPlanId = curryRight(filter, 2)({ goalId: plan.id });
        const filteredParents = filterByPlanId(parents);
        const filteredChildren = filterByPlanId(children);
        const filteredGlobalChildren = filter(filteredChildren, { parent: { typeCode: typeCodes.plan } });

        return {
            ...omit(plan, 'settings'),
            children: composePlanTree(
                globalParents,
                filteredGlobalChildren,
                filteredParents,
                filteredChildren,
                sortByAttribute,
            )
        };
    };

    const result = plans.map(mapPlan);

    if (unassignedItems.length) {
        result.push({
            id: unassignedPlan.id,
            title: unassignedPlan.title,
            counter: unassignedItems.length,
            children: unassignedItems.map(item => ({
                ...item,
                goalId: unassignedPlan.id,
            })),
        });
    }

    return result;
};

export const getChildrenByPlan = (children, parents) => {
    return (planId) => {
        if (planId === unassignedPlan.id) {
            return filter(children, child => !child.goalId);
        }

        const planParents = filter(
            reject(parents, { typeCode: typeCodes.plan }),
            { goalId: planId }
        );

        const filterChildrenByParent = child => find(
            planParents,
            parent => child.parent.id === parent.id && child.parent.typeCode === parent.typeCode
        );

        const filterChildrenByPlan = child => (
            child.parent.typeCode === typeCodes.plan
            && child.parent.id === planId
        );

        const parentChildren = filter(children, filterChildrenByParent);
        const globalChildren = filter(children, filterChildrenByPlan);

        return [...parentChildren, ...globalChildren];
    };
};

export const collectItems = (items, level, array, parents = []) => {
    items.forEach((item) => {
        const uId = uniqueId();

        array.push({ ...item, level, uniqueId: uId, parents });

        if (item.children) {
            collectItems(item.children, level + 1, array, [...parents, uId]);
        }
    });

    const counters = array.reduce((accum, cur) => {
        if (cur.parent) {
            cur.parents.forEach((parentId) => {
                accum[parentId] = (accum[parentId] || 0) + 1;
            });
        }

        return accum;
    }, {});

    return array.map(item => (item.children ? { counter: counters[item.uniqueId], ...omit(item, 'children') } : item));
};

const planColorReducer = (accum, item, index) => {
    const color = colors[index];
    accum[item.id] = color || defaultColor;
    return accum;
};

const customFilterPlanColorReducer = (accum, item) => {
    const color = colors[item.plan_color_index];
    accum[item.plan_id] = color;
    return accum;
};

export const getPlanColorMap = (plans, filters, customFilter) => {
    const customFiltersPlans = get(customFilter, 'filters.plan.value');

    if (!isEmpty(customFiltersPlans) && filters?.quick_filter === 'custom') {
        return customFiltersPlans.reduce(customFilterPlanColorReducer, {});
    }

    return plans.reduce(planColorReducer, {});
};

const sortByTitle = curryRight(sortBy, 2)(['title']);

export const getTimelinePlans = (parents, plans) => {
    const planIds = uniq(parents.map(it => it.goalId));

    return sortByTitle(
        planIds
            .map(id => find(plans, { id }))
            .map(plan => pick(plan, ['id', 'title', 'settings']))
            .map(keysToCamelCase),
    );
};

export const getGridPlans = (parents) => {
    const planIds = uniq(parents.map(it => it.goalId));

    return sortByTitle(
        planIds
            .map(id => find(parents, { id, typeCode: typeCodes.plan }))
            .map(plan => pick(plan, ['id', 'title', 'settings']))
            .map(keysToCamelCase),
    );
};

export const iterator = (el, attribute) => {
    if (attribute === tableViewItemAttributes.title) {
        return el[attribute].toLowerCase();
    }
    if (isMilestone({ type_code: el.typeCode }) && attribute === tableViewItemAttributes.status) {
        return el.entity.state === states.active ? 'Active' : 'Inactive';
    }
    if (attribute === tableViewItemAttributes.location) {
        return el[attribute]?.title?.toLowerCase();
    }
    if (attribute === tableViewItemAttributes.owner) {
        return el?.ownerFullName?.toLowerCase();
    }
    if (attribute === tableViewItemAttributes.created_by) {
        return el?.authorFullName?.toLowerCase();
    }
    return el?.[attribute] || el.entity?.[attribute];
};

export const getItemsByOwner = (plans, parents, childrens, owners, sortByAttribute = 'name,desc') => {
    const [attribute, direction] = sortByAttribute.split(',');

    const childrenOwnerIds = sortBy(owners, ['firstName', 'lastName'])
        .map(item => item.id);

    const result = childrenOwnerIds.map((ownerId, ownerPosition) => {
        const owner = find(owners, { id: ownerId });
        const filteredChildrenByOwnerId = filter(childrens, { ownerId: owner.id });

        const plansForOwner = uniq(filteredChildrenByOwnerId.map(child => child.goalId));

        const plansWithEntities = plansForOwner.map((planId) => {
            let planDetails;

            if (planId === null) {
                planDetails = {
                    title: unassignedPlan.title,
                    id: unassignedPlan.id,
                    typeCode: typeCodes.plan
                };
            } else {
                planDetails = {
                    ...omit(plans.find(plan => plan.id === planId), 'settings'),
                    typeCode: typeCodes.plan,
                };
            }

            const planEntities = filteredChildrenByOwnerId
                .filter(el => el.goalId === planId)
                .map((child) => {
                    const parentEntityDetails = parents.find(
                        parent => parent.id === child.parent.id && parent.typeCode === child.parent.typeCode
                    );
                    return ({
                        ...child,
                        location: { ...(parentEntityDetails ? omit(parentEntityDetails, ['children']) : planDetails) },
                        planUniqueId: `${planDetails.id}-${ownerPosition}`,
                    });
                });

            const orderedPlanEntities = orderBy(
                planEntities,
                [el => iterator(el, attribute)],
                direction
            );

            return {
                ...planDetails,
                ownerId: owner.id,
                counter: planEntities.length,
                children: orderedPlanEntities,
                planUniqueId: `${planDetails.id}-${ownerPosition}`,
            };
        });

        return {
            ...owner,
            counter: filteredChildrenByOwnerId.length,
            children: plansWithEntities,
        };
    });

    return collectItems(result, 1, []);
};

const getOwnerFullName = owner => (owner ? `${owner.first_name} ${owner.last_name}` : 'Unassigned');
export const addOwnerFullName = (owners = []) => item =>
    ({ ...item, ownerFullName: getOwnerFullName(owners.find(owner => owner.id === item.owner_id)) });
export const addAuthorFullName = users => item =>
    ({ ...item, authorFullName: getOwnerFullName(users.find(user => user.id === item.author_id)) });
