export const apiUrl = '/related_links';
export const csfLinkingCollectionName = 'csf-linking-collection';
export const defaultRequestIncludes = 'author.user_profile,related_link';
export const defaultSortingField = 'updated_at,desc';

export const entityTypes = Object.freeze({
    driver: 'driver',
    milestone: 'milestone',
    segment: 'segment',
    plan_action: 'plan_action',
});

export const entityTypesPlural = Object.freeze({
    driver: 'drivers',
    milestone: 'milestones',
    segment: 'segments',
    plan_action: 'plans_actions',
});

export const types = Object.freeze({
    internal: 'internal',
    external: 'external',
});

export const attributeNames = Object.freeze({
    id: 'id',
    url: 'url',
    internalLink: 'internal_link',
    relatedLink: 'related_link',
    relatedLinkId: 'related_link_id',
    relatedLinkType: 'related_link_type',
    primaryLink: 'primary_link',
    primaryLinkId: 'primary_link_id',
    primaryLinkType: 'primary_link_type',
    type: 'type',
    exclude: 'exclude',
    sort: 'order_by',
    includes: 'includes',
    title: 'title',
    status: 'status',
    author: 'author',
    owner: 'owner',
    progressStatus: 'progress_status',
    userProfile: 'user_profile',
    firstName: 'first_name',
    lastName: 'last_name',
    href: 'href',
    links: 'links',
    typeCode: 'type_code',
});

export const attributePaths = Object.freeze({
    id: [attributeNames.id],
    href: [attributeNames.href],
    progressStatus: [
        attributeNames.relatedLink,
        attributeNames.progressStatus
    ],
    title: [
        attributeNames.relatedLink,
        attributeNames.title,
    ],
    status: [
        attributeNames.relatedLink,
        attributeNames.status,
    ],
    ownerFirstName: [
        attributeNames.relatedLink,
        attributeNames.owner,
        attributeNames.userProfile,
        attributeNames.firstName,
    ],
    ownerLastName: [
        attributeNames.relatedLink,
        attributeNames.owner,
        attributeNames.userProfile,
        attributeNames.lastName,
    ],
});

export const titleSortAttribute = attributeNames.title;
