/* eslint-disable object-curly-newline */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classname from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router';
import {
    Transition, Icon,
} from '../../../shared/components';
import * as routeUtils from '../../../routes/rules';
import Permissions from '../../permissions';
import { getHaveUserPermissionForPlanSettings, getPlanSettingsAllowedPath, } from '../../permissions/utils';
import { getTitlesFromPlanById } from '../../goalmap-list/selector';
import { subscriptionFields, subscriptionLimitSelectors } from '../../subscription';

import '../styles/plan-selector.css';

const getSubMenuElements = (
    titlesCurrentPlan, isUserHavePermissionForPlanSettings, planSettingsAllowedPath, csfLimit, archiveLimit, kpiLimit
) => ([
    { title: 'Plan Overview', icon: 'chart-network', path: routeUtils.paths.planOverview, isRender: true },
    { title: 'Navigator', icon: 'compass', path: routeUtils.paths.planNavigator, isRender: true },
    // eslint-disable-next-line max-len
    { title: get(titlesCurrentPlan, 'milestone', 'Key Performance Indicator'), icon: 'analytics', path: routeUtils.paths.planKPIList, isRender: kpiLimit },
    // eslint-disable-next-line max-len
    { title: get(titlesCurrentPlan, 'driver', 'Critical Success Factor'), icon: 'trophy-alt', path: routeUtils.paths.planCSFList, isRender: csfLimit },
    { title: 'Members', icon: 'users', path: routeUtils.paths.planMembers, isRender: true },
    { title: 'Plan Settings', icon: 'cogs', path: planSettingsAllowedPath, isRender: isUserHavePermissionForPlanSettings },
    { title: 'Plan Archive', icon: 'file-archive', path: routeUtils.paths.planArchive, isRender: archiveLimit },
]);

const SubMenu = (props) => {
    const {
        isTouchDevice, planId, titlesCurrentPlan, handleDropdownHide, onClick, visible,
        onMouseLeave, onMouseOver, onFocus, onBlur, planPermissions, csfLimit, archiveLimit, kpiLimit
    } = props;

    const subMenuHide = () => {
        handleDropdownHide();
    };

    const handleEvents = isTouchDevice ? { onClick, onFocus, onBlur } : {
        onMouseOver, onMouseLeave, onFocus, onBlur, onKeyDown: onFocus
    };

    const urlReplaceObject = { [routeUtils.params.PlanId]: planId };

    const isUserHavePermissionForPlanSettings = getHaveUserPermissionForPlanSettings(planPermissions);
    const planSettingsAllowedPath = getPlanSettingsAllowedPath(planPermissions);

    return (
        <div
            className={classname('nav-header-plan-selector-sub-menu', {
                visible,
            })}
            role="menuitem"
            tabIndex={0}
            {...handleEvents}
        >
            <div className="sub-menu-children-container">
                <span>{props.children}</span>
                <Icon name="angle-right" className="sub-menu-children-icon" />
            </div>
            <Transition
                className="sub-menu"
                visibleClassName="sub-menu-visible"
                visible={visible}
            >
                <div className="sub-menu-content">
                    {getSubMenuElements(
                        titlesCurrentPlan,
                        isUserHavePermissionForPlanSettings,
                        planSettingsAllowedPath,
                        csfLimit,
                        archiveLimit,
                        kpiLimit
                    ).map(menuElement => (
                        <Fragment key={menuElement.title}>
                            {menuElement.isRender && (
                                <Link
                                    to={routeUtils.createUrl(menuElement.path, urlReplaceObject)}
                                    className="sub-menu-content-item"
                                    onClick={subMenuHide}
                                    key={menuElement.title}
                                >
                                    <Icon
                                        fixedWidth
                                        type="regular"
                                        name={menuElement.icon}
                                        className="sub-menu-content-icon"
                                    />
                                    {menuElement.title}
                                </Link>
                            )}
                        </Fragment>
                    ))}
                </div>
            </Transition>
        </div>
    );
};

SubMenu.defaultProps = {
    visible: false,
    csfLimit: false,
    archiveLimit: false,
    kpiLimit: false,
};

SubMenu.propTypes = {
    children: PropTypes.node.isRequired,
    isTouchDevice: PropTypes.bool.isRequired,
    planId: PropTypes.number.isRequired,
    handleDropdownHide: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    onMouseLeave: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    planPermissions: PropTypes.object.isRequired,
    csfLimit: PropTypes.bool,
    archiveLimit: PropTypes.bool,
    kpiLimit: PropTypes.bool,
    titlesCurrentPlan: PropTypes.shape({
        driver: PropTypes.string,
        milestone: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    csfLimit: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.driver).value,
    archiveLimit: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.archive).value,
    kpiLimit: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.milestones).value,
    titlesCurrentPlan: getTitlesFromPlanById(state, ownProps.planId),
    planPermissions:
        Permissions.selectors.selectEntityPermissions(state, ownProps.planId, Permissions.constants.entitySections.strategicPlan),
});

export default connect(mapStateToProps, null)(SubMenu);
