import { takeEvery, put } from 'redux-saga/effects';
import { relatedLinkCollection, relatedLinkModel } from './related-link-models';

function* relatedLinkSaga() {
    yield takeEvery(
        relatedLinkModel.actions.remove.success.getType(),
        function* removeRelatedLink({ payload }) {
            const id = payload.related_link
                ? payload.related_link.id
                : payload.id;

            yield put({
                type: relatedLinkCollection.actions.remove.getType(),
                payload: {
                    id,
                    idKey: payload.related_link
                        ? 'related_link_id'
                        : 'id',
                },
            });
        }
    );
}

export default relatedLinkSaga;
