import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import { TableView, Clickable, Button } from '../../../shared/components';
import styles from './settings-account-demo-data.module.css';

const SettingsAccountDemoDataItem = ({
    title, description, slug, goals, type,
    onInstall, onView, onReset
}) => {
    const hasPlans = !!goals.length;
    const lastPlanId = last(goals)?.id;

    const handleInstall = () => {
        onInstall(slug);
    };

    const handleReset = () => {
        onReset(lastPlanId);
    };

    const handleView = () => {
        onView(lastPlanId);
    };

    const renderSeparator = () => (
        <>&nbsp;|&nbsp;</>
    );

    return (
        <TableView.Row>
            <TableView.Cell
                align={TableView.align.LEFT}
                className={styles.cellIndustry}
            >
                {title}
            </TableView.Cell>
            <TableView.Cell
                align={TableView.align.LEFT}
                className={styles.cellIndustry}
            >
                {type}
            </TableView.Cell>
            <TableView.Cell
                align={TableView.align.LEFT}
                overflowVisible
            >
                {description}
            </TableView.Cell>
            <TableView.Cell
                align={TableView.align.CENTER}
                className={styles.cellActions}
            >
                {hasPlans ? (
                    <>
                        <Clickable
                            action="view-demo-plan"
                            onClick={handleView}
                            active
                        >
                            View
                        </Clickable>
                        {renderSeparator()}
                        <Clickable
                            action="reset-demo-plan"
                            onClick={handleReset}
                            active
                        >
                            Reset to default
                        </Clickable>
                    </>
                ) : (
                    <Button
                        onClick={handleInstall}
                        outline
                    >
                        Add Demo Plan
                    </Button>
                )}
            </TableView.Cell>
        </TableView.Row>
    );
};

SettingsAccountDemoDataItem.defaultProps = {
    goals: [],
};

SettingsAccountDemoDataItem.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    goals: PropTypes.array,

    onInstall: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
};

export default SettingsAccountDemoDataItem;
