/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
    CategorizationTagSelector, OwnerSelect, TitleTagnameFields,
} from '../../../shared/modules';
import {
    Col, Editor, ProgressStatusInput, Row, StatusInput, StatusIndicator,
} from '../../../shared/components';
import ActionFields from '../create/action-fields';

export default function ActionForm({
    change, goalId, currentValues, titles, sensitivities, dispatch, currentBreakpoint, disableHealthChange,
}) {
    const smallScreen = ['xsmall', 'small'].includes(currentBreakpoint);

    return (
        <div className="plan-create-form">
            <TitleTagnameFields
                change={change}
                goalId={goalId}
                titleFieldLayoutRenderer={children => (
                    <Row>
                        <Col xs={12} md={8}>
                            {children}
                        </Col>
                        <Col xs={12} md={4}>
                            <OwnerSelect
                                id="subsegment-select-owner"
                                label="Owner"
                                placeholder="Select Owner"
                                goalId={goalId}
                                required
                            />
                        </Col>
                    </Row>
                )}
                tagnameFieldLayoutRenderer={children => (
                    <Row>
                        <Col xs={12} md={8}>
                            {children}
                        </Col>
                        {
                            !smallScreen
                                ? (
                                    <Col md={4}>
                                        <Row>
                                            <Col>
                                                <div className="input-container">
                                                    <span className="input-container-label">Status</span>
                                                    <ProgressStatusInput
                                                        onChange={(value) => {
                                                            dispatch(change('status', value));
                                                        }}
                                                        value={currentValues.status}
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="input-container">
                                                    <span className="input-container-label">Health</span>
                                                    {
                                                        !disableHealthChange
                                                            ? (
                                                                <StatusInput
                                                                    value={currentValues.progress_status}
                                                                    className="driver-progress-status"
                                                                    onChange={(value) => {
                                                                        dispatch(change('progress_status', value));
                                                                    }}
                                                                />
                                                            )
                                                            : (
                                                                <StatusIndicator
                                                                    status={currentValues.progress_status}
                                                                    className="driver-progress-status"
                                                                />
                                                            )
                                                    }
                                                </div>
                                            </Col>
                                        </Row>

                                    </Col>
                                )
                                : null
                        }
                    </Row>
                )}
                titleFieldProps={{
                    autoFocus: true,
                    label: 'Name',
                    placeholder: `Name your ${titles.action}`
                }}
                tagnameFieldProps={{
                    label: 'Tag Name',
                    placeholder: `Type in Tag Name for your ${titles.action}`
                }}
            />

            <Field
                name="description"
                label="Target State"
                placeholder={`Type in ${titles.action} Target State`}
                component={Editor}
                goal_id={goalId}
                controlsHidden
            />

            <Field
                name="tags"
                label="Tags"
                placeholder="Tags"
                id="plan-tags-select"
                component={CategorizationTagSelector}
                goal_id={goalId}
            />

            <ActionFields
                currentValues={currentValues}
                sensitivities={sensitivities}
                dispatch={dispatch}
                change={change}
                smallScreen={smallScreen}
            />
        </div>
    );
}

ActionForm.defaultProps = {
    sensitivities: {
        1: 'standard',
        2: 'restricted',
        3: 'confidential',
    },
    currentBreakpoint: undefined,
    disableHealthChange: false
};

ActionForm.propTypes = {
    goalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
        start_date: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        end_date: PropTypes.string,
        status: PropTypes.string,
        progress_status: PropTypes.number,
        title: PropTypes.string,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    titles: PropTypes.object, // eslint-disable-line
    sensitivities: PropTypes.object, // eslint-disable-line
    currentBreakpoint: PropTypes.string,
    disableHealthChange: PropTypes.bool,
};
