import { List } from '../../../shared/entities';

export default new List({
    apiUrl: '/teams/roles',
    name: 'team-role-list',
    parse(array) {
        return {
            data: array,
        };
    }
});
