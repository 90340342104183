import { Model } from '../../../shared/entities-v2';

const getApiUrl = () => '/authorization_code';

const credentialsLoginModel = new Model({
    getApiUrl,
    name: 'credentials-login-model',
});

export default credentialsLoginModel;
