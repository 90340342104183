import { find, get, keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { name, states } from './config';
import { getStepPercentage } from '../goalmap-wizard/selector';
import { safeClone } from '../../shared/utils/object';

const getPropsId = (_, id) => id;

export function getGoalmaps(state) {
    return get(state, name, {});
}

export function getGoalmapsItems(state) {
    return getGoalmaps(state).items;
}

export const getSortedGoalmapsListItems = createSelector(
    [getGoalmapsItems],
    items => safeClone(items || []).sort((val1, val2) => {
        if (val1.title < val2.title) { return -1; }
        if (val1.title > val2.title) { return 1; }
        return 0;
    })
);

export const getGoalmapsItemsMap = createSelector(
    [getGoalmapsItems],
    items => keyBy(items, 'id')
);

const getPlanFromPlanList = createSelector(
    [getGoalmapsItems, getPropsId],
    (plans, planId) => plans.find(plan => plan.id === planId)
);

export const getTitlesFromPlanById = createSelector(
    getPlanFromPlanList,
    plan => get(plan, 'settings', {})
);

export function goalmapByIdSelector(state, id) {
    const { items } = getGoalmaps(state);
    return find(items, { id });
}

export function getActiveGoalmapsItems(state) {
    return getGoalmapsItems(state).filter(goal => goal.state === 1);
}

export function getDetails(state) {
    return get(state, [name, 'details'], {});
}

export const getPlanTitle = createSelector(
    getDetails,
    planDetails => get(planDetails, 'title', ''),
);

export const getPlanDetailsError = (state) => {
    return get(state, [name, 'detailsError'], {});
};

export const getPlanDetailsState = createSelector(
    getDetails,
    planDetails => planDetails?.state,
);

function getTitles(data = {}) {
    return data.settings || {};
}

export function getDetailsTitles(state) {
    const currentGoal = getDetails(state);
    return getTitles(currentGoal);
}

export function getIsDraftGoal(data = {}) {
    return data.state === states.draft;
}

function getWizardSettings(data = {}) {
    const settings = get(data, 'wizard_settings');

    if (typeof settings === 'string') {
        return JSON.parse(settings);
    }

    return settings;
}

export function getDraftProgress(data) {
    const settings = getWizardSettings(data);
    return getStepPercentage(get(settings, 'step'));
}

export function getGoalWizardStep(data) {
    const settings = getWizardSettings(data);
    return get(settings, 'step');
}

export function getIsDetailsUpdating(state) {
    return getGoalmaps(state).detailsUpdating;
}

export function getDetailsFetchingState(state) {
    return getGoalmaps(state).detailsFetching;
}

export function getIsDemoPlan(data) {
    return !data.is_billable;
}

export function getCurrentPlanIsDemo(state) {
    return getIsDemoPlan(getDetails(state));
}

export function getDetailsUpdatingState(state) {
    return getGoalmaps(state).detailsUpdatingState;
}

export function getMembersUpdatingId(state) {
    return getGoalmaps(state).membersUpdatingId;
}

export function getDetailsRemoving(state) {
    return getGoalmaps(state).detailsRemoving;
}
