import _ from 'lodash';

function isEmptyValue(value) {
    return _.isNull(value) || _.isUndefined(value);
}

export function buildFormData(obj, form, namespace, isArray) {
    const fd = form || new FormData();
    let formKey;

    Object.keys(obj).forEach((objKey) => {
        if (namespace && !isArray) {
            formKey = `${namespace}[${objKey}]`;
        } else {
            formKey = objKey;
        }

        if (Array.isArray(obj[objKey])) {
            obj[objKey].forEach((item, idx) => {
                Object.keys(item).forEach((prop) => {
                    if (isEmptyValue(item[prop])) return;
                    fd.append(`${objKey}[${idx}][${prop}]`, item[prop]);
                });
            });
        } else if (obj[objKey] && typeof obj[objKey] === 'object' && !(obj[objKey] instanceof File)) {
            buildFormData(obj[objKey], fd, objKey, false);
        } else {
            if (isEmptyValue(obj[objKey])) return;
            fd.append(formKey, obj[objKey]);
        }
    });

    // for (const property in obj) {
    //     if (obj.hasOwnProperty(property)) {
    //         if (namespace && !isArray) {
    //             formKey = `${namespace}[${property}]`;
    //         } else {
    //             formKey = property;
    //         }
    //         if (Array.isArray(obj[property])) {
    //             for (const item in obj[property]) {
    //                 if (obj[property].hasOwnProperty(item)) {
    //                     for (const prop in obj[property][item]) {
    //                         if (obj[property][item]
    //                                .hasOwnProperty(prop)) {
    //                             fd.append(`${property}[${item}][${prop}]`,
    //                             obj[property][item][prop]);
    //                         }
    //                     }
    //                 }
    //             }
    //         } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
    //             buildFormData(obj[property], fd, property, false);
    //         } else {
    //             fd.append(formKey, obj[property]);
    //         }
    //     }
    // }

    return fd;
}
