/* eslint-disable max-len */
import React from 'react';
import Loadable from 'react-loadable';
import querystring from 'querystring';
import { flow, isEmpty } from 'lodash';
import withMobileRestriction from '../shared/modules/with-mobile-restrict';
import withScrollTop from '../shared/components/with-scroll-top';
import { withStickyContext } from '../shared/components/sticky/sticky-context';
import { stickyConf } from '../shared/components/sticky/config';
import Loading from './components/loadable';
import planTreeChildRoutes from './plan-tree-children';
import { createUrl, paths, params } from './rules';
import { withPlanSettingsPermissions } from '../modules/permissions/withPlanSettingsPermissions';
import { withPersistPlanPage } from '../modules/plan/with-persist-plan-page';

const PlanRoot = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/plan/container'),
    loading: Loading,
});

const PlanOverview = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/plan/modules/overview/plan-overview'),
    loading: Loading,
});

const PlanGlobalKPI = Loadable({
    loader: () => import(/* webpackChunkName: "plan-additional" */ '../modules/goalmap-milestones/container'),
    loading: Loading,
});

const PlanGlobalKPIUpdate = Loadable({
    loader: () => import(/* webpackChunkName: "plan-additional" */ '../modules/goalmap-milestones/update/kpi-update'),
    loading: Loading,
});

const PlanGlobalKPIList = Loadable({
    loader: () => import(/* webpackChunkName: "plan-additional" */ '../modules/goalmap-milestones/list/container'),
    loading: Loading,
});

const PlanGlobalKPIDetails = Loadable({
    loader: () => import(/* webpackChunkName: "plan-additional" */ '../modules/goalmap-milestones/details'),
    loading: Loading,
});

const PlanCsfContainer = Loadable({
    loader: () =>
        import(/* webpackChunkName: "plan-additional" */ '../modules/primary-entities/critical-success-factor/csf-page-container'),
    loading: Loading,
});

const PlanCsfList = Loadable({
    loader: () =>
        import(/* webpackChunkName: "plan-additional" */ '../modules/primary-entities/critical-success-factor/list/csf-list'),
    loading: Loading,
});

const PlanCsfDetailsContainer = Loadable({
    loader: () =>
        import(/* webpackChunkName: "plan-additional" */ '../modules/primary-entities/critical-success-factor/details/csf-details-container'),
    loading: Loading,
});

const PlanSettingsRoot = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/goalmap-settings'),
    loading: Loading,
});

const PlanSettingsTitles = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/goalmap-settings-titles/container'),
    loading: Loading,
});

const PlanSettingsNotifications = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/goalmap-settings-notifications/container'),
    loading: Loading,
});

const PlanSettingsConfiguration = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/plan/modules/settings/configuration/plan-settings-configuration'),
    loading: Loading,
});

const PlanMembers = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/plan/modules/members/container'),
    loading: Loading,
});

const PlanNavigator = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/plan-navigator/container'),
    loading: Loading,
});

const PlanArchive = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/plan/modules/archive/plan-archive'),
    loading: Loading,
});

const SearchMobile = Loadable({
    loader: () => import(/* webpackChunkName: "plan" */ '../modules/search-mobile/index'),
    loading: Loading,
});

const toLegacyParamsConfig = {
    [params.PlanId]: `:${params.LegacyPlanId}`,
    [params.EntityId]: `:${params.LegacyEntityId}`,
};

function handlePlanRootEnter(data, replace) {
    const id = data.params[params.LegacyPlanId];
    const { query } = data.location;

    const replacedUrlParts = [
        createUrl(paths.planOverview, { [params.PlanId]: id })
    ];

    if (!isEmpty(query)) {
        replacedUrlParts.push(querystring.stringify(query));
    }

    return replace(replacedUrlParts.join('?'));
}

function handlePlanSettingsRootEnter(data, replace) {
    const id = data.params[params.LegacyPlanId];
    const nextUrl = createUrl(paths.planSettingsTerminology, { [params.PlanId]: id });
    replace(nextUrl);
}

export default {
    path: createUrl(paths.plan, toLegacyParamsConfig),
    component: flow(
        withStickyContext(stickyConf.withPlanSubHeader),
        withScrollTop
    )(PlanRoot),
    indexRoute: {
        onEnter: handlePlanRootEnter,
    },
    childRoutes: [
        {
            path: createUrl(paths.planOverview, toLegacyParamsConfig),
            component: flow(
                withScrollTop,
                withPersistPlanPage(paths.planOverview)
            )(PlanOverview),
        },
        {
            path: createUrl(paths.planNavigator, toLegacyParamsConfig),
            component: flow(
                withScrollTop,
                withPersistPlanPage(paths.planNavigator)
            )(PlanNavigator),
            childRoutes: planTreeChildRoutes,
        },
        {
            path: createUrl(paths.planMembers, toLegacyParamsConfig),
            component: flow(
                withScrollTop,
                withPersistPlanPage(paths.planMembers)
            )(PlanMembers),
        },
        {
            path: createUrl(paths.planArchive, toLegacyParamsConfig),
            component: flow(
                withScrollTop,
                withPersistPlanPage(paths.planArchive)
            )(PlanArchive),
        },
        {
            path: createUrl(paths.searchMobile, toLegacyParamsConfig),
            component: flow(
                withScrollTop,
                withPersistPlanPage(paths.searchMobile)
            )(SearchMobile),
        },
        {
            path: createUrl(paths.planKPIList, toLegacyParamsConfig),
            component: withPersistPlanPage(paths.planKPIList)(PlanGlobalKPI),
            indexRoute: {
                component: flow(
                    withMobileRestriction,
                    withScrollTop
                )(PlanGlobalKPIList),
            },
            childRoutes: [
                {
                    path: createUrl(paths.planKPIDetails, toLegacyParamsConfig),
                    component: withScrollTop(props => <PlanGlobalKPIDetails contextLocation="global" {...props} />),
                },
                {
                    path: createUrl(paths.planKPIUpdate, toLegacyParamsConfig),
                    component: flow(withMobileRestriction, withScrollTop)(PlanGlobalKPIUpdate),
                }
            ],
        },
        {
            path: createUrl(paths.planCSFList, toLegacyParamsConfig),
            component: flow(
                withScrollTop,
                withPersistPlanPage(paths.planCSFList)
            )(PlanCsfContainer),
            indexRoute: {
                component: flow(
                    withMobileRestriction,
                    withScrollTop,
                )(PlanCsfList),
            },
            childRoutes: [
                {
                    path: createUrl(paths.planCSFDetails, toLegacyParamsConfig),
                    component: withScrollTop(PlanCsfDetailsContainer),
                },
            ],
        },
        {
            path: createUrl(paths.planSettings, toLegacyParamsConfig),
            component: flow(
                withScrollTop,
                withMobileRestriction,
                withPlanSettingsPermissions,
                withPersistPlanPage(paths.planSettings)
            )(PlanSettingsRoot),
            indexRoute: {
                onEnter: handlePlanSettingsRootEnter,
            },
            childRoutes: [
                {
                    path: createUrl(paths.planSettingsTerminology, toLegacyParamsConfig),
                    component: withScrollTop(PlanSettingsTitles),
                },
                {
                    path: createUrl(paths.planSettingsNotifications, toLegacyParamsConfig),
                    component: withScrollTop(PlanSettingsNotifications),
                },
                {
                    path: createUrl(paths.planSettingsConfiguration, toLegacyParamsConfig),
                    component: withScrollTop(PlanSettingsConfiguration),
                },
            ],
        }
    ]
};
