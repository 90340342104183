import { get } from 'lodash';
import u from 'updeep';
import request from '../../../request';
import { Collection } from '../../../shared/entities-v2';
import { formatThresholds, getPeriodTargetValueAttributeByKpi } from '../utils';
import { attributes } from '../constants';
import { formatAdvancedCalculationFields } from './utils';

function prepareData(data) {
    const periodTargets = get(data, attributes.kpi_period_targets, []);
    const compareAttribute = getPeriodTargetValueAttributeByKpi(get(data, attributes.kpi_type));
    const filteredPeriodTargets = periodTargets.filter((item) => {
        const value = get(item, compareAttribute);
        return value !== null && value !== '';
    });
    const formattedThresholds = formatThresholds(data);
    const formattedAdvCalcFields = formatAdvancedCalculationFields(data);

    return u({
        ...formattedThresholds,
        ...formattedAdvCalcFields,
        [attributes.kpi_period_targets]: filteredPeriodTargets,
    }, data);
}

class PeriodTargetCollection extends Collection {
    format = (params) => {
        return prepareData(params);
    }

    parse = (response) => {
        return {
            data: response
        };
    }

    fetch(params, options) {
        const formattedParams = this.format(params);
        const url = this.getApiUrl(formattedParams);

        return request
            .post(url, formattedParams, options)
            .then(this.parse, this.parseError);
    }
}

const periodTargetCollection = new PeriodTargetCollection({
    apiUrl: '/milestones/period_targets',
    name: 'milestone-period-target-collection',
});

export default periodTargetCollection;
