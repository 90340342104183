import request from '../../../../request';
import { Model } from '../../../../shared/entities-v2';
import { asyncTypes as requestTypes } from '../../../../shared/entities-v2/model/config';
import { applicationSettingsModel } from '../../../application';
import { multiFactorAuthType } from './constants';


const apiUrl = '/multi_factor_authentications/app/state';
const nameModel = 'settings-account-multi-factor-auth-model';

class SettignsAccountMultiFactorAuth extends Model {
    // eslint-disable-next-line class-methods-use-this
    format(data) {
        return ({ state: data === true ? multiFactorAuthType.active : multiFactorAuthType.inactive });
    }

    // eslint-disable-next-line class-methods-use-this
    update(data, options) {
        const formattedData = this.format(data);
        return request
            .put(apiUrl, formattedData, options)
            .then(response => this.parse(response, requestTypes.update))
            .catch(this.parseError);
    }
}

const settingsAccountMultiFactorAuthModel = new SettignsAccountMultiFactorAuth({
    getApiUrl: () => apiUrl,
    name: nameModel,
    additionalArtifacts: {
        [Model.additionalArtifacts.triggers]: {
            [Model.events.onUpdateSuccess]: applicationSettingsModel.actions.fetch.request,
        }
    }
});

export default settingsAccountMultiFactorAuthModel;
