import u from 'updeep';
import { ModelCollectionSyncPlugin, ModelNotificationsPlugin } from '../../shared/entities-v2/plugins';
import { TeamModel } from '../../shared/modules/team-section';
import teamCollection from './teams-collection';
import { apiUrl } from './teams-constants';

const teamModel = new TeamModel({
    apiUrl,
    name: 'profile-team-model',
    plugins: [
        new ModelCollectionSyncPlugin({
            name: 'profile-team-model-collection-sync',
            collection: teamCollection,
        }),
        new ModelNotificationsPlugin({
            name: 'profile-team-model-notifications',
            dictionary: u({
                success: {
                    create: 'The Team has been created',
                    update: 'The Team has been updated',
                    remove: 'The Team has been deleted',
                }
            }, ModelNotificationsPlugin.dictionary)
        })
    ],
});

export default teamModel;
