import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../user/selectors';
import { preferencesAccountSecurityMFADisableModal } from '../../preferences/account-security/preferences-account-security-models';
import PreferencesAccountSecurityRegisterDeviceModal from
    '../../preferences/account-security/modals/preferences-account-security-register-device-modal';
import { selectIsRecoveryUsed } from '../selectors';
import { logout, setRecoveryUsed } from '../actions';


const AuthenticationRecoveryUsedMessage = () => {
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    const isRecoveryUsed = useSelector(selectIsRecoveryUsed);
    const closeModal = useCallback(() => {
        dispatch(setRecoveryUsed(false));
    }, [dispatch]);

    const updating = useSelector(preferencesAccountSecurityMFADisableModal.selectors.getStateRemoving);

    const handleRegisterAnotherDevice = useCallback(() => {
        const handleSuccess = () => {
            dispatch(setRecoveryUsed(false));
            dispatch(logout());
        };

        const data = {
            params: { userId },
        };

        const options = { onSuccess: handleSuccess };

        dispatch(preferencesAccountSecurityMFADisableModal.actions.remove.request(data, options));
    }, [dispatch, userId]);

    return (
        <PreferencesAccountSecurityRegisterDeviceModal
            isFetching={updating}
            visible={isRecoveryUsed}
            onHide={closeModal}
            onClick={handleRegisterAnotherDevice}
        />
    );
};

export default AuthenticationRecoveryUsedMessage;
