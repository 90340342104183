import objectToFormData from 'object-to-formdata';
import request from '../../../request';
import { List, createModal, createDetails } from '../../../shared/entities';
import { attachmentCollection } from '../attachment';
import { getEntitiesByType } from '../../../shared/utils/content-editor-entity-selector';
import { states } from '../../goalmap-list/config';
import formatAttachments from '../attachment/attachment-format';
import { apiCreate, assignEntityRequiredError, parseErrorResponse } from './progress-note-config';

const apiUrl = '/notes';

export const progressNoteCollection = new List({
    apiUrl,
    name: 'progress-note-list',
});

export const progressNoteCreateModel = createModal({
    apiUrl,
    name: 'progress-note-create',
    updateHTML: true,
    onSuccess: [
        progressNoteCollection.actions.add,
        attachmentCollection.actions.request,
    ],
    extendAPI: {
        create: apiCreate,
    }
});

export const progressNoteModel = createDetails({
    apiUrl,
    name: 'progress-note-details',
    onSuccess: attachmentCollection.actions.request,
    onUpdate: [
        progressNoteCollection.actions.update,
        attachmentCollection.actions.request,
    ],
    updateHTML: true,
    extendAPI: {
        update({ params }) {
            const {
                id,
                content,
                attachments,
                deleted,
                state,
                parentEntityType,
                parentId,
                entity_id,
                entity_type
            } = params;

            const data = {
                content,
                mentions: getEntitiesByType(content, 'mention'),
                attachments: formatAttachments(attachments),
                entity_type: entity_type || parentEntityType,
                entity_id: entity_id || parentId,
                deleted,
                state
            };

            if (state !== states.draft && (!data.entity_type || !data.entity_id)) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    response: { data: { errors: { entity: [assignEntityRequiredError] } } }
                });
            }

            return request
                .post(`${apiUrl}/${id}`, objectToFormData(data, { indices: true }))
                .catch(parseErrorResponse);
        },

        remove(params) {
            const {
                id,
                entityType,
                attachments,
                entityId,
            } = params;

            return request
                .delete(`${apiUrl}/${id}${(attachments ? '/attach' : '')}`)
                .then(() => ({
                    id,
                    entity_id: entityId,
                    entity_type: entityType,
                }));
        }
    }
});
