import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import FileDrop from 'react-file-drop';
import { LoadingIndicator } from '../../../../shared/components';
import validateSize from '../../../../shared/utils/files';
import { SubscriptionLimitProviders } from '../../../subscription';
import { attachmentFilePickerShape } from '../attachment-constants';
import AttachmentFileSource from '../file-source/attachment-file-source';

// TO DO: Move to shared folder/component
import '../../progress-note/form/progress-note-form-file-drop.css';

export default class AttachmentForm extends React.Component {
    constructor(props) {
        super(props);

        this.attachmentsField = React.createRef();
    }

    handleDrop = (files) => {
        files = [...files];
        const fileSource = this.attachmentsField.current.getRenderedComponent();
        fileSource.dropzoneComponent.current.dropzone.current.handleFiles(files);
    }

    handleDragOverDropArea = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    renderAttachmentInput = (limitProps) => {
        const {
            filePicker, dirty, canCreateAttachment, canDestroyAttachment, canDestroyOwnAttachment,
        } = this.props;

        return (
            <Field
                ref={this.attachmentsField}
                name="attachments"
                component={AttachmentFileSource}
                validate={value => validateSize(value, limitProps.value)}
                fileSizeLimit={limitProps.value}
                filePicker={filePicker}
                dirtyForm={dirty}
                canCreateAttachment={canCreateAttachment}
                canDestroyAttachment={canDestroyAttachment}
                canDestroyOwnAttachment={canDestroyOwnAttachment}
                forwardRef
            />
        );
    }

    render() {
        const { submitting, canCreateAttachment, } = this.props;
        return (
            <div className="attachment-create-form">
                {canCreateAttachment && (
                    <div className="react-file-drop">
                        <FileDrop
                            onDrop={this.handleDrop}
                            onDragOver={this.handleDragOverDropArea}
                        />
                    </div>
                )}
                {submitting
                    ? (
                        <div className="loading-overlay">
                            <LoadingIndicator />
                        </div>
                    )
                    : null
                }

                <SubscriptionLimitProviders.AttachmentSize>
                    {this.renderAttachmentInput}
                </SubscriptionLimitProviders.AttachmentSize>
            </div>
        );
    }
}

AttachmentForm.propTypes = {
    submitting: PropTypes.bool.isRequired,
    filePicker: attachmentFilePickerShape.isRequired,
    dirty: PropTypes.bool,
    canCreateAttachment: PropTypes.bool.isRequired,
    canDestroyAttachment: PropTypes.bool.isRequired,
    canDestroyOwnAttachment: PropTypes.bool.isRequired,
};

AttachmentForm.defaultProps = {
    dirty: undefined
};
