import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/component';
import WithSort from './with-sort';

export default function TableViewSortIcon({ sortDirection }) {
    let iconName;
    switch (sortDirection) {
        case WithSort.direction.ASC:
            iconName = 'sort-down'; break;
        case WithSort.direction.DESC:
            iconName = 'sort-up'; break;
        default:
            iconName = 'sort';
    }

    return (
        <Icon name={iconName} type={Icon.types.solid} />
    );
}

TableViewSortIcon.propTypes = {
    sortDirection: PropTypes.string.isRequired
};
