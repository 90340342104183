import u from 'updeep';
import { createReducer } from 'redux-act';
import * as actions from './color-theme-actions';
import { reducerName, themes } from './color-theme-constants';

const initialState = {
    theme: themes.DARK,
    accentColor: '#6eb13c',
};

const colorThemeReducer = createReducer({
    [actions.setTheme]: (state, { theme }) => u(
        { theme },
        state
    ),
    [actions.setAccentColor]: (state, { color }) => u(
        { accentColor: color },
        state
    ),
}, initialState);

colorThemeReducer.path = reducerName;

export default colorThemeReducer;
