import u from 'updeep';
import colors from '../../../styles/variables';
import { prepareOuterSections, prepareInnerSections } from './utils';
import { defaultHeight, defaultColor, defaultPivotRadius } from './constants';
import baseConfig from './base';

const sectionColors = [
    colors['color-warm-grey-light'],
];

export default function placeholder({
    backgroundColor = defaultColor,
    height = defaultHeight,
    size,
    radius = defaultPivotRadius,
}) {
    const series = [{
        name: 'Value',
        data: [{
            y: 0,

        }],
        dataLabels: {
            enabled: true,
            formatter() {
                return 'No Value';
            },
        }
    }];

    return u({
        chart: u({
            backgroundColor,
            height,
        }, baseConfig.chart),
        pane: u({
            size,
        }, baseConfig.pane),
        plotOptions: {
            gauge: {
                pivot: {
                    radius,
                }
            }
        },
        yAxis: [
            u({
                min: 0,
                max: 100,
                tickPositions: [],
                plotBands: [
                    ...prepareOuterSections([0, 100], sectionColors, 0),
                    ...prepareInnerSections([0, 100], sectionColors, 0),
                ],
            }, baseConfig.yAxis),
        ],
        series
    }, baseConfig);
}
