import { isIE } from 'mobile-device-detect';
import { eventChannel } from 'redux-saga';
import registerEvents, { events } from './register-events';

function install(oneSignalAppId) {
    if ('serviceWorker' in navigator) {
        return navigator.serviceWorker.register(`/OneSignalSDKWorker.js?appId=${oneSignalAppId}`, { scope: '/' });
    }

    return Promise.reject();
}

function logEvent(name) {
    console.log(`[SW Lifecycle Event]: ${name}`); // eslint-disable-line no-console
}

// Chrome will suspend execution on the tab when it is not visible
// chrome://discards/
// Simple trick to make chrome disable `discard` function on this page
function keepTabAlive() {
    let postfix = '';

    setInterval(() => {
        postfix = postfix === '' ? ' ' : '';
        document.title = `MPOWR Envision${postfix}`;

        const serviceWorkerController = navigator.serviceWorker && navigator.serviceWorker.controller;

        if (serviceWorkerController) {
            serviceWorkerController.postMessage({
                action: 'ping',
            });
        }
    }, 1000);
}

function attachEvents(promise) {
    return eventChannel((emit) => {
        registerEvents(promise, {
            [events.onInstalled]: () => {
                emit({ type: events.onInstalled });
                logEvent(events.onInstalled);
            },
            [events.onUpdateReady]: () => {
                emit({ type: events.onUpdateReady });
                logEvent(events.onUpdateReady);

                promise.then((registration) => {
                    registration.update();
                    return registration;
                });
            },
            [events.onUpdating]: () => {
                emit({ type: events.onUpdating });
                logEvent(events.onUpdating);
            },
            [events.onUpdateFailed]: () => {
                emit({ type: events.onUpdateFailed });
                logEvent(events.onUpdateFailed);
            },
            [events.onUpdated]: () => {
                emit({ type: events.onUpdated });
                logEvent(events.onUpdated);
            },
        });

        return () => { };
    });
}

export function init(oneSignalAppId) {
    if (!isIE) {
        const promise = install(oneSignalAppId);
        const eventsChannel = attachEvents(promise);
        keepTabAlive();

        return eventsChannel;
    }

    return undefined;
}
