import { filter } from 'lodash';
import u from 'updeep';
import { mergeInCollection } from '../../shared/utils/collection';
import * as actions from './dashboard-actions';
import { createWidgetMap, widgetMapToState, insertRemainingWidgets } from './dashboard-mappers';

function updateItems(items, column, position, modifier, avoidSideEffects, stopPosition) {
    return items.map((item) => {
        if (item.column === column
                && ((item.position >= position)
                && !(avoidSideEffects && item.position > stopPosition))) {
            return u(
                { position: item.position + modifier },
                item
            );
        }

        return item;
    });
}

function findItem(items, id) {
    return items.find(item => item.id === id) || {};
}

function handleMove(state, action) {
    if (!action.targetId) {
        return state;
    }

    let { items } = state;
    const source = findItem(items, action.sourceId);
    let target = findItem(items, action.targetId);

    if (action.type) {
        const widgets = filter(items, { column: action.type });

        target = {
            column: action.type,
            position: widgets.length + 1,
        };
    }

    const insertPosition = action.position === 'above'
        ? target.position
        : target.position + 1;

    const sameColumn = source.column === target.column;

    items = updateItems(items, target.column, insertPosition, 1, sameColumn, source.position);

    items = mergeInCollection(items, source.id, {
        column: target.column,
        position: insertPosition,
    });

    if (!sameColumn) {
        items = updateItems(items, source.column, source.position, -1);
    }

    return u(
        { items },
        state
    );
}

function handleAdd(state, action) {
    const { column, position } = action;

    const items = updateItems(state.items, column, position, 1);

    return u(
        { items },
        state
    );
}

function handleRemove(state, action) {
    const deletedItem = findItem(state.items, action.id);
    const { position, column } = deletedItem;
    const items = updateItems(state.items, column, position, -1);

    return u(
        { items },
        state
    );
}

function handleReLayout(state, action) {
    const { columnCount } = action;

    const { widgetMap, remaining } = createWidgetMap(state.items, columnCount);

    insertRemainingWidgets(widgetMap, remaining);

    const nextWidgetState = widgetMapToState(state.items, widgetMap);

    return u({
        items: nextWidgetState,
    }, state);
}

export default {
    [actions.move]: handleMove,
    [actions.reLayout]: handleReLayout,
    onAdd: handleAdd,
    onRemove: handleRemove,
};
