import React from 'react';
import PropTypes from 'prop-types';
import { GridContainer } from '../../shared/components';

function EmptyPage({ title, children }) {
    return (
        <GridContainer>
            <h1>Empty Page - {title}</h1>
            <p>For development purposes</p>

            {children ? (
                <div>
                    <hr />
                    <h2>Children of the page</h2>
                    {children}
                </div>
            ) : null}

        </GridContainer>
    );
}

EmptyPage.defaultProps = {
    title: 'No Name',
    children: undefined,
};

EmptyPage.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

export const EmptyPageReports = props => (<EmptyPage {...props} title="Reports" />);
