import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';
import useResizeObserver from '../../hooks/use-resize-observer';
import { ChipsItem, HorizontalScrollContainer } from '../index';
import './style.css';

const Chips = ({
    items,
    className,
    itemsType,
    withReduxFormField,
    currentValue,
    ...rest
}) => {
    const ref = useRef(null);
    const scrollRef = useRef(null);
    const listRef = useRef(null);
    const [setNode, observerEntry] = useResizeObserver();

    useEffect(() => {
        setNode(ref.current);
    }, [ref]);

    useEffect(() => {
        if (currentValue && observerEntry.target && scrollRef.current) {
            const el = listRef.current.querySelector(`[data-value=${currentValue}]`);

            if (el?.offsetLeft) {
                const scrollPos = (el.offsetLeft + el.offsetWidth) - listRef.current.offsetLeft;
                const visibleOffset = scrollRef.current.offsetWidth + listRef.current.scrollLeft;

                if (scrollPos > visibleOffset) scrollRef.current.scrollTo(scrollPos, 0);
            }
        }
    }, [observerEntry, currentValue, scrollRef, listRef.current?.offsetWidth]);

    return (
        <HorizontalScrollContainer ref={scrollRef}>
            <div className={classNames('chips', { [className]: !!className })} ref={ref}>
                <ul className="chips-list" ref={listRef}>
                    {items.map((item, index) => (
                        <li key={String(index)} className="chips-list-item" data-value={item.value}>
                            {withReduxFormField ? (
                                <Field
                                    component={ChipsItem}
                                    type={itemsType}
                                    {...item}
                                    {...rest}
                                />
                            ) : (
                                <ChipsItem
                                    type={itemsType}
                                    {...item}
                                    {...rest}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </HorizontalScrollContainer>
    );
};

Chips.defaultProps = {
    className: '',
    itemsType: 'radio',
    withReduxFormField: false,
    currentValue: undefined,
};

Chips.propTypes = {
    items: PropTypes.array.isRequired,
    itemsType: PropTypes.oneOf(['radio', 'checkbox']),
    className: PropTypes.string,
    withReduxFormField: PropTypes.bool,
    currentValue: PropTypes.any,
};

export default Chips;
