import PropTypes from 'prop-types';
import React from 'react';
import { formValueSelector, Field } from 'redux-form';
import get from 'lodash';
import createFormModal from '../../../../shared/modules/create-view/component';
import { RelatedLinkSelect } from '../../../../shared/modules';
import { Clickable, Input } from '../../../../shared/components';
import parseError from '../related-link-parse-error';
import { relatedLinkCreateModel } from '../related-link-models';
import { attributeNames as attrNames, types, entityTypesPlural } from '../related-link-constants';
import './related-link-create-modal.css';

function RelatedLinkCreateModal({
    change,
    currentValues,
    allowedTypes,
    internalLinkTitle
}) {
    const currentType = currentValues[attrNames.type];

    return (
        <div className="related-links-form">
            <div className="related-links-tabs">
                {allowedTypes[types.external] && (
                    <Clickable
                        action="Set Web Link Tab"
                        className="related-links-toggle"
                        onClick={() => change(attrNames.type, types.external)}
                        active={currentType === types.external}
                    >
                        Web Link
                    </Clickable>
                )}


                {allowedTypes[types.internal] && (
                    <Clickable
                        action="Set Internal Link Tab"
                        className="related-links-toggle"
                        onClick={() => change(attrNames.type, types.internal)}
                        active={currentType === types.internal}
                    >
                        {internalLinkTitle}
                    </Clickable>
                )}
            </div>

            {currentType === types.internal && (
                <Field
                    name="internal_link"
                    component={RelatedLinkSelect}
                    props={{
                        params: {
                            id: currentValues.primary_link_id,
                            primary_link_type: currentValues.primary_link_type,
                            exclude: entityTypesPlural.driver
                        },
                        required: true,
                        entity: 'object',
                    }}
                    onChange={(event, value) => {
                        change(attrNames.relatedLinkId, value.id);
                        change(attrNames.relatedLinkType, value.related_link_type);
                    }}
                />
            )}

            {currentType === types.external && (
                <span>
                    <Field
                        name={attrNames.url}
                        component={Input}
                        props={{
                            placeholder: 'Paste Link',
                            required: true,
                        }}
                    />

                    <Field
                        name={attrNames.title}
                        component={Input}
                        props={{
                            placeholder: 'Display Name',
                        }}
                    />
                </span>
            )}
        </div>
    );
}

const defaultAllowedTypes = {
    [types.internal]: true,
    [types.external]: true,
};

RelatedLinkCreateModal.defaultProps = {
    allowedTypes: defaultAllowedTypes,
    internalLinkTitle: 'Internal Link'
};

RelatedLinkCreateModal.propTypes = {
    change: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
        type: PropTypes.string,
        primary_link_id: PropTypes.number,
        primary_link_type: PropTypes.string,
    }).isRequired,
    allowedTypes: PropTypes.object,
    internalLinkTitle: PropTypes.string
};

const {
    actions, selector
} = relatedLinkCreateModel;
const formName = 'related-links-create';
const formSelector = formValueSelector(formName);

const formModal = createFormModal({
    actions,
    selector,
    formName,
    parseFormSubmitError: parseError,
    mapStateToProps(state, ownProps) {
        const {
            parentId,
            parentEntityType,
            updateDetails,
            allowedTypes = defaultAllowedTypes,
        } = ownProps;

        return {
            title: 'Related Links',
            draftAvailable: false,
            currentValues: {
                type: formSelector(state, attrNames.type),

                primary_link_type: formSelector(state, attrNames.primaryLinkType),
                primary_link_id: formSelector(state, attrNames.primaryLinkId),
            },
            initialValues: {
                type: get(allowedTypes, types.external)
                    ? types.external
                    : types.internal,

                related_link_id: undefined,
                related_link_type: undefined,

                primary_link_id: parentId,
                primary_link_type: parentEntityType,
            },
            onCreateSuccess(response) {
                (response || []).forEach(item => updateDetails(item.primary_link));
                ownProps.onCreateSuccess();
            },
        };
    }
})(RelatedLinkCreateModal);

export default formModal;
