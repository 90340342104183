import { List } from '../../shared/entities';
import parseData from '../../shared/modules/with-move-entity/parse';
import reducer from './reducer';

const name = 'wizard-navigation-goalmap-tree';

export default new List({
    apiUrl: '/goals',
    name,
    getApiUrl({ id }) {
        return `/goals/${id}/tree`;
    },
    parse: parseData,
    extendReducer: reducer,
});
