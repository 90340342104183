import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.css';

const CheckBox = (props) => {
    const {
        id,
        name,
        text,
        checked,
        onChange,
        is_editable,
        className,
    } = props;
    const disable = !is_editable ? { opacity: '0.5' } : null;
    const status = checked ? 'activeBox' : 'inactiveBox';
    return (
        <div className={cx('customCheckBox', status, className)} style={disable}>
            <input
                checked={checked}
                onChange={e => onChange(name, e)}
                className="customCheckBox-custom"
                type="checkbox"
                id={id}
                name={name}
                disabled={!is_editable}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label className="customCheckBox-custom-label" htmlFor={id}><span />{text}</label>
        </div>
    );
};

CheckBox.defaultProps = {
    checked: true,
    is_editable: true,
    className: null
};

CheckBox.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    is_editable: PropTypes.bool,
    checked: PropTypes.bool,
};

export default CheckBox;
