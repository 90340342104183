import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './table-view-row.css';

export default function TableViewRow({ children, disabled, className }) {
    return (
        <tr className={
            cx('table-view-row', {
                [className]: !!className,
                disabled,
            })}
        >
            {children}
        </tr>
    );
}

TableViewRow.defaultProps = {
    children: undefined,
    className: undefined,
    disabled: false,
};

TableViewRow.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};
