import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

export default function ModalFormContainer({ children, className }) {
    return (
        <div
            className={classnames('modal-form-container', {
                [className]: !!className,
            })}
        >
            {children}
        </div>
    );
}

ModalFormContainer.defaultProps = {
    className: undefined,
    children: undefined,
};

ModalFormContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
