import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { types } from '../../../shared/utils/entity-type';
import createModalWizard from '../../wizard-entity/wizard-modal';
import { getDetailsTitles } from '../../goalmap-list/selector';
import { create as createStrategyModel, details as strategyModel } from '../entity';
import StrategyForm from '../form';
import WizardForm from './wizard';

const StrategyModalWizard = createModalWizard({
    name: 'strategy-wizard-form-create',
    actions: {
        create: createStrategyModel.actions.request,
        update: strategyModel.actions.update.request,
        updateTags: strategyModel.actions.updateTags.request,
        remove: strategyModel.actions.remove.request,
        fetch: strategyModel.actions.request,
        toggle: createStrategyModel.actions.toggle,
    },
    selectors: {
        selectModalVisible: state => createStrategyModel.selector(state).modalVisible,
        selectFetchingState: state => strategyModel.selector(state).loading,
        selectAttributes: state => strategyModel.selector(state).data,
        titleSelector: titles => get(titles, types.segment),
    },
})(WizardForm);

const StrategyModalWizardRenderer = props => <StrategyModalWizard {...props} />;

function mapStateToProps(state) {
    const titles = getDetailsTitles(state);
    const steps = [
        {
            ContentComponent: StrategyForm,
            description: `Name your ${titles.segment}, Assign Owner, Add Target State`
        }
    ];
    return { steps };
}

export default connect(mapStateToProps)(StrategyModalWizardRenderer);
