import React from 'react';
import PropTypes from 'prop-types';
import Clickable from '../../../../../shared/components/clickable/component';
import Text from '../../../../../shared/components/text';

export default function AttachmentFilePickerBreadcrumbs({ items, onClick, renderItemContent }) {
    return (
        <div className="file-picker-breadcrumbs">
            {items.map((item, index) => (
                <Text
                    component={Clickable}
                    action="navigate"
                    className="file-picker-breadcrumbs-item"
                    onClick={() => onClick(index)}
                    styleType="dark"
                    size="small"
                >
                    {renderItemContent(item)}
                </Text>
            ))}
        </div>
    );
}

AttachmentFilePickerBreadcrumbs.propTypes = {
    items: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    renderItemContent: PropTypes.func.isRequired,
};
