import { useEffect } from 'react';
import { connect } from 'react-redux';
import localizationsCollection from './localizations-collection';

const ApplicationLocalizations = ({ fetchLocalizations }) => {
    useEffect(() => {
        fetchLocalizations();
    }, []);

    return null;
};

const mapDispatchToProps = {
    fetchLocalizations: localizationsCollection.actions.fetch.request,
};

export default connect(null, mapDispatchToProps)(ApplicationLocalizations);
