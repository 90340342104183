import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, getFormValues, initialize } from 'redux-form';
import classnames from 'classnames';
import { bind } from 'lodash';
import Composer from 'react-composer';
import { noop } from '../../../shared/utils/common';
import {
    Button,
    Modal,
    Icon,
    WizardHeader,
    WizardFooter, PromptProvider,
} from '../../../shared/components';
import {
    ModalFormContainer,
    ModalFormHeader,
    ModalFormBody,
} from '../../../shared/components/modal-form';
import {
    WizardButtonsLeftGroup,
    WizardButtonSlot,
} from '../../../shared/components/wizard';
import { SubscriptionLimitProviders } from '../../subscription';
import { getDetailsTitles } from '../../goalmap-list/selector';
import { initEntityFormWizardProvider } from '../../wizard-entity/entity-form-wizard-provider';
import { locations as milestoneLocation } from '../../goalmap-milestones/constants';
import styles from './styles.module.css';

export const modes = Object.freeze({
    default: 'default',
    draft: 'draft',
});

const defaultProps = {
    id: undefined,
    initialValues: {},
    onFinished: noop,
    onRemoveSuccess: noop,
    onSubmitStep: noop,
    onCancel: noop,
    titles: {},
    steps: [],
    currentValues: {},
    getSubmitSteps() { return [2]; },
    keepModalVisible: null,
    remove: noop,
    data: {},
    mode: modes.default,
    isGlobalKpi: false,
};

const propTypes = {
    id: PropTypes.number,
    fetch: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    remove: PropTypes.func,
    resetForm: PropTypes.func.isRequired,
    onFinished: PropTypes.func,
    onRemoveSuccess: PropTypes.func,
    onSubmitStep: PropTypes.func,
    onCancel: PropTypes.func,
    steps: PropTypes.array,
    visible: PropTypes.bool.isRequired,
    initialValues: PropTypes.object,
    titles: PropTypes.object,
    submitTitle: PropTypes.string.isRequired,
    currentValues: PropTypes.object,
    getSubmitSteps: PropTypes.func,
    keepModalVisible: PropTypes.func,
    initializeForm: PropTypes.func.isRequired,
    data: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf(Object.values(modes)),
    isGlobalKpi: PropTypes.bool,
    goalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};

function defaultMergeInitialValues(data, initialValues) {
    return {
        ...data,
        id: initialValues.id,
    };
}

function defaultParse(data) { return data; }

function createWizardModal(options) {
    const formValueSelector = getFormValues(options.name);
    const parse = options.parseData || defaultParse;
    const mergeInitialValues = options.mergeInitialValues || defaultMergeInitialValues;

    function mapStateToProps(state) {
        return {
            visible: options.selectors.selectModalVisible(state),
            titles: getDetailsTitles(state),
            currentValues: formValueSelector(state),
            fetching: options.selectors.selectFetchingState(state),
            data: options.selectors.selectAttributes(state),
        };
    }

    const actions = {
        fetch: options.actions.fetch,
        create: options.actions.create,
        update: options.actions.update,
        updateTags: options.actions.updateTags,
        remove: options.actions.remove,
    };

    const mapDispatchToProps = {
        fetch: options.actions.fetch,
        toggle: options.actions.toggle,
        remove: options.actions.remove,
        resetForm: reset,
        keepModalVisible: options.actions.keepModalVisible,
        initializeForm: initialize,
    };

    const Provider = initEntityFormWizardProvider(options.name);

    return (EnhancedComponent) => {
        class WizardModal extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    page: 1,
                    savedAsDraft: false,
                };

                this.providerProps = null;
            }

            componentDidMount() {
                const { keepModalVisible } = this.props;

                if (keepModalVisible) {
                    keepModalVisible({ state: true });
                }
            }

            get entityTitle() {
                const { titles, initialValues } = this.props;
                const entityType = initialValues.entity_type;
                return options.selectors.titleSelector(titles, entityType);
            }

            get mergedInitialValues() {
                const { initialValues, data, goalId, } = this.props;

                if (initialValues.id) {
                    return mergeInitialValues(data, initialValues);
                }

                return { ...initialValues, goal_id: goalId };
            }

            get pageComponents() {
                const { steps } = this.props;

                return steps.map(pg => pg.ContentComponent);
            }

            handlePageChanged = (page) => {
                this.setState({ page });
            }

            handleCancel = (promptProps) => {
                const { toggle, currentValues, onCancel } = this.props;

                if (currentValues.id && options.removeOnCancel) {
                    return promptProps.show();
                }

                onCancel();
                return this.reset(toggle);
            };

            renderCancel = (promptProps) => {
                const handleCancelWithPrompt = bind(this.handleCancel, this, promptProps);

                return (
                    <Button
                        type="button"
                        className={WizardFooter.classNames.control}
                        onClick={handleCancelWithPrompt}
                        outline
                    >
                        Cancel
                    </Button>
                );
            };

            handleRemove = () => {
                const {
                    toggle, remove, currentValues, onRemoveSuccess
                } = this.props;

                remove({
                    params: currentValues,
                    resolve: () => {
                        onRemoveSuccess();
                        this.reset(toggle);
                    }
                });
            };

            handleSubmitSuccess = (data) => {
                const {
                    onFinished, steps, initializeForm, initialValues,
                    toggle, keepModalVisible, onSubmitStep
                } = this.props;
                const entityType = initialValues.entity_type;
                const { page, savedAsDraft, } = this.state;
                if ((page === steps.length && data) || savedAsDraft) {
                    this.reset(() => onFinished(data));
                    this.setState({ savedAsDraft: false });
                    if (keepModalVisible) {
                        toggle();
                    }
                } else if (data) {
                    this.providerProps.toNextPage();
                    onSubmitStep(page);
                    initializeForm(options.name, { ...parse(data), entity_type: entityType });
                }
            }

            handleErrorSubmit = () => {
                this.setState({ savedAsDraft: false });
            }

            reset = (cb) => {
                const { resetForm } = this.props;

                resetForm(options.name);
                this.setState({ page: 1 }, cb);
            }

            renderHeader = () => {
                const { page } = this.state;
                const { steps, initialValues, isGlobalKpi, } = this.props;
                const title = initialValues.id
                    ? this.entityTitle
                    : `Create New ${this.entityTitle}`;
                const entityType = initialValues.entity_type;
                return (
                    <WizardHeader
                        className={styles.modalBodyWizardSection}
                        steps={steps}
                        title={title}
                        currentStep={page}
                        entity={{
                            type: entityType,
                            ...(isGlobalKpi && { location: milestoneLocation.global })
                        }}
                    />
                );
            }

            renderFooter = (providerProps, promptProps, draftLimitProps) => {
                this.providerProps = providerProps;
                const {
                    steps, submitTitle, getSubmitSteps, mode
                } = this.props;
                const { page } = this.state;
                const { submitting } = providerProps.formProps;
                const submitSteps = getSubmitSteps();
                const nextButtonAction = submitSteps.includes(page)
                    ? providerProps.triggerSubmit
                    : providerProps.toNextPage;

                const onSubmitDraft = () => {
                    providerProps.changeField('state', 4);
                    this.setState({ savedAsDraft: true });
                    setTimeout(() => providerProps.triggerSubmit(), 15);
                };

                const onSubmitActive = () => {
                    providerProps.changeField('state', 1);
                    setTimeout(() => providerProps.triggerSubmit(), 15);
                };

                const isLastStep = page === steps.length;
                const isSubmitStep = isLastStep || submitSteps.includes(page);
                const shouldRenderDraft = isSubmitStep && mode !== modes.draft && draftLimitProps.value;

                return (
                    <WizardFooter
                        error={this.milestoneProcessingError}
                        contentWrapperClassName={styles.modalBodyWizardSection}
                        className={styles.modalWizardFooter}
                    >
                        <WizardButtonsLeftGroup>
                            {page > 1 && (
                                <WizardButtonSlot>
                                    <Button
                                        className={classnames({
                                            [WizardFooter.classNames.controlToLeft]: true,
                                            [WizardFooter.classNames.control]: true,
                                        })}
                                        disabled={submitting}
                                        onClick={providerProps.toPrevPage}
                                        outline
                                    >
                                        <Icon
                                            className={Button.classNames.icon.small}
                                            name="arrow-left"
                                        /> Back
                                    </Button>
                                </WizardButtonSlot>
                            )}
                        </WizardButtonsLeftGroup>

                        <WizardButtonSlot>
                            {this.renderCancel(promptProps)}
                        </WizardButtonSlot>

                        {shouldRenderDraft && (
                            <WizardButtonSlot>
                                <Button
                                    className={WizardFooter.classNames.control}
                                    type="button"
                                    styleType="accent"
                                    disabled={submitting}
                                    loading={submitting}
                                    onClick={onSubmitDraft}
                                >
                                    Save as draft
                                </Button>
                            </WizardButtonSlot>
                        )}

                        {isLastStep ? (
                            <Fragment>
                                <WizardButtonSlot>
                                    <Button
                                        className={WizardFooter.classNames.control}
                                        type="button"
                                        styleType="success"
                                        disabled={submitting}
                                        loading={submitting}
                                        onClick={mode !== modes.draft ? onSubmitActive : onSubmitDraft}
                                    >
                                        {submitTitle}
                                    </Button>
                                </WizardButtonSlot>
                            </Fragment>
                        ) : (
                            <WizardButtonSlot>
                                <Button
                                    className={WizardFooter.classNames.control}
                                    type="button"
                                    styleType="success"
                                    onClick={() => nextButtonAction()}
                                    disabled={submitting}
                                >
                                    Next
                                </Button>
                            </WizardButtonSlot>
                        )}
                    </WizardFooter>
                );
            }

            renderModal(promptProps) {
                const {
                    visible,
                    steps,
                    currentValues,
                    fetching,
                    ...rest
                } = this.props;
                const handleCancelWithPrompt = bind(this.handleCancel, this, promptProps);

                const initialValues = this.mergedInitialValues;

                const title = initialValues.id
                    ? 'Edit Entity'
                    : 'Create Entity';

                return (
                    <Modal
                        visible={visible}
                        hide={handleCancelWithPrompt}
                        className={styles.modalWizard}
                        modalContentOuterClassName={styles.modalWizardContentOuter}
                        modalContentClassName={styles.modalWizardContent}
                    >
                        <ModalFormContainer>
                            <ModalFormHeader
                                hide={handleCancelWithPrompt}
                            >
                                {title}
                            </ModalFormHeader>

                            <ModalFormBody className={styles.modalBodyWizard}>
                                <Composer
                                    components={[
                                        <Provider
                                            checkValidityOnPageChange
                                            pageComponents={this.pageComponents}
                                            page={this.state.page}
                                            create={actions.create}
                                            update={actions.update}
                                            fetch={actions.fetch}
                                            updateTags={actions.updateTags}
                                            remove={actions.remove}
                                            initialValues={initialValues}
                                            currentValues={currentValues}
                                            onChangePage={this.handlePageChanged}
                                            onSubmitSuccess={this.handleSubmitSuccess}
                                            onErrorSubmit={this.handleErrorSubmit}
                                            entityId={initialValues.id}
                                            fetching={fetching}
                                        />,
                                        <SubscriptionLimitProviders.Draft />
                                    ]}
                                >
                                    {([providerProps, draftLimitProps]) => (
                                        <Fragment>
                                            {this.renderHeader()}

                                            <EnhancedComponent
                                                {...rest}
                                                {...providerProps}
                                                initialValues={providerProps.formProps.initialValues}
                                                title={this.entityTitle}
                                            />

                                            {this.renderFooter(providerProps, promptProps, draftLimitProps)}
                                        </Fragment>
                                    )}
                                </Composer>
                            </ModalFormBody>
                        </ModalFormContainer>
                    </Modal>
                );
            }

            render() {
                return (
                    <PromptProvider
                        description={`${this.entityTitle} will be deleted if you cancel.`}
                        onAccept={this.handleRemove}
                    >
                        {promptProps => this.renderModal(promptProps)}
                    </PromptProvider>
                );
            }
        }

        WizardModal.propTypes = propTypes;
        WizardModal.defaultProps = defaultProps;
        WizardModal.modes = modes;

        return connect(mapStateToProps, mapDispatchToProps)(WizardModal);
    };
}

export default createWizardModal;
