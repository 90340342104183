import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../input/base';
import Icon from '../icon/component';
import { noop } from '../../utils/common';

export default class CustomInputDate extends Component {
    handleClear = () => {
        const { input } = this.props;

        input.onChange(null);
    }

    renderPrefix = ({ iconClassName }) => {
        return (
            <Icon
                name="calendar-alt"
                type={Icon.types.regular}
                className={iconClassName}
            />
        );
    }

    renderPostfix = ({ iconClassName }) => {
        const { isClearable, input } = this.props;

        if (!isClearable || !input.value) {
            return null;
        }

        return (
            <Icon
                name="times-circle"
                className={iconClassName}
                onClick={this.handleClear}
            />
        );
    }

    focus = () => {
        this.input.focus();
    }

    render() {
        const {
            onClick,
            input,
            value,
            ...rest
        } = this.props;

        return (
            <Input
                ref={(el) => { this.input = el; }}
                onClick={onClick}
                input={{
                    ...input,
                    value
                }}
                prefix={this.renderPrefix}
                postfix={this.renderPostfix}
                autoComplete="off"
                value={value}
                {...rest}
            />
        );
    }
}

CustomInputDate.defaultProps = {
    isClearable: false,
    value: undefined,
    onChange: noop,
    onClick: noop
};

CustomInputDate.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
        ]),
        onChange: PropTypes.func,
    }).isRequired,
    isClearable: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.string
};
