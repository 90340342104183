import {
    takeEvery, select, put, call, fork, race, take
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import u from 'updeep';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import resetMiddlewareAction from '../../middleware/reset/actions';
import { selectSearchQuery, selectCurrentPathname, selectHash } from '../../shared/selectors/routing';
import { routerLocationChange } from '../../shared/constants';
import apiService from '../../request';
import appConfig from '../../config';
import * as routeUtils from '../../routes/rules';
import { wait } from '../../shared/utils/common';
import { joinGetParams } from '../../shared/utils/url';
import * as cacheUtils from '../../shared/utils/cache';
import { selectUserDetails } from '../user/selectors';
import workCenterCollection from '../work-center/work-center-collection';
import { settingsAccountSubscriptionModel } from '../settings-account/subscription/entity';
import { applicationSettingsActions, applicationSettingsSelectors, } from '../application';
import { handleLogout as unregisterPush } from '../push-notifications/saga';
import permissionModel from '../permissions/permission-model';
import {
    composeLoginResponse, fieldNames, resetPasswordSuccessParam, changePasswordSuccessParam,
    getIsAllowedUnauthorizedPage, defaultRedirectURL, providers
} from './config';
import * as authActions from './actions';
import {
    getAuthenticationData, getIsSignupAttempt, getIsSocialChangeEmailAttempt, 
    getTokenRefreshDelay, getSignupAttempt
} from './selectors';
import loginModel from './login/login-model';
import credentialsLoginModel from './login/credentials-login-model';
import signupModel from './signup/signup-model';
import { serviceAuthModelPlugin } from './service-auth/service-auth-model';
import resetPasswordModel from './reset-password/reset-password-request-model';
import changePasswordModel from './reset-password/reset-password-model';
import * as changeEmailActions from './change-email/actions';
import changeEmailModel from './change-email/change-email-model';
import * as auth0 from './auth0';
import refreshTokenAPI from './token-refresh-api';
import type from './components/social-button/type';
import getIsRecoveryUsed from './recovery-used-message/authentication-get-recovery-used-api';
import { selectAccentColor } from '../application/color-theme/color-theme-selectors';


const loginUrl = routeUtils.createUrl(routeUtils.paths.login);
const signupUrl = routeUtils.createUrl(routeUtils.paths.signup);
const changeEmailCallbackUrl = routeUtils.createUrl(routeUtils.paths.changeEmailCallback);
const isServiceAuthQuery = query => query.client_id && query.state;

function* refreshToken() {
    const authData = yield select(getAuthenticationData);
    try {
        const response = yield call(refreshTokenAPI);
        apiService.setAuthHeader(response.refresh_token);
        const userData = yield select(selectUserDetails);
        const loginResponseData = composeLoginResponse({
            ...authData,
            lastLoginTime: moment().format(),
            accessToken: response.refresh_token,
            accessTokenExpired: response.access_token_expired,
            accessTokenRefreshTime: response.access_token_refresh_time,
            accessTokenExpiredAt: response.access_token_expired_at,
            accessTokenRefreshTimeAt: response.access_token_refresh_time_at,
        }, userData);

        yield put(authActions.authenticate(loginResponseData));
    } catch (error) {
        yield put(authActions.logout());
    }
}


function* startInteractionsTimer() {
    const delay = yield select(applicationSettingsSelectors.selectUserSessionTimeout);

    const { activity } = yield race({
        timeout: call(wait, delay * 1000),
        activity: take(routerLocationChange)
    });

    if (activity) {
        yield fork(startInteractionsTimer);
    } else {
        yield put(authActions.logout());
    }
}

function* startRefreshTokenTimer() {
    const delay = yield select(getTokenRefreshDelay);

    const { logout } = yield race({
        refreshDelay: call(wait, delay * 1000),
        logout: take(authActions.logout),
    });

    if (!logout) {
        yield call(refreshToken);
        yield fork(startRefreshTokenTimer);
    }
}

function* startSessionTimers() {
    yield fork(startRefreshTokenTimer);
    yield fork(startInteractionsTimer);
}

function* handleLoginSuccess({ payload }) {
    apiService.setAuthHeader(payload.access_token);

    const data = u({
        lastLoginTime: moment().format(),
    }, payload);

    yield put(authActions.authenticate(data));
    yield put(authActions.startSessionTimers());
    yield put(permissionModel.actions.fetch.request());
    yield put(settingsAccountSubscriptionModel.actions.fetch.request());
    yield put(workCenterCollection.actions.reset());
    yield put(authActions.setSignupAttempt());
    yield put(authActions.setSocialChangeEmailAttempt());
    yield put(authActions.setExpiringSSOProviderCheck(true));

    const isRecoveryUsed = yield call(getIsRecoveryUsed, payload.id);

    if (isRecoveryUsed) {
        yield put(authActions.setRecoveryUsed(true));
    }
}

function* handleAuthError() {
    yield put(authActions.setSignupAttempt());
    yield put(authActions.setSocialChangeEmailAttempt());
}

function* checkAuthentication() {
    const authData = yield select(getAuthenticationData);
    const currentPath = yield select(selectCurrentPathname);
    const isAllowedUnauthorizedPage = getIsAllowedUnauthorizedPage(currentPath);

    const {
        accessToken,
        lastLoginTime,
        accessTokenExpiredAt,
        accessTokenRefreshTimeAt,
    } = authData;

    if (!accessToken || !lastLoginTime) {
        const query = yield select(selectSearchQuery);
        const nextQuery = queryString.stringify({
            ...query,
            code: undefined,
            oauth_type: undefined,
        });
        const redirectUrl = yield select(applicationSettingsSelectors.getSavedRedirectPath);

        if (!isAllowedUnauthorizedPage) {
            yield put(applicationSettingsActions.saveRedirectPath(joinGetParams(currentPath, nextQuery)));
        }

        if (isAllowedUnauthorizedPage && nextQuery) {
            yield put(applicationSettingsActions.saveRedirectPath(
                joinGetParams(redirectUrl || defaultRedirectURL, nextQuery)
            ));
        }

        if (!isAllowedUnauthorizedPage) {
            yield put(push(loginUrl));
        }

        return;
    }

    const now = moment();
    const isTokenExpired = accessTokenExpiredAt
        ? moment(accessTokenExpiredAt * 1000).isBefore(now)
        : true;

    const isTokenRefreshExpired = accessTokenRefreshTimeAt
        ? moment(accessTokenRefreshTimeAt * 1000).isBefore(now)
        : true;

    if ((isTokenExpired || isTokenRefreshExpired) && !isAllowedUnauthorizedPage) {
        const query = yield select(selectSearchQuery);
        const nextQuery = queryString.stringify(query);

        yield put(applicationSettingsActions.saveRedirectPath(joinGetParams(currentPath, nextQuery)));
        yield put(push(loginUrl));
    }

    if (!isTokenExpired) {
        const query = yield select(selectSearchQuery);

        if (isServiceAuthQuery(query)) {
            yield put(resetMiddlewareAction());
            yield put(applicationSettingsActions.resetRedirectPath());
            return;
        }

        const userData = yield select(selectUserDetails);
        const loginResponseData = composeLoginResponse(authData, userData);
        apiService.setAuthHeader(loginResponseData.access_token);

        yield put(permissionModel.actions.fetch.request());
        yield put(settingsAccountSubscriptionModel.actions.fetch.request());
        yield put(authActions.authenticate(loginResponseData));
        yield put(authActions.startSessionTimers());
    }
}

export function* handleLogout({ payload = {} }) {
    const query = queryString.stringify({
        message: payload.message,
        logout: 1,
        ...payload.query,
    });

    const returnTo = joinGetParams(`${appConfig.origin}${loginUrl}`, query);

    yield call(unregisterPush);
    apiService.removeAuthHeader();

    yield put(applicationSettingsActions.setAccess({
        accessRights: {
            mobile: false,
            desktop: false
        }
    }));

    yield put(resetMiddlewareAction());
    yield put(applicationSettingsActions.resetRedirectPath());

    yield call(cacheUtils.clearCache);

    auth0.logout(returnTo);
}

function* handleSignupError() {
    const isSignup = yield select(getIsSignupAttempt);
    const query = yield select(selectSearchQuery);

    if (isSignup && isEmpty(query)) {
        yield put(authActions.setSignupAttempt());
    }

    if (isSignup && !isEmpty(query)) {
        const signupData = yield select(getSignupAttempt);
        const redirectQuery = queryString.stringify({
            [fieldNames.inviteToken]: signupData.inviteToken,
            [fieldNames.email]: signupData.email,
        });
        const redirectUrl = joinGetParams(signupUrl, redirectQuery);

        yield put(authActions.setSignupAttempt());
        yield put(push(redirectUrl));
    }
}

function getAuthData(query, hash) {
    const result = {
        oauthType: undefined,
        accessToken: undefined,
    };

    if (query.oauth_type && query.access_token) {
        result.oauthType = query.oauth_type;
        result.accessToken = query.access_token;
        return Promise.resolve(result);
    }

    if (hash) {
        return auth0
            .parseHash(hash)
            .then((response) => {
                result.oauthType = providers.auth0;
                result.accessToken = response.accessToken;
                return result;
            });
    }

    return Promise.reject(Error('Could not get authentication data'));
}

function* handleSocialAuthCallback() {
    const isSignupAttempt = yield select(getIsSignupAttempt);
    const isSocialChangeEmailAttempt = yield select(getIsSocialChangeEmailAttempt);
    const isServiceAuthAttemptInProgress = yield select(serviceAuthModelPlugin.selectors.getAttemptInProgress);
    const hash = yield select(selectHash);
    const query = yield select(selectSearchQuery);

    if (isSignupAttempt) {
        yield put(authActions.signupCallback({ hash }));
        return;
    }

    try {
        const authData = yield call(getAuthData, query, hash);

        if (isSocialChangeEmailAttempt) {
            const nextQuery = queryString.stringify({
                oauthType: authData.oauthType,
                accessToken: authData.accessToken,
            });

            const nextUrl = joinGetParams(changeEmailCallbackUrl, nextQuery);

            yield put(push(nextUrl));

            return;
        }

        if (isServiceAuthAttemptInProgress) {
            const redirectQuery = queryString.stringify({
                code: authData.accessToken,
                oauth_type: authData.oauthType,
            });
            yield put(push(`/service-auth?${redirectQuery}`));
            return;
        }

        yield put(loginModel.actions.request({
            params: {
                oauth_type: authData.oauthType,
                access_token: authData.accessToken,
            }
        }));
    } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.error('Failed to parse hash data', err);
        }
        yield put(push(loginUrl));
    }
}

function* handleSocialAuthCallbackSuccess({ payload }) {
    yield call(handleLoginSuccess, { payload: payload.response });
}

function* handleResetPasswordSuccess() {
    const query = queryString.stringify({
        [resetPasswordSuccessParam]: true,
    });
    const url = joinGetParams(loginUrl, query);

    yield put(push(url));
}

function* handleChangePasswordSuccess() {
    const query = queryString.stringify({
        [changePasswordSuccessParam]: true,
    });
    const url = joinGetParams(loginUrl, query);

    yield put(push(url));
}

function* handleCredentialLoginSuccess({ payload }) {
    const { code } = payload.response;
    const isServiceAuthAttemptInProgress = yield select(serviceAuthModelPlugin.selectors.getAttemptInProgress);

    if (!isServiceAuthAttemptInProgress) {
        yield put(loginModel.actions.request({
            params: {
                oauth_type: type.auth0,
                code,
                scope: '*',
            }
        }));
        return;
    }

    const query = queryString.stringify({
        code,
        oauth_type: type.auth0,
    });
    yield put(push(`/service-auth?${query}`));
}

function* handleLoginAttempt({ payload = {} }) {
    if (!payload.provider) return;

    const redirectUri = `${appConfig.origin}${loginUrl}?ver=${Date.now()}`;

    if (payload.provider === providers.auth0) {
        const { email, password } = payload.data;

        try {
            yield call(auth0.loginWithCredentials, {
                email,
                password,
                redirectUri,
            });
        } catch (err) {
            payload.reject();
            yield put(loginModel.actions.error({
                response: { data: { message: err.description } }
            }));
        }

        return;
    }

    yield call(auth0.authorize, {
        connection: payload.provider,
        redirectUri,
    });
}

function* checkUnauthorizedResponse({ payload }) {
    if (get(payload, ['response', 'code']) === 401) {
        yield put(authActions.logout());
    }
}

function* handleSignupCallback({ payload }) {
    const { hash } = payload;

    try {
        const parsedAuthData = yield call(auth0.parseHash, hash);
        const signupAttempt = yield select(getSignupAttempt);

        yield put(signupModel.actions.request({
            params: {
                access_token: parsedAuthData.accessToken,
                invite_token: signupAttempt.inviteToken,
            },
        }));
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to parse hash data');
        yield call(handleSignupError);
    }
}

function* handleRedirectToUniversalLogin({ payload }) {
    const accentColor = yield select(selectAccentColor);

    const data = {
        redirectUri: `${appConfig.origin}/login`,
        responseType: 'token',
        envision: {
            SSOLoginUrl: `${appConfig.origin}/login`,
            changePasswordUrl: `${appConfig.origin}/password-reset-request`,
            accentColor,
            ...payload,
        }
    };

    yield call(auth0.loginWithUniversalPage, data);
}

export default function* authSaga() {
    yield takeEvery(loginModel.actions.success, handleLoginSuccess);
    yield takeEvery(credentialsLoginModel.actions.fetch.success, handleCredentialLoginSuccess);
    yield takeEvery(signupModel.actions.success, handleLoginSuccess);
    yield takeEvery(signupModel.actions.error, handleSignupError);
    yield takeEvery(changeEmailActions.changeEmailComplete, handleLoginSuccess);
    yield takeEvery(changeEmailModel.actions.update.success, handleSocialAuthCallbackSuccess);
    yield takeEvery(changeEmailModel.actions.update.error, handleAuthError);
    yield takeEvery(authActions.checkAuthentication, checkAuthentication);
    yield takeEvery(authActions.logout, handleLogout);
    yield takeEvery(authActions.initAuth0Service, auth0.init);
    yield takeEvery(authActions.setLoginAttempt, handleLoginAttempt);
    yield takeEvery(authActions.signupCallback, handleSignupCallback);
    yield takeEvery(authActions.startSessionTimers, startSessionTimers);
    yield takeEvery(resetPasswordModel.actions.success, handleResetPasswordSuccess);
    yield takeEvery(changePasswordModel.actions.success, handleChangePasswordSuccess);
    yield takeEvery(authActions.socialAuthCallback, handleSocialAuthCallback);
    yield takeEvery(authActions.redirectToUniversalLogin, handleRedirectToUniversalLogin);
    yield takeEvery('*', checkUnauthorizedResponse);
}
