import { get } from 'lodash';
import colors from '../../../styles/variables';
import { outerSection, innerSection } from './constants';
import { rangeOfMeasure } from '../../../../modules/goalmap-milestones/constants';

function ascColorGetterComparator(lowBound, highBound, value) {
    return value >= lowBound && value <= highBound;
}

function descColorGetterComparator(lowBound, highBound, value) {
    return value <= lowBound && value >= highBound;
}

const colorGetterComparators = {
    [rangeOfMeasure.asc]: ascColorGetterComparator,
    [rangeOfMeasure.between]: ascColorGetterComparator,
    [rangeOfMeasure.desc]: descColorGetterComparator,
};

export function getSectionColorByValue(bounds, sectionColors, value, range) {
    const comparator = colorGetterComparators[range] || ascColorGetterComparator;

    const color = sectionColors.find((item, index) => {
        const lowBound = bounds[index];
        const highBound = bounds[index + 1];

        return comparator(lowBound, highBound, value);
    });

    return color || sectionColors[sectionColors.length - 1];
}

export function prepareOuterSections(bounds, sectionColors) {
    const result = [];

    for (let i = 0; i < bounds.length - 1; i += 1) {
        result.push({
            from: bounds[i],
            to: bounds[i + 1],
            color: get(sectionColors, i, sectionColors[sectionColors.length - 1]),
            innerRadius: outerSection.innerRadius,
            outerRadius: outerSection.outerRadius,
        });
    }

    return result;
}

export function prepareInnerSections(bounds, sectionColors, value, range) {
    return [
        {
            from: bounds[0],
            to: value,
            color: getSectionColorByValue(bounds, sectionColors, value, range),
            innerRadius: innerSection.innerRadius,
            outerRadius: innerSection.outerRadius,
        },
        {
            from: value,
            to: bounds[bounds.length - 1],
            color: colors['color-warm-grey-light'],
            innerRadius: innerSection.innerRadius,
            outerRadius: innerSection.outerRadius,
        }
    ];
}


export function normalizeExceedingValue(lowBound, highBound, value) {
    if (value < lowBound) return lowBound;
    if (value > highBound) return highBound;
    return value;
}
