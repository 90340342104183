import io from 'socket.io-client';

const events = Object.freeze({
    connect: 'connect',
    authenticate: 'authenticate',
    notification: 'desktop_messages',
    disconnect: 'disconnect',
    error: 'error',
});

export default class Socket {
    constructor(options = {}) {
        this.url = options.url;
        this.socket = null;
        this.destroy = null;
    }

    getInstance = () => this.socket

    initialize = ({
        token,
        onInitialize,
        onInitializeError,
        onNotification,
        onClose,
    }) => {
        this.socket = io.connect(this.url, {
            transport: ['websocket'],
            secure: true,
            rejectUnauthorized: false
        });
        this.destroy = this.socket.close;

        this.socket.on(events.connect, (data) => {
            this.socket.emit(events.authenticate, { token: `Bearer ${token}` });
            onInitialize(data);
        });
        this.socket.on(events.error, onInitializeError);
        this.socket.on(events.notification, onNotification);
        this.socket.on(events.disconnect, onClose);
    }

    send = (type, data = {}) => this.socket.emit(type, data)
}
