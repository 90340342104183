import u from 'updeep';
import { createReducer } from 'redux-act';
import { get } from 'lodash';
import * as collaboratorActions from '../../../modules/secondary-entities/collaborator/collaborator-actions';

const initialState = {
    modalVisible: false,
    modalItemId: undefined,
    data: {},
    loading: false,
    updating: false,
    error: null,
    updateError: null,
    versioningConflict: false,
};

function fetch(state, action) {
    if (action && action.silent) {
        return state;
    }

    const nextState = {
        ...state,
        data: {},
    };

    return u(
        {
            data: {},
            loading: true,
            error: null,
            updateError: null,
            versioningConflict: false,
        },
        nextState
    );
}

function fetchSuccess(state, response) {
    return u(
        {
            loading: false,
            data: response,
        },
        state
    );
}

function fetchError(state, response) {
    return u(
        {
            loading: false,
            error: get(response, ['response', 'data'], response),
        },
        state
    );
}

function updateStart(state) {
    return u(
        {
            updating: true,
            updateError: null,
            versioningConflict: false,
        },
        state
    );
}

function updateSuccess(state, response) {
    return u(
        {
            data: response || {},
            updating: false,
            modalVisible: false,
        },
        state
    );
}

function removeSuccess(state) {
    return u(
        {
            data: {},
            updating: false,
            modalVisible: false,
            modalItemId: undefined,
        },
        state
    );
}

function updateError(state, response) {
    return u(
        {
            updating: false,
            updateError: get(response, ['response', 'data'], response),
            versioningConflict: !!response.versioning,
        },
        state
    );
}

function handleClearError(state) {
    return u(
        {
            updating: false,
            updateError: null
        },
        state
    );
}

function toggleModalReducer(state, action) {
    return u(
        {
            modalVisible: !state.modalVisible,
            modalItemId: action && action.id,
        },
        state
    );
}

function setWatchStatusSuccess(state, response) {
    let newStatus;

    if ('status' in response) {
        newStatus = response.status;
    } else {
        newStatus = true;
    }

    return u(
        {
            updating: false,
            data: {
                is_watching: newStatus,
            }
        },
        state
    );
}

function hideVersionConflictModalReducer(state) {
    return u(
        { versioningConflict: false, },
        state
    );
}

function handleInitializeWith(state, action) {
    return u({
        data: action,
    }, state);
}

function handleReset() {
    return initialState;
}

export default function createReducerFn({
    request,
    success,
    error,
    clearError,
    update,
    updateTags,
    updateStatus,
    remove,
    toggleModal,
    setWatchStatus,
    hideVersionConflictModal,
    reset,
    initializeWith,
}, extraActions, extraInitialState) {
    return createReducer({
        [request]: fetch,
        [success]: fetchSuccess,
        [error]: fetchError,

        [update.request]: updateStart,
        [updateTags.request]: updateStart,
        [updateStatus.request]: updateStart,
        [remove.request]: updateStart,
        [setWatchStatus.request]: updateStart,

        [update.success]: updateSuccess,
        [updateTags.success]: updateSuccess,
        [updateStatus.success]: updateSuccess,
        [remove.success]: removeSuccess,
        [setWatchStatus.success]: setWatchStatusSuccess,
        [collaboratorActions.updateWatchStatus]: setWatchStatusSuccess,

        [update.error]: updateError,
        [updateTags.error]: updateError,
        [updateStatus.error]: updateError,
        [remove.error]: updateError,
        [setWatchStatus.error]: updateError,

        [hideVersionConflictModal]: hideVersionConflictModalReducer,
        [toggleModal]: toggleModalReducer,
        [reset]: handleReset,
        [clearError]: handleClearError,
        [initializeWith]: handleInitializeWith,
        ...extraActions
    }, {
        ...initialState,
        ...extraInitialState,
    });
}

export function createSelector(name) {
    return state => state[name];
}
