import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HistoryDescriptionModal from './history-description-modal';

export default class HistoryDescriptionContainer extends Component {
    constructor(args) {
        super(args);

        this.state = {
            descriptionModalVisible: false,
            descriptionModalContent: '',
        };
    }

    toggleDescriptionModal = (content) => {
        if (this.state.descriptionModalVisible) {
            this.setState({
                descriptionModalVisible: false,
                descriptionModalContent: '',
            });
        } else {
            this.setState({
                descriptionModalVisible: true,
                descriptionModalContent: content,
            });
        }
    }

    render() {
        const { children, row } = this.props;

        return (
            <div className={`description-wrap ${row ? 'row' : ''}`}>
                {children({
                    toggleDescrModal: this.toggleDescriptionModal,
                })}

                <HistoryDescriptionModal
                    visible={this.state.descriptionModalVisible}
                    toggle={this.toggleDescriptionModal}
                    content={this.state.descriptionModalContent}
                />
            </div>
        );
    }
}

HistoryDescriptionContainer.defaultProps = {
    children() { return null; },
    row: false,
};

HistoryDescriptionContainer.propTypes = {
    children: PropTypes.func,
    row: PropTypes.bool
};
