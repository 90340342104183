import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
    AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from '../../../../../shared/components/accordion';
import { Text, PromptProvider } from '../../../../../shared/components';
import ListViewInfiniteScrollProvider from '../../../../../shared/modules/list-view/infinite-scroll';
import { legacyFormatSelectorFactory } from '../../../../../shared/entities-v2/collection/legacy';

import { types as relatedLinksType, attributeNames as attrNames } from '../../related-link-constants';
import driverRelatedLinkCollection from '../csf-connection-collection';
import { csfConnectionModel } from '../csf-connection-models';
import ItemDriverMobile from './csf-connection-list-item-mobile';

const CsfConnectionListMobile = ({
    id, fetch, fetchMore, entityType, titleDriver, remove,
}) => {
    const renderItem = (item, modalProps) =>
        (
            <ItemDriverMobile
                title={get(item, [attrNames.relatedLink, 'title'], '')}
                progress_status={get(item, [attrNames.relatedLink, 'progress_status'], 4)}
                href={item.href}
                icon_name={item.icon_name}
                key={item.id}
                onRemove={() => modalProps.show(item.id, {
                    description: 'You are about to remove this link.',
                })}
            />
        );

    const renderListItem = ({ items, modalProps }) => items.map(item => renderItem(item, modalProps));

    return (
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    <Text>{titleDriver} Connection(s)</Text><br />
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <PromptProvider onAccept={item_id => remove({ id: item_id })}>
                    {modalProps => (
                        <ListViewInfiniteScrollProvider
                            params={{
                                [attrNames.primaryLinkId]: id,
                                [attrNames.primaryLinkType]: entityType,
                                [attrNames.exclude]: 'milestone,plan_action,segment',
                                [attrNames.type]: relatedLinksType.internal,
                                [attrNames.sort]: 'title,asc',
                                limit: 25,
                            }}
                            selector={legacyFormatSelectorFactory(driverRelatedLinkCollection.selectors)}
                            fetch={fetch}
                            fetchMore={fetchMore}
                            silentReFetch={false}
                            itemsProps={{ modalProps }}
                        >
                            {renderListItem}
                        </ListViewInfiniteScrollProvider>
                    )}
                </PromptProvider>
            </AccordionItemPanel>
        </AccordionItem>
    );
};

CsfConnectionListMobile.propTypes = {
    id: PropTypes.number.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    entityType: PropTypes.string.isRequired,
    titleDriver: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    fetch: driverRelatedLinkCollection.actions.fetch.request,
    fetchMore: driverRelatedLinkCollection.actions.fetchLazy.request,
    remove: csfConnectionModel.actions.remove.request,
};

export default connect(null, mapDispatchToProps)(CsfConnectionListMobile);
