import React from 'react';
import { flow, get, update } from 'lodash';
import { withBreakpoints } from 'react-breakpoints';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { InlineForm, AdvancedCalculationForm, AdvancedCalculationFormMobile } from '../form';
import { applicationSettingsSelectors } from '../../application';
import createEditFormModal from '../../../shared/modules/details-form-view/component';
import { details as entity } from '../entity';
import { editFormName, attributes as dataPointAttributes } from '../constants';
import * as actionsDataPointList from '../actions';
import { formatPeriodStart, getDataSourcesPoints } from '../utils';
import { attributes as kpiAttributes, operations } from '../../goalmap-milestones/constants';
import DataPointModalEditFormControls from './data-point-modal-edit-form-control';
import '../styles/create.css';
import '../styles/form.css';

const { actions, selector } = entity;

const MilestoneDataPointEdit = (props) => {
    const AdvCalcForm = props.isMobile ? AdvancedCalculationFormMobile : AdvancedCalculationForm;
    const Form = props.advCalcEnabled ? AdvCalcForm : InlineForm;
    return (
        <Form {...props} />
    );
};

MilestoneDataPointEdit.defaultProps = {
    advCalcEnabled: false,
};

MilestoneDataPointEdit.propTypes = {
    advCalcEnabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
};

const formModal = createEditFormModal({
    actions: { ...actions, update: { ...update, request: actionsDataPointList.updateDataPoint, } },
    selector,
    formOptions: {
        form: editFormName,
        enableReinitialize: true,
    },
    ControlsComponent: DataPointModalEditFormControls,
    mapStateToProps(state, ownProps) {
        const currentBranch = selector(state);
        const {
            id, goal_milestone_id, progress_note, period_start, value, period_target,
        } = currentBranch.data;
        const { data, handleDataPointRemove, onDataPointsUpdated } = ownProps;

        const frequency = get(data, kpiAttributes.frequency);
        const advCalcEnabled = get(data, kpiAttributes.advanced_calculation_enabled, false);
        const advCalcSources = get(data, kpiAttributes.advanced_calculation_sources, []);
        const advCalcOperation = get(data, kpiAttributes.advanced_calculation_type) || operations.sum;
        const type = get(data, kpiAttributes.kpi_type);
        const goal_id = get(data, kpiAttributes.goal_id);
        const periodStart = formatPeriodStart(period_start, frequency);

        const formSelector = formValueSelector(editFormName);
        return {
            title: 'Edit Data Point',
            isNeedInitializeForm: true,
            modalContentClassName: advCalcEnabled ? 'modal-data-point-adv-calc' : 'modal-data-point-simple',
            isUpdateDataPoint: true,
            initialValues: {
                [dataPointAttributes.id]: id,
                [dataPointAttributes.goal_milestone_id]: goal_milestone_id,
                [dataPointAttributes.progress_note_content]: get(progress_note, 'content', ''),
                [dataPointAttributes.value]: value,
                [dataPointAttributes.period_start]: periodStart,
                [dataPointAttributes.period_target]: period_target,
                [dataPointAttributes.data_points]: advCalcEnabled
                    ? getDataSourcesPoints(advCalcSources, periodStart)
                    : undefined,
            },
            currentValues: {
                periodStart: formSelector(state, dataPointAttributes.period_start),
                dataPoints: formSelector(state, dataPointAttributes.data_points),
            },
            controlProps: {
                data_point_id: id,
                advCalcEnabled,
                handleDataPointRemove,
            },
            type,
            frequency,
            goal_id,
            advCalcSources,
            advCalcOperation,
            advCalcEnabled,
            handleSuccessSubmit: onDataPointsUpdated,
            isMobile: applicationSettingsSelectors.isMobileSelector(state),
        };
    }
});

export default flow(withBreakpoints, formModal)(MilestoneDataPointEdit);
