import {
    get, difference, last, first, omit, isEqual,
} from 'lodash';
import { sortDates } from '../../../../shared/utils/date';
import {
    attributes, dataSourceAttributes, operations, kpiTypes,
    requiredFromValues, types
} from '../../constants';

export const availableLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export function getDefaultLabel(sources = []) {
    const pos = sources.length;
    const usedLabels = sources.map(source => get(source, dataSourceAttributes.label));
    const result = difference(availableLabels, usedLabels)[pos - usedLabels.length];

    return result;
}

const relatedAttributesByType = Object.freeze({
    [kpiTypes.static.type]: [
        attributes.type,
        attributes.frequency,
        attributes.target_value,
        attributes.required_from,
        attributes.started_at,
        attributes.target_at,
        attributes.currency_format,
    ],
    [kpiTypes.linear.type]: [
        attributes.type,
        attributes.frequency,
        attributes.start_value,
        attributes.target_value,
        attributes.required_from,
        attributes.started_at,
        attributes.target_at,
        attributes.currency_format,
    ],
    [kpiTypes.stepped.type]: [
        attributes.type,
        attributes.frequency,
        attributes.required_from,
        attributes.started_at,
        attributes.target_at,
        attributes.currency_format,
    ],
});

const computedRelatedAttributes = [
    attributes.start_value,
    attributes.target_value,
    attributes.required_from,
    attributes.started_at,
    attributes.target_at,
    attributes.type,
];

const quotientTypeMap = [
    {
        precondition: [types.numeric, types.numeric],
        result: types.numeric,
    },
    {
        precondition: [types.numeric, types.percentage],
        result: types.numeric,
    },
    {
        precondition: [types.numeric, types.monetary],
        result: types.monetary,
    },
    {
        precondition: [types.percentage, types.percentage],
        result: types.percentage,
    },
    {
        precondition: [types.percentage, types.numeric],
        result: types.numeric,
    },
    {
        precondition: [types.percentage, types.monetary],
        result: types.monetary,
    },
    {
        precondition: [types.monetary, types.monetary],
        result: types.numeric,
    },
    {
        precondition: [types.monetary, types.numeric],
        result: types.monetary,
    },
    {
        precondition: [types.monetary, types.percentage],
        result: types.monetary,
    },
];

const total = Object.freeze({
    [operations.sum]: sources => sources.reduce((accum, source = 0) => {
        accum += source;
        return accum;
    }, 0),
    [operations.average]: sources => sources.reduce((accum, source = 0) => {
        accum += source;
        return accum;
    }, 0) / sources.length,
    [operations.quotient]: ([a, b]) => {
        if (a === 0 || b === 0) {
            return 0;
        }

        return a / b;
    },
    [operations.quotientPercentage]: ([a, b]) => {
        if (a === 0 || b === 0) {
            return 0;
        }

        return (a / b) * 100;
    },
    [operations.product]: ([a, b]) => a * b,
    [operations.difference]: ([a, b]) => a - b,
});

function computeTotal(sources, operation) {
    return get(total, operation, operations.sum)(sources);
}

function computeRequiredFrom(sources) {
    if (sources.includes(requiredFromValues.last)) {
        return requiredFromValues.last;
    }

    return requiredFromValues.first;
}

function computeStartedAt(sources) {
    return first(sources.sort(sortDates));
}

function computeTargetAt(sources) {
    return last(sources.sort(sortDates));
}

function computeType(sources, _, parent) {
    const parentOperation = get(parent, attributes.advanced_calculation_type);
    const isQuotient = parentOperation === operations.quotient;
    const isQuotientPercentage = parentOperation === operations.quotientPercentage;

    if (isQuotientPercentage) {
        return types.percentage;
    }

    const defaultValue = sources[0];

    if (isQuotient) {
        const currentRule = quotientTypeMap.find(rule => isEqual(rule.precondition, sources));

        return get(currentRule, 'result', defaultValue);
    }

    return defaultValue;
}

const computeRelatedValueByAttribute = Object.freeze({
    [attributes.start_value]: computeTotal,
    [attributes.target_value]: computeTotal,
    [attributes.required_from]: computeRequiredFrom,
    [attributes.started_at]: computeStartedAt,
    [attributes.target_at]: computeTargetAt,
    [attributes.type]: computeType,
});

function getComputedValue(sources, attribute, operation, parentValue) {
    const arrLength = Math.max(2, sources.length);
    const computeFn = get(computeRelatedValueByAttribute, attribute);

    const values = [...new Array(arrLength)]
        .map((item, index) => {
            return get(sources[index], [
                dataSourceAttributes.related_entity,
                attribute
            ]);
        });

    return computeFn(values, operation, parentValue);
}

export function collectRelatedValues(currentValues) {
    const sources = get(currentValues, attributes.advanced_calculation_sources);
    const operation = get(currentValues, attributes.advanced_calculation_type);
    const kpiType = get(currentValues, attributes.kpi_type);

    const result = {};

    if (!sources.length) return result;

    const source = sources[0];
    const relatedAttributes = get(relatedAttributesByType, kpiType, kpiTypes.static.type);

    relatedAttributes.reduce((accum, item) => {
        const isComputed = computedRelatedAttributes.includes(item);

        if (isComputed) {
            const parent = omit(currentValues, [attributes.advanced_calculation_sources]);
            accum[item] = getComputedValue(sources, item, operation, parent);
        } else {
            accum[item] = get(source, [dataSourceAttributes.related_entity, item]);
        }

        return accum;
    }, result);

    return result;
}

const getDataSourceKpiId = dataSourceList => dataSourceList.find(source => source?.related_entity_id)?.related_entity_id;

const getDataSourceKpi = (dataSourceList = [], kpiList = []) => {
    const dataSourceKpiId = getDataSourceKpiId(dataSourceList);
    return kpiList.find(kpi => kpi.id === dataSourceKpiId);
};

export const getOptListKpiFilteredByAddedDataSource = (kpiList = [], dataSourceList = []) => {
    const dataSourceKpi = getDataSourceKpi(dataSourceList, kpiList);
    if (!dataSourceKpi) return kpiList;
    const filteredKpi = kpiList.filter(kpi => kpi.type === dataSourceKpi.type && kpi.frequency === dataSourceKpi.frequency);
    return (dataSourceKpi.type === types.monetary)
        ? filteredKpi.filter(kpi => kpi.currency_format === dataSourceKpi.currency_format)
        : filteredKpi;
};
