import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputSelect from '../../modules/input-select/component';
import Option from './option';
import OptionEmpty from './option-empty';
import './styles.css';

export default class InputUserSelect extends Component {
    optionRenderer = (data) => {
        const {
            first_name,
            last_name,
            tag_name,
            image,
            unassigned,
        } = data;

        return (unassigned
            ? <OptionEmpty />
            : (
                <Option
                    first_name={first_name}
                    last_name={last_name}
                    tag_name={tag_name}
                    avatar={image}
                />
            )
        );
    }


    render() {
        const { className, ...rest } = this.props;

        return (
            <InputSelect
                className={classnames('input-select-user', {
                    [className]: !!className,
                })}
                optionRenderer={this.optionRenderer}
                {...rest}
            />
        );
    }
}

InputUserSelect.defaultProps = {
    className: undefined,
};

InputUserSelect.propTypes = {
    className: PropTypes.string,
};
