import { createSelector } from 'reselect';
import { reducerName } from './color-theme-constants';

export const selectRoot = state => state[reducerName];

export const persistStateSelector = selectRoot;

export const selectAccentColor = createSelector(selectRoot, state => state.accentColor);

export const selectColorTheme = createSelector(selectRoot, state => state.theme);
