import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StickyContext } from '../../components/sticky/sticky-context';
import { Form, Button, LoadingIndicator } from '../../components';
import {
    ModalFormContainer, ModalFormHeader, ModalFormBody, ModalFormFooter,
} from '../../components/modal-form';
import { get as getCustomProperty } from '../../utils/css-custom-properties';
import { stickyConf } from '../../components/sticky/config';

const DefaultControlsComponent = ({
    // eslint-disable-next-line react/prop-types
    hide, updating, remove, id, hideDeleteButton, resendInvite, toggle, user
}) => {
    return (
        <span>
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    hide();
                }}
                outline
                disabled={updating}
            >
                Cancel
            </Button>
            {' '}
            {
                !hideDeleteButton
                && (
                    <Button
                        styleType="danger"
                        disabled={updating}
                        onClick={(event) => {
                            event.preventDefault();
                            remove({ id });
                        }}
                    >
                        Delete
                    </Button>
                )
            }
            {' '}
            <Button
                styleType="success"
                type="submit"
                loading={updating}
            >
                Save
            </Button>
            {' '}
            {
                user.status === 1
                    ? (
                        <Button
                            styleType="success"
                            onClick={(event) => {
                                event.preventDefault();
                                resendInvite(
                                    {
                                        params: {
                                            id: user.id
                                        }
                                    }
                                );
                                toggle();
                            }}
                        >
                            Resend invite
                        </Button>
                    )
                    : null
            }
        </span>
    );
};

DefaultControlsComponent.defaultProps = {
    user: null,
};

DefaultControlsComponent.propTypes = {
    user: PropTypes.object,
};

export default class ModalFormContentDetailsView extends Component {
    hide = () => {
        const {
            toggle, reset, onHide, onHideEnd
        } = this.props;

        onHide();
        toggle();
        reset();

        setTimeout(() => {
            onHideEnd();
        }, parseFloat(getCustomProperty('--transition-duration')) * 1000);
    }

    handleSuccessSubmit = (data) => {
        const {
            reset, onHide, handleSuccessSubmit, forbidReset
        } = this.props;

        onHide();
        handleSuccessSubmit(data);

        if (!forbidReset) {
            reset();
        }
    }

    render() {
        const {
            id, updating, handleSubmit, onSubmit, reset, toggle, fetching, remove,
            user, resendInvite, controlProps, permissions, title, onHideEnd, dirty,
            modalContentClassName, parseFormSubmitError,
            ControlsComponent, hideDeleteButton, EnhancedComponent, ...rest
        } = this.props;

        return (
            <Form
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                onSuccessSubmit={this.handleSuccessSubmit}
                parseError={parseFormSubmitError}
                dirty={dirty}
                useNavigationBlocking
                ref={(el) => { this.form = el; }}
            >
                <ModalFormContainer>
                    <ModalFormHeader hide={this.hide}>
                        {title}
                    </ModalFormHeader>

                    <ModalFormBody>
                        <StickyContext.Provider value={stickyConf.modal}>
                            {
                                fetching
                                    ? <LoadingIndicator centered />
                                    : (
                                        <EnhancedComponent
                                            {...rest}
                                            toggle={toggle}
                                            submitting={updating}
                                            reset={reset}
                                        />
                                    )
                            }
                        </StickyContext.Provider>
                    </ModalFormBody>

                    <ModalFormFooter>
                        {
                            ControlsComponent
                                ? (
                                    <ControlsComponent
                                        {...controlProps}
                                        submitting={updating}
                                        toggle={this.hide}
                                        reset={reset}
                                        remove={remove}
                                        id={id}
                                        resendInvite={resendInvite}
                                        user={user}
                                        permissions={permissions}
                                        change={this.props.change}
                                        form={this.form}
                                    />
                                )
                                : (
                                    <DefaultControlsComponent
                                        hide={this.hide}
                                        updating={updating}
                                        remove={remove}
                                        id={id}
                                        hideDeleteButton={hideDeleteButton}
                                        resendInvite={resendInvite}
                                        toggle={toggle}
                                        user={user}
                                    />
                                )
                        }
                    </ModalFormFooter>
                </ModalFormContainer>
            </Form>
        );
    }
}

ModalFormContentDetailsView.defaultProps = {
    title: undefined,
    onHide() { },
    onHideEnd() { },
    parseFormSubmitError: error => error,
    user: null,
    id: undefined,
    permissions: {},
    controlProps: {},
    resendInvite: undefined,
    handleSuccessSubmit() {},
    modalContentClassName: undefined,
    forbidReset: false,
    ControlsComponent: null,
    hideDeleteButton: false,
    handleSubmit() {},
    reset() {},
};

ModalFormContentDetailsView.propTypes = {
    ControlsComponent: PropTypes.func,
    controlProps: PropTypes.object,
    dirty: PropTypes.bool.isRequired,
    EnhancedComponent: PropTypes.object.isRequired,
    forbidReset: PropTypes.bool,
    fetching: PropTypes.bool.isRequired,
    handleSuccessSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    hideDeleteButton: PropTypes.bool,
    id: PropTypes.number,
    modalContentClassName: PropTypes.string,
    onHide: PropTypes.func,
    onHideEnd: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    parseFormSubmitError: PropTypes.func,
    permissions: PropTypes.object,
    resendInvite: PropTypes.func,
    remove: PropTypes.func.isRequired,
    reset: PropTypes.func,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string,
    updating: PropTypes.bool.isRequired,
    user: PropTypes.object,
    change: PropTypes.func.isRequired,
};
