import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { pick, get } from 'lodash';
import { reduxForm, SubmissionError } from 'redux-form';
import { noop } from '../../shared/utils/common';
import { Form } from '../../shared/components';

const errorObjectGetter = error => get(error, ['response', 'data', 'errors'], get(error, ['data', 'message']));
class EntityFormWizardProvider extends Component {
    constructor(props) {
        super(props);

        this.formRef = createRef();
        this.state = {
            redirectPending: false
        };
    }

    componentDidMount() {
        const { entityId, fetch, dispatch } = this.props;

        if (entityId) {
            dispatch(fetch({ id: entityId }));
        }
    }

    componentDidUpdate(prevProps) {
        const { entityId, fetch, dispatch } = this.props;

        if ((!prevProps.entityId && entityId) || (prevProps.entityId !== entityId)) {
            dispatch(fetch({ id: entityId }));
        }
    }

    get formProps() {
        const {
            children, pageComponents, page, ...formProps
        } = this.props;

        return formProps;
    }

    toPrevPage = () => {
        const { onChangePage, page } = this.props;

        onChangePage(page - 1, this.formProps);
    };

    toNextPage = () => {
        const { onChangePage, page, checkValidityOnPageChange } = this.props;

        if (checkValidityOnPageChange && !this.isFormValid()) {
            return;
        }

        onChangePage(page + 1, this.formProps);
    };

    isFormValid = () => {
        return this.formRef.current.reportValidity();
    };

    cancelRedirectPending = () => {
        this.setState({ redirectPending: false });
    };

    handleSubmitError = (error) => {
        this.setState({ redirectPending: false });
        const message = errorObjectGetter(error);
        if (message) {
            const errorEl = document.querySelector(`input[name="${Object.keys(message)[0]}"]`);
            if (errorEl) errorEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            throw new SubmissionError(message);
        }
    }

    handleSubmit = (data) => {
        const {
            currentValues, create, update, updateTags, dispatch
        } = this.props;

        const params = {
            ...currentValues,
            ...data
        };

        const submitPromise = new Promise((resolve, reject) => {
            if (params.id) {
                if (updateTags) {
                    dispatch(updateTags({ params }));
                }
                return dispatch(update({ params, resolve, reject }));
            }

            return dispatch(create({ params, resolve, reject }));
        });

        return submitPromise
            .catch(this.handleSubmitError);
    };

    submit = (data = {}) => {
        const { handleSubmit } = this.props;
        this.setState({ redirectPending: true });
        const submit = handleSubmit(this.handleSubmit.bind(this, data));

        submit().then(this.cancelRedirectPending, this.cancelRedirectPending);
    }

    onSubmit = ({ params, resolve, reject }) => {
        const {
            update, create, updateTags, dispatch, currentValues
        } = this.props;

        if (currentValues.id) {
            if (updateTags) {
                dispatch(updateTags({ param: currentValues }));
            }

            // using `currentValues` to prevent an issue
            // https://github.com/redux-form/redux-form/issues/3032
            return dispatch(update({
                params: currentValues,
                resolve,
                reject,
            }));
        }

        return dispatch(create({ params, resolve, reject }));
    }

    triggerSubmit = () => {
        this.formRef.current.submit();
    }

    render() {
        const {
            children, pageComponents, page, removing, dirty, submitting, handleSubmit, onSubmitSuccess, change, onErrorSubmit,
        } = this.props;

        const { redirectPending } = this.state;

        return (
            <Form
                ref={this.formRef}
                onSubmit={this.onSubmit}
                handleSubmit={handleSubmit}
                onSuccessSubmit={onSubmitSuccess}
                dirty={dirty && !removing && !redirectPending}
                submitting={submitting}
                onErrorSubmit={onErrorSubmit}
                useNavigationBlocking
            >
                {children({
                    ...pick(this.props, ['removing', 'updating', 'submitting', 'fetching']),
                    toNextPage: this.toNextPage,
                    toPrevPage: this.toPrevPage,
                    submit: this.submit,
                    triggerSubmit: this.triggerSubmit,
                    changeField: change,
                    page,
                    Content: pageComponents[page - 1],
                    formProps: this.formProps,
                })}
            </Form>
        );
    }
}

EntityFormWizardProvider.defaultProps = {
    pageComponents: [],
    onSubmitSuccess: noop,
    onErrorSubmit: noop,
    fetch: noop,
    update: noop,
    create: noop,
    updateTags: noop,
    checkValidityOnPageChange: false,
    removing: false,
    entityId: undefined
};

EntityFormWizardProvider.propTypes = {
    children: PropTypes.func.isRequired,
    pageComponents: PropTypes.array,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    onSubmitSuccess: PropTypes.func,
    onErrorSubmit: PropTypes.func,
    removing: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    currentValues: PropTypes.object.isRequired,
    entityId: PropTypes.number,
    fetch: PropTypes.func,
    update: PropTypes.func,
    create: PropTypes.func,
    updateTags: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    checkValidityOnPageChange: PropTypes.bool,
};

export function initEntityFormWizardProvider(formName) {
    return reduxForm({
        form: formName,
        enableReinitialize: true
    })(EntityFormWizardProvider);
}
