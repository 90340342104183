import { createStore, applyMiddleware, compose } from 'redux';
import persistState from 'redux-localstorage';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { promiseMiddleware } from '@adobe/redux-saga-promise';

import reset from '../middleware/reset/middleware';
import dynamicStore from '../middleware/dynamic-store/middleware';

import rootSaga from './saga-middleware';
import { slicer, localStoragePath } from './store-localstorage-config';
import createReducer from './create-reducer';

export const sagaMiddleware = createSagaMiddleware();

const createStoreWithMiddleware = compose(
    applyMiddleware(promiseMiddleware),
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware(browserHistory)),
    applyMiddleware(dynamicStore(createReducer)),
    reset(),
    persistState([], {
        key: localStoragePath,
        slicer
    }),
)(createStore);

const store = createStoreWithMiddleware(createReducer());

sagaMiddleware.run(rootSaga);

export default store;
