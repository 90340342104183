import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const sharedPropTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

const sharedDefaultProps = {
    className: undefined,
    children: undefined,
};

export function WidgetSection({ className, children, ...rest }) {
    return (
        <div
            className={classnames('auth-widget-section', {
                [className]: !!className,
            })}
            {...rest}
        >
            {children}
        </div>
    );
}

export function WidgetTitle({ className, children, ...rest }) {
    return (
        <h1
            className={classnames('auth-widget-title', {
                [className]: !!className,
            })}
            {...rest}
        >
            {children}
        </h1>
    );
}

export function WidgetRow({ className, children, ...rest }) {
    return (
        <div
            className={classnames('auth-widget-row', {
                [className]: !!className,
            })}
            {...rest}
        >
            {children}
        </div>
    );
}

WidgetSection.defaultProps = sharedDefaultProps;
WidgetSection.propTypes = sharedPropTypes;

WidgetTitle.defaultProps = sharedDefaultProps;
WidgetTitle.propTypes = sharedPropTypes;

WidgetRow.defaultProps = sharedDefaultProps;
WidgetRow.propTypes = sharedPropTypes;
