import { createAction } from 'redux-act';

const prefix = 'application';

export const setAccess = createAction(`${prefix}/SET_DEFAULT_ACCESS_RIGHTS`);
export const setBreakpoint = createAction(`${prefix}/SET_BREAKPOINT`);

export const persistListSortType = createAction(`${prefix}/PERSIST_LIST_SORT_TYPE`);
export const persistListViewType = createAction(`${prefix}/PERSIST_LIST_VIEW_TYPE`);

export const saveRedirectPath = createAction(`${prefix}/SAVE_REDIRECT_PATH`);
export const resetRedirectPath = createAction(`${prefix}/RESET_REDIRECT_PATH`);
export const setPlanPageType = createAction(`${prefix}/SET_PLAN_PAGE_TYPE`);

export const persistPlanTreeNavSortType = createAction(`${prefix}/PERSIST_PLAN_TREE_NAV_SORT_TYPE`);
