import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import {
    Avatar,
    Icon,
    createInlineFieldForm,
    Text,
    Clickable
} from '../../components';
import OwnerSelect from '../owner-select/component';
import './styles.css';

export default class DetailsOwnerSection extends Component {
    constructor(props) {
        super(props);

        this.FormComponent = createInlineFieldForm({
            formName: `entity-owner-edit-${props.entity_id}`,
            enableReinitialize: props.enableReinitialize,
        });

        this.state = {
            editing: false,
        };
    }

    handleEditingStart = () => {
        this.setState({
            editing: true,
        });
    }

    handleCancelEdit = () => {
        this.setState({
            editing: false,
        });
    }

    render() {
        const {
            owner,
            goal_id,
            initialValues,
            onSubmit,
            editable,
            onSuccessSubmit,
            onErrorSubmit,
            isCancelEditOnVersionValidCheck,
        } = this.props;

        const { editing } = this.state;
        const { FormComponent } = this;

        return (
            <div className={classnames('details-owner-section-container', { 'is-form-editing': editing })}>
                <div className="details-owner-container">
                    {!editing && !!owner && (
                        <Fragment>
                            <Avatar
                                src={owner.user_profile.image}
                                alt="Owner Avatar"
                                className="owner-avatar"
                                first_name={owner.user_profile.first_name}
                                last_name={owner.user_profile.last_name}
                            />
                            { editable && !editing ? (
                                <Clickable
                                    className="owner-clickable-container"
                                    action="edit-owner"
                                    onClick={this.handleEditingStart}
                                >
                                    <Text bold className="owner-info-name">
                                        {owner.user_profile.first_name} {owner.user_profile.last_name}
                                    </Text>
                                    <Icon name="angle-down" type="regular" className="owner-edit-icon" />
                                </Clickable>
                            ) : (
                                <Text bold className="owner-info-name">
                                    {owner.user_profile.first_name} {owner.user_profile.last_name}
                                </Text>
                            )}
                        </Fragment>
                    )}
                    {!editing && !owner && (
                        <Fragment>
                            <Icon
                                name="user-circle"
                                type="solid"
                                className="owner-info-icon-unassigned"
                            />
                            { editable && !editing ? (
                                <Clickable
                                    className="owner-clickable-container"
                                    action="edit-owner"
                                    onClick={this.handleEditingStart}
                                >
                                    <Text bold className="owner-info-name">
                                        Unassigned
                                    </Text>
                                    <Icon name="angle-down" type="regular" className="owner-edit-icon" />
                                </Clickable>
                            ) : (
                                <Text bold className="owner-info-name">
                                    Unassigned
                                </Text>
                            )}
                        </Fragment>
                    )}
                </div>
                {editing && (
                    <FormComponent
                        containerClassName="owner-info-form"
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        onCancel={this.handleCancelEdit}
                        onSuccessSubmit={onSuccessSubmit}
                        onErrorSubmit={onErrorSubmit}
                        onSubmitSuccess={isCancelEditOnVersionValidCheck ? this.handleCancelEdit : () => {}}
                    >
                        <OwnerSelect
                            name="owner_id"
                            id="entity_owner_id"
                            goalId={goal_id}
                            required
                        />
                    </FormComponent>
                )}
            </div>
        );
    }
}

DetailsOwnerSection.defaultProps = {
    editable: false,
    owner: null,
    onSuccessSubmit: undefined,
    onErrorSubmit: undefined,
    entity_id: undefined,
    enableReinitialize: false,
    isCancelEditOnVersionValidCheck: false,
};

DetailsOwnerSection.propTypes = {
    entity_id: PropTypes.number,
    owner: PropTypes.shape({
        avatar: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        tag_name: PropTypes.string,
        user_profile: PropTypes.object,
    }),
    editable: PropTypes.bool,
    goal_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    initialValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onSubmit: PropTypes.func.isRequired,
    onSuccessSubmit: PropTypes.func,
    onErrorSubmit: PropTypes.func,
    enableReinitialize: PropTypes.bool,
    isCancelEditOnVersionValidCheck: PropTypes.bool,
};
