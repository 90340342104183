import { get } from 'lodash';
import { states } from '../goalmap-list/config';
import { integrationAttributeBySlug, integrationAttributes, attributes } from './config';

function getIntegrationObject(data, slug) {
    const integrationAttribute = get(integrationAttributeBySlug, slug);
    return get(data, integrationAttribute);
}

export function isIntegrationConfigured(data, slug) {
    const integrationObject = getIntegrationObject(data, slug);

    return !!get(integrationObject, attributes.url);
}

export function isIntegrationActivated(data, slug) {
    const integrationObject = getIntegrationObject(data, slug);

    return get(integrationObject, integrationAttributes.isActive);
}

export function isArchived(data) {
    return data.state === states.archived;
}

export function isActiveIntegration(payload) {
    return payload?.response?.is_active || payload.is_active;
}
