import {
    useState, useMemo, useCallback, useContext
} from 'react';
import queryString from 'query-string';
import { replace } from 'react-router-redux';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentLocation } from '../../../../shared/selectors/routing';
import { getFormattedPeriod } from '../../../../shared/utils/date';
import { removeParamFromUrl, updateGetParameter } from '../../../../shared/utils/url';
import { AppContext } from '../../../app-context/component';
import Permissions from '../../../permissions';
import { entitySections } from '../../../permissions/constants';
import { progressNoteModel } from '../progress-note-models';

const { selectResolvedEntityPermissions } = Permissions.selectors;

const initialState = {
    promptVisible: false,
    promptRequestParams: null,
    milestoneDataPoint: null,
};

const promptNotFoundInitialState = {
    visible: false,
    title: 'Not Found',
    description: 'The note might have been deleted.',
    buttonText: 'Got It!'
};

export const ProgressNoteIdKey = 'progress_note_id';

export const updateUrlToShowProgressNoteDetailsModal = (location, historyReplace, progressNoteId) => {
    const { pathname, search } = location;
    const url = updateGetParameter(`${pathname}${search}`, ProgressNoteIdKey, progressNoteId);

    historyReplace(url);
};

const useProgressNoteDetailsModalBinder = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [promptNotFoundState, promptNotFoundStateSetState] = useState(promptNotFoundInitialState);

    const {
        deleteProgressNoteSuccessCallback,
        addDraftItemThatNeedDelete,
        updateProgressNoteSuccessCallback,
    } = useContext(AppContext);

    const location = useSelector(selectCurrentLocation);
    const detailsBranch = useSelector(mainState => progressNoteModel.selector(mainState));
    const goalId = detailsBranch.data.goal_id;

    const permissions = useSelector(
        mainState => selectResolvedEntityPermissions(mainState, goalId, entitySections.note, detailsBranch.data)
    );

    const showDetailsModal = progressNoteModel.actions.toggleModal;
    const remove = progressNoteModel.actions.remove.request;
    const { reset } = progressNoteModel.actions;

    const boundPopupId = useMemo(() => {
        const search = queryString.parse(location?.search);
        if (search && search[ProgressNoteIdKey]) {
            return search[ProgressNoteIdKey];
        }
        return null;
    }, [location]);

    const promptDescription = useMemo(() => {
        const { milestoneDataPoint } = state;
        return milestoneDataPoint
            ? `This progress note is associated with data point ${milestoneDataPoint}.`
            : 'You are about to delete this Progress Note.';
    }, [state]);

    const promptVisible = useMemo(() => state.promptVisible, [state]);

    const handleSuccessSubmit = useCallback((data) => {
        updateProgressNoteSuccessCallback(data);
    }, [updateProgressNoteSuccessCallback]);

    const handleRemoveStart = useCallback((params, milestoneDataPoint, milestoneFrequency) => {
        setState(prevState => ({
            ...prevState,
            promptVisible: true,
            promptRequestParams: params,
            milestoneDataPoint: milestoneDataPoint && getFormattedPeriod(
                milestoneFrequency,
                milestoneDataPoint.period_start,
            ),
        }));
    }, []);

    const handleRemoveCancel = useCallback(() => {
        setState(initialState);
    }, []);

    const handleRemoveApply = useCallback(() => {
        const { promptRequestParams } = state;
        setState(initialState);
        dispatch(remove({
            ...promptRequestParams,
            onSuccess(data) {
                deleteProgressNoteSuccessCallback(data);
                addDraftItemThatNeedDelete(data);
            }
        }));
    }, [dispatch, state, deleteProgressNoteSuccessCallback]);

    const onShowDetailModal = useCallback((id) => {
        dispatch(showDetailsModal({ id }));
    }, [dispatch]);

    const handleUrlDataValueParsed = useCallback((id) => {
        if (!detailsBranch.modalVisible) onShowDetailModal(id);
    }, [dispatch, detailsBranch, showDetailsModal]);

    const handlePopupClose = useCallback(() => {
        const url = `${location.pathname}${location.search}`;
        const newUrl = removeParamFromUrl(url, ProgressNoteIdKey);

        dispatch(replace(newUrl));
        dispatch(reset());
    }, [dispatch, location]);

    const togglePromptNotFound = useCallback(() => {
        promptNotFoundStateSetState(prevState => ({
            ...prevState,
            visible: !prevState.visible,
        }));
    }, []);

    const handleFetchError = useCallback((response) => {
        const code = response?.data?.code;
        if (code && code === 404) {
            togglePromptNotFound();
        }
    }, [togglePromptNotFound]);


    return {
        ProgressNoteIdKey,
        boundPopupId,
        permissions,
        promptVisible,
        promptDescription,
        promptNotFoundState,
        handleSuccessSubmit,
        handleRemoveStart,
        handleUrlDataValueParsed,
        handleRemoveCancel,
        handleRemoveApply,
        handlePopupClose,
        handleFetchError,
        togglePromptNotFound,
    };
};

export default useProgressNoteDetailsModalBinder;
