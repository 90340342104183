import { takeEvery, put, select } from 'redux-saga/effects';
import { applicationSettingsSelectors } from '../application';
import { authenticate } from '../authentication/actions';
import { selectUserId } from '../user/selectors';
import { notificationsHeaderCollection } from '../navigation-header/notifications/entity';

function* handleAppStart() {
    const isMobile = yield select(applicationSettingsSelectors.isMobileSelector);
    const userId = yield select(selectUserId);

    if (isMobile) {
        yield put({
            type: notificationsHeaderCollection.actions.fetch.request.getType(),
            payload: {
                data: {
                    user_id: userId,
                    order_by: 'is_read,desc|created_at,desc',
                    page: 1,
                },
            }
        });
    }
}

export default function* mobileNotificationSaga() {
    yield takeEvery(authenticate.getType(), handleAppStart);
}
