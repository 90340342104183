import { isMobile, isTablet } from 'mobile-device-detect';
import { logWarning } from './logger';

const isServiceWorkerAvailable = () =>
    (('serviceWorker' in navigator) && !!navigator.serviceWorker.controller);

export function getAppId(appSettingsResponse) {
    if (process.env.NODE_ENV === 'production') {
        return appSettingsResponse.onesignal_app_id;
    }

    return 'a1300df9-d35a-41c3-9a56-354efafe217b';
}

export function callOneSignalAPI(cb) {
    const OneSignal = window.OneSignal || [];
    OneSignal.push(() => cb(OneSignal));
}

callOneSignalAPI(OneSignal => OneSignal.log.setLevel('trace'));

function callIneSignalAsyncAPI(cb) {
    if (process.env.NODE_ENV === 'production') {
        return new Promise((resolve, reject) => {
            callOneSignalAPI((OneSignal) => {
                cb(OneSignal, resolve, reject);
            });
        });
    }

    return Promise.reject();
}

function getRegistrationState() {
    return callIneSignalAsyncAPI((OneSignal, resolve) => {
        OneSignal.isPushNotificationsEnabled((isEnabled) => {
            resolve(isEnabled);
        });
    });
}

export function getUserId() {
    return callIneSignalAsyncAPI((OneSignal, resolve, reject) => {
        OneSignal.getUserId().then(resolve, reject).catch(reject);
    });
}

export function unregisterPushClient() {
    return callIneSignalAsyncAPI((OneSignal, resolve, reject) => {
        OneSignal.removeExternalUserId().then(resolve, reject);
    });
}

export function logRegistrationState() {
    getRegistrationState().then((isEnabled) => {
        logWarning('[OneSignal]: registration enabled', isEnabled); // eslint-disable-line no-console
    });
}

export function registerOnBeforeUnload({ token, baseUrl }) {
    if (isMobile || isTablet || !isServiceWorkerAvailable()) {
        return;
    }

    getUserId().then((deviceId) => {
        logWarning('[Push]: Device ID registration attempt', deviceId, token, baseUrl);

        if (!deviceId) {
            logWarning('[Push]: No Device ID, registration will be skipped');
        }

        navigator.serviceWorker.controller.postMessage({
            action: 'push-subscribe',
            apiUrl: `${baseUrl}/web_notifications/devices/${deviceId}`,
            token,
        });
    });
}
