import { debounce } from 'lodash';

const normalize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const debouncedNormalize = debounce(normalize, 150);

export const normalizeVhUnit = () => {
    normalize();

    window.addEventListener('resize', debouncedNormalize);
};
