import React from 'react';
import BodyScrollLock from './component';

export default function withBodyScrollLock(EnhancedComponent) {
    return function (props) {
        return (
            <BodyScrollLock>
                <EnhancedComponent
                    {...props}
                />
            </BodyScrollLock>
        );
    };
}
