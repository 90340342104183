import PropTypes from 'prop-types';
import React from 'react';
import {
    HTMLRenderer,
    Clickable,
    Avatar,
    Image,
    MetaInfo,
    Icon,
    Text
} from '../../../../shared/components';
import { LinkDownloadSecure } from '../../../../shared/modules';
import { tagNameEntityType, attribute } from '../../../../shared/components/editor/constants';

import { getAttachmentStorageIcon } from '../../../secondary-entities/attachment';

import './progress-note-list-item.css';

export default function ProgressNoteListItem({
    id, content, attachments, author, updated_at, showDetailsModal, is_auto_creation,
}) {
    return (
        <div className="progress-note-item">
            <div className="avatar-container">
                <Avatar
                    src={author.user_profile.image}
                    first_name={author.user_profile.first_name}
                    last_name={author.user_profile.last_name}
                />
                <Text overflowEllipsis className="note-item-avatar-full-name">{author.user_profile.full_name}</Text>
                {is_auto_creation && <Icon className="note-item-icon" name="broadcast-tower" /> }
            </div>
            <div className="note-content">
                <div className="progress-note-description">
                    <Clickable
                        href="#"
                        action="Edit Progress Note"
                        onClick={(event) => {
                            if (
                                event.target.dataset[attribute.TAGNAME_ENTITY_TYPE]
                                === tagNameEntityType.USER_PROFILE
                            ) {
                                return false;
                            }

                            // we dont need to open the popup if user is clicking the link
                            if (event.target.tagName === 'A') {
                                event.preventDefault();
                                window.open(event.target.href);
                            } else {
                                showDetailsModal({ id });
                            }

                            return false;
                        }}
                    >
                        <HTMLRenderer>
                            {content}
                        </HTMLRenderer>
                    </Clickable>
                </div>

                <div className="progress-note-attachments">
                    {attachments.map(item => (
                        <LinkDownloadSecure
                            className="attachment-item"
                            url={`/attachments/${item.pivot.id}`}
                            name={item.pivot.user_filename}
                            key={item.id}
                            downloadIconEnabled={false}
                        >
                            <div className="attachment-item-storage-container">
                                <Image
                                    src={getAttachmentStorageIcon(item.storage_type)}
                                    alt="storage-icon"
                                    className="attachment-item-storage-icon"
                                />
                                {item.pivot.user_filename}
                            </div>
                            <Text
                                component={Icon}
                                name="arrow-to-bottom"
                                type={Icon.types.regular}
                                styleType="muted"
                                size="medium-large"
                            />
                        </LinkDownloadSecure>
                    ))}
                </div>

                <div className="progress-note-meta">
                    <MetaInfo
                        date={updated_at}
                    />
                </div>
            </div>
        </div>
    );
}

ProgressNoteListItem.defaultProps = {
    attachments: [],
};

ProgressNoteListItem.propTypes = {
    content: PropTypes.string.isRequired,
    attachments: PropTypes.array,
    author: PropTypes.shape({
        user_profile: PropTypes.object,
        id: PropTypes.number,
    }).isRequired,
    updated_at: PropTypes.string.isRequired,
    showDetailsModal: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    is_auto_creation: PropTypes.bool.isRequired,
};
