import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../../../../../shared/components/icon/component';
import Clickable from '../../../../../shared/components/clickable/component';
import Text from '../../../../../shared/components/text';
import Image from '../../../../../shared/components/image/component';
import './attachment-file-picker-item.css';

const types = {
    file: 'file',
    dir: 'directory',
};

const icons = {
    [types.file]: 'doc',
    [types.dir]: 'folder',
};

export default function FilePickerItem({
    icon_link, icon, title, selected, onClick
}) {
    return (
        <Clickable
            className={classnames('file-picker-item', {
                'selected': selected,
            })}
            onClick={onClick}
        >
            {!!icon_link && (
                <div className="file-picker-item-icon">
                    <Image
                        src={icon_link}
                        className="file-picker-item-icon-link"
                    />
                </div>
            )}

            {!icon_link && (
                <Icon name={icon} className="file-picker-item-icon" />
            )}

            <Text
                size="small"
                title={title}
                className="file-picker-item-title"
                styleType={classnames({
                    dark: !selected,
                    accent: selected,
                })}
            >
                {title}
            </Text>
        </Clickable>
    );
}

FilePickerItem.types = types;
FilePickerItem.icons = icons;

FilePickerItem.defaultProps = {
    icon: icons[types.file],
    selected: false,
    icon_link: undefined,
};

FilePickerItem.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    icon_link: PropTypes.string,
};
