import { isEmptyEditor } from '../../../shared/components/editor/utils';

const errorFieldRequired = 'Please enter text into this field';

export const required = (value) => {
    const errors = [errorFieldRequired];
    return (!value || isEmptyEditor(value)) ? errors : undefined;
};

export const messages = {
    update: 'Progress Note has been saved',
    delete: 'Progress Note has been deleted',
    publish: 'Progress Note has been published'
};
