import u from 'updeep';
import request from '../../request';
import {
    List, createModal, updateModal, createDetails
} from '../../shared/entities';
import { list as childrenList } from '../../modules/goalmap-segment-children/entity';
import { states } from '../goalmap-list/config';
import { getEntitiesByType } from '../../shared/utils/content-editor-entity-selector';
import { urlTypes } from '../../shared/utils/entity-type';
import { entityState } from '../../shared/utils/entity';

const listName = 'segment-list';

const apiUrl = '/segments';

export const list = new List({
    apiUrl,
    name: listName
});

export const create = createModal({
    apiUrl,
    name: 'segment-create',
    extendAPI: {
        create({ params }) {
            return request.post(apiUrl, { state: entityState.active, ...params });
        }
    }
});

export const copy = createModal({
    apiUrl,
    name: 'segment-copy',
    extendAPI: {
        create({ params }) {
            return request.post(apiUrl, { state: entityState.active, ...params });
        }
    }
});


export const createChildren = createModal({
    name: 'segment-add-children',
    updateHTML: true,
    extendAPI: {
        create: ({ params }) => {
            const { entity_type, ...rest } = params;

            const mentions = getEntitiesByType(rest.description, 'mention');
            const data = {
                ...rest,
                mentions,
            };

            return request.post(`/${urlTypes[entity_type]}`, data);
        }
    }
});

export const update = updateModal({
    apiUrl,
    name: 'segment-update',
    onSuccess: list.actions.update,
});

export const details = createDetails({
    apiUrl,
    name: 'segment-details',
    updateHTML: true,
    onErrorUpdateStatus: createDetails.errorUpdateStatusNotification,
    messages: {
        error: 'To activate this entity you should first activate the item on the higher level'
    },
    extraActions: {
        [childrenList.actions.add](state, action) {
            let result = state;

            if (action.state !== states.draft) {
                result = u.updateIn(
                    ['data', 'health_manually'],
                    false,
                    state,
                );
            }

            const parent = action.parent || action.segment;

            if (parent && parent.progress_status && action.state !== states.draft) {
                result = u.updateIn(
                    ['data', 'progress_status'],
                    parent.progress_status,
                    result,
                );
            }

            return result;
        },
    },
    format(params) {
        return u({
            parent_id: params.parent_id !== 0
                ? params.parent_id
                : undefined,
        }, params);
    }
});
