import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import createModalWizard from '../../wizard-entity/wizard-modal';
import { parse } from '../../goalmap-milestones/create/utils';
import { getDetailsTitles } from '../../goalmap-list/selector';
import { subscriptionLimitSelectors } from '../../subscription';
import { create as createTacticChildrenModel, details as tacticChildrenModel } from '../entity';
import { createSelectAvailableEntities } from '../create-wizard/selectors';
import { getScreensByType, getInitialValuesSelectorByType } from '../create-wizard/utils';
import { getFirstAvailableEntity } from '../../goalmap-segment-children/create-wizard/utils';
import * as actions from './actions';
import WizardForm from './wizard';

const formName = 'tactic-children-wizard-form-create';

const TacticChildrenModalWizard = createModalWizard({
    name: formName,
    removeOnCancel: true,
    actions: {
        create: actions.createChild,
        update: actions.createChild,
        updateTags: tacticChildrenModel.actions.updateTags.request,
        remove: actions.removeChild,
        fetch: tacticChildrenModel.actions.request,
        toggle: createTacticChildrenModel.actions.toggle,
        keepModalVisible: createTacticChildrenModel.actions.setKeepModalVisible,
    },
    selectors: {
        selectModalVisible: state => createTacticChildrenModel.selector(state).modalVisible,
        selectFetchingState: state => tacticChildrenModel.selector(state).loading,
        selectAttributes: state => tacticChildrenModel.selector(state).data,
        titleSelector: (titles, type) => get(titles, type, ''),
    },
    parseData: parse,
})(WizardForm);

const TacticChildrenModalWizardRenderer = props => <TacticChildrenModalWizard {...props} disableAdvancedCalcKPI={false} />;

const formSelector = formValueSelector(formName);
const selectFormValues = getFormValues(formName);

function mapStateToProps(state, ownProps) {
    const availableEntitiesSelector = createSelectAvailableEntities(ownProps.parentEntityData);
    const availableEntities = availableEntitiesSelector(state, ownProps.goalId);
    const titles = getDetailsTitles(state);
    const selectedType = formSelector(state, 'entity_type');
    const currentValues = selectFormValues(state);
    const steps = getScreensByType(selectedType, titles);
    const canCreateDraft = subscriptionLimitSelectors.canCreateDraft(state);
    const initialValuesSelector = getInitialValuesSelectorByType(selectedType);
    const initialValues = initialValuesSelector(state, ownProps.parentEntityData, canCreateDraft);
    initialValues.entity_type = selectedType || getFirstAvailableEntity(availableEntities);

    return {
        initialValues,
        availableEntities,
        steps,
        currentValues,
    };
}

export default connect(mapStateToProps)(TacticChildrenModalWizardRenderer);
