import { put, takeEvery, select } from 'redux-saga/effects';
import { commitFacets } from './actions';
import list from './entity';

function* commitFacetsState({ payload }) {
    const emptyFacets = yield select(state => list.selector(state).emptyFacets);

    if (emptyFacets) return;

    yield put({
        type: commitFacets.getType(),
        payload: payload.facets
    });
}

export default function* saga() {
    yield takeEvery(list.actions.success.getType(), commitFacetsState);
}
