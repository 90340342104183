export const attribute = Object.freeze({
    ID: 'id',
    NAME: 'name',
    TITLE: 'title',
    GOAL_ID: 'goal_id',
    TAG_NAME: 'tag_name',
    TAGNAME_ENTITY: 'tagname_entity',
    TAGNAME_ENTITY_ID: 'tagname_entity_id',
    TAGNAME_ENTITY_TYPE: 'tagname_entity_type'
});

export const tagNameEntityType = Object.freeze({
    USER_PROFILE: 'user_profile',
});

export const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'mention'
    // 'image'
    // 'video'
];
