import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col, Editor, Row, } from '../../../shared/components';
import { CategorizationTagSelector, OwnerSelect, TitleTagnameFields } from '../../../shared/modules';
import styles from './styles.module.css';

export default function SegmentForm({
    change, goalId, titles,
}) {
    return (
        <div className={styles.segmentCreateForm}>
            <TitleTagnameFields
                change={change}
                goalId={goalId}
                titleFieldLayoutRenderer={children => (
                    <Row>
                        <Col xs={12} md={8}>
                            {children}
                        </Col>
                        <Col xs={12} md={4}>
                            <OwnerSelect
                                id="subsegment-select-owner"
                                label="Owner"
                                goalId={goalId}
                                required
                            />
                        </Col>
                    </Row>
                )}
                titleFieldProps={{
                    autoFocus: true,
                    label: 'Name',
                    placeholder: `Name your ${titles.segment}`
                }}
                tagnameFieldProps={{
                    label: 'Tag Name',
                    placeholder: `Type in Tag Name for your ${titles.segment}`
                }}
            />

            <Field
                name="description"
                label="Target State"
                placeholder={`Type in ${titles.segment} Target State`}
                component={Editor}
                goal_id={goalId}
                controlsHidden
            />

            <Field
                name="tags"
                label="Tags"
                placeholder="Select tags"
                id="segment-tags-select"
                component={CategorizationTagSelector}
                goal_id={goalId}
            />
        </div>
    );
}

SegmentForm.propTypes = {
    change: PropTypes.func.isRequired,
    goalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    titles: PropTypes.shape({
        segment: PropTypes.string,
    }).isRequired,
};
