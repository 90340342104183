export const GETParams = Object.freeze({
    issuer: 'issuer',
    state: 'state',
    redirectUri: 'redirect_uri',
    responseType: 'response_type',
    clientId: 'client_id',
});

export const requiredGETParams = Object.freeze({
    state: 'state',
    redirectUri: 'redirect_uri'
});
