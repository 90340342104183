import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { InputPassword, Icon, Text } from '../../../../shared/components';
import passwordPolicyCollection from './password-policy-collection';
import {
    validationTests, commonGroup, specialGroup, specialGroupParent, getSpecialGroupMessage, validateSpecialGroupParent,
    getRuleByName, specialGroupLabels, isSpecialRuleExists,
} from './config';
import './styles.css';

class PasswordWithPolicy extends Component {
    componentDidMount() {
        const { fetchPolicies } = this.props;

        fetchPolicies();
    }

    renderRule = (rule, isValid, isSpecialGroup) => {
        if (!rule) {
            return null;
        }

        return (
            <li key={rule.name} className="password-policy-rules-item">
                <Text
                    component={Icon}
                    name={classnames({
                        'check-circle': isValid,
                        'dot-circle': isSpecialGroup && !isValid,
                        'times-circle': !isSpecialGroup && !isValid,
                    })}
                    styleType={classnames({
                        'success': isValid,
                        'error': !isSpecialGroup && !isValid,
                    })}
                    className="password-policy-rules-item-icon"
                    type={Icon.types.solid}
                />
                <Text>{rule.message}</Text>
            </li>
        );
    }

    renderCommonRule = (name) => {
        const { rules, input } = this.props;
        const { value } = input;
        const rule = getRuleByName(rules, name);
        const isValid = validationTests[name](value, rule);

        return this.renderRule(rule, isValid);
    }

    renderSpecialRule = (name) => {
        const { input, rules } = this.props;

        if (!isSpecialRuleExists(rules, name)) {
            return null;
        }

        const { value } = input;
        const rule = {
            name,
            message: specialGroupLabels[name],
        };

        const isValid = validationTests[name](value, rule);

        return this.renderRule(rule, isValid, 'is-special');
    }

    renderSpecialGroup = () => {
        const { rules, input } = this.props;
        // const rule = getRuleByName(rules, specialGroupParent);
        const isValid = validateSpecialGroupParent(rules, input.value);

        return (
            <Fragment>
                {this.renderRule({
                    name: specialGroupParent,
                    message: getSpecialGroupMessage(rules),
                }, isValid)}

                <div className="password-policy-rules-indent">
                    {specialGroup.map(this.renderSpecialRule)}
                </div>
            </Fragment>
        );
    }

    render() {
        const { rules, meta, ...rest } = this.props;

        return (
            <div className="password-policy">
                <InputPassword meta={meta} {...rest} />

                {meta.active && rules.length && (
                    <ul className="password-policy-rules">
                        {commonGroup.map(this.renderCommonRule)}
                        {this.renderSpecialGroup()}
                    </ul>
                )}
            </div>
        );
    }
}

PasswordWithPolicy.propTypes = {
    fetchPolicies: PropTypes.func.isRequired,
    rules: PropTypes.array.isRequired,
    input: PropTypes.shape({
        value: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        active: PropTypes.bool,
    }).isRequired,
};

function mapStateToProps(state) {
    return {
        rules: passwordPolicyCollection.selectors.getItems(state),
    };
}

const mapDispatchToProps = {
    fetchPolicies: passwordPolicyCollection.actions.fetch.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordWithPolicy);
