import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { applicationSettingsSelectors } from '../../../modules/application';
import Icon from '../../components/icon/component';
import DropdownDeprecated from '../../components/dropdown/deprecated/component';

class BreadcrumbsItem extends Component {
    constructor(args) {
        super(args);

        this.dropdown = undefined;
    }

    hide = () => {
        this.dropdown.hide();
    };

    render() {
        const {
            title, siblings, href, index, total, contentRenderer, isTouchDevice
        } = this.props;
        const hasSiblings = !!(siblings && siblings.length);
        const content = contentRenderer({
            title,
            siblings,
            href,
        }, index, total);

        const RenderComponent = index === (total - 1)
            ? 'span'
            : Link;

        return (
            <span
                className="breadcrumbs-item"
            >
                {
                    !hasSiblings
                        ? (
                            <div className="breadcrumbs-dropdown-container">
                                <div className="breadcrumbs-dropdown-inner-wrapper">
                                    <RenderComponent
                                        key={title}
                                        to={href}
                                        title={title}
                                        className="breadcrumbs-item-link"
                                    >
                                        {content}
                                    </RenderComponent>
                                </div>
                            </div>
                        )
                        : (
                            <DropdownDeprecated
                                containerClassName="breadcrumbs-dropdown-container"
                                className="breadcrumbs-dropdown-content"
                                triggerClassName="breadcrumbs-dropdown-inner-wrapper breadcrumbs-dropdown-trigger"
                                ref={(el) => { this.dropdown = el; }}
                                triggerEvent={isTouchDevice ? 'click' : 'hover'}
                                trigger={() => (
                                    <Fragment>
                                        <span className="breadcrumbs-item-link" title={content}>
                                            {content}{' '}
                                        </span>
                                        <Icon
                                            name="chevron-down"
                                            className="breadcrumbs-item-expand-icon"
                                        />
                                    </Fragment>
                                )}
                            >
                                <RenderComponent
                                    to={href}
                                    className="breadcrumbs-dropdown-link active"
                                    onClick={this.hide}
                                    title={title}
                                >
                                    {title}<br />
                                </RenderComponent>

                                {siblings && siblings.map(item => (
                                    <Link
                                        className="breadcrumbs-dropdown-link"
                                        to={item.href}
                                        key={item.href}
                                        title={item.title}
                                    >
                                        {item.title}<br />
                                    </Link>
                                ))}
                            </DropdownDeprecated>
                        )
                }
                <div className="breadcrumbs-item-icon-wrapper">
                    <Icon className="breadcrumbs-item-arrow-icon" name="chevron-right" />
                </div>
            </span>
        );
    }
}

BreadcrumbsItem.defaultProps = {
    siblings: [],
    contentRenderer(details) {
        return details.title;
    },
    title: undefined
};

BreadcrumbsItem.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string.isRequired,
    siblings: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        }),
    ),
    contentRenderer: PropTypes.func,
    index: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    isTouchDevice: PropTypes.bool.isRequired,
};

function mapStateToProps() {
    return {
        isTouchDevice: applicationSettingsSelectors.isTouchDeviceSelector()
    };
}

export default connect(mapStateToProps)(BreadcrumbsItem);
