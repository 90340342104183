import { Collection } from '../../../../shared/entities-v2';
import { apiUrl, csfLinkingCollectionName } from '../related-link-constants';
import parseItem from '../related-link-parse-item';

class CsfConnectionCollection extends Collection {
    parseItem = item => parseItem(item)
}

const csfConnectionCollection = new CsfConnectionCollection({
    apiUrl,
    name: csfLinkingCollectionName,
});

export default csfConnectionCollection;
