/* eslint-disable no-constant-condition */
import { take, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import uniqid from 'uniqid';
// import { notificationPermissions } from '../config';
import actions from './actions';

// export function checkPermissions() {
// if (!('Notification' in window)) {
//     return undefined;
// }

// if (Notification.permission !== notificationPermissions.denied) {
// Notification.requestPermission();
// }

// return undefined;
// }

function showNotification(title, config) {
    return new Promise((resolve, reject) => {
        try {
            const notification = new Notification(title, config);
            resolve(notification);
        } catch (err) {
            navigator.serviceWorker.ready.then((registration) => {
                registration
                    .showNotification(title, config)
                    .then(() => registration.getNotifications({ tag: config.tag }))
                    .then((notifications) => {
                        if (notifications[0]) {
                            return resolve(notifications[0]);
                        }

                        return reject();
                    });
            });
        }
    });
}

function notificationChannel(data) {
    return eventChannel((emitter) => {
        showNotification(data.title, {
            body: data.body,
            icon: data.icon,
            tag: uniqid(),
        }).then((notif) => {
            // eslint-disable-next-line no-param-reassign
            notif.onclick = () => emitter({
                type: actions.notification.click.getType(),
                payload: data,
            });

            // eslint-disable-next-line no-param-reassign
            notif.onclose = () => emitter({
                type: actions.notification.close.getType(),
                payload: data,
            });
        });

        return () => {};
    });
}

export function* create(action) { // eslint-disable-line import/prefer-default-export
    const channel = notificationChannel(action);

    while (true) {
        const channelAction = yield take(channel);
        yield put(channelAction);

        if (channelAction.type === actions.notification.close.getType()) {
            channel.close();
        }
    }
}
