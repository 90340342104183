import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../text';
import './styles.css';

const orientations = {
    horizontal: 'horizontal',
    vertical: 'vertical',
};

export default function DataSet({
    title, value, orientation, className, titleProps, valueProps, size, accent
}) {
    return (
        <div
            className={classnames('data-set', {
                [`data-set-${orientation}`]: true,
                [className]: !!className,
            })}
        >
            <Text
                styleType="muted"
                size={size}
                {...titleProps}
                className={classnames('data-set-title', {
                    [titleProps.className]: !!titleProps.className,
                })}
            >
                {title}:
            </Text>

            <Text
                styleType="dark"
                bold={accent}
                size={orientation === orientations.horizontal ? 'small' : 'medium'}
                {...valueProps}
                className={classnames('data-set-value', {
                    [valueProps.className]: !!valueProps.className,
                    'data-set-value-accent': accent
                })}
            >
                {value}
            </Text>
        </div>
    );
}

DataSet.orientations = orientations;

DataSet.defaultProps = {
    title: undefined,
    value: undefined,
    orientation: 'vertical',
    size: 'small',
    accent: false,
    className: undefined,
    titleProps: {},
    valueProps: {},
};

DataSet.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    orientation: PropTypes.oneOf([
        orientations.horizontal,
        orientations.vertical,
    ]),
    size: PropTypes.string,
    accent: PropTypes.bool,
    className: PropTypes.string,
    titleProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    valueProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
