import { createAction } from 'redux-act';

const prefix = 'work-center';

export const workCenterFetch = createAction(`${prefix}/FETCH`);
export const workCenterReset = createAction(`${prefix}/RESET`);
export const workCenterOverride = createAction(`${prefix}/OVERRIDE`);
export const workCenterNotificationOverride = createAction(`${prefix}/OVERRIDE_REMINDERS`);
export const workCenterRedirect = createAction(`${prefix}/REDIRECT`);
export const workCenterViewTypeChange = createAction(`${prefix}/VIEW_TYPE_CHANGE`);
export const workCenterMobileRedirect = createAction(`${prefix}/MOBILE_REDIRECT`);
