import Loadable from 'react-loadable';
import _ from 'lodash';
import Loading from './components/loadable';
import integrations from '../modules/settings-integrations/routes';
import withMobileRestriction from '../shared/modules/with-mobile-restrict';
import { withStickyContext } from '../shared/components/sticky/sticky-context';
import { stickyConf } from '../shared/components/sticky/config';
import withScrollTop from '../shared/components/with-scroll-top';
import { createUrl, paths } from './rules';

const Settings = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../modules/settings/container'),
    loading: Loading,
});

const SettingsUsers = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../modules/settings-users/settings-users-container'),
    loading: Loading,
});

const SettingsTeams = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../modules/settings/teams/settings-teams'),
    loading: Loading,
});

const SettingsGui = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../modules/settings-gui/container'),
    loading: Loading,
});

const SettingsRoles = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../modules/settings-roles/container'),
    loading: Loading,
});

const SettingsAccount = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../modules/settings-account'),
    loading: Loading,
});

const SettingsSecurity = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../modules/settings/security/settings-security-container'),
    loading: Loading,
});

export default {
    path: createUrl(paths.settings),
    component: _.flow(
        withScrollTop,
        withMobileRestriction,
        withStickyContext(stickyConf.default)
    )(Settings),
    name: 'System Settings',
    createHref: () => createUrl(paths.settings),
    indexRoute: {
        onEnter: (nextState, replace) => replace(createUrl(paths.settingsUsers))
    },
    childRoutes: [
        {
            path: createUrl(paths.settingsUsers),
            component: withScrollTop(SettingsUsers),
            name: 'Users',
            createHref: () => createUrl(paths.settingsUsers),
        },
        {
            path: createUrl(paths.settingsGui),
            component: withScrollTop(SettingsGui),
            name: 'GUI Settings',
            createHref: () => createUrl(paths.settingsGui),
        },
        {
            path: createUrl(paths.settingsRoles),
            component: withScrollTop(SettingsRoles),
            name: 'Roles',
            createHref: () => createUrl(paths.settingsRoles),
        },
        {
            path: createUrl(paths.settingsTeams),
            component: withScrollTop(SettingsTeams),
            name: 'Teams',
            createHref: () => createUrl(paths.settingsTeams),
        },
        {
            path: createUrl(paths.settingsAccount),
            component: withScrollTop(SettingsAccount),
            name: 'Account',
            createHref: () => createUrl(paths.settingsAccount),
        },
        {
            path: createUrl(paths.settingsSecurity),
            component: withScrollTop(SettingsSecurity),
            name: 'Security',
            createHref: () => createUrl(paths.settingsSecurity),
        },
        integrations,
    ],
};
