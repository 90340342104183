import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { createFormModal, } from '../../../shared/modules';
import { copy as createCopyStrategyMapModalEntity } from '../entity';
import StrategyMapForm from '../form/index';
import { strategyMapsAttributes } from '../constants';
import { WizardHeader, LoadingIndicator } from '../../../shared/components';
import { types as entityTypes } from '../../../shared/utils/entity-type';
import styles from '../../../shared/components/wizard/wizard-header/styles.module.css';

const StrategyMapFormWithWizardHeader = (props) => {
    const { titles, loading } = props;
    const description = `Create a new ${titles.strategy_map} with the
        same configuration as the current entity has.`;

    return (
        <>
            <WizardHeader
                className={styles.wizardHeaderInModal}
                steps={[{ description }]}
                title={`Copy ${titles.strategy_map}`}
                currentStep={1}
                entity={{ type: entityTypes.strategy_map }}
            />
            {loading ? <LoadingIndicator centered /> : <StrategyMapForm {...props} />}
        </>
    );
};

StrategyMapFormWithWizardHeader.defaultProps = {
    loading: false,
};

StrategyMapFormWithWizardHeader.propTypes = {
    titles: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = (_state, ownProps) => ({
    initialValues: {
        ...omit(ownProps.data, [
            strategyMapsAttributes.entity_children_counters,
            strategyMapsAttributes.strategy_map,
            strategyMapsAttributes.goal,
        ]),
        [strategyMapsAttributes.tag_name]: undefined,
    }
});

const formName = 'strategy-map-copy';

const formModal = createFormModal({
    actions: createCopyStrategyMapModalEntity.actions,
    selector: createCopyStrategyMapModalEntity.selector,
    formName,
    enableReinitialize: true,
    mapStateToProps,
})(StrategyMapFormWithWizardHeader);

export default formModal;
