import u from 'updeep';
import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
// import { get } from 'lodash';

class ModalPlugin {
    constructor(name) {
        this.name = name;
    }

    initialize(parent) {
        this.actions = this.getActions(parent.name);
        this.reducer = this.getReducer(parent.actions);
        this.selectors = this.getSelectors(parent.selectors);

        return {
            reducer: this.reducer,
            actions: this.actions,
            saga: this.saga,
            selectors: this.selectors,
        };
    }

    getReducer = (parentActions) => {
        return createReducer({
            [this.actions.toggle]: (state, action) => u({ visible: !state.visible, modalItemId: action && action.id, }, state),
            [this.actions.setVisibilityState]: (state, visible) => u({ visible }, state),
            [this.actions.setKeepModalVisible]: (state, action) => u({ keepModalVisible: action.state }, state),
            [parentActions.update.success]: state => u({ visible: false }, state),
            [parentActions.create.success]: state => u({ visible: false }, state),
            ...(parentActions?.sendInvitation && { [parentActions.sendInvitation.success]: state => u({ visible: false }, state) }),
        }, { visible: false, modalItemId: undefined, });
    }

    getActions = (parentName) => {
        return {
            toggle: createAction(`${parentName}/${this.name}/TOGGLE`),
            setVisibilityState: createAction(`${parentName}/${this.name}/SET_VIS_STATE`),
            setKeepModalVisible: createAction(`${parentName}/${this.name}/SET_KEEP_MODAL_VISIBLE`),
        };
    }

    getSelectors = (parentSelectors) => {
        const selector = state => parentSelectors.getPluginsState(state)[this.name];

        return {
            selectBranch: selector,
            isVisible: createSelector(
                selector,
                state => state.visible
            ),
            selectModalItemId: createSelector(
                selector,
                state => state.modalItemId
            ),
        };
    }
}

export default ModalPlugin;
