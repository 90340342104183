import { createReducer } from 'redux-act';
import u from 'updeep';
import { name as reducerName } from './constants';
import * as actions from './actions';

const initialState = {
    activeFormsDirtyStates: {}
};

function handleAddFormDirtyState(state, payload) {
    const newState = {
        activeFormsDirtyStates: u.updateIn(payload.formId, payload.dirty, state.activeFormsDirtyStates),
    };

    return u(newState, state);
}

function handleRemoveFormDirtyState(state, formId) {
    return u({ activeFormsDirtyStates: u.omit(formId) }, state);
}

export default createReducer({
    [actions.addFormDirtyState]: handleAddFormDirtyState,
    [actions.removeFormDirtyState]: handleRemoveFormDirtyState,
}, initialState);

export const name = reducerName;
