export const attributes = Object.freeze({
    id: 'id',
    strategy_map_id: 'strategy_map_id',
    parent_id: 'parent_id',
    author_id: 'author_id',
    owner_id: 'owner_id',
    description: 'description',
    tag_name: 'tag_name',
    title: 'title',
    tags: 'tags',
    state: 'state',
    entity_type: 'entity_type',
    strategy_map: 'strategy_map',
    parent: 'parent',
    children: 'children',
    children_counts: 'children_counts',
    has_plan_actions: 'has_plan_actions',
    entity_children_counters: 'entity_children_counters',
});

export const defaultInitialValues = [
    attributes.strategy_map_id,
    attributes.id,
    attributes.author_id,
    attributes.owner_id,
    attributes.description,
    attributes.tag_name,
    attributes.title,
    attributes.tags,
    attributes.state,
];

export const mobileSegmentChildrenListTitle = 'Strategic Activity';
