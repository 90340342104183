import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './styles.css';

export default function ButtonGroup({ children, className, ...rest }) {
    return (
        <div
            className={classnames('button-group', {
                [className]: className
            })}
            {...rest}
        >
            {children}
        </div>
    );
}

ButtonGroup.defaultProps = {
    className: undefined,
    children: undefined
};

ButtonGroup.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
