import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import inlineInputClassNames from '../../../../shared/components/inline-field-form/classnames';
import { Input, Button, Icon } from '../../../../shared/components';

const rootClassName = 'structure-entity-title-field';

export default function StructureEntityTitleField({
    name, onCancel, submitting, className
}) {
    return (
        <div className={classnames(rootClassName, inlineInputClassNames.root, className)}>
            <Field
                component={Input}
                name={name}
                required
                autoFocus
            />

            <div className={inlineInputClassNames.controls}>
                <Button
                    type="submit"
                    styleType={Button.styleTypes.success}
                    size={Button.sizes.small}
                    disabled={submitting}
                    outline
                >
                    <Icon name="check" />
                </Button>

                <Button
                    styleType={Button.styleTypes.danger}
                    size={Button.sizes.small}
                    outline
                    disabled={submitting}
                    onClick={onCancel}
                >
                    <Icon name="times-circle" />
                </Button>
            </div>
        </div>
    );
}

StructureEntityTitleField.defaultProps = {
    className: PropTypes.string
};

StructureEntityTitleField.propTypes = {
    name: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    className: PropTypes.string
};
