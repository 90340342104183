import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from '../../../shared/components';
import styles from './styles.module.css';

const DataPointModalEditFormControls = ({
    toggle, reset, submitting, handleDataPointRemove, data_point_id, advCalcEnabled
}) => {
    return (
        <Row className={styles.dataPointModalEditControls}>
            <Col base={advCalcEnabled ? 6 : 4}>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        toggle();
                        reset();
                    }}
                    outline
                    disabled={submitting}
                    mobile
                >
                    Cancel
                </Button>
            </Col>
            {!advCalcEnabled && (
                <Col base={4}>
                    <Button
                        styleType="danger"
                        onClick={(event) => {
                            event.preventDefault();
                            handleDataPointRemove({ id: data_point_id });
                        }}
                        disabled={submitting}
                        mobile
                    >
                        Delete
                    </Button>
                </Col>
            )}
            <Col base={advCalcEnabled ? 6 : 4}>
                <Button
                    styleType="success"
                    type="submit"
                    loading={submitting}
                    mobile
                >
                    Save
                </Button>
            </Col>
        </Row>
    );
};

DataPointModalEditFormControls.defaultProps = {
    data_point_id: undefined,
};

DataPointModalEditFormControls.propTypes = {
    toggle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    handleDataPointRemove: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    data_point_id: PropTypes.number,
    advCalcEnabled: PropTypes.bool.isRequired,
};

export default DataPointModalEditFormControls;
