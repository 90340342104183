import { name } from './config';
import navigationTreeList from '../navigation-goalmap-tree/entity';
import { details as detailsKpi } from '../goalmap-milestones/entity';
import { csfModel } from '../primary-entities/critical-success-factor';
import { isGlobalKpi } from '../goalmap-milestones/selectors';
import { goalmapByIdSelector, getPlanTitle } from '../goalmap-list/selector';
import { findItem } from '../../shared/modules/with-move-entity/utils';
import { types as entityTypes } from '../../shared/utils/entity-type';

export function navigationContextSelector(state) {
    return state[name];
}

const getEntityDataFromTree = (state, context) => {
    const { items } = navigationTreeList.selectors.getAttribute(state, 'data');
    return findItem(items, context.entityType, context.id);
};

export function navigationContextDataSelector(state) {
    const context = navigationContextSelector(state);
    const planTitle = getPlanTitle(state);

    switch (context.entityType) {
        case entityTypes.goal: return goalmapByIdSelector(state, context.id);
        case entityTypes.milestone: {
            const { title, parent_entity_type } = detailsKpi.selector(state).data;
            return {
                ...context, title, global_kpi: isGlobalKpi({ parent_entity_type }), planTitle
            };
        }
        case entityTypes.driver: {
            const { title } = csfModel.selector(state).data;
            return {
                ...context, title, planTitle,
            };
        }
        default: {
            const data = getEntityDataFromTree(state, context);
            return data ? { ...data, planTitle } : {};
        }
    }
}
