import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Modal } from '../../../../shared/components';
import {
    ModalFormHeader, ModalFormContainer, ModalFormBody, ModalFormFooter
} from '../../../../shared/components/modal-form';
import styles from './styles.module.css';

const PreferencesAccountSecurityRegisterDeviceModal = ({
    isFetching,
    visible,
    onHide,
    onClick,
}) => (
    <Modal
        alignCentered
        className={styles.accountSecurityModal}
        modalContentClassName={styles.modalContent}
        visible={visible}
    >
        <ModalFormContainer className={styles.modalContainer}>
            <ModalFormHeader
                hide={onHide}
            >
                Register New Device for Two-Factor Authentication
            </ModalFormHeader>
            <ModalFormBody>
                <p className={classNames(styles.baseText, styles.boldText)}>
                    Would you like to register a new device for two-factor authentication?
                </p>
                <p className={styles.baseText}>
                    This will require re-authentication. You will be logged out<br />
                    of MPOWR Envision application.
                </p>
            </ModalFormBody>
            <ModalFormFooter>
                <Button
                    outline
                    className={styles.button}
                    disabled={isFetching}
                    onClick={onHide}
                >
                    Cancel
                </Button>
                <Button
                    type="button"
                    onClick={onClick}
                    loading={isFetching}
                    disabled={isFetching}
                    styleType="success"
                    className={styles.button}
                >
                    Register New Device
                </Button>
            </ModalFormFooter>
        </ModalFormContainer>

    </Modal>
);

PreferencesAccountSecurityRegisterDeviceModal.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default PreferencesAccountSecurityRegisterDeviceModal;
