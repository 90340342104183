import { takeEvery, put } from 'redux-saga/effects';
import { get } from 'lodash';
import driverConnectionCollection from './csf-connection-collection';
import { csfConnectionCreateModel, csfConnectionModel } from './csf-connection-models';
import parseItem from '../related-link-parse-item';
import { attributeNames as attrNames } from '../related-link-constants';

function* handleConnectionAdd({ payload }) {
    for (let i = 0; i < payload.length; i += 1) {
        const data = parseItem(payload[i]);
        const action = driverConnectionCollection.actions.push(data);
        yield put(action);
    }
}

function* handleConnectionRemove({ payload }) {
    const hasRelatedLink = get(payload, attrNames.relatedLink);
    const idKey = hasRelatedLink
        ? attrNames.relatedLinkId
        : attrNames.id;
    const id = hasRelatedLink
        ? get(payload, [attrNames.relatedLink, attrNames.id])
        : get(payload, attrNames.id);

    yield put(driverConnectionCollection.actions.remove({ id, idKey }));
}

export default function* csfConnectionSaga() {
    yield takeEvery(csfConnectionCreateModel.actions.success, handleConnectionAdd);
    yield takeEvery(csfConnectionModel.actions.remove.success, handleConnectionRemove);
}
