import React from 'react';
import PropTypes from 'prop-types';
import { Pluralize, TableView } from '../../../shared/components';
import Text from '../../../shared/components/text';
import Toggle from '../../../shared/components/toggle/component';

import './styles.css';

const renderItem = (item) => {
    const { id, file_storage } = item;
    const { amount_attachments, is_active } = file_storage;

    return (
        <TableView.Row key={id}>
            <TableView.Cell noBorder>
                <TableView.formatters.User
                    avatar={item.avatar_image_url}
                    firstName={item.first_name}
                    lastName={item.last_name}
                    tagName={item.tag_name}
                />
            </TableView.Cell>
            <TableView.Cell align={TableView.align.LEFT} noBorder>
                {!!amount_attachments && (
                    <Text className="integration-user-amount" styleType="dark">
                        {amount_attachments} <Pluralize value={amount_attachments} one="file" other="files" />
                    </Text>
                )}
            </TableView.Cell>
            <TableView.Cell>
                <Toggle
                    className="integration-user-status"
                    id={`integration-user-${id}`}
                    name={`integration-user-${id}`}
                    disabled
                    input={{
                        value: is_active,
                    }}
                />
            </TableView.Cell>
        </TableView.Row>
    );
};

export function FilesInfoTable({ items }) {
    return (
        <TableView className="file-info-table">
            <TableView.Header>
                <TableView.HeaderCell
                    align={TableView.align.LEFT}
                    className="file-info-table-user"
                    noBorder
                >
                    User
                </TableView.HeaderCell>
                <TableView.HeaderCell
                    align={TableView.align.LEFT}
                    className="file-info-table-toggle"
                    noBorder
                >
                    Linked Files
                </TableView.HeaderCell>
                <TableView.HeaderCell className="file-info-table-status">Status</TableView.HeaderCell>
            </TableView.Header>

            {items.map(renderItem)}
        </TableView>
    );
}

FilesInfoTable.defaultProps = {
    items: []
};

FilesInfoTable.propTypes = {
    items: PropTypes.array
};
