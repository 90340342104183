import moment from 'moment';
import config from '../../../config';
import { isValidDate } from '../../utils/date';

export function applyUTCOffset(date) {
    return moment.utc(date).local();
}

export function fromNow(date) {
    return moment(date).fromNow();
}

export default function formatDate(date, format = config.shortDateFormat) {
    if (isValidDate(date)) return moment(date).format(format);
    return date;
}
