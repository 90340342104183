import { get } from 'lodash';
import rules from './rules.json';

const keys = {
    global: 'global',
    dashboard: 'dashboard',
    workCenter: 'work-center',
    workCenterView: 'view',
    reports: 'reports',
    profile: 'profile',
    preferences: 'preferences',
    accountSecurity: 'account-security',
    storageServices: 'storage-services',
    email: 'email',
    search: 'search',
    searchMobile: 'search-mobile',
    notifications: 'notifications',
    root: 'root',
    planArchived: 'plan-archived',

    widgetList: 'widget-list',
    activityFeed: 'activity-feed',
    myDrafts: 'my-drafts',
    myNotes: 'my-notes',

    auth: 'auth',
    login: 'login',
    signup: 'signup',
    changeEmail: 'change-email',
    changeEmailCallback: 'change-email-callback',
    resetPassword: 'reset-password',
    resetPasswordRequest: 'reset-password-request',

    settings: 'settings',
    gui: 'gui',
    users: 'users',
    roles: 'roles',
    teams: 'teams',
    account: 'account',
    integrations: 'integrations',
    security: 'security',

    plan: 'plan',
    navigator: 'navigator',
    overview: 'overview',
    members: 'members',
    kpi: 'kpi',
    csf: 'csf',
    terminology: 'terminology',
    configuration: 'configuration',

    children: 'children',
    planSegment: 6,
    planStrategy: 5,
    planObjective: 4,
    planKpi: 3,
    planTactic: 2,
    planAction: 1,

    view: 'view',
    viewMobile: 'view-mobile',
    create: 'create',
    details: 'details',
    update: 'update',
    archive: 'archive',
    list: 'list',

    integration: 'integration',
    zapier: 'zapier',
};

function getFromRule(path) {
    return get(rules, path, []);
}

// global
export const dashboard = getFromRule([keys.global, keys.dashboard]);
export const activityFeed = getFromRule([keys.global, keys.activityFeed]);
export const workCenter = getFromRule([keys.global, keys.workCenter, keys.root]);
export const workCenterView = getFromRule([keys.global, keys.workCenter, keys.view]);
export const reports = getFromRule([keys.global, keys.reports]);
export const profile = getFromRule([keys.global, keys.profile]);
export const teams = getFromRule([keys.global, keys.teams]);
export const search = getFromRule([keys.global, keys.search]);
export const searchMobile = getFromRule([keys.plan, keys.searchMobile]);
export const notifications = getFromRule([keys.global, keys.notifications]);
export const archivedPlans = getFromRule([keys.global, keys.planArchived]);
export const integrationZapier = getFromRule([keys.global, keys.integration, keys.zapier]);

// global - auth
export const login = getFromRule([keys.global, keys.auth, keys.login]);
export const signup = getFromRule([keys.global, keys.auth, keys.signup]);
export const changeEmail = getFromRule([keys.global, keys.auth, keys.changeEmail]);
export const changeEmailCallback = getFromRule([keys.global, keys.auth, keys.changeEmailCallback]);
export const resetPassword = getFromRule([keys.global, keys.auth, keys.resetPassword]);
export const resetPasswordRequest = getFromRule([keys.global, keys.auth, keys.resetPasswordRequest]);

// global - preferences
export const preferences = getFromRule([keys.global, keys.preferences, keys.root]);
export const preferencesEmail = getFromRule([keys.global, keys.preferences, keys.email]);
export const preferencesAccountSecurity = getFromRule([keys.global, keys.preferences, keys.accountSecurity]);
export const preferencesStorageServices = getFromRule([keys.global, keys.preferences, keys.storageServices]);


// global - settings
export const settings = getFromRule([keys.global, keys.settings, keys.root]);
export const settingsGui = getFromRule([keys.global, keys.settings, keys.gui]);
export const settingsUsers = getFromRule([keys.global, keys.settings, keys.users]);
export const settingsRoles = getFromRule([keys.global, keys.settings, keys.roles]);
export const settingsTeams = getFromRule([keys.global, keys.settings, keys.teams]);
export const settingsAccount = getFromRule([keys.global, keys.settings, keys.account]);
export const settingsIntegrations = getFromRule([keys.global, keys.settings, keys.integrations]);
export const settingsSecurity = getFromRule([keys.global, keys.settings, keys.security]);

// global - widget lists
export const widgetList = getFromRule([keys.global, keys.widgetList, keys.root]);
export const widgetListActivityFeed = getFromRule([keys.global, keys.widgetList, keys.activityFeed]);
export const widgetListMyDrafts = getFromRule([keys.global, keys.widgetList, keys.myDrafts]);

export const zapierPermissionRequest = getFromRule([keys.global, keys.zapierPermissionsRequest]);

// plan top-level
export const plan = getFromRule([keys.plan, keys.root]);
export const planList = getFromRule([keys.plan, keys.list]);
export const planCreate = getFromRule([keys.plan, keys.create]);
export const planMembers = getFromRule([keys.plan, keys.members]);
export const planNavigator = getFromRule([keys.plan, keys.navigator]);
export const planOverview = getFromRule([keys.plan, keys.overview]);
export const planArchive = getFromRule([keys.plan, keys.archive]);

// plan top-level KPI
export const planKPIList = getFromRule([keys.plan, keys.kpi, keys.root]);
export const planKPIDetails = getFromRule([keys.plan, keys.kpi, keys.details]);
// export const planKPICreate = getFromRule([keys.plan, keys.kpi, keys.create]);
export const planKPIUpdate = getFromRule([keys.plan, keys.kpi, keys.update]);

// plan top-level CSF
export const planCSFList = getFromRule([keys.plan, keys.csf, keys.root]);
// export const planCSFCreate = getFromRule([keys.plan, keys.csf, keys.create]);
export const planCSFDetails = getFromRule([keys.plan, keys.csf, keys.details]);

// plan settings
export const planSettings = getFromRule([keys.plan, keys.settings, keys.root]);
export const planSettingsTerminology = getFromRule([keys.plan, keys.settings, keys.terminology]);
export const planSettingsNotifications = getFromRule([keys.plan, keys.settings, keys.notifications]);
export const planSettingsConfiguration = getFromRule([keys.plan, keys.settings, keys.configuration]);

// plan children
// segment
export const planChildrenSegment = getFromRule([keys.plan, keys.children, 6, keys.view]);
export const planChildrenSegmentMobile = getFromRule([keys.plan, keys.children, 6, keys.viewMobile]);
// export const planChildrenSegmentCreate = getFromRule([keys.plan, keys.children, 6, keys.create]);
// export const planChildrenSegmentCreateChild = getFromRule([keys.plan, keys.children, 6, keys.createChild]);

// strategy/objective
export const planChildrenStrategyObjective = getFromRule([keys.plan, keys.children, 5, keys.view]);
export const planChildrenStrategyObjectiveMobile = getFromRule([keys.plan, keys.children, 5, keys.viewMobile]);
// export const planChildrenStrategyObjectiveCreate = getFromRule([keys.plan, keys.children, 5, keys.create]);
// export const planChildrenStrategyObjectiveCreateChild = getFromRule([keys.plan, keys.children, 5, keys.createChild]);

// tactic/action
export const planChildrenTacticAction = getFromRule([keys.plan, keys.children, 2, keys.view]);
export const planChildrenTacticActionMobile = getFromRule([keys.plan, keys.children, 2, keys.viewMobile]);
// export const planChildrenTacticActionCreate = getFromRule([keys.plan, keys.children, 2, keys.create]);
// export const planChildrenTacticActionCreateChild = getFromRule([keys.plan, keys.children, 2, keys.createChild]);

// kpi
export const planChildrenKPI = getFromRule([keys.plan, keys.children, 3, keys.view]);
export const planChildrenKPIUpdate = getFromRule([keys.plan, keys.children, 3, keys.update]);
// export const planChildrenKPICreate = getFromRule([keys.plan, keys.children, 3, keys.create]);
// export const planChildrenKPICreateChild = getFromRule([keys.plan, keys.children, 3, keys.createChild]);
