/** @namespace Utils.Files */

/**
 * Validate a file by size
 * @function isValid
 * @memberof Utils.Files
 * @param {File} el Validated file
 * @param {number} maxSizeMB Maximum size of the file
 * @return {string} True if size of the file less than maximum allowed size
 */
function isValid(el, maxSizeMB) {
    if (el.size > maxSizeMB * 1024 * 1024) {
        return false;
    }

    return true;
}

/**
 * Validate size for every file in array
 * @function validateSize
 * @memberof Utils.Files
 * @param {Array<File>} fileArr Validated files array
 * @return {Array<string>} Array of errors
 */
export default function validateSize(fileArr, maxMB) {
    if (!fileArr) return null;

    const errors = {};
    const errorMsg = `The attachment should not be greater than ${maxMB} MB!`;
    for (let i = 0; i < fileArr.length; i += 1) {
        if (!isValid(fileArr[i], maxMB)) {
            errors[i] = [errorMsg];
        }
    }

    return Object.keys(errors).length > 0 ? errors : undefined;
}

/**
 * Automatically Download File
 * @function automaticallyDownloadFile
 * @memberof Utils.Files
 * @param {string} fileName filename
 * @param {string} fileText file content
 * @return {Void} void
 */
export const automaticallyDownloadFile = (fileName, fileText) => {
    const link = document.createElement('a');
    link.setAttribute(
        'href',
        `data:text/plain;charset=utf-8,${encodeURIComponent(fileText)}`
    );
    link.setAttribute('download', fileName);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadFile = (data, fileName) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
};

export const downloadBlob = (data, fileName, mimeType) => {
    const blob = new Blob([data], {
        type: mimeType,
    });
    const url = window.URL.createObjectURL(blob);
    downloadFile(url, fileName);
    setTimeout(() => {
        return window.URL.revokeObjectURL(url);
    }, 1000);
};
