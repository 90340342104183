import request from '../../../request';

export default function getUsers(type = 2, extraOptions = []) {
    return request.get(
        '/roles',
        { params: { type } }
    ).then(
        (response) => {
            if (extraOptions) {
                return {
                    options: [...extraOptions, ...response.data],
                };
            }

            return {
                options: response.data,
            };
        }
    );
}
