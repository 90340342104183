import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import './styles.css';

export default class Image extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            ready: false,
            error: false,
        };
    }

    render() {
        const {
            src, alt, onLoad, onError, className
        } = this.props;
        const { ready, error } = this.state;

        return (
            <img
                src={src}
                alt={error ? '' : alt}
                className={classnames('image', {
                    'image-ready': ready,
                    'image-error': error,
                    [className]: className,
                })}
                onLoad={(event) => {
                    onLoad(event);

                    this.setState({
                        ready: true
                    });
                }}
                onError={(event) => {
                    onError(event);

                    this.setState({
                        ready: true,
                        error: true,
                    });
                }}
            />
        );
    }
}

Image.defaultProps = {
    onLoad() {},
    onError() {},
    className: undefined,
    alt: 'Image'
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};
