import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CancelToken } from 'axios';
import request from '../../request';

const source = CancelToken.source();
const getErrorMessage = error => get(error, ['response', 'data', 'message'], 'Unable to load the data');

export const useApiGet = (endpoint, fetchParams = {}, dependenciesArray = [], autoFetch = true) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [fetching, setFetching] = useState(true);
    let isMounted = false;

    const fetch = async () => {
        try {
            setFetching(true);
            const res = await request.service(endpoint, { params: fetchParams, cancelToken: source.token });
            if (isMounted) {
                setData(res);
                setFetching(false);
                setError(null);
            }
        } catch (err) {
            if (!request.isCancel(err) && isMounted) {
                const errorMessage = getErrorMessage(err);
                setError(errorMessage);
            }

            setFetching(false);
        }
    };

    useEffect(() => {
        isMounted = true;

        if (autoFetch) fetch();

        return () => {
            isMounted = false;
            // source.cancel(); toDo think about cancel request, now doesn't work normally
        };
    }, dependenciesArray);

    return {
        data, error, fetching, setData, fetch
    };
};

useApiGet.propTypes = {
    endpoint: PropTypes.string.isRequired,
    fetchParams: PropTypes.object,
    dependenciesArray: PropTypes.array,
};
