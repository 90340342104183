import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import Transition from '../../transition/component';
import './style.css';

export default class DropdownDeprecated extends Component {
    constructor(...args) {
        super(...args);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleEscPress = this.handleEscPress.bind(this);

        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        if (this.props.closeOnOuterClick) {
            document.addEventListener('mousedown', this.handleDocumentClick);
        }

        if (this.props.closeOnESC) {
            document.addEventListener('keyup', this.handleEscPress);
        }
    }

    componentWillUnmount() {
        if (this.props.closeOnOuterClick) {
            document.removeEventListener('mousedown', this.handleDocumentClick);
        }

        if (this.props.closeOnESC) {
            document.removeEventListener('keyup', this.handleEscPress);
        }
    }

    handleDocumentClick(event) {
        const { visible } = this.state;

        if (!visible) {
            return;
        }

        if (!ReactDOM.findDOMNode(this).contains(event.target)) {
            this.hide();
        }
    }

    handleEscPress(event) {
        if (this.state.visible) {
            if (event.key === 'Escape') {
                this.hide();
            }
        }
    }

    show() {
        this.setState({
            visible: true
        });

        this.props.onShow();
    }

    hide() {
        this.setState({
            visible: false
        });

        this.props.onHide();
    }

    // eslint-disable-next-line consistent-return
    toggle() {
        const {
            disabled, onVisibilityChange, onHide, onShow,
        } = this.props;

        if (disabled) return null;

        this.setState({
            visible: !this.state.visible
        });

        onVisibilityChange({
            visible: !this.state.visible
        });

        if (!this.state.visible) {
            onShow();
        } else {
            onHide();
        }
    }

    render() {
        const {
            children, className, containerClassName, triggerClassName, triggerActiveClassName, trigger, animated,
            closeOnESC, closeOnOuterClick, onVisibilityChange, triggerEvent, onTransitionShow, onTransitionHide,
            disabled,
            ...rest
        } = this.props;

        const { visible } = this.state;
        const containedChildren = !visible ? null : children;
        const triggerEvents = (triggerEvent === 'click')
            ? { onClick: this.toggle }
            : { onMouseEnter: this.toggle };

        const containerEvents = (triggerEvent === 'click')
            ? {}
            : { onMouseLeave: this.toggle };

        const transitionProps = {
            ...rest,
            onShow: onTransitionShow,
            onHide: onTransitionHide,
        };

        return (
            <span
                className={classnames('dropdown-container', {
                    [containerClassName]: containerClassName
                })}
                {...containerEvents}
            >
                <span // eslint-disable-line jsx-a11y/no-static-element-interactions
                    className={classnames('dropdown-trigger', {
                        [triggerClassName]: triggerClassName,
                        [triggerActiveClassName]: triggerActiveClassName && visible,
                        'disabled': disabled,
                    })}
                    {...triggerEvents}
                >
                    {trigger({ open: visible })}
                </span>

                {
                    animated
                        ? (
                            <Transition
                                className={classnames('dropdown', {
                                    [className]: className
                                })}
                                visibleClassName="dropdown-visible"
                                visible={visible}
                                {...transitionProps}
                            >
                                {children}
                            </Transition>
                        )
                        : containedChildren
                }

            </span>
        );
    }
}

DropdownDeprecated.defaultProps = {
    children: undefined,
    className: undefined,
    containerClassName: undefined,
    triggerClassName: undefined,
    triggerActiveClassName: undefined,
    animated: true,
    closeOnESC: true,
    closeOnOuterClick: true,
    triggerEvent: 'click',
    onTransitionShow() {},
    onTransitionHide() {},
    onVisibilityChange() {},
    onShow() {},
    onHide() {},
    disabled: false,
};

DropdownDeprecated.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    trigger: PropTypes.func.isRequired,
    triggerClassName: PropTypes.string,
    triggerActiveClassName: PropTypes.string,
    animated: PropTypes.bool,
    closeOnESC: PropTypes.bool,
    closeOnOuterClick: PropTypes.bool,
    onVisibilityChange: PropTypes.func,
    onTransitionShow: PropTypes.func,
    onTransitionHide: PropTypes.func,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    triggerEvent: PropTypes.oneOf([
        'click',
        'hover',
    ]),
    disabled: PropTypes.bool,
};
