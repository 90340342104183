import { cloneDeep, flow, omit } from 'lodash';
import request from '../../../request';
import { createDetails, List } from '../../entities';
import { isMilestone, isObjective, typeCodes } from '../../utils/entity-type';
import { attributes } from '../../../modules/goalmap-milestones/constants';
import parseData from './parse';
import reducer from './reducer';

export const list = new List({
    apiUrl: 'goalmap',
    name: 'goalmap-settings-move-list',
    getApiUrl({ id }) {
        return `/goals/${id}/tree`;
    },
    parse(response) {
        return flow(
            parseData,
            cloneDeep
        )(response);
    },
    extendReducer: reducer,
});

export const move = createDetails({
    apiUrl: '/move',
    name: 'goalmap-settings-move-details',
    extendAPI: {
        async update({ source, target }) {
            const move_type = source.entity_type;
            const targetId = target.id;

            const data = {
                move_type,
            };

            if ([typeCodes.strategy, typeCodes.objective].includes(move_type)) {
                if (isObjective(source)) {
                    data.parent_id = targetId;
                } else {
                    data.strategy_map_id = targetId;
                }
            }

            if ([typeCodes.tactic, typeCodes.action].includes(move_type)) {
                if ([typeCodes.strategy, typeCodes.objective].includes(target.entity_type)) {
                    data.segment_id = targetId;
                } else {
                    data.parent_id = targetId;
                }
            }

            if (isMilestone(source)) {
                let milestone;

                try {
                    milestone = await request.get(`/milestones/${source.id}`);
                } catch (e) {
                    throw new Error(e);
                }

                if (!milestone[attributes.advanced_calculation_enabled]) {
                    milestone = omit(
                        milestone,
                        [
                            attributes.advanced_calculation_sources,
                            attributes.advanced_calculation_type
                        ]
                    );
                }

                const newData = {
                    [attributes.parent_entity_id]: targetId,
                    [attributes.parent_entity_type]: target.entity_type
                };

                return request.put(`/milestones/${milestone.id}`, { ...milestone, ...newData });
            }

            return request.put(`/move/${source.id}`, data);
        },
    },
});
