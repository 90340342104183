/**
 * Reduces values and calculates average value
 * @class Average
 * @memberof Utils
 */
export class Average {
    avgData = {
        sum: 0,
        count: 0
    };

    /**
     * Add value to calculation.
     * @param {number} value - Add value to heap for calculation.
     */
    add(value) {
        this.avgData.count += 1;
        this.avgData.sum += value;
    }

    /**
     * Get average value.
     * @return {number} Return average value.
     */
    get() {
        return this.avgData.sum / this.avgData.count;
    }
}
