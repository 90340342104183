import objectToFormData from 'object-to-formdata';
import { get, isString } from 'lodash';
import u from 'updeep';
import request from '../../../request';
import { getEntityCode } from '../../../shared/utils/entity-type';
import { getEntitiesByType } from '../../../shared/utils/content-editor-entity-selector';
import { formatAttachments } from '../../secondary-entities/attachment';
import { states } from '../../goalmap-list/config';

const baseApiUrl = '/notes';
export const assignEntityRequiredError = 'Assign a location if you would like to publish the note';

export const parseErrorResponse = (res) => {
    const errorObj = get(res, 'response.data.errors');

    const contentErrors = Object.entries(errorObj).reduce((accum, entry) => {
        const [key, val] = entry;

        const [formattedKey] = key.split('.');

        if (formattedKey === 'mentions') {
            accum.push(val);
        }

        return accum;
    }, errorObj.content || []);


    return Promise.reject(u.updateIn('response.data.errors.content', contentErrors, res));
};

export function apiCreate({ params }) {
    const {
        attachments,
        content,
        state,
    } = params;

    const parentId = params.parentId || params.entity_id;

    let parentEntityType = params.parentEntityType || params.entity_type;
    parentEntityType = isString(parentEntityType)
        ? getEntityCode({ url_type: parentEntityType })
        : parentEntityType;

    if (state !== states.draft && (!parentId || !parentEntityType)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
            response: { data: { errors: { entity: [assignEntityRequiredError] } } }
        });
    }

    const data = {
        content,
        entity_id: parentId,
        entity_type: parentEntityType,
        state: state || states.active,
        mentions: getEntitiesByType(content, 'mention'),
        attachments: formatAttachments(attachments),
    };

    return request
        .post(baseApiUrl, objectToFormData(data, { indices: true }))
        .catch(parseErrorResponse);
}
