import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.css';

export default function ButtonGroupRadio({
    options, input, className, classNameLabel
}) {
    return (
        <span className={cx('button-group-radio', className)}>
            {options.map((item) => {
                const id = `${input.name}${item.value}`;
                const selected = input.value === item.value;

                return (
                    <label
                        key={id}
                        htmlFor={id}
                        className={cx('button buttonGroup-groupedHorizontal', {
                            'button-type-accent': selected,
                            'button-type-default': !selected,
                            'button-outline': !selected,
                            [classNameLabel]: !!classNameLabel,
                        })}
                    >
                        <input
                            id={id}
                            type="radio"
                            style={{ display: 'none' }}
                            {...input}
                            value={item.value}
                            onChange={() => input.onChange(item.value)}
                        />

                        {item.label}
                    </label>
                );
            })}
        </span>
    );
}

ButtonGroupRadio.defaultProps = {
    options: [],
    className: undefined,
    classNameLabel: undefined,
};

ButtonGroupRadio.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
        }),
    ),
    className: PropTypes.string,
    classNameLabel: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.any,
    }).isRequired,
};
