import u from 'updeep';
import { List } from '../../../shared/entities';

const apiUrl = '/history';

export default new List({
    apiUrl,
    name: 'history-list',
    parseItem(item) {
        const data = JSON.parse(item.data);
        return u(
            {
                fields: data.fields,
                updated: data.updated,
                original: data.original,
            },
            item
        );
    }
});
