import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import localizationsCollection from './localizations-collection';
import { localizationsKey } from './constants';
import { selectDefaultCurrencyFormat } from '../settings-data/application-settings-selectors';

export const getDateFormats = createSelector(
    localizationsCollection.selectors.getRaw,
    data => (data?.length > 0
        ? data.find(item => item?.key === localizationsKey.dateFormats)?.values || []
        : [])
);

const getCurrenciesFormats = createSelector(
    localizationsCollection.selectors.getRaw,
    data => (data?.length > 0
        ? data.find(item => item?.key === localizationsKey.currenciesFormat)?.values || []
        : [])
);

export const getCurrenciesFormatsWithLabel = createSelector(
    getCurrenciesFormats,
    items => items.map(item => ({ ...item, label: `${item.code} - ${item.name}` }))
);

export const getDefaultCurrencyFormatSymbol = createSelector(
    [getCurrenciesFormats, selectDefaultCurrencyFormat],
    (data, defaultCurrencyCode) => data.find(item => item.code === defaultCurrencyCode)?.symbol || '$'
);

export const getCurrencyFormatSymbol = createSelector(
    getCurrenciesFormats,
    data => memoize(
        currencyCode => data.find(item => item.code === currencyCode)?.symbol || '$'
    )
);
