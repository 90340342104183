import { takeEvery, put } from 'redux-saga/effects';
import { get, isEmpty, } from 'lodash';
import * as ToastNotification from '../../../../modules/notifications/toast';
import { getErrors } from '../../utils/parse-response-payload';

function defaultGetActions(actions) {
    return [
        actions.create.success,
        actions.create.error,
        actions.update.success,
        actions.update.error,
        actions.remove.success,
        actions.remove.error,
    ];
}

const errorsByStatus = {
    503: 'Permission error',
    404: 'Operation failed. The entity does not exist',
    400: 'Something went wrong. Please try again',
    422: 'Invalid data',
};

const defaultDictionary = {
    errors: {
        subject: 'Error:',
        generic: 'Something went wrong.',
        ...errorsByStatus,
    },
    success: {
        subject: 'Success:',
        generic: 'The changes have been saved',
        update: 'The entity was updated',
        create: 'The entity was created',
        remove: 'The entity was removed',
    },
};

function defaultGetNotificationObject(parentActions, action, dictionary) {
    const { type, payload } = action;

    switch (type) {
        case parentActions.update.error.getType():
        case parentActions.create.error.getType():
        case parentActions.remove.error.getType():
            return {
                type: ToastNotification.types.SERVICE,
                data: {
                    type: ToastNotification.serviceTypes.ERROR,
                    text: !isEmpty(payload.response.errors)
                        ? getErrors(payload.response.errors)
                        : get(dictionary.errors, payload.response.status, dictionary.errors.generic),
                }
            };

        case parentActions.update.success.getType():
            return {
                type: ToastNotification.types.SERVICE,
                data: {
                    type: ToastNotification.serviceTypes.SUCCESS,
                    text: dictionary.success.update,
                }
            };
        case parentActions.create.success.getType():
            return {
                type: ToastNotification.types.SERVICE,
                data: {
                    type: ToastNotification.serviceTypes.SUCCESS,
                    text: dictionary.success.create,
                }
            };
        case parentActions.remove.success.getType():
            return {
                type: ToastNotification.types.SERVICE,
                data: {
                    type: ToastNotification.serviceTypes.SUCCESS,
                    text: dictionary.success.remove,
                }
            };
        default:
            return {
                type: ToastNotification.types.SERVICE,
                data: {
                    type: ToastNotification.serviceTypes.SUCCESS,
                    text: dictionary.success.generic,
                }
            };
    }
}


class ModelNotificationsPlugin {
    constructor(options) {
        this.name = options.name;
        this.getActions = options.getActions || defaultGetActions;
        this.dictionary = options.dictionary || defaultDictionary;
    }

    initialize = (parent) => {
        const { actions } = parent;
        this.saga = this.getSagas(actions);

        return {
            saga: this.saga,
        };
    }

    getSagas(parentActions) {
        const actions = this.getActions(parentActions);
        const patterns = actions.map(item => item.getType());
        const { dictionary } = this;

        function* notificationHandler(action) {
            const notificationObject = defaultGetNotificationObject(parentActions, action, dictionary);
            yield put(ToastNotification.create(notificationObject));
        }

        return function* modelNotificationSaga() {
            yield takeEvery(patterns, notificationHandler);
        };
    }
}

ModelNotificationsPlugin.dictionary = defaultDictionary;

export default ModelNotificationsPlugin;
