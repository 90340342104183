import moment from 'moment';

export function parse(value) {
    if (!value) {
        return null;
    }

    return moment(value).format('YYYY-MM-DD HH:mm:ss');
}

export function format(value) {
    if (!value) {
        return null;
    }

    return moment(value);
}
