/** @namespace Utils.EditorValue */

/**
 * Checks value is empty or not
 * @function isEmpty
 * @memberof Utils.EditorValue
 * @param {string} value
 * @return {boolean} Return true if value is empty
 */
export function isEmpty(value) { // eslint-disable-line import/prefer-default-export
    if (!value || value === '' || value === '<p></p>') {
        return true;
    }

    return false;
}

/**
 * Checks html is empty or not
 * @function isEmptyHTML
 * @memberof Utils.EditorValue
 * @param {string} value Checked HTML string
 * @return {boolean} Return true if html is empty
 */
export function isEmptyHTML(value) {
    if (!value) return true;

    const el = document.createElement('div');
    el.innerHTML = value;
    return !el.innerText;
}
