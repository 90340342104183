import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router';
import { get } from 'lodash';
import { createHrefByTypeCode } from '../../../../shared/utils/compose-href';
import {
    LoadingIndicator, Icon, StatusIndicator, Avatar, MetaInfo, Clickable,
} from '../../../../shared/components';
import isOwnEntity from '../../../../shared/utils/permissions';
import * as routeUtils from '../../../../routes/rules';
import { getIconByTypeCode, } from '../../../../shared/utils/entity';
import Permissions from '../../../permissions';
import { isGlobal } from '../../../goalmap-milestones/utils';

import styles from './related-link-list-item.module.css';


export default function RelatedLinkListItem(props) {
    const {
        id, goal_id, updatingItemId, onRemove, type, url, author, updated_at, related_link, related_link_type, titles, tooltip_path, href,
        permissions, userId, title,
    } = props;

    const authorProfile = author.user_profile;
    const safeRelatedLink = (related_link || {});
    const icon = safeRelatedLink?.type_code ? getIconByTypeCode(safeRelatedLink.type_code) : 'link';

    const isOwn = isOwnEntity(userId, props);
    const canDestroy = get(permissions, Permissions.keys.canDestroy);
    const canDestroyOwn = get(permissions, Permissions.keys.canDestroyOwn) && isOwn;
    const destroyAllowed = canDestroy || canDestroyOwn;

    const renderExternalLinkInfo = useCallback(() => {
        return (
            <div className={styles.relatedLinkInfo}>
                <div className={styles.relatedLinkInfoTitleGroup}>
                    <Icon className={styles.relatedLinkIcon} name="link" />
                    <Link
                        className={cx(styles.relatedLinkTitle, styles.relatedLinkExternal)}
                        to={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {title || url}
                    </Link>
                </div>
            </div>
        );
    }, [title, url]);

    let breadcrumbs = tooltip_path.related_link_path || [];

    if (related_link_type === 'milestone' && isGlobal(related_link)) {
        const globalKPIHref = routeUtils.createUrl(routeUtils.paths.planKPIList, {
            [routeUtils.params.PlanId]: goal_id,
            [routeUtils.params.EntityId]: id,
        });
        breadcrumbs = [
            ...breadcrumbs.slice(0, 1),
            { title: titles.milestone, link: globalKPIHref },
            ...breadcrumbs.slice(1, breadcrumbs.length)
        ];
    }

    const renderInternalLinkInfo = useCallback(() => {
        return (
            <div className={styles.relatedLinkInfo}>
                <div className={styles.relatedLinkInfoTitleGroup}>
                    <Icon
                        className={styles.relatedLinkIcon}
                        name={icon}
                        alt="Related Link Icon"
                    />

                    {safeRelatedLink.progress_status ? (
                        <span className={styles.relatedLinkEntityProgressStatus}>
                            <StatusIndicator
                                status={safeRelatedLink.progress_status}
                                size={StatusIndicator.sizes.small}
                            />
                        </span>
                    ) : null}
                    <Link
                        to={href}
                        className={styles.relatedLinkTitle}
                    >
                        {safeRelatedLink.title}
                    </Link>

                </div>
                <div className={styles.relatedLinkPath}>
                    {breadcrumbs.map((item, index, arr) => {
                        const isLast = index >= arr.length - 1;

                        return (
                            <Link
                                key={`${item.entity_type}-${item.entity_id}`}
                                to={item.link || createHrefByTypeCode(item.entity_type, {
                                    ...isLast ? related_link : item,
                                    goal_id
                                })}
                                className={styles.relatedLinkPathItem}
                            >
                                {item.title}{' '}
                                {(index < arr.length - 1) && (
                                    <Icon
                                        name="chevron-right"
                                        className={styles.relatedLinkPathItemIcon}
                                    />
                                )}
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }, [safeRelatedLink.progress_status, safeRelatedLink.title, tooltip_path.related_link_path]);

    return (
        <div className={styles.relatedLinkItem}>
            {(updatingItemId === id) && (
                <div className="loading-overlay">
                    <LoadingIndicator size="small" centered />
                </div>
            )}

            <div className={styles.relatedLinkUser}>
                <Avatar
                    className={styles.relatedLinkUserAvatar}
                    src={authorProfile.image}
                    first_name={authorProfile.first_name}
                    last_name={authorProfile.last_name}
                    size={Avatar.sizes.small}
                />
                <div className={styles.relatedLinkUserName}>{authorProfile.first_name} {authorProfile.last_name}</div>
            </div>
            <div className={styles.relatedLinkBody}>
                <div className={styles.relatedLinkBodyInfoBlock}>
                    { type === 'external' && renderExternalLinkInfo()}
                    { type === 'internal' && renderInternalLinkInfo()}
                </div>
                {destroyAllowed && (
                    <Clickable
                        styleType="danger"
                        outline
                        onClick={() => onRemove(id)}
                    >
                        <Icon
                            name="trash-alt"
                            className={styles.relatedLinkRemoveButtonIcon}
                        />
                    </Clickable>
                )}
            </div>

            <MetaInfo date={updated_at} />
        </div>
    );
}

RelatedLinkListItem.defaultProps = {
    updatingItemId: undefined,
    url: undefined,
    related_link: {},
    tooltip_path: {},
    href: '#',
    title: undefined,
};

RelatedLinkListItem.propTypes = {
    id: PropTypes.number.isRequired,
    goal_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    related_link_type: PropTypes.string.isRequired,
    updatingItemId: PropTypes.number,
    onRemove: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    related_link: PropTypes.shape({
        title: PropTypes.string,
        progress_status: PropTypes.number,
        type_code: PropTypes.number,
    }),
    author: PropTypes.shape({
        user_profile: PropTypes.object,
    }).isRequired,
    url: PropTypes.string,
    href: PropTypes.string,
    permissions: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    tooltip_path: PropTypes.shape({
        related_link_path: PropTypes.array,
    }),
    title: PropTypes.string,
    titles: PropTypes.object.isRequired
};
