import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { ServicePage404, ServicePage405 } from '../../../modules/service-pages';
import {
    Clearfix,
    GridContainer,
    LoadingIndicator,
    Row,
    Col,
    Text
} from '../../components';

// eslint-disable-next-line react/prefer-stateless-function
export default class ListRenderer extends Component {
    render() {
        const {
            items,
            fetching,
            itemProps,
            prepend,
            append,
            className,
            emptyScreen,
            loadingIndicatorProps,
            error,
            hideOnError,
            toggleDetailsModal,
            getItemProps,
            itemClassName,
            wrapperClassName,
            customRenderFunc,
            customRenderProps,
            itemComponent,
            pagination,
            lazyPagination,
            getUniqueId,
            placeholderContainerClassName
        } = this.props;

        const ItemComponent = itemComponent;
        const CustomRender = customRenderFunc;

        if (!fetching && error) {
            if (hideOnError) {
                return null;
            }

            if (error.code === 405) {
                return <ServicePage405 />;
            }

            if (error.code === 400) {
                return (
                    <p>Error occurred during request, please refresh page.</p>
                );
            }

            return <ServicePage404 />;
        }

        return (
            <div
                className={
                    classnames('list-container', {
                        [className]: className
                    })
                }
            >
                { // when we getting data from the server
                    fetching
                    && (
                        <span>
                            <Clearfix />
                            <LoadingIndicator centered {...loadingIndicatorProps} />
                        </span>
                    )
                }

                { // when there is no result
                    (!fetching && !items.length)
                    && (
                        <span>
                            {prepend}
                            <Clearfix />
                            {emptyScreen || (
                                <GridContainer className={placeholderContainerClassName}>
                                    <Row>
                                        <Col base={12}><Text component="h4">No Results</Text></Col>
                                    </Row>
                                </GridContainer>
                            )}
                        </span>
                    )
                }
                { // when we get data
                    (!fetching && items.length)
                        ? (
                            <span>
                                {prepend}

                                <Clearfix />
                                {pagination}

                                <ReactCSSTransitionGroup
                                    className={classnames('list-wrapper', {
                                        [wrapperClassName]: !!wrapperClassName,
                                    })}
                                    transitionName="list-transition"
                                    transitionAppear
                                    transitionAppearTimeout={500}
                                    transitionEnterTimeout={500}
                                    transitionLeaveTimeout={100}
                                >
                                    {!!CustomRender && (
                                        <CustomRender
                                            items={items}
                                            itemProps={itemProps}
                                            getItemProps={getItemProps}
                                            toggleDetailsModal={toggleDetailsModal}
                                            ItemComponent={ItemComponent}
                                            {...customRenderProps}
                                        />
                                    )}

                                    {!CustomRender && items.map((item, index) => (
                                        <div
                                            className={classnames('list-item', {
                                                [itemClassName]: !!itemClassName,
                                            })}
                                            key={getUniqueId(item)}
                                        >
                                            <ItemComponent
                                                {...item}
                                                {...itemProps}
                                                {...getItemProps(item, index)}
                                                index={index}
                                                toggleDetailsModal={toggleDetailsModal}
                                            />
                                        </div>
                                    ))}
                                </ReactCSSTransitionGroup>
                                {pagination}
                                {lazyPagination}
                                {append}
                            </span>
                        )
                        : null
                }
            </div>
        );
    }
}

ListRenderer.defaultProps = {
    items: [],
    itemProps: {},
    fetching: false,
    prepend: null,
    append: null,
    className: undefined,
    emptyScreen: undefined,
    loadingIndicatorProps: {},
    error: undefined,
    hideOnError: false,
    itemClassName: undefined,
    wrapperClassName: undefined,
    getItemProps() {
        return {};
    },
    uniqueIdAttribute: 'tag_name',
    customRenderFunc: null,
    customRenderProps: {},
    toggleDetailsModal: () => {},
    getUniqueId: () => {},
    itemComponent: undefined,
    pagination: undefined,
    lazyPagination: undefined,
    placeholderContainerClassName: undefined
};

ListRenderer.propTypes = {
    items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    customRenderProps: PropTypes.object,
    fetching: PropTypes.bool,
    itemProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    prepend: PropTypes.node,
    append: PropTypes.node,
    className: PropTypes.string,
    emptyScreen: PropTypes.node,
    toggleDetailsModal: PropTypes.func,
    loadingIndicatorProps: PropTypes.shape({
        centered: PropTypes.bool,
        size: PropTypes.string,
    }),
    error: PropTypes.shape({
        code: PropTypes.number,
    }),
    hideOnError: PropTypes.bool,
    getItemProps: PropTypes.func,
    itemClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    uniqueIdAttribute: PropTypes.string,
    customRenderFunc: PropTypes.func,
    itemComponent: PropTypes.func,
    getUniqueId: PropTypes.func,
    pagination: PropTypes.node,
    lazyPagination: PropTypes.node,
    placeholderContainerClassName: PropTypes.string
};
