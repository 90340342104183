import { camelCase } from 'lodash';
import u from 'updeep';
import apiService from '../../../request';
import { Model } from '../../entities-v2';
import { keysToCamelCase, keysToSnakeCase } from '../../utils/object';

const teamUserFilter = user => !!user.userId;

const submitTypes = Object.freeze({
    leader: 'leader',
    member: 'member',
});

const getApiUrlBySubmitType = Object.freeze({
    [submitTypes.leader]: id => `/teams/${id}`,
    [submitTypes.member]: id => `/teams/${id}/members`,
});

const methodBySubmitType = Object.freeze({
    [submitTypes.leader]: 'put',
    [submitTypes.member]: 'post',
});

class TeamModel extends Model {
    format = (data, requestType) => {
        if (requestType === Model.requestTypes.remove) {
            return data;
        }

        const result = { ...data };

        let nextTeamUsers = result.teamUsers.filter(teamUserFilter);

        if (result.submitType === submitTypes.member) {
            nextTeamUsers = nextTeamUsers.filter((user) => {
                return !data.persistedTeamUserIds.includes(user.userId);
            });
        }

        delete result.id;
        delete result.submitType;
        delete result.persistedTeamUserIds;

        return keysToSnakeCase(u.updateIn('teamUsers', nextTeamUsers, result));
    }

    parse = keysToCamelCase;

    parseError = (error) => {
        const { data } = error.response;

        if (!data.errors) {
            return Promise.reject(data);
        }

        const errorsReducer = (accum, [key, value]) => {
            const nextKey = key
                .split('.')
                .map(camelCase)
                .join('.');

            accum[nextKey] = value;
            return accum;
        };

        const errors = Object
            .entries(data.errors)
            .reduce(errorsReducer, {});

        const errorDescription = {
            errors,
            status: error.response.status,
        };

        return Promise.reject(errorDescription);
    }

    update(data, options) {
        const apiUrl = getApiUrlBySubmitType[data.submitType](data.id);
        const method = methodBySubmitType[data.submitType];
        const formattedData = this.format(data, Model.requestTypes.update);

        return apiService[method](apiUrl, formattedData, options)
            .then(response => this.parse(response, Model.requestTypes.update))
            .catch(this.parseError);
    }
}

TeamModel.submitTypes = submitTypes;

export default TeamModel;
