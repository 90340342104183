import { call, put } from 'redux-saga/effects';

export default function createAsyncSaga(actions, request) {
    return function* asyncSaga({ payload = {} }) {
        const {
            reject,
            resolve,
            onSuccess,
            onError,
            silent,
            ...rest
        } = payload;

        try {
            const response = yield call(request, rest);

            if (resolve) {
                resolve(response);
            }

            yield put({
                type: actions.success.getType(),
                payload: (response || rest)
            });

            if (onSuccess) {
                onSuccess(response);
            }
        } catch (err) {
            if (reject) {
                reject(err);
            }

            yield put({
                type: actions.error.getType(),
                payload: err
            });

            if (onError) {
                onError(err);
            }
        }
    };
}
