import PropTypes from 'prop-types';
import React, {
    useMemo, useRef, useState, useEffect
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col } from '../../shared/components';
import { getIsAuthorizedState, getIsAuthenticationView } from '../authentication/selectors';
import { logout as logoutAction } from '../authentication/actions';
import { SubscriptionLimitProviders, subscriptionFields, subscriptionLimitSelectors } from '../subscription';
import SearchContainer from './search-form/search-container';
import QuickAdd from './quick-add/quick-add';
import UserInfo from './components/user-info';
import NavigationButtons from './components/navigation-buttons';
import Notifications from './notifications';
import { createProgressNotes, details } from './entity';
import { applicationSettingsSelectors } from '../application';
import { selectIntegrationIsActive, slugs as integrationSlugs } from '../application/integrations/integrations-collection';
import { getLogo } from './utils';
import { getHaveUserPermissionForSystemSettings } from '../permissions/utils';
import { selectSystemPermissions } from '../permissions/selectors';
import './styles/main.css';
import useResizeObserver from '../../shared/hooks/use-resize-observer';

const { actions } = createProgressNotes;

const checkExtraLargeDevice = headerWidth => (headerWidth >= 1700);

function NavigationHeader({
    authorized, isAuthenticationView, logout, userFetching, userInfo, create, location, settingsData,
    showDetailsModal, systemPermissions, isZapierActive, zapierLimit,
}) {
    const containerRef = useRef(null);
    const [searchIsActive, setSearchIsActive] = useState(false);
    const logo = getLogo(settingsData.logo);
    const [setNode, observerEntry] = useResizeObserver();

    useEffect(() => {
        setNode(containerRef.current);
    }, []);

    const isUserHavePermissionForSystemSettings = systemPermissions ? getHaveUserPermissionForSystemSettings(systemPermissions) : false;

    const headerWidth = useMemo(() => {
        if (observerEntry.target) {
            return observerEntry.target.clientWidth;
        }

        return 0;
    }, [observerEntry.target?.clientWidth]);

    const isExtraLargeDevice = checkExtraLargeDevice(headerWidth);

    const handleClickActiveSearch = (e) => {
        e.preventDefault();
        setSearchIsActive(true);
    };

    useEffect(() => {
        setSearchIsActive(isExtraLargeDevice);
    }, [headerWidth]);

    const handleClickOutside = () => {
        if (!isExtraLargeDevice) setSearchIsActive(false);
    };

    if (isAuthenticationView) {
        return null;
    }

    return (
        <header id="nav-header" ref={containerRef}>
            <div className="container">
                <Row>
                    {authorized ? (
                        <>
                            <Col md={8} lg={8} xl={8} className="nav-header-buttons-container">
                                <Link to="/" className="nav-header-logo">
                                    <div id="logo" style={{ backgroundImage: `url(${logo})` }} />
                                </Link>
                                {!(searchIsActive && !isExtraLargeDevice) && <NavigationButtons locationPathname={location.pathname} />}
                            </Col>
                            <Col md={4} lg={4} xl={4} className="nav-header-elements-container">
                                <div className="nav-header-element">
                                    <SearchContainer
                                        location={location}
                                        isActive={searchIsActive}
                                        handleClickActiveSearch={handleClickActiveSearch}
                                        handleClickOutside={handleClickOutside}
                                    />
                                </div>
                                <div className="nav-header-element">
                                    <SubscriptionLimitProviders.Draft>
                                        {(limitProps) => {
                                            const attrs = SubscriptionLimitProviders.Draft.attributes;
                                            const available = limitProps[attrs.value];

                                            return (
                                                <QuickAdd
                                                    canCreateDraft={available}
                                                    onSubmit={create}
                                                />
                                            );
                                        }}
                                    </SubscriptionLimitProviders.Draft>
                                </div>
                                <div className="nav-header-element">
                                    <Notifications tooltipContentClassName="nav-header-tooltip-content" />
                                </div>
                                <div className="nav-header-element">
                                    {!userFetching && (
                                        <UserInfo
                                            logout={logout}
                                            userName={`${userInfo.first_name} ${userInfo.last_name}`}
                                            userEmail={userInfo.email}
                                            userTitle="VP East Coast Sales"
                                            avatar={userInfo.image}
                                            id={userInfo.id}
                                            showDetailsModal={showDetailsModal}
                                            isUserHavePermissionForSystemSettings={isUserHavePermissionForSystemSettings}
                                            isZapierActive={isZapierActive && zapierLimit}
                                        />
                                    )}
                                </div>
                            </Col>
                        </>
                    ) : (
                        <Col base={12}>
                            <Link to="/" className="nav-header-logo">
                                <div id="logo" style={{ backgroundImage: `url(${logo})` }} />
                            </Link>
                        </Col>
                    )}
                </Row>
            </div>
        </header>
    );
}

NavigationHeader.defaultProps = {
    systemPermissions: undefined,
    isZapierActive: false,
    zapierLimit: false,
};

NavigationHeader.propTypes = {
    authorized: PropTypes.bool.isRequired,
    isAuthenticationView: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    showDetailsModal: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    settingsData: PropTypes.object.isRequired,
    userFetching: PropTypes.bool.isRequired,
    userInfo: PropTypes.shape({
        picture: PropTypes.string,
        given_name: PropTypes.string,
        family_name: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        image: PropTypes.string,
        id: PropTypes.number,
        email: PropTypes.string,
    }).isRequired,
    systemPermissions: PropTypes.object,
    isZapierActive: PropTypes.bool,
    zapierLimit: PropTypes.bool,
};

const mapStateToProps = state => ({
    authorized: getIsAuthorizedState(state),
    isAuthenticationView: getIsAuthenticationView(state),
    userFetching: state.user.fetching,
    userInfo: state.user.data,
    settingsData: applicationSettingsSelectors.selectApplicationData(state),
    user: state.user.data,
    systemPermissions: selectSystemPermissions(state),
    isZapierActive: selectIntegrationIsActive(integrationSlugs.zapier)(state),
    zapierLimit: subscriptionLimitSelectors.getLimitByEntity(state, subscriptionFields.zapier_integration).value,
});

const mapDispatchToProps = {
    create: actions.request,
    logout: logoutAction,
    showDetailsModal: details.actions.toggleModal,
    updateMemberStatus: details.actions.updateStatus.request,
};

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(NavigationHeader);
export default connectedHeader;
