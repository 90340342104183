import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

export default class Transition extends Component {
    state = {
        mounted: false
    };

    componentWillMount() {
        if (this.props.visible) {
            this.setState({
                mounted: true
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                mounted: true
            });
        }
    }

    onTransitionEnd = () => {
        if (!this.props.visible) {
            this.setState({
                mounted: false
            });

            return this.props.onHide();
        }

        return this.props.onShow();
    }

    render() {
        const {
            children,
            visible,
            className,
            visibleClassName,
            onShow,
            onHide,
            ...rest
        } = this.props;

        return (
            <div
                className={classnames(className, {
                    [visibleClassName]: visible
                })}
                onTransitionEnd={this.onTransitionEnd}
                {...rest}
            >
                {this.state.mounted ? children : null}
            </div>
        );
    }
}

Transition.defaultProps = {
    visible: false,
    children: undefined,
    className: undefined,
    onShow() {},
    onHide() {},
};

Transition.propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    visibleClassName: PropTypes.string.isRequired,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
};
