import u from 'updeep';
import request from '../../request';
import {
    List, createModal, createDetails
} from '../../shared/entities';
import { getEntitiesByType } from '../../shared/utils/content-editor-entity-selector';
import { types } from '../../shared/utils/entity-type';
import { format as formatKPI } from '../goalmap-milestones/entity';
// import { entityState } from '../../shared/utils/entity';

// const apiUrl = '/segments';
const listName = 'segment-children-list';

export const list = new List({
    name: listName,
    getApiUrl({ parentId, goalId, parentTypeCode }) {
        return `/goals/${goalId}/children/${parentTypeCode}/${parentId}`;
    },
    extendReducer: {
        onAdd(state, action) {
            if (action.state === 4) {
                let result = state;

                const currentDraftValue = state.raw.children_counts
                    ? state.raw.children_counts.draft
                    : 0;

                const entityTypeToSet = action.segment
                    ? 'has_plan_actions'
                    : 'has_segments';

                result = u.updateIn(
                    ['raw', entityTypeToSet],
                    true,
                    result
                );

                return u.updateIn(
                    ['raw', 'children_counts', 'draft'],
                    currentDraftValue + 1,
                    result
                );
            }

            return state;
        }
    },
    format(params = {}) {
        const filter = params.filter || {};

        const order_by = params.order_by
            ? params.order_by
            : undefined;

        const result = u({
            order_by,
            filter: undefined,
            ...filter,
            limit: 10,
        }, params);

        return result;
    },
    parseItem(item) {
        return {
            ...item,
            ...item.entity,
        };
    }
});

function createEntity({ params }) {
    const { entity_type, ...rest } = params;

    const mentions = getEntitiesByType(rest.description, 'mention');
    const data = {
        ...rest,
        mentions,
    };

    let url = null;
    let dataToSend = data;

    switch (entity_type) {
        case types.sub_segment:
            url = 'segments';
            break;
        case types.plan:
            url = 'plans';
            break;
        case types.milestone:
            url = 'milestones';
            dataToSend = formatKPI(data);
            break;
        default: url = 'actions';
    }

    return request
        .post(`/${url}`, dataToSend)
        .then(response => ({
            ...response,
            entity: response,
        }));
}

export const create = createModal({
    name: 'segment-children-create',
    updateHTML: true,
    extendAPI: { create: createEntity }
});

export const createObjectiveChildren = createModal({
    name: 'objective-children-create',
    updateHTML: true,
    extendAPI: { create: createEntity }
});

export const details = createDetails({
    apiUrl(data) {
        const entityType = data.entity_type;
        switch (entityType) {
            case types.sub_segment:
                return 'segments';
            case types.plan:
                return 'plans';
            case types.milestone:
                return 'milestones';
            default:
                return 'actions';
        }
    },
    format(data) {
        const entityType = data.entity_type;
        switch (entityType) {
            case types.milestone:
                return formatKPI(data);
            default:
                return data;
        }
    },
    name: 'segment-children-details',
    updateHTML: true
});
