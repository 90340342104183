import React from 'react';
import { ServicePage } from '../../shared/components';

export default function ServicePageCookiesError() {
    return (
        <ServicePage
            icon="lock-alt"
            title="Your cookies are blocked"
            description=" Please enable cookies in order to use the application"
        />
    );
}
