import PropTypes from 'prop-types';

export const name = 'nav-context'; // eslint-disable-line import/prefer-default-export
export const localStorageKey = 'envision-nav-context';

export const shape = {
    id: PropTypes.number,
    entityType: PropTypes.string,
    tab: PropTypes.string,
    goalId: PropTypes.number,
};
