import { takeEvery, put, select } from 'redux-saga/effects';

import { selectUserId } from '../../user/selectors';
import { details as planDetails } from '../../goalmap-plans-actions/entity';

import { collaboratorCollection, collaboratorCreateModel, collaboratorModel } from './collaborator-models';
import { updateWatchStatus } from './collaborator-actions';

function payloadCountainsUser(payload = [], userId) {
    let result = false;

    payload.forEach((item) => {
        if (item.user_id === userId) {
            result = true;
        }
    });

    return result;
}

function* collaboratorSaga() {
    yield takeEvery(
        collaboratorCreateModel.actions.success.getType(),
        function* update({ payload }) {
            const userId = yield select(selectUserId);

            if (payloadCountainsUser(payload, userId)) {
                yield put({
                    type: updateWatchStatus.getType(),
                    payload: {
                        status: true,
                    }
                });
            }
        }
    );

    yield takeEvery(
        collaboratorModel.actions.remove.success.getType(),
        function* remove({ payload }) {
            const userId = yield select(selectUserId);

            if (userId === payload.id) {
                yield put({
                    type: updateWatchStatus.getType(),
                    payload: {
                        status: false,
                    },
                });
            }
        }
    );

    yield takeEvery(
        planDetails.actions.update.success.getType(),
        function* updateList({ payload }) {
            yield put({
                type: collaboratorCollection.actions.request.getType(),
                payload: {
                    includes: 'user.user_profile',
                    watcher_id: payload.id,
                    watcher_type: 'plan_action',
                    page: 1,
                }
            });
        }
    );
}

export default collaboratorSaga;
