import u from 'updeep';
import { get } from 'lodash';
import { createHrefByTypeCode } from '../../../shared/utils/compose-href';
import { getIconByTypeCode } from '../../../shared/utils/entity';
import { attributeNames as attrNames, types } from './related-link-constants';

export default function parseItem(item) {
    const typeCode = get(item, [attrNames.relatedLink, attrNames.typeCode]);
    const entity = get(item, attrNames.relatedLink);

    const href = get(item, attrNames.type) === types.internal
        ? createHrefByTypeCode(typeCode, entity)
        : undefined;

    const icon_name = entity ? getIconByTypeCode(typeCode) : 'link';

    return u({ href, icon_name }, item);
}
