import React from 'react';
import PropTypes from 'prop-types';
import PanelWithAction from '../../components/panel-with-action/component';
import GoalMember from './component';
import './goal-member-pane.css';

export default function GoalMemberPane(props) {
    return (
        <PanelWithAction className="goal-member-pane" iconName="trash-alt" onActionClick={props.onRemoveUser}>
            <GoalMember {...props} />
        </PanelWithAction>
    );
}

GoalMemberPane.propTypes = {
    onRemoveUser: PropTypes.func.isRequired
};
