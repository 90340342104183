import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { isMobile, } from 'mobile-device-detect';
import { useHoverIntent } from '../../hooks/use-hover-intent';
import Icon from '../icon/component';
import Button from '../button/component';

import styles from './styles.module.css';

const anchors = Object.freeze({
    left: 'left',
    right: 'right',
});

function getAnchorClassName(anchor) {
    switch (anchor) {
        case anchors.right:
            return styles.anchorRight;
        case anchors.left:
        default:
            return styles.anchorLeft;
    }
}

const hoverIntentConfig = {
    timeout: 100,
    sensitivity: 6,
    interval: 200,
};

function validateBreakpoint(visibleBreakpoints, currentBreakpoint) {
    return !visibleBreakpoints || visibleBreakpoints.includes(currentBreakpoint);
}

const Drawer = ({
    children, open, onToggleDrawer, className, openClassName, anchor
}) => {
    const [isHovered, intentRef, setIsHovering] = useHoverIntent({ ...hoverIntentConfig, isMobile });
    const visible = open || isHovered;

    const rootClassNames = classnames(styles.componentDrawer, getAnchorClassName(anchor), {
        [styles.open]: visible,
        [openClassName]: visible && !!openClassName,
        [className]: !!className,
    });
    const buttonClassNames = classnames(styles.buttonToggle, getAnchorClassName(anchor));
    const icon = visible ? 'chevron-left' : 'chevron-right';
    const childrenProps = {
        isHovered,
        isDrawerOpen: visible,
        setIsHovering,
    };

    return (
        <div className={rootClassNames}>
            <Button
                size="small"
                round
                className={buttonClassNames}
                onClick={onToggleDrawer}
            >
                <Icon
                    type="regular"
                    name={icon}
                    className={styles.buttonToggleIcon}
                />
            </Button>
            <div
                className={styles.content}
                ref={intentRef}
            >
                {children(childrenProps)}
            </div>
        </div>
    );
};

Drawer.anchors = anchors;
Drawer.validateBreakpoint = validateBreakpoint;

Drawer.defaultProps = {
    open: false,
    onToggleDrawer: noop,
    className: undefined,
    openClassName: undefined,
    anchor: anchors.left,
};

Drawer.propTypes = {
    children: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onToggleDrawer: PropTypes.func,
    className: PropTypes.string,
    openClassName: PropTypes.string,
    anchor: PropTypes.oneOf(Object.values(anchors)),
};

export default Drawer;
