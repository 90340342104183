import Loadable from 'react-loadable';
import _ from 'lodash';
import withScrollTop from '../shared/components/with-scroll-top';
import withMobileRestriction from '../shared/modules/with-mobile-restrict';
import { withStickyContext } from '../shared/components/sticky/sticky-context';
import { stickyConf } from '../shared/components/sticky/config';
import Loading from './components/loadable';
import { createUrl, paths } from './rules';


const Preferences = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/preferences/index'),
    loading: Loading,
});

const PreferencesEmail = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/preferences/email/preferences-email'),
    loading: Loading,
});

const PreferencesAccountSecurity = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/preferences/account-security/preferences-account-security'),
    loading: Loading,
});

const PreferencesStorageServices = Loadable({
    loader: () => import(/* webpackChunkName: "authenticated" */ '../modules/preferences/storage-services/preferences-storage-services'),
    loading: Loading,
});

export default {
    path: createUrl(paths.preferences),
    component: _.flow(
        withScrollTop,
        withMobileRestriction,
        withStickyContext(stickyConf.default)
    )(Preferences),
    name: 'Preferences',
    createHref: () => createUrl(paths.preferences),
    indexRoute: {
        onEnter: (nextState, replace) => replace(createUrl(paths.preferencesEmail))
    },
    childRoutes: [
        {
            name: 'Storage Services',
            path: createUrl(paths.preferencesStorageServices),
            component: withScrollTop(PreferencesStorageServices),
            createHref: () => createUrl(paths.preferencesStorageServices),
        },
        {
            name: 'Email Preferences',
            path: createUrl(paths.preferencesEmail),
            component: withScrollTop(PreferencesEmail),
            createHref: () => createUrl(paths.preferencesEmail),
        },
        {
            name: 'Account Security',
            path: createUrl(paths.preferencesAccountSecurity),
            component: withScrollTop(PreferencesAccountSecurity),
            createHref: () => createUrl(paths.preferencesAccountSecurity),
        },
    ],
};
