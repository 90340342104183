import PropTypes from 'prop-types';
import React from 'react';
import Button from '../button/component';
import LoadingIndicator from '../loading-indicator/component';
import './styles.css';

export default function PaginationLazy({
    currentPage, totalPages, getMore, loading
}) {
    if (totalPages <= currentPage) {
        return null;
    }

    return (
        <div className="pagination-lazy">
            {
                !loading
                    ? (
                        <Button
                            outline
                            onClick={() => getMore(currentPage + 1)}
                        >
                            Load More
                        </Button>
                    )
                    : (
                        <LoadingIndicator
                            centered
                            size="small"
                        />
                    )
            }
        </div>
    );
}

PaginationLazy.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    getMore: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
