import { takeEvery, put } from 'redux-saga/effects';
import { progressNoteModel } from './progress-note-models';
import * as Notifications from '../../notifications/toast';
import { messages } from './progress-note-utils';

function* handleRemoveSuccess() {
    const action = Notifications.create({
        type: Notifications.types.SERVICE,
        data: {
            type: Notifications.serviceTypes.SUCCESS,
            text: messages.delete,
        }
    });

    yield put(action);
}

function* progressNoteSaga() {
    yield takeEvery(progressNoteModel.actions.remove.success, handleRemoveSuccess);
}

export default progressNoteSaga;
