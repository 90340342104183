export const paramNames = Object.freeze({
    slug: 'slug',
    filter: 'filter',
    search: 'search',
    archive: 'archive',
});

export const states = Object.freeze({
    active: 'active',
    inactive: 'inactive',
    empty: 'all',
});

export const filterOptions = [
    {
        label: 'All',
        value: states.empty,
    },
    {
        label: 'Active',
        value: states.active,
    },
    {
        label: 'Inactive',
        value: states.inactive,
    },
];
