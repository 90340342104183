import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import { Modal } from '../../../shared/components';
import ModalFormContentDetailsView from './modal-form-details-view';

export default function createForm(options) {
    const {
        actions, selector, formOptions, ControlsComponent, parseFormSubmitError, forbidReset, hideDeleteButton,
    } = options;

    return (EnhancedComponent) => {
        const ReduxBindedFormContent = reduxForm(formOptions)(ModalFormContentDetailsView);

        class CreateFormModal extends Component {
            componentDidMount() {
                const {
                    initializeForm, initialValues, isNeedInitializeForm
                } = this.props;
                if (isNeedInitializeForm && initialValues) {
                    initializeForm(initialValues);
                }
            }

            componentDidUpdate(prevProps) {
                const {
                    fetch, id, fetching, initializeForm, initialValues, onFetchError, fetchOptionsFormat,
                } = this.props;
                if (id && prevProps.id !== id) {
                    const onError = ({ response }) => {
                        if (onFetchError) {
                            onFetchError(response);
                        }
                        initializeForm(formOptions.form, { fetchingError: response.data }, true);
                    };
                    const params = fetchOptionsFormat({ id, onError });
                    fetch(...params);
                }

                if (!fetching && prevProps.fetching) {
                    initializeForm(initialValues);
                }
            }

            render() {
                const {
                    visible, onShow, onHideEnd, modalContentClassName, onSubmit, toggle, modalContentOuterClassName, ...rest
                } = this.props;

                return (
                    <Modal
                        visible={visible}
                        hide={this.hide}
                        onShow={onShow}
                        onHideEnd={onHideEnd}
                        modalContentClassName={modalContentClassName}
                        modalContentOuterClassName={modalContentOuterClassName}
                    >
                        <ReduxBindedFormContent
                            EnhancedComponent={EnhancedComponent}
                            ControlsComponent={ControlsComponent}
                            parseFormSubmitError={parseFormSubmitError}
                            onSubmit={onSubmit}
                            toggle={toggle}
                            hideDeleteButton={hideDeleteButton}
                            forbidReset={forbidReset}
                            {...rest}
                        />
                    </Modal>
                );
            }
        }

        CreateFormModal.defaultProps = {
            id: undefined,
            initialValues: undefined,
            onShow() {},
            onHideEnd() {},
            isNeedInitializeForm: false,
            modalContentClassName: undefined,
            modalContentOuterClassName: undefined,
            fetchOptionsFormat: params => [params],
        };

        CreateFormModal.propTypes = {
            fetch: PropTypes.func.isRequired,
            fetching: PropTypes.bool.isRequired,
            id: PropTypes.number,
            initializeForm: PropTypes.func.isRequired,
            initialValues: PropTypes.object,
            isNeedInitializeForm: PropTypes.bool,
            visible: PropTypes.bool.isRequired,
            onShow: PropTypes.func,
            onHideEnd: PropTypes.func,
            modalContentClassName: PropTypes.string,
            modalContentOuterClassName: PropTypes.string,
            onSubmit: PropTypes.func.isRequired,
            toggle: PropTypes.func.isRequired,
            onFetchError: PropTypes.func.isRequired,
            fetchOptionsFormat: PropTypes.func,
        };

        function mapStateToProps(state, ownProps) {
            const currentSelector = selector(state);
            return {
                visible: currentSelector.modalVisible,
                fetching: currentSelector.loading,
                id: currentSelector.modalItemId && Number(currentSelector.modalItemId),
                updating: currentSelector.updating,
                user: currentSelector.data,
                ...(
                    options.mapStateToProps
                        ? options.mapStateToProps(state, ownProps)
                        : undefined
                ),
            };
        }

        const mapDispatchToProps = {
            fetch: actions.request,
            remove: actions.remove.request,
            onSubmit: actions.update.request,
            // toDo after move all modals to new model, change toggleModal to toggle
            toggle: actions.toggleModal,
            // toDo after move all modals to new model, change updateStatus to sendInvitation
            resendInvite: actions.updateStatus.request,
            initializeForm: initialize,
        };

        return connect(mapStateToProps, mapDispatchToProps)(CreateFormModal);
    };
}
