import PropTypes from 'prop-types';
import React from 'react';
import Clickable from '../clickable/component';
import { get as getCustomProp } from '../../utils/css-custom-properties';
import './styles.css';
import hexToRgba from '../../utils/color';

// const TOTAL_HEIGHT_PX = 450; // 200;
const TOTAL_HEIGHT_PX = 290; // 200;

function getMaxValue(arr) {
    let max = 1;

    arr.forEach((item) => {
        if (item.value > max) {
            max = item.value;
        }
    });

    return max;
}

export default function ChartBarItem({ data, handleClick }) {
    const maxValue = getMaxValue(data);
    const onePersPart = 100 / maxValue;

    return (
        <div
            className="chart-bar-container"
            style={{ height: `${TOTAL_HEIGHT_PX}px` }}
        >
            {data.map((item) => {
                const style = {
                    backgroundColor: hexToRgba(getCustomProp(item.color), 1),
                    width: `${item.value * onePersPart}%`,
                    // boxShadow: `0 2px 6px 0 ${hexToRgba(getCustomProp(item.color), 0.5)}`,
                };

                const backgroundStyle = {
                    color: item.color,
                    backgroundColor: hexToRgba(getCustomProp(item.color), 0.2),
                };

                return (
                    <div className="chart-item" key={item.color}>
                        <div className="text-info">
                            <div className="text">{item.name}</div>
                            <div className="value">{item.value}</div>
                        </div>

                        <Clickable
                            action="chart-bar-action"
                            className="chart-line-container"
                            key={item.name}
                            style={backgroundStyle}
                            onClick={() => handleClick(item.slug)}
                        >
                            <div className="chart-line-value" style={style} />
                        </Clickable>
                    </div>
                );
            })}
        </div>
    );
}

ChartBarItem.defaultProps = {
    data: [],
    handleClick() { },
};

ChartBarItem.propTypes = {
    handleClick: PropTypes.func,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            color: PropTypes.string,
            value: PropTypes.number,
        }),
    ),
};
