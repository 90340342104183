import { types } from '../../shared/utils/entity-type';

export const segmentChildrenAttributes = Object.freeze({
    parent_id: 'parent_id',
    segment_id: 'segment_id',
    state: 'state',
    started_date: 'started_date',
    target_date: 'target_date',
    closed_date: 'closed_date',
    update_required: 'update_required',
    entity_children_counters: 'entity_children_counters',
    status: 'status',
    type_code: 'type_code',
});

export const objectiveAttributes = Object.freeze({
    id: 'id',
    title: 'title',
    description: 'description',
    entity_type: 'entity_type',
    author_id: 'author_id',
    owner_id: 'owner_id',
    type: 'type',
    segment_id: 'segment_id',
    parent_id: 'parent_id',
    state: 'state',
    tag_name: 'tag_name',
    children: 'children',
    tags: 'tags'
});

export const defaultAvailableEntities = Object.freeze({
    [types.sub_segment]: false,
    [types.action]: false,
    [types.milestone]: false,
});
